import { environment } from '../../../../../../environment';
import { IProjectType } from '../model/project-type.interface';
import { axiosClient } from '../../../../../../axios-client';
import { AxiosResponse } from 'axios';
import { IPagination } from "../../../../../../core/model/pagination.interface";

class ProjectTypeApi {
  private baseUrl: string = `${environment.backendUrl}/forms`;

  // async getAll(): Promise<IProjectType[]> {
  //   try {
  //     const response: AxiosResponse<IProjectType[]> = await axiosClient.get<IProjectType[]>(`${this.baseUrl}`);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getAllPaginated(params?: any): Promise<{ content: IProjectType[], pagination: IPagination }> {
    try {
      const response: AxiosResponse<{ content: IProjectType[], pagination: IPagination }> = await axiosClient.get<{
        content: IProjectType[],
        pagination: IPagination
      }>(`${this.baseUrl}/paginated`, {
        params: params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getById(id: number): Promise<IProjectType> {
    try {
      const response: AxiosResponse<IProjectType> =
        await axiosClient.get<IProjectType>(`${this.baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async create(projectType: IProjectType): Promise<IProjectType> {
    try {
      const response: AxiosResponse<IProjectType> =
        await axiosClient.post<IProjectType>(`${this.baseUrl}`, projectType);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async update(id: number, projectType: IProjectType): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/${id}`,
        projectType
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async archive(id: number): Promise<IProjectType> {
    try {
      const response: AxiosResponse<IProjectType> =
        await axiosClient.delete<IProjectType>(`${this.baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatus(id: number, status: string): Promise<IProjectType> {
    try {
      const response: AxiosResponse<IProjectType> =
        await axiosClient.post<IProjectType>(`${this.baseUrl}/status/${id}`, {
          status,
        });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getWorkflows(params?: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${environment.backendUrl}/workflow`, {
          params: params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ProjectTypeApi();
