import * as React from "react";

const SvgServerError = (props: any) => {
  return (
    <svg width={385} height={208} viewBox="0 0 385 208"  {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-849 -298) translate(851 301)">
            <g>
              <path fill="#FFF"
                    d="M40.986 48.68L5.04 18.199c-2.096-1.778-3.355-4.355-3.476-7.11-.117-2.757.922-5.434 2.861-7.384.27-.274.543-.546.813-.817 3.66-3.685 9.536-3.862 13.41-.41 11.236 10.017 32.248 28.754 32.248 28.754"
                    transform="rotate(125 57.028 79.457)"/>
              <path stroke="#1B3380" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.483"
                    d="M39.431 48.68L3.485 18.196C1.389 16.419.13 13.84.009 11.087-.108 8.33.93 5.652 2.87 3.703c.27-.274.543-.546.813-.818 3.66-3.684 9.536-3.861 13.41-.409L49.342 31.23"
                    transform="rotate(125 57.028 79.457)"/>
            </g>
            <path stroke="#1B3380" strokeLinecap="round" strokeWidth="6.049"
                  d="M282.817 63.193c-4.566 6.14-18.31 1.024-41.232-15.346-34.384-24.556-82.534 12.114-53.852 35.24 28.682 23.125 63.496-42.865 10.765-48.747-52.732-5.883-59.355 57.086-88.768 96.372-19.61 26.19-53.807 42.945-102.593 50.266"/>
            <g fill="#1B3380">
              <path
                d="M25.793 0c2.249 0 4.072 1.823 4.072 4.073v10.982h5.43V27.52c0 9.747-7.9 17.648-17.647 17.648C7.9 45.167 0 37.266 0 27.519V15.056l5.43-.001V4.073C5.43 1.823 7.253 0 9.503 0c2.249 0 4.072 1.823 4.072 4.073v10.982h8.145V4.073C21.72 1.823 23.544 0 25.793 0z"
                transform="rotate(67 139.39 257.177)"/>
              <path
                d="M9.778 43.04h16.135v1.491c0 3.612-2.927 6.54-6.539 6.54h-3.057c-3.611 0-6.539-2.928-6.539-6.54v-1.49z"
                transform="rotate(67 139.39 257.177)"/>
            </g>
            <ellipse cx="360.857" cy="38.281" fill="#1B3380" rx="3.025" ry="3.011"/>
            <ellipse cx="360.857" cy="51.33" fill="#1B3380" rx="3.025" ry="3.011"/>
            <ellipse cx="364.386" cy="43.802" stroke="#D1D8E0" strokeWidth="4.032" rx="15.63" ry="32.629"/>
            <g fill="#55A0F6">
              <path d="M14.87 37.551v10.74h4.242l-7.744 14.32-.001-10.74h-4.24l7.744-14.32z"
                    transform="rotate(-27 193.364 -641.107) rotate(-45 13.12 50.082)"/>
              <path d="M25.657 18.457v10.74h4.241l-7.744 14.321v-10.74h-4.24l7.743-14.32z"
                    transform="rotate(-27 193.364 -641.107) rotate(90 23.906 30.988)"/>
              <path d="M14.87.557v10.74h4.242l-7.744 14.32-.001-10.74h-4.24L14.87.557z"
                    transform="rotate(-27 193.364 -641.107) rotate(45 13.12 13.087)"/>
            </g>
            <path stroke="#D1D8E0" strokeWidth="4.032"
                  d="M364.067 11.194c2.587-.025 4.788.912 6.787 2.734 1.81 1.65 3.467 4.055 4.847 7.068h0l.305.685c1.22 2.826 2.215 6.156 2.907 9.84h0l.164.91.152.93.139.935c.399 2.842.624 5.858.646 8.992h0l.002.514c0 3.313-.227 6.506-.648 9.506h0l-.139.935-.152.93-.164.91c-.692 3.683-1.687 7.013-2.907 9.84h0l-.305.685c-1.38 3.013-3.037 5.418-4.847 7.067-1.997 1.82-4.196 2.756-6.468 2.756-.341 0-.61-.077-.824-.17-.47-.204-.787-.527-.996-.924-.187-.357-.398-.883-.1-1.684.058-.158.18-.418.433-.736.178-.224.506-.58.938-1.039 1.327-1.412 3.835-4.038 5.162-6.926h0l.274-.616.399-.964.254-.665c.248-.668.483-1.361.704-2.078h0l.224-.751c.25-.864.479-1.76.687-2.684h0l.177-.814.165-.82.153-.831.21-1.282.186-1.316c.057-.435.109-.873.156-1.315h0l.092-.917c.07-.753.127-1.516.171-2.288h0l.047-.945.034-.953c.013-.472.021-.946.025-1.423h0l-.001-.76c-.003-.684-.011-1.164-.024-1.639h0l-.034-.954-.047-.944c-.044-.773-.101-1.536-.17-2.288h0l-.093-.917c-.064-.593-.135-1.179-.215-1.757h0l-.127-.875-.136-.85-.228-1.266-.163-.813-.27-1.22c-.489-2.085-1.086-4.022-1.775-5.77h0l-.27-.661c-.172-.413-.35-.813-.534-1.202h0l-.292-.6c-1.25-2.482-3.481-4.76-4.715-6.04-.44-.457-.775-.812-.956-1.034-.268-.331-.393-.604-.452-.768-.303-.84-.062-1.381.158-1.753.219-.372.575-.844 1.454-.985z"/>
            <ellipse cx="130.932" cy="191.347" fill="#D1D8E0" rx="70.591" ry="9.535"/>
            <g>
              <path fill="#FFF" stroke="#1B3380" strokeWidth="2.483"
                    d="M108.41 108.84L77.735 91.06c-2.034.129-6.402 6.185-7.108 7.83-4.178 9.735-7.826 23.723-9.248 34.97l-11.595-1.126-10.84 10.84-14.001-.014L42.46 161.08l12.41-12.41 10.623 4.914c3.514 3.39 8.333 7.03 11.756 8.593L75.02 178.9l-12.134 6.96 23.969 6.445 10.105-31.242 1.07-2.279c5.073-10.32 7.579-33.77 8.755-38.672.785-3.268 1.327-7.026 1.626-11.273z"
                    transform="matrix(-1 0 0 1 171.217 0)"/>
              <g>
                <path fill="#FFF"
                      d="M15.178 38.425l23.854-20.228c2.096-1.778 3.355-4.356 3.474-7.11.116-2.757-.922-5.435-2.86-7.384l-.81-.818c-3.661-3.684-9.54-3.861-13.41-.409C18.264 8.86 7.134 18.783 0 25.143"
                      transform="matrix(-1 0 0 1 171.217 0) scale(-1 1) rotate(63 -99.016 -7.449)"/>
                <path stroke="#1B3380" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.483"
                      d="M14.62 38.898l24.412-20.701c2.096-1.778 3.355-4.356 3.474-7.11.116-2.757-.922-5.435-2.86-7.384l-.81-.818c-3.661-3.684-9.54-3.861-13.41-.409L1.346 23.943"
                      transform="matrix(-1 0 0 1 171.217 0) scale(-1 1) rotate(63 -99.016 -7.449)"/>
              </g>
              <g>
                <g>
                  <g>
                    <path fill="#1B87E6"
                          d="M74.326 14.957C74.326 6.701 67.664 0 59.46 0H14.865C6.66 0 0 6.7 0 14.957V59.83c0 8.256 6.66 14.957 14.865 14.957h44.596c8.203 0 14.865-6.7 14.865-14.957V14.957z"
                          transform="matrix(-1 0 0 1 171.217 0) rotate(-15 137.786 17.972) rotate(35 39.776 125.866)"/>
                    <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.45"
                          d="M43.186 20.045H30.444c-12.742 0-12.742 17.45 0 17.45h12.742v7.479"
                          transform="matrix(-1 0 0 1 171.217 0) rotate(-15 137.786 17.972) rotate(35 39.776 125.866)"/>
                    <path fill="#FFF"
                          d="M46.902 57.436c0 2.064-1.665 3.741-3.714 3.741-2.056 0-3.719-1.677-3.719-3.741 0-2.062 1.663-3.737 3.72-3.737 2.048 0 3.713 1.675 3.713 3.737"
                          transform="matrix(-1 0 0 1 171.217 0) rotate(-15 137.786 17.972) rotate(35 39.776 125.866) matrix(-1 0 0 1 86.371 0)"/>
                    <path stroke="#1B3380" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.483"
                          d="M74.326 14.957C74.326 6.701 67.664 0 59.46 0H14.865C6.66 0 0 6.7 0 14.957V59.83c0 8.256 6.66 14.957 14.865 14.957h44.596c8.203 0 14.865-6.7 14.865-14.957V14.957z"
                          transform="matrix(-1 0 0 1 171.217 0) rotate(-15 137.786 17.972) rotate(35 39.776 125.866)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgServerError;
