import React from 'react';

const SvgGridView = (props: any) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0 2H20V18H0V2ZM2 4H6V9H2V4ZM6 11H2V16H6V11ZM8 4H12V9H8V4ZM12 11H8V16H12V11ZM14 4H18V9H14V4ZM18 11H14V16H18V11Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>
  );
};

export default SvgGridView;
