import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import Layout from '../components/layout/layout';
import { IRoute } from './route-interface';
import Login from '../components/login/login';
import InternalError from '../components/internal-error/internal-error';
import Unauthorized from '../error-pages/unauthorized';
import { moduleSeperatedRoutes, nonModulesRoutes } from './routes';
import { NotFound } from '../components/not-found/not-found';
import PreviewStory from '../../pages/stories/preview-story/preview-story';
import SelectHub from "../components/select-hub/select-hub";
import LicenseError from "../error-pages/license-error/license-error";
import HomeInitialSync from "../../pages/home/pages/home-initial-sync/home-initial-sync";
import SyncWithSurveys from "../../pages/home/pages/home-initial-sync/pages/sync-with-surveys/sync-with-surveys";
import SyncWithExternalData
  from "../../pages/home/pages/home-initial-sync/pages/sync-with-external-data/sync-with-external-data";
import { IUserData } from "../model/user-data.interface";

interface Props {
  user: IUserData;
  showSelectHub: boolean;
}

const Routing = ({user, showSelectHub}: Props): JSX.Element => {
  function renderRoutes() {
    const routes: IRoute[] = [];
    user.license.modules.forEach((module) => {
      moduleSeperatedRoutes[module].forEach(route => routes.push(route))
    });

    nonModulesRoutes.forEach(route => routes.push(route));
    return routes.map((route, i) => <Route key={i} path={route.path}
                                           element={route.element}/>)
  }

  return (
    <Routes>
      <Route path="/unauthorized-401" element={<Unauthorized/>}/>
      <Route path="/oauth" element={<Login/>}/>
      <Route path="/internal-error" element={<InternalError/>}/>
      <Route path="/license-error" element={<LicenseError/>}/>
      <Route path="/not-found" element={<NotFound/>}/>
      <Route path="/" element={<Navigate to="/home"/>}/>
      <Route path="/stories/preview" element={<PreviewStory/>}/>

      {user ?
        <>
          <Route path="/initial-page" element={<HomeInitialSync/>}/>
          <Route path="/initial-page/import-surveys" element={<SyncWithSurveys/>}/>
          <Route path="/initial-page/import-external-data" element={<SyncWithExternalData/>}/>
          <Route element={<Layout/>}>
            {renderRoutes()}
          </Route>

          {showSelectHub && <Route element={<SelectHub/>} path={'/select-hub'}/>}
        </>
        :
        <Route path="*" element={<Navigate to="/oauth"/>}/>
      }

      <Route path="*" element={<Navigate to="/not-found"/>}/>
    </Routes>
  );
};

export default Routing;
