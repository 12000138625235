import React from 'react'

const SvgWorkflowIcon = (props: any) => {
  return (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9 10H3V12.2676C3.5978 12.6134 4 13.2597 4 14C4 15.1046 3.10457 16 2 16C0.89543 16 0 15.1046 0 14C0 13.2597 0.402199 12.6134 1 12.2676V3.73244C0.402199 3.38663 0 2.74028 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.74028 3.5978 3.38663 3 3.73244V8H7V5.73244C6.4022 5.38663 6 4.74028 6 4C6 2.89543 6.89543 2 8 2C9.10457 2 10 2.89543 10 4C10 4.74028 9.5978 5.38663 9 5.73244V10Z"
            fill="#545E6B"/>
    </svg>
  )
}

export default SvgWorkflowIcon
