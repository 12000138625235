import { INote } from "../model/note.interface";
import { getMediaTypeByExtension } from "../../../../../../_shared/helpers/get-media-type-by-extension";
import { convertFromRaw, EditorState } from "draft-js";
import DaysLeftDatePipe from "../../../../../../_shared/helpers/days-left-date-pipe";
import { Dropdown, MenuProps, Spin } from "antd";
import SvgEdit from "../../../../../../assets/icons/js/Edit";
import SvgTrash from "../../../../../../assets/icons/js/Trash";
import React, { useEffect, useState } from "react";
import notesApi from "../api/notes-api";
import Link_Thumbnail from '../../../../../../assets/images/Link_Thumbnail.png'

const NoteTile = (props: {
  note: INote,
  openCreateEditNoteDialog: (noteId?: number) => void,
  deleteNote: (noteId: number) => void
}) => {
  const {note, openCreateEditNoteDialog, deleteNote} = props;
  // let showTitle = !!note?.title?.length;
  // let showMedia = !!note?.fileUrl?.length;

  const [fileUrl, setFileUrl] = useState(note?.fileUrl);
  const [fileExtension, setFileExtension] = useState(note.fileExtension);
  const [showMedia, setShowMedia] = useState(!!note?.fileUrl?.length);
  const [showTitle, setShowTitle] = useState(!!note?.title?.length)
  const [title, setTitle] = useState(note?.title ? note.title : note.fileName ? note.fileName : '');
  const [contentState, setContentState] = useState(EditorState.createWithContent(convertFromRaw(JSON.parse(note.content))).getCurrentContent())

  const [loading, setLoading] = useState(false);

  const menuItems: MenuProps['items'] = [
    {
      key: "edit",
      label: (
        <div className="snapshot-icon-button d-flex align-items-center"
             onClick={() => openCreateEditNoteDialog(note.id)}>
          <SvgEdit/> Edit
        </div>
      ),
    },
    {
      key: "delete",
      label: (
        <div className="snapshot-icon-button d-flex align-items-center" onClick={() => deleteNote(note.id)}>
          <SvgTrash/> Delete
        </div>
      ),
    },
  ];


  useEffect(() => {
    if (note?.fileUrl) {
      setFileUrl(note.fileUrl);
      setFileExtension(note.fileExtension)
      return;
    }

    setLoading(true)
    notesApi.getExternalLinkInfo(note.externalUrl).then((response) => {
      if (response && !note?.fileUrl) {
        if (response?.ogImage?.url) {
          setShowMedia(true)
          setFileUrl(response?.ogImage?.url)
          setFileExtension('jpg');
        } else {
          setFileUrl(Link_Thumbnail);
          setShowMedia(true)
        }

        if (!note?.title && response?.ogSiteName && !contentState.hasText()) {
          setTitle(response?.ogSiteName);
          setShowTitle(true);
        }
      }
      setLoading(false)
    })

  }, [note?.fileUrl, note.externalUrl, note.title]);

  useEffect(() => {
    if (!note?.title && note.fileName && !contentState.hasText()) {
      setTitle(note.fileName);
      setShowTitle(true);
      return;
    }

    if (note?.title) {
      setShowTitle(true);
      setTitle(note.title);
      return;
    }
  }, [note?.fileUrl, note.title]);


  return <div className='note-tile pointer' onClick={(e) => {
    e.stopPropagation()
    openCreateEditNoteDialog(note.id)
  }}>
    {
      loading ? <Spin/> :
        <div className='note-tile-main'>
          {
            showMedia &&
              <div className='note-tile-media'>
                {
                  getMediaTypeByExtension(fileExtension) === 'video' ?
                    <video
                      data-testid="video"
                      controls>
                      <source
                        src={note.fileUrl}/>
                    </video> :
                    <img
                      src={fileUrl}
                      alt=''/>
                }
              </div>
          }

          <div className="note-tile-borders"
               style={{borderTopRightRadius: showMedia ? 0 : 8, borderTopLeftRadius: showMedia ? 0 : 8}}>
          <span>
            {
              showTitle &&
                <div className='note-tile-title'>
                  {title}
                </div>
            }
            {
              (!showMedia || !showTitle) &&
                <div className='note-tile-description'
                     style={{
                       WebkitLineClamp: !showTitle && !showMedia ? 11 : !showTitle ? 2 : 8,
                       //height: !showTitle && !showMedia ? 180 : !showTitle ? 40 : 130
                     }}>
                  {
                    note.content ?
                      contentState.getPlainText('\u0001') :
                      ""
                  }
                </div>
            }
          </span>


            <div className='note-tile-info'>
              <div className='note-tile-user'>
                <div className='note-tile-created-at'>
                  {DaysLeftDatePipe(note.lastUpdate)}
                </div>
                <div className='note-tile-user-name'>
                  {note.createdBy}
                </div>
              </div>
              <div className='note-tile-more' onClick={event => event.stopPropagation()}>
                <Dropdown trigger={["click"]} menu={{items: menuItems}}>
                  <div className="pointer" data-testid={"more-options"}>
                    <i className="bi bi-three-dots" style={{fontSize: 18}}/>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
    }

  </div>
}

export default NoteTile
