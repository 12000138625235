import React from 'react';
import './not-found.scss';
import Svg404 from '../../../assets/icons/js/404';

export function NotFound(): JSX.Element {
  const navigateToHome = () => {
    window.location.replace(`/home`);
  }

  return (
    <div className="error-page row align-items-center justify-space-center">
      <div className="error-message column">
        <h1 className="error-page-title">InsightsHub</h1>
        <h2 className="error-page-reason">Sorry, we couldn’t find the page you were looking for.</h2>

        <div className="column">
          <span className="error-code"><b>Error 404</b> - Page not found</span>
          <span>Maybe it was moved, deleted or doesn’t exist, just in case….please check that the URL you entered it’s spelled correctly</span>
        </div>

        <div className="row sign-in">
          <button className="sign-in-button" onClick={navigateToHome}>
            <span>Go to homepage</span>
          </button>
        </div>
      </div>
      <div className="column logo">
        <Svg404/>
      </div>
    </div>
  )
}
