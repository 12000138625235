export enum FileTypeEnum {
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  XLS = 'xls',
  XLSX = 'xlsx',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  JFIF = 'jfif',
  SVG = 'svg',
  PDF = 'pdf',
  AUDIO = 'mp3',
  VIDEO = 'mp4',
  CSV = 'csv',
}
