// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notepad-search-container .notepad-search-card-wrapper {
  width: 100%;
  height: 95px;
  border: 1px solid #e8e8e8;
  border-left: 5px solid #e8e8e8;
  padding: 14px 10px;
  overflow: hidden;
}
.notepad-search-container .notepad-search-card-wrapper .notepad-search-name {
  display: flex;
  align-items: center;
}
.notepad-search-container .notepad-search-card-wrapper .notepad-search-name .notepad-search-name-title {
  color: #545e6b;
  font-size: 16px;
  margin-left: 10px;
  display: inline;
}
.notepad-search-container .notepad-search-card-wrapper .notepad-search-description {
  color: #545e6b;
  font-size: 14px;
  margin-top: 10px;
  max-width: 100%;
  max-height: 20px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  display: inline;
}
.notepad-search-container .notepad-matches {
  color: #D4D4D4;
  font-weight: 600;
  margin-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/search-results/components/notepads/notepad-search-card/notepad-search-card.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,8BAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;AAAN;AAEM;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAAR;AAII;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,oBAAA;EACA,4BAAA;EACA,eAAA;AAFN;AAME;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AAJJ","sourcesContent":[".notepad-search-container {\n  .notepad-search-card-wrapper {\n    width: 100%;\n    height: 95px;\n    border: 1px solid #e8e8e8;\n    border-left: 5px solid #e8e8e8;\n    padding: 14px 10px;\n    overflow: hidden;\n\n    .notepad-search-name {\n      display: flex;\n      align-items: center;\n\n      .notepad-search-name-title {\n        color: #545e6b;\n        font-size: 16px;\n        margin-left: 10px;\n        display: inline;\n      }\n    }\n\n    .notepad-search-description {\n      color: #545e6b;\n      font-size: 14px;\n      margin-top: 10px;\n      max-width: 100%;\n      max-height: 20px;\n      -webkit-line-clamp: 2;\n      display: -webkit-box;\n      -webkit-box-orient: vertical;\n      display: inline;\n    }\n  }\n\n  .notepad-matches {\n    color: #D4D4D4;\n    font-weight: 600;\n    margin-bottom: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
