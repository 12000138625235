import * as React from "react";

const SvgBold = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-947.000000, -208.000000)">
          <g id="Bold" transform="translate(947.000000, 208.000000)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16"/>
            <path
              d="M9,2 C10.6568542,2 12,3.34314575 12,5 C12,6.524998 10.9232236,7 10.9232236,7.30253096 C10.9232236,7.60506192 13,7.973595 13,10.5 C13,12.4329966 11.4329966,14 9.5,14 L4,14 C3.44771525,14 3,13.5522847 3,13 L3,3 C3,2.44771525 3.44771525,2 4,2 L9,2 Z M8,8 L5.5,8 L5.5,13 L8,13 C9.38071187,13 10.5,11.8807119 10.5,10.5 C10.5,9.11928813 9.38071187,8 8,8 L8,8 Z M7.5,3 L5.5,3 L5.5,7 L7.5,7 C8.6045695,7 9.5,6.1045695 9.5,5 C9.5,3.8954305 8.6045695,3 7.5,3 L7.5,3 Z"
              id="Combined-Shape"
              fill="#545E6B"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgBold;
