import React, { createRef, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import "./snapshot-edit.scss"
import qs from "query-string"
import { ISnapshot, SnapshotTemplateEnum } from '../model/snapshot.interface'
import { Button, Tabs, Tooltip } from 'antd'
import Snapshot from './snapshot/snapshot'
import FirstTemplateSnapshot from './snapshot/first-template-snapshot/first-template-snapshot'
import SecondTemplateSnapshot from './snapshot/second-template-snapshot/second-template-snaphot'
import ThirdTemplateSnapshot from './snapshot/third-template-snapshot/third-template-snapshot'
import FourthTemplateSnapshot from './snapshot/fourth-template-snapshot/fourth-template-snapshot'
import snapshotsApi from '../api/snapshots-api'
import { usePrevious } from '../../../core/hooks/use-previous';
import SvgArrowBack from '../../../assets/icons/js/ArrowBack';
import SvgSaving from '../../../assets/icons/js/Saving';
import SvgSuccess from '../../../assets/icons/js/Success';
import { Loader } from '../../../_shared/components/loader/loader';
import MediaDialog from '../../../_shared/components/media-dialog/media-dialog';
import SvgClose from "../../../assets/icons/js/Close";
import SnapshotThemeForm from "../components/snapshot-theme-form/snapshot-theme-form";

const SnapshotEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam: any = qs.parse(location.search);
  let nuggetId = urlParam?.nuggetId;
  let id = urlParam?.id;
  const snapshotComp: any = createRef();

  const [snapshot, setSnapshot] = useState<ISnapshot>()
  const [image, setImage] = useState({url: "", name: ""})
  // const prevImage = usePrevious(image.url);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mediaDialog, setMediaDialog] = useState<any>({visible: false})
  const [canUpdate, setCanUpdate] = useState(false);
  const previousUpdate = usePrevious(canUpdate);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [snapshotTheme, setSnapshotTheme] = useState<any>();
  const [activeTab, setActiveTab] = useState('');
  const [theme, setTheme] = useState<any>({
    themeId: undefined,
    template: undefined
  });

  useEffect(() => {
    loadPage()
  }, [id]);

  useEffect(() => {
    if (!previousUpdate) {
      return;
    }

    if (loading) {
      return
    }

    setCanUpdate(true);

    const payload = {
      imageUrl: image.url,
      imageText: image.name,
      template: theme.template,
      themeId: theme.themeId,
    }

    setSaving(true)
    snapshotsApi.updateSnapshot(id, payload).then((response: any) => {
      setSaving(false);
    })
  }, [image, theme])

  useEffect(() => {
    if (activeTab === '1') {
      setSnapshotTheme(null);
      setTheme({
        template: SnapshotTemplateEnum.FIRST,
        themeId: null
      })
    }
  }, [activeTab]);


  const updateDownloadLoader = (value: boolean) => {
    setDownloadLoader(value)
  }

  const navigateBack = () => {
    navigate(-1)
  }

  const closeMediaDialog = () => {
    setMediaDialog({visible: false})
  }

  const openMediaDialog = () => {
    setMediaDialog({visible: true})
  }

  const changeBackground = (data: { url: string, imageName: string }) => {
    setImage({
      url: data.url,
      name: data.imageName
    })
  }

  const removeBackground = () => {
    setImage({
      url: "",
      name: ""
    })
  }

  const onTemplateClick = (value: SnapshotTemplateEnum) => {
    setTheme({
      themeId: null,
      template: value
    });
  }

  const downloadSnapshot = () => {
    snapshotComp.current.download();
  }

  const loadPage = () => {
    if (nuggetId) {
      snapshotsApi.createSnapshot(parseInt(nuggetId)).then((response: ISnapshot) => {
        navigate({
          pathname: '',
          search: qs.stringify({id: response.id})
        }, {replace: true});
      })
    }

    if (id) {
      setLoading(true)
      snapshotsApi.getSnapshot(id).then((response: ISnapshot) => {
        setImage({url: response.imageUrl, name: response.imageText})
        setTheme({
          themeId: response?.theme?.id,
          template: response?.template
        })
        setSnapshot(response)
        setSnapshotTheme(response?.theme)
        setLoading(false);
        setCanUpdate(true)
      })
    }
  }

  const navigateToProject = (projectId: number | undefined, projectArchived?: boolean) => {
    if (projectArchived) {
      return;
    }
    if (projectId) {
      navigate({pathname: '/projects/edit', search: qs.stringify({id: projectId})}, {
        state: {
          fromSnapshots: true,
          id: snapshot?.id
        }
      })
    }
  }

  const onThemeFormChange = (data: any) => {
    setSnapshotTheme(data)
  }

  const updateThemeId = (id: number) => {
    setTheme({
      setTemplate: null,
      themeId: id
    })
  }

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
  }


  return (
    <div className='snapshot-edit-wrapper column'>
      <div className="story-edit column">
        <div className="intake-form-header justify-space-between">
          <div className="row align-items-center">
            <button style={saving ? {opacity: 0.5} : {opacity: 1}}
                    className="icon-button back"
                    onClick={navigateBack}>
              <SvgArrowBack/>
            </button>
            <span className="font-weight-500">{snapshot?.project}</span>
          </div>
          <div className="row align-items-center">
            {
              saving ?
                <>
                  <SvgSaving/>
                  <span className="font-weight-300" style={{fontSize: '12px', marginLeft: 0}}>Saving ...</span>
                </> :
                <>
                  <SvgSuccess/>
                  <span className="font-weight-300"
                        style={{fontSize: '12px', marginLeft: 5}}>All changes saved</span>
                </>
            }
          </div>
          <div className="row align-items-center">
            <Button
              loading={downloadLoader}
              onClick={downloadSnapshot}
              type='primary'>
              <i className="bi bi-download"/>
              <span style={{marginLeft: 8}}>
                Download
              </span>
            </Button>
          </div>
        </div>
        {
          loading ? <Loader/> :
            <div className='snapshot-content row'>
              <div className="snapshot-details">
                <div className="snapshot-details-info">
                  {/*<div className='details-info'>*/}
                  {/*  <div className='details-info-name'><SvgNugget width={16} height={15}/> <span>Key Finding</span>*/}
                  {/*  </div>*/}
                  {/*  <div className='details-info-value'>{snapshot?.nugget}</div>*/}
                  {/*</div>*/}
                  <div className='details-info'>
                    <div className='details-info-name'>Project</div>
                    <div className='details-info-value'>
                      <Tooltip title={`${snapshot?.projectArchived ? "Project is archived" : ""}`}>
                        <div onClick={() => navigateToProject(snapshot?.projectId, snapshot?.projectArchived)}
                             className='details-project-name pointer'>
                          {snapshot?.project}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className='details-info'>
                    <div className='details-info-name'>Created By</div>
                    <div className='details-info-value'>{snapshot?.createdBy?.displayName}</div>
                  </div>
                  <Tabs destroyInactiveTabPane={true}
                        onChange={onTabChange}
                        defaultActiveKey={snapshot?.theme ? '2' : '1'}>
                    <Tabs.TabPane tab="Templates" key="1">
                      <div>
                        <div className="details-info">
                          <div className="details-info-name">
                            Select a template
                          </div>
                          <div className='details-info-value'>
                            <div className='snapshot-templates'>
                              <div
                                className={theme.template === SnapshotTemplateEnum.FIRST ? 'snapshot-template-select-active' : 'snapshot-template-select'}>
                                <FirstTemplateSnapshot
                                  forSelectTemplate
                                  selectedImage={{url: snapshot?.imageUrl}}
                                  onTemplateClick={onTemplateClick}
                                  {...snapshot}/>
                              </div>
                              <div
                                className={theme.template === SnapshotTemplateEnum.SECOND ? 'snapshot-template-select-active' : 'snapshot-template-select'}>
                                <SecondTemplateSnapshot
                                  forSelectTemplate
                                  selectedImage={{url: snapshot?.imageUrl}}
                                  onTemplateClick={onTemplateClick}
                                  {...snapshot}/>
                              </div>
                              <div
                                className={theme.template === SnapshotTemplateEnum.THIRD ? 'snapshot-template-select-active' : 'snapshot-template-select'}>
                                <ThirdTemplateSnapshot
                                  forSelectTemplate
                                  selectedImage={{url: snapshot?.imageUrl}}
                                  onTemplateClick={onTemplateClick}
                                  {...snapshot}/>
                              </div>
                              <div
                                className={theme.template === SnapshotTemplateEnum.FOURTH ? 'snapshot-template-select-active' : 'snapshot-template-select'}>
                                <FourthTemplateSnapshot
                                  forSelectTemplate
                                  selectedImage={{url: snapshot?.imageUrl}}
                                  onTemplateClick={onTemplateClick}
                                  {...snapshot}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='details-info ' style={{marginTop: 30}}>
                          <div className='details-info-name'>Image</div>
                          <div className='details-info-value'>
                            <div className='selected-image-wrapper'>
                              <div className='selected-image'>
                                {
                                  image.url &&
                                    <img src={image.url} alt=""/>
                                }
                              </div>
                              {
                                image.url ?
                                  <div>
                              <span className='selected-image-label'>
                                {image.name ? image.name : "Untitled"}
                              </span>
                                    <Tooltip placement='bottom' title="Remove Image">
                                      <button className="icon-button" onClick={removeBackground}>
                                        <SvgClose/>
                                      </button>
                                    </Tooltip>
                                  </div> :
                                  <Button type='link' onClick={openMediaDialog}>Add image</Button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Custom theme" key="2">
                      <SnapshotThemeForm updateThemeId={updateThemeId}
                                         onThemeFormChange={onThemeFormChange}
                                         theme={snapshotTheme}/>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </div>
              {
                <Snapshot
                  updateDownloadLoader={updateDownloadLoader}
                  noImageLabel
                  ref={snapshotComp}
                  html={snapshot?.htmlContent}
                  project={snapshot?.project}
                  nugget={snapshot?.nugget}
                  template={theme.template}
                  selectedImage={image}
                  snapshotTheme={snapshotTheme}
                  createdBy={snapshot?.createdBy}
                  createdAt={snapshot?.createdAt}
                />
              }
            </div>
        }
      </div>

      {
        mediaDialog.visible &&
          <MediaDialog
              onConfirm={changeBackground}
              onCancel={closeMediaDialog}
              visible={mediaDialog.visible}/>
      }
    </div>
  )
}

export default SnapshotEdit
