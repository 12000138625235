import React, { useContext } from 'react'
import SvgNugget from '../../../../../assets/icons/js/nugget'
import SvgQuestionProLogo from '../../../../../assets/icons/js/questionProLogo'
import GlobalContext from '../../../../../store/global-context'
import { SnapshotTemplateEnum } from '../../../model/snapshot.interface'
import "./first-template-snapshot.scss"
import firstVector from "./images/firstVector.png"
import secondVector from "./images/secondVector.png"
import TextFit from "../../../../../_shared/components/text-fit/text-fit";

const FirstTemplateSnapshot = (props: any) => {
  const globalContext = useContext(GlobalContext);

  const onClick = () => {
    if (props?.forSelectTemplate) {
      props.onTemplateClick(SnapshotTemplateEnum.FIRST)
    }
  }

  let scale = 1;
  let transformOrigin = '';

  if (props?.mdSize) {
    scale = 0.36;
    transformOrigin = 'top left';
  }

  if (props?.smSize) {
    scale = 0.23;
    transformOrigin = 'top left';
  }

  if (props?.forSelectTemplate) {
    scale = 0.173;
    transformOrigin = 'top left';
  }

  return (
    <div className='first-template-snapshot-wrapper' data-testid={'first-template-snapshot'}
         onClick={onClick}
         style={{scale: `${scale}`, transformOrigin}}
    >
      <div className='first-template-selected-image'>
        {
          props?.selectedImage?.url?.length > 0 ?
            <img src={props?.selectedImage?.url} alt="selected-image"/> :
            <div className='first-template-background-color'/>
        }
      </div>

      <div className='first-vector'>
        <img src={firstVector} alt=""/>
      </div>
      <div className='second-vector'>
        <img src={secondVector} alt=""/>
      </div>

      <div className="first-template-main-content">
        <SvgNugget height={48} width={48} color="#E3D058"/>
        <div className='first-template-nugget-description'>
          <TextFit mode="multi" min={12} max={56} style={{height: 200, lineHeight: '150%', padding: '10px 0'}}>
            {props?.nugget}
          </TextFit>
        </div>
        <div className="first-template-project">
          {props?.project}
          <div style={{marginTop: 2, fontWeight: 600}}>{globalContext.user?.organization.name}</div>

        </div>
      </div>
      {
        (props?.selectedImage?.url?.length === 0) &&
          <>
            {props?.noImageLabel &&
                <div className='first-template-no-image-selected' id="no-image-label-template">
                    <i className="bi bi-card-image" style={{fontSize: "4em"}}/>
                    <div>
                        No Image Selected
                    </div>
                </div>
            }
          </>
      }

      <div className='first-template-question-pro-logo'>
        <SvgQuestionProLogo color="#fff" width={126} height={24}/>
      </div>
    </div>
  )
}

export default FirstTemplateSnapshot
