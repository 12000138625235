import { ProjectSearchCard } from './project-search-card/project-search-card';
import { Empty } from 'antd';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import React from 'react';
import './projects.scss'
import { UseQueryResult } from "react-query";
import { ISearchProjectData, ITagLinkedWith } from "../../models/search-project";

export function Projects(data: {
  searchProjects: UseQueryResult<ISearchProjectData, unknown>,
  isModalSearch?: boolean
}) {
  const searchProjects: UseQueryResult<ISearchProjectData, unknown> = data.searchProjects;

  return <>
    {!searchProjects.isLoading ?
      <div className='search-projects-wrapper'>
        <div className='search-projects'>
          {searchProjects?.data?.projects?.length ? searchProjects?.data.projects.map((project: any, i: number) => {
              return (
                <ProjectSearchCard key={i}
                                   project={project}/>
              )
            })
            :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
          }
        </div>
        {
          !data.isModalSearch &&
            <div className="search-projects-commonly-tagged">
                <div className='commonly-tagged-with-title'>
                    <i className="bi bi-tag-fill" style={{fontSize: 20}}/>
                    Commonly tagged with
                </div>
              {
                searchProjects?.data?.tags?.length !== 0 ?
                  <div className='commonly-tagged-wrapper'>
                    {
                      searchProjects.data?.tags?.map((tag: ITagLinkedWith, i: number) => {
                        return <div className="commonly-tagged" key={i}>
                          <span className='commonly-tagged-name'>{tag.name}</span>
                          <span className='commonly-tagged-number'>{tag.count}</span>
                        </div>
                      })
                    }
                  </div>
                  : <div className="column justify-space-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  </div>
              }
            </div>
        }
      </div>
      :
      <>
        <Loader/>
      </>
    }
  </>
}
