import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import './team.scss'
import { ColumnsType } from 'antd/lib/table'
import { ITeamMember } from './model/team-member.interface';
import DeskSettingsApi from '../../api/settings-api';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import SvgSearch from '../../../../assets/icons/js/Search';
import { DebounceInput } from 'react-debounce-input';
import FormattedDate from '../../../../_shared/helpers/formatted-date';
import UserActivityDialog
  from "../../../home/components/usage-dashboard/homepage-activity-feed/user-activity-dialog/user-activity-dialog";
import Pagination from "../../../../_shared/components/pagination/pagination";


export function Team(): JSX.Element {
  const [sortInfo, setSortInfo] = useState<any>({});
  const insightsAdminColumns: ColumnsType<ITeamMember> = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (value: string, record) => <span onClick={() => openUserActivityDialog({userId: record.id})}
                                               className="link pointer">{value}</span>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortInfo?.columnKey === "email" && sortInfo?.order,
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      sorter: true,
      sortOrder: sortInfo?.columnKey === "userId" && sortInfo?.order,
    },
    {
      title: 'Created on',
      dataIndex: 'createdOn',
      key: 'createdAt',
      sorter: true,
      sortOrder: sortInfo?.columnKey === "createdAt" && sortInfo?.order,
      render: (value: string) => <FormattedDate date={value} formatString="MMM d, yyyy"/>
    },
    {
      title: 'Last login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      sorter: true,
      sortOrder: sortInfo?.columnKey === "lastLogin" && sortInfo?.order,
      render: (value: string) => <FormattedDate date={value} formatString="MMM d, yyyy"/>
    },
  ];
  const portalUsersColumns: ColumnsType<ITeamMember> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value: string) => <span className="email">{value}</span>
    },
    {
      title: 'Last login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: (value: string) => <FormattedDate date={value} formatString="MMM d, yyyy HH:mm"/>
    },
  ];

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [sortParam, setSortParam] = useState<string>("")
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [currentPagePortalUsers, setCurrentPagePortalUsers] = useState(1);
  const [pageSizePortalUsers, setPageSizePortaUsers] = useState(10);

  const [userActivityDialog, setUserActivityDialog] = useState<any>({visible: false, userId: 11});

  let teamMembersParams = {
    search,
    sort: sortParam,
    pageSize,
    page: currentPage
  }

  let portalUsersParams = {
    pageSize: pageSizePortalUsers,
    page: currentPagePortalUsers
  }

  const insightsAdminData = useQuery(['teammembers', teamMembersParams], () => DeskSettingsApi.getTeamMembers(teamMembersParams));
  const portalUsersData = useQuery(['portalUsersData', portalUsersParams], () => DeskSettingsApi.getPortalUsers(portalUsersParams));


  function openUserActivityDialog(user: { userId: string | undefined }) {
    if (user.userId)
      setUserActivityDialog({visible: true, ...user})
  }

  function closeUserActivityDialog() {
    setUserActivityDialog({visible: false, userId: 0})
  }

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  }

  const handleTableChange = (pagination: any, filter: any, sort: any) => {
    setSortInfo(sort);
    if (sort.column) {
      setSortParam(`${sort?.columnKey}:${sort?.order?.slice(0, -3)}`)
    } else {
      setSortParam("");
    }
    
  }

  const openAddTeamMember = () => {
    navigate('/settings/manage-insights-admins');
  }

  return (
    <div className="team">
      <div className="header">
        <Button type="primary" onClick={openAddTeamMember}>Manage insights admin</Button>
      </div>
      <div className='row justify-space-between' style={{marginBottom: 10}}>
        <div className="relative">
          <DebounceInput
            debounceTimeout={300}
            style={{width: 362}}
            value={search}
            placeholder="Search users"
            className='outline-input'
            onChange={handleSearchChange}
          />
          <span className='absolute' style={{top: 8, left: 340}}><SvgSearch/></span>
        </div>
        {
          insightsAdminData.data && insightsAdminData?.data?.pagination?.totalElements > 0 &&
          <Pagination totalItems={insightsAdminData?.data?.pagination?.totalElements}
                      pageSizeOptions={[10, 20, 30]}
                      showSizeChanger
                      currentPage={currentPage}
                      pageSize={pageSize}
                      onChange={(page, pageSize) => {
                        setCurrentPage(page)
                        setPageSize(pageSize)
                      }
                      }/>
        }
      </div>
      <div className="body">
        <Table<ITeamMember> columns={insightsAdminColumns}
                            loading={insightsAdminData.isLoading}
                            pagination={false}
                            dataSource={insightsAdminData?.data?.content}
                            rowKey="id"
                            onChange={handleTableChange}
        />
      </div>
      <div className="header">
        <h3 style={{fontSize: 18}}>Portal history</h3>
      </div>
      <div className="body">
        <div className="row justify-space-end" style={{marginBottom: 10}}>
          {
            portalUsersData.data && portalUsersData?.data?.pagination?.totalElements > 0 &&
            <Pagination totalItems={portalUsersData?.data?.pagination?.totalElements}
                        pageSizeOptions={[10, 20, 30]}
                        showSizeChanger
                        currentPage={currentPagePortalUsers}
                        pageSize={pageSizePortalUsers}
                        onChange={(page, pageSize) => {
                          setCurrentPagePortalUsers(page)
                          setPageSizePortaUsers(pageSize)
                        }
                        }/>
          }
        </div>
        <Table<ITeamMember> columns={portalUsersColumns}
                            loading={portalUsersData.isLoading}
                            pagination={false}
                            dataSource={portalUsersData?.data?.content}
                            rowKey="email"
                            onChange={(pagination: any) => setCurrentPagePortalUsers(pagination.current)}
        />
      </div>
      {
        userActivityDialog.visible &&
          <UserActivityDialog data={userActivityDialog} onCancel={closeUserActivityDialog}/>
      }
    </div>
  )
}
