import React from 'react';
import './integrations.scss';
import DeskSettingsApi from '../../../api/settings-api';
import { Loader } from '../../../../../_shared/components/loader/loader';
import { Link } from 'react-router-dom';
import SvgSlackLogo from '../../../../../assets/icons/js/slack-logo';
import SvgGoogleDrive from '../../../../../assets/icons/js/googleDrive';
import SvgZip from '../../../../../assets/icons/js/zip';
import { useQuery } from 'react-query';
import SvgFiletypeXls from "../../../../../assets/icons/js/FiletypeXls";
import SvgOneDrive from "../../../../../assets/icons/js/OneDrive";
import SharepointLogo from "../../../../../assets/icons/js/SharepointLogo";

export default function Integrations() {
  const integrations = useQuery('integrationsInstalled', () => DeskSettingsApi.integrationsInstalled());

  return (
    <>
      {integrations.isLoading ? <Loader/>
        :
        <div className="integrations">
          <div className="body">
            <Link to={'/settings/zip-file-import'}
                  className="integration-card row"
                  style={{borderLeft: '4px solid #1b87e6'}}>
              <div className="row logo">
                <SvgZip/>
              </div>
              <div className="column content">
                <span className="title">ZIP file Import</span>
                <span>Bring your projects straight from a ZIP file</span>
              </div>
            </Link>
            <Link to={'/settings/slack-integration'}
                  className="integration-card row"
                  style={{borderLeft: integrations.data?.slackIntegration ? '4px solid #1b87e6' : ''}}>
              <div className="row logo">
                <SvgSlackLogo/>
              </div>
              <div className="column content">
                <span className="title">Slack</span>
                <span>Start projects, enhance collaboration and get live updates.</span>
              </div>
            </Link>
            <Link to={'/settings/google-drive-integration'}
                  className="integration-card row"
                  style={{borderLeft: integrations.data?.googleDriveIntegration ? '4px solid #1b87e6' : ''}}>
              <div className="row logo">
                <SvgGoogleDrive/>
              </div>
              <div className="column content">
                <span className="title">Google Drive</span>
                <span>Easily onboard your Google Drive files into InsightsHub projects</span>
              </div>
            </Link>
            <Link to={'/settings/excel-file-import'}
                  className="integration-card row"
                  style={{borderLeft: '4px solid #1b87e6'}}>
              <div className="row logo">
                <SvgFiletypeXls width={32} height={32}/>
              </div>
              <div className="column content">
                <span className="title">Excel File Import</span>
                <span>Bring your projects straight from an Excel file</span>
              </div>
            </Link>
            <Link to={'/settings/one-drive-integration'}
                  className="integration-card row"
                  style={{borderLeft: '4px solid #1b87e6'}}>
              <div className="row logo">
                <SvgOneDrive width={32} height={32}/>
              </div>
              <div className="column content">
                <span className="title">One Drive</span>
                <span>Easily onboard your One Drive files into InsightsHub projects</span>
              </div>
            </Link>

            <Link to={'/settings/sharepoint-integration'}
                  className="integration-card row"
                  style={{borderLeft: '4px solid #1b87e6'}}>
              <div className="row logo">
                <SharepointLogo width={32} height={32}/>
              </div>
              <div className="column content">
                <span className="title">SharePoint</span>
                <span>Easily onboard your SharePoint files into InsightsHub projects</span>
              </div>
            </Link>

          </div>
        </div>
      }
    </>
  );
}
