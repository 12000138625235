import * as React from "react";

const SvgProduct = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 0a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V2a2 2 0 012-2h6zM9 12H7a1 1 0 000 2h2a1 1 0 000-2zm2-10H5v8h6V2z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgProduct;
