import React from 'react';
import '../login/login.scss'
import SvgServerError from '../../../assets/icons/js/server-error';
import qs from "query-string";
import { useLocation } from "react-router-dom";

const InternalError = () => {
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);

  const navigateToHome = () => {
    window.location.replace(`/home`);
  }

  return (
    <div className="error-page row align-items-center justify-space-center">
      <div className="error-message column">
        <h1 className="error-page-title">InsightsHub</h1>
        <h2 className="error-page-reason">500 - Internal server error</h2>

        <div className="column">
          <span className="error-code"><b>{urlParam?.id}</b> - Server Error</span>
          <span>Please try again later or feel free to contact us if the problem persists.</span>
        </div>

        <div className="row sign-in">
          <button className="sign-in-button" onClick={navigateToHome}>
            <span>Go to homepage</span>
          </button>
        </div>
      </div>
      <div className="column logo">
        <SvgServerError/>
      </div>
    </div>
  )
}

export default InternalError;
