import { IKnowledgeNode } from "../model/knowledge-node.interface";
import { AxiosResponse } from "axios";
import { environment } from "../../../environment";
import { axiosClient } from "../../../axios-client";

class KnowledgeApi {
  private baseUrl: string = `${environment.backendUrl}/graph`;

  async getAll(): Promise<IKnowledgeNode[]> {
    try {
      const response: AxiosResponse<IKnowledgeNode[]> = await axiosClient.get<IKnowledgeNode[]>(`${this.baseUrl}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createGraph(graph: IKnowledgeNode): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.post<IKnowledgeNode>(`${this.baseUrl}`, graph);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async editGraph(
    graphId: number,
    graph: { name: string }
  ): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.put<IKnowledgeNode>(
          `${this.baseUrl}/${graphId}`,
          graph
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteGraph(graphId: number): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.delete<IKnowledgeNode>(`${this.baseUrl}/${graphId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createGraphNode(graphId: number, node: any): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.post<IKnowledgeNode>(
          `${this.baseUrl}/${graphId}/node`,
          node
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getNodeChildren(nodeId: number): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.get<IKnowledgeNode>(`${this.baseUrl}/node/${nodeId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createNode(
    nodeId: number,
    node: IKnowledgeNode
  ): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.post<IKnowledgeNode>(
          `${this.baseUrl}/${nodeId}/node`,
          node
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async editNode(
    nodeId: number,
    node: { name: string }
  ): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.put<IKnowledgeNode>(
          `${this.baseUrl}/node/${nodeId}`,
          node
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteNode(nodeId: number): Promise<IKnowledgeNode> {
    try {
      const response: AxiosResponse<IKnowledgeNode> =
        await axiosClient.delete<IKnowledgeNode>(
          `${this.baseUrl}/node/${nodeId}`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new KnowledgeApi();
