import * as React from "react";

const SvgUploadFile = (props: any) => {
  return (
    <svg width={33} height={33} viewBox="0 0 33 33" {...props}>
      <path fillRule="evenodd"
            fill={props.color ? props.color : '#545E6B'}
            d="M30.8,16.8c0-2.9-2.4-5.3-5.4-5.3c0-3.8-2.8-7.2-6.6-7.7c-1.2-0.2-2.3-0.3-2.7-0.3c-0.5,0-1.5,0.1-2.7,0.3
	c-3.8,0.6-6.6,3.9-6.5,7.7c-2.9,0-5.3,2.4-5.3,5.4l0,1.2c0,3.5,3.3,6.1,6.7,5.4l0-2.7l-0.7,0c-1.8,0-3.3-1.5-3.4-3.3
	c0-1.8,1.5-3.3,3.3-3.4l2,0l0-1.3c0-3.7,2.9-6.7,6.6-6.7c3.7,0,6.7,2.9,6.7,6.6l0,1.3l2,0c1.8,0,3.3,1.5,3.4,3.3
	c0,1.8-1.5,3.3-3.3,3.4l-0.7,0l0,2.7c3.5,0.7,6.7-2,6.6-5.5L30.8,16.8z M10.5,20.4l5.6-5.6l5.6,5.6l-1.9,1.9L17.5,20l0.1,10.5
	l-2.7,0L14.7,20l-2.3,2.3L10.5,20.4z"/>
    </svg>
  );
}

export default SvgUploadFile;
