import * as React from "react";

const SvgAlert = (props: any) => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" {...props}>
      <g
        transform="translate(-463.000000, -390.000000) translate(211.000000, 256.000000) translate(240.000000, 8.000000) translate(12.000000, 116.000000)">
        <path
          fill={props.color ? props.color : '#F8004B'}
          d="M6 12c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6zm0-2.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75-.75.336-.75.75.336.75.75.75zm-.732-7.5v4.5h1.5v-4.5h-1.5z"
          transform="translate(0.000000, 10.000000)"/>
      </g>
    </svg>
  );
}

export default SvgAlert;
