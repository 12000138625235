// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metrics {
  display: flex;
  flex-direction: row;
}
.metrics .title-metrics {
  padding: 8px 0px 8px 16px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #EEEEEE;
  font-size: 14px;
  font-weight: 500;
  color: #545E6B;
  margin-bottom: 30px;
  width: 480px;
}
.metrics .pricing-model {
  padding: 0;
}
.metrics .pricing-model .options {
  margin-bottom: 32px;
}
.metrics .pricing-model .outline-input-wrapper {
  width: 320px;
}
.metrics .pricing-model .input-options {
  width: 200px;
  height: 32px;
  display: flex;
  align-self: flex-end;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
}
.metrics .pricing-model .input-options .option {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.metrics .pricing-model .input-options .option:hover > svg > g > g > rect {
  fill: #1b87e6 !important;
}
.metrics .pricing-model .input-options .option:hover > svg > g > g > path {
  fill: #ffffff !important;
}
.metrics .pricing-model .input-options .option:hover > svg > g > g > rect:last-child {
  fill: #ffffff !important;
}
.metrics .pricing-model .input-options .option:first-child {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/project-management/key-metrics/metrics.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAGE;EACE,UAAA;AADJ;AAGI;EACE,mBAAA;AADN;AAII;EACE,YAAA;AAFN;AAKI;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AAHN;AAKM;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAHR;AAKQ;EACE,wBAAA;AAHV;AAMQ;EACE,wBAAA;AAJV;AAOQ;EACE,wBAAA;AALV;AAQQ;EACE,kBAAA;AANV","sourcesContent":[".metrics {\n  display: flex;\n  flex-direction: row;\n\n  .title-metrics {\n    padding: 8px 0px 8px 16px;\n    height: 32px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    background-color: #EEEEEE;\n    font-size: 14px;\n    font-weight: 500;\n    color: #545E6B;\n    margin-bottom: 30px;\n    width: 480px;\n\n  }\n\n  .pricing-model {\n    padding: 0;\n\n    .options {\n      margin-bottom: 32px;\n    }\n\n    .outline-input-wrapper {\n      width: 320px;\n    }\n\n    .input-options {\n      width: 200px;\n      height: 32px;\n      display: flex;\n      align-self: flex-end;\n      justify-content: flex-start;\n      align-items: center;\n      padding: 0 16px;\n\n      .option {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        cursor: pointer;\n\n        &:hover > svg > g > g > rect {\n          fill: #1b87e6 !important;\n        }\n\n        &:hover > svg > g > g > path {\n          fill: #ffffff !important;\n        }\n\n        &:hover > svg > g > g > rect:last-child {\n          fill: #ffffff !important;\n        }\n\n        &:first-child {\n          margin-right: 15px;\n        }\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
