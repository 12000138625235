import React, { useState } from 'react';
import SvgSave from '../../../../../../assets/icons/js/save';
import SvgCancel from '../../../../../../assets/icons/js/Cancel';
import SvgEdit from '../../../../../../assets/icons/js/Edit';
import { DebounceInput } from 'react-debounce-input';
import MyProjectApi from '../../../../api/project-api';
import { AxiosResponse } from 'axios';
import { message, notification, Spin } from 'antd';
import { queryClient } from '../../../../../../index';
import { IProject } from '../../../../models/project.interface';
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SvgTick from "../../../../../../assets/icons/js/Tick";

export function DetailsKeyMetrics(props: { project: any, viewOnly?: boolean }): JSX.Element {
  const {handleSubmit, control, getValues} = useForm({
    defaultValues: {
      keyMetricsValues: props?.project?.keyMetrics ?? []
    }
  })

  const {fields} = useFieldArray({
    control, name: 'keyMetricsValues'
  })

  const project = props?.project;
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);

  const submitKeyMetrics = (data: { keyMetricsValues: any[] }): void => {
    if (data.keyMetricsValues?.length) {
      setApiLoading(true);

      MyProjectApi.updateKeyMetrics(project.id, data.keyMetricsValues)
        .then((response: AxiosResponse<IProject>) => {
          message.success({
            content: "Key metrics has been updated",
            className: "custom-success-message",
            icon: <SvgTick color={"#227700"}/>
          })

          setEdit(false);
          // @ts-ignore
          queryClient.setQueryData<IProject>(['project', JSON.stringify(project.id)], oldData => ({
            ...oldData,
            ...response
          }));
          queryClient.invalidateQueries(['project', JSON.stringify(project.id)]);
          setApiLoading(false);
        });
    } else {
      setEdit(false);
    }

  }

  return (
    <div className="project-edit-card">
      <div className="project-edit-header">
        <div className="left">
          <h3>Key metrics</h3>
        </div>
        <div className="right">
          {
            apiLoading ? <>
                <div/>
                <Spin/>
              </>
              :
              <>
                {edit ?
                  <>
                    <button className="icon-button" onClick={handleSubmit(submitKeyMetrics)}>
                      <SvgSave width={20}/>
                    </button>
                    <button className="icon-button cancel-icon-hover" onClick={() => setEdit(false)}>
                      <SvgCancel width={16} height={16} color="red"/>
                    </button>
                  </>
                  :
                  <>
                    <span/>
                    {!props.viewOnly
                      &&
                        <button className="icon-button" onClick={() => setEdit(true)}>
                            <SvgEdit/>
                        </button>
                    }
                  </>
                }
              </>
          }
        </div>
      </div>
      <div className="project-edit-body-program column" style={{gap: 10}}>
        <table>
          <tbody>
          {fields.map((keyMetric, index: number) => {
            return (
              <tr key={index}>
                <td style={{
                  color: "#bbbbbb",
                  width: edit ? "75%" : "80%"
                }}>{getValues(`keyMetricsValues.${index}.name`)}</td>
                <td style={{padding: edit ? 2 : 8}}>{edit ?
                  <div className="description pricing-model-input">
                    <div className="outline-input-wrapper">
                      <Controller control={control} render={({field}) => <DebounceInput
                        className="outline-input"
                        debounceTimeout={600}
                        type="number"
                        {...field}
                      />} name={`keyMetricsValues.${index}.value`}/>

                    </div>
                  </div>
                  :
                  <span className="description">{getValues(`keyMetricsValues.${index}.value`) ?? 0}</span>
                }
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
