import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider, Form, FormInstance, Input, InputRef, Modal, Table, Tooltip } from "antd";
import { useQuery } from "react-query";
import SnapshotsApi from "../../api/snapshots-api";
import Pagination from "../../../../_shared/components/pagination/pagination";
import SvgTrash from "../../../../assets/icons/js/Trash";
import { ISnapshotTheme } from "../../model/snapshot.interface";
import { queryClient } from "../../../../index";
import snapshotsApi from "../../api/snapshots-api";

type SnapshotThemesModalProps = {
  open: boolean;
  onCancel: () => void;
}
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const SnapshotThemesModal = (props: SnapshotThemesModalProps) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: selectedRows.length ? <div className='selected-rows-title-wrapper'>
        <span>{selectedRows.length} selected</span>
        <div className='selected-rows-btn'
             data-testid="bulk-archive-btn" onClick={() => bulkDeleteThemes(selectedRows.map((row) => row.id))}>
          <SvgTrash color={'#1b87e6'}/>
          &nbsp;Delete
        </div>
      </div> : "Theme",
      dataIndex: 'themeName',
      editable: true,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "right",
      render: (id: number, record: any) => (
        <div className="action-hover-column row">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Archive repository">
              <button className="icon-button project-icon-button" onClick={() => bulkDeleteThemes([id])}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ]

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);


  const snapshotThemes = useQuery(["snapshotThemes", page, pageSize], () => SnapshotsApi.getSnapshotThemes({
    page,
    pageSize
  }))

  function bulkDeleteThemes(ids: number[]) {
    snapshotsApi.bulkDeleteThemes(ids).then(() => {
      queryClient.invalidateQueries(["snapshotThemes", page, pageSize])
    })
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ISnapshotTheme) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  function handleSave(record: ISnapshotTheme) {
    queryClient.setQueryData(["snapshotThemes", page, pageSize], (oldData: any) => {
      if (!oldData) return
      return {
        ...oldData,
        content: oldData.content.map((theme: ISnapshotTheme) => {
          if (theme.id === record.id) {
            return {...theme, ...record}
          }
          return theme
        })
      }
    })

    snapshotsApi.updateThemeSnapshot(record.id, {themeName: record.themeName}).then(() => {
      queryClient.invalidateQueries(["snapshotThemes", page, pageSize])
    })
  }


  return (
    <Modal open={props.open}
           onCancel={props.onCancel}
           okButtonProps={{style: {display: 'none'}}}
           cancelText={"Close"}
           width={600}
           cancelButtonProps={{type: 'link'}}
           title="Snapshot themes"
    >
      <div>
        <div className="row justify-space-end" style={{marginBottom: 10}}>
          {snapshotThemes.data && snapshotThemes.data.pagination.totalElements > 0 && (
            <Pagination
              totalItems={snapshotThemes.data.pagination.totalElements}
              currentPage={page}
              pageSize={pageSize}
              onChange={handlePaginationChange}
              showSizeChanger={true}
            />
          )}
        </div>
        <Table columns={columns as any}
               components={components}
               rowSelection={rowSelection}
               loading={snapshotThemes.isLoading}
               rowKey={"id"}
               dataSource={snapshotThemes.data?.content}
               pagination={false}/>
      </div>
      <Divider style={{marginBottom: -10}}/>
    </Modal>
  );
};

export default SnapshotThemesModal;


interface EditableRowProps {
  index: number;
}


const EditableContext = React.createContext<FormInstance<any> | null>(null);
const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ISnapshotTheme;
  record: ISnapshotTheme;
  handleSave: (record: ISnapshotTheme) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                     title,
                                                     editable,
                                                     children,
                                                     dataIndex,
                                                     record,
                                                     handleSave,
                                                     ...restProps
                                                   }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({[dataIndex]: record[dataIndex]});
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({...record, ...values});
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item

        style={{margin: 0}}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `Name is required.`,
          },
        ]}
      >
        <Input className="editable-cell-input-wrap"
               ref={inputRef}
               alt="themeName"
               onPressEnter={save}
               onBlur={save}/>
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
