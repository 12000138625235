import React from 'react';
import { RichUtils } from 'draft-js';
import SvgBold from '../../../../assets/icons/js/bold';
import SvgItalic from '../../../../assets/icons/js/italic';
import SvgStrike from '../../../../assets/icons/js/strike';
import SvgUnderScore from '../../../../assets/icons/js/underscore';
import SvgOrderedList from '../../../../assets/icons/js/ordered-list';
import SvgBulletedList from '../../../../assets/icons/js/bulleted-list';
import { Select } from 'antd';
import { HighlightToolbar } from './highlight-toolbar/highlight-toolbar';

const {Option} = Select;

export default function Toolbar(props: any) {
  const {editorState, onSetEditorState} = props;
  const blockType = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType();
  const currentStyle = editorState.getCurrentInlineStyle();

  const handleToggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    onSetEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault();
    onSetEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const isActive = (type: string) => {
    return blockType === type ? 'active-button' : '';
  }

  const isCurrentStyle = (type: string) => {
    return currentStyle.has(type) ? 'active-button-style' : '';
  }

  return (
    <div className="d-flex row">
      <Select value={blockType} style={{width: 150}}>
        <Option className={isActive('header-one')}
                onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => handleBlockClick(e, "header-one")}
                value="header-one">Title</Option>
        <Option className={isActive('header-two')}
                onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => handleBlockClick(e, "header-two")}
                value="header-two">Subtitle</Option>
        <Option value="header-three" className={isActive('header-three')}
                onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => handleBlockClick(e, "header-three")}>
          Heading
        </Option>
        <Option value="header-four" className={isActive('header-four')}
                onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => handleBlockClick(e, "header-four")}>
          Subheading
        </Option>
        <Option value="unstyled" className={isActive('unstyled')}
                onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => handleBlockClick(e, "unstyled")}>
          Text
        </Option>
      </Select>
      <button className={isCurrentStyle('BOLD')} onMouseDown={(e) => handleToggleClick(e, "BOLD")}>
        <SvgBold/>
      </button>
      <button className={isCurrentStyle('UNDERLINE')} onMouseDown={(e) => handleToggleClick(e, "UNDERLINE")}>
        <SvgUnderScore/>
      </button>
      <button className={isCurrentStyle('ITALIC')} onMouseDown={(e) => handleToggleClick(e, "ITALIC")}>
        <SvgItalic/>
      </button>
      <button className={isCurrentStyle('STRIKETHROUGH')} onMouseDown={(e) => handleToggleClick(e, "STRIKETHROUGH")}>
        <SvgStrike/>
      </button>
      <button className={isActive('ordered-list-item')} onMouseDown={(e) => handleBlockClick(e, "ordered-list-item")}>
        <SvgOrderedList/>
      </button>
      <button className={isActive('unordered-list-item')}
              onMouseDown={(e) => handleBlockClick(e, "unordered-list-item")}>
        <SvgBulletedList/>
      </button>
      {props?.highlightToolbar &&
          <HighlightToolbar editorState={editorState} noteType={props.noteType}/>
      }
    </div>
  )
}
