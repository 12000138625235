import React from 'react';

const SvgQpLogoIH = () => {
  return (
    <svg width="126" height="50" viewBox="0 0 96 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2705 0H2.19415C0.982356 0 0 0.989069 0 2.20915V16.3789C0 17.599 0.982356 18.5881 2.19415 18.5881H16.2705C17.4823 18.5881 18.4646 17.599 18.4646 16.3789V2.20915C18.4646 0.989069 17.4823 0 16.2705 0Z"
        fill="#1B3380"/>
      <path
        d="M8.05314 14.7673C8.05314 14.9819 7.98996 15.1916 7.87158 15.37C7.7532 15.5483 7.58494 15.6874 7.38808 15.7695C7.19122 15.8516 6.9746 15.8731 6.76561 15.8312C6.55662 15.7893 6.36466 15.686 6.21399 15.5343C6.06332 15.3826 5.96071 15.1894 5.91914 14.9789C5.87757 14.7685 5.8989 14.5504 5.98045 14.3522C6.06199 14.154 6.20008 13.9846 6.37725 13.8654C6.55442 13.7462 6.76271 13.6826 6.97579 13.6826C7.26141 13.683 7.53523 13.7974 7.7372 14.0007C7.93916 14.2041 8.05278 14.4798 8.05314 14.7673Z"
        fill="white"/>
      <path
        d="M11.0785 3.20117H5.9502V5.26651H11.0785C11.2805 5.26651 11.4806 5.30658 11.6673 5.38442C11.8539 5.46227 12.0235 5.57637 12.1664 5.7202C12.3092 5.86404 12.4226 6.0348 12.4999 6.22274C12.5772 6.41067 12.617 6.6121 12.617 6.81552C12.617 7.01893 12.5772 7.22036 12.4999 7.4083C12.4226 7.59623 12.3092 7.76699 12.1664 7.91083C12.0235 8.05467 11.8539 8.16877 11.6673 8.24661C11.4806 8.32445 11.2805 8.36452 11.0785 8.36452H5.9502V10.4299V12.4952H8.00152V10.4299H11.0785C12.0306 10.4299 12.9437 10.0491 13.6169 9.37124C14.2901 8.69342 14.6683 7.7741 14.6683 6.81552C14.6683 5.85693 14.2901 4.93761 13.6169 4.25979C12.9437 3.58197 12.0306 3.20117 11.0785 3.20117Z"
        fill="white"/>
      <path
        d="M27.2509 12.6337V13.2842C27.2509 14.0334 27.41 14.158 28.1732 14.158H29.0424C29.2192 14.158 29.3607 14.2306 29.3607 14.4087V14.9264C29.3607 15.177 28.9186 15.2838 28.1555 15.2838C26.7 15.2838 25.9205 14.8908 25.9205 13.3199V12.6351C23.9522 12.3858 22.5674 10.9751 22.5674 7.97846V6.02542C22.5674 2.70417 24.2705 1.3291 26.5952 1.3291C28.92 1.3291 30.6067 2.70691 30.6067 6.02542V7.95517C30.6244 10.9683 29.2464 12.4022 27.255 12.6337M29.2764 6.04597C29.2764 3.52729 28.2657 2.5631 26.6347 2.5631C25.0037 2.5631 23.9739 3.52181 23.9739 6.04597V7.97435C23.9739 10.4917 25.0023 11.4558 26.6347 11.4558C28.267 11.4558 29.2764 10.4971 29.2764 7.97435V6.04597Z"
        fill="#1B3380"/>
      <path
        d="M37.9522 12.5631H37.2081C37.1692 12.5631 37.1307 12.5552 37.095 12.5397C37.0593 12.5242 37.0272 12.5015 37.0005 12.473C36.9739 12.4445 36.9533 12.4108 36.9401 12.374C36.9269 12.3372 36.9214 12.2981 36.9238 12.259V11.9741C36.1076 12.4384 35.4343 12.6699 34.565 12.6699C32.7735 12.6699 31.9805 11.5455 31.9805 9.56367V4.63315C31.9829 4.55327 32.0154 4.47733 32.0716 4.42082C32.1277 4.36431 32.2031 4.33151 32.2825 4.3291H32.9912C33.0706 4.33118 33.1462 4.36388 33.2024 4.42046C33.2586 4.47703 33.2911 4.55317 33.2932 4.63315V9.56367C33.2932 11.0812 33.8073 11.5455 34.9255 11.5455C35.5458 11.5455 36.2368 11.2948 36.9292 10.9565V4.63589C36.9346 4.55704 36.9682 4.48282 37.0237 4.42694C37.0792 4.37105 37.1529 4.33728 37.2312 4.33184H37.9522C38.0265 4.3405 38.0951 4.37616 38.1452 4.43214C38.1953 4.48811 38.2234 4.56056 38.2242 4.63589V12.2768C38.2253 12.351 38.1974 12.4225 38.1465 12.4761C38.0956 12.5297 38.0258 12.5609 37.9522 12.5631Z"
        fill="#1B3380"/>
      <path
        d="M45.7332 8.86748H40.7478V9.08113C40.7478 10.8616 41.4401 11.5272 42.8236 11.5272C43.7069 11.5243 44.5893 11.4708 45.4666 11.367H45.5374C45.5678 11.3636 45.5986 11.3665 45.6279 11.3755C45.6572 11.3846 45.6844 11.3996 45.7077 11.4196C45.731 11.4396 45.75 11.4642 45.7636 11.4919C45.7771 11.5196 45.7848 11.5498 45.7863 11.5806V12.0983C45.7863 12.2956 45.7156 12.4024 45.4843 12.438C44.6087 12.6158 43.7167 12.6994 42.8236 12.6873C41.1205 12.6873 39.4541 11.8847 39.4541 9.09894V7.74168C39.4487 5.59964 40.624 4.24512 42.7528 4.24512C44.9293 4.24512 46.0339 5.61471 46.0339 7.74442V8.54563C46.0162 8.74148 45.9101 8.86748 45.7332 8.86748ZM44.6858 7.72387C44.6858 6.02832 44.0478 5.36681 42.7351 5.36681C41.4578 5.36681 40.7314 6.09954 40.7314 7.72387V7.86083H44.6858V7.72387Z"
        fill="#1B3380"/>
      <path
        d="M49.5815 12.67C49.0374 12.67 47.9492 12.6344 47.2228 12.4385C47.0283 12.4029 46.9031 12.3015 46.9031 12.1345V11.6414C46.9049 11.6079 46.9135 11.5751 46.9284 11.545C46.9433 11.515 46.9641 11.4883 46.9896 11.4666C47.015 11.4449 47.0446 11.4287 47.0766 11.419C47.1085 11.4092 47.142 11.4062 47.1752 11.4099H47.2459C47.956 11.499 49.0374 11.588 49.587 11.588C50.5623 11.588 51.0602 11.3387 51.0602 10.517C51.0602 10.0705 50.7881 9.78424 49.942 9.26654L48.4266 8.35439C47.522 7.80655 46.9004 7.15874 46.9004 6.21235C46.9004 4.64143 48.0539 4.23056 49.5965 4.23056C50.3751 4.22773 51.1525 4.29326 51.9199 4.42641C52.1158 4.46202 52.2042 4.56337 52.2042 4.73046V5.20433C52.2084 5.2376 52.2051 5.27139 52.1946 5.30323C52.1842 5.33506 52.1667 5.36413 52.1436 5.3883C52.1205 5.41247 52.0924 5.43112 52.0612 5.4429C52.03 5.45467 51.9966 5.45926 51.9634 5.45634H51.9267C51.4138 5.42073 50.4548 5.33171 49.621 5.33171C48.7871 5.33171 48.2376 5.43853 48.2376 6.20687C48.2376 6.65336 48.5749 6.9396 49.2659 7.34911L50.7391 8.22428C51.9811 8.95701 52.3715 9.59387 52.3715 10.4923C52.3715 12.0276 51.2533 12.67 49.5856 12.67"
        fill="#1B3380"/>
      <path
        d="M57.7586 12.5999C57.435 12.6408 57.1094 12.6645 56.7833 12.6711C55.423 12.6711 54.7619 12.315 54.7619 10.6537V5.42052L53.3077 5.24248C53.1295 5.22467 53.0234 5.13565 53.0234 4.96856V4.55768C53.0234 4.52065 53.0308 4.484 53.0453 4.44993C53.0597 4.41587 53.0808 4.38511 53.1074 4.3595C53.134 4.3339 53.1654 4.31399 53.1999 4.30097C53.2343 4.28795 53.271 4.2821 53.3077 4.28377H54.7619V2.832C54.7652 2.75742 54.7941 2.6863 54.8437 2.63084C54.8934 2.57537 54.9606 2.539 55.034 2.52795L55.7957 2.40332H55.8311C55.8625 2.40438 55.8935 2.41167 55.9221 2.42478C55.9507 2.43788 55.9765 2.45654 55.998 2.47969C56.0195 2.50284 56.0362 2.53003 56.0473 2.55969C56.0583 2.58936 56.0634 2.62093 56.0623 2.65259V4.29609H57.658C57.7364 4.29824 57.8108 4.33131 57.8653 4.38818C57.9197 4.44504 57.9498 4.52116 57.9491 4.60014V5.11785C57.9463 5.19748 57.9136 5.27309 57.8575 5.3293C57.8014 5.38552 57.7262 5.41814 57.6471 5.42052H56.0569V10.6524C56.0569 11.4741 56.2691 11.5987 56.854 11.5987H57.6879C57.9014 11.5987 58.0076 11.67 58.0076 11.8124V12.326C58.0252 12.4629 57.9545 12.5575 57.7586 12.5931"
        fill="#1B3380"/>
      <path
        d="M60.0286 3.01276H59.2668C59.2271 3.01331 59.1877 3.00586 59.151 2.99086C59.1142 2.97586 59.0807 2.95361 59.0526 2.92541C59.0244 2.89722 59.0022 2.86364 58.9871 2.82666C58.9721 2.78968 58.9645 2.75005 58.9649 2.71008V1.50621C58.9645 1.46618 58.9721 1.42648 58.9871 1.38942C59.0022 1.35237 59.0244 1.31871 59.0525 1.2904C59.0806 1.26209 59.114 1.23971 59.1508 1.22456C59.1876 1.20941 59.2271 1.2018 59.2668 1.20216H60.0286C60.0684 1.2018 60.1078 1.20941 60.1446 1.22456C60.1814 1.23971 60.2148 1.26209 60.243 1.2904C60.2711 1.31871 60.2933 1.35237 60.3083 1.38942C60.3234 1.42648 60.331 1.46618 60.3306 1.50621V2.70323C60.3319 2.74377 60.325 2.78415 60.3103 2.82193C60.2957 2.85971 60.2736 2.8941 60.2454 2.92304C60.2171 2.95197 60.1834 2.97484 60.1461 2.99026C60.1089 3.00568 60.0689 3.01333 60.0286 3.01276ZM59.9892 12.5629H59.2804C59.2407 12.5633 59.2013 12.5557 59.1645 12.5405C59.1276 12.5254 59.0942 12.503 59.0661 12.4747C59.038 12.4464 59.0158 12.4127 59.0007 12.3756C58.9857 12.3386 58.9781 12.2989 58.9785 12.2589V4.61792C58.9805 4.53793 59.013 4.4618 59.0692 4.40522C59.1254 4.34865 59.201 4.31595 59.2804 4.31387H59.9892C60.0289 4.3135 60.0683 4.32112 60.1051 4.33627C60.142 4.35142 60.1754 4.3738 60.2035 4.40211C60.2316 4.43041 60.2538 4.46408 60.2689 4.50113C60.2839 4.53818 60.2915 4.57789 60.2911 4.61792V12.2589C60.2931 12.2993 60.2866 12.3397 60.2721 12.3775C60.2576 12.4153 60.2355 12.4496 60.207 12.4782C60.1786 12.5068 60.1445 12.5292 60.107 12.5437C60.0695 12.5583 60.0293 12.5649 59.9892 12.5629Z"
        fill="#1B3380"/>
      <path
        d="M64.8534 12.6695C62.6769 12.6695 61.5547 11.2054 61.5547 9.00995V7.88415C61.5547 5.69281 62.6715 4.22461 64.8534 4.22461C67.0353 4.22461 68.1698 5.6887 68.1698 7.88415V9.02776C68.1698 11.2191 67.0353 12.6695 64.8534 12.6695ZM66.8571 7.88415C66.8571 6.34884 66.2368 5.38465 64.8534 5.38465C63.47 5.38465 62.8456 6.34884 62.8456 7.88415V9.02776C62.8456 10.5631 63.4305 11.5273 64.8493 11.5273C66.2327 11.5273 66.853 10.5685 66.853 9.02776L66.8571 7.88415Z"
        fill="#1B3380"/>
      <path
        d="M75.3359 12.5629H74.6272C74.5874 12.5633 74.548 12.5556 74.5112 12.5405C74.4744 12.5253 74.441 12.503 74.4128 12.4747C74.3847 12.4463 74.3625 12.4127 74.3474 12.3756C74.3324 12.3386 74.3248 12.2989 74.3252 12.2588V7.32832C74.3252 5.81082 73.811 5.34653 72.6928 5.34653C72.0712 5.34653 71.3802 5.59716 70.6878 5.93682V12.2588C70.6824 12.3377 70.6488 12.4119 70.5933 12.4678C70.5378 12.5237 70.4641 12.5575 70.3858 12.5629H69.6607C69.584 12.5574 69.512 12.5231 69.4591 12.4668C69.4062 12.4106 69.3763 12.3363 69.3751 12.2588V4.61791C69.3732 4.53977 69.402 4.46405 69.4553 4.40714C69.5085 4.35023 69.5818 4.31672 69.6594 4.31386H70.4035C70.4423 4.31378 70.4808 4.32173 70.5165 4.33722C70.5522 4.3527 70.5844 4.3754 70.611 4.40389C70.6377 4.43239 70.6582 4.46608 70.6714 4.50289C70.6846 4.5397 70.6902 4.57885 70.6878 4.61791V4.90278C71.5039 4.43849 72.1841 4.20703 73.0465 4.20703C74.838 4.20703 75.6311 5.33146 75.6311 7.31326V12.2438C75.6322 12.3264 75.6013 12.4063 75.5449 12.4664C75.4885 12.5265 75.4111 12.5621 75.3291 12.5656"
        fill="#1B3380"/>
      <path
        d="M80.0012 8.13478C79.5224 8.13478 78.9007 8.09917 78.5049 8.06356V12.2764C78.5076 12.3516 78.4817 12.425 78.4324 12.4816C78.383 12.5381 78.3141 12.5735 78.2396 12.5805H77.4411C77.3656 12.5749 77.2952 12.5401 77.2445 12.4834C77.1938 12.4268 77.1668 12.3526 77.1691 12.2764V1.83193C77.1687 1.73324 77.2071 1.6384 77.2759 1.5681C77.3447 1.49781 77.4383 1.45775 77.5364 1.45666C78.3558 1.3736 79.179 1.332 80.0026 1.33203C82.4144 1.33203 84.1705 2.17159 84.1705 4.58207V4.77792C84.1705 7.27742 82.4144 8.13478 80.0026 8.13478M82.8225 4.58207C82.8225 3.06319 81.9179 2.49207 80.0203 2.49207C79.5591 2.49207 78.8844 2.52768 78.5239 2.56329V6.95693C78.8613 6.97337 79.6244 7.02815 80.0203 7.02815C82.0607 7.02815 82.8225 6.36664 82.8225 4.81353V4.58207Z"
        fill="#1B87E6"/>
      <path
        d="M88.6392 5.36685C87.9468 5.36685 87.3265 5.54626 86.5987 5.99138V12.2586C86.5936 12.3373 86.5604 12.4115 86.5051 12.4675C86.4498 12.5234 86.3763 12.5572 86.2981 12.5627H85.5703C85.4947 12.5556 85.4242 12.5207 85.3725 12.4646C85.3208 12.4085 85.2915 12.3351 85.2901 12.2586V4.61768C85.2883 4.53954 85.3171 4.46382 85.3703 4.40691C85.4235 4.35 85.4969 4.31649 85.5744 4.31363H86.2655C86.343 4.31649 86.4164 4.35 86.4696 4.40691C86.5228 4.46382 86.5516 4.53954 86.5498 4.61768V4.93817C87.2952 4.42046 87.9685 4.22461 88.6433 4.22461C88.8555 4.22461 88.9616 4.31363 88.9616 4.49168V5.11758C88.9439 5.27782 88.8378 5.36685 88.6433 5.36685"
        fill="#1B87E6"/>
      <path
        d="M92.6839 12.6695C90.5006 12.6695 89.3838 11.2054 89.3838 9.00995V7.88415C89.3838 5.69281 90.5006 4.22461 92.6839 4.22461C94.8671 4.22461 96.0003 5.6887 96.0003 7.88415V9.02776C96.0003 11.2191 94.8644 12.6695 92.6839 12.6695ZM94.6876 7.88415C94.6876 6.34884 94.0673 5.38465 92.6839 5.38465C91.3004 5.38465 90.6788 6.34336 90.6788 7.88415V9.02776C90.6788 10.5631 91.2637 11.5273 92.6839 11.5273C94.0673 11.5273 94.6876 10.5685 94.6876 9.02776V7.88415Z"
        fill="#1B87E6"/>
      <path d="M63.2293 18.6812H62.8457V14.2725H63.2293V18.6812Z" fill="#707070"/>
      <path
        d="M67.0134 16.2737V18.6814H66.642V16.3257C66.642 15.7724 66.4244 15.5464 66.0149 15.5464C65.5606 15.5464 65.2477 15.8299 64.9798 16.2422V18.6814H64.6084V15.315H64.9281L64.9607 15.882C65.2287 15.5026 65.6069 15.2451 66.0857 15.2451C66.6924 15.2451 67.012 15.6245 67.012 16.275L67.0134 16.2737Z"
        fill="#707070"/>
      <path
        d="M70.1976 15.6171L70.0249 15.8622C69.7311 15.6568 69.4821 15.5527 69.1421 15.5527C68.7013 15.5527 68.4007 15.7718 68.4007 16.1197C68.4007 16.4676 68.6183 16.5963 69.2128 16.7566C69.9474 16.9497 70.2874 17.2003 70.2874 17.7742C70.2874 18.3987 69.7501 18.7521 69.0795 18.7521C68.5354 18.7521 68.1722 18.5658 67.8838 18.3206L68.0878 18.0823C68.3762 18.3069 68.6632 18.4357 69.0795 18.4357C69.5583 18.4357 69.8984 18.2042 69.8984 17.792C69.8984 17.3797 69.7134 17.2387 69.0224 17.0455C68.3068 16.8593 68.0185 16.5949 68.0185 16.1252C68.0185 15.6102 68.4986 15.2432 69.1312 15.2432C69.5597 15.2432 69.8861 15.3719 70.199 15.6171H70.1976Z"
        fill="#707070"/>
      <path
        d="M71.7136 14.1038C71.7136 14.2517 71.6048 14.3736 71.432 14.3736C71.2592 14.3736 71.1572 14.2517 71.1572 14.1038C71.1572 13.9559 71.2661 13.834 71.432 13.834C71.598 13.834 71.7136 13.9559 71.7136 14.1038ZM71.6238 18.681H71.2524V15.3145H71.6238V18.681Z"
        fill="#707070"/>
      <path
        d="M75.4532 15.3856C75.2233 15.45 74.9295 15.476 74.5268 15.4828C74.8778 15.6499 75.0954 15.9334 75.0954 16.3963C75.0954 17.0524 74.6411 17.503 73.8875 17.503C73.7283 17.503 73.5801 17.4838 73.4522 17.4386C73.2931 17.529 73.1965 17.6701 73.1965 17.8248C73.1965 18.0248 73.3243 18.1727 73.727 18.1727H74.2697C74.9349 18.1727 75.3444 18.5329 75.3444 19.0355C75.3444 19.6409 74.871 20.0011 73.8929 20.0011C72.9149 20.0011 72.5 19.7053 72.5 19.0355H72.8455C72.8523 19.5053 73.0944 19.6916 73.8943 19.6916C74.6942 19.6916 74.9689 19.4533 74.9689 19.0547C74.9689 18.6945 74.6874 18.4946 74.2153 18.4946H73.6712C73.1149 18.4946 72.8469 18.2247 72.8469 17.9029C72.8469 17.6769 72.9815 17.4783 73.2114 17.3304C72.8278 17.1304 72.6483 16.8346 72.6483 16.384C72.6483 15.7088 73.1407 15.2445 73.8753 15.2445C74.7703 15.2569 75.0138 15.161 75.3389 15.0254L75.4546 15.3856H75.4532ZM73.0305 16.3827C73.0305 16.9236 73.3624 17.2318 73.8739 17.2318C74.418 17.2318 74.7186 16.9223 74.7186 16.3881C74.7186 15.854 74.4439 15.5321 73.8739 15.5321C73.3039 15.5321 73.0305 15.8855 73.0305 16.3813V16.3827Z"
        fill="#707070"/>
      <path
        d="M78.598 16.2733V18.681H78.228V16.3253C78.228 15.7775 78.0049 15.5461 77.6009 15.5461C77.1465 15.5461 76.8337 15.8296 76.5643 16.2418V18.681H76.1943V13.9436L76.5643 13.8984V15.8679C76.8391 15.4885 77.1969 15.2434 77.6703 15.2434C78.2715 15.2434 78.5966 15.6364 78.5966 16.2733H78.598Z"
        fill="#707070"/>
      <path
        d="M81.4369 18.5384C81.2328 18.674 81.0016 18.7507 80.7336 18.7507C80.229 18.7507 79.9147 18.448 79.9147 17.8618V15.6171H79.3516V15.3144H79.9147V14.5091L80.2861 14.4639V15.313H81.1485L81.1036 15.6157H80.2847V17.844C80.2847 18.2371 80.4384 18.4302 80.7772 18.4302C80.9622 18.4302 81.1227 18.3782 81.2886 18.2823L81.4355 18.5398L81.4369 18.5384Z"
        fill="#707070"/>
      <path
        d="M83.9818 15.6171L83.8091 15.8622C83.5152 15.6568 83.2663 15.5527 82.9276 15.5527C82.4869 15.5527 82.1862 15.7718 82.1862 16.1197C82.1862 16.4676 82.4039 16.5963 82.9983 16.7566C83.7329 16.9497 84.0716 17.2003 84.0716 17.7742C84.0716 18.3987 83.5343 18.7521 82.8637 18.7521C82.3209 18.7521 81.9564 18.5658 81.668 18.3206L81.872 18.0823C82.159 18.3069 82.4474 18.4357 82.8623 18.4357C83.3425 18.4357 83.6812 18.2042 83.6812 17.792C83.6812 17.3797 83.4962 17.2387 82.8052 17.0455C82.0897 16.8593 81.8013 16.5949 81.8013 16.1252C81.8013 15.6102 82.2815 15.2432 82.914 15.2432C83.3425 15.2432 83.6676 15.3719 83.9818 15.6171Z"
        fill="#707070"/>
      <path
        d="M87.555 16.5241H85.4588V18.6798H85.0752V14.2725H85.4588V16.1967H87.555V14.2725H87.9386V18.6812H87.555V16.5254V16.5241Z"
        fill="#707070"/>
      <path
        d="M91.6604 18.6809H91.3475L91.3217 18.0824C91.0918 18.4809 90.7463 18.7521 90.228 18.7521C89.6336 18.7521 89.2812 18.3851 89.2812 17.7222V15.3145H89.6526V17.6825C89.6526 18.2166 89.8757 18.4426 90.3178 18.4426C90.7599 18.4426 91.0592 18.1522 91.289 17.7537V15.3145H91.6604V18.6809Z"
        fill="#707070"/>
      <path
        d="M95.592 16.9814C95.592 18.0634 95.1322 18.7509 94.3255 18.7509C93.9038 18.7509 93.5774 18.5386 93.3597 18.2551L93.3216 18.6797H93.002V13.9436L93.3733 13.8984V15.7775C93.61 15.4557 93.9419 15.2434 94.3772 15.2434C95.1376 15.2434 95.592 15.8611 95.592 16.9814ZM95.2015 16.9814C95.2015 15.9898 94.8696 15.5529 94.2997 15.5529C93.8644 15.5529 93.5964 15.8104 93.3733 16.1322V17.9154C93.5842 18.225 93.878 18.4427 94.2561 18.4427C94.8438 18.4427 95.2029 17.9606 95.2029 16.9814H95.2015Z"
        fill="#707070"/>
    </svg>
  );
};

export default SvgQpLogoIH;
