import { environment } from "../../../../../../environment";
import { IActivity, IActivityData } from "../model/activities.interface";
import { axiosClient } from "../../../../../../axios-client";
import { AxiosResponse } from "axios";
import { ITeamMemberData } from "../../../../../settings/settings-tabs/team/model/team-member.interface";

class ActivityFeedApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getAllActivities(params: any): Promise<IActivityData> {
    try {
      const response: AxiosResponse<IActivityData> = await axiosClient.get<IActivityData>(`${this.baseUrl}/users-activity`, {
        params: params
      });
      return response.data;
    } catch (err) {
      throw err
    }
  }

  async getUserActivity(userId: string): Promise<IActivity[]> {
    try {
      const response: AxiosResponse<IActivity[]> = await axiosClient.get<IActivity[]>(`${this.baseUrl}/users-activity/${userId}`);
      return response.data;
    } catch (err) {
      throw err
    }
  }

  async getTeamMembers(search: string): Promise<ITeamMemberData> {
    try {
      const response: AxiosResponse<ITeamMemberData> = await axiosClient.get<ITeamMemberData>(`${this.baseUrl}/teammembers?pageSize=1000`, {
        params: {search}
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ActivityFeedApi()