
import { ILicense } from "../../pages/admin/admin-tabs/licenses/model/licenses.interface";

export interface IUserData {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  initials: string;
  profilePic: string;
  userId: number;
  authorizationCode: string;
  userApiKey: string;
  organization: any;
  instance: InstanceEnum;
  license: ILicense;
  isImpersonated?: boolean;
  role: IUserRole;
  categories: any[];
  myAccount: any;
  profilePictureColor: string;
  displayName: string;
  createdAt: string;
}

interface IUserRole {
  id: number;
  name: string;
}

export enum InstanceEnum {
  US = "US",
  EU = "EU",
  CA = "CA",
  AE = "AE",
  AU = "AU",
  DEV = "DEV",
}
