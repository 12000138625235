export enum FlagTypeIconsEnum {
  IH_FLAG = 'IH_FLAG',
  IH_ALERT = 'IH_ALERT',
  IH_HELP = 'IH_HELP',
  IH_LIKE = 'IH_LIKE',
  IH_DISLIKE = 'IH_DISLIKE',
  IH_GPS = 'IH_GPS',
  IH_CALENDAR = 'IH_CALENDAR',
  IH_ANALYSIS = 'IH_ANALYSIS',
  IH_TARGET = 'IH_TARGET',
  IH_THUNDER = 'IH_THUNDER',
}
