import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../../../../store/global-context";
import { Button, Tabs } from "antd";
import ExternalURLImport from "./components/external-url-import/external-url-import";
import GoogleDriveIntegrationApi
  from "../../../../../settings/settings-tabs/setup/integrations/google-drive-integration/api/google-drive-integration-api";
import GoogleImportDetails from "./components/google-import-details/google-import-details";
import SyncingDataIndicator from "../../components/syncing-data-indicator/syncing-data-indicator";
import ExternalZipImport from "./components/external-zip-import/external-zip-import";
import ZipImportApi from "../../../../../settings/settings-tabs/setup/integrations/zip-import/api/zip-import-api";
import OneDriveIntegrationApi
  from "../../../../../settings/settings-tabs/setup/integrations/one-drive-integration/api/one-drive-integration-api";
import SharepointIntegrationApi
  from "../../../../../settings/settings-tabs/setup/integrations/sharepoint-integration/api/sharepoint-integration-api";
import { WuAppHeader } from "@questionproext/wick-ui-lib";

const SyncWithExternalData = () => {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [apiLoading, setApiLoading] = useState(false);
  const [showSyncingView, setShowSyncingView] = useState(false);
  const [showImportDetailsView, setShowImportDetailsView] = useState(false);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalFolders, setTotalFolders] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [filesWithoutFolder, setFilesWithoutFolder] = useState(0);
  const [error, setError] = useState("");
  const [tab, setTab] = useState("1");
  const [driveSelected, setDriveSelected] = useState<"GOOGLE" | "MICROSOFT" | "SHAREPOINT">("GOOGLE");


  const [externalDriveFolder, setExternalDriveFolder] = useState<any>(null);

  //for ZipImport
  const [zipFolder, setZipFolder] = useState<any>(null)

  useEffect(() => {
    setError("")
    if (url) {
      searchForProject(url);
    }
  }, [url]);

  useEffect(() => {
    setError("");
    setUrl("")
  }, [driveSelected]);


  const updateDriveSelected = (drive: "GOOGLE" | "MICROSOFT" | "SHAREPOINT") => setDriveSelected(drive);

  function isURLCompatible(str: string): boolean {
    const urlCompatibleRegex: RegExp = /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+[/#?]?.*)$/;
    return urlCompatibleRegex.test(str);
  }

  function searchForProject(search: string): void {
    if (!isURLCompatible(search)) {
      setError("Invalid URL");
      return;
    }

    setLoading(true);
    if (!loading) {
      if (driveSelected === "GOOGLE") {
        GoogleDriveIntegrationApi.searchForProject(search)
          .then((response: any) => {
            setExternalDriveFolder(response)
            setLoading(false);
            setError('');
          }, (err: any) => {
            setLoading(false);
            setError(err.response.data.message);
          });
      } else if (driveSelected === "MICROSOFT") {
        OneDriveIntegrationApi.getOneDriveMetaData(search)
          .then((response: any) => {
            setExternalDriveFolder(response)
            setLoading(false);
            setError('');
          }, (err: any) => {
            setLoading(false);
            setError(err.response.data.message);
          });
      } else {
        SharepointIntegrationApi.getSharepointLinkData(search).then((response: any) => {
          setExternalDriveFolder(response)
          setLoading(false);
          setError('');
        }, (err: any) => {
          setLoading(false);
          setError(err.response.data.message);
        });
      }
    }
  }

  const navigateBack = () => {
    if (showImportDetailsView) {
      setShowImportDetailsView(false);
      return;
    }

    navigate('/initial-page');
  }


  const onContinue = () => {
    if (!showImportDetailsView) {
      setShowImportDetailsView(true);
      const {
        projectsNumber, filesNumber, filesNumberWithinAnyFolder
      } = tab === "1" ? externalDriveFolder : zipFolder;
      setTotalFolders(projectsNumber);
      setTotalFiles(filesNumber);
      setFilesWithoutFolder(filesNumberWithinAnyFolder);
      return;
    }

    setApiLoading(true);
    if (tab === "1") {
      if (driveSelected === "GOOGLE") {
        GoogleDriveIntegrationApi.linkAndImport(url, totalFiles, filesWithoutFolder)
          .then(() => {
            setShowSyncingView(true);
          }).finally(() => {
          setApiLoading(false);
        });
      } else if (driveSelected === "MICROSOFT") {
        OneDriveIntegrationApi.linkAndImport(url).then(() => {
          setShowSyncingView(true);
        })
          .finally(() => {
            setApiLoading(false);
          });

      } else {
        SharepointIntegrationApi.linkAndImport(url).then(() => {
          setShowSyncingView(true);
        })
          .finally(() => {
            setApiLoading(false);
          });
      }
    } else {
      zipFolder.status = "IMPORT"
      ZipImportApi.editImportData(zipFolder).then(() => {
        setShowSyncingView(true);
      })
        .finally(() => {
          setApiLoading(false);
        });
    }
  };

  const updateUrl = (value: string) => {
    setUrl(value)
  }

  const updateFolder = (folder: any) => setZipFolder(folder);

  const onTabChange = (key: string) => setTab(key)

  const isContinueDisabled = () => {
    if (tab === "1") {
      return !url || loading || !!error || !externalDriveFolder || apiLoading
    } else {
      return !zipFolder
    }
  }

  return (
    <div className="main column">
      <>
        <WuAppHeader activeProductName={"InsightsHub"}
                     onLogoutClick={() => globalContext.logoutUser()}
                     productCategories={globalContext.user.categories}
                     myAccount={globalContext.user.myAccount}/>
        <div className="container">
          <div className="main-wrapper" style={{backgroundColor: "#F5F5F5"}}>
            {/*<Suspense fallback={<Loader/>}>*/}
            {
              !showSyncingView ?
                <div className="home-welcome-page-wrapper">
                  <div className="home-welcome-page-container">
                    <h1>Import external data</h1>
                    <p>Synchronize survey folders for powerful insights, ensuring knowledge continuity.</p>
                    {
                      showImportDetailsView ?
                        <GoogleImportDetails totalFolders={totalFolders}
                                             loading={loading}
                                             totalFiles={totalFiles}
                                             filesWithoutFolder={filesWithoutFolder}
                        />
                        :
                        <div className="import-details-wrapper">
                          <Tabs onChange={onTabChange}>
                            <Tabs.TabPane tab="External URL" key="1">
                              <ExternalURLImport url={url}
                                                 error={error}
                                                 updateUrl={updateUrl}
                                                 driveSelected={driveSelected}
                                                 updateDriveSelected={updateDriveSelected}
                              />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Upload from your computer" key="2">
                              <ExternalZipImport folder={zipFolder} updateFolder={updateFolder}/>
                            </Tabs.TabPane>
                          </Tabs>
                        </div>
                    }
                    <div className="row" style={{gap: 10, marginTop: 30}}>
                      <Button type="link" onClick={navigateBack}>Go back</Button>
                      <Button disabled={isContinueDisabled()} type="primary" style={{padding: "5px 60px"}}
                              loading={loading || apiLoading}
                              onClick={onContinue}>Continue</Button>
                    </div>
                  </div>
                </div>
                :
                <SyncingDataIndicator onClick={() => navigate('/home')}/>
            }
            {/*</Suspense>*/}
          </div>
        </div>
      </>
    </div>
  );
};

export default SyncWithExternalData;
