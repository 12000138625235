import React, { useEffect, useState } from 'react';
import classes from '../../google-drive-integration/tabs/settings/google-drive-project-import.module.scss';
import SvgSaving from '../../../../../../../assets/icons/js/Saving';
import SvgSuccess from '../../../../../../../assets/icons/js/Success';
import { Button, notification, Table, Tag } from 'antd';
import SvgArrowBack from '../../../../../../../assets/icons/js/ArrowBack';
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { Loader } from '../../../../../../../_shared/components/loader/loader';
import qs from 'query-string';
import SvgFiletypeXls from "../../../../../../../assets/icons/js/FiletypeXls";
import { ExcelEditableRow } from "./excel-editable-row";
import { ExcelEditableCell } from "./excel-editable-cell";
import excelImportApi from "../api/excel-import-api";
import '../../zip-import/zip-import.scss'
import { ColumnsType } from "antd/lib/table";
import { IImportContent } from "../model/import-content.interface";
import { IImportColumns } from "../model/import-columns.interface";

export default function ImportExcelTable() {
  const location: any = useLocation();
  const urlParam: any = qs.parse(location.search);

  const navigate: NavigateFunction = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [excelColumnsData, setExcelColumnsData] = useState<ColumnsType<any>>();

  useEffect(() => {
    const id: string | null = urlParam.id;

    if (id) {
      setLoader(true);
      Promise.all([excelImportApi.getImportById(id), excelImportApi.getImportColumns(id)])
        .then((response: [IImportContent, IImportColumns]) => {

          if (response[0]?.status === 'IMPORTED') {
            navigateBack();
            return;
          }

          setDataSource(response[0].content);
          setExcelColumnsData([
            {
              title: '#',
              dataIndex: 'id',
              key: 'id',
              width: '3%',
              render: (val: number) => <span>{response[0].content.findIndex((row: {
                id: number;
              }) => row.id == val) + 1}</span>
            },
            {
              title: 'Name',
              dataIndex: 'name',
            },
            {
              title: 'Due Date',
              editable: true,
              dataIndex: 'dueDate',
            },
            {
              title: 'Description',
              editable: true,
              dataIndex: 'description',
              width: 250
            },
            ...response[1].columns.map((column: { name: string; id: string; }) => {
              return {
                title: column?.name,
                dataIndex: column?.id,
                editable: true
              }
            }),
          ].map(mapExcelColumns))
          setLoader(false);
        })
      return;
    } else {
      navigateBack()
    }

  }, []);


  const components = {
    body: {
      row: ExcelEditableRow,
      cell: ExcelEditableCell,
    },
  };

  function mapExcelColumns(col: any) {
    if (!col.editable) {
      return col;
    }


    const newCol = {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        columnKey: col.key,
        handleSave
      })
    };
    if (col.children) {
      newCol.children = col.children.map(mapExcelColumns);
    }
    return newCol;
  }


  function handleSave(row: any): void {
    setDataSource(prevItems => {
      return prevItems.map(item => {
        if (item.id === row.id) {
          return {...item, ...row};
        }
        return item
      })
    })

    setSaving(true);
    excelImportApi.updateImportItem(row.id, row).then(() => {
      setSaving(false)
    })
  }

  function publishImport(): void {
    excelImportApi.publishImport(urlParam.id).then(() => {
      navigate('/projects')
    }).catch(() => {
      showErrorToast()
    })
  }

  const showErrorToast = () => {
    notification.open({
      message: "Error",
      description: `There has been a problem with your import`,
      type: "error",
      className: "infobox-error",
      duration: 3,
      placement: "bottomRight",
    });
  };

  function navigateBack(): void {
    navigate('/settings/excel-file-import');
  }

  return (
    <div className={classes.googleDriveProjectImport}>
      <div className={`${classes.header} justify-space-between`}>
        <div className="row align-items-center">
          <button style={saving ? {opacity: 0.5, marginRight: 10} : {opacity: 1, marginRight: 10}}
                  className="icon-button back"
                  onClick={navigateBack}>
            <SvgArrowBack/>
          </button>
          <SvgFiletypeXls/>

          <span className="font-weight-300">
            Import from Excel File
          </span>
          <Tag className={classes.chip}>{dataSource?.length} {dataSource?.length === 1 ? "Project" : "Projects"}</Tag>
        </div>
        <div className="row align-items-center">
          {
            saving ?
              <>
                <SvgSaving/>
                <span className="font-weight-300" style={{fontSize: '12px', marginLeft: 0}}>Saving ...</span>
              </> :
              <>
                <SvgSuccess/>
                <span className="font-weight-300"
                      style={{fontSize: '12px', marginLeft: 5}}>All changes saved</span>
              </>
          }
        </div>
        <div className="row align-items-center">
          <div className='dropdown-button-wrapper'>
            <Button type="primary" onClick={() => publishImport()}>
              Import
            </Button>
          </div>
        </div>
      </div>
      <>
        {loader ? <Loader/> :
          <div className={`${classes.body}`}>
            <div className="column">
              <p className={classes.title}>Project Details</p>
              <div className="row justify-space-between">
                <span className={classes.description}>Click on any specific field you'd like to edit</span>
              </div>
            </div>
            <div className={`${classes.table} column`}>
              {excelColumnsData && dataSource &&
                  <Table
                      components={components}
                      rowClassName={() => 'editable-row'}
                      bordered
                      dataSource={dataSource}
                      columns={excelColumnsData}
                      scroll={{y: 680, x: 1500}}
                      rowKey="id"
                      pagination={false}
                  />
              }
            </div>
          </div>
        }
      </>
    </div>
  )
}
