import * as React from "react";

const SvgResearch = (props: any) => {
  return (
    <svg width={17} height={13} viewBox="0 0 17 13" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill={props.color ? props.color : '#545E6B'}
           fillRule="nonzero">
          <path
            d="M9 0c2.761 0 5 2.239 5 5 0 .997-.292 1.925-.794 2.705l2.587 2.588c.39.39.39 1.024 0 1.414l-.086.086c-.39.39-1.024.39-1.414 0l-2.588-2.587C10.925 9.708 9.997 10 9 10v2H0V0h9zM6.184 9.132l.043.029c-.077-.051-.152-.105-.226-.16l.183.131zm-1.183-1.13L2 8v1l4 .001.02.014c-.402-.298-.757-.656-1.053-1.06L5 8.002zM9 2C7.343 2 6 3.343 6 5s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3zM4 5H2v1l2.1.001.005.025C4.036 5.695 4 5.352 4 5zm.027-.526l.014-.117-.005.043-.009.074zM4.3 3.292c.036-.098.075-.196.117-.292l-.103.254-.014.038zm.737-1.34L5 2H2v1h2.416l-.014.031c.167-.387.38-.75.634-1.08zM6.501.669L6.413.72l-.05.03C6.408.723 6.454.695 6.5.67zM8.757.006L9 0c-.107 0-.213.003-.319.01l.076-.004z"
            transform="translate(-163 -442) translate(163 436) translate(0 6)"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgResearch;
