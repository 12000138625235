// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-types-drop-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 44px;
  max-height: 160px;
  width: 100px;
  min-height: 50px;
  overflow: auto;
  border: 1px solid #1b87e6;
  background-color: #fff;
  color: #545e6b;
  z-index: 99;
}
.text-types-drop-down .text-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 4px;
  max-height: 30px;
}
.text-types-drop-down .text-option:hover {
  background-color: #f5f5f5;
}
.text-types-drop-down .text-option .menu-item-icon {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-types-drop-down .text-option .text {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.text-types-drop-down .text-option .text svg {
  margin-right: 10px;
}
.text-types-drop-down .active {
  color: #0a86ea !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/story-editor/components/floating-menu/text-types-dropdown/text-types-dropdown.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,sBAAA;EACA,cAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACI;EACE,yBAAA;AACN;AAEI;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAN;AAGI;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADN;AAIM;EACE,kBAAA;AAFR;AAOE;EACE,yBAAA;AALJ","sourcesContent":[".text-types-drop-down {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  top: 44px;\n  max-height: 160px;\n  width: 100px;\n  min-height: 50px;\n  overflow: auto;\n  border: 1px solid #1b87e6;\n  background-color: #fff;\n  color: #545e6b;\n  z-index: 99;\n\n  .text-option {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    padding: 4px;\n    max-height: 30px;\n\n    &:hover {\n      background-color: #f5f5f5;\n    }\n\n    .menu-item-icon {\n      width: 30%;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n\n    .text {\n      display: flex;\n      align-items: center;\n      font-size: 12px;\n      font-weight: 400;\n      line-height: 16px;\n      text-align: left;\n\n\n      svg {\n        margin-right: 10px;\n      }\n    }\n  }\n\n  .active {\n    color: #0a86ea !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
