export const environment = {
  name: process.env.REACT_APP_ENV,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  oauthUrl: process.env.REACT_APP_OAUTH_URL,
  oauthClientId: process.env.REACT_APP_QP_OAUTH_CLIENT_ID,
  slack_client_id: process.env.REACT_APP_SLACK_CLIENT_ID,
  google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  google_redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
  google_redirect_uri_initial: process.env.REACT_APP_GOOGLE_REDIRECT_URI_INITIAL,
  google_redirect_uri_home: process.env.REACT_APP_GOOGLE_REDIRECT_URI_HOME,
  microsoft_client_id: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
  microsoft_redirect_uri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI,
  microsoft_redirect_uri_initial: process.env.REACT_APP_MICROSOFT_REDIRECT_URI_INITIAL,
  microsoft_redirect_uri_home: process.env.REACT_APP_MICROSOFT_REDIRECT_URI_HOME,
  microsoft_tenant_id: process.env.REACT_APP_MICROSOFT_TENANT_ID,
};
