import * as React from "react";

const SvgCopy = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Mask"
           fill={props.color ? props.color : '#545E6B'}
           transform="translate(2.000000, 0.000000)">
          <path
            d="M4,3 L9,8 L4,8 L4,3 Z M1.2819426,3 L3,3 L3,9 L9,9 L9,14.7180574 C9,15.1638168 8.95358719,15.3254599 8.86643365,15.4884229 C8.7792801,15.6513858 8.65138581,15.7792801 8.48842285,15.8664336 C8.32545989,15.9535872 8.16381677,16 7.7180574,16 L1.2819426,16 C0.83618323,16 0.674540111,15.9535872 0.511577148,15.8664336 C0.348614185,15.7792801 0.220719898,15.6513858 0.133566352,15.4884229 C0.0464128056,15.3254599 0,15.1638168 0,14.7180574 L0,4.2819426 C0,3.83618323 0.0464128056,3.67454011 0.133566352,3.51157715 C0.220719898,3.34861419 0.348614185,3.2207199 0.511577148,3.13356635 C0.674540111,3.04641281 0.83618323,3 1.2819426,3 Z M3,0 L7,0 L12,5 L12,12 C12,12.5522847 11.5522847,13 11,13 L10,13 L10,7 L5,2 L2,2 L2,1 C2,0.44771525 2.44771525,0 3,0 Z"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgCopy;
