import * as React from "react";

const SvgProject = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M6 0.475C6 0.212665 6.22386 0 6.5 0H15.5C15.7761 0 16 0.212665 16 0.475V3.325C16 3.58734 15.7761 3.8 15.5 3.8H6.5C6.22386 3.8 6 3.58734 6 3.325V0.475Z"
        fill={props.color ? props.color : '#545E6B'}/>
      <path
        d="M3.8 1.9C3.8 2.94934 2.94934 3.8 1.9 3.8C0.850659 3.8 0 2.94934 0 1.9C0 0.850659 0.850659 0 1.9 0C2.94934 0 3.8 0.850659 3.8 1.9Z"
        fill={props.color ? props.color : '#545E6B'}/>
      <path
        d="M6 6.57472C6 6.31237 6.22386 6.0997 6.5 6.0997H15.5C15.7761 6.0997 16 6.31237 16 6.57472V9.4248C16 9.68715 15.7761 9.89982 15.5 9.89982H6.5C6.22386 9.89982 6 9.68715 6 9.4248V6.57472Z"
        fill={props.color ? props.color : '#545E6B'}/>
      <path
        d="M3.8 7.9997C3.8 9.04904 2.94934 9.8997 1.9 9.8997C0.850659 9.8997 0 9.04904 0 7.9997C0 6.95036 0.850659 6.0997 1.9 6.0997C2.94934 6.0997 3.8 6.95036 3.8 7.9997Z"
        fill={props.color ? props.color : '#545E6B'}/>
      <path
        d="M6 12.6744C6 12.4121 6.22386 12.1994 6.5 12.1994H15.5C15.7761 12.1994 16 12.4121 16 12.6744V15.5244C16 15.7867 15.7761 15.9994 15.5 15.9994H6.5C6.22386 15.9994 6 15.7867 6 15.5244V12.6744Z"
        fill={props.color ? props.color : '#545E6B'}/>
      <path
        d="M3.8 14.0994C3.8 15.1487 2.94934 15.9994 1.9 15.9994C0.850659 15.9994 0 15.1487 0 14.0994C0 13.0501 0.850659 12.1994 1.9 12.1994C2.94934 12.1994 3.8 13.0501 3.8 14.0994Z"
        fill={props.color ? props.color : '#545E6B'}/>
    </svg>
  );
}

export default SvgProject;
