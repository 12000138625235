import { Divider, Empty } from 'antd'
import React from 'react'
import SvgDocuments from '../../../../../../assets/icons/js/documents'

const Notepads = ({notepads}: any) => {
  if (!notepads.length) {
    return <div className="w-100 column justify-space-center">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </div>
  }
  return (
    <div className='notepads-wrapper'>
      {notepads.map((notepad: any, index: number) => {
        return <div
          key={index}
          className='notepad'>
          <div className='notepad-title'><SvgDocuments color="#1b87e6" height={12}/> {notepad.title}</div>
          <Divider style={{marginTop: 2}}/>
          <div className='selected-wrapper'>
            {
              notepad.selected.map((selected: any, index: number) => {
                return <div key={index} className='selected-text-card'>
                  <div className='row' style={{color: "#545e6b"}}>
                    {selected.selectedText}
                  </div>
                  <div className='row nodes-wrapper'>
                    {selected.graphs.map((graph: any, index: number) => {
                      return <div
                        key={index}
                        style={graph.selected ? {
                          backgroundColor: "#1b87e6",
                          color: "white"
                        } : {backgroundColor: "#d8d8d8", color: "#545e6b"}}
                        className='node'>
                        {graph.name}
                      </div>
                    })}
                  </div>
                </div>
              })
            }
          </div>
        </div>
      })}
    </div>
  )
}

export default Notepads
