import React, { useState } from 'react';
import { Loader } from '../../../../../_shared/components/loader/loader';
import { ColumnsType } from 'antd/lib/table';
import { Button, Select, Table, Tooltip } from 'antd';
import SvgEdit from '../../../../../assets/icons/js/Edit';
import SvgTrash from '../../../../../assets/icons/js/Trash';
import DeskSettingsApi from '../../../api/settings-api';
import { ConfirmationDialog } from '../../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import { useQuery } from 'react-query';
import { IFolder } from "./model/folder.interface";
import { AddEditFolderDialog } from "./add-edit-folder-dialog/add-edit-folder-dialog";
import SettingsApi from "../../../api/settings-api";
import { IProgram } from "../programs/model/program.interface";
import Pagination from "../../../../../_shared/components/pagination/pagination";

export function Folders(): JSX.Element {
  const programsColumns: ColumnsType<IFolder> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (value: string) => <span className="email">{value}</span>
    },
    {
      title: 'Program',
      key: 'program',
      dataIndex: 'program',
      width: 300,
      render: (value: IProgram) => <span>{value.name}</span>
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      align: 'right',
      render: (folder: any) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <Tooltip placement="bottom" title="Edit Folder">
              <button className="icon-button"
                      onClick={() => openAddEditFolderDialog(folder)}>
                <SvgEdit/>
              </button>
            </Tooltip>

            <Tooltip placement="bottom" title="Delete Folder">
              <button className="icon-button"
                      onClick={() => openConfirmationDialog(folder)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ];
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});
  const [modalData, setModalData] = useState<any>({visible: false});
  const programs = useQuery('allPrograms', () => DeskSettingsApi.getPrograms());
  const [programID, setProgramID] = useState<number | 'all-programs'>('all-programs');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);

  const [totalSize, setTotalSize] = useState(0);

  const folders = useQuery(['folders', programID ? programID : 0, page, pageSize], () => SettingsApi.getFolders(programID === 'all-programs' ? undefined : programID, page, pageSize), {
    onSuccess: (data) => {
      setTotalSize(data.totalSize);
    }
  });

  const isLoading = [programs, folders].some(query => query.isLoading)

  const deleteFolder = (id: number | undefined) => {
    if (id) {
      SettingsApi.deleteFolder(id)
        .then(() => {
          folders.refetch();
        });
    }
  }

  const openConfirmationDialog = (folder: IFolder) => {
    setConfirmationDialog({
      visible: true,
      id: folder.id,
      okText: 'Delete',
      title: 'Delete Folder',
      titleSec: 'You\'re about to delete this Folder',
      content: `${folder.name}`,
      // description: <>
      //   Continuing will remove the Program from this
      //   list{folder.projectNumber && program.projectNumber > 0 ? `, and removes the Program from ${program.projectNumber} projects` : ''}.
      // </>
    })
  }

  const openAddEditFolderDialog = (folder?: IFolder) => {
    if (folder?.id) {
      setModalData({
        id: folder.id,
        name: folder.name,
        programID: folder.programID,
        visible: true
      })
    } else {
      setModalData({...modalData, visible: true, programID: programID === 'all-programs' ? undefined : programID})
    }
  }

  const onConfirmationDialogConfirm = (props: any) => {
    if (props.modal) {
      deleteFolder(props.id);
      onCancel();
    }
  }

  const onOk = (props?: { programID: number }) => {
    if (props) {
      onCancel();
      setProgramID(props.programID);
      folders.refetch();
    }
  }

  const onCancel = () => {
    setModalData({visible: false});
    setConfirmationDialog({visible: false});
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  return <div className="programs-wrapper">
    {<div className="programs">
      <div className="row justify-space-between" style={{marginBottom: 20}}>
        <div className="row" style={{gap: 15}}>
          <Select placeholder={"Select Program"} style={{width: 250}} value={programID}
                  onChange={value => setProgramID(value)}>
            <Select.Option value={'all-programs'}>
              All programs
            </Select.Option>
            {programs.data && programs.data.content.map((program) => {
              return <Select.Option value={program.id} key={program.id}>
                {program.name}
              </Select.Option>
            })}
          </Select>
          <Button type="primary" onClick={() => openAddEditFolderDialog()}>Add folder</Button>
        </div>
        <Pagination totalItems={totalSize}
                    onChange={handlePaginationChange}
                    showSizeChanger={true}
                    pageSize={pageSize}
                    currentPage={page}/>
      </div>
      <div className="body">
        <Table columns={programsColumns}
               dataSource={folders.data?.content}
               pagination={false}
               loading={isLoading}
               rowKey="id"/>
      </div>


    </div>
    }
    {
      modalData.visible &&
      <AddEditFolderDialog data={modalData} onCancel={onCancel} programs={programs?.data ? programs.data.content : []}
                           onOk={onOk}/>
    }
    {
      confirmationDialog.visible &&
      <ConfirmationDialog data={confirmationDialog} onCancel={onCancel}
                          onOk={onConfirmationDialogConfirm}/>
    }

  </div>;
}
