export interface ISnapshot {
  id?: number;
  nugget: string;
  project: string;
  projectId: number;
  projectArchived: boolean;
  imageUrl: string;
  imageText: string;
  htmlContent: string;
  createdBy: { id: number; displayName: string };
  template: SnapshotTemplateEnum;
  createdAt: string;
  updatedAt: string;
  theme: ISnapshotTheme | null;
}

export interface IProjectSnapshot {
  id: number;
  name: string;
}

export enum SnapshotTemplateEnum {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD = "THIRD",
  FOURTH = "FOURTH",
}

export interface ISnapshotProps {
  template?: SnapshotTemplateEnum;
  html?: string;
  selectedImage?: { url: string; name: string };
  lgSize?: boolean;
  mdSize?: boolean;
  smSize?: boolean;
  ref?: any;
  nugget?: string;
  project?: string;
  htmlContent?: string;
  noImageLabel?: boolean;
  onDownload?: (htmlContent: string) => void;
  updateDownloadLoader?: (value: boolean) => void;
  snapshotTheme?: ISnapshotTheme;
  createdBy?: { id: number; displayName: string };
  createdAt?: string;
}

export interface ISnapshotTheme {
  id: number;
  themeName: string,
  layout: 'default' | 'rightAligned' | 'leftAligned' | 'bottomAligned'
  font: string,
  fontColor: string;
  showDetails: boolean;
  logoUrl: string;
  logoImageText?: string;
  backgroundUrl: string;
  backgroundColor: string;
  backgroundImageText?: string;
  overlayStyle: 'fill' | 'gradient' | 'none' | null,
  overlayColor: string | null;
}