// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-edit-nugget-wrapper {
  padding: 10px 40px;
}
.add-edit-nugget-wrapper .overflow-evidence {
  margin-top: 20px;
  max-height: 200px;
  overflow: auto;
  margin-right: 24px;
}
.add-edit-nugget-wrapper .outline-input-label {
  font-weight: 600;
  font-size: 14px;
  color: #545e6b;
}
.add-edit-nugget-wrapper .character-count {
  color: #545e6b;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  right: 0;
  bottom: -20px;
}
.add-edit-nugget-wrapper .nugget-title {
  margin-right: 20px;
  width: 100%;
}
.add-edit-nugget-wrapper .nugget-node-wrapper {
  margin-top: 14px;
  flex-wrap: wrap;
}
.add-edit-nugget-wrapper .nugget-node-wrapper .nugget-node {
  display: flex;
  align-items: center;
  max-height: 24px;
  padding: 0 8px;
  border-radius: 2px;
  background-color: #545E6B;
  color: #FFFFFF;
  margin: 4px;
  font-size: 14px;
  font-weight: 400;
}
.add-edit-nugget-wrapper .nugget-node-wrapper .nugget-node:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/nuggets/add-edit-nugget-dialog/add-edit-nugget-dialog.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAAJ;AAGE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,aAAA;AADJ;AAKE;EACE,kBAAA;EACA,WAAA;AAHJ;AAME;EACE,gBAAA;EACA,eAAA;AAJJ;AAMI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAJN;AAMM;EACE,eAAA;AAJR","sourcesContent":[".add-edit-nugget-wrapper {\n  padding: 10px 40px;\n\n  .overflow-evidence {\n    margin-top: 20px;\n    max-height: 200px;\n    overflow: auto;\n    margin-right: 24px;\n  }\n\n  .outline-input-label {\n    font-weight: 600;\n    font-size: 14px;\n    color: #545e6b;\n  }\n\n  .character-count {\n    color: #545e6b;\n    font-size: 12px;\n    font-weight: 400;\n    position: absolute;\n    right: 0;\n    bottom: -20px;\n\n  }\n\n  .nugget-title {\n    margin-right: 20px;\n    width: 100%;\n  }\n\n  .nugget-node-wrapper {\n    margin-top: 14px;\n    flex-wrap: wrap;\n\n    .nugget-node {\n      display: flex;\n      align-items: center;\n      max-height: 24px;\n      padding: 0 8px;\n      border-radius: 2px;\n      background-color: #545E6B;\n      color: #FFFFFF;\n      margin: 4px;\n      font-size: 14px;\n      font-weight: 400;\n\n      &:last-child {\n        margin-right: 0;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
