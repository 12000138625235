import React, { useState } from 'react';
import './nugget-card.scss'
import SvgMarketFlipped from "../../../../../../../assets/icons/js/marketFlipped";
import SvgTrash from "../../../../../../../assets/icons/js/Trash";
import { IKnowledgeNode } from "../../../../../../knowledge/model/knowledge-node.interface";
import AddEditNuggetDialog from "../add-edit-nugget-dialog/add-edit-nugget-dialog";
import SnapshotsDialog from "../snapshots-dialog/snapshots-dialog";
import projectEditApi from "../../../../api/project-edit-api";
import { Divider, Dropdown, Empty, Menu } from "antd";
import UserAvatar from "../../../../../../../_shared/components/user-avatar/user-avatar";
import SvgEdit from "../../../../../../../assets/icons/js/Edit";
import SvgDownload from "../../../../../../../assets/icons/js/download";
import SvgInfoCircleFilled from "../../../../../../../assets/icons/js/InfoCircleFilled";
import KeyFindingDetailsDialog from "../key-finding-details-dialog/key-finding-details-dialog";
import AiUserAvatar from "../../../../../../../_shared/components/ai-user-avatar/ai-user-avatar";
import jsPDF from 'jspdf';
import { INugget } from "../../../../../models/nugget.interface";


const NuggetCard = (props: { nugget: INugget, projectId: number, refetchProjectNuggets: () => void }) => {
  const nugget = props.nugget;
  const projectId = props.projectId
  const [isExpanded, setIsExpanded] = useState(false);
  const [addEditNuggetDialog, setAddEditNuggetDialog] = useState<any>({
    open: false,
    nodes: [],
    description: ""
  })
  const [snapshotsDialog, setSnapshotsDialog] = useState<any>({
    visible: false,
    nuggetId: 0
  });

  const [keyFindingDetailsDialog, setKeyFindingDetailsDialog] = useState<any>({
    open: false
  })

  function openAddEditNuggetDialog(nuggetId?: number, nodes?: any[], description?: string): void {
    setAddEditNuggetDialog({
      open: true,
      nuggetId,
      nodes,
      projectId,
      description
    })
  }

  function closeAddEditNuggetDialog(): void {
    setAddEditNuggetDialog({
      open: false,
    })
    props.refetchProjectNuggets();
  }


  function openSnapshotsDialog(nuggetId: number, description: string): void {
    setSnapshotsDialog({
      visible: true,
      nuggetId,
      projectId,
      description
    })
  }

  function closeSnapshotsDialog(): void {
    setSnapshotsDialog({
      visible: false,
    })
  }

  const deleteNugget = (id: number) => {
    projectEditApi.deleteNugget(id).then(() => {
      props.refetchProjectNuggets();
    })
  }


  function openKeyFindingDetailsDialog(keyFinding: any) {
    setKeyFindingDetailsDialog({open: true, keyFinding})
  }

  function closeKeyFindingDetailsDialog() {
    setKeyFindingDetailsDialog({open: false})
  }

  const downloadPdf = (text: string) => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(doc.splitTextToSize(text, 180), 10, 20);
    doc.save("download.pdf");
  };

  return (
    <>
      <div className='nugget-card-wrapper pointer'>
        <div className='row w-100'>
          <div className='nugget-description'>
            {nugget.description}
          </div>
          <div className='action-buttons-wrapper'>
            <Dropdown trigger={["click"]} overlay={
              <Menu>
                <Menu.Item key={"Edit"}
                           onClick={() => openAddEditNuggetDialog(nugget.id, nugget.nodes, nugget.description)}>
                  <div className=" snapshot-icon-button d-flex align-items-center">
                    <SvgEdit width={16} height={16}/> Edit
                  </div>
                </Menu.Item>
                <Menu.Item key={"Details"} onClick={() => openKeyFindingDetailsDialog(nugget)}>
                  <div className=" snapshot-icon-button d-flex align-items-center">
                    <SvgInfoCircleFilled width={16} height={16}/> Details
                  </div>
                </Menu.Item>
                <Menu.Item key={"Snapshots"} onClick={() => openSnapshotsDialog(nugget.id, nugget.description)}>
                  <div className=" snapshot-icon-button d-flex align-items-center" style={{width: 'max-content'}}>
                    <SvgMarketFlipped width={16} height={16}/> Snapshots
                  </div>
                </Menu.Item>
                {/*<Menu.Item key={"Share"}>*/}
                {/*  <div className=" snapshot-icon-button d-flex align-items-center">*/}
                {/*    <SvgShare/> Share*/}
                {/*  </div>*/}
                {/*</Menu.Item>*/}
                <Menu.Item key={"Download"} onClick={() => downloadPdf(nugget.description)}>
                  <div className=" snapshot-icon-button d-flex align-items-center" style={{width: 'max-content'}}>
                    <SvgDownload width={16} height={16}/> Download
                  </div>
                </Menu.Item>
                <Divider style={{marginTop: 0, marginBottom: 0}}/>
                <Menu.Item key={"delete"} onClick={() => deleteNugget(nugget.id)}>
                  <div className=" snapshot-icon-button d-flex align-items-center">
                    <SvgTrash width={16} height={16}/> Delete
                  </div>
                </Menu.Item>
              </Menu>
            }>
              <div className="pointer" data-testid="more-options">
                <i className="bi bi-three-dots-vertical" style={{fontSize: 18}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className='nugget-creator row align-items-center'>
          {nugget.aiGenerated ? <AiUserAvatar size="sm"/> :
            <UserAvatar size={24} user={nugget.createdBy}/>} &nbsp; &nbsp;
          {nugget.aiGenerated ?
            <span>AskIH {nugget.lastUpdatedBy && <span>
              <span> - Last edited by </span>
              <span style={{fontWeight: 400}}>{nugget.lastUpdatedBy}</span>
            </span>}
            </span>
            : nugget.createdBy?.displayName}

        </div>
        <div className="nugget-creator row">

        </div>
        {/*{*/}
        {/*  isExpanded &&*/}
        {/*  <div className="row nugget-node-wrapper"*/}
        {/*       style={{marginLeft: -2}}>*/}
        {/*    {nugget?.nodes.length ? (*/}
        {/*      nugget.nodes.map(*/}
        {/*        (node: IKnowledgeNode, i: number) => {*/}
        {/*          return (*/}
        {/*            <span className="nugget-node" key={i}>{node.name}</span>*/}
        {/*          );*/}
        {/*        }*/}
        {/*      )*/}
        {/*    ) : (*/}
        {/*      <div className="empty-wrapper">*/}
        {/*        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
      {
        addEditNuggetDialog.open && (
          <AddEditNuggetDialog
            open={addEditNuggetDialog.open}
            data={addEditNuggetDialog}
            onCancel={closeAddEditNuggetDialog}
          />
        )
      }
      {
        snapshotsDialog.visible && (
          <SnapshotsDialog
            data={snapshotsDialog}
            onCancel={closeSnapshotsDialog}
            visible={snapshotsDialog.visible}/>
        )
      }
      {
        keyFindingDetailsDialog.open && (
          <KeyFindingDetailsDialog
            open={keyFindingDetailsDialog.open}
            data={keyFindingDetailsDialog}
            onCancel={closeKeyFindingDetailsDialog}/>
        )
      }
    </>
  );
};

export default NuggetCard;
