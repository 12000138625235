import React, { PropsWithChildren, useState } from 'react';
import { Button, Checkbox, Divider, Modal, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import './pop-up-tutorial.scss'

type PopUpTutorialProps = {
  open: boolean;
  onPopUpClose: (checked?: boolean) => void;
  title: string;
}

const PopUpTutorial = ({open, onPopUpClose, title, children}: PropsWithChildren<PopUpTutorialProps>) => {
  const [checked, setChecked] = useState(false);

  return (
    <Modal open={open}
           className='pop-up-tutorial'
           onCancel={() => onPopUpClose()}
           width={800}
           title={<div className="row" style={{gap: 10}}>
             <Tooltip> <i className="bi bi-question-circle-fill"/></Tooltip>
             <span>Help Center</span>
           </div>}
           footer={
             <>
               <Divider style={{marginBottom: 10, marginTop: -20}}/>
               <div className="row justify-space-between">
                 <Checkbox checked={checked}
                           onChange={(value: CheckboxChangeEvent) => setChecked(value.target.checked)}
                           className='checkbox-wrapper'>Don't show
                   this again</Checkbox>
                 <Button onClick={() => onPopUpClose(checked)} type='primary'>Got it</Button>
               </div>
             </>}>
      <div className='pop-up-tutorial-wrapper'>
        <div className='pop-up-tutorial-title'>{title}</div>
        {children}
        {/*<div className='pop-up-tutorial-video'>*/}

        {/*</div>*/}
      </div>
    </Modal>
  );
};

export default PopUpTutorial;
