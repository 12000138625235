import { Empty } from 'antd'
import React from 'react'

const Nuggets = ({nuggets}: any) => {
  if (!nuggets.length) {
    return <div className="w-100 column justify-space-center">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </div>
  }
  return (
    <div className='nuggets-wrapper'>
      {nuggets.map((nugget: any, index: number) => {
        return <div key={index} className='nugget-card relative'>
          <div className='bullet-point absolute'/>
          <div className='nugget-description'>{nugget.description}</div>
          <div className='row nodes-wrapper'>
            {
              nugget.nodes.map((node: any, index: number) => {
                return <div key={index} style={node.selected ? {
                  backgroundColor: "#1b87e6",
                  color: "white"
                } : {backgroundColor: "#d8d8d8", color: "#545e6b"}} className='node'>
                  {node.name}
                </div>
              })
            }
          </div>
        </div>
      })}
    </div>
  )
}

export default Nuggets
