import React from 'react';

const SvgFire = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M5.15564 0.186947C5.27778 1.02042 5.56183 3.74429 4.25 4.75886C4.02432 4.9334 3.79579 5.09764 3.56998 5.25992C2.23766 6.21743 1 7.10691 1 9.6434C1 12.6098 3.15165 16 7.5 16C11.8333 16 14 13.1726 14 9.6434C14 7.16767 11.5625 4.35739 11.0464 5.5618C10.246 7.42984 8.21195 7.01262 8.71875 4.75886C9.20611 2.59156 7.38384 0.609413 5.35881 0.00669903C5.24568 -0.0269692 5.13871 0.0714028 5.15564 0.186947ZM11.5228 7.28635C11.8845 8.04982 12.1845 8.80104 11.8965 9.97705C11.5161 11.5307 9.12187 11.5563 9.28634 9.97573C9.33858 9.47372 9.69825 9.28078 10.119 9.05508C10.6614 8.7641 11.3053 8.41868 11.5228 7.28635ZM3.58844 11.1824C3.25 9.23151 4.31622 8.02126 5.20555 7.01179C5.78034 6.35935 6.28125 5.79077 6.28125 5.16033C7.32585 6.14719 7.16039 7.3362 6.99754 8.50651C6.76289 10.1928 6.53364 11.8403 9.93746 12.7882C7.90621 14.7956 4.02259 13.685 3.58844 11.1824Z"
            fill="#545E6B"/>
      <path
        d="M10.1592 3.167C10.1499 2.73656 10.1378 2.1767 9.87165 1.43971C9.84836 1.37524 9.9102 1.31196 9.97477 1.33677C10.5543 1.55946 11.5038 2.04404 11.7985 2.54887C12.5856 3.89742 11.4084 4.69084 10.5751 4.21854C10.177 3.99292 10.17 3.6674 10.1592 3.167Z"
        fill="#545E6B"/>
    </svg>
  );
};

export default SvgFire;
