import React, { useState } from 'react';
import { Button, Modal, Spin, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import SvgDownload from "../../../../../../assets/icons/js/download";
import FileTypePipe from "../../../../../../_shared/helpers/file-type-pipe";
import { IRepoFile } from "../../../../../repositories/model/repo-file.interface";
import repositoriesApi from "../../../../../repositories/api/repositories-api";
import { useQuery } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { queryClient } from "../../../../../../index";

const RepositoryDialog = (props: {
  data: any,
  onCancel: () => void,
  open: boolean,
  refetchProjectFiles: any
}) => {

  const [selectedRows, setSelectedRows] = useState<any[]>
  ([]);
  const [bulkImportLoader, setBulkImportLoader] = useState(false)

  const columns: ColumnsType<any> = [
    {
      title: selectedRows.length ?
        <div className='selected-rows-title-wrapper'>
          <span>{selectedRows.length} selected</span>
          <div className='selected-rows-btn' onClick={() => importFiles()}> {bulkImportLoader ?
            <Spin indicator={<LoadingOutlined/>}/> :
            <SvgDownload color={'#1b87e6'}/>}
            &nbsp;Import
          </div>
        </div> : "Filename",
      render: (file: { name: string }) => {
        const nameArr = file.name.split(".");
        return <div className="row align-items-center" style={{gap: 10}}>
          <span>
            {
              nameArr.length > 1 &&
              <FileTypePipe width={20} height={20} value={nameArr[nameArr.length - 1] as any}/>
            }
          </span>
          <span>{file.name}</span>
        </div>
      }
      ,
      dataIndex: 'file',
      // sorter: !selectedRows.length
    },
    {
      title: <span className='column-title-to-be-hided'>Owner</span>,
      dataIndex: 'owner',
      //sorter: !selectedRows.length,
      width: '30%',
      render: (owner: { firstName?: string, lastName?: string, emailAddress: string }) => <span>{
        owner.firstName && owner.lastName ? `${owner.firstName} ${owner.lastName}` : owner.emailAddress
      }</span>
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "right",
      render: (id: number, record: IRepoFile) => (
        <div className="action-hover-column row">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Import File">
              <button className="icon-button project-icon-button" onClick={() => importFile(record)}>
                <SvgDownload/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ]

  const files = useQuery(`files-${props.data.repositoryId}`, () => repositoriesApi.getRepositoryFiles(props.data.repositoryId))

  function importFiles() {
    setBulkImportLoader(true)
    repositoriesApi.assignFilesToProject(props.data.projectId, selectedRows.map((row) => row.fileId)).then(() => {
      setBulkImportLoader(false)
      setSelectedRows([])
      files.refetch()
      queryClient.invalidateQueries(['project', String(props.data.projectId)])
      props.refetchProjectFiles()
    })
  }

  function importFile(file: IRepoFile) {
    repositoriesApi.assignFilesToProject(props.data.projectId, [file.fileId]).then(() => {
      setSelectedRows([])
      queryClient.invalidateQueries(['project', String(props.data.projectId)])
      files.refetch()
      props.refetchProjectFiles();
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows);
      queryClient.invalidateQueries(['project', String(props.data.projectId)])
      props.refetchProjectFiles();
    },
  };

  return (
    <Modal title="Import from repository"
           open={props.open}
           width={1400}
           onCancel={props.onCancel}
           footer={[<Button onClick={props.onCancel} type='primary'>Close</Button>]}>
      <div>
        <div
          className={selectedRows.length ? 'file-repository-table file-repository-table-selected-rows' : 'file-repository-table'}>
          <Table
            rowKey={"id"}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            scroll={{y: 400}}
            pagination={false}
            dataSource={files?.data ? files.data.content : []}
            columns={columns}/>
        </div>
      </div>
    </Modal>
  );
};

export default RepositoryDialog;
