import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { usePrevious } from "../../../../../../../core/hooks/use-previous";
import { IOwner } from "../../../../../models/owner.interface";

const DetailsBlank = (props: any) => {
  const [update, setUpdate] = useState<boolean>(false);
  const [owners, setOwners] = useState<IOwner[]>([]);
  const [organizer, setOrganizer] = useState<number>();
  const [description, setDescription] = useState<any>();
  const [title, setTitle] = useState(props?.details.title);

  const prevOrganizer = usePrevious<any>(organizer);
  const prevDescription = usePrevious<string>(description);

  useEffect(() => {
    const details = props.details;
    setOwners(props.teamMembers ? props.teamMembers : []);
    setOrganizer(details.organizerId);
    setDescription(details.description ? details.description : "");
  }, [props.details])

  useEffect(() => {
    if (valuesHasChanged() && update) {
      const payload = {
        projectId: props.projectId,
        interviewId: props.interviewId,
        organizerId: organizer,
        description: description,
        title: title
      }
      props.socket.emit('editDetails', {...payload})
      setUpdate(false);
    } else {
      return
    }
  }, [organizer, description, title])

  useEffect(() => {
    if (props.details?.title) {
      const details = props.details;
      setTitle(details.title);
      setUpdate(true);
    }
  }, [props.details?.title]);


  function valuesHasChanged(): boolean {
    return !(prevOrganizer === organizer && prevDescription === description);
  }

  return (
    <div className="interview-form">
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Organizer</span>
        <Select value={organizer}
                onChange={(id: number) => {
                  setUpdate(true);
                  setOrganizer(id);
                }}>
          {owners?.map((owner: any) => {
            return (
              owner.id &&
              <Select.Option key={owner.id}
                             value={owner.id}>
                {owner?.name}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Desciption</span>
        <DebounceInput
          debounceTimeout={600}
          onChange={(e) => {
            setUpdate(true);
            setDescription(e.target.value)
          }}
          value={description}
          element="textarea"
          placeholder="Description"
          className="outline-input"
        />
      </div>
    </div>
  );
};

export default DetailsBlank;
