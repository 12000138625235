// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key-metrics-input {
  margin-bottom: 32px;
}
.key-metrics-input .outline-input-wrapper {
  margin-right: 16px;
}
.key-metrics-input .input-options {
  width: 200px;
  height: 32px;
  display: flex;
  align-self: flex-end;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
}
.key-metrics-input .input-options .option {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.key-metrics-input .input-options .option:hover > svg > g > g > rect {
  fill: #1b87e6 !important;
}
.key-metrics-input .input-options .option:hover > svg > g > g > path {
  fill: #ffffff !important;
}
.key-metrics-input .input-options .option:hover > svg > g > g > rect:last-child {
  fill: #ffffff !important;
}
.key-metrics-input .input-options .option:first-child {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/project-management/key-metrics/key-metric-input/key-metrics-input.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAAN;AAEM;EACE,wBAAA;AAAR;AAGM;EACE,wBAAA;AADR;AAIM;EACE,wBAAA;AAFR;AAKM;EACE,kBAAA;AAHR","sourcesContent":[".key-metrics-input {\n  margin-bottom: 32px;\n\n  .outline-input-wrapper {\n    margin-right: 16px;\n  }\n\n  .input-options {\n    width: 200px;\n    height: 32px;\n    display: flex;\n    align-self: flex-end;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 0 16px;\n\n    .option {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      cursor: pointer;\n\n      &:hover > svg > g > g > rect {\n        fill: #1b87e6 !important;\n      }\n\n      &:hover > svg > g > g > path {\n        fill: #ffffff !important;\n      }\n\n      &:hover > svg > g > g > rect:last-child {\n        fill: #ffffff !important;\n      }\n\n      &:first-child {\n        margin-right: 15px;\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
