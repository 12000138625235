import React from "react";
import { DraftDecoratorComponentProps } from "draft-js";

export const PreviewLinkStrategy = (props: DraftDecoratorComponentProps | any) => {
  let url: any;
  if (props.entityKey != null) {
    url = props.contentState.getEntity(props?.entityKey).getData().url;
  } else {

    return null;
  }


  return (
    <a rel="noopener noreferrer" target="_blank" href={url ? url : null} onClick={() => window.open(url, "_blank")}>
      {props.children}
    </a>
  );

};
