import React from 'react';
import './external-tool-tile.scss'
import { Dropdown, MenuProps } from "antd";
import DaysLeftDatePipe from "../../../../../../../_shared/helpers/days-left-date-pipe";
import { IExternalTool } from "../../model/external-tool.interface";
import SvgEdit from "../../../../../../../assets/icons/js/Edit";
import SvgTrash from "../../../../../../../assets/icons/js/Trash";
import SvgExternalTool from "../../../../../../../assets/icons/js/ExternalTool";


const ExternalToolTile = (props: {
  externalTool: IExternalTool,
  openConfirmationDialog: (externalTool: IExternalTool) => void,
  openExternalLinkDialog: (externalTool?: IExternalTool) => void,
}) => {
  const {externalTool, openConfirmationDialog, openExternalLinkDialog} = props;

  function openExternalLink(link: string): void {
    if (link.startsWith('http') || link.startsWith('www')) {
      window.open(link, '_blank');
    } else {
      window.open(`https://${link}`, '_blank');
    }
  }

  const menuItems: MenuProps['items'] = [
    {
      key: "edit",
      label: (
        <div className="snapshot-icon-button d-flex align-items-center"
             onClick={() => openExternalLinkDialog(externalTool)}>
          <SvgEdit/> Edit
        </div>
      ),
    },
    {
      key: "delete",
      label: (
        <div className="snapshot-icon-button d-flex align-items-center"
             onClick={() => openConfirmationDialog(externalTool)}>
          <SvgTrash/> Delete
        </div>
      ),
    },
  ];

  return (
    <div className="external-tool-tile-wrapper">
      <div className="external-tool-tile-header justify-space-between">
        <div className="external-tool-tile-name">
          {externalTool.logoUrl ? <div>
            <img src={externalTool.logoUrl} style={{objectFit: "cover", width: 15, height: 15}} alt=""/>
          </div> : <SvgExternalTool color={"#1b87e6"}/>}

          <span className="pointer"
                onClick={() => openExternalLink(externalTool.link)}>{externalTool.title}</span>
        </div>
        <div className="external-tool-tile-dropdown">
          <Dropdown trigger={["click"]} menu={{items: menuItems}}>
            <div className="pointer" data-testid={"more-options"}>
              <i className="bi bi-three-dots-vertical" style={{fontSize: 18}}/>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="external-tool-tile-description">
        {externalTool.description}
      </div>
      <div className="external-tool-tile-details">
        <div className="row">
          <div className="external-tool-tile-detail-title">Owner</div>
          <div className="external-tool-tile-detail-value">{externalTool?.createdBy?.displayName}</div>
        </div>
        <div className="row">
          <div className="external-tool-tile-detail-title">Date Created</div>
          <div
            className="external-tool-tile-detail-value">{externalTool.createdAt && DaysLeftDatePipe(externalTool.createdAt)}</div>
        </div>
        <div className="row">
          <div className="external-tool-tile-detail-title">Type</div>
          <div className="external-tool-tile-detail-value">{externalTool.type}</div>
        </div>
        <div className="row">
          <div className="external-tool-tile-detail-title">Status</div>
          <div className="external-tool-tile-detail-value">{externalTool.status}</div>
        </div>
      </div>
    </div>
  );
};

export default ExternalToolTile;
