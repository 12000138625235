// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-metric-input {
  margin-bottom: 32px;
}
.program-metric-input .outline-input-wrapper {
  margin-right: 16px;
}
.program-metric-input .metric-input-options {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 16px;
}
.program-metric-input .metric-input-options .option {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: min-content;
}
.program-metric-input .metric-input-options .option:hover > svg > g > g > rect {
  fill: #1b87e6 !important;
}
.program-metric-input .metric-input-options .option:hover > svg > g > g > path {
  fill: #ffffff !important;
}
.program-metric-input .metric-input-options .option:hover > svg > g > g > rect:last-child {
  fill: #ffffff !important;
}
.program-metric-input .metric-input-options .option:first-child {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/taxonomy/programs/add-edit-programs-dialog/group-metrics-input/program-metric-input/program-metric-input.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,2BAAA;EACA,qBAAA;EACA,eAAA;AAAJ;AAEI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AAAN;AAEM;EACE,wBAAA;AAAR;AAGM;EACE,wBAAA;AADR;AAIM;EACE,wBAAA;AAFR;AAKM;EACE,kBAAA;AAHR","sourcesContent":[".program-metric-input {\n  margin-bottom: 32px;\n\n  .outline-input-wrapper {\n    margin-right: 16px;\n  }\n\n  .metric-input-options {\n    height: 50px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-end;\n    padding: 0 16px;\n\n    .option {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      cursor: pointer;\n      height: min-content;\n\n      &:hover > svg > g > g > rect {\n        fill: #1b87e6 !important;\n      }\n\n      &:hover > svg > g > g > path {\n        fill: #ffffff !important;\n      }\n\n      &:hover > svg > g > g > rect:last-child {\n        fill: #ffffff !important;\n      }\n\n      &:first-child {\n        margin-right: 15px;\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
