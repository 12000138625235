import * as React from "react";

const SvgStrike = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1019.000000, -208.000000)">
          <g id="Strike" transform="translate(1019.000000, 208.000000)">
            <rect id="Rectangle-Copy-12" x="0" y="0" width="16" height="16"/>
            <path
              d="M12,10 L12,11 C12,12.5261424 10.5261424,14 8.5,14 C6.5437246,14 5.10230205,12.6260352 5.00521973,11.157632 L5,11 L6,11 C6,11.9738576 7.02614237,13 8.5,13 C9.91244689,13 10.9137109,12.057588 10.9946974,11.1219265 L11,11 L11,10 L12,10 Z M8.5,2 C10.4562754,2 11.8976979,3.37396478 11.9947803,4.842368 L12,5 L11,5 C11,4.02614237 9.97385763,3 8.5,3 C6.83382341,3 6,3.72959548 6,5 C6,5.79705805 6.2371686,6.45640104 6.7167456,7.00080532 L15,7 L15,8 L1,8 L1,7 L5.496437,7.00138803 C5.16612317,6.41308174 5,5.74315231 5,5 C5,3.14540452 6.30903373,2 8.5,2 Z"
              id="Combined-Shape" fill="#545E6B" fillRule="nonzero"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgStrike;
