import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  ContentBlock,
  ContentState,
  convertFromRaw,
  DraftEditorCommand,
  EditorState,
  RichUtils,
  SelectionState
} from "draft-js";
import "draft-js/dist/Draft.css";
import '@draft-js-plugins/mention/lib/plugin.css';
import './draft.scss';
import Toolbar from './components/toolbar';
import { usePrevious } from '../../../core/hooks/use-previous';
import {
  KnowledgeGraphsDialog
} from "../../../pages/projects/project-edit/tabs/insights/nuggets/knowledge-graphs-dialog/knowledge-graphs-dialog";
import { extendSelectionByData, findEntityInSelection, getEntities } from './components/helpers/entity';
import { assignInterviewTaskEntity } from './components/helpers/assign-interview-task-entity';
import Editor from '@draft-js-plugins/editor';
import { compositeDecorator } from './components/decorators';
import { FloatingMenu } from './components/floating-menu/floating-menu';
import createMentionPlugin, { defaultSuggestionsFilter, MentionSuggestions } from '@draft-js-plugins/mention';
// @ts-ignore
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import { assignNodeEntity } from './components/helpers/assign-node-entity';
import { handleOnMouseUp } from './components/helpers/handle-on-mouse-up';
import CSS from 'csstype';
import { onShowFloatingMenu } from './components/floating-menu/on-show-floating-menu';
import { removeTaskFromEditorHelper } from './components/helpers/remove-task-from-editor-helper';
import { isGuide } from './components/helpers/is-guide';
import { IFlagType } from '../../../pages/settings/settings-tabs/interview-labs-settings/model/flag-type.interface';
import { EvidenceDialog } from './components/evidence-dialog/evidence-dialog';
import { assignFlagsEntity } from './components/helpers/assign-flags-entity';
import { manageHighlights } from './components/helpers/manage-highlights';
import { assignEvidenceEntity } from './components/helpers/assign-evidence-entity';
import { ITask } from '../../../pages/settings/settings-tabs/interview-labs-settings/model/task.interface';

export const DraftJs = forwardRef((props: any, ref: any) => {
  let textEditor: any = useRef(null);
  let editor: any = useRef(null);

  const mentionPlugin = createMentionPlugin(({
    mentionPrefix: '@',
    supportWhitespace: true,
    mentionComponent(mentionProps) {
      return (
        <span
          id={mentionProps?.entityKey}
          className={mentionProps.className}
          onClick={() => console.log('')}>
            {mentionProps.children}
          </span>
      );
    },
  }));

  const blockBreakoutPlugin = createBlockBreakoutPlugin();

  const initialState = props.content
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.content)))
    : EditorState.createEmpty();

  const [editorState, setEditorState] = React.useState<EditorState>(() => initialState);
  const prevEditorState = usePrevious<EditorState>(editorState);
  const [open, setOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState(JSON.parse(JSON.stringify(props?.teamMembers ? props.teamMembers : [])));

  const [{plugins, MentionSuggestions}] = useState(() => {
    const {MentionSuggestions} = mentionPlugin;
    const plugins = [mentionPlugin, blockBreakoutPlugin];
    return {plugins, MentionSuggestions}
  });


  const [nuggets, setNuggets] = useState<any[]>(props?.nuggets);
  const [isFocus, setIsFocus] = React.useState<boolean>(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState<boolean>(false);
  const [nodeButtonPosition, setNodeButtonPosition] = useState<CSS.Properties>();
  const [knowledgeGraphDialog, setKnowledgeGraphDialog] = useState(
    {
      visible: false,
      title: '',
      index: 0,
      selectedNodes: '',
      entityKey: ''
    },
  );
  const [evidenceDialog, setEvidenceDialog] = useState(
    {
      visible: false,
      title: '',
      index: 0,
      selectedText: '',
      selectedNuggets: '',
      entityKey: '',
      interviewId: '',
      entityMapId: ''
    },
  );

  useEffect(() => {
    textEditor = isGuide(props?.isGuide, textEditor);
  }, []);

  useEffect(() => {
    if (props?.content) {
      const timeout = setTimeout(() => {
        const rawContentState: ContentState = convertFromRaw(JSON.parse(props.content));
        onSetEditorState(EditorState.push(editorState, rawContentState, 'adjust-depth'));
      }, 100);

      return () => {
        clearTimeout(timeout);
      }
    }
  }, [props?.content]);

  useEffect(() => {
    manageHighlights(editorState);

    if (editorState) {
      if (prevEditorState?.getCurrentContent() !== editorState.getCurrentContent()) {
        if (!!props.updateEditorState) {
          props.updateEditorState(editorState);
        }
      }
    }

    onFloatingMenu();
  }, [editorState]);

  useImperativeHandle(ref, () => ({
    assignInterviewTaskEntityToEditor(task: ITask) {
      onSetEditorState(assignInterviewTaskEntity(task, editorState));
    },
    removeInterviewTaskEntityToEditor(uuid: number) {
      onSetEditorState(removeTaskFromEditorHelper(editorState, uuid));
    },
    onOpenEvidenceDialog(evidenceEntityKey: string) {
      openEvidenceDialog(true, evidenceEntityKey);
    },
    onAssignEvidenceToSelectedText(evidenceDialogData: any) {
      const data: any = {
        selectedNodes: [],
        entityKey: evidenceDialogData.entityData.entityKey,
        entityMapId: evidenceDialogData.entityMapId,
      }

      assignEvidenceToSelectedText(data);
    },
    onAssignFlagsToSelectedText(data: { selectedFlags: IFlagType[], entityKey?: string, entityMapId?: string }) {
      assignFlagsToSelectedText(data);
    }
  }));

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({value}: { value: string }) => {
    setTeamMembers(defaultSuggestionsFilter(value, teamMembers));
  }, []);

  function keyBindingFn(e: any): any {
    if (e.key === '#') {
      return '';
    }

    if (e.keyCode === 13) {
      if (e.nativeEvent.shiftKey) {
        // shift + enter
      }
    }
  }

  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const onSetEditorState = (editorState: EditorState) => {
    setEditorState(editorState);
  }

  const onBlur = () => {
    setIsFocus(false);
    setNodeButtonPosition({display: 'none'});
  }

  const onFocus = () => {
    setIsFocus(true);
  }

  const assignFlagsToSelectedText = (data: {
    selectedFlags: IFlagType[],
    entityKey?: string,
    entityMapId?: string
  }) => {
    setEditorState(assignFlagsEntity(editorState, data));
  }

  const assignNodeToSelectedText = (data: { selectedNodes: any[], entityKey?: string }) => {
    onSetEditorState(assignNodeEntity(editorState, data));
    closeDialogs();
  }

  const openKnowledgeGraphDialog = (clicked?: boolean, entityKey?: string) => {
    let selectedNodes: any = '';

    if (clicked && entityKey) {
      const entity: any = getEntities(editorState, 'NODE', entityKey);
      const newEditorState: EditorState = extendSelectionByData(editorState, entity);
      const contentState = newEditorState.getCurrentContent();
      const nodeContent = contentState.getEntity(entityKey).getData().nodes;
      selectedNodes = nodeContent.nodes;

      setKnowledgeGraphDialog({
        ...knowledgeGraphDialog,
        selectedNodes: selectedNodes,
        visible: true,
        entityKey
      });

    } else {
      const entity: any = findEntityInSelection(editorState, "NODE");

      if (entity && entity.entity.data.nodes?.nodes) {
        selectedNodes = entity.entity.data.nodes.nodes;
      }

      setTimeout(() => {
        setKnowledgeGraphDialog({
          ...knowledgeGraphDialog,
          selectedNodes: selectedNodes,
          visible: true
        });
      }, 500);
    }
  }

  const assignEvidenceToSelectedText = (data: {
    selectedNuggets: any[],
    entityKey?: string,
    entityMapId?: string,
    newNuggets: any[]
  }) => {
    if (data?.newNuggets?.length) {
      setNuggets(data.newNuggets);
    }

    onSetEditorState(assignEvidenceEntity(editorState, data));
    closeDialogs();
  }

  const openEvidenceDialog = (clicked?: boolean, entityKey?: string) => {
    let selectedNuggets: any = '';

    if (clicked && entityKey) {
      const entity: any = getEntities(editorState, 'EVIDENCE', entityKey);
      const newEditorState: EditorState = extendSelectionByData(editorState, entity);
      const contentState = newEditorState.getCurrentContent();
      const evidenceContent = contentState.getEntity(entityKey).getData();
      selectedNuggets = evidenceContent.nuggets;

      setEvidenceDialog({
        ...evidenceDialog,
        selectedText: evidenceContent.selectedText,
        selectedNuggets,
        visible: true,
        interviewId: props?.interviewId,
        entityKey,
        entityMapId: evidenceContent?.entityMapId
      });

    } else {
      const entity: any = findEntityInSelection(editorState, "EVIDENCE");

      if (entity && entity.entity?.data?.evidence?.nuggets) {
        selectedNuggets = entity.entity.data.nuggets;
      }

      const selectionState: SelectionState = editorState.getSelection();
      const anchorKey: string = selectionState.getAnchorKey();
      const currentBlock: ContentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);
      const start: number = selectionState.getStartOffset();
      const end: number = selectionState.getEndOffset();
      const selectedText: string = currentBlock.getText().slice(start, end);

      setTimeout(() => {
        setEvidenceDialog({
          ...evidenceDialog,
          selectedText,
          selectedNuggets,
          interviewId: props?.interviewId,
          visible: true
        });
      }, 500);
    }
  }

  const closeDialogs = () => {
    setKnowledgeGraphDialog({...knowledgeGraphDialog, visible: false});
    setEvidenceDialog({...evidenceDialog, visible: false});
  }

  const onMouseUp = () => {
    if (props.readOnly) {
      return;
    }

    setNodeButtonPosition(handleOnMouseUp());
    !isFocus && setIsFocus(true);
  };

  const onFloatingMenu = () => setShowFloatingMenu(onShowFloatingMenu(editorState, isFocus, props?.disableNodes))

  return (
    <div className={props.readOnly ? "text-editor text-editor-read-only" : "text-editor"}
         ref={(r) => textEditor = r}
         is-focus={JSON.stringify(isFocus)}
         onKeyUp={onMouseUp}
         onMouseUp={onMouseUp}>
      <div className="row justify-space-between align-items-center">
        <div className="text-editor-toolbar row">
          {
            !props.readOnly &&
              <Toolbar editorState={editorState}
                       disableNodes={props?.disableNodes !== true}
                       onSetEditorState={onSetEditorState}
                       flags={props?.flags}
                       nodes={props?.nodes}
                       highlightToolbar={props?.disableNodes !== true}
                       openKnowledgeGraphDialog={openKnowledgeGraphDialog}
                       noteType={props.noteType}/>
          }
        </div>
      </div>

      <div id="DraftEditor-root">
        <Editor
          ref={editor}
          editorKey={'editor'}
          editorState={editorState}
          onChange={setEditorState}
          readOnly={props.readOnly}
          onBlur={onBlur}
          decorators={[compositeDecorator(props.readOnly, openKnowledgeGraphDialog, openEvidenceDialog, {...plugins})]}
          onFocus={onFocus}
          plugins={plugins}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          customStyleMap={{
            SELECTED: {
              background: '#FFD700',
            },
          }}/>
      </div>

      {
        props.mention &&
          <MentionSuggestions
              open={open}
              onOpenChange={onOpenChange}
              suggestions={teamMembers}
              onSearchChange={onSearchChange}
          />
      }

      {knowledgeGraphDialog.visible &&
          <KnowledgeGraphsDialog data={knowledgeGraphDialog}
                                 onConfirm={assignNodeToSelectedText}
                                 onCancel={closeDialogs}/>
      }

      {showFloatingMenu &&
          <FloatingMenu noteType={props.noteType}
                        nodeButtonPosition={nodeButtonPosition}
                        editorState={editorState}
                        openKnowledgeGraphDialog={openKnowledgeGraphDialog}
                        openEvidenceDialog={openEvidenceDialog}
                        assignFlagsToSelectedText={assignFlagsToSelectedText}
                        flags={props?.flags}/>
      }

      {evidenceDialog.visible &&
          <EvidenceDialog data={evidenceDialog}
                          nuggets={nuggets}
                          project={props?.project}
                          onConfirm={assignEvidenceToSelectedText}
                          onCancel={closeDialogs}/>
      }
    </div>
  );
});
