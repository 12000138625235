import React, { useState } from 'react';
import { Loader } from '../../../../../_shared/components/loader/loader';
import './files.scss';
import { ChangeDescriptionDialog } from './change-description-dialog/change-description-dialog';
import { IFile } from '../../../../../_shared/model/files.Interface';
import ProjectEditApi from '../../api/project-edit-api';
import FilesDropzone from "./files-dropzone/files-dropzone";
import { Button, Checkbox, CheckboxProps, Dropdown, Empty, MenuProps, notification, Select } from "antd";
import FileCard from "./file-card/file-card";
import { useQuery } from "react-query";
import { queryClient } from "../../../../../index";
import SvgArrowDown from "../../../../../assets/icons/js/ArrowDown";
import repositoriesApi from "../../../../repositories/api/repositories-api";
import SvgFolder from "../../../../../assets/icons/js/Folder";
import RepositoryDialog from "./repository-dialog/repository-dialog";
import SvgAddBig from "../../../../../assets/icons/js/addBig";
import Pagination from "../../../../../_shared/components/pagination/pagination";
import AnalyzeChat from "../../../../surveys/survey-details/components/analyze-chat/analyze-chat";
import SvgTrash from "../../../../../assets/icons/js/Trash";
import SvgDownload from "../../../../../assets/icons/js/download";
import { ConfirmationDialog } from "../../../../../_shared/components/confirmation-dialog/confirmation-dialog";
import { delay } from "../../../../../_shared/helpers/delay";

type IProps = {
  projectId: number
}

export function Files({projectId}: IProps): JSX.Element {
  const [changeDescription, setChangeDesciption] = useState<any>({visible: false});
  const [filesFilter, setFilesFilter] = useState<string>('all-files');
  const [loading, setLoading] = useState(true);
  const [showNoFiles, setShowNoFiles] = useState(true);
  const [page, setPage] = useState(1);
  const [menuItems, setMenuItems] = useState<MenuProps['items']>();
  const [repositoryDialog, setRepositoryDialog] = useState<any>({open: false});
  const [fileIDs, setFileIDs] = useState<number[]>([]);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<any>({
    visible: false,
  });

  let params = {
    page,
    size: 8,
    types: filesFilter === 'all-files' ? undefined : filesFilter
  }

  const files = useQuery([`files${projectId}`, projectId, params],
    () => ProjectEditApi.loadFiles(projectId, params), {
      onSuccess: (data: any) => {
        if (filesFilter === 'all-files' && (!data || data?.content.length === 0)) {
          setLoading(true)
          setShowNoFiles(true);
        }

        if (data?.content.length >= 1) {
          setShowNoFiles(false);
        }

        setLoading(false);
      }
    });

  const repositories = useQuery('repositories', () => repositoriesApi.getRepositories({
      page: 1,
      pageSize: 9999
    }),
    {
      onSuccess: data => setMenuItems(data.content.map((repo) => {
        return {
          key: "" + repo.id, label:
            <div className="row align-items-center"><SvgFolder/> &nbsp; {repo.name} </div>
        }
      }))
    }
  )

  const downloadFile = (file: IFile): void => {
    ProjectEditApi.downloadFile(file.fileId)
      .then((response: any) => {
        const filenamePath: string = response;
        const fileName: string = `${file.name.toLowerCase()}`
        const downloadElement = document.createElement('a');
        document.body.appendChild(downloadElement); //required in FF, optional for Chrome
        downloadElement.href = filenamePath;
        downloadElement.download = fileName;
        downloadElement.target = "_self";
        downloadElement.click();
      });
  }

  const bulkDownloadFiles = async () => {
    const filesSelected: any = fileIDs.map((fileId) => {
      if (files.data) {
        return files.data.content.find((file: any) => file.fileId === fileId);
      }
      return null;
    });

    const fileUrls = await Promise.all(filesSelected.map((file: any) => {
      return ProjectEditApi.downloadFile(file.fileId);
    }));


    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    for (let fileUrl of fileUrls) {
      if (typeof fileUrl === "string") {
        const downloadElement = document.createElement('a');
        downloadElement.href = fileUrl;
        downloadElement.download = fileUrl;
        downloadElement.target = "_self";
        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);

        await delay(1000);
      }
    }
  };


  const openDeleteConfirmationDialog = (fileIDs: number[]) => {

    const filesSelected: any[] = fileIDs.map((fileId) => {
      if (files.data) {
        return files.data.content.find((file: any) => file.fileId === fileId);
      }
      return null;
    });


    setDeleteConfirmationDialog({
      visible: true,
      ids: filesSelected.map((file) => file.id),
      okText: 'Delete',
      title: 'Delete file',
      titleSec: `You\'re about to delete ${fileIDs.length} files`,
      description: 'Removing this files will permanently delete theme from the project. Are you sure you want to proceed?',
    })
  }

  const onOkDeleteConfirmationDialog = ({modal, ids}: { modal: boolean, ids: number[] }) => {
    if (modal) {
      bulkDeleteFiles(ids);
      closeDeleteConfirmationDialog();
      setFileIDs([])
    }
  }

  const closeDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog({
      visible: false,
      ids: []
    })
  }

  const deleteFile = (id: number): void => {
    ProjectEditApi.deleteFiles([id])
      .then(() => {
        queryClient.invalidateQueries([`files${projectId}`, projectId, params])
        queryClient.invalidateQueries(['project', String(projectId)])
      })
  }

  const bulkDeleteFiles = (ids: number[]): void => {
    ProjectEditApi.deleteFiles(ids)
      .then(() => {
        queryClient.invalidateQueries([`files${projectId}`, projectId, params])
        queryClient.invalidateQueries(['project', String(projectId)])
      })
  }

  const markAsPrivate = (id: number, privacy: 0 | 1): void => {
    ProjectEditApi.markAsPrivate(id, privacy).then(() => {
      queryClient.invalidateQueries([`files${projectId}`, projectId, params])
      notification.open({
        message: !privacy ? "File is marked as private!" : "File is marked as public!",
        type: "info",
        className: "infobox-none",
        duration: 0.8,
        placement: "top",
      });
    })
  }

  const openChangeDescriptionDialog = (file: IFile,): void => {
    setChangeDesciption({
      visible: true,
      file: file,
    })
  }

  const onCancel = (): void => {
    setChangeDesciption({
      visible: false
    })
  }

  const handleChangeDescription = (id: number, description: string): void => {
    ProjectEditApi.descriptionChange(projectId, id, description)
      .then(() => {
        queryClient.invalidateQueries([`files${projectId}`, projectId, params])
        onCancel();
      });
  }

  const openRepositoryDialog = (id: string) => {
    setRepositoryDialog({open: true, projectId: projectId, repositoryId: parseInt(id)})
  }

  const closeRepositoryDialog = () => {
    setRepositoryDialog({open: false})
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    openRepositoryDialog(e.key)
  }

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const updateFileIDs = (id: number) => {
    if (fileIDs.includes(id)) {
      setFileIDs(fileIDs.filter((fileId) => fileId !== id))
    } else {
      setFileIDs([...fileIDs, id])
    }
  }

  const indeterminate = fileIDs.length > 0 && fileIDs.length < files.data?.content?.length;
  const checkAll = files.data?.content?.length === fileIDs.length;
  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setFileIDs(e.target.checked ? files.data.content.map((file: any) => file.fileId) : []);
  };

  return (
    <>
      <div className="files">
        <div className='file-library-title'>File Library</div>
        <div className="row" style={{gap: 20, height: 360}}>
          <div className="row" style={{width: "70%"}}>
            <div className='file-library-wrapper'>
              <div className='file-library-header'>
                <div className="row align-items-center" style={{gap: 10}}>
                  {
                    fileIDs.length > 0 ?
                      <>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                          {fileIDs.length} selected
                        </Checkbox>
                        <div className='selected-rows-btn'
                             onClick={bulkDownloadFiles}>
                          <SvgDownload color={'#1b87e6'}/>
                          &nbsp;Download
                        </div>
                        <div className='selected-rows-btn'
                             onClick={() => openDeleteConfirmationDialog(fileIDs)}>
                          <SvgTrash color={'#1b87e6'}/>
                          &nbsp;Delete
                        </div>
                      </> :
                      <>
                        <FilesDropzone
                          params={params}
                          projectId={projectId}
                          refetchProjectFiles={files.refetch}
                        />
                        <Dropdown menu={menuProps} trigger={["click"]}>
                          <Button style={{width: 200}} type={"ghost"}
                                  icon={<span className={"anticon"}><SvgAddBig width={14} height={14}
                                                                               color={"#1b87e6"}/></span>}>
                            Import from repository
                          </Button>
                        </Dropdown>
                      </>
                  }

                </div>
                <div className='file-library-filter row ' style={{gap: 10}}>

                  <Select
                    style={{width: 120}}
                    value={filesFilter}
                    suffixIcon={<SvgArrowDown/>}
                    onChange={(value: string) => setFilesFilter(value)}>
                    <Select.Option key={'all-files'} value={'all-files'}>
                      All Files
                    </Select.Option>
                    <Select.Option key={'IMAGE'} value={'IMAGE'}>
                      Image
                    </Select.Option>
                    <Select.Option key={'VIDEOAUDIO'} value={'VIDEOAUDIO'}>
                      Video/Audio
                    </Select.Option>
                    <Select.Option key={'DOCUMENT'} value={'DOCUMENT'}>
                      Documents
                    </Select.Option>
                  </Select>
                  {
                    files.data?.total > 0 &&
                    <Pagination
                      currentPage={page}
                      totalItems={files.data?.total}
                      pageSize={params.size}
                      onChange={(page: number) => setPage(page)}/>
                  }
                </div>
              </div>
              <div>
                {
                  files.isLoading ? <Loader/> :
                    files.data?.total === 0 ?
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                      <div className='file-library'>
                        {
                          files.data?.content?.map((file: any, i: number) => {
                            return <FileCard key={i}
                                             file={file}
                                             handleChangeDescription={handleChangeDescription}
                                             openChangeDescriptionDialog={openChangeDescriptionDialog}
                                             downloadFile={downloadFile}
                                             markAsPrivate={markAsPrivate}
                                             deleteFile={deleteFile}
                                             isSelected={fileIDs.includes(file.fileId)}
                                             updateFileIDs={updateFileIDs}
                            />
                          })
                        }
                      </div>
                }


              </div>
            </div>
          </div>
          <div style={{maxHeight: "100%", width: "30%"}}>
            <AnalyzeChat projectId={projectId} fileIDs={fileIDs}/>
          </div>
        </div>
      </div>
      {
        changeDescription.visible &&
        <ChangeDescriptionDialog data={changeDescription}
                                 onCancel={onCancel}
                                 handleChangeDescription={handleChangeDescription}/>
      }
      {
        repositoryDialog.open &&
        <RepositoryDialog
          open={repositoryDialog.open}
          data={repositoryDialog}
          onCancel={closeRepositoryDialog}
          refetchProjectFiles={files.refetch}
        />
      }
      {deleteConfirmationDialog.visible &&
        <ConfirmationDialog data={deleteConfirmationDialog}
                            onConfirm={onOkDeleteConfirmationDialog}
                            onCancel={closeDeleteConfirmationDialog}/>
      }
    </>
  );
}
