import React from 'react'

const SvgHourGlass = (props: any) => {
  return (
    <svg viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
      <path
        d="M8.25 12.75L8.24375 9L5.75 6.5L8.24375 3.99375L8.25 0.25H0.75V4L3.25 6.5L0.75 8.99375V12.75H8.25ZM2 3.6875V1.5H7V3.6875L4.5 6.1875L2 3.6875Z"
        fill={props.color ? props.color : '#545E6B'}/>
    </svg>
  )
}

export default SvgHourGlass
