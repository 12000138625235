import * as React from "react";

const SvgConversation = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.999 5H14a1 1 0 01.993.883L15 6v9l-2-2H6a1 1 0 01-.993-.883L5 12v-1h7a1 1 0 00.993-.883L13 10l-.001-5H14h-1.001zM10 1a1 1 0 011 1v6a1 1 0 01-1 1H3l-2 2V2a1 1 0 011-1h8z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgConversation;
