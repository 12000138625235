import React, { useEffect, useState } from 'react';
import './interviews-labs.scss';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import SvgTrash from '../../../../../assets/icons/js/Trash';
import InterviewsApi from './api/interviews-api';
import { IInterviews } from './model/interviews.interface';
import SvgEdit from '../../../../../assets/icons/js/Edit';
import { ConfirmationDialog } from '../../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import FlagIconsPipe from '../../../../../_shared/helpers/flag-icons-pipe';
import CreateNotepadModal from './create-notepad/create-notepad';
import { findNotePadType } from './_shared/notepad-type';
import { Button, Table } from "antd";
import { UsageStats } from "./usage-stats/usage-stats";
import { Loader } from "../../../../../_shared/components/loader/loader";
import FormattedDate from '../../../../../_shared/helpers/formatted-date';
import { useQuery } from "react-query";
import Pagination from "../../../../../_shared/components/pagination/pagination";

export default function InterviewsLabs(props: any): JSX.Element {
  const columns: ColumnsType<any> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (value: string, interview: any) => (
        <span
          className="link"
          onClick={() => navigateToInterview(interview.id)}>
          {value}
        </span>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'organizer',
      key: 'organizer',
      render: (value: any) => (
        <>
          <span>
            {value?.displayName}
          </span>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "typeId",
      key: "typeId",
      render: (value: any) => {
        return <>
          <span>
            {findNotePadType(value)?.name}
          </span>
        </>;
      }
    },
    {
      title: 'Guests',
      dataIndex: 'guests',
      key: 'guests',
    },
    {
      title: 'Flags',
      dataIndex: 'flags',
      key: 'flags',
      render: (value: any) => (
        <>
          {value &&
            value.map((flag: any, i: number) => {
              return (
                <svg width={16} height={16} key={i}>
                  <FlagIconsPipe
                    width={13}
                    height={13}
                    icon={flag?.icon && flag?.icon}
                    color={flag?.color && flag?.color}
                  />
                </svg>
              );
            })}
        </>
      ),
    },
    {
      title: 'Date Created',
      dataIndex: 'date',
      key: 'date',
      render: (value: string) => (
        <span>
          <FormattedDate date={value} formatString="MMM d, yyyy"/>
        </span>
      ),
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      key: 'lastModified',
      render: (value: string) => (
        <span>
          <FormattedDate date={value} formatString="MMM d, yyyy"/>
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '1',
      render: (v, interview) => (
        <div className="action-hover-column flex-end align-items-center">
          <button
            className="icon-button"
            style={{marginRight: 10}}
            onClick={() => navigateToInterview(interview.id)}
          >
            <SvgEdit/>
          </button>
          <button
            className="icon-button"
            onClick={() => openConfirmationDialog(interview)}
          >
            <SvgTrash/>
          </button>
        </div>
      ),
    },
  ];

  const navigate: NavigateFunction = useNavigate();
  const [confirmationDialog, setConfirmationDialog] = useState<any>({
    visible: false,
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const interviews = useQuery(['interviews', props.project.id, page, pageSize], () => InterviewsApi.getAllPaginated(props.project.id, page, pageSize));

  const [modalVisible, setModalVisible] = useState<any>({open: false});

  const closeModal = () => {
    setModalVisible({open: false});
  }

  const openModal = () => {
    setModalVisible({
      open: true,
      projectId: props.project.id
    });
  }


  function navigateToInterview(id: number) {
    navigate(`/projects/edit/interview?interview=${id}&id=${props.project.id}`);
  }


  function openConfirmationDialog(interview: IInterviews): void {
    setConfirmationDialog({
      visible: true,
      id: interview.id,
      okText: 'Delete',
      title: `Delete ${interview.title}`,
      titleSec: "You're about to Delete this interview",
      content: `${interview.title}`,
    });
  }

  function closeDialog(): void {
    setConfirmationDialog({visible: false});
  }

  function deleteInterview(interview: IInterviews): void {
    closeDialog();

    InterviewsApi.delete(interview.id).then(() => {
      interviews.refetch();
    });
  }

  const handlePagination = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }


  return (
    <div className="column" style={{paddingBottom: 40}}>
      <div className='row'>
        <div className='notepad-tile'>

        </div>
      </div>
      {interviews.isLoading ? (
        <Loader/>
      ) : (
        <>
          <UsageStats project={props.project}/>

          <div
            className="row"
            style={{marginBottom: 20, justifyContent: 'space-between'}}
          >
            <span style={{fontSize: 16, color: "#545e6b"}}>Interview notepads</span>
            <div className="row">
              <Button type="primary" onClick={openModal}>
                <span style={{color: '#fff'}}>New Interview</span>
              </Button>
              {
                interviews.data && interviews.data?.pagination.totalElements > 0 &&
                <Pagination totalItems={interviews.data.pagination.totalElements} currentPage={page} pageSize={pageSize}
                            onChange={handlePagination}/>
              }
            </div>

          </div>
          <div className="row">
            <Table
              columns={columns}
              style={{width: '100%'}}
              pagination={false}
              dataSource={interviews.data?.content}
              rowKey="id"
            />
          </div>
        </>
      )}

      {confirmationDialog.visible && (
        <ConfirmationDialog
          data={confirmationDialog}
          onConfirm={deleteInterview}
          onCancel={closeDialog}
        />
      )}
      {modalVisible.open &&
          <CreateNotepadModal closeModal={closeModal} visible={modalVisible.open} projectId={props.project.id}/>
      }
    </div>
  );
}
