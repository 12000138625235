import React from 'react';
import './steps.scss'
import SvgStepArrow from "../../../../../../../assets/icons/js/StepArrow";

const Steps = (props: any) => {
  return (
    <div className='steps-wrapper row'>
      {
        props.items.map((item: { icon: any, title: string }, index: number) => {
          return <span
            key={index}
            data-testid={`step-item-${index}`}
            className={props.current === index ? 'step-item step-item-active' : props.current > index ? 'step-item-done step-item' : 'step-item'}>
            {
              index > 0 &&
                <span className='step-item-arrow'>
                <SvgStepArrow/>
              </span>

            }
            <span className='step-item-icon'>{item.icon}</span>
            <span className='step-item-title'>{item.title}</span>
          </span>
        })
      }
    </div>
  );
};

export default Steps;
