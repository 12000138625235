import React, { useState } from 'react';
import { ColumnsType } from "antd/es/table";
import { IImportJobDetail, IImportJobs } from "../../../models/import-jobs.interface";
import { format } from "date-fns";
import { Button, notification, Table, TableColumnsType } from "antd";
import { useQuery } from "react-query";
import questionProImportsApi from "../api/question-pro-imports.api";

const DefaultImport = () => {

  const columns: ColumnsType<IImportJobs> = [
    {title: "ID", dataIndex: 'id', key: "id", width: "5%"},
    {title: "Name", dataIndex: 'name', key: "name"},
    {title: "Status", dataIndex: 'status', key: "status"},
    {title: "Surveys", dataIndex: 'surveyImportedNumber', key: "surveyImportedNumber"},
    {
      title: "Started at",
      dataIndex: 'startTime',
      key: 'startTime',
      render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
    },
    {
      title: "Ended at",
      dataIndex: 'endTime',
      key: 'endTime',
      render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
    },
    {title: "Duration", dataIndex: "duration", key: "duration"}
  ]

  const expandedRowRender = (record: IImportJobs) => {
    const columns: TableColumnsType<IImportJobDetail> = [
      {title: 'ID', dataIndex: 'id', key: 'id', width: "5%"},
      {title: 'Program Name', dataIndex: 'programName', key: 'programName'},
      {title: "User ID", dataIndex: 'userId', key: 'userId'},
      {title: 'Status', key: 'status', dataIndex: 'status'},
      {title: "Surveys", dataIndex: 'importedSurveys', key: "importedSurveys"},
      {
        title: "Started At",
        dataIndex: 'startTime',
        key: 'startTime',
        render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
      },
      {
        title: "Ended At",
        dataIndex: 'endTime',
        key: 'endTime',
        render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
      },
      {title: "Duration", dataIndex: "duration", key: "duration"}
    ];

    return <Table scroll={{y: 350}} columns={columns} dataSource={record.details} pagination={false}/>;
  };

  const [apiLoading, setApiLoading] = useState(false);

  const importJobs = useQuery('importJobsDefault', () => questionProImportsApi.getImportJobs('DEFAULT'))

  const startImport = () => {
    setApiLoading(false);
    questionProImportsApi.startImportJob().then((response) => {
      importJobs.refetch();
      setApiLoading(false);
      notification.open({
        message: "Done",
        description: response.message,
        type: "success",
        className: "infobox-success",
        duration: 5,
        placement: "bottomRight",
      });
    })
  }

  return (
    <div className="page column" style={{gap: 20}}>
      <Button loading={apiLoading} onClick={startImport} type="primary" style={{width: "fit-content"}}>Start
        import</Button>
      <div>
        <Table columns={columns} dataSource={importJobs.data}
               loading={importJobs.isLoading}
               pagination={false}
               rowKey={"id"}
               expandable={{expandedRowRender}}/>
      </div>
    </div>
  );
};

export default DefaultImport;
