// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-details-dialog-wrapper .project-details-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.project-details-dialog-wrapper .project-details-info-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.project-details-dialog-wrapper .project-details-info-wrapper .project-details-info-name {
  width: 25%;
  color: #545e6b;
  font-size: 12px;
}
.project-details-dialog-wrapper .project-details-info-wrapper .project-details-info-value {
  width: 75%;
  font-weight: 300;
  color: #545e6b;
  font-size: 12px;
}
.project-details-dialog-wrapper .project-details-info-wrapper .project-details-info-value .project-url-placeholder {
  width: 100%;
  height: 32px;
}
.project-details-dialog-wrapper .project-details-info-wrapper .project-details-info-value .project-url-placeholder input {
  font-size: 12px;
  font-weight: 300;
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/project-details-dialog/project-details-dialog.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAGE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;AAGI;EACE,UAAA;EACA,cAAA;EACA,eAAA;AADN;AAII;EAEE,UAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAHN;AAMM;EACE,WAAA;EACA,YAAA;AAJR;AAMQ;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJV","sourcesContent":[".project-details-dialog-wrapper {\n  .project-details-form-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n  }\n\n  .project-details-info-wrapper {\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n\n    .project-details-info-name {\n      width: 25%;\n      color: #545e6b;\n      font-size: 12px;\n    }\n\n    .project-details-info-value {\n\n      width: 75%;\n      font-weight: 300;\n      color: #545e6b;\n      font-size: 12px;\n\n\n      .project-url-placeholder {\n        width: 100%;\n        height: 32px;\n\n        input {\n          font-size: 12px;\n          font-weight: 300;\n          color: #545e6b;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
