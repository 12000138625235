import React from 'react';
import './setup.scss';
import { Portal } from './portal/portal';
import Integrations from "./integrations/integrations";
import MaskPIIForm from "../../../../_shared/components/mask-pii-form/mask-pii-form";


interface IPage {
  title: string;
  content: JSX.Element;
}

export function Setup(props: any): JSX.Element {
  const pages: IPage[] = [
    {
      title: 'Portal',
      content: <Portal/>
    },
    {
      title: "Integrations",
      content: <Integrations/>
    },
    {
      title: "Data protection",
      content: <MaskPIIForm/>
    }
  ]

  return (
    <div className="setup column">
      {pages.map((page: IPage, i: number) =>
        <div className="main-title" key={i}>
          <h4>{page.title}</h4>
          {/*<div className="hr"/>*/}
          <div className="page">
            {page.content}
          </div>
        </div>
      )}
    </div>
  );
}
