import React, { useEffect, useRef, useState } from 'react';
import './create-project-page.scss'
import SvgArrowBack from "../../../../../assets/icons/js/ArrowBack";
import { Button, Empty } from "antd";
import HomeApi from "../../../api/home-api";
import { IPublishedForms } from "../../../models/published-forms.interface";
import {
  NarrowProjectTypeCard
} from "../../../../../_shared/components/narrow-project-type-card/narrow-project-type-card";
import SvgSettings from "../../../../../assets/icons/js/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import CreateProject from "../create-project";
import { Loader } from "../../../../../_shared/components/loader/loader";

const CreateProjectPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);
  const createProjectRef = useRef<{ submitForm: () => void }>(null);
  const [projects, setProjects] = useState<IPublishedForms[]>();
  const [selectedFormId, setSelectedFormId] = useState<number>();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    loadPage()
  }, []);

  function onFormClick(formId: number) {
    setSelectedFormId(formId)
    navigateToProject(formId)
  }

  function loadPage(): void {
    HomeApi.getPublishedForms()
      .then((publishedForms: IPublishedForms[]) => {
        setProjects(publishedForms);
        setLoader(false);
        if (urlParam?.id) {
          onFormClick(JSON.parse(urlParam?.id));
          return;
        }

        onFormClick(publishedForms[0].id)
      });
  }

  function navigateToProject(id: string | number): void {
    const params = {
      ...qs.parse(location?.search),
      id
    }


    navigate({
      search: qs.stringify(params),
    }, {state: location.state});
  }

  const handleSubmit = () => {
    if (createProjectRef.current) {
      createProjectRef.current.submitForm()
    }
  }

  const navigateBack = () => {
    const state: any = location.state;
    if (state?.fromHome) {
      navigate("/home")
    }

    if (state?.fromProjects) {
      navigate("/projects")
    }

    if (state?.fromInitialPage) {
      navigate("/initial-page")
    }
  }

  return (
    <div className="create-project-page-wrapper" data-testid={"create-project-page"}>
      <div className="create-project-page">
        <div className="intake-form-header justify-space-between">
          <div className="row align-items-center">
            <button
              className="icon-button back"
              onClick={navigateBack}>
              <SvgArrowBack/>
            </button>
            <span className="font-weight-500" style={{color: "#545e6b"}}>New project</span>
          </div>
          <div className="row align-items-center">
            <Button onClick={() => handleSubmit()} type="primary">Add project</Button>
          </div>
        </div>
        <div className="create-project-content">
          <div className="create-project-types-wrapper">
            {
              loader ? <Loader/> :
                <>
                  <div className="create-project-types">
                    {projects?.length ? projects.map((projectType: IPublishedForms, i: number) => (
                        <div onClick={() => onFormClick(projectType.id)} key={i}>
                          <NarrowProjectTypeCard selectedFormId={selectedFormId} projectType={projectType}/>
                        </div>
                      ))
                      :
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    }
                  </div>
                  <div className="edit-project-types-btn-wrapper">
                    <div className="hr"/>
                    <Button onClick={() => navigate('/settings')} className="edit-project-types-btn"
                            icon={<SvgSettings color={"#1890ff"}/>} type="link">
                      Edit Project Types
                    </Button>
                  </div>
                </>
            }
          </div>
          <div className="create-project-form-wrapper">
            <CreateProject ref={createProjectRef}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectPage;
