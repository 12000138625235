import { DraggableColumns } from "./draggable-columns";
import { useState } from "react";
import { ICustomizeColumnTable, ICustomizeTable } from "../../models/customize-table.interface";

type ComponentType = {
  data: ICustomizeTable
  onChange: (data: any) => void
}

export function CustomizeTableBasicView({data, onChange}: ComponentType): JSX.Element {
  const [columns, setColumns] = useState<any>(data.basic);

  const columnsChanged = (newColumns: any) => {
    setColumns(newColumns);

    onChange({basic: newColumns});
  }

  return <DraggableColumns columns={columns}
                           onColumnsChanged={(newColumns: ICustomizeColumnTable[]) => columnsChanged(newColumns)}/>
}
