import React from 'react';
import SvgQpLogoIH from "../../../../../../assets/icons/js/qp-logo-ih";
import TextFit from "../../../../../../_shared/components/text-fit/text-fit";
import { ICustomTheme } from "../interface/custom-theme.interface";
import { format } from "date-fns";

const RightTheme: React.FC<ICustomTheme> = ({snapshotTheme, nugget, createdAt, createdBy, project}) => {
  return (
    <div className="relative column justify-space-between w-100 h-100">
      <div className="logo-img-wrapper align-self-end">
        {snapshotTheme?.logoUrl && (
          <img src={snapshotTheme.logoUrl} alt="Logo" style={{height: '48px'}}/>
        )}
      </div>

      <div className="column align-self-end" style={{
        width: '400px',
        gap: '20px'
      }}>
        <div className="key-finding-wrapper" style={{height: '231px'}}>
          <TextFit
            mode="multi"
            min={12}
            max={56}
            style={{
              height: '100%',
              width: '100%',
              lineHeight: 1.5,
              textAlign: 'right',
              fontWeight: '500'
            }}>
            {nugget}
          </TextFit>
        </div>
      </div>
      <div className="project-details-wrapper align-self-end">
        {
          snapshotTheme.showDetails &&
          <>
            {project}
            <div style={{fontWeight: 500}}>
              - {createdBy.displayName} , {format(new Date(createdAt), 'MMMM yyyy')}
            </div>
          </>
        }
      </div>

      <div className="qp-logo-wrapper" style={{alignSelf: 'flex-end'}}>
        <SvgQpLogoIH/>
      </div>
    </div>
  );
};

export default RightTheme;
