import { FlagTypeIconsEnum } from '../model/flag-type-icons.enum';
import SvgFlag from '../../assets/icons/js/Flag';
import SvgFlagAlert from '../../assets/icons/js/FlagAlert';
import SvgFlagAnalysis from '../../assets/icons/js/FlagAnalysis';
import SvgFlagCalendar from '../../assets/icons/js/FlagCalendar';
import SvgFlagDislike from '../../assets/icons/js/FlagDislike';
import SvgFlagGps from '../../assets/icons/js/FlagGps';
import SvgFlagHelp from '../../assets/icons/js/FlagHelp';
import SvgFlagLike from '../../assets/icons/js/FlagLike';
import SvgFlagTarget from '../../assets/icons/js/FlagTarget';
import SvgFlagThunder from '../../assets/icons/js/FlagThunder';

const FlagIconsPipe = (props: any) => {
  const iconProps: { width?: string; height?: string; color?: string } = {};

  if (props.width) {
    iconProps.width = props.width;
  }

  if (props.height) {
    iconProps.height = props.height;
  }

  if (props.color) {
    iconProps.color = props.color;
  }

  const pipe = () => {
    switch (props.icon) {
      case FlagTypeIconsEnum.IH_FLAG:
        return <SvgFlag {...iconProps} />;
      case FlagTypeIconsEnum.IH_ALERT:
        return <SvgFlagAlert {...iconProps} />;
      case FlagTypeIconsEnum.IH_ANALYSIS:
        return <SvgFlagAnalysis {...iconProps} />;
      case FlagTypeIconsEnum.IH_CALENDAR:
        return <SvgFlagCalendar {...iconProps} />;
      case FlagTypeIconsEnum.IH_DISLIKE:
        return <SvgFlagDislike {...iconProps} />;
      case FlagTypeIconsEnum.IH_GPS:
        return <SvgFlagGps {...iconProps} />;
      case FlagTypeIconsEnum.IH_HELP:
        return <SvgFlagHelp {...iconProps} />;
      case FlagTypeIconsEnum.IH_LIKE:
        return <SvgFlagLike {...iconProps} />;
      case FlagTypeIconsEnum.IH_TARGET:
        return <SvgFlagTarget {...iconProps} />;
      case FlagTypeIconsEnum.IH_THUNDER:
        return <SvgFlagThunder {...iconProps} />;
      default:
        return null;
    }
  };

  const renderIcon = (): JSX.Element => {
    const oldIcon = pipe();

    if (oldIcon === null) {
      return <i className={`bi-${props.icon}`} style={{
        fontSize: iconProps.width ? iconProps.width : 20,
        color: iconProps.color ? iconProps.color : '#545E6B'
      }}/>
    } else {
      return oldIcon;
    }
  }


  return <>{renderIcon()}</>;
};

export default FlagIconsPipe;
