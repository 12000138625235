import * as React from 'react';

const SvgFlag = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 14a1 1 0 0 1-2 0V2C3.667.667 6.667.667 8 2c1.333 1.333 4.333 1.333 7 0v9c-2.667 1.333-5.667 1.333-7 0-.976-.976-3.31-1.238-5-.785V14z"
        fill={props.color ? props.color : "#9B9B9B"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlag;
