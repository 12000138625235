import React, { useContext, useRef, useState } from 'react';
import './ai-input.scss'
import SvgRobot from "../../../../../../assets/icons/js/robot";
import SvgSend from "../../../../../../assets/icons/js/Send";
import { useOnClickOutside } from "../../../../../../core/hooks/use-outside-click";
import SvgEdit from "../../../../../../assets/icons/js/Edit";
import SvgPaginationArrowForward from "../../../../../../assets/icons/js/PaginationArrowForward";
import SvgDocuments from "../../../../../../assets/icons/js/documents";
import SvgDraftAskAI from "../../../../../../assets/icons/js/DraftAskAi";
import SvgFixSpelling from "../../../../../../assets/icons/js/FixSpelling";
import SvgImproveWriting from "../../../../../../assets/icons/js/ImproveWriting";
import SvgLighting from "../../../../../../assets/icons/js/Lightning";
import SvgTranslate from "../../../../../../assets/icons/js/Translate";
import StoryContext from "../../../story-edit.context";
import SvgNugget from "../../../../../../assets/icons/js/nugget";
import SvgShortenText from "../../../../../../assets/icons/js/shorten-text";
import SvgExpandText from "../../../../../../assets/icons/js/expand-text";
import { convertFromRaw, EditorState, Modifier } from "draft-js";
import StoriesApi from "../../../../api/stories-api";
import { NormalLoader } from "../../../../../../_shared/components/normal-loader/normal-loader";
import SvgTrash from "../../../../../../assets/icons/js/Trash";
import { Button } from "antd";
import SvgLoop from "../../../../../../assets/icons/js/Loop";
import { removeUnderlineAndBoldStyle } from "../../helpers/remove-underline-and-bold-style";
import { removeBlockByKey } from "../../helpers/remove-block-by-key";
import { removeText } from "../../helpers/remove-text";
import HomeApi from "../../../../../home/api/home-api";
import SvgLike from "../../../../../../assets/icons/js/Like";
import SvgDislike from "../../../../../../assets/icons/js/Dislike";
import AdditionalFeedbackDialog from "./additional-feedback-dialog/additional-feedback-dialog";
import SvgAddBig from "../../../../../../assets/icons/js/addBig";
import { IProject } from "../../../../../projects/models/project.interface";
import LinkProjectModal from "../../../../../../_shared/components/link-project-modal/link-project-modal";
import SvgSearch from "../../../../../../assets/icons/js/Search";
import SvgAdd from "../../../../../../assets/icons/js/add";

type MenuItem = {
  text: string;
  itemCode?: string;
  icon: React.ReactNode;
  children?: {
    text: string;
    itemCode: string
    icon?: React.ReactNode;
  }[]
}

const AiInput = (props: any) => {
  const {setEditorReadOnly, storedSelection, setStoredSelection, onProjectAssign} = props.blockProps;
  const {block} = props;
  const selectionDetails = block.getData().get("selectionDetails");
  const generateFrom = block.getData().get("generateFrom");

  const storyContext = useContext(StoryContext);
  const contentState = storyContext.editorState.getCurrentContent();
  const hasText = contentState.getPlainText().trim().length > 0;
  const [menuItems, setMenuItems] = useState<MenuItem[]>(() => getMenuItems());

  const component = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [inputText, setInputText] = useState<string>('');
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(true);
  const [generatedText, setGeneratedText] = useState<string>('');
  const [generatedTextId, setGeneratedTextId] = useState<number>();
  const [prompt, setPrompt] = useState<string>('');
  const [lastUseCase, setLastUseCase] = useState<string>('');
  const [isResponseLiked, setIsResponseLiked] = useState(false);
  const [isResponseDisliked, setIsResponseDisliked] = useState(false);
  const [additionalFeedbackDialog, setAdditionalFeedbackDialog] = useState<any>({
    open: false,
  })

  const [linkProjectModal, setLinkProjectModal] = useState<any>({visible: false})


  let selectionState = storedSelection;

  useOnClickOutside(component, () => {
    if (additionalFeedbackDialog.open || linkProjectModal.visible) {
      return;
    }
    close()
  });

  const close = () => {
    setEditorReadOnly(false);
    const newEditorState = removeUnderlineAndBoldStyle(storyContext.editorState, generatedText);
    const newEditorStateWithoutAiInput = removeBlockByKey(newEditorState, block.getKey());
    if (newEditorStateWithoutAiInput) {
      storyContext.onSetEditorState(newEditorStateWithoutAiInput);
    }
  }

  function getMenuItems() {

    if (generateFrom === "content" && !hasText) {
      return contentMenuItems[2].children as MenuItem[];
    }

    if (generateFrom !== "content") {
      return getMenuItemsFromLinkedProjects(storyContext.linkedProjects);
    }

    return contentMenuItems;
  }

  const resetFeedback = () => {
    setIsResponseLiked(false);
    setIsResponseDisliked(false);
  }

  const send = async () => {
    if (inputText.length <= 1) return;
    setPrompt(inputText)
    let newEditorState: EditorState;
    if (generatedText) {
      newEditorState = removeUnderlineAndBoldStyle(storyContext.editorState, generatedText);
    }
    setApiLoading(true);
    setInputText("")
    setLastUseCase('GENERATE_FROM_QUERY')
    StoriesApi.getAiGeneratedContentFromPrompt(storyContext.id, {
      prompt: inputText,
      useCase: 'GENERATE_FROM_QUERY'
    }).then((data: { responseText: string, id: number }) => {
      insertContent(newEditorState, data)
    })
  }

  const insertContent = (newEditorState: EditorState | undefined, data: { responseText: string, id: number }) => {
    const responseText = JSON.parse(data.responseText).text
    setGeneratedText(responseText);
    setGeneratedTextId(data.id)
    typeTextEffect(newEditorState || storyContext.editorState, responseText, storyContext.onSetEditorState);
    setApiLoading(false);
    resetFeedback();
  }

  const handleEnterEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      send();
    }
  }


  const typeTextEffect = (initialEditorState: EditorState, text: string, setEditorState: (editorState: EditorState) => void) => {
    let currentIndex = 0;
    let editorState = initialEditorState;

    const typingInterval = setInterval(() => {
      if (currentIndex < text.length) {
        editorState = insertTextAtCursor(editorState, text[currentIndex]);
        setEditorState(editorState);
        setStoredSelection(editorState.getSelection());
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 5); // Typing speed (milliseconds per character)
  };


  const insertTextAtCursor = (editorState: EditorState, text: string) => {
    // Get the current content and selection state
    const contentState = editorState.getCurrentContent();
    // Get the current selection

    // Insert the current character at the selection point
    const contentStateWithText = Modifier.insertText(contentState, selectionState, text, editorState.getCurrentInlineStyle().add('UNDERLINE').add('BOLD'));

    // Update the selection by moving the cursor forward by one position after the inserted text
    selectionState = contentStateWithText.getSelectionAfter();
    const newEditorState = EditorState.push(editorState, contentStateWithText, 'insert-characters');

    // Update the selection state in the new editor state
    return EditorState.forceSelection(newEditorState, contentStateWithText.getSelectionAfter());
  };


  const handleMenuItemClick = (item: MenuItem) => {
    // setDropdownVisible(false)

    if (item.itemCode === 'INSERT_TEXT') {
      setInputText(item.text);
      inputRef.current.focus();
      return;
    }

    if (item.itemCode === "ADD_PROJECT") {
      openLinkProjectModal()
      return;
    }

    setApiLoading(true);

    if (item.itemCode && item.itemCode.includes('project-highlight-insights')) {
      setLastUseCase(item.itemCode);
      const projectId = item.itemCode.split('-')[3];

      StoriesApi.getAiGeneratedContentFromPrompt(storyContext.id, {
        useCase: 'PROJECT_HIGHLIGHT_INSIGHTS',
        projectId: parseInt(projectId)
      }).then((data: { responseText: string, id: number }) => {
        insertContent(undefined, data)
      });
      return;
    }


    if (item.itemCode && item.itemCode.includes('project-research-summary')) {
      setLastUseCase(item.itemCode);
      const projectId = item.itemCode.split('-')[3];

      StoriesApi.getAiGeneratedContentFromPrompt(storyContext.id, {
        useCase: 'PROJECT_RESEARCH_SUMMARY',
        projectId: parseInt(projectId)
      }).then((data: { responseText: string, id: number }) => {
        insertContent(undefined, data)
      })
      return;
    }

    if (item.itemCode === 'TRANSLATE') {
      StoriesApi.getAiGeneratedContent(storyContext.id, {
        selectionDetails: selectionDetails,
        useCase: 'TRANSLATE',
        language: item.text
      }).then((rawContent) => {
        updateStoryContent(rawContent);
        setApiLoading(false);
      })
      return;
    }


    if (item.itemCode && item.itemCode.includes('TONE')) {
      StoriesApi.getAiGeneratedContent(storyContext.id, {
        selectionDetails: selectionDetails,
        useCase: 'CHANGE_TONE',
        tone: item.itemCode.split('_')[1]
      }).then((rawContent) => {
        updateStoryContent(rawContent);
        setApiLoading(false);
      });
      return;
    }

    StoriesApi.getAiGeneratedContent(storyContext.id, {
      selectionDetails: selectionDetails,
      useCase: item?.itemCode ?? ''
    }).then((rawContent) => {
      updateStoryContent(rawContent);
      setApiLoading(false);
    })
  }


  function updateStoryContent(rawContent: any) {
    storyContext.onSetEditorState(EditorState.createWithContent(convertFromRaw(rawContent)));
  }

  const handleRemoveText = () => {
    const newEditorState = removeText(storyContext.editorState, generatedText);
    storyContext.onSetEditorState(newEditorState);
    setGeneratedText('');
  }

  const handleFocus = () => {
    setEditorReadOnly(true);
  }

  const handleRegenerate = async () => {
    if (lastUseCase === "GENERATE_FROM_QUERY") {
      if (!prompt) {
        return;
      }
      let newEditorState: EditorState;
      if (generatedText) {
        newEditorState = removeText(storyContext.editorState, generatedText);
      }
      setApiLoading(true);
      setInputText("")
      StoriesApi.getAiGeneratedContentFromPrompt(storyContext.id, {
        prompt: prompt,
        useCase: 'GENERATE_FROM_QUERY'
      }).then((data: { responseText: string, id: number }) => {
        insertContent(newEditorState, data)
      });

    } else if (lastUseCase.includes('project-highlight-insights')) {
      setLastUseCase(lastUseCase);
      const projectId = lastUseCase.split('-')[3];
      let newEditorState: EditorState;
      if (generatedText) {
        newEditorState = removeText(storyContext.editorState, generatedText);
      }
      setApiLoading(true);

      StoriesApi.getAiGeneratedContentFromPrompt(storyContext.id, {
        useCase: 'PROJECT_HIGHLIGHT_INSIGHTS',
        projectId: parseInt(projectId)
      }).then((data: { responseText: string, id: number }) => {
        insertContent(newEditorState, data)
      })

    } else if (lastUseCase.includes('project-research-summary')) {
      setLastUseCase(lastUseCase);
      const projectId = lastUseCase.split('-')[3];
      let newEditorState: EditorState;
      if (generatedText) {
        newEditorState = removeText(storyContext.editorState, generatedText);
      }
      setApiLoading(true);
      StoriesApi.getAiGeneratedContentFromPrompt(storyContext.id, {
        useCase: 'PROJECT_RESEARCH_SUMMARY',
        projectId: parseInt(projectId)
      }).then((data: { responseText: string, id: number }) => {
        insertContent(newEditorState, data)
      })
    }
  }

  const toggleFeedback = (chatId: number | undefined, action: 'LIKE' | 'DISLIKE') => {
    if (!chatId) {
      return;
    }

    if (action === 'LIKE') {
      if (isResponseDisliked) {
        setIsResponseDisliked(false);
      }
      setIsResponseLiked(prev => !prev);
      HomeApi.likeDislikeResponse(chatId, isResponseLiked ? null : 'LIKED').then(() => {
      });
    }

    if (action === 'DISLIKE') {
      if (isResponseLiked) {
        setIsResponseLiked(false);
      }
      setIsResponseDisliked(prev => !prev);
      HomeApi.likeDislikeResponse(chatId, isResponseDisliked ? null : 'DISLIKED').then(() => {
      });
    }
  };

  const openAdditionalFeedbackDialog = () => {
    setAdditionalFeedbackDialog({
      open: true,
      chatId: generatedTextId
    })
  }

  const onCloseAdditionalFeedbackDialog = () => {
    setAdditionalFeedbackDialog({open: false});
  }

  const openLinkProjectModal = () => {
    setLinkProjectModal({visible: true, storyId: storyContext.id})
  }

  const closeLinkProjectModal = () => {
    setLinkProjectModal({visible: false})
  }

  const assignProjectToStory = (data: { project: IProject }) => {
    onProjectAssign(data.project)
    closeLinkProjectModal();
  }

  const filterProjects = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMenuItems(getMenuItemsFromLinkedProjects(storyContext.linkedProjects).filter((item) => item.text.toLowerCase().includes(e.target.value.toLowerCase())
    ))
  }


  return (
    <>
      <div className="ai-input-wrapper" ref={component} style={{marginTop: 30}}>
        {
          generatedText && (
            <div className="ai-generated-text">
              <div className="ai-generated-text-actions">
                <Button type="text" onClick={handleRemoveText} style={{padding: 8}}>
                  <SvgTrash/>
                </Button>
                <Button type="text" onClick={handleRegenerate} style={{padding: 8}}>
                  <SvgLoop/>
                </Button>
                <Button type="text" onClick={() => toggleFeedback(generatedTextId, "LIKE")}
                        className={`${isResponseLiked && 'liked-response'}`}
                        style={{padding: 8}}>
                  <SvgLike/>
                </Button>
                <Button type="text" onClick={() => toggleFeedback(generatedTextId, "DISLIKE")}
                        className={`${isResponseDisliked && 'liked-response'}`}
                        style={{padding: 8}}>
                  <SvgDislike/>
                </Button>
                {
                  (isResponseLiked || isResponseDisliked) &&
                  <Button type="link"
                          onClick={openAdditionalFeedbackDialog}
                          style={{padding: 8}}>
                    Additional feedback
                  </Button>
                }
              </div>
              <div>
              </div>
            </div>
          )
        }
        <div className="ai-input-container relative">
          <input type="text"
                 className="ai-input"
                 ref={inputRef}
                 onFocus={handleFocus}
                 onKeyDown={handleEnterEvent}
                 value={inputText}
                 onChange={(e) => setInputText(e.target.value)}
                 placeholder={apiLoading ? "" : generatedText ? "Tell AskIH what to do next..." : "Ask your IH to write anything"}/>
          <div className="ai-input-icon">
            <SvgRobot color={"#545e6b"}/>
          </div>
          <button className="ai-input-send-button" onClick={send} disabled={inputText.length <= 1 || false}>
            <SvgSend width={12} height={12}/>
          </button>
          {
            apiLoading && (
              <div className="ai-input-loader">
                Generating
                <NormalLoader backgroundColor={"#545e6b"}/>
              </div>
            )
          }
        </div>

        {
          dropdownVisible && (
            <div className="ai-input-dropdown">
              <div className="ai-input-dropdown-content">
                <div className="ai-input-dropdown-content-label">
                  {generateFrom === "content" ? " Generate from content" : "Generate from linked project"}
                </div>
                {
                  generateFrom !== "content" &&
                  <div className="outline-input-wrapper relative" style={{padding: "2px 5px"}}>
                    <input type="text"
                           onFocus={handleFocus}
                           onChange={filterProjects}
                           className="outline-input"
                           placeholder="Search for project"
                           style={{paddingLeft: 25}}/>
                    <div className="absolute" style={{left: 10, top: 7}}>
                      <SvgSearch color={"#D8D8D8"}/>
                    </div>
                  </div>
                }
                {
                  !menuItems.length ? <div className="ai-input-dropdown-content-label" style={{fontWeight: 400}}>
                    No projects available
                  </div> : menuItems.map((item: MenuItem, index) => (
                    <>
                      {
                        index === 3 && generateFrom === "content" && hasText &&
                        <div className="ai-input-dropdown-content-label">
                          Writing
                        </div>
                      }
                      <div key={index} className="dropdown-item-wrapper"
                           onClick={() => !item.children && handleMenuItemClick(item)}
                      >
                        <div className="dropdown-item">
                          <div className="dropdown-item-icon">
                            {item.icon}
                          </div>
                          <div className="dropdown-item-text">
                            {item.text}
                          </div>
                        </div>
                        {
                          item.children && (
                            <div>
                              <SvgPaginationArrowForward width={10} height={12}/>
                            </div>
                          )
                        }
                        {
                          !!item.children && Array.isArray(item.children) && (
                            <div className="children-dropdown-content-wrapper">
                              <div className={"children-dropdown-content"}>
                                {
                                  item.children.map((childItem: {
                                    text: string,
                                    icon?: any,
                                  }, childIndex: number) => {
                                    return (
                                      <div onClick={() => handleMenuItemClick(childItem as MenuItem)}
                                           className="children-dropdown-item-wrapper"
                                           key={childIndex}>
                                        <div className="children-dropdown-item">
                                          <div className="children-dropdown-item-icon">
                                            {childItem.icon}
                                          </div>
                                          <div className="children-dropdown-item-text">
                                            {childItem.text}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </>
                  ))
                }
                {
                  generateFrom !== "content" &&
                  <div className="dropdown-item-wrapper" onClick={() => handleMenuItemClick({
                    itemCode: 'ADD_PROJECT'
                  } as MenuItem)}>
                    <div className="dropdown-item">
                      <div className="dropdown-item-icon">
                        <SvgAddBig width={16} height={16} color={"#545e6b"}/>
                      </div>
                      <div className="dropdown-item-text">
                        Add new project
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          )
        }
      </div>
      {
        additionalFeedbackDialog.open &&
        <AdditionalFeedbackDialog data={additionalFeedbackDialog} onCancel={onCloseAdditionalFeedbackDialog}/>
      }
      {
        linkProjectModal.visible &&
        <LinkProjectModal data={linkProjectModal} onConfirm={assignProjectToStory}
                          onCancel={closeLinkProjectModal}/>
      }
    </>
  );
};

export default AiInput;


const contentMenuItems: MenuItem[] = [
  {
    text: "Shorten",
    icon: <SvgShortenText/>,
    itemCode: "SHORTEN"
  },
  {
    text: "Expand",
    icon: <SvgExpandText/>,
    itemCode: "EXPAND"
  },
  {
    text: "Draft with AskIH",
    icon: <SvgDraftAskAI/>,
    children: [
      {
        text: "Write an introduction...",
        icon: <SvgEdit color={"#545e6b"}/>,
        itemCode: "INSERT_TEXT"
      },
      {
        text: "Create an outline...",
        icon: <SvgEdit color={"#545e6b"}/>,
        itemCode: "INSERT_TEXT"
      },
      {
        text: "Suggest story post ideas...",
        icon: <SvgEdit color={"#545e6b"}/>,
        itemCode: "INSERT_TEXT"
      },
      {
        text: "Story hook",
        icon: <SvgEdit color={"#545e6b"}/>,
        itemCode: "INSERT_TEXT"
      },
      {
        text: "Headings and subheadings",
        icon: <SvgEdit color={"#545e6b"}/>,
        itemCode: "INSERT_TEXT"
      },
    ]
  },
  {
    text: "Fix spelling and grammar",
    icon: <SvgFixSpelling/>,
    itemCode: "SPELLING_AND_GRAMMAR"
  },
  {
    text: "Improve writing",
    icon: <SvgImproveWriting/>,
    itemCode: "IMPROVE_WRITING"
  },
  {
    text: "Change tone",
    icon: <SvgLighting/>,
    children: [
      {
        text: "Formal",
        itemCode: "TONE_formal"
      },
      {
        text: "Casual",
        itemCode: "TONE_casual"
      },
      {
        text: "Academic",
        itemCode: "TONE_academic"
      },
      {
        text: "Simple",
        itemCode: "TONE_simple"
      },
      {
        text: "Creative",
        itemCode: "TONE_creative"
      },
    ]
  },
  {
    text: "Translate",
    icon: <SvgTranslate/>,
    children: [
      {
        text: "English",
        itemCode: "TRANSLATE"
      },
      {
        text: "Spanish",
        itemCode: "TRANSLATE"
      },
      {
        text: "Portuguese",
        itemCode: "TRANSLATE"
      },
      {
        text: "French",
        itemCode: "TRANSLATE"
      },
      {
        text: "German",
        itemCode: "TRANSLATE"
      },
      {
        text: "Japanese",
        itemCode: "TRANSLATE"
      },
      {
        text: "Russian",
        itemCode: "TRANSLATE"
      },
      {
        text: "Turkish",
        itemCode: "TRANSLATE"
      },
      {
        text: "Arabic",
        itemCode: "TRANSLATE"
      },
    ]
  }
]

function getMenuItemsFromLinkedProjects(linkedProjects: any[]): MenuItem[] {
  return linkedProjects.map((project): MenuItem => {
    return {
      text: project.project.name,
      icon: '',
      itemCode: `linked-project-${project.project.id}`,
      children: [
        {
          text: "Research summary",
          itemCode: `project-research-summary-${project.project.id}`,
          icon: <SvgDraftAskAI/>,
        },
        {
          text: "Highlight insights",
          itemCode: `project-highlight-insights-${project.project.id}`,
          icon: <SvgNugget width={16} height={16} color={"#545e6b"}/>,
        },
      ]
    }
  })
}