import * as React from "react";

const SvgAnimal = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 6.13c.182 0 .371 0 .546.028.077.013.154.034.231.061.49.164.903.534 1.225.903.616.698 1.127 1.293 1.736 1.99.917.897 2.044 1.889 1.827 3.284-.203.691-.714 1.389-1.631 1.587-.511.096-2.142-.3-3.878-.3H7.93c-1.736 0-3.367.403-3.878.3-.917-.198-1.428-.889-1.631-1.587-.21-1.388.917-2.38 1.834-3.276.616-.698 1.127-1.293 1.736-1.99.329-.37.735-.74 1.225-.904.077-.027.154-.048.231-.061.182-.035.371-.035.553-.035zM2.75 3.736c.966 0 1.75.766 1.75 1.71 0 .945-.784 1.71-1.75 1.71S1 6.391 1 5.446c0-.944.784-1.71 1.75-1.71zm10.5 0c.966 0 1.75.766 1.75 1.71 0 .945-.784 1.71-1.75 1.71s-1.75-.765-1.75-1.71c0-.944.784-1.71 1.75-1.71zM5.9 1c.966 0 1.75.766 1.75 1.71 0 .945-.784 1.71-1.75 1.71s-1.75-.765-1.75-1.71C4.15 1.766 4.934 1 5.9 1zm4.2 0c.966 0 1.75.766 1.75 1.71 0 .945-.784 1.71-1.75 1.71s-1.75-.765-1.75-1.71c0-.944.784-1.71 1.75-1.71z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgAnimal;
