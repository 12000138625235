// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slack-integration .header .back-button {
  margin-top: 8px;
}
.slack-integration .header .logo {
  width: 45px;
  padding: 0 10px;
  margin-right: 5px;
}
.slack-integration .header .description {
  padding-top: 10px;
  color: #9B9B9B;
}
.slack-integration .header .button-wrapper {
  padding-top: 40px;
}
.slack-integration .header .button-wrapper .integration-button {
  padding: 0 50px;
}
.slack-integration .body {
  margin-top: 70px;
  margin-left: 25px;
}
.slack-integration .body .ant-tabs-content {
  height: 63vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/setup/integrations/slack-integration/slack-integration.scss"],"names":[],"mappings":"AAGI;EACE,eAAA;AAFN;AAKI;EACE,WAAA;EACA,eAAA;EACA,iBAAA;AAHN;AAMI;EACE,iBAAA;EACA,cAAA;AAJN;AAOI;EACE,iBAAA;AALN;AAOM;EACE,eAAA;AALR;AAUE;EACE,gBAAA;EACA,iBAAA;AARJ;AAUI;EACE,YAAA;EACA,gBAAA;AARN","sourcesContent":[".slack-integration {\n\n  .header {\n    .back-button {\n      margin-top: 8px;\n    }\n\n    .logo {\n      width: 45px;\n      padding: 0 10px;\n      margin-right: 5px;\n    }\n\n    .description {\n      padding-top: 10px;\n      color: #9B9B9B;\n    }\n\n    .button-wrapper {\n      padding-top: 40px;\n\n      .integration-button {\n        padding: 0 50px;\n      }\n    }\n  }\n\n  .body {\n    margin-top: 70px;\n    margin-left: 25px;\n\n    .ant-tabs-content {\n      height: 63vh;\n      overflow-y: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
