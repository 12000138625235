import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { AddEditOauthProviderDialog } from './add-edit-oauth-provider-dialog/add-edit-oauth-provider.dialog';
import { useMutation, useQuery } from 'react-query';
import SettingsApi from '../../../../api/settings-api';
import { ColumnsType } from 'antd/lib/table';
import SvgEdit from '../../../../../../assets/icons/js/Edit';
import { IExternalProviderInterface } from './external-oauth.interface';
import SvgTrash from '../../../../../../assets/icons/js/Trash';
import { IPortalSettingsProps } from "../portal-settings/model/portal-settings-props.interface";

export function ExternalOauth(props: IPortalSettingsProps): JSX.Element {
  const [dialogData, setDialogData] = useState<{
    visible: boolean,
    externalProvider?: IExternalProviderInterface,
    portalSettings: IPortalSettingsProps
  }>({visible: false, portalSettings: props});
  const externalProviders = useQuery(['externalProviders'], () => SettingsApi.getExternalProviders());

  const deleteExternalProvider = useMutation((id: number) => SettingsApi.deleteExternalProvide(id), {
    onSuccess: (res) => {
      externalProviders.refetch();
    }
  });

  const columns: ColumnsType<{ id: number, name: string }> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => {
        return <div>
          <div className="flex-end align-items-center">
            <button className="icon-button" style={{marginRight: 10}} onClick={() => openDialog(id)}>
              <SvgEdit/>
            </button>
            <button
              className="icon-button"
              onClick={() => deleteExternalProvider.mutate(id)}>
              <SvgTrash/>
            </button>
          </div>
        </div>
      }
    }
  ];

  function openDialog(id?: number) {
    setDialogData({
      ...dialogData,
      visible: true,
      externalProvider: externalProviders.data.filter((_ex: IExternalProviderInterface) => _ex.id === id)[0]
    });
  }

  return (
    <>
      <div className="portal-settings">
        <div className="row align-items-center justify-space-between">
          <h3>OAuth Providers</h3>
          <Button type="primary" onClick={() => openDialog()}>Add oauth provider</Button>
        </div>
        <div className="row">
          <Table
            style={{marginTop: 10}}
            className="w-100"
            loading={externalProviders.isLoading}
            columns={columns}
            pagination={false}
            dataSource={externalProviders.data}
          />
        </div>
      </div>

      {dialogData.visible &&
          <AddEditOauthProviderDialog
              data={dialogData}
              onCancel={() => {
                setDialogData({...dialogData, visible: false});
                externalProviders.refetch();
              }}/>
      }
    </>
  );
}
