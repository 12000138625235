import React, { useEffect, useMemo, useState } from 'react';
import { Input, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from "react-router-dom";
import _ from 'lodash';
import { ICustomizeKeyMetricTable } from "../../models/customize-table.interface";
import ProjectApi from "../../api/project-api";

type MappedDataType = {
  id: number;
  name: string;
  [metricName: string]: { id: number; value: number } | number | string;
};

type KeyMetric = {
  id: number;
  name: string;
  value: number;
};

const mapData = (data: any[]) => {
  return data.map((item) => {
    const mappedData: MappedDataType = {
      id: item.id,
      name: item.name,
    };

    item.keyMetrics.forEach((metric: KeyMetric) => {
      mappedData[metric.name] = {id: metric.id, value: metric.value};
    });

    return mappedData;
  });
};

const createUniqueKeyMetrics = (
  data: any[],
  onInputChange: (value: number, rowIndex: number, name: string) => void,
  tableData?: ICustomizeKeyMetricTable[]
): ColumnsType<MappedDataType> => {
  const keyMetrics: KeyMetric[] = data[0].keyMetrics;

  const filteredMetrics = keyMetrics.filter((metric) => {
    if (!Array.isArray(tableData)) return true;
    const metricData = tableData.find((item) => item.name === metric.name);
    return metricData ? metricData.selected : true;
  });

  filteredMetrics.sort((a, b) => {
    const indexA = tableData ? tableData.findIndex(item => item.name === a.name) : -1;
    const indexB = tableData ? tableData.findIndex(item => item.name === b.name) : -1;
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  if (filteredMetrics.length === 1) {
    const metric = filteredMetrics[0];
    return [
      {
        title: metric.name as React.ReactNode,
        dataIndex: metric.name,
        key: `keyMetrics0-${metric.id}`,
        width: 100,
        render: (item: any, record: MappedDataType, rowIndex: number) => (
          <Input
            value={item?.value}
            type={"number"}
            onChange={(e) => onInputChange(Number(e.target.value), rowIndex, metric.name)}
          />
        ),
      },
    ];
  }

  return [
    {
      title: "Key Metrics",
      children: filteredMetrics.map((metric, index) => ({
        title: metric.name as React.ReactNode,
        dataIndex: metric.name,
        key: `keyMetrics${index.toString()}-${metric.id}`,
        width: 100,
        render: (item: any, record: MappedDataType, rowIndex: number) => (
          <Input
            value={item?.value}
            type={"number"}
            onChange={(e) => onInputChange(Number(e.target.value), rowIndex, metric.name)}
          />
        ),
      })),
    },
  ];
};

export const KeyMetricsTable = ({data, tableData}: any): JSX.Element => {
  const [dataSource, setDataSource] = useState<MappedDataType[]>([]);

  useEffect(() => {
    setDataSource(mapData(data));
  }, [data]);

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      fixed: 'left',
      render: (value: string, project: any) => (
        <Link to={"/projects/edit?id=" + project.id} className="project-name">
          {value}
        </Link>
      ),
    },
    ...createUniqueKeyMetrics(data, onInputChange, tableData)
  ];

  const updateKeyMetricsDebounced = useMemo(() =>
      _.debounce((metric: KeyMetric) => {
        ProjectApi.updateKeyMetric(metric.id, metric.value).then();
      }, 600),
    []
  );

  function onInputChange(value: number, rowIndex: number, metricName: string): void {
    const updatedDataSource: any = [...dataSource];
    const currentMetric: any = updatedDataSource[rowIndex][metricName];
    currentMetric.value = value;
    setDataSource(updatedDataSource);

    const updateKeyMetric = {
      id: currentMetric.id,
      name: metricName,
      value: value
    };

    updateKeyMetricsDebounced(updateKeyMetric);
  }

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={false}
      rowKey="id"
    />
  )
};
