import * as React from "react";

const SvgArrowDown = (props: any) => {
  return (
    <svg width={10} height={6} viewBox="0 0 10 6" {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/Caret/Standby"
           fill={props.color ? props.color : '#545E6B'}
           fillRule="nonzero">
          <polygon points="0 0 5 6 10 0"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgArrowDown;
