import * as React from "react";

const SvgSearch = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.536 13.121a1 1 0 01-1.415 1.415l-2.644-2.645a6 6 0 111.414-1.414l2.645 2.644zM7 11a4 4 0 100-8 4 4 0 000 8z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSearch;
