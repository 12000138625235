import * as React from "react";

const SvgInsightDesk = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group" transform="translate(-3.000000, 0.000000)">
        <path
          d="M4.5,13 C5.32842857,13 6,13.6715714 6,14.5 C6,15.3284286 5.32842857,16 4.5,16 C3.67157143,16 3,15.3284286 3,14.5 C3,13.6715714 3.67157143,13 4.5,13 Z M10.0588235,0 C12.7877647,0 15,2.08933333 15,4.66666667 C15,7.244 12.7877647,9.33333333 10.0588235,9.33333333 L10.0588235,9.33333333 L5.82352941,9.33333333 L5.823529,11.5 L3,11.5 L3,6.66666667 L10.0588235,6.66666667 C11.2283294,6.66666667 12.1764706,5.77133333 12.1764706,4.66666667 C12.1764706,3.56213333 11.2283294,2.66666667 10.0589647,2.66666667 L10.0589647,2.66666667 L3,2.66666667 L3,0 Z"
          id="Icon/QPGlyph"
          fill={props.color ? props.color : '#545E6B'}/>
      </g>
    </svg>
  );
}

export default SvgInsightDesk;
