import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, Select } from 'antd';
import { EditableContext } from './edtiable-row';
import { State } from './import-table';
import classes from '../../google-drive-integration/tabs/settings/google-drive-project-import.module.scss';
import { AddEditTagsDialog } from '../../../../../../projects/project-edit/add-edit-tags-dialog/add-edit-tags-dialog';
import { IMetaTag } from '../../../../taxonomy/meta-tags/model/meta-tag.interface';
import FormattedDate from '../../../../../../../_shared/helpers/formatted-date';

export const EditableCell = (props: any) => {
  const [editing, setEditing] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(true);
  const [openSelect, setOpenSelect] = useState(true);
  const [metaTagDialog, setMetaTagDialog] = useState<any>({visible: false});
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext);
  const utils = useContext<any>(State);

  useEffect(() => {
    if (editing && inputRef.current) {
      setOpenDatePicker(true);
      setOpenSelect(true);

      if (inputRef?.current.id === "tags") {
        // @ts-ignore
        const tags: number[] = form.getFieldValue('tags');
        setMetaTagDialog({visible: true, tags});
      }

      // @ts-ignore
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    // @ts-ignore
    form.setFieldsValue({
      [props.dataIndex]: props.record[props.dataIndex],
      dueDate: moment(props.record['dueDate'])
    });

  };

  const save = async () => {
    try {
      // @ts-ignore
      const values = await form.validateFields();

      if (values.dueDate) {
        values.dueDate = moment(values.dueDate, 'MMMM D, YYYY').format();
      }

      toggleEdit();
      setOpenDatePicker(false);
      setOpenSelect(false);
      props.handleSave({...props.record, ...values});
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
      setOpenDatePicker(false);
      setOpenSelect(false);
    }
  };

  let childNode = props.children;

  if (props.editable) {
    let input: JSX.Element;
    let domDisplay = props.children;

    if (props.dataIndex === 'projectTypeId' || props.dataIndex === 'programId') {
      if (props.dataIndex === 'projectTypeId') {
        input =
          <Select className="w-100" defaultValue="lucy" ref={inputRef} open={openSelect} style={{width: 120}}
                  onChange={save} onBlur={save}>
            {utils?.projectTypes.map((projectType: any) => {
              return (
                <Select.Option key={projectType.id}
                               value={projectType.id}>
                  {projectType.title}
                </Select.Option>
              )
            })}
          </Select>
      } else {
        input =
          <Select className="w-100" defaultValue="lucy" ref={inputRef} open={openSelect} style={{width: 120}}
                  onChange={save} onBlur={save}>
            {utils?.programs.map((program: any) => {
              return (
                <Select.Option key={program.id}
                               value={program.id}>
                  {program.name}
                </Select.Option>
              )
            })}
          </Select>
      }
    } else {
      input = <Input className="w-100" ref={inputRef} onPressEnter={save} onBlur={save}/>
    }


    if (props.dataIndex === 'dueDate') {
      domDisplay = <FormattedDate date={domDisplay[1]} formatString="MMM d, yyyy"/>
      input = <DatePicker className="w-100" ref={inputRef} onClick={() => setOpenDatePicker(true)}
                          onChange={() => setOpenDatePicker(false)} open={openDatePicker} onBlur={save}/>
    }

    if (props.dataIndex === 'description') {
      input = <Input.TextArea autoSize={true} className="w-100" ref={inputRef} onPressEnter={save} onBlur={save}/>
    }

    if (props.dataIndex === 'projectTypeId') {
      if (domDisplay[1] && utils?.projectTypes) {
        domDisplay = utils?.projectTypes?.find((form: any) => domDisplay[1] === form.id)?.title;
      }
    }

    if (props.dataIndex === 'programId') {
      if (domDisplay[1] && utils?.programs) {
        domDisplay = utils?.programs?.find((program: any) => domDisplay[1] === program.id)?.name;
      }
    }

    if (props.dataIndex === 'tags') {
      const tagsName: string[] = [];
      const tags: any[] = [];
      const tagsNumbers: any[] = domDisplay[1];

      tagsNumbers?.forEach((tagNumber: number) => {
        utils?.tags.forEach((metatag: IMetaTag) => {
          metatag.tags.forEach((tag: any) => {
            if (tagNumber === tag.id) {
              tags.push(tag);
              tagsName.push(tag.name);
            }
          });
        })
      });

      domDisplay = tags.map((tag: any, i: number) => {
        return (
          <span key={tag.id} style={{paddingRight: 3}}>
            {tag.name}{i === tags.length - 1 ? '' : ','}
          </span>
        )
      });

      input = <div className="w-100 editable-cell-value-wrap" ref={inputRef}>
        {domDisplay}
      </div>
    }

    childNode = editing ? (
      <Form.Item
        style={{margin: 0}}
        name={props.dataIndex}
        rules={[{required: false, message: `Field is required.`}]}>
        {input}
      </Form.Item>
    ) : (
      <div className={`editable-cell-value-wrap + ${classes.EditableCellValueWrap}`} style={{paddingRight: 24}}
           onClick={toggleEdit}>
        {domDisplay}
      </div>
    );
  }


  function onTagSave(data: { modal: boolean, tags: number[] }): void {
    if (data.modal && data?.tags?.length) {
      // @ts-ignore
      form.setFieldsValue({
        tags: data.tags
      });
    }

    onTagCancel();
  }

  function onTagCancel(): void {
    setMetaTagDialog({...metaTagDialog, visible: false});
    save();
  }

  return <>
    <td {...props.restProps}>{childNode}</td>
    {metaTagDialog.visible &&
        <AddEditTagsDialog data={metaTagDialog} googleDrive={true} onSave={onTagSave} onCancel={onTagCancel}/>}
  </>;
};
