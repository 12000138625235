import React, { useContext, useState } from "react";
import { CheckCircleTwoTone, CopyOutlined, WindowsOutlined, } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import { DebounceInput } from "react-debounce-input";
import SvgTrash from "../../../../../../assets/icons/js/Trash";
import { AuthorizedEmailDomain, IPortalSettings, } from "../model/portal-settings.interface";
import DeskSettingsApi from "../../../../api/settings-api";
import { IPortalSettingsProps } from "./model/portal-settings-props.interface";
import { AddEditAuthorizedEmailDialog } from "../add-edit-authorized-email.dialog/add-edit-authorized-email.dialog";
import { queryClient } from "../../../../../../index";
import GlobalContext from '../../../../../../store/global-context';
import { IUserData } from '../../../../../../core/model/user-data.interface';
import { getInstance } from "../../../../../../_shared/helpers/domain-instance";

const PortalSettings = (props: IPortalSettingsProps): JSX.Element => {
  const GlobalStore = useContext(GlobalContext);
  const userData: IUserData = GlobalStore.user;
  const [publicUrl, setPublicUrl] = useState(props?.publicUrl);
  const [selectedSSO, setSelectedSSO] = useState(props?.ssoProvider);
  const [authorizedEmailDomains, setAuthorizedEmailDomains] = useState(
    props?.authorizedEmailDomains
  );
  const [publicUrlInputWidth, setPublicInputWidth] = useState(
    (props?.publicUrl?.length + 2) * 5.4
  );
  const [portalSettingsHasValues, setPortalSettingHasValues] = useState(
    !!(publicUrl && selectedSSO && authorizedEmailDomains.length > 0)
  );
  const [portalSettingsSaving, setPortalSettingsSaving] = useState(false);
  const [portalSettingsError, setPortalSettingsError] = useState([]);
  const [copiedUrl, setCopiedUrl] = useState(false);
  const [authorizedEmailDialog, setAuthorizedEmailDialog] = useState({
    visible: false
  });

  const columns: any[] = [
    {
      title: "Domain",
      dataIndex: "emailDomain",
      key: "emailDomain",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "action",
      align: "right",
      render: (empty: any, emailDomain: any, index: number) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <Tooltip placement="bottom" title="Delete Email Domain">
              <button
                className="icon-button"
                onClick={() => deleteEmailDomain(index, emailDomain?.id)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  const deleteEmailDomain = (index: number, id?: number): void => {
    let aE: AuthorizedEmailDomain[] = [...authorizedEmailDomains];

    if (id) {
      aE = aE.filter((item: AuthorizedEmailDomain) => item.id !== id);
    } else {
      aE.splice(index, 1);
    }

    setAuthorizedEmailDomains(aE);
    setPortalSettingHasValues(false);
  };


  const copyPublicUrl = (): void => {
    navigator.clipboard.writeText(
      `https://${publicUrl}.insightshub.questionpro.${getInstance(userData)}`
    );

    setCopiedUrl(true);

    setTimeout(() => setCopiedUrl(false), 3000);
  };

  const openPortal = (): void => {
    window.open(`https://${publicUrl}.insightshub.questionpro.${getInstance(userData)}`);
  };

  const openAddEditAuthorizedEmailDialog = (): void => {
    setAuthorizedEmailDialog({visible: true});
  };

  const submitPortalSettings = (): void => {
    if (!selectedSSO && !publicUrl && authorizedEmailDomains!.length > 0) {
      return;
    }

    const payload: IPortalSettings = {
      ssoProvider: selectedSSO,
      publicUrl: publicUrl,
      authorizedEmailDomains: authorizedEmailDomains,
    };

    setPortalSettingsSaving(true);

    DeskSettingsApi.updatePortalSettings(payload).then(
      (response) => {
        setPortalSettingHasValues(true);
        setPortalSettingsSaving(false);
        queryClient.invalidateQueries("organisationSettings");
      },
      (error: any) => {
        setPortalSettingsError(error.response.data.message);
        setPortalSettingsSaving(false);
      }
    );
  };

  const onDialogConfirm = (props: any): void => {
    if (props.modal) {
      const authorizedEmail: AuthorizedEmailDomain[] = authorizedEmailDomains.slice();
      authorizedEmail.push(...props.authorizedEmail);
      setPortalSettingHasValues(false);
      setAuthorizedEmailDomains([...authorizedEmail.slice()]);
      onDialogCancel();
    }
  };

  const onDialogCancel = (): void => {
    setAuthorizedEmailDialog({visible: false});
  };

  return (
    <>
      <div className="portal-settings">
        <div className="row">
          <div className="outline-input-wrapper fxFlex relative">
            <span className="outline-input-label">Url</span>
            <div className="row">
              <input
                className="outline-input"
                style={{
                  width: "7%",
                  paddingRight: 0,
                  pointerEvents: "none",
                  backgroundColor: "#e4e4e4",
                }}
                value="https://"
                readOnly
              />
              <DebounceInput
                name="portal-url"
                className="outline-input"
                placeholder="example"
                style={{
                  width: publicUrlInputWidth ? publicUrlInputWidth : 47,
                  minWidth: 47,
                  padding: 0,
                  margin: "0 4px",
                }}
                onKeyPress={(e: any) => {
                  setPublicInputWidth((e.target.value.length + 2) * 5.4);
                }}
                value={publicUrl}
                onChange={(e) => {
                  setPublicUrl(e.target.value);
                  setPortalSettingHasValues(false);
                  setPublicInputWidth((e.target.value.length + 2) * 5.4);
                }}
              />
              <input
                className="outline-input d-flex fxFlex"
                style={{
                  paddingLeft: 0,
                  pointerEvents: "none",
                  backgroundColor: "#e4e4e4",
                }}
                value={`.insightshub.questionpro.${getInstance(userData)}`}
                readOnly
              />
              <Tooltip placement="bottom" title="Copy Url">
                <Button
                  type="primary"
                  className="copy-url"
                  aria-label={"Copy Url"}
                  onClick={() => copyPublicUrl()}
                >
                  {copiedUrl ? (
                    <CheckCircleTwoTone/>
                  ) : (
                    <CopyOutlined
                      style={{
                        fontSize: 16,
                      }}
                    />
                  )}
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Open Portal">
                <Button
                  type="primary"
                  className="new-window"
                  aria-label={"Open Portal"}
                  disabled={!portalSettingsHasValues || publicUrl.length <= 1}
                  onClick={() => openPortal()}
                >
                  <WindowsOutlined/>
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="table">
          <div className="title">
            <span>Authorized email domains</span>
            <span
              className="link"
              onClick={() => openAddEditAuthorizedEmailDialog()}
            >
              Add new
            </span>
          </div>
          {columns.length && (
            <Table
              columns={columns}
              pagination={false}
              dataSource={authorizedEmailDomains}
              rowKey="id"
            />
          )}
        </div>
        <div className="portal-error">
          {portalSettingsError &&
            portalSettingsError?.map((error: string, i: number) => {
              return (
                <span key={i} className="error">
                  {error}
                </span>
              );
            })}
        </div>
        <div className="save-button column align-items-end">
          <Button
            type="primary"
            loading={portalSettingsSaving}
            icon={portalSettingsHasValues && <CheckCircleTwoTone/>}
            onClick={() => !portalSettingsHasValues && submitPortalSettings()}
          >
            {portalSettingsHasValues ? "Saved" : "Save"}
          </Button>
        </div>
      </div>
      {authorizedEmailDialog && (
        <AddEditAuthorizedEmailDialog
          data={authorizedEmailDialog}
          onConfirm={onDialogConfirm}
          onCancel={onDialogCancel}
        />
      )}
    </>
  );
};

export default PortalSettings;
