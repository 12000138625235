// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
}
.container .main-wrapper {
  padding: 32px 5.1vw 0 3.24vw;
  width: 100%;
  overflow: auto;
}
.container .main-wrapper .client-hub-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background-color: #FFEEAB;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #545e6b;
}

.stop-impersonate-button {
  top: 8px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}`, "",{"version":3,"sources":["webpack://./src/core/components/layout/layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,4BAAA;EACA,WAAA;EACA,cAAA;AACJ;AACI;EACE,eAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACN;;AAKA;EACE,QAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;AAFF","sourcesContent":[".main {\n  display: flex;\n  height: 100vh;\n  min-height: 100%;\n  min-width: 100%;\n  width: 100%;\n  flex-direction: column;\n  box-sizing: border-box;\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  margin: 0 auto;\n  height: 100%;\n  overflow: hidden;\n\n  .main-wrapper {\n    padding: 32px 5.1vw 0 3.24vw;\n    width: 100%;\n    overflow: auto;\n\n    .client-hub-footer {\n      position: fixed;\n      bottom: 0;\n      left: 0;\n      width: 100%;\n      height: 25px;\n      background-color: #FFEEAB;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      color: #545e6b;\n    }\n  }\n}\n\n\n.stop-impersonate-button {\n  top: 8px;\n  z-index: 9999;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
