import * as React from "react";

const SvgMove = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g fill={props.color ? props.color : '#545E6B'}>
        <path d="M11 8v2h3l-5 6-5-6h3V9c0-4.667-1.667-7.667-5-9 6 0 9 2.667 9 8z"
              transform="translate(-715.000000, -622.000000) translate(680.000000, 570.000000) translate(31.000000, 0.000000) translate(12.000000, 60.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -60.000000) translate(4.000000, 52.000000) translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000)"/>
      </g>
    </svg>
  );
}

export default SvgMove;
