import { CompositeDecorator } from 'draft-js'
import React from 'react'
import IFrameStrategy from './strategies/iframe-strategy/iframe-strategy';
import ImageStrategy from './strategies/image-strategy/image-strategy';
import { LinkStrategy } from './strategies/link-strategy';
import { ProjectStrategy } from './strategies/project-strategy/project-strategy';
import GraphStrategy from './strategies/graph-strategy/graph-strategy';
import { RecommendationStrategy } from "./strategies/recommendation-strategy/recommendation-strategy";


export const compositeDecorator = (readOnly?: boolean,
                                   openHyperlinkModal?: any,
                                   openLinkProjectModal?: any,
                                   onOpenQuickActionMenu?: any,
                                   insertImage?: any,
                                   onOpenImageMenu?: any,
                                   updateViewDataForImageMenu?: any,
                                   insertRecommendation?: () => void) => new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
      }, callback);
    },
    component: LinkStrategy,
    props: {readOnly, openHyperlinkModal}
  },
  {
    strategy: (ContentBlock, callback, contentState) => {
      ContentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "PROJECT";
      }, callback);
    },
    component: ProjectStrategy,
    props: {readOnly, openLinkProjectModal, onOpenQuickActionMenu}
  },
  {
    strategy: (ContentBlock, callback, contentState) => {
      ContentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "FIGURE";
      }, callback)
    },
    component: ImageStrategy,
    props: {readOnly, insertImage, onOpenImageMenu, updateViewDataForImageMenu}
  },
  {
    strategy: (ContentBlock, callback, contentState) => {
      ContentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "IFRAME";
      }, callback)
    },
    component: IFrameStrategy,
    props: {readOnly}
  },
  {
    strategy: (ContentBlock, callback, contentState) => {
      ContentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "GRAPH";
      }, callback)
    },
    component: GraphStrategy,
    props: {readOnly}
  },
  {
    strategy: (ContentBlock, callback, contentState) => {
      ContentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "RECOMMENDATION";
      }, callback)
    },
    component: RecommendationStrategy,
    props: {readOnly}
  }
])
