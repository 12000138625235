import { Divider } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../../../../../core/hooks/use-outside-click';
import "./blocks-menu.scss"
import { EditorState, RichUtils, SelectionState } from 'draft-js';
import SvgInsightDesk from "../../../../../../assets/icons/js/InsightDesk";
import SvgSubtitle from "../../../../../../assets/icons/js/subtitle";
import SvgHeading from "../../../../../../assets/icons/js/heading";
import SvgText from "../../../../../../assets/icons/js/text";
import SvgBulletedList2 from "../../../../../../assets/icons/js/bulleted-list-2";
import SvgNumberedList2 from "../../../../../../assets/icons/js/numbered-list-2";
import ImageIcon from "../../../../../../assets/icons/js/ImageIcon";
import SvgFiletypeHtml from "../../../../../../assets/icons/js/SvgFiletypeHtml";
import { removeLastCharacter } from "../../helpers/remove-last-character";
import SvgRobot from "../../../../../../assets/icons/js/robot";


type MenuItem = {
  icon: JSX.Element,
  text: string,
  blockType: string,
  fontSize?: number
}

type BlocksMenuProps = {
  data: { visible: boolean, position: any },
  editorState: EditorState,
  onSetEditorState: (editorState: EditorState) => void,
  close: () => void,
  openAddImageModal: () => void,
  openGraphDialog: () => void,
  openIFrameModal: () => void,
  insertRecommendation: () => void
  showAskIHInputOnEditor: (generateFrom: "content" | "linkedProjects", selectedContent?: SelectionState) => void
}

const BlocksMenu = (props: BlocksMenuProps) => {
  const component = useRef<any>(null);

  const menu: MenuItem[] = [
    {
      icon: <SvgSubtitle/>,
      fontSize: 22,
      text: "Subtitle",
      blockType: 'header-two',
    },
    {
      icon: <SvgHeading/>,
      fontSize: 18,
      text: "Heading",
      blockType: "header-three"
    },
    {
      icon: <SvgText/>,
      fontSize: 14,
      text: "Text",
      blockType: "unstyled"
    },
    {
      icon: <SvgBulletedList2/>,
      text: "Bulleted List",
      blockType: "unordered-list-item"
    },
    {
      icon: <SvgNumberedList2/>,
      text: "Numbered List",
      blockType: "ordered-list-item"
    },
    {
      icon: <SvgRobot color={"#545e6b"}/>,
      text: "Write with AskIH...",
      blockType: "ask-ih"
    },
    {
      icon: <SvgRobot color={"#545e6b"}/>,
      text: "Generate from project",
      blockType: "ask-ih-project"
    },
    {
      icon: <ImageIcon height={20}/>,
      text: "Image",
      blockType: "image"
    },
    {
      icon: <SvgFiletypeHtml/>,
      text: "iFrame",
      blockType: "iframe"
    },
    {
      icon: <span style={{marginLeft: 5}}>
        <SvgInsightDesk width={14}/>
      </span>,
      text: "Survey Chart",
      blockType: "graph"
    },
    {
      icon: <i className="bi bi-hand-thumbs-up-fill" style={{fontSize: 14, color: "#545e6b"}}/>,
      text: "Recommendation",
      blockType: "recommendation"
    }
  ];

  const [indexSelected, setIndexSelected] = useState(-1);
  const {editorState, onSetEditorState} = props;
  const blockType = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType();

  useEffect(() => {
    if (props.data.visible) {
      component.current.focus();
    }
  }, [])

  useEffect(() => {
    const removeScrolling = (e: KeyboardEvent) => {
      if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
        e.preventDefault();
      }
    }

    window.addEventListener('keydown', removeScrolling);
    return () => {
      window.removeEventListener('keydown', removeScrolling)
    };
  }, []);

  useOnClickOutside(component, () => {
    props.close();
  });

  const handleBlockClick = (blockType: string, e?: React.MouseEvent) => {
    // handles block type for heading text ...
    e?.preventDefault();
    const newEditorState = removeLastCharacter(editorState);

    if (blockType === "image") {
      props.openAddImageModal()
      onSetEditorState(newEditorState);
      props.close();
      return;
    }


    if (blockType === "iframe") {
      props.openIFrameModal();
      onSetEditorState(newEditorState)
      props.close();
      return;
    }

    if (blockType === "graph") {
      props.openGraphDialog();
      onSetEditorState(newEditorState);
      props.close();
      return;
    }

    if (blockType === "recommendation") {
      props.insertRecommendation();
      //onSetEditorState(newEditorState);
      props.close();
      return;
    }

    if (blockType === "ask-ih") {
      props.showAskIHInputOnEditor('content');
      props.close();
      return;
    }

    if (blockType === "ask-ih-project") {
      props.showAskIHInputOnEditor('linkedProjects');
      props.close();
      return;
    }


    onSetEditorState(RichUtils.toggleBlockType(newEditorState, blockType));
    props.close();
  };

  const isActive = (type: string) => {
    return blockType === type ? 'active-button' : '';
  }

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      if (indexSelected === menu.length - 1) {
        setIndexSelected(0);
        return
      }
      setIndexSelected(prev => prev + 1);
      return;
    }
    if (e.key === "ArrowUp") {
      if (indexSelected === -1 || indexSelected === 0) {
        setIndexSelected(menu.length - 1)
        return;
      }
      setIndexSelected(prev => prev - 1);
      return;
    }
    if (e.key === "Enter") {
      if (indexSelected === -1) {
        return;
      }
      handleBlockClick(menu[indexSelected].blockType);
      return;
    }
    //props.close();
  }

  return (
    <>
      {
        props.data.visible ?
          <div ref={component} style={props.data.position} tabIndex={0} onKeyDown={handleOnKeyDown}
               data-testid={"blocks-menu"}
               className='blocks-menu-wrapper'>
            {
              menu.map((menuItem: any, i: number) => {
                return <span key={i}>
                  <div
                    className={isActive(menuItem.blockType) ? 'menu-item active-button' : 'menu-item'}
                    style={{backgroundColor: indexSelected === i ? "#F5F5F5" : "#ffffff"}}
                    onClick={(e: React.MouseEvent) => handleBlockClick(menuItem.blockType, e)}>
                  <span style={{fontSize: menuItem.fontSize}} className='menu-item-icon'>{menuItem.icon}</span>
                  <span className='menu-item-text'> {menuItem.text} </span>
                </div>
                  {(i === 4 || i === 6) && <Divider className="blocks-menu-divider"/>}
                  {i === 4 && <span className="use-ai-label">Ask AI</span>}
                </span>
              })
            }
          </div> :
          <></>
      }
    </>
  )
}

export default BlocksMenu
