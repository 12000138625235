import React, { useEffect, useRef, useState } from 'react';
import CreateEditNoteDialog from "./create-edit-note-dialog/create-edit-note-dialog";
import "./notes.scss"
import { useQuery } from "react-query";
import NotesApi from "./api/notes-api";
import notesApi from "./api/notes-api";
import { Loader } from "../../../../../_shared/components/loader/loader";
import { INote } from "./model/note.interface";
import NoteTile from "./note-tile/note-tile";
import { useOnLoadImages } from "../../../../../core/hooks/use-on-load-images";
import SvgAddBig from "../../../../../assets/icons/js/addBig";
import { Button } from "antd";
import Pagination from "../../../../../_shared/components/pagination/pagination";

const Notes = (props: any) => {
  const notesRef = useRef(null);

  const [createEditNoteDialog, setCreateEditNoteDialog] = useState<any>({visible: false});
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const notes = useQuery(['notes', props.project.id, page, pageSize], () => NotesApi.getNotes(props.project.id, {
    page,
    pageSize
  }));

  const handlePagination = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  const openCreateEditNoteDialog = (noteId?: number) => {
    setCreateEditNoteDialog({visible: true, projectId: props.project.id, noteId: noteId})
  }

  const closeCreateEditNoteDialog = () => {
    setCreateEditNoteDialog({visible: false})
  }

  const deleteNote = (noteId: number) => {
    notesApi.deleteNote(noteId).then(() => {
      notes.refetch()
    })
  }

  return (
    <div className="notes-wrapper">
      <div className="row justify-space-between">
        <Button type='primary'
                onClick={() => openCreateEditNoteDialog()}
                icon={<span className="anticon"><SvgAddBig width={14} height={14} color={'#fff'}/></span>}>
          New note
        </Button>
        {
          notes.data && notes.data.total > 0 &&
          <Pagination currentPage={page} totalItems={notes.data.total} showSizeChanger pageSize={pageSize}
                      onChange={handlePagination}/>
        }
      </div>

      {
        notes.isLoading ? <Loader/> :
          <>
            {
              notes.data &&
              notes.data.total === 0 &&
              <h3>
                Create a new note to get started
              </h3>
            }

            <div className='notes-library-wrapper' ref={notesRef}>
              {
                notes.data &&
                notes.data.data.map((note: INote, index) => {
                  return <NoteTile note={note}
                                   key={index}
                                   openCreateEditNoteDialog={openCreateEditNoteDialog}
                                   deleteNote={deleteNote}/>
                })
              }
            </div>
          </>
      }

      {
        createEditNoteDialog.visible &&
          <CreateEditNoteDialog onCancel={closeCreateEditNoteDialog} visible={createEditNoteDialog.visible}
                                data={createEditNoteDialog}/>
      }
    </div>
  );
};

export default Notes;
