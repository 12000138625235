import React from 'react';

const SvgQPLogoBig = (props: any) => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.5 39.5C12.9853 39.5 15 41.5147 15 44C15 46.4853 12.9853 48.5 10.5 48.5C8.01471 48.5 6 46.4853 6 44C6 41.5147 8.01471 39.5 10.5 39.5ZM27.1765 0.5C35.3633 0.5 42 6.768 42 14.5C42 22.232 35.3633 28.5 27.1765 28.5H14.4706L14.4706 35H6V20.5H27.1765C30.685 20.5 33.5294 17.814 33.5294 14.5C33.5294 11.1864 30.685 8.5 27.1769 8.5H6V0.5H27.1765Z"
            fill={props?.color ? props.color : "#1B87E6"}/>
    </svg>

  );
};

export default SvgQPLogoBig;
