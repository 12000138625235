import React, { useState } from 'react';
import './program.scss';
import { ColumnsType } from 'antd/lib/table';
import { Button, Table, Tooltip } from 'antd';
import SvgEdit from '../../../../../assets/icons/js/Edit';
import SvgTrash from '../../../../../assets/icons/js/Trash';
import DeskSettingsApi from '../../../api/settings-api';
import { IProgram } from './model/program.interface';
import { ConfirmationDialog } from '../../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import { AddEditProgramsDialog } from './add-edit-programs-dialog/add-edit-programs-dialog';
import { useQuery } from 'react-query';
import Pagination from "../../../../../_shared/components/pagination/pagination";

export function Programs(): JSX.Element {
  const programsColumns: ColumnsType<IProgram> = [
    {
      title: 'Program',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (value: string) => <span className="email">{value}</span>
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: 900,
      render: (value: string) => <span>{value}</span>
    },
    {
      title: 'Projects',
      dataIndex: 'projectNumber',
      key: 'projectNumber',
      align: 'center'
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      align: 'right',
      render: (program: IProgram) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <Tooltip placement="bottom" title="Edit Program">
              <button className="icon-button" hidden={program.name === 'General'}
                      onClick={() => openAddEditProgramDialog(program)}>
                <SvgEdit/>
              </button>
            </Tooltip>

            <Tooltip placement="bottom" title="Delete Program">
              <button className="icon-button" hidden={program.name === 'General'}
                      onClick={() => openConfirmationDialog(program)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});
  const [modalData, setModalData] = useState<any>({visible: false});
  const programs = useQuery(['allPrograms', page, pageSize], () => DeskSettingsApi.getPrograms(page, pageSize), {
    onSuccess: (data) => {
      setTotalSize(data.totalSize);
    }
  });

  const deleteProgram = (id: number | undefined) => {
    if (id) {
      DeskSettingsApi.deleteProgram(id)
        .then(() => {
          programs.refetch();
        });
    }
  }

  const openConfirmationDialog = (program: IProgram) => {
    setConfirmationDialog({
      visible: true,
      id: program.id,
      okText: 'Delete',
      title: 'Delete Program',
      titleSec: 'You\'re about to delete this Program',
      content: `${program.name}`,
      description: <>
        Continuing will remove the Program from this
        list{program.projectNumber && program.projectNumber > 0 ? `, and removes the Program from ${program.projectNumber} projects` : ''}.
      </>
    })
  }

  const openAddEditProgramDialog = (program?: any) => {
    if (program?.id) {
      setModalData({
        id: program.id,
        formData: {name: program.name, description: program.description, groups: program.groups},
        visible: true
      })
    } else {
      setModalData({...modalData, visible: true})
    }
  }

  const onConfirmationDialogConfirm = (props: any) => {
    if (props.modal) {
      deleteProgram(props.id);
      onCancel();
    }
  }

  const onOk = (props: any) => {
    if (props.modal) {
      onCancel();
      programs.refetch();
    }
  }

  const onCancel = () => {
    setModalData({visible: false});
    setConfirmationDialog({visible: false});
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  return <div className="programs-wrapper">
    <div className="programs">
      <div className="row justify-space-between" style={{marginBottom: 20}}>
        <Button type="primary" onClick={() => openAddEditProgramDialog()}>Add program</Button>
        <Pagination pageSize={pageSize}
                    showSizeChanger={true}
                    currentPage={page}
                    totalItems={totalSize}
                    onChange={handlePaginationChange}/>
      </div>
      <div className="body">
        <Table<IProgram> columns={programsColumns}
                         pagination={false}
                         loading={programs.isLoading}
                         dataSource={programs?.data ? programs?.data.content : []}
                         rowKey="id"/>
        </div>
      </div>
    {modalData.visible &&
        <AddEditProgramsDialog data={modalData} onOk={onOk} onCancel={onCancel}/>
    }

    {confirmationDialog.visible &&
        <ConfirmationDialog data={confirmationDialog}
                            onConfirm={onConfirmationDialogConfirm}
                            onCancel={onCancel}/>
    }
  </div>;
}
