import React from 'react';
import FileSearchCard from "./file-search-card/file-search-card";
import { UseQueryResult } from "react-query";
import { ISearchFile, ISearchFileData } from "../../models/search-file.interface";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import './files.scss'
import { Empty } from "antd";

const Files = (props: { searchFiles: UseQueryResult<ISearchFileData, unknown> }) => {
  const {searchFiles} = props;

  return (
    <>
      {
        !searchFiles?.isLoading ?
          <div className='search-files'>
            {
              searchFiles?.data &&
              searchFiles?.data?.total > 0 ?
                searchFiles?.data.files.map((file: ISearchFile, index: number) => {
                  return <FileSearchCard file={file} key={index}/>
                }) :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            }
          </div>
          :
          <>
            <Loader/>
          </>
      }
    </>
  );
};

export default Files;
