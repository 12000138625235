// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archived {
  padding: 0 20px;
}
.archived .archive-column {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 34px;
}
.archived .archive-column:last-child {
  margin-bottom: 10px;
}
.archived .archive-column > h3 {
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/archived/archived.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACI;EACE,mBAAA;AACN;AAEI;EACE,oBAAA;AAAN","sourcesContent":[".archived {\n  padding: 0 20px;\n\n  .archive-column {\n    display: flex;\n    flex-direction: column;\n    margin-top: 32px;\n    margin-bottom: 34px;\n\n    &:last-child {\n      margin-bottom: 10px;\n    }\n\n    > h3 {\n      padding-bottom: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
