import { InitialOperationsDialogsState, OperationsDialogReducersActions, } from "./operations-dialogs-reducer.types";

export const initialOperationsDialogsState: InitialOperationsDialogsState = {
  surveyLinkDialog: {
    visible: false,
  },
  surveyLinkConfirmationDialog: {
    visible: false,
    id: '',
    okText: '',
    title: '',
    titleSec: '',
    content: '',
    description: <></>,
  },
  externalLinkDialog: {
    visible: false,
  },
  externalLinkConfirmationDialog: {
    visible: false,
    externalLink: false,
    id: '',
    okText: '',
    title: '',
    titleSec: '',
    content: '',
    description: <></>,
  },
}

export function OperationsDialogsReducer(state: InitialOperationsDialogsState = initialOperationsDialogsState, action: OperationsDialogReducersActions): InitialOperationsDialogsState {
  switch (action.type) {
    case "set-external-link-dialog-action":
      return {...state, externalLinkDialog: action.payload}
    case "set-survey-link-dialog-action":
      return {...state, surveyLinkDialog: action.payload}
    case "set-survey-link-confirmation-dialog-action":
      return {...state, surveyLinkConfirmationDialog: action.payload}
    case "set-external-link-confirmation-dialog-action":
      return {...state, externalLinkConfirmationDialog: action.payload}
    case "set-dialogs-hidden":
      return {...initialOperationsDialogsState};
    default:
      return {...initialOperationsDialogsState};
  }
}
