import { EditorState } from "draft-js";

const extractSelectionDetails = (editorState: EditorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  let startKey, startOffset, endKey, endOffset;

  if (selectionState.isCollapsed()) {
    // No selection, simulate an entire select the entire content
    const firstBlock = contentState.getBlockMap().first();
    const lastBlock = contentState.getBlockMap().last();

    startKey = firstBlock.getKey();
    startOffset = 0;

    endKey = lastBlock.getKey();
    endOffset = lastBlock.getLength();
  } else {
    // Get actual selection details
    startKey = selectionState.getStartKey();
    startOffset = selectionState.getStartOffset();

    endKey = selectionState.getEndKey();
    endOffset = selectionState.getEndOffset();
  }

  return {
    start: {
      blockKey: startKey,
      offset: startOffset,
    },
    end: {
      blockKey: endKey,
      offset: endOffset,
    },
  };
};


export { extractSelectionDetails };