import { environment } from '../../../../../environment';
import { IGuideTask } from '../model/guide-task.interface';
import { axiosClient } from '../../../../../axios-client';
import { AxiosResponse } from 'axios';
import { IFlagType } from '../model/flag-type.interface';

class InterviewLabsSettingsApi {
  baseUrl: string = `${environment.backendUrl}`;

  async get(): Promise<IGuideTask> {
    try {
      const response: AxiosResponse<IGuideTask> =
        await axiosClient.get<IGuideTask>(`${this.baseUrl}/forms/guide/task`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async update(guideTask: IGuideTask): Promise<IGuideTask> {
    try {
      const response: AxiosResponse<IGuideTask> =
        await axiosClient.put<IGuideTask>(`${this.baseUrl}/forms/guide/task`, guideTask);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createFlag(payload: IFlagType): Promise<IFlagType> {
    try {
      const response: AxiosResponse<IFlagType> = await axiosClient.post(`${this.baseUrl}/flags`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getFlags(): Promise<IFlagType[]> {
    try {
      const response: AxiosResponse<IFlagType[]> = await axiosClient.get(`${this.baseUrl}/flags`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateFlag(id: number, payload: IFlagType): Promise<IFlagType> {
    try {
      const response: AxiosResponse<IFlagType> = await axiosClient.put(`${this.baseUrl}/flags/${id}`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFlag(id: number): Promise<any> {
    const response: AxiosResponse<any> = await axiosClient.delete(`/flags/${id}`);
    return response.data;
  }
}

export default new InterviewLabsSettingsApi();
