import React from 'react';
import './custom-theme-snapshot.scss'
import TextFit from "../../../../../_shared/components/text-fit/text-fit";
import SvgQpLogoIH from "../../../../../assets/icons/js/qp-logo-ih";
import { format } from "date-fns";
import BottomTheme from "./components/custom-bottom-theme";
import RightTheme from "./components/custom-right-theme";
import LeftTheme from "./components/custom-left-theme";
import DefaultTheme from "./components/custom-default-theme";

const CustomThemeSnapshot = (props: any) => {
  let scale = 1;
  let transformOrigin = '';
  const {snapshotTheme} = props;


  if (props?.mdSize) {
    scale = 0.34;
    transformOrigin = 'top left';
  }

  if (props?.smSize) {
    scale = 0.23;
    transformOrigin = 'top left';
  }

  const colorToCssString = (color: any) => {
    const {r, g, b, a} = JSON.parse(color || "{\"r\":0,\"g\":0,\"b\":0,\"a\":1}");
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };


  const getGradientOverlayStyle = () => {
    const {r, g, b} = JSON.parse(snapshotTheme?.overlayColor);
    switch (snapshotTheme?.layout) {
      case 'leftAligned':
        return `linear-gradient(90deg, 
              rgba(${r}, ${g}, ${b}, 0.9) 15%, 
              rgba(${r}, ${g}, ${b}, 0.85) 37.5%, 
              rgba(${r}, ${g}, ${b}, 0.5) 62.5%, 
              rgba(${r}, ${g}, ${b}, 0) 100%)`

      case 'rightAligned':
        return `linear-gradient(90deg, 
              rgba(${r}, ${g}, ${b}, 0) 0%,
              rgba(${r}, ${g}, ${b}, 0.5) 37.5%, 
              rgba(${r}, ${g}, ${b}, 0.85) 62.5%, 
              rgba(${r}, ${g}, ${b}, 0.9) 85%)`

      case 'bottomAligned':
        return `linear-gradient(0deg, 
              rgba(${r}, ${g}, ${b}, 0.9) 15%, 
              rgba(${r}, ${g}, ${b}, 0.884718) 21.65%, 
              rgba(${r}, ${g}, ${b}, 0.862667) 27.48%, 
              rgba(${r}, ${g}, ${b}, 0.833934) 32.67%, 
              rgba(${r}, ${g}, ${b}, 0.798608) 37.36%, 
              rgba(${r}, ${g}, ${b}, 0.756777) 41.71%, 
              rgba(${r}, ${g}, ${b}, 0.708527) 45.86%, 
              rgba(${r}, ${g}, ${b}, 0.653949) 49.98%, 
              rgba(${r}, ${g}, ${b}, 0.593128) 54.21%, 
              rgba(${r}, ${g}, ${b}, 0.526154) 58.71%, 
              rgba(${r}, ${g}, ${b}, 0.453114) 63.64%, 
              rgba(${r}, ${g}, ${b}, 0.374095) 69.14%, 
              rgba(${r}, ${g}, ${b}, 0.289187) 75.38%, 
              rgba(${r}, ${g}, ${b}, 0.198476) 82.5%, 
              rgba(${r}, ${g}, ${b}, 0.102051) 90.65%, 
              rgba(${r}, ${g}, ${b}, 0) 100%)`
      default:
        return `linear-gradient(0deg, 
              rgba(${r}, ${g}, ${b}, 0.9) 15%, 
              rgba(${r}, ${g}, ${b}, 0.884718) 21.65%, 
              rgba(${r}, ${g}, ${b}, 0.862667) 27.48%, 
              rgba(${r}, ${g}, ${b}, 0.833934) 32.67%, 
              rgba(${r}, ${g}, ${b}, 0.798608) 37.36%, 
              rgba(${r}, ${g}, ${b}, 0.756777) 41.71%, 
              rgba(${r}, ${g}, ${b}, 0.708527) 45.86%, 
              rgba(${r}, ${g}, ${b}, 0.653949) 49.98%, 
              rgba(${r}, ${g}, ${b}, 0.593128) 54.21%, 
              rgba(${r}, ${g}, ${b}, 0.526154) 58.71%, 
              rgba(${r}, ${g}, ${b}, 0.453114) 63.64%, 
              rgba(${r}, ${g}, ${b}, 0.374095) 69.14%, 
              rgba(${r}, ${g}, ${b}, 0.289187) 75.38%, 
              rgba(${r}, ${g}, ${b}, 0.198476) 82.5%, 
              rgba(${r}, ${g}, ${b}, 0.102051) 90.65%, 
              rgba(${r}, ${g}, ${b}, 0) 100%)`
    }
  }


  const getThemeOverlay = () => {
    switch (snapshotTheme?.layout) {
      case 'leftAligned':
        return <LeftTheme snapshotTheme={snapshotTheme}
                          createdBy={props.createdBy}
                          createdAt={props.createdAt}
                          nugget={props.nugget}
                          project={props.project}/>

      case 'rightAligned':
        return <RightTheme snapshotTheme={snapshotTheme}
                           createdBy={props.createdBy}
                           createdAt={props.createdAt}
                           nugget={props.nugget}
                           project={props.project}/>

      case 'bottomAligned':
        return <BottomTheme snapshotTheme={snapshotTheme}
                            createdBy={props.createdBy}
                            createdAt={props.createdAt}
                            nugget={props.nugget}
                            project={props.project}/>
      default:
        return <DefaultTheme snapshotTheme={snapshotTheme}
                             createdBy={props.createdBy}
                             createdAt={props.createdAt}
                             nugget={props.nugget}
                             project={props.project}/>
    }
  }

  return (
    <div className="custom-theme-snapshot-wrapper"
         style={{
           position: 'relative',
           scale: `${scale}`,
           transformOrigin,
           backgroundColor: snapshotTheme?.backgroundUrl ? '' : colorToCssString(snapshotTheme?.backgroundColor),
           backgroundImage: `url(${snapshotTheme?.backgroundUrl})`,
           fontFamily: `'${snapshotTheme?.font}', sans-serif`,
           color: colorToCssString(snapshotTheme?.fontColor)
         }}>
      {
        snapshotTheme?.backgroundUrl &&
          <div className="overlay-wrapper"
               style={{
                 background: snapshotTheme?.overlayStyle === 'gradient' ? getGradientOverlayStyle() : snapshotTheme?.overlayColor && snapshotTheme.overlayStyle === 'fill' ? colorToCssString(snapshotTheme?.overlayColor) : ''
               }}/>
      }
      {getThemeOverlay()}
    </div>
  );
};

export default CustomThemeSnapshot;
