// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archived-projects h3 {
  padding-bottom: 20px;
}
.archived-projects .project-name {
  color: #1b87e6;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/tabs/recycle-bin-projects/recycle-bin-projects.scss"],"names":[],"mappings":"AAEE;EACE,oBAAA;AADJ;AAIE;EACE,cAAA;AAFJ","sourcesContent":[".archived-projects {\n\n  h3 {\n    padding-bottom: 20px;\n  }\n\n  .project-name {\n    color: #1b87e6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
