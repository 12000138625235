import React from 'react';
import { Empty } from "antd";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import StoryCard from "./story-card/story-card";
import { ISearchStoriesData, IStorySearchResult } from "../../models/search-stories.interface";
import { UseQueryResult } from "react-query";

const Stories = (props: { searchStories: UseQueryResult<ISearchStoriesData, unknown> }) => {
  const searchStories = props.searchStories;


  return <>
    {!searchStories?.isLoading ?
      <div className='column' style={{gap: 10, marginBottom: 20}}>
        {searchStories?.data?.searchResult?.length ? searchStories?.data?.searchResult.map((story: IStorySearchResult, i: number) => {
            return (
              <StoryCard key={i}
                         story={story}/>
            )
          })
          :
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }
      </div>
      :
      <>
        <Loader/>
      </>
    }
  </>
};

export default Stories;
