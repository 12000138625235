import React, { useRef, useState } from 'react';
import { useOutsideHover } from '../../../../../core/hooks/use-outside-hover';
import FlagIconsPipe from '../../../../helpers/flag-icons-pipe';

export function HighlightToolbarItem(props: any): JSX.Element {
  const {nodes, nuggets, flags} = props;
  let component = useRef(null);
  const [visibleDropDown, setVisibleDropDown] = useState<any>();

  useOutsideHover(component, () => {
    if (visibleDropDown) {
      setVisibleDropDown(false);
    }
  });

  return (
    <>
      <div ref={component}
           data-testid={"highlight-menu"}
           className={nuggets ? 'highlight-menu highlight-menu-nuggets' : flags ? 'highlight-menu-flags highlight-menu' : 'highlight-menu'}
           onMouseOver={() => setVisibleDropDown(true)}>
        {props?.icon}
        <div className="number">
          {nodes && nodes?.length}
          {nuggets && nuggets?.length}
          {flags && flags?.length}
        </div>
        {
          (visibleDropDown ? nodes?.length || nuggets?.length || flags?.length : false) ?
            <div className="dropdown">
              {nodes &&
                  <>
                    {nodes?.nodes?.map((item: any, index: number) => {
                      return (
                        <div key={index} className="item">
                          <span className="name">{item.name}</span>
                          <span className="length">{item.length}</span>
                        </div>
                      )
                    })}
                  </>
              }
              {nuggets &&
                  <>
                    {nuggets?.nuggets?.map((item: any, index: number) => {
                      return (
                        <div key={index} className="item">
                          <span className="name">{item.name}</span>
                          <span className="length">{item.length}</span>
                        </div>
                      )
                    })}
                  </>
              }


              {flags &&
                  <>
                    {flags?.flags?.map((item: any, index: number) => {
                      return (
                        <div key={index} className="item">
                      <span className="name">
                                      <FlagIconsPipe icon={item?.icon && item?.icon}
                                                     color={item?.color && item?.color}/>
                        {item.name}
                      </span>
                          <span className="length">{item.length}</span>
                        </div>
                      )
                    })}
                  </>
              }
            </div>
            :
            <></>
        }
      </div>
    </>
  );
}
