import React, { useContext } from 'react'
import SvgNugget from '../../../../../assets/icons/js/nugget'
import SvgQuestionProLogo from '../../../../../assets/icons/js/questionProLogo'
import GlobalContext from '../../../../../store/global-context'
import { SnapshotTemplateEnum } from '../../../model/snapshot.interface'
import "./fourth-template-snapshot.scss"
import fourthTemplateBackground from "./images/fourthtemplateBackground.png"
import TextFit from "../../../../../_shared/components/text-fit/text-fit";


const FourthTemplateSnapshot = (props: any) => {
  const globalContext = useContext(GlobalContext);

  const onClick = () => {
    if (props?.forSelectTemplate) {
      props.onTemplateClick(SnapshotTemplateEnum.FOURTH)
    }
  }

  let scale = 1;
  let transformOrigin = '';

  if (props?.mdSize) {
    scale = 0.36;
    transformOrigin = 'top left';
  }

  if (props?.smSize) {
    scale = 0.23;
    transformOrigin = 'top left';
  }

  if (props?.forSelectTemplate) {
    scale = 0.173;
    transformOrigin = 'top left';
  }

  return (
    <div className="fourth-template-snapshot-wrapper"
         onClick={onClick}
         style={{scale: `${scale}`, transformOrigin}}>
      <div className='fourth-template-image'>
        {
          props?.selectedImage?.url?.length > 0 ?
            <img src={props?.selectedImage?.url} alt="selected-image"/> :
            <>
              {props?.noImageLabel &&
                <div className='fourth-template-no-image-selected' id="no-image-label-template">
                  <i className="bi bi-card-image" style={{fontSize: "2em"}}/>
                  <div>
                    No Image Selected
                  </div>
                </div>
              }
            </>
        }
      </div>
      <div className='fourth-template-graphic-background'>
        <img src={fourthTemplateBackground} alt=""/>
      </div>

      <div className="fourth-template-main-content">
        <div className='fourth-template-nugget-wrapper'>
          <SvgNugget width={"3.25em"} height={"3.5em"} color="#1B87E6"/>
        </div>
        <div className='fourth-template-nugget-description'>
          <TextFit mode="multi" min={16} max={55} style={{height: 168, lineHeight: '150%'}}>
            {props?.nugget}
          </TextFit>
        </div>
      </div>
      <div className='fourth-template-project'>
        {props?.project}
        <div style={{fontWeight: 600}}>{globalContext.user?.organization.name}</div>
      </div>

      <div className='fourth-template-question-pro-logo'>
        <SvgQuestionProLogo color="#fff" width={126} height={24}/>
      </div>
    </div>
  )
}

export default FourthTemplateSnapshot
