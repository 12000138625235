// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploader-file {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  padding-left: 16px;
  margin-bottom: 5px;
  min-height: 32px;
}
.hover {
  background-color: #e8e8e8;
}

.hover-error {
  background-color: #FBE8E9;
  cursor: pointer;
}

.error {
  color: #D0021C;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/uploader-popup/uploader-file/uploader-file.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAUA;EACE,yBAAA;AARF;;AAWA;EACE,yBAAA;EACA,eAAA;AARF;;AAWA;EACE,cAAA;AARF","sourcesContent":[".uploader-file {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 14px;\n  padding-left: 16px;\n  margin-bottom: 5px;\n  min-height: 32px;\n\n\n  .uploader-file-icon {\n  }\n\n  .uploader-file-name {\n\n  }\n}\n\n.hover {\n  background-color: #e8e8e8;\n}\n\n.hover-error {\n  background-color: #FBE8E9;\n  cursor: pointer;\n}\n\n.error {\n  color: #D0021C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
