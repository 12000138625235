import { getPosition, getSelected } from '../get-element-position';
import CSS from 'csstype';

export function handleOnMouseUp(): CSS.Properties {
  const selected = getSelected();
  const sel = getPosition();

  if (selected?.rangeCount !== undefined && selected?.toString()?.length > 0 && selected?.rangeCount > 0) {
    return {
      position: "absolute",
      width: `${sel.width + 10}px`,
      // height: sel.height,
      top: `${sel.top - 60}px`,
      left: `${sel.left}px`,
      right: sel.right,
      zIndex: 2,
      //backgroundColor: "#fff",
    };

  } else {
    return {display: 'none'};
  }
}
