import React from 'react';
import { DetailsRequirements } from "./components/details-requirements/details-requirements";
import { DetailsContributors } from "./components/details-contributors";
import { DetailsPricingModel } from "./components/details-pricing-model";
import { DetailsKeyMetrics } from "./components/details-key-metrics";
import "./insights.scss"
import { Nuggets } from "./nuggets/nuggets";
import LinkedStories from "./linked-stories/linked-stories";
import { DetailsProgramKeyMetrics } from "./components/details-program-key-metrics";


const Insights = (props: any) => {
  return (
    <div className="insights-wrapper">
      <div className="side">
        <DetailsRequirements project={props?.project}/>
        <DetailsContributors project={props?.project} owners={props?.owners}/>
        <DetailsPricingModel project={props?.project}/>
        <DetailsKeyMetrics project={props?.project}/>
        {
          !!props?.project?.projectProgramMetrics?.length &&
            <DetailsProgramKeyMetrics project={props?.project}/>
        }

      </div>
      <div className="body">
        <Nuggets project={props.project}/>
        {/*<Findings findings={props.project?.findings} project={props.project}/>*/}
        <LinkedStories project={props.project}/>
      </div>
    </div>
  );
};

export default Insights;
