import React, { useContext, useEffect, useState } from 'react';
import './project-type-form.scss'
import ProjectTypeContext from '../store/project-type-context';
import { Dropdown, Menu, Select } from 'antd';
import SvgArrowDown from '../../../../../../assets/icons/js/ArrowDown';
import SvgSaving from '../../../../../../assets/icons/js/Saving';
import SvgSuccess from '../../../../../../assets/icons/js/Success';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import { IProjectType, IWidget } from '../model/project-type.interface';
import InformIconsPipe from '../../../../../../_shared/helpers/inform-icons-pipe';
import { DebounceInput } from 'react-debounce-input';
import WidgetForm from './forms/widget-form';
import AddIconDialog from './add-icon-dialog';
import ProjectTypeApi from '../api/project-type-api';
import SvgMultipleChoice from '../../../../../../assets/icons/js/MultipleChoice';
import SvgCommentBox from '../../../../../../assets/icons/js/CommentBox';
import SvgUploadFile from '../../../../../../assets/icons/js/UploadFile';
import { MainFormTypeEnum } from '../model/main-form-type.enum';
import { MainFormPositionEnum } from '../model/main-form-position.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import SvgWorkflowIcon from '../../../../../../assets/icons/js/WorkflowIcon';
import SvgArrowBack from "../../../../../../assets/icons/js/ArrowBack";


export default function ProjectTypeForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);
  const projectTypeContext = useContext(ProjectTypeContext);
  const projectType: IProjectType = projectTypeContext.projectType;
  const [addIconHover, setAddIconHover] = useState<boolean>(false);
  const [openIconColorDialog, setOpenIconColorDialog] = useState<boolean>(false);
  const [titleLength, setTitleLength] = useState<number>(0);

  useEffect(() => {
    const projectId: any = parseInt(urlParam?.id) || projectTypeContext.id;
    projectTypeContext.setProjectId(projectId);
  }, []);

  function closeDialog() {
    setOpenIconColorDialog(false);
  }

  function onStateChanged(status: string): void {
    if (projectType.id) {
      if (status === 'archived') {
        ProjectTypeApi.archive(projectType.id)
          .then(() => {
            navigate('/settings');
          });
        return;
      }

      ProjectTypeApi.changeStatus(projectType.id, status)
        .then(() => {
          projectTypeContext.setProjectStatus(status);
        });

      if (status !== 'draft') {
        navigate('/settings');
      }
    }
  }

  const navigateBack = () => {
    navigate({pathname: '/settings', search: qs.stringify({tab: 1})})
  }

  return (
    <div className="intake-form column">
      <div className="intake-form-header justify-space-between">
        <div className="row align-items-center">
          <button
            className="icon-button back"
            onClick={navigateBack}>
            <SvgArrowBack/>
          </button>
          <span className="font-weight-300">Workspace</span>
        </div>
        <div className="row align-items-center">
          {
            projectTypeContext.saving && !projectTypeContext.saved &&
              <>
                  <SvgSaving/>
                  <span className="font-weight-300" style={{fontSize: '12px', marginLeft: 0}}>Saving ...</span>
              </>
          }
          {
            projectTypeContext.saved &&
              <>
                  <SvgSuccess/>
                  <span className="font-weight-300"
                        style={{fontSize: '12px', marginLeft: 5}}>All changes saved</span>
              </>
          }
        </div>
        <div className="row align-items-center">
          <Dropdown.Button type="primary"
                           icon={<SvgArrowDown color="#ffffff"/>}
                           overlay={
                             <Menu>
                               <Menu.Item key="1" onClick={() => onStateChanged('draft')}>
                                 Draft
                               </Menu.Item>
                               <Menu.Item key="2" onClick={() => onStateChanged('published')}>
                                 Publish
                               </Menu.Item>
                               <Menu.Item key="3" onClick={() => onStateChanged('archived')}>
                                 Archived
                               </Menu.Item>
                             </Menu>
                           }>
            {projectType.status ?
              projectType?.status[0].toUpperCase() + projectTypeContext.projectType.status.slice(1)
              :
              'Draft'
            }
          </Dropdown.Button>
        </div>
      </div>

      {projectTypeContext.loader ? <Loader/>
        :
        <>
          <div className="header row">
            <button className="icon"
                    onMouseEnter={() => setAddIconHover(!addIconHover)}
                    onMouseLeave={() => setAddIconHover(!addIconHover)}
                    onClick={() => setOpenIconColorDialog(true)}>
              {addIconHover && projectType.color && projectType.icon &&
                  <>
                      <div className="click-to-edit">
                          <span>Click to Edit</span>
                      </div>
                  </>
              }
              <>
                <div className="body">
                  <InformIconsPipe color={projectType?.color}
                                   icon={projectType?.icon} width={24} height={24}/>
                </div>
              </>
            </button>
            <div className="d-flex fxFlex relative">
              <DebounceInput className="title"
                             type="text"
                             value={projectType.title}
                             debounceTimeout={600}
                             maxLength={50}
                             onKeyUp={(e: any) => setTitleLength(e.target.value?.length)}
                             onChange={e => {
                               projectTypeContext.setProjectTypeTitle(e.target.value)
                             }}
                             placeholder="Form Title"/>
              <div className="title-counter">{titleLength}/50</div>
            </div>
          </div>
          <div className='outline-input-wrapper'>
            <div className='outline-input-label'>Select workflow</div>
            <div className='row relative'>
              <div className='workflow-icon'><SvgWorkflowIcon/></div>
              <Select value={projectTypeContext.selectedWorkflow?.id}
                      className="select-workflow"
                      suffixIcon={<SvgArrowDown/>}
                      onChange={(val: string) => projectTypeContext.onWorkflowChange(val)}>
                {projectTypeContext.workflows.map((workflow: any, i: number) => {
                  return <Select.Option value={workflow.id} key={i}>
                    <div className="row">
                      <span className="workflow-name">{workflow.name}:</span>
                      {workflow.statuses.map((status: any, i: number) => {
                        if (i === workflow.statuses.length - 1) {
                          return <div className="row align-items-center" style={{gap: 5}} key={i}>
                            <div
                              className="color-circle"
                              style={{
                                backgroundColor: status.color,
                              }}/>
                            <div className="workflow-status-name">{status.name} </div>
                          </div>
                        }
                        return <div className="row align-items-center" style={{gap: 5}} key={i}>
                          <div className="color-circle"
                               style={{
                                 backgroundColor: status.color,
                               }}/>
                          <div className="workflow-status-name">{status.name} {'>'} </div>
                        </div>
                      })}
                    </div>
                  </Select.Option>
                })}
              </Select>
            </div>
          </div>

          <div className="body">
            {projectTypeContext.projectType.widgets.length ? projectTypeContext.projectType.widgets.map((widget: IWidget, index: number) => {
                return (
                  <div key={index}>
                    <WidgetForm widget={widget} index={index}/>
                  </div>
                )
              })
              :
              <div className="create-first-widget">
                <h2>Add Widget</h2>
                <div className="row justify-space-between">
                  <div className="widget-type-card" onClick={() => projectTypeContext.addWidget({
                    type: MainFormTypeEnum.MULTI_CHOICE,
                    options: [''],
                    position: MainFormPositionEnum.TOP
                  }, 0)}>
                    <SvgMultipleChoice width="2.5rem" height="2.5rem"/>
                    <span>Multiple Choice</span>
                  </div>
                  <div className="widget-type-card" onClick={() => projectTypeContext.addWidget({
                    type: MainFormTypeEnum.TEXT_INPUT,
                  }, 0)}>
                    <SvgCommentBox width="2.5rem" height="2.5rem"/>
                    <span>Comment box</span>
                  </div>
                  <div className="widget-type-card" onClick={() => projectTypeContext.addWidget({
                    type: MainFormTypeEnum.FILE_UPLOAD,
                  }, 0)}>
                    <SvgUploadFile width="2.5rem" height="2.5rem"/>
                    <span>Upload file</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </>
      }
      {openIconColorDialog &&
          <AddIconDialog visible={openIconColorDialog} title={projectType.title} onCancel={closeDialog}/>}
    </div>
  );
}
