import React, { useState } from 'react';
import { Button } from 'antd';
import interviewLabsSettingsApi from '../../api/interview-labs-settings.api';
import './flags-setup.scss';
import { FlagSetupCard } from './flag-setup-card';
import AddEditFlagDialog from './add-edit-flag-dialog';
import { IFlagType } from '../../model/flag-type.interface';
import { useMutation, useQuery } from 'react-query';

export function FlagsSetup(): JSX.Element {
  const [modalData, setModalData] = useState<any>({});
  const [openFlagIconColorDialog, setOpenFlagIconColorDialog] = useState<boolean>(false);
  const flags = useQuery('flags', () => interviewLabsSettingsApi.getFlags());
  const deleteFlag = useMutation('deleteFlag', interviewLabsSettingsApi.deleteFlag, {
    onSuccess: () => {
      flags.refetch();
    }
  });

  const onDeleteFlag = (id: number) => {
    deleteFlag.mutate(id);
  }

  function closeDialog(): void {
    setOpenFlagIconColorDialog(false);
  }

  function onSubmit(): void {
    flags.refetch();
    closeDialog();
  }

  function openNewFlagDialog() {
    setModalData({});
    setOpenFlagIconColorDialog(true);
  }

  const openEditFlagDialog = (flag: IFlagType): void => {
    setModalData({
        id: flag.id,
        name: flag.name,
        color: flag.color,
        icon: flag.icon,
        title: 'Edit flag'
      }
    );

    setOpenFlagIconColorDialog(true);
  }

  return (
    <div className="flags-setup-wrapper">
      <div className="main-title">
        <h2>Flags</h2>
      </div>
      <div className="flag-button-content">
        <Button
          className="custom-flag-button"
          onClick={() => openNewFlagDialog()}>
          New flag
        </Button>
        {flags.data && flags.data?.map((flag: IFlagType, i: number) => {
          return (
            <FlagSetupCard key={i} flag={flag} openEditFlagDialog={openEditFlagDialog} deleteFlag={onDeleteFlag}/>
          )
        })}
      </div>

      {openFlagIconColorDialog &&
          <AddEditFlagDialog
              visible={openFlagIconColorDialog}
              data={modalData}
              title={'projectType.title'}
              onCancel={closeDialog}
              onSubmit={onSubmit}
          />
      }
    </div>
  );
}
