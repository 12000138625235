import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Select, Tooltip } from "antd";
import SvgArrowDown from "../../../../assets/icons/js/ArrowDown";
import { Controller, useForm } from "react-hook-form";
import SvgThemeDefaultLayout from "../../../../assets/icons/js/ThemeDefaultLayout";
import SvgThemeLeftAligned from "../../../../assets/icons/js/ThemeLeftAligned";
import SvgThemeRightAligned from "../../../../assets/icons/js/ThemeRightAligned";
import SvgThemeBottomAligned from "../../../../assets/icons/js/ThemeBottomAligned";
import "./snapshot-theme-form.scss";
import Switch from "../../../../_shared/components/switch/switch";
import SvgThemeOverlayFill from "../../../../assets/icons/js/ThemeOverlayFill";
import SvgThemeOverlayGradient from "../../../../assets/icons/js/ThemeOverlayGradient";
import SvgThemeOverlayNone from "../../../../assets/icons/js/ThemeOverlayNone";
import CustomColorPicker from "../../../../_shared/components/color-picker/color-picker";
import { ColorResult } from "react-color";
import SvgClose from "../../../../assets/icons/js/Close";
import MediaDialog from "../../../../_shared/components/media-dialog/media-dialog";
import { ISnapshotTheme } from "../../model/snapshot.interface";
import SnapshotsApi from "../../api/snapshots-api";
import { useQuery } from "react-query";
import SvgSettings from "../../../../assets/icons/js/Settings";
import SnapshotThemesModal from "../snapshot-themes-modal/snapshot-themes-modal";

type SnapshotThemeFormProps = {
  onThemeFormChange: (theme: ISnapshotTheme) => void;
  theme?: ISnapshotTheme,
  updateThemeId: (themeId: number) => void;
}

const SnapshotThemeForm = ({onThemeFormChange, theme, updateThemeId}: SnapshotThemeFormProps) => {
  const [mediaDialog, setMediaDialog] = React.useState({visible: false});
  const [mediaDialogType, setMediaDialogType] = React.useState<string>('');
  const [fontColorPickerVisible, setFontColorPickerVisible] = React.useState<boolean>(false);
  const [backgroundColorPickerVisible, setBackgroundColorPickerVisible] = React.useState<boolean>(false);
  const [overlayColorPickerVisible, setOverlayColorPickerVisible] = React.useState<boolean>(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState<boolean>(true);
  const [toggleSaveInput, setToggleSaveInput] = React.useState<boolean>(false);

  const form = useForm({
    defaultValues: theme ? {
      theme: theme.id,
      themeName: theme.themeName,
      layout: theme.layout,
      font: theme.font,
      showDetails: theme.showDetails,
      logoUrl: theme.logoUrl,
      backgroundUrl: theme.backgroundUrl,
      fontColor: JSON.parse(theme.fontColor),
      backgroundColor: JSON.parse(theme.backgroundColor),
      overlayStyle: theme.overlayStyle,
      overlayColor: JSON.parse(theme.overlayColor || '{r: 0, g: 0, b: 0, a: 0.2}')
    } : {
      theme: 0,
      themeName: 'Custom theme',
      layout: 'default',
      font: 'Fira Sans',
      showDetails: false,
      logoUrl: '',
      backgroundUrl: '',
      fontColor: {r: 0, g: 0, b: 0, a: 1},
      backgroundColor: {r: 255, g: 255, b: 255, a: 1},
      overlayStyle: 'none',
      overlayColor: {r: 0, g: 0, b: 0, a: 0.2}
    }
  });

  const overlayColor = form.watch('overlayColor')
  const fontColor = form.watch('fontColor')
  const backgroundColor = form.watch('backgroundColor')
  const backgroundUrl = form.watch('backgroundUrl');
  const logoUrl = form.watch('logoUrl');
  const layoutStyle = form.watch('layout');
  const overlayStyle = form.watch('overlayStyle');

  const snapshotThemes = useQuery('snapshotThemes', () => SnapshotsApi.getSnapshotThemes({page: 1, pageSize: 999}), {
    onSuccess: (data) => {
      setSnapshotThemeOptions(data.content);
    }
  })

  const [snapshotThemeOptions, setSnapshotThemeOptions] = useState<any>([]);
  const [snapshotThemesModal, setSnapshotThemesModal] = useState({open: false});

  useEffect(() => {
    //Set the form to first theme if there is no theme
    if (!theme && snapshotThemeOptions.length > 0) {
      const firstTheme = snapshotThemeOptions[0];
      updateThemeId(firstTheme.id);
      form.setValue('theme', firstTheme.id);
      setFormValues(firstTheme);
      onThemeFormChange(firstTheme);
    }
  }, [theme, snapshotThemeOptions, updateThemeId, onThemeFormChange, form]);


  useEffect(() => {
    const subscription = form.watch((value: any, {name}: any) => {
      value = transformFormValues(value);
      onThemeFormChange(value);
      if (name && name !== 'theme') {
        setSaveButtonDisabled(false);
      }
      if (name && name === 'theme') {
        //Set the form to new theme
        const selectedTheme = snapshotThemeOptions.find((theme: any) => theme.id === value.theme);
        if (!selectedTheme) {
          return;
        }

        setFormValues(selectedTheme);

        setSaveButtonDisabled(true);
        updateThemeId(value.theme);
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [form, snapshotThemeOptions]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (!saveButtonDisabled) {
        event.preventDefault();
        event.returnValue = ''; // For modern browsers
        return ''; // For legacy browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [saveButtonDisabled]);


  const handleFormSubmit = useCallback((data: any) => {
    data = transformFormValues(data);
    if (data.theme === 0) {
      SnapshotsApi.createThemeSnapshot(data).then((response) => {
        updateThemeId(response.id);
      });
    } else {
      const id = data.theme;
      delete data.theme;
      SnapshotsApi.updateThemeSnapshot(id, data).then(() => {
        setSaveButtonDisabled(true);
        updateThemeId(data.theme);
      });
    }
  }, [updateThemeId]);


  const handleFormSubmitWithNewName = useCallback((data: any) => {
    delete data.theme;
    data = transformFormValues(data);
    SnapshotsApi.createThemeSnapshot(data).then((response) => {
      snapshotThemes.refetch();
      form.setValue('theme', response.id);
      updateThemeId(response.id);
      setToggleSaveInput(false);
      setSaveButtonDisabled(true);
    });
  }, [form, snapshotThemes]);


  const removeLogoUrl = () => {
    form.setValue('logoUrl', '');
  }

  const removeBackgroundUrl = () => {
    form.setValue('backgroundUrl', '');
  }

  const openMediaDialog = useCallback((type: string) => {
    setMediaDialogType(type);
    setMediaDialog({visible: true});
  }, []);

  const closeMediaDialog = useCallback(() => {
    setMediaDialog({visible: false});
    setMediaDialogType('');
  }, []);

  const changeMedia = ({url}: { url: string }) => {
    if (mediaDialogType === 'logo') {
      form.setValue('logoUrl', url);
    } else if (mediaDialogType === 'background') {
      form.setValue('backgroundUrl', url);
    }
    closeMediaDialog();
  };

  const handleColorChange = (color: ColorResult, colorType: string) => {
    form.setValue(colorType, color.rgb);
  };

  const toggleColorPicker = (colorType: string) => {
    if (colorType === 'fontColor') {
      setFontColorPickerVisible(!fontColorPickerVisible);
    } else if (colorType === 'backgroundColor') {
      setBackgroundColorPickerVisible(!backgroundColorPickerVisible);
    } else if (colorType === 'overlayColor') {
      setOverlayColorPickerVisible(!overlayColorPickerVisible);
    }
  };

  const onLayoutChange = (layout: string) => {
    form.setValue('layout', layout);
  }

  const onOverlayChange = (overlay: string) => {
    form.setValue('overlayStyle', overlay);
  }

  const openSnapshotThemesModal = () => {
    setSnapshotThemesModal({open: true});
  }

  const closeSnapshotThemesModal = () => {
    setSnapshotThemesModal({open: false});
  }

  const transformFormValues = (values: ISnapshotTheme) => {
    return {
      ...values,
      fontColor: JSON.stringify(values.fontColor),
      backgroundColor: JSON.stringify(values.backgroundColor),
      overlayColor: JSON.stringify(values.overlayColor)
    };
  };

  const setFormValues = useCallback((theme: ISnapshotTheme) => {
    form.setValue('themeName', theme.themeName);
    form.setValue('layout', theme.layout);
    form.setValue('font', theme.font);
    form.setValue('showDetails', theme.showDetails);
    form.setValue('logoUrl', theme.logoUrl);
    form.setValue('backgroundUrl', theme.backgroundUrl);
    form.setValue('fontColor', JSON.parse(theme.fontColor));
    form.setValue('backgroundColor', JSON.parse(theme.backgroundColor));
    form.setValue('overlayStyle', theme.overlayStyle);
    form.setValue('overlayColor', JSON.parse(theme.overlayColor || '{r: 0, g: 0, b: 0, a: 0.2}'));
  }, [form]);

  return (
    <div className="snapshot-theme-form">
      <div className="theme-property">
        <div className="theme-property-name">
          Theme
        </div>
        <div className="theme-property-value align-items-center" style={{gap: 5}}>
          <Controller control={form.control} render={({field}) =>
            <Select style={{width: 200}} suffixIcon={<SvgArrowDown/>} defaultValue={0} {...field}>
              {snapshotThemeOptions.length === 0 &&
                <Select.Option value={0}>Custom theme</Select.Option>}
              {snapshotThemeOptions.map((theme: any) => (
                <Select.Option key={theme.id} value={theme.id}>
                  {theme.themeName}
                </Select.Option>
              ))}
            </Select>
          } name={'theme'}/>
          <button className="icon-button" onClick={openSnapshotThemesModal}><SvgSettings/></button>
        </div>
      </div>
      <div className="theme-property">
        <div className="theme-property-name">
          Layout
        </div>
        <div className="theme-property-value" style={{gap: 10}}>
          {layoutOptions.map((layout, i) => (
            <button key={i}
                    onClick={() => onLayoutChange(layout.value)}
                    className={layoutStyle === layout.value ? "layout-option layout-option-active" : "layout-option"}>
              {layout.icon}
            </button>
          ))}
        </div>
      </div>
      <div className="theme-property">
        <div className="theme-property-name">
          Font
        </div>
        <div className="theme-property-value align-items-center" style={{gap: 10}}>
          <Controller
            control={form.control}
            render={({field}) => <Select style={{width: 200}} suffixIcon={<SvgArrowDown/>}
                                         defaultValue={'Fira Sans'} {...field}>
              {fontOptions.map((font, i) => (
                <Select.Option key={i} value={font.name} style={{fontFamily: `'${font.name}', sans-serif`}}>
                  {font.name}
                </Select.Option>
              ))}
            </Select>} name={'font'}/>
          <div>
            <div className="color-picked pointer" onClick={() => toggleColorPicker('fontColor')}>
              <div
                style={{backgroundColor: `rgba(${fontColor.r},${fontColor.g},${fontColor.b},${fontColor.a})`}}
                className="color-picked-inner"/>
            </div>
            {fontColorPickerVisible &&
              <div className="color-picker-wrapper">
                <div
                  className="color-picker-cover"
                  onClick={() => setFontColorPickerVisible(false)}
                  onKeyDown={() => setFontColorPickerVisible(false)}
                  role="button"
                  tabIndex={0}
                  aria-label="Save"
                />
                <CustomColorPicker
                  color={fontColor}
                  disableAlpha={true}
                  onChange={(colorResult: ColorResult) => handleColorChange(colorResult, 'fontColor')}
                />
              </div>
            }
          </div>

        </div>
      </div>
      <div className="theme-property">
        <div className="theme-property-name">
          Show details
        </div>
        <div>
          <Controller control={form.control}
                      name={'showDetails'}
                      render={({field}) => <Switch
                        {...field}
                        checked={field.value}/>}
          />
        </div>
      </div>
      <div className="theme-property">
        <div className="theme-property-name">
          Logo
        </div>
        <div>
          {
            logoUrl ?
              <div className='selected-image-wrapper'>
                <div className='selected-image'>
                  <img src={logoUrl} alt="logo-url"/>
                </div>
                <div>
                  <Tooltip placement='bottom' title="Remove image">
                    <button className="icon-button" onClick={removeLogoUrl}>
                      <SvgClose/>
                    </button>
                  </Tooltip>
                </div>
              </div> : <Button type='link' onClick={() => openMediaDialog('logo')}>Add image</Button>
          }
        </div>
      </div>
      <div className="theme-property">
        <div className="theme-property-name">
          Background
        </div>
        <div className={"row align-items-center"}>
          {
            backgroundUrl ?
              <div className="selected-image-wrapper">
                <div className="selected-image">
                  <img src={backgroundUrl} alt="background-url"/>
                </div>
                <div>
                  <Tooltip placement='bottom' title="Remove image">
                    <button className="icon-button" onClick={removeBackgroundUrl}>
                      <SvgClose/>
                    </button>
                  </Tooltip>
                </div>
              </div>
              : <>
                <div>
                  <div className="color-picked pointer" onClick={() => toggleColorPicker('backgroundColor')}>
                    <div
                      style={{backgroundColor: `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`}}
                      className="color-picked-inner"/>
                  </div>
                  {backgroundColorPickerVisible &&
                    <div className="color-picker-wrapper">
                      <div
                        className="color-picker-cover"
                        onClick={() => setBackgroundColorPickerVisible(false)}
                        onKeyDown={() => setBackgroundColorPickerVisible(false)}
                        role="button"
                        tabIndex={0}
                        aria-label="Save"
                      />
                      <CustomColorPicker
                        color={backgroundColor}
                        disableAlpha={false}
                        onChange={(colorResult: ColorResult) => handleColorChange(colorResult, 'backgroundColor')}
                      />
                    </div>
                  }
                </div>
                <Button onClick={() => openMediaDialog('background')} type={"link"}>
                  Add image
                </Button>
              </>
          }

        </div>
      </div>
      {
        backgroundUrl &&
        <>
          <div className="theme-property">
            <div className="theme-property-name">
              Overlay style
            </div>
            <div className="theme-property-value" style={{gap: 10}}>
              {overlayOptions.map((overlay, i) => (
                <button key={i}
                        onClick={() => onOverlayChange(overlay.value)}
                        className={overlayStyle === overlay.value ? "layout-option layout-option-active" : "layout-option"}>
                  {overlay.icon}
                </button>
              ))}
            </div>
          </div>
          <div className="theme-property">
            <div className="theme-property-name">
              Overlay color
            </div>
            <div>
              <div className="color-picked pointer" onClick={() => toggleColorPicker('overlayColor')}>
                <div
                  style={{backgroundColor: `rgba(${overlayColor.r},${overlayColor.g},${overlayColor.b},${overlayColor.a})`}}
                  className="color-picked-inner"/>
              </div>
              {overlayColorPickerVisible &&
                <div className="color-picker-wrapper">
                  <div
                    className="color-picker-cover"
                    onClick={() => setOverlayColorPickerVisible(false)}
                    onKeyDown={() => setOverlayColorPickerVisible(false)}
                    role="button"
                    tabIndex={0}
                    aria-label="Save"
                  />
                  <CustomColorPicker
                    color={overlayColor}
                    disableAlpha={false}
                    onChange={(colorResult: ColorResult) => handleColorChange(colorResult, 'overlayColor')}
                  />
                </div>
              }
            </div>
          </div>
        </>
      }
      <Divider style={{marginTop: 0, marginBottom: 0}}/>
      <div className="row" style={{gap: 10}}>
        {
          toggleSaveInput ?
            <div className={"row"} style={{gap: 10}}>
              <div className="outline-input-wrapper">
                <input type="text"
                       style={{width: 200}}
                       placeholder="Enter theme name"
                       className="outline-input" {...form.register('themeName')}/>
              </div>
              <Button type={"primary"} onClick={form.handleSubmit(handleFormSubmitWithNewName)}>Save</Button>
              <Button type={"link"} onClick={() => setToggleSaveInput(false)}>Cancel</Button>
            </div>
            : <>
              <Button type={"primary"} disabled={saveButtonDisabled}
                      onClick={form.handleSubmit(handleFormSubmit)}>Save</Button>
              <Button type={"link"} onClick={() => setToggleSaveInput(true)}>Save as</Button>
            </>
        }

      </div>
      {
        mediaDialog.visible &&
        <MediaDialog
          onConfirm={changeMedia}
          onCancel={closeMediaDialog}
          visible={mediaDialog.visible}/>
      }
      {
        snapshotThemesModal.open &&
        <SnapshotThemesModal open={snapshotThemesModal.open} onCancel={closeSnapshotThemesModal}/>
      }
    </div>
  );
};

export default SnapshotThemeForm;

const layoutOptions = [
  {icon: <SvgThemeDefaultLayout/>, value: 'default'},
  {icon: <SvgThemeLeftAligned/>, value: 'leftAligned'},
  {icon: <SvgThemeRightAligned/>, value: 'rightAligned'},
  {icon: <SvgThemeBottomAligned/>, value: 'bottomAligned'}
]

const overlayOptions = [
  {icon: <SvgThemeOverlayFill/>, value: 'fill'},
  {icon: <SvgThemeOverlayGradient/>, value: 'gradient'},
  {icon: <SvgThemeOverlayNone/>, value: 'none'}
]

const fontOptions = [
  {
    name: 'Fira Sans',
  },
  {
    name: 'Helvetica',
  },
  {
    name: 'Times New Roman',
  },
  {
    name: 'Arial',
  },
  {
    name: 'Avant Garde'
  },
  {
    name: 'Courier New',
  },
  {
    name: 'Verdana',
  },
  {
    name: 'Georgia',
  },
]