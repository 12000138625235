import React from 'react';

const SvgShortenText = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 6.3C1 6.13431 1.1567 6 1.35 6H14.65C14.8433 6 15 6.13431 15 6.3V7.7C15 7.86569 14.8433 8 14.65 8H1.35C1.1567 8 1 7.86569 1 7.7V6.3Z"
        fill="#545E6B"/>
      <path
        d="M1 9.3C1 9.13431 1.1567 9 1.35 9H7.65C7.8433 9 8 9.13431 8 9.3V10.7C8 10.8657 7.8433 11 7.65 11H1.35C1.1567 11 1 10.8657 1 10.7V9.3Z"
        fill="#545E6B"/>
    </svg>
  );
};

export default SvgShortenText;
