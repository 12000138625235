import { DraftDecoratorComponentProps } from 'draft-js'
import React from 'react'
import './graph-strategy.scss'
import HighchartsReact from 'highcharts-react-official';
// @ts-ignore
import * as Highcharts from 'highcharts';

const GraphStrategy = (props: DraftDecoratorComponentProps | any) => {
  let title: any;
  let highChartJSON: any = ''
  let surveyInfo: any = {}
  let selectedChart: any = {};

  if (props.entityKey != null) {
    title = props.contentState.getEntity(props?.entityKey).getData().title;
    surveyInfo = props.contentState.getEntity(props?.entityKey).getData().surveyInfo;
    highChartJSON = props.contentState.getEntity(props?.entityKey).getData().highChartJSON;
    highChartJSON = JSON.parse(highChartJSON);
    selectedChart = props.contentState.getEntity(props?.entityKey).getData().selectedChart;
  } else {
    return null;
  }

  highChartJSON.chart.type = selectedChart?.value;

  return (
    <>
      <div className="d-flex column justify-space-center">
        <div className='graph-question-title'>"{title}"</div>
        {
          highChartJSON &&
            <HighchartsReact
                options={highChartJSON}
                highcharts={Highcharts}/>
        }
        <div className='column graph-strategy-info'>
          <span className='graph-strategy-source'>
            Source: <span className="graph-strategy-link">{surveyInfo?.name} &nbsp;
            {/*<SvgExternalLink/>*/}
          </span>
          </span>
          {/*<span className='graph-strategy-date'>Added on Oct 4 </span>*/}
          {/*<span className='graph-strategy-date'>220 responses</span>*/}
        </div>
      </div>
    </>
  )
}

export default GraphStrategy
