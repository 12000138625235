import React from 'react';
import { DebounceInput } from "react-debounce-input";

const ImageFromUrl = (props: { onUrlChange: (url: string) => void, urlError: string }) => {
  const {onUrlChange, urlError} = props;
  return (
    <div className="image-url">
      <div className="outline-input-wrapper">
        <DebounceInput type="text" className='outline-input'
                       debounceTimeout={600}
                       placeholder='Paste URL of image'
                       onChange={(e) => onUrlChange(e.target.value)}
        />
        {urlError && <small className='form-error'>{urlError}</small>}
      </div>
      <span
        className='label'>Minimum image size is 630x420 pixels. Accepted file types .jpg and .png only</span>
    </div>
  );
};

export default ImageFromUrl;
