import React, { useState } from 'react';
import { Button, Table } from "antd";
import { useQuery } from "react-query";
import { ProjectStatusEnum } from "../../../../models/project-status.enum";
import analyticsApi from "../../../../api/analytics-api";
import AnalyticsApi from "../../../../api/analytics-api";
import { saveAs } from "file-saver";
import Pagination from "../../../../../../_shared/components/pagination/pagination";

const ProjectsTable = (props: { columns: any[], queryParams: any, status: ProjectStatusEnum }) => {
  const [pagination, setPagination] = useState({pageSize: 10, page: 1})
  const [totalNumber, setTotalNumber] = useState(0);

  const projects = useQuery([`${props.status}-projects`, props.queryParams, pagination], () => analyticsApi.getProjects({
    ...props.queryParams, ...pagination,
    status: props.status
  }), {
    onSuccess: data => setTotalNumber(data.pagination.totalElements)
  })

  function exportExcel(e: any): void {
    e.preventDefault();

    AnalyticsApi.exportExcel({...props.queryParams, status: props.status})
      .then((response: any) => {
        let blob = new Blob([response], {type: 'xlsx'})
        saveAs(blob, 'analytics-projects.xlsx');
      });
  }


  return (
    <div className="table">
      <div className="row justify-space-between" style={{marginBottom: 10}}>
        <Button onClick={(e) => exportExcel(e)}>Export excel</Button>
        {
          totalNumber > 0 && <Pagination totalItems={totalNumber}
                                         pageSize={pagination.pageSize}
                                         currentPage={pagination.page}
                                         showSizeChanger
                                         onChange={(page: number, pageSize: number) => setPagination({
                                           page,
                                           pageSize
                                         })}/>
        }
      </div>
      <Table columns={props.columns}
             loading={projects.isLoading}
             pagination={false}
             dataSource={projects.data?.content}
             rowKey="id">
      </Table>
    </div>
  );
};

export default ProjectsTable;
