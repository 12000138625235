import React from 'react';
import { EditorState } from "draft-js";
import PreviewRecommendationThumbsUp from "./preview-recommendation-thumbs-up/preview-recommendation-thumbs-up";

type RecommendationHighlightsContainerProps = {
  displayTextHighlights: any;
  editorState: EditorState
}

const PreviewRecommendationHighlightsContainer = (props: RecommendationHighlightsContainerProps) => {
  return (
    <div className="relative" style={{right: -10}}>
      {props.displayTextHighlights?.length &&
        props.displayTextHighlights?.map((value: any, index: number) => {
          return <PreviewRecommendationThumbsUp index={index}
                                                value={value}
                                                key={index}
                                                editorState={props.editorState}
          />
        })
      }
    </div>
  );
};

export default PreviewRecommendationHighlightsContainer;
