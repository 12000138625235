import React from 'react';

const SvgLoop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_12_598)">
        <rect width="16" height="16" fill="white" fillOpacity="0.01"/>
        <path
          d="M0 -6.10352e-05L2.41212 2.41206C5.52054 -0.696355 10.5603 -0.696355 13.6687 2.41206C16.7771 5.52047 16.7771 10.5602 13.6687 13.6686C10.5603 16.777 5.52054 16.777 2.41212 13.6686C1.44283 12.6993 0.775792 11.5422 0.411005 10.3145H2.82795C3.10429 10.9483 3.5017 11.542 4.0202 12.0605C6.2405 14.2808 9.84031 14.2808 12.0606 12.0605C14.2809 9.84025 14.2809 6.24044 12.0606 4.02014C9.84031 1.79984 6.2405 1.79984 4.0202 4.02014L6.43232 6.43226H0V-6.10352e-05Z"
          fill="#545E6B"/>
      </g>
      <defs>
        <clipPath id="clip0_12_598">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgLoop;
