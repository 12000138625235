// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-assigments-header {
  position: absolute;
  margin-top: 48px;
  top: 0;
  left: 45px;
  height: 50px;
  width: calc(100% - 45px);
  border-bottom: 1px solid #D8D8D8;
  background-color: #ffffff;
  padding: 0 15px;
  z-index: 10;
}
.all-assigments-header > div > span {
  margin-left: 14px;
}

.content .ant-tabs-content {
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/interviews-labs/usage-stats/usage-stats-view-all/usage-stats-view-all.scss","webpack://./src/assets/styles/abstracts/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gBCHkB;EDIlB,MAAA;EACA,UCNc;EDOd,YAAA;EACA,wBAAA;EACA,gCAAA;EACA,yBAAA;EACA,eAAA;EACA,WAAA;AADF;AAGE;EACE,iBAAA;AADJ;;AAME;EACE,qBAAA;AAHJ","sourcesContent":["@import \"../../../../../../../assets/styles/abstracts/variables\";\n\n.all-assigments-header {\n  position: absolute;\n  margin-top: $app-header-height;\n  top: 0;\n  left: $sidenav_width;\n  height: 50px;\n  width: calc(100% - #{$sidenav_width});\n  border-bottom: 1px solid #D8D8D8;\n  background-color: #ffffff;\n  padding: 0 15px;\n  z-index: 10;\n\n  & > div > span {\n    margin-left: 14px;\n  }\n}\n\n.content {\n  .ant-tabs-content {\n    padding: 0 !important;\n  }\n}\n","$sidenav_width: 45px;\n$app-header-height: 48px;\n\n\n$header-color: #3D3D3D;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
