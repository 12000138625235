import { Button, Divider, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./snapshots-dialog.scss"
import qs from "query-string"
import Snapshot from '../../../../../../snapshots/snapshot-edit/snapshot/snapshot'
import { ISnapshot } from '../../../../../../snapshots/model/snapshot.interface'
import snapshotsApi from '../../../../../../snapshots/api/snapshots-api'
import { Loader } from '../../../../../../../_shared/components/loader/loader';

const SnapshotsDialog = (props: any) => {
  const snapshotComp: any = useRef([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [snapshots, setSnapshots] = useState<ISnapshot[]>([]);

  useEffect(() => {
    loadPage()
  }, [])

  function navigateToSnapshot(data: { nuggetId?: number, id?: number }): void {
    if (data?.nuggetId) {
      navigate({pathname: '/snapshots/edit', search: qs.stringify({nuggetId: data.nuggetId})})
    } else {
      navigate({pathname: '/snapshots/edit', search: qs.stringify({id: data.id})})
    }
  }

  function loadPage(): void {
    snapshotsApi.getSnapshots(props?.data.projectId, props?.data.nuggetId).then((response: ISnapshot[]) => {
      setSnapshots(response)
      setLoading(false)
    })
  }

  const downloadSnapshot = (i: number) => {
    snapshotComp.current[i].download();
  }

  return (
    <Modal
      title="Snapshots"
      onCancel={props.onCancel}
      open={props.visible}
      width={"60%"}
      footer={[
        <Button type='link' onClick={props.onCancel}>Cancel</Button>,
        <button onClick={() => navigateToSnapshot({nuggetId: props.data.nuggetId})}
                className="icon-button new-snapshot-button">
          <span className="plus-icon"/>
          <span>Create new snapshot</span>
        </button>]}>
      <>
        {
          loading ? <Loader/> :
            <div className='snapshots-dialog-wrapper'>
              {
                snapshots?.length === 0 ?
                  <span className='no-snapshots-wrapper'>
                    There are no Snapshots associated with this nugget. Create a new Snapshot to get started.
                  </span> :
                  <>
                    <div className='snapshots-dialog-info'>
                      <div className='snapshots-dialog-number'>
                        There are currently <span>{snapshots?.length}</span> Snapshots associated to the Nugget:
                      </div>
                      <div className='snapshots-dialog-nugget-description'>
                        {props.data.description}
                      </div>
                    </div>
                    <div className='snapshots-dialog-snapshots-wrapper'>
                      <div className='snapshots-dialog-snapshots'>
                        {
                          snapshots?.length >= 0 && snapshots?.map((snapshot: ISnapshot, i: number) => {
                            return <div className="relative pointer"
                                        style={{width: 210, height: 140}}
                                        key={i}
                                        onClick={(e: any) => navigateToSnapshot({id: snapshot.id})}>
                              <Snapshot
                                ref={el => snapshotComp.current[i] = el}
                                html={snapshot?.htmlContent}
                                template={snapshot.template}
                                project={snapshot?.project}
                                nugget={snapshot?.nugget}
                                selectedImage={{url: snapshot.imageUrl, name: snapshot.imageText}}
                                smSize={true}/>
                              <div className='template-download-button' onClick={(e: any) => {
                                e.stopPropagation()
                                downloadSnapshot(i)
                              }
                              }>
                                <i className='bi bi-download'/>
                              </div>
                            </div>

                          })
                        }
                      </div>
                    </div>
                  </>
              }
            </div>
        }
        <Divider style={{marginBottom: -15, marginTop: 50}}/>
      </>
    </Modal>
  )
}

export default SnapshotsDialog
