import React, { useContext, useState } from 'react';
import './agency-edition-menu.scss'
import GlobalContext from "../../../../../store/global-context";
import SvgTick from "../../../../../assets/icons/js/Tick";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useOutsideHover } from '../../../../hooks/use-outside-hover';

const antIcon = <LoadingOutlined style={{fontSize: 18}} spin/>;

const AgencyEditionMenu = (props: any) => {
  const globalContext = useContext(GlobalContext)

  const [isExpanded, setIsExpanded] = useState(false)
  const [loading, setLoading] = useState<number | string | undefined>(undefined);

  const isParent = props.partnerOrganizations.length > 0;
  const organizationName = globalContext.user?.organization.name
  const getInitials = (organizationName: string) => {
    let initials = "";
    organizationName.split(" ").forEach((word: string) => {
      initials = initials + word.substring(0, 1).toUpperCase();
    })
    return initials.substring(0, 3);
  }

  useOutsideHover(props.component, () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  });

  const toggleExpanded = (e: any) => {
    if (!isParent) {
      return
    }

    setIsExpanded(prevState => !prevState)
  }

  const isMenuActive = (organization: any) => {
    return organization.organizationID == globalContext.selectedOrganization
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, organization: any) => {
    e.stopPropagation();
    if (isMenuActive(organization)) {
      return;
    }
    if (loading) {
      return;
    }
    setLoading(organization.organizationID);
    globalContext.switchOrganizations(organization)
  }

  return (
    <div style={{backgroundColor: isExpanded ? "#FFFFFF" : "#EEEEEE"}} className='agency-edition-menu-wrapper nav-item'
         onClick={toggleExpanded}>
      <div className='divider'/>
      <div className='agency-icon'>
        <div className='profile-avatar'>
          {getInitials(organizationName)}
        </div>
      </div>
      <div className='title' style={{
        marginTop: 5
      }}>
        Hub: {organizationName}
      </div>

      <div className={isExpanded && isParent ? "expand-container-right" : "expand-container-right collapse "}>
        {
          props.partnerOrganizations.map((organization: any) => {
            return <div className={isMenuActive(organization)
              ? 'organization-nav-item organization-nav-item-active'
              : 'organization-nav-item'}
                        onClick={(e) => handleClick(e, organization)}
                        key={organization.organizationID}>
              <div className="icon">
                <img src='' alt=''/>
              </div>
              <div className="expand-right-title" style={{marginTop: 5}}>
                <span>{organization.name}</span>
                {
                  loading == organization.organizationID &&
                    <Spin indicator={antIcon}/>
                }
                {
                  isMenuActive(organization) &&
                    <SvgTick color={"#1B87E6"}/>
                }

              </div>
            </div>
          })
        }

        <div className={isMenuActive({organizationID: "AGENCY"})
          ? 'organization-nav-item organization-nav-item-active relative'
          : 'organization-nav-item relative'}
             key={"AGENCY"}
             onClick={(e) => handleClick(e, {organizationID: "AGENCY"})}>
          <div className='divider'/>
          <div className="icon">
          </div>
          <div className="expand-right-title" style={{marginTop: 5}}>
            <span>My Hub</span>
            {
              loading == "AGENCY" &&
                <Spin indicator={antIcon}/>
            }
            {
              isMenuActive({organizationID: "AGENCY"}) &&
                <SvgTick color={"#1B87E6"}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyEditionMenu;
