import { EditorState, Modifier, SelectionState } from "draft-js";


export function removeLastCharacter(editorState: EditorState, storedSelection?: SelectionState): EditorState {
  const contentState = editorState.getCurrentContent();
  const selectionState = storedSelection || editorState.getSelection();

  // Get the current selection range
  const startKey = selectionState.getStartKey();
  const startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();

  // Remove the last character from the selected range
  const contentStateWithoutLastChar = Modifier.removeRange(
    contentState,
    selectionState.merge({
      anchorOffset: startOffset - 1,
      focusOffset: endOffset,
    }),
    'backward'
  );

  // Update the editor state with the modified content
  const newEditorState = EditorState.push(
    editorState,
    contentStateWithoutLastChar,
    'remove-range'
  );

  return EditorState.forceSelection(
    newEditorState,
    contentStateWithoutLastChar.getSelectionAfter()
  );
}
