import { ContentBlock, ContentState, EditorState, Modifier, RichUtils, SelectionState, } from "draft-js";
import { getHyperlinkSelectionByEntityMapId } from "./entity";
import { moveSelectionToTheEndOfBlock } from '../../../../../_shared/components/draft-js/components/helpers/entity';

let url: string = "";

export function assignHyperlinkEntity(editorState: EditorState, data: {
  url: string;
  entityKey?: string;
  entityMapId?: string;
}): EditorState {
  url = data?.url;

  const entityKey = data?.entityKey;
  const entityMapId = data?.entityMapId;
  if (entityKey && entityMapId) {
    return manageHyperlinkWithEntityKey(editorState, entityKey, entityMapId);
  } else {
    return manageHyperlinkWithSelection(editorState);
  }
}

function manageHyperlinkWithEntityKey(editorState: EditorState, entityKey: string, entityMapId: string): EditorState {
  const entitySelection: SelectionState | undefined = getHyperlinkSelectionByEntityMapId(editorState, entityMapId);
  const contentState: ContentState = editorState.getCurrentContent();

  // if url is not present remove the entity from selectedText in editor
  if (!url.length && entitySelection) {
    const newContentState = Modifier.applyEntity(contentState, entitySelection, null);
    return EditorState.push(editorState, newContentState, 'apply-entity');
  } else {
    contentState.mergeEntityData(entityKey, {
      url: url,
    });

    return moveSelectionToTheEndOfBlock(editorState);
  }
}

function manageHyperlinkWithSelection(editorState: EditorState): EditorState {
  const selectionState: SelectionState = editorState.getSelection();
  const anchorKey: string = selectionState.getAnchorKey();
  const contentState: ContentState = editorState.getCurrentContent();
  const currentBlock: ContentBlock = contentState.getBlockForKey(anchorKey);

  const start: number = selectionState.getStartOffset();
  const end: number = selectionState.getEndOffset();
  const selectedText: string = currentBlock.getText().slice(start, end);

  if (!url.length) {
    return RichUtils.toggleLink(editorState, selectionState, null);
  } else {
    const contentWithEntity: ContentState = contentState.createEntity(
      "LINK",
      "MUTABLE",
      {
        selectedText,
        url: url,
        entityMapId: Math.floor(100000 + Math.random() * 900000),
      }
    );
    const newEditorState: EditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "apply-entity"
    );
    const entityKey: string | null =
      contentWithEntity.getLastCreatedEntityKey();
    return RichUtils.toggleLink(newEditorState, selectionState, entityKey);
  }
}
