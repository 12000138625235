// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-key-finding-dialog-wrapper .ant-select-selection-item {
  padding-left: 10px !important;
}
.save-key-finding-dialog-wrapper .ant-select-selection-search-input {
  padding-left: 10px !important;
}
.save-key-finding-dialog-wrapper .ant-select-selection-placeholder {
  padding-left: 10px !important;
}
.save-key-finding-dialog-wrapper p {
  font-size: 12px;
  font-weight: 400;
  color: #545e6b;
}
.save-key-finding-dialog-wrapper .key-finding-description {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  color: #545e6b;
}
.save-key-finding-dialog-wrapper .key-finding-project-name {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/ai-chat/components/save-key-finding-dialog/save-key-finding-dialog.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;AAIE;EACE,6BAAA;AAFJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJJ;AAOE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AALJ;AAQE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AANJ","sourcesContent":[".save-key-finding-dialog-wrapper {\n  .ant-select-selection-item {\n    padding-left: 10px !important;\n  }\n\n  .ant-select-selection-search-input {\n    padding-left: 10px !important;\n  }\n\n  .ant-select-selection-placeholder {\n    padding-left: 10px !important;\n  }\n\n\n  p {\n    font-size: 12px;\n    font-weight: 400;\n    color: #545e6b;\n  }\n\n  .key-finding-description {\n    font-size: 12px;\n    font-weight: 300;\n    line-height: 16px;\n    text-align: left;\n    color: #545e6b;\n  }\n\n  .key-finding-project-name {\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 16px;\n    text-align: left;\n    color: #545e6b;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
