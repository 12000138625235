// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.narrow-project-type-card {
  height: 56px;
  display: flex;
  align-items: center;
  border: 1px solid #D8D8D8;
  padding: 8px 12px 8px 16px;
  margin: 8px;
  color: #545E6B;
  font-size: 14px;
  cursor: pointer;
  transition: box-shadow 200ms;
  background-color: #FFFFFF;
}
.narrow-project-type-card:hover {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.narrow-project-type-card .narrow-card-body {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.narrow-project-type-card .narrow-card-body .narrow-card-image {
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
}
.narrow-project-type-card .narrow-card-body .narrow-card-detail {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  color: #545E6B;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/narrow-project-type-card/narrow-project-type-card.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,0BAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,4BAAA;EACA,yBAAA;AAAF;AAEE;EACE,+GAAA;AAAJ;AAGE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AADJ;AAGI;EACE,aAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AADN;AAII;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,SAAA;AAFN","sourcesContent":[".narrow-project-type-card {\n  //min-width: 304px;\n  height: 56px;\n  display: flex;\n  align-items: center;\n  border: 1px solid #D8D8D8;\n  padding: 8px 12px 8px 16px;\n  margin: 8px;\n  color: #545E6B;\n  font-size: 14px;\n  cursor: pointer;\n  transition: box-shadow 200ms;\n  background-color: #FFFFFF;\n\n  &:hover {\n    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);\n  }\n\n  .narrow-card-body {\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    .narrow-card-image {\n      display: flex;\n      height: 80%;\n      justify-content: center;\n      align-items: center;\n      margin-right: 18px;\n    }\n\n    .narrow-card-detail {\n      display: flex;\n      flex-direction: column;\n      font-size: 14px;\n      font-weight: 400;\n      color: #545E6B;\n      flex: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
