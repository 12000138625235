// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-story-wrapper {
  background-color: #f5f5f5;
  overflow-x: hidden;
  height: 100%;
  position: relative;
  width: 100%;
}
.preview-story-wrapper .preview-story-side-bar {
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
  padding-top: 20px;
  position: fixed;
}
.preview-story-wrapper .preview-story-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-left: 115px;
}
.preview-story-wrapper .preview-story-content .preview-story-cover {
  height: 235px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.preview-story-wrapper .preview-story-content .preview-story-no-cover {
  height: 235px;
  width: 100%;
  background-color: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-story-wrapper .preview-story-footer {
  height: 30px;
  width: 100%;
  background-color: #E8E8E8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  position: absolute;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/preview-story/preview-story.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EAEE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AADJ;AAGI;EACE,aAAA;EACA,WAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;AADN;AAII;EACE,aAAA;EACA,WAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFN;AAOE;EACE,YAAA;EACA,WAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;AALJ","sourcesContent":[".preview-story-wrapper {\n  background-color: #f5f5f5;\n  overflow-x: hidden;\n  height: 100%;\n  position: relative;\n  width: 100%;\n\n  .preview-story-side-bar {\n    //width: 115px;\n    min-height: 100%;\n    max-height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    background-color: #f5f5f5;\n    padding-top: 20px;\n    position: fixed;\n  }\n\n  .preview-story-content {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    margin-left: 115px;\n\n    .preview-story-cover {\n      height: 235px;\n      width: 100%;\n      background-repeat: no-repeat;\n      background-size: cover;\n      background-position: center;\n    }\n\n    .preview-story-no-cover {\n      height: 235px;\n      width: 100%;\n      background-color: #F2F2F2;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n\n    }\n  }\n\n  .preview-story-footer {\n    height: 30px;\n    width: 100%;\n    background-color: #E8E8E8;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 12;\n    position: absolute;\n    bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
