// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.external-zip-import-dragger {
  background-color: transparent !important;
  border: 1px dashed #9B9B9B !important;
  width: 255px;
  height: 119px;
  border-radius: 4px;
}
.external-zip-import-dragger .info-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #545e6b;
}

.external-zip-import-folder {
  border: 1px solid #EEEEEE;
  width: 255px;
  height: 119px;
  border-radius: 4px;
  padding: 20px 80px 8px 80px;
  position: relative;
}
.external-zip-import-folder .info-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/home-initial-sync/pages/sync-with-external-data/components/external-zip-import/external-zip-import.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,qCAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAGA;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,2BAAA;EACA,kBAAA;AAAF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AAAJ","sourcesContent":[".external-zip-import-dragger {\n  background-color: transparent !important;\n  border: 1px dashed #9B9B9B !important;\n  width: 255px;\n  height: 119px;\n  border-radius: 4px;\n\n  .info-text {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 14px;\n    letter-spacing: 0px;\n    text-align: left;\n    color: #545e6b;\n  }\n}\n\n.external-zip-import-folder {\n  border: 1px solid #EEEEEE;\n  width: 255px;\n  height: 119px;\n  border-radius: 4px;\n  padding: 20px 80px 8px 80px;\n  position: relative;\n\n  .info-text {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 14px;\n    letter-spacing: 0px;\n    text-align: center;\n    color: #545e6b;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
