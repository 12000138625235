// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragger .dragger-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
}
.dragger .dragger-text {
  color: gray;
}

.video-title {
  color: gray;
  margin-top: 5px;
}

.error-message {
  margin-top: 5px;
}

.fancy-radio-group {
  display: flex;
  background-color: #d8d8d8;
  border-radius: 20px;
  height: 40px;
}
.fancy-radio-group .radio-button {
  width: 50%;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/interviews-labs/interview/details/media.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,WAAA;AADJ;;AAKA;EACE,WAAA;EACA,eAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;AAFF;AAIE;EACE,UAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAFJ","sourcesContent":[".dragger {\n  .dragger-wrapper {\n    margin-top: 50px;\n    margin-bottom: 50px;\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n\n  .dragger-text {\n    color: gray;\n  }\n}\n\n.video-title {\n  color: gray;\n  margin-top: 5px;\n}\n\n.error-message {\n  margin-top: 5px;\n}\n\n.fancy-radio-group {\n  display: flex;\n  background-color: #d8d8d8;\n  border-radius: 20px;\n  height: 40px;\n\n  .radio-button {\n    width: 50%;\n    border-radius: 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    // background-color: #1b87e6;\n    // color: white\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
