import React from 'react';

const SvgNoImage = (props: any) => {
  return (
    <svg width="64" height="48" {...props} viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M60.0528 0C61.7983 0 63.2134 1.34315 63.2134 3V45C63.2134 46.6569 61.7983 48 60.0528 48H3.16067C1.41508 48 0 46.6569 0 45V3C0 1.34315 1.41508 0 3.16067 0H60.0528ZM53.7314 36L39.5084 21L26.9265 34.2692L20.5444 27L12.6427 36H53.7314ZM19.5843 6C15.7506 6 12.6427 8.9499 12.6427 12.5888C12.6427 16.2277 15.7506 19.1776 19.5843 19.1776C23.4181 19.1776 26.526 16.2277 26.526 12.5888C26.526 8.9499 23.4181 6 19.5843 6Z"
            fill={props.color ? props.color : "#d8d8d8"}/>
    </svg>
  );
};

export default SvgNoImage;
