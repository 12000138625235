import Axios from "axios";
import { environment } from "../../environment";
import {
  useFileProgressContext
} from "../../_shared/components/uploader-popup/file-progress-context/file-progress-context";
import GoogleDriveIntegrationApi
  from "../../pages/settings/settings-tabs/setup/integrations/google-drive-integration/api/google-drive-integration-api";
import OneDriveIntegrationApi
  from "../../pages/settings/settings-tabs/setup/integrations/one-drive-integration/api/one-drive-integration-api";
import { useEffect } from "react";
import SharepointIntegrationApi
  from "../../pages/settings/settings-tabs/setup/integrations/sharepoint-integration/api/sharepoint-integration-api";

export function useUploadFile(selectedProject: any) {
  const {
    updateFilesAreBeingChanged,
    updateUploading,
    updateFilesBeingUploaded,
    updateProject,
  } = useFileProgressContext();


  useEffect(() => {
    if (selectedProject) {
      updateProject(selectedProject)
    }
  }, [selectedProject]);


  const uploadDriveFile = (file: any) => {
    if (!selectedProject.id) {
      return;
    }

    updateUploading(true);

    const source = Axios.CancelToken.source();

    file.source = source;
    file.percent = 0;
    file.status = 'uploading';

    updateFilesBeingUploaded(file);

    const config = {
      onUploadProgress: (event: any) => {
        file.percent = Math.floor((event.loaded / event.total) * 100);
        updateFilesAreBeingChanged();
      },
      cancelToken: source.token
    };

    if (file.externalUrl.includes("google")) {
      GoogleDriveIntegrationApi.importFilesToProject(selectedProject.id, file.externalUrl, [file.name], config).then((data) => {
        file.status = 'done';
        updateFilesAreBeingChanged();
      }).catch((err) => {
        if (err?.message === "canceled") {
          file.status = "canceled"
        } else {
          file.status = "error"
        }
      })
    } else if (file.externalUrl.includes("onedrive")) {
      OneDriveIntegrationApi.importFilesToProject(selectedProject.id, file.externalUrl, [file.name], config).then((data) => {
        file.status = 'done';
        updateFilesAreBeingChanged();
      })
        .catch((err) => {
          if (err?.message === "canceled") {
            file.status = "canceled";
          } else {
            file.status = "error";
          }
        });
    } else if (file.externalUrl.includes('sharepoint')) {
      SharepointIntegrationApi.importFilesToProject(selectedProject.id, file.externalUrl, [file.name], config).then((data) => {
        file.status = 'done';
        updateFilesAreBeingChanged();
      })
        .catch((err) => {
          if (err?.message === "canceled") {
            file.status = "canceled";
          } else {
            file.status = "error";
          }
        });
    }
  }

  const uploadZipFile = async (file: any) => {
    updateUploading(true);

    const fmData = new FormData();
    let token = localStorage.getItem('access_token');

    const source = Axios.CancelToken.source();

    file.source = source;
    file.percent = 0;
    file.status = 'uploading';

    updateFilesBeingUploaded(file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        'Authorization': 'Bearer ' + token,
      },
      onUploadProgress: (event: any) => {
        file.percent = Math.floor((event.loaded / event.total) * 100);
        updateFilesAreBeingChanged();
      },
      cancelToken: source.token
    };

    let newFileList: any[] = [];
    fmData.append('file', file)

    try {
      const res = await Axios.post(`${environment.backendUrl}/projects/upload/${selectedProject.id}`, fmData, config);
      if (res.data) {
        file.status = 'done';
        updateFilesAreBeingChanged();
      }

    } catch (err) {
      if (err?.message === "canceled") {
        file.status = "canceled"
      } else {
        file.status = "error"
        // showErrorToast()
      }
      //onError({err})
    }
  }

  return {
    uploadDriveFile,
    uploadZipFile
  }
}