import * as React from "react";

const SvgSettings = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g id="Icon/SideNav/Settings">
        <path
          d="M9.4,2.57690991 C9.90131304,2.70582196 10.38825,2.90518649 10.8450975,3.17500348 L11.959798,2.06030304 L13.939697,4.04020203 L12.8249965,5.15490246 C13.0948135,5.61174997 13.294178,6.09868696 13.4230901,6.6 L15,6.6 L15,9.4 L13.4230901,9.4 C13.294178,9.90131304 13.0948135,10.38825 12.8249965,10.8450975 L13.939697,11.959798 L11.959798,13.939697 L10.8450975,12.8249965 C10.38825,13.0948135 9.90131304,13.294178 9.4,13.4230901 L9.4,15 L6.6,15 L6.6,13.4230901 C6.09868696,13.294178 5.61174997,13.0948135 5.15490246,12.8249965 L4.04020203,13.939697 L2.06030304,11.959798 L3.17500348,10.8450975 C2.90518649,10.38825 2.70582196,9.90131304 2.57690991,9.4 L1,9.4 L1,6.6 L2.57690991,6.6 C2.70582196,6.09868696 2.90518649,5.61174997 3.17500348,5.15490246 L2.06030304,4.04020203 L4.04020203,2.06030304 L5.15490246,3.17500348 C5.61174997,2.90518649 6.09868696,2.70582196 6.6,2.57690991 L6.6,1 L9.4,1 L9.4,2.57690991 Z M6.02010101,9.97989899 C7.11356903,11.073367 8.88643097,11.073367 9.97989899,9.97989899 C11.073367,8.88643097 11.073367,7.11356903 9.97989899,6.02010101 C8.88643097,4.926633 7.11356903,4.926633 6.02010101,6.02010101 C4.926633,7.11356903 4.926633,8.88643097 6.02010101,9.97989899 Z"
          id="Shape" fill={props.color ? props.color : '#545E6B'}/>
      </g>
    </svg>
  );
}

export default SvgSettings;
