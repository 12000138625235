import { createContext, useEffect, useState } from 'react';
import { ITask } from '../model/task.interface';
import InterviewLabsSettingsApi from '../api/interview-labs-settings.api';
import { IGuideTask } from '../model/guide-task.interface';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { compositeDecorator } from '../../../../../_shared/components/draft-js/components/decorators';
import useDebounce from '../../../../../_shared/helpers/use-debounce.hook';

export const InterviewLabsSettingsContext = createContext({
  tasks: [],
  loader: true,
  saving: false,
  saved: false,
  content: EditorState.createEmpty(compositeDecorator()),
  optionValueChanged: (index: number, value: string) => {
  },
  createNewOption: (task: ITask, index: number) => {
  },
  deleteOption: (index: number) => {
  },
  onSetSaving: (value: boolean) => {
  },
  onSetSaved: (value: boolean) => {
  },
  onSetEditorState: (editorState: string) => {
  }
});

export function InterviewLabsSettingsContextProvider(props: any): JSX.Element {
  const [loader, setLoader] = useState<boolean>(true);
  const [content, setContent] = useState<EditorState>(EditorState.createEmpty(compositeDecorator()));
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty(compositeDecorator()));

  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any>();
  const debouncedGuide = useDebounce(JSON.stringify(convertToRaw(editorState?.getCurrentContent())), 600);
  const debouncedTasks = useDebounce(tasks, 600);

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (debouncedGuide || debouncedTasks) {
      updateGuideTask();
    }
  }, [debouncedGuide, debouncedTasks])


  function updateGuideTask(): void {
    if (tasks?.length && editorState) {
      setSaved(false);
      setSaving(true);

      const payload: IGuideTask = {
        guide: editorState && JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        tasks: tasks
      }

      InterviewLabsSettingsApi.update(payload)
        .then(() => {
          setSaved(true);
        });
    }
  }

  const context = {
    tasks,
    loader,
    saving,
    saved,
    content,
    optionValueChanged,
    createNewOption,
    deleteOption,
    onSetSaving,
    onSetSaved,
    onSetEditorState
  };

  function loadPage(): void {
    InterviewLabsSettingsApi.get()
      .then((response: IGuideTask) => {
        const editorState: EditorState = response.guide
          ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.guide)), compositeDecorator())
          : EditorState.createEmpty(compositeDecorator());

        setContent(response.guide);
        setEditorState(editorState);
        if (response.tasks.length >= 1) {
          setTasks(response.tasks);
        } else {
          const uuid = new Date().getTime();
          setTasks([{uuid, text: ''}])
        }
        setLoader(false);
      });
  }


  function createNewOption(task: ITask, index: number): void {
    const copyTasks: ITask[] = JSON.parse(JSON.stringify(tasks));
    const newTasks: ITask[] = [];
    const uuid = new Date().getTime();

    copyTasks.splice(index + 1, 0, {
      uuid,
      text: '',
    });

    copyTasks.forEach((task: ITask) => {
      const newTask: ITask = {
        ...task,
      }

      newTasks.push(newTask);
    });

    setTasks(newTasks);
  }

  function optionValueChanged(index: number, value: string): void {
    const newTasks: ITask[] = JSON.parse(JSON.stringify(tasks));

    newTasks[index].text = value;
    setTasks(() => {
      return newTasks
    });
  }

  function deleteOption(index: number): void {
    const newTasks: ITask[] = JSON.parse(JSON.stringify(tasks));
    if (newTasks.length === 1) {
      return;
    }

    newTasks.splice(index, 1);

    setTasks(newTasks);
  }

  function onSetSaving(value: boolean): void {
    setSaving(value);
  }

  function onSetSaved(value: boolean): void {
    setSaved(value);
  }

  function onSetEditorState(value: string): void {
    const editorState: EditorState = EditorState.createWithContent(convertFromRaw(JSON.parse(value)), compositeDecorator());
    setEditorState(editorState);
  }

  return <InterviewLabsSettingsContext.Provider value={context}>
    {props.children}
  </InterviewLabsSettingsContext.Provider>
}

export default InterviewLabsSettingsContext;
