import * as React from "react";

const SvgSave = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1165 -564) translate(131 552)">
          <path fill="#3FCA5A"
                d="M2 8c.552-.552 1.448-.553 2 0l2 1.996L13 3c.552-.552 1.448-.552 2 0s.552 1.448 0 2l-9 9-4-4c-.552-.552-.552-1.448 0-2z"
                transform="translate(1034 12)"/>
        </g>
      </g>
    </svg>

  );
}

export default SvgSave;
