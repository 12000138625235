import React, { useEffect, useState } from 'react';
import './settings.scss';
import { Tabs } from 'antd';
import { Team } from './settings-tabs/team/team';
import { Archived } from './settings-tabs/archived/archived';
import SvgTrash from '../../assets/icons/js/Trash';
import deskSettingsApi from './api/settings-api';
import { IArchived } from './models/archived.interface';
import { Setup } from './settings-tabs/setup/setup';
import qs from 'query-string';
import { InterviewLabsSettings } from './settings-tabs/interview-labs-settings/interview-labs-settings';
import { useLocation, useNavigate } from 'react-router-dom';
import { Taxonomy } from "./settings-tabs/taxonomy/taxonomy";
import { ProjectManagement } from "./settings-tabs/project-management/project-management";
import { AskAi } from "./settings-tabs/ask-ai/ask-ai";
import QuestionProImports from "./settings-tabs/question-pro-imports/question-pro-imports";

export function Settings(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam: any = qs.parse(location.search);
  const [activeTab, setActiveTab] = useState<string>('1');
  const [archivedNumber, setArchivedNumber] = useState<number>(0);

  useEffect(() => {
    const activeTab = urlParam.tab;

    if (activeTab) {
      setActiveTab(activeTab);
    }

    loadArchives();
  }, []);

  const changeTab = (activeKey: string) => {
    setActiveTab(activeKey);

    const params = {
      tab: activeKey
    }

    navigate({
      pathname: '',
      search: qs.stringify(params)
    }, {replace: true});
  };


  const handleArchivesNumber = (props: any) => {
    let tempArchivedNumber: number;

    props ? tempArchivedNumber = archivedNumber + 1 : tempArchivedNumber = archivedNumber - 1;

    setArchivedNumber(tempArchivedNumber);
  }


  async function loadArchives() {
    const archivedForms = await deskSettingsApi.getArchivedForms({page: 1, pageSize: 1})
    const archivedMetatags = await deskSettingsApi.getArchivedMetatags({page: 1, pageSize: 1});

    setArchivedNumber(archivedForms.pagination.totalElements + archivedMetatags.pagination.totalElements)
  }

  return (
    <div className="desk-settings">
      <div className="header">
        <h2>Hub settings</h2>
      </div>
      <div className="body">
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={activeTab}
          onChange={changeTab}
          items={[
            {
              key: "1",
              label: "Taxonomy",
              children: <Taxonomy updateArchive={handleArchivesNumber}/>
            },
            {
              key: "2",
              label: "Project management",
              children: <ProjectManagement/>
            },
            {
              key: "3",
              label: "Notepads",
              children: <InterviewLabsSettings/>
            },
            {
              key: "4",
              label: "Team",
              children: <Team/>
            },
            {
              key: "5",
              label: "Setup",
              children: <Setup/>
            },
            {
              key: "7",
              label: "QuestionPro imports",
              children: <QuestionProImports/>
            },
            {
              key: "8",
              label: "AskIH",
              children: <AskAi/>
            },
            {
              key: "9",
              label: <div className="row align-items-center archived">
                <span>{archivedNumber}</span>
                <SvgTrash color='#9b9b9b'/>
              </div>,
              children: <Archived updateArchive={handleArchivesNumber}/>
            },
          ]}>
        </Tabs>
      </div>
    </div>
  );
}
