import axios from "axios";

async function getImageInfo(url: string) {
  try {
    const image = new Image();
    image.src = url;
    await new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
    });

    const type = url.split('.').pop();

    return {width: image.width, height: image.height, type};
  } catch (e) {
    console.error(e);
  }
}

export { getImageInfo }