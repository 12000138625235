import React, { useEffect, useState } from 'react';
import "./activity-feed-dialog.scss"
import { Empty, Modal } from "antd";
import ActivityCard from "../../../../../projects/project-edit/tabs/activity-log/activity-card/activity-card";
import ActivityFilters from "../components/activity-filters/activity-filters";
import { ActivitiesFilterEnum } from "../components/activity-filters/model/activities-filter.enum";
import { useQuery } from "react-query";
import ActivityFeedApi from "../api/activity-feed-api";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import UserActivityDialog from "../user-activity-dialog/user-activity-dialog";
import Pagination from "../../../../../../_shared/components/pagination/pagination";

const activityOptions = [
  {value: ActivitiesFilterEnum.ALL_ACTIVITY, label: "All activity"},
  {value: ActivitiesFilterEnum.PROJECTS_ACTIVITY, label: "Project updates"},
  {value: ActivitiesFilterEnum.NUGGETS_ACTIVITY, label: "Key findings"},
  {value: ActivitiesFilterEnum.KNOWLEDGE_GRAPHS_ACTIVITY, label: "Knowledge graphs"},
  {value: ActivitiesFilterEnum.STORIES_ACTIVITY, label: "Stories"}
]

const ActivityFeedDialog = (props: any) => {
  const [activitiesFilter, setActivitiesFilter] = useState<ActivitiesFilterEnum>(ActivitiesFilterEnum.ALL_ACTIVITY)
  const [membersFilter, setMembersFilter] = useState<null | any[]>(null);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [userActivityDialog, setUserActivityDialog] = useState<any>({visible: false});


  const activities = useQuery(['activities', pageSize, page, membersFilter, activitiesFilter], () => ActivityFeedApi.getAllActivities({
    pageSize,
    page,
    usersId: membersFilter ? membersFilter.toString() : "",
    activityTypes: activitiesFilter === ActivitiesFilterEnum.ALL_ACTIVITY ? "" : activitiesFilter
  }))

  useEffect(() => {
    setPage(1)
  }, [membersFilter, activitiesFilter, pageSize]);

  const openUserActivityDialog = (user: { userId: number }) => {
    setUserActivityDialog({visible: true, ...user})
  }

  const closeUserActivityDialog = () => {
    setUserActivityDialog({visible: false, userId: 0})
  }

  return (
    <Modal title="Activity Feed"
           open={props.data.visible}
           onCancel={props.onCancel}
           width={1200}
           footer={[]}
    >
      <div className='activity-feed-dialog-wrapper'>
        <div className='row justify-space-between' style={{marginBottom: 10}}>
          <ActivityFilters
            setMembersFilter={setMembersFilter}
            membersFilter={membersFilter}
            activitiesFilter={activitiesFilter}
            setActivitiesFilter={setActivitiesFilter}
            activityOptions={activityOptions}/>
          {
            activities.data && activities.data.total[0].count > 0 &&
            <Pagination
              pageSizeOptions={[5, 10, 20, 50]}
              pageSize={pageSize}
              showSizeChanger
              onChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
              }}
              currentPage={page}
              totalItems={activities.data.total[0].count}/>
          }
        </div>
        <div className='activities-wrapper'>
          {
            activities.isLoading ? <Loader/> : !activities.data?.data.length ?
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
              activities.data?.data.map((activity, i) => {
                return <ActivityCard activity={activity} key={i}
                                     openUserActivityDialog={openUserActivityDialog} source='fromHome'/>
              })
          }
        </div>

      </div>
      {
        userActivityDialog.visible &&
          <UserActivityDialog data={userActivityDialog} onCancel={closeUserActivityDialog}/>
      }
    </Modal>
  );
};

export default ActivityFeedDialog;
