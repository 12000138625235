import { Image } from 'antd';
import { DraftDecoratorComponentProps } from 'draft-js'
import React from 'react'
import "./preview-image-strategy.scss"

const PreviewImageStrategy = (props: any) => {
  let url: string;
  let username: string;
  let userUrl: string;
  let captionValue: string;
  let view: any;

  let unsplashUrl: string = "https://www.unsplash.com"
  if (props.entityKey != null) {
    const data = props.contentState.getEntity(props?.entityKey).getData();
    url = data.url;
    username = data?.username;
    userUrl = data?.userUrl;
    captionValue = data?.captionValue;
    view = data?.view;
  } else {
    return null;
  }

  return (
    <div className='pointer row justify-space-center' style={{marginBottom: 10, marginTop: 10}}>
      <div className='column' style={{width: view?.narrow ? "70%" : "100%"}}>
        <Image src={url} alt=""/>
        {
          (username || userUrl) &&
            <div className='w-100 flex-end unsplash-label'>
                Photo By <a
                rel="noopener noreferrer"
                target="_blank"
                href={userUrl}
            >
              {username}
            </a> on <a rel="noopener noreferrer"
                       target="_blank"
                       href={unsplashUrl}
            >Unsplash</a>
            </div>
        }
        {
          view?.caption &&
            <span className='unsplash-label'>{captionValue}</span>
        }
      </div>
    </div>
  )
}

export default PreviewImageStrategy
