// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-nuggets-wrapper .nuggets-wrapper {
  min-height: 100px;
  width: 100%;
  margin-bottom: 20px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.project-nuggets-wrapper .action-buttons {
  gap: 10px;
}
.project-nuggets-wrapper .action-buttons .icon-button {
  margin-right: 0;
}
.project-nuggets-wrapper .ant-table .ant-table-cell {
  transition: background 0ms;
}
.project-nuggets-wrapper .ant-table .ant-table-row:hover + .ant-table-expanded-row {
  background-color: #F5F5F5;
}
.project-nuggets-wrapper .ant-table .ant-table-expanded-row .ant-table-cell {
  padding: 0;
}
.project-nuggets-wrapper .ant-table .ant-table-expanded-row:hover {
  background-color: #F5F5F5;
}
.project-nuggets-wrapper .nugget-node-wrapper {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/nuggets/nuggets.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EAEA,WAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AADJ;AAIE;EACE,SAAA;AAFJ;AAII;EACE,eAAA;AAFN;AAOI;EACE,0BAAA;AALN;AAQI;EACE,yBAAA;AANN;AAUM;EACE,UAAA;AARR;AAWM;EACE,yBAAA;AATR;AAcE;EACE,aAAA;AAZJ","sourcesContent":[".project-nuggets-wrapper {\n  .nuggets-wrapper {\n    min-height: 100px;\n    //background-color: #F5F5F5;\n    width: 100%;\n    margin-bottom: 20px;\n    padding: 8px 0;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n\n  .action-buttons {\n    gap: 10px;\n\n    .icon-button {\n      margin-right: 0;\n    }\n  }\n\n  .ant-table {\n    .ant-table-cell {\n      transition: background 0ms;\n    }\n\n    .ant-table-row:hover + .ant-table-expanded-row {\n      background-color: #F5F5F5;\n    }\n\n    .ant-table-expanded-row {\n      .ant-table-cell {\n        padding: 0;\n      }\n\n      &:hover {\n        background-color: #F5F5F5;\n      }\n    }\n  }\n\n  .nugget-node-wrapper {\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
