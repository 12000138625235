// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customize-table-dialog .isDragging {
  background-color: rgba(84, 94, 107, 0.5);
}
.customize-table-dialog .ant-radio-group .ant-radio-wrapper {
  color: #545e6b;
}
.customize-table-dialog .customize-table-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 32px;
  border-bottom: 1px solid #D8D8D8;
  color: #545E6B;
  padding: 0 10px;
  border-radius: 4px;
}
.customize-table-dialog .customize-table-column .title {
  display: flex;
  align-items: center;
}
.customize-table-dialog .customize-table-column .title *:first-child {
  margin-right: 10px;
}
.customize-table-dialog .customize-table-column .title .drag-handle {
  padding: 8px;
}

.customize-table-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/customize-table-dialog/customize-table-dialog.scss"],"names":[],"mappings":"AACE;EACE,wCAAA;AAAJ;AAII;EACE,cAAA;AAFN;AAME;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;AAJJ;AAOI;EACE,aAAA;EACA,mBAAA;AALN;AAOM;EACE,kBAAA;AALR;AAQM;EACE,YAAA;AANR;;AAaE;EACE,WAAA;EACA,YAAA;AAVJ","sourcesContent":[".customize-table-dialog {\n  .isDragging {\n    background-color: rgb(84, 94, 107, 0.5);\n  }\n\n  .ant-radio-group {\n    .ant-radio-wrapper {\n      color: #545e6b;\n    }\n  }\n\n  .customize-table-column {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 10px;\n    height: 32px;\n    border-bottom: 1px solid #D8D8D8;\n    color: #545E6B;\n    padding: 0 10px;\n    border-radius: 4px;\n\n\n    .title {\n      display: flex;\n      align-items: center;\n\n      *:first-child {\n        margin-right: 10px;\n      }\n\n      .drag-handle {\n        padding: 8px;\n      }\n    }\n  }\n}\n\n.customize-table-checkbox {\n  .ant-checkbox-inner {\n    width: 18px;\n    height: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
