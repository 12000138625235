import React from 'react';
import '../external-tool-tile/external-tool-tile.scss'
import { ISurvey } from "../../model/survey.interface";
import { Dropdown, MenuProps } from "antd";
import SvgEdit from "../../../../../../../assets/icons/js/Edit";
import SvgTrash from "../../../../../../../assets/icons/js/Trash";
import SvgInsightsDeskWrapped from "../../../../../../../assets/icons/js/InsightsDeskWrapped";
import { useNavigate } from "react-router-dom";

const SurveyTile = (props: {
  linkedSurvey: ISurvey,
  openConfirmationDialog: (linkedSurvey: ISurvey) => void,
  openSurveyDialog: (linkedSurvey?: ISurvey) => void,
  projectId: number;
}) => {
  const {linkedSurvey, openConfirmationDialog, openSurveyDialog} = props;
  const navigate = useNavigate();

  const menuItems: MenuProps['items'] = [
    {
      key: "edit",
      label: (
        <div className="snapshot-icon-button d-flex align-items-center" onClick={() => openSurveyDialog(linkedSurvey)}>
          <SvgEdit/> Edit
        </div>
      ),
    },
    {
      key: "delete",
      label: (
        <div className="snapshot-icon-button d-flex align-items-center"
             onClick={() => openConfirmationDialog(linkedSurvey)}>
          <SvgTrash/> Delete
        </div>
      ),
    },
  ];


  return (
    <div className="external-tool-tile-wrapper">
      <div className="external-tool-tile-header">
        <SvgInsightsDeskWrapped/>
        <span>QuestionPro survey</span>
        <div className="external-tool-tile-dropdown">
          <Dropdown trigger={["click"]} menu={{items: menuItems}}>
            <div className="pointer" data-testid={"more-options"}>
              <i className="bi bi-three-dots-vertical" style={{fontSize: 18}}/>
            </div>
          </Dropdown>
        </div>
      </div>
      {/*<div className="external-tool-tile-description">*/}
      {/*  {linkedSurvey.description}*/}
      {/*</div>*/}
      <div className="external-tool-tile-name pointer"
           onClick={() => navigate('/surveys/details?surveyId=' + linkedSurvey.surveyId, {state: {projectId: props.projectId}})}>
        <span>{linkedSurvey.name}</span>
      </div>
      <div className="external-tool-tile-details">
        {
          linkedSurvey?.qpOwner &&
          <div className="row">
            <div className="external-tool-tile-detail-title">Owner</div>
            <div
              className="external-tool-tile-detail-value">{linkedSurvey.qpOwner.firstName ? linkedSurvey.qpOwner.firstName + " " + linkedSurvey.qpOwner?.lastName : linkedSurvey.qpOwner?.emailAddress}</div>
          </div>
        }

        {/*<div className="row">*/}
        {/*  <div className="external-tool-tile-detail-title">Date Created</div>*/}
        {/*  <div*/}
        {/*    className="external-tool-tile-detail-value">{linkedSurvey.createdTime && DaysLeftDatePipe(linkedSurvey.createdTime)}</div>*/}
        {/*</div>*/}
        <div className="row">
          <div className="external-tool-tile-detail-title">Responses</div>
          <div className="external-tool-tile-detail-value">{linkedSurvey.completedResponses}</div>
        </div>
        {/*<div className="row">*/}
        {/*  <div className="external-tool-tile-detail-title">Type</div>*/}
        {/*  <div className="external-tool-tile-detail-value">{linkedSurvey.type}</div>*/}
        {/*</div>*/}
        {/*<div className="row">*/}
        {/*  <div className="external-tool-tile-detail-title">Status</div>*/}
        {/*  <div className="external-tool-tile-detail-value">{linkedSurvey.status}</div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default SurveyTile;

