import React from 'react';
import TextFit from "../../../../../../_shared/components/text-fit/text-fit";
import SvgQpLogoIH from "../../../../../../assets/icons/js/qp-logo-ih";
import { ICustomTheme } from "../interface/custom-theme.interface";
import { format } from "date-fns";

const LeftTheme: React.FC<ICustomTheme> = ({snapshotTheme, nugget, createdAt, createdBy, project}) => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <div className="logo-img-wrapper" style={{alignSelf: 'flex-start'}}>
        {snapshotTheme?.logoUrl && (
          <img src={snapshotTheme.logoUrl} alt="Logo" style={{height: '48px'}}/>
        )}
      </div>

      <div style={{
        width: '400px',
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}>
        <div className="key-finding-wrapper" style={{height: '231px'}}>
          <TextFit
            mode="multi"
            min={12}
            max={56}
            style={{
              height: '100%',
              width: '100%',
              lineHeight: 1.5,
              fontWeight: '500'
            }}>
            {nugget}
          </TextFit>
        </div>
        <div className="project-details-wrapper">
          {
            snapshotTheme.showDetails &&
            <>
              {project}
              <div style={{fontWeight: 500}}>
                - {createdBy.displayName} , {format(new Date(createdAt), 'MMMM yyyy')}
              </div>
            </>
          }
        </div>
      </div>

      <div className="qp-logo-wrapper" style={{alignSelf: 'flex-start'}}>
        <SvgQpLogoIH/>
      </div>
    </div>
  );
};

export default LeftTheme;
