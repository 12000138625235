import React from 'react';
import './project-search-card.scss';
import InformIconsPipe from '../../../../../../../_shared/helpers/inform-icons-pipe';
import SvgLabel from '../../../../../../../assets/icons/js/label';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { ISearchProject } from '../../../models/search-project';
import SvgRequestedBy from '../../../../../../../assets/icons/js/owner';

export function ProjectSearchCard(props: { project: ISearchProject }): JSX.Element {
  const navigate = useNavigate();
  const project: ISearchProject = props.project;

  function navigateToProject(id: number): void {
    navigate({pathname: '/projects/edit', search: qs.stringify({id})});
  }

  return (
    <>
      {project &&
          <div className="project-search-container">
              <div className="matches">
                {project?.matches ?
                  <span>{project?.matches} {project.matches === 1 ? 'match' : 'matches'}</span> : <></>
                }
              </div>
              <div
                  className={!!project?.reports?.length ? "project-search-card" : "project-search-card project-search-card-no-reports"}>
            <span className="description"
                  style={{marginTop: 0, paddingBottom: 4, paddingLeft: 2}}>ID: {project?.id}</span>
                  <div className="project-search-card-header row justify-space-between">
                      <div className="title-icon row align-item-center" onClick={() => navigateToProject(project?.id)}>
                          <InformIconsPipe icon={project?.icon} color={project?.color} width={24}
                                           height={24}/>
                          <h3 className="d-flex"
                              dangerouslySetInnerHTML={{__html: project?.title}}/>
                      </div>

                      <div className="tags">
                        {project?.tags?.length ?
                          <div className="row tags align-items-center">
                            <SvgLabel className="label-icon" width={16} style={{marginRight: 4}}/>
                            {project?.tags.map((tag: string, i: number) => {
                              return (
                                <div key={i} className="tag" dangerouslySetInnerHTML={{__html: tag}}/>
                              )
                            })}
                          </div>
                          :
                          <></>
                        }
                      </div>
                  </div>
                  <div className="owner-content">
                    {project?.owner && <div className="d-flex align-item-center"
                                            style={{marginRight: 16, wordWrap: 'break-word', wordBreak: 'break-word'}}>
                        <SvgRequestedBy width={21} height={21} style={{marginRight: 10}}/>
                        <span dangerouslySetInnerHTML={{__html: project?.owner}}/>
                    </div>}
                  </div>
                  <div className="description">
                      <span dangerouslySetInnerHTML={{__html: project?.description}}/>
                  </div>
              </div>
          </div>
      }
    </>
  );
}
