import React from 'react';

const SvgEye = (props: any) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M16.5 8C16.5 6 12.9183 2 8.5 2C4.08172 2 0.5 6 0.5 8C0.5 10 4.08172 14 8.5 14C12.9183 14 16.5 10 16.5 8ZM12.4807 5.60321L12.4607 5.44284C13.6625 6.29833 14.5001 7.34085 14.5001 7.99995C14.5001 9.27853 11.3482 12 8.50008 12C5.65195 12 2.50008 9.27853 2.50008 7.99995C2.50008 7.34085 3.33765 6.29833 4.53847 5.44353C4.51316 5.62538 4.50008 5.81114 4.50008 5.99995C4.50008 8.20909 6.29094 9.99995 8.50008 9.99995C10.7092 9.99995 12.5001 8.20909 12.5001 5.99995C12.5001 5.86607 12.4935 5.73372 12.4807 5.60321Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>
  );
};

export default SvgEye;
