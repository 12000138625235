import { environment } from '../../../../../../../environment';
import { IGoogleDriveProject } from '../../google-drive-integration/model/google-drive-project.interface';
import { IGoogleDriveFolder } from '../../google-drive-integration/model/google-drive-folder.interface';
import { axiosClient } from '../../../../../../../axios-client';
import { AxiosResponse } from 'axios';

class ZipImportApi {
  baseUrl: string = `${environment.backendUrl}/integrations/zip-file`;

  async get(): Promise<IGoogleDriveProject[]> {
    try {
      const response: AxiosResponse<IGoogleDriveProject[]> =
        await axiosClient.get<IGoogleDriveProject[]>(`${this.baseUrl}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async importProject(data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        `${this.baseUrl}/import`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(
        `${this.baseUrl}/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getImportById(id: string): Promise<IGoogleDriveFolder> {
    try {
      const response: AxiosResponse<IGoogleDriveFolder> =
        await axiosClient.get<IGoogleDriveFolder>(`${this.baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async editImportData(data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        `${this.baseUrl}/import`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async utils(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${environment.backendUrl}/integrations/google-drive/imports/utils`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ZipImportApi();
