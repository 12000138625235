// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usage-stats-tasks {
  overflow: auto;
}
.usage-stats-tasks .task {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  justify-content: space-between;
  border-left: 4px solid transparent;
  padding: 8px 2px;
  padding-left: 4px;
  margin: 6px 0;
  cursor: pointer;
}
.usage-stats-tasks .task:hover {
  color: #1b87e6;
}
.usage-stats-tasks .task .task-total-assigned {
  padding: 5px 10px;
  background-color: #f5f5f5;
  text-align: center;
  border-radius: 2px;
}
.usage-stats-tasks .active {
  border-left: 4px solid #1b87e6;
  margin: 6px 0;
  background-color: #f5f5f5;
}
.usage-stats-tasks .active .flag-total-assigned {
  background-color: #e8e8e8;
}
.usage-stats-tasks .selected-task-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  margin-bottom: 30px;
  background-color: #eeeeee;
  font-size: 18px;
  font-weight: 400;
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/interviews-labs/usage-stats/usage-stats-view-all/tabs/all-tasks/all-tasks.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;EACA,8BAAA;EACA,kCAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,eAAA;AACJ;AACI;EACE,cAAA;AACN;AAEI;EACE,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AAAN;AAIE;EACE,8BAAA;EACA,aAAA;EACA,yBAAA;AAFJ;AAII;EACE,yBAAA;AAFN;AAME;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAJJ","sourcesContent":[".usage-stats-tasks {\n  overflow: auto;\n\n  .task {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    background-color: transparent;\n    justify-content: space-between;\n    border-left: 4px solid transparent;\n    padding: 8px 2px;\n    padding-left: 4px;\n    margin: 6px 0;\n    cursor: pointer;\n\n    &:hover {\n      color: #1b87e6;\n    }\n\n    .task-total-assigned {\n      padding: 5px 10px;\n      background-color: #f5f5f5;\n      text-align: center;\n      border-radius: 2px;\n    }\n  }\n\n  .active {\n    border-left: 4px solid #1b87e6;\n    margin: 6px 0;\n    background-color: #f5f5f5;\n\n    .flag-total-assigned {\n      background-color: #e8e8e8;\n    }\n  }\n\n  .selected-task-header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 10px;\n    margin-bottom: 30px;\n    background-color: #eeeeee;\n    font-size: 18px;\n    font-weight: 400;\n    color: #545e6b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
