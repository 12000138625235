import * as React from "react";

const SvgMultimedia = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"
            d="M3.4 2C2.6268 2 2 2.6268 2 3.4V17.4C2 18.1732 2.6268 18.8 3.4 18.8H17.4C18.1732 18.8 18.8 18.1732 18.8 17.4V3.4C18.8 2.6268 18.1732 2 17.4 2H3.4ZM23 10.4C22.2268 10.4 21.6 11.0268 21.6 11.8V17.4C21.6 18.1732 22.2268 18.8 23 18.8H28.6C29.3732 18.8 30 18.1732 30 17.4V11.8C30 11.0268 29.3732 10.4 28.6 10.4H23ZM21.6 23C21.6 22.2268 22.2268 21.6 23 21.6H28.6C29.3732 21.6 30 22.2268 30 23V28.6C30 29.3732 29.3732 30 28.6 30H23C22.2268 30 21.6 29.3732 21.6 28.6V23ZM11.8 21.6C11.0268 21.6 10.4 22.2268 10.4 23V28.6C10.4 29.3732 11.0268 30 11.8 30H17.4C18.1732 30 18.8 29.3732 18.8 28.6V23C18.8 22.2268 18.1732 21.6 17.4 21.6H11.8ZM4.67273 11.9273C4.67273 10.8729 5.52746 10.0182 6.58182 10.0182C7.63618 10.0182 8.49091 10.8729 8.49091 11.9273C8.49091 12.5592 8.18388 13.1194 7.71086 13.4669L9.01497 14.5102L12.9455 10.0182L17.4 15.1091H3.4L5.45277 13.4669C4.97975 13.1194 4.67273 12.5592 4.67273 11.9273Z"
            fill={props.color ? props.color : "#545E6B"}/>
    </svg>
  );
};

export default SvgMultimedia;
