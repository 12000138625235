import React, { ChangeEvent, useEffect, useState } from 'react';
import { Loader } from '../../../../../../../../_shared/components/loader/loader';
import { DebounceInput } from 'react-debounce-input';
import { Button, Table, Tooltip } from 'antd';
import GoogleDriveIntegrationApi from '../../api/google-drive-integration-api';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SvgGoogleDrive from '../../../../../../../../assets/icons/js/googleDrive';
import SvgHelp from '../../../../../../../../assets/icons/js/Help';
import { ColumnsType } from 'antd/lib/table';
import SvgEdit from '../../../../../../../../assets/icons/js/Edit';
import SvgTrash from '../../../../../../../../assets/icons/js/Trash';
import { GoogleDriveStateEnum } from '../../model/google-drive-state.enum';
import { ConfirmationDialog } from '../../../../../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import FormattedDate from '../../../../../../../../_shared/helpers/formatted-date';

export default function GoogleDriveSettingsTab(props: any) {
  const navigate: NavigateFunction = useNavigate();
  const [loader, setLoader] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [isUrl, setIsurl] = useState<boolean>(false);
  const [folder, setFolder] = useState<any>(null);
  const [error, setError] = useState<string>();
  const [importedProjects, setImportedProjects] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});
  const columns: ColumnsType<any> = [
    {
      title: 'Batch #',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Started By',
      dataIndex: 'startedBy',
      key: 'startedBy',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startedBy',
      render: (value: string) => <span>
        <FormattedDate date={value} formatString="MMM d, yyyy"/>
      </span>
    },
    {
      title: 'Last Edit',
      dataIndex: 'lastEdit',
      key: 'lastEdit',
      render: (value: string) => <span>
        <FormattedDate date={value} formatString="MMM d, yyyy"/>
      </span>
    },
    {
      title: 'Projects',
      dataIndex: 'projectsNumber',
      key: 'projectsNumber',
    },
    {
      title: 'Files',
      dataIndex: 'filesNumber',
      key: 'filesNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <span>{status[0].toUpperCase() + status.slice(1).toLocaleLowerCase()}</span>
    },
    {
      title: '',
      dataIndex: 'delete',
      align: 'right',
      render: (empty: undefined, driveImport: any) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            {driveImport.status === GoogleDriveStateEnum.DRAFT &&
                <>
                    <Tooltip placement="bottom" title="Edit Import">
                        <button className="icon-button" onClick={() => editImport(driveImport.id)}>
                            <SvgEdit/>
                        </button>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Delete Import">
                        <button className="icon-button" onClick={() => openConfirmationDialog(driveImport.id)}>
                            <SvgTrash/>
                        </button>
                    </Tooltip>
                </>
            }
          </div>
        </div>
      )
    }
  ];

  useEffect(() => {
    getImportedProjects();
  }, []);

  useEffect(() => {
    if (search.length) {
      const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      setIsurl(regex.test(search));
    } else {
      setIsurl(false);
    }

    setError('');
    setFolder(null);
  }, [search, isUrl]);

  function searchForProject(): void {
    setLoadingSearch(true);

    if (!loadingSearch) {
      GoogleDriveIntegrationApi.searchForProject(search)
        .then((response: any) => {
          setFolder(response)
          setLoadingSearch(false);
          setError('');
        }, (err: any) => {
          setLoadingSearch(false);
          setError(err.response.data.message);
        });
    }
  }

  function navigateToGoogleDriveProjectImport(): void {
    navigate(`import`, {state: {folder}})
  }

  function getImportedProjects(): void {
    GoogleDriveIntegrationApi.getImports()
      .then((response: any) => {
        setLoader(false);
        setImportedProjects(response)
      }, () => {
        setLoader(false);
      });
  }

  function editImport(id: string): void {
    navigate(`import?id=${id}`)
  }

  function openConfirmationDialog(id: string): void {
    setConfirmationDialog({
      visible: true,
      okText: 'Delete',
      id: id,
      title: 'Delete Import',
      titleSec: 'You\'re about to delete this Google Drive Import',
      description: <>
        Continuing will remove the Google Drive Import from this
        list.
      </>
    });
  }

  function onConfirmationDialogConfirm(props: any): void {
    if (props.modal) {
      deleteImport(props.id);
      onCancel();
    }
  }

  function onCancel(): void {
    setConfirmationDialog({visible: false});
  }

  function deleteImport(id: string): void {
    GoogleDriveIntegrationApi.deleteImport(id)
      .then(() => {
        getImportedProjects();
      });
  }

  const supportedFiles = ['xlsx', 'docx', 'pdf', 'jpg', 'png', 'pptx', 'csv', 'zip', 'txt']

  const files = () => {
    return (
      <div className="members-tooltip">
        <span className="view-all-members">File Types</span>
        <ul>
          <div className="column">
            {supportedFiles && supportedFiles.map((supportedFile: any, i: number) => {
              return (
                <span key={i} className="slack-channel-user">
                  <li style={{color: 'white', marginBottom: '0px'}}>{supportedFile.split(' ')[0]}</li>
                </span>
              )
            })}
          </div>
        </ul>
      </div>
    )
  }

  return (
    <>
      {loader ? <Loader/>
        :
        <div className="integration-tab">
          <div className="row align-items-center">
            <span>Import</span>
            <SvgGoogleDrive width={15} style={{margin: '5px 5px'}}/>
            <span>Google Drive projects</span>
            <Tooltip placement="bottom" title={files}>
              <SvgHelp width={15} style={{margin: '5px 5px'}}/>
            </Tooltip>
          </div>
          <div className="row justify-space-between">
            <div className="outline-input-wrapper w-100">
              <DebounceInput
                name="search"
                className="outline-input w-100"
                placeholder="Google Drive folder URL"
                debounceTimeout={600}
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}/>
              <small className="form-error">{error ? error : ''}</small>
            </div>
            {folder &&
                <Button type="primary" style={{marginLeft: 10}}
                        onClick={navigateToGoogleDriveProjectImport}>
                    Import {folder.projectsNumber} projects
                </Button>
            }
            {!folder &&
                <Button type="primary" style={{marginLeft: 10}}
                        onClick={searchForProject}
                        loading={loadingSearch}
                        disabled={!isUrl}>Import from URL</Button>
            }
          </div>

          <div className="table" style={{marginTop: 60}}>
            <Table columns={columns}
                   pagination={false}
                   dataSource={importedProjects}
                   rowKey="id"/>
          </div>
        </div>
      }
      {confirmationDialog.visible &&
          <ConfirmationDialog data={confirmationDialog}
                              onConfirm={onConfirmationDialogConfirm}
                              onCancel={onCancel}/>}
    </>
  )
}
