// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-column {
  display: flex;
  flex-wrap: wrap;
}
.status-column .status-color-name {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.status-column .status-color-name .color-circle {
  width: 12px;
  height: 12px;
  border-radius: 8px;
  margin-right: 5px;
}
.status-column .status-color-name .status-name {
  font-weight: 300;
  font-size: 12px;
  margin-right: 10px;
}

.action-hover-column .disabled {
  pointer-events: none;
}
.action-hover-column .disabled svg > path {
  fill: #9B9B9B;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/workflows/workflows.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AACN;AAEI;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;AAAN;;AAME;EACE,oBAAA;AAHJ;AAKI;EACE,aAAA;AAHN","sourcesContent":[".status-column {\n  display: flex;\n  flex-wrap: wrap;\n\n  .status-color-name {\n    display: flex;\n    align-items: center;\n    margin-right: 20px;\n\n    .color-circle {\n      width: 12px;\n      height: 12px;\n      border-radius: 8px;\n      margin-right: 5px;\n    }\n\n    .status-name {\n      font-weight: 300;\n      font-size: 12px;\n      margin-right: 10px;\n    }\n  }\n}\n\n.action-hover-column {\n  .disabled {\n    pointer-events: none;\n\n    svg > path {\n      fill: #9B9B9B;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
