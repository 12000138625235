import AiUserAvatar from "../../../../../../_shared/components/ai-user-avatar/ai-user-avatar";
import React from "react";

export default function AIWelcomePrompts(props: { onClick: (text: string) => void }) {
  return <div className="welcome-ai-chat-screen">
    <div className="welcome-ai-chat-assistant">
      <AiUserAvatar color={"#545e6b"} style={{borderRadius: 5}}/>
      <p>You want some insights? Ask our assistant!</p>
    </div>
    <div className="welcome-ai-chat-recommendations">
      <div className="welcome-ai-chat-recommendation"
           onClick={() => props.onClick("What were the main findings from last week?")}>
        What were the main findings from last week?
      </div>
      <div className="welcome-ai-chat-recommendation"
           onClick={() => props.onClick("Summarize a survey's responses.")}>
        Summarize a survey's responses.
      </div>
    </div>
  </div>;
}