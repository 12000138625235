import { environment } from '../../../../../../environment';
import { IInterviews } from '../model/interviews.interface';
import { ITopKnowledgeGraphDetails } from '../model/top-knowledge-graph-details.interface';
import { axiosClient } from '../../../../../../axios-client';
import { AxiosResponse } from 'axios';
import { IFlagType } from '../../../../../settings/settings-tabs/interview-labs-settings/model/flag-type.interface';
import { ITopThreeGraph } from '../model/top-three-graph.interface';
import { ITopFlagsDetails } from '../model/top-flags-details.interface';
import { ITopTasksDetails } from '../model/top-tasks-details.interface';
import { IAllAssigments } from '../model/all-assigments.interface';
import { INotepadType } from '../model/types.interface';
import { IPagination } from "../../../../../../core/model/pagination.interface";

class InterviewsApi {
  baseUrlInterviews: string = `${environment.backendUrl}/interviews`;
  baseUrl: string = `${environment.backendUrl}/`;
  

  async getAllPaginated(projectId: string, page: number, pageSize: number): Promise<{
    content: IInterviews[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<{ content: IInterviews[], pagination: IPagination }> = await axiosClient.get<{
        content: IInterviews[],
        pagination: IPagination
      }>(this.baseUrlInterviews + `/${projectId}/paginated`, {
        params: {
          page,
          pageSize
        }
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async create(projectId: string, notepadsTypeId: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(this.baseUrlInterviews, {
        projectId,
        notepadsTypeId
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(interviewId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(this.baseUrlInterviews + `/${interviewId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getById(id: number | string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any[]>(`${this.baseUrlInterviews}/details/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getTopThree(projectId: number): Promise<ITopThreeGraph> {
    try {
      const response: AxiosResponse<ITopThreeGraph> = await axiosClient.get<ITopThreeGraph>(`${this.baseUrlInterviews}/top/graph/${projectId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getTopKnowLedgeDetailsById(graphId: number): Promise<ITopKnowledgeGraphDetails[]> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<ITopKnowledgeGraphDetails[]>(`${this.baseUrlInterviews}/graph/${graphId}`)

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getFlags(): Promise<IFlagType> {
    try {
      const response: AxiosResponse<IFlagType> = await axiosClient.get(`${this.baseUrl}flags`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getNuggets(interviewId: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrlInterviews}/nuggets/${interviewId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createNugget(interviewId: any, description: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(`${this.baseUrlInterviews}/nuggets/${interviewId}`, {description});
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSpecificGraph(id: number, projectId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrlInterviews}/graph/${id}?projectId=${projectId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSpecificFlag(id: number, projectId: number): Promise<ITopFlagsDetails[]> {
    try {
      const response: AxiosResponse<ITopFlagsDetails[]> = await axiosClient.get(`${this.baseUrlInterviews}/flag/${id}?projectId=${projectId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSpecificTask(id: number, projectId: number): Promise<ITopTasksDetails[]> {
    try {
      const response: AxiosResponse<ITopTasksDetails[]> = await axiosClient.get(`${this.baseUrlInterviews}/task/${id}?projectId=${projectId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getAllAssigned(urlParam: any): Promise<IAllAssigments> {
    try {
      const response: AxiosResponse<IAllAssigments> = await axiosClient.get(`${this.baseUrlInterviews}/all/assigments?nodeId=${urlParam?.nodeId}&taskId=${urlParam.taskId}&flagId=${urlParam.flagId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getTypes(): Promise<INotepadType[]> {
    try {
      const response: AxiosResponse<INotepadType[]> = await axiosClient.get(
        `${this.baseUrlInterviews}/types`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}


export default new InterviewsApi();
