import React, { Suspense, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './layout.scss'
import GlobalContext from '../../../store/global-context';
import { Loader } from '../../../_shared/components/loader/loader';
import Sidenav from './sidenav/sidenav';
import { WuAppHeader } from "@questionproext/wick-ui-lib";
import { Button } from "antd";
import AuthApi from "../../../store/auth.api";
import { queryClient } from "../../../index";
import { useMutation } from "react-query";

const Layout = () => {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const showSelectHub = globalContext.isPartner && !globalContext.selectedOrganization;
  const showClientFooter = globalContext.isPartner && globalContext.selectedOrganization && globalContext?.selectedOrganization !== 'AGENCY';

  const stopImpersonate = useMutation(() => AuthApi.stopImpersonate(), {
    onSuccess: () => {
      queryClient.resetQueries();
      navigate('/');
    }
  });

  return (
    <div className="main column">
      <>
        {/*<Header/>*/}
        {
          globalContext.user?.isImpersonated &&
            <div className="stop-impersonate-button">
                <Button danger type="primary" onClick={() => stopImpersonate.mutate()}>STOP IMPERSONATE</Button>
            </div>
        }
        <WuAppHeader activeProductName={"InsightsHub"}
                     onLogoutClick={() => globalContext.logoutUser()}
                     productCategories={globalContext.user.categories}
                     myAccount={globalContext.user.myAccount}/>
        <div className="container">
          {
            !showSelectHub &&
              <Sidenav/>
          }
          <div className="main-wrapper" style={{paddingBottom: showClientFooter ? 25 : 0}}>
            <Suspense fallback={<Loader/>}>
              <Outlet/>
            </Suspense>
            {
              showClientFooter &&
                <div className='client-hub-footer'>
                    Client Hub
                </div>
            }
          </div>
        </div>
      </>
    </div>
  );
}

export default Layout;

