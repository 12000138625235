import React from 'react';

const SvgLineChart = (props: any) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1 0.000219058C1.55228 0.000219058 2 0.447934 2 1.00022V12.0002H13C13.5523 12.0002 14 12.4479 14 13.0002C14 13.5525 13.5523 14.0002 13 14.0002H0V1.00022C0 0.447934 0.447715 0.000219058 1 0.000219058ZM12.1056 0.553005C12.3526 0.059027 12.9532 -0.141197 13.4472 0.105792C13.9412 0.352781 14.1414 0.953454 13.8944 1.44743L10.8944 7.44743C10.5259 8.18448 9.4741 8.18448 9.10557 7.44743L8 5.23629L5.89443 9.44743C5.64744 9.94141 5.04676 10.1416 4.55279 9.89465C4.05881 9.64766 3.85858 9.04698 4.10557 8.55301L7.10557 2.55301C7.4741 1.81596 8.5259 1.81596 8.89443 2.55301L10 4.76415L12.1056 0.553005Z"
            fill={props?.color ? props.color : '#545e6b'}/>
    </svg>
  );
};

export default SvgLineChart;
