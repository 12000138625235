import React from 'react';

const SvgColumnChart = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.14286 0C1.77404 0 2.28571 0.511675 2.28571 1.14286V13.7143H14.8571C15.4883 13.7143 16 14.226 16 14.8571C16 15.4883 15.4883 16 14.8571 16H0V1.14286C0 0.511675 0.511675 0 1.14286 0ZM16 0V11.4286H11.4286V0H16ZM9.14286 4.57143V11.4286H4.57143V4.57143H9.14286Z"
            fill={props?.color ? props.color : '#545e6b'}/>
    </svg>

  );
};

export default SvgColumnChart;
