import { ContentBlock, ContentState, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import { getFlagSelectionByEntityMapId } from './entity';

let selectedFlags: any[] = [];

export function assignFlagsEntity(editorState: EditorState, data: {
  selectedFlags: any[],
  entityKey?: string,
  entityMapId?: string
}): EditorState {
  selectedFlags = data?.selectedFlags;
  const entityKey = data?.entityKey;
  const entityMapId = data?.entityMapId;

  if (entityKey && entityMapId) {
    return manageFlagWithEntityKey(editorState, entityKey, entityMapId);
  } else {

    return manageFlagWithSelection(editorState);
  }
}

function manageFlagWithEntityKey(editorState: EditorState, entityKey: string, entityMapId: string): EditorState {
  const entitySelection: SelectionState | undefined = getFlagSelectionByEntityMapId(editorState, entityMapId);
  const contentState: ContentState = editorState.getCurrentContent();

  // if selectedFlags is empty array remove the entity from selectedText in editor
  if (entitySelection) {
    if (!selectedFlags?.length) {
      const newContentState = Modifier.applyEntity(contentState, entitySelection, null);
      return EditorState.push(editorState, newContentState, 'apply-entity');
    } else {
      contentState.mergeEntityData(entityKey, {
        flags: selectedFlags,
      });

      const newContentState = Modifier.applyEntity(contentState, entitySelection, entityKey);
      return EditorState.push(editorState, newContentState, 'apply-entity');
    }
  } else {
    return editorState;
  }
}

function manageFlagWithSelection(editorState: EditorState): EditorState {
  const selectionState: SelectionState = editorState.getSelection();
  const anchorKey: string = selectionState.getAnchorKey();
  const contentState: ContentState = editorState.getCurrentContent();
  const currentBlock: ContentBlock = contentState.getBlockForKey(anchorKey);

  const start: number = selectionState.getStartOffset();
  const end: number = selectionState.getEndOffset();
  const selectedText: string = currentBlock.getText().slice(start, end);

  // if selectedFlags is empty array remove the entity from selectedText in editor
  if (!selectedFlags?.length) {
    return RichUtils.toggleLink(editorState, selectionState, null);
  } else {
    const contentWithEntity: ContentState = contentState.createEntity("FLAG", "MUTABLE", {
      selectedText,
      flags: selectedFlags,
      entityMapId: Math.floor(100000 + Math.random() * 900000)
    });

    const newEditorState: EditorState = EditorState.push(editorState, contentWithEntity, "apply-entity");
    const entityKey: string | null = contentWithEntity.getLastCreatedEntityKey();
    return RichUtils.toggleLink(newEditorState, selectionState, entityKey);
  }
}
