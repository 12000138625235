import React, { useEffect, useState } from 'react';
import './survey-dialog.scss';
import { Button, Divider, Empty, Modal, Table, Tabs } from 'antd';
import { AxiosResponse } from 'axios';
import SvgInsightDesk from '../../../../../../../assets/icons/js/InsightDesk';
import { ColumnsType } from 'antd/lib/table';
import { Loader } from '../../../../../../../_shared/components/loader/loader';
import { DebounceInput } from 'react-debounce-input';
import OperationsApi from '../../api/operations-api';
import { ISurvey } from "../../model/survey.interface";
import DaysLeftDatePipe from "../../../../../../../_shared/helpers/days-left-date-pipe";
import { LinkSurveyDialogData } from "../../operations-dialogs-reducer/operations-dialogs-reducer.types";

const TabPane = Tabs.TabPane;

type LinkSurveyDialogProps = {
  data: LinkSurveyDialogData;
  onCancel: () => void;
  onConfirm: (props: { modal?: boolean }) => void
}

export function LinkSurveyDialog({data, onCancel, onConfirm}: LinkSurveyDialogProps): JSX.Element {
  const surveysColumns: ColumnsType<any> = [
    {
      title: 'Survey Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => <span className='link pointer' onClick={() => selectSurvey(record)}>{value}</span>
    },
    {
      title: 'Date Created',
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (value) => <span className=''>{DaysLeftDatePipe(value)}</span>
    },
    // {
    //   title: 'Started',
    //   dataIndex: 'started',
    //   key: 'started'
    // },
    {
      title: 'Responses',
      dataIndex: 'completedResponses',
      key: 'completed'
    },
  ];

  let page: number = 1;
  let pageSize: number = 5;
  let searchText: string = '';

  const {linkedSurvey, projectId} = data;

  const [loader, setLoader] = useState<boolean>(true);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [surveysLoading, setSurveysLoading] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<any>();
  const [selectedSurvey, setSelectedSurvey] = useState<ISurvey | undefined>(linkedSurvey ? linkedSurvey : undefined);
  const [folders, setFolders] = useState<any[]>([]);
  const [folderId, setFolderId] = useState<number | undefined>(undefined);

  const [description, setDescription] = useState(linkedSurvey?.description ? linkedSurvey.description : "")


  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (linkedSurvey) {
      return;
    }

    if (folderId) {
      setLoader(true)
      loadSurveys();
    }
  }, [folderId]);

  const onSave = () => {
    if (!projectId) {
      return;
    }

    if (linkedSurvey) {
      setApiLoading(true);
      const payload = {
        description: description
      };

      OperationsApi.editLinkedSurvey(projectId, linkedSurvey.surveyId, payload)
        .then((response: AxiosResponse<any>) => {
          onConfirm({modal: true});
          setApiLoading(false);
        }, () => {
          setApiLoading(false);
        });

      return
    }

    setApiLoading(true);
    const payload = {
      projectId: data.projectId,
      surveyId: selectedSurvey?.surveyId,
      description: description,
      completedResponses: selectedSurvey?.completedResponses,
      createdTime: selectedSurvey?.createdTime ? selectedSurvey.createdTime : ""
    };

    OperationsApi.addLinkedSurvey(payload)
      .then((response: AxiosResponse<any>) => {
        onConfirm({modal: true});
        setApiLoading(false);
      }, () => {
        setApiLoading(false);
      });
  }

  function title(): JSX.Element {
    return <div className="row align-items-center">
      <span style={{color: "#545e6b"}}> {linkedSurvey ? "Edit Linked Survey" : "Link Survey"}</span>
      <SvgInsightDesk style={{marginLeft: 8, color: "#545e6b"}}/>
    </div>
  }

  function selectSurvey(survey: ISurvey | undefined): void {
    setSelectedSurvey(survey)
  }

  function onFolderChange(idString: string): void {
    if (idString !== 'null') {
      setFolderId(parseInt(idString));
    }
  }

  function handleTableChange(pagination: any): void {
    page = pagination.current
    loadSurveys();
  }

  function search(value: string): void {
    searchText = value;
    if (value.length) {
      searchSurveys();
      return;
    }

    loadSurveys();
  }

  function searchSurveys(): void {
    setSurveysLoading(true);

    const data: { page: number, size: number, search: string } = {
      page: page,
      size: pageSize,
      search: searchText
    };

    OperationsApi.searchSurveys(data.page, data.size, data.search)
      .then((response: AxiosResponse<any>) => {
        setSurveys(response);
        setLoader(false);
        setSurveysLoading(false);
      });
  }

  function loadPage(): void {
    OperationsApi.loadFolders()
      .then((response: any[]) => {
        if (response?.length) {
          setFolderId(response[0]?.folderId);
          setFolders(response);
        } else {
          setFolders([{folderId: null, folderName: "My Surveys"}]);
        }
      }, () => {
        setLoader(false);
        setFolders([]);
      });
  }

  function loadSurveys(): void {
    if (!projectId) {
      return;
    }

    setSurveysLoading(true);

    const data: { page: number, size: number, search: string } = {
      page,
      size: pageSize,
      search: searchText
    };

    OperationsApi.loadSurveyFolder(folderId, data.page, data.size, projectId)
      .then((response: any) => {
        setSurveys(response);
        setLoader(false);
        setSurveysLoading(false);
      });
  }

  return (
    <Modal
      title={title()}
      open={data.visible}
      centered
      className="survey-dialog"
      width={920}
      onCancel={() => (onCancel())}
      footer={linkedSurvey && selectedSurvey ? [
        <Button key={1} type='link' onClick={() => onCancel()}>Cancel</Button>,
        <Button loading={apiLoading} key={2} type='primary' onClick={onSave}>Link</Button>] : selectedSurvey ? [
        <Button key={1} type='link' onClick={() => setSelectedSurvey(undefined)}>Back</Button>,
        <Button loading={apiLoading} key={2} type='primary' onClick={onSave}>Link</Button>] : []}>
      <>
        {
          selectedSurvey ?
            <>
              <div className='survey-selected-information-wrapper column'>
                <div className='row survey-selected-name'>
                  <SvgInsightDesk width={16} height={16} color={"#1B87E6"}/>
                  <span>{selectedSurvey.name}</span>
                </div>
                {/*<div className='row'>*/}
                {/*  <span className='survey-selected-detail-name'>Owner</span>*/}
                {/*  <span className='survey-selected-detail-value'>Jane Doe</span>*/}
                {/*</div>*/}
                <div className='row'>
                  <span className='survey-selected-detail-name'>Date Created</span>
                  <span
                    className='survey-selected-detail-value'>{selectedSurvey.createdTime && DaysLeftDatePipe(selectedSurvey.createdTime)}</span>
                </div>
                <div className='row'>
                  <span className='survey-selected-detail-name'>Responses</span>
                  <span className='survey-selected-detail-value'>{selectedSurvey.completedResponses}</span>
                </div>
                <div className='outline-input-wrapper relative'>
                  <div className='outline-input-label'>
                    Description
                  </div>
                  <input
                    name="tools-name"
                    placeholder="Describe the resource linked"
                    className="outline-input"
                    value={description}
                    maxLength={100}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <div className="outline-input-character-count">{description.length}/100</div>
                </div>
              </div>
              <Divider style={{marginBottom: 0, marginTop: 0}}/>
            </> :
            loader ? <Loader/>
              :
              <div>
                {!folders.length ?
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  :
                  <Tabs type="card" tabPosition={'left'} onChange={(id: any) => id && onFolderChange(id)}>
                    {folders.map((folder: any) => {
                      return (
                        <TabPane tab={folder.folderName} key={folder.folderId}>
                          <div className="column align-items-end" style={{margin: '10px 0'}}>
                            <div className="outline-input-wrapper">
                              <DebounceInput
                                className="outline-input"
                                placeholder='Search...'
                                debounceTimeout={600}
                                value=''
                                onChange={e => search(e.target.value)}/>
                            </div>
                          </div>
                          <Table
                            loading={surveysLoading}
                            pagination={{
                              position: ['topRight'],
                              defaultCurrent: surveys?.pagination?.number,
                              total: surveys?.pagination?.totalElements,
                              pageSize: surveys?.pagination?.size
                            }}
                            columns={surveysColumns}
                            onChange={(p) => handleTableChange(p)}
                            className="w-100"
                            dataSource={surveys?.content}
                            rowKey="surveyId"/>

                        </TabPane>
                      )
                    })}
                  </Tabs>
                }
              </div>
        }
      </>
    </Modal>
  );
}
