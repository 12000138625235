import * as React from "react";

const SvgRating = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 2a8 8 0 018 8c-.831-.961-1.666-1.75-2.504-2.364l-.16-.116-.105-.07c-1.822 3.485-2.978 5.472-3.467 5.961a2 2 0 11-2.828-2.828c.484-.484 2.44-1.623 5.864-3.416C11.542 6.39 10.275 6 9 6c-2.21 0-5 1.79-5 4H0a8 8 0 018-8z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgRating;
