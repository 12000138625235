import { Radio } from "antd";
import { TableViewEnum } from "../../models/customize-table.interface";
import React from "react";

type ComponentType = {
  view: TableViewEnum;
  onChange: (view: TableViewEnum) => void
}

export function TableView({view, onChange}: ComponentType): JSX.Element {

  return (
    <div className="row" style={{marginBottom: 20}}>
      <Radio.Group onChange={(e) => onChange(e.target.value)} value={view}>
        <Radio value={TableViewEnum.BASIC_VIEW}>Basic view</Radio>
        <Radio value={TableViewEnum.KEY_METRICS_VIEW}>Key metrics</Radio>
        <Radio value={TableViewEnum.PROGRAM_METRICS_VIEW}>Program metrics</Radio>
      </Radio.Group>
    </div>
  );
}
