import * as React from "react";

const SvgArrowUp = (props: any) => {
  return (
    <svg width={10} height={6} viewBox="0 0 10 6" {...props}>
      <g fill={props.color ? props.color : '#545E6B'}>
        <path d="M0 0L5 6 10 0z" transform="translate(-273 -278) translate(131 256) matrix(1 0 0 -1 142 28)"/>
      </g>
    </svg>

  );
}

export default SvgArrowUp;
