import { environment } from '../../../environment';
import { IArchived } from '../models/archived.interface';
import { IHighlight } from '../models/highlights.interface';
import { IOrganizationSettings } from '../../home/models/organization.settings.interface';
import { ITeamMember, ITeamMemberData } from '../settings-tabs/team/model/team-member.interface';
import { IKeyMetric } from '../settings-tabs/project-management/key-metrics/model/key-metric.interface';
import { IMetaTag, IMetaTagData } from '../settings-tabs/taxonomy/meta-tags/model/meta-tag.interface';
import { IPricingModel } from '../../../_shared/model/pricing-model.interface';
import { IHighlightFunction } from '../models/highlight-function.interface';
import { IPortalSettings } from '../settings-tabs/setup/portal/model/portal-settings.interface';
import { IProgram } from '../settings-tabs/taxonomy/programs/model/program.interface';
import { axiosClient } from '../../../axios-client';
import { AxiosResponse } from 'axios';
import { IExternalProviderInterface } from '../settings-tabs/setup/portal/external-oauth/external-oauth.interface';
import { ProgramFormDataType } from "../settings-tabs/taxonomy/programs/model/program-form.data.type";
import { IPineconeVectors } from "../models/pinecone-vectors.interface";
import { IFolder } from "../settings-tabs/taxonomy/folders/model/folder.interface";
import { IArchivedMetatag } from "../models/archived-metatag.interface";
import { IPagination } from "../../../core/model/pagination.interface";
import { IArchivedForm } from "../models/archived-form.interface";

class SettingsApi {
  private baseUrl: string = `${environment.backendUrl}`;

  async getArchived(): Promise<IArchived> {
    try {
      const response: AxiosResponse<IArchived> =
        await axiosClient.get<IArchived>(`${this.baseUrl}/archived`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getArchivedMetatags(params: { page: number, pageSize: number }): Promise<{
    content: IArchivedMetatag[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<{ content: IArchivedMetatag[], pagination: IPagination }> =
        await axiosClient.get<{
          content: IArchivedMetatag[],
          pagination: IPagination
        }>(`${this.baseUrl}/archived/metatags/paginated`, {
          params
        });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getArchivedForms(params: { page: number, pageSize: number }): Promise<{
    content: IArchivedForm[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<{ content: IArchivedForm[], pagination: IPagination }> =
        await axiosClient.get<{
          content: IArchivedForm[],
          pagination: IPagination
        }>(`${this.baseUrl}/archived/forms/paginated`, {
          params
        });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async unarchiveMetatag(id: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/metatags/unarchive/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async unarchiveForm(id: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/forms/unarchive/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getHighlights(): Promise<IHighlight[]> {
    try {
      const response: AxiosResponse<IHighlight[]> =
        await axiosClient.get<IHighlight[]>(
          `${this.baseUrl}/highlights/all`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getHighlightById(id: number): Promise<IHighlight> {
    try {
      const response: AxiosResponse<IHighlight> =
        await axiosClient.get<IHighlight>(
          `${this.baseUrl}/highlights/${id}`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateHighlightStatus(
    id: number,
    status: string
  ): Promise<IHighlight> {
    try {
      const response: AxiosResponse<IHighlight> =
        await axiosClient.put<IHighlight>(
          `${this.baseUrl}/highlights/status/${id}/${status}`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async runHighlight(
    queryParams: any,
    payload: any
  ): Promise<IHighlight> {
    try {
      const response: AxiosResponse<IHighlight> =
        await axiosClient.put<IHighlight>(
          `${this.baseUrl}/highlights/run${queryParams}`,
          payload
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getHighlightsFunctions(): Promise<IHighlightFunction[]> {
    try {
      const response: AxiosResponse<IHighlightFunction[]> =
        await axiosClient.get<IHighlightFunction[]>(
          `${this.baseUrl}/highlights/functions`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteHighlight(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/highlights/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getOrganisationSettings(): Promise<IOrganizationSettings> {
    try {
      const response: AxiosResponse<IOrganizationSettings> =
        await axiosClient.get<IOrganizationSettings>(
          `/organizations/settings`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateOrganisationSettings(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/organizations/settings`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updatePortalSettings(
    portalSettings: IPortalSettings
  ): Promise<IPortalSettings> {
    try {
      const response: AxiosResponse<IPortalSettings> =
        await axiosClient.put<IPortalSettings>(
          `${this.baseUrl}/organizations/portal/settings`,
          portalSettings
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createKeymetric(keymetrics: IKeyMetric): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/keymetrics`,
        keymetrics
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getKeymetrics(): Promise<IKeyMetric[]> {
    try {
      const response: AxiosResponse<IKeyMetric[]> =
        await axiosClient.get<IKeyMetric[]>(
          `${this.baseUrl}/keymetrics`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteKeymetric(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/keymetrics/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateKeymetric(
    id: number,
    keymetrics: IKeyMetric
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/keymetrics/${id}`,
        keymetrics
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createMetatag(metatag: IMetaTag): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/metatags`,
        metatag
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getMetaTags(params: { page: number, pageSize: number }): Promise<IMetaTagData> {
    try {
      const response: AxiosResponse<IMetaTagData> = await axiosClient.get<IMetaTagData>(
        `${this.baseUrl}/metatags`,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteMetaTag(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/metatags/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateMetaTag(id: number, metatags: IMetaTag): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/metatags/${id}`,
        metatags
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createPricingModel(pricingModel: IPricingModel): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/pricingmodels`,
        pricingModel
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPricingModels(): Promise<IPricingModel[]> {
    try {
      const response: AxiosResponse<IPricingModel[]> =
        await axiosClient.get<IPricingModel[]>(
          `${this.baseUrl}/pricingmodels`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deletePricingModel(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/pricingmodels/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updatePricingModel(
    id: number,
    PricingModels: IPricingModel
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/pricingmodels/${id}`,
        PricingModels
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  // team
  async createQpUser(teamMember: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/teammembers`,
        teamMember
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getTeamMembers(params: any): Promise<ITeamMemberData> {
    try {
      const response: AxiosResponse<ITeamMemberData> = await axiosClient.get<ITeamMemberData>(`${this.baseUrl}/teammembers`, {params});
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getPortalUsers(params: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<ITeamMember[]>(`${this.baseUrl}/teams/portal`, {params});
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async deleteTeamMembers(id: number): Promise<any> {
  //   try {
  //     const response: any = await axiosClient.delete<any>(
  //       `${this.baseUrl}/teammembers/${id}`
  //     );
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  //
  // async updateTeamMember(id: number, teamMember: ITeamMember): Promise<any> {
  //   try {
  //     const response: any = await axiosClient.put<any>(
  //       `${this.baseUrl}/teammembers/${id}`,
  //       teamMember
  //     );
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async toggleAdmin(isActive: boolean, id: string): Promise<any> {
    try {
      const response: any = await axiosClient.put<any>(
        `${this.baseUrl}/teammembers/${id}/active/${isActive}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getQPTeamMembers(params?: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/teammembers/admins`,
        {params}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //Integrations
  async integrationsInstalled(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/integrations/installed`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async installSlack(code: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/integrations/slack/oauth/install?code=${code}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async slackInstalled(): Promise<boolean> {
    try {
      const response: AxiosResponse<boolean> = await axiosClient.get<boolean>(
        `${this.baseUrl}/integrations/slack/installed`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uninstallSlack(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/integrations/slack`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getChannels(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/integrations/slack/channel`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async changeNotificationAndConversationStatus(
    type: string,
    channelId: string,
    value: boolean
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/integrations/slack/channel/${channelId}?${type}=${value}`,
        null
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async googleDriveInstalled(): Promise<boolean> {
  //   try {
  //     const response: AxiosResponse<boolean> = await axiosClient.get<boolean>(
  //       `${this.baseUrl}/integrations/google-drive/installed`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // Programs
  async getPrograms(page?: number, pageSize?: number): Promise<{ content: IProgram[], totalSize: number }> {
    try {
      const response: AxiosResponse<{ content: IProgram[], totalSize: number }> = await axiosClient.get<{
        content: IProgram[],
        totalSize: number
      }>(
        `${this.baseUrl}/program`, {params: {page, pageSize}}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async checkForUniqueProgramName(value: string): Promise<boolean> {
    try {
      const response: AxiosResponse<boolean> = await axiosClient.get<boolean>(
        `${this.baseUrl}/program/check/${value}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createProgram(program: ProgramFormDataType): Promise<IProgram[]> {
    try {
      const response: AxiosResponse<IProgram[]> = await axiosClient.post<IProgram[]>(`${this.baseUrl}/program`, program);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateProgram(id: number, program: ProgramFormDataType): Promise<IProgram> {
    try {
      const response: AxiosResponse<IProgram> = await axiosClient.put<IProgram>(
        `${this.baseUrl}/program/${id}`,
        program
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteProgram(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/program/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getFolders(programId?: number, page?: number, pageSize?: number): Promise<{
    content: IFolder[],
    totalSize: number
  }> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/folders`, {params: {programId, page, pageSize}}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createFolder(data: { name: string, programId: number }): Promise<IFolder> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/folders`, data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async editFolder(id: number, data: { name: string, programId: number }): Promise<IFolder> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/folders/${id}`, data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFolder(id: number): Promise<IFolder> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/folders/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async getFolder(id: number): Promise<IFolder> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.get<any>(
  //       `${this.baseUrl}/folders/${id}`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  //

  async uploadLogo(file: any): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await axiosClient.put<any[]>(`${this.baseUrl}/organizations/logo`,
        file)
      return response.data
    } catch (error) {
      throw (error);
    }
  }

  async getExternalProviders(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/organizations/portal/external-providers`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addExternalProvide(form: IExternalProviderInterface): Promise<any> {
    try {
      const response: AxiosResponse<IExternalProviderInterface> = await axiosClient.post<IExternalProviderInterface>(`${this.baseUrl}/organizations/portal/external-providers`, form)
      return response.data
    } catch (error) {
      throw (error);
    }
  }

  async deleteExternalProvide(id: number): Promise<any> {
    try {
      const response: AxiosResponse<IExternalProviderInterface> = await axiosClient.delete<IExternalProviderInterface>(`${this.baseUrl}/organizations/portal/external-providers/${id}`)
      return response.data
    } catch (error) {
      throw (error);
    }
  }

  // async downloadExcelTemplate(): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/analytics/projects/excel-template`, {
  //       responseType: 'arraybuffer',
  //     })
  //     return response.data
  //   } catch (error) {
  //     throw (error);
  //   }
  // }

  async startIndexing(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.put<IPineconeVectors>(`/hub-dashboard/pinecone-vectors/start-indexing`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async pineVectorsFiles(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.get<IPineconeVectors>(`/hub-dashboard/pinecone-vectors/files`)
      return response.data
    } catch (error) {
      throw (error);
    }
  }

  async pineVectorsNotes(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.get<IPineconeVectors>(`/hub-dashboard/pinecone-vectors/notes`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async pineVectorsStories(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.get<IPineconeVectors>(`/hub-dashboard/pinecone-vectors/stories`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async pineVectorsProjects(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.get<IPineconeVectors>(`/hub-dashboard/pinecone-vectors/projects`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async awsCloudsearchProjects(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.get<IPineconeVectors>(`/hub-dashboard/aws-cloudsearch/projects`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async awsCloudsearchFiles(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.get<IPineconeVectors>(`/hub-dashboard/aws-cloudsearch/files`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async awsCloudsearchNotes(): Promise<IPineconeVectors> {
    try {
      const response: AxiosResponse<IPineconeVectors> = await axiosClient.get<IPineconeVectors>(`/hub-dashboard/aws-cloudsearch/notes`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async askAiAll(params: { page: number, pageSize: number }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`/langchain/all-chats-paginated`, {
        params: params
      });
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

  async maskPIIData(maskPII: boolean): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(`/organizations/update-mask-pii-data?maskPiiData=${maskPII}`);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }
}

export default new SettingsApi();
