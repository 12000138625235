export enum ProjectTypeIconsEnum {
  IH_CONVERSATION = 'IH_CONVERSATION',
  IH_MARKET = 'IH_MARKET',
  IH_SCIENCE = 'IH_SCIENCE',
  IH_PRODUCT = 'IH_PRODUCT',
  IH_RATING = 'IH_RATING',
  IH_SURVEY = 'IH_SURVEY',
  IH_RESTAURANT = 'IH_RESTAURANT',
  IH_SHOPPING = 'IH_SHOPPING',
  IH_SATISFACTION = 'IH_SATISFACTION',
  IH_ANIMAL = 'IH_ANIMAL',
}
