import React, { useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import SettingsApi from '../../../../api/settings-api';
import settingsApi from '../../../../api/settings-api';
import './add-edit-programs-dialog.scss'
import GroupMetricsInput from "./group-metrics-input/group-metrics-input";
import { ProgramFormDataType } from "../model/program-form.data.type";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { hasDuplicateProperty } from "../../../../../../_shared/helpers/has-duplicate-property";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

export function AddEditProgramsDialog(props: any): JSX.Element {
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const schema = yup.object({
    name: yup.string().required('Name is required!'),
    description: yup.string(),
    groups: yup.array().of(yup.object({
      name: yup.string().required('Group name is required!'),
      metrics: yup.array().of(yup.object({
        name: yup.string().required('Metric name is required!'),
        type: yup.mixed().oneOf(['Numeric', 'Percentage', 'Calculated'] as const).required(),
        code: yup.string().required('Metric code is required!'),
        calculationFormula: yup.string().when('type', ([type], schema) =>
          type === 'Calculated' ? schema.required('Calculation formula is required!') : schema.notRequired()
        )
      }))
    }))
  });

  const formMethods = useForm({
    defaultValues: props?.data?.formData ? props.data.formData : {},
    resolver: yupResolver(schema)
  })
  const {control} = formMethods;
  const {errors} = formMethods.formState;
  const {fields, append, remove} = useFieldArray({name: 'groups', control});

  function handleDuplicateMetricCodes(payload: ProgramFormDataType): boolean {
    let groupsWithFaultyCodes: number[] = [];
    payload.groups.forEach((field: any, index: number) => {
      if (field?.metrics?.length) {
        if (hasDuplicateProperty(field?.metrics, 'code')) {
          groupsWithFaultyCodes.push(index);
        }
      }
    })

    if (groupsWithFaultyCodes.length) {
      groupsWithFaultyCodes.forEach((indexVal: number) => {
        // @ts-ignore
        formMethods.setError(`groups.${indexVal}.faultyMetrics`, {
          type: 'faulty-metrics',
          message: 'Metric Codes should be unique per each group!',
        })
      })
    }

    return !!groupsWithFaultyCodes.length
  }

  async function onFormSubmit(payload: ProgramFormDataType) {
    setApiLoading(true);

    if (payload.name !== props.data?.formData?.name) {
      const response = await SettingsApi.checkForUniqueProgramName(payload.name)

      if (!response) {
        // @ts-ignore
        formMethods.setError('name', {
          type: 'server',
          message: 'Program Name should be unique!'
        }, {shouldFocus: true})
        setApiLoading(false)
        return;
      }
    }

    if (handleDuplicateMetricCodes(payload)) {
      setApiLoading(false);
      return;
    }


    if (props.data.id) {
      settingsApi.updateProgram(props.data.id, payload)
        .then(() => {
          setApiLoading(false);
          props.onOk({modal: true});
        })
    } else {
      SettingsApi.createProgram(payload)
        .then(() => {
          setApiLoading(false);
          props.onOk({modal: true});
        })
    }
  }

  const removeGroup = (index: number) => {
    remove(index)
  }

  const addGroup = () => {
    append({name: `Group ${formMethods.watch('groups').length + 1}`, metrics: []})
  }

  return (
    <Modal
      title={props.data?.id ? "Edit program" : "Add program"}
      open={props.data.visible}
      bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
      onCancel={() => (props.onCancel())}
      okText='Save'
      cancelText='Cancel'
      okButtonProps={{loading: apiLoading}}
      cancelButtonProps={{type: 'link'}}
      onOk={formMethods.handleSubmit(onFormSubmit)}
      centered
      width={850}
    >
      <div className="add-edit-programs-dialog-wrapper">
        <FormProvider {...formMethods}>
          <div className='add-edit-programs-dialog-form'>
            <div className='outline-input-wrapper'>
              <div className='outline-input-label'>
                Name
              </div>
              <input
                type='text'
                className='outline-input'
                placeholder='Enter name'
                {...formMethods.register('name')}
              />
              <ErrorMessage errors={errors} name={'name'} render={({message}) => <small
                className="form-error">{message}</small>}/>
            </div>
            <div className='outline-input-wrapper'>
              <div className='outline-input-label'>
                Description
              </div>
              <textarea
                placeholder='Enter a short description'
                className='outline-input'
                style={{resize: 'none'}}
                maxLength={280}
                rows={3}
                {...formMethods.register('description')}
              />
              <div className='character-count-wrapper'>
                {formMethods.watch('description')?.length ? formMethods.watch('description').length : 0}/280
              </div>
            </div>
            <div className="program-metrics-wrapper">
              <div className="row justify-space-between" style={{marginBottom: 10}}>
                <div className="program-metrics-title">Program Metrics</div>
                <Button onClick={() => addGroup()} type="ghost" className="new-group-metric-button"><span
                  className='plus-icon'/> Add Group</Button>
              </div>
              <div className="column" style={{gap: 10}}>
                {
                  fields && fields.map((group: any, i: number) => {
                    return <GroupMetricsInput group={group}
                                              removeGroup={removeGroup}
                                              index={i}
                                              key={`${i}-${JSON.stringify(group)}`}/>
                  })
                }
              </div>
            </div>
          </div>
        </FormProvider>
        <Divider style={{marginBottom: -20, marginTop: 20}}/>
      </div>
    </Modal>
  );
}
