import React from 'react';

const SvgUpload3 = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_971_2293)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14 9C14.5523 9 15 9.44771 15 10V13C15 14.0544 14.1841 14.9182 13.1493 14.9945L13 15H3C1.89543 15 1 14.1046 1 13V10C1 9.44771 1.44772 9 2 9C2.55228 9 3 9.44771 3 10V13H13V10C13 9.44771 13.4477 9 14 9ZM9 11V5H12L8 1L4 5H7V11H9Z"
              fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_971_2293">
          <rect width="16" height="16" {...props} fill={props?.color ? props.color : 'white'}/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default SvgUpload3;
