export function validateMathFormula(formula: string) {
  const stack = [];

  const validOperations = ['+', '-', '*', '/'];
  const validVariableRegex = /^[a-zA-Z]+\d*$/; // Regex to validate variables like x1, programMetric1, etc.

  let i = 0;
  while (i < formula.length) {
    const char = formula[i];

    if (char === '(') {
      stack.push(char);
    } else if (char === ')') {
      if (stack.length === 0) {
        return false; // Unbalanced parentheses
      }
      stack.pop();
    } else if (validOperations.includes(char)) {
      const prevChar = formula[i - 1];
      const nextChar = formula[i + 1];
      if (
        !prevChar ||
        !nextChar ||
        prevChar === '(' ||
        nextChar === ')' ||
        validOperations.includes(prevChar) ||
        validOperations.includes(nextChar)
      ) {
        return false; // Invalid placement of operation
      }
    } else if (char.match(/[0-9.]/)) {
      let j = i + 1;
      while (j < formula.length && formula[j].match(/[0-9.]/)) {
        j++;
      }
      i = j - 1;
    } else if (char.match(validVariableRegex)) {
      // Valid variable, no action needed
    } else if (char.trim() !== '') {
      return false; // Invalid character
    }

    i++;
  }

  if (stack.length !== 0) {
    return false; // Unclosed parentheses
  }

  return true; // Formula is valid
}
