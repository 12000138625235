import './widget-form.scss'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IWidget } from '../../model/project-type.interface';
import { MainFormTypeEnum } from '../../model/main-form-type.enum';
import { MainFormPositionEnum } from '../../model/main-form-position.enum';
import SvgMultipleChoice from '../../../../../../../assets/icons/js/MultipleChoice';
import SvgUploadFile from '../../../../../../../assets/icons/js/UploadFile';
import SvgCommentBox from '../../../../../../../assets/icons/js/CommentBox';
import SvgAddForm from '../../../../../../../assets/icons/js/AddForm';
import { Popover, Switch } from 'antd';
import Choice from './choice/choice';
import Input from './input/input';
import Upload from './upload/upload';
import SvgCopy from '../../../../../../../assets/icons/js/copy';
import SvgTrash from '../../../../../../../assets/icons/js/Trash';
import SvgClose from '../../../../../../../assets/icons/js/Close';
import SvgMore from '../../../../../../../assets/icons/js/More';
import ProjectTypeContext from '../../store/project-type-context';
import { DebounceInput } from 'react-debounce-input';
import SvgLabel from '../../../../../../../assets/icons/js/label';

const WidgetForm = (props: { widget: IWidget, index: number }) => {
  const widget: IWidget = props.widget;
  const projectTypeContext = useContext(ProjectTypeContext);
  let [popOverTop, setPopOverTop] = useState(false);
  let [popOverBottom, setPopOverBottom] = useState(false);
  let [footer, setFooter] = useState(false);
  const [labelState, setLabelState] = useState(false);

  useEffect(() => {
    if (widget.label) {
      setLabelState(!!widget.label);
    }
  }, [widget.label]);

  return (
    <>
      {widget &&
          <div className="intake-form-card">
              <Popover
                  content={() => {
                    return (
                      <div className="row">
                        <button className="pop-over-button"
                                onClick={() => {
                                  projectTypeContext.addWidget({
                                    type: MainFormTypeEnum.MULTI_CHOICE,
                                    options: [''],
                                    position: MainFormPositionEnum.TOP
                                  }, props.index);
                                  setPopOverTop(false)
                                }}>
                          <SvgMultipleChoice width="32"/>
                          <span>Multiple Choice</span>
                        </button>
                        <button className="pop-over-button"
                                onClick={() => {
                                  projectTypeContext.addWidget({
                                    type: MainFormTypeEnum.TEXT_INPUT,
                                    position: MainFormPositionEnum.TOP
                                  }, props.index);
                                  setPopOverTop(false);
                                }}>
                          <SvgCommentBox/>
                          <span>Comment box</span>
                        </button>
                        <button className="pop-over-button"
                                onClick={() => {
                                  projectTypeContext.addWidget({
                                    type: MainFormTypeEnum.FILE_UPLOAD,
                                    position: MainFormPositionEnum.TOP
                                  }, props.index);
                                  setPopOverTop(false);
                                }}>
                          <SvgUploadFile width="32"/>
                          <span>Upload file</span>
                        </button>
                      </div>
                    )
                  }}
                  open={popOverTop}
                  trigger="click"
                  onOpenChange={(e) => setPopOverTop(!popOverTop)}>

                  <button className="intake-form-card-add-button-top">
                      <SvgAddForm weight="24" height="24"/>
                  </button>
              </Popover>

              <Popover
                  content={() => {
                    return (
                      <div className="row">
                        <button className="pop-over-button"
                                onClick={() => {
                                  projectTypeContext.addWidget({
                                    type: MainFormTypeEnum.MULTI_CHOICE,
                                    options: [''],
                                    position: MainFormPositionEnum.BOTTOM
                                  }, props.index);
                                  setPopOverBottom(false);
                                }}>
                          <SvgMultipleChoice width="32"/>
                          <span>Multiple Choice</span>
                        </button>
                        <button className="pop-over-button"
                                onClick={() => {
                                  projectTypeContext.addWidget({
                                    type: MainFormTypeEnum.TEXT_INPUT,
                                    position: MainFormPositionEnum.BOTTOM
                                  }, props.index);
                                  setPopOverBottom(false);
                                }}>
                          <SvgCommentBox/>
                          <span>Comment box</span>
                        </button>
                        <button className="pop-over-button"
                                onClick={() => {
                                  projectTypeContext.addWidget({
                                    type: MainFormTypeEnum.FILE_UPLOAD,
                                    position: MainFormPositionEnum.BOTTOM
                                  }, props.index);
                                  setPopOverBottom(false)
                                }}>
                          <SvgUploadFile width="32"/>
                          <span>Upload file</span>
                        </button>
                      </div>
                    )
                  }}
                  placement="bottom"
                  open={popOverBottom}
                  trigger="click"
                  onOpenChange={(e) => setPopOverBottom(!popOverBottom)}>
                  <button className="intake-form-card-add-button-bottom">
                      <SvgAddForm weight="24" height="24"/>
                  </button>
              </Popover>

              <div className="intake-form-card-body column">
                  <div className="header row">
                      <div className="left">
                        {widget.required &&
                            <span className="input-is-required">*</span>
                        }
                          <DebounceInput
                              className="main-form-input"
                              type="text"
                              placeholder="Question"
                              autoComplete="off"
                              debounceTimeout={600}
                              value={widget.question}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => projectTypeContext.widgetQuestionChanged(widget, e.target.value, props.index)}/>
                      </div>
                      <div className="right">
                        {
                          labelState ?
                            <DebounceInput
                              className="main-form-input"
                              type="text"
                              placeholder="Label"
                              autoComplete="off"
                              debounceTimeout={600}
                              value={widget.label}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => projectTypeContext.widgetLabelChanged(widget, e.target.value, props.index)}/>
                            :
                            <div className="add-label" onClick={() => setLabelState(true)}>
                              <SvgLabel width={18} height={18}/>
                            </div>
                        }
                      </div>
                  </div>

                {widget.type === MainFormTypeEnum.MULTI_CHOICE &&
                    <Choice widget={widget} index={props.index}/>
                }

                {widget.type === MainFormTypeEnum.TEXT_INPUT &&
                    <Input/>
                }

                {widget.type === MainFormTypeEnum.FILE_UPLOAD &&
                    <Upload/>
                }
              </div>
              <div className="spacer"/>
              <div className="intake-form-card-footer row"
                   style={{backgroundColor: footer ? '#F5F5F5' : 'transparent'}}>
                {
                  footer ?
                    <>
                      {widget.type === MainFormTypeEnum.MULTI_CHOICE ?
                        <div className="row align-items-center" data-testid="footer-widget">
                          <button
                            data-testid={"single-choice"}
                            className={!widget.multiple ? "intake-form-footer-button active-choice" : "intake-form-footer-button"}
                            onClick={() => projectTypeContext.choiceTypeWidgetOption(widget, false, props.index)}>
                            <div className="choice-single-choice">
                              <div className="circle"/>
                            </div>
                          </button>
                          <button
                            data-testid={"multi-choice"}
                            className={widget.multiple ? "intake-form-footer-button active-choice" : "intake-form-footer-button"}
                            onClick={() => projectTypeContext.choiceTypeWidgetOption(widget, true, props.index)}>
                            <div className="choice-multi-choice">
                              <div className="checkmark">
                                <div className="checkmark_stem"/>
                                <div className="checkmark_kick"/>
                              </div>
                            </div>
                          </button>
                          <div className="intake-form-button-required">
                            <span>Required</span>
                            <Switch size="small"
                                    style={{width: '30px'}}
                                    checked={widget.required}
                                    onClick={() => projectTypeContext.widgetIsRequiredChanged(widget, !widget.required, props.index)}/>
                          </div>
                        </div>
                        :
                        <div className="intake-form-button-required" data-testid="footer-widget">
                          <span>Required</span>
                          <Switch size="small"
                                  style={{width: '30px'}}
                                  checked={widget.required}
                                  onClick={() => projectTypeContext.widgetIsRequiredChanged(widget, !widget.required, props.index)}/>
                        </div>
                      }
                      <div className="row">
                        <button className="intake-form-footer-button"
                                onClick={() => projectTypeContext.copyWidget(widget, props.index)}>
                          <SvgCopy width="24"/>
                        </button>
                        <button className="intake-form-footer-button"
                                onClick={() => projectTypeContext.deleteWidget(widget, props.index)}>
                          <SvgTrash width="24"/>
                        </button>
                        <div className="small-divider"/>
                        <button className="intake-form-footer-button" onClick={() => setFooter(false)}>
                          <SvgClose width="24"/>
                        </button>
                      </div>
                    </>
                    :
                    <>
                      <div/>
                      <button className="intake-form-footer-button" onClick={() => setFooter(true)}>
                        <SvgMore width="24"/>
                      </button>
                    </>
                }
              </div>
          </div>
      } </>
  );
}

export default WidgetForm
