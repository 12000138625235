import React, { useEffect, useState } from 'react';
import { KeyMetricsInput } from './key-metric-input/key-metrics-input';
import { IKeyMetric } from './model/key-metric.interface';
import { Loader } from '../../../../../_shared/components/loader/loader';
import DeskSettingsApi from '../../../api/settings-api';
import "./metrics.scss"
import { IPricingModel } from '../../../../../_shared/model/pricing-model.interface';
import SvgAdd from '../../../../../assets/icons/js/add';
import { DebounceInput } from 'react-debounce-input';
import SvgDelete from '../../../../../assets/icons/js/delete';
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";

type MetricsForm = {
  keyMetrics: IKeyMetric[],
  pricingModels: IPricingModel[]
}

export function Metrics(): JSX.Element {
  const formMethods = useForm<MetricsForm>({
    defaultValues: {
      keyMetrics: [],
      pricingModels: []
    }
  })
  const {control} = formMethods;
  const {
    fields: keyMetricsFields,
    append: appendKeyMetric,
    remove: removeKeyMetric
  } = useFieldArray({name: 'keyMetrics', control, keyName: "key"});
  const {
    fields: pricingModelsFields,
    append: appendPricingModel,
    remove: removePricingModel
  } = useFieldArray({name: 'pricingModels', control, keyName: "key"});

  const [loaderKeyMetrics, setLoaderKeyMetrics] = useState<any>();
  const [loaderPricingModel, setLoaderPricingModel] = useState<any>();

  useEffect(() => {
    loadKeyMetrics();
    loadPricingModels();
  }, []);

  const handleAddClick = () => {
    appendKeyMetric({
      name: '',
      type: 'Numeric'
    })
  };

  const handleAddClickPricingModel = () => {
    appendPricingModel('')
  };

  const addPricingModel = (value: string, id: number) => {
    const pricingModel = {
      id: id,
      name: value,
    }

    if (id) {
      DeskSettingsApi.updatePricingModel(id, pricingModel)
        .then();
      return;
    }

    DeskSettingsApi.createPricingModel(pricingModel)
      .then(() => {
        loadPricingModels()
      })
  }

  const deletePricingModel = (id: number, index: number) => {
    if (id) {
      DeskSettingsApi.deletePricingModel(id)
        .then(() => {
          removePricingModel(index)
        });
    } else {
      removePricingModel(index)
    }
  }

  const addKeyMetric = (keyMetric: IKeyMetric) => {
    const isKeyMetricValid = keyMetric.name && keyMetric.type;
    if (isKeyMetricValid && keyMetric.id) {
      DeskSettingsApi.updateKeymetric(keyMetric.id, keyMetric)
        .then(() => {
          //loadKeyMetrics();
        });
      return;
    }

    if (isKeyMetricValid) {
      DeskSettingsApi.createKeymetric(keyMetric)
        .then(() => {
          loadKeyMetrics()
        });
    }
  }

  const deleteKeyMetric = (id: number, index: number) => {
    if (id) {
      DeskSettingsApi.deleteKeymetric(id)
        .then(() => {
          removeKeyMetric(index)
        });
    } else {
      removeKeyMetric(index)
    }
  }


  const loadKeyMetrics = () => {
    DeskSettingsApi.getKeymetrics()
      .then((response: IKeyMetric[]) => {
        formMethods.setValue('keyMetrics', response.length ? response : [])
        setLoaderKeyMetrics(false);
      });
  }

  const loadPricingModels = () => {
    DeskSettingsApi.getPricingModels()
      .then((response: IPricingModel[]) => {
        formMethods.setValue('pricingModels', response.length ? response : [])
        setLoaderPricingModel(false);
      });
  }

  return (<>
      <div className='metrics'>
        <FormProvider {...formMethods}>
          <div className=''>
            <div className='title-metrics' style={{width: 550}}>Key metrics</div>
            <div className="key-metrics column align-items-start">
              {loaderKeyMetrics ? <Loader/> :
                keyMetricsFields && keyMetricsFields.map((keyMetric: any, i: number) => {
                  return (
                    <KeyMetricsInput key={i}
                                     inputKey={i}
                                     keyMetricId={keyMetric.id}
                                     option={keyMetric}
                                     addKeyMetric={addKeyMetric}
                                     addInput={handleAddClick}
                                     deleteKeyMetric={deleteKeyMetric}
                                     isAddAvailable={true}
                                     isDeleteAvailable={keyMetricsFields.length !== 1}/>
                  )
                })}
            </div>
          </div>
          <div className=''>
            <div className='title-metrics' style={{width: 400}}>Pricing model</div>
            <div className="pricing-model column align-items-start">
              {loaderPricingModel ? <Loader/> :
                pricingModelsFields && pricingModelsFields.map((option: any, i: number) => {
                  return (
                    <div className="options" key={i}>
                      <div className="option row">
                        <div className="outline-input-wrapper">
                          <Controller control={control}
                                      rules={{onChange: e => addPricingModel(e.target.value, option.id)}}
                                      render={({field: {ref, ...field}}) => <DebounceInput
                                        className="outline-input"
                                        placeholder="Price Model Name..."
                                        debounceTimeout={600}
                                        {...field}
                                        inputRef={ref}
                                      />
                                      }
                                      name={`pricingModels.${i}.name`}/>
                        </div>
                        <div className="input-options">
                          <div className="option" onClick={handleAddClickPricingModel}>
                            <SvgAdd color="#F5F5F5"/>
                          </div>
                          {
                            pricingModelsFields.length !== 1 &&
                              <div className="option" onClick={() => deletePricingModel(option.id, i)}>
                                  <SvgDelete color="#F5F5F5"/>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </FormProvider>
      </div>
    </>
  );
}

