import { EditorState, SelectionState, ContentBlock } from 'draft-js';

export function onShowFloatingMenu(editorState: EditorState, isFocus: boolean, disable: any): boolean {
  const selectionState: SelectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  if (selectionState.isCollapsed()) return false;


  const startKey: string = selectionState.getStartKey();
  const endKey: string = selectionState.getEndKey();
  const startOffset: number = selectionState.getStartOffset();
  const endOffset: number = selectionState.getEndOffset();

  let selectedText = '';

  if (startKey !== endKey) {
    const startBlock = contentState.getBlockForKey(startKey);
    const endBlock = contentState.getBlockForKey(endKey);

    // Get the text from the start block from the start offset to the end of the block
    selectedText += startBlock.getText().slice(startOffset) + '\n';

    // Get the text from the blocks in between
    let blockKey = contentState.getKeyAfter(startKey);
    while (blockKey !== endKey) {
      const block = contentState.getBlockForKey(blockKey);
      selectedText += block.getText() + '\n';
      blockKey = contentState.getKeyAfter(blockKey);
    }

    // Get the text from the end block from the beginning to the end offset
    selectedText += endBlock.getText().slice(0, endOffset);
  } else {
    const currentBlock: ContentBlock = contentState.getBlockForKey(startKey);
    selectedText = currentBlock.getText().slice(startOffset, endOffset);
  }
  
  return (
    isFocus &&
    selectedText.length >= 1 &&
    disable !== true &&
    selectedText !== " "
  );
}
