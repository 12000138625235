// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-avatar-wrapper {
  min-width: 30px;
  max-width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #FFF;
}
.user-avatar-wrapper img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/user-avatar/user-avatar.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,WAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".user-avatar-wrapper {\n  min-width: 30px;\n  max-width: 30px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: transparent;\n  color: #FFF;\n\n  img {\n    height: 30px;\n    width: 30px;\n    border-radius: 50%;\n    object-fit: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
