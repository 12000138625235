import * as React from "react";

const SvgKnowledgeGraph = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g transform="translate(-251.000000, -304.000000) translate(243.000000, 296.000000)">
        <path fill={props.color ? props.color : '#545E6B'}
              fillRule="nonzero"
              d="M8 11c1.38 0 2.5 1.12 2.5 2.5S9.38 16 8 16s-2.5-1.12-2.5-2.5S6.62 11 8 11zm0 1c-.828 0-1.5.672-1.5 1.5S7.172 15 8 15s1.5-.672 1.5-1.5S8.828 12 8 12zM8 0c1.657 0 3 1.343 3 3 0 .605-.179 1.168-.487 1.639l-.105.15 2.556 2.5-.022.013C13.25 7.111 13.612 7 14 7c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2c0-.379.105-.733.288-1.035l-.023.04-2.58-2.522.02-.014C9.22 5.804 8.633 6 8 6c-.554 0-1.073-.15-1.518-.412l-.166-.105-2.58 2.522-.024-.04C3.895 8.267 4 8.621 4 9c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2c.324 0 .63.077.9.214l.136.075 2.556-2.5.016.022C5.226 4.308 5 3.68 5 3c0-1.657 1.343-3 3-3zm.5 9.1v1.3h-1V9.1h1zm0-2.3v1.3h-1V6.8h1z"
              transform="translate(8.000000, 8.000000)"/>
      </g>
    </svg>
  );
}

export default SvgKnowledgeGraph;
