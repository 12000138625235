import * as React from "react";

const SvgFiletypeDoc = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero">
        <path
          d="M12 0l4 4v12H4v-1h11V5h-4V1H5v6H4V0h8zm2 8v6H0V8h14zm-6.757.764c-.368 0-.689.086-.963.258-.274.172-.485.421-.633.747-.148.326-.222.713-.222 1.161 0 .456.074.846.222 1.17.148.324.358.57.63.738.272.168.594.252.966.252.368 0 .688-.086.96-.258.272-.172.482-.42.63-.744.148-.324.222-.712.222-1.164 0-.456-.073-.846-.219-1.17-.146-.324-.355-.57-.627-.738-.272-.168-.594-.252-.966-.252zm4.14 0c-.344 0-.653.084-.927.252-.274.168-.49.414-.648.738-.158.324-.237.712-.237 1.164 0 .46.076.853.228 1.179.152.326.366.573.642.741.276.168.594.252.954.252.264 0 .503-.048.717-.144.16-.072.3-.157.42-.256l.111-.104-.372-.468c-.144.108-.28.191-.408.249-.128.058-.274.087-.438.087-.312 0-.56-.126-.744-.378-.184-.252-.276-.638-.276-1.158 0-.532.093-.922.279-1.17.186-.248.433-.372.741-.372.223 0 .438.068.645.204l.123.09.402-.468c-.18-.148-.364-.258-.552-.33-.188-.072-.408-.108-.66-.108zm-8.598.09h-1.02V13h1.128c.584 0 1.066-.157 1.446-.471.38-.314.57-.855.57-1.623 0-.784-.193-1.322-.579-1.614-.338-.255-.774-.4-1.31-.431l-.235-.007zm4.458.516c.32 0 .563.124.729.372.166.248.249.642.249 1.182 0 .544-.083.94-.249 1.188-.166.248-.409.372-.729.372-.656 0-.984-.518-.984-1.554 0-.54.084-.935.252-1.185.168-.25.412-.375.732-.375zm-4.32.078c.232 0 .431.038.597.114.166.076.3.221.402.435.102.214.153.517.153.909 0 .564-.1.955-.3 1.173-.2.218-.476.327-.828.327h-.39V9.448h.366z"
          transform="translate(-867 -1908) translate(451 1832) translate(400 64) translate(16 8) translate(0 4)"/>
      </g>
    </svg>

  );
}

export default SvgFiletypeDoc;
