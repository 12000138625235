import React, { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { PageViewEnum } from "../../model/page-view.enum";
import StoryTile from "../story-tile/story-tile";
import { Empty, message, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IStory } from "../../model/story-interface";
import FormattedDate from "../../../../_shared/helpers/formatted-date";
import SvgRestoreTrash from "../../../../assets/icons/js/RestoreTrash";
import SvgTrash from "../../../../assets/icons/js/Trash";
import SvgEdit from "../../../../assets/icons/js/Edit";
import StoriesApi from "../../api/stories-api";
import storiesApi from "../../api/stories-api";
import SvgTick from "../../../../assets/icons/js/Tick";
import SvgAlert from "../../../../assets/icons/js/alert";
import qs from "query-string";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "../../../../_shared/components/confirmation-dialog/confirmation-dialog";
import Pagination from "../../../../_shared/components/pagination/pagination";
import { useSearchParams } from "react-router-dom";
import { Loader } from "../../../../_shared/components/loader/loader";
import { queryClient } from "../../../../index";

type StoriesViewProps = {
  status: "DRAFT" | "PUBLISHED"
  view: PageViewEnum
}

const StoriesView = ({status, view}: StoriesViewProps) => {
  const columns: ColumnsType<any> = [
    {
      title: "Story title",
      dataIndex: "title",
      key: "title",
      render: (value: string, record: IStory) => {
        return <div key={value} onClick={() => navigateToStory(record.id, record.archived)}
                    className='link'>{value?.length ? value : "Untitled"}</div>
      }
    },
    {
      title: "Owner",
      dataIndex: "createdBy",
      key: "createdBy"
    },
    {
      title: "Last modified",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: string) => {
        return <FormattedDate date={value} formatString="MMM d, yyyy"/>
      }
    },
    {
      title: "",
      dataIndex: "id",
      key: "x",
      width: 45,
      align: "right",
      render: (id: number, story: IStory) => (
        <div className="action-hover-column">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Edit">
              <button className="icon-button" data-testid={"edit-button"}
                      onClick={() => navigateToStory(id, story.archived)}>
                <SvgEdit/>
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title="Archive">
              <button className="icon-button" data-testid={"archive-button"}
                      onClick={() => openArchiveConfirmationDialog(story)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ]

  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const page = Number(params.get('page')) || 1;
  const pageSize = Number(params.get('pageSize')) || 10;

  const [archiveConfirmationDialog, setArchiveConfirmationDialog] = useState<any>({visible: false, id: null});
  const stories = useQuery(['stories', status, page, pageSize], () => StoriesApi.getStories({
    status,
    page,
    pageSize
  }));


  const navigateToStory = (id: number, archived?: boolean) => {
    if (archived) {
      return;
    }

    navigate({pathname: "/stories/edit", search: qs.stringify({id: id})})
  }

  const archiveStory = (storyId: number | undefined) => {
    if (storyId) {
      storiesApi.archiveStory(storyId).then(() => {
        stories.refetch();
        queryClient.invalidateQueries(["storiesCounts"])
        message.success({
          content: 'Story archived successfully!',
          className: 'custom-success-message',
          icon: <SvgTick color={"#227700"}/>
        })
      }).catch(error => {
        message.error({
          content: 'Error archiving story!',
          className: 'custom-error-message',
          icon: <SvgAlert color={"#CC0000"}/>
        })
      })
    }
  }


  const openArchiveConfirmationDialog = (story?: Partial<IStory>) => {
    setArchiveConfirmationDialog({
      visible: true,
      id: story?.id,
      okText: 'Move',
      title: 'Move to Recycle Bin',
      titleSec: 'You\'re about to move this story to the Recycle Bin',
      content: `${story?.title || 'Untitled'}`,
      description: <>
        Continuing will remove the story from this list. You can easily restore it by going to
        the <SvgTrash/> Recycle Bin tab and clicking
        the <SvgRestoreTrash/> Restore From Recycle Bin button.
      </>
    });
  }


  const onArchiveConfirmationDialog = (props: any) => {
    if (props.modal) {
      archiveStory(props.id);
      onDialogCancel();
    }
  }

  const onDialogCancel = () => {
    setArchiveConfirmationDialog({visible: false})
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setParams((prevParams) => {
      prevParams.set('page', page.toString());
      prevParams.set('pageSize', pageSize.toString());

      return prevParams;
    })
  }

  const renderGridStories = () => {
    if (stories.isLoading) {
      return <Loader/>;
    } else if (
      stories.data &&
      stories.data.pagination.totalElements === 0
    ) {
      return <div className="w-100 column justify-space-center" data-testid={"no-data"}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
      </div>;
    } else if (stories.data) {
      return stories.data.content.map((story, index) => (
        <StoryTile
          key={index}
          fromProjectEdit={false}
          openArchiveConfirmationDialog={openArchiveConfirmationDialog}
          openDeleteConfirmationDialog={() => {
          }}
          restoreStory={() => {
          }}
          navigateToStory={navigateToStory}
          story={story}
        />
      ));
    }
  };

  return (
    <div className="">
      <div>
        <div className="row justify-space-end">
          {
            stories.data && stories.data?.pagination.totalElements > 0 &&
            <Pagination pageSize={pageSize}
                        currentPage={page}
                        onChange={handlePaginationChange}
                        showSizeChanger
                        totalItems={stories.data?.pagination.totalElements}/>
          }

        </div>
        <div className="stories">
          {
            view === PageViewEnum.GRID ?
              renderGridStories() :
              <Table
                columns={columns}
                rowKey={"id"}
                loading={stories.isLoading}
                dataSource={stories.data?.content}
                pagination={false}
                style={{width: "100%"}}/>
          }
        </div>
      </div>
      {
        archiveConfirmationDialog.visible &&
        <ConfirmationDialog data={archiveConfirmationDialog}
                            onConfirm={onArchiveConfirmationDialog}
                            onCancel={onDialogCancel}/>

      }
    </div>
  );
};

export default StoriesView;
