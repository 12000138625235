import React, { useEffect, useState } from 'react';
import SvgSave from '../../../../../../assets/icons/js/save';
import SvgCancel from '../../../../../../assets/icons/js/Cancel';
import SvgEdit from '../../../../../../assets/icons/js/Edit';
import { message, notification, Select, Spin } from 'antd';
import { ITeamMember } from '../../../../../settings/settings-tabs/team/model/team-member.interface';
import MyProjectApi from '../../../../api/project-api';
import { ICollaborator, IProject } from '../../../../models/project.interface';
import { queryClient } from '../../../../../../index';
import { Link } from 'react-router-dom';
import { IOwner } from '../../../../models/owner.interface';
import { Controller, useForm, useWatch } from "react-hook-form";
import SvgArrowDown from "../../../../../../assets/icons/js/ArrowDown";
import SvgTick from "../../../../../../assets/icons/js/Tick";

type DetailsContributorsPayload = {
  requestedBy: number | null,
  owner: number | null,
  collaborators: number[]
}

export function DetailsContributors(props: { project: any; owners: any; }): JSX.Element {
  const project = props?.project;
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);

  const {control, handleSubmit} = useForm<DetailsContributorsPayload>({
    defaultValues: {
      requestedBy: props?.project?.requestedBy?.id ?? null,
      owner: props?.project?.owner ?? null,
      collaborators: props?.project.collaborators.map((collaborator: ICollaborator) => collaborator.id)
    }
  })

  const submit = (data: DetailsContributorsPayload): void => {
    setApiLoading(true);

    const displayCollaborators: ICollaborator[] = [];

    data.collaborators.forEach((id: number) => {
      displayCollaborators.push(props?.owners.find((owner: IOwner) => owner.id === id));
    });

    const payload = {
      ...data,
      ownerId: data.owner,
      collaborators: displayCollaborators,
    };

    MyProjectApi.updateProjectContributors(project.id, payload).then(
      (response: any) => {
        queryClient.setQueryData<IProject>(
          ['project', JSON.stringify(project.id)],
          (oldData) => ({
            ...oldData,
            ...response,
          })
        );
        queryClient.invalidateQueries(['project', JSON.stringify(project.id)]);

        setEdit(false);

        message.success({
          content: "Project status has been updated",
          className: "custom-success-message",
          icon: <SvgTick color={"#227700"}/>
        })
        setApiLoading(false);
      }
    );
  };


  return (
    <div className="project-edit-card">
      <div className="project-edit-header">
        <div className="left">
          <h3>Project contributors</h3>
        </div>
        <div className="right">
          {apiLoading ? (
            <>
              <div/>
              <Spin/>
            </>
          ) : (
            <>
              {edit ? (
                <>
                  <button className="icon-button" onClick={handleSubmit(submit)}>
                    <SvgSave width={20}/>
                  </button>
                  <button
                    className="icon-button cancel-icon-hover"
                    onClick={() => setEdit(false)}
                  >
                    <SvgCancel width={16} height={16} color="red"/>
                  </button>
                </>
              ) : (
                <>
                  <span/>
                  <button className="icon-button" onClick={() => setEdit(true)}>
                    <SvgEdit/>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="project-edit-body">
        <div className="content-block">
          <span className="title">Requested by</span>
          {edit ? (
            <Controller control={control} name={'requestedBy'} render={({field}) => <Select
              showSearch
              suffixIcon={<SvgArrowDown/>}
              {...field}
              filterOption={(input: string, option: any) => (option?.children ?? '')?.toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) => (optionA?.children ?? '')?.toLowerCase().localeCompare((optionB?.children ?? '')?.toLowerCase())}
            >
              {props?.owners.map((owner: ITeamMember) => {
                return (
                  owner.id && (
                    <Select.Option key={owner.id} value={owner.id}>
                      {owner?.displayName}
                    </Select.Option>
                  )
                );
              })}
            </Select>}/>
          ) : (
            <div className="description">
              {project?.requestedBy && (
                <>
                  <Link
                    to={'/home/search?search=' + project.requestedBy?.displayName}>{project.requestedBy.displayName}</Link>
                </>
              )}
            </div>
          )}
        </div>
        <div className="content-block">
          <span className="title">Owner</span>
          {edit ? (
            <Controller control={control} name={'owner'} render={({field}) => <Select
              {...field}
              suffixIcon={<SvgArrowDown/>}
              showSearch
              filterOption={(input: string, option: any) => (option?.children ?? '')?.includes(input)}
              filterSort={(optionA, optionB) => (optionA?.children ?? '')?.toLowerCase().localeCompare((optionB?.children ?? '')?.toLowerCase())}
            >
              {props?.owners.map((owner: ITeamMember) => {
                return (
                  owner.id && (
                    <Select.Option key={owner.id} value={owner.id}>
                      {owner?.displayName}
                    </Select.Option>
                  )
                );
              })}
            </Select>}/>
          ) : (
            <div className="description">
              {project?.owner && (
                <>
                  <Link
                    to={'/home/search?search=' + project.owner?.displayName}>{project.owner.displayName}</Link>
                </>
              )}
            </div>
          )}
        </div>
        <div className="content-block">
          <span className="title">Collaborators</span>
          {edit ? (
            <Controller control={control} name={'collaborators'} render={({field}) => <Select
              {...field}
              mode='multiple'
            >
              {props?.owners.map((owner: ITeamMember) => {
                return (
                  owner.id && (
                    <Select.Option key={owner.id} value={owner.id}>
                      {owner?.displayName}
                    </Select.Option>
                  )
                );
              })}
            </Select>}/>
          ) : (
            <div className="description">
              {
                props.project?.collaborators.map((collaborator: ICollaborator, index: number, arr: ICollaborator[]) => {
                  if (arr.length - 1 === index) return <span key={collaborator.id}>{collaborator?.displayName}</span>
                  return (<span key={collaborator.id}>{collaborator?.displayName}, </span>)
                })
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
