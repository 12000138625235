import React from 'react';

const SvgCreateProjectBig = (props: any) => {
  return (
    <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.49997 7.5C1.56699 7.5 0 9.067 0 11L0.000427243 46C0.000427243 47.933 1.56742 49.5 3.5004 49.5H52.5C54.433 49.5 56 47.933 56 46V18C56 16.067 54.433 14.5 52.5 14.5H27.9998V11C27.9998 9.067 26.4328 7.5 24.4998 7.5H3.49997Z"
        fill="#1B87E6"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M29.75 30.25H36.75V33.75H29.75V40.75H26.25V33.75H19.25V30.25H26.25V23.25H29.75V30.25Z" fill="white"/>
    </svg>

  );
};

export default SvgCreateProjectBig;
