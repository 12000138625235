import React from 'react';
import './sync-type-box.scss';
import { Tooltip } from "antd";
import SvgBetaLabel from "../../../../../../assets/icons/js/BetaLabel";

type IProps = {
  //image: string;
  icon: React.ReactNode,
  title: string;
  text: string;
  button: JSX.Element;
  isBetaFeature?: boolean;
}

const SyncTypeBox = (props: IProps) => {
  return (
    <div className="sync-type-box-wrapper relative">
      {
        props.isBetaFeature && <div className="beta-label">
          <div data-testid="beta-label">
            <Tooltip placement="topLeft" title="Beta feature">
              <SvgBetaLabel/>
            </Tooltip>
          </div>

        </div>
      }
      <div className="sync-type-box-icon">
        {props.icon}
        {/*<img src={props.image} alt=""/>*/}
      </div>
      <div className="sync-type-box-title">
        {props.title}
      </div>
      <div className="sync-type-box-text">
        {props.text}
      </div>
      <div className="sync-type-box-button">
        {props.button}
      </div>
    </div>
  );
};

export default SyncTypeBox;
