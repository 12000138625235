// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trending-prompts-wrapper {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.trending-prompts-wrapper .trending-prompts-header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.trending-prompts-wrapper .trending-suggestions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  padding: 8px;
}
.trending-prompts-wrapper .trending-suggestions .trending-suggestion {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #1b87e6;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  border: 1px solid #1b87e6;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.trending-prompts-wrapper .trending-suggestions .trending-suggestion:hover {
  border-color: #8DC3F2;
  background-color: #8DC3F2;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/ai-chat/components/trending-prompts/trending-prompts.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;AACJ;AAEE;EAEE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,QAAA;EACA,YAAA;AADJ;AAII;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,yBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,wBAAA;EAAA,mBAAA;AAFN;AAIM;EACE,qBAAA;EACA,yBAAA;EACA,YAAA;AAFR","sourcesContent":[".trending-prompts-wrapper {\n  width: 100%;\n  height: 50%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  .trending-prompts-header {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    position: relative;\n  }\n\n  .trending-suggestions {\n\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 8px;\n    padding: 8px;\n\n\n    .trending-suggestion {\n      font-size: 14px;\n      font-weight: 400;\n      line-height: 16px;\n      text-align: center;\n      color: #1b87e6;\n      border-radius: 4px;\n      padding: 4px 8px 4px 8px;\n      cursor: pointer;\n      border: 1px solid #1b87e6;\n      width: fit-content;\n      height: fit-content;\n\n      &:hover {\n        border-color: #8DC3F2;\n        background-color: #8DC3F2;\n        color: white;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
