import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { DebounceInput } from "react-debounce-input";
import { queryClient } from "../../../../../../index";
import DeskSettingsApi from "../../../../api/settings-api";
import { IWelcomeMessageProps } from "./model/welcome-message-props.interface";
import SvgUploadFile from "../../../../../../assets/icons/js/UploadFile";
import { environment } from "../../../../../../environment";
import "./welcome-message.scss";
import SvgFiletypeImg from "../../../../../../assets/icons/js/SvgFiletypeImg";
import SvgAlert from "../../../../../../assets/icons/js/alert";
import SvgTrash from "../../../../../../assets/icons/js/Trash";
import { Controller, useForm } from "react-hook-form";

const {Dragger} = Upload;

const WelcomeMessage = (props: IWelcomeMessageProps): JSX.Element => {
  let token = localStorage.getItem("access_token");
  const {control} = useForm<{ welcomeMessage: string, description: string }>({
    defaultValues: {
      welcomeMessage: props?.welcomeMessage ?? '',
      description: props?.description ?? ''
    }
  })
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [error, setError] = useState<any>("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const portalChanged = (payload: any): void => {
    DeskSettingsApi.updateOrganisationSettings(payload).then(() => {
      queryClient.invalidateQueries("organisationSettings");
    });
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setError("Format unvalid. Allowed file extensions: png, jpg");
    }
    return isJpgOrPng;
  };

  const method: any = "PUT";

  const uploadProps = {
    name: "file",
    openFileDialogOnClick: true,
    multiple: false,
    headers: {
      Authorization: "Bearer " + token,
    },
    action: `${environment.backendUrl}/organizations/logo`,
    method,
    onChange: (info: any) => onFileChange(info),
    showUploadList: false,
    beforeUpload,
  };

  const deleteLogo = (): void => {
    setDeleteLoading(true);
    const fm = new FormData();
    const file: any = null;
    fm.append("file", file);
    DeskSettingsApi.uploadLogo(fm).then(() => {
      queryClient.invalidateQueries("organisationSettings").then(() => {
        setDeleteLoading(false);
      });
    });
  };

  const handleOnMouseOverOnIcon = () => {
    setIsHover(true);
  };

  const handleOnMouseLeaveOnIcon = () => {
    setIsHover(false);
  };

  const onFileChange = (info: any) => {
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (!isJpgOrPng) {
      setError("Format unvalid. Allowed file extensions: png, jpg");
      return;
    }
    if (isJpgOrPng) setError("");
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
    setLoading(true);

    const {status} = info.file;

    if (status === "done") {
      queryClient
        .invalidateQueries("organisationSettings")
        .then(() => setLoading(false));
    }
    if (status === "error") {
      setError("Failed To Upload");
    }
  };

  return (
    <>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Title</span>
        <Controller control={control} render={({field}) => <DebounceInput
          style={{width: 320}}
          className="outline-input"
          placeholder="Title"
          debounceTimeout={600}
          {...field}
          onChange={(e) => {
            portalChanged({welcomeMessage: e.target.value});
          }}
        />} name={'welcomeMessage'}/>
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Subtitle</span>
        <Controller control={control} render={({field}) => <DebounceInput
          element="textarea"
          rows="8"
          style={{width: 784}}
          className="outline-input"
          placeholder="Add Subtitle ..."
          debounceTimeout={600}
          {...field}
          onChange={(e) => {
            portalChanged({description: e.target.value});
          }}
        />} name={'description'}/>
      </div>
      <div
        className="outline-input-wrapper row flex-start"
        style={{marginTop: 30, gap: props.logoUrl ? 30 : 140}}
      >
        <span className="outline-input-label">Upload your logo</span>
        <div style={{width: props.logoUrl ? "fit-content" : "70%"}}>
          {props.logoUrl ? (
            <div className="logo-wrapper">
              <div
                className="logo-delete-button"
                onMouseEnter={handleOnMouseOverOnIcon}
                onMouseLeave={handleOnMouseLeaveOnIcon}
                onClick={deleteLogo}
              >
                <SvgTrash color={isHover ? "white" : "#545E6B"}/>
              </div>
              {deleteLoading ? (
                <LoadingOutlined/>
              ) : (
                <img src={props.logoUrl} className="logo-img" alt="logo"/>
              )}
            </div>
          ) : (
            <Dragger {...uploadProps}>
              <div className="column justify-space-center align-items-center">
                {
                  !loading ? (
                    <>
                      <SvgUploadFile color="#545E6B"/>
                      <div>
                        <span className="file-success">
                          Drop your files here or
                        </span>
                        <span className="link">&nbsp;Browse</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <LoadingOutlined/>
                      <span className="file-success">Uploading</span>
                      <span className="file-name">
                        <SvgFiletypeImg color="#9B9B9B"/> &nbsp;{" "}
                        {fileList[0].name} &nbsp; &nbsp; {fileList[0].size}KB
                      </span>
                    </>
                  )
                  //  (
                  //   <>
                  //     <SvgCheck color="#3FCA5A" />
                  //     <span className="file-success">
                  //       File Successfuly uploaded
                  //     </span>
                  //     <span className="file-name">
                  //       <SvgFiletypeImg color="#9B9B9B" /> &nbsp;{" "}
                  //       {fileList[0].name} &nbsp; &nbsp; {fileList[0].size}KB
                  //       &nbsp;
                  //       <SvgClose color="#9B9B9B" width={8} />
                  //     </span>
                  //   </>)
                  // )
                }
              </div>
            </Dragger>
          )}

          {error && (
            <div className="error">
              <SvgAlert color="#e53251"/> Format unvalid. Allowed file
              extensions: png, jpg
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WelcomeMessage;
