import { ContentBlock, ContentState, EditorState, Modifier, RichUtils, SelectionState, } from "draft-js";
import { moveSelectionToTheEndOfBlock } from '../../../../../_shared/components/draft-js/components/helpers/entity';
import { getProjectSelectionByEntityMapId } from './entity';

let project: any;

export function assignProjectEntity(
  editorState: EditorState,
  data: { project?: any; entityKey?: string; entityMapId?: string }
): EditorState {
  project = data?.project;

  const entityKey = data?.entityKey;
  const entityMapId = data?.entityMapId;

  if (entityKey && entityMapId) {
    return manageProjectWithEntityKey(editorState, entityKey, entityMapId);
  }

  return manageProjectWithSelection(editorState);
}

function manageProjectWithEntityKey(editorState: EditorState, entityKey: string, entityMapId: string): EditorState {
  const entitySelection: SelectionState | undefined = getProjectSelectionByEntityMapId(editorState, entityMapId);
  const contentState: ContentState = editorState.getCurrentContent();

  // if project is not present remove the entity from selectedText in editor
  if (!project?.id && entitySelection) {
    const newContentState = Modifier.applyEntity(
      contentState,
      entitySelection,
      null
    );
    return EditorState.push(editorState, newContentState, "apply-entity");
  } else {
    contentState.mergeEntityData(entityKey, {
      project: project,
    });

    return moveSelectionToTheEndOfBlock(editorState);
  }
}

function manageProjectWithSelection(editorState: EditorState): EditorState {
  const selectionState: SelectionState = editorState.getSelection();
  const anchorKey: string = selectionState.getAnchorKey();
  const contentState: ContentState = editorState.getCurrentContent();
  const currentBlock: ContentBlock = contentState.getBlockForKey(anchorKey);

  const start: number = selectionState.getStartOffset();
  const end: number = selectionState.getEndOffset();
  const selectedText: string = currentBlock.getText().slice(start, end);

  if (!project) {
    return RichUtils.toggleLink(editorState, selectionState, null);
  } else {
    const contentWithEntity: ContentState = contentState.createEntity(
      "PROJECT",
      "MUTABLE",
      {
        selectedText,
        project: project,
        entityMapId: Math.floor(100000 + Math.random() * 900000),
      }
    );

    const newEditorState: EditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "apply-entity"
    );

    const entityKey: string | null = contentWithEntity.getLastCreatedEntityKey();
    return RichUtils.toggleLink(newEditorState, selectionState, entityKey);
  }
}
