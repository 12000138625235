import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult
} from "react-beautiful-dnd";
import SvgDrag from "../../../../assets/icons/js/drag";
import { Checkbox, Empty } from "antd";
import React from "react";
import { ICustomizeColumnTable } from "../../models/customize-table.interface";
import SvgLock from "../../../../assets/icons/js/lock";

export function DraggableColumns({columns, onColumnsChanged}: {
  columns: ICustomizeColumnTable[],
  onColumnsChanged: (newColumns: ICustomizeColumnTable[]) => void
}): JSX.Element {
  function onDragEnd(result: DropResult) {
    if (!columns) {
      return;
    }

    const {source, destination} = result;

    if (!destination) {
      return;
    }

    if (destination.index === 0) {
      return;
    }

    const newColumns: ICustomizeColumnTable[] = Array.from(columns);
    const [removed] = newColumns.splice(source.index, 1);
    newColumns.splice(destination.index, 0, removed);

    newColumns.forEach((column, index) => {
      column.order = index;
    });

    onColumnsChanged([...newColumns]);
  }

  function onCheckBoxToggle(column: ICustomizeColumnTable) {
    if (!columns) {
      return
    }

    const newColumns: ICustomizeColumnTable[] = Array.from(columns);
    let findColumnIndex: number = newColumns.findIndex((col: ICustomizeColumnTable) => col.name === column.name);
    newColumns[findColumnIndex].selected = !newColumns[findColumnIndex].selected;
    onColumnsChanged([...newColumns]);
  }

  if (!columns || columns.length === 0) {
    return <div>
      <Empty/>
    </div>;
  }

  const orderedColumns = [...columns].sort((a, b) => a.order - b.order);
  

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="customize-table-dialog">
        {(provided: DroppableProvided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} key={0}>
            {
              orderedColumns && orderedColumns.map((column: any, index: number) => {
                return (
                  <Draggable isDragDisabled={column.name === "Project name"} key={column.name}
                             draggableId={`${column.name}-item`} index={index}>
                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                      <div
                        className={snapshot.isDragging ? 'customize-table-column isDragging' : 'customize-table-column'}
                        style={snapshot.isDragging ? {background: 'green'} : {background: 'gold'}}
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <div className="title">
                          <div className="drag-handle" {...provided.dragHandleProps}>
                            {
                              column.name === "Project name" ? <SvgLock/> : <SvgDrag color="#545E6B"/>
                            }
                          </div>
                          {column.name}
                        </div>
                        <Checkbox className="customize-table-checkbox"
                                  checked={column.selected}
                                  disabled={column.name === "Project name"}
                                  onClick={() => onCheckBoxToggle(column)}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
