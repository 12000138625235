import React from 'react';
import { EditorState } from "draft-js";
import { getRecommendationByEntityMapId } from "../../../story-edit/story-editor/helpers/entity";


type RecommendationThumbsUpProps = {
  index: number;
  value: any;
  editorState: EditorState,
}
const RecommendationThumbsUp = (props: RecommendationThumbsUpProps) => {
  const entity = getRecommendationByEntityMapId(props.editorState, props.value[1][0].entityMapId)
  const accountsThatLiked: {
    id: number,
    userId: number,
    email: string,
    profilePic: string,
    firstName: string,
    lastName: string
  }[] | undefined = entity.entity.getData()?.accountsThatLiked;

  // const items: MenuProps['items'] = accountsThatLiked && accountsThatLiked.map((user) => {
  //   return {
  //     label: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email,
  //     key: user.userId,
  //     icon: user.profilePic ? <div style={{width: 20, height: 20, borderRadius: "50%"}}>
  //       <img style={{objectFit: "cover", width: 20, height: 20, borderRadius: "50%"}} src={user.profilePic} alt=""/>
  //     </div> : <></>
  //   }
  // })

  return (
    <div className="recommendation-thumbs-up-wrapper row" style={{top: `${parseInt(String(props.value[0]))}px`}}>
      <button className={"recommendation-thumbs-up-icon"} style={{pointerEvents: 'none'}}
              key={props.index + Math.random()}>
        <i className="bi bi-hand-thumbs-up-fill"/>
      </button>
      <div className="recommendation-thumbs-up-counter">
        {accountsThatLiked ? accountsThatLiked.length : 0}
      </div>
      {/*<Dropdown overlayClassName={"recommendation-thumbs-up-overlay"} menu={{items}} trigger={["click"]}>*/}
      {/*  <div className="recommendation-thumbs-up-dropdown">*/}
      {/*    <SvgArrowDown />*/}
      {/*  </div>*/}
      {/*</Dropdown>*/}
    </div>
  );
};

export default RecommendationThumbsUp;
