import React, { CSSProperties } from 'react';
import SvgRobot from "../../../assets/icons/js/robot";
import './ai-user-avatar.scss'

type Props = {
  color?: string
  size?: "md" | "sm";
  style?: CSSProperties
}
const UserAvatar = ({color = "#1B3380", size = "md", style}: Props) => {
  let wrapperSize = 30;
  let svgSize = 16;
  
  if (size === "sm") {
    wrapperSize = 24;
    svgSize = 12;
  }


  return (
    <div className={'user-avatar-wrapper'} style={{
      minWidth: wrapperSize,
      minHeight: wrapperSize,
      backgroundColor: color,
      ...style
    }}>
      <SvgRobot width={svgSize} height={svgSize}/>
    </div>
  );
};

export default UserAvatar;
