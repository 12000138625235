import { AxiosResponse } from "axios";
import { axiosClient } from "../../../axios-client";
import { environment } from "../../../environment";
import { IProjectSnapshot, ISnapshot, ISnapshotTheme } from "../model/snapshot.interface";
import { IPagination } from "../../../core/model/pagination.interface";

class SnapshotsApi {
  private baseUrl: string = `${environment.backendUrl}`;

  async getProjects(): Promise<IProjectSnapshot[]> {
    try {
      const response: AxiosResponse<IProjectSnapshot[]> = await axiosClient.get<IProjectSnapshot[]>(`${this.baseUrl}/snapshots/projects`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async getNuggets(
    projectId: number | undefined
  ): Promise<{ id: number; description: string; projectId: number }[]> {
    try {
      const response: AxiosResponse<{ id: number; description: string; projectId: number }[]> = await axiosClient.get<{
        id: number;
        description: string;
        projectId: number
      }[]>(`${this.baseUrl}/snapshots/nuggets`, {
        params: {projectId},
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async getSnapshots(
    projectId: number | undefined,
    nuggetId: number | undefined
  ): Promise<ISnapshot[]> {
    const response: AxiosResponse<ISnapshot[]> = await axiosClient.get<ISnapshot[]>(`${this.baseUrl}/snapshots`, {
      params: {
        projectId,
        nuggetId
      }
    });
    return response.data;
  }


  async getSnapshot(id: number): Promise<ISnapshot> {
    try {
      const response: AxiosResponse<ISnapshot> = await axiosClient.get(
        `${this.baseUrl}/snapshots/${id}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async updateSnapshot(id: number, payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        `${this.baseUrl}/snapshots/${id}`,
        payload
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async createSnapshot(nuggetId: number): Promise<ISnapshot> {
    try {
      const response: AxiosResponse<ISnapshot> =
        await axiosClient.post<ISnapshot>(`${this.baseUrl}/snapshots`, {
          nuggetId,
        });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async deleteSnapshot(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${this.baseUrl}/snapshots/${id}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async createThemeSnapshot(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/snapshots/theme`,
        payload
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async updateThemeSnapshot(id: number, payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/snapshots/theme/${id}`,
        payload
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async getSnapshotThemes(params?: any): Promise<{ content: ISnapshotTheme[], pagination: IPagination }> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/snapshots/theme`,
        {params}
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async bulkDeleteThemes(themeIds: number[]): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/snapshots/theme/bulk-delete`,
        {themeIds}
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new SnapshotsApi();
