import React, { useEffect, useState } from 'react';
import './zip-import.scss';
import { ColumnsType } from 'antd/lib/table';
import { GoogleDriveStateEnum } from '../google-drive-integration/model/google-drive-state.enum';
import { Button, Table, Tooltip, Upload } from 'antd';
import SvgEdit from '../../../../../../assets/icons/js/Edit';
import SvgTrash from '../../../../../../assets/icons/js/Trash';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import SvgArrowBack from '../../../../../../assets/icons/js/ArrowBack';
import SvgZip from '../../../../../../assets/icons/js/zip';
import SvgUploadFile from '../../../../../../assets/icons/js/UploadFile';
import { environment } from '../../../../../../environment';
import ZipImportApi from './api/zip-import-api';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload/interface';
import SvgCancel from '../../../../../../assets/icons/js/Cancel';
import { ConfirmationDialog } from '../../../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import FormattedDate from '../../../../../../_shared/helpers/formatted-date';

const {Dragger} = Upload;

export function ZipImport(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const [loader, setLoader] = useState<any>(true);
  const [importedProjects, setImportedProjects] = useState<any[]>([]);
  const [folder, setFolder] = useState<any>(null);
  const [isHover, setIsHover] = useState(false);
  const [onlyZipMessage, setOnlyZipMessage] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});

  let token = localStorage.getItem('access_token');

  const columns: ColumnsType<any> = [
    {
      title: 'Batch #',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Started By',
      dataIndex: 'startedBy',
      key: 'startedBy',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startedBy',
      render: (value: string) => <span>
        <FormattedDate date={value} formatString="MMM d, yyyy"/>
      </span>
    },
    {
      title: 'Last Edit',
      dataIndex: 'lastEdit',
      key: 'lastEdit',
      render: (value: string) => <span>
        <FormattedDate date={value} formatString="MMM d, yyyy"/>
      </span>
    },
    {
      title: 'Projects',
      dataIndex: 'projectsNumber',
      key: 'projectsNumber',
    },
    {
      title: 'Files',
      dataIndex: 'filesNumber',
      key: 'filesNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <span>{status[0].toUpperCase() + status.slice(1).toLocaleLowerCase()}</span>
    },
    {
      title: '',
      dataIndex: 'delete',
      align: 'right',
      render: (empty: undefined, driveImport: any) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            {driveImport.status === GoogleDriveStateEnum.DRAFT &&
                <>
                    <Tooltip placement="bottom" title="Edit Import">
                        <button className="icon-button" onClick={() => editImport(driveImport.id)}>
                            <SvgEdit/>
                        </button>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Delete Import">
                        <button className="icon-button" onClick={() => openConfirmationDialog(driveImport.id)}>
                            <SvgTrash/>
                        </button>
                    </Tooltip>
                </>
            }
          </div>
        </div>
      )
    }
  ];

  let uploadProps: UploadProps = {
    name: 'file',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    action: environment.backendUrl + '/integrations/zip-file/upload',
    method: 'POST',
    openFileDialogOnClick: false,
    beforeUpload: (file: RcFile) => {
      setOnlyZipMessage(false);
      if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
        return true
      } else {
        setOnlyZipMessage(true);
        setIsHover(false);
        return false;
      }
    },
    onChange: (info: UploadChangeParam) => onFileChange(info),
    multiple: false,
    accept: 'application/zip'
  };

  useEffect(() => {
    getImportedProjects();
  }, []);

  function navigateBack(): void {
    navigate('/settings?tab=5');
  }

  const onFileChange = (info: UploadChangeParam): void => {
    const {status} = info.file;
    handleDragLeave();
    setIsUploading(true);

    if (status === 'uploading') {
      setUploadPercentage(info.file.percent || 0)
    }

    if (status === 'done') {
      const response = info.file.response;

      setIsUploading(false);
      setFolder(response);
    }

    if (status === 'error') {
      // console.log(`${info.file.name} file upload failed.`);
    }
  }

  function importProject(): void {
    ZipImportApi.importProject(folder)
      .then((response: any) => {
        folder.id = response.id
        navigate(`/settings/zip-file-import/import`, {state: {folder}})
      });
  }

  function onFileDelete(): void {
    setFolder(null);
  }

  const handleDragOver = (): void => {
    setIsHover(true);
  }

  const handleDragLeave = (): void => {
    setIsHover(false);
  }

  function editImport(id: number): void {
    navigate(`/settings/zip-file-import/import?id=${id}`)
  }

  function openConfirmationDialog(id: number): void {
    setConfirmationDialog({
      visible: true,
      okText: 'Delete',
      id: id,
      title: 'Delete Import',
      titleSec: 'You\'re about to delete this ZIP Import',
      description: <>
        Continuing will remove the ZIP Import from this
        list.
      </>
    });
  }

  function onConfirmationDialogConfirm(props: any): void {
    if (props.modal) {
      deleteImport(props.id);
      onCancel();
    }
  }

  function onCancel(): void {
    setConfirmationDialog({visible: false});
  }

  function deleteImport(id: number): void {
    ZipImportApi.delete(id)
      .then(() => {
        getImportedProjects();
      });
  }

  function getImportedProjects(): void {
    ZipImportApi.get()
      .then((response: any) => {
        setLoader(false);
        setImportedProjects(response);
      }, () => {
        setLoader(false);
      });
  }
  

  return (
    <div className="zip-import">
      {loader ? <Loader/>
        :
        <div className="google-drive-integration">
          <div className="header row">
            <div className="column align-items-center back-button">
              <button className="icon-button back" onClick={() => navigateBack()}>
                <SvgArrowBack/>
              </button>
            </div>
            <div className="column">
              <div className="row align-items-center">
                <div className="d-flex logo">
                  <SvgZip/>
                </div>
                <h2>ZIP file</h2>
              </div>
              <div className="row">
                  <span className="description">
                    Bring your projects straight from a ZIP file in just a couple of clicks.
                  </span>
              </div>
            </div>
          </div>
          <div className="body">
            <div className="row justify-space-between">
              <div onDragOver={() => handleDragOver()}
                   onDragLeave={() => handleDragLeave()}
                   onDragEnd={() => handleDragLeave()}
                   className={folder ? 'upload-file-input uploaded' : isHover ? 'isHover upload-file-input' : 'upload-file-input'}>
                <Dragger  {...uploadProps}>
                  <div className="header row justify-space-between align-items-center">
                    <div className="info-text d-flex align-items-center">
                      <SvgUploadFile width={20} style={!folder ? {marginRight: 10} : {marginRight: 10, opacity: 0}}/>
                      <span>{!folder ? 'Drop your file here to upload' : `${folder?.folderName}.zip`} </span>
                    </div>
                    {folder &&
                      <button className="icon-button" onClick={onFileDelete}>
                        <SvgCancel/>
                      </button>
                    }
                  </div>
                </Dragger>
              </div>
              {folder &&
                  <Button type="primary" style={{marginLeft: 10, marginTop: 5}}
                          onClick={importProject}>
                      Import {folder.projectsNumber} projects
                  </Button>
              }
              {!folder &&
                  <Upload {...uploadProps} className="upload-file-button" multiple={false} openFileDialogOnClick={true}>
                      <Button type="primary"
                              loading={isUploading}
                              style={{
                                marginLeft: 10,
                                marginTop: 5
                              }}>{isUploading ? `${Math.trunc(uploadPercentage)} %` : `Browse`}</Button>
                  </Upload>
              }
            </div>
            {onlyZipMessage && <span style={{color: '#e53251'}}>Only ZIP file types are allowed to be imported</span>}

            <div className="table" style={{marginTop: 60}}>
              <Table columns={columns}
                     pagination={false}
                     dataSource={importedProjects}
                     rowKey="id"/>
            </div>
          </div>
        </div>
      }
      {confirmationDialog.visible &&
          <ConfirmationDialog data={confirmationDialog}
                              onConfirm={onConfirmationDialogConfirm}
                              onCancel={onCancel}/>}
    </div>
  );
}
