import React, { useEffect, useState } from 'react';
import {
  IFlagType
} from '../../../../../pages/settings/settings-tabs/interview-labs-settings/model/flag-type.interface';
import './flags-drop-down.scss';
import SvgCheck from '../../../../../assets/icons/js/check';
import FlagIconsPipe from '../../../../helpers/flag-icons-pipe';

export function FlagsDropDown(props: any): JSX.Element {
  const [flags, setFlags] = useState<IFlagType[]>([]);

  useEffect(() => {
    const selectedFlags: IFlagType[] = [...props?.selectedFlags];
    let flags: IFlagType[] = JSON.parse(JSON.stringify(props?.flags));

    flags = flags?.map((flag: IFlagType) => ({...flag, checked: false}))

    if (selectedFlags?.length >= 1) {
      selectedFlags?.forEach((selectedFlag: IFlagType) => {
        const foundFlag = flags.findIndex((flag: IFlagType) => flag.id === selectedFlag.id);
        if (foundFlag >= 0) {
          flags[foundFlag].checked = true;
        }
      });

      setFlags(flags);
    } else {
      flags = flags?.map((flag: IFlagType) => ({...flag, checked: false}))
      setFlags(flags ? flags : []);
    }
  }, []);

  function toggleCheckFlag(flag: IFlagType): void {
    const newFlags: IFlagType[] = JSON.parse(JSON.stringify(flags));

    newFlags.find((t: IFlagType, i: number) => {
      if (t.id === flag.id) {
        t.checked = !flag.checked
        return t;
      }
    });

    setFlags(newFlags.slice());
    const selectedTasks: any[] = newFlags.filter((f: IFlagType) => f.checked === true);
    props?.assignFlagsToSelectedText && props?.assignFlagsToSelectedText(selectedTasks);
  }

  return (
    <div className="flags-drop-down">
      {flags?.map((flag: IFlagType, index: number) => {
        return (
          <div className="flag-option" key={index} onClick={() => toggleCheckFlag(flag)}>
            {flag.checked ? <div className="checked">
                <SvgCheck width={20} height={20} color="#1b87e6"/>
              </div>
              :
              <div className="square"/>
            }
            <div className="text">
              <FlagIconsPipe width={19} height={19}
                             icon={flag?.icon && flag?.icon}
                             color={flag?.color && flag?.color}/>
              {flag.name}
            </div>
          </div>
        )
      })}
    </div>
  );
}
