import React from 'react';
import { Loader } from '../../../../../_shared/components/loader/loader';
import './slack-conversation.scss';
import MyProjectApi from "../../../api/project-api";
import SvgSlackLogo from '../../../../../assets/icons/js/slack-logo';
import SvgPeople from '../../../../../assets/icons/js/people';
import { Tooltip } from 'antd';
import { ISlackMember } from '../../../models/slack-info-members.interface';
import { SlackMembers } from './slack-members/slack-members';
import FormattedDate from '../../../../../_shared/helpers/formatted-date';

export class SlackConversation extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      loader: true,
      slackConversation: {
        channelName: '',
        messages: [],
        teamMembers: []
      },
      membersDialog: {
        visible: false,
      }
    }
  }

  componentDidMount() {
    this.loadMembersAndChannelInfo();
    this.loadPage();
  }

  sortByDate(): void {
    const {slackConversation} = this.state;

    if (slackConversation?.messages?.length) {
      let sortedMessages: any = {}

      slackConversation.messages.forEach((games: any) => {
        const date = games.createdAt.substr(0, 10)
        if (sortedMessages[date]) {
          sortedMessages[date].push(games);
        } else {
          sortedMessages[date] = [games];
        }
      })

      const messages = Object.entries(sortedMessages);
      this.setState({
        ...this.state,
        messages: messages
      });
    }
  }

  openMembersDialog(): void {
    this.setState({
      ...this.state,
      membersDialog: {
        ...this.state.membersDialog,
        visible: true
      }
    })
  }

  onCancel = () => {
    this.setState({
      ...this.state,
      membersDialog: {
        ...this.state.membersDialog,
        visible: false
      }
    });
  }

  private loadPage(): void {
    MyProjectApi.getSlackConversation(this.props.project.id)
      .then((response) => {
        this.setState({
          loader: false,
          slackConversation: response,
          membersDialog: {
            ...this.state.membersDialog,
            channelName: response.channelName
          }
        });

        this.sortByDate();
      });
  }

  private loadMembersAndChannelInfo(): void {
    MyProjectApi.getSlackInfoAndMembers(this.props.project.id)
      .then((response) => {
        this.setState({
          ...this.state,
          slackChannelInfo: response,
          membersDialog: {
            ...this.state.membersDialog,
            slackChannelInfo: response
          }
        });
      });
  }

  render() {
    const {loader, slackConversation, slackChannelInfo} = this.state;
    const messages = this.state.messages && this.state.messages;

    const members = () => {
      return (
        <div className="members-tooltip">
          <span className="view-all-members">View all members - {slackChannelInfo?.members?.length}</span>
          <div className="column">
            {slackChannelInfo.members && slackChannelInfo.members.map((member: ISlackMember, i: string) => {
              return (
                <span key={i} className="slack-channel-user">
                        {member.realName.split(' ')[0]}
                      </span>
              )
            })}
          </div>
        </div>
      )
    }

    return (
      <>
        {loader ? <Loader/>
          :
          <div className="slack-conversation">
            <div className="slack-channel row justify-space-between align-items-center">
              <div className="column">
                <div className="logo-name row">
                  {slackConversation &&
                      <>
                          <SvgSlackLogo width={16}/>
                          <span>#{slackConversation.channelName}</span>
                      </>
                  }
                </div>
                <div className="slack-channel-info row">
                  <img className="slack-channel-logo" src={slackChannelInfo?.logo} alt={slackChannelInfo?.name}/>
                  <span className="slack-name">{slackChannelInfo?.name}</span>
                  <span className="slack-url">{slackChannelInfo?.url}.slack.com</span>
                </div>
              </div>
              <div className="column">
                <Tooltip placement="bottom" title={members}>
                  <div className="members" onClick={() => this.openMembersDialog()}>
                    <SvgPeople/>
                    <span>{slackChannelInfo?.members?.length}</span>
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="slack-chat-container column">
              {messages && messages.map((msn: any, i: number) => {
                return (
                  <div key={i} className="chat-block">
                    <div className="day-date">
                      <FormattedDate className="date" date={msn[0]} formatString="MMM d, yyyy"/>
                    </div>
                    {msn[1] && msn[1].map((message: any, i: number) => {
                      return (
                        <div key={i} className="message-block">
                          <div className="name-date">
                            <span className="name">{message.fullName}</span>
                            <FormattedDate date={message.createdAt} formatString="hh:mm a - MMM d, yyyy"/>
                          </div>

                          <div className="message">
                            {message.message}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
              <div className="end-message">
                This is the end of the feed. You are all caught up!
              </div>
            </div>
          </div>
        }

        {this.state.membersDialog.visible &&
            <SlackMembers data={this.state.membersDialog} onCancel={this.onCancel}/>}
      </>
    );
  }
}
