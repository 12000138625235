import * as React from "react";

const SvgHome = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="home">
        <path
          d="M8,1 L15,8 L13,8 L13,14 C13,14.5522847 12.5522847,15 12,15 L10,15 L10,11 L6,11 L6,15 L4,15 C3.44771525,15 3,14.5522847 3,14 L3,8 L1,8 L8,1 Z"
          id="Path"
          fill={props.color ? props.color : '#545E6B'}/>
      </g>
    </svg>
  );
}

export default SvgHome;
