// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ask-ai {
  padding: 0 20px;
}
.ask-ai .header {
  padding: 28px 0 15px 0;
}
.ask-ai .email {
  color: #1B87E6;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/ask-ai/ask-ai.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,sBAAA;AACJ;AAEE;EACE,cAAA;AAAJ","sourcesContent":[".ask-ai {\n  padding: 0 20px;\n\n  .header {\n    padding: 28px 0 15px 0;\n  }\n\n  .email {\n    color: #1B87E6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
