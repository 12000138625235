import React, { useState } from 'react';
import storiesApi from "../../api/stories-api";
import { Empty, message, Table, Tooltip } from "antd";
import SvgTick from "../../../../assets/icons/js/Tick";
import SvgAlert from "../../../../assets/icons/js/alert";
import { ColumnsType } from "antd/lib/table";
import { IStory } from "../../model/story-interface";
import FormattedDate from "../../../../_shared/helpers/formatted-date";
import SvgRestoreTrash from "../../../../assets/icons/js/RestoreTrash";
import SvgTrash from "../../../../assets/icons/js/Trash";
import { ConfirmationDialog } from "../../../../_shared/components/confirmation-dialog/confirmation-dialog";
import { Loader } from "../../../../_shared/components/loader/loader";
import StoryTile from "../story-tile/story-tile";
import StoriesApi from "../../api/stories-api";
import { useSearchParams } from "react-router-dom";
import { PageViewEnum } from "../../model/page-view.enum";
import Pagination from "../../../../_shared/components/pagination/pagination";
import { useQuery } from "react-query";
import { queryClient } from "../../../../index";

type ArchivedStoriesViewProps = {
  view: PageViewEnum
}

const ArchivedStoriesView = ({view}: ArchivedStoriesViewProps) => {
  const columns: ColumnsType<any> = [
    {
      title: "Story title",
      dataIndex: "title",
      key: "title",
      render: (value: string, record: IStory) => {
        return <div key={value}
                    className=''>{value?.length ? value : "Untitled"}</div>
      }
    },
    {
      title: "Owner",
      dataIndex: "createdBy",
      key: "createdBy"
    },
    {
      title: "Last modified",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: string) => {
        return <FormattedDate date={value} formatString="MMM d, yyyy"/>
      }
    },
    {
      title: "",
      dataIndex: "id",
      key: "x",
      width: 45,
      align: "right",
      render: (id: number, story: IStory) => (
        <div className="action-hover-column">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Restore">
              <button className="icon-button" data-testid={"restore-button"} onClick={() => restoreStory(id)}>
                <SvgRestoreTrash/>
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title="Delete">
              <button className="icon-button" data-testid={"delete-button"}
                      onClick={() => openDeleteConfirmationDialog(story)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ]

  const [params, setParams] = useSearchParams()
  const page = Number(params.get('page')) || 1;
  const pageSize = Number(params.get('pageSize')) || 10;
  const archivedStories = useQuery(['archivedStories', page, pageSize], () => StoriesApi.getArchivedStories({
    page,
    pageSize
  }));

  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<any>({visible: false, id: null});
  const restoreStory = (storyId: number | undefined) => {
    if (storyId) {
      storiesApi.restoreStory(storyId).then(() => {
        archivedStories.refetch()
        queryClient.invalidateQueries(["storiesCounts"])
        message.success({
          content: 'Story restored successfully!',
          className: 'custom-success-message',
          icon: <SvgTick color={"#227700"}/>
        })
      }).catch(error => {
        message.error({
          content: 'Error restoring story!',
          className: 'custom-error-message',
          icon: <SvgAlert color={"#CC0000"}/>
        })
      })
    }
  }

  const openDeleteConfirmationDialog = (story?: Partial<IStory>) => {
    setDeleteConfirmationDialog({
      visible: true,
      id: story?.id,
      okText: 'Delete',
      title: 'Delete',
      titleSec: 'You\'re about to delete this story permanently',
      content: `${story?.title || 'Untitled'}`,
      description: <>
        Continuing will remove the story from this list. You can't restore it after deletion.
      </>
    });
  }

  const onDialogCancel = () => {
    setDeleteConfirmationDialog({visible: false})
  }

  const renderGridStories = () => {
    if (archivedStories.isLoading) {
      return <Loader/>;
    } else if (
      archivedStories.data &&
      archivedStories.data.pagination.totalElements === 0
    ) {
      return <div data-testid="no-data" className="w-100 column justify-space-center">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
      </div>;
    } else if (archivedStories.data) {
      return archivedStories.data.content.map((story, index) => (
        <StoryTile
          key={index}
          fromProjectEdit={false}
          openArchiveConfirmationDialog={() => {
          }}
          openDeleteConfirmationDialog={openDeleteConfirmationDialog}
          restoreStory={restoreStory}
          navigateToStory={() => {
          }}
          story={story}
        />
      ));
    }
    return null;
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setParams((prevParams) => {
      prevParams.set('page', page.toString());
      prevParams.set('pageSize', pageSize.toString());

      return prevParams;
    })
  }

  const onDeleteConfirmationDialog = (props: any) => {
    if (props.modal) {
      deleteStory(props.id);
      onDialogCancel();
    }
  }

  const deleteStory = (storyId: number | undefined) => {
    if (storyId) {
      storiesApi.deleteStory(storyId).then(() => {
        archivedStories.refetch();
        queryClient.invalidateQueries(["storiesCounts"])
        message.success({
          content: 'Story deleted successfully!',
          className: 'custom-success-message',
          icon: <SvgTick color={"#227700"}/>
        })
      }).catch(error => {
        message.error({
          content: 'Error deleting story!',
          className: 'custom-error-message',
          icon: <SvgAlert color={"#CC0000"}/>
        })
      })
    }
  }

  return (
    <div>
      <div>
        <div className="row justify-space-end">
          {
            archivedStories.data && archivedStories.data?.pagination.totalElements > 0 &&
            <Pagination pageSize={pageSize}
                        currentPage={page}
                        showSizeChanger
                        onChange={handlePaginationChange}
                        totalItems={archivedStories.data?.pagination.totalElements}/>
          }
        </div>
        <div className="stories">
          {
            view === PageViewEnum.GRID ?
              renderGridStories() :
              <Table
                columns={columns}
                rowKey={"id"}
                loading={archivedStories.isLoading}
                dataSource={archivedStories.data?.content}
                pagination={false}
                style={{width: "100%"}}/>
          }
        </div>
      </div>

      {
        deleteConfirmationDialog.visible &&
        <ConfirmationDialog data={deleteConfirmationDialog}
                            onConfirm={onDeleteConfirmationDialog}
                            onCancel={onDialogCancel}/>
      }
    </div>
  );
};

export default ArchivedStoriesView;
