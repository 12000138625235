import { Select } from "antd";
import SvgArrowDown from "../../../../assets/icons/js/ArrowDown";
import React, { useEffect, useRef, useState } from "react";
import { DraggableColumns } from "./draggable-columns";
import {
  ICustomizeColumnTable,
  ICustomizeKeyMetricTable,
  ICustomizeProgramTable,
  ICustomizeTable
} from "../../models/customize-table.interface";

type ComponentType = {
  data: ICustomizeTable,
  onChange: (data: any) => void
}

export function CustomizeTableProgramMetrics({data, onChange}: ComponentType): JSX.Element {
  const isFirstUpdate = useRef(true);

  const programs = data.programs;

  const [columns, setColumns] = useState<any>();
  const [selectedProgram, setSelectedProgram] = useState<ICustomizeProgramTable | undefined>(programs.find(program => program.selected));

  useEffect(() => {
    const programFound = programs?.find(program => program.id === selectedProgram?.id);


    if (!programFound || !programFound.groups) {
      setColumns([{name: "Project", selected: true}]);
      return;
    }

    const newColumns: ICustomizeKeyMetricTable | any = [
      {name: "Project", selected: true},
      ...programFound.groups
    ];

    setColumns(newColumns);

    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }

    updateOnChange(newColumns);
  }, [selectedProgram]);

  const columnsChanged = (newColumns: ICustomizeColumnTable[]): void => {
    setColumns(newColumns);
    updateOnChange(newColumns);
  }


  // const filteredColumns = columns ? [...columns]?.filter((column: any) => !(column.name === 'Project' && !column.id)) : [];

  // const newPrograms = [...programs].map((program) => {
  //   if (program.id === selectedProgram?.id) {
  //     return {
  //       ...program,
  //       selected: true,
  //       groups: filteredColumns
  //     };
  //   } else {
  //     return {...program, selected: false};
  //   }
  // });

  const updateOnChange = (currentColumns: ICustomizeColumnTable[]) => {
    const filteredColumns = currentColumns ? [...currentColumns]?.filter((column: any) => !(column.name === 'Project' && !column.id)) : [];

    const newPrograms = [...programs].map((program) => {
      if (program.id === selectedProgram?.id) {
        return {
          ...program,
          selected: true,
          groups: filteredColumns
        };
      } else {
        return {...program, selected: false};
      }
    });
    onChange({programs: newPrograms});
  }

  return (
    <>
      <div style={{marginBottom: 20}}>
        <Select value={selectedProgram?.id}
                style={{width: 200}}
                placeholder={"Select a program"}
                suffixIcon={<SvgArrowDown/>}
                onChange={(value) => setSelectedProgram(programs.find(program => program.id === value))}>
          {
            programs && programs.map((program: ICustomizeProgramTable) => {
              return (
                <Select.Option value={program.id} key={program.id}>
                  {program.name}
                </Select.Option>
              )
            })
          }
        </Select>
      </div>

      <DraggableColumns columns={columns}
                        onColumnsChanged={(newColumns: ICustomizeColumnTable[]) => columnsChanged(newColumns)}/>
    </>
  );
}
