const generateRandomIcon = () => {
  const icons = require("../../assets/bootstrap-icons/bootstrap-icons.json")
  const colors: string[] = ['#1B3380', '#1B87E6', '#80EAD9', '#6AD986', '#E9E96C', '#EBB15F', '#F83F73', '#F458A4', '#E549C5', '#CC0092'];

  const generateRandomIconWithColor = () => {
    const randomColor = Math.floor(Math.random() * colors.length);
    const randomIcon = Math.floor(Math.random() * Object.keys(icons).length);

    return {
      color: colors[randomColor],
      icon: Object.keys(icons)[randomIcon]
    };
  }

  return generateRandomIconWithColor();
}

export default generateRandomIcon
