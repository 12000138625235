import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import { getFigureSelectionByEntityMapId } from "./entity";

export function insertIframeEntity(
  editorState: EditorState,
  data: { url: string; entityKey?: string; entityMapId?: number },
  isDelete?: boolean
) {
  if (isDelete && data?.entityKey && data?.entityMapId) {
    const selectionState: any = getFigureSelectionByEntityMapId(
      editorState,
      data.entityMapId
    );

    const newContentState = Modifier.applyEntity(
      editorState.getCurrentContent(),
      selectionState,
      null
    );

    return EditorState.push(editorState, newContentState, "apply-entity");
  } else {
    const entityKey = editorState
      .getCurrentContent()
      .createEntity("IFRAME", "MUTABLE", {
        url: data.url,
        entityMapId: Math.floor(100000 + Math.random() * 900000),
      })
      .getLastCreatedEntityKey();

    const newEditorState: EditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );
    return newEditorState;
  }
}
