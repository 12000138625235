import React from 'react';
import './story-tile.scss'
import SvgNoImage from "../../../../assets/icons/js/noImage";
import FormattedDate from "../../../../_shared/helpers/formatted-date";
import { IStory } from "../../model/story-interface";
import { Dropdown, Menu } from "antd";
import SvgEdit from "../../../../assets/icons/js/Edit";
import SvgTrash from "../../../../assets/icons/js/Trash";
import { StoryStatusEnum } from "../../model/story-status.enum";
import SvgRestoreTrash from "../../../../assets/icons/js/RestoreTrash";
import { IProject } from "../../../projects/models/project.interface";

type StoryTileCommonProps = {
  story: IStory;
  navigateToStory: (id: number, archived?: boolean) => void;
  openArchiveConfirmationDialog: (story: Partial<IStory>) => void;
}

type StoryTileConditionalProps = {
  fromProjectEdit: true;
} | {
  fromProjectEdit: false;
  openDeleteConfirmationDialog: (story: Partial<IStory>) => void;
  restoreStory: (id: number | undefined) => void;
}

type StoryTileProps = StoryTileCommonProps & StoryTileConditionalProps;


const StoryTile = (props: StoryTileProps) => {
  const {story, navigateToStory} = props

  return <div className='story-tile' data-testid={"story-tile"}>
    <div className='story-tile-image-wrapper pointer' onClick={() => navigateToStory(story.id, story.archived)}>
      {
        story.cover ?
          <img className='story-tile-image'
               src={story.cover}
               alt="story-image"/> :
          <div data-testid="no-image">
            <SvgNoImage/>
          </div>
      }
    </div>
    <div className='story-tile-info'>
      <div className='story-tile-title' onClick={() => {
        navigateToStory(story.id, story.archived)
      }}>
        {story.title.length > 0 ? story.title : "Untitled"}
      </div>
      <div>
        <div className="row justify-space-between">
          <div className='story-tile-date'>
            {
              story?.createdAt &&
                <FormattedDate date={story.createdAt} formatString="MMM d, yyyy"/>
            }

          </div>
          {
            !!props?.fromProjectEdit && story.status === StoryStatusEnum.DRAFT &&
            <div className="story-tile-draft">
              Draft
            </div>
          }
        </div>

        <div className="row w-100 justify-space-between">
          <span className='story-tile-creator justify-space-between'>
            {story.createdBy}
          </span>
          <Dropdown trigger={["click"]} overlay={
            <Menu>
              {
                story.archived && !props.fromProjectEdit ?
                  <>
                    <Menu.Item key={"restore"} onClick={() => props.restoreStory(story.id)}>
                      <div className=" snapshot-icon-button d-flex align-items-center">
                        <SvgRestoreTrash/> Restore
                      </div>
                    </Menu.Item>
                    <Menu.Item key={"delete"} onClick={() => props.openDeleteConfirmationDialog(story)}>
                      <div className=" snapshot-icon-button d-flex align-items-center">
                        <SvgTrash/> Delete
                      </div>
                    </Menu.Item>
                  </> :
                  <>
                    <Menu.Item key={"edit"} onClick={() => navigateToStory(story.id)}>
                      <div className=" snapshot-icon-button d-flex align-items-center">
                        <SvgEdit/> Edit
                      </div>
                    </Menu.Item>
                    <Menu.Item key={"delete"} onClick={() => props.openArchiveConfirmationDialog(story)}>
                      <div className=" snapshot-icon-button d-flex align-items-center">
                        <SvgTrash/> Archive
                      </div>
                    </Menu.Item>
                  </>
              }
            </Menu>
          }>
            <div className="pointer" data-testid={"dropdown-tile"}>
              <i className="bi bi-three-dots" style={{fontSize: 18}}/>
            </div>
          </Dropdown>
        </div>

      </div>
    </div>
  </div>
};

export default StoryTile;
