// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-up-tutorial .checkbox-wrapper {
  color: #545e6b;
  font-weight: 400;
}
.pop-up-tutorial .ant-modal-close-x {
  color: #ffffff;
}
.pop-up-tutorial .ant-modal-header {
  background-color: #123485;
  justify-content: flex-start;
  padding: 15px;
}
.pop-up-tutorial .ant-modal-header .ant-modal-title {
  color: #ffffff;
}
.pop-up-tutorial .pop-up-tutorial-wrapper .pop-up-tutorial-title {
  color: #545e6b;
  font-size: 28px;
  margin-bottom: 10px;
}
.pop-up-tutorial .pop-up-tutorial-wrapper p {
  color: #545e6b;
}
.pop-up-tutorial .pop-up-tutorial-wrapper .pop-up-tutorial-video {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/pop-up-tutorial/pop-up-tutorial.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,gBAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,yBAAA;EACA,2BAAA;EACA,aAAA;AAFJ;AAII;EACE,cAAA;AAFN;AAOI;EACE,cAAA;EACA,eAAA;EACA,mBAAA;AALN;AAQI;EACE,cAAA;AANN;AASI;EACE,aAAA;EACA,uBAAA;AAPN","sourcesContent":[".pop-up-tutorial {\n  .checkbox-wrapper {\n    color: #545e6b;\n    font-weight: 400;\n  }\n\n  .ant-modal-close-x {\n    color: #ffffff;\n  }\n\n  .ant-modal-header {\n    background-color: #123485;\n    justify-content: flex-start;\n    padding: 15px;\n\n    .ant-modal-title {\n      color: #ffffff;\n    }\n  }\n\n  .pop-up-tutorial-wrapper {\n    .pop-up-tutorial-title {\n      color: #545e6b;\n      font-size: 28px;\n      margin-bottom: 10px;\n    }\n\n    p {\n      color: #545e6b;\n    }\n\n    .pop-up-tutorial-video {\n      display: flex;\n      justify-content: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
