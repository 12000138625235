// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snapshots-dialog-wrapper .no-snapshots-wrapper {
  display: flex;
  justify-content: center;
  color: #545E6B;
}
.snapshots-dialog-wrapper .snapshots-dialog-info {
  color: #545E6B;
  width: 60%;
  font-weight: 500;
}
.snapshots-dialog-wrapper .snapshots-dialog-info .snapshots-dialog-number {
  font-size: 12px;
}
.snapshots-dialog-wrapper .snapshots-dialog-info .snapshots-dialog-number span {
  font-size: 14px;
}
.snapshots-dialog-wrapper .snapshots-dialog-info .snapshots-dialog-nugget-description {
  font-size: 14px;
  margin-top: 10px;
}
.snapshots-dialog-wrapper .snapshots-dialog-snapshots-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.snapshots-dialog-wrapper .snapshots-dialog-snapshots-wrapper .snapshots-dialog-snapshots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 20px;
}
.snapshots-dialog-wrapper .snapshots-dialog-snapshots-wrapper .snapshots-dialog-snapshots .template-download-button {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/nuggets/snapshots-dialog/snapshots-dialog.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,cAAA;AAAJ;AAGE;EACE,cAAA;EACA,UAAA;EACA,gBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAGM;EACE,eAAA;AADR;AAKI;EACE,eAAA;EACA,gBAAA;AAHN;AAOE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AALJ;AAOI;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AALN;AAOM;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AALR","sourcesContent":[".snapshots-dialog-wrapper {\n  .no-snapshots-wrapper {\n    display: flex;\n    justify-content: center;\n    color: #545E6B;\n  }\n\n  .snapshots-dialog-info {\n    color: #545E6B;\n    width: 60%;\n    font-weight: 500;\n\n    .snapshots-dialog-number {\n      font-size: 12px;\n\n      span {\n        font-size: 14px;\n      }\n    }\n\n    .snapshots-dialog-nugget-description {\n      font-size: 14px;\n      margin-top: 10px;\n    }\n  }\n\n  .snapshots-dialog-snapshots-wrapper {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    overflow: hidden;\n\n    .snapshots-dialog-snapshots {\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n      margin-top: 40px;\n      gap: 20px;\n\n      .template-download-button {\n        position: absolute;\n        top: 5px;\n        right: 8px;\n        font-size: 16px;\n        color: #fff;\n        cursor: pointer;\n        z-index: 999;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
