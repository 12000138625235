// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blocks-menu-wrapper {
  background-color: white;
  width: 162px;
  z-index: 9;
  display: flex;
  padding-bottom: 2px;
  padding-top: 2px;
  flex-direction: column;
  gap: 2px;
  left: 15px;
  position: absolute;
  border: 1px solid rgb(27, 135, 230);
}
.blocks-menu-wrapper .blocks-menu-divider {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(217, 217, 217);
  width: 144px;
  min-width: 0;
  margin-left: 8px;
}
.blocks-menu-wrapper .use-ai-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #545e6b;
  margin-left: 8px;
}
.blocks-menu-wrapper:focus {
  outline-width: 0;
}
.blocks-menu-wrapper .menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 8px;
  height: 32px;
  background-color: #ffffff;
}
.blocks-menu-wrapper .menu-item:hover {
  background-color: #F5F5F5 !important;
}
.blocks-menu-wrapper .menu-item .menu-item-icon {
  font-size: 18px;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blocks-menu-wrapper .menu-item .menu-item-text {
  font-size: 12px;
  font-weight: 400;
  color: #545e6b;
  width: 90%;
}
.blocks-menu-wrapper .active-button .menu-item-text {
  color: #0a86ea;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/story-editor/components/blocks-menu/blocks-menu.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,QAAA;EACA,UAAA;EACA,kBAAA;EACA,mCAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;EACA,oCAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;AAFJ;AAII;EACE,oCAAA;AAFN;AAKI;EACE,eAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHN;AAMI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,UAAA;AAJN;AASI;EACE,cAAA;AAPN","sourcesContent":[".blocks-menu-wrapper {\n  background-color: white;\n  width: 162px;\n  z-index: 9;\n  display: flex;\n  padding-bottom: 2px;\n  padding-top: 2px;\n  flex-direction: column;\n  gap: 2px;\n  left: 15px;\n  position: absolute;\n  border: 1px solid rgba(27, 135, 230, 1);\n\n  .blocks-menu-divider {\n    margin-top: 5px;\n    margin-bottom: 5px;\n    background-color: rgba(217, 217, 217, 1);\n    width: 144px;\n    min-width: 0;\n    margin-left: 8px;\n  }\n\n  .use-ai-label {\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 21px;\n    text-align: left;\n    color: #545e6b;\n    margin-left: 8px;\n  }\n\n  &:focus {\n    outline-width: 0;\n  }\n\n  .menu-item {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    cursor: pointer;\n    padding: 8px;\n    height: 32px;\n    background-color: #ffffff;\n\n    &:hover {\n      background-color: #F5F5F5 !important;\n    }\n\n    .menu-item-icon {\n      font-size: 18px;\n      width: 10%;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n\n    .menu-item-text {\n      font-size: 12px;\n      font-weight: 400;\n      color: #545e6b;\n      width: 90%;\n    }\n  }\n\n  .active-button {\n    .menu-item-text {\n      color: #0a86ea;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
