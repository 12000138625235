import { BlockMap, CharacterMetadata, EditorState } from 'draft-js';

export function manageHighlights(editorState: EditorState): void {
  const contentState = editorState.getCurrentContent();
  const blockMap: BlockMap = contentState.getBlockMap();

  blockMap.map((block: any) => {
    block.findEntityRanges((character: CharacterMetadata) => {
      const entityKey = character.getEntity();
      const foundElement = document.getElementById(entityKey);
      let elementPosition = null;

      if (!foundElement) {
        return;
      }

      elementPosition = foundElement.getBoundingClientRect();

      if (!entityKey || !elementPosition) {
        return;
      }

      const entityType: string = contentState.getEntity(entityKey).getType();

      switch (entityType) {
        case 'mention':
          const mentionContent = contentState.getEntity(entityKey).getData().mention;
          mentionContent.position = elementPosition;
          break;
        case 'NODE':
          const nodeContent = contentState.getEntity(entityKey).getData().nodes;
          nodeContent.selectedText = foundElement?.innerText;
          nodeContent.position = elementPosition;
          break;
        case 'FLAG':
          const flagContent = contentState.getEntity(entityKey).getData();

          if (foundElement?.innerText?.length) {
            flagContent.selectedText = foundElement?.innerText;
          }
          const position: any = elementPosition;
          // position.y + 1 because of flag style border: 1px
          position.y = position.y + 1;
          flagContent.position = position;
          break;
        case 'EVIDENCE':
          const evidenceContent = contentState.getEntity(entityKey).getData();
          if (foundElement?.innerText?.length) {
            evidenceContent.selectedText = foundElement?.innerText;
          }
          evidenceContent.position = elementPosition;
          break;
        case 'TASK':
          const taskContent = contentState.getEntity(entityKey).getData();
          if (foundElement?.innerText?.length) {
            taskContent.selectedText = foundElement?.innerText;
          }
          taskContent.position = elementPosition;
          break;
      }
    });
  });
}
