import React, { useEffect, useRef, useState } from 'react';
import './knowledge-node.scss';
import { Dropdown, Menu } from 'antd';
import { IKnowledgeNode } from '../../model/knowledge-node.interface';
import KnowledgeApi from '../../api/knowledge-api';
import SvgAdd from '../../../../assets/icons/js/add';
import SvgArrowDown from '../../../../assets/icons/js/ArrowDown';
import SvgArrowUp from '../../../../assets/icons/js/ArrowUp';
import SvgEdit from '../../../../assets/icons/js/Edit';
import SvgMore from '../../../../assets/icons/js/More';
import SvgTrash from '../../../../assets/icons/js/Trash';
import SvgArrowDownNoFill from '../../../../assets/icons/js/ArrowDownNoFill';
import { usePrevious } from '../../../../core/hooks/use-previous';
import qs from "query-string";
import { useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { DebounceInput } from "react-debounce-input";

type KnowledgeNodeProps = {
  index: number,
  prefix: string,
  graphId: number,
  isParent?: boolean,
  isSubNode: boolean,
  editEnable: boolean,
  deleteNode?: (index: number) => void,
  updateSaving: (value: boolean) => void,
  deleteSubNode?: (id: number, index?: number) => void
}

export default function KnowledgeNode(props: KnowledgeNodeProps): JSX.Element {
  const navigate = useNavigate();
  let nameInput: any = useRef();

  const formMethods = useFormContext();
  const indexedNode = props.prefix.substring(0, props.prefix.length - 1);
  const {fields, remove, append, update} = useFieldArray({
    control: formMethods.control,
    name: `${props.prefix}nodes`,
    keyName: 'key'
  });
  

  const [openSubNodes, setOpenSubNodes] = useState<boolean>(props.isParent ? true : !!getNodeProperties('open'));
  const [edit, setEdit] = useState<boolean>(false);
  const prevEditEnable = usePrevious(props.editEnable);
  useEffect(() => {
    const editNode = getNodeProperties('editNode')

    if (editNode) {
      toggleEdit();
      formMethods.setValue(`${indexedNode}.editNode`, false)
    }
  }, [])

  useEffect(() => {
    if (prevEditEnable !== props.editEnable) {
      if (!props.editEnable) {
        setEdit(false);
      }
    }
  });

  function getNodeProperties(indexName: string) {
    return formMethods.getValues(`${props.prefix}${indexName}`);
  }

  function onEnter(event: any): void {
    if (event.keyCode === 13 && event.shiftKey) {
      event.preventDefault();
      setEdit(false);
      addNewNode();
      return;
    }

    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      setEdit(false);
    }
  }

  function onNameChanged(e: any): void {
    const newNode: { name: string } = {
      name: e.target.value
    }

    props.updateSaving(true);

    KnowledgeApi.editNode(getNodeProperties('id'), newNode)
      .then(() => {
        props.updateSaving(false)
      });

    e.preventDefault && e.preventDefault();
  }

  function toggleEdit(): void {
    setEdit(!edit);
    if (!edit) {
      setTimeout(() => {
        nameInput.current && nameInput?.current.focus();
      }, 20)
    }
  }

  function toggleSubNodes(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    e.stopPropagation()
    if (getNodeProperties('hasChild') || getNodeProperties('nodes').length) {
      setOpenSubNodes(!openSubNodes);
    }
  }

  function addNewNode(): void {
    if (getNodeProperties('id')) {
      const newNode: any = {
        name: 'Sub-Item',
        parentId: getNodeProperties('id'),
        nodes: [],
      }

      append(newNode)

      props.updateSaving(true);

      KnowledgeApi.createNode(props.graphId, newNode)
        .then((response: IKnowledgeNode) => {
          const newNodes: IKnowledgeNode[] = getNodeProperties('nodes')
          const index = newNodes.findIndex((node) => !node.id);
          response.editNode = true
          update(index, response)
          //formMethods.setFocus(`${indexedNode}.nodes.${index}.name`)
          props.updateSaving(false);
        });
    }
  }

  function deleteNode(): void {
    if (!props.isSubNode) {
      props.updateSaving(true)
      KnowledgeApi.deleteNode(getNodeProperties('id'))
        .then(() => {
          props.updateSaving(false)
          !!props.deleteNode && props.deleteNode(props.index);
        });
    } else {
      !!props.deleteSubNode &&
      props.deleteSubNode(getNodeProperties('id'), props.index);
    }
  }

  function deleteSubNode(id: number, index?: number): void {
    props.updateSaving(true);
    KnowledgeApi.deleteNode(id)
      .then(() => {
        remove(index)
      });
  }

  function navigateToReadNode(id: number | undefined): void {
    if (id && !edit) {
      navigate({pathname: '/nodes/node', search: qs.stringify({id})});
    }
  }

  return (
    <div className={props.editEnable ? "knowledge-sub-node-wrapper remove-lines" : "knowledge-sub-node-wrapper"}
         style={{
           minWidth: props.isSubNode ? 134 : 164,
         }}>
      {
        !props.editEnable &&
        <div className='draw-arrow'>
          <SvgArrowDownNoFill/>
        </div>
      }
      <div className="knowledge-sub-node"
           style={openSubNodes && props.isParent
             ? {backgroundColor: !edit ? '#7dc4f6' : "", borderBottomRightRadius: 0, borderBottomLeftRadius: 0}
             : {}}>
        <div
          data-testid={"navigate-div"}
          className={"title pointer"}
          onClick={() => navigateToReadNode(getNodeProperties('id'))}>
          {
            edit && props.editEnable
              ? <Controller control={formMethods.control}
                            name={`${props.prefix}name`}
                            rules={{onChange: e => onNameChanged(e)}}
                            render={({field: {ref, ...field}}) =>
                              <DebounceInput
                                className="outline-input"
                                debounceTimeout={600}
                                style={openSubNodes && props.isParent
                                  ? {backgroundColor: '#eeeeee'}
                                  : {}}
                                placeholder="New node"
                                autoComplete="off"
                                disabled={!edit && !props.editEnable}
                                onKeyDown={(e: any) => onEnter(e)}
                                onClick={(e: any) => e.stopPropagation()}
                                inputRef={nameInput}
                                {...field}
                              />}/>
              : <span style={{paddingRight: props.editEnable ? 34 : 10}}
                      className="knowledge-sub-node-name">{getNodeProperties('name')}</span>
          }
          {getNodeProperties('nodes')?.length ?
            <div className="open-subNodes-button" data-testid={'toggle-subnodes'} onClick={toggleSubNodes}
                 style={{right: props.editEnable ? 30 : 5}}>
              {
                openSubNodes ?
                  <SvgArrowUp width={8} height={8}/> :
                  <SvgArrowDown width={8}
                                height={8}/>
              }
            </div>
            :
            <></>
          }
        </div>
        {props.editEnable &&
            <div className="knowledge-sub-node-more"
                 onClick={(e: { preventDefault: () => any; }) => e.preventDefault()}>
                <Dropdown placement="bottom"
                          overlayClassName="knowledge-more-dropdown"
                          trigger={['click']}
                          overlay={
                            <Menu>
                              {
                                props.isParent && getNodeProperties('nodes')?.length < 20 &&
                                <Menu.Item key="1">
                                  <div className="menu-icons-knowledge-node icon-button d-flex align-items-center"
                                       onClick={() => addNewNode()}>
                                    <SvgAdd/> Add sub-item
                                  </div>
                                </Menu.Item>
                              }

                              <Menu.Item key="2">
                                <div className="menu-icons-knowledge-node icon-button d-flex align-items-center"
                                     onClick={() => deleteNode()}>
                                  <SvgTrash/> Delete
                                </div>
                              </Menu.Item>
                              <Menu.Item key="3">
                                <div className="menu-icons-knowledge-node icon-button d-flex align-items-center"
                                     onClick={() => toggleEdit()}>
                                  <SvgEdit/> Edit
                                </div>
                              </Menu.Item>
                            </Menu>
                          }>
                    <button className="icon-button pointer"
                            onClick={(e: { preventDefault: () => any; }) => e.preventDefault()}>
                        <SvgMore/>
                    </button>
                </Dropdown>
            </div>
        }
      </div>
      {openSubNodes &&
          <div className="children-nodes"
               style={props.isSubNode ? {borderWidth: '0'} : {borderWidth: '1px'}}>
              <div className={props.isParent ? "children-nodes-parent" : ""}>
                {fields && fields.map((node: any, i: number) => {
                  return (
                    <KnowledgeNode key={node.key}
                                   index={i}
                                   prefix={`${indexedNode}.nodes.${i}.`}
                                   graphId={props.graphId}
                                   isSubNode={true}
                                   editEnable={props.editEnable}
                                   deleteSubNode={deleteSubNode}
                                   updateSaving={props.updateSaving}/>
                  )
                })}
              </div>
          </div>
      }
    </div>
  );
}
