import { environment } from '../../../environment';

import { axiosClient } from '../../../axios-client';
import { AxiosResponse } from 'axios';
import { ISearchFilters } from "../../home/models/search-filters.interface";
import { IProgram } from "../../settings/settings-tabs/taxonomy/programs/model/program.interface";
import { IAnalyticsProjects } from "../models/analytics-project.interface";
import { IAnalyticsColumns } from "../models/analytics-columns.interface";
import { IAnalyticsHighlight } from "../models/analytics-highlight.interface";
import { IAnalyticsPricingModel } from "../models/analytics-pricing-model.interface";
import { IAnalyticsChart } from "../models/analytics-chart.interface";
import { IProgramMetricsProjects } from "../models/program-metrics-projects.interface";
import { IProgramMetricsColumn } from "../models/program-metrics-column.interface";
import IUsageMetrics from "../models/usage-metrics.interface";

class AnalyticsApi {
  baseUrl: string = `${environment.backendUrl}`;

  // projects
  async getProjects(queryParams: any): Promise<IAnalyticsProjects> {
    const response: AxiosResponse<IAnalyticsProjects> = await axiosClient.get<IAnalyticsProjects>(`/analytics/projects`, {params: queryParams});
    return response.data;
  }

  async getColumns(): Promise<IAnalyticsColumns> {
    const response: AxiosResponse<IAnalyticsColumns> = await axiosClient.get<IAnalyticsColumns>(`/analytics/columns`,);
    return response.data;
  }

  // async getHighlights(queryParams: any): Promise<IAnalyticsHighlight[]> {
  //   const response: AxiosResponse<IAnalyticsHighlight[]> = await axiosClient.get<IAnalyticsHighlight[]>(`/highlights`, {params: queryParams});
  //   return response.data;
  // }

  async getProjectsCount(queryParams: any): Promise<any> {
    const response: AxiosResponse<any> = await axiosClient.get<any>(`/analytics/projects-count`, {params: queryParams});
    return response.data;
  }

  async getPricingModels(queryParams: any): Promise<IAnalyticsPricingModel[]> {
    const response: AxiosResponse<IAnalyticsPricingModel[]> = await axiosClient.get<IAnalyticsPricingModel[]>(`/analytics/pricingmodels`, {params: queryParams});
    return response.data;
  }

  async getChart(queryParams: any): Promise<IAnalyticsChart[]> {
    const response: AxiosResponse<IAnalyticsChart[]> = await axiosClient.get<IAnalyticsChart[]>(`/analytics/chart`, {params: queryParams});
    return response.data;
  }

  async getFilters(): Promise<ISearchFilters> {
    try {
      const response: AxiosResponse<ISearchFilters> = await axiosClient.get<ISearchFilters>(
        `${this.baseUrl}/analytics/filters`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async exportExcel(queryParams: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/analytics/projects/excel`,
        {
          params: queryParams,
          responseType: 'arraybuffer',
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // timeline
  async getWorkflowTimeline(queryParams: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/workflow/analytics`,
        {params: queryParams}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async getProgramsForMetrics(): Promise<IProgram[]> {
    try {
      const response: AxiosResponse<IProgram[]> = await axiosClient.get<IProgram[]>(
        `${this.baseUrl}/analytics/program-metric/programs`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectsForProgramMetrics(params: any): Promise<IProgramMetricsProjects> {
    try {
      const response: AxiosResponse<IProgramMetricsProjects> = await axiosClient.get<IProgramMetricsProjects>(
        `${this.baseUrl}/analytics/program-metric/projects`,
        {params: params}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProgramMetricsColumns(programId: number | undefined): Promise<{ columns: IProgramMetricsColumn[] }> {
    try {
      const response: AxiosResponse<{ columns: IProgramMetricsColumn[] }> = await axiosClient.get<{
        columns: IProgramMetricsColumn[]
      }>(
        `${this.baseUrl}/analytics/program-metric/columns/${programId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUsageMetrics(): Promise<IUsageMetrics> {
    try {
      const response: AxiosResponse<IUsageMetrics> = await axiosClient.get<IUsageMetrics>(
        `${this.baseUrl}/storage-usage/metrics`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new AnalyticsApi();
