import * as React from "react";

const SvgDelete = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Delete">
          <rect id="Rectangle-Copy-2"
                fill="#F5F5F5"
                x="0" y="0"
                width={props.width ? props.width : 24}
                height={props.height ? props.height : 24}
                rx="12"/>
          <rect id="Rectangle" fill="#9B9B9B" x="6" y="11" width="12" height="2"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgDelete;
