import { Loader } from '../../../../../../../_shared/components/loader/loader';
import './top-flags-dialog.scss';
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import SvgDocuments from '../../../../../../../assets/icons/js/documents';
import InterviewsApi from '../../api/interviews-api';
import FlagIconsPipe from '../../../../../../../_shared/helpers/flag-icons-pipe';
import { IFlag, ISelectedFlag, ITopFlagsDetails } from '../../model/top-flags-details.interface';

export function TopFlagsDialog(props: any): JSX.Element {
  const [loader, setLoader] = useState<boolean>(true);
  const [data] = useState<any>(props.data);
  const [flagDetails, setFlagDetails] = useState<ITopFlagsDetails[]>([]);

  useEffect(() => {
    loadPage();
  }, []);

  function title(): JSX.Element {
    return (
      <div className="d-flex row align-items-center" style={{color: '#545e6b'}}>
        <div style={{marginRight: 10, marginTop: 3}}>
          <FlagIconsPipe icon={data.flag?.icon && data.flag?.icon} color={data?.color && data?.color}/>
        </div>
        <span>
          {data.flag.name} - {data.flag.totalAssigned} connections
        </span>
      </div>
    );
  }

  function loadPage(): void {
    InterviewsApi.getSpecificFlag(data.flag.id, data.projectId).then((response: ITopFlagsDetails[]) => {
      setFlagDetails(response);
      setLoader(false);
    });
  }

  return (
    <Modal
      title={title()}
      open={props.visible}
      onCancel={() => props.onCancel()}
      centered
      width={'60%'}
      footer={[]}>
      <div style={{maxHeight: '85vh', overflow: 'scroll'}}>
        {loader ? (
          <Loader/>
        ) : (
          <div className="top-connections-dialog-flags">
            {flagDetails.map((flagDetail: ITopFlagsDetails, index: number) => {
              return (
                <div key={index} className="interview">
                  <div className="title-container">
                    <div className="title-connections">
                      <SvgDocuments color={'#1B87E6'} style={{marginRight: 8}}/>
                      <div className="title">{flagDetail.title}</div>
                    </div>
                    <div className="flag-connections">{flagDetail.totalConnections}</div>
                  </div>
                  <div className="hr"/>
                  {flagDetail?.selected?.map((selected: ISelectedFlag, index: number) => {
                    return (
                      <div key={index} className="selected-graph-card">
                        <div className="content">
                          “…{selected.selectedText}…”
                        </div>
                        <div className="footer">
                          {selected?.flags?.map((flag: IFlag, index: number) => {
                            return (
                              <div key={index}
                                   className={flag.selected ? 'flag-selected-nodes' : 'flag-nodes'}>
                                <FlagIconsPipe
                                  height={12}
                                  width={12}
                                  icon={flag?.icon && flag?.icon}
                                  color={flag?.color && flag?.color}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
}
