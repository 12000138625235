import React from 'react';
import './confirmation-dialog.scss';
import { Button, Divider, Modal } from 'antd';
import SvgAlert from '../../../assets/icons/js/alert';

export function ConfirmationDialog(props: any): JSX.Element {

  const onConfirm = (): void => {
    props.onConfirm({modal: true, id: props.data?.id, ids: props.data?.ids});
  }

  return (
    <Modal
      title={props.data.title}
      open={props.data.visible}
      onCancel={() => (props.onCancel())}
      centered
      width={400}
      footer={[
        <span key={1}>{props.data.cancelText &&
          <Button key='cancelText' type="link" onClick={props.onCancel}>
            {props.data.cancelText}
          </Button>}</span>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          {props.data.okText ? props.data.okText : 'Ok'}
        </Button>,
      ]}>

      <div className="confirmation-dialog">
        <p className="title-sec">{props.data.titleSec}</p>
        <p style={{color: "#545e6b", fontWeight: 500}}
           className={props.data.type === "error" ? "content error-container" : "content"}>
          {props.data.type === "error" &&
            <SvgAlert color="#545E6B" width={30} height={30}/>} {props.data.content}
        </p>
        <p className="description">{props.data.description}</p>
      </div>
      <Divider style={{marginBottom: -20}}/>
    </Modal>
  )
}
