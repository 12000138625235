import * as React from "react";

const SvgAddForm = (props: any) => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <g id="Custom-1366-x-768-Copy-3" transform="translate(-187.000000, -352.000000)">
        <g id="Group" transform="translate(131.000000, 296.000000)">
          <g transform="translate(56.000000, 56.000000)" id="Group-14">
            <rect id="Rectangle-Copy-7"
                  fill={props.color ? props.color : '#1B87E6'} x="0" y="0" width="32" height="32" rx="16"/>
            <path d="M17,8 L17,15 L24,15 L24,17 L17,17 L17,24 L15,24 L15,17 L8,17 L8,15 L15,15 L15,8 L17,8 Z"
                  id="Combined-Shape" fill="#FFFFFF"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAddForm;
