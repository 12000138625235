import React, { useEffect, useState } from "react";
import { Button, message, notification, Tooltip } from "antd";
import "./projects.scss";
import SvgTrash from "../../assets/icons/js/Trash";
import ProjectApi from "./api/project-api";
import { useQuery, UseQueryResult } from "react-query";
import { IProject } from './models/project.interface';
import SvgRestoreTrash from '../../assets/icons/js/RestoreTrash';
import MoveProjectDialog from './tabs/_shared/move-project-dialog/move-project-dialog';
import { ConfirmationDialog } from '../../_shared/components/confirmation-dialog/confirmation-dialog';
import { CustomizeTableDialog } from './customize-table-dialog/customize-table-dialog';
import SvgSettings from '../../assets/icons/js/Settings';
import { Loader } from '../../_shared/components/loader/loader';
import { IProjectTableData } from "./models/project-table-data.interface";
import { IProjectTableFilters } from "./models/project-table-filters.interface";
import { useLocation } from "react-router";
import { ProjectCard } from "./components/project-card";
import { PageViewEnum } from "../stories/model/page-view.enum";
import CustomTable from "./components/custom-table/custom-table";
import { KeyMetricsTable } from "./components/custom-table/key-metrics-table";
import { ICustomizeTable, TableViewEnum } from "./models/customize-table.interface";
import { ProgramMetricsTable } from "./components/custom-table/program-metrics-table";
import { useNavigate } from "react-router-dom";
import SvgAddBig from "../../assets/icons/js/addBig";
import { DebounceInput } from "react-debounce-input";
import SvgSearch from "../../assets/icons/js/Search";
import Pagination from "../../_shared/components/pagination/pagination";
import SvgTick from "../../assets/icons/js/Tick";
import { useCheckSyncStatus } from "./hooks/use-check-sync-status";
import GridView from "../../assets/icons/js/GridView";
import ListView from "../../assets/icons/js/ListView";

export default function Projects(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [params, setParams] = useState<any>({
    page: 1,
    pageSize: 50,
    sort: "updatedAt:desc",
    search: ""
  });

  const filters: UseQueryResult<IProjectTableFilters, unknown> = useQuery("projectFilters", () => ProjectApi.getFilters(), {
    refetchOnWindowFocus: false
  });

  const customizeTableSettings = useQuery('customizeTableSettings', ProjectApi.getCustomizeTable, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setCustomizeTableData(data);
      if (data?.view === TableViewEnum.PROGRAM_METRICS_VIEW) {
        const program = data.programs.find((program) => program.selected);

        if (program) {
          onSetParams({programIds: program.id});
        }
      }

      return data;
    },
  });

  const projects: UseQueryResult<IProjectTableData, unknown> = useQuery(["projects", params], () => ProjectApi.getProjects(params), {
    refetchOnWindowFocus: false,
    enabled: !customizeTableSettings.isLoading && customizeTableSettings.isSuccess,
    onSuccess: data => {
      setTotalCount(data.pagination.totalElements);
    }
  });

  const [customizeTableData, setCustomizeTableData] = useState<ICustomizeTable | undefined>(customizeTableSettings.data ? customizeTableSettings.data : undefined);
  const [activePageView, setActivePageView] = useState<PageViewEnum>(localStorage.getItem('projectsPageView') as PageViewEnum ?? PageViewEnum.LIST);
  const [drawer, setDrawer] = useState<boolean>(false);

  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});
  const [moveProjectDialog, setMovieProjectDialog] = useState<any>({visible: false});
  const [openCustomizeTableDialog, setOpenCustomizeTableDialog] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState(0);

  const isLoading: boolean = [filters, customizeTableSettings].some(query => query.isLoading);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useCheckSyncStatus(projects, params);

  useEffect(() => {
    let activePageView: PageViewEnum = localStorage.getItem('projectsPageView') as PageViewEnum;

    if (!activePageView) {
      activePageView = PageViewEnum.LIST;
      localStorage.setItem('storiesPageView', activePageView);
    }

    setActivePageView(activePageView);
  }, []);

  useEffect(() => {
    if (location?.state?.excelImport && isLoading) {
      notification.open({
        message: "Done",
        description: `${location?.state?.numberOfProjects} projects have been imported successfully`,
        type: "success",
        className: "infobox-success",
        duration: 5,
        placement: "bottomRight",
      });
    }
  }, [isLoading]);

  const handleSearchChange = (e: any) => {
    onSetParams({search: e.target.value})
  }

  const onConfirmationDialogConfirm = (props: any) => {
    if (props.modal) {
      if (props.id) {
        archiveProject(props.id, confirmationDialog);
      }

      if (props.ids) {
        archiveProjects(props.ids)
      }

      onDialogCancel();
    }
  }

  function archiveProjects(ids: string[]): void {
    const idsS = ids.map((id) => (String(id)))
    ProjectApi.bulkDeleteProjects(idsS)
      .then(() => {
        projects.refetch();
        setSelectedRowKeys([])

        message.success({
          content: `${ids.length} projects have moved to Recycle bin`,
          className: "custom-success-message",
          icon: <SvgTick color={"#227700"}/>
        });
      });
  }


  function archiveProject(id: number, project: any): void {
    if (id) {
      ProjectApi.deleteProject(id)
        .then(() => {
          projects.refetch();

          message.success({
            content: `${project.content} has moved to Recycle bin`,
            className: "custom-success-message",
            icon: <SvgTick color={"#227700"}/>
          });
        });
    }
  }

  const onDialogCancel = () => {
    setConfirmationDialog({visible: false})
    setMovieProjectDialog({visible: false})
  }

  function openMoveDialog(project: IProject): void {
    setMovieProjectDialog({
      visible: true,
      project: project,
    });
  }

  const openConfirmationDialog = (project?: Partial<IProject>) => {
    setConfirmationDialog({
      visible: true,
      id: project?.id,
      color: project?.color,
      icon: project?.icon,
      okText: 'Move',
      title: 'Move to Recycle Bin',
      titleSec: 'You\'re about to move this project to the Recycle Bin',
      content: `${project?.name}`,
      description: <>
        Continuing will remove the project from this list. You can easily restore it by going to
        the <SvgTrash/> Recycle Bin tab and clicking
        the <SvgRestoreTrash/> Restore From Recycle Bin button.
      </>
    });
  }

  const openConfirmationDialogForMultipleProjects = (selectedRowKeys?: number[]) => {
    setConfirmationDialog({
      visible: true,
      ids: selectedRowKeys,
      okText: 'Move',
      title: 'Move to Recycle Bin',
      titleSec: 'You\'re about to move these projects to the Recycle Bin',
      content: `${selectedRowKeys?.length} projects`,
      description: <>
        Continuing will remove the projects from this list. You can easily restore them by going to
        the <SvgTrash/> Recycle Bin tab and clicking
        the <SvgRestoreTrash/> Restore From Recycle Bin button.
      </>
    });
  }

  const changePageView = (pageView: PageViewEnum) => {
    setActivePageView(pageView);
    localStorage.setItem('projectsPageView', pageView);
  }

  const isActive = (pageView: PageViewEnum) => {
    return (pageView === activePageView || (!activePageView && pageView === PageViewEnum.LIST));
  }

  function onCustomizeTable(data: ICustomizeTable | undefined): void {
    if (data?.view === TableViewEnum.PROGRAM_METRICS_VIEW) {
      const program = data.programs.find((program) => program.selected);

      if (program) {
        setParams((prev: any) => ({
          ...prev,
          programIds: program.id
        }));
      }
    } else {
      const {programIds, ...rest} = params;
      setParams(rest);
    }

    if (!data) {
      setOpenCustomizeTableDialog(false);
      return;
    }

    setCustomizeTableData(data);

    ProjectApi.updateCustomizeTable(data).then();

    setOpenCustomizeTableDialog(false);
  }

  function onSetParams(params: any) {
    setParams((prev: any) => ({
      ...prev,
      ...params
    }));
  }

  const renderTable = () => {
    if (activePageView === PageViewEnum.GRID) {
      return (
        <div className="row flex-wrap" style={{gap: '30px'}}>
          {projects?.data
            ? projects.data.content.map((project: any) => (
              <div key={project.id}>
                <ProjectCard
                  project={project}
                  openMoveDialog={openMoveDialog}
                  openConfirmationDialog={openConfirmationDialog}
                />
              </div>
            ))
            : null}
        </div>
      );
    } else {
      switch (customizeTableData?.view) {
        case TableViewEnum.BASIC_VIEW:
        default:
          return <CustomTable projects={projects}
                              filters={filters}
                              tableData={customizeTableData?.basic}
                              openMoveDialog={openMoveDialog}
                              onSetParams={onSetParams}
                              projectParams={params}
                              openConfirmationDialog={openConfirmationDialog}
                              selectedRowKeys={selectedRowKeys}
                              setSelectedRowKeys={setSelectedRowKeys}
                              openConfirmationDialogForMultipleProjects={openConfirmationDialogForMultipleProjects}/>;
        case TableViewEnum.KEY_METRICS_VIEW:
          return <>
            {projects.isLoading ? <Loader/> :
              <KeyMetricsTable data={projects?.data?.content} tableData={customizeTableData.keyMetrics}/>
            }
          </>
        case TableViewEnum.PROGRAM_METRICS_VIEW:
          return <>
            {projects.isLoading ? <Loader/> :
              <ProgramMetricsTable data={projects?.data?.content} tableData={customizeTableData.programs}/>
            }
          </>
      }
    }
  };

  return (
    <div className="project">
      <div className="header row justify-space-between align-items-center">
        <h2>Projects</h2>
      </div>
      <div className="content">
        <div className="table-header">
          <Button type='primary'
                  onClick={() => navigate('/home/project', {state: {fromProjects: true}})}
                  icon={<span className="anticon"><SvgAddBig width={14} height={14} color={'#fff'}/></span>}>
            Create
          </Button>
          <div className="row align-items-center" style={{gap: 8}}>
            <div className="outline-input-wrapper relative" style={{width: 200}}>
              <div className="absolute" style={{left: 5, top: 6}}>
                <SvgSearch color={"#9B9B9B"}/>
              </div>
              <DebounceInput type="text"
                             placeholder="Search by name or description ..."
                             style={{paddingLeft: 25}}
                             className="outline-input"
                             value={params.search}
                             debounceTimeout={600}
                             onChange={handleSearchChange}/>
            </div>
            {
              totalCount > 0 &&
              <Pagination
                onChange={(page, pageSize) => {
                  setParams((prev: any) => ({
                    ...(prev || {}),
                    page,
                    pageSize
                  }));
                }}
                pageSizeOptions={[10, 20, 30, 50, 100]}
                //defaultCurrent={params.page}
                pageSize={params.pageSize}
                showSizeChanger
                currentPage={params.page}
                totalItems={totalCount}/>
            }


            <Button
              type="text"
              style={{padding: 8}}
              data-testid="grid-view"
              className='pointer'
              onClick={() => changePageView(PageViewEnum.GRID)}
            >
              <Tooltip placement={"topRight"} title={<span style={{fontStyle: "italic"}}>Grid view</span>}>
                <GridView color={isActive(PageViewEnum.GRID) ? "#1b87e6" : "#545e6b"} width="20" height="20"/>
              </Tooltip>
            </Button>
            <Button
              type="text"
              style={{padding: 8}}
              data-testid="list-view"
              className='pointer'
              onClick={() => changePageView(PageViewEnum.LIST)}
            >
              <Tooltip placement={"topRight"} title={<span style={{fontStyle: "italic"}}>List view</span>}>
                <ListView color={isActive(PageViewEnum.LIST) ? "#1b87e6" : "#545e6b"} width="20" height="20"/>
              </Tooltip>
            </Button>


            {activePageView === PageViewEnum.LIST
              && <Button type="text" onClick={() => setOpenCustomizeTableDialog(true)} style={{padding: 8}}>
                <Tooltip placement={"topRight"} title={<span style={{fontStyle: "italic"}}>Customize table</span>}>
                  <SvgSettings width="20" height="20"/>
                </Tooltip>
              </Button>
            }
            <div className="">
              <Button type="text" onClick={() => navigate('archived')} style={{padding: 8, marginTop: 2}}>
                <Tooltip placement={"topRight"} title={<span style={{fontStyle: "italic"}}>Recycle bin</span>}>
                  <SvgTrash width="20" height="20"/>
                </Tooltip>
              </Button>
            </div>
          </div>

        </div>
        {isLoading ? <Loader/> : <>
            {renderTable()}
        </>
        }

        {confirmationDialog.visible &&
            <ConfirmationDialog data={confirmationDialog}
                                onConfirm={onConfirmationDialogConfirm}
                                onCancel={onDialogCancel}/>
        }
        {moveProjectDialog.visible &&
            <MoveProjectDialog data={moveProjectDialog}
                               loadPage={() => projects.refetch()}
                               onCancel={onDialogCancel}/>

        }
      </div>

      {
        openCustomizeTableDialog &&
          <CustomizeTableDialog open={openCustomizeTableDialog}
                                customizeTableData={customizeTableData}
                                onCancel={() => setOpenCustomizeTableDialog(false)}
                                apply={onCustomizeTable}/>
      }
    </div>
  );
}
