import React from 'react';
import { ISearchUsersData } from "../../models/search-users.interface";
import { UseQueryResult } from "react-query";
import { Empty } from "antd";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import UserCard from "./user-card/user-card";

const Users = ({searchUsers}: { searchUsers: UseQueryResult<ISearchUsersData, unknown> }) => {
  return (
    <>
      {!searchUsers?.isLoading ?
        <div className='column' style={{gap: 10, marginBottom: 20}}>
          {searchUsers?.data?.users?.length ? searchUsers?.data.users.map((user: any, i: number) => {
              return (
                <UserCard key={i}
                          user={user}/>
              )
            })
            :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
          }
        </div>
        :
        <>
          <Loader/>
        </>
      }
    </>
  );
};

export default Users;
