import React, { useEffect, useState } from 'react';
import '../zip-import/zip-import.scss';
import { Button, Dropdown, Menu, Table, Tooltip, Upload } from 'antd';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import SvgArrowBack from '../../../../../../assets/icons/js/ArrowBack';
import SvgUploadFile from '../../../../../../assets/icons/js/UploadFile';
import { environment } from '../../../../../../environment';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload/interface';
import SvgFiletypeXls from "../../../../../../assets/icons/js/FiletypeXls";
import { saveAs } from "file-saver";
import SvgArrowDown from "../../../../../../assets/icons/js/ArrowDown";
import DeskSettingsApi from "../../../../api/settings-api";
import { useQuery } from "react-query";
import { ColumnsType } from "antd/lib/table";
import SvgEdit from "../../../../../../assets/icons/js/Edit";
import SvgTrash from "../../../../../../assets/icons/js/Trash";
import ExcelImportApi from "./api/excel-import-api";
import { ConfirmationDialog } from "../../../../../../_shared/components/confirmation-dialog/confirmation-dialog";
import { ExcelImportStatusEnum } from "./model/excel-import-status.enum";
import { IExcelImport } from "./model/excel-import.interface";


const {Dragger} = Upload;

export function ExcelImport(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const [loader, setLoader] = useState<any>(true);
  const [importedFiles, setImportedFiles] = useState<IExcelImport[]>([]);
  const [isHover, setIsHover] = useState(false);
  const [onlyExcelMessage, setOnlyExcelMessage] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});

  const programs = useQuery('allPrograms', () => DeskSettingsApi.getPrograms());


  let token = localStorage.getItem('access_token');

  const columns: ColumnsType<IExcelImport> = [
    {
      title: 'Batch #',
      dataIndex: 'id',
      key: 'id',
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (value) => {
        return <div className="row align-items-center"><SvgFiletypeXls/> &nbsp; {value}</div>
      }
    },
    {
      title: 'Started By',
      dataIndex: 'startedBy',
      key: 'startedBy',
    },
    // {
    //   title: 'Last Edit',
    //   dataIndex: 'updatedAt',
    //   key: 'lastEdit',
    //   render: (value: string) => <span>
    //     <FormattedDate date={value} formatString="MMM d, yyyy"/>
    //   </span>
    // },
    {
      title: 'Projects',
      dataIndex: 'nrOfProjects',
      key: 'nrOfProjects',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <span>{status[0].toUpperCase() + status.slice(1).toLocaleLowerCase()}</span>
    },
    {
      title: '',
      dataIndex: 'delete',
      align: 'right',
      render: (empty: undefined, fileImport: IExcelImport) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <>
              {fileImport.status === ExcelImportStatusEnum.DRAFT &&
                  <Tooltip placement="bottom" title="Edit Import">
                      <button className="icon-button" onClick={() => editImport(fileImport.id)}>
                          <SvgEdit/>
                      </button>
                  </Tooltip>
              }

              <Tooltip placement="bottom" title="Delete Import">
                <button className="icon-button" onClick={() => openConfirmationDialog(fileImport.id)}>
                  <SvgTrash/>
                </button>
              </Tooltip>
            </>
          </div>
        </div>
      )
    }
  ];

  let uploadProps: UploadProps = {
    name: 'file',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    action: environment.backendUrl + '/integrations/excel-import/upload',
    method: 'POST',
    openFileDialogOnClick: false,
    beforeUpload: (file: RcFile) => {
      setOnlyExcelMessage(false);
      if (file.type === 'application/vnd.ms-excel'
        || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return true
      } else {
        setOnlyExcelMessage(true);
        setIsHover(false);
        return false;
      }
    },
    onChange: (info: UploadChangeParam) => onFileChange(info),
    multiple: false,
    accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  };

  useEffect(() => {
    getFileImports();
  }, []);


  function navigateBack(): void {
    navigate('/settings?tab=5');
  }

  const onFileChange = (info: UploadChangeParam): void => {
    const {status} = info.file;
    handleDragLeave();
    setIsUploading(true);

    if (status === 'uploading') {
      setUploadPercentage(info.file.percent || 0)
    }

    if (status === 'done') {
      const response = info.file.response;
      getFileImports();
      editImport(info.file.response.id);

      setIsUploading(false);
    }

    if (status === 'error') {
      // console.log(`${info.file.name} file upload failed.`);
    }
  }

  const downloadTemplate = (programId: number) => {
    ExcelImportApi.downloadExcelTemplate(programId)
      .then((response: any) => {
        let blob = new Blob([response], {type: 'xlsx'})
        saveAs(blob, 'import-projects-template.xlsx');
      });
  }

  function editImport(id: number): void {
    navigate(`/settings/excel-file-import/import?id=${id}`)
  }

  const handleDragOver = (): void => {
    setIsHover(true);
  }

  const handleDragLeave = (): void => {
    setIsHover(false);
  }

  function openConfirmationDialog(id: number): void {
    setConfirmationDialog({
      visible: true,
      okText: 'Delete',
      id: id,
      title: 'Delete Import',
      titleSec: 'You\'re about to delete this Excel Import',
      description: <>
        Continuing will remove the Excel Import from this
        list.
      </>
    });
  }

  function onConfirmationDialogConfirm(props: any): void {
    if (props.modal) {
      deleteImport(props.id);
      onCancel();
    }
  }

  function onCancel(): void {
    setConfirmationDialog({visible: false});
  }

  function deleteImport(id: number): void {
    ExcelImportApi.delete(id)
      .then(() => {
        getFileImports();
      });
  }

  function getFileImports(): void {
    ExcelImportApi.get()
      .then((response: IExcelImport[]) => {
        setLoader(false);
        setImportedFiles(response);
      }, () => {
        setLoader(false);
      });
  }


  return (
    <div className="zip-import">
      {loader ? <Loader/>
        :
        <div className="google-drive-integration">
          <div className="header row">
            <div className="column align-items-center back-button">
              <button className="icon-button back" onClick={() => navigateBack()}>
                <SvgArrowBack/>
              </button>
            </div>
            <div className="column w-100">
              <div className="row align-items-center">
                <div className="d-flex logo">
                  <SvgFiletypeXls width={32} height={32}/>
                </div>
                <h2>Excel file</h2>
              </div>
              <div className="row justify-space-between w-100">
                <span className="description">
                  Bring your projects straight from an Excel file in just a couple of clicks.
                </span>
                <div className='dropdown-button-wrapper'>
                  <Dropdown.Button
                    type="primary"
                    icon={<SvgArrowDown color="#ffffff"/>}
                    overlay={
                      <Menu>
                        {
                          programs.data &&
                          programs.data.content.map((program) => {
                            return <Menu.Item key={program.id}
                                              onClick={() => downloadTemplate(program.id)}>{program.name}</Menu.Item>
                          })
                        }
                      </Menu>
                    }>
                    Download Template
                  </Dropdown.Button>
                </div>
              </div>
            </div>
          </div>
          <div className="body">
            <div className="row justify-space-between">
              <div onDragOver={() => handleDragOver()}
                   onDragLeave={() => handleDragLeave()}
                   onDragEnd={() => handleDragLeave()}
                   className={isHover ? 'isHover upload-file-input' : 'upload-file-input'}>
                <Dragger {...uploadProps}>
                  <div className="header row justify-space-between align-items-center">
                    <div className="info-text d-flex align-items-center">
                      <SvgUploadFile width={20} style={{marginRight: 10, opacity: 1}}/>
                      <span>Drop your file here to upload</span>
                    </div>
                  </div>
                </Dragger>
              </div>
              <Upload {...uploadProps} className="upload-file-button" multiple={false} openFileDialogOnClick={true}>
                <Button type="primary"
                        loading={isUploading}
                        style={{
                          marginLeft: 10,
                          marginTop: 5
                        }}>{isUploading ? `${Math.trunc(uploadPercentage)} %` : `Browse`}</Button>
              </Upload>
            </div>
            {onlyExcelMessage
              &&
                <span style={{color: '#e53251'}}>Only Excel file types are allowed to be imported</span>}

            <div className="table" style={{marginTop: 60, marginBottom: 40}}>
              <Table columns={columns}
                     pagination={false}
                     dataSource={importedFiles ? importedFiles : []}
                     rowKey="id"/>
            </div>
          </div>
          <div>

          </div>
        </div>
      }
      {confirmationDialog.visible &&
          <ConfirmationDialog data={confirmationDialog}
                              onConfirm={onConfirmationDialogConfirm}
                              onCancel={onCancel}/>}
    </div>
  );
}
