export interface ICollaborator {
  id: number;
  userId: number;
  displayName: string;
  content: any;
  highlights: any;
  status: CollaboratorStatusEnum
}

export enum CollaboratorStatusEnum {
  TYPING = 'TYPING',
  IDLE = 'IDLE'
}
