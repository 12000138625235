export function ProjectColumnPipe(columnName: string) {
  switch (columnName) {
    case 'Project name':
      return 'name'
    case 'Project type':
      return 'form'
    case 'Owner':
      return 'owner'
    case 'Workflow':
      return 'workflow'
    case 'Program':
      return 'program'
    case 'Status':
      return 'workflowStatus'
    case 'Folder':
      return 'folder'
    case 'Due date':
      return 'dueDate'
    case 'Created at':
      return 'createdAt'
    case 'Created on':
      return 'createdAt'
    case 'Modified':
      return 'updatedAt'
    case 'Tags':
      return 'projectTags'
    case 'Actions':
      return 'id'
    case 'Contact email address':
      return 'contactEmailAddress'
    default:
      console.error("Unexpected columnName in ProjectColumnPipe:", columnName);
      return 'Undefined';
  }
}
