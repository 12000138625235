import CSS from "csstype";

export function getCursorPosition(): CSS.Properties | null {
  let selection = window.getSelection();
  let node: any = null;

  if (selection !== null) {
    node = selection.getRangeAt(0).getBoundingClientRect();
  }

  if (!node) {
    node = node?.parentNode;
  }


  return {
    top: node.top,
    left: node.left + 10,
    //right: node.right,
    //bottom: node.bottom
  };
}
