import React, { useState } from 'react';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ITag } from '../taxonomy/meta-tags/model/tag.interface';
import './archived.scss';
import SvgRestoreTrash from '../../../../assets/icons/js/RestoreTrash';
import DeskSettingsApi from '../../api/settings-api';
import { useQuery } from 'react-query';
import FormattedDate from '../../../../_shared/helpers/formatted-date';
import Pagination from "../../../../_shared/components/pagination/pagination";

export function Archived(props: any): JSX.Element {
  const formColumns: ColumnsType<any> = [
    {
      title: 'Project',
      dataIndex: 'title',
      key: 'title',
      render: (value: string) => <span className="email">{value}</span>
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (value: string) => <span>
        <FormattedDate date={value} formatString="MMM d, yyyy"/>
      </span>
    },
    {
      title: 'Archived on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (value: string) => <span>
        <FormattedDate date={value} formatString="MMM d, yyyy"/>
      </span>
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      align: 'right',
      render: (form: any) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <Tooltip placement="bottom" title="Restore From Recycle Bin">
              <button className="icon-button" onClick={() => unArchiveForm(form.id)}>
                <SvgRestoreTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ];
  const tagsColumns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string) => <span className="email">{value}</span>
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'name',
      render: (tag: ITag[]) => (
        <div>
          {tag && tag.map((tag: ITag) => {
            return (
              <span className="tag" key={tag.id}>{tag.name}</span>
            )
          })}
        </div>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      className: "sorter-no-tooltip",
      align: 'right',
      render: (metaTag: any) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <Tooltip placement="bottom" title="Restore From Recycle Bin">
              <button className="icon-button" onClick={() => unArchiveMetaTags(metaTag.id)}>
                <SvgRestoreTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ];

  const [formPage, setFormPage] = useState(1);
  const [formPageSize, setFormPageSize] = useState(10);
  const [metatagPage, setMetatagPage] = useState(1);
  const [metatagPageSize, setMetatagPageSize] = useState(10);

  const archivedForms = useQuery(["archivedForms", metatagPage, metatagPageSize], () => DeskSettingsApi.getArchivedForms({
    page: formPage,
    pageSize: formPageSize
  }))

  const archivedMetatags = useQuery(["archivedMetatags", metatagPage, metatagPageSize], () => DeskSettingsApi.getArchivedMetatags({
    page: metatagPage,
    pageSize: metatagPageSize
  }))


  const unArchiveForm = (id: string) => {
    DeskSettingsApi.unarchiveForm(id)
      .then(() => {
        archivedForms.refetch();
        props.updateArchive(false);
      });
  }


  const unArchiveMetaTags = (id: string) => {
    DeskSettingsApi.unarchiveMetatag(id)
      .then(() => {
        archivedMetatags.refetch();
        props.updateArchive(false);
      });
  }

  const handleFormPaginationChange = (page: number, pageSize: number) => {
    setFormPage(page);
    setFormPageSize(pageSize);
  }

  const handleMetatagsPaginationChange = (page: number, pageSize: number) => {
    setMetatagPage(page);
    setMetatagPageSize(pageSize);
  }

  return (
    <div className="archived">
      <>
        <div className="archive-column">
          <div className="w-100 row justify-space-between" style={{marginBottom: 10}}>
            <h3>Project types</h3>
            {
              archivedForms.data && archivedForms.data.pagination.totalElements > 0 &&
              <Pagination
                onChange={handleFormPaginationChange}
                pageSize={formPageSize}
                showSizeChanger={true}
                currentPage={formPage}
                totalItems={archivedForms.data.pagination.totalElements}
              />
            }
          </div>
          <Table columns={formColumns}
                 pagination={false}
                 loading={archivedForms.isLoading}
                 dataSource={archivedForms.data?.content}
                 rowKey="id"/>
        </div>

        <div className="archive-column">
          <div className="w-100 row justify-space-between" style={{marginBottom: 10}}>
            <h3>Tags</h3>
            {
              archivedMetatags.data && archivedMetatags.data.pagination.totalElements > 0 &&
              <Pagination
                onChange={handleMetatagsPaginationChange}
                pageSize={metatagPageSize}
                currentPage={metatagPage}
                showSizeChanger={true}
                totalItems={archivedMetatags.data.pagination.totalElements}
              />
            }
          </div>
          <Table columns={tagsColumns}
                 pagination={false}
                 loading={archivedMetatags.isLoading}
                 dataSource={archivedMetatags.data?.content}
                 rowKey="id"/>
        </div>
      </>
    </div>
  );
}
