import { Button, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import "./add-tags-modal.scss"
import SettingsApi from "../../../../settings/api/settings-api";
import Pagination from "../../../../../_shared/components/pagination/pagination";

const AddTagsModal = (props: any) => {
  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      className: "tags",
      render: (tags: any) => {
        return <>
          {
            tags.map((tag: any) => {
              return <span key={tag.id} className={isSelected(tag) ? "tag pointer selected" : "tag pointer"}
                           onClick={() => handleTagClick(tag)}>{tag.name}</span>
            })
          }
        </>
      }
    },
  ]

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const metaTags = useQuery(['allMetaTags', page, pageSize], () => SettingsApi.getMetaTags({
    page,
    pageSize
  }));

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }

  const [selectedTags, setSelectedTags] = useState<any>(props.data?.tags ? props.data.tags : []);

  const isSelected = (tag: any) => {
    return selectedTags.findIndex((val: any) => val.id === tag.id) > -1;
  }

  const handleTagClick = (tag: any) => {
    if (!isSelected(tag)) {
      setSelectedTags((prev: any) => [...prev, tag])
    } else {
      let tempSelectedTags = [...selectedTags];
      const index = tempSelectedTags.findIndex((value: any) => value.id === tag.id);
      tempSelectedTags.splice(index, 1);
      setSelectedTags(tempSelectedTags);
    }
  }

  const onSave = () => {
    props.onTagsChange(selectedTags.map((tag: any) => tag));
    props.onCancel();
  }

  return (
    <Modal open={props.data.visible}
           onCancel={props.onCancel}
           onOk={onSave}
           title="MetaTags"
           width={800}
           footer={[<Button
             key="submit"
             type="primary"
             onClick={onSave}>
             Save
           </Button>,
           ]}>
      <div className="tags-table" data-testid={"tags-modal"}>
        {
          metaTags.data && metaTags.data?.pagination.totalElements > 0 &&
          <Pagination totalItems={metaTags.data?.pagination.totalElements}
                      currentPage={page}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}/>
        }
        <Table style={{marginTop: 10}}
               columns={columns}
               pagination={false}
               dataSource={metaTags.data?.content}
               rowKey="id"
               loading={metaTags.isLoading}/>
      </div>
    </Modal>
  )
}

export default AddTagsModal
