import React from 'react';

const SvgClock = (props: any) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.5 8V4H7.5V10H11.5V8H9.5ZM8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8C16.5 12.4183 12.9183 16 8.5 16Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>

  );
};

export default SvgClock;
