import React from 'react';
import SvgConversation from '../../../../../../../assets/icons/js/Conversation';
import SvgMember from '../../../../../../../assets/icons/js/member';
import SvgWorkSpaceCircles from '../../../../../../../assets/icons/js/workspace-circles';

export class SlackRequirementsTab extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      colorPicked: '#1b3380'
    }
  }

  pickColor(color: string): void {
    if (!color) {
      return;
    }

    this.setState({colorPicked: color});
  }

  render() {
    const {colorPicked} = this.state;

    return (
      <div className="integration-tab column">
        <div className="row description-tab w-100">
          <div className="column">
            <h3 className="title">InsightsHub requires access to: </h3>
            <div className="column">
              <div className="content-title">
                <SvgMember/>
                <h4>Content and info about you</h4>
              </div>
              <ul>
                <li>View information about your identity</li>
              </ul>
            </div>
            <div className="column">
              <div className="content-title">
                <SvgConversation/>
                <h4>Content and info about you</h4>
              </div>
              <ul>
                <li>View messages and other content in channels associated with InsightsHub</li>
                <li>View basic information about direct and group direct messages that InsightsHub has been added to
                </li>
                <li>View basic information about channels in your workspace</li>
                <li>View files shared in channels and conversations that QuestionPro Workforce has been added to</li>
                <li>View pinned content in channels and conversations that QuestionPro Workforce has been added to</li>
                <li>View messages and files that QuestionPro Workforce has starred</li>
                <li>View emoji reactions and their associated content in channels and conversations that QuestionPro
                  Workforce has been added to
                </li>
              </ul>
            </div>
            <div className="column">
              <div className="content-title">
                <SvgWorkSpaceCircles/>
                <h4>Content and info about your workspace</h4>
              </div>
              <ul>
                <li>View the name, email domain, and icon for workspaces QuestionPro Workforce is connected to</li>
                <li>View people in your workspace</li>
                <li>View email addresses of people in your workspace</li>
                <li>View user groups in your workspace</li>
                <li>View custom emoji in your workspace</li>
                <li>View Do Not Disturb settings for people in your workspace</li>
              </ul>
            </div>
          </div>
          <div className="column color-picker w-100 d-flex align-items-center">
            <div>
              <div className="color-picked" style={{backgroundColor: colorPicked}}/>
              <div className="row">
                <div className="pick-color"
                     style={{backgroundColor: '#1b3380'}}
                     onClick={() => this.pickColor('#1b3380')}/>

                <div className="pick-color"
                     style={{backgroundColor: '#545e6b'}}
                     onClick={() => this.pickColor('#545e6b')}/>

                <div className="pick-color"
                     style={{backgroundColor: '#1b87e6'}}
                     onClick={() => this.pickColor('#1b87e6')}/>

                <div className="pick-color"
                     style={{backgroundColor: '#a5d5ff'}}
                     onClick={() => this.pickColor('#a5d5ff')}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
