import * as React from "react";

const SvgStories = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g fill={props.color ? props.color : '#545E6B'}>
        <path
          d="M10.975 4.694l2.828 2.826-7.474 7.47H15.5c.276 0 .5.224.5.5s-.224.5-.5.5h-10c-.052 0-.101-.008-.148-.023L5.318 16H2.49v-2.827l8.485-8.48zM10 0c.552 0 1 .448 1 1v1.998L5 8.994H3l-3 2.998V1c0-.552.448-1 1-1h9zM4.5 5.996h-2c-.276 0-.5.224-.5.5 0 .245.177.45.41.491l.09.008h2c.276 0 .5-.223.5-.5 0-.275-.224-.499-.5-.499zm9.303-2.716l1.415 1.414c.39.39.39 1.023 0 1.413l-.708.707-2.828-2.827.707-.707c.39-.39 1.024-.39 1.414 0zM6.5 3.997h-4c-.276 0-.5.224-.5.5 0 .245.177.45.41.492l.09.008h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zM8.5 2h-6c-.276 0-.5.223-.5.5 0 .245.177.449.41.491l.09.008h6c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z"
          transform="translate(-16 -288) translate(0 128) translate(16 156) translate(0 4)"/>
      </g>
    </svg>
  );
}

export default SvgStories;
