import React from 'react';
import { DisplayNode } from './display-node';
import { DisplayTasks } from './display-tasks';
import { DisplayMention } from './display-mention';
import { DisplayFlag } from './display-flag';
import isEqual from 'lodash.isequal';

// made it as a class component because we need shouldComponentUpdate lifecycle
export class DisplayHighlightContainer extends React.Component<any, any> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    return !isEqual(nextProps.displayTextHighlights, this.props.displayTextHighlights) || this.props?.readOnly !== nextProps?.readOnly;
  }


  render() {
    const {displayTextHighlights, flags, editorState, assignFlagsToSelectedText, readOnly} = this.props;
    return (
      <>
        {
          displayTextHighlights?.length && displayTextHighlights.map((value: any, index: number) => {
            if (value) {
              return (
                <div
                  key={index + Math.random()}
                  className="display-highlight-container"
                  style={{top: `${parseInt(value[0]) + 20}px`}}>
                  {
                    value[1]?.length && value[1].map((item: any, index: number) => {
                      if (item.type === 'NODE') {
                        return (
                          <DisplayNode node={item.node} key={index}/>
                        )
                      }

                      if (item.type === 'TASK') {
                        return (
                          <DisplayTasks task={item.task} key={index}/>
                        )
                      }

                      if (item.type === 'mention') {
                        return (<DisplayMention mention={item} key={index}/>)
                      }

                      if (item.type === 'FLAG') {
                        return (<DisplayFlag flags={flags}
                                             key={index}
                                             readOnly={readOnly}
                                             flag={item}
                                             editorState={editorState}
                                             assignFlagsToSelectedText={assignFlagsToSelectedText}/>
                        )
                      }
                    })
                  }
                </div>
              )
            }
          })
        }
      </>
    );
  }
}
