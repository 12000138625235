/* eslint-disable react/no-did-mount-set-state, no-param-reassign */

import React from 'react'
import * as color from './helpers/color'
import isUndefined from 'lodash/isUndefined'

import { EditableInput } from 'react-color/lib/components/common'
import SvgArrowUp from '../../../../assets/icons/js/ArrowUp'
import './chrome-fields.scss'
import SvgArrowDown from '../../../../assets/icons/js/ArrowDown'

export class ChromeFields extends React.Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      view: "hex",
    }
  }

  static getDerivedStateFromProps(nextProps: any, state: any) {
    if (nextProps.hsl.a !== 1 && state.view === 'hex') {
      return {view: 'rgb'}
    }
    return null
  }

  toggleViews = () => {
    if (this.state.view === 'hex') {
      this.setState({view: 'rgb'})
    } else if (this.state.view === 'rgb') {
      this.setState({view: 'hsl'})
    } else if (this.state.view === 'hsl') {
      if (this.props.hsl.a === 1) {
        this.setState({view: 'hex'})
      } else {
        this.setState({view: 'rgb'})
      }
    }
  }

  handleChange = (data: any, e: any) => {
    if (data.hex) {
      color.isValidHex(data.hex) && this.props.onChange({
        hex: data.hex,
        source: 'hex',
      }, e)
    } else if (data.r !== undefined || data.g !== undefined || data.b !== undefined || data.a !== undefined) {
      this.props.onChange({
        r: data.r !== undefined ? data.r : this.props.rgb.r,
        g: data.g !== undefined ? data.g : this.props.rgb.g,
        b: data.b !== undefined ? data.b : this.props.rgb.b,
        a: data.a !== undefined ? data.a : this.props.rgb.a,
        source: 'rgb',
      }, e)
    } else if (data.h !== undefined || data.s !== undefined || data.l !== undefined) {
      const s = typeof data.s === 'string' ? data.s.replace('%', '') : data.s;
      const l = typeof data.l === 'string' ? data.l.replace('%', '') : data.l;

      this.props.onChange({
        h: data.h !== undefined ? data.h : this.props.hsl.h,
        s: Number(!isUndefined(s) ? s : this.props.hsl.s),
        l: Number(!isUndefined(l) ? l : this.props.hsl.l),
        a: data.a !== undefined ? Math.round(data.a * 100) / 100 : this.props.hsl.a,
        source: 'hsl',
      }, e)
    }
  }

  showHighlight = (e: any) => {
    e.currentTarget.style.background = '#eee'
  }

  hideHighlight = (e: any) => {
    e.currentTarget.style.background = 'transparent'
  }

  render() {
    const styles = {
      input: {
        fontSize: '12px',
        color: '#545E6B',
        width: '100%',
        borderRadius: '2px',
        border: 'none',
        boxShadow: 'inset 0 0 0 1px #dadada',
        height: '24px',
        textAlign: 'center'
      },
      label: {
        display: "none"
      },
    }
    let fields
    if (this.state.view === 'hex') {
      fields = (<div className="flexbox-fix fields">
        <div className='field'>
          <EditableInput
            style={{label: styles.label}}
            value={this.props.hex}
            onChange={this.handleChange}
            label="hex"
          />
        </div>
      </div>)
    } else if (this.state.view === 'rgb') {
      fields = (<div className="flexbox-fix fields ">
        <div className='field'>
          <EditableInput
            style={{label: styles.label}}
            label="r"
            value={this.props.rgb.r}
            onChange={this.handleChange}
          />
        </div>
        <div className='field'>
          <EditableInput
            style={{label: styles.label}}
            label="g"
            value={this.props.rgb.g}
            onChange={this.handleChange}
          />
        </div>
        <div className='field'>
          <EditableInput
            style={{label: styles.label}}
            label="b"
            value={this.props.rgb.b}
            onChange={this.handleChange}
          />
        </div>
        <div className='field alpha'>
          <EditableInput
            style={{label: styles.label}}
            label="a"
            value={this.props.rgb.a}
            onChange={this.handleChange}
          />
        </div>
      </div>)
    } else if (this.state.view === 'hsl') {
      fields = (<div className="flexbox-fix fields">
        <div className='field'>
          <EditableInput
            style={{label: styles.label}}
            label="h"
            value={Math.round(this.props.hsl.h)}
            onChange={this.handleChange}
          />
        </div>
        <div className='field'>
          <EditableInput
            style={{label: styles.label}}
            value={`${Math.round(this.props.hsl.s * 100)}%`}
            label="s"
            onChange={this.handleChange}
          />
        </div>
        <div className='field'>
          <EditableInput
            style={{label: styles.label}}
            label="l"
            value={`${Math.round(this.props.hsl.l * 100)}%`}
            onChange={this.handleChange}
          />
        </div>
        <div className='field alpha'>
          <EditableInput
            style={{label: styles.label}}
            label="a"
            value={this.props.hsl.a}
            onChange={this.handleChange}
          />
        </div>
      </div>)
    }

    return (
      <div className="flexbox-fix wrap-chrome-fields">
        <div className='toggle'>
          <div className='icon' onClick={this.toggleViews}
               onMouseOver={this.showHighlight}
               onMouseEnter={this.showHighlight}
               onMouseOut={this.hideHighlight}
          >
            <SvgArrowUp width={8} height={4}/>
            <SvgArrowDown width={8} height={4}/>
          </div>
          <div className='label'>{this.state.view.toUpperCase()}</div>
        </div>
        <div style={{width: 220, marginLeft: 10}}>
          {fields}
        </div>
      </div>
    )
  }
}


export default ChromeFields
