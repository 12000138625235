import React, { useContext } from 'react';
import './error-page.scss';
import Svg401 from '../../assets/icons/js/401';
import GlobalContext from '../../store/global-context';

export default function Unauthorized() {
  const globalContext = useContext(GlobalContext);

  function navigateToLoginPage(): void {
    globalContext.logoutUser();
  }

  return (
    <div className="error-page row align-items-center justify-space-center">
      <div className="error-message column">
        <h1 className="error-page-title">InsightsHub</h1>
        <h2 className="error-page-reason">You don't have access to this workplace</h2>

        <div className="column">
          <span className="error-code"><b>Error 401</b> - Unauthorized</span>
          <span>Contact your InsightsHub Admin or</span>
        </div>

        <div className="row sign-in">
          <button className="sign-in-button" onClick={() => navigateToLoginPage()}>
            {/*<SvgGoogleLogo width={18}/>*/}
            <span>Log in with a different account</span>
          </button>
        </div>
      </div>
      <div className="column logo">
        <Svg401/>
      </div>
    </div>
  );
}
