import React from 'react';
import SearchKnowledgeGraphCard
  from "../search-knowledge-graphs/search-knowledge-graph-card/search-knowledge-graph-card";
import { Empty } from "antd";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import SearchSurveyCard from "./search-survey-card/search-survey-card";

const SearchSurveys = (props: any) => {
  const searchSurveys = props.searchSurveys;

  return <>
    {!searchSurveys?.isLoading ?
      <div className='column' style={{gap: 10, marginBottom: 20}}>
        {searchSurveys?.data?.surveys?.length ? searchSurveys?.data.surveys.map((survey: any, i: number) => {
            return (
              <SearchSurveyCard key={i}
                                survey={survey}/>
            )
          })
          :
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }
      </div>
      :
      <>
        <Loader/>
      </>
    }
  </>
};

export default SearchSurveys;
