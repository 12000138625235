import React, { FC } from 'react';
import { format, isValid } from 'date-fns';

type FormattedDateProps = {
  date: string | Date;
  formatString: string;
  className?: string;
};

const FormattedDate: FC<FormattedDateProps> = ({date, formatString, className}) => {
  const parsedDate = new Date(date);

  if (!isValid(parsedDate) || !formatString) {
    return <span className={className}>Invalid date or format string</span>;
  }

  const formattedDate = format(parsedDate, formatString);
  return <span className={className}>{formattedDate}</span>;
};

export default FormattedDate;
