import React, { useEffect, useState } from 'react';
import { Button, Divider, Modal, Select, Tooltip, Upload } from "antd";
import { useQuery, UseQueryResult } from "react-query";
import './import-files-dialog.scss'
import { IProjectTable } from "../../../projects/models/project-table.interface";
import { IProjectTableData } from "../../../projects/models/project-table-data.interface";
import ProjectApi from "../../../projects/api/project-api";
import OneDriveIntegrationApi
  from "../../../settings/settings-tabs/setup/integrations/one-drive-integration/api/one-drive-integration-api";
import SvgUpload2 from "../../../../assets/icons/js/upload-2";
import FileTypePipe from "../../../../_shared/helpers/file-type-pipe";
import SvgClose from "../../../../assets/icons/js/Close";
import SvgArrowDown from "../../../../assets/icons/js/ArrowDown";
import GoogleDriveIntegrationApi
  from "../../../settings/settings-tabs/setup/integrations/google-drive-integration/api/google-drive-integration-api";
import { getFileType } from "../../../../_shared/helpers/get-file-type";
import { truncateTextInMiddle } from "../../../../_shared/helpers/truncate-text-in-middle";
import SvgSearch from "../../../../assets/icons/js/Search";
import ExternalURLImport
  from "../../pages/home-initial-sync/pages/sync-with-external-data/components/external-url-import/external-url-import";
import { useUploadFile } from "../../../../core/hooks/use-upload-file";
import SvgBetaLabel from "../../../../assets/icons/js/BetaLabel";
import SharepointIntegrationApi
  from "../../../settings/settings-tabs/setup/integrations/sharepoint-integration/api/sharepoint-integration-api";
import { FileTypeEnum } from "../../../projects/tabs/_shared/model/file-type.enum";


type ImportFilesDialogProps = {
  open: boolean;
  data: { open: boolean, selectedProjectId?: number };
  onCancel: () => void;
}

const {Dragger} = Upload;
const ImportFilesDialog = (props: ImportFilesDialogProps) => {
  const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(props.data.selectedProjectId ?? undefined);
  const [selectedProject, setSelectedProject] = useState<IProjectTable>();
  const [uploadFilesView, setUploadFilesView] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [externalUrl, setExternalUrl] = useState<string>('');
  const [externalUrlError, setExternalUrlError] = useState<string>('');
  const [driveSelected, setDriveSelected] = useState<"GOOGLE" | "MICROSOFT" | "SHAREPOINT">("GOOGLE");

  const [areDrivesInstalled, setAreDrivesInstalled] = useState({
    "GOOGLE": false,
    "MICROSOFT": false,
    "SHAREPOINT": false
  });
  
  const {uploadZipFile, uploadDriveFile} = useUploadFile(selectedProject);

  const projects: UseQueryResult<IProjectTableData, unknown> = useQuery(["select-projects"], () => ProjectApi.getProjects({
    page: 1,
    pageSize: 9999,
    sort: 'updatedAt:desc'
  }), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!projects.data) {
      return;
    }

    const project = projects.data.content.find(project => Number(project.id) === selectedProjectId);


    if (project) {
      setSelectedProject(project);
    }

    if (props.data.selectedProjectId) {
      setUploadFilesView(true)
    }

  }, [selectedProjectId, projects.data]);


  const handleSelectChange = (id: number) => {
    setSelectedProjectId(id);
  }

  const onOk = () => {
    if (uploadFilesView) {
      files.forEach((file) => {
        file.externalUrl ? uploadDriveFile(file) : uploadZipFile(file);
      });
      props.onCancel();
    } else {
      setUploadFilesView(true);
    }
  };


  const onCancel = () => {
    if (uploadFilesView) {
      setUploadFilesView(false)
      return;
    }

    props.onCancel();
  }

  const addFile = (options: any) => {
    const {file} = options;
    setFiles(prevFiles => [...prevFiles, file]);
  }

  let uploadProps = {
    name: 'file',
    multiple: true,
    openFileDialogOnClick: false,
    customRequest: (options: any) => addFile(options),
    showUploadList: false,
  };


  const removeFile = (uidToRemove: string): void => {
    setFiles(prevFiles => prevFiles.filter(file => file.uid !== uidToRemove));
  };


  const getExternalURLFiles = () => {
    const handleSuccess = (data: any) => {
      if (data) {
        setFiles(files => [...files, ...data.map((file: any) => ({...file, type: file.mimeType, externalUrl}))]);
      }
    };

    const handleError = (err: any) => {
      setExternalUrlError(err.response.data.message);
    };

    if (driveSelected === "GOOGLE" && externalUrl.includes("google")) {
      GoogleDriveIntegrationApi.listFiles(externalUrl)
        .then(handleSuccess)
        .catch(handleError);
    } else if (driveSelected === "MICROSOFT" && externalUrl.includes("onedrive")) {
      OneDriveIntegrationApi.listFiles(externalUrl)
        .then(handleSuccess)
        .catch(handleError);
    } else if (driveSelected === "SHAREPOINT" && externalUrl.includes("sharepoint")) {
      SharepointIntegrationApi.listFiles(externalUrl)
        .then(handleSuccess)
        .catch(handleError)
    } else {
      setExternalUrlError('Invalid URL');
    }
  };

  const showUploadButton = () => {
    if (driveSelected === "GOOGLE") {
      return areDrivesInstalled.GOOGLE;
    } else if (driveSelected === "MICROSOFT") {
      return areDrivesInstalled.MICROSOFT;
    } else if (driveSelected === "SHAREPOINT") {
      return areDrivesInstalled.SHAREPOINT;
    }

    return false
  }

  const updateDriveInstalled = (drive: "GOOGLE" | "MICROSOFT" | "SHAREPOINT", value: boolean) => {
    setAreDrivesInstalled(prev => ({...prev, [drive]: value}));
  }

  const filterOption = (input: string, option?: {
    label: string;
    value: string,
    children: string
  }) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase());


  return (
    <Modal open={props.open}
           onCancel={props.onCancel}
           width={600}
           cancelText={uploadFilesView ? "Back" : "Cancel"}
           cancelButtonProps={{type: 'link', onClick: onCancel}}
           okButtonProps={{disabled: !selectedProjectId || (uploadFilesView && !files.length)}}
           okText={uploadFilesView ? "Import" : "Continue"}
           onOk={onOk}
           title={<div className="row align-items-center" style={{gap: 5}}>
             <div>Import files</div>
             <Tooltip placement="topLeft" className="pointer" title="Beta feature">
               <SvgBetaLabel/>
             </Tooltip>
           </div>}
    >
      <div className="import-files-dialog-wrapper" style={{height: 450}}>
        {
          uploadFilesView ?
            <div className="upload-files-view-wrapper">
              <Dragger {...uploadProps} className="dragger-wrapper">
                <p>
                  <SvgUpload2/>
                </p>
                <div className='dragger-text' style={{color: "#545e6b"}}>
                  Drag your files or
                  <Upload {...uploadProps} openFileDialogOnClick={true}>
                    <span className='link' style={{fontSize: 12}}>&nbsp;Browse</span>
                  </Upload>
                </div>
              </Dragger>
              <div className="row align-items-end" style={{gap: 10, marginTop: 10}}>
                <ExternalURLImport url={externalUrl}
                                   updateUrl={(value) => setExternalUrl(value)}
                                   fromHome
                                   error={externalUrlError}
                                   driveSelected={driveSelected}
                                   selectedProjectId={selectedProjectId}
                                   updateDriveSelected={(value) => setDriveSelected(value)}
                                   updateDriveInstalled={updateDriveInstalled}/>
                {
                  showUploadButton() &&
                  <Button type="ghost" onClick={getExternalURLFiles}>Upload</Button>
                }

              </div>
              {
                externalUrlError && <small style={{marginLeft: 160}} className="form-error">{externalUrlError}</small>
              }

              <div className="uploading-files-information">
                <p>Importing files into <span style={{fontWeight: 500}}>{selectedProject?.name}</span></p>
              </div>
              <div className="files-list-wrapper">
                {
                  files.map((file: { uid: string, name: string, type: string }, i: number) => {
                    let fileType = getFileType(file.type);
                    if (!fileType) {
                      fileType = file.type;
                    }
                    return <div className="file-info-wrapper" key={i}>
                      <div className="row align-items-center" style={{gap: 15}}>
                        <FileTypePipe width={16} height={16} value={fileType as FileTypeEnum} className="row"/>
                        <span
                          className="file-info-name">
                          {truncateTextInMiddle(file.name.split(".")[0] + `.${fileType}`, 60)}
                        </span>
                      </div>
                      <div className="action-button pointer" onClick={() => removeFile(file.uid)}>
                        <SvgClose width={12} height={12}/>
                      </div>
                    </div>
                  })
                }
              </div>
            </div> :
            <div className="outline-input-wrapper">
              <div className="outline-input-label">
                Select a project to associate with
              </div>
              <div className="relative">
                <div className="absolute" style={{top: 6, left: 5, zIndex: 99}}>
                  <SvgSearch color={"#9B9B9B"} width={12} height={12}/>
                </div>
                <Select showSearch
                        placeholder={"Search for project"}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        suffixIcon={<SvgArrowDown/>}
                        onChange={handleSelectChange}>
                  {projects.data && projects.data.content.map((project: IProjectTable, i: number) => <Select.Option
                    key={i}
                    value={project.id}>{project.name}</Select.Option>)}
                </Select>
              </div>
            </div>
        }

      </div>
      <Divider style={{marginBottom: -20}}/>
    </Modal>
  );
};

export default ImportFilesDialog;
