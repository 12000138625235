import * as React from "react";

const SvgLabel = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd">
        <path
          d="M1 2.67C1 1.746 1.747 1 2.67 1h3.639c.442 0 .867.176 1.18.489l7.33 7.33c.653.653.653 1.71 0 2.361l-3.639 3.64c-.652.652-1.708.652-2.36 0L1.489 7.489C1.176 7.176 1 6.75 1 6.309v-3.64zM5 7c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z"
          transform="translate(-1119 -436) translate(131 -274) translate(80 651) translate(32 51) translate(876 8)"/>
      </g>
    </svg>

  );
}

export default SvgLabel;
