import { useState, useEffect } from 'react';

type PaginationOptions = {
  pageSize: number;
  currentPage: number;
}

type PaginationResult<T> = {
  items: T[];
}

function usePagination<T>(options: PaginationOptions, data?: T[]): PaginationResult<T> {
  const {pageSize, currentPage} = options;
  const [paginatedData, setPaginatedData] = useState<T[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, data.length);
    setPaginatedData(data.slice(startIndex, endIndex));
    
  }, [data, pageSize, currentPage]);

  return {
    items: !!data ? paginatedData : [],
  };
}

export default usePagination;
