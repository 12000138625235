import React, { Ref, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Checkbox, DatePicker, notification, Select } from 'antd';
import InformIconsPipe from '../../../../_shared/helpers/inform-icons-pipe';
import './create-project.scss';
import { MainFormTypeEnum } from '../../../settings/settings-tabs/taxonomy/project-type/model/main-form-type.enum';
import { WidgetInput } from './widgets/widget-input';
import { WidgetChoice } from './widgets/widget-choice';
import { WidgetUpload } from './widgets/widget-upload/widget-upload';
import { IProject } from './models/project.interface';
import { Loader } from '../../../../_shared/components/loader/loader';
import IntakeFormApi from './api/create-project-api';
import { IProgram } from '../../../settings/settings-tabs/taxonomy/programs/model/program.interface';
import { ITeamMember, ITeamMemberData } from '../../../settings/settings-tabs/team/model/team-member.interface';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import projectTypeApi from '../../../settings/settings-tabs/taxonomy/project-type/api/project-type-api';
import GlobalContext from '../../../../store/global-context';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import MaskPIIForm from "../../../../_shared/components/mask-pii-form/mask-pii-form";
import Switch from "../../../../_shared/components/switch/switch";
import SvgNugget from "../../../../assets/icons/js/nugget";

function CreateProject(props: {}, ref: Ref<{ submitForm: () => void }>): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const urlParam: any = qs.parse(location.search);
  const [loader, setLoader] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string>('');
  const [showInputError, setShowInputError] = useState<boolean>(false);
  const [workflows, setWorkflows] = useState<any>([]);
  const [programs, setPrograms] = useState<any[]>([]);
  const [owners, setOwners] = useState<any[]>([]);

  const [intakeForm, setIntakeForm] = useState<any>({
    formId: '',
    color: '',
    icon: '',
    logConversations: false,
    widgets: []
  });

  type FormData = {
    name: string;
    dueDate: Date;
    requestedBy: number;
    description: string;
    programId: number;
    workflowId: number;
    logConversations?: boolean;
    slackChannelName?: string;
    contactEmailAddress?: string;
    autoGenerateKeyFindings: boolean;
  };

  const schema = yup.object({
    name: yup.string().required('Name is required!'),
    description: yup.string().required('Project Brief is required!'),
    dueDate: yup.date().required('Due date is required!'),
    requestedBy: yup.number().required('Requested by is required!'),
    programId: yup.number().required('Program is required!'),
    workflowId: workflows.customWorkflowEnabled ? yup.number().required('Workflow is required!') : yup.number().notRequired(),
    logConversations: yup.boolean().optional(),
    slackChannelName: yup.string().optional(),
    contactEmailAddress: yup.string().email('Invalid email!').optional(),
    autoGenerateKeyFindings: yup.boolean().optional(),
  });

  const {register, handleSubmit, watch, reset, control, formState: {errors}} = useForm({
    resolver: yupResolver(schema),
  });
  const slackChannelNameRegister = register('slackChannelName');


  useEffect(() => {
    getPrograms();
    getOwners();
    getWorkflows();
  }, []);

  useEffect(() => {
    getFormById();
  }, [programs, workflows, urlParam?.id])

  useImperativeHandle(
    ref,
    () => ({
      //@ts-ignore
      submitForm: handleSubmit(onFormSubmit, (errors) => {
        console.log('Form errors:', errors);
      })
    }),
  );

  function setSlackChannelName(e: React.ChangeEvent<HTMLInputElement>): void {
    const disableCharacters = new RegExp(/[^a-zA-Z0-9-]/g);
    const value = e.target.value;
    const replaceSpace = value.replace(/\s/g, '-');
    const val = replaceSpace.replace(disableCharacters, '');
    e.target.value = val;
    slackChannelNameRegister.onChange(e)
  }

  const setWidget = (data: { index: number, type: MainFormTypeEnum, value: any, label: any }) => {
    const widgets = intakeForm.widgets;
    widgets[data.index] = {
      ...widgets[data.index],
      value: data.value,
      label: data.label
    }

    setIntakeForm((prevIntakeForm: any) => {
      return {...prevIntakeForm, widgets};
    });
  }

  const setUploadWidget = (data: { index: number, value: any, label: any }) => {
    const widgets = intakeForm.widgets;
    if (widgets[data.index]?.value?.length) {
      widgets[data.index].value.push(...data.value);
    } else {
      widgets[data.index].value = [...data.value];
    }

    setIntakeForm((prevIntakeForm: any) => {
      return {...prevIntakeForm, widgets};
    });
  }

  const removeFileFromWidget = (data: { index: number, id: string }) => {
    const widgets = intakeForm.widgets;

    if (widgets[data.index]?.value?.length) {
      widgets[data.index]?.value.splice(widgets[data.index]?.value.findIndex((file: any) => {
        return file.id === data.id;
      }), 1);
    }

    setIntakeForm((prevIntakeForm: any) => {
      return {...prevIntakeForm, widgets};
    });
  }

  const onFormSubmit = (data: FormData) => {
    if (dynamicInputFormError()) {
      setShowInputError(true);
      return;
    }

    const {dueDate, ...restData} = data;

    IntakeFormApi.createIntakeProject({...intakeForm, ...restData, dueDate: dueDate.toISOString()})
      .then((response: IProject) => {

        notification.open({
          message: `${data.name}`,
          description: 'Has been created',
          style: {borderColor: `${intakeForm.color}`},
          duration: 3,
          icon: <InformIconsPipe icon={intakeForm.icon} width={16} height={16}
                                 color={intakeForm.color}/>,
          placement: 'bottomRight',
        });

        navigate({pathname: '/projects/edit', search: qs.stringify({id: response.id})}, {state: {fromHome: true}});

      }, (err: any) => {
        const error = err.response;
        if (error) {
          setErrorMessages(error.message);
        }
      });
  }

  function getFormById() {
    const formId = urlParam?.id;
    setLoader(true);


    if (formId) {
      IntakeFormApi.getFormById(formId)
        .then((project: IProject) => {

          const resetValues = {
            requestedBy: globalContext?.user?.id,
            programId: programs[0]?.id,
            workflowId: project.workflowId ? parseInt(project.workflowId) : null,
            autoGenerateKeyFindings: true
          }

          reset(resetValues);

          setIntakeForm({
            formId: project.id,
            slackIntegration: project.slackIntegration,
            widgets: project.widgets,
            icon: project.icon,
            color: project.color,
            title: project.title
          });


          setLoader(false);
        }, (err: any) => {
          console.log('err: ', err);
        });
    }
  }


  function getPrograms(): void {
    setLoader(true);
    IntakeFormApi.getPrograms()
      .then((response: { content: IProgram[], totalSize: number }) => {
        setPrograms(response.content);
        setLoader(false);
      }, (err: any) => {
        console.log('err: ', err);
        getFormById();
      });
  }

  function getOwners(): void {
    setLoader(true);
    IntakeFormApi.getOwners()
      .then((response: ITeamMemberData) => {
        setOwners(response.content);
        setLoader(false);
      }, (err: any) => {
        console.log('err: ', err);
      });
  }

  function getWorkflows(): void {
    setLoader(true);

    projectTypeApi.getWorkflows().then((response: any) => {
      setWorkflows(response);
      setLoader(false);
    })
  }


  function dynamicInputFormError(): boolean {
    return intakeForm.widgets.some((widget: any) => !widget.value && widget.required);
  }

  return (
    <div className="intake-form-dialog">
      {/*<div className="intake-form-header justify-space-between">*/}
      {/*  <div className="row align-items-center">*/}
      {/*    <button className="icon-button back" onClick={() => navigateBack()}>*/}
      {/*      <SvgArrowBack/>*/}
      {/*    </button>*/}
      {/*    <InformIconsPipe icon={intakeForm.icon} color={intakeForm.color}/>*/}
      {/*    <span className="font-weight-500">{intakeForm.title}</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {loader ? <Loader/> :
        <>
          <div className="body">
            <div style={{marginBottom: 30}}>
              <div className="column">
                <div className="row">
                  <div className="outline-input-wrapper fxFlex">
                    <span className="outline-input-label">
                      <span style={{color: "red"}}>* </span>
                      Project name</span>
                    <input
                      className="outline-input"
                      placeholder="Project name..."
                      maxLength={120}
                      {...register("name")}
                    />
                    <div className='character-count'> {watch('name') ? `${watch('name').length}/120` : '0/120'}</div>
                    <ErrorMessage errors={errors} name={'name'} render={({message}) => <small
                      className="form-error">{message}</small>}/>
                  </div>
                  <div className="outline-input-wrapper fxFlex" style={{marginLeft: 16}}>
                    <span className="outline-input-label">Requested by</span>
                    <Controller control={control} name='requestedBy'
                                render={({field}) => <Select
                                  {...field} >
                                  {owners.map((owner: ITeamMember) => {
                                    return (
                                      owner.id &&
                                      <Select.Option key={owner.id}
                                                     value={owner.id}>
                                        {owner?.displayName}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                                }
                    />

                  </div>
                </div>
                <div className="row">
                  <div className="outline-input-wrapper date-picker fxFlex">
                    <span className="outline-input-label">
                      <span style={{color: "red"}}>* </span>Due/Complete date</span>
                    <Controller control={control}
                                name='dueDate'
                                render={({field}) => <DatePicker {...field} className="outline-input"/>}/>
                    <ErrorMessage errors={errors} name={'dueDate'} render={({message}) => <small
                      className="form-error">{message}</small>}/>
                  </div>
                </div>
                <div className="row">
                  <div className="outline-input-wrapper fxFlex">
                    <span className="outline-input-label"><span style={{color: "red"}}>* </span>Project brief</span>
                    <textarea
                      rows={3}
                      maxLength={280}
                      placeholder={"Project brief..."}
                      className="outline-input"
                      {...register("description")}
                    />
                    <div
                      className='character-count'> {watch('description') ? `${watch('description').length}/280` : '0/280'}</div>
                    <ErrorMessage errors={errors} name={'description'} render={({message}) => <small
                      className="form-error">{message}</small>}/>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="row">
                  {
                    programs?.length > 0 &&
                      <div className="outline-input-wrapper fxFlex">
                          <span className="outline-input-label">Program</span>
                          <Controller control={control} name='programId'
                                      render={({field}) => <Select
                                        {...field} >
                                        {programs.map((program: IProgram) => {
                                          return (
                                            <Select.Option key={program.id}
                                                           value={program.id}>
                                              {program?.name}
                                            </Select.Option>
                                          )
                                        })}
                                      </Select>
                                      }
                          />
                      </div>
                  }
                  {
                    workflows?.workflows?.length > 0 && workflows.customWorkflowEnabled &&
                      <div className="outline-input-wrapper fxFlex" style={{marginLeft: 16}}>
                          <span className="outline-input-label">Workflow</span>
                          <Controller control={control} name='workflowId'
                                      render={({field}) => <Select
                                        {...field} >
                                        {workflows?.workflows.map((workflow: any) => {
                                          return (
                                            <Select.Option key={workflow.id}
                                                           value={workflow.id}>
                                              {workflow?.name}
                                            </Select.Option>
                                          )
                                        })}
                                      </Select>
                                      }
                          />
                        <ErrorMessage errors={errors} name={'workflowId'} render={({message}) => <small
                          className="form-error">{message}</small>}/>
                      </div>
                  }
                </div>
                <div className="outline-input-wrapper w-50" style={{paddingRight: 9}}>
                  <span className="outline-input-label">Customer point of contact (email address)</span>
                  <input type="text"
                         className="outline-input"
                         {...register("contactEmailAddress")}
                  />
                  <ErrorMessage errors={errors} name={'contactEmailAddress'} render={({message}) => <small
                    className="form-error">{message}</small>}/>
                </div>
              </div>
              <div className="row justify-space-between align-items-center" style={{marginBottom: 8, padding: "8px 0"}}>
                <div className="column">
                  <div className="row justify-center" style={{gap: 8, padding: "12px 0"}}>
                    <SvgNugget width={16} height={16}/>
                    <h4 style={{marginBottom: 0}}>Auto Key Findings</h4>
                  </div>
                  <h5 style={{marginBottom: 0}}>Let AskIH automatically generate key findings from all your project
                    data,
                    including QuestionPro surveys
                    and
                    external files.</h5>

                </div>
                <Controller control={control} name='autoGenerateKeyFindings'
                            render={({field}) => <Switch
                              name='autoGenerateKeyFindings'
                              checked={field.value}
                              onChange={(checked: boolean) => field.onChange(checked)}
                            />
                            }
                />
              </div>
              <div className="row">
                <MaskPIIForm/>
              </div>
              {intakeForm.slackIntegration && (
                <div className="column">
                  <div className="row">
                    <div className="outline-input-wrapper fxFlex">
                      <span className="outline-input-label">Slack channel - Optional</span>
                      <input
                        className="outline-input"
                        placeholder="Slack channel..."
                        {...slackChannelNameRegister}
                        onChange={(e) => setSlackChannelName(e)}
                      />
                    </div>
                  </div>

                  <div className="row flex-end">
                    <div className="outline-input-wrapper column align-items-end">
                      <span className="outline-input-label">Save conversation to project details</span>
                      <Controller control={control} name='logConversations'
                                  render={({field}) => <Switch
                                    checked={field.value}
                                    {...field}
                                  />
                                  }
                      />
                    </div>
                  </div>
                </div>
              )}
              {intakeForm.widgets && intakeForm.widgets.map((widget: any, i: number) => {
                return (
                  <div key={i}>
                    <div className="header">
                      {widget.required &&
                          <span style={{color: "red"}}>* </span>
                      }
                      {widget.question}</div>

                    <div>
                      {
                        widget.type === MainFormTypeEnum.TEXT_INPUT &&
                        <div>
                          <WidgetInput index={i} getTextAreaValue={setWidget}/>
                          <small
                            className="form-error">{!widget.value && widget.required && showInputError && `${widget?.question} is required!`}</small>
                        </div>
                      }
                      {
                        widget.type === MainFormTypeEnum.MULTI_CHOICE &&
                        <div>
                          <WidgetChoice index={i}
                                        options={widget.options}
                                        choiceType={widget.multiple}
                                        getSelected={setWidget}/>
                          <small
                            className="form-error">{!widget.value?.length && widget.required && showInputError && `${widget?.question} is required!`}</small>
                        </div>
                      }
                      {
                        widget.type === MainFormTypeEnum.FILE_UPLOAD &&
                        <div>
                          <WidgetUpload index={i}
                                        options={widget.options}
                                        removeFile={removeFileFromWidget}
                                        getFileInfo={setUploadWidget}/>
                          <small
                            className="form-error">{!widget.value && widget.required && showInputError && `${widget?.question} is required!`}</small>
                        </div>
                      }
                    </div>
                  </div>

                )
              })}
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default React.forwardRef(CreateProject)
