import React, { useEffect, useState } from 'react';
import './knowledge.scss';
import { Button, Empty } from 'antd';
import KnowledgeGraph from './knowledge-node/knowledge-graph';
import KnowledgeApi from './api/knowledge-api';
import { IKnowledgeNode } from './model/knowledge-node.interface';
import { Loader } from '../../_shared/components/loader/loader';
import SvgAddBig from '../../assets/icons/js/addBig';
import { useNavigate } from 'react-router-dom';
import { useQuery, UseQueryResult } from 'react-query';
import qs from "query-string";
import HomeApi from "../home/api/home-api"
import { DebounceInput } from 'react-debounce-input';
import SvgSearch from '../../assets/icons/js/Search';
import { treeFilterIncludeSearch } from '../../_shared/helpers/tree-filter-include-search';
import { ITopNode } from "../home/models/top-nodes.interface";
import { usePopUpTutorial } from "../../core/hooks/use-pop-up-tutorial";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import PopUpTutorial from "../../_shared/components/pop-up-tutorial/pop-up-tutorial";

const Knowledge = () => {
  const navigate = useNavigate();
  const {popUpTutorial, onPopUpClose} = usePopUpTutorial('graphs');
  const [graphs, setGraphs] = useState<IKnowledgeNode[]>([]);
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const formMethods = useForm<{ graphs: IKnowledgeNode[] }>({
    defaultValues: {
      graphs: []
    }
  })

  const {fields, prepend, remove, insert} = useFieldArray({
    control: formMethods.control, name: 'graphs', keyName: 'key'
  })

  const knowledgeGraphs: UseQueryResult<ITopNode[], unknown> = useQuery("knowledgeGraphs", HomeApi.getKnowledgeGraphs);

  useEffect(() => {
    loadPage()
  }, []);

  function navigateToReadNode(id: number | undefined): void {
    if (id) {
      navigate({pathname: '/nodes/node', search: qs.stringify({id})});
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value.length) {
      formMethods.setValue('graphs', treeFilterIncludeSearch(graphs, 'name', e.target.value));
    } else {
      formMethods.setValue('graphs', graphs)
    }
  }

  const addNewParentNode = () => {
    const graph: IKnowledgeNode = {
      id: Math.floor(Math.random() * 50000),
      name: 'Untitled Knowledge Graph',
      nodes: [],
      open: true
    }

    prepend(graph)

    KnowledgeApi.createGraph(graph)
      .then(() => {
        loadPage(true);
      });
  }

  const loadPage = (create?: boolean) => {
    KnowledgeApi.getAll()
      .then((response: IKnowledgeNode[]) => {
        if (create) {
          response[0].open = true;
        }

        formMethods.setValue('graphs', response);
        setGraphs(response);
        setLoader(false)
      });
  }

  const removeGraphFromArray = (index: number) => {
    remove(index);
  }

  const addGraphToSpecifiedIndex = (index: number, graph: IKnowledgeNode) => {
    insert(index, graph);
  }

  return (
    <div className="knowledge-wrapper">
      <FormProvider {...formMethods}>
        {loader ? <Loader/> :
          <>
            {
              graphs.length === 0 ?
                <div className='knowledge-welcome'>
                  <h1>Welcome to Knowledge graphs</h1>
                  <h2>Connect your findings and turn insights into knowledge</h2>
                  <h3>Create a new Knowledge Graph to get started</h3>
                  <Button onClick={() => addNewParentNode()} className='row align-items-center' type='primary'>
                    <SvgAddBig width={18} height={18} color={"white"}/>
                    <span className="new-knowledge">New Knowledge Graph</span>
                  </Button>
                </div> :
                <>
                  <div className="header row align-items-end justify-space-between">
                    <div className="column">
                      <h2>Knowledge graphs</h2>
                      <h3>Connect your findings and turn insights into knowledge</h3>
                    </div>
                    <div className='search-knowledge-graphs'
                         style={{marginRight: knowledgeGraphs?.data && knowledgeGraphs.data.length >= 3 ? "12%" : 0}}>
                      <div className='relative'>
                        <DebounceInput
                          debounceTimeout={300}
                          style={{width: 362}}
                          value={searchValue}
                          placeholder="Search here"
                          className="outline-input"
                          onChange={handleSearchChange}
                        />
                        <span className='absolute' style={{top: 8, left: 340}}>
                        <SvgSearch/>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="body row">
                    <div className="knowledge-graphs-wrapper">
                      {fields?.length > 0 ? fields.map((graph: any, i) => {
                          return (
                            <KnowledgeGraph key={graph.key}
                                            index={i}
                                            addGraphToSpecifiedIndex={addGraphToSpecifiedIndex}
                                            removeGraphFromArray={removeGraphFromArray}
                                            loadGraphs={loadPage}/>
                          )
                        })
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                      }
                    </div>
                    {
                      knowledgeGraphs?.data &&
                      knowledgeGraphs?.data?.length >= 3 &&
                        <div className='knowledge-top-connections column'>
                          <h2>Top connections</h2>
                          {knowledgeGraphs.data.slice(0, 15).map((knowledgeNode: ITopNode) => {
                            return (
                              <div key={knowledgeNode.id}
                                   className="tag pointer"
                                   onClick={() => navigateToReadNode(knowledgeNode?.id)}>
                                {`${knowledgeNode.name} (${knowledgeNode.count})`}
                              </div>
                            )
                          })
                          }
                        </div>
                    }
                  </div>

                  <button data-testid={"new-knowledge-graph-button"} className="icon-button new-knowledge-button"
                          onClick={() => addNewParentNode()}>
                    <span className="plus-icon"/>
                  </button>
                </>
            }
          </>
        }
      </FormProvider>
      {
        popUpTutorial.open &&
        <PopUpTutorial open={popUpTutorial.open} onPopUpClose={onPopUpClose} title='Knowledge Graphs'>
          <>
            <p>Knowledge Graphs track and visualize relationships between your Projects and larger research themes
              or
              ideas.</p>
            <p>To get started, create a few Knowledge Graphs that are relevant to what your team is researching.
              Then
              link
              Key Findings from your Projects to these Knowledge Graphs. Once enough Key Findings have been
              linked to
              your
              Knowledge
              Graphs, you’ll begin to see how these themes relate to each other.</p>
            <p>By linking Key Findings from your Projects to various Knowledge Graphs, you’ll discover research
              gaps or
              relationships you might not have known existed.</p>
          </>
        </PopUpTutorial>
      }
    </div>
  );
}

export default Knowledge
