import React, { useEffect, useState } from 'react';
import { DatePicker, Select, TimePicker } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { usePrevious } from '../../../../../../../core/hooks/use-previous';
import { IOwner } from '../../../../../models/owner.interface';
import moment from "moment";

const {RangePicker} = TimePicker;

export function Details(props: any): JSX.Element {
  const [owners, setOwners] = useState<IOwner[]>([]);
  const [date, setDate] = useState<any>(null);
  const [time, setTime] = useState<any>();
  const [organizer, setOrganizer] = useState<number>();
  const [guests, setGuests] = useState<string>('');
  const [update, setUpdate] = useState<boolean>(false);
  const [title, setTitle] = useState(props?.details?.title);

  const prevTitle = usePrevious<string>(title);
  const prevGuests = usePrevious<string>(guests);
  const prevDate = usePrevious<string>(date);
  const prevTime = usePrevious<any>(time);
  const prevOrganizer = usePrevious<any>(organizer);

  useEffect(() => {
    if (props.details?.title) {
      const details = props.details;
      setTitle(details.title);
      setUpdate(true);
    }
  }, [props.details?.title]);

  useEffect(() => {
    if (props.details) {
      const details = props.details;

      setOwners(props.teamMembers ? props.teamMembers : []);
      setOrganizer(details.organizerId);
      setGuests(details.guests ? details.guests : '');
      setDate(details.date);
      setTime([moment(details.startTime), moment(details.endTime)]);
    }
  }, [props.details]);

  useEffect(() => {
    if (time && valuesHasChanged() && update) {
      const payload = {
        projectId: props.projectId,
        interviewId: props.interviewId,
        title,
        guests,
        date,
        startTime: time[0],
        endTime: time[1],
        organizerId: organizer,
      }

      props.socket.emit('editDetails', {...payload});
      setUpdate(false);
    } else {
      return;
    }

  }, [title, guests, date, time, organizer]);


  function valuesHasChanged(): boolean {
    if (time?.length
      && prevTime?.length
      && prevTitle === title
      && prevGuests === guests
      && prevDate === date
      && prevTime[0].toISOString() === time[0].toISOString()
      && prevTime[1].toISOString() === time[1].toISOString()
      && prevOrganizer === organizer) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="interview-form">
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Date</span>
        <DatePicker className="outline-input"
                    value={moment.utc(date, 'YYYY / MM / DD')}
                    onChange={(date: any) => {
                      setUpdate(true);
                      setDate(moment(date).set({hours: 0, minutes: 0}));
                    }}/>
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Time</span>
        <RangePicker use12Hours
                     format="hh:mm a"
                     value={time && time}
                     onChange={(time: any) => {
                       setUpdate(true);
                       setTime(time);
                     }}/>
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Organizer</span>
        <Select value={organizer}
                onChange={(id: number) => {
                  setUpdate(true);
                  setOrganizer(id);
                }}>
          {owners?.map((owner: any) => {
            return (
              owner.id &&
              <Select.Option key={owner.id}
                             value={owner.id}>
                {owner?.name}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Guests</span>
        <DebounceInput
          name="guests"
          className="outline-input"
          placeholder="Guests"
          debounceTimeout={600}
          element="textarea"
          value={guests}
          onChange={(e) => {
            setUpdate(true);
            setGuests(e.target.value);
          }}/>
      </div>
    </div>
  );
}
