import * as React from "react";

const SvgGuide = (props: any) => {
  return (
    <svg width={10} height={12} viewBox="0 0 10 12" {...props}>
      <title>Icon/SideNav/List 2</title>
      <g id="Nov-9-21" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01-My-page---Details-tab--Copy-5" transform="translate(-1132.000000, -146.000000)" fill="#545E6B">
          <g id="Group-5" transform="translate(1131.000000, 140.000000)">
            <g id="Icon/SideNav/List" transform="translate(0.000000, 6.000000)">
              <path
                d="M3,3 C2.17157288,3 1.5,2.32842712 1.5,1.5 C1.5,0.671572875 2.17157288,0 3,0 C3.82842712,0 4.5,0.671572875 4.5,1.5 C4.5,2.32842712 3.82842712,3 3,3 Z M3,7.5 C2.17157288,7.5 1.5,6.82842712 1.5,6 C1.5,5.17157288 2.17157288,4.5 3,4.5 C3.82842712,4.5 4.5,5.17157288 4.5,6 C4.5,6.82842712 3.82842712,7.5 3,7.5 Z M3,12 C2.17157288,12 1.5,11.3284271 1.5,10.5 C1.5,9.67157288 2.17157288,9 3,9 C3.82842712,9 4.5,9.67157288 4.5,10.5 C4.5,11.3284271 3.82842712,12 3,12 Z M6.75,0.75 L9.75,0.75 C10.1642136,0.75 10.5,1.08578644 10.5,1.5 C10.5,1.91421356 10.1642136,2.25 9.75,2.25 L6.75,2.25 C6.33578644,2.25 6,1.91421356 6,1.5 C6,1.08578644 6.33578644,0.75 6.75,0.75 Z M6.75,5.25 L9.75,5.25 C10.1642136,5.25 10.5,5.58578644 10.5,6 C10.5,6.41421356 10.1642136,6.75 9.75,6.75 L6.75,6.75 C6.33578644,6.75 6,6.41421356 6,6 C6,5.58578644 6.33578644,5.25 6.75,5.25 Z M6.75,9.75 L9.75,9.75 C10.1642136,9.75 10.5,10.0857864 10.5,10.5 C10.5,10.9142136 10.1642136,11.25 9.75,11.25 L6.75,11.25 C6.33578644,11.25 6,10.9142136 6,10.5 C6,10.0857864 6.33578644,9.75 6.75,9.75 Z"
                id="Shape"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgGuide;
