import React, { useState } from 'react';
import './repository-edit.scss'
import { ColumnsType } from "antd/lib/table";
import SvgDownload from "../../../assets/icons/js/download";
import { message, Spin, Table, Tooltip, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SvgTrash from "../../../assets/icons/js/Trash";
import FileTypePipe from "../../../_shared/helpers/file-type-pipe";
import { IRepoFile } from "../model/repo-file.interface";
import SvgUnlock from "../../../assets/icons/js/unlock";
import SvgLock from "../../../assets/icons/js/lock";
import {
  useFileProgressContext
} from "../../../_shared/components/uploader-popup/file-progress-context/file-progress-context";
import { useNavigate, useSearchParams } from "react-router-dom";
import repositoriesApi from "../api/repositories-api";
import { queryClient } from "../../../index";
import ProjectEditApi from "../../projects/project-edit/api/project-edit-api";
import Axios from "axios";
import { environment } from "../../../environment";
import SvgUpload3 from "../../../assets/icons/js/Upload3";
import UploaderPopUp from "../../../_shared/components/uploader-popup/uploader-popup";
import { useQuery } from "react-query";
import SvgArrowBack from "../../../assets/icons/js/ArrowBack";
import Pagination from "../../../_shared/components/pagination/pagination";
import { ConfirmationDialog } from "../../../_shared/components/confirmation-dialog/confirmation-dialog";
import { FileTypeEnum } from "../../projects/tabs/_shared/model/file-type.enum";
import SvgTick from "../../../assets/icons/js/Tick";
import DaysLeftDatePipe from "../../../_shared/helpers/days-left-date-pipe";
import { truncateTextInMiddle } from "../../../_shared/helpers/truncate-text-in-middle";

const RepositoryEdit = () => {
  const [selectedRows, setSelectedRows] = useState<any[]>
  ([]);
  const [deleteFilesLoader, setDeleteFilesLoader] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: selectedRows.length ?
        <div className='selected-rows-title-wrapper'>
          <span>{selectedRows.length} selected</span>
          <div className='selected-rows-btn' onClick={downloadFiles}><SvgDownload color={'#1b87e6'}/> Download</div>
          <div className='selected-rows-btn' onClick={openBulkArchiveConfirmationDialog}>
            {deleteFilesLoader ? <Spin indicator={<LoadingOutlined/>}/> : <SvgTrash color={'#1b87e6'}/>}
            &nbsp;Delete
          </div>
        </div> : "Filename",
      render: (file: IRepoFile) => {
        const nameArr = file.name.split(".");
        return <div className="row align-items-center" style={{gap: 10}}>
          <span> <FileTypePipe width={20} height={20} value={nameArr[nameArr.length - 1] as FileTypeEnum}/> </span>
          <span>{truncateTextInMiddle(file.name, 60)}</span>
        </div>
      }
      ,
      dataIndex: 'file',
      // sorter: !selectedRows.length
    },
    {
      title: <span className='column-title-to-be-hided'>Owner</span>,
      dataIndex: 'owner',
      width: '30%',
      render: (value: any) =>
        <span>{value?.firstName ? value.firstName + " " + value?.lastName : value.emailAddress}</span>,
    },
    {
      title: <span className='column-title-to-be-hided'>Date Added</span>,
      dataIndex: 'file',
      //sorter: !selectedRows.length,
      width: '10%',
      render: (file: any) => <span>{DaysLeftDatePipe(file.createdAt)}</span>,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "right",
      render: (id: number, record: any) => (
        <div className="action-hover-column row">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Download File">
              <button className="icon-button project-icon-button" onClick={() => downloadFile(record)}>
                <SvgDownload/>
              </button>
            </Tooltip>
            {/*<Tooltip placement="bottom" title="Edit File">*/}
            {/*  <button className="icon-button project-icon-button">*/}
            {/*    <SvgEdit/>*/}
            {/*  </button>*/}
            {/*</Tooltip>*/}
            <Tooltip placement="bottom" title="Move To Recycle Bin">
              <button className="icon-button project-icon-button"
                      onClick={() => openDeleteConfirmationDialog(record.file)}>
                <SvgTrash/>
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title={!record.privacy ? "Mark as public" : "Mark as private"}>
              <button className="icon-button project-icon-button" style={{marginLeft: 3}}
                      onClick={() => markAsPrivate(record.file.id, record.privacy)}>
                {
                  !record.privacy ? <SvgUnlock/> : <SvgLock/>
                }
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ]


  const {
    isUploading,
    filesBeingUploaded,
    accPercentage,
    uploadFinished,
    cancelFile,
    updateFilesAreBeingChanged,
    updateUploading,
    updateFilesBeingUploaded,
  } = useFileProgressContext();
  const navigate = useNavigate();
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<any>({visible: false, id: null});
  const [searchParams, setSearchParams] = useSearchParams();
  const id = Number(searchParams.get('id'));
  const page = parseInt(searchParams.get("page") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || "10");

  const files = useQuery(['repositoryFiles', id, page, pageSize], () => repositoriesApi.getRepositoryFiles(id, {
    page,
    pageSize
  }));

  const repositoryInfo = useQuery<{
    name: string,
    archivedFilesCount: number
  }>(['repositoryInfo', id], () => repositoriesApi.getRepositoryInfo(id));

  const openDeleteConfirmationDialog = (file?: any) => {
    setDeleteConfirmationDialog({
      visible: true,
      id: file?.id,
      okText: 'Archive',
      title: 'Archive',
      titleSec: 'You\'re about to archive this repository',
      content: `${file?.name || 'Untitled'}`,
      description: <>
        Continuing will remove the file from this list. You can restore it after deletion.
      </>
    });
  }

  function openBulkArchiveConfirmationDialog() {
    setDeleteConfirmationDialog({
      visible: true,
      id: null,
      ids: selectedRows.map((row) => row.file.id),
      okText: 'Archive',
      title: 'Archive',
      titleSec: 'You\'re about to archive these files',
      content: `${selectedRows.length} files`,
      description: <>
        Continuing will remove the files from this list. You can restore them after deletion.
      </>
    });
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setSearchParams((prev) => {
      prev.set('page', String(page));
      prev.set('pageSize', String(pageSize));
      return prev;
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
  };

  function archiveFile(fileId: number) {
    repositoriesApi.archiveFile(id, fileId).then(() => {
      setTimeout(() => {
        files.refetch();
        queryClient.invalidateQueries('repositories')
        message.success({
          content: "File has been successfully archived",
          className: "custom-success-message",
          icon: <SvgTick color={"#227700"}/>
        });
      }, 0)
    })
  }

  function markAsPrivate(fileId: number, privacy: 0 | 1) {
    repositoriesApi.markAsPrivate(id, fileId, privacy ? 0 : 1).then(() => {
      setTimeout(() => {
        message.success({
          content: privacy ? "File is marked as private!" : "File is marked as public!",
          className: "custom-success-message",
          icon: <SvgTick color={"#227700"}/>
        });
        files.refetch()
      }, 0)
    })
  }

  function archiveFiles(ids: number[]) {
    setDeleteFilesLoader(true);
    repositoriesApi.bulkArchiveFiles(id, ids).then(() => {
      setDeleteFilesLoader(false);
      setSelectedRows([]);
      files.refetch()
      message.success({
        content: "Files have been successfully archived",
        className: "custom-success-message",
        icon: <SvgTick color={"#227700"}/>
      });
    })
  }

  const downloadFile = (file: any): void => {
    ProjectEditApi.downloadFile(file.file.id)
      .then((response: any) => {
        const filenamePath: string = response;
        const fileName: string = `${file.file.name.toLowerCase()}`
        const downloadElement = document.createElement('a');
        document.body.appendChild(downloadElement); //required in FF, optional for Chrome
        downloadElement.href = filenamePath;
        downloadElement.download = fileName;
        downloadElement.target = "_self";
        downloadElement.click();
      });
  }

  function downloadFiles() {
    selectedRows.forEach((file: IRepoFile, index) => {
      downloadFile(file)
    })
  }


  const uploadFile = async (options: any) => {
    updateUploading(true);
    const {onSuccess, onError, file, onProgress} = options;

    const fmData = new FormData();
    let token = localStorage.getItem('access_token');

    const source = Axios.CancelToken.source();

    file.source = source;
    file.percent = 0;
    file.status = 'uploading';

    updateFilesBeingUploaded(file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        'Authorization': 'Bearer ' + token,
      },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        file.percent = percent;
        onProgress({percent: (event.loaded / event.total) * 100});
        updateFilesAreBeingChanged()
      },
      cancelToken: source.token
    };

    fmData.append('file', file)

    Axios.post(`${environment.backendUrl}/repository/files/${id}`, fmData, config).then((response) => {
      onSuccess("Ok");
      if (response.data) {
        file.status = 'done';
        files.refetch();
        queryClient.invalidateQueries('repositories')
        updateFilesAreBeingChanged()
      }

    }).catch((err) => {
      if (err?.message === 'canceled') {
        file.status = 'canceled'
      } else {
        file.status = 'error'
        message.error({
          content: "There has been a problem with your upload",
          className: "custom-error-message",
        });
      }
      onError({err})
    });
  }

  const uploadProps = {
    name: 'file',
    multiple: true,
    openFileDialogOnClick: false,
    customRequest: (options: any) => uploadFile(options),
    showUploadList: false
  }

  const onDeleteConfirmationDialog = (props: any) => {
    if (props.modal) {
      if (props.id) {
        archiveFile(props.id);
      }
      if (props.ids) {
        archiveFiles(props.ids);
      }
      onDialogCancel();
    }
  }

  const onDialogCancel = () => {
    setDeleteConfirmationDialog({visible: false})
  }

  const navigateToArchivedFiles = () => {
    navigate('/repositories/edit/archived?id=' + id);
  }


  return (
    <div>
      <div className="update-folder-modal-wrapper">
        <div className={"row align-items-center"} style={{gap: 5, marginBottom: 20}}>
          <button className="icon-button" onClick={() => navigate(-1)}><SvgArrowBack/></button>
          <h3>{repositoryInfo?.data?.name}</h3>
        </div>
        <div className='update-folder-modal-header'>
          <Upload data-testid={"upload-file"} {...uploadProps} openFileDialogOnClick={true}>
            <div className='file-repository-upload-button'>
              <SvgUpload3/> Upload
            </div>
          </Upload>
          <div className='file-repository-table-header-pagination row'>
            {
              files.data && files.data.pagination.totalElements > 0 &&
              <Pagination currentPage={page}
                          pageSize={pageSize}
                          showSizeChanger={true}
                          totalItems={files.data.pagination.totalElements}
                          onChange={handlePaginationChange}
              />
            }
            <div className='file-repository-archived' onClick={navigateToArchivedFiles}>
              {repositoryInfo?.data?.archivedFilesCount}
              <SvgTrash/>
            </div>
          </div>
        </div>
        <div>
          <div
            className={selectedRows.length ? 'file-repository-table file-repository-table-selected-rows' : 'file-repository-table'}>
            <Table
              rowKey={"id"}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              scroll={{y: 400}}
              pagination={false}
              loading={files.isLoading}
              dataSource={files?.data ? files.data.content : []}
              columns={columns}/>
          </div>
        </div>
      </div>
      {
        isUploading &&
        <UploaderPopUp
          fileListData={filesBeingUploaded}
          cancelFile={cancelFile}
          percent={accPercentage}
          name={'File Library'}
          uploadFinished={uploadFinished}
        />
      }
      {
        deleteConfirmationDialog.visible &&
        <ConfirmationDialog data={deleteConfirmationDialog}
                            onConfirm={onDeleteConfirmationDialog}
                            onCancel={onDialogCancel}/>

      }
    </div>
  );
};

export default RepositoryEdit;
