import React, { ChangeEvent, useContext } from 'react';
import './choice.scss';
import SvgCancel from '../../../../../../../../assets/icons/js/Cancel';
import { DebounceInput } from 'react-debounce-input';
import { IWidget } from '../../../model/project-type.interface';
import ProjectTypeContext from '../../../store/project-type-context';

const Choice = (props: { widget: IWidget, index: number }) => {
  const widget: IWidget = props.widget;
  const projectTypeContext = useContext(ProjectTypeContext);

  return (
    <div className="choice">
      {widget.options && widget.options.map((option: string, i: number) => {
        return (
          <div className="options" key={i}>
            <div className="option-input-wrapper">
              <DebounceInput
                name="option"
                className="option-input"
                placeholder="Option"
                autoComplete="off"
                debounceTimeout={600}
                value={option}
                onChange={(e: ChangeEvent<HTMLInputElement>) => projectTypeContext.optionValueChanged(widget, props.index, i, e.target.value)}/>

              {widget.multiple ?
                <div className="dummy-circle-wrapper">
                  <div className="dummy-square"/>
                </div>
                :
                <div className="dummy-circle-wrapper">
                  <div className="dummy-circle"/>
                </div>
              }
              {
                widget?.options?.length !== 1 &&
                  <div className="delete-icon"
                       onClick={() => projectTypeContext.deleteWidgetOption(widget, i, props.index)}>
                      <SvgCancel/>
                  </div>
              }
            </div>
            <small className="form-error">
              {projectTypeContext.isOptionValueSame.index === i && projectTypeContext.isOptionValueSame.isSame ? 'Option question should be unique' : ''}
            </small>
          </div>
        );
      })}
      <div className="add-option" onClick={() => projectTypeContext.addWidgetOption(widget, props.index)}>
        <span>Add Option</span>
      </div>
    </div>
  )
}
export default Choice
