import React, { useEffect, useRef, useState } from 'react';
import SvgNugget from '../../../../../../../assets/icons/js/nugget';
import SvgEdit from '../../../../../../../assets/icons/js/Edit';
import SvgTrash from '../../../../../../../assets/icons/js/Trash';
import { useOnClickOutside } from '../../../../../../../core/hooks/use-outside-click';
import { getNuggetByEntityMapId } from '../../../../../../../_shared/components/draft-js/components/helpers/entity';

export function DisplayNugget(props: any): JSX.Element {
  let component = useRef(null);
  const [entityData, setEntityData] = useState<any>();
  const [visibleDropDown, setVisibleDropDown] = useState<boolean>(false);

  useOnClickOutside(component, () => {
    if (visibleDropDown) {
      setVisibleDropDown(false);
    }
  });

  useEffect(() => {
    if (!entityData) {
      setEntityData(getNuggetByEntityMapId(props.editorState, props.nugget?.entityMapId));
    }
  }, []);

  function openDropDown(value: boolean): void {
    if (!props?.readOnly && entityData?.entityKey) {
      setVisibleDropDown(value);
    }
  }

  function openEvidenceDialog(value: 'edit' | 'delete'): void {
    setTimeout(() => {
      setVisibleDropDown(false);
    }, 0);


    props.openEvidenceDialog({[value]: true, entityData, entityMapId: props.nugget?.entityMapId});
  }

  return (
    <>
      <div ref={component}
           data-testid={"open-dropdown"}
           className={entityData?.entityKey && !props?.readOnly ? "pointer display-highlight-card" : "display-highlight-card"}
           onClick={() => openDropDown(true)}>
        <SvgNugget key={props.index}/>
        {
          visibleDropDown &&
            <div className="nugget-drop-down">
                <div className="item icon-button" onClick={() => openEvidenceDialog('edit')}>
                    <SvgEdit/>
                    <span>Edit</span>
                </div>
                <div className="item icon-button"
                     onClick={() => openEvidenceDialog('delete')}>
                    <SvgTrash/>
                    <span>Remove</span>
                </div>
            </div>
        }
      </div>
    </>
  );
}
