import React from 'react';
import { Link } from "react-router-dom";
import './recent-study-box.scss'
import { IProjectTable } from "../../../projects/models/project-table.interface";
import FormattedDate from "../../../../_shared/helpers/formatted-date";

const RecentStudyBox = ({project}: { project: IProjectTable }) => {
  return (
    <div className="recent-study-box">
      <div>
        <Link to={"/projects/edit?id=" + project.id} className="project-name">
          <h3>{project.name}</h3>
        </Link>

        {/*<p*/}
        {/*  style={{overflow: 'hidden'}}>By {project.owner?.displayName}</p>*/}
      </div>
      <div>
        {/*<p>{study.completedCount} of {study.responseCount} responses </p>*/}
      </div>
      <div>
        <p><FormattedDate date={project.createdAt} formatString="MMM d, yyyy"/></p>
        <div className="row flex-wrap" style={{gap: 8}}>
        </div>
      </div>
    </div>
  );
};

export default RecentStudyBox;
