import { EditorState, Modifier, SelectionState } from "draft-js";

const removeUnderlineAndBoldStyle = (editorState: EditorState, text: string) => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  let newContentState = contentState;

  // Iterate through the blocks in the editor
  blockMap.forEach((block: any) => {
    const blockText = block.getText();
    const blockKey = block.getKey();

    // Find where the generatedText appears in this block
    const textToRemoveStart = blockText.indexOf(text);

    if (textToRemoveStart !== -1) {
      const textToRemoveEnd = textToRemoveStart + text.length;

      // Create selection state around the text where the styles should be removed
      const selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: textToRemoveStart,
        focusOffset: textToRemoveEnd,
      });

      // Remove both UNDERLINE and BOLD styles directly
      ['UNDERLINE', 'BOLD'].forEach((style) => {
        newContentState = Modifier.removeInlineStyle(newContentState, selectionState, style);
      });
    }
  });

  // Push the new content state into the editor state
  return EditorState.push(editorState, newContentState, 'change-inline-style');
};

export { removeUnderlineAndBoldStyle };