import * as React from "react";

const SvgHelp = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 11a1 1 0 100 2 1 1 0 000-2zm0-8C6.343 3 5 4.108 5 6h2c0-.693.372-1 1-1 .652 0 1 .282 1 1 0 .606-.085.778-.524 1.091C7.414 7.851 7 8.937 7 10h2c0-.478.182-.955.64-1.282C10.61 8.023 11 7.242 11 6c0-1.926-1.325-3-3-3z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgHelp;
