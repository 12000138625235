import React from 'react';
import './import-survey-details.scss';
import { Button, Checkbox, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SvgInsightDesk from "../../../../../../../../assets/icons/js/InsightDesk";
import SvgFolder from "../../../../../../../../assets/icons/js/Folder";
import Switch from "../../../../../../../../_shared/components/switch/switch";
import MaskPIIForm from "../../../../../../../../_shared/components/mask-pii-form/mask-pii-form";

type IProps = {
  totalFolders: number;
  totalSurveys: number;
  loading: boolean;
  updateAutoSync: (e: boolean) => void;
  autoSync: boolean;
  updateShowFolderSelection: (e: boolean) => void;
};

const ImportSurveyDetails = (props: IProps) => {
  return (
    <div className="import-details-wrapper">
      <div className="import-details">
        <div className="row align-items-center" style={{gap: 20}}>
          <SvgFolder width={24} height={24} color={"#1B3380"}/>
          <div>
            <h3>
              {
                props.loading &&
                <Spin style={{marginRight: 5}} indicator={<LoadingOutlined style={{fontSize: 12}} spin/>}/>
              }
              {props.totalFolders} folders will be imported
            </h3>
            <p>
              Paving the way for enhanced organization and accessibility of your data.
            </p>
          </div>
        </div>
        <div className="row align-items-center" style={{gap: 20}}>
          <SvgInsightDesk width={24} height={24} color={"#1B3380"}/>
          <div>
            <h3>
              {
                props.loading &&
                <Spin style={{marginRight: 5}} indicator={<LoadingOutlined style={{fontSize: 12}} spin/>}/>
              }
              {props.totalSurveys} Surveys will be synced
            </h3>
            <p>
              Take advantage of this powerful synchronization feature to enhance your data management capabilities.
            </p>
          </div>
        </div>
        <div className="row">
          <Button type="link" disabled={props.totalFolders < 2}
                  style={{paddingLeft: 0}}
                  onClick={() => props.updateShowFolderSelection(true)}>Customize
            folders selection</Button>
        </div>
        <div className="row align-items-center">
          <Switch name="autoSync" checked={props.autoSync} onChange={props.updateAutoSync}/>
          <span style={{marginLeft: 10, fontSize: 14, color: "#545e6b"}}>
              Auto-sync all new surveys and folders to InsightsHub in the future
          </span>
        </div>
        <MaskPIIForm/>
      </div>
    </div>
  );
};

export default ImportSurveyDetails;
