import * as React from "react";

const SvgPeople = (props: any) => {
  return (
    <svg width={16} height={12} viewBox="0 0 16 12" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-163 -554) translate(163 548) translate(0 6)">
          <g fill={props.color ? props.color : '#545E6B'}>
            <path id="fk61nuje9a"
                  d="M8 4c1.657 0 3 1.343 3 3 0 .676-.223 1.3-.6 1.8.914.686 1.525 1.756 1.594 2.97L12 12H4c0-1.309.629-2.471 1.6-3.2C5.224 8.3 5 7.675 5 7c0-1.657 1.343-3 3-3zm4-4c1.657 0 3 1.343 3 3 0 .676-.223 1.3-.6 1.8.914.686 1.525 1.756 1.594 2.97L16 8l-3.1.001c.065-.323.1-.658.1-1.001 0-2.373-1.653-4.36-3.871-4.872C9.502.896 10.646 0 12 0zM4 0c1.354 0 2.498.897 2.872 2.13C4.653 2.64 3 4.626 3 7c0 .343.035.678.1 1.001L0 8c0-1.309.629-2.471 1.6-3.2C1.224 4.3 1 3.675 1 3c0-1.657 1.343-3 3-3z"/>

          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgPeople;
