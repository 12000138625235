import React, { useEffect } from 'react';
import InformIconsPipe from '../../helpers/inform-icons-pipe';
import './project-type-card.scss';
import FormattedDate from '../../helpers/formatted-date';


export function ProjectTypeCard(props: {
  projectType: any,
  projectTypeIcon?: string,
  projectTypeColor?: string
}): JSX.Element {
  const {projectTypeIcon, projectTypeColor, projectType} = props;


  return (
    <div className="project-type-card"
         style={{borderLeftColor: projectTypeColor ? projectTypeColor : projectType.color}}>
      <div className="card-body">
        <div className="card-image">
          <InformIconsPipe icon={projectTypeIcon ? projectTypeIcon : projectType.icon} width={28} height={28}
                           color={projectTypeColor ? projectTypeColor : projectType.color}/>
        </div>
        <span className="card-detail">
          {projectType?.title?.length >= 45 ?
            projectType?.title?.substring(0, 45) + '...'
            :
            projectType?.title
          }
          <span className="time">{projectType.updatedAt &&
              <FormattedDate date={projectType.updatedAt} formatString="MMM d, yyyy"/>
          }
          </span>
        </span>
      </div>
    </div>
  );
}
