import React, { useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { IWorkflow, IWorkflowStatus } from './model/workflow.interface';
import "./workflows.scss"
import SvgEdit from '../../../../assets/icons/js/Edit';
import SvgTrash from '../../../../assets/icons/js/Trash';
import SvgArrowBack from '../../../../assets/icons/js/ArrowBack';
import { useQuery } from 'react-query';
import workflowsApi from './api/workflows-api';
import WorkflowsApi from './api/workflows-api';
import { WorkflowDialog } from './workflow-dialog/workflow-dialog';
import { WorkflowStageEnum } from "./model/workflow-stage.enum";
import Switch from "../../../../_shared/components/switch/switch";

export function Workflows(): JSX.Element {
  const [loadingCustomWorkflowToggle, setLoadingCustomWorkflowToggle] = useState<boolean>(false);

  const columns: any = [
    {
      title: "Name",
      key: "Name",
      dataIndex: "name",
      width: 250,
      render: (value: string) => <div className='link'>{value}</div>
    },
    {
      title: "Status",
      key: "statuses",
      dataIndex: "statuses",
      render: (value: IWorkflowStatus[]) => {
        const renderByStage = (stage: WorkflowStageEnum) =>
          value
            .filter((status) => status.stage === stage)
            .map((status, i) => renderStatus(status, i, value.length));

        const queued = renderByStage(WorkflowStageEnum.QUEUED);
        const active = renderByStage(WorkflowStageEnum.ACTIVE);
        const closed = renderByStage(WorkflowStageEnum.CLOSED);

        return queued.concat(active, closed);
      },
      width: 800,
      className: "status-column",
      //ellipsis: true,
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: "35px",
      align: "right",
      render: (workflow: IWorkflow, i: number) => (
        <div className="action-hover-column" key={i}>
          <div className="action-buttons d-flex">
            <Tooltip placement="bottom" title="Change Program">
              <button className="icon-button" style={{marginRight: 10}} onClick={() => addEditWorkflow(workflow)}>
                <SvgEdit/>
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title="Move To Recycle Bin">
              <button className="icon-button disabled" style={{marginRight: 10}}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  function renderStatus(status: IWorkflowStatus, index: number, length: number) {
    if (status.order === length - 1) return <div className='status-color-name' key={status.id}>
      <div className='color-circle' style={{backgroundColor: status.color}}>
      </div>
      <div className='status-name'>{status.name}</div>
    </div>
    return <div className='status-color-name' key={status.id}>
      <div className='color-circle' style={{backgroundColor: status.color}}/>
      <div className='status-name'>{status.name}</div>
      <SvgArrowBack width={12} color={"#9B9B9B"} style={{transform: 'rotate(180deg)'}}/>
    </div>
  }

  const workflow = useQuery('workflows', () => workflowsApi.getAll());

  const [workflowDialog, setWorkflowDialog] = useState<{ visible: boolean, data?: IWorkflow | null }>({
    visible: false,
    data: null
  });

  const addEditWorkflow = (workflow?: IWorkflow) => {
    if (workflow?.id) {
      setWorkflowDialog({visible: true, data: workflow});
    } else {
      setWorkflowDialog({visible: true, data: null});
    }
  }

  const closeWorkflowDialog = () => {
    setWorkflowDialog({visible: false});
    workflow.refetch();
  }

  const toggleCustomWorkflow = (value: boolean) => {
    setLoadingCustomWorkflowToggle(true);

    WorkflowsApi.toggleCustomWorkflow(value)
      .then(() => {
        setLoadingCustomWorkflowToggle(false);
        workflow.refetch();
      });
  }

  return (
    <div className="d-flex align-items-start column">
      <div>
        <div className="row align-items-center justify-space-between">
          <Button type='primary' onClick={() => addEditWorkflow()}>Add workflow</Button>
          <div className="row align-items-center" style={{gap: 10}}>
            <span style={{color: '#9b9b9b', fontSize: 15}}>Custom workflows: </span>
            <Switch onChange={(checked: boolean) => toggleCustomWorkflow(checked)}
                    loading={loadingCustomWorkflowToggle}
                    name={"customWorkflows"}
                    checked={workflow.data?.customWorkflowEnabled}/>
          </div>
        </div>
        <Table dataSource={workflow?.data?.workflows}
               loading={workflow.isLoading}
               columns={columns}
               pagination={false}
               style={{marginTop: 20}}
               rowKey="id"/>
      </div>

      {
        workflowDialog.visible &&
        <WorkflowDialog visible={workflowDialog.visible}
                        onCancel={closeWorkflowDialog}
                        data={workflowDialog.data}/>
      }
    </div>
  );
}
