import { Loader } from '../../../../../../_shared/components/loader/loader';
import './google-drive-integration.scss';
import SvgArrowBack from '../../../../../../assets/icons/js/ArrowBack';
import { Button, Tabs } from 'antd';
import { AiOutlinePoweroff } from 'react-icons/all';
import React, { useEffect, useState } from 'react';
import GoogleDriveSettingsTab from './tabs/settings/google-drive-settings-tab';
import GoogleDriveRequirementsTab from './tabs/google-drive-requirements-tab';
import GoogleDriveDescriptionTab from './tabs/google-drive-description-tab';
import SvgGoogleDrive from '../../../../../../assets/icons/js/googleDrive';
import { environment } from '../../../../../../environment';
import GoogleDriveIntegrationApi from './api/google-drive-integration-api';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import SvgSignInGoogle from '../../../../../../assets/icons/js/SvgSignInGoogle'
import qs from 'query-string';

const TabPane = Tabs.TabPane;

export default function GoogleDriveIntegration() {
  const location = useLocation();
  const urlParam: any = qs.parse(location?.search);
  const navigate: NavigateFunction = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [installedLoading, setInstalledLoading] = useState<boolean>(false);
  const [googleDriveInstalled, setGoogleDriveInstalled] = useState<boolean>(false);

  useEffect(() => {
    isInstalled();
    install();
  }, []);

  function navigateBack(): void {
    navigate('/settings?tab=5');
  }

  function uninstall(): void {
    GoogleDriveIntegrationApi.uninstall()
      .then(() => {
        setGoogleDriveInstalled(false);
        setLoader(false);
      });
  }

  function install(): void {
    const code: string | null = urlParam?.code;
    console.log('code -> ', code)

    if (!code || !environment.google_redirect_uri) {
      return;
    }

    GoogleDriveIntegrationApi
      .install(code, environment.google_redirect_uri)
      .then(() => {
        setGoogleDriveInstalled(true);
        navigate('/settings/google-drive-integration');
      });
  }

  function connectToDrive(): void {
    window.location.href =
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.google_client_id}&response_type=code&prompt=consent&access_type=offline&redirect_uri=${environment.google_redirect_uri}&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/docs`
  }

  function isInstalled(): void {
    GoogleDriveIntegrationApi.isInstalled()
      .then((response) => {
        if (response) {
          // get smth
        }
        setInstalledLoading(false);
        setGoogleDriveInstalled(response);
      });
  }

  return (
    <>
      {loader ? <Loader/>
        :
        <div className="google-drive-integration">
          <div className="header row">
            <div className="column align-items-center back-button">
              <button className="icon-button back" onClick={() => navigateBack()}>
                <SvgArrowBack/>
              </button>
            </div>
            <div className="column">
              <div className="row align-items-center">
                <div className="d-flex logo">
                  <SvgGoogleDrive/>
                </div>
                <h2>Google Drive Integration</h2>
              </div>
              <div className="row">
                  <span className="description">
                    Bring your files from Google Drive into InsightsHub projects in just a couple of clicks.
                  </span>
              </div>
              <div className="row button-wrapper">
                {
                  installedLoading ?
                    <Button type="primary" className="integration-button" icon={<AiOutlinePoweroff/>} loading/>
                    :
                    <>
                      {googleDriveInstalled ?
                        <Button type="primary" className="integration-button"
                                onClick={() => uninstall()}>Uninstall</Button>
                        :
                        <Button className="install-button"
                                onClick={() => connectToDrive()}>
                          <div className="google-icon">
                            <div className="google-icon-wrapper">
                              <div className="svg-signin-google">
                                <div style={{width: 18, height: 18}}>
                                  <SvgSignInGoogle/>
                                </div>
                              </div>
                              <span className="text-signin-google">
                                  <span>Sign in with Google</span>
                                </span>
                            </div>
                          </div>
                        </Button>
                      }
                    </>
                }
              </div>
            </div>
          </div>
          <div className="body">
            <Tabs>
              <TabPane tab="Description" key="1">
                <GoogleDriveDescriptionTab/>
              </TabPane>
              <TabPane tab="Requirements" key="2">
                <GoogleDriveRequirementsTab/>
              </TabPane>
              <TabPane tab="Settings" key="3">
                <GoogleDriveSettingsTab/>
              </TabPane>
            </Tabs>
          </div>
        </div>
      }
    </>
  )
}
