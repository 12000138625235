import React from 'react'

const SvgArrowUpNoFill = (props: any) => {
  return (
    <svg width={14} height={10} viewBox="0 0 14 10" {...props}>
      <g transform="matrix(0.17 0 0 -0.17 6.92 4.17)">
        <path strokeWidth="1.5" vectorEffect="non-scaling-stroke" fill="none" transform=" translate(-30, -20)"
              d="M 0 0 L 30 40 L 60 0" strokeLinecap="round"
              stroke={props.color ? props.color : "#d8d8d8"}/>
      </g>
    </svg>
  )
}

export default SvgArrowUpNoFill
