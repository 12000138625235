import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import './customize-table-dialog.scss';
import { CustomizeTableKeyMetrics } from "./components/customize-table-key-metrics";
import { CustomizeTableBasicView } from "./components/customize-table-basic-view";
import { CustomizeTableProgramMetrics } from "./components/customize-table-program-metrics";
import { ICustomizeTable, TableViewEnum } from "../models/customize-table.interface";
import { Loader } from "../../../_shared/components/loader/loader";
import { TableView } from "./components/table-view";
import cloneDeep from 'lodash/cloneDeep';

const RenderTableView = {
  [TableViewEnum.BASIC_VIEW]: CustomizeTableBasicView,
  [TableViewEnum.KEY_METRICS_VIEW]: CustomizeTableKeyMetrics,
  [TableViewEnum.PROGRAM_METRICS_VIEW]: CustomizeTableProgramMetrics,
};

type ComponentType = {
  open: boolean;
  onCancel: () => void,
  customizeTableData: ICustomizeTable | undefined;
  apply: (data: ICustomizeTable | undefined) => void
}

export function CustomizeTableDialog(props: ComponentType): JSX.Element {
  const [data, setData] = useState<ICustomizeTable>();

  useEffect(() => {
    setData(cloneDeep(props.customizeTableData));
  }, []);

  const CustomTable = RenderTableView[data?.view || TableViewEnum.BASIC_VIEW];

  const onDataChanged = (newData: any) => {
    const constructNewData: ICustomizeTable = {
      ...data,
      ...newData
    }

    setData(constructNewData);
  }

  const onApply = () => {
    props.apply(data);
  }

  return (
    <Modal
      title={`Customize table`}
      open={props.open}
      onCancel={() => (props.onCancel())}
      centered
      destroyOnClose={true}
      width={620}
      footer={[
        <Button key="cancel" type="link" onClick={() => props.onCancel()}>
          Cancel
        </Button>,
        <Button key="submit"
                type="primary"
                onClick={() => onApply()}>
          Apply
        </Button>,
      ]}>
      <div className="customize-table-dialog">
        <div className="row">
          <h3>Preset views</h3>
        </div>
        <div className="column">
          {data
            ? <>
              <TableView view={data?.view} onChange={(viewValue: TableViewEnum) => onDataChanged({view: viewValue})}/>
              <CustomTable data={data} onChange={onDataChanged}/>
            </>
            : <Loader/>
          }
        </div>
      </div>
    </Modal>
  );
}
