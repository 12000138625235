import React from 'react';
import './pagination.scss';
import { Divider, Dropdown } from "antd";
import SvgArrowDown from "../../../assets/icons/js/ArrowDown";
import SvgPaginationArrowForward from "../../../assets/icons/js/PaginationArrowForward";
import SvgPaginationArrowBackward from "../../../assets/icons/js/PaginationArrowBackward";
import SvgArrowUp from "../../../assets/icons/js/ArrowUp";

type Page = {
  pageIndex: number;
  label: string;
};

type IProps = {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  onChange: (page: number, pageSize: number) => void;
  pageSizeOptions?: number[];
  showSizeChanger?: boolean;
};

const Pagination: React.FC<IProps> = ({
                                        totalItems,
                                        currentPage,
                                        pageSize,
                                        onChange,
                                        pageSizeOptions = [5, 10, 20, 50],
                                        showSizeChanger = false
                                      }: IProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const pagesCount = Math.ceil(totalItems / pageSize);

  const pages: Page[] = Array.from({length: pagesCount}, (_, i) => {
    const start = i * pageSize + 1;
    const end = Math.min(start + pageSize - 1, totalItems);
    return {
      pageIndex: i + 1,
      label: `${start} - ${end}`,
    };
  });

  const handlePageChange = (page: number) => {
    if (page < 1 || page > pagesCount) {
      return;
    }
    onChange(page, pageSize);
  };

  const handlePageSizeChange = (size: number) => {
    onChange(1, size);
  };

  const getCurrentPage = (): Page | undefined => {
    return pages.find((page) => page.pageIndex === currentPage);
  };

  return (
    <div className="pagination-wrapper" data-testid={"pagination"}>
      <div data-testid="pagination-back-button"
           className={`pagination-back-button ${currentPage === 1 ? 'pagination-back-button-disabled' : ''}`}
           onClick={() => handlePageChange(currentPage - 1)}>
        <SvgPaginationArrowBackward/>
      </div>
      <Dropdown
        onOpenChange={(open) => {
          setIsDropdownOpen(open)
        }}
        overlay={
          <div className="pagination-dropdown">
            <p className="pagination-label">Showing</p>
            <div className="pagination-pages-wrapper pagination-pages-wrapper-scroll">
              {pages.map((page) => (
                <div
                  key={page.pageIndex}
                  className={`pagination-page-label ${currentPage === page.pageIndex ? 'pagination-page-label-active' : ''}`}
                  onClick={() => handlePageChange(page.pageIndex)}
                >
                  {page.label}
                </div>
              ))}
            </div>

            {
              showSizeChanger &&
              <>
                <div className="hr"/>
                <p className="pagination-label">Show up to</p>
                <div className="pagination-pages-wrapper">
                  {pageSizeOptions.map((size) => (
                    <div
                      key={size}
                      className={`pagination-page-label ${pageSize === size ? 'pagination-page-label-active' : ''}`}
                      onClick={() => handlePageSizeChange(size)}
                    >
                      {size}
                    </div>
                  ))}
                </div>
              </>
            }
          </div>
        }
      >
        <div className="pagination-dropdown-button">
          <span>
            {getCurrentPage()?.label} of {totalItems}
          </span>
          {
            isDropdownOpen
              ? <SvgArrowUp width={8} height={6}/>
              : <SvgArrowDown width={8} height={6}/>
          }
        </div>
      </Dropdown>
      <div data-testid="pagination-forward-button"
           className={`pagination-back-button ${currentPage === pagesCount ? 'pagination-back-button-disabled' : ''}`}
           onClick={() => handlePageChange(currentPage + 1)}>
        <SvgPaginationArrowForward/>
      </div>
    </div>
  );
};

export default Pagination;
