import * as React from "react";

const SvgRestoreTrash = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        fill={props.color ? props.color : '#545E6B'}>
        <path d="M12 1v2h2v2h-1v10H3V5H2V3h2V1h8zM8 4L5 8h2v4h2V8h2L8 4z"
              transform="translate(-1155 -387) translate(1155 387)"/>
      </g>
    </svg>
  );
}

export default SvgRestoreTrash;
