import React from 'react';
import { Form } from 'antd';

export const ExcelEditableContext = React.createContext(null);

export const ExcelEditableRow: React.FC = ({...props}) => {
  const [form]: any = Form.useForm();


  return (
    <Form form={form} component={false}>
      <ExcelEditableContext.Provider value={form}>
        <tr {...props} />
      </ExcelEditableContext.Provider>
    </Form>
  );
};
