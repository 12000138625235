import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider, Input, message, Modal, notification, Select } from "antd";
import './project-details-dialog.scss'
import { IProject } from "../../models/project.interface";
import SvgArrowDown from "../../../../assets/icons/js/ArrowDown";
import { Controller, useForm, useWatch } from "react-hook-form";
import DaysLeftDatePipe from "../../../../_shared/helpers/days-left-date-pipe";
import { getInstance } from "../../../../_shared/helpers/domain-instance";
import GlobalContext from "../../../../store/global-context";
import DeskSettingsApi from "../../../settings/api/settings-api";
import { IProgram } from "../../../settings/settings-tabs/taxonomy/programs/model/program.interface";
import { useQuery } from "react-query";
import HomeApi from "../../../home/api/home-api";
import SvgExternalTool from "../../../../assets/icons/js/ExternalTool";
import InformIconsPipe from "../../../../_shared/helpers/inform-icons-pipe";
import projectEditApi from "../api/project-edit-api";
import { Loader } from "../../../../_shared/components/loader/loader";
import SvgFolder from "../../../../assets/icons/js/Folder";
import SettingsApi from "../../../settings/api/settings-api";
import { IFolder } from "../../../settings/settings-tabs/taxonomy/folders/model/folder.interface";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import SvgTick from "../../../../assets/icons/js/Tick";
import Switch from "../../../../_shared/components/switch/switch";

type ProjectDetailsDialogProps = {
  data: {
    project: IProject,
    open: boolean,
  }
  onCancel: (data?: { refetch: boolean }) => void
}
const ProjectDetailsDialog = (props: ProjectDetailsDialogProps) => {
  const {project} = props.data;
  const {user} = useContext(GlobalContext);
  const copyProjectIdRef = useRef(null);

  const schema = yup.object({
    title: yup.string().required('Title is required!'),
    programId: yup.number().required('Program is required!'),
    folderId: yup.number().required('Folder is required!'),
    projectTypeId: yup.number().required('Project Type is required!'),
    contactEmailAddress: yup.string().email('Invalid email!').optional()
  });

  console.log('project', project)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors}
  } = useForm<any>({
    defaultValues: {
      title: project.name,
      programId: project.program.id,
      projectTypeId: project.projectType.id,
      folderId: project?.folder?.id,
      contactEmailAddress: project.contactEmailAddress,
    },
    resolver: yupResolver(schema)
  })


  const [apiLoading, setApiLoading] = useState(false);
  const title = useWatch({control, name: 'title'})

  const programs = useQuery('allPrograms', () => DeskSettingsApi.getPrograms());
  const projectTypes = useQuery('projectTypes', () => HomeApi.getPublishedForms());

  const programId = useWatch({control, name: 'programId'})
  const folders = useQuery(['folders', programId], () => SettingsApi.getFolders(programId));


  const copyProjectId = (e: any) => {
    if (!e.target.value && !copyProjectIdRef.current) {
      return;
    }

    if (copyProjectIdRef.current) {
      // @ts-ignore
      copyProjectIdRef.current?.select();
    }

    navigator.clipboard.writeText(e.target.value);

    message.success({
      content: 'Project URL copied to clipboard',
      className: 'custom-success-message',
      icon: <SvgTick color={"#227700"}/>
    })
  }

  const onSave = (data: {
    title: string,
    programId: number,
    projectTypeId: number,
    folderId: number,
  }) => {
    setApiLoading(true)
    projectEditApi.saveProjectDetails(props.data.project.id, data).then(() => {
      setApiLoading(false)
      props.onCancel({refetch: true})
    })
  }

  return (
    <Modal title='Project Details'
           open={props.data.open}
           cancelButtonProps={{type: "link"}}
           width={600}
           onOk={handleSubmit(onSave)}
           okButtonProps={{loading: apiLoading}}
           okText={"Save"}
           onCancel={(e) => props.onCancel()}>
      <>
        <div className="project-details-dialog-wrapper">
          {
            (programs.isLoading || projectTypes.isLoading) ? <Loader/> :
              <div className="project-details-form-wrapper">
                <div className="outline-input-wrapper relative">
                  <div className="outline-input-label">
                    Project Name
                  </div>
                  <textarea style={{resize: "none"}}
                            {...register('title')}
                            maxLength={120}
                            className="outline-input"
                            placeholder="Enter project name"
                            rows={3}/>
                  <div className="character-count absolute" style={{bottom: 0, right: 5}}>
                    {title?.length ?? 0}/120
                  </div>
                  <ErrorMessage errors={errors} name={'title'} render={({message}) => <small
                    className="form-error">{message}</small>}/>
                </div>
                <div className="outline-input-wrapper">
                  <div className="outline-input-label">
                    Program
                  </div>
                  <Controller name={'programId'} control={control}
                              render={({field}) =>
                                <Select style={{width: "100%"}} {...field}
                                        suffixIcon={<SvgArrowDown/>}>
                                  {programs.data?.content.map((program: IProgram) => <Select.Option
                                    value={program.id} key={program.id}>{program.name}
                                  </Select.Option>)}
                                </Select>}
                  />
                  {/*<ErrorMessage errors={errors} name={'programId'} render={({message}) => <small*/}
                  {/*  className="form-error">{message}</small>}/>*/}
                </div>
                <div className="outline-input-wrapper">
                  <div className="outline-input-label">
                    Folder
                  </div>
                  <Controller name={'folderId'} control={control}
                              render={({field}) =>
                                <Select style={{width: "100%"}} {...field}
                                        placeholder={"Select folder"}
                                        suffixIcon={<SvgArrowDown/>}>
                                  {folders.data?.content.map((folder: IFolder) => <Select.Option
                                    value={folder.id} key={folder.id}> <SvgFolder width={14}
                                                                                  height={14}/> &nbsp; {folder.name}
                                  </Select.Option>)}
                                </Select>}
                  />
                  <ErrorMessage errors={errors} name={'folderId'} render={({message}) => <small
                    className="form-error">{message}</small>}/>
                </div>
                <div className="outline-input-wrapper">
                  <div className="outline-input-label">
                    Project type
                  </div>
                  <Controller control={control}
                              name={'projectTypeId'}
                              render={({field}) =>
                                <Select {...field}
                                        style={{width: "100%"}}
                                        suffixIcon={<SvgArrowDown/>}>
                                  {projectTypes.data?.map((projectType) => {
                                    return <Select.Option key={projectType.id}
                                                          value={projectType.id}>
                                      <InformIconsPipe width={14} height={14}
                                                       icon={projectType.icon}/> &nbsp; {projectType.title}
                                    </Select.Option>
                                  })}
                                </Select>}/>
                </div>
                <div className="outline-input-wrapper">
                  <div className="outline-input-label">
                    Customer point of contact (Email address)
                  </div>
                  <input className="outline-input" {...register('contactEmailAddress')}/>
                  <ErrorMessage errors={errors} name={'contactEmailAddress'} render={({message}) => <small
                    className="form-error">{message}</small>}/>
                </div>
              </div>
          }

          <div className="project-details-info-wrapper">
            <div className="row" style={{gap: 10}}>
              <div className="project-details-info-name">
                Project ID
              </div>
              <div className="project-details-info-value">
                {project.id}
              </div>
            </div>
            <div className="row" style={{gap: 10}}>
              <div className="project-details-info-name">
                Date Created
              </div>
              <div className="project-details-info-value">
                {DaysLeftDatePipe(project.createdAt)}
              </div>
            </div>
            <div className="row align-items-center" style={{gap: 10}}>
              <div className="project-details-info-name">
                Project URL
              </div>
              <div className="project-details-info-value">
                <Input className="project-url-placeholder"
                       suffix={<SvgExternalTool/>}
                       ref={copyProjectIdRef}
                       onClick={(e) => copyProjectId(e)}
                       value={`https://insightshub.questionpro.${getInstance(user)}/projects/edit?id=${project.id}`}/>
              </div>
            </div>
          </div>
        </div>
        <Divider style={{marginBottom: -15}}/>
      </>
    </Modal>
  );
};

export default ProjectDetailsDialog;
