// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-details-dialog-wrapper .project-edit-body {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.project-details-dialog-wrapper .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/components/details-requirements/project-details-dialog/projects-details-dialog.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,SAAA;EACA,sBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AADJ","sourcesContent":[".project-details-dialog-wrapper {\n  .project-edit-body {\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n  }\n\n  .title {\n    font-size: 16px;\n    font-weight: 500;\n    margin-bottom: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
