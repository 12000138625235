import { Button, Divider, Modal } from 'antd'
import React, { useState } from 'react'
import storiesApi from '../../api/stories-api'
import "./publish-story-dialog.scss"
import MediaDialog from '../../../../_shared/components/media-dialog/media-dialog';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


const PublishStoryModal = (props: any) => {
  const navigate = useNavigate();
  const [cover, setCover] = useState(props.data?.imageSrc ? props.data.imageSrc : "")
  const [coverEror, setCoverError] = useState(false);
  const [portalError, setPortalError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mediaDialog, setMediaDialog] = useState({visible: false});
  const schema = yup.object({
    title: yup.string().required('Title is required!'),
  })
  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues: {
      title: props?.data?.title ?? '',
    },
    resolver: yupResolver(schema)
  })
  const navigateToSettings = () => {
    navigate('/settings?tab=5')
  }

  const onSave = (data: { title: string }) => {
    if (!cover.length) {
      setCoverError(true)
      return;
    }

    setLoading(true);
    const payload = {
      title: data.title,
      cover
    }
    storiesApi.publishStory(props.data.id, payload).then((response) => {
      if (response.status >= 400 && response.message === "Not allowed to publish story!") {
        setPortalError(true);
        setLoading(false);
        return;
      }

      props.onCancel({published: true});
    })
  }

  const openMediaDialog = () => {
    setMediaDialog({visible: true})
  }

  const closeMediaDialog = () => {
    setMediaDialog({visible: false})
  }

  const onConfirm = (data: { url: string }) => {
    setCover(data.url);
  }

  return (
    <Modal
      title="Publish Story"
      open={props.data.visible}
      onCancel={props.onCancel}
      width={800}
      okText="Publish"
      cancelButtonProps={{type: "link"}}
      onOk={handleSubmit(onSave)}
      okButtonProps={{loading: loading}}
    >
      <div className="publish-story-wrapper row">
        <div className="select-image-wrapper">
          <div>Story Cover (required)</div>
          <div data-testid={"story-cover-button"} onClick={openMediaDialog} className='pointer select-image-container'>
            {
              cover.length ?
                <img src={cover} height={120} alt=""/>
                :
                <Button onClick={openMediaDialog} type="link">
                  Select Image
                </Button>
            }
          </div>
          <small className='form-error'>{coverEror && !cover.length && "Story Cover is required"}</small>
        </div>
        <div className='publish-story-form column'>
          <div className='outline-input-wrapper'>
            <span className='outline-input-label'>
              Story title
            </span>
            <input
              className='outline-input'
              placeholder='Type Here'
              {...register('title')}
            />
            <ErrorMessage errors={errors} name={'title'} render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
          <big
            className='form-error'>{portalError &&
              <span>The portal is not set up yet, please set up the portal on <Button onClick={navigateToSettings}
                                                                                      type='link'
                                                                                      style={{
                                                                                        fontSize: 16,
                                                                                        padding: 0
                                                                                      }}>Settings</Button> and try again!</span>}
          </big>
        </div>
      </div>
      <Divider style={{marginTop: 5, marginBottom: -5}}/>
      {
        mediaDialog.visible &&
          <MediaDialog visible={mediaDialog.visible}
                       onCancel={closeMediaDialog}
                       onConfirm={onConfirm}/>
      }
    </Modal>
  )
}

export default PublishStoryModal
