import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Loader } from '../../../../../../../_shared/components/loader/loader';
import AllFlags from './tabs/all-flags/all-flags';
import AllGraphs from './tabs/all-graphs/all-graphs';
import AllTasks from './tabs/all-tasks/all-tasks';
import qs from 'query-string';
import interviewsApi from '../../api/interviews-api';
import SvgArrowBack from '../../../../../../../assets/icons/js/ArrowBack';
import './usage-stats-view-all.scss'
import { IAllAssigments, IAllFlags, IAllGraphs, IAllTasks } from '../../model/all-assigments.interface';
import { useLocation, useNavigate } from 'react-router-dom';

const TabPane = Tabs.TabPane;

export default function UsageStatsViewAll(props: any): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam: any = qs.parse(location.search);


  const [loader, setLoader] = useState<boolean>(true);
  const [flags, setFlags] = useState<IAllFlags>();
  const [knowledgeGraphs, setKnowledgeGraphs] = useState<IAllGraphs>();
  const [tasks, setTasks] = useState<IAllTasks>();

  useEffect(() => {
    loadPage();
  }, []);


  function loadPage(data?: any) {
    if (data?.flag) {
      urlParam.flagId = data.flag.id;
    }

    if (data?.task) {
      urlParam.taskId = data.task.id;
    }

    if (data?.knowledgeGraph) {
      urlParam.knowledgeGraph = data.knowledgeGraphId;
    }

    interviewsApi.getAllAssigned(urlParam)
      .then((response: IAllAssigments) => {
        setFlags(response.flags);
        setKnowledgeGraphs(response.knowledgeGraphs);
        setTasks(response.tasks);

        setLoader(false);
      })
  }

  function navigateBack(): void {
    navigate({
      pathname: '/projects/edit',
      search: qs.stringify({tab: '6', id: urlParam.id})
    });
  }

  return (
    <div className="project">
      <div className="all-assigments-header justify-space-between">
        <div className="row align-items-center">
          <button
            className="icon-button back"
            onClick={() => navigateBack()}>
            <SvgArrowBack/>
          </button>
          <span className="font-weight-500">Back To Interviews</span>
        </div>
      </div>

      {
        loader ? <Loader/>
          :
          <>
            <div className="content" style={{paddingTop: 50}}>
              <Tabs destroyInactiveTabPane={true}>
                {knowledgeGraphs?.graphs?.length && urlParam?.knowledgeGraph &&
                    <TabPane tab={<div> Knowledge Graph</div>} key="1">
                        <AllGraphs knowledgeGraphs={knowledgeGraphs}
                                   urlParam={urlParam}
                                   loadPage={loadPage}
                                   selectedGraphId={urlParam?.knowledgeGraph}/>
                    </TabPane>
                }
                {flags?.flags?.length &&
                    <TabPane tab={<div>Flags</div>} key="2">
                        <AllFlags flags={flags} urlParam={urlParam} loadPage={loadPage}/>
                    </TabPane>
                }
                {tasks?.tasks?.length &&
                    <TabPane tab={<div>Interview Tasks </div>}
                             key="3">
                        <AllTasks tasks={tasks} urlParam={urlParam} loadPage={loadPage}/>
                    </TabPane>
                }
              </Tabs>
            </div>
          </>
      }
    </div>

  )
}
