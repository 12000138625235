import { ContentBlock, ContentState, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import { getNuggetSelectionByEntityMapId, moveFocusToEnd, moveSelectionToTheEndOfBlock } from './entity';

let selectedNuggets: any[] = [];

export function assignEvidenceEntity(editorState: EditorState, data: {
  selectedNuggets: any[],
  entityKey?: string,
  entityMapId?: string
}): EditorState {
  selectedNuggets = data?.selectedNuggets;
  const entityKey = data?.entityKey;
  const entityMapId = data?.entityMapId;
  if (entityMapId && entityKey) {
    return manageEvidenceWithEntityKey(editorState, entityKey, entityMapId);
  } else {
    return manageEvidenceWithSelection(editorState);
  }
}

function manageEvidenceWithEntityKey(editorState: EditorState, entityKey: string, entityMapId: string): EditorState {
  const entitySelection: SelectionState | undefined = getNuggetSelectionByEntityMapId(editorState, entityMapId);
  const contentState: ContentState = editorState.getCurrentContent()

  // if selectedNodes is empty array remove the entity from selectedText in editor
  if (!selectedNuggets?.length && entitySelection) {
    const newContentState = Modifier.applyEntity(contentState, entitySelection, "");
    return EditorState.push(editorState, newContentState, 'apply-entity');
  } else {
    contentState.mergeEntityData(entityKey, {
      nuggets: selectedNuggets
    });

    return moveSelectionToTheEndOfBlock(editorState);
  }
}

function manageEvidenceWithSelection(editorState: EditorState): EditorState {
  const selectionState: SelectionState = editorState.getSelection();
  const anchorKey: string = selectionState.getAnchorKey();
  const contentState: ContentState = editorState.getCurrentContent();
  const currentBlock: ContentBlock = contentState.getBlockForKey(anchorKey);

  const start: number = selectionState.getStartOffset();
  const end: number = selectionState.getEndOffset();
  const selectedText: string = currentBlock.getText().slice(start, end);

  // if selectedNodes is empty array remove the entity from selectedText in editor
  if (!selectedNuggets?.length) {
    return RichUtils.toggleLink(editorState, selectionState, null);
  } else {
    const contentWithEntity: ContentState = contentState.createEntity("EVIDENCE", "MUTABLE", {
      selectedText,
      nuggets: selectedNuggets,
      entityMapId: Math.floor(100000 + Math.random() * 900000)
    });

    const newEditorState: EditorState = EditorState.push(editorState, contentWithEntity, "apply-entity");
    const entityKey: string | null = contentWithEntity.getLastCreatedEntityKey();
    return moveFocusToEnd(RichUtils.toggleLink(newEditorState, selectionState, entityKey));
  }
}
