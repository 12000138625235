import { environment } from '../../../environment';
import { IProject } from '../models/project.interface';
import { ISlackConversation } from '../models/slack-conversation.interface';
import { ISlackInfoMembers } from '../models/slack-info-members.interface';
import { IProgramProjects } from '../models/program-projects.interface';
import { IProgram } from '../../settings/settings-tabs/taxonomy/programs/model/program.interface';
import { ITeamMemberData } from '../../settings/settings-tabs/team/model/team-member.interface';
import { axiosClient } from '../../../axios-client';
import { AxiosResponse } from 'axios';
import { IProjectTopContributor } from "../models/project-top-contributors.interface";
import { IProjectTopNode } from "../models/project-top-nodes.interface";
import { IProjectTableData } from "../models/project-table-data.interface";
import { IProjectTableFilters } from "../models/project-table-filters.interface";
import { ICustomizeTable } from "../models/customize-table.interface";
import { IActivityData } from "../../home/components/usage-dashboard/homepage-activity-feed/model/activities.interface";

class ProjectApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getProjectContributors(projectId: number): Promise<IProjectTopContributor[]> {
    try {
      const response: AxiosResponse<IProjectTopContributor[]> = await axiosClient.get<IProjectTopContributor[]>(
        this.baseUrl + `/users-activity/top-contributors/${projectId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectActivities(projectId: number, params: any): Promise<IActivityData> {
    try {
      const response: AxiosResponse<IActivityData> = await axiosClient.get<IActivityData>(
        this.baseUrl + `/users-activity/${projectId}`, {
          params: params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectTopNodes(projectId: number): Promise<IProjectTopNode[]> {
    try {
      const response: AxiosResponse<IProjectTopNode[]> = await axiosClient.get<IProjectTopNode[]>(
        this.baseUrl + `/interviews/top/knowledge-graphs/${projectId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async getArchivedProjects(): Promise<IProjectTableData> {
    const response: AxiosResponse<IProjectTableData> = await axiosClient.get<IProjectTableData>(`/projects/archived`);
    return response.data;
  }

  async getProject(id: number | string): Promise<IProject> {
    try {
      const response: AxiosResponse<IProject> = await axiosClient.get<IProject>(`${this.baseUrl}/projects/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateProjectContributors(projectId: number, payload: any): Promise<any> {
    try {
      const response: any = await axiosClient.put<any>(this.baseUrl + `/projects/contributors/${projectId}`, payload)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateRequirementsStatus(projectId: number, payload: any): Promise<any> {
    try {
      const response: any = await axiosClient.put<any>(this.baseUrl + `/projects/${projectId}/requirements`, payload)
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async updateTitle(projectId: number, title: string): Promise<any> {
    try {
      const response: any = await axiosClient.put<any>(this.baseUrl + `/projects/title/${projectId}`, {title})
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async deleteProject(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(`${this.baseUrl}/projects/${id}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //
  // async getTotalProjectsCount(params?: any): Promise<any> {
  //   const response: any = await axiosClient.get<IProgramProjects[]>(`/projects/total/group`, {params})
  //   return response.data;
  // }
  //
  // async getActiveProjects(params?: any): Promise<IProgramProjects[]> {
  //   const response: any = await axiosClient.get<IProgramProjects[]>(`/projects/group/active`, {params})
  //   return response.data;
  // }
  //
  // async getClosedProjects(params?: any): Promise<IProgramProjects[]> {
  //   const response: any = await axiosClient.get<IProgramProjects[]>(`${this.baseUrl}/projects/group/closed`, {params})
  //   return response.data;
  // }
  //
  // async getQueuedProjects(params?: any): Promise<IProgramProjects[]> {
  //   const response: any = await axiosClient.get<IProgramProjects[]>(`/projects/group/queued`, {params})
  //   return response.data;
  // }

  async getProjects(params?: any): Promise<IProjectTableData> {
    const response: AxiosResponse<IProjectTableData> = await axiosClient.get<IProjectTableData>(`/projects/table`, {params});
    return response.data;
  }

  async getFilters(): Promise<IProjectTableFilters> {
    const response: AxiosResponse<IProjectTableFilters> = await axiosClient.get<IProjectTableFilters>('/projects/filters');
    return response.data;
  }

  async getCustomizeTable(): Promise<ICustomizeTable> {
    const response: AxiosResponse<ICustomizeTable> = await axiosClient.get<ICustomizeTable>('/customize-project-table');
    return response.data;
  }

  async updateCustomizeTable(customizeTable: ICustomizeTable): Promise<ICustomizeTable> {
    const response: AxiosResponse<ICustomizeTable> = await axiosClient.put<ICustomizeTable>('/customize-project-table', customizeTable);
    return response.data;
  }

  async restoreProject(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<IProject[]>(this.baseUrl + `/projects/restore/${id}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async moveProjectToProgram(projectId: number, programId: number): Promise<null> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<null>(this.baseUrl + `/projects/${projectId}/program/move/${programId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSlackConversation(id: number): Promise<ISlackConversation> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<ISlackConversation>(this.baseUrl + `/integrations/slack/conversation/${id}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSlackInfoAndMembers(id: number): Promise<ISlackInfoMembers> {
    try {
      const response: AxiosResponse<ISlackInfoMembers> = await axiosClient.get<ISlackInfoMembers>(this.baseUrl + `/integrations/slack/members/${id}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Workflow
  async getWorkflows(): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await axiosClient.get<any[]>(`${this.baseUrl}/workflow`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Programs
  async getPrograms(): Promise<{ content: IProgram[], totalSize: number }> {
    try {
      const response: AxiosResponse<{ content: IProgram[], totalSize: number }> = await axiosClient.get<{
        content: IProgram[],
        totalSize: number
      }>(`${this.baseUrl}/program`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectsPerProgram(programId: number): Promise<IProject[]> {
    try {
      const response: AxiosResponse<IProject[]> = await axiosClient.get<IProject[]>(
        this.baseUrl + `/program/projects/${programId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProgram(programId: number): Promise<{ name: string; description: string }> {
    try {
      const response: AxiosResponse<{ name: string; description: string }> = await axiosClient.get<{
        name: string;
        description: string
      }>(
        this.baseUrl + `/program/${programId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // TeamMember / Owners
  async getOwners(): Promise<ITeamMemberData> {
    try {
      const response: AxiosResponse<ITeamMemberData> = await axiosClient.get<ITeamMemberData>(`${this.baseUrl}/teammembers?pageSize=100`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async getOwnersWithProjectNumber(): Promise<any> {
  //   const response: any = await axiosClient.get<any>(`/projects/filter/owners`)
  //   return response.data;
  // }

  async updateKeyMetrics(id: number, keyMetricsValues: any[]): Promise<any> {
    try {
      const response: AxiosResponse<IProject> = await axiosClient.put<any>(`${this.baseUrl}/projects/keymetric/${id}`, keyMetricsValues)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateKeyMetric(id: number, value: number): Promise<any> {
    try {
      const response: AxiosResponse<IProject> = await axiosClient.put<any>(`${this.baseUrl}/keymetrics/value/${id}`, {value})
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateProgramMetrics(id: number, progamMetricsValues: any[]): Promise<any> {
    try {
      const response: AxiosResponse<IProject> = await axiosClient.put<any>(`${this.baseUrl}/projects/program-metric/${id}`, progamMetricsValues)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateProgramMetric(id: number, value: number): Promise<any> {
    try {
      const response: AxiosResponse<IProject> = await axiosClient.put<any>(`${this.baseUrl}/program-metrics/value/${id}`, {value})
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async downloadFile(id: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrl}/projects/download/${id}`)
      return response.data
    } catch (error) {
      throw (error);
    }
  }

  async submitPricingModel(id: any, pricingModelsValues: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/projects/pricingmodels/${id}`, pricingModelsValues)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async startSyncModalInit(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(`${this.baseUrl}/folder-counter/sync`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async bulkDeleteProjects(ids: string[]): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(`${this.baseUrl}/projects/bulk-archived`, {data: {ids}})
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async bulkRestoreProjects(ids: number[]): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(`${this.baseUrl}/projects/bulk-restore`, {ids})
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ProjectApi();
