import React, { CSSProperties } from 'react';
import './user-avatar.scss'
import { IUserData } from "../../../core/model/user-data.interface";
import { getInitials } from "../../helpers/get-initials-of-name";

type Props = {
  user: IUserData
  size?: number;
  style?: CSSProperties
}
const UserAvatar = (props: Props) => {
  const displayName = props.user?.displayName ? props.user.displayName : props.user?.firstName ? props.user.firstName + " " + props.user.lastName : props.user?.emailAddress;
  const initials = getInitials(displayName ? displayName : "");

  return (
    <div className={'user-avatar-wrapper'} style={{
      minWidth: props?.size ? props.size : 30,
      minHeight: props?.size ? props.size : 30,
      backgroundColor: props.user?.profilePictureColor ? props.user.profilePictureColor : "",
      ...props.style
    }}>
      {
        props?.user?.profilePic ?
          <img src={props?.user.profilePic}
               style={{
                 width: props?.size ? props.size : 30,
                 height: props?.size ? props.size : 30,
               }}
               alt="user-profile-image"/> :
          initials
      }
    </div>
  );
};

export default UserAvatar;
