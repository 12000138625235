import FlagIconsPipe from '../../../../../../_shared/helpers/flag-icons-pipe';
import { Dropdown } from 'antd';
import SvgEdit from '../../../../../../assets/icons/js/Edit';
import SvgTrash from '../../../../../../assets/icons/js/Trash';
import SvgMore from '../../../../../../assets/icons/js/More';
import React, { useState } from 'react';

export function FlagSetupCard(props: any): JSX.Element {
  const {flag} = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      className={open ? "flag-button flag-button-active" : "flag-button"}>
      <div className="icon-flag-button">
        <FlagIconsPipe icon={flag?.icon && flag?.icon}
                       color={flag?.color && flag?.color}/>
      </div>
      <div className="label-flag-button">{flag?.name}</div>
      <Dropdown
        onOpenChange={(open: boolean) => setOpen(open)}
        overlay={
          <div style={{width: 90}} className="more-menu-files column">
            <div
              className="more-menu-item"
              onClick={(e) => {
                setOpen(false);
                props.openEditFlagDialog(flag)
              }}>
              <SvgEdit/>
              <span>Edit</span>
            </div>

            <div className="more-menu-item"
                 onClick={(e) => {
                   setOpen(false);
                   props.deleteFlag(flag.id)
                 }}>
              <SvgTrash/>
              <span>Delete</span>
            </div>
          </div>
        }
        trigger={['click']}>
        <div className={open ? "menu-flag-button menu-flag-button-active" : "menu-flag-button"}
             onClick={(e) => e.preventDefault()}>
          <SvgMore className="more-icon"/>
        </div>
      </Dropdown>
    </div>
  );
}
