// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculated-formula-editor .DraftEditor-root {
  max-height: 80px;
  height: 80px;
}
.calculated-formula-editor .DraftEditor-root .public-DraftEditorPlaceholder-root {
  z-index: 2;
  top: 10px;
  font-size: 12px;
  left: 12px;
}
.calculated-formula-editor .DraftEditor-root .DraftEditor-editorContainer {
  position: relative;
  color: #545e6b;
  height: 80px;
  max-height: 80px;
}
.calculated-formula-editor .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content {
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  overflow: auto;
}
.calculated-formula-editor .calculated-formula-editor-operations {
  padding: 10px 4px 4px;
  color: #545e6b;
  font-size: 10px;
  border: 1px solid #F5F5F5;
  margin-bottom: 5px;
}
.calculated-formula-editor .calculated-formula-editor-operations .calculated-formula-operation {
  background-color: #F6F8F9;
  min-width: 25px;
  min-height: 25px;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/taxonomy/programs/add-edit-programs-dialog/group-metrics-input/program-metric-input/calculated-formula-editor/calculated-formula-editor.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,YAAA;AADJ;AAGI;EACE,UAAA;EACA,SAAA;EACA,eAAA;EACA,UAAA;AADN;AAKI;EACE,kBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AAHN;AAKM;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AAHR;AAQE;EACE,qBAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;AANJ;AAQI;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AANN","sourcesContent":[".calculated-formula-editor {\n\n  .DraftEditor-root {\n    max-height: 80px;\n    height: 80px;\n\n    .public-DraftEditorPlaceholder-root {\n      z-index: 2;\n      top: 10px;\n      font-size: 12px;\n      left: 12px;\n    }\n\n\n    .DraftEditor-editorContainer {\n      position: relative;\n      color: #545e6b;\n      height: 80px;\n      max-height: 80px;\n\n      .public-DraftEditor-content {\n        height: 60px;\n        max-height: 60px;\n        min-height: 60px;\n        overflow: auto;\n      }\n    }\n  }\n\n  .calculated-formula-editor-operations {\n    padding: 10px 4px 4px;\n    color: #545e6b;\n    font-size: 10px;\n    border: 1px solid #F5F5F5;\n    margin-bottom: 5px;\n\n    .calculated-formula-operation {\n      background-color: #F6F8F9;\n      min-width: 25px;\n      min-height: 25px;\n      padding: 0 8px;\n      font-size: 14px;\n      border-radius: 10px;\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
