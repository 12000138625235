import React from 'react';
import SvgArrowDown from '../../../../../assets/icons/js/ArrowDown';
import SvgArrowUp from '../../../../../assets/icons/js/ArrowUp';
import { IKnowledgeNode } from '../../../../knowledge/model/knowledge-node.interface';
import './read-sub-node.scss';


export class ReadSubNode extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      node: this.props.node ? this.props.node : [],
      openSubNodes: this.props.node.open ? this.props.node.open : false
    }

    this.onNodeClick = this.onNodeClick.bind(this);
    this.isNodeSelected = this.isNodeSelected.bind(this);
  }

  toggleSubNodes(): void {
    this.setState({
      ...this.state,
      openSubNodes: !(this.state.openSubNodes || this.state.node.open),
      node: {
        ...this.state.node,
        open: !(this.state.openSubNodes || this.state.node.open)
      }
    });
  }

  onNodeClick(node: IKnowledgeNode): void {
    if (this.props.assignable) {
      this.props.addRemoveNode(node);
      return;
    }

    if (this.props.getNodeDetails) {
      this.props.getNodeDetails(node.id);
    }
  }

  isNodeSelected(id: number | undefined): boolean {
    if (this.props.selectedNodes && id) {
      let selectedNodes: IKnowledgeNode[] = [...this.props.selectedNodes];
      return selectedNodes.some((selectedNode: IKnowledgeNode) => selectedNode.id === id);
    } else {
      return false;
    }
  }

  render() {
    const {node, openSubNodes} = this.state;

    return (
      <div className="read-sub-node">
        <div className="knowledge-sub-node">
          <div className="title pointer" onClick={() => this.onNodeClick(node)}>
            <div className={this.props.selectedId === node.id || this.props.isSelected
              ? "knowledge-sub-node-name knowledge-sub-node-name-active pointer"
              : "knowledge-sub-node-name pointer"}>
              <span className="text-ellipsis">{node.name}</span>
              <span>{node?.assigned}</span>
            </div>
            {(node.hasChild || node.nodes.length > 0) &&
                <div className="knowledge-sub-node-name-expand pointer" onClick={(e: any) => {
                  e.stopPropagation();
                  this.toggleSubNodes()
                }}>
                  {
                    openSubNodes || node.open
                      ? <SvgArrowUp width={8} height={8}/>
                      : <SvgArrowDown width={8} height={8}/>
                  }
                </div>
            }
          </div>
        </div>
        {
          openSubNodes || node.open ?
            <div className="children-nodes" style={{borderWidth: '1px'}}>
              {node.nodes && node.nodes.map((node: any, i: number) => {
                return (
                  <ReadSubNode key={i}
                               node={node}
                               selectedId={this.props.selectedId}
                               assignable={true}
                               selectedNodes={this.props.selectedNodes}
                               isSelected={this.isNodeSelected(node?.id)}
                               addRemoveNode={this.onNodeClick}
                               getNodeDetails={this.onNodeClick}/>
                )
              })}
            </div>
            :
            <></>
        }
      </div>
    );
  }
}
