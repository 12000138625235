import React from 'react';
import SearchKnowledgeGraphCard
  from "../search-knowledge-graphs/search-knowledge-graph-card/search-knowledge-graph-card";
import { Empty } from "antd";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import SearchSnapshotCard from "./search-snapshot-card/search-snapshot-card";

const SearchSnapshots = ({searchSnapshots}: any) => {

  return <>
    {!searchSnapshots?.isLoading ?
      <div className='column' style={{gap: 10, marginBottom: 20}}>
        {searchSnapshots?.data?.snapshots?.length ? searchSnapshots?.data.snapshots.map((snapshot: any, i: number) => {
            return (
              <SearchSnapshotCard key={i}
                                  snapshot={snapshot}/>
            )
          })
          :
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }
      </div>
      :
      <>
        <Loader/>
      </>
    }
  </>
};

export default SearchSnapshots;
