import React from 'react';

const SvgBetaLabel = (props: any) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5 10.5C20.5 4.97715 16.0228 0.5 10.5 0.5C4.97715 0.5 0.5 4.97715 0.5 10.5C0.5 16.0228 4.97715 20.5 10.5 20.5C16.0228 20.5 20.5 16.0228 20.5 10.5Z"
        fill="#FFD12C"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.6429 3C13.0373 3 13.3571 3.30526 13.3571 3.68182C13.3571 4.05838 13.0373 4.36364 12.6429 4.36364L12.6434 8.91408C14.3323 9.68018 15.5 11.3236 15.5 13.2273C15.5 15.8632 13.2614 18 10.5 18C7.73858 18 5.5 15.8632 5.5 13.2273C5.5 11.3233 6.66798 9.67971 8.35733 8.91375L8.35714 4.36364C7.96265 4.36364 7.64286 4.05838 7.64286 3.68182C7.64286 3.30526 7.96265 3 8.35714 3H9.78571L9.78569 9.88508C8.20987 10.1815 7.01082 11.4078 6.93263 12.4395L6.92857 12.5455C6.92857 13.0368 7.31571 13.1788 7.95718 13.1751L8.18024 13.1688L8.42105 13.1533L8.81314 13.1167L10.0139 12.9801L10.3434 12.9462L10.6824 12.9158L11.03 12.8904C11.3228 12.8719 11.6233 12.8605 11.9286 12.8605C12.1955 12.8605 12.4455 12.9003 12.6739 12.9517L12.8643 12.9979L13.2892 13.1118L13.437 13.1464C13.8366 13.229 14.0714 13.1641 14.0714 12.5455C14.0714 11.5286 12.9252 10.2786 11.3766 9.91918L11.215 9.88522L11.2143 3H12.6429ZM9.42857 15.2727C9.23133 15.2727 9.07143 15.4254 9.07143 15.6136C9.07143 15.8019 9.23133 15.9545 9.42857 15.9545C9.62582 15.9545 9.78571 15.8019 9.78571 15.6136C9.78571 15.4254 9.62582 15.2727 9.42857 15.2727ZM11.9286 13.5682C11.5341 13.5682 11.2143 13.8734 11.2143 14.25C11.2143 14.6266 11.5341 14.9318 11.9286 14.9318C12.3231 14.9318 12.6429 14.6266 12.6429 14.25C12.6429 13.8734 12.3231 13.5682 11.9286 13.5682ZM9.07143 11.0455C9.46592 11.0455 9.78571 11.3507 9.78571 11.7273C9.78571 12.1038 9.46592 12.4091 9.07143 12.4091C8.67694 12.4091 8.35714 12.1038 8.35714 11.7273C8.35714 11.3507 8.67694 11.0455 9.07143 11.0455Z"
            fill="#555555"/>
    </svg>
  );
};

export default SvgBetaLabel;
