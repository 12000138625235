import React, { useEffect, useRef, useState } from 'react';
import UserActivityDialog from "./user-activity-dialog/user-activity-dialog";
import "./homepage-activity-feed.scss"
import { getInitials } from "../../../../../_shared/helpers/get-initials-of-name";
import { useQuery } from "react-query";
import ActivityFeedApi from "./api/activity-feed-api";
import { ActivitiesFilterEnum } from "./components/activity-filters/model/activities-filter.enum";
import DaysLeftDatePipe from "../../../../../_shared/helpers/days-left-date-pipe";
import SvgKnowledgeGraph from "../../../../../assets/icons/js/knowledge-graph";
import { EventTypesEnum } from "../../../../../_shared/helpers/general-event-type-pipe/event-types.enum";
import { useNavigateToPageForActivity } from "../../../../../core/hooks/use-navigate-to-page";
import { IActivity } from "./model/activities.interface";

const HomepageActivityFeed = () => {
  const navigateToPage = useNavigateToPageForActivity();
  const [userActivityDialog, setUserActivityDialog] = useState({visible: false, userId: 0});


  const activities = useQuery('activities', () => ActivityFeedApi.getAllActivities({
    pageSize: 7,
    page: 1,
    activityTypes: `${ActivitiesFilterEnum.PROJECTS_ACTIVITY},${ActivitiesFilterEnum.STORIES_ACTIVITY},${ActivitiesFilterEnum.NUGGETS_ACTIVITY},${ActivitiesFilterEnum.KNOWLEDGE_GRAPHS_ACTIVITY}`
  }))

  const openUserActivityDialog = (user: { userId: number }) => {
    setUserActivityDialog({visible: true, ...user})
  }

  const closeUserActivityDialog = () => {
    setUserActivityDialog({visible: false, userId: 0})
  }


  return (
    <>
      <div className="activity-feed metric-card">
        {
          activities.data?.data.map((activity, i) => {
            return <HomepageActivityCard key={i}
                                         activity={activity}
                                         onClick={() => navigateToPage({
                                           eventNameEnum: activity.eventNameEnum as EventTypesEnum,
                                           id: activity.id,
                                           source: "fromHome"
                                         })}
                                         onUserClick={() =>
                                           openUserActivityDialog({
                                             userId: activity.userId,
                                           })}/>
          })
        }
      </div>
      {
        userActivityDialog.visible &&
        <UserActivityDialog data={userActivityDialog} onCancel={closeUserActivityDialog}/>
      }
    </>
  );
};

export default HomepageActivityFeed;

export function HomepageActivityCard(props: { onClick: () => void, activity: IActivity, onUserClick: () => void }) {
  const textRef = useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      if (textRef.current) {
        setIsEllipsisActive(textRef.current.scrollWidth > textRef.current.clientWidth);
      }
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <div className="activity-element" data-testid={"homepage-activity-card"}>
    <div className="activity-content">
      <div className="column">
        {
          props.activity.description || props.activity.nuggetDescription &&
          <div className="row">
            <div ref={textRef} className="activity-secondary-text">
              {props.activity.description}
              {props.activity.nuggetDescription}
            </div>
            {
              isEllipsisActive &&
              <span className="pointer link" style={{whiteSpace: "nowrap"}} onClick={props.onClick}>
                {"See more"}
            </span>
            }
          </div>
        }

        <div className="activity-primary-text" onClick={props.onClick}>
          {props.activity.eventName.includes("Knowledge") && <SvgKnowledgeGraph color={"#008EEE"}/>}
          {props.activity.name}
        </div>
      </div>
      {
        props.activity.nodeName &&
        <span className="activity-nugget-update">
                  Connected to <span className="activity-nugget-node">{props.activity.nodeName}</span>
                </span>
      }
      <div className="row justify-space-between">
        <div className="user-info">
          <div className="user-avatar" style={{backgroundColor: props.activity.userColorCode}}>
            {props.activity.profilePicture ?
              <img src={props.activity.profilePicture} alt=""/> : getInitials(props.activity.user)}
          </div>
          <span className="user-name pointer" onClick={props.onUserClick}>
                  {props.activity.user}
                </span>
          <span className="updated-at">{DaysLeftDatePipe(props.activity.createdAt)}</span>
        </div>
        <div className="activity-type" style={{textAlign: "center"}}>
          {props.activity.eventName}
        </div>
      </div>
    </div>
  </div>;
}
