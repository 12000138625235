import { convertToRaw, EditorState } from 'draft-js';

export function getDisplayHighlights(editorState: EditorState): any {
  let displayHighlightsArray: any[] = [];
  let displayHighlightsObject: any = null;

  const contentState = convertToRaw(editorState.getCurrentContent());

  const tasks = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'TASK').map((v: any) => v[1].data)
    .map(obj => ({task: {...obj.task, answer: obj?.answer}, position: obj.position, type: 'TASK'}));

  tasks.length && displayHighlightsArray.push(...tasks);

  const mentions = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'mention').map((v: any) => v[1].data.mention)
    .map(obj => ({...obj, type: 'mention'}));

  mentions && displayHighlightsArray.push(...mentions);

  const nodes = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'NODE').map((v: any) => v[1].data.nodes)
    .map(obj => ({
      node: obj.nodes,
      position: obj.position,
      type: 'NODE',
      selectedText: obj?.selectedText !== undefined && obj.selectedText
    }));

  nodes.length && displayHighlightsArray.push(...nodes);

  const flags = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'FLAG').map((v: any) => v[1].data)
    .map(obj => ({...obj, type: 'FLAG'}));

  flags && displayHighlightsArray.push(...flags);

  if (displayHighlightsArray.length) {
    displayHighlightsArray.sort((a, b) => (a?.position?.left > b?.position?.left) ? 1 : -1).forEach((nItem: any) => {
      const parentTopTemp = document.getElementsByClassName('text-editor');
      const parentTop = document.getElementsByClassName('text-editor')[0]?.getBoundingClientRect()?.top;
      const topPosition = nItem.position.top - parentTop;

      const item = {
        ...nItem,
        position: {
          ...nItem.position,
          top: Math.round(topPosition),
        }
      };

      if (displayHighlightsObject === null) {
        displayHighlightsObject = {
          [item?.position?.top]: [item]
        }
      } else {
        if (displayHighlightsObject[item?.position?.top] !== undefined) {
          displayHighlightsObject[item?.position?.top].push(item);
        } else {
          displayHighlightsObject[item?.position?.top] = [item];
        }
      }
    });
  }


  if (displayHighlightsObject !== null) {
    return Object.entries(displayHighlightsObject);
  } else {
    return null;
  }
}
