import { Button, Modal } from 'antd';
import FlagIconsPipe from '../../../../../../_shared/helpers/flag-icons-pipe';
import React, { useState } from 'react';
import '../add-edit-interview-lab.scss';
import interviewLabsSettingsApi from '../../api/interview-labs-settings.api';
import { IFlagType } from '../../model/flag-type.interface';
import SvgSearch from '../../../../../../assets/icons/js/Search';

export default function AddEditFlagDialog(props: any) {
  const [id] = useState(props.data.id && props.data.id);
  const [flagTypeColor, setFlagTypeColor] = useState(props.data.color ? props.data.color : '');
  const [flagTypeIcon, setFlagTypeIcon] = useState<any>(props.data.icon ? props.data.icon : '');
  const [flagName, setFlagName] = useState<string>(props.data.name ? props.data.name : '');
  const [title] = useState(props.data.title ? props.data.title : 'Add flag');
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");

  const [showInputError, setShowInputError] = useState<boolean>(false)
  const colors = ['#332288', '#0077BB', '#88CCEE', '#44AA99', '#117733', '#999933', '#DDAA33', '#CC6677', '#882255', '#AA4499'];

  const bootstrapIcons = require("../../../../../../assets/bootstrap-icons/bootstrap-icons.json")
  const [filteredIcons, setFilteredIcons] = useState(Object.keys(bootstrapIcons).slice(0, 28).map((key: string) => key));


  function dynamicInputFormError(): boolean {
    return !flagName || !flagTypeColor || !flagTypeIcon;
  }

  function onSubmit(): void {
    if (dynamicInputFormError()) {
      setShowInputError(true);
      return;
    }

    const payload = {
      name: flagName,
      icon: flagTypeIcon,
      color: flagTypeColor,
    };

    setApiLoading(true);
    id ? editFlag(payload) : createFlag(payload);
  }

  function editFlag(payload: IFlagType): void {
    interviewLabsSettingsApi.updateFlag(id, payload)
      .then(() => {
        props.onSubmit();
      });
  }

  function createFlag(payload: IFlagType): void {
    interviewLabsSettingsApi.createFlag(payload)
      .then(() => {
        props.onSubmit();
      });
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)
    setFilteredIcons(Object.keys(bootstrapIcons).filter((value: string) => value.includes(e.target.value)).slice(0, 28));
  }


  return (
    <Modal
      title={<div style={{color: "#545E6B", fontWeight: 400}}>{title}</div>}
      open={props.visible}
      onCancel={() => props.onCancel()}
      centered
      width={716}
      footer={[
        <Button key="submit" type="primary" loading={apiLoading} onClick={onSubmit}>
          Save
        </Button>
      ]}>
      <div className="modal-body-flag">
        <div className='flag-name'>
          <span className="title">Name</span>
          <div className="row flex-wrap w-100">
            <input
              name="flag-name"
              placeholder="Enter Flag Name"
              className="outline-input-flag"
              value={flagName}
              onChange={(e) => setFlagName(e.target.value)}
            />
          </div>
          <div>
            <small
              className="form-error">{!flagName && showInputError && `Flag Name is required.`}
            </small>
          </div>
        </div>

        <div className='icons-and-color'>
          <div className='icons-wrapper'>
            <div className='title'>
              Icon
            </div>
            <div className='relative'>
              <input
                style={{width: 400}}
                value={searchValue}
                placeholder="Search Icons"
                className='outline-input'
                onChange={handleSearchChange}
              />
              <span className='absolute' style={{top: 8, right: 10}}><SvgSearch/></span>
            </div>
            <div className="icons">
              <div className="row flex-wrap">
                {
                  filteredIcons.map((icon: string, i: number) => {
                    const setActiveBorder = icon === flagTypeIcon;
                    return <button className="radio-button-icons"
                                   key={i}
                                   style={{borderColor: setActiveBorder ? '#1B87E6' : '#D8D8D8'}}
                                   onClick={() => setFlagTypeIcon(icon)}>
                      <FlagIconsPipe icon={icon} width={24}/>
                    </button>
                  })
                }
              </div>
              <small
                className="form-error">{!flagTypeIcon && showInputError && `Flag Icon is required.`}
              </small>
            </div>
          </div>
          <div className="colors">
            <div className="title">Color</div>
            <div className="row flex-wrap">
              {colors.map((color: string, i: number) => {
                const setActiveBorder = color === flagTypeColor
                return (
                  <button
                    key={i}
                    style={{border: setActiveBorder ? '2px solid ' + color : '2px solid rgba(216, 216, 216, 0.5)'}}
                    data-testid="mock-flag-color"
                    onClick={() => setFlagTypeColor(color)}
                    className="radio-button">
                    <div style={{backgroundColor: color && color}} className="radio-button-inner"/>
                  </button>
                )
              })}
            </div>
            <div>
              <small
                className="form-error">{!flagTypeColor && showInputError && `Flag Color is required.`}
              </small>
            </div>
          </div>

        </div>
      </div>
    </Modal>
  );
}
