import React from 'react';
import { useNavigate } from "react-router-dom";
import SvgMarketFlipped from "../../../../../../../assets/icons/js/marketFlipped";
import qs from "query-string";

const SearchSnapshotCard = ({snapshot}: any) => {
  const navigate = useNavigate();

  const navigateToProject = () => {
    navigate({pathname: "/projects/edit", search: qs.stringify({id: snapshot.projectId})})
  }

  return (
    <div className='nugget-search-container'>
      <div className='nugget-search-card-wrapper'>
        <div>
          <SvgMarketFlipped width={24} height={24}/>
        </div>

        <div className='nugget-search-card'>
          <div className='nugget-search-description pointer'
               onClick={navigateToProject}
               dangerouslySetInnerHTML={{__html: snapshot?.projectNugget.description}}/>
        </div>
      </div>
    </div>
  )
}


export default SearchSnapshotCard;
