import React, { useEffect, useState } from 'react';
import { Tabs } from "antd";
import Overview from "./tabs/overview/overview";
import { FilterForm } from "../../_shared/components/filter-form/filter-form";
import ProgramMetrics from "./tabs/program-metrics/program-metrics";
import Storage from "./tabs/storage/storage";
import './analytics.scss'
import qs from "query-string";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import TabPane = Tabs.TabPane;
import { usePopUpTutorial } from "../../core/hooks/use-pop-up-tutorial";
import PopUpTutorial from "../../_shared/components/pop-up-tutorial/pop-up-tutorial";

const Analytics = (): JSX.Element => {
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);
  const {popUpTutorial, onPopUpClose} = usePopUpTutorial('analytics');
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<any>({});
  const [activeTab, setActiveTab] = useState<string>('1');


  useEffect(() => {
    const activeTab = urlParam?.tab;
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, []);

  const changeTab = (activeKey: string) => {
    setActiveTab(activeKey);
    navigate({pathname: '', search: qs.stringify({tab: activeKey})}, {replace: true});
  };

  function filterFormChanged(params: any): void {
    setQueryParams(params);
  }

  return (
    <div className="analytics">
      <div className="row justify-space-between">
        <h2>Metrics</h2>
        <FilterForm filterFormChanged={filterFormChanged} disablePrograms/>
      </div>
      <Tabs activeKey={activeTab}
            onChange={changeTab}>
        <TabPane tab="Overview" key={1}>
          <Overview queryParams={queryParams}/>
        </TabPane>
        <TabPane tab="Program metrics" key={2}>
          <ProgramMetrics queryParams={queryParams}/>
        </TabPane>
        <TabPane tab="Storage" key={3}>
          <Storage/>
        </TabPane>
      </Tabs>
      {
        popUpTutorial.open &&
        <PopUpTutorial open={popUpTutorial.open} onPopUpClose={onPopUpClose} title='Analytics'>
          <>
            <p>Analytics shows you your insights production over time, along with other key metrics about your
              projects.</p>
            <p>
              Get a quick view of how many projects are queued, active, and closed. See averages for any number
              of
              possible
              key metrics (you can set these up in Settings &#62; Project Management).
            </p>
            <p>Additionally, you can apply filters and save views, in order to see the exact metrics you need to
              see.
            </p>
          </>
        </PopUpTutorial>
      }
    </div>
  );
};

export default Analytics;
