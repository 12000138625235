import React from 'react';

const SvgThemeLeftAligned = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M-6.10352e-05 1.99988C-6.10352e-05 0.895309 0.895369 -0.00012207 1.99994 -0.00012207H13.9999C15.1045 -0.00012207 15.9999 0.895308 15.9999 1.99988V9.99988C15.9999 11.1044 15.1045 11.9999 13.9999 11.9999H1.99994C0.895369 11.9999 -6.10352e-05 11.1044 -6.10352e-05 9.99988V1.99988ZM-6.10352e-05 14.9999C-6.10352e-05 14.4476 0.447654 13.9999 0.999939 13.9999H14.9999C15.5522 13.9999 15.9999 14.4476 15.9999 14.9999C15.9999 15.5522 15.5522 15.9999 14.9999 15.9999H0.999939C0.447654 15.9999 -6.10352e-05 15.5522 -6.10352e-05 14.9999ZM13.9999 1.99978H7.99987V9.99978H13.9999V1.99978Z"
            fill="#545E6B"/>
    </svg>
  );
};

export default SvgThemeLeftAligned;
