import React, { useState } from 'react';
import FileTypePipe from "../../../../../../_shared/helpers/file-type-pipe";
import { Dropdown, MenuProps } from "antd";
import SvgDownload from "../../../../../../assets/icons/js/download";
import SvgEdit from "../../../../../../assets/icons/js/Edit";
import SvgTrash from "../../../../../../assets/icons/js/Trash";
import SvgMore from "../../../../../../assets/icons/js/More";
import FormattedDate from '../../../../../../_shared/helpers/formatted-date';
import SvgLock from "../../../../../../assets/icons/js/lock";
import SvgUnlock from "../../../../../../assets/icons/js/unlock";
import { IFile } from "../../../../../../_shared/model/files.Interface";
import { ConfirmationDialog } from "../../../../../../_shared/components/confirmation-dialog/confirmation-dialog";

const FileCard = (props: any) => {
  const isPrivate = !props.file.privacy;
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<any>({
    visible: false,
    file: null,
  });

  const openDeleteConfirmationDialog = (file: IFile) => {
    setDeleteConfirmationDialog({
      visible: true,
      id: file.id,
      okText: 'Delete',
      title: 'Delete file',
      titleSec: 'You\'re about to delete this file',
      content: `${file.name}`,
      description: 'Removing this file will permanently delete it from the project. Are you sure you want to proceed?',
    })
  }

  const closeDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog({
      visible: false,
      id: null
    })
  }

  const onOkDeleteConfirmationDialog = ({modal, id}: { modal: boolean, id: number }) => {
    if (modal) {
      props.deleteFile(id);
      closeDeleteConfirmationDialog();
    }
  }

  let menuItems: MenuProps['items'] = [];

  if (!props.readOnly) {
    menuItems = [
      {
        key: "1",
        label: (
          <div className="more-menu-item" onClick={(e) => {
            e.stopPropagation()
            props.downloadFile(props.file)
          }}>
            <SvgDownload/>
            <span>Download</span>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div className="more-menu-item"
               onClick={(e) => {
                 e.stopPropagation()
                 props.openChangeDescriptionDialog(props.file)
               }}>
            <SvgEdit/>
            <span>Description</span>
          </div>
        ),
      },
      {
        key: "3",
        label: (
          <div className="more-menu-item" onClick={(e) => {
            e.stopPropagation()
            openDeleteConfirmationDialog(props.file)
          }}>
            <SvgTrash/>
            <span>Delete</span>
          </div>
        ),
      },
      {
        key: "5",
        label: (
          <div className="more-menu-item" onClick={(e) => {
            e.stopPropagation()
            props.markAsPrivate(props.file.id, isPrivate ? 1 : 0)
          }}>
            {
              isPrivate ?
                <>
                  <SvgUnlock/>
                  <span>Mark as public</span>
                </> : <>
                  <SvgLock/>
                  <span>Mark as private</span>
                </>
            }

          </div>
        ),
      },
    ];
  } else {
    menuItems = [
      {
        key: "1",
        label: (
          <div className="more-menu-item" onClick={(e) => {
            e.stopPropagation()
            props.downloadFile(props.file)
          }}>
            <SvgDownload/>
            <span>Download</span>
          </div>
        ),
      },
    ];
  }

  const handleToggle = (e: any) => {
    props.updateFileIDs(props.file.fileId);
  }

  if (props.file?.description) {
    return <div className="file-card file-card-description" onClick={handleToggle}
                style={{
                  border: props.isSelected ? '1px solid #1890ff' : '1px solid #f0f0f0',
                }}>
      <div className="body row justify-space-between align-items-start">
        <div className="row">
          <FileTypePipe value={props.file.extension}/>
          <div className="footer column">
            <div className="description link" style={{width: 145}}>{props.file.name}</div>
            <div className="when">
              <FormattedDate date={props.file.updatedAt} formatString="MMM d, yyyy"/>
            </div>
          </div>
        </div>
        <Dropdown menu={{items: menuItems, className: "more-menu-files column"}}

                  trigger={["click"]}>
          <div className="more-icon-wrapper pointer" onClick={(e) => e.stopPropagation()}>
            <SvgMore className="more-icon"/>
          </div>
        </Dropdown>
      </div>
      <div className="hr"/>

      <div className="footer-description">
        {props.file?.description}
      </div>
      {deleteConfirmationDialog.visible &&
        <ConfirmationDialog data={deleteConfirmationDialog}
                            onConfirm={onOkDeleteConfirmationDialog}
                            onCancel={closeDeleteConfirmationDialog}/>
      }
    </div>
  }

  return (
    <>
      <div className="file-card" onClick={handleToggle} style={{
        border: props.isSelected ? '1px solid #1890ff' : '1px solid #f0f0f0',
      }}>
        <div className="body justify-space-center">
          <div className="row align-self-flex-end">
            {/*<div>*/}
            {/*  <SvgLock/>*/}
            {/*</div>*/}
            <Dropdown menu={{items: menuItems, className: "more-menu-files column"}}
                      trigger={["click"]}>
              <div className="more-icon-wrapper pointer" onClick={(e) => e.stopPropagation()}>
                <SvgMore className="more-icon"/>
              </div>
            </Dropdown>
          </div>
          <div className="row justify-space-center">
            <FileTypePipe value={props.file?.extension}/>

          </div>
        </div>
        <div className="hr"/>
        <div className="footer column">
          <div className="description link">{props.file.name}</div>
          <div className="when">
            <FormattedDate date={props.file.updatedAt} formatString="MMM d, yyyy"/>
          </div>
        </div>
      </div>
      {deleteConfirmationDialog.visible &&
        <ConfirmationDialog data={deleteConfirmationDialog}
                            onConfirm={onOkDeleteConfirmationDialog}
                            onCancel={closeDeleteConfirmationDialog}/>
      }
    </>
  );
};

export default FileCard;
