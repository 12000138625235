// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage-cards .analytics-project-card h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}
.storage-cards .analytics-project-card p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
  color: #545e6b;
}
.storage-cards .analytics-project-card .info-item-cards {
  display: flex;
  justify-content: space-between;
}
.storage-cards .analytics-project-card .info-item-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}
.storage-cards .analytics-project-card .info-item-card .item-label {
  font-size: 12px;
  font-weight: 300;
  line-height: 14.4px;
  text-align: center;
  gap: 5px;
  color: #545e6b;
  padding: 0 8px;
}
.storage-cards .analytics-project-card .info-item-card .item-count {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: center;
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/analytics/tabs/storage/storage.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADN;AAII;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AAFN;AAKI;EACE,aAAA;EACA,8BAAA;AAHN;AAMI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,aAAA;AAJN;AAMM;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,cAAA;EACA,cAAA;AAJR;AAOM;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AALR","sourcesContent":[".storage-cards {\n  .analytics-project-card {\n    h2 {\n      font-size: 24px;\n      font-weight: 400;\n      line-height: 32px;\n      text-align: center;\n    }\n\n    p {\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 24px;\n      text-align: center;\n      margin-bottom: 0;\n      color: #545e6b;\n    }\n\n    .info-item-cards {\n      display: flex;\n      justify-content: space-between;\n    }\n\n    .info-item-card {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n      padding: 16px;\n\n      .item-label {\n        font-size: 12px;\n        font-weight: 300;\n        line-height: 14.4px;\n        text-align: center;\n        gap: 5px;\n        color: #545e6b;\n        padding: 0 8px;\n      }\n\n      .item-count {\n        font-size: 24px;\n        font-weight: 500;\n        line-height: 28.8px;\n        text-align: center;\n        color: #545e6b;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
