// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-filters {
  position: relative;
  min-height: 32px;
}
.search-filters .advanced-search-toggle {
  position: absolute;
  right: 30px;
  display: flex;
  align-items: center;
}
.search-filters .advanced-search-toggle span {
  margin-right: 10px;
  color: #545e6b;
}
.search-filters .period-search {
  width: 240px;
  margin-left: 10px;
  border-bottom: 1px solid #d8d8d8;
  padding: 0 5px 5px 5px;
}
.search-filters .ant-select .ant-select-selector {
  color: #545e6b;
}
.search-filters .ant-select .ant-select-selector:hover {
  background-color: #EEEEEE;
}
.search-filters .ant-select .ant-select-selector .ant-select-selection-placeholder {
  color: #545e6b;
}

.ant-picker-range {
  background-color: transparent;
}
.ant-picker-range:hover {
  background-color: #EEEEEE;
}
.ant-picker-range input {
  color: #545e6b;
}
.ant-picker-range input::placeholder {
  color: #545e6b;
}
.ant-picker-range .ant-picker-suffix span {
  color: #545e6b;
}
.ant-picker-range .ant-picker-range-seperator {
  display: none;
}
.ant-picker-range .ant-picker-range-seperator span span {
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/search/components/search-filters.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACE,kBAAA;EACA,cAAA;AACN;AAGE;EACE,YAAA;EACA,iBAAA;EACA,gCAAA;EACA,sBAAA;AADJ;AAKI;EAEE,cAAA;AAJN;AAMM;EACE,yBAAA;AAJR;AAOM;EACE,cAAA;AALR;;AAWA;EACE,6BAAA;AARF;AAUE;EACE,yBAAA;AARJ;AAWE;EACE,cAAA;AATJ;AAWI;EACE,cAAA;AATN;AAcI;EACE,cAAA;AAZN;AAgBE;EACE,aAAA;AAdJ;AAiBM;EACE,cAAA;AAfR","sourcesContent":[".search-filters {\n  position: relative;\n  min-height: 32px;\n\n  .advanced-search-toggle {\n    position: absolute;\n    right: 30px;\n    display: flex;\n    align-items: center;\n\n    span {\n      margin-right: 10px;\n      color: #545e6b;\n    }\n  }\n\n  .period-search {\n    width: 240px;\n    margin-left: 10px;\n    border-bottom: 1px solid #d8d8d8;\n    padding: 0 5px 5px 5px;\n  }\n\n  .ant-select {\n    .ant-select-selector {\n      //background-color: #fff;\n      color: #545e6b;\n\n      &:hover {\n        background-color: #EEEEEE;\n      }\n\n      .ant-select-selection-placeholder {\n        color: #545e6b;\n      }\n    }\n  }\n}\n\n.ant-picker-range {\n  background-color: transparent;\n\n  &:hover {\n    background-color: #EEEEEE;\n  }\n\n  input {\n    color: #545e6b;\n\n    &::placeholder {\n      color: #545e6b;\n    }\n  }\n\n  .ant-picker-suffix {\n    span {\n      color: #545e6b;\n    }\n  }\n\n  .ant-picker-range-seperator {\n    display: none;\n\n    span {\n      span {\n        color: #545e6b;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
