import React, { useEffect, useState } from 'react';
import "./program-metrics.scss"
import { Select, Table } from "antd";
import SvgArrowDown from "../../../../assets/icons/js/ArrowDown";
import { useQuery } from "react-query";
import { IProgram } from "../../../settings/settings-tabs/taxonomy/programs/model/program.interface";
import { Loader } from "../../../../_shared/components/loader/loader";
import analyticsApi from "../../api/analytics-api";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import FormattedDate from "../../../../_shared/helpers/formatted-date";
import { ColumnType } from "antd/lib/table/interface";
import Pagination from "../../../../_shared/components/pagination/pagination";

const ProgramMetrics = (props: { queryParams: any }) => {
  const [programsAreFetched, setProgramsAreFetched] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState<number | undefined>();
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const [pagination, setPagination] = useState({pageSize: 10, page: 1})
  const [totalNumber, setTotalNumber] = useState(0);

  const programs = useQuery('programsForTable', () => analyticsApi.getProgramsForMetrics(), {
    onSuccess: data => {
      if (!programsAreFetched) {
        setSelectedProgram(data.length ? data[0].id : undefined)
      }
      setProgramsAreFetched(true);
    }
  });

  const programMetricsColumns = useQuery(['programMetricsColumns', selectedProgram], () => analyticsApi.getProgramMetricsColumns(selectedProgram), {
    enabled: !!selectedProgram,
    onSuccess: data => {
      const columns: any[] = [
        {
          title: 'Project',
          dataIndex: 'name',
          key: 'name',
          render: (value: string, projects: any) => <Link className='table-project-name'
                                                          to={'/projects/edit?id=' + projects.id}
                                                          state={{fromAnalytics: true, tab: '2'}}>{value}</Link>
        },
        {
          title: 'Completed',
          dataIndex: 'completed',
          key: 'completed',
          align: 'right',
          render: ((value: any) => <FormattedDate date={value} formatString="MMM d, yyyy"/>)
        },
      ];

      data.columns.forEach((column, columnIndex: number) => {
        const addColumn: ColumnType<any> = {
          title: column.name,
          dataIndex: column.id,
          key: columnIndex,
          align: 'right',
        }

        columns.push(addColumn);
      });

      setColumns(columns.slice());
    }
  })

  const projectsForProgramMetrics = useQuery(['programMetricsTableData', props.queryParams, selectedProgram, pagination], () => analyticsApi.getProjectsForProgramMetrics({
    ...props.queryParams,
    programId: selectedProgram,
    ...pagination
  }), {
    enabled: !!selectedProgram,
    onSuccess: data => setTotalNumber(data.pagination.totalElements)
  })


  useEffect(() => {
    setPagination({...pagination, page: 1})
  }, [selectedProgram])

  return (
    <div className="program-metrics-table-wrapper">
      {
        programs.isLoading ? <Loader/> :
          <div>
            <div className="row w-100 justify-space-between">
              <Select
                value={selectedProgram}
                onChange={(val) => setSelectedProgram(val)}
                style={{width: 200, marginBottom: 10}}
                suffixIcon={<SvgArrowDown/>}>
                {programs?.data &&
                  programs?.data?.map((program: IProgram) => {
                    return <Select.Option value={program.id} key={program.id}>
                      {program.name}
                    </Select.Option>
                  })
                }
              </Select>
              {
                projectsForProgramMetrics?.data && !!projectsForProgramMetrics.data.content.length &&
                <Pagination totalItems={totalNumber}
                            pageSize={pagination.pageSize}
                            currentPage={pagination.page}
                            showSizeChanger
                            onChange={(page: number, pageSize: number) => setPagination({page, pageSize})}/>
              }
            </div>
            <div>
              {programMetricsColumns.isLoading ? <Loader/> :
                <Table columns={columns}
                       loading={projectsForProgramMetrics.isLoading}
                       pagination={false}
                       dataSource={projectsForProgramMetrics?.data ? projectsForProgramMetrics?.data?.content : []}
                       rowKey={"id"}/>
              }
            </div>
          </div>
      }
    </div>
  );
};

export default ProgramMetrics;
