import * as React from "react";

const SvgSatisfaction = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm4 8H4c0 2.667 1.333 4 4 4s4-1.333 4-4zm-1-4a1 1 0 100 2 1 1 0 000-2zM5 4a1 1 0 100 2 1 1 0 000-2z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSatisfaction;
