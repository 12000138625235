// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-new-user_emailFound__epdGx, .create-new-user_emailNotFound__Uo6Ld {
  height: 20px;
  margin-top: 2px;
  margin-bottom: 5px;
  color: #e53251;
  font-size: 12px;
}

.create-new-user_emailNotFound__Uo6Ld {
  color: #2ea846;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/team/manage-insights-admins/create-new-user/create-new-user.module.scss"],"names":[],"mappings":"AAIA;EACE,YAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AAHF;;AAMA;EACE,cAAA;AAHF","sourcesContent":[".outlineInputWrapper {\n  //padding-bottom: 20px;\n}\n\n.emailFound, .emailNotFound {\n  height: 20px;\n  margin-top: 2px;\n  margin-bottom: 5px;\n  color: #e53251;\n  font-size: 12px;\n}\n\n.emailNotFound {\n  color: #2ea846;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emailFound": `create-new-user_emailFound__epdGx`,
	"emailNotFound": `create-new-user_emailNotFound__Uo6Ld`
};
export default ___CSS_LOADER_EXPORT___;
