import React, { memo, useEffect, useState } from 'react';
import './knowledge-graph.scss';
import { DebounceInput } from 'react-debounce-input';
import { Button, Dropdown, message } from 'antd';
import KnowledgeNode from './knowledge-sub-node/knowledge-node';
import KnowledgeApi from '../api/knowledge-api';
import { IKnowledgeNode } from '../model/knowledge-node.interface';
import SvgMore from '../../../assets/icons/js/More';
import SvgUnlock from '../../../assets/icons/js/unlock';
import SvgAdd from '../../../assets/icons/js/add';
import SvgSaving from '../../../assets/icons/js/Saving';
import SvgSuccess from '../../../assets/icons/js/Success';
import SvgArrowDownNoFill from '../../../assets/icons/js/ArrowDownNoFill';
import SvgArrowUpNoFill from '../../../assets/icons/js/ArrowUpNoFill';
import SvgEdit from '../../../assets/icons/js/Edit';
import SvgTrash from '../../../assets/icons/js/Trash';
import { LoadingOutlined } from '@ant-design/icons';
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ConfirmationDialog } from "../../../_shared/components/confirmation-dialog/confirmation-dialog";
import SvgTick from "../../../assets/icons/js/Tick";
import SvgClose from "../../../assets/icons/js/Close";
import SvgAlert from "../../../assets/icons/js/alert";

type KnowledgeGraphProps = {
  index: number,
  removeGraphFromArray: (i: number) => void,
  loadGraphs: (create?: boolean) => void
  addGraphToSpecifiedIndex: (index: number, graph: IKnowledgeNode) => void
}

function KnowledgeGraph(props: KnowledgeGraphProps): JSX.Element {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});

  const formMethods = useFormContext();
  const indexedGraph = `graphs.${props.index}`;
  const graph = formMethods.getValues(indexedGraph);
  const graphId = getGraphProperties('id');

  const {fields, remove, append, update} = useFieldArray({
    control: formMethods.control,
    name: `${indexedGraph}.nodes`,
    keyName: 'key'
  });

  useEffect(() => {
    if (getGraphProperties('open')) {
      setCollapsed(false);
      setEdit(true);
    }
  }, []);

  function getGraphProperties(indexName: string) {
    return formMethods.getValues(`${indexedGraph}.${indexName}`);
  }

  function onEnter(event: any): void {
    if (event.key === "Enter" || event === 'Enter') {
      event.key === "Enter" && event.preventDefault();

      setEdit(false);
    }
  }

  function onNameChanged(value: string): void {
    const node: { name: string } = {
      name: value
    }

    updateSaving(true);

    KnowledgeApi.editGraph(graphId, node).then(() => {
      updateSaving(false)
    });
  }

  function updateSaving(value: boolean) {
    setSaving(value);
  }

  function toggleEdit(): void {
    setCollapsed(edit);
    setEdit(!edit);
    setDropdownVisible(false)
  }

  function addNewNode(): void {

    const node: any = {
      name: 'Node',
      nodes: []
    }

    append(node)
    updateSaving(true);


    KnowledgeApi.createGraphNode(graphId, node)
      .then((response: IKnowledgeNode) => {
        const newNodes: IKnowledgeNode[] = getGraphProperties('nodes')
        const index = newNodes.findIndex((node) => !node.id);
        response.editNode = true
        update(index, response)
        //formMethods.setFocus(`${indexedGraph}.nodes.${index}.name`)
        updateSaving(false);
      });
  }

  function deleteNode(index: number): void {
    remove(index)
  }

  function toggleCollapsed(): void {
    setCollapsed(!collapsed);
  }

  const toggleVisible = () => {
    setDropdownVisible(prev => !prev);
  }

  const handleDeleteGraph = ({modal, id}: { modal: boolean, id: number }) => {
    if (modal) {
      props.removeGraphFromArray(props.index);
      closeConfirmationDialog();
      message.success({
        className: 'custom-success-message',
        onClose: finalizeDelete,
        key: props.index,
        content: <div className={"row align-items-center"} style={{gap: 16}}>
          <div className="row align-items-center">
            <span>Knowledge graph deleted
             <span className="undo-action" onClick={handleUndoDelete}> (Undo)</span>
            </span>
          </div>
          <span className="row align-items-end pointer" onClick={finalizeDelete}>
            <SvgClose color={"#227700"}
                      width={12}
                      height={12}/>
          </span>
        </div>,
        duration: 3,
        icon: <SvgTick color={"#227700"} width={20} height={20}/>
      });
    }
  }

  const openConfirmationDialog = () => {
    setConfirmationDialog({
      visible: true,
      okText: 'Delete',
      id: props.index,
      cancelText: "Cancel",
      title: 'Delete knowledge graph',
      titleSec: 'Are you sure you want to delete it?',
      description: <>
        This action cannot be undone. Deleting the knowledge graph will permanently remove all its data and
        associations.
      </>
    });
  }

  const closeConfirmationDialog = () => {
    setConfirmationDialog({visible: false});
  }

  const finalizeDelete = () => {
    message.destroy(props.index);
    KnowledgeApi.deleteGraph(graphId)
      .then(() => {
        toggleVisible();
        props.loadGraphs();
      }).catch(() => {
      props.loadGraphs();
      message.error({
        content: "An error occurred while deleting the knowledge graph",
        className: 'custom-error-message',
        icon: <SvgAlert color={"#CC0000"}/>
      })
    })
  }

  const handleUndoDelete = () => {
    props.addGraphToSpecifiedIndex(props.index, graph);
    message.destroy(props.index);
  };

  return (
    <div className="knowledge-node">
      <div className="knowledge-node-header" style={{
        borderBottom: collapsed || edit ? "" : "1px solid #d5d5d5",
        padding: edit ? "" : "5px 10px",
        backgroundColor: collapsed ? "#fff" : "#f5f5f5"
      }}>
        {
          edit ?
            <Controller name={`${indexedGraph}.name`} control={formMethods.control}
                        rules={{onChange: (e) => onNameChanged(e.target.value)}}
                        render={({field: {ref, ...field}}) => <DebounceInput
                          className='outline-input'
                          placeholder="New node"
                          debounceTimeout={600}
                          autoComplete="off"
                          disabled={!edit}
                          {...field}
                          onKeyDown={(e: any) => onEnter(e)}
                        />}/>
            :
            <div className='row'>
              <div onClick={() => toggleCollapsed()} className='pointer toggle-collapse relative'>
                {
                  collapsed ?
                    <SvgArrowDownNoFill color="#545e6b" style={{position: "relative", top: 2, left: 1}}/> :
                    <SvgArrowUpNoFill color="#545e6b" style={{position: "relative", top: 1}}/>
                }
              </div>
              <div className='knowledge-node-header-name'>
                {formMethods.getValues(`${indexedGraph}.name`)}
              </div>
            </div>
        }
        <div className="knowledge-node-header-action">
          {edit ?
            <>
              <div className='knowledge-node-edit-container'>
                <div className='saving-container'>
                  {
                    saving ?
                      <>
                        <SvgSaving/>
                        <span className="font-weight-300" style={{fontSize: '12px', marginLeft: 0}}>Saving ...</span>
                      </> :
                      <>
                        <SvgSuccess/>
                        <span className="font-weight-300"
                              style={{fontSize: '12px', marginLeft: 5}}>All changes saved</span>
                      </>
                  }
                </div>
                <div className='graph-action-buttons'>
                  {
                    getGraphProperties('nodes').length < 20 &&
                    <button className="icon-button rectangle" onClick={() => addNewNode()}>
                      <SvgAdd color="#F5F5F5"/>
                    </button>
                  }
                  <button className="icon-button" onClick={() => toggleEdit()}>
                    <SvgUnlock/>
                  </button>
                </div>
              </div>
            </>
            :
            <Dropdown trigger={['click']} overlay={
              <div className="column dropdown-custom-menu" style={{backgroundColor: 'white'}}>
                <Button type="text" onClick={() => toggleEdit()}>
                  <div className="menu-icons-knowledge-node icon-button d-flex align-items-center">
                    <SvgEdit/> Edit
                  </div>
                </Button>
                <Button type="text" onClick={() => openConfirmationDialog()}>
                  <div className="menu-icons-knowledge-node icon-button d-flex align-items-center">
                    {
                      deleteLoading ? <LoadingOutlined style={{fontSize: 16, marginLeft: -5, marginRight: 5}} spin/> :
                        <SvgTrash/>
                    } Delete
                  </div>
                </Button>
              </div>
            }>
              <button onClick={toggleVisible} className="icon-button pointer">
                <SvgMore onClick={(e: { preventDefault: () => any; }) => e.preventDefault()}/>
              </button>
            </Dropdown>
          }
        </div>
      </div>
      {/* <div className="hr" /> */}
      <div className="knowledge-node-body-wrapper">
        {
          !edit && !collapsed &&
          <div
            className={
              getGraphProperties('nodes').length === 0 ?
                "parent-node parent-node-has-0-children" : getGraphProperties('nodes').length === 1 ?
                  "parent-node parent-node-has-1-children" : "parent-node parent-node-has-2-more-children"}>{getGraphProperties('name')}
            <div className='draw-line'/>
          </div>
        }
        {
          !collapsed &&
          <div className="knowledge-node-body" style={{minHeight: 120}}>
            {fields.map((node, i: number) => {
              return (
                <KnowledgeNode key={node.key}
                               index={i}
                               prefix={`${indexedGraph}.nodes.${i}.`}
                               graphId={graphId}
                               isParent={true}
                               editEnable={edit}
                               isSubNode={false}
                               deleteNode={deleteNode}
                               updateSaving={updateSaving}/>
              )
            })}
          </div>
        }
      </div>
      {
        confirmationDialog.visible &&
        <ConfirmationDialog onConfirm={handleDeleteGraph} data={confirmationDialog} onCancel={closeConfirmationDialog}/>
      }
    </div>
  );
}

export default memo(KnowledgeGraph)
