import React, { useReducer } from 'react';
import './operations.scss';
import SvgArrowDown from "../../../../../assets/icons/js/ArrowDown";
import { Button, Dropdown, MenuProps } from "antd";
import SvgInsightDesk from "../../../../../assets/icons/js/InsightDesk";
import SvgExternalTool from "../../../../../assets/icons/js/ExternalTool";
import { ISurvey, ISurveyResponse } from "./model/survey.interface";
import OperationsApi from "./api/operations-api";
import { useQuery } from "react-query";
import { IExternalTool } from "./model/external-tool.interface";
import { Loader } from "../../../../../_shared/components/loader/loader";
import {
  initialOperationsDialogsState,
  OperationsDialogsReducer
} from "./operations-dialogs-reducer/operations-dialogs-reducer";
import { ConfirmationDialog } from "../../../../../_shared/components/confirmation-dialog/confirmation-dialog";
import ExternalToolTile from "./components/external-tool-tile/external-tool-tile";
import SurveyTile from "./components/survey-tile/survey-tile";
import { LinkSurveyDialog } from "./components/survey-dialog/link-survey-dialog";
import LinkExternalToolDialog from "./components/link-tool-dialog/link-tool-dialog";
import SvgAddBig from "../../../../../assets/icons/js/addBig";

export function Operations(props: any) {
  const projectId = props?.project?.id;

  const surveys = useQuery<ISurveyResponse>(['surveys', projectId], () =>
    OperationsApi.loadSurveys(projectId)
  );

  const externalTools = useQuery<IExternalTool[]>(['externaltools', props.projectId], () =>
    OperationsApi.getExternalTools(projectId)
  );

  const [{
    surveyLinkDialog,
    externalLinkDialog,
    externalLinkConfirmationDialog,
    surveyLinkConfirmationDialog
  }, dispatch] = useReducer(OperationsDialogsReducer, initialOperationsDialogsState);

  const openSurveyDialog = (linkedSurvey?: ISurvey) => {
    dispatch({
      type: 'set-survey-link-dialog-action',
      payload: {
        linkedSurvey: linkedSurvey,
        visible: true,
        projectId: projectId,
      }
    })
  };

  const openExternalLinkDialog = (externalTool?: IExternalTool) => {
    dispatch({
      type: 'set-external-link-dialog-action',
      payload: {
        externalTool: externalTool,
        visible: true,
        projectId
      }
    })
  };

  const openExternalConfirmationDialog = (tool: IExternalTool) => {
    dispatch({
      type: 'set-external-link-confirmation-dialog-action',
      payload: {
        visible: true,
        externalLink: true,
        id: "" + tool.id,
        okText: 'Unlink',
        title: 'Unlink Tool',
        titleSec: "You're about to unlink this External Tool from this project.",
        content: `${tool.title}`,
        description: <>Continuing will unlink the Survey from this project.</>,
      }
    })
  };

  const openConfirmationDialog = (survey: ISurvey) => {
    dispatch({
      type: 'set-survey-link-confirmation-dialog-action',
      payload: {
        visible: true,
        id: survey.surveyId + "",
        okText: 'Unlink',
        title: 'Unlink Survey',
        titleSec: "You're about to unlink this Survey from this project.",
        content: `${survey.name}`,
        description: <>Continuing will unlink the Survey from this project.</>,
      }
    })
  };

  const onCancel = () => {
    dispatch({type: 'set-dialogs-hidden'})
  };

  const onLinkSurveyDialogConfirm = (props: { modal?: boolean }) => {
    if (props.modal) {
      surveys.refetch();
      onCancel();
    }
  };

  const onConfirmationDialogConfirm = (props: any) => {
    if (props.modal) {
      unLinkSurvey(props.id);
      onCancel();
    }
  };

  function unLinkSurvey(surveyId: string): void {
    OperationsApi.unlinkSurvey(projectId, surveyId).then(() => {
      surveys.refetch();
    });
  }

  function newSurvey(): void {
    OperationsApi.newSurvey(projectId).then((response: any) => {
      window.open(response, '_blank');
    });
  }

  const onExternalConfirmationDialogConfirm = (props: any) => {
    if (props.modal) {
      unLinkExternalTool(props.id);
      onCancel();
    }
  };

  function unLinkExternalTool(surveyId: number): void {
    OperationsApi.deleteExternalTool(surveyId).then(() => {
      externalTools.refetch();
    });
  }

  const onLinkExternalSurveyDialogConfirm = (props: { externalLinkModal?: boolean }) => {
    if (props.externalLinkModal) {
      externalTools.refetch();
      onCancel();
    }
  };


  const menuItems: MenuProps['items'] = [
    {
      key: "surveys",
      label: (
        <span className="row align-items-center" style={{gap: 5}} onClick={() => openSurveyDialog()}>
        <SvgInsightDesk/>
        Surveys
      </span>
      ),
    },
    {
      key: "external-tool",
      label: (
        <span className="row align-items-center" style={{gap: 5}} onClick={() => openExternalLinkDialog()}>
        <SvgExternalTool/>
        External tool
      </span>
      ),
    },
  ];

  if (externalTools.isLoading || surveys.isLoading) {
    return <Loader/>
  }

  return (
    <>
      <div className="project-edit-operations">
        <div className="row project-edit-operations-buttons">
          <Dropdown.Button
            type='primary'
            trigger={["click"]}
            placement="bottomRight"
            icon={<SvgArrowDown color={"#fff"}/>}
            menu={{items: menuItems}}>
            Link service
          </Dropdown.Button>
          <Button type='ghost'
                  onClick={() => newSurvey()}
                  icon={<span className="anticon"><SvgAddBig width={14} height={14} color={"#1E87E6"}/></span>}>
            New survey
          </Button>
        </div>
        {
          externalTools.data && surveys.data &&
          externalTools.data.length === 0 && surveys.data.surveys.length === 0 &&
            <h3>
                Create a survey or link a service to get started
            </h3>
        }
        <div className="external-tools-surveys-wrapper">
          {
            externalTools.data &&
            externalTools.data.map((externalTool: IExternalTool, i: number) => {
              return <ExternalToolTile externalTool={externalTool}
                                       openConfirmationDialog={openExternalConfirmationDialog}
                                       openExternalLinkDialog={openExternalLinkDialog}
                                       key={i}
              />
            })
          }
          {
            surveys.data &&
            surveys.data.surveys.map((linkedSurvey: ISurvey, i: number) => {
              if (linkedSurvey === null) {
                return null;
              }

              return <SurveyTile linkedSurvey={linkedSurvey}
                                 openConfirmationDialog={openConfirmationDialog}
                                 openSurveyDialog={openSurveyDialog}
                                 projectId={projectId}
                                 key={i}/>
            })
          }
        </div>
      </div>
      {surveyLinkDialog.visible && (
        <LinkSurveyDialog
          data={surveyLinkDialog}
          onConfirm={onLinkSurveyDialogConfirm}
          onCancel={onCancel}
        />
      )}

      {externalLinkDialog.visible && (
        <LinkExternalToolDialog
          data={externalLinkDialog}
          onCancel={onCancel}
          onConfirm={onLinkExternalSurveyDialogConfirm}
        />
      )}

      {surveyLinkConfirmationDialog.visible && (
        <ConfirmationDialog
          data={surveyLinkConfirmationDialog}
          onConfirm={onConfirmationDialogConfirm}
          onCancel={onCancel}
        />
      )}

      {externalLinkConfirmationDialog.visible && (
        <ConfirmationDialog
          data={externalLinkConfirmationDialog}
          onConfirm={onExternalConfirmationDialogConfirm}
          onCancel={onCancel}
        />
      )}
    </>
  );
}
