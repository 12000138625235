import { EditorState, Modifier, SelectionState } from "draft-js";

const removeText = (editorState: EditorState, text: string) => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  let newContentState = contentState;

  // Iterate through the blocks in the editor
  blockMap.forEach((block: any) => {
    const blockText = block.getText();
    const blockKey = block.getKey();

    // Find where the generatedText appears in this block
    const textToRemoveStart = blockText.indexOf(text);

    if (textToRemoveStart !== -1) {
      const textToRemoveEnd = textToRemoveStart + text.length;

      // Create selection state around the text to be removed
      const selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: textToRemoveStart,
        focusOffset: textToRemoveEnd,
      });

      // Remove the text from the block content
      newContentState = Modifier.replaceText(
        newContentState,
        selectionState,
        ''
      );
    }
  });

  // Push the new content state into the editor state
  return EditorState.push(editorState, newContentState, 'remove-range');
};

export { removeText };