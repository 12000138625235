import * as React from "react";

const SvgArrowDownNoFill = (props: any) => {
  return (
    <svg width={14} height={10} viewBox="-2.5 -5 75 60" {...props}>
      <path d="M0,0 l30,40 l30,-40"
            fill="none"
            stroke={props.color ? props.color : '#d8d8d8'}
            strokeLinecap="round" strokeWidth="10"/>
    </svg>
  );
}

export default SvgArrowDownNoFill;
