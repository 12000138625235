function getPosition() {
  let selection = window.getSelection();
  let node: any = null;

  if (selection !== null && selection?.rangeCount === 0) {
    return null;
  } else {
    if (selection !== null) {
      if (!!selection.getRangeAt(0)?.getBoundingClientRect) {
        node = selection.getRangeAt(0)?.getBoundingClientRect();
      }
    }
  }

  if (node) {
    return node;
  } else {
    node = node?.parentNode;
    return null
  }
}

function getSelected() {
  var t: Selection | null = null;
  if (window.getSelection) {
    t = window.getSelection();
  } else if (document.getSelection) {
    t = document.getSelection();
    //@ts-ignore
  } else if (document?.selection) {
    //@ts-ignore
    t = document?.selection.createRange().text;
  }
  return t;
}


export { getPosition, getSelected }
