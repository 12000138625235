import { differenceInDays, format } from 'date-fns';

type DiffCategoryAndValue = {
  diff: string;
  value: string;
};


const getDiffCategoryAndValue = (diff: number, date: Date): DiffCategoryAndValue => {
  switch (diff) {
    case 0:
      return {diff: 'TODAY', value: 'Today'};
    case 1:
      return {diff: 'YESTERDAY', value: 'Yesterday'};
    default:
      if (Math.sign(diff) === -1 && Math.abs(diff) <= 1) {
        return {diff: 'LEFT', value: 'Tomorrow'};
      }
      return {
        diff: 'AGO',
        value: format(date, 'MMM d, yyyy'),
      };
  }
};

export function daysLeftDateFormatter(dateString: string): DiffCategoryAndValue {
  const date: Date = new Date(dateString);
  const today: Date = new Date();
  const diff: number = differenceInDays(today, date);

  return getDiffCategoryAndValue(diff, date);
}
