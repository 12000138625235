import React from 'react';

const SvgResponses = (props: any) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 6C5.38071 6 6.5 4.88071 6.5 3.5C6.5 2.11929 5.38071 1 4 1C2.61929 1 1.5 2.11929 1.5 3.5C1.5 4.88071 2.61929 6 4 6Z"
        fill={props?.color ? props.color : "#545E6B"}/>
      <path
        d="M4 15C5.38071 15 6.5 13.8807 6.5 12.5C6.5 11.1193 5.38071 10 4 10C2.61929 10 1.5 11.1193 1.5 12.5C1.5 13.8807 2.61929 15 4 15Z"
        fill={props?.color ? props.color : "#545E6B"}/>
      <path
        d="M15.5 3.5C15.5 4.88071 14.3807 6 13 6C11.6193 6 10.5 4.88071 10.5 3.5C10.5 2.11929 11.6193 1 13 1C14.3807 1 15.5 2.11929 15.5 3.5Z"
        fill={props?.color ? props.color : "#545E6B"}/>
      <path
        d="M13 15C14.3807 15 15.5 13.8807 15.5 12.5C15.5 11.1193 14.3807 10 13 10C11.6193 10 10.5 11.1193 10.5 12.5C10.5 13.8807 11.6193 15 13 15Z"
        fill={props?.color ? props.color : "#545E6B"}/>
    </svg>
  );
};

export default SvgResponses;
