import { DatePicker, Modal, notification, Select, Table, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import SvgArrowDown from '../../../../assets/icons/js/ArrowDown'
import "./project-status-dialog.scss"
import SvgCalendar from '../../../../assets/icons/js/calendar';
import { ColumnsType } from 'antd/lib/table';
import SvgEdit from '../../../../assets/icons/js/Edit';
import SvgCancel from '../../../../assets/icons/js/Cancel';
import SvgTick from '../../../../assets/icons/js/Tick';
import projectEditApi from '../api/project-edit-api'
import { Loader } from '../../../../_shared/components/loader/loader'
import moment from 'moment'
import { WorkflowStageEnum } from '../../../settings/settings-tabs/workflows/model/workflow-stage.enum'
import { useQuery } from 'react-query';
import FormattedDate from '../../../../_shared/helpers/formatted-date';
import { Controller, useForm } from "react-hook-form";
import _ from 'lodash';

const {Option} = Select;
type ProjectStatusPayloadData = {
  workflowId: number,
  workflowStatusId: number,
  dueDate: any,
  statuses: any[]
}

const ProjectStatusDialog = (props: any) => {
  const {register, handleSubmit, control, setValue, getValues, watch} = useForm<ProjectStatusPayloadData>()


  const [edit, setEdit] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState<number>();
  const workFlowStatusesColumns: ColumnsType<any> = [
    {
      title: 'Status',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 150,
      render: (name: any, workflowStatus: any) => {
        return (
          <div className="row align-items-center">
            <div className="color-circle" style={{backgroundColor: workflowStatus?.color}}/>
            <div>
              {name}
            </div>
          </div>
        )
      }
    },
    {
      title: `Time Elapsed: ${timeElapsed} days`,
      dataIndex: 'time',
      key: 'time',
      align: 'left',
      width: 180,
      render: (time: any, record, index) => {
        if (record.stage === WorkflowStageEnum.CLOSED) {
          return <></>
        }
        return (
          <div className="d-flex w-100 align-items-center" key={record.index}>
            {
              edit ?
                <input {...register(`statuses.${index}.time`, {valueAsNumber: true})} className='outline-input'
                       maxLength={3} type="number"
                />
                :
                <><span>{time > 0 && time + " " + 'days'}</span>
                  <span>{time === 0 && '0d'}</span> </>
            }

          </div>
        )
      }
    },
    {
      title: () =>
        <div className="flex-end w-100">
          {
            edit ?
              <>
                <Tooltip placement="bottom" title="Save">
                  <button className="icon-button" onClick={saveEditTimelapse}>
                    <SvgTick color="#3FCA5A"/>
                  </button>
                </Tooltip>
                <Tooltip placement="bottom" title="Cancel">
                  <button className="icon-button" onClick={() => toggleEditTimelapse(false)}>
                    <SvgCancel color="#E53251"/>
                  </button>
                </Tooltip>
              </>
              :
              <Tooltip placement="bottom" title="Edit Timelapse">
                <button className="icon-button" onClick={() => toggleEditTimelapse(true)}>
                  <SvgEdit/>
                </button>
              </Tooltip>
          }
        </div>,
      dataIndex: 'percentage',
      key: 'percentage',
      render: (percentage: number, record: any) => {
        if (record.stage === WorkflowStageEnum.CLOSED) {
          return <></>
        }
        return (
          <div className="workflow-status-progress-wrapper w-100">
            <div className="workflow-status-progress-bar"
                 style={{backgroundColor: record?.color, width: percentage + '%'}}/>
            <span style={{opacity: 0}}>.</span>
          </div>
        )
      }
    },];


  const [saveLoading, setSaveLoading] = useState(false);
  const [workflows, setWorkflows] = useState<any>();
  const [workflowStatuses, setWorkflowStatuses] = useState<any>();

  const projectStatusData = useQuery(['projectStatusData', props.data.projectId], () => projectEditApi.getProjectStatusData(props.data.projectId), {
    enabled: !!props?.data?.projectId
  });

  useMemo(() => {
    if (!projectStatusData?.data) {
      return;
    }

    setValue('workflowId', projectStatusData.data.currentWorkflow.id);
    setValue('workflowStatusId', projectStatusData.data.currentWorkflow.status.id);
    setValue('dueDate', moment(projectStatusData.data.dueDate));
    setValue('statuses', projectStatusData.data.workflows.find((workflow: any) => workflow.id === projectStatusData.data.currentWorkflow.id)?.statuses);

    setWorkflows(projectStatusData.data.workflows);
    setTimeElapsed(projectStatusData.data.timeElapsed);
    setWorkflowStatuses(projectStatusData.data.workflows.find((workflow: any) => workflow.id === projectStatusData.data.currentWorkflow.id)?.statuses);
  }, [projectStatusData.data])

  const onWorkflowChange = (id: any) => {
    const workflow = workflows.find((workflow: any) => workflow.id === id)
    setWorkflowStatuses(workflow.statuses);
    setValue('statuses', workflow.statuses);
    setValue('workflowId', workflow.id)
    setValue('workflowStatusId', workflow.statuses[0].id)
  }

  const toggleEditTimelapse = (value: boolean) => {
    if (!value) {
      setValue('statuses', _.cloneDeep(workflowStatuses));
    }

    setEdit(value);
  }

  const saveEditTimelapse = () => {
    setWorkflowStatuses(_.cloneDeep(getValues('statuses')));
    const calculatedTimeElapsed = getValues('statuses').reduce((total: number, status: any) => total + parseInt(status.time), 0);
    setTimeElapsed(parseInt(calculatedTimeElapsed));
    setEdit(false);
  }

  const onSave = (payload: ProjectStatusPayloadData) => {
    setSaveLoading(true);

    payload.statuses = workflowStatuses
      .filter((status: any) => status.stage !== WorkflowStageEnum.CLOSED)
      .map((status: any) => {
        return {id: status.id, time: status.time}
      })

    payload.dueDate = payload.dueDate.toISOString()

    projectEditApi.updateProjectStatusData(props.data.projectId, payload).then(() => {
      setSaveLoading(false);
      props.onCancel();
      notification.open({
        message: "Done",
        description: `Project Status information updated successfuly.`,
        type: "success",
        className: "infobox-success",
        duration: 3,
        placement: "bottomRight",
      });
    })
  }

  return (
    <Modal title="Project Status" width={784} open={props.data.visible} onCancel={props.onCancel}
           cancelButtonProps={{type: "link"}} okText="Save" onOk={handleSubmit(onSave)}
           okButtonProps={{loading: saveLoading}}>
      <div className="project-status-wrapper">
        {!projectStatusData.isLoading ?
          <>
            <div className="project-status-header" style={{gap: 20}}>
              <div className="row justify-space-between">
                <div className='outline-input-wrapper w-33'>
                  <div className="outline-input-label">
                    Workflow
                  </div>
                  <Controller name={'workflowId'} control={control} render={({field}) =>
                    <Select className={"w-100"} {...field} onChange={onWorkflowChange}
                            suffixIcon={<SvgArrowDown/>}>
                      {
                        workflows?.length &&
                        workflows.map((workflow: any, i: number) => {
                          return <Option key={i} value={workflow.id}>{workflow.name}</Option>
                        })
                      }
                    </Select>}/>
                </div>
                <div className='outline-input-wrapper w-33'>
                  <div className='outline-input-label'>Date Created</div>
                  {
                    projectStatusData?.data?.createdAt &&
                      <FormattedDate className="date-created-value" date={projectStatusData?.data?.createdAt}
                                     formatString="MMMM dd, yyyy"/>
                  }

                </div>
              </div>

              <div className="row justify-space-between">
                <div className='outline-input-wrapper w-33'>
                  <div className="outline-input-label">
                    Current Status
                  </div>
                  <Controller name={'workflowStatusId'} control={control} render={({field}) =>
                    <Select  {...field} style={{width: '100%'}}
                             suffixIcon={<SvgArrowDown/>}>
                      {
                        workflowStatuses?.length &&
                        workflowStatuses.map((workflowStatus: any, i: number) => {
                          return <Option key={i} value={workflowStatus.id}>
                            <div className="row align-items-center">
                              <div className='color-circle' style={{backgroundColor: workflowStatus.color}}/>
                              &nbsp; {workflowStatus.name}
                            </div>
                          </Option>
                        })
                      }
                    </Select>}/>
                </div>
                <div className="outline-input-wrapper w-33">
                  <div className='outline-input-label'>Due Date</div>
                  <Controller name={'dueDate'} control={control}
                              render={({field}) => <DatePicker
                                className={"outline-input"}
                                {...field}
                                style={{backgroundColor: "#fff"}}
                                showToday={false}
                                allowClear={false}
                                suffixIcon={<SvgCalendar/>}
                                placeholder=""/>}
                  />

                </div>
              </div>

            </div>

            <div className="table">
              <Table columns={workFlowStatusesColumns}
                     pagination={false}
                     className="w-100"
                     dataSource={edit ? getValues('statuses') : workflowStatuses}
                     rowKey="id"/>
            </div>
          </>
          :
          <Loader/>
        }
      </div>
    </Modal>
  )
}

export default ProjectStatusDialog
