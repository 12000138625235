import * as React from "react";

const SvgMember = (props: any) => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill={props.color ? props.color : '#545E6B'}>
          <path
            d="M7 0c2.761 0 5 2.239 5 5 0 1.202-.424 2.305-1.131 3.167C12.756 9.42 14 11.564 14 14H0c0-2.436 1.244-4.58 3.13-5.834C2.425 7.305 2 6.202 2 5c0-2.761 2.239-5 5-5z"
            transform="translate(-171 -458) translate(171 452) translate(0 6)"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgMember;
