import { EditorState, EntityInstance, SelectionState } from "draft-js";

function getEditorData(editorState: EditorState) {
  return {
    contentState: editorState.getCurrentContent(),
    inlineStyle: editorState.getCurrentInlineStyle(),
    selectionState: editorState.getSelection(),
    hasFocus: editorState.getSelection().getHasFocus(),
    isCollapsed: editorState.getSelection().isCollapsed(),
    startKey: editorState.getSelection().getStartKey(),
    startOffset: editorState.getSelection().getStartOffset(),
    endKey: editorState.getSelection().getEndKey(),
    endOffset: editorState.getSelection().getEndOffset(),
  };
}

export function getEntities(
  editorState: EditorState,
  entityType: any = null,
  selectedEntityKey: any = null
) {
  const {contentState} = getEditorData(editorState);
  const entities: any[] = [];
  contentState.getBlocksAsArray().forEach((block) => {
    let selectedEntity: {
      entityKey: string;
      blockKey: string;
      entity: EntityInstance;
    } | null = null;
    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey !== null) {
          const entity = contentState.getEntity(entityKey);
          if (!entityType || (entityType && entity.getType() === entityType)) {
            if (selectedEntityKey === null || entityKey === selectedEntityKey) {
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: contentState.getEntity(entityKey),
              };
              return true;
            } else {
              return false;
            }
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({...selectedEntity, start, end});
      }
    );
  });
  return entities;
}

export function findEntityInSelection(
  editorState: EditorState,
  entityType: any
) {
  const {startKey, startOffset, endOffset} = getEditorData(editorState);
  // @ts-ignore
  const entities = getEntitiesByBlockKey(editorState, entityType, startKey);
  if (entities.length === 0) return null;

  let selectedEntity = null;
  entities.forEach((entity: { blockKey: any; start: any; end: any }) => {
    const {blockKey, start, end} = entity;
    if (
      blockKey === startKey &&
      ((startOffset > start && startOffset < end) ||
        (endOffset > start && endOffset < end) ||
        (startOffset === start && endOffset === end))
    ) {
      selectedEntity = entity;
    }
  });
  return selectedEntity;
}

export function extendSelectionByData(editorState: EditorState, data: any) {
  const {selectionState, startKey, startOffset, endKey, endOffset} =
    getEditorData(editorState);
  let anchorKey = startKey;
  let focusKey = endKey;
  let anchorOffset = startOffset;
  let focusOffset = endOffset;
  // @ts-ignore
  data.forEach(({blockKey, start, end}, key) => {
    if (key === 0) {
      anchorKey = blockKey;
      anchorOffset = start;
    }
    if (key === data.length - 1) {
      focusKey = blockKey;
      focusOffset = end;
    }
  });
  const state = Object.assign({}, anchorKey ? {anchorKey} : {}, {
    focusKey,
    anchorOffset,
    focusOffset,
    isBackward: false,
  });
  const newSelectionState = selectionState.merge(state);
  return EditorState.acceptSelection(editorState, newSelectionState);
}

export function getEntitiesByBlockKey(
  editorState: EditorState,
  entityType = null,
  blockKey = null
) {
  return getEntities(editorState, entityType).filter(
    (entity) => entity.blockKey === blockKey
  );
}

export function getHyperlinkByEntityMapId(editorState: EditorState, entityMapId: string): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();
          // If our logic is successful
          if (entityType === "LINK") {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                url: entityData?.url,
              };
              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });
  return entities;
}

export function getRecommendationByEntityMapId(editorState: EditorState, entityMapId: string): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();
          // If our logic is successful
          if (entityType === "RECOMMENDATION") {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                url: entityData?.url,
              };
              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });
  return entities;
}

export function getImageByEntityMapId(
  editorState: EditorState,
  entityMapId: number
): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();

          // If our logic is successful
          if (entityType === "FIGURE") {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                url: entityData?.url,
              };
              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });

  return entities;
}

export function getGraphByEntityMapId(
  editorState: EditorState,
  entityMapId: number
): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();

          // If our logic is successful
          if (entityType === "GRAPH") {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                title: entityData?.title,
              };
              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });

  return entities;
}

export function getProjectByEntityMapId(
  editorState: EditorState,
  entityMapId: string
): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity: any = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();
          // If our logic is successful
          if (entityType === "PROJECT") {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                project: entityData?.project,
              };
              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });
  return entities;
}

export function getHyperlinkSelectionByEntityMapId(
  editorState: EditorState,
  entityMapId: string
): SelectionState | undefined {
  const entities: any = getHyperlinkByEntityMapId(editorState, entityMapId);

  if (entities) {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(entities.blockKey);
    const mySelection = SelectionState.createEmpty(block.getKey());

    return mySelection.merge({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: block.getKey(),
      focusOffset: block.getLength(),
    });
  }
}

export function getFigureSelectionByEntityMapId(
  editorState: EditorState,
  entityMapId: number
): SelectionState | undefined {
  const entities: any = getImageByEntityMapId(editorState, entityMapId);

  if (entities) {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(entities.blockKey);
    const mySelection = SelectionState.createEmpty(block.getKey());

    return mySelection.merge({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: block.getKey(),
      focusOffset: block.getLength(),
    });
  }
}

export function getGraphSelectionByEntityMapId(
  editorState: EditorState,
  entityMapId: number
): SelectionState | undefined {
  const entities: any = getGraphByEntityMapId(editorState, entityMapId);

  if (entities) {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(entities.blockKey);
    const mySelection = SelectionState.createEmpty(block.getKey());

    return mySelection.merge({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: block.getKey(),
      focusOffset: block.getLength(),
    });
  }
}

export function getProjectSelectionByEntityMapId(
  editorState: EditorState,
  entityMapId: string
): SelectionState | undefined {
  const entities: any = getProjectByEntityMapId(editorState, entityMapId);

  if (entities) {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(entities.blockKey);
    const mySelection = SelectionState.createEmpty(block.getKey());

    return mySelection.merge({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: block.getKey(),
      focusOffset: block.getLength(),
    });
  }
}
