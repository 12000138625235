import React, { useState } from 'react';
import SvgSave from '../../../../../../assets/icons/js/save';
import SvgCancel from '../../../../../../assets/icons/js/Cancel';
import SvgEdit from '../../../../../../assets/icons/js/Edit';
import { DebounceInput } from 'react-debounce-input';
import MyProjectApi from '../../../../api/project-api';
import { AxiosResponse } from 'axios';
import { message, notification, Spin } from 'antd';
import { queryClient } from '../../../../../../index';
import { IProject } from '../../../../models/project.interface';
import {
  ProgramMetricTypeEnum
} from "../../../../../settings/settings-tabs/taxonomy/programs/model/program-metric-type.enum";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SvgTick from "../../../../../../assets/icons/js/Tick";

export function DetailsProgramKeyMetrics(props: { project: any, viewOnly?: boolean }): JSX.Element {
  const {handleSubmit, control, getValues} = useForm({
    defaultValues: {
      projectProgramMetricsValues: props?.project?.projectProgramMetrics ?? []
    }
  })

  const {fields} = useFieldArray({
    control, name: 'projectProgramMetricsValues', keyName: "keyId"
  })

  const project = props?.project;
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);

  const submitProgramMetrics = (data: { projectProgramMetricsValues: any[] }): void => {
    if (data.projectProgramMetricsValues?.length) {
      setApiLoading(true);

      MyProjectApi.updateProgramMetrics(project.id, data.projectProgramMetricsValues)
        .then((response: AxiosResponse<IProject>) => {
          message.success({
            content: "Program metrics have been updated",
            className: "custom-success-message",
            icon: <SvgTick color={"#227700"}/>
          })

          setEdit(false);
          // @ts-ignore
          queryClient.setQueryData<IProject>(['project', JSON.stringify(project.id)], oldData => ({
            ...oldData,
            ...response
          }));
          queryClient.invalidateQueries(['project', JSON.stringify(project.id)]);
          setApiLoading(false);
        });
    } else {
      setEdit(false);
    }

  }

  let groupedMetrics: any = {};

  props.project.projectProgramMetrics.forEach((metric: { groupName: string | number; }) => {
    if (groupedMetrics[metric.groupName]) {
      groupedMetrics[metric.groupName].push(metric);
    } else {
      groupedMetrics[metric.groupName] = [metric];
    }
  });

  const findProgramMetricIndex = (metric: {
    id: number
  }) => fields.findIndex((m: any) => m.id === metric.id);

  return (
    <div className="project-edit-card">
      <div className="project-edit-header">
        <div className="left">
          <h3>Program metrics</h3>
        </div>
        <div className="right">
          {
            apiLoading ? <>
                <div/>
                <Spin/>
              </>
              :
              <>
                {edit ?
                  <>
                    <button className="icon-button" onClick={handleSubmit(submitProgramMetrics)}>
                      <SvgSave width={20}/>
                    </button>
                    <button className="icon-button cancel-icon-hover" onClick={() => setEdit(false)}>
                      <SvgCancel width={16} height={16} color="red"/>
                    </button>
                  </>
                  :
                  <>
                    <span/>
                    {!props.viewOnly &&
                        <button className="icon-button" onClick={() => setEdit(true)}>
                            <SvgEdit/>
                        </button>
                    }
                  </>
                }
              </>
          }
        </div>
      </div>
      <div className="project-edit-body-program column" style={{gap: 10}}>
        <table>
          <tbody>
          {
            Object.keys(groupedMetrics).map(groupName => (
              <React.Fragment key={groupName}>
                <tr>
                  <td colSpan={2}>{groupName}</td>
                </tr>
                {groupedMetrics[groupName].map((metric: {
                  name: string,
                  id: number,
                  value: string,
                  type: ProgramMetricTypeEnum
                }) => (
                  <tr key={metric.name + metric.id?.toString()}>
                    <td style={{
                      color: "#bbbbbb",
                      width: edit ? "75%" : "80%",
                      paddingLeft: 20
                    }}>{getValues(`projectProgramMetricsValues.${findProgramMetricIndex(metric)}.name`)}</td>
                    <td style={{padding: edit ? 2 : 8}}>{edit && metric.type !== ProgramMetricTypeEnum.CALCULATED ?
                      <div className="description pricing-model-input">
                        <div className="outline-input-wrapper">
                          <Controller control={control}
                                      name={`projectProgramMetricsValues.${findProgramMetricIndex(metric)}.value`}
                                      render={({field}) => <DebounceInput
                                        className="outline-input"
                                        debounceTimeout={600}
                                        type="number"
                                        {...field}/>}/>
                        </div>
                      </div>
                      :
                      <span className="description"
                            style={{paddingLeft: 2}}>{getValues(`projectProgramMetricsValues.${findProgramMetricIndex(metric)}.value`) ?? 0}</span>
                    }
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}
