const SvgAddBig = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 13.6667H30V18.3333H18.3333V30H13.6667V18.3333H2V13.6667H13.6667V2H18.3333V13.6667Z"
        fill={props?.color ? props.color : "#545E6B"}
      />
    </svg>
  );
};

export default SvgAddBig
