import SvgFolder from "../../../../../../../../assets/icons/js/Folder";
import { Checkbox, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import SvgFileCorrupted from "../../../../../../../../assets/icons/js/FileCorrupted";
import SvgHelp from "../../../../../../../../assets/icons/js/Help";
import MaskPIIForm from "../../../../../../../../_shared/components/mask-pii-form/mask-pii-form";

function GoogleImportDetails(props: {
  totalFolders: number,
  loading: boolean,
  totalFiles: number,
  filesWithoutFolder: number,
}) {
  return <div className="import-details-wrapper" data-testid={'import-details'}>
    <div className="import-details">
      <div className="row align-items-center" style={{gap: 20}}>
        <SvgFolder width={20} height={20} color={"#1B3380"}/>
        <div>
          <h3>
            {
              props.loading &&
              <Spin style={{marginRight: 5}} indicator={<LoadingOutlined style={{fontSize: 12}} spin/>}/>
            }
            {props.totalFolders} Folders holding {props.totalFiles} files will be imported
          </h3>
          <p>
            Each of the selected folder will be transformed into a project where you can extract and utilize valuable
            data.
          </p>
        </div>
      </div>
      <div className="row align-items-center" style={{gap: 20}}>
        <div style={{marginRight: 4}}>
          <SvgFileCorrupted/>
        </div>

        <div>
          <span className={"row align-items-center"} style={{gap: 5}}>
            <h3>
            {
              props.loading &&
              <Spin style={{marginRight: 5}} indicator={<LoadingOutlined style={{fontSize: 12}} spin/>}/>
            }
              {props.filesWithoutFolder} files are not within any folder
          </h3>
            <Tooltip
              className={"pointer"}
              title={"We bundle single files into a \"General\" project for organization and easy access. This method allows to quickly find files and offers flexibility to move them to specific projects later on if needed."}>
              <SvgHelp width={12} height={12} color={"#1B3380"}/>
            </Tooltip>
          </span>
          <p>
            These files will be merged into a project called "General", with te option to relocate them to another
            project at a later time.
          </p>
        </div>
      </div>
      <MaskPIIForm/>
    </div>
  </div>;
}

export default GoogleImportDetails;