import * as React from "react";

const Svg404 = (props: any) => {
  return (
    <svg width={378} height={236} viewBox="0 0 378 236" {...props}>
      <defs>
        <ellipse id="gvwoue1qla" cx="86.254" cy="21.353" rx="86.254" ry="21.353"/>
        <path id="4r9bs5pd9c" d="M0 4.235S3.436 0 10.09 0c6.653 0 10.113 4.235 10.113 4.235"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path fill="#D6E3F5" stroke="#B3C8E6" strokeWidth="2.1"
                      d="M16.453 54.699l-6.195 11.213 1.995 4.533H0l10.258-38.951h22.544l8.568 11.098c.714.927 1.008 2.11.808 3.262-.2 1.154-.861 2.172-1.848 2.81 0 0 0 0 0 0-1.575 1.021-3.633.871-5.04-.365l-4.798-4.208v6.298l6.342 7.46-2.016 7.474 4.2 5.122h-12.6l1.974-10.608-5.25-5.138h-6.689z"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706)"/>
                <path fill="#55A0F6" stroke="#0D78F2" strokeWidth="2.1"
                      d="M40.995 6.299c0-3.477-2.827-6.299-6.3-6.299h-18.9c-3.48 0-6.3 2.822-6.3 6.299v18.896c0 3.476 2.82 6.298 6.3 6.298h18.9c3.473 0 6.3-2.822 6.3-6.298V6.299z"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706)"/>
                <path stroke="#FFF" strokeWidth="3.151" d="M24.412 9.187h5.13c5.13 0 5.13 7.041 0 7.041h-5.13v3.018"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706)"/>
                <path fill="#FFF" fillRule="nonzero"
                      d="M3.15 1.574c0 .87-.705 1.575-1.575 1.575S0 2.443 0 1.574.705 0 1.575 0 3.15.705 3.15 1.574"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706) translate(22.711 23.28)"/>
                <g stroke="#7585A3">
                  <g fill="#D1D8E0" strokeWidth="2.1">
                    <path d="M0 0H3.15V16.796H0z"
                          transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706) rotate(-30 77.141 -19.701) translate(7.875 18.896)"/>
                  </g>
                  <g fill="#B4BFCD" strokeWidth="2.1">
                    <path
                      d="M7.35 18.896L7.35 14.697 0 8.398 0 0 3.15 0 3.15 6.299 7.35 10.498 10.5 10.498 14.7 6.299 14.7 0 17.85 0 17.85 8.398 10.5 14.697 10.5 18.896z"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706) rotate(-30 77.141 -19.701) translate(.525)"/>
                  </g>
                  <g fill="#D1D8E0" strokeWidth="2.1">
                    <path d="M0 0H11.55V3.103H0z"
                          transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706) rotate(-30 77.141 -19.701) translate(3.675)"/>
                  </g>
                  <g fill="#B4BFCD" strokeWidth="2.101">
                    <path
                      d="M.254 5.699h6.131L9.451 0l3.066 5.699h6.132S21.3 19.946 9.45 19.946C-2.399 19.946.254 5.699.254 5.699z"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706) rotate(-30 77.141 -19.701) translate(0 25.718)"/>
                  </g>
                </g>
                <path fill="#D6E3F5" stroke="#B3C8E6" strokeWidth="2.1"
                      d="M17.861 36.064l1.596 2.77s1.911-.677 3.833-1.361c2.247-.8 4.735.243 5.743 2.407 0 0 0 0 0 0 .536 1.145.578 2.455.126 3.632-.451 1.176-1.365 2.116-2.53 2.605-3.465 1.451-7.686 3.222-7.686 3.222l-9.45-9.448c-3.58-3.58-2.174-7.17.766-8.398h5.114"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706)"/>
                <path stroke="#0D78F2" strokeWidth="2.1" d="M9.496 25.195c0 3.476 2.818 6.298 6.3 6.298"
                      transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(8.706)"/>
              </g>
              <g stroke="#7585A3">
                <g fill="#E9EDF1" strokeWidth="2.101">
                  <path
                    d="M.136 31.828h40.48c0-7.023-4.598-7.227-4.598-7.227s1.489-5.891-2.94-7.578C28.64 15.337 31.655 2.226 23.428.425c-8.226-1.801-6.793 2.556-11.505 4.96-5.815 2.97-2.55 11.736-7.805 15.552-5.256 3.816-3.982 8.648-3.982 10.89z"
                    transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(0 19.756) translate(50.33 20.66)"/>
                </g>
                <g strokeWidth="2.101">
                  <path d="M0 0.826L378 0.826"
                        transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(0 19.756) translate(0 51.663)"/>
                </g>
                <g fill="#E9EDF1" strokeWidth="2.107">
                  <path
                    d="M0 52.489h128.684s-3.88-14.677-17.155-18.382c-5.877-1.635-7.322-6.778-15.539-23.819-8.216-17.04-19.799-9.942-25.22 0-5.42 9.94-10.479 18.695-19.285 23.82-8.939 5.214-10.879-4.77-21.549-6.897C23.318 25.89 9.016 26.639 0 52.489z"
                    transform="translate(-851 -312) translate(851 314) translate(0 100.103) translate(0 19.756) translate(240.6)"/>
                </g>
              </g>
            </g>
            <g transform="translate(-851 -312) translate(851 314) translate(102.744 189.293)">
              <ellipse cx="86.254" cy="21.353" fill="#55A0F6" rx="86.254" ry="21.353"/>
              <g>
                <mask id="e53pq1p7ab" fill="#fff">
                  <use xlinkHref="#gvwoue1qla"/>
                </mask>
                <g fill="#284B8A" mask="url(#e53pq1p7ab)">
                  <ellipse cx="86.254" cy="21.353" rx="86.254" ry="21.353" transform="translate(0 12.139)"/>
                </g>
              </g>
              <ellipse cx="86.254" cy="21.353" stroke="#284B8A" strokeWidth="2.105" rx="86.254" ry="21.353"/>
            </g>
            <g fill="#D6E3F5" stroke="#284B8A" strokeWidth="2.1">
              <path
                d="M129.693 231.973c-20.737-.26-35.311-2.347-35.311-2.347V153.64c0-1.018-.588-1.942-1.502-2.375-.924-.434-2.005-.296-2.793.352-3.811 3.153-9.502 7.86-14.29 11.815-2.268 1.878-5.218 2.716-8.137 2.308-2.92-.407-5.534-2.023-7.203-4.45-9.408-13.682-26.25-38.185-31.437-45.733-.987-1.425-2.604-2.276-4.326-2.276H5.248c-2.236 0-4.231-1.425-4.966-3.545-.724-2.12-.021-4.47 1.743-5.846C8.829 98.6 17.176 92.103 23.435 87.24c2.33-1.819 5.333-2.565 8.252-2.055 2.92.51 5.481 2.229 7.067 4.733 7.318 11.576 19.214 30.413 24.937 39.458.819 1.295 2.163 2.167 3.675 2.39 1.512.222 3.055-.227 4.21-1.232 3.906-3.4 9.66-8.4 14.637-12.717 6.142-5.338 14.815-6.646 22.26-3.355l9.702 4.285v-7.27c-24.255-6.424-42.147-28.534-42.147-54.79C76.028 25.4 101.438 0 132.728 0c31.29 0 56.699 25.4 56.699 56.688 0 25.385-16.726 46.896-39.753 54.105v7.955l9.692-4.285c7.444-3.29 16.127-1.983 22.27 3.355 4.966 4.318 10.72 9.317 14.637 12.717 1.155 1.005 2.688 1.454 4.21 1.232 1.512-.223 2.856-1.095 3.675-2.39l24.937-39.458c1.575-2.504 4.148-4.224 7.067-4.733 2.919-.51 5.911.236 8.253 2.055 6.247 4.862 14.605 11.359 21.398 16.648 1.775 1.376 2.478 3.725 1.743 5.846-.724 2.12-2.719 3.545-4.966 3.545h-19.435c-1.733 0-3.35.851-4.326 2.276-5.187 7.548-22.029 32.05-31.437 45.732-1.67 2.428-4.284 4.044-7.203 4.451-2.919.408-5.87-.43-8.148-2.308-4.777-3.955-10.468-8.662-14.28-11.815-.787-.648-1.879-.786-2.793-.352-.924.433-1.511 1.357-1.511 2.375v75.987S156.887 232 133.924 232l-4.231-.027z"
                transform="translate(-851 -312) translate(851 314) translate(55.076)"/>
            </g>
            <g stroke="#284B8A" strokeWidth="4.195">
              <path d="M0.826 0L0.826 13.213"
                    transform="translate(-851 -312) translate(851 314) translate(167.492 44.265)"/>
            </g>
            <g stroke="#284B8A" strokeWidth="4.195">
              <path d="M0.826 0L0.826 13.213"
                    transform="translate(-851 -312) translate(851 314) translate(208.861 44.265)"/>
            </g>
            <g>
              <path d="M0 4.235S3.436 0 10.09 0c6.653 0 10.113 4.235 10.113 4.235"
                    transform="translate(-851 -312) translate(851 314) translate(178.892 64.875)"/>
              <g transform="translate(-851 -312) translate(851 314) translate(178.892 64.875)"/>
              <path stroke="#284B8A" strokeWidth="3.144" d="M0 4.235S3.436 0 10.09 0c6.653 0 10.113 4.235 10.113 4.235"
                    transform="translate(-851 -312) translate(851 314) translate(178.892 64.875)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Svg404;
