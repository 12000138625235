import React, { useState } from 'react';
import { Divider, message, Modal } from "antd";
import "./new-repository-modal.scss"
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ErrorMessage } from "@hookform/error-message";
import repositoriesApi from "../../api/repositories-api";
import { queryClient } from "../../../../index";
import SvgTick from "../../../../assets/icons/js/Tick";

const NewRepositoryModal = (props: any) => {
  const schema = yup.object().shape({
    name: yup.string().required('Name is required!')
  })

  const form = useForm({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema)
  });

  const onCreate = (formData: { name: string }) => {
    repositoriesApi.createRepository(formData).then(() => {
      queryClient.invalidateQueries('repositories');
      message.success({
        content: 'Repository successfully created',
        className: 'custom-success-message',
        icon: <SvgTick color={"#227733"}/>
      })
      props.onCancel()
    })
  }

  return (
    <Modal
      title="New repository"
      open={props.data.open}
      onCancel={props.onCancel}
      onOk={form.handleSubmit(onCreate)}
      okText="Create"
      width={640}
      cancelButtonProps={{type: 'link'}}>
      <div className='new-folder-modal-wrapper'>
        <div className="new-folder-name-wrapper outline-input-wrapper">
          <div className="new-folder-name-label">Repository Name</div>
          <input className="new-folder-name-input outline-input"
                 type="text"
                 {...form.register('name')}
                 placeholder={"Repository name"}/>
        </div>
        <ErrorMessage name={'name'} errors={form.formState.errors} render={({message}) =>
          <small style={{marginLeft: "20%", marginTop: 20}}
                 className="form-error">{message}</small>}/>
        <Divider style={{marginBottom: -20}}/>
      </div>
    </Modal>
  );
};

export default NewRepositoryModal;
