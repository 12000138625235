import * as React from "react";

const SvgTrash = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 3h2v2H2V3h2V1h8v2zM3 7h10v8H3V7z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgTrash;
