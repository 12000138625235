import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import './meta-tags.scss';
import { IMetaTag } from './model/meta-tag.interface';
import { ITag } from './model/tag.interface';
import DeskSettingsApi from '../../../api/settings-api';
import { CloseOutlined } from '@ant-design/icons';
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const {confirm} = Modal;

type Inputs = {
  name: string
}

export function AddEditMetaTagDialog(props: any): JSX.Element {
  let tagsInput: any;
  const [tags, setTags] = useState<any[]>(props.data?.tags ? [...props.data.tags] : []);

  const [inputValue, setInputValue] = useState<any>('');
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [tagInputFocused, setTagInputFocused] = useState<boolean>(false);
  const [showInputError, setShowInputError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>({
    defaultValues: {name: props.data.name ?? ''}
  })

  const deleteTag = (removedTag: ITag) => {
    const filteredTags = [...tags].filter((tag: any) => tag.id !== removedTag.id);

    setTags(filteredTags);
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e: any) => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags.push({name: inputValue, id: Math.floor(Math.random() * (999999))})
    }

    setInputValue('')

    if (e.type === "blur") {
      setTagInputFocused(false);
    }

    e.preventDefault();
  };

  const handleInputFocus = (e: any) => {
    if (e.type === "focus") {
      setTagInputFocused(true);
    }
  }

  // function handleKeyDown(event: any): void {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //   }
  // }

  function dynamicInputFormError(): boolean {
    if (tags.length === 0) {
      return true;
    }

    return false;
  }

  const onFormSubmit = (data: Inputs) => {
    if (dynamicInputFormError()) {
      setShowInputError(true);
      return;
    }

    setApiLoading(true);

    const metaTag: IMetaTag = {
      id: props.data.id,
      name: data.name,
      tags: []
    }

    tags.forEach((tag: ITag) => {
      metaTag.tags.push({
        name: tag.name,
        id: tag.id,
        projectNumbers: tag.projectNumbers,
        storiesNumber: tag.storiesNumber
      });
    });

    if (props.data.id) {
      DeskSettingsApi.updateMetaTag(metaTag.id, metaTag)
        .then(() => {
          setApiLoading(false);
          props.onOk({modal: true});
        }, () => {
          setApiLoading(false);
        });
      return;
    }

    DeskSettingsApi.createMetatag(metaTag)
      .then(() => {
        setApiLoading(false);
        props.onOk({modal: true});
      }, () => {
        setApiLoading(false);
      });
  }

  function showPropsConfirm(tag: ITag): void {
    confirm({
      title: 'Are you sure delete this tag?',
      content: `${tag.name} is assigned to ${tag.projectNumbers} projects`,
      centered: true,
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => deleteTag(tag),
    });
  }

  return (
    <Modal
      title="New tag group"
      open={props.data.visible}
      onCancel={() => (props.onCancel())}
      centered
      width={765}
      footer={[
        <Button key="submit"
                type="primary"
                loading={apiLoading}
                onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      ]}>
      <div className="modal-body add-edit-meta-tag-dialog">
        <div className="outline-input-wrapper">
            <span className="outline-input-label">
              <span style={{color: "red"}}>* </span>Group Name</span>
          <input
            className="outline-input input"
            placeholder="Enter Group Name"
            {...register('name', {required: "Group name is required!"})}
          />
          <ErrorMessage errors={errors} name={'name'} render={({message}) => <small
            className="form-error">{message}</small>}/>
        </div>

        <div className="outline-input-wrapper">
            <span className="outline-input-label">
              <span style={{color: "red"}}>* </span>Tags (press Tab or Enter to finish writing a tag)</span>
          <div className="outline-input outline-input-tags"
               onClick={() => tagsInput?.focus()}
               style={{borderBottom: tagInputFocused ? '1px solid #1B87E6' : ''}}>
            <div className="tags-container row flex-wrap">
              {tags.map((tag: ITag, index: number) => {
                const isLongTag = tag.name?.length > 20;
                return (
                  <div key={index}>
                    <div className="tag">
                      <span>{isLongTag ? `${tag?.name.slice(0, 20)}...` : tag?.name}</span>
                      <CloseOutlined style={{marginLeft: 3}} onClick={() => {
                        tag.projectNumbers > 0 ? showPropsConfirm(tag) : deleteTag(tag)
                      }}/>
                    </div>

                  </div>
                )
              })}
              <Input
                ref={(input) => {
                  tagsInput = input;
                }}
                className="tags-input"
                placeholder={tags.length > 0 ? '' : 'Enter Tag ...'}
                type="text"
                size="small"
                style={{width: 120}}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onPressEnter={handleInputConfirm}
                onKeyDown={(e: any) => {
                  if (e.key === "Tab") {
                    e.preventDefault();
                    handleInputConfirm(e)
                  }
                }}
              />

            </div>
          </div>
          <div>
            <small className="form-error">{tags.length === 0 && showInputError && `Tag is required.`}</small>
          </div>
        </div>
      </div>
    </Modal>
  )
}
