import * as React from "react";

const SvgCommentBox = (props: any) => {
  return (
    <svg width={33} height={33} viewBox="0 0 33 33" {...props}>
      <path fillRule="evenodd" fill={props.color ? props.color : '#545E6B'} d="M30.2,25.7c0,0.6-0.4,1-1,1L4.6,26.8c-0.6,0-1-0.4-1-1L3.4,6.5c0-0.6,0.4-1,1-1l24.7-0.1c0.6,0,1,0.4,1,1
	L30.2,25.7z M6.7,18.8c-0.3,0-0.5-0.2-0.5-0.5l0-1.7c0-0.3,0.2-0.5,0.5-0.5l2.2,0l0-5.3l-2,0c-0.4,0-0.7-0.3-0.7-0.7l0-1.3
	c0-0.4,0.3-0.7,0.7-0.7l6.7,0c0.4,0,0.7,0.3,0.7,0.7l0,1.3c0,0.4-0.3,0.7-0.7,0.7l-2,0l0,5.3l2.2,0c0.3,0,0.5,0.2,0.5,0.5l0,1.7
	c0,0.3-0.2,0.5-0.5,0.5L6.7,18.8z"/>
    </svg>
  );
}

export default SvgCommentBox;
