import * as React from "react";

const SvgScience = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 6.838V3h2V1H3v2h2v3.838l-3.615 4.971A2.009 2.009 0 003.01 15h9.98a2.009 2.009 0 001.625-3.191L11 6.838zm-4 .324V3h2v4.162L10.337 9H5.664L7 7.162z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgScience;
