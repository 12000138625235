// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-wrapper {
  position: relative;
}
.switch-wrapper .switch-button {
  background-color: #D8D8D8;
  width: 32px;
  height: 8px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  display: block;
}
.switch-wrapper .switch-button::before {
  position: absolute;
  content: "";
  background-color: #9B9B9B;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  transition: 0.3s;
  top: -4px;
}
.switch-wrapper .switch-loading {
  position: absolute;
  top: -7px;
}
.switch-wrapper input {
  display: none;
}
.switch-wrapper input:checked + .switch-button::before {
  transform: translateX(16px);
  background-color: #1B87E6;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/switch/switch.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,yBAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AACJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,SAAA;AAAJ;AAGE;EACE,kBAAA;EACA,SAAA;AADJ;AAKE;EACE,aAAA;AAHJ;AAKI;EACE,2BAAA;EACA,yBAAA;AAHN","sourcesContent":[".switch-wrapper {\n  position: relative;\n\n  .switch-button {\n    background-color: #D8D8D8;\n    width: 32px;\n    height: 8px;\n    border-radius: 8px;\n    cursor: pointer;\n    position: relative;\n    transition: 0.2s;\n    display: block;\n  }\n\n  .switch-button::before {\n    position: absolute;\n    content: '';\n    background-color: #9B9B9B;\n    width: 16px;\n    height: 16px;\n    border-radius: 8px;\n    transition: 0.3s;\n    top: -4px;\n  }\n\n  .switch-loading {\n    position: absolute;\n    top: -7px;\n  }\n\n\n  input {\n    display: none;\n\n    &:checked + .switch-button::before {\n      transform: translateX(16px);\n      background-color: #1B87E6;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
