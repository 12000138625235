import Syncing from "../../../../../../assets/images/Syncing.png";
import { Button } from "antd";
import React from "react";

function SyncingDataIndicator(props: { onClick: () => any }) {
  return <div className="sync-syncing-view" data-testid={"sync-syncing-view"}>
    <h1>Syncing data</h1>
    <img src={Syncing} alt="Syncing data"/>
    <p>Your data is being transferred to InsightsHub. This may take a few minutes.</p>
    <Button type="primary" style={{padding: "5px 20px"}} onClick={props.onClick}>Go to
      home</Button>
  </div>;
}

export default SyncingDataIndicator;