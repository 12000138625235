import React from 'react';
import './taxonomy.scss';
import ProjectType from "./project-type/project-type";
import { Programs } from "./programs/programs";
import { MetaTags } from "./meta-tags/meta-tags";
import { Folders } from "./folders/folders";
// import { HighlightAnalytics } from "./highlight-analytics/highlight-analytics";

interface IPage {
  title: string;
  content: JSX.Element;
}

export function Taxonomy(props: any): JSX.Element {
  const pages: IPage[] = [
    {
      title: 'Project type',
      content: <ProjectType/>
    },
    {
      title: 'Programs',
      content: <Programs/>
    },
    {
      title: 'Folders',
      content: <Folders/>
    },
    {
      title: 'Tags',
      content: <MetaTags updateArchive={props.updateArchive}/>
    },
    // {
    //   title: 'Highlight',
    //   content: <HighlightAnalytics/>
    // },
  ]

  return (
    <div className="setup column">
      {pages.map((page: IPage, i: number) =>
        <div className="main-title" key={i}>
          <h4>{page.title}</h4>
          <div className="page">
            {page.content}
          </div>
        </div>
      )}
    </div>
  );
}
