import * as React from "react";

const SvgAdd = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Add">
          <rect id="Rectangle"
                fill={props.color ? props.color : '#545E6B'}
                x="0" y="0" width="24" height="24" rx="12"/>
          <path d="M13,6 L13,11 L18,11 L18,13 L13,13 L13,18 L11,18 L11,13 L6,13 L6,11 L11,11 L11,6 L13,6 Z"
                id="Combined-Shape"
                fill="#9B9B9B"/>
        </g>
      </g>
    </svg>
  );
};

export default SvgAdd;
