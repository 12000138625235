import React, { useContext, useEffect, useState } from 'react';
import './project-edit.scss';
import { Loader } from '../../../_shared/components/loader/loader';
import SvgArrowBack from '../../../assets/icons/js/ArrowBack';
import { message, Popover, Tabs, Tooltip } from 'antd';
import { AddEditTagsDialog } from './add-edit-tags-dialog/add-edit-tags-dialog';
import { ITag } from "../../settings/settings-tabs/taxonomy/meta-tags/model/tag.interface"
import SvgEdit from '../../../assets/icons/js/Edit';
import { Files } from './tabs/files/files';
import { Operations } from './tabs/operations/operations';
import ProjectApi from '../api/project-api';
import { SlackConversation } from './tabs/slack-conversation/slack-conversation';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IProject } from '../models/project.interface';
import { queryClient } from '../../../index';
import { ActivityLog } from './tabs/activity-log/activity-log';
import ProjectStatusDialog from './project-status-dialog/project-status-dialog';
import SvgHourGlass from '../../../assets/icons/js/HourGlass';
import Insights from "./tabs/insights/insights";
import Notes from "./tabs/notes/notes";
import GlobalContext from "../../../store/global-context";
import ProjectDetailsDialog from "./project-details-dialog/project-details-dialog";
import TagMatchesDialog from "../../../_shared/components/tag-matches-dialog/tag-matches-dialog";
import InformIconsPipe from "../../../_shared/helpers/inform-icons-pipe";
import SvgInfoCircleFilled from "../../../assets/icons/js/InfoCircleFilled";
import SvgExternalLink from "../../../assets/icons/js/ExternalLink";
import { getInstance } from "../../../_shared/helpers/domain-instance";
import SvgTick from "../../../assets/icons/js/Tick";
import SvgSettings from "../../../assets/icons/js/Settings";
import InterviewsLabs from "./tabs/interviews-labs/interviews-labs";
import { DataSettings } from "./tabs/data-settings/data-settings";

const TabPane = Tabs.TabPane;

export function ProjectEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const state: any = location.state;
  const urlParam: any = qs.parse(location.search);
  const {user} = useContext(GlobalContext);
  const workflows = useQuery('workflows', () => ProjectApi.getWorkflows(), {
    refetchOnWindowFocus: false
  });

  const owners = useQuery('teammembers', () => ProjectApi.getOwners(), {
    refetchOnWindowFocus: false
  });

  const project = useQuery<IProject>(['project', urlParam?.id], () => ProjectApi.getProject(urlParam?.id), {
    enabled: !!owners && !!workflows,
    retry: false,
    onError: (err: any) => {
      if (err.response.data.message.includes('not found')) {
        navigate('/not-found');
      }
    }
  });

  const [metaTagDialog, setMetaTagDialog] = useState<any>({visible: false});
  const [activeTab, setActiveTab] = useState<any>('1');
  const [projectStatusDialog, setProjectStatusDialog] = useState<any>({visible: false});
  // const [programDetailsDialog, setProgramDetailsDialog] = useState<any>({visible: false})
  const [projectDetailsDialog, setProjectDetailsDialog] = useState<any>({open: false})
  const [tagMatchesDialog, setTagMatchesDialog] = useState<any>({open: false});

  useEffect(() => {
    const activeTab = urlParam?.tab;
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [location]);

  const openAddMetaTagDialog = () => {
    let tags: any[] = [];

    if (tags) {
      project.data?.tags.forEach((tag: ITag) => {
        if (tag.id != null) {
          tags.push(tag);
        }
      });
    }

    setMetaTagDialog({
      id: project.data?.id,
      visible: true,
      okText: 'Save',
      tags: tags
    });
  }

  const onCancel = () => {
    setMetaTagDialog({visible: false});
  }

  const onSave = () => {
    onCancel();
    queryClient.invalidateQueries(['project', urlParam?.id]);
  }

  function navigateBack(): void {
    if (state?.fromHome) {
      navigate("/home")
      return
    }

    if (state?.fromAiChat) {
      navigate("/home?tab=2")
      return;
    }

    if (state?.fromAnalytics) {
      navigate("/metrics?tab=" + state?.tab);
      return
    }

    if (state?.fromStory) {
      navigate({pathname: '/stories/edit', search: qs.stringify({id: state.id})})
      return
    }

    if (state?.fromSnapshots) {
      navigate({pathname: '/snapshots/edit', search: qs.stringify({id: state.id})})
      return;
    }

    navigate({pathname: '/projects'});
  }

  const changeTab = (activeKey: string) => {
    const id = urlParam.id;

    setActiveTab(activeKey);

    window.history.replaceState(null, '', `?id=${id}&tab=${activeKey}`);
  };

  const openProjectStatusDialog = () => {
    setProjectStatusDialog({visible: true, projectId: urlParam?.id});
  }

  const closeProjectStatusDialog = () => {
    setProjectStatusDialog({visible: false});
    project.refetch();
  }


  const openProjectDetailsDialog = () => {
    setProjectDetailsDialog({open: true, project: project.data});
  }

  const closeProjectDetailsDialog = (data?: {
    refetch: boolean
  }) => {
    setProjectDetailsDialog({open: false})
    if (data?.refetch) {
      project.refetch()
    }
  }

  const openTagMatchesDialog = (tag: ITag) => {
    setTagMatchesDialog({open: true, tag: tag})
  }

  const closeTagMatchesDialog = () => {
    setTagMatchesDialog({open: false})
  }

  const isProjectClosed = (statusStage: string) => {
    return statusStage === 'CLOSED'
  }

  const copyProjectId = () => {
    navigator.clipboard.writeText(`https://insightshub.questionpro.${getInstance(user)}/projects/edit?id=${urlParam.id}`).then(() => message.success({
      content: "Project URL copied to clipboard",
      className: "custom-success-message",
      icon: <SvgTick color={"#227700"}/>
    }));
  }

  return (
    <>
      {(project.isLoading || workflows.isLoading || owners.isLoading) && !project?.data ? <Loader/> :
        <div className="project-edit">
          <div className="header">
            <div className="row w-100">
              <div className="column align-items-center back-button">
                <button className="icon-button back" onClick={() => navigateBack()}>
                  <SvgArrowBack/>
                </button>
              </div>
              <div className="column fxFlex" style={{gap: 0}}>
                {project?.data &&
                  <div className="row align-items-center" style={{gap: 10, minHeight: 38}}>
                    <InformIconsPipe icon={project.data.icon} color={project.data.color} width={24} height={24}/>
                    <h2 style={{marginTop: 0, marginBottom: 0}}>{project.data.name}</h2>
                    <Tooltip placement={"bottom"} title={"Project Details"}>
                      <button onClick={() => openProjectDetailsDialog()}
                              className="icon-button project-details-button">
                        <SvgSettings width={16}/>
                      </button>
                    </Tooltip>
                  </div>
                }

                <div className="project-tags-wrapper">
                  {project?.data?.tags.slice(0, 5).map((tag: ITag) => {
                    return (
                      <div className="project-tag" key={tag.id} onClick={() => openTagMatchesDialog(tag)}>
                        {tag.name}
                      </div>
                    )
                  })
                  }
                  {
                    project?.data?.tags && project?.data?.tags.length > 5 ?
                      <Popover placement={"bottomLeft"}
                               content={<div className="popover-project-tags-wrapper">
                                 {project.data.tags.slice(5).map((tag: ITag) => {
                                   return <div className="project-tag" key={tag.id}
                                               onClick={() => openTagMatchesDialog(tag)}>
                                     {tag.name}
                                   </div>
                                 })}
                               </div>}>
                        <div className="project-tag">
                          +{project?.data?.tags.length - 5}
                        </div>
                      </Popover>
                      : <></>
                  }
                  {project?.data?.tags.length ?
                    <button className="icon-button" style={{marginLeft: 4}} onClick={openAddMetaTagDialog}>
                      <SvgEdit/>
                    </button>
                    :
                    <button className="add-tag" onClick={openAddMetaTagDialog}>
                      Add tags
                    </button>
                  }


                </div>


                {/* <div className="column" style={{maxWidth: 304}}>
                  <StatusBar status={project?.data?.status.name} color={project?.data?.status.color}/>
                  <div className="row justify-space-between">
                    <StatusPipe value={project.data?.status.name} height={'200px'}/>
                    <span>
                      {project.data?.updatedAt &&
                        <FormattedDate date={project.data?.updatedAt} formatString="MMM d, yyyy - hh:mm a"/>
                      }
                    </span>
                  </div>
                </div> */}
              </div>
              <div className="column justify-space-between align-items-end">
                <div className="row">
                  {
                    project.data && (!!project?.data?.statusTimeLine?.length || !!Object.keys(project?.data?.statusTimeLine).length) && <>
                      <div className="project-status" onClick={openProjectStatusDialog}>
                        <div className="project-status-name"
                             style={{
                               backgroundColor: "" + project.data?.statusTimeLine?.color,
                               borderTopRightRadius: isProjectClosed(project.data?.statusTimeLine?.stage) ? 3 : 0,
                               borderBottomRightRadius: isProjectClosed(project.data?.statusTimeLine?.stage) ? 3 : 0
                             }}>
                          {project.data?.statusTimeLine?.name}
                        </div>

                        {
                          !isProjectClosed(project.data?.statusTimeLine?.stage) &&
                          <div className="project-status-time"
                               style={{backgroundColor: "" + project.data?.statusTimeLine?.color}}>
                            <SvgHourGlass height={14} color={"#ffffff"}/> {project.data?.statusTimeLine?.time}d
                          </div>
                        }
                      </div>
                    </>
                  }

                  <button className="icon-button" style={{marginLeft: 10}} onClick={copyProjectId}>
                    <SvgExternalLink width={16} height={16}/>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="body">
            <Tabs
              activeKey={activeTab}
              onChange={changeTab}
              items={[
                {
                  key: "1",
                  label: <div className='tab'>
                    <span className='tab-name'>Insights</span>
                  </div>,
                  children: <Insights project={project?.data} owners={owners?.data?.content}/>
                },
                {
                  key: "2",
                  label:
                    <div className='tab'>
                      <span className='tab-name'>Operations</span>
                      <span className='tab-length'>{project?.data?.counters?.operationsCount}</span>
                    </div>,
                  children: <Operations project={project?.data}/>
                },
                {
                  key: "3",
                  label: <div className='tab'>
                    <span className='tab-name'>Files</span>
                    <span className='tab-length'>{project?.data?.counters?.filesCount}</span>
                  </div>,
                  children: <Files projectId={project?.data?.id as number}/>
                },
                (project.data?.conversationLog && project.data.conversationLog && {
                  key: "4",
                  label: "Slack conversation log",
                  children: <SlackConversation project={project?.data}/>
                }),
                {
                  key: "5",
                  label: <div className='tab'>
                    <span className='tab-name'>Notes</span>
                    <span className='tab-length'>{project?.data?.counters?.notesCount}</span>
                  </div>,
                  children: <Notes project={project?.data}/>
                },
                {
                  key: "6",
                  label: <div className='tab'>
                    <span className='tab-name'>Interview Lab</span>
                  </div>,
                  children: <InterviewsLabs project={project?.data}/>
                },
                {
                  key: "7",
                  label: <div className='tab'>
                    <span className='tab-name'>Activity</span>
                  </div>,
                  children: <ActivityLog project={project?.data}/>
                },
                {
                  key: "8",
                  label: <div className='tab'>
                    <span className='tab-name'>Data Settings</span>
                  </div>,
                  children: <DataSettings
                    projectId={project?.data?.id as number}
                    autoGenerateKeyFindings={project?.data?.autoGenerateKeyFindings}/>
                },
              ]}>

              {project.data?.conversationLog && project.data.conversationLog &&
                  <TabPane tab="Slack conversation log" key="4">
                      <SlackConversation project={project?.data}/>
                  </TabPane>
              }
            </Tabs>
          </div>
        </div>
      }
      {metaTagDialog.visible &&
          <AddEditTagsDialog data={metaTagDialog} onSave={onSave} onCancel={onCancel}/>}
      {
        projectStatusDialog.visible &&
          <ProjectStatusDialog onCancel={closeProjectStatusDialog} data={projectStatusDialog}/>
      }
      {
        projectDetailsDialog.open &&
          <ProjectDetailsDialog data={projectDetailsDialog} onCancel={closeProjectDetailsDialog}/>
      }
      {
        tagMatchesDialog.open &&
          <TagMatchesDialog data={tagMatchesDialog} onSave={() => {
          }} onCancel={closeTagMatchesDialog}/>
      }
    </>
  );
}
