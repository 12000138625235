import React, { useContext } from 'react'
import thirdTemplateSnapshotBackground from "../third-template-snapshot/images/ThirdTemplateSnapshotBackground.png"
import { SnapshotTemplateEnum } from '../../../model/snapshot.interface'
import "./third-template-snapshot.scss"
import GlobalContext from '../../../../../store/global-context';
import SvgNugget from '../../../../../assets/icons/js/nugget';
import SvgQuestionProLogo from '../../../../../assets/icons/js/questionProLogo';
import TextFit from "../../../../../_shared/components/text-fit/text-fit";

const ThirdTemplateSnapshot = (props: any) => {
  const globalContext = useContext(GlobalContext);

  const onClick = () => {
    if (props?.forSelectTemplate) {
      props.onTemplateClick(SnapshotTemplateEnum.THIRD)
    }
  }

  let scale = 1;
  let transformOrigin = '';

  if (props?.mdSize) {
    scale = 0.36;
    transformOrigin = 'top left';
  }

  if (props?.smSize) {
    scale = 0.23;
    transformOrigin = 'top left';
  }

  if (props?.forSelectTemplate) {
    scale = 0.173;
    transformOrigin = 'top left';
  }

  return (
    <div className='third-template-snapshot-wrapper'
         onClick={onClick}
         style={{scale: `${scale}`, transformOrigin}}>
      <div className='third-template-snapshot-background'>
        <img src={thirdTemplateSnapshotBackground} alt=""/>
      </div>
      <div className='third-template-main-content'>
        <div className="third-template-nugget">
          <div className="third-template-nugget-logo">
            <SvgNugget color="#1B87E6" width={48} height={48}/>
          </div>
          <div className="third-template-nugget-description">
            <TextFit mode="multi" min={12} max={56} style={{height: 216, lineHeight: '150%'}}>
              {props?.nugget}
            </TextFit>
          </div>
        </div>
        <div className="third-template-image-wrapper"
        >
          {
            (props?.selectedImage?.url?.length > 0) ?
              <img src={props?.selectedImage?.url} alt="selected-image"/> :
              <>
                {
                  props?.noImageLabel &&
                  <div className='third-template-image' id="no-image-label-template">
                    <i className="bi bi-card-image" style={{fontSize: "1.25em"}}/>
                    <div>
                      No Image Selected
                    </div>
                  </div>
                }
              </>

          }
        </div>
      </div>
      <div className="third-template-footer">
        <div className="third-template-project">
          {props?.project}
          <div style={{fontWeight: 600, fontSize: 12}}>{globalContext.user?.organization.name}</div>
        </div>
      </div>

      <div className='third-template-question-pro-logo'>
        <SvgQuestionProLogo color="#fff" width={126} height={24}/>
      </div>
    </div>
  )
}

export default ThirdTemplateSnapshot
