import * as React from "react";

const SvgFiletypeXls = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero">
        <path
          d="M12 0l4 4v12H4v-1h11V5h-4V1H5v6H4V0h8zm2 8v6H0V8h14zm-3.796.764c-.272 0-.515.048-.729.144-.214.096-.381.23-.501.402-.12.172-.18.372-.18.6 0 .312.098.564.294.756.196.192.514.356.954.492.22.068.388.133.504.195.116.062.198.132.246.21.048.078.072.177.072.297 0 .196-.07.348-.21.456-.14.108-.324.162-.552.162-.208 0-.398-.035-.57-.105-.129-.053-.255-.124-.377-.213l-.121-.096-.408.456c.18.176.395.315.645.417.25.102.533.153.849.153.324 0 .604-.054.84-.162.236-.108.417-.258.543-.45.126-.192.189-.412.189-.66 0-.364-.103-.641-.309-.831-.206-.19-.513-.349-.921-.477-.236-.072-.414-.138-.534-.198-.12-.06-.203-.124-.249-.192-.046-.068-.069-.154-.069-.258 0-.156.059-.277.177-.363.118-.086.273-.129.465-.129.172 0 .333.028.483.084.113.042.224.103.336.183l.111.087.39-.45c-.188-.172-.39-.3-.606-.384-.216-.084-.47-.126-.762-.126zm-7.11.09h-.882l1.104 1.938L2.116 13h.846l.822-1.698L4.612 13h.882l-1.23-2.238 1.11-1.908h-.846l-.732 1.434-.702-1.434zm3.672 0h-.792V13h2.364l.09-.648H6.766V8.854z"
          transform="translate(-499 -2260) translate(451 1832) translate(32 416) translate(16 8) translate(0 4)"/>
      </g>
    </svg>

  );
}

export default SvgFiletypeXls;
