import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SvgTrash from '../../assets/icons/js/Trash';
import './stories.scss';
import { PageViewEnum } from './model/page-view.enum';
import SvgAddBig from '../../assets/icons/js/addBig';
import { useQuery } from 'react-query';
import storiesApi from './api/stories-api';
import { Loader } from '../../_shared/components/loader/loader';
import StoriesView from "./components/stories-view/stories-view";
import ArchivedStoriesView from "./components/archived-stories-view/archived-stories-view";
import { usePopUpTutorial } from "../../core/hooks/use-pop-up-tutorial";
import PopUpTutorial from "../../_shared/components/pop-up-tutorial/pop-up-tutorial";

export function Stories(): JSX.Element {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const {popUpTutorial, onPopUpClose} = usePopUpTutorial('stories');
  const activeTab = params.get('tab') || "PUBLISHED";
  const initialPageView = localStorage.getItem('storiesPageView') as PageViewEnum || PageViewEnum.GRID;
  const [activePageView, setActivePageView] = useState<PageViewEnum>(initialPageView);

  const storiesCounts = useQuery(['storiesCounts'], () => storiesApi.getStoriesCounts());


  useEffect(() => {
    let activePageView: PageViewEnum = localStorage.getItem('storiesPageView') as PageViewEnum;

    if (!activePageView) {
      activePageView = PageViewEnum.GRID;
      localStorage.setItem('storiesPageView', activePageView);
    }

    setActivePageView(activePageView);
  }, []);

  const navigateToStory = () => {
    navigate('/stories/edit')

  }

  const changeTab = (activeKey: string) => {
    setParams(prevParams => {
      prevParams.set('tab', activeKey)
      prevParams.delete('page')
      prevParams.delete('pageSize')
      return prevParams
    })
  };

  const changePageView = (pageView: PageViewEnum) => {
    setActivePageView(pageView);
    localStorage.setItem('storiesPageView', pageView);
  }

  const isActive = (pageView: PageViewEnum) => {
    return (pageView === activePageView || (!activePageView && pageView === PageViewEnum.LIST));
  }


  const showWelcome = () => {
    return storiesCounts.data &&
      storiesCounts.data.published === 0 &&
      storiesCounts.data.draft === 0 &&
      storiesCounts.data.archived === 0
  }

  if (storiesCounts.isLoading) {
    return <Loader/>
  }

  return (
    <div className="stories-wrapper">
      {
        showWelcome() ?
          <div className='stories-welcome'>
            <h1>Welcome to Stories</h1>
            <h2>Read, create and connect projects within your own narrative</h2>
            <h3>Create a new story to get started</h3>
            <Button onClick={() => navigateToStory()} className='row align-items-center' type='primary'>
              <SvgAddBig width={18} height={18} color={"white"}/>
              <span className="new-story">New story</span>
            </Button>
          </div> :
          <>
            <div className='header column'>
              <h2>Stories</h2>
              <div className='row justify-space-between align-items-center'>
                <h3>Read, create and connect projects within your own narrative</h3>
                <div className='view row'>
                  <div className='row' style={{gap: 20}}>
                    <span
                      data-testid={"grid-view"}
                      className='pointer'
                      onClick={() => changePageView(PageViewEnum.GRID)}
                      style={{color: isActive(PageViewEnum.GRID) ? "#1b87e6" : "#545e6b", fontSize: 20}}>
                      <i className="bi bi-grid-3x2"/>
                    </span>
                    <span
                      className='pointer'
                      data-testid={"list-view"}
                      onClick={() => changePageView(PageViewEnum.LIST)}
                      style={{color: isActive(PageViewEnum.LIST) ? "#1b87e6" : "#545e6b", fontSize: 20}}>
                      <i className="bi bi-list-ul"/>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='stories-content'>
              <Tabs destroyInactiveTabPane={true}
                    activeKey={activeTab}
                    onChange={changeTab}>
                <Tabs.TabPane data-testid={"PUBLISHED"} tab={
                  <div className='snapshots-tab'>
                    <span className='snapshot-tab-name'>Published</span>
                    <span
                      className='snapshot-tab-length'>{storiesCounts.data?.published}</span>
                  </div>}
                              key="PUBLISHED">
                  <StoriesView status="PUBLISHED" view={activePageView}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={
                  <div className='snapshots-tab'>
                    <span className='snapshot-tab-name'>Drafts</span>
                    <span
                      className='snapshot-tab-length'>{storiesCounts.data?.draft}</span>
                  </div>} key="DRAFT">
                  <StoriesView status="DRAFT" view={activePageView}/>
                </Tabs.TabPane>
                <Tabs.TabPane className='archived' tab={<div className="row align-items-center archived">
                  <div className={"snapshots-tab"}>
                    <SvgTrash color='#9b9b9b'/>
                    <span
                      className='snapshot-tab-length'>{storiesCounts.data?.archived}</span>
                  </div>
                </div>} key="ARCHIVED">
                  <ArchivedStoriesView view={activePageView}/>
                </Tabs.TabPane>
              </Tabs>
            </div>
            <button data-testid={"new-story-button"} className="icon-button new-story-button"
                    onClick={() => navigateToStory()}>
              <span className="plus-icon"/>
            </button>
          </>
      }
      {
        popUpTutorial.open &&
        <PopUpTutorial open={popUpTutorial.open} title='Stories' onPopUpClose={onPopUpClose}>
          <>
            <p>Stories allow you to craft cross-project narratives to share outside of InsightsHub.</p>
            <p>
              Think of Stories like an content editor for insights. To get started, open a new Story, write a
              title, and
              choose a header image. Then write something about your insights, and highlight any claims or
              insights that
              you
              want to link to a particular project inside of InsightsHub.
            </p>
            <p>Any project you link to a Story will be available for readers to see and access in more detail.
              This way,
              your content can be fully integrated with your InsightsHub database.
            </p>
          </>
        </PopUpTutorial>
      }
    </div>

  );
}
