import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import './all-graphs.scss';
import SvgDocuments from '../../../../../../../../../assets/icons/js/documents';
import { ReadSubNode } from '../../../../../../../../../_shared/components/read-sub-node/read-sub-node';
import {
  IGraph,
  ISelectedGraph,
  ITopKnowledgeGraphDetails,
} from '../../../../model/top-knowledge-graph-details.interface';
import SvgKnowledgeGraph from '../../../../../../../../../assets/icons/js/knowledge-graph';
import { Loader } from '../../../../../../../../../_shared/components/loader/loader';
import { IKnowledgeNode } from '../../../../../../../../knowledge/model/knowledge-node.interface';
import { searchTree } from '../../../../../../../../../_shared/helpers/search-tree';

export default function AllGraphs(props: any): JSX.Element {
  const [loader, setLoader] = useState(true);
  const [graphs, setGraphs] = useState<any[]>([]);
  const [interviews, setInterviews] = useState<any[]>([]);
  const [selectedGraph, setSelectedGraph] = useState<any>();

  useEffect(() => {
    if (selectedGraph?.id) {
      openNodeTree(selectedGraph?.id);
    }
  }, [selectedGraph]);

  useEffect(() => {
    setGraphs(props?.knowledgeGraphs?.graphs ? props.knowledgeGraphs?.graphs : [])
    setInterviews(props?.knowledgeGraphs.interviews ? props?.knowledgeGraphs.interviews : []);
    setSelectedGraph(searchTree(props?.knowledgeGraphs?.graphs, 'nodes', 'id', parseInt(props?.urlParam?.nodeId)));
    setLoader(false);
  }, []);

  useEffect(() => {
    setGraphs(props?.knowledgeGraphs?.graphs ? props.knowledgeGraphs?.graphs : [])
    setInterviews(props?.knowledgeGraphs.interviews ? props?.knowledgeGraphs.interviews : []);
    openNodeTree(selectedGraph?.id);
    setLoader(false);
  }, [props?.knowledgeGraphs]);

  function openNodeTree(nodeId: any): void {
    const id = parseInt(nodeId);
    const node = searchTree(graphs, 'nodes', 'id', id);
    if (node?.parentId) {
      const r = searchTree(graphs, 'nodes', 'id', node.parentId);
      r['open'] = true;
      openNodeTree(r.id);
      setGraphs(() => {
        return [...graphs]
      });
    }
  }

  function onSelectNode(nodeId: any) {
    setLoader(true);
    setSelectedGraph(searchTree(props?.knowledgeGraphs?.graphs, 'nodes', 'id', parseInt(nodeId)));
    props.loadPage({knowledgeGraphId: nodeId});
  }

  return (
    <>
      <div className="graph-nodes-wrapper" style={{overflow: 'auto'}}>
        <>
          {loader ? <Loader/> :
            <div className="body">
              <div className="node-wrapper">
                <div className="title">
                  <h2>Knowledge Graph</h2>
                  <h3>Insights made knowledge at everyone’s reach</h3>
                </div>
                <div className="node">
                  <div className="nodes-wrapper">
                    {graphs?.length &&
                      graphs.map(
                        (n: IKnowledgeNode, i: number) => {
                          return (
                            <ReadSubNode node={n} key={i} selectedId={selectedGraph?.id} getNodeDetails={onSelectNode}/>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
              <div className="projects">
                <div className="top-connections-dialog-graphs">
                  <div className="selected-graph-header">
                    <div className="row align-items-center">
                      <SvgKnowledgeGraph style={{marginLeft: 5}}/>
                      <div className="selected-flag-card-text" style={{marginLeft: 5}}>
                        <span>{selectedGraph?.name}</span>
                      </div>
                    </div>

                    <span>{interviews?.length}</span>
                  </div>
                </div>
                {interviews?.length ? (
                  interviews.map((project: any, i: number) => {
                    return (
                      <div className="node-project-card" key={i}>
                        <div className="node-project-card-nuggets-wrapper">
                          {interviews.map((interviews: ITopKnowledgeGraphDetails, i: number) => {
                              return (
                                <div key={i} className="interview">
                                  <div className="title-container">
                                    <div className="title-connections">
                                      <SvgDocuments
                                        width={13}
                                        height={13}
                                        color={'#1B87E6'}
                                        style={{marginRight: 8}}
                                      />
                                      <div className="title">
                                        {interviews.title}
                                      </div>
                                    </div>
                                    <div className="total-graphs-assigned">
                                      {interviews.totalConnections}
                                    </div>
                                  </div>
                                  <div className="hr"/>
                                  {interviews?.selected?.map(
                                    (selected: ISelectedGraph, i: number) => {
                                      return (
                                        <div key={i} className="selected-graph-card">
                                          <div className="content">
                                            “…{selected.selectedText}…”
                                          </div>
                                          <div className="footer">
                                            {selected?.graphs?.map(
                                              (graph: IGraph, i: number) => {
                                                return (
                                                  <div key={i} className={graph.selected ? 'node node-selected' : 'node'}>
                                                    {graph.name}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="empty-align">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  </div>
                )}
              </div>
            </div>
          }
        </>
      </div>
    </>
  )
}
