import { EventTypesEnum, GeneralEventTypesEnum } from "./event-types.enum";

export function generalEventTypePipe(eventType: EventTypesEnum): GeneralEventTypesEnum {
  switch (eventType) {
    case EventTypesEnum.ARCHIVED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_CREATED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_INDEX:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_CHANGED_REQUESTED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_DESCRIPTION_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_FINDING_CREATED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_FINDING_LINKED_TO_KG:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_FORM_VALUE_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.UPDATED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_OWNER_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_QUOTE_LINKED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_QUOTE_LINKED_TO_FLAG:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_RECOMMENDATION_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_NAME_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_KEY_METRIC_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_STATUS_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_QUOTE_LINKED_TO_KG:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_SURVEY_LINKED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_PRICING_MODEL_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_THEME_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.PROJECT_TAGS_CHANGED:
      return GeneralEventTypesEnum.PROJECT_RELATED
    case EventTypesEnum.STORY_CREATED:
      return GeneralEventTypesEnum.STORY_RELATED
    case EventTypesEnum.STORY_VIEWED_FROM_HOME:
      return GeneralEventTypesEnum.STORY_RELATED
    case EventTypesEnum.STORY_VIEWED_FROM_MENU:
      return GeneralEventTypesEnum.STORY_RELATED
    case EventTypesEnum.PROJECT_NUGGET_CREATED:
      return GeneralEventTypesEnum.NUGGET_RELATED
    case EventTypesEnum.PROJECT_NUGGET_LINKED_TO_KG:
      return GeneralEventTypesEnum.NUGGET_RELATED
    case EventTypesEnum.KNOWLEDGE_GRAPH_CREATED:
      return GeneralEventTypesEnum.KNOWLEDGE_GRAPH_RELATED
    case EventTypesEnum.KNOWLEDGE_GRAPH_UPDATED:
      return GeneralEventTypesEnum.KNOWLEDGE_GRAPH_RELATED
    case EventTypesEnum.INTERVIEW_CREATED:
      return GeneralEventTypesEnum.NOTEPAD_RELATED
    default:
      return GeneralEventTypesEnum.PROJECT_RELATED
  }
}