import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import './ai-chat.scss';
import { format, isThisWeek, isThisYear, isToday } from "date-fns";
import { useQuery } from "react-query";
import { IGlobalContext } from "../../../../../store/global-context.interface";
import GlobalContext from "../../../../../store/global-context";
import { IUserData } from "../../../../../core/model/user-data.interface";
import ProjectApi from "../../../../projects/api/project-api";
import { message, Tooltip } from "antd";
import { Loader } from "../../../../../_shared/components/loader/loader";
import AiUserAvatar from "../../../../../_shared/components/ai-user-avatar/ai-user-avatar";
import SvgCopy from "../../../../../assets/icons/js/copy";
import SvgProject from "../../../../../assets/icons/js/List";
import SvgStories from "../../../../../assets/icons/js/Stories";
import SvgDocuments from "../../../../../assets/icons/js/documents";
import SvgFiletypeDoc from "../../../../../assets/icons/js/FiletypeDoc";
import SvgExternalTool from "../../../../../assets/icons/js/ExternalTool";
import SvgDownload from "../../../../../assets/icons/js/download";
import SvgSend from "../../../../../assets/icons/js/Send";
import SaveKeyFindingDialog from "./save-key-finding-dialog/save-key-finding-dialog";
import HomeApi from "../../../api/home-api";
import SvgTick from "../../../../../assets/icons/js/Tick";
import SvgNugget2 from "../../../../../assets/icons/js/Nugget2";
import { truncateTextInMiddle } from "../../../../../_shared/helpers/truncate-text-in-middle";
import SvgLike from "../../../../../assets/icons/js/Like";
import SvgDislike from "../../../../../assets/icons/js/Dislike";
import SvgFire from "../../../../../assets/icons/js/fire";
import AIWelcomePrompts from "./ai-welcome-prompts/ai-welcome-prompts";
import TrendingPrompts from "./trending-prompts/trending-prompts";
import { NormalLoader } from "../../../../../_shared/components/normal-loader/normal-loader";
import SvgBetaLabel from "../../../../../assets/icons/js/BetaLabel";

export enum VectorTypeEnum {
  PROJECT = 'PROJECT',
  FILE = 'FILE',
  STORY = 'STORY',
  NOTE = 'NOTE'
}


export function AiChat({home}: { home: boolean }): JSX.Element {
  const globalContext: IGlobalContext = useContext(GlobalContext);
  const user: IUserData | undefined = globalContext.user;
  const textareaRef: any = useRef(null);
  const textareaContainerRef: any = useRef(null);
  const chatContainerRef: any = useRef(null);

  const [inputText, setInputText] = useState<string>('');
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [chatHistory, setChatHistory] = useState<Array<{
    isLoader?: boolean,
    id: number,
    timestamp: string,
    question?: string,
    responseText?: { text: string, references: Array<{ fileId: number, fileName: string }> }
  }>>([]);
  const [chatsIdSaved, setChatsIdSaved] = useState<number[]>([]);
  const [saveKeyFindingDialog, setKeyFindingDialog] = useState<any>({open: false});
  const [showTrendingPrompts, setShowTrendingPrompts] = useState<boolean>(false);

  const aiChatHistory = useQuery(['aiChatHistory'], () => HomeApi.langChainHistory({}), {
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (aiChatHistory.data?.length) {
      setChatHistory(aiChatHistory.data);
    }
  }, [aiChatHistory.data]);

  useEffect(() => {
    if (inputText && textareaRef.current && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaContainerRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputText]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTop = scrollHeight;
    }
  }, [chatHistory, showTrendingPrompts]);

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (inputText.length <= 1 || apiLoading) {
        return;
      }

      send();
    }
  };

  function send(text?: string) {
    setApiLoading(true);

    const userQuestion = {
      id: chatHistory.length + 1,
      timestamp: new Date().toISOString(),
      question: text ? text : inputText
    };

    setChatHistory(prevHistory => [...prevHistory, userQuestion]);

    HomeApi.askAI({question: userQuestion.question})
      .then((response: {
        id: number,
        timestamp: string,
        question: string,
        responseText: { text: string, references: Array<{ fileId: number, fileName: string }> }
      }) => {
        const aiResponse = {id: response.id, timestamp: response.timestamp, responseText: response.responseText};
        setChatHistory(prevHistory => [...prevHistory, aiResponse]);
        setApiLoading(false);
      });

    setInputText('');
  }

  function formatDateTime(dateTime: string): string {
    const date = new Date(dateTime);

    const formats = [
      {condition: isToday, format: "h:mm aa"},
      {condition: isThisWeek, format: "EEE h:mm aa"},
      {condition: isThisYear, format: "MMM d h:mm aa"},
      {condition: () => true, format: "MMM d, yyyy h:mm aa"}, // Default format
    ];

    return format(date, formats.find(({condition}) => condition(date))!.format);
  }


  const downloadFile = (file: any): void => {
    ProjectApi.downloadFile(file.id).then((response: any) => {
      const link = document.createElement('a');
      const fileName = `${file.name.toLowerCase()}.${file.extension}`;
      link.href = response;
      link.setAttribute('download', fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      // @ts-ignore
      link.parentNode.removeChild(link);
    });
  };

  const copyFile = (file: any) => {
    ProjectApi.downloadFile(file.id).then((response: any) => {
      navigator.clipboard.writeText(response).then(r => message.success({
        content: 'File copied to clipboard',
        className: 'custom-success-message',
        icon: <SvgTick color={"#227700"}/>
      }))
    });
  }


  function copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(r => message.success({
      content: 'Response copied to clipboard',
      className: 'custom-success-message',
      icon: <SvgTick color={"#227700"}/>
    }));
  }

  function openSaveKeyFindingDialog(chatId: number, description: string, references: any[]) {
    const projectReferences = references.filter((ref) => ref.type === VectorTypeEnum.PROJECT);
    const fileReferences = references.filter((ref) => ref.type === VectorTypeEnum.FILE);
    if (projectReferences.length || fileReferences.length) {
      setKeyFindingDialog((prev: any) => ({
        ...prev,
        open: true,
        description,
        chatId,
        reference: projectReferences[0],
        fileReference: fileReferences[0]
      }));
      return;
    }
    setKeyFindingDialog((prev: any) => ({...prev, open: true, chatId, description}));
  }

  function closeSaveKeyFindingDialog(data?: { chatIdSaved: number }) {
    if (data?.chatIdSaved) {
      message.success({
        content: 'Key finding saved',
        className: 'custom-success-message',
        icon: <SvgTick color={"#227700"}/>
      })
      setChatsIdSaved(prev => ([...prev, data.chatIdSaved]));
    }

    setKeyFindingDialog({open: false})
  }

  const saveInsightIsDisabled = (chatId: number) => chatsIdSaved.includes(chatId);

  const likeResponse = (message: any) => {
    const reaction = message.liked === true ? null : "LIKED";
    HomeApi.likeDislikeResponse(message.id, reaction).then(() => {
      aiChatHistory.refetch()
    });
  }

  const dislikeResponse = (message: any) => {
    const reaction = message.liked === false ? null : "DISLIKED";
    HomeApi.likeDislikeResponse(message.id, reaction).then(() => {
      aiChatHistory.refetch()
    });
  }

  const showTrendingPromptsHandler = () => {
    setShowTrendingPrompts(prev => !prev);
  }

  return (
    <div className="home-ai-chat column justify-space-between">
      {/*<button onClick={onDelete}>del</button>*/}
      {aiChatHistory.isLoading ? <Loader/>
        :
        <>
          {
            showTrendingPrompts ?
              <TrendingPrompts onCancelClick={() => setShowTrendingPrompts(false)}
                               onSendClick={(text: string) => send(text)}/> :
              chatHistory.length === 0 ?
                <AIWelcomePrompts onClick={(text) => send(text)}/> :
                <div className="chat-convo" ref={chatContainerRef}>
                  {home &&
                    chatHistory.map((message: any, index) => (
                      <div key={index}>
                        {message.question && <div key={index + 'question'} className="chat-text user-chat-text">
                          <div className="user-info">
                            <div className="user-display-name">
                              <span
                                className="timestamp">{formatDateTime(message.timestamp ?? new Date().toISOString())}</span>
                              <span>You</span>
                            </div>
                            <div className="user-avatar">
                              {
                                user?.profilePic ? <img src={user.profilePic} alt='userProfilePic'/> :
                                  <span>{user?.initials ? user.initials : 'IH'}</span>
                              }
                            </div>
                          </div>
                          <div className="chat-bubble">
                            <span>{message.question}</span>
                          </div>
                        </div>}
                        {message.responseText &&
                          <div key={index + 'airesponse'} className="chat-text ai-chat-text">
                            <div className="user-info">
                              <div style={{width: 30, marginRight: 10}}>
                                <AiUserAvatar color={'#1B3380'}/>
                              </div>
                              <div className="user-display-name">
                                <span>AskIH</span>
                                <span className="timestamp">{formatDateTime(message.timestamp)}</span>
                              </div>
                            </div>
                            <div className="chat-bubble-wrapper column pointer">
                              <div className="chat-bubble">
                                <span>{message?.responseText?.text}</span>
                              </div>
                              <div className="chat-bubble-actions">
                                <Tooltip title={"Save as a key finding"}>
                                  <div
                                    className={saveInsightIsDisabled(message.id) ? "chat-bubble-action disabled-chat-bubble-action" : "chat-bubble-action"}
                                    onClick={() => openSaveKeyFindingDialog(message.id, message.responseText.text, message.responseText.references)}>
                                    <SvgNugget2/>
                                  </div>
                                </Tooltip>
                                <Tooltip title={"Copy to clipboard"}>
                                  <div className="chat-bubble-action"
                                       onClick={() => copyToClipboard(message.responseText?.text)}>
                                    <SvgCopy width={16} height={16}/>
                                  </div>
                                </Tooltip>
                                <Tooltip title={"Good response"}>
                                  <div
                                    className={`chat-bubble-action ${message?.liked === true ? 'chat-bubble-action-active' : ''}`}
                                    onClick={() => likeResponse(message)}>
                                    <SvgLike/>
                                  </div>
                                </Tooltip>
                                <Tooltip title={"Bad response"}>
                                  <div
                                    className={`chat-bubble-action ${message?.liked === false ? 'chat-bubble-action-active' : ''}`}
                                    onClick={() => dislikeResponse(message)}>
                                    <SvgDislike/>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>

                            {message.responseText.references.map((ref: {
                              id: number,
                              name: string,
                              type: VectorTypeEnum,
                              projectName?: string,
                              projectDescription?: string,
                              projectId?: number
                            }, index: number) => {
                              if (ref.type === VectorTypeEnum.PROJECT) {
                                return (
                                  <div className="chat-bubble-wrapper" style={{marginTop: 5}}>
                                    <div className="chat-bubble"
                                         key={ref.id + ref.type}
                                         style={{marginTop: '5px', width: 'max-content'}}>
                                      <Link to={`/projects/edit?id=${ref.id}`} className="row align-items-center"
                                            state={{fromAiChat: true}} style={{borderRadius: 10, gap: 8}}
                                            key={ref.id + index}><SvgProject width={12} height={12}/> {ref.name}</Link>
                                    </div>
                                    {/*<div className="chat-bubble-actions">*/}
                                    {/*  <Tooltip title={"Save as a key finding"}>*/}
                                    {/*    <div className="chat-bubble-action"*/}
                                    {/*         onClick={() => openSaveKeyFindingDialog(message.responseText.text, message.responseText.references)}>*/}
                                    {/*      <SvgNugget width={16} height={16}/>*/}
                                    {/*    </div>*/}
                                    {/*  </Tooltip>*/}
                                    {/*  <Tooltip title={"Copy to clipboard"}>*/}
                                    {/*    <div className="chat-bubble-action"*/}
                                    {/*         onClick={() => copyToClipboard(message.responseText?.text)}>*/}
                                    {/*      <SvgCopy width={12} height={12}/>*/}
                                    {/*    </div>*/}
                                    {/*  </Tooltip>*/}
                                    {/*</div>*/}
                                  </div>

                                )
                              }

                              if (ref.type === VectorTypeEnum.STORY) {
                                return (
                                  <div className="chat-bubble-wrapper" style={{marginTop: 5}}>
                                    <div className="chat-bubble column"
                                         key={ref.id + ref.type}
                                         style={{marginTop: '5px'}}>
                                      <Link to={`/stories/edit?id=${ref.id}`} className="row align-items-center"
                                            state={{fromAiChat: true}} style={{borderRadius: 10, gap: 8}}
                                            key={ref.id + index}><SvgStories width={12} height={12}/> {ref.name}</Link>
                                    </div>

                                    {/*<div className="chat-bubble-actions">*/}
                                    {/*  <Tooltip title={"Save as a key finding"}>*/}
                                    {/*    <div className="chat-bubble-action"*/}
                                    {/*         onClick={() => openSaveKeyFindingDialog(message.responseText.text, message.responseText.references)}>*/}
                                    {/*      <SvgNugget width={16} height={16}/>*/}
                                    {/*    </div>*/}
                                    {/*  </Tooltip>*/}
                                    {/*  <Tooltip title={"Copy to clipboard"}>*/}
                                    {/*    <div className="chat-bubble-action"*/}
                                    {/*         onClick={() => copyToClipboard(message.responseText?.text)}>*/}
                                    {/*      <SvgCopy width={12} height={12}/>*/}
                                    {/*    </div>*/}
                                    {/*  </Tooltip>*/}
                                    {/*</div>*/}
                                  </div>
                            )
                          }

                          if (ref.type === VectorTypeEnum.NOTE) {
                            return (
                              <div className="chat-bubble-wrapper" style={{marginTop: 5}}>
                                <div className="chat-bubble column"
                                     key={ref.id + ref.type}
                                     style={{marginTop: '5px'}}>
                                  <Link to={`/projects/edit/interview?id=${ref.projectId}&interview=${ref.id}`}
                                        state={{fromAiChat: true}}
                                        onClick={e => !ref?.projectId && e.preventDefault()}
                                        className="row align-items-center"
                                        style={{borderRadius: 10, gap: 8}}
                                        key={ref.id + index}><SvgDocuments width={12} height={12}/> {ref.name}</Link>
                                </div>

                                {/*<div className="chat-bubble-actions">*/}
                                {/*  <Tooltip title={"Save as a key finding"}>*/}
                                {/*    <div className="chat-bubble-action"*/}
                                {/*         onClick={() => openSaveKeyFindingDialog(message.responseText.text, message.responseText.references)}>*/}
                                {/*      <SvgNugget width={16} height={16}/>*/}
                                {/*    </div>*/}
                                {/*  </Tooltip>*/}
                                {/*  <Tooltip title={"Copy to clipboard"}>*/}
                                {/*    <div className="chat-bubble-action"*/}
                                {/*         onClick={() => copyToClipboard(message.responseText?.text)}>*/}
                                {/*      <SvgCopy width={12} height={12}/>*/}
                                {/*    </div>*/}
                                {/*  </Tooltip>*/}
                                {/*</div>*/}
                              </div>
                            )
                          }

                          if (ref.type === VectorTypeEnum.FILE) {
                            return (
                              <div className="chat-bubble-wrapper" style={{marginTop: 5}}>
                                <Tooltip
                                  className=""
                                  // placement={"left"}
                                  // title={ref?.projectId ? `Project Name: ${ref?.proejctName}, \n Project Description: ${ref?.projectDescription}` : ""}
                                >
                                  <div className="chat-bubble column w-100"
                                       key={ref.id + ref.type}
                                       style={{marginTop: '5px'}}>
                                    <Link to={`/projects/edit?id=${ref?.projectId}&tab=3`}
                                          state={{fromAiChat: true}}
                                          onClick={e => !ref?.projectId && e.preventDefault()}
                                          style={{
                                            borderRadius: 10,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 8,
                                            width: 'max-content'
                                          }}
                                          key={ref.id + index}
                                    >
                                      <div style={{padding: 2}}>
                                        <SvgFiletypeDoc/>
                                      </div>
                                      <div>
                                        {truncateTextInMiddle(ref.name, 50)}
                                      </div>
                                    </Link>
                                  </div>
                                </Tooltip>

                                <div className="chat-bubble-actions">
                                  <Tooltip title={"Copy to clipboard"}>
                                    <div className="chat-bubble-action"
                                         onClick={(e) => {
                                           copyFile(ref)
                                         }}>
                                      <SvgExternalTool width={16} height={16}/>
                                    </div>
                                  </Tooltip>
                                  <Tooltip title={"Download file"}>
                                    <div className="chat-bubble-action"
                                         onClick={(e) => {
                                           downloadFile(ref)
                                         }}>
                                      <SvgDownload width={20} height={20}/>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            )
                          }

                          return (
                            <div className="chat-bubble-wrapper" style={{marginTop: 5}}>
                              <div className="chat-bubble"
                                   key={ref.id + ref.type}
                                   style={{marginTop: '5px'}}>
                          <span style={{borderRadius: 10}} key={ref.id + index}>
                            {ref.name}
                          </span>
                              </div>

                              <div className="chat-bubble-actions">
                                <Tooltip title={"Copy to clipboard"}>
                                  <div className="chat-bubble-action"
                                       onClick={() => copyToClipboard(message.responseText?.text)}>
                                    <SvgCopy width={16} height={16}/>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          )
                            })
                            }
                          </div>}
                      </div>
                    ))
                  }
                  {
                    apiLoading &&
                    <div key={'loader' + 'airesponse'} className="chat-text ai-chat-text">
                      <div className="user-info">
                        <div style={{width: 30, marginRight: 10}}>
                          <AiUserAvatar color={'#1B3380'}/>
                        </div>
                        <div className="user-display-name">
                          <span>AskIH</span>
                          {/*<span className="timestamp">{formatDateTime(message.timestamp)}</span>*/}
                        </div>
                      </div>
                      <div className="chat-bubble-wrapper column pointer">
                        <div className="chat-bubble">
                          <span className="">
                            <NormalLoader/>
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
          }

          <div className="row">
            <div className="input-box w-100 relative"
                 ref={textareaContainerRef}>
              <input className="input-text"
                     ref={textareaRef}
                     placeholder="Ask for insights"
                     onKeyDown={onKeyDown}
                     value={inputText} onChange={(e) => setInputText(e.target.value)}/>
              <button className="send-button" onClick={() => send()} disabled={inputText.length <= 1 || apiLoading}>
                <SvgSend width={16} height={16}/>
              </button>
            </div>
            <Tooltip title={"Trending prompts"} placement={"topRight"}>
              <button onClick={showTrendingPromptsHandler} className="show-recommendations-button">
                <SvgFire/>
                <div className="beta-feature-svg">
                  <SvgBetaLabel width={16} height={16}/>
                </div>
              </button>
            </Tooltip>
          </div>

        </>
      }
      {
        saveKeyFindingDialog.open &&
        <SaveKeyFindingDialog open={saveKeyFindingDialog.open} data={saveKeyFindingDialog}
                              onCancel={closeSaveKeyFindingDialog}/>
      }
    </div>
  );
}
