// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.external-url-import-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.external-url-import-wrapper .ant-select .ant-select-selector {
  border: 1px solid #d8d8d8;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/home-initial-sync/pages/sync-with-external-data/components/external-url-import/external-url-import.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,WAAA;EACA,uBAAA;AAAF;AAGI;EACE,yBAAA;AADN","sourcesContent":[".external-url-import-wrapper {\n  //height: 150px;\n  display: flex;\n  width: 100%;\n  justify-content: center;\n\n  .ant-select {\n    .ant-select-selector {\n      border: 1px solid #d8d8d8;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
