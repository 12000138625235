import { Button, Modal, Tooltip } from 'antd';
import React, { useCallback, useContext } from 'react';
import './add-edit-oauth-provider.dialog.scss';
import { IExternalProviderInterface } from '../external-oauth.interface';
import { useMutation } from 'react-query';
import SettingsApi from '../../../../../api/settings-api';
import GlobalContext from "../../../../../../../store/global-context";
import { IUserData } from "../../../../../../../core/model/user-data.interface";
import { getInstance } from "../../../../../../../_shared/helpers/domain-instance";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { ErrorMessage } from "@hookform/error-message";

export function AddEditOauthProviderDialog({data, onCancel}: any): JSX.Element {
  const GlobalStore = useContext(GlobalContext);
  const userData: IUserData = GlobalStore.user;
  const redirectUrl: string = `https://${data.portalSettings.publicUrl}.insightshub.questionpro.${getInstance(userData)}/auth/generic/redirect`;


  const schema = yup.object({
    name: yup.string().required('Name is required!'),
    clientId: yup.string().required('Client ID is required!'),
    clientSecret: yup.string().required('Client Secret is required!'),
    authorizationEndpoint: yup.string().required('Authorization Endpoint is required!'),
    tokenEndpoint: yup.string().required('Token Endpoint is required!'),
    userInfoEndpoint: yup.string().required('User Info Endpoint is required!'),
    // redirectUri: yup.string().required('Redirect Uri is required!'),
    endSessionEndpoint: yup.string().required('End Session Endpoint is required!'),
    scopes: yup.string().required('Scopes is required!')
  })

  const {register, control, handleSubmit, formState: {errors}} = useForm({
    defaultValues: data.externalProvider ?? {
      name: '',
      clientId: '',
      clientSecret: '',
      authorizationEndpoint: '',
      tokenEndpoint: '',
      userInfoEndpoint: '',
      redirectUri: redirectUrl,
      endSessionEndpoint: '',
      scopes: ''
    },
    resolver: yupResolver(schema)
  })

  const mutation = useMutation((formData: IExternalProviderInterface) => SettingsApi.addExternalProvide(formData));

  const onSubmit = useCallback(
    (data: IExternalProviderInterface) => {
      mutation.mutate(data, {
        onSuccess: () => onCancel()
      })
    },
    [mutation],
  );


  if (!data?.portalSettings?.publicUrl) {
    return (
      <Modal title="Add OAuth Provider"
             open={data.visible}
             onCancel={onCancel}
             width={750}
             footer={[
               <Button key="submit" type="primary"
                       onClick={onCancel}>Cancel</Button>
             ]}>
        <div className="d-flex justify-space-center w-100">
          <h2>Please Setup Portal First!</h2>
        </div>
      </Modal>
    )
  }

  return (
    <Modal title="Add OAuth Provider"
           open={data.visible}
           onCancel={onCancel}
           width={750}
           footer={[
             <Button key="submit" type="primary"
                     onClick={handleSubmit(onSubmit)}>Save</Button>
           ]}>
      <div className="column add-edit-external-auth">
        <div className="row input-margin">
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">Name</span>
            <input
              className="outline-input"
              {...register('name')}
              placeholder="Name"/>
            <ErrorMessage errors={errors} name='name' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
        </div>
        <div className="row input-margin">
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">Client ID</span>
            <input
              className="outline-input"
              {...register('clientId')}
              placeholder="clientId"/>
            <ErrorMessage errors={errors} name='clientId' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">Client Secret</span>
            <input
              className="outline-input"
              {...register('clientSecret')}
              placeholder="clientSecret"/>
            <ErrorMessage errors={errors} name='clientSecret' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
        </div>
        <div className="row input-margin">
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">Authorization Endpoint</span>
            <input
              className="outline-input"
              {...register('authorizationEndpoint')}
              placeholder="authorizationEndpoint"/>
            <ErrorMessage errors={errors} name='authorizationEndpoint' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">Token Endpoint</span>
            <input
              className="outline-input"
              {...register('tokenEndpoint')}
              placeholder="tokenEndpoint"/>
            <ErrorMessage errors={errors} name='tokenEndpoint' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
        </div>
        <div className="row input-margin">
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">User Info Endpoint</span>
            <input
              className="outline-input"
              {...register('userInfoEndpoint')}
              placeholder="userInfoEndpoint"/>
            <ErrorMessage errors={errors} name='userInfoEndpoint' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
          <div className="outline-input-wrapper fxFlex">
            <Tooltip placement="top"
                     title="This cannot be edited. Please add the redirect URI as shown in the input to your configuration.">
            <span className="outline-input-label">Redirect Uri
              &nbsp;<InfoCircleOutlined/>
            </span>
            </Tooltip>
            <textarea
              className="outline-input"
              style={{resize: 'none', padding: '2px 2px'}}
              rows={2}
              disabled={true}
              {...register('redirectUri')}
              placeholder="redirectUri"/>
          </div>
        </div>
        <div className="row input-margin">
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">End Session Endpoint</span>
            <input
              className="outline-input"
              {...register('endSessionEndpoint')}
              placeholder="endSessionEndpoint"/>
            <ErrorMessage errors={errors} name='endSessionEndpoint' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
          <div className="outline-input-wrapper fxFlex">
            <span className="outline-input-label">Scopes</span>
            <input
              className="outline-input"
              {...register('scopes')}
              placeholder="scopes"/>
            <ErrorMessage errors={errors} name='scopes' render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
        </div>
      </div>
    </Modal>
  );
}
