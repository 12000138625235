import React, { useState } from 'react';
import './surveys.scss'
import { ColumnsType } from "antd/es/table";
import { ISurveyTableElement } from "./model/survey.interface";
import DaysLeftDatePipe from "../../_shared/helpers/days-left-date-pipe";
import { Button, message, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import surveysApi from "./api/surveys-api";
import ImportSurveyDialog from "./components/import-survey-dialog/import-survey-dialog";
import Pagination from "../../_shared/components/pagination/pagination";
import { DebounceInput } from "react-debounce-input";
import SvgSearch from "../../assets/icons/js/Search";
import SvgTick from "../../assets/icons/js/Tick";
import SvgAlert from "../../assets/icons/js/alert";

const Surveys = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortInfo, setSortInfo] = useState<any>({});
  const columns: ColumnsType<ISurveyTableElement> = [
    {
      title: "Survey name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: sortInfo?.columnKey === "name" ? sortInfo?.order : undefined,
      render: (value: string, survey: ISurveyTableElement) => (
        <Link to={`/surveys/details?id=${survey.id}&surveyId=${survey.surveyId}`} className="project-name">
          {value}
        </Link>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdTime",
      key: "createdTime",
      sorter: true,
      sortOrder: sortInfo?.columnKey === "createdTime" ? sortInfo?.order : undefined,
      render: (value) => <>{value && DaysLeftDatePipe(value)}</>
    },
    {
      title: "Responses",
      key: "totalResponses",
      sorter: true,
      sortOrder: sortInfo?.columnKey === "totalResponses" ? sortInfo?.order : undefined,
      dataIndex: "totalResponses"
    },
    {
      title: "Status",
      key: "status",
      sorter: true,
      sortOrder: sortInfo?.columnKey === "status" ? sortInfo?.order : undefined,
      dataIndex: "status"
    },
  ]
  const [importSurveyDialog, setImportSurveyDialog] = useState({open: false});

  const page = parseInt(searchParams.get("page") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || "10");
  const search = searchParams.get("search") || "";
  const orderBy = searchParams.get("orderBy") || "";
  const orderDirection = searchParams.get("orderDirection") || "";
  const [totalCount, setTotalCount] = useState(0);

  const params = {
    page,
    pageSize,
    ...(search && {search}),
    ...(orderBy && {orderBy: orderBy as string}),
    ...(orderDirection && {orderDirection: orderDirection as "ASC" | "DESC"})
  }

  const surveys = useQuery(["surveys", params], () => surveysApi.getSurveys(params), {
    onSuccess: data => setTotalCount(data.totalCount)
  })

  const onConfirm = (survey: any) => {
    const payload = {
      surveyId: survey.surveyId,
    }
    surveysApi.importSurvey(payload).then(() => {
      closeImportSurveyDialog();
      message.success({
        content: "Survey imported successfully",
        className: "custom-success-message",
        icon: <SvgTick color={"#227700"}/>
      });
    }).catch((err: any) => {
      message.error({
        content: err.response.data.message,
        className: "custom-error-message",
        icon: <SvgAlert color={"#CC0000"}/>
      });
    })
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    setSortInfo({
      columnKey: sorter.order ? sorter.columnKey : null,
      order: sorter.order
    })
    setSearchParams((prevParams) => {
      prevParams.set("orderBy", sorter.order ? sorter.columnKey : "");
      prevParams.set("orderDirection", sorter.order === "ascend" ? "ASC" : sorter.order === "descend" ? "DESC" : "");
      if (!sorter.order) {
        prevParams.delete("orderBy");
        prevParams.delete("orderDirection");
      }
      return prevParams
    })
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setSearchParams((prevParams) => {
      prevParams.set("page", page.toString());
      prevParams.set("pageSize", pageSize.toString());
      return prevParams;
    })
  }

  const handleSearchChange = (e: any) => {
    setSearchParams((prevParams) => {
      prevParams.set("search", e.target.value);
      prevParams.delete("page");
      prevParams.delete("pageSize");
      if (!e.target.value) {
        prevParams.delete("search");
      }
      return prevParams;
    })
  }

  const openImportSurveyDialog = () => {
    setImportSurveyDialog({open: true})
  }

  const closeImportSurveyDialog = () => {
    setImportSurveyDialog({open: false})
  }

  return (
    <div className="surveys-page-wrapper" style={{marginBottom: 40}}>
      {
        surveys.data && surveys.data.totalCount === 0 && !search.length ?
          <div className="surveys-landing-page">
            <h2>Welcome to QuestionPro Surveys</h2>
            <h3>Link and analyze your Surveys to start getting the insights you need</h3>
            <Button type="primary" onClick={openImportSurveyDialog} icon={<PlusOutlined/>}>New survey</Button>
          </div> : <>
            <h2 style={{fontSize: 24, fontWeight: 400}}>QuestionPro Surveys</h2>
            <div className="row justify-space-between" style={{marginBottom: 20}}>
              <Button type="primary" onClick={openImportSurveyDialog} icon={<PlusOutlined/>}>Link survey</Button>
              <div className="row" style={{gap: 10}}>
                <div className="outline-input-wrapper relative">
                  <div className="absolute" style={{top: 7, left: 5}}>
                    <SvgSearch color={"#9B9B9B"}/>
                  </div>
                  <DebounceInput onChange={handleSearchChange}
                                 width={200}
                                 value={search}
                                 style={{paddingLeft: 25}}
                                 className={"outline-input"}
                                 placeholder="Search survey"
                                 debounceTimeout={500}/>
                </div>
                {
                  surveys.data && surveys.data.totalCount > 0 &&
                  <Pagination currentPage={page}
                              pageSize={pageSize}
                              totalItems={totalCount}
                              showSizeChanger
                              onChange={handlePaginationChange}/>
                }
              </div>

            </div>
            <div>
              <Table pagination={false}
                     columns={columns}
                     rowKey={"id"}
                     dataSource={surveys.data?.data ?? []}
                     loading={surveys.isLoading}
                     onChange={handleTableChange}/>
            </div>
          </>
      }

      {
        importSurveyDialog.open &&
        <ImportSurveyDialog data={importSurveyDialog} onCancel={closeImportSurveyDialog} onConfirm={onConfirm}/>
      }
    </div>
  );
};

export default Surveys;
