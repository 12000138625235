import * as React from "react";

const SvgRestaurant = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.384 2.308c1.02 1.051.745 3.04-.615 4.442-1.071 1.105-2.496 1.516-3.551 1.125l-.756.779 4.922 5.077L13.154 15 8.23 9.923 3.308 15l-1.23-1.27 6.908-7.124c-.379-1.088.02-2.558 1.091-3.664 1.36-1.402 3.288-1.686 4.307-.634zM6.942 6.077L4.481 8.615 2.02 6.077C.66 4.675.66 2.402 2.02 1l4.922 5.077z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgRestaurant;
