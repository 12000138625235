import * as React from "react";

const SvgDetails = (props: any) => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" {...props}>
      <g id="Nov-9-21" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01-My-page---Details-tab--Copy-5" transform="translate(-1035.000000, -146.000000)" fill="#545E6B">
          <g id="Group-2" transform="translate(1035.000000, 140.000000)">
            <g id="Icon/SideNav/List" transform="translate(0.000000, 6.000000)">
              <path
                d="M6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 Z M6,3.75 C6.41421356,3.75 6.75,3.41421356 6.75,3 C6.75,2.58578644 6.41421356,2.25 6,2.25 C5.58578644,2.25 5.25,2.58578644 5.25,3 C5.25,3.41421356 5.58578644,3.75 6,3.75 Z M5.26828546,5.25 L5.26828546,9.75 L6.76828546,9.75 L6.76828546,5.25 L5.26828546,5.25 Z"
                id="Mask"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgDetails;
