import * as React from "react";

const SvgUpload2 = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="34" height="25" viewBox="0 0 34 25"
         fill='none'>
      <path
        d="M12.8634 21C10.9114 21 7.75819 21 7.1641 21C3.78929 21 1.05347 18.4416 1.05347 15.2857C1.05347 12.5614 3.09219 10.2823 5.82077 9.70995C5.70016 9.20589 5.63644 8.68133 5.63644 8.14286C5.63644 4.19797 9.05622 1 13.2747 1C16.9701 1 20.0525 3.45395 20.7602 6.7143L25.0219 6.71429C29.2404 6.71429 32.6602 9.91225 32.6602 13.8571C32.6602 17.802 29.2404 21 25.0219 21C24.7519 21 22.0728 21 21.3243 21"
        stroke="#545E6B" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M17.0361 10.26C17.1815 10.26 17.3132 10.316 17.4085 10.4065L22.5499 15.2865C22.7556 15.4817 22.7556 15.7983 22.5499 15.9936C22.3442 16.1888 22.0106 16.1888 21.8049 15.9936L17.5628 11.9671V23.4C17.5628 23.6762 17.327 23.9 17.0361 23.9C16.7451 23.9 16.5093 23.6762 16.5093 23.4V11.9671L12.2672 15.9936C12.0615 16.1888 11.7279 16.1888 11.5222 15.9936C11.3165 15.7983 11.3165 15.4817 11.5222 15.2865L16.6633 10.4067C16.7586 10.3161 16.8904 10.26 17.0361 10.26Z"
        fill="#545E6B" stroke="#545E6B" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default SvgUpload2;
