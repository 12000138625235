import { Alert } from 'antd';
import React, { useState } from 'react'
import { DebounceInput } from 'react-debounce-input';
import { useQuery } from 'react-query';
import SvgSearch from '../../../../assets/icons/js/Search';
import { Loader } from '../../loader/loader';
import mediaApi from '../api/media-api';
import Pagination from "../../pagination/pagination";

const UnsplashGallery = (props: any) => {
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [unsplashError, setUnsplashError] = useState<boolean>(false);

  const unsplashParams = {
    per_page: pageSize,
    page: page,
  };

  const unsplashGallery = useQuery(['unsplashGallery', unsplashParams, search], () => mediaApi.getUnsplashPhotos(unsplashParams, search),
    {
      onError: () => {
        setUnsplashError(true);
        return;
      },
      retry: false
    }
  );


  const isSelected = (url: string) => {
    return props.selectedUrl === url
  }

  const handlePaginationChange = (page: any) => {
    setPage(page);
  }

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
    setPage(1);
  }

  return (
    <div className="unsplash">
      <div className='outline-input-wrapper relative'>
        <DebounceInput
          value={search}
          onChange={handleSearchChange}
          className='outline-input'
          placeholder="Search Photos"
          debounceTimeout={600}
        />
        <span className='absolute' style={{top: 7, left: 5}}><SvgSearch/></span>
      </div>
      <div className='images'>
        {
          unsplashGallery.isLoading ? <Loader/> :
            unsplashGallery.data?.results?.map((unsplashImage: any, index: number) => {
              return <div
                onClick={() => props.handleImageClick({
                  url: unsplashImage?.urls?.regular,
                  name: `Unsplash: ${unsplashImage?.description ? unsplashImage.description : "Untitled"}`,
                  username: unsplashImage.user.name,
                  userUrl: unsplashImage.user.links.html
                })}
                key={index}
                className={isSelected(unsplashImage?.urls?.regular) ? "image-container selected-image" : "image-container"}>
                <img className='image' src={unsplashImage?.urls?.small} alt=""/>
                <span className='image-name'>{unsplashImage.description ? unsplashImage.description : "Untitled"}</span>
              </div>
            })
        }
        {
          unsplashError &&
          <div style={{width: '100%'}} className='justify-space-center'>
            <Alert
              message="Error"
              description="Limit Reached with fetching photos, please try again later!"
              type="error"
            />
          </div>
        }
      </div>
      <div style={{marginTop: 30}} className='justify-space-center w-100'>
        <Pagination
          currentPage={page}
          totalItems={unsplashGallery?.data?.total > 9999 ? 9999 : unsplashGallery?.data?.total}
          pageSize={pageSize}
          onChange={handlePaginationChange}/>
      </div>
    </div>
  )
}

export default UnsplashGallery
