import React from 'react';
import './data-settings.scss'
import SvgNugget from "../../../../../assets/icons/js/nugget";
import Switch from "../../../../../_shared/components/switch/switch";
import ProjectEditApi from "../../api/project-edit-api";
import { queryClient } from "../../../../../index";

type DataSettingsProps = {
  projectId: number;
  autoGenerateKeyFindings?: boolean;

}
const DataSettings = ({
                        projectId,
                        autoGenerateKeyFindings
                      }: DataSettingsProps) => {

  const [autoGenerateKeyFindingsState, setAutoGenerateKeyFindingsState] = React.useState<boolean>(!!autoGenerateKeyFindings || false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleSwitchChange = (checked: boolean) => {
    setAutoGenerateKeyFindingsState(checked);
    ProjectEditApi.toggleAutoGenerateKeyFindings(projectId).then(() => {
      queryClient.invalidateQueries(['project', String(projectId)]);
      setLoading(false);
    })
  }

  return (
    <div className="data-settings">
      <div className="label-wrapper">
        <p>Data generation</p>
      </div>
      <div className="row justify-space-between align-items-center" style={{marginTop: 8, padding: "8px 0"}}>
        <div className="row align-items-center" style={{gap: 16}}>
          <SvgNugget width={32} height={32}/>
          <div>
            <h4 style={{marginBottom: 0}}>Auto Key Findings</h4>
            <h5 style={{marginBottom: 0}}>Let AskIH automatically generate key findings from all your project data,
              including QuestionPro surveys
              and
              external files.</h5>
          </div>
        </div>
        <Switch name="autoGenerate" checked={autoGenerateKeyFindingsState} onChange={handleSwitchChange}
                loading={loading}/>
      </div>
    </div>
  );
};

export { DataSettings };
