import React from 'react';
import { Button, Divider, Modal } from 'antd';
import { Loader } from '../../../../../../../_shared/components/loader/loader';
import { IKnowledgeNode } from '../../../../../../knowledge/model/knowledge-node.interface';
import knowledgeApi from '../../../../../../knowledge/api/knowledge-api';
import ReadKnowledgeGraph from "../../../../../../../_shared/components/read-knowledge-graph/read-knowledge-graph";
import "./knowledege-graphs-dialog.scss"

export class KnowledgeGraphsDialog extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      graphs: [],
      selectedNodes: this.props.data.selectedNodes.length ? this.props.data.selectedNodes : [],
      loader: true
    }
    this.addRemoveNode = this.addRemoveNode.bind(this);
    this.isNodeSelected = this.isNodeSelected.bind(this);
  }


  componentDidMount() {
    this.loadPage();
  }


  onConfirm(): void {
    this.props.onConfirm({selectedNodes: this.state.selectedNodes, entityKey: this.props?.data?.entityKey});
  }


  addRemoveNode(node: IKnowledgeNode): void {
    let selectedNodes: IKnowledgeNode[] = [...this.state.selectedNodes];
    const isSelected: boolean = selectedNodes.some((selectedNode: IKnowledgeNode) => selectedNode.id === node.id);

    if (!isSelected) {
      selectedNodes.push(node);
      this.setState({
        ...this.state,
        selectedNodes: selectedNodes
      });
    } else {
      selectedNodes = selectedNodes.filter((selectedNode: IKnowledgeNode) => selectedNode.id !== node.id);
      this.setState({
        ...this.state,
        selectedNodes: selectedNodes
      });
    }
  }

  isNodeSelected(id: number | undefined): boolean {
    if (id) {
      let selectedNodes: IKnowledgeNode[] = [...this.state.selectedNodes];
      return selectedNodes.some((selectedNode: IKnowledgeNode) => selectedNode.id === id);
      return false
    } else {
      return false;
    }
  }

  searchTree(tree: any, nodesProp: string, prop: string | number, value: any): any {
    let i, f = null;
    if (Array.isArray(tree)) {
      for (i = 0; i < tree.length; i++) {
        f = this.searchTree(tree[i], nodesProp, prop, value);
        if (f) {
          return f;
        }
      }
    } else if (typeof tree === 'object') {
      if (tree[prop] !== undefined && tree[prop] === value) {
        return tree;
      }
    }
    if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
      return this.searchTree(tree[nodesProp], nodesProp, prop, value);
    } else {
      return null;
    }
  }

  openNodeTree(nodeId: any): void {
    const id = parseInt(nodeId);
    const graphs: IKnowledgeNode[] = this.state.graphs;
    if (graphs) {
      graphs.forEach((graph: IKnowledgeNode) => {
        if (graph.nodes.length) {
          const node = this.searchTree(graph.nodes, 'nodes', 'id', id);
          if (node?.parentId) {
            const r = this.searchTree(graph.nodes, 'nodes', 'id', node.parentId);
            r['open'] = true;
            this.openNodeTree(r.id);
          } else {
            this.setState({
              ...this.state,
              graphs: [...this.state.graphs]
            });
          }
        }
      });
    }
  }

  render() {
    const {graphs, selectedNodes, loader} = this.state;

    return (
      <Modal
        title={"Knowledge Graphs"}
        open={this.props.data.visible}
        onCancel={() => (this.props.onCancel())}
        //bodyStyle={{}}
        centered
        width={'80%'}
        footer={[
          <Button key="submit" type="primary" onClick={() => this.onConfirm()}>
            Apply
          </Button>,
        ]}>

        <div className="knowledge-graphs-dialog">
          <div className='knowledge-graphs-wrapper'>
            {loader && <Loader/>}
            {
              graphs.length ? graphs.map((graph: IKnowledgeNode, i: number) => {
                return <ReadKnowledgeGraph
                  graph={graph}
                  key={i}
                  graphId={graph.id}
                  addRemoveNode={this.addRemoveNode}
                  selectedNodes={selectedNodes}
                  isNodeSelected={this.isNodeSelected}
                />
              }) : <></>
            }
          </div>
        </div>
        <Divider style={{marginBottom: -20}}/>
      </Modal>
    );
  }

  private loadPage(): void {
    knowledgeApi.getAll()
      .then((response: IKnowledgeNode[]) => {
        this.setState({
          ...this.state,
          graphs: response,
          loader: false
        }, () => {
          this.state.selectedNodes.forEach((node: IKnowledgeNode) => {
            this.openNodeTree(node.id);
          });
        });
      });
  }
}
