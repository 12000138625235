import * as React from "react";

const SvgArrowBack = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"
            fill={props.color ? props.color : '#545E6B'}/>
    </svg>

  );
}

export default SvgArrowBack;
