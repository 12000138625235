import { environment } from '../../../environment';
import { IPublishedForms } from '../models/published-forms.interface';
import { IProject } from '../../projects/models/project.interface';
import { ISurvey } from '../../../_shared/model/survey.interface';
import { IFormTypes } from '../pages/search-results/models/forms-types.interface';
import { IMetaTag } from '../../settings/settings-tabs/taxonomy/meta-tags/model/meta-tag.interface';
import { axiosClient } from '../../../axios-client';
import { AxiosResponse } from 'axios';
import { IOrganizationSettings } from '../models/organization.settings.interface';
import { ISearchProject } from '../pages/search-results/models/search-project';
import { ISearchData } from '../models/search-data.interface';
import { ISearchNuggetData } from "../pages/search-results/models/search-nugget";
import { ISearchNotepadData } from "../pages/search-results/models/search-notepad";
import { ISearchFileData } from "../pages/search-results/models/search-file.interface";
import { IOrganizationFolders } from "../models/organization-folders.interface";
import { bool } from "yup";
import { ISearchUsersData } from "../pages/search-results/models/search-users.interface";
import { ISearchStoriesData } from "../pages/search-results/models/search-stories.interface";
import { SyncStatus } from "../models/sync-status.type";
import { IPagination } from "../../../core/model/pagination.interface";

class HomeApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getPublishedForms(): Promise<IPublishedForms[]> {
    try {
      const response: any = await axiosClient.get<IPublishedForms[]>(
        `${this.baseUrl}/forms/published`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getOrganisationSettings(): Promise<IOrganizationSettings> {
    const response: any = await axiosClient.get<IPublishedForms>(
      `/organizations/settings`
    );
    return response.data;
  }

  async getProjects(
    queryParams: ISearchData
  ): Promise<{ total: number; projects: ISearchProject[] }> {
    try {
      const response: AxiosResponse<{
        total: number;
        projects: ISearchProject[];
      }> = await axiosClient.get<{ total: number; projects: ISearchProject[] }>(
        `${this.baseUrl}/projects`,
        {
          params: {
            ...queryParams,
            tags: queryParams.tags?.length
              ? queryParams?.tags.toString()
              : null,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getNuggets(queryParams: ISearchData): Promise<ISearchNuggetData> {
    try {
      const response: AxiosResponse<ISearchNuggetData> =
        await axiosClient.get<ISearchNuggetData>(
          `${this.baseUrl}/projects/nuggets/search`,
          {
            params: {
              search: queryParams?.search,
              searchType: queryParams?.searchType,
            },
          }
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async getNotepads(queryParams: ISearchData): Promise<ISearchNotepadData> {
  //   try {
  //     const response: AxiosResponse<ISearchNotepadData> = await axiosClient.get(
  //       `${this.baseUrl}/interviews/search`,
  //       {
  //         params: {
  //           search: queryParams?.search,
  //           searchType: queryParams?.searchType,
  //         },
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  //
  // async getSearchFiles(queryParams: ISearchData): Promise<ISearchFileData> {
  //   try {
  //     const response: AxiosResponse<ISearchFileData> = await axiosClient.get<ISearchFileData>(
  //       `${this.baseUrl}/project-files/search`,
  //       {
  //         params: {
  //           search: queryParams?.search,
  //           searchType: queryParams?.searchType,
  //         },
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }


  async getSearchUsers(queryParams: ISearchData): Promise<ISearchUsersData> {
    try {
      const response: AxiosResponse<ISearchUsersData> = await axiosClient.get<ISearchUsersData>(
        `${this.baseUrl}/projects/search-users`,
        {
          params: {
            search: queryParams?.search,
            searchType: queryParams?.searchType,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSearchStories(queryParams: ISearchData): Promise<ISearchStoriesData> {
    try {
      const response: AxiosResponse<ISearchStoriesData> = await axiosClient.get<ISearchStoriesData>(
        `${this.baseUrl}/story/search`,
        {
          params: {
            search: queryParams?.search,
            searchType: queryParams?.searchType,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async checkForMisspell(word: string | undefined): Promise<{ misspelled: boolean; suggestions: string[] }> {
    try {
      const response: AxiosResponse<{ misspelled: boolean; suggestions: string[] }> = await axiosClient.get<{
        misspelled: boolean;
        suggestions: string[]
      }>(
        `${this.baseUrl}/projects/check-spelling`,
        {params: {term: word ? word : ""}}
      );
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getProject(id: number): Promise<IProject> {
    try {
      const response: AxiosResponse<IProject> = await axiosClient.get<IProject>(
        `${this.baseUrl}/projects/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async getProjectsSurvey(id: number): Promise<ISurvey> {
  //   try {
  //     const response: AxiosResponse<ISurvey> = await axiosClient.get<ISurvey>(
  //       `${this.baseUrl}/survey/project/${id}`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  //
  // async getFormsTypes(): Promise<IFormTypes[]> {
  //   const response: any = await axiosClient.get<IPublishedForms>(
  //     `/forms/types`
  //   );
  //   return response.data;
  // }

  async getMetaTags(): Promise<{ content: IMetaTag[], pagination: IPagination }> {
    const response: any = await axiosClient.get<IPublishedForms>(`/metatags`);
    return response.data;
  }

  /// knowledgeGraph
  async getKnowledgeGraphs(): Promise<any> {
    const response: any = await axiosClient.get<any>(`/graph/nodes`);
    return response.data;
  }

  async getNodeById(id: string): Promise<any> {
    try {
      const response: any = await axiosClient.get<any>(
        `${this.baseUrl}/graph/details/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async getFileDownload(id: string): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.get<any>(
  //       `${this.baseUrl}/projects/download/${id}`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getNotifications(): Promise<any> {
    const response: any = await axiosClient.get<any>("/notifications");
    return response.data;
  }

  async askAI({question, specificSurvey, surveyName, surveyID, fileIDs, projectID}: {
    question: string,
    specificSurvey?: boolean,
    surveyName?: string,
    surveyID?: number,
    projectID?: number,
    fileIDs?: number[]
  }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(
        `${this.baseUrl}/langchain`, {
          params: {
            question,
            specificSurvey,
            ...(surveyName && {surveyName}),
            ...(surveyID && {surveyID}),
            ...(projectID && {projectID}),
            ...(fileIDs && {fileIDs: fileIDs.join(',')}),
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async syncPineconeDocs(): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.post(`${this.baseUrl}/projects/sync-pinecone-docs`);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  //
  // async isPineconeOrganizationSynced(): Promise<boolean> {
  //   try {
  //     const response: AxiosResponse<boolean> = await axiosClient.get(`${this.baseUrl}/pinecone/is-organization-synced`);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }


  async langChainHistory({
                           surveyID,
                           projectID
                         }: {
    surveyID?: number,
    projectID?: number
  }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrl}/langchain/history`, {
        params: {
          surveyID,
          projectID
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteLangChainHistory(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(`${this.baseUrl}/langchain/history`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async getOrganizationFolders(params: { page: number, pageSize: number }): Promise<IOrganizationFolders> {
  //   try {
  //     const response: AxiosResponse<IOrganizationFolders> = await axiosClient.get<IOrganizationFolders>(`${this.baseUrl}/folders/qp-organization-folders`, {
  //       params
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async autoSyncOrganization(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post(`${this.baseUrl}/future-project-synced-orgs`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async stopFolderCounter(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post(`${this.baseUrl}/folder-counter/stop-org-counter-jobs`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async checkForSyncStatus(): Promise<SyncStatus> {
    try {
      const response: AxiosResponse<SyncStatus> = await axiosClient.get<SyncStatus>(`${this.baseUrl}/jobs/sync/check-status`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async likeDislikeResponse(id: number, reaction: "LIKED" | "DISLIKED" | null): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(`${this.baseUrl}/langchain/chat-reaction/${id}`, {
        reaction
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getTrendingPrompts(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrl}/langchain/chat-ai-suggestions`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async searchSurveys(queryParams: ISearchData): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrl}/organization-surveys/search`, {
        params: {
          search: queryParams?.search,
          searchType: queryParams?.searchType,
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSearchFiles(queryParams: ISearchData): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrl}/project-files/search`, {
        params: {
          search: queryParams?.search,
          searchType: queryParams?.searchType,
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async searchKnowledgeGraphs(queryParams: ISearchData): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrl}/graph/knowledge/search`, {
        params: {
          search: queryParams?.search,
          searchType: queryParams?.searchType,
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async searchSnapshots(queryParams: ISearchData): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`${this.baseUrl}/snapshots/search`, {
        params: {
          search: queryParams?.search,
          searchType: queryParams?.searchType,
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new HomeApi();
