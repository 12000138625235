import * as React from "react";

const SvgUnderScore = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-995.000000, -208.000000)">
          <g id="Underscore" transform="translate(995.000000, 208.000000)">
            <rect id="Rectangle-Copy-9" x="0" y="0" width="16" height="16"/>
            <path
              d="M13,14 L13,16 L3,16 L3,14 L13,14 Z M5.31617647,0 L5.31617647,7.91869919 L5.32179752,8.12987973 C5.35927119,8.81836099 5.58411319,9.35230352 5.99632353,9.73170732 C6.4497549,10.1490515 7.11764706,10.3577236 8,10.3577236 C9.78921569,10.3577236 10.6838235,9.52303523 10.6838235,7.85365854 L10.6838235,7.85365854 L10.6838235,0 L13,0 L13,7.91056911 L12.9939338,8.15857272 C12.9373162,9.29965071 12.484375,10.2129178 11.6351103,10.898374 C10.7251838,11.6327913 9.51348039,12 8,12 C6.46813725,12 5.25183824,11.6382114 4.35110294,10.9146341 C3.45036765,10.1910569 3,9.18699187 3,7.90243902 L3,7.90243902 L3,0 L5.31617647,0 Z"
              id="Combined-Shape" fill="#545E6B" fillRule="nonzero"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgUnderScore;
