import * as React from "react";

const Svg401 = (props: any) => {
  return (
    <svg width={384} height={256} viewBox="0 0 384 256" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H384V256H0z" transform="translate(-851 -302) translate(851 302)"/>
        <g>
          <path fill="#B4C0CD" fillRule="nonzero"
                d="M254.503 141.694h-5.433v6.997h-7.53v-6.997h-17.967v-5.188L239.3 115.37h8.102l-14.584 20.04h8.96v-6.236h7.292v6.236h5.433v6.283zm16.585 7.568c-2.764 0-5.227-.682-7.387-2.046-2.16-1.365-3.853-3.34-5.076-5.927-1.223-2.586-1.835-5.672-1.835-9.258 0-3.586.612-6.672 1.835-9.258 1.223-2.586 2.915-4.562 5.076-5.926 2.16-1.365 4.623-2.047 7.387-2.047 2.764 0 5.226.682 7.387 2.047 2.16 1.364 3.852 3.34 5.075 5.926 1.224 2.586 1.835 5.672 1.835 9.258 0 3.586-.611 6.672-1.835 9.258-1.223 2.587-2.915 4.562-5.075 5.927-2.16 1.364-4.623 2.046-7.387 2.046zm0-6.52c2.033 0 3.63-.873 4.79-2.619 1.16-1.745 1.739-4.442 1.739-8.092 0-3.65-.58-6.346-1.74-8.092-1.16-1.745-2.756-2.618-4.79-2.618-2 0-3.582.873-4.741 2.618-1.16 1.746-1.74 4.443-1.74 8.092 0 3.65.58 6.347 1.74 8.092 1.16 1.746 2.74 2.618 4.742 2.618zm30.93-27.37v33.32h-7.72v-27.133h-6.673v-6.188h14.393z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#8F96A3" strokeWidth="1.4"
                d="M208.414 209.117L327.964 209.117 327.964 89.712 208.414 89.712z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#B4C0CD" d="M208.414 101.628L327.964 101.628 327.964 89.712 208.414 89.712z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#8F96A3" strokeWidth="1.4"
                d="M208.414 101.628L327.964 101.628 327.964 89.712 208.414 89.712z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#D1D8E0"
                d="M223.344 167.33L301.616 167.33 301.616 158.152 223.344 158.152zM223.344 181.502L313.034 181.502 313.034 172.322 223.344 172.322zM223.344 195.673L290.608 195.673 290.608 186.494 223.344 186.494zM214.937 93.43c1.238 0 2.243 1.004 2.243 2.24 0 1.236-1.005 2.24-2.243 2.24-1.237 0-2.242-1.004-2.242-2.24 0-1.236 1.005-2.24 2.242-2.24M221.682 93.43c1.237 0 2.242 1.004 2.242 2.24 0 1.236-1.005 2.24-2.242 2.24-1.238 0-2.242-1.004-2.242-2.24 0-1.236 1.004-2.24 2.242-2.24M228.426 93.43c1.237 0 2.242 1.004 2.242 2.24 0 1.236-1.005 2.24-2.242 2.24-1.238 0-2.243-1.004-2.243-2.24 0-1.236 1.005-2.24 2.243-2.24"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#55A0F6"
                d="M12.674 55.879c2.139 4.394 8.151.762 5.164-4.04-2.988-4.801-9.755-5.762-14.505-2.81-4.75 2.952 5.748-.53 9.34 6.85z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M12.674 55.879c2.139 4.394 8.151.762 5.164-4.04-2.988-4.801-9.755-5.762-14.505-2.81-4.75 2.952 5.748-.53 9.34 6.85z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#55A0F6"
                d="M55.205 4.274c3.408 2.996 9.751 8.342 4.947 14.643-3.43 4.499-8.024 2.07-7.652-2.786.37-4.856 4.65-1.544 2.705-11.857z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M55.205 4.274c3.408 2.996 9.751 8.342 4.947 14.643-3.43 4.499-8.024 2.07-7.652-2.786.37-4.856 4.65-1.544 2.705-11.857z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#55A0F6"
                d="M4.184 27.288c-3.313-2.012-6.282-5.352-2.205-13.27 1.504 3.276 2.743 6.566 6.779 7.475 4.035.908-.26 8.417-4.574 5.795z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M4.184 27.288c-3.313-2.012-6.282-5.352-2.205-13.27 1.504 3.276 2.743 6.566 6.779 7.475 4.035.908-.26 8.417-4.574 5.795z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#55A0F6"
                d="M59.282 78.675c-8.605-7.47-22-1.653-31.685-12.773-8.353-9.593.985-32.62-19.392-24.603 3.967-11.782 14.468-8.006 14.468-8.006C6.82 17.817 33.254 9.437 30.351 0c10.414 1.02 6.012 19.906 6.012 19.906 4.75-8.105 10.11-8.29 10.11-8.29-3.718 13.195 8.538 13.356 6.802 29.683 7.967-.266 5.36-15.121 5.36-15.121 15.48 18.506-7.742 34.56-.131 43.486l-.037.042 11.127 8.457c4.397.282 5.493 2.642 5.951 5.062l-4.788 2.668-11.475-7.218z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M59.282 78.675c-8.605-7.47-22-1.653-31.685-12.773-8.353-9.593.985-32.62-19.392-24.603 3.967-11.782 14.468-8.006 14.468-8.006C6.82 17.817 33.254 9.437 30.351 0c10.414 1.02 6.012 19.906 6.012 19.906 4.75-8.105 10.11-8.29 10.11-8.29-3.718 13.195 8.538 13.356 6.802 29.683 7.967-.266 5.36-15.121 5.36-15.121 15.48 18.506-7.742 34.56-.131 43.486l-.037.042 11.127 8.457c4.397.282 5.493 2.642 5.951 5.062l-4.788 2.668-11.475-7.218z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#D6E3F5"
                d="M100.574 89.082v.047c5.652 8.025 2.712 16.745 2.397 25.83-.534 15.366 9.602 20.313 18.934 15.984 15.749-7.303 22.662-46.867 0-75.182 38.02-11.065 89.362.606 98.287 66.19-7.701-23.832-19.086-19.805-19.855-11.91-4.244-13.924-20.364-12.209-19.08-3.5-24.412-7.39-23.127 18.21-23.127 18.21 11.117-.362 20.084-2.606 28.34-3.826 15.433-2.28 28.385-.983 48.266 22.856 17.49 20.972 41.305 36.654 48.333 26.926.324-.501.629-1.042.913-1.623 1.332-3.076 1.54-7.808.26-14.511 1.505 7.879 1.025 12.348-.212 15.386-.565 1.388-1.288 2.477-2.052 3.456-.468.6-.965 1.107-1.472 1.537-7.744 7.185-26.678 10.658-41.921-3.406-16.312-15.05-29.803-17.897-42.153-12.328 1.622 5.342 4.666 10.8 10.381 12.39v37.509h-4.043l-8.186-23.735c-12.304 5.016-25.028-10.34-19.771-24.095 0 0-10.835-4.338-21.017 3.321-6.078 4.573-7.044 9.31-20.226 9.817-.75 9.212-2.356 23.372-5.667 34.692h-6.936s-3.783-19.689-5.678-27.886c-.632-2.802-2.656-5.085-5.363-6.051-2.725-1.168-6.704-3.1-11.917-6.213-4.054-2.419-7.358-5.345-9.98-8.58-15.758 2.727-34.557-3.701-34.557-3.701v-7.441s18.268 3.622 27.548-2.955c-2.003-7.992-1.271-16.222 1.52-22.745 2.231-5.213 3.315-10.795 3.14-15.77-.307-8.802-4.553-15.708-13.346-15.327l-13.166-9.923-.7-4.362 12.289 7.73 4.788-2.668c-.458-2.42-1.554-4.78-5.95-5.062l-11.128-8.457c5.621-7.566 13.166-2.625 13.166-2.625l6.352 5.271c5.743-.575 9.335-.392 12.608 1.533 3.036 1.786 5.14 4.766 9.003 7.854 4.468 3.572 9.8 4.154 9.8 4.154s-4.873 5.206-8.822 3.189"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M140.905 73.943c26.743-3.809 52.832 9.09 59.432 36.098m-19.08-3.5c-10.504-20.07-36.73-16.886-36.73-16.886"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M202.77 209.117H191.7l-6.313-4.43-4.954 4.43M92.695 83.225s4.853 3.367 6.9 5.184c4.07 3.607 9.8-2.516 9.8-2.516s-5.33-.582-9.8-4.154c-3.862-3.088-5.966-6.068-9.002-7.854-3.273-1.925-6.865-2.108-12.608-1.533l-6.352-5.271s-7.545-4.941-13.166 2.625l11.127 8.457c4.397.282 5.493 2.642 5.951 5.062l-4.788 2.668-12.29-7.73.701 4.362 13.166 9.923c13.762-.597 16.385 16.658 10.206 31.097-5.815 13.591-2.69 34.582 15.469 45.422 5.158 3.08 9.108 5.005 11.83 6.176 2.755.988 4.796 3.34 5.384 6.204 1.87 8.886 5.744 27.77 5.744 27.77h6.936c7.813-26.71 6.13-55.963 6.13-55.963m-13.066 55.963h-7.918l-5.58-5.195-7.485 5.195m33.586-34.692c13.182-.507 14.148-5.244 20.226-9.817 10.182-7.659 21.017-3.321 21.017-3.321-5.257 13.756 7.467 29.11 19.77 24.095l8.187 23.735h4.043v-37.508c-12.163-3.385-12.23-24.296-12.23-24.296m25.61-25.362c-23.524-60.266-54.828-65.889-86.352-62.214 0 0 17.68 23.277 8.648 59.292m-41.915-29.9c5.652 8.025 2.712 16.745 2.397 25.83-.534 15.366 9.602 20.313 18.934 15.984 15.749-7.303 22.662-46.867 0-75.182 38.02-11.065 89.362.606 98.287 66.19-7.701-23.832-19.086-19.805-19.855-11.91-4.244-13.924-20.364-12.209-19.08-3.5-24.412-7.39-23.127 18.21-23.127 18.21 11.117-.362 20.084-2.606 28.34-3.826 15.433-2.28 28.385-.983 48.266 22.856 22.731 27.258 56.147 45.578 49.507 10.792m-87.81 4.645c12.349-5.569 25.84-2.721 42.152 12.328 19.054 17.58 43.895 7.716 45.415-2.504m-129.698-4.804c-3.504 15.445 5.44 29.395 20.18 27.148v17.923H169.1l-5.292-3.674-4.462 3.674m-71.318-48.923c-15.757 2.728-34.556-3.7-34.556-3.7v-7.441s18.268 3.622 27.548-2.955m-31.445 14.674l3.897-4.278v-12.548l6.942-5.61-6.942-5.177"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M121.905 130.943c11.961-20.624 4.766-52.115-11.398-61.266 0 0 3.07-5.667 11.398-7.124"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeWidth="1.4" d="M283.142 148.891L281.324 142.246"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#55A0F6"
                d="M284.214 148.692c1.644 0 2.978 1.333 2.978 2.974 0 1.642-1.334 2.975-2.978 2.975-1.643 0-2.978-1.333-2.978-2.975 0-1.641 1.335-2.974 2.978-2.974z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"
                d="M284.214 148.692c1.644 0 2.978 1.333 2.978 2.974 0 1.642-1.334 2.975-2.978 2.975-1.643 0-2.978-1.333-2.978-2.975 0-1.641 1.335-2.974 2.978-2.974z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path fill="#55A0F6"
                d="M284.516 120.394c4.777.068 9.127 4.693 8.305 9.467-.468 2.713-2.758 4.962-5.441 5.704l-4.663 1.248 1.54 5.74-5.416 1.449-2.99-11.149c3.358-.899 6.776-1.598 10.074-2.698.11-.04.219-.086.324-.137.078-.038.153-.078.227-.122.967-.572 1.01-2.22.116-3.14-.65-.67-2.117-.762-2.117-.762l-9.926 2.656-1.45-5.41 10.46-2.799c.21-.02.366-.05.957-.047z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
          <path stroke="#1F51AD" strokeLinejoin="round" strokeWidth="1.4"
                d="M284.516 120.394c4.777.068 9.127 4.693 8.305 9.467-.468 2.713-2.758 4.962-5.441 5.704l-4.663 1.248 1.54 5.74-5.416 1.449-2.99-11.149c3.358-.899 6.776-1.598 10.074-2.698.11-.04.219-.086.324-.137.078-.038.153-.078.227-.122.967-.572 1.01-2.22.116-3.14-.65-.67-2.117-.762-2.117-.762l-9.926 2.656-1.45-5.41 10.46-2.799c.21-.02.366-.05.957-.047z"
                transform="translate(-851 -302) translate(851 302) translate(28 22)"/>
        </g>
      </g>
    </svg>
  );
}

export default Svg401;
