import React, { ReactElement, useState } from 'react';
import './workflow-dialog.scss';
import { IWorkflow, IWorkflowStatus } from '../model/workflow.interface';
import arrayMove from 'array-move';
import { ReassignWorkflow } from '../reassign-workflow/reassign-workflow';
import { Divider, Modal, notification } from 'antd';
import { WorkflowStageEnum } from '../model/workflow-stage.enum';
import WorkflowsApi from '../api/workflows-api';
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import DraggableStatus from "../draggable-status/draggable-status";
import * as yup from "yup"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

export function WorkflowDialog(props: any): ReactElement {
  const initialStatuses: IWorkflowStatus[] = [
    {
      id: 0,
      name: "Queued",
      color: "#88CCEE",
      stage: WorkflowStageEnum.QUEUED,
      order: 0,
      lock: true
    },
    {
      id: 1,
      name: "Active",
      color: "#0077BB",
      stage: WorkflowStageEnum.ACTIVE,
      order: 0,
      lock: true
    },
    {
      id: 2,
      name: "Closed",
      color: "#332288",
      stage: WorkflowStageEnum.CLOSED,
      order: 0,
      lock: true
    },
  ]

  const schema = yup.object().shape({
    name: yup.string().required('Name is required!'),
    statuses: yup.array().of(yup.object({
      color: yup.string().required('Color is required!'),
      name: yup.string().required('Name is required!')
    }))
  })

  const formMethods = useForm({
    defaultValues: {
      name: props?.data?.name ?? '',
      statuses: props?.data?.statuses ? props.data.statuses : initialStatuses
    },
    resolver: yupResolver(schema)
  });

  const {fields, append, remove} = useFieldArray({
    control: formMethods.control,
    name: 'statuses',
    keyName: 'key'
  });

  const statuses: IWorkflowStatus[] = useWatch({name: 'statuses', control: formMethods.control})
  const [workFlowIsSaving, setWorkFlowIsSaving] = useState<boolean>(false);
  const [reassignWorkFlow, setReassignWorkFlow] = useState<any>({visible: false});


  const appendStatus = (stage: WorkflowStageEnum) => {
    if (statuses.length <= 7) {
      const newStatus: IWorkflowStatus = {
        id: new Date().getTime(),
        name: '',
        color: '',
        lock: false,
        stage
      };

      append(newStatus)
    }
  }

  const removeStatus = (index: number) => {
    remove(index)
  }

  function save(payload: IWorkflow): void {
    if (!props?.data?.id) {
      create(payload);
      return;
    }

    const workFlow: IWorkflow = {
      id: props.data.id,
      ...payload
    }

    WorkflowsApi.checkForChanges(props.data.id, workFlow)
      .then((response: any[]) => {
        if (!response?.length) {
          update(workFlow);
          return;
        }

        setReassignWorkFlow({
          id: props.data.id,
          visible: true,
          reassign: response,
          statuses: statuses
        })
      });
  }

  function update(payload: IWorkflow): void {
    setWorkFlowIsSaving(true);

    const workflow: IWorkflow = {
      id: props.data.id,
      ...payload
    }

    WorkflowsApi.update(workflow)
      .then(() => {
        setWorkFlowIsSaving(false);
        notification.open({
          message: "Done",
          description: `Your workflow has been updated successfully.`,
          type: "success",
          className: "infobox-success",
          duration: 3,
          placement: "bottomRight",
        });
        props.onCancel();
      });
  }

  function create(payload: IWorkflow): void {
    WorkflowsApi.create(payload)
      .then(() => {
        notification.open({
          message: "Done",
          description: `Your workflow has been created successfully.`,
          type: "success",
          className: "infobox-success",
          duration: 3,
          placement: "bottomRight",
        });
        props.onCancel();
      });
  }

  const onCancel = () => {
    setReassignWorkFlow({visible: false});
  }

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result;

    if (!destination || source.droppableId !== destination.droppableId) {
      return;
    }

    const destinationItem = statuses[destination.index];

    if (destinationItem?.lock) {
      return;
    }

    const arrayMoved = arrayMove(statuses, source.index, destination.index);
    formMethods.setValue('statuses', arrayMoved);
  };

  return (
    <Modal
      open={props.visible}
      onCancel={props.onCancel}
      onOk={formMethods.handleSubmit(save)}
      okText="Save"
      cancelButtonProps={{type: "link"}}
      okButtonProps={{loading: workFlowIsSaving}}
      width={660}
      title="New workflow">
      <div className="workflow">
        <div className="workflow-table">
          <FormProvider {...formMethods}>
            <div className="workflow-table-body">
              <div className="workflow-table-header">
                <div className="outline-input-wrapper">
                  <h4>Name</h4>
                  <input
                    className="outline-input"
                    {...formMethods.register('name')}
                    placeholder="Workflow Name"
                  />
                  <ErrorMessage errors={formMethods.formState.errors} name={'name'} render={({message}) => <small
                    className="form-error">{message}</small>}/>
                </div>
                <div className="d-flex header-row">
                  <div className="status-label">
                    <h4>Status</h4>
                  </div>
                </div>
              </div>

              <DragDropContext onDragEnd={onDragEnd}>
                {Object.values(WorkflowStageEnum).map((stage: WorkflowStageEnum) => (
                  <Droppable droppableId={stage} key={stage}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="workflow-sortable-divider">
                        <div className="sortable-label">{stage}</div>
                        <Divider className="divider"/>
                        {fields
                          .filter((status: any) => status.stage === stage)
                          .map((status: any, index: number) => (
                            <DraggableStatus
                              key={status.key}
                              index={fields.indexOf(status)}
                              appendStatus={appendStatus}
                              removeStatus={removeStatus}
                            />
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </div>
          </FormProvider>
        </div>

        {reassignWorkFlow.visible && (
          <ReassignWorkflow
            data={reassignWorkFlow}
            onConfirm={onCancel}
            onCancel={onCancel}
          />
        )}
      </div>
    </Modal>
  );
}
