import React from 'react';

const SvgThemeOverlayFill = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8688 10.4698L16.4895 12.8545C17.0182 13.6325 16.9195 14.6765 16.2544 15.3416C15.4891 16.1069 14.2484 16.1069 13.4831 15.3416C12.818 14.6765 12.7193 13.6325 13.2481 12.8545L14.8688 10.4698Z"
        fill="#545E6B"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M5.26019 0.212085C5.54297 -0.0706951 6.00145 -0.0706951 6.28423 0.212085L8.30292 2.23077C8.31313 2.2399 8.32315 2.24937 8.33296 2.25918L14.4772 8.40341C14.76 8.68619 14.76 9.14467 14.4772 9.42745L8.84498 15.0597C7.71386 16.1908 5.87995 16.1908 4.74883 15.0597L1.67671 11.9875C0.545588 10.8564 0.545589 9.02252 1.67671 7.89139L6.28407 3.28404C6.56685 3.00126 6.56685 2.54278 6.28407 2.26L5.26019 1.23612C4.97741 0.953344 4.97741 0.494866 5.26019 0.212085ZM8.33231 4.30824L7.82013 3.79606L3.09732 8.51887L12.8347 8.80903L8.36235 4.33665C8.35214 4.32752 8.34212 4.31805 8.33231 4.30824Z"
            fill="#545E6B"/>
    </svg>
  );
};

export default SvgThemeOverlayFill;
