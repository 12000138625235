import * as React from "react";

const SvgFiletypeDefault = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 1024 1024" {...props}>
      <g
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero">
        <path
          d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494z"
          transform="translate(-499 -1908) translate(451 1832) translate(32 64) translate(16 8) translate(0 4)"
        />
      </g>
    </svg>
  );
}

export default SvgFiletypeDefault;
