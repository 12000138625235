import React from 'react';

const SvgLighting = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.10019 9.50963L3.63191 9.98973C3.25772 10.0625 2.93014 9.73708 3.01293 9.37479L4.86989 1.24789C4.91756 1.03926 5.09311 0.881162 5.31065 0.850938L11.3964 0.00541226C11.8073 -0.0516765 12.1222 0.353429 11.9536 0.722316L9.36619 6.38415L11.4182 6.00796C11.8486 5.92905 12.1569 6.45351 11.9149 6.85286L5.91419 15.7537C5.61403 16.2489 4.91229 15.9371 5.00909 15.3515L6.10019 9.50963Z"
        fill="#545E6B"/>
    </svg>
  );
};

export default SvgLighting;
