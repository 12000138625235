import React from 'react';

const SvgRobot = (props: any) => {
  return (
    <svg data-testid={"svg-robot"} width="16" height="16" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...props} >
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9 5.8293C10.1652 5.41746 11 4.30622 11 3C11 1.34315 9.65685 0 8 0C6.34315 0 5 1.34315 5 3C5 4.30622 5.83481 5.41746 7 5.8293V7H2C0.895431 7 0 7.80589 0 8.8V14.2C0 15.1941 0.895431 16 2 16H14C15.1046 16 16 15.1941 16 14.2V8.8C16 7.80589 15.1046 7 14 7H9V5.8293ZM13 11.25C13 12.2165 12.2165 13 11.25 13C10.2835 13 9.5 12.2165 9.5 11.25C9.5 10.2835 10.2835 9.5 11.25 9.5C12.2165 9.5 13 10.2835 13 11.25ZM4.75 13C5.7165 13 6.5 12.2165 6.5 11.25C6.5 10.2835 5.7165 9.5 4.75 9.5C3.7835 9.5 3 10.2835 3 11.25C3 12.2165 3.7835 13 4.75 13Z"
            fill={props?.color ? props.color : "#F5F5F5"}/>
    </svg>

  );
};

export default SvgRobot;
