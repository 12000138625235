import * as React from 'react';

const SvgFlagAnalysis = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2a5 5 0 0 1 4.206 7.705l2.587 2.588a1 1 0 0 1 0 1.414l-.086.086a1 1 0 0 1-1.414 0l-2.588-2.587A4.977 4.977 0 0 1 9 12v2H0V2h9zm-2.816 9.132.043.029a5.014 5.014 0 0 1-.226-.16l.183.131zm-1.183-1.13L2 10v1l4 .001.02.014a5.03 5.03 0 0 1-1.053-1.06l.034.046zM9 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 7H2v1l2.1.001.005.025A5.021 5.021 0 0 1 4 7zm.027-.526.014-.117-.005.043-.009.074zM4.3 5.292c.036-.098.075-.196.117-.292l-.103.254-.014.038zm.737-1.34L5 4H2v1h2.416l-.014.031c.167-.387.38-.75.634-1.08zm1.465-1.283-.088.052a5 5 0 0 0 .088-.052zm2.256-.663L9 2c-.107 0-.213.003-.319.01l.076-.004z"
        fill={props.color ? props.color : "#9B9B9B"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlagAnalysis;
