import React, { useEffect, useState } from 'react';
import './survey-details.scss'
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import SvgArrowBack from "../../../assets/icons/js/ArrowBack";
import { Button, Empty } from "antd";
import SvgEye from "../../../assets/icons/js/eye";
import SvgResponses from "../../../assets/icons/js/responses";
import SvgCompleted from "../../../assets/icons/js/Completed";
import SvgClock from "../../../assets/icons/js/Clock";
import LinkProjectModal from "../../../_shared/components/link-project-modal/link-project-modal";
import { IProject } from "../../projects/models/project.interface";
import SvgSurvey from "../../../assets/icons/js/Survey";
import SvgEdit from "../../../assets/icons/js/Edit";
import { ISurveyDetails } from "../model/survey.interface";
import surveysApi from "../api/surveys-api";
import { Loader } from "../../../_shared/components/loader/loader";
import DaysLeftDatePipe from "../../../_shared/helpers/days-left-date-pipe";
import DonutChart from "./components/donut-chart/donut-chart";
import OperationsApi from "../../projects/project-edit/tabs/operations/api/operations-api";
import { AxiosResponse } from "axios";
import SvgExternalLink from "../../../assets/icons/js/ExternalLink";
import HighchartsReact from 'highcharts-react-official';
// @ts-ignore
import * as Highcharts from 'highcharts';
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import AnalyzeChat from "./components/analyze-chat/analyze-chat";

highchartsMore(Highcharts);
solidGauge(Highcharts);

const SurveyDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location?.state;
  const urlParam: any = qs.parse(location.search);
  const id = urlParam.id;
  const surveyId = urlParam.surveyId
  const [linkProjectModal, setLinkProjectModal] = useState<{
    visible: boolean,
    project: IProject | undefined
  }>({visible: false, project: undefined});
  const [survey, setSurvey] = useState<ISurveyDetails>();
  const [graphs, setGraphs] = useState<{ highChartJSON: string | undefined, title: string }[]>()
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    loadPage()
  }, []);

  const loadPage = () => {
    Promise.all([id ? surveysApi.getSurveyDetails(id) : surveysApi.getSurveyDetailsFromSurveyId(surveyId), surveysApi.getGraphs(surveyId)]).then(responses => {
      setSurvey(responses[0]);
      setGraphs(responses[1]);
      setLoader(false)
    })
  }

  const openLinkProjectModal = (project?: IProject) => {
    setLinkProjectModal({visible: true, project: project ?? undefined})
  }

  const closeLinkProjectModal = () => {
    setLinkProjectModal({visible: false, project: undefined})
  }

  const assignProject = (data: { project: IProject }) => {
    if (!survey) {
      return;
    }
    //Assign Project to Survey

    const payload = {
      projectId: data.project.id,
      surveyId: survey?.surveyId,
      description: "Survey linked to project!",
    };


    OperationsApi.addLinkedSurvey(payload)
      .then((response: AxiosResponse<any>) => {
        closeLinkProjectModal()
        loadPage();
      });
  }

  const navigateBack = () => {
    if (state?.projectId) {
      navigate("/projects/edit?id=" + state.projectId + "&tab=2")
      return;
    }

    navigate('/surveys')
  }

  return (
    <div className="survey-details-wrapper">
      {loader ? <Loader/> : !survey ? <></> :
        <>
          <div className="header">
            <div className="row w-100">
              <div className="column align-items-center back-button">
                <button className="icon-button back" onClick={() => navigateBack()}>
                  <SvgArrowBack/>
                </button>
              </div>
              <div className="column fxFlex" style={{gap: 15}}>
                <h2 className="survey-title">
                  {survey.name}
                </h2>
                <div className="row survey-info">
              <span>
               {DaysLeftDatePipe(survey.createdTime)}
              </span>
                  <span>
               {survey.status}
              </span>
                </div>
                <div>
                  {
                    !!survey.linkedProject ?
                      <div className="survey-project-linked">
                        <div><SvgSurvey color={"#1B3380"} width={24} height={24}/></div>
                        <div className="survey-project-linked-name">{survey.linkedProject.name}</div>
                        <button className="icon-button" onClick={() => openLinkProjectModal(survey?.linkedProject)}>
                          <SvgEdit/>
                        </button>
                      </div> :
                      <Button type="primary" onClick={() => openLinkProjectModal()}>
                        Link to Project
                      </Button>
                  }
                </div>
              </div>
              <div>
                <Button type="primary" className="row align-items-center" style={{gap: 8}} ghost
                        icon={<SvgExternalLink/>}
                        onClick={() => window.open(`https://www.questionpro.com/a/editSurvey.do?surveyID=${survey?.surveyId}`)}>
                  Open in surveys
                </Button>
              </div>
            </div>
          </div>
          <div className="survey-body">
            <div className="survey-main">
              <div className="survey-details">
                <div className="survey-detail-info">
                  <div className="survey-detail-number">{survey.timesViewed}</div>
                  <div className="survey-detail-hr"/>
                  <div className="survey-detail-label">
                    <SvgEye/> <span>Viewed</span>
                  </div>
                </div>
                <div className="survey-detail-info">
                  <div className="survey-detail-number">{survey.totalResponses}</div>
                  <div className="survey-detail-hr"/>
                  <div className="survey-detail-label">
                    <SvgResponses/> <span>Total responses</span>
                  </div>
                </div>
                <div className="survey-detail-info">
                  <div className="survey-detail-number">{survey.completedResponses}</div>
                  <div className="survey-detail-hr"/>
                  <div className="survey-detail-label">
                    <SvgCompleted/> <span>Completed</span>
                  </div>
                </div>
                <div className="survey-detail-info">
                  <div className="survey-detail-number">{!!survey.averageTime ? survey.averageTime : 0}</div>
                  <div className="survey-detail-hr"/>
                  <div className="survey-detail-label">
                    <SvgClock/> <span>Average time</span>
                  </div>
                </div>
                <div className="survey-detail-info survey-detail-info-graph-wrapper" style={{width: "fit-content"}}>
                  <div className="survey-detail-info-graph"><DonutChart
                    data={{percentage: survey.totalResponses ? (survey.completedResponses / survey.totalResponses) * 100 : 0}}/>
                  </div>
                  <div className="">
                    <div
                      className="survey-detail-number"> {survey.totalResponses ? parseFloat((survey.completedResponses / survey.totalResponses).toFixed(2)) * 100 : 0}%
                    </div>
                    <div className="survey-detail-label">Completion</div>
                  </div>
                </div>
              </div>
              <div className="survey-graphs">
                <div className="column justify-space-center" style={{gap: 20}}>
                  {

                    graphs && graphs.length ? graphs.map((graph, index: number) => {
                      if (!graph.highChartJSON?.length) {
                        return <></>
                      }
                      return <div style={{padding: 20}} key={index}>
                        <div className='graph-question-title'>{graph.title}</div>
                        <HighchartsReact
                          options={JSON.parse(graph.highChartJSON)}
                          highcharts={Highcharts}/>
                      </div>
                    }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  }
                  {
                    graphs && graphs.length && graphs.filter(graph => !!graph.highChartJSON).length === 0 &&
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  }
                </div>
              </div>
            </div>
            {survey &&
              <AnalyzeChat survey={survey}/>
            }

          </div>
        </>}
      {
        linkProjectModal.visible &&
        <LinkProjectModal
          data={linkProjectModal}
          onCancel={closeLinkProjectModal}
          onConfirm={assignProject}/>
      }
    </div>
  );
};

export default SurveyDetails;
