// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.normal-loader {
  width: 4em;
  height: 1em;
  display: block;
  font-size: 12px;
  padding-left: 5px;
  z-index: 99;
  position: relative;
}
.normal-loader span {
  width: 1em;
  height: 1em;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background: #0a86ea;
  border-radius: 0.5em;
  position: absolute;
  z-index: 2;
}
.normal-loader span:nth-child(1) {
  animation-name: reveal;
  left: 0;
}
.normal-loader span:nth-child(2) {
  animation-name: slide;
  left: 0;
}
.normal-loader span:nth-child(3) {
  animation-name: slide;
  left: 1.5em;
}
.normal-loader span:nth-child(4) {
  animation-name: reveal;
  animation-direction: reverse;
  left: 3em;
}

@keyframes reveal {
  from {
    -webkit-transform: scale(0.001);
    -moz-transform: scale(0.001);
    -ms-transform: scale(0.001);
    -o-transform: scale(0.001);
  }
  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}
@keyframes slide {
  to {
    -webkit-transform: translateX(1.5em);
    -moz-transform: translateX(1.5em);
    -ms-transform: translateX(1.5em);
    -o-transform: translateX(1.5em);
  }
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/normal-loader/normal-loader.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;EACA,WAAA;EACA,wBAAA;EACA,+BAAA;EACA,mCAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,UAAA;AACJ;AACI;EACE,sBAAA;EACA,OAAA;AACN;AAEI;EACE,qBAAA;EACA,OAAA;AAAN;AAGI;EACE,qBAAA;EACA,WAAA;AADN;AAII;EACE,sBAAA;EACA,4BAAA;EACA,SAAA;AAFN;;AAOA;EACE;IACE,+BAAA;IACA,4BAAA;IACA,2BAAA;IACA,0BAAA;EAJF;EAMA;IACE,2BAAA;IACA,wBAAA;IACA,uBAAA;IACA,sBAAA;EAJF;AACF;AAOA;EACE;IACE,oCAAA;IACA,iCAAA;IACA,gCAAA;IACA,+BAAA;EALF;AACF","sourcesContent":[".normal-loader {\n  width: 4em;\n  height: 1em;\n  display: block;\n  font-size: 12px;\n  padding-left: 5px;\n  z-index: 99;\n  position: relative;\n\n  span {\n    width: 1em;\n    height: 1em;\n    animation-duration: .5s;\n    animation-timing-function: ease;\n    animation-iteration-count: infinite;\n    background: #0a86ea;\n    border-radius: .5em;\n    position: absolute;\n    z-index: 2;\n\n    &:nth-child(1) {\n      animation-name: reveal;\n      left: 0;\n    }\n\n    &:nth-child(2) {\n      animation-name: slide;\n      left: 0;\n    }\n\n    &:nth-child(3) {\n      animation-name: slide;\n      left: 1.5em;\n    }\n\n    &:nth-child(4) {\n      animation-name: reveal;\n      animation-direction: reverse;\n      left: 3em;\n    }\n  }\n}\n\n@keyframes reveal {\n  from {\n    -webkit-transform: scale(.001);\n    -moz-transform: scale(.001);\n    -ms-transform: scale(.001);\n    -o-transform: scale(.001)\n  }\n  to {\n    -webkit-transform: scale(1);\n    -moz-transform: scale(1);\n    -ms-transform: scale(1);\n    -o-transform: scale(1)\n  }\n}\n\n@keyframes slide {\n  to {\n    -webkit-transform: translateX(1.5em);\n    -moz-transform: translateX(1.5em);\n    -ms-transform: translateX(1.5em);\n    -o-transform: translateX(1.5em)\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
