import * as React from "react";

const SvgAnalytics = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 4C0 3.44772 0.447715 3 1 3H2.69231C3.24459 3 3.69231 3.44772 3.69231 4V15C3.69231 15.5523 3.24459 16 2.69231 16H1C0.447716 16 0 15.5523 0 15V4Z"
          fill={props.color ? props.color : '#545E6B'}/>
        <path
          d="M6.15385 11C6.15385 10.4477 6.60156 10 7.15385 10H8.84615C9.39844 10 9.84615 10.4477 9.84615 11V15C9.84615 15.5523 9.39844 16 8.84615 16H7.15385C6.60156 16 6.15385 15.5523 6.15385 15V11Z"
          fill={props.color ? props.color : '#545E6B'}/>
        <path
          d="M12.3077 1C12.3077 0.447715 12.7554 0 13.3077 0H15C15.5523 0 16 0.447715 16 1V15C16 15.5523 15.5523 16 15 16H13.3077C12.7554 16 12.3077 15.5523 12.3077 15V1Z"
          fill={props.color ? props.color : '#545E6B'}/>
      </svg>

    </svg>
  );
}

export default SvgAnalytics;
