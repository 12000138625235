import React from "react";
import { DraftDecoratorComponentProps } from "draft-js";

export const LinkStrategy = (props: DraftDecoratorComponentProps) => {
  let url;
  if (props.entityKey != null) {
    url = props.contentState.getEntity(props?.entityKey).getData();
  } else {
    return;
  }

  return (
    <a rel="noopener noreferrer" target="_blank" href={url ? url : null}>
      {props.children}
    </a>
  );
};
