import { Divider, Modal, Tabs } from 'antd'
import React, { useState } from 'react'
import "./media-dialog.scss"
import MediaGallery from './media-gallery/media-gallery';
import UnsplashGallery from './unsplash-gallery/unsplash-gallery';
import { DebounceInput } from "react-debounce-input";
import axios from "axios";
import ImageFromUrl from "./image-from-url/image-from-url";
import { getImageInfo } from "../../helpers/get-image-info";

const MediaDialog = (props: any) => {
  const [tabKey, setTabKey] = useState("1");
  const [selectedUrl, setSelectedUrl] = useState("");
  const [imageName, setImageName] = useState<string | undefined>("");
  const [username, setUsername] = useState("");
  const [userUrl, setUserUrl] = useState("");

  const [urlError, setUrlError] = useState<string>("");

  const handleImageClick = (data: { url: string, name?: string, username?: string, userUrl?: string }) => {
    setSelectedUrl(data.url);
    setImageName(data.name);

    if (data.username && data.userUrl) {
      setUsername(data.username);
      setUserUrl(data.userUrl);
    } else {
      setUsername("")
      setUserUrl("")
    }
  }

  const onSave = () => {
    if (tabKey === '2' && urlError) {
      return;
    }

    if (selectedUrl === "") {
      return;
    }

    props.onConfirm({url: selectedUrl, imageName, username, userUrl});
    props.onCancel();
  }


  const onUrlChange = async (url: string) => {
    setUrlError("")
    setSelectedUrl(url);

    if (!url) {
      return;
    }

    const imageInfo = await getImageInfo(url)

    if (!imageInfo) {
      setUrlError('Failed to fetch image')
      return
    }

    if (imageInfo.width < 630 || imageInfo.height < 420) {
      setUrlError('Minimum image size is 630x420 pixels')
      return;
    }

    if (imageInfo.type !== 'jpg' && imageInfo.type !== 'png' && imageInfo.type !== 'jpeg') {
      setUrlError('Accepted file types .jpg and .png only')
      return;
    }
  }

  return (
    <Modal onCancel={props.onCancel} onOk={onSave} open={props.visible} title="Add image" width={1000}
           okText="Add Image" cancelButtonProps={{type: "link"}}>
      <div className="add-image-wrapper">
        <Tabs destroyInactiveTabPane={true}
              activeKey={tabKey}
              onChange={(key) => setTabKey(key)}>
          <Tabs.TabPane tab="Media library" key="1">
            <MediaGallery handleImageClick={handleImageClick} selectedUrl={selectedUrl}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Image from URL" key="2">
            <ImageFromUrl onUrlChange={onUrlChange} urlError={urlError}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span style={{fontSize: 12}}>Photos by <span style={{fontWeight: 600}}>Unsplash</span> </span>}
            key="3">
            <UnsplashGallery handleImageClick={handleImageClick} selectedUrl={selectedUrl}/>
          </Tabs.TabPane>
        </Tabs>
      </div>
      <Divider style={{marginTop: 5, marginBottom: -5}}/>
    </Modal>
  )
}

export default MediaDialog
