// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analytics-timeline svg {
  margin: 0 4px;
}
.analytics-timeline .workflow-stats {
  font-size: 14px;
  font-weight: 300;
  color: #545e6b;
}
.analytics-timeline .color-circle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 4px;
}
.analytics-timeline .workflow-status-progress-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  padding: 4px 0;
}
.analytics-timeline .workflow-status-progress-wrapper .median-line {
  position: absolute;
  height: 130%;
  top: -4px;
  width: 2px;
  border: 1px dashed #9b9b9b;
}
.analytics-timeline .table {
  margin-top: 16px;
}
.analytics-timeline .table, .analytics-timeline .ant-table-tbody {
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/analytics/timeline/analytics-timeline.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;AAHJ;AAQI;EACE,kBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,0BAAA;AANN;AAUE;EACE,gBAAA;AARJ;AAWE;EACE,cAAA;AATJ","sourcesContent":[".analytics-timeline {\n  svg {\n    margin: 0 4px;\n  }\n\n  .workflow-stats {\n    font-size: 14px;\n    font-weight: 300;\n    color: #545e6b;\n  }\n\n  .color-circle {\n    width: 12px;\n    height: 12px;\n    border-radius: 100%;\n    margin-right: 4px;\n  }\n\n  .workflow-status-progress-wrapper {\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    background-color: #f5f5f5;\n    padding: 4px 0;\n\n    .workflow-status-progress-bar {\n    }\n\n    .median-line {\n      position: absolute;\n      height: 130%;\n      top: -4px;\n      width: 2px;\n      border: 1px dashed #9b9b9b;\n    }\n  }\n\n  .table {\n    margin-top: 16px;\n  }\n\n  .table, .ant-table-tbody {\n    color: #545e6b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
