// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  width: 100%;
}
.input .header .left {
  flex: 1 1;
}
.input .header .left .hr {
  width: 100%;
  height: 1px;
  background-color: #D8D8D8;
}
.input .header .right {
  padding-left: 15px;
}
.input .header .right .add-label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #1B87E6;
}
.input .header .right .main-form-input {
  height: 100%;
  width: 250px;
  border: 0;
  border-bottom: 1px solid #D8D8D8;
}
.input .header .right .main-form-input:focus {
  outline: 0;
  border-bottom: 1px solid #1B87E6;
}
.input .form-input-text-area {
  width: 50%;
}
.input .form-input-text-area .form-input-text-area-title {
  color: #545E6B;
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 8px;
}
.input .form-input-text-area .text-area .dash {
  border: none;
  border-top: 1px dotted #545E6B;
  color: #fff;
  background-color: transparent;
  height: 1.5px;
  width: 50%;
  margin: 15px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/taxonomy/project-type/project-type-form/forms/input/input.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEI;EACE,SAAA;AAAN;AAEM;EACE,WAAA;EACA,WAAA;EACA,yBAAA;AAAR;AAII;EACE,kBAAA;AAFN;AAIM;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;AAFR;AAKM;EACE,YAAA;EACA,YAAA;EACA,SAAA;EACA,gCAAA;AAHR;AAKQ;EACE,UAAA;EACA,gCAAA;AAHV;AASE;EACE,UAAA;AAPJ;AASI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAPN;AAWM;EACE,YAAA;EACA,8BAAA;EACA,WAAA;EACA,6BAAA;EACA,aAAA;EACA,UAAA;EACA,cAAA;AATR","sourcesContent":[".input {\n  width: 100%;\n\n  .header {\n    .left {\n      flex: 1;\n\n      .hr {\n        width: 100%;\n        height: 1px;\n        background-color: #D8D8D8;\n      }\n    }\n\n    .right {\n      padding-left: 15px;\n\n      .add-label {\n        width: 100%;\n        height: 100%;\n        cursor: pointer;\n        color: #1B87E6;\n      }\n\n      .main-form-input {\n        height: 100%;\n        width: 250px;\n        border: 0;\n        border-bottom: 1px solid #D8D8D8;\n\n        &:focus {\n          outline: 0;\n          border-bottom: 1px solid #1B87E6;\n        }\n      }\n    }\n  }\n\n  .form-input-text-area {\n    width: 50%;\n\n    .form-input-text-area-title {\n      color: #545E6B;\n      font-size: 14px;\n      font-weight: 300;\n      padding-bottom: 8px;\n    }\n\n    .text-area {\n      .dash {\n        border: none;\n        border-top: 1px dotted #545E6B;\n        color: #fff;\n        background-color: transparent;\n        height: 1.5px;\n        width: 50%;\n        margin: 15px 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
