import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import './sidenav.scss';
import { INavigationItem } from './model/navigation-item.interface';
import { Link, useLocation } from 'react-router-dom';
import SvgHome from '../../../../assets/icons/js/Home';
import SvgProject from '../../../../assets/icons/js/List';
import SvgSettings from '../../../../assets/icons/js/Settings';
import SvgAnalytics from '../../../../assets/icons/js/Analytics';
import SvgStories from '../../../../assets/icons/js/Stories';
import SvgAdminSettings from '../../../../assets/icons/js/AdminSettings';
import GlobalContext from '../../../../store/global-context';
import { IUserData } from '../../../model/user-data.interface';
import { isNotAdmin } from '../../../../_shared/helpers/is-admin';
import { useForceUpdate } from '../../../hooks/use-force-update'
import SvgKnowledgeGraph from '../../../../assets/icons/js/knowledge-graph';
import SvgMarketFlipped from '../../../../assets/icons/js/marketFlipped';
import AgencyEditionMenu from "./components/agency-edition-menu";
import SvgFolder from "../../../../assets/icons/js/Folder";
import SvgInsightDesk from "../../../../assets/icons/js/InsightDesk";

function Sidenav() {
  let component = useRef(null);
  const location = useLocation();
  const globalContext = useContext(GlobalContext);
  const user: IUserData | any = globalContext.user;
  const [expendedItem, setExpendedItem] = useState<string>('');
  const forceUpdate = useForceUpdate();


  const navigationItems: INavigationItem[] = [
    {
      title: 'Home',
      icon: <SvgHome/>,
      url: '/home',
      hidden: !user.license.modules.includes('HOMEPAGE')
    },
    {
      title: 'Projects',
      icon: <SvgProject/>,
      url: '/projects',
      hidden: !user.license.modules.includes('PROJECTS')
    },

    {
      title: 'QuestionPro Surveys',
      icon: <SvgInsightDesk style={{marginLeft: 2}}/>,
      url: '/surveys',
      hidden: !user.license.modules.includes('SURVEYS')
    },
    {
      title: 'Knowledge Graphs',
      icon: <SvgKnowledgeGraph/>,
      url: '/nodes',
      hidden: !user.license.modules.includes('KNOWLEDGE_GRAPHS')
    },
    {
      title: 'Snapshots',
      icon: <SvgMarketFlipped/>,
      url: '/snapshots',
      hidden: !user.license.modules.includes('SNAPSHOTS')
    },
    {
      title: 'Stories',
      icon: <SvgStories/>,
      url: '/stories',
      hidden: !user.license.modules.includes('STORIES')
    },
    {
      title: 'Repositories',
      icon: <SvgFolder/>,
      url: '/repositories',
      hidden: !user.license.modules.includes('REPOSITORIES')
    },
    {
      title: 'Metrics',
      icon: <SvgAnalytics/>,
      url: '/metrics',
      hidden: !user.license.modules.includes('METRICS')
    }
  ];

  const bottomNavigationItems: INavigationItem[] = [
    {
      title: 'Admin',
      icon: <SvgAdminSettings/>,
      url: '/admin',
      hidden: isNotAdmin(user.role.name)
    },
    {
      title: 'Settings',
      icon: <SvgSettings/>,
      url: '/settings'
    }
  ]

  useEffect(() => {
    checkForUrlChange();
  }, [location]);

  useEffect(() => {
    checkForUrlChange();
  });

  // useEffect(() => {
  //   navigationItems.forEach((item: INavigationItem) => {
  //     if (window.location.pathname.includes(item.url) && item.subItems?.length) {
  //       toggleMenu(item);
  //     }
  //   });
  // }, []);


  function renderNavigationItems() {
    return navigationItems.map((item: INavigationItem) => {
      if (item.hidden) {
        return null;
      }

      // if (!item.subItems?.length) {
      return (
        <Link className={isMenuActive(item)
          ? 'nav-item nav-item-active'
          : 'nav-item pointer'}
              onClick={() => toggleMenu(item)}
              to={item.url}
              state={{lastPath: location.pathname}}
              key={item.title}>
          <div className="icon">
            {item.icon}
          </div>
          <div className="title">{item.title}</div>
        </Link>
      )
      // } else {
      //   return (
      //     <div key={item.title}>
      //       <div
      //         className={expendedItem === item.title ? 'expandable-item expandable-item-active pointer' : 'expandable-item nav-item pointer'}>
      //         <div className="nav-item" onClick={() => toggleMenu(item)}>
      //           <div className="icon">
      //             {item.icon}
      //           </div>
      //           <div className="title">{item.title}</div>
      //         </div>
      //         <div className={expendedItem === item.title ? 'expand-container' : 'expand-container collapse'}>
      //           {item.subItems.map((subItem: INavigationItem) => {
      //             if (subItem.hidden) {
      //               return null;
      //             }
      //
      //             return (
      //               <Link className={isMenuActive(subItem)
      //                 ? 'nav-item nav-item-active'
      //                 : 'nav-item pointer'}
      //                     onClick={forceUpdate}
      //                     state={{lastPath: location.pathname}}
      //                     to={subItem.url}
      //                     key={subItem.title}>
      //                 <div className="title">{subItem.title}</div>
      //               </Link>
      //             )
      //           })}
      //         </div>
      //       </div>
      //     </div>
      //   )
      // }
    })
  }

  function changePageTitle(title: string): void {
    document.title = title ? `InsightsHub · ${title}` : 'InsightsHub'
  }

  function checkForUrlChange(): void {
    navigationItems.forEach((item: INavigationItem) => {
      if (window.location.pathname.startsWith(item.url)) {
        if (item.subItems?.length) {
          const foundItem: INavigationItem | undefined = item.subItems.find((item: INavigationItem) => window.location.pathname === item.url);
          if (foundItem) {
            changePageTitle(`${item.title} · ${foundItem.title}`);
            return;
          }
        }
        changePageTitle(item.title);
      }
    });

    bottomNavigationItems.forEach((item: INavigationItem) => {
      if (window.location.pathname.startsWith(item.url)) {
        if (item.subItems?.length) {
          const foundItem: INavigationItem | undefined = item.subItems.find((item: INavigationItem) => window.location.pathname === item.url);
          if (foundItem) {
            changePageTitle(`${item.title}/${foundItem.title}`);
            return;
          }
        }
        changePageTitle(item.title);
      }
    });
  }

  function toggleMenu(item: INavigationItem): void {
    if (expendedItem === item.title && item) {
      setExpendedItem('');
    } else {
      setExpendedItem(item.title);
    }
  }

  function isMenuActive(item: INavigationItem): boolean {
    if (item.organizationData) {
      return item.organizationData.organizationID == globalContext.selectedOrganization;
    }
    return window.location.pathname.startsWith(item.url);
  }

  return (
    <div className="sidenav-wrapper" onMouseLeave={() => setExpendedItem('')} ref={component}>
      <div className="sidenav">
        <div className="sidenav-navigation-list">
          <div className="nav-list column">
            {
              renderNavigationItems()
            }
          </div>

          <div className="nav-list column">
            {bottomNavigationItems.map((item: INavigationItem) => {
              if (item.hidden) {
                return null;
              }
              return (
                <Link className={isMenuActive(item)
                  ? 'nav-item nav-item-active'
                  : 'nav-item'}
                      onClick={() => toggleMenu(item)}
                      to={item.url}
                      key={item.title}>
                  <div className="icon">
                    {item.icon}
                  </div>
                  <div className="title">{item.title}</div>
                </Link>
              )
            })}
            {
              globalContext.isPartner &&
                <AgencyEditionMenu partnerOrganizations={globalContext.partnerOrganizations} component={component}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Sidenav);
