import * as React from 'react';

const ImageIcon = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4C21.5523 4 22 4.44772 22 5V19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19V5C2 4.44772 2.44772 4 3 4H21ZM19 16L14.5 11L10.5192 15.4231L8.5 13L6 16H19ZM8.19626 6C6.9833 6 6 6.9833 6 8.19626C6 9.40922 6.9833 10.3925 8.19626 10.3925C9.40922 10.3925 10.3925 9.40922 10.3925 8.19626C10.3925 6.9833 9.40922 6 8.19626 6Z"
        fill={props.color ? props.color : '#545E6B'}
      />
    </svg>
  );
};

export default ImageIcon;
