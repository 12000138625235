import React, { useState } from 'react';
import "./repositories.scss"
import { Button, message, Table, Tooltip } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { IRepoFile } from "./model/repo-file.interface";
import SvgTrash from "../../assets/icons/js/Trash";
import { IRepository } from "./model/repository.interface";
import repositoriesApi from "./api/repositories-api";
import { ConfirmationDialog } from "../../_shared/components/confirmation-dialog/confirmation-dialog";
import SvgAddBig from "../../assets/icons/js/addBig";
import { useQuery } from "react-query";
import RepositoriesLandingPage from "./components/repositories-landing-page/repositories-landing-page";
import Pagination from "../../_shared/components/pagination/pagination";
import DaysLeftDatePipe from "../../_shared/helpers/days-left-date-pipe";
import SvgTick from "../../assets/icons/js/Tick";
import NewRepositoryModal from "./components/new-repository-modal/new-repository-modal";

const Repositories = (props: any) => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<any[]>
  ([]);

  const columns: ColumnsType<any> = [
    {
      title: selectedRows.length ? <div className='selected-rows-title-wrapper'>
        <span>{selectedRows.length} selected</span>
        <div className='selected-rows-btn'
             data-testid="bulk-archive-btn" onClick={openBulkArchiveConfirmationDialog}>
          <SvgTrash color={'#1b87e6'}/>
          &nbsp;Archive
        </div>
      </div> : "Name",
      dataIndex: 'name',
      render: (val, record: IRepoFile) => <Link to={`/repositories/edit?id=${record.id}`}
                                                className=""
                                                style={{gap: 10}}> {val}</Link>
    },
    {
      title: <span className='column-to-be-hided'>
          Owner
        </span>,
      dataIndex: 'owner',
      width: '20%',
    },
    {
      title: <span className='column-to-be-hided'>
          Files
        </span>,
      dataIndex: 'numberOfFiles',
      width: '20%',
      render: (value) => <span>{value}</span>
    },
    {
      title: "Created on",
      dataIndex: 'createdAt',
      width: '20%',
      render: (value) => <>{value && DaysLeftDatePipe(value)}</>
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "right",
      render: (id: number, record: IRepository) => (
        <div className="action-hover-column row">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Archive repository">
              <button className="icon-button project-icon-button" onClick={() => openDeleteConfirmationDialog(record)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ]

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || "10");

  const repositories = useQuery(['repositories', page, pageSize], () => repositoriesApi.getRepositories({
    page,
    pageSize,
  }));

  const archivedCount = useQuery('archivedRepositoriesCount', () => repositoriesApi.getArchivedRepositoriesCount());

  const [newFolderModal, setNewFolderModal] = useState({open: false})
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<any>({visible: false, id: null});

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
  };


  function openNewFolderModal() {
    setNewFolderModal({open: true})
  }

  function closeNewFolderModal() {
    setNewFolderModal({open: false})
  }

  const navigateToArchivedRepositories = () => {
    navigate('/repositories/archived');
  }

  const onDialogCancel = () => {
    setDeleteConfirmationDialog({visible: false})
  }

  const openDeleteConfirmationDialog = (repository?: Partial<IRepository>) => {
    setDeleteConfirmationDialog({
      visible: true,
      id: repository?.id,
      okText: 'Archive',
      title: 'Archive',
      titleSec: 'You\'re about to archive this repository',
      content: `${repository?.name || 'Untitled'}`,
      description: <>
        Continuing will remove the repository from this list. You can restore it after deletion.
      </>
    });
  }

  function openBulkArchiveConfirmationDialog() {
    setDeleteConfirmationDialog({
      visible: true,
      id: null,
      ids: selectedRows.map((row) => row.id),
      okText: 'Archive',
      title: 'Archive',
      titleSec: 'You\'re about to archive these repositories',
      content: `${selectedRows.length} repositories`,
      description: <>
        Continuing will remove the repositories from this list. You can restore them after deletion.
      </>
    });
  }

  const onDeleteConfirmationDialog = (props: any) => {
    if (props.modal) {
      if (props.id) {
        archiveRepository(props.id);
      }
      if (props.ids) {
        archiveRepositories(props.ids);
      }
      onDialogCancel();
    }
  }


  const archiveRepository = (id: number) => {
    repositoriesApi.deleteRepository(id).then(() => {
      repositories.refetch();
      message.success({
        content: 'Repository archived successfully',
        className: 'custom-success-message',
        icon: <SvgTick color={"#227700"}/>,
      })
    })
  }

  const archiveRepositories = (ids: number[]) => {
    repositoriesApi.bulkArchiveRepositories(ids).then(() => {
      repositories.refetch();
      message.success({
        content: 'Repositories archived successfully',
        className: 'custom-success-message',
        icon: <SvgTick color={"#227700"}/>,
      });
      setSelectedRows([])
    })
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setSearchParams((prevParams) => {
      prevParams.set("page", page.toString());
      prevParams.set("pageSize", pageSize.toString());
      return prevParams;
    })
  }


  return (
    <div className='file-repository-wrapper'>
      {
        repositories.data && repositories.data.pagination.totalElements === 0 ?
          <RepositoriesLandingPage/>
          : <div className="file-repository-page-dragger">
            <h3>Repositories</h3>
            <div className='file-repository-table-wrapper'>
              <div className='file-repository-table-header'>
                <div className='file-repository-table-header-buttons'>
                  <Button type={"primary"}
                          icon={<span className="anticon"><SvgAddBig width={14} height={14} color={'#fff'}/></span>}
                          onClick={openNewFolderModal}>
                    New repository
                  </Button>
                </div>
                <div className='file-repository-table-header-pagination'>
                  {
                    repositories.data &&
                    <Pagination currentPage={page}
                                pageSize={pageSize}
                                showSizeChanger={true}
                                totalItems={repositories.data.pagination.totalElements}
                                onChange={handlePaginationChange}
                    />
                  }
                  <div className='file-repository-archived row align-items-center'
                       onClick={navigateToArchivedRepositories}>
                    {
                      archivedCount?.data
                    }
                    <SvgTrash/>
                  </div>
                </div>
              </div>
              {
                repositories.data &&
                <div
                  className={selectedRows.length ? 'file-repository-table file-repository-table-selected-rows' : 'file-repository-table'}>
                  <Table
                    rowKey={"id"}
                    loading={repositories.isLoading}
                    pagination={false}
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    dataSource={repositories.data.content}
                    columns={columns}/>
                </div>
              }
            </div>
          </div>
      }
      {
        newFolderModal.open &&
        <NewRepositoryModal data={newFolderModal} onCancel={closeNewFolderModal}/>
      }
      {
        deleteConfirmationDialog.visible &&
        <ConfirmationDialog data={deleteConfirmationDialog}
                            onConfirm={onDeleteConfirmationDialog}
                            onCancel={onDialogCancel}/>

      }
    </div>
  );
};

export default Repositories;
