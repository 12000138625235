import React, { useState } from 'react';
import SvgResearch from '../../../../../../../assets/icons/js/research';
import SvgPeople from '../../../../../../../assets/icons/js/people';
import SvgListChecked from '../../../../../../../assets/icons/js/list-checked';

export default function GoogleDriveDescriptionTab() {

  const [colorPicked, setColorPicked] = useState('#1b3380');

  const pickColor = (color: string): void => {
    if (!color) {
      return;
    }
    setColorPicked(color);
  }
  const supportedFiles = ['xlsx ', 'docx ', 'pdf ', 'jpg ', 'png ', 'pptx ', 'csv ', 'zip ', 'txt']

  const files = () => {
    return (
      <ul>
        <div>
          {supportedFiles && supportedFiles.map((supportedFile: any, i: number) => {
            return (
              <span key={i}>
                                <li style={{marginBottom: '0px'}}>{supportedFile.split(' ')[0]}</li>
                            </span>
            )
          })}
        </div>
      </ul>
    )
  }

  return (
    <div className="integration-tab column">
      <div className="row description-tab w-100">
        <div className="column">
          <h3 className="title">InsightsHub allows you to: </h3>
          <div className="column">
            <div className="content-title">
              <SvgResearch/>
              <h4>Quickly bring Drive files into InsightsHub projects</h4>
            </div>
            <ul>
              <li>Bring all your relevant files in to the right place at the right time.</li>
              <li>Supported Files are: {files()}
              </li>
            </ul>
          </div>
          <div className="column">
            <div className="content-title">
              <SvgListChecked/>
              <h4>Research repository</h4>
            </div>
            <ul>
              <li>Store and keep track of all your research projects in a single place.</li>
            </ul>
          </div>
          <div className="column">
            <div className="content-title">
              <SvgPeople/>
              <h4>Democratize insights</h4>
            </div>
            <ul>
              <li>Get everyone involved and shared your research with your entire
                organization
              </li>
            </ul>
          </div>
        </div>
        <div className="column image-picker w-100 d-flex align-items-end">
          <div>
            <div style={{backgroundColor: colorPicked}} className="image-picked"/>
            <div className="row">
              <div className="pick-image"
                   style={{backgroundColor: '#1b3380'}}
                   onClick={() => pickColor('#1b3380')}/>

              <div className="pick-image"
                   style={{backgroundColor: '#545e6b'}}
                   onClick={() => pickColor('#545e6b')}/>

              <div className="pick-image"
                   style={{backgroundColor: '#1b87e6'}}
                   onClick={() => pickColor('#1b87e6')}/>

              <div className="pick-image"
                   style={{backgroundColor: '#a5d5ff'}}
                   onClick={() => pickColor('#a5d5ff')}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
