// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.knowledge-graphs-dialog {
  overflow: auto;
  max-height: 500px;
  padding: 10px 0;
}
.knowledge-graphs-dialog .knowledge-graphs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.knowledge-graphs-dialog .knowledge-graphs-dialog-title {
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/nuggets/knowledge-graphs-dialog/knowledege-graphs-dialog.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAEE;EACE,cAAA;AAAJ","sourcesContent":[".knowledge-graphs-dialog {\n  overflow: auto;\n  max-height: 500px;\n  padding: 10px 0;\n\n  .knowledge-graphs-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n\n  .knowledge-graphs-dialog-title {\n    color: #545e6b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
