import { INotepadType } from "../model/types.interface";
import SvgSurvey from "../../../../../../assets/icons/js/Survey";
import SvgMultimedia from "../../../../../../assets/icons/js/multimedia";
import SvgAddBig from "../../../../../../assets/icons/js/addBig";

export const notepadTypes: INotepadType[] = [
  {
    id: 2,
    name: "Blank",
    icon: <SvgAddBig color="#545E6B"/>,
  },
  {
    id: 1,
    name: "Live Interview",
    icon: <SvgSurvey color="#545E6B"/>,
  },
  {
    id: 3,
    name: "Video/Audio",
    icon: <SvgMultimedia color="#545E6B"/>,
  },
];

export function findNotePadType(id: number): INotepadType | undefined {
  return notepadTypes.find(
    (notepadType: INotepadType) => notepadType.id === id
  );
}
