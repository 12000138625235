// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-study-box {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  width: 200px;
  max-width: 200px;
}
.recent-study-box h3 {
  color: #1B87E6;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.recent-study-box p {
  color: #545E6B;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/recent-study-box/recent-study-box.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,6BAAA;AAAJ","sourcesContent":[".recent-study-box {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  border-radius: 4px;\n  border: 1px solid #D8D8D8;\n  width: 200px;\n  max-width: 200px;\n\n  h3 {\n    color: #1B87E6;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 24px; /* 150% */\n  }\n\n  p {\n    color: #545E6B;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 16px; /* 133.333% */\n    margin-bottom: 5px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
