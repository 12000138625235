import { Divider, Modal, Select, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './link-tool-dialog.scss';
import OperationsApi from '../../api/operations-api';
import SvgArrowDown from '../../../../../../../assets/icons/js/ArrowDown';
import { LinkTypeEnum } from "./model/link-type.enum";
import { LinkStatusEnum } from "./model/link-status.enum";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import notesApi from "../../../notes/api/notes-api";
import { DebounceInput } from "react-debounce-input";
import { LinkExternalToolDialogData } from "../../operations-dialogs-reducer/operations-dialogs-reducer.types";

export type LinkExternalToolPayload = {
  id?: number;
  projectId?: number;
  link: string;
  description: string;
  type: LinkTypeEnum;
  status: LinkStatusEnum;
  title: string;
  logoUrl?: string;
}

type LinkExternalToolDialogProps = {
  data: LinkExternalToolDialogData;
  onConfirm: (props: { externalLinkModal?: boolean }) => void;
  onCancel: () => void
}
export default function LinkExternalToolDialog({data, onCancel, onConfirm}: LinkExternalToolDialogProps) {
  const {externalTool} = data;
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [linkFocused, setLinkFocused] = useState(false);
  const isFirstUpdate = useRef(true);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: {errors},
  } = useForm<LinkExternalToolPayload>({
    defaultValues: externalTool ? {
      title: externalTool.title,
      link: externalTool.link,
      status: externalTool.status,
      type: externalTool.type,
      description: externalTool.description,
      logoUrl: externalTool.logoUrl,
      id: externalTool.id
    } : {}
  })

  const logoUrl: string = useWatch({control, name: 'logoUrl'});
  const externalLink: string = useWatch({control, name: 'link'});
  const title: string = useWatch({control, name: 'title'})

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }

    if (externalLink?.length === 0) {
      setValue('logoUrl', "")
      //setValue('title', "")
      return
    }

    notesApi.getExternalLinkInfo(externalLink).then((data) => {
      if (data?.ogTitle && !title?.length) {
        setValue('title', data.ogTitle)
      }

      if (data?.ogImage?.url) {
        setValue('logoUrl', data.ogImage.url)
        return;
      }

      if (data?.favicon) {
        setValue('logoUrl', data.favicon)
        return;
      }

      setValue('logoUrl', "")
    })

  }, [externalLink]);


  function validateUrl(url: string): undefined | string {
    const regex = new RegExp(
      '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    );
    const isValid = regex.test(url);
    if (isValid) {
      return undefined
    } else {
      return "URL is not valid!"
    }
  }

  function onFormSubmit(formData: LinkExternalToolPayload) {
    setApiLoading(true);

    const {id, ...restFormData} = formData;

    if (id) {
      OperationsApi.updateExternalTool(id, restFormData).then(() => {
          setApiLoading(false);
          onConfirm({externalLinkModal: true});
        }, () => {
          setApiLoading(false);
          onConfirm({externalLinkModal: true});
        }
      );
    } else {
      restFormData.projectId = data.projectId;

      OperationsApi.addExternalTool(restFormData).then(() => {
          setApiLoading(false);
          onConfirm({externalLinkModal: true});
        }, () => {
          setApiLoading(false);
          onConfirm({externalLinkModal: true});
        }
      );
    }
  }


  return (
    <>
      <Modal
        title={externalTool ? "Edit External Tool" : "Add External Tool"}
        open={data.visible}
        onCancel={() => onCancel()}
        centered
        width={600}
        onOk={handleSubmit(onFormSubmit)}
        okText="Save"
        okButtonProps={{loading: apiLoading}}
        cancelButtonProps={{type: "link"}}
      >
        <div className="modal-body-tools">

          <div className='outline-input-wrapper relative'>
            {logoUrl && <div className="external-link-logo-wrapper">
                <img src={logoUrl} width={15} height={15} alt="Logo"/>
            </div>}

            <div className='outline-input-label'>
              Link to your external tool
            </div>
            <Controller name='link'
                        control={control}
                        rules={{
                          onBlur: () => setLinkFocused(false),
                          required: "Link is required!",
                          validate: (value) => validateUrl(value)
                        }}
                        render={({field}) => <DebounceInput
                          placeholder="Paste link here"
                          className="outline-input"
                          style={{paddingLeft: logoUrl ? 30 : 10}}
                          debounceTimeout={600}
                          onFocus={() => setLinkFocused(true)} {...field}/>
                        }/>
            {
              externalLink?.length > 0 && !linkFocused &&
              <div onClick={() => setValue("link", "")}
                   data-testid='remove-link'
                   className='absolute pointer'
                   style={{right: 10, top: 32}}>
                <Tooltip placement='bottom' title="Remove Link">
                  <i className="bi bi-x-lg"/>
                </Tooltip>
              </div>
            }

            <div className="error-tools">
              <ErrorMessage errors={errors} name={'link'} render={({message}) =>
                <small className="form-error">
                  {message}
                </small>
              }/>
            </div>
          </div>


          <div className='outline-input-wrapper'>
            <div className='outline-input-label'>
              Title
            </div>
            <input
              placeholder="Enter a title for this research"
              className="outline-input"
              {...register('title', {required: "Title is required!"})}
            />
            <div className="error-tools">
              <ErrorMessage errors={errors} name={'title'} render={({message}) =>
                <small className="form-error">
                  {message}
                </small>
              }/>
            </div>
          </div>

          <div className='outline-input-wrapper relative'>
            <div className='outline-input-label'>
              Description
            </div>
            <input
              placeholder="Describe the resource linked"
              className="outline-input"
              maxLength={100}
              {...register('description')}
            />
            <div
              className="outline-input-character-count">{!!watch('description') ? watch('description').length : 0}/100
            </div>

          </div>

          <div className="modal-body-tools-row row">
            <div className='dropdown-input-wrapper'>
              <div className="dropdown-input-label">
                Type
              </div>
              <div className="dropdown-selected">
                <div className='selected-value'>{!!watch('type') ? watch('type') : "Select Type"}</div>
                <Controller name='type'
                            control={control}
                            rules={{required: "Type is required!"}}
                            render={({field}) =>
                              <Select
                                className="dropdown-input"
                                placeholder="Select type"
                                {...field}
                              >
                                <Select.Option key={LinkTypeEnum.SURVEY} value={LinkTypeEnum.SURVEY}>
                                  {LinkTypeEnum.SURVEY}
                                </Select.Option>
                                <Select.Option key={LinkTypeEnum.FOCUS_GROUP} value={LinkTypeEnum.FOCUS_GROUP}>
                                  {LinkTypeEnum.FOCUS_GROUP}
                                </Select.Option>
                                <Select.Option key={LinkTypeEnum.OBSERVATION} value={LinkTypeEnum.OBSERVATION}>
                                  {LinkTypeEnum.OBSERVATION}
                                </Select.Option>
                                <Select.Option key={LinkTypeEnum.OTHER} value={LinkTypeEnum.OTHER}>
                                  {LinkTypeEnum.OTHER}
                                </Select.Option>
                              </Select>
                            }/>
                <SvgArrowDown width={14}/>
              </div>
              <div className="error-tools">
                <ErrorMessage errors={errors} name={'type'} render={({message}) =>
                  <small className="form-error">
                    {message}
                  </small>
                }/>
              </div>
            </div>

            <div className='dropdown-input-wrapper'>
              <div className="dropdown-input-label">
                Status
              </div>
              <div className="dropdown-selected">
                <div className='selected-value'>{!!watch('status') ? watch('status') : "Select Status"}</div>
                <Controller name='status'
                            control={control}
                            rules={{required: "Status is required!"}}
                            render={({field}) =>
                              <Select
                                className="dropdown-input"
                                placeholder="Select status"
                                {...field}
                              >
                                <Select.Option key={LinkStatusEnum.QUEUED} value={LinkStatusEnum.QUEUED}>
                                  {LinkStatusEnum.QUEUED}
                                </Select.Option>
                                <Select.Option key={LinkStatusEnum.ACTIVE} value={LinkStatusEnum.ACTIVE}>
                                  {LinkStatusEnum.ACTIVE}
                                </Select.Option>
                                <Select.Option key={LinkStatusEnum.CLOSED} value={LinkStatusEnum.CLOSED}>
                                  {LinkStatusEnum.CLOSED}
                                </Select.Option>
                              </Select>
                            }/>
                <SvgArrowDown width={14}/>
              </div>
              <div className="error-tools">
                <ErrorMessage errors={errors} name={'status'} render={({message}) =>
                  <small className="form-error">
                    {message}
                  </small>
                }/>
              </div>
            </div>
          </div>
        </div>
        <Divider style={{marginBottom: -20,}}/>
      </Modal>
    </>
  );
}
