import React, { useState } from 'react';
import './read-knowledge-node.scss';
import { IKnowledgeNode } from "../../../../pages/knowledge/model/knowledge-node.interface";
import KnowledgeApi from "../../../../pages/knowledge/api/knowledge-api";
import SvgArrowDownNoFill from "../../../../assets/icons/js/ArrowDownNoFill";

export default function ReadKnowledgeNode(props: any): JSX.Element {
  const [node, setNode] = useState<IKnowledgeNode>(props.node);
  const [openSubNodes, setOpenSubNodes] = useState<boolean>(props.isParent ? true : !!props.node?.open);


  // function toggleSubNodes(): void {
  //   if (node.hasChild || node?.nodes?.length) {
  //     setOpenSubNodes(!openSubNodes);
  //   }
  //
  //   if (node.id && (node.hasChild || node?.nodes?.length) && !openSubNodes) {
  //     getNodeChildren();
  //   }
  // }
  //
  // function getNodeChildren(): void {
  //   if (props.isParent && props.mainNode) {
  //     KnowledgeApi.getNodeChildren(props.mainNode.id)
  //       .then((response: IKnowledgeNode) => {
  //         setNode(response);
  //       });
  //   }
  // }

  return (
    <div className="knowledge-sub-node-wrapper" style={{
      minWidth: props.isSubNode ? 134 : 164,
    }}>
      <div className='draw-arrow'>
        <SvgArrowDownNoFill/>
      </div>
      <div className="knowledge-sub-node"
           style={{
             backgroundColor: props.isSelected ? "rgb(27, 51, 128)" : "#eeeeee",
             color: props.isSelected ? "#fff" : "#545e6b"
           }}
      >
        <div
          className="title pointer"
          onClick={() => props.addRemoveNode(node)}>
          <span style={{paddingRight: props.editEnable ? 34 : 10}}
                className="knowledge-sub-node-name">{node.name}</span>
        </div>
      </div>
      {openSubNodes &&
          <div className="children-nodes"
               style={node.nodes.length ? {borderWidth: '1px'} : {borderWidth: '0'}}>
              <div className={props.isParent ? "children-nodes-parent" : ""}>
                {node.nodes && node.nodes.map((node: any, i: number) => {
                  return (
                    <ReadKnowledgeNode key={i}
                                       index={i}
                                       parentNode={props?.node}
                                       graphId={props.graphId}
                                       node={node}
                                       isSubNode={true}
                                       editEnable={props.editEnable}
                                       addRemoveNode={props.addRemoveNode}
                                       selectedNodes={props.selectedNodes}
                                       isSelected={props?.isNodeSelected(node.id)}
                    />
                  )
                })}
              </div>
          </div>
      }
    </div>
  );
}
