import React, { useState } from 'react';
import './repositories-archived.scss'
import SvgArrowBack from "../../../assets/icons/js/ArrowBack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import SvgRestoreTrash from "../../../assets/icons/js/RestoreTrash";
import { useQuery } from "react-query";
import { IRepoFile } from "../model/repo-file.interface";
import RepositoriesApi from "../api/repositories-api";
import SvgTick from "../../../assets/icons/js/Tick";
import Pagination from "../../../_shared/components/pagination/pagination";
import DaysLeftDatePipe from "../../../_shared/helpers/days-left-date-pipe";

const RepositoriesArchived = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page') || '1');
  const pageSize = parseInt(searchParams.get('pageSize') || '10');

  const repositoriesArchived: any = useQuery(['repositoriesArchived', page, pageSize], () => RepositoriesApi.getArchivedRepositories({
    page,
    pageSize
  }));

  const columns: ColumnsType<any> = [
    {
      title: selectedRows.length ?
        <div className='selected-rows-title-wrapper'>
          <span>{selectedRows.length} selected</span>
          <div data-testid={"bulk-restore"} className='selected-rows-btn' onClick={bulkRestoreRepositories}>
            <SvgRestoreTrash color={'#1b87e6'}/>
            &nbsp; Restore
          </div>
          {/*<div className="selected-rows-btn">*/}
          {/*  <SvgDelete color={"#1b87e6"}/>*/}
          {/*  Delete permanently*/}
          {/*</div>*/}
        </div> : "Name"
      ,
      dataIndex: 'name',
      //sorter: !selectedRows.length
    },
    // {
    //   title: <span className='column-title-to-be-hided'>File size</span>,
    //   dataIndex: 'size',
    //   //sorter: !selectedRows.length,
    //   width: '10%'
    // },
    // {
    //   title: <span className='column-title-to-be-hided'>Date Added</span>,
    //   dataIndex: 'createdAt',
    //   //sorter: !selectedRows.length,
    //   width: '10%',
    //   render: (value: any) => <span>{DaysLeftDatePipe(value)}</span>,
    // },
    {
      dataIndex: 'owner',
      title: <span className='column-title-to-be-hided'>Owner</span>,
      width: '20%',
      render: (value) => <span>{value?.firstName ? value.firstName + " " + value.lastName : value?.emailAddress}</span>
    },
    {
      dataIndex: 'repositoryFiles',
      title: <span className='column-title-to-be-hided'>Number of files</span>,
      width: '20%',
      render: (value) => <span>{value.length}</span>
    },
    {
      title: <span className='column-title-to-be-hided'>Archived On</span>,
      dataIndex: 'updatedAt',
      //sorter: !selectedRows.length,
      width: '25%',
      render: (value) => <>{value && DaysLeftDatePipe(value)}</>
    },
    {
      title: "",
      dataIndex: "id",
      key: "x",
      width: "5%",
      align: "right",
      render: (id: number, record: IRepoFile) => (
        <div className="action-hover-column row">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Restore repository">
              <button className="icon-button project-icon-button" onClick={() => restoreRepository(id)}>
                <SvgRestoreTrash/>
              </button>
            </Tooltip>
            {/*<Tooltip placement="bottom" title="Delete repository">*/}
            {/*  <button className="icon-button project-icon-button">*/}
            {/*    <SvgDelete/>*/}
            {/*  </button>*/}
            {/*</Tooltip>*/}
          </div>
        </div>
      )
    }
  ]

  function restoreRepository(repositoryId: number) {
    RepositoriesApi.restoreRepository(repositoryId).then(() => {
      message.success({
        content: 'Repository restored successfully',
        icon: <SvgTick color={"#227700"}/>,
        className: 'custom-success-message'
      });
      repositoriesArchived.refetch();
    })
  }

  function bulkRestoreRepositories() {
    RepositoriesApi.bulkRestoreRepositories(selectedRows.map((repository) => repository.id)).then(() => {
      message.success({
        content: 'Repositories restored successfully',
        icon: <SvgTick color={"#227700"}/>,
        className: 'custom-success-message'
      })
      repositoriesArchived.refetch();
      setSelectedRows([])
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setSearchParams(searchParams => {
      searchParams.set('page', page.toString());
      searchParams.set('pageSize', pageSize.toString());
      return searchParams;
    });
  }

  return (
    <div className='file-repository-archived-wrapper'>
      <div className='file-repository-archived-title row align-items-center' style={{marginBottom: 10}}>
        <button className='icon-button' onClick={() => navigate(-1)}>
          <SvgArrowBack/>
        </button>
        <h3>Deleted repositories</h3>
      </div>
      <div className='row justify-space-end' style={{marginBottom: 20}}>
        {repositoriesArchived.data && repositoriesArchived.data.pagination?.totalElements > 0 &&
          <Pagination totalItems={repositoriesArchived.data.pagination.totalElements}
                      currentPage={page}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}/>
        }
      </div>
      <div
        className={selectedRows.length ? 'file-repository-archived-table file-repository-archived-table-selected-rows' : 'file-repository-archived-table'}>
        <Table
          pagination={false}
          rowKey={"id"}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          loading={repositoriesArchived.isLoading}
          dataSource={repositoriesArchived?.data ? repositoriesArchived?.data.content : []}
          columns={columns}/>
      </div>
    </div>
  );
};

export default RepositoriesArchived;
