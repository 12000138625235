import React from 'react';

const SvgFixSpelling = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_39_7913)">
        <path
          d="M10.652 11L9.964 8.44H6.3L5.612 11H3.42L6.86 -0.0560007H9.468L12.892 11H10.652ZM6.716 6.84H9.532L8.124 1.56L6.716 6.84Z"
          fill="#545E6B"/>
        <rect x="3" y="13" width="2" height="2" fill="#545E6B"/>
        <rect x="7" y="13" width="2" height="2" fill="#545E6B"/>
        <rect x="11" y="13" width="2" height="2" fill="#545E6B"/>
      </g>
      <defs>
        <clipPath id="clip0_39_7913">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgFixSpelling;
