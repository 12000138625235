import { Progress } from "antd";
import React, { useState } from "react";
import "./uploader-popup.scss";
import UploaderFile from "./uploader-file/uploader-file";
import SvgArrowDown from "../../../assets/icons/js/ArrowDown";
import SvgArrowUp from "../../../assets/icons/js/ArrowUp";
import SvgCancel from "../../../assets/icons/js/Cancel";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog";

const UploaderPopUp = (props: any): JSX.Element => {
  const [tabIsOpen, setTabIsOpen] = useState(true);
  const [cancelUploadDialog, setCancelUploadDialog] = useState({visible: false});

  const confirmationDialogProps = {
    title: "Cancel Upload",
    okText: "Continue Upload",
    titleSec: "Your upload is not complete",
    description:
      "Are you sure you want to cancel the upload of all files?",
    visible: cancelUploadDialog.visible,
    cancelText: "Cancel",
  };

  const toggleTab = (): void => {
    setTabIsOpen((prev) => !prev);
  };

  const handleXClick = (): void => {
    if (props.fileListData.filter((file: any) => file.status === "uploading").length === 0) {
      props.uploadFinished();
    } else {
      openModal();
    }
  }

  const openModal = (): void => {
    setCancelUploadDialog({visible: true})
  }

  const closeModal = (): void => {
    setCancelUploadDialog({visible: false})
  };

  const cancelUpload = (args: any) => {
    props.fileListData.filter((file: any) => file.status === "uploading").forEach((file: any) => props.cancelFile(file));
    props.uploadFinished();
  }

  return (
    <div className="uploader-wrapper">
      <div className="uploader-header-wrapper">
        <div className="uploader-header-content">
          <div className="uploader-header-title">
            {props.fileListData.length === 1
              ? `Uploading 1 file to ${props.name}`
              : props.fileListData.length > 1
                ? `Uploading ${props.fileListData.length} files to ${props.name}`
                : 'Uploading'}
          </div>
          <div className="uploader-header-icons">
            <div className="uploader-header-icon-wrapper" data-testid={"toggle-tab-button"} onClick={toggleTab}>
              {tabIsOpen ? <SvgArrowDown/> : <SvgArrowUp/>}
            </div>
            <div
              onClick={handleXClick}
              className="uploader-header-icon-wrapper"
              data-testid={"close-uploader-button"}
            >
              <SvgCancel/>
            </div>
          </div>
        </div>
        <div className="uploader-header-progress-bar">
          <Progress
            type="line"
            percent={props.percent}
            showInfo={false}
            status="active"
            strokeWidth={4}
          />
        </div>
      </div>
      <div
        className="uploader-main-content"
        data-testid={"uploader-main-content"}
        style={{maxHeight: tabIsOpen ? 202 : 0}}
      >
        <div className="uploader-files-wrapper">
          {props.fileListData.map((file: any, i: number) => (
            <UploaderFile file={file} key={i} cancelFile={props.cancelFile}/>
          ))}
        </div>
      </div>
      {
        cancelUploadDialog.visible &&
          <ConfirmationDialog
              data={confirmationDialogProps}
              onCancel={cancelUpload}
              onConfirm={closeModal}
          />
      }
    </div>
  );
};

export default UploaderPopUp;
