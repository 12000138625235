import React, { useState } from 'react';
import { Button, Table, TableColumnsType } from "antd";
import ExternalImportDialog from "./external-import-dialog/external-import-dialog";
import questionProImportsApi from "../api/question-pro-imports.api";
import { useQuery } from "react-query";
import { ColumnsType } from "antd/es/table";
import { IImportJobDetail, IImportJobs } from "../../../models/import-jobs.interface";
import { format } from "date-fns";


const ExternalImport = () => {
  const columns: ColumnsType<IImportJobs> = [
    {title: "ID", dataIndex: 'id', key: "id", width: "5%"},
    {title: "Name", dataIndex: 'name', key: "name"},
    {title: "Status", dataIndex: 'status', key: "status"},
    {title: "Surveys", dataIndex: 'surveyImportedNumber', key: "surveyImportedNumber"},
    {title: "Base URL", dataIndex: 'baseUrl', key: "baseUrl"},
    {title: "API Key", dataIndex: 'apiKey', key: "apiKey"},
    {title: "Organization ID", dataIndex: 'externalOrgId', key: 'externalOrgId'},
    {title: "Users", dataIndex: 'users', key: 'users'},
    {
      title: "Started At",
      dataIndex: 'startTime',
      key: 'startTime',
      render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
    },
    {
      title: "Ended At",
      dataIndex: 'endTime',
      key: 'endTime',
      render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
    },
    {title: "Duration", dataIndex: "duration", key: "duration"}
  ]

  const expandedRowRender = (record: IImportJobs) => {
    const columns: TableColumnsType<IImportJobDetail> = [
      {title: 'ID', dataIndex: 'id', key: 'id', width: "5%"},
      {title: 'Program name', dataIndex: 'programName', key: 'programName'},
      {title: "User ID", dataIndex: 'userId', key: 'userId'},
      {title: 'Status', key: 'status', dataIndex: 'status'},
      {title: "Surveys", dataIndex: 'importedSurveys', key: "importedSurveys"},
      {
        title: "Started at",
        dataIndex: 'startTime',
        key: 'startTime',
        render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
      },
      {
        title: "Ended at",
        dataIndex: 'endTime',
        key: 'endTime',
        render: (value) => format(new Date(value), 'MMM d, yyyy  - hh:mm a')
      },
      {title: "Duration", dataIndex: "duration", key: "duration"}
    ];

    return <Table scroll={{y: 350}} columns={columns} dataSource={record.details} pagination={false}/>;
  };

  const [externalImportDialog, setExternalImportDialog] = useState({open: false});

  const importJobs = useQuery('importJobsExternal', () => questionProImportsApi.getImportJobs('EXTERNAL'))

  const openExternalImportDialog = () => {
    setExternalImportDialog({open: true})
  }

  const closeExternalImportDialog = (data?: { refetch: boolean }) => {
    if (data?.refetch) {
      importJobs.refetch();
    }
    setExternalImportDialog({open: false})
  }

  return (
    <div>
      <div className="page column" style={{gap: 20, marginBottom: 30}}>
        <Button onClick={openExternalImportDialog} type="primary" style={{width: "fit-content"}}>
          Add external import
        </Button>
        <div>
          <Table
            columns={columns} dataSource={importJobs.data}
            loading={importJobs.isLoading}
            pagination={false}
            rowKey={"id"}
            expandable={{expandedRowRender}}
          />
        </div>
      </div>
      {
        externalImportDialog.open &&
          <ExternalImportDialog data={externalImportDialog} onCancel={closeExternalImportDialog}/>
      }
    </div>
  );
};

export default ExternalImport;
