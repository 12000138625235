import React, { useContext, useEffect, useRef } from 'react';
import { Controller, useForm, useWatch } from "react-hook-form";
import { Checkbox } from "antd";
import GlobalContext from "../../../store/global-context";
import SettingsApi from "../../../pages/settings/api/settings-api";
import SvgNugget from "../../../assets/icons/js/nugget";
import Switch from "../switch/switch";
import SvgLock from "../../../assets/icons/js/lock";
import './mask-pii-form.scss'

const MaskPIIForm = ({label = "Mask Personally Identifiable Information (PII) from my data in InsightsHub"}: {
  label?: string
}) => {
  const globalContext = useContext(GlobalContext);

  const form = useForm({
    defaultValues: {
      maskPII: globalContext.user.organization.organizationSettings.maskPiiData
    }
  });
  const maskPII = useWatch({name: 'maskPII', control: form.control})

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    SettingsApi.maskPIIData(maskPII);
  }, [maskPII]);

  return (
    <div className="mask-pii-form outline-input-wrapper fxFlex">
      <div className="row justify-space-between align-items-center">
        <div className="column">
          <div className="row justify-center" style={{gap: 8, padding: "12px 0"}}>
            <SvgLock width={16} height={16}/>
            <h4 style={{marginBottom: 0}}>Data protection</h4>
          </div>
          <h5 style={{marginBottom: 0}}>Mask Personally Identifiable Information (PII) from my data in InsightsHub.</h5>

        </div>
        <Controller control={form.control} name='maskPII'
                    render={({field}) => <Switch
                      name='maskPII'
                      checked={field.value}
                      onChange={(checked: boolean) => field.onChange(checked)}
                    />
                    }
        />
      </div>
    </div>
  );
};

export default MaskPIIForm;
