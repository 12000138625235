import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

const removeBlockByKey = (editorState: EditorState, blockKey: string) => {
  const rawContent = convertToRaw(editorState.getCurrentContent());

  const blockIndex = rawContent.blocks.findIndex((block: any) => block.key === blockKey);
  if (blockIndex === -1) return;

  const rawContentWithoutBlock = {
    ...rawContent,
    blocks: rawContent.blocks.filter((block: any) => block.key !== blockKey)
  }

  return EditorState.createWithContent(convertFromRaw(rawContentWithoutBlock));
};

export { removeBlockByKey };