import { DraftDecoratorComponentProps } from "draft-js";
import React from "react";

type RecommendationStrategyProps = DraftDecoratorComponentProps & {}

export const RecommendationStrategy = (props: RecommendationStrategyProps) => {
  return <span id={props.entityKey} className="story-recommendation" data-testid="recommendation-element"
               style={{backgroundColor: "rgba(74,117,208,0.2)", padding: "2px 4px"}}>
      {props.children}
    </span>
}
