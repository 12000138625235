import React, { useContext } from 'react'
import { SnapshotTemplateEnum } from '../../../model/snapshot.interface'
import "./second-template-snapshot.scss"
import GlobalContext from '../../../../../store/global-context';
import SvgNugget from '../../../../../assets/icons/js/nugget';
import SvgTheme from '../../../../../assets/icons/js/theme';
import SvgQuestionProLogo from '../../../../../assets/icons/js/questionProLogo';
import TextFit from "../../../../../_shared/components/text-fit/text-fit";

const SecondTemplateSnapshot = (props: any) => {
  const globalContext = useContext(GlobalContext);

  const onClick = () => {
    if (props?.forSelectTemplate) {
      props.onTemplateClick(SnapshotTemplateEnum.SECOND)
    }
  }

  let scale = 1;
  let transformOrigin = '';

  if (props?.mdSize) {
    scale = 0.36;
    transformOrigin = 'top left';
  }

  if (props?.smSize) {
    scale = 0.23;
    transformOrigin = 'top left';
  }

  if (props?.forSelectTemplate) {
    scale = 0.173;
    transformOrigin = 'top left';
  }

  return (
    <div className="second-template-snapshot-wrapper" data-testid={"second-template-snapshot"}
         onClick={onClick}
         style={{scale: `${scale}`, transformOrigin}}>
      <div className="second-template-background-color"
           style={{backgroundColor: props?.selectedImage?.url?.length > 0 ? "rgba(27, 51, 128, 0.8)" : " rgba(27, 51, 128, 1)"}}/>
      {
        props?.selectedImage?.url?.length > 0 &&
        <div className='second-template-selected-image'>
          <img src={props.selectedImage.url} alt="selected-image"/>
        </div>
      }
      <div className="second-template-snapshot-main-content">
        <SvgNugget width={48} height={48} color="#E3D058"/>
        <div className="second-template-nugget-description">
          <TextFit mode="multi" min={16} max={56} style={{height: 200, lineHeight: '150%'}}>
            {props?.nugget}
          </TextFit>
        </div>
      </div>

      <div className="second-template-project">
        <div className="second-template-project-logo align-items-start row">
          <SvgTheme color="#8DC3F2" width={24} height={24}/>
        </div>
        <div className='column' style={{gap: 5}}>
          <div>{props?.project}</div>
          <div style={{marginTop: 2, fontWeight: 600}}>{globalContext.user?.organization.name}
          </div>
        </div>

      </div>
      <div className='second-template-question-pro-logo'>
        <SvgQuestionProLogo color="#fff" width={126} height={24}/>
      </div>
    </div>
  )
}

export default SecondTemplateSnapshot
