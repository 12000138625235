import React from "react";
import "./portal.scss";
import DeskSettingsApi from "../../../api/settings-api";
import { Loader } from "../../../../../_shared/components/loader/loader";
import { useQuery } from "react-query";
import BackgroundSettings from "./background-settings/background-settings";
import WelcomeMessage from "./welcome-message/welcome-message";
import PortalSettings from "./portal-settings/portal-settings";
import { IPortalSettingsProps } from "./portal-settings/model/portal-settings-props.interface";
import { IWelcomeMessageProps } from "./welcome-message/model/welcome-message-props.interface";
import { IBackgroundSettingsProps } from "./background-settings/model/background-settings-props.interface";
import { ExternalOauth } from './external-oauth/external-oauth';


export function Portal(): JSX.Element {
  const organisationSettings: any = useQuery(
    "organisationSettings",
    DeskSettingsApi.getOrganisationSettings
  );

  const welcomeMessageProps: IWelcomeMessageProps = {
    welcomeMessage: organisationSettings.data?.welcomeMessage,
    description: organisationSettings.data?.description,
    logoUrl: organisationSettings.data?.logoUrl
  };

  const portalSettingsProps: IPortalSettingsProps = {
    publicUrl: organisationSettings.data?.publicUrl,
    ssoProvider: organisationSettings.data?.ssoProvider,
    authorizedEmailDomains: organisationSettings.data?.authorizedEmailDomains,
  };

  const backgroundSettingsProps: IBackgroundSettingsProps = {
    backgroundImage: organisationSettings.data?.backgroundImage,
    loginImage: organisationSettings.data?.loginImage,
    welcomeMessage: organisationSettings.data?.welcomeMessage,
    description: organisationSettings.data?.description,
    logoUrl: organisationSettings.data?.logoUrl
  };

  return (
    <div className="portal-wrapper">
      {organisationSettings.isLoading ? (
        <Loader/>
      ) : (
        <div className="portal">
          <div className="portal-config column w-100">
            <WelcomeMessage {...welcomeMessageProps} />
            <PortalSettings {...portalSettingsProps} />
            <ExternalOauth {...portalSettingsProps}/>
          </div>
          <div className="w-100">
            <BackgroundSettings {...backgroundSettingsProps} />
          </div>
        </div>
      )}
    </div>
  );
}
