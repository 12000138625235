import { environment } from '../../../../../../../environment';
import { axiosClient } from '../../../../../../../axios-client';
import { AxiosResponse } from 'axios';
import { IExcelImport } from "../model/excel-import.interface";
import { IImportContent } from "../model/import-content.interface";
import { IImportColumns } from "../model/import-columns.interface";

class ExcelImportApi {
  baseUrl: string = `${environment.backendUrl}/integrations/excel-import`;

  async get(): Promise<IExcelImport[]> {
    try {
      const response: AxiosResponse<IExcelImport[]> =
        await axiosClient.get<IExcelImport[]>(`${this.baseUrl}/imports`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async delete(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(
        `${this.baseUrl}/imports/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getImportById(id: string): Promise<IImportContent> {
    try {
      const response: AxiosResponse<IImportContent> =
        await axiosClient.get<IImportContent>(`${this.baseUrl}/items/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateImportItem(id: string, payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any[]> =
        await axiosClient.put<any[]>(`${this.baseUrl}/items/${id}`, {
          ...payload
        });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getImportColumns(id: string): Promise<IImportColumns> {
    try {
      const response: AxiosResponse<IImportColumns> =
        await axiosClient.get<IImportColumns>(`${this.baseUrl}/columns/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async publishImport(id: string): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.post<any>(`${this.baseUrl}/imports/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async utils(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${environment.backendUrl}/integrations/google-drive/imports/utils`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async downloadExcelTemplate(programId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        `${this.baseUrl}/template/${programId}`,
        {responseType: 'arraybuffer'}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ExcelImportApi();
