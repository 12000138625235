import { convertFromRaw, EditorState } from 'draft-js';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import "./preview-story.scss"
import PreviewStoryEditor from './preview-story-editor/preview-story-editor';
import SvgTheme from '../../../assets/icons/js/theme';
import { Loader } from '../../../_shared/components/loader/loader';
import storiesApi from '../api/stories-api';
import { IStory } from '../model/story-interface';
import {
  RetrieveHighlightsFromStoryEditor
} from '../story-edit/story-editor/helpers/retrieve-highlights-from-story-editor';
import SvgNoImage from "../../../assets/icons/js/noImage";
import { getDisplayHighlights } from "../story-edit/story-editor/helpers/get-display-highlights";
import PreviewRecommendationHighlightsContainer
  from "./preview-recommendation-highlights-container/preview-recommendation-highlights-container";
import { manageHighlights } from "../story-edit/story-editor/helpers/manage-highlights";
import LinkedProjects from "../story-edit/linked-projects/linked-projects";

const PreviewStory = () => {
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);
  const [story, setStory] = useState<IStory>()
  const [linkedProjects, setLinkedProjects] = useState<any[]>();
  const [editorState, setEditorState] = useState<EditorState>();
  const [displayTextHighlights, setDisplayTextHighlights] = useState();
  const [loadedImages, setLoadedImages] = useState(false);

  useEffect(() => {
    if (!editorState) {
      return
    }

    manageHighlights(editorState)
    setDisplayTextHighlights(getDisplayHighlights(editorState))

  }, [loadedImages, editorState]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  useEffect(() => {
    loadPage();
  }, [])

  useEffect(() => {
    if (story?.content) {
      const highlights = RetrieveHighlightsFromStoryEditor(EditorState.createWithContent(convertFromRaw(JSON.parse(story.content))));
      setLinkedProjects(highlights.projects);
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(story?.content))))
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [story])

  const updateLoadedImages = (lI: boolean) => {
    setLoadedImages(lI)
  }


  const loadPage = () => {
    if (!urlParam.id) {
      window.location.replace(`/404`);
      return;
    }
    storiesApi.getStory(urlParam.id).then((response) => {
      setStory(response);
    })
  }

  return (
    <div className='row preview-story-wrapper'>
      <div className='preview-story-side-bar'>
        <SvgTheme color={"#1B3380"} width={60} height={60}/>
      </div>
      <div className='preview-story-content column'>
        <div className='preview-story-container row' style={{
          gap: 80
        }}>
          {
            !story ? <Loader/> :
              <>
                <div className='column'>
                  {
                    story?.cover ?
                      <div data-testid={"preview-story-cover"} className='preview-story-cover'
                           style={{backgroundImage: `url(${story.cover})`}}/>
                      : <div className='preview-story-no-cover'>
                        <SvgNoImage width={80} height={64}/>
                      </div>
                  }
                  {
                    editorState &&
                      <div className="row">
                          <PreviewStoryEditor
                              editorState={editorState}
                              setEditorState={setEditorState}
                              story={story}
                              updateLoadedImages={updateLoadedImages}/>
                        {
                          displayTextHighlights &&
                          <PreviewRecommendationHighlightsContainer
                            editorState={editorState} displayTextHighlights={displayTextHighlights}
                          />
                        }

                      </div>
                  }
                </div>
                <LinkedProjects
                  nonTextLinkedProjects={story.nonTextLinkedProjects}
                  tags={story.tags}
                  linkedProjects={linkedProjects as any[]}
                  onProjectAssign={() => {
                  }}
                  onTagsChange={() => {
                  }}
                  forPreview={true}
                  readOnly={true}/>
              </>
          }
        </div>
      </div>
      <div className='preview-story-footer'>
        Powered By &nbsp; <a href="https://www.questionpro.com">QuestionPro</a>
      </div>
    </div>
  )
}

export default PreviewStory
