import React, { useEffect, useState } from 'react';
import SvgKnowledgeGraph from '../../../../../assets/icons/js/knowledge-graph';
import './highlight-toolbar.scss';
import SvgNugget from '../../../../../assets/icons/js/nugget';
import SvgFlag from '../../../../../assets/icons/js/Flag';
import { HighlightToolbarItem } from './highlight-toolbar-item';
import { RetrieveHighlightsForToolbar } from '../helpers/retrieve-highlights-for-toolbar';

export function HighlightToolbar(props: any): JSX.Element {
  const {editorState} = props;
  const [displayNodes, setDisplayNodes] = useState<{
    nodes: { id: number, name: string, length: number }[],
    length: number
  }>();
  const [displayNuggets, setDisplayNuggets] = useState<{ nuggets: any[], length: number }>();
  const [displayFlags, setDisplayFlags] = useState<{ flags: any[], length: number }>();

  useEffect(() => {
    const highlights = RetrieveHighlightsForToolbar(editorState);
    if (highlights.flags) {
      const flags: any[] = [];
      let length: number = 0;

      highlights.flags.forEach((selectedFlagsArray: any) => {
        selectedFlagsArray?.flags.forEach((flag: any) => {
          if (flag) {
            length = length + 1;
            flag['length'] = 1;

            const findNode = flags.findIndex((f: any) => f.id === flag.id);
            if (findNode === 0 || findNode > 0) {
              flags[findNode].length = flags[findNode].length + 1;
            } else {
              flags.push(flag);
            }
          }
        });
      });

      setDisplayFlags({flags: flags, length});
    }

    if (highlights.nodes) {
      const nodes: { id: number, name: string, length: number }[] = [];
      let length: number = 0;

      highlights.nodes.forEach((selectedNodesArray: any) => {
        selectedNodesArray?.nodes.forEach((node: any) => {
          if (node) {
            length = length + 1;
            node['length'] = 1;

            const findNode = nodes.findIndex((n: any) => n.id === node.id);
            if (findNode === 0 || findNode > 0) {
              nodes[findNode].length = nodes[findNode].length + 1;
            } else {
              nodes.push(node);
            }
          }
        });
      });

      setDisplayNodes({nodes, length});
    }

    if (highlights.nuggets) {
      const nuggets: any[] = [];
      let length: number = 0;

      highlights.nuggets.forEach((selectedNuggetsArray: any) => {
        selectedNuggetsArray?.nuggets.forEach((nugget: any) => {
          if (nugget) {
            length = length + 1;
            nugget['length'] = 1;

            const findNode = nuggets.findIndex((f: any) => f.id === nugget.id);
            if (findNode === 0 || findNode > 0) {
              nuggets[findNode].length = nuggets[findNode].length + 1;
            } else {
              nuggets.push(nugget);
            }
          }
        });
      });

      setDisplayNuggets({nuggets, length});
    }
  }, [props?.editorState]);

  return (
    <>
      <div className="highlight-toolbar" style={{right: props.noteType ? 0 : '-30%'}}>
        <HighlightToolbarItem key="0" icon={<SvgKnowledgeGraph/>} nodes={displayNodes}/>
        <HighlightToolbarItem key="1" icon={<SvgNugget/>} nuggets={displayNuggets}/>
        <HighlightToolbarItem key="2" icon={<SvgFlag color="#545e6b"/>} flags={displayFlags}/>
      </div>
    </>
  );
}
