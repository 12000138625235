import { Dropdown, Empty, Menu, Select, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import "./snapshots.scss"
import { ISnapshot, } from './model/snapshot.interface';
import Snapshot from './snapshot-edit/snapshot/snapshot';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import snapshotsApi from './api/snapshots-api';
import qs from "query-string"
import { Loader } from '../../_shared/components/loader/loader';
import SvgArrowDown from '../../assets/icons/js/ArrowDown';
import SvgSearch from '../../assets/icons/js/Search';
import SvgEdit from '../../assets/icons/js/Edit';
import { daysLeftDateFormatter } from '../../_shared/helpers/days-left-date-formatter';
import SvgTrash from '../../assets/icons/js/Trash';
import PopUpTutorial from "../../_shared/components/pop-up-tutorial/pop-up-tutorial";
import { usePopUpTutorial } from "../../core/hooks/use-pop-up-tutorial";

const Snapshots = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {popUpTutorial, onPopUpClose} = usePopUpTutorial('snapshots');
  const urlParam: any = qs.parse(location.search);
  const snapshotComp: any = useRef([]);

  const [showNoSnapshot, setShowNoSnapshot] = useState<boolean>(false);
  const projects = useQuery("snapshotProjects", () => snapshotsApi.getProjects());
  const [projectId, setProjectId] = useState<number>();
  const nuggets = useQuery(["snapshotNuggets", projectId], () => snapshotsApi.getNuggets(projectId))
  const [nuggetId, setNuggetId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const snapshots = useQuery(["snapshots", projectId, nuggetId], () => snapshotsApi.getSnapshots(projectId, nuggetId));

  useEffect(() => {
    if (snapshots.isLoading) {
      // setLoading(true);
      return;
    }

    if (snapshots?.data && snapshots.data?.length >= 1) {
      setLoading(false);
      setShowNoSnapshot(false);
    }

    if (!projectId && !nuggetId && (!snapshots.data || snapshots.data?.length === 0)) {
      setLoading(false);
      setShowNoSnapshot(true);
    }

  }, [snapshots, projectId, nuggetId]);

  const [filteredProjects, setFilteredProjects] = useState<any[]>();
  const [filteredNuggets, setFilteredNuggets] = useState<any[]>();
  const [nuggetsSearchValue, setNuggetsSearchValue] = useState("");
  const [projectsSearchValue, setProjectsSearchValue] = useState("");

  useEffect(() => {
    const project = urlParam?.project;
    const nugget = urlParam?.nugget;

    if (project) {
      setProjectId(parseInt(project))
    }
    if (nugget) {
      setNuggetId(parseInt(nugget))
    }
  }, []);

  useEffect(() => {
    let params = {
      project: projectId,
      nugget: nuggetId
    }

    navigate({
      pathname: '',
      search: qs.stringify(params)
    }, {replace: true});
  }, [projectId, nuggetId, snapshots?.data])

  useEffect(() => {
    setFilteredProjects(projects?.data)
  }, [projects?.data])

  useEffect(() => {
    setFilteredNuggets(nuggets?.data);
    setNuggetsSearchValue("");
  }, [nuggets?.data])

  const downloadSnapshot = (i: number) => {
    snapshotComp.current[i].download();
  }

  const deleteSnapshot = (id: number | undefined) => {
    if (id) {
      snapshotsApi.deleteSnapshot(id).then((response: any) => {
        snapshots.refetch();
      })
    }
  }

  const handleSearchNuggets = (e: any) => {
    setNuggetsSearchValue(e.target.value);
    if (e.target.value.length) {
      setFilteredNuggets(nuggets?.data?.filter((value: { id: number, description: string }) => {
        return value.description.toLowerCase().includes(e.target.value.toLowerCase())
      }))
    } else {
      setFilteredNuggets(nuggets?.data)
    }
  }

  const handleSearchProjects = (e: any) => {
    setProjectsSearchValue(e.target.value)
    if (e.target.value.length) {
      setFilteredProjects(projects?.data?.filter((value: { id: number, name: string }) => {
        return value.name.toLowerCase().includes(e.target.value.toLowerCase())
      }))
    } else {
      setFilteredProjects(projects?.data);
    }
  }

  const handleNuggetChange = (value: number) => {
    setNuggetId(value)
    if (!projectId) {
      const projectId = nuggets?.data?.find((nugget: any) => nugget.id == value)?.projectId
      if (projectId) {
        if (projects?.data?.findIndex((project) => project.id === projectId) !== -1) {
          setProjectId(projectId)
        }
      }
    }
  }

  const handleProjectChange = (value: number) => {
    setProjectId(value)
    setNuggetId(undefined)
  }

  const renderSnapshotTile = (snapshot: ISnapshot, i: number) => {
    const {diff, value} = daysLeftDateFormatter(snapshot.createdAt);

    return (
      <div className='snapshot-wrapper' key={i}>
        <div data-testid="snapshot-wrapper" className="snapshot-template-wrapper pointer" onClick={() => {
          navigate("/snapshots/edit?id=" + snapshot.id)
        }}>
          <Snapshot
            ref={el => snapshotComp.current[i] = el}
            html={snapshot?.htmlContent}
            selectedImage={{url: snapshot.imageUrl, name: snapshot.imageText}}
            project={snapshot?.project}
            nugget={snapshot?.nugget}
            template={snapshot?.template}
            snapshotTheme={snapshot?.theme ? snapshot.theme : undefined}
            createdBy={snapshot?.createdBy}
            createdAt={snapshot?.createdAt}
            mdSize={true}/>
        </div>
        <div className="snapshot-info-wrapper">
          <div className='created-info'>
            <div>
              {snapshot?.createdBy?.displayName}
            </div>
            <div className='created-ago'>
              {parseInt(diff) > 1 && diff} {value}
            </div>
          </div>
          <Dropdown trigger={["click"]} overlay={
            <Menu>
              <Menu.Item key={"edit"} onClick={() => navigate("/snapshots/edit?id=" + snapshot.id)}>
                <div className=" snapshot-icon-button d-flex align-items-center">
                  <SvgEdit/> Edit
                </div>
              </Menu.Item>
              <Menu.Item key={"download"} onClick={() => downloadSnapshot(i)}>
                <div data-testid={"download-button"} className="snapshot-icon-button d-flex align-items-center">
                  <i className="bi bi-download" style={{fontSize: 16}}/> Download
                </div>
              </Menu.Item>
              <Menu.Item key={"delete"} onClick={() => deleteSnapshot(snapshot.id)}>
                <div className=" snapshot-icon-button d-flex align-items-center">
                  <SvgTrash/> Delete
                </div>
              </Menu.Item>
            </Menu>
          }>
            <div className="pointer" data-testid={"more-options"}>
              <i className="bi bi-three-dots" style={{fontSize: 18}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  return (
    <>
      {loading ?
        <Loader/>
        :
        <div style={{width: "100%",}}>
          {
            showNoSnapshot ?
              <div className='snapshots-landing-page-wrapper' data-testid={"snapshots-landing-page"}>
                <h1>Snapshots</h1>
                <h2>Democratize your insights and empower your team</h2>
                <h3>Turn your key findings into social media content to share</h3>
                <div className="snapshots-selects-wrapper" style={{marginBottom: 20}}>
                  <Select
                    value={projectId}
                    onChange={handleProjectChange}
                    suffixIcon={<SvgArrowDown/>}
                    aria-label={"Select project"}
                    className="dropdown-input"
                    placeholder="All projects"
                    defaultValue={undefined}
                    dropdownRender={(menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
                      return <>
                        <div className='dropdown-search-wrapper relative'>
                          <input
                            type="text"
                            className='dropdown-search'
                            placeholder='Search projects'
                            onChange={handleSearchProjects}
                            value={projectsSearchValue}
                          />
                          <div className='absolute' style={{top: 8, right: 10}}><SvgSearch width={14} height={14}/>
                          </div>
                        </div>
                        {menu}
                      </>
                    }}>
                    <Select.Option key={undefined} value={undefined}>All projects</Select.Option>
                    {
                      !projects?.isLoading &&
                      filteredProjects?.map((project: any, i: number) => {
                        return <Select.Option key={project?.id} value={project?.id}>{project?.name}</Select.Option>
                      })
                    }
                  </Select>
                  <Select
                    value={nuggetId}
                    className="dropdown-input"
                    placeholder="All key findings"
                    onChange={handleNuggetChange}
                    suffixIcon={<SvgArrowDown/>}
                    defaultValue={undefined}
                    dropdownRender={(menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
                      return <>
                        <div className='dropdown-search-wrapper relative'>
                          <input
                            type="text"
                            className='dropdown-search'
                            placeholder='Search nuggets'
                            onChange={handleSearchNuggets}
                            value={nuggetsSearchValue}
                          />
                          <div className='absolute' style={{top: 8, right: 10}}><SvgSearch width={14} height={14}/>
                          </div>
                        </div>
                        {menu}
                      </>
                    }}
                  >
                    <Select.Option key={undefined} value={undefined}>All key findings</Select.Option>
                    {
                      !nuggets?.isLoading &&
                      filteredNuggets?.map((nugget: any, i: number) => {
                        return <Select.Option key={nugget?.id} value={nugget?.id}>{nugget?.description}</Select.Option>
                      })
                    }
                  </Select>
                </div>

                <button disabled={!nuggetId}
                        onClick={() => navigate("/snapshots/edit?nuggetId=" + nuggetId)}
                        className={nuggetId ? "icon-button new-snapshot-button" : "icon-button new-snapshot-button disabled"}>
                  <span className="plus-icon"/>
                  <span>New snapshot</span>
                </button>
              </div>
              :
              <div className='snapshots-wrapper'>
                <h2>Snapshots</h2>
                <h3>Turn your key findings into social media content to share</h3>
                <div className='snapshots-selects-wrapper'>
                  <Select
                    value={projectId}
                    onChange={handleProjectChange}
                    suffixIcon={<SvgArrowDown/>}
                    className="dropdown-input"
                    placeholder="All projects"
                    aria-label={"Select project"}
                    defaultValue={undefined}
                    dropdownRender={(menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
                      return <>
                        <div className='dropdown-search-wrapper relative'>
                          <input
                            type="text"
                            className='dropdown-search'
                            placeholder='Search'
                            onChange={handleSearchProjects}
                            value={projectsSearchValue}
                          />
                          <div className='absolute' style={{top: 8, right: 10}}><SvgSearch width={14} height={14}/>
                          </div>
                        </div>
                        {menu}
                      </>
                    }}
                  >
                    <Select.Option key={undefined} value={undefined}>All projects</Select.Option>
                    {
                      !projects?.isLoading &&
                      filteredProjects?.map((project: any, i: number) => {
                        return <Select.Option key={project?.id} value={project?.id}>{project?.name}</Select.Option>
                      })
                    }
                  </Select>
                  <Select
                    value={nuggetId}
                    className="dropdown-input"
                    placeholder="All key findings"
                    onChange={handleNuggetChange}
                    suffixIcon={<SvgArrowDown/>}
                    defaultValue={undefined}
                    aria-label={"Select key finding"}
                    dropdownRender={(menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
                      return <>
                        <div className='dropdown-search-wrapper relative'>
                          <input
                            type="text"
                            className='dropdown-search'
                            placeholder='Search'
                            onChange={handleSearchNuggets}
                            value={nuggetsSearchValue}
                          />
                          <div className='absolute' style={{top: 8, right: 10}}><SvgSearch width={14} height={14}/>
                          </div>
                        </div>
                        {menu}
                      </>
                    }}>
                    <Select.Option key={undefined} value={undefined}>All key findings</Select.Option>
                    {
                      !nuggets?.isLoading &&
                      filteredNuggets?.map((nugget: any, i: number) => {
                        return <Select.Option key={nugget?.id} value={nugget?.id}>{nugget?.description}</Select.Option>
                      })
                    }
                  </Select>

                  <button onClick={() => navigate("/snapshots/edit?nuggetId=" + nuggetId)}
                          disabled={!nuggetId}
                          className={nuggetId ? "icon-button new-snapshot-button" : "icon-button new-snapshot-button disabled"}>
                    <span className="plus-icon"/>
                    <span>New snapshot</span>
                  </button>
                </div>
                <div className='snapshots-library-wrapper'>
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={
                      <div className='snapshots-tab'>
                        <span className='snapshot-tab-name'>Snapshots</span>
                        <span className='snapshot-tab-length'>{snapshots?.data?.length}</span>
                      </div>} key="1">
                      <div className="snapshots-library">
                        {snapshots?.isLoading ? <Loader/> :
                          snapshots?.data?.length === 0 ?
                            <div className="column justify-space-center w-100">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                            </div> :
                            snapshots?.data?.map((snapshot: ISnapshot, i: number) => {
                              return renderSnapshotTile(snapshot, i);
                            })
                        }
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </div>
          }
          {
            popUpTutorial.open &&
            <PopUpTutorial open={popUpTutorial.open} onPopUpClose={onPopUpClose} title='Snapshots'>
              <>
                <p>Snapshots give you a super-fast way to create an attractive “card” featuring one of your Key
                  Findings.</p>
                <p>Share them on social media, on your internal Slack, or directly with other members of your
                  organization. Each
                  Snapshot displays the name of the associated project, so viewers can visit InsightsHub for
                  more detail
                  and
                  backstory about the featured Key Finding.</p>
              </>
            </PopUpTutorial>
          }
        </div>
      }
    </>
  )
}

export default Snapshots
