import * as React from "react";

const SvgSuccess = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g id="Icon/Saving/Success" stroke="none"
         strokeWidth="1"
         fill="none"
         fillRule="evenodd">
        <path
          d="M8,0 C10.199481,0 12.1916477,0.887618264 13.6378351,2.32418992 L8.0198957,7.99169182 L6.2849913,6.24279209 C5.80526608,5.75919697 5.02815076,5.75949132 4.5487851,6.24344972 C4.06952474,6.72730181 4.06952474,7.51178105 4.5487851,7.99563314 L4.5487851,7.99563314 L8.0198957,11.5 L15.1143042,4.33755943 C15.6803747,5.4349338 16,6.68014066 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z"
          id="Combined-Shape"
          fill="#9FE4AC"/>
      </g>
    </svg>
  );
}

export default SvgSuccess;
