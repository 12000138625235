import './usage-stats.scss';
import { useEffect, useState } from 'react';
import InterviewsApi from '../api/interviews-api';
import { IProject } from '../../../../models/project.interface';
import { TopConnectionsDialog } from './top-connections-dialog/top-connections-dialog';
import { Divider } from 'antd';
import FlagIconsPipe from '../../../../../../_shared/helpers/flag-icons-pipe';
import { ITopFlag, ITopGraph, ITopTask, ITopThreeGraph, } from '../model/top-three-graph.interface';
import { TopFlagsDialog } from './top-flags-dialog/top-flags-dialog';
import { TopTasksDialog } from './top-tasks-dialog/top-tasks-dialog';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { TextResize } from '../../../../../../_shared/helpers/text-resize';

export function UsageStats(props: { project: IProject; }): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const [topGraphs, setTopGraphs] = useState<ITopGraph[]>([]);
  const [topFlags, setTopFlags] = useState<ITopFlag[]>([]);
  const [topTasks, setTopTasks] = useState<ITopTask[]>([]);
  const [modalDataFlags, setModalDataFlags] = useState<any>(
    {
      flag: [],
      projectId: null
    });
  const [dialogFlags, setDialogFlags] = useState<boolean>(false);
  const [modalDataGraphs, setModalDataGraphs] = useState<any>(
    {
      graph: [],
      projectId: null
    });
  const [dialogGraphs, setDialogGraphs] = useState<boolean>(false);
  const [modalDataTasks, setModalDataTasks] = useState<any>(
    {
      task: [],
      projectId: null
    });
  const [dialogTasks, setDialogTasks] = useState<boolean>(false);

  useEffect(() => {
    loadPage();
  }, []);

  function loadPage(): void {
    InterviewsApi.getTopThree(props.project.id).then(
      (response: ITopThreeGraph) => {
        setTopGraphs(response.graphs);
        setTopFlags(response.flags);
        setTopTasks(response.tasks);
      }
    );
  }

  function closeDialog(): void {
    setDialogFlags(false);
    setDialogGraphs(false);
    setDialogTasks(false);
  }

  function onClickFlags(flag: ITopFlag): void {
    setModalDataFlags({flag: flag, projectId: props.project.id});
    setDialogFlags(true);
  }

  function onClickGraphs(graph: ITopGraph): void {
    setModalDataGraphs({graph: graph, projectId: props.project.id});
    setDialogGraphs(true);
  }

  function onClickTasks(task: ITopTask): void {
    setModalDataTasks({task: task, projectId: props.project.id});
    setDialogTasks(true);
  }

  function navigateToUsageStatsViewAll(): void {
    const params = {
      id: props.project.id,
      nodeId: topGraphs[0]?.id,
      taskId: topTasks[0]?.id,
      flagId: topFlags[0]?.id
    }

    navigate({pathname: '/projects/edit/interview/usage-stats', search: qs.stringify(params)})
  }

  return (
    <div className="column">
      {topGraphs?.length !== 0 ||
      topFlags?.length !== 0 ||
      topTasks?.length !== 0 ?
        <div className="stats-card-wrapper d-flex">
          {topGraphs && topGraphs.length !== 0 && (
            <div className="stats-cards-splitter">
              <span className="stats-main-title">Knowledge Graph</span>
              <div className="d-flex">
                {topGraphs.map((graph: ITopGraph, i: number) => {
                  return (
                    <div
                      className="stats-card-style"
                      key={i}
                      onClick={() => onClickGraphs(graph)}>
                      <div className="stats-card">
                        <span className="value-stats-card">
                          {graph.totalAssigned}
                        </span>
                        <span className="title-stats-card">{TextResize(graph.name, 'tasks')}</span>
                      </div>
                    </div>
                  );
                })}
                <Divider
                  className=""
                  style={{
                    height: 'auto',
                    color: '#d8d8d8',
                    margin: '0 8px 0 0',
                  }}
                  type="vertical"
                />
              </div>
            </div>
          )}
          {topFlags && topFlags.length !== 0 && (
            <div className="stats-cards-splitter">
              <span className="stats-main-title">Flags</span>
              <div className="d-flex">
                {topFlags.map((flag: ITopFlag, i: number) => {
                  return (
                    <div
                      className="stats-card-style"
                      key={i}
                      onClick={() => onClickFlags(flag)}>
                      <div className="stats-card">
                        <span className="value-stats-card">
                          {flag.totalAssigned}
                        </span>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <span className="title-stats-card">
                            <FlagIconsPipe
                              width={12}
                              height={12}
                              icon={flag?.icon && flag?.icon}
                              color={flag?.color && flag?.color}
                            />
                            <span className="flag-name-position">
                              {TextResize(flag.name, 'flag')}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Divider
                  style={{
                    height: 'auto',
                    color: '#d8d8d8',
                    margin: '0 8px 0 0',
                  }}
                  type="vertical"
                />
              </div>
            </div>
          )}
          {topTasks && topTasks.length !== 0 && (
            <div className="stats-cards-splitter">
              <span className="stats-main-title">Interview Tasks</span>
              <div className="d-flex">
                {topTasks.map((task: ITopTask, i: number) => {
                  return (
                    <div
                      className="stats-card-style"
                      key={i}
                      onClick={() => onClickTasks(task)}>
                      <div className="stats-card">
                        <span className="value-stats-card">
                          {task.totalAssigned}
                        </span>
                        <span className="title-stats-card">{TextResize(task.name, 'tasks')}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div/>
            </div>
          )}
          <span className="view-all-button" onClick={() => navigateToUsageStatsViewAll()}>View All</span>
        </div>
        :
        <></>
      }

      {dialogFlags &&
          <TopFlagsDialog
              visible={dialogFlags}
              data={modalDataFlags}
              title={'projectType.title'}
              onCancel={closeDialog}
          />
      }

      {dialogGraphs && (
        <TopConnectionsDialog
          visible={dialogGraphs}
          data={modalDataGraphs}
          title={'projectType.title'}
          onCancel={closeDialog}
        />
      )}

      {dialogTasks && (
        <TopTasksDialog
          visible={dialogTasks}
          data={modalDataTasks}
          title={'projectType.title'}
          onCancel={closeDialog}
        />
      )}
    </div>
  );
}
