import * as React from "react";

const SvgLock = (props: any) => {
  return (
    <svg width={12} height={14} viewBox="0 0 12 14" {...props}>
      <g id="July-7th---Edit-graph-new---Approved" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Sub-item-options-Copy-5" transform="translate(-1173.000000, -377.000000)">
          <g id="Group-5" transform="translate(1167.000000, 372.000000)">
            <g id="QPIcons/Edit/16px" transform="translate(4.000000, 4.000000)">
              <g>
                <path
                  d="M11,7 L11,6 C11,4.56225375 9.65685425,3 8,3 C6.34314575,3 5,4.56225375 5,6 C5,7.61101938 5,7.27768604 5,7 L11,7 Z M3,7 L3,6 C3,3.18715525 5.23857625,1 8,1 C10.7614237,1 13,3.18715525 13,6 L13,7 L12.7180574,7 C13.1638168,7 13.3254599,7.04641281 13.4884229,7.13356635 C13.6513858,7.2207199 13.7792801,7.34861419 13.8664336,7.51157715 C13.9535872,7.67454011 14,7.83618323 14,8.2819426 L14,13.7180574 C14,14.1638168 13.9535872,14.3254599 13.8664336,14.4884229 C13.7792801,14.6513858 13.6513858,14.7792801 13.4884229,14.8664336 C13.3254599,14.9535872 13.1638168,15 12.7180574,15 L3.2819426,15 C2.83618323,15 2.67454011,14.9535872 2.51157715,14.8664336 C2.34861419,14.7792801 2.2207199,14.6513858 2.13356635,14.4884229 C2.04641281,14.3254599 2,14.1638168 2,13.7180574 L2,8.2819426 C2,7.83618323 2.04641281,7.67454011 2.13356635,7.51157715 C2.2207199,7.34861419 2.34861419,7.2207199 2.51157715,7.13356635 C2.67454011,7.04641281 2.83618323,7 3.2819426,7 L3,7 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z"
                  id="Shape"
                  fill={props.color ? props.color : '#545E6B'}/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgLock;
