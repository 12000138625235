import { Loader } from '../../../../../../../_shared/components/loader/loader';
import './top-tasks-dialog.scss';
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import InterviewsApi from '../../api/interviews-api';
import SvgDocuments from '../../../../../../../assets/icons/js/documents';
import { ISelectedTasks, ITopTasksDetails } from '../../model/top-tasks-details.interface';

export function TopTasksDialog(props: any): JSX.Element {
  const [data] = useState<any>(props.data);
  const [loader, setLoader] = useState<boolean>(true);
  const [taskDetails, setTaskDetails] = useState<ITopTasksDetails[]>([]);

  useEffect(() => {
    loadPage();
  }, []);

  function title(): JSX.Element {
    return (
      <div className="d-flex row align-items-center" style={{color: '#545e6b'}}>
        <span>
          {data.task.name} - {data.task.totalAssigned} connections
        </span>
      </div>
    );
  }

  function loadPage(): void {
    InterviewsApi.getSpecificTask(data.task.id, data.projectId).then((response: ITopTasksDetails[]) => {
      setTaskDetails(response);
      setLoader(false);
    });
  }

  return (
    <Modal
      title={title()}
      open={props.visible}
      onCancel={() => props.onCancel()}
      centered
      width={'60%'}
      footer={[]}>
      <div style={{maxHeight: '85vh', overflow: 'scroll'}}>
        {loader ? (
          <Loader/>
        ) : (
          <div className="top-connections-dialog-tasks">
            {taskDetails.map((taskDetail: ITopTasksDetails, index: number) => {
              return (
                <div key={index} className="interview">
                  <div className="title-container">
                    <div className="title-connections">
                      <SvgDocuments
                        color={'#1B87E6'}
                        style={{marginRight: 8}}
                      />
                      <div className="title">{taskDetail.title}</div>
                    </div>
                    <div className="tasks-connections">{taskDetail.totalConnections}</div>
                  </div>
                  <div className="hr"/>
                  {taskDetail?.selected?.map((selected: ISelectedTasks, index: number) => {
                    return (
                      <div key={index} className="selected-graph-card">
                        <div className="content">
                          “…{selected.selectedText}…”
                        </div>
                        <div className="footer">
                          <div className='selected-user'>{selected.user}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
}
