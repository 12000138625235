import React, { useState } from 'react';
import { Divider, Modal } from "antd";
import { useForm, useWatch } from "react-hook-form";
import StoriesApi from "../../../../../api/stories-api";
import './additional-feedback-dialog.scss'

const AdditionalFeedbackDialog = ({data, onCancel}: {
  data: { open: boolean, chatId: number },
  onCancel: () => void
}) => {
  const [apiLoading, setApiLoading] = useState(false)
  const form = useForm({
    defaultValues: {
      additionalFeedback: ""
    }
  });

  const handleSubmit = (form: { additionalFeedback: string }) => {
    setApiLoading(true);
    StoriesApi.submitAdditionalFeedback(data.chatId, form).then(() => {
      setApiLoading(false);
      onCancel()
    })
  }

  const additionalFeedback = useWatch({
    control: form.control,
    name: "additionalFeedback"
  })

  return (
    <Modal open={data.open} title="Additional feedback" onCancel={onCancel}
           cancelButtonProps={{type: "link"}}
           cancelText="Back"
           width={600}
           okText={"Submit"}
           onOk={form.handleSubmit(handleSubmit)}
           okButtonProps={{disabled: !additionalFeedback.length, loading: apiLoading}}
    >
      <div className="additional-feedback-dialog-wrapper">
        <div className="outline-input-wrapper">
          <label className="label">
            How was your experience using AskIH to craft stories?
          </label>
          <textarea placeholder={"What do you like about it? What should we be doing better?"} className="outline-input"
                    rows={20} {...form.register('additionalFeedback')}/>
        </div>
      </div>
      <Divider style={{marginBottom: -20}}/>
    </Modal>
  );
};

export default AdditionalFeedbackDialog;
