import { environment } from "../../../../environment";
import { axiosClient } from "../../../../axios-client";
import { AxiosResponse } from "axios";

class SelectHubApi {
  private baseUrl: string = `${environment.backendUrl}`;

  async signInAs(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<Promise<any>> = await axiosClient.post<Promise<any>>(`${this.baseUrl}/auth/sign-in-as`, payload)
      return response.data;
    } catch (err) {
      throw err
    }
  }

  async signOutAs(): Promise<any> {
    try {
      const response: AxiosResponse<Promise<any>> = await axiosClient.post<Promise<any>>(`${this.baseUrl}/auth/sign-out-as`)
      return response.data;
    } catch (err) {
      throw err
    }
  }
}

export default new SelectHubApi()