import { useContext, useEffect, useState } from 'react';
import './task-editor.scss';
import InterviewLabsSettingsContext from '../../context/interview-labs-settings.context';
import { ITask } from '../../model/task.interface';

export function TaskEditor(props: { tasks: ITask[] }): JSX.Element {
  let timeout: NodeJS.Timeout;
  const context = useContext(InterviewLabsSettingsContext);
  const tasks: ITask[] = props.tasks;
  const [focus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  function onKeyDown(e: any, task: ITask, index: number): void {
    if (e.key === 'Enter') {
      e.preventDefault();
      context.createNewOption(task, index);

      timeout = setTimeout(() => {
        focusElement(index + 1);
      }, 5);
    }


    if (e.key === 'Backspace' && e.target.value.length === 0) {
      e.preventDefault();
      context.deleteOption(index);
      focusElement(index - 1);
    }

    if (e.key === 'ArrowDown') {
      focusElement(index + 1);
    }

    if (e.key === 'ArrowUp') {
      focusElement(index - 1);
    }
  }

  function focusElement(index: number): void {
    const elementId: string = JSON.stringify('task-' + (index));
    const element: any = document.getElementById(elementId);
    if (element) {
      element.focus();
    }
  }

  function onBlur(): void {
    setIsFocus(false);
  }

  function onFocus(): void {
    setIsFocus(true);
  }

  return (
    <div className="task-editor" is-focus={JSON.stringify(focus)}>
      {tasks?.map((task: ITask, index: number) =>
        <div className="task-option" key={index}>
          <div className="square"/>
          <textarea
            id={JSON.stringify('task-' + index)}
            name="option"
            className="option-input"
            placeholder="Type here to add tasks. 1 per line."
            autoComplete="off"
            style={{width: "100%"}}
            onBlur={onBlur}
            onFocus={onFocus}
            value={task.text}
            onKeyDown={(e) => onKeyDown(e, task, index)}
            onChange={(e: any) => context.optionValueChanged(index, e.target?.value)}
          />
        </div>)}
    </div>
  );
}
