// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-link {
  font-weight: 600;
  cursor: pointer;
  color: #0a86ea;
}
.project-link:hover {
  color: #1B3380;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/preview-story/preview-story-editor/strategies/project-strategy/preview-project-strategy.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AACF;AACE;EACE,cAAA;AACJ","sourcesContent":[".project-link {\n  font-weight: 600;\n  cursor: pointer;\n  color: #0a86ea;\n\n  &:hover {\n    color: #1B3380;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
