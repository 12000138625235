import { ReviewStateEnum } from '../../pages/projects/tabs/_shared/model/review-state.enum';
import React from 'react';

const StatusPipe = (props: any) => {

  const pipe = () => {
    switch (props.value) {
      case ReviewStateEnum.REVIEW:
        return <div className="status-pipe">
          {props.icon && <span className="circle" style={{backgroundColor: '#1b87e6'}}/>}
          <span>Review</span>
        </div>
      case ReviewStateEnum.IN_PROGRESS:
        return <div className="status-pipe">
          {props.icon && <span className="circle" style={{backgroundColor: '#ffd700'}}/>}
          <span>In progress</span>
        </div>
      case ReviewStateEnum.COMPLETED:
        return <div className="status-pipe">
          {props.icon && <span className="circle" style={{backgroundColor: '#3fca5a'}}/>}
          <span>Completed</span>
        </div>
      case ReviewStateEnum.CANCELED:
        return <div className="status-pipe">
          {props.icon && <span className="circle" style={{backgroundColor: '#e53251'}}/>}
          <span>Cancelled</span>
        </div>
      default:
        return <div className="status-pipe">
          {props.icon && <span className="circle" style={{backgroundColor: props.color ? props.color : 'white'}}/>}
          <span>{props.value ? props.value : ''}</span>
        </div>
    }
  }

  return (
    <>
      {pipe()}
    </>
  )
}

export default StatusPipe
