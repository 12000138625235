import React from 'react';

const SvgTranslate = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.60607 7.6749C3.65754 7.6191 3.7038 7.56185 3.74389 7.50326C4.09008 6.99706 4.22668 6.58311 4.2788 6.30409C4.28474 6.27249 4.28957 6.24249 4.2935 6.21429H2.88449C2.88744 6.24424 2.89149 6.27632 2.89696 6.31042C2.94049 6.58255 3.07394 6.99126 3.44958 7.49441C3.49555 7.55597 3.54814 7.61618 3.60607 7.6749Z"
        fill="#545E6B"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0 4.42857C0 3.6396 0.716336 3 1.60001 3H8.5515C9.08605 3 9.35375 3.57704 8.97577 3.91452L8.06866 4.72442C7.76861 4.99236 7.60003 5.3557 7.60003 5.73459V10.1429C7.60003 10.9318 6.8837 11.5714 6.00003 11.5714H1.60001C0.716336 11.5714 0 10.9318 0 10.1429V4.42857ZM3.90002 5.67857H5.60003V6.21429H4.89717C4.89118 6.26869 4.88262 6.32816 4.87063 6.39229C4.80598 6.73827 4.64257 7.21718 4.25595 7.78245C4.21545 7.84166 4.17094 7.89912 4.12307 7.95488C4.09946 7.98239 4.07502 8.00946 4.04985 8.0361C4.28171 8.19348 4.5395 8.33373 4.78882 8.45258C5.03738 8.57098 5.26665 8.66315 5.43369 8.72562C5.51707 8.75679 5.58455 8.78042 5.63067 8.79607L5.6677 8.8085L5.68308 8.81356L5.69592 8.8177L5.69878 8.81861C5.69815 8.82031 5.69068 8.84041 5.59998 9.07152C5.50076 9.32433 5.50064 9.32429 5.50064 9.32429L5.49863 9.32363L5.49399 9.3222L5.47747 9.31683C5.46331 9.31226 5.44299 9.30554 5.41719 9.29674C5.3656 9.27921 5.29199 9.2534 5.20193 9.21974C5.02214 9.15252 4.77522 9.05334 4.50629 8.92516C4.22509 8.79115 3.90688 8.61951 3.61452 8.41251C3.31969 8.62626 2.99379 8.80048 2.70624 8.93467C2.43392 9.06171 2.18251 9.15893 1.99913 9.22445C1.90726 9.25724 1.83202 9.28226 1.77921 9.29926C1.75282 9.30772 1.73197 9.31422 1.71744 9.31866L1.70047 9.32385L1.69569 9.32525L1.69425 9.32568C1.69425 9.32568 1.69346 9.3259 1.59991 9.07143C1.50635 8.81691 1.50626 8.81696 1.50626 8.81696L1.5096 8.81595L1.52264 8.81199C1.5345 8.80837 1.5525 8.80274 1.57596 8.7952C1.62291 8.78012 1.69156 8.75732 1.77623 8.72706C1.94593 8.66642 2.17818 8.57656 2.42833 8.45986C2.68168 8.34167 2.94083 8.20159 3.17025 8.04333C3.08976 7.96303 3.01571 7.87911 2.95033 7.79152C2.52601 7.22324 2.35946 6.7391 2.30299 6.38619C2.29706 6.34922 2.29237 6.31377 2.28873 6.2799C2.28629 6.25732 2.28431 6.23543 2.28275 6.21429H1.60001V5.67857H3.30001V5.14286H3.90002V5.67857Z"
            fill="#545E6B"/>
      <path d="M12.4 7.75878L12.89 9.07143H11.9099L12.4 7.75878Z" fill="#545E6B"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M16 11.5714C16 12.3604 15.2837 13 14.4 13H7.4485C6.91395 13 6.64625 12.423 7.02423 12.0855L7.93133 11.2756C8.23139 11.0076 8.39997 10.6443 8.39997 10.2654V5.85714C8.39997 5.06818 9.1163 4.42857 9.99997 4.42857H14.4C15.2837 4.42857 16 5.06818 16 5.85714V11.5714ZM12.8 6.57143H12L10.4 10.8571H11.2433L11.6433 9.78571H13.1567L13.5567 10.8571H14.4L12.8 6.57143Z"
            fill="#545E6B"/>
    </svg>
  );
};

export default SvgTranslate;
