import { ContentBlock, EditorState, EntityInstance, SelectionState } from "draft-js";

function getEditorData(editorState: EditorState) {
  return {
    contentState: editorState.getCurrentContent(),
    inlineStyle: editorState.getCurrentInlineStyle(),
    selectionState: editorState.getSelection(),
    hasFocus: editorState.getSelection().getHasFocus(),
    isCollapsed: editorState.getSelection().isCollapsed(),
    startKey: editorState.getSelection().getStartKey(),
    startOffset: editorState.getSelection().getStartOffset(),
    endKey: editorState.getSelection().getEndKey(),
    endOffset: editorState.getSelection().getEndOffset(),
  };
}

export function findEntityInSelection(editorState: EditorState, entityType: any) {
  const {startKey, startOffset, endOffset} = getEditorData(editorState);
  // @ts-ignore
  const entities = getEntitiesByBlockKey(editorState, entityType, startKey);
  if (entities.length === 0) return null;

  let selectedEntity = null;
  entities.forEach((entity) => {
    const {blockKey, start, end} = entity;
    if (
      blockKey === startKey &&
      ((startOffset > start && startOffset < end) ||
        (endOffset > start && endOffset < end) ||
        (startOffset === start && endOffset === end))
    ) {
      selectedEntity = entity;
    }
  });
  return selectedEntity;
}

export function getEntities(editorState: EditorState, entityType: any = null, selectedEntityKey: any = null) {
  const {contentState} = getEditorData(editorState);
  const entities: any[] = [];
  contentState.getBlocksAsArray().forEach((block) => {
    let selectedEntity: { entityKey: string; blockKey: string; entity: EntityInstance; } | null = null;
    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey !== null) {
          const entity = contentState.getEntity(entityKey);
          if (!entityType || (entityType && entity.getType() === entityType)) {
            if (
              selectedEntityKey === null ||
              (entityKey === selectedEntityKey)
            ) {
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: contentState.getEntity(entityKey)
              };
              return true;
            } else {
              return false;
            }
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({...selectedEntity, start, end});
      }
    );
  });
  return entities;
}

export function getEntitiesByBlockKey(
  editorState: EditorState,
  entityType = null,
  blockKey = null
) {
  return getEntities(editorState, entityType).filter(
    (entity) => entity.blockKey === blockKey
  );
}

export function extendSelectionByData(editorState: EditorState, data: any) {
  const {
    selectionState,
    startKey,
    startOffset,
    endKey,
    endOffset,
  } = getEditorData(editorState);
  let anchorKey = startKey;
  let focusKey = endKey;
  let anchorOffset = startOffset;
  let focusOffset = endOffset;
  // @ts-ignore
  data.forEach(({blockKey, start, end}, key) => {
    if (key === 0) {
      anchorKey = blockKey;
      anchorOffset = start;
    }
    if (key === data.length - 1) {
      focusKey = blockKey;
      focusOffset = end;
    }
  });
  const state = Object.assign({}, anchorKey ? {anchorKey} : {}, {
    focusKey,
    anchorOffset,
    focusOffset,
    isBackward: false,
  });
  const newSelectionState = selectionState.merge(state);
  return EditorState.acceptSelection(editorState, newSelectionState);
}

export function getSelectedBlocksMap(editorState: EditorState) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

export function getSelectedBlocksList(editorState: EditorState) {
  return getSelectedBlocksMap(editorState).toList();
}

export function getSelectedBlock(editorState: EditorState) {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

export function getEntityRange(editorState: EditorState, entityKey: string): SelectionState {
  const block = getSelectedBlock(editorState);
  const selectionState = editorState.getSelection();
  let entitySelection: SelectionState = editorState.getSelection();

  block?.findEntityRanges(
    (value: any) => value?.get('entity') === entityKey,
    (start, end) => {
      entitySelection = selectionState.merge({
        anchorOffset: start,
        focusOffset: end
      });
    }
  );

  return entitySelection;
}

export function moveSelectionToTheEndOfBlock(editorState: EditorState): EditorState {
  const selection: SelectionState = editorState.getSelection();
  const block: ContentBlock = editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey());

  const newSelection = new SelectionState({
    anchorKey: block.getKey(),
    anchorOffset: block.getLength(),
    focusKey: block.getKey(),
    focusOffset: block.getLength(),
  });

  return EditorState.forceSelection(editorState, newSelection);
}

export function moveFocusToEnd(editorState: EditorState) {
  editorState = EditorState.moveSelectionToEnd(editorState);
  return EditorState.forceSelection(editorState, editorState.getSelection());
}

export function getNuggetByEntityMapId(editorState: EditorState, entityMapId: string): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach(block => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();
          // If our logic is successful
          if (entityType === 'EVIDENCE') {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                nuggets: entityData?.nuggets
              };
              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });

  return entities;
}

export function getFlagByEntityMapId(editorState: EditorState, entityMapId: string): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach(block => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();
          // If our logic is successful
          if (entityType === 'FLAG') {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                flags: entityData?.flags
              };

              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });


  return entities;
}

export function getTaskEntitiesByUuid(editorState: EditorState, uuid: number): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach(block => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();
          // If our logic is successful
          if (entityType === 'TASK' && entityData.task.uuid === uuid) {
            // set data
            selectedEntity = {
              entityKey,
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
              task: entityData?.task?.task?.text
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });

  return entities;
}

export function getNodesByEntityMapId(editorState: EditorState, entityMapId: string): any {
  const content = editorState.getCurrentContent();
  let entities = null;
  content.getBlocksAsArray().forEach(block => {
    let selectedEntity: any = null;

    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entityKey = character.getEntity();
          const entity = content.getEntity(entityKey);
          const entityData = entity.getData();
          const entityType = entity.getType();
          // If our logic is successful
          if (entityType === 'NODE') {
            if (entityData.entityMapId === entityMapId) {
              // set data
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity()),
                nodes: entityData?.nodes
              };
              return true;
            }
            return false;
          }
        }
        return false;
      },
      (start, end) => {
        entities = {...selectedEntity, start, end};
      }
    );
  });

  return entities;
}

export function getNuggetSelectionByEntityMapId(editorState: EditorState, entityMapId: string): SelectionState | undefined {
  const entities: any = getNuggetByEntityMapId(editorState, entityMapId);

  if (entities) {
    const contentState = editorState.getCurrentContent()
    const block = contentState.getBlockForKey(entities.blockKey);
    const mySelection = SelectionState.createEmpty(block.getKey());

    return mySelection.merge({
      anchorKey: block.getKey(),
      anchorOffset: entities.start,
      focusKey: block.getKey(),
      focusOffset: entities.end,
    });
  }
}

export function getFlagSelectionByEntityMapId(editorState: EditorState, entityMapId: string): SelectionState | undefined {
  const entities: any = getFlagByEntityMapId(editorState, entityMapId);

  if (entities) {
    const contentState = editorState.getCurrentContent()
    const block = contentState.getBlockForKey(entities.blockKey);
    const mySelection = SelectionState.createEmpty(block.getKey());

    return mySelection.merge({
      anchorKey: block.getKey(),
      anchorOffset: entities.start,
      focusKey: block.getKey(),
      focusOffset: entities.end,
    });
  }
}

