// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-projects-wrapper {
  width: 100%;
  display: flex;
}
.search-projects-wrapper .search-projects {
  width: 100%;
}
.search-projects-wrapper .search-projects-commonly-tagged {
  width: 20%;
  padding: 20px 40px;
}
.search-projects-wrapper .search-projects-commonly-tagged .commonly-tagged-with-title {
  color: #54546b;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.search-projects-wrapper .search-projects-commonly-tagged .commonly-tagged-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
}
.search-projects-wrapper .search-projects-commonly-tagged .commonly-tagged-wrapper .commonly-tagged {
  background-color: #f5f5f5;
  font-size: 14px;
  min-width: 50px;
  color: #54546b;
  display: flex;
  justify-content: space-between;
}
.search-projects-wrapper .search-projects-commonly-tagged .commonly-tagged-wrapper .commonly-tagged .commonly-tagged-name {
  padding-left: 5px;
  padding-right: 5px;
}
.search-projects-wrapper .search-projects-commonly-tagged .commonly-tagged-wrapper .commonly-tagged .commonly-tagged-number {
  background-color: #dfdfdf;
  height: 100%;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/search-results/components/projects/projects.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ;AAEI;EACE,cAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAAN;AAGI;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,iBAAA;AADN;AAGM;EACE,yBAAA;EACA,eAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;AADR;AAGQ;EACE,iBAAA;EACA,kBAAA;AADV;AAIQ;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFV","sourcesContent":[".search-projects-wrapper {\n  width: 100%;\n  display: flex;\n\n  .search-projects {\n    width: 100%;\n  }\n\n  .search-projects-commonly-tagged {\n    width: 20%;\n    padding: 20px 40px;\n\n    .commonly-tagged-with-title {\n      color: #54546b;\n      font-size: 16px;\n      display: flex;\n      align-items: center;\n      gap: 10px;\n    }\n\n    .commonly-tagged-wrapper {\n      display: flex;\n      flex-wrap: wrap;\n      gap: 10px;\n      padding-top: 10px;\n\n      .commonly-tagged {\n        background-color: #f5f5f5;\n        font-size: 14px;\n        min-width: 50px;\n        color: #54546b;\n        display: flex;\n        justify-content: space-between;\n\n        .commonly-tagged-name {\n          padding-left: 5px;\n          padding-right: 5px;\n        }\n\n        .commonly-tagged-number {\n          background-color: #dfdfdf;\n          height: 100%;\n          min-width: 20px;\n          display: flex;\n          align-items: center;\n          justify-content: center;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
