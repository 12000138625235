import { EventTypesEnum, GeneralEventTypesEnum } from "../../_shared/helpers/general-event-type-pipe/event-types.enum";
import { generalEventTypePipe } from "../../_shared/helpers/general-event-type-pipe/general-event-type-pipe";
import qs from "query-string";
import { useNavigate } from "react-router-dom";

export function useNavigateToPageForActivity() {
  const navigate = useNavigate();

  function navigateToPage(data: { eventNameEnum: EventTypesEnum, id?: number, projectId?: number, source?: string }) {
    const {eventNameEnum, id, projectId, source} = data;
    let state: any = {}

    if (source) {
      state[source] = true;
    }

    if (generalEventTypePipe(eventNameEnum) === GeneralEventTypesEnum.KNOWLEDGE_GRAPH_RELATED) {
      navigate('/nodes', {state})
      return;
    }

    if (id) {
      if (generalEventTypePipe(eventNameEnum) === GeneralEventTypesEnum.NUGGET_RELATED || generalEventTypePipe(eventNameEnum) === GeneralEventTypesEnum.PROJECT_RELATED) {
        navigate({pathname: '/projects/edit', search: qs.stringify({id: id, tab: '1'})}, {state})
        return;
      }
      if (generalEventTypePipe(eventNameEnum) === GeneralEventTypesEnum.STORY_RELATED) {
        navigate({pathname: '/stories/edit', search: qs.stringify({id: id, tab: '1'})}, {state})
        return;
      }

      //only for notepads on activityLog
      if (projectId) {
        if (generalEventTypePipe(eventNameEnum) === GeneralEventTypesEnum.NOTEPAD_RELATED) {
          navigate({
            pathname: '/projects/edit/interview',
            search: qs.stringify({id: projectId, interview: id})
          }, {state})
          return;
        }
      }
    }
  }

  return navigateToPage
}