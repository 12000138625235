import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";

type UsePlaceholderReturn = {
  placeholderVisible: boolean;
  placeholderPosition: number;
  placeholderFontSize: number;
}

export function usePlaceholder(editorState: EditorState, titleInput: React.RefObject<HTMLInputElement>): UsePlaceholderReturn {
  const titleHeight = titleInput?.current?.getBoundingClientRect().height;
  const [placeholderPosition, setPlaceholderPosition] = useState<number>(titleHeight ? titleHeight + 10 : 10)
  const [placeholderVisible, setPlaceholderVisible] = useState(false);
  const [placeholderFontSize, setPlaceholderFontSize] = useState(14);

  useEffect(() => {
    if (!titleInput.current) {
      return
    }

    if (!titleHeight) {
      return;
    }

    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const blockFocused = editorState.getCurrentContent().getBlockForKey(anchorKey);
    const block = document.querySelector(`[data-offset-key="${anchorKey}-0-0"`) as HTMLElement | null;
    if (!block) {
      return;
    }
    
    setPlaceholderPosition(block?.offsetTop + titleHeight + 10);
    if (!blockFocused.getText().length) {
      switch (blockFocused.getType()) {
        case 'header-one':
          setPlaceholderVisible(true);
          setPlaceholderFontSize(40)
          break;
        case 'header-two':
          setPlaceholderVisible(true);
          setPlaceholderFontSize(24)
          break;
        case 'header-three':
          setPlaceholderVisible(true);
          setPlaceholderFontSize(18)
          break;
        case 'unstyled':
          setPlaceholderVisible(true);
          setPlaceholderFontSize(16)
          break;
        default:
          setPlaceholderVisible(false);
      }
    } else {
      setPlaceholderVisible(false)
    }

  }, [editorState, titleInput.current])

  return {placeholderVisible, placeholderPosition, placeholderFontSize}
}
