import * as React from "react";

const SvgTheme = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(-139 -392) translate(131 384) translate(8 8)"/>
        <path
          fill={props.color ? props.color : '#545E6B'}
          d="M15.125 22.72v1.23h-6.15v-.615l6.15-.615zm0-1.23v.615l-6.15.615v-.615l6.15-.615zM12.05 4.885c3.736 0 6.765 3.029 6.765 6.765 0 2.54-1.412 4.82-3.595 5.978l-.095.047v3.142l-6.15.615v-3.756l-.094-.047c-2.108-1.119-3.498-3.283-3.591-5.717l-.005-.262c0-3.736 3.029-6.765 6.765-6.765zM6.56 18.439c.196.196.196.512 0 .707l-1.414 1.415c-.195.195-.511.195-.707 0-.195-.196-.195-.512 0-.707l1.415-1.415c.195-.195.511-.195.707 0zm11.586 0l1.415 1.415c.195.195.195.511 0 .707-.196.195-.512.195-.707 0l-1.415-1.415c-.195-.195-.195-.511 0-.707.196-.195.512-.195.707 0zM12.05 6.115c-3.057 0-5.535 2.478-5.535 5.535 0 2.221 1.32 4.2 3.321 5.075l.37.16-.001 3.187 1.23-.123v-3.994c0-.039.004-.077.01-.113-.84-.19-1.605-.618-2.283-1.272-.245-.236-.252-.625-.016-.87.236-.244.625-.251.87-.015.628.605 1.323.942 2.102 1.025.804.086 1.503-.03 2.113-.342.303-.155.673-.036.828.267.155.302.035.673-.267.827-.645.33-1.356.498-2.126.505l-.001 3.859 1.23-.123v-2.817l.37-.162c2-.874 3.32-2.853 3.32-5.074 0-3.057-2.478-5.535-5.535-5.535zm.066 2.52c1.402.158 2.579.859 3.502 2.072.205.27.153.656-.117.862-.27.206-.657.153-.862-.117-.304-.4-.634-.723-.993-.973.158.271.249.588.249.925 0 1.019-.826 1.845-1.845 1.845-1.019 0-1.845-.826-1.845-1.845 0-.67.357-1.256.89-1.58-.694.034-1.283.22-1.775.555-.28.192-.663.119-.854-.162-.192-.28-.119-.663.162-.854.948-.646 2.12-.884 3.488-.729zM1.575 7.742l.088.015 1.932.518c.267.071.425.345.354.612-.072.267-.346.425-.612.354l-1.932-.518c-.267-.071-.425-.345-.354-.612.072-.267.346-.425.612-.354zm20.762.015c.266-.071.54.087.612.354.071.267-.087.54-.354.612l-1.932.518c-.266.071-.54-.087-.612-.354-.071-.267.087-.54.354-.612zM12 0c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5z"
          transform="translate(-139 -392) translate(131 384) translate(8 8)"/>
      </g>
    </svg>
  );
}

export default SvgTheme;
