import * as React from 'react';

const SvgFlagCalendar = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 3h1a1 1 0 0 1 1 1v2H1V4a1 1 0 0 1 1-1h1V1h2v2h6V1h2v2zM1 7h14v7a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm2 3v2h2v-2H3zm4 0v2h2v-2H7zm4 0v2h2v-2h-2z"
        fill={props.color ? props.color : "#9B9B9B"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlagCalendar;
