import React, { useEffect, useState } from 'react';
import './read-node.scss';
import HomeApi from '../../api/home-api';
import { Loader } from '../../../../_shared/components/loader/loader';
import SvgArrowBack from '../../../../assets/icons/js/ArrowBack';
import InformIconsPipe from '../../../../_shared/helpers/inform-icons-pipe';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { UsageMetrics } from './usage-metrics/usage-metrics';
import { IKnowledgeNode } from '../../../knowledge/model/knowledge-node.interface';
import { ReadSubNode } from './read-sub-node/read-sub-node';
import { Empty, Spin } from 'antd';
import { ProjectsViewEnum } from './model/projects-view.enum';

export function ReadNode(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam: any = qs.parse(location.search);
  let nodeId: any = urlParam?.id;
  const [loader, setLoader] = useState<boolean>(true);
  const [changeNodeLoader, setChangeNodeLoader] = useState(false);
  const [selectedNode, setSelectedNode] = useState<any>();
  const [node, setNode] = useState<any>({});
  const [projectBeingHovered, setProjectBeingHovered] = useState<number | null>(null);
  const [projectsView, setProjectsView] = useState(ProjectsViewEnum.TILES)

  useEffect(() => {
    getNodeById();
  }, []);

  useEffect(() => {
    if (node?.nodes) {
      openNodeTree(nodeId);
    }
  }, [node]);


  function navigateBack(): void {
    navigate(-1);
  }

  function searchTree(
    tree: any,
    nodesProp: string,
    prop: string | number,
    value: any
  ): any {
    let i,
      f = null;
    if (Array.isArray(tree)) {
      for (i = 0; i < tree.length; i++) {
        f = searchTree(tree[i], nodesProp, prop, value);
        if (f) {
          return f;
        }
      }
    } else if (typeof tree === 'object') {
      if (tree[prop] !== undefined && tree[prop] === value) {
        return tree;
      }
    }
    if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
      return searchTree(tree[nodesProp], nodesProp, prop, value);
    } else {
      return null;
    }
  }

  function openNodeTree(nodeId: any): void {
    const id = parseInt(nodeId);
    const copiedNodes = [...node?.nodes];
    const foundNode = searchTree(copiedNodes, 'nodes', 'id', id);
    if (foundNode?.parentId) {
      const r = searchTree(copiedNodes, 'nodes', 'id', foundNode.parentId);
      r['open'] = true;
      openNodeTree(r.id);
    }
  }

  function getNodeById(id?: string): void {
    const hasNodeId: any = id ?? nodeId;
    let foundSelectedNode: IKnowledgeNode;

    if (id) {
      navigate(
        {pathname: '', search: qs.stringify({id})},
        {replace: true}
      );
      nodeId = id;
    }

    if (nodeId) {
      setChangeNodeLoader(true);
      HomeApi.getNodeById(hasNodeId).then((data: any) => {
        if (data.nodes.length) {
          foundSelectedNode = searchTree(
            data.nodes,
            'nodes',
            'id',
            parseInt(nodeId)
          );
        }
        setNode(data);
        setSelectedNode(foundSelectedNode);
        setLoader(false);
        setChangeNodeLoader(false)
      });
    }
  }

  const handleMouseOver = (projectId: number) => {
    setProjectBeingHovered(projectId);
  }

  const handleMouseLeave = () => {
    setProjectBeingHovered(null)
  }

  const navigateToReadProject = (nodeId: number, projectId: number) => {
    const params = {nodeId, projectId}
    navigate({pathname: '/nodes/node/project', search: qs.stringify(params)})
  }


  return (
    <div className="read-node">
      {loader ? (
        <Loader/>
      ) : (
        <>
          <div className="intake-form-header">
            <div className="node-header justify-space-between">
              <div className="row align-items-center">
                <button
                  className="icon-button back"
                  onClick={() => navigateBack()}
                >
                  <SvgArrowBack/>
                </button>
                <span className="font-weight-500">KnowledgeGraph</span>
              </div>
            </div>
          </div>
          <div className="body">
            <div className="node-wrapper">
              <div className="title">
                <h2>{node.node}</h2>
                <h3>{node.totalAssigned}
                  {
                    node.totalAssigned === 1 ? " Connection" : " Connections"
                  }
                </h3>
              </div>
              <div className="node">
                <div className="main-node">
                  <span>{node.name}</span>
                </div>
                <div className="nodes-wrapper">
                  {node.nodes?.length &&
                    node.nodes.map((n: IKnowledgeNode, i: number) => {
                      return (
                        <ReadSubNode
                          node={n}
                          key={i}
                          selectedId={nodeId && parseInt(nodeId)}
                          getNodeDetails={getNodeById}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="projects-wrapper">
              <div className='projects-header'>
                <h3>Connected Projects</h3>
              </div>
              <div className='projects-rows'>
                {changeNodeLoader ?
                  <div
                    style={{paddingTop: 20}} className="row justify-space-center align-items-center">
                    <Spin size='large'/>
                  </div>
                  :
                  node?.projects?.length > 0 ?
                    node.projects.map((project: any, index: number) => {
                      return <div
                        key={index}
                        onClick={() => navigateToReadProject(node.id, project.id)}
                        onMouseOver={() => handleMouseOver(project.id)}
                        onMouseLeave={() => handleMouseLeave()}
                        className='project-row pointer'>
                        <div className='name-and-icon'>
                          <div className='project-logo'><InformIconsPipe icon={project.icon} width={32} height={32}/>
                          </div>
                          <div className='project-name'>{project.name}
                          </div>
                        </div>
                        <div className='project-connections'>
                          <span>
                            {
                              project?.notepads.length + project?.nuggets.length
                            }
                          </span>
                          <span>
                            {
                              project?.notepads.length + project?.nuggets.length === 1 ? " Connection" : " Connections"
                            }
                          </span>
                        </div>
                      </div>
                    }) :
                    <div className="w-100 column justify-space-center">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    </div>
                }
              </div>
            </div>
          </div>
          {node.analytics.length !== 0 && (
            <UsageMetrics analytics={node.analytics} changeNodeLoader={changeNodeLoader}/>
          )}
        </>
      )}
    </div>
  );
}
