// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-edit-interview-lab {
  width: 100%;
  height: auto;
}
.add-edit-interview-lab .content {
  display: flex;
  flex-direction: row;
}
.add-edit-interview-lab .content .guide-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.add-edit-interview-lab .content .guide-wrapper .text-editor {
  margin-top: 20px;
  padding: 10px;
  padding-left: 0;
}
.add-edit-interview-lab .content .tasks-wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 7.5%;
}
.add-edit-interview-lab .content .tasks-wrapper .tasks-editor {
  margin-top: 59px;
  padding: 10px;
  padding-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/interview-labs-settings/add-edit-interview-lab/add-edit-interview-lab.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;AADJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,UAAA;AADN;AAGM;EACE,gBAAA;EACA,aAAA;EACA,eAAA;AADR;AAKI;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;AAHN;AAKM;EACE,gBAAA;EACA,aAAA;EACA,eAAA;AAHR","sourcesContent":["@import '../../../../../assets/styles/abstracts/variables';\n\n.add-edit-interview-lab {\n  width: 100%;\n  height: auto;\n\n  .content {\n    display: flex;\n    flex-direction: row;\n\n    .guide-wrapper {\n      display: flex;\n      flex-direction: column;\n      width: 50%;\n\n      .text-editor {\n        margin-top: 20px;\n        padding: 10px;\n        padding-left: 0;\n      }\n    }\n\n    .tasks-wrapper {\n      display: flex;\n      flex-direction: column;\n      width: 60%;\n      padding-left: 7.5%;\n\n      .tasks-editor {\n        margin-top: 59px;\n        padding: 10px;\n        padding-left: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
