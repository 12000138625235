import React from 'react';

const SvgNugget2 = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1943_45990)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.726 9.70618L11.7954 14.4859L4.80335 15.9514L0.785245 10.4227L2.45473 4.19212L10.0033 0.649785L15.1344 2.02466L14.726 9.70618ZM3.95057 6.81771L8.80342 9.23102L5.63799 12.8348L3.8638 11.2464L3.95057 6.81771ZM10.0212 12.8981L12.3953 10.1952L9.55149 10.5462L7.96878 12.3481L10.0212 12.8981ZM12.6738 9.15625L10.1083 8.46881L5.53368 5.01707L10.4733 3.00166L12.5257 3.5516L12.6738 9.15625Z"
              fill={props?.color ? props.color : "#545e6b"}/>
      </g>
      <defs>
        <clipPath id="clip0_1943_45990">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default SvgNugget2;
