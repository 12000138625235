import React, { useEffect, useState } from 'react';
import './home.scss';
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { useQuery, UseQueryResult } from "react-query";
import HomeApi from "./api/home-api";
import { Search } from "./components/search/search";
import { IOrganizationSettings } from "./models/organization.settings.interface";
import { ISearchData } from "./models/search-data.interface";
import { Empty, Button, message, Tooltip } from "antd";
import { AiChat } from "./components/ai-chat/components/ai-chat";
import {
  useFileProgressContext
} from "../../_shared/components/uploader-popup/file-progress-context/file-progress-context";
import { IProjectTableData } from "../projects/models/project-table-data.interface";
import ProjectApi from "../projects/api/project-api";
import { useSyncSurveysContext } from "../../store/sync-surveys-context";
import SvgInfoCircleFilled from "../../assets/icons/js/InfoCircleFilled";
import { Loader } from "../../_shared/components/loader/loader";
import SvgAddBig from "../../assets/icons/js/addBig";
import SvgExit from "../../assets/icons/js/Exit";
import RecentStudyBox from "./components/recent-study-box/recent-study-box";
import HomepageActivityFeed from "./components/usage-dashboard/homepage-activity-feed/homepage-activity-feed";
import ImportFilesDialog from "./components/import-files-dialog/import-files-dialog";
import UploaderPopUp from "../../_shared/components/uploader-popup/uploader-popup";
import ActivityFeedDialog
  from "./components/usage-dashboard/homepage-activity-feed/activity-feed-dialog/activity-feed-dialog";
import SvgBetaLabel from "../../assets/icons/js/BetaLabel";


export function Home(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);

  const {
    isUploading,
    filesBeingUploaded,
    accPercentage,
    uploadFinished,
    cancelFile,
    project
  } = useFileProgressContext();
  const organisationSettings: UseQueryResult<IOrganizationSettings, unknown> = useQuery("organisationSettings", HomeApi.getOrganisationSettings);

  const projects: UseQueryResult<IProjectTableData, unknown> = useQuery(["projects"], () => ProjectApi.getProjects({
    page: 1,
    pageSize: 3,
    sort: 'updatedAt:desc'
  }), {
    refetchOnWindowFocus: false,
    // onSuccess: data => console.log('projects', data)
  });


  const isLoading = [organisationSettings, projects].some(query => query.isLoading)

  const [importFilesDialog, setImportFilesDialog] = useState<any>({open: false});
  const [activityFeedDialog, setActivityFeedDialog] = useState({visible: false});

  const {progress, socket, thereIsImport, importDone} = useSyncSurveysContext();

  useEffect(() => {
    const base64State: string | null = urlParam?.state;

    if (!base64State) {
      return;
    }

    const base64DecodedState: string = atob(base64State);
    const state = JSON.parse(base64DecodedState);

    if (base64State) {
      openImportFilesDialog(state?.projectId);
    }

  }, []);


  useEffect(() => {
    if (!projects.data) {
      return;
    }

    if (projects.isFetchedAfterMount) {
      HomeApi.checkForSyncStatus().then((status) => {
        if (status === 'FINISHED') {
          if (projects.data.pagination.totalElements === 0) {
            if (thereIsImport || importDone) {
              return;
            }

            if (location.state?.lastPath === '/home/project') {
              message.info({
                content: 'Redirecting to landing page in 5 seconds as you haven\'t created the project.',
                duration: 5,
                className: "custom-info-message",
                icon: <SvgInfoCircleFilled color={"#1B3380"}/>
              })

              setTimeout(() => {
                navigate('/initial-page');
              }, 5000)

              return;
            }

            navigate('/initial-page');
          }
        }
      });
    }
  }, [projects.isRefetching, projects.data]);

  const navigateToProjects = () => {
    navigate('/projects');
  }


  function searchChanged(params: ISearchData): void {
    if (params?.enter || params?.formId || params?.tags?.length) {
      delete params?.enter;

      navigate({
        pathname: '/home/search',
        search: qs.stringify(params)
      });
    }
  }


  useEffect(() => {
    if (importDone) {
      projects.refetch();
    }
  }, [importDone])


  const openImportFilesDialog = (selectedProjectId?: number) => {
    setImportFilesDialog({open: true, selectedProjectId});
  }

  const closeImportFilesDialog = () => {
    setImportFilesDialog({open: false})
  }

  const openActivityFeedDialog = () => {
    setActivityFeedDialog({visible: true})
  }

  const closeActivityFeedDialog = () => {
    setActivityFeedDialog({visible: false})
  }


  return (
    <div className="v3-home-wrapper">
      {isLoading ? <Loader/>
        :
        <>
          {
            <>
              <header className="home-header align-items-center">
                <div className="row w-50" style={{gap: 16}}>
                  <div className="organization-logo-wrapper">
                    <img src={organisationSettings.data?.logoUrl} alt=""/>
                  </div>
                  <div className="organization-title-wrapper" style={{width: '50%'}}>
                    <h2>{organisationSettings.data?.welcomeMessage}</h2>
                  </div>
                </div>
                <div className="home-search-wrapper w-50">
                  <div className="" style={{marginTop: 20}}>
                    <Search searchChanged={searchChanged} home={true}/>
                  </div>
                </div>
              </header>
              <main className="home-body">
                <section className="insightshub-ai-wrapper w-50">
                  <div className="row" style={{marginBottom: 30, gap: 20}}>
                    <Button type='primary'
                            onClick={() => navigate('/home/project', {state: {fromHome: true}})}
                            icon={<span className="anticon"><SvgAddBig width={14} height={14} color={'#fff'}/></span>}>
                      Create new project
                    </Button>
                    <div className="row align-items-center " style={{gap: 5}}>
                      <Button type='ghost'
                              onClick={() => openImportFilesDialog()}
                              icon={<span className="anticon">
                                    <SvgExit width={14} height={14} color={'#1E87E6'}/>
                                  </span>
                              }
                      >
                        Import files
                      </Button>
                      <Tooltip placement="topLeft" className="pointer" title="Beta feature">
                        <SvgBetaLabel/>
                      </Tooltip>
                    </div>

                  </div>

                  <div className="row align-items-center" style={{gap: 5, marginBottom: 10}}>
                    <h3 className="section-title">AskIH</h3>
                    <Tooltip placement="topLeft" className="pointer" title="Beta feature">
                      <SvgBetaLabel/>
                    </Tooltip>
                  </div>
                  <div className="ai-chat-wrapper">
                    <AiChat home={true}/>
                  </div>
                </section>
                <section className="recent-studies-and-activity-feed-wrapper w-50">
                  <div className="recent-studies-wrapper">
                    <div className="row justify-space-between align-items-center">
                      <h3 className="section-title">
                        Recent projects
                      </h3>
                      <Button type="link" onClick={navigateToProjects}>See all</Button>
                    </div>

                    <div className="relative">
                      <div data-testid={"opacity-div"} className="row"
                           style={{gap: 10, filter: thereIsImport ? 'opacity(20%)' : 'blur(0)'}}>
                        {projects.data && projects.data.pagination.totalElements === 0 ?
                          <div className="column justify-space-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                          </div>
                          : projects.data && projects.data.content.map((project, i) => {
                          return <RecentStudyBox project={project} key={i}/>
                        })}
                      </div>
                      {
                        thereIsImport &&
                        <Loader/>
                      }
                    </div>

                  </div>
                  <div className="activity-feed-wrapper">
                    <div className="row justify-space-between">
                      <h3 className="section-title">
                        Activity feed
                      </h3>
                      <Button type="link" onClick={openActivityFeedDialog}>See all</Button>
                    </div>

                    {
                      thereIsImport &&
                      <div className="row align-items-center justify-space-center relative"
                           style={{gap: 10, marginTop: 50}}>
                        <Loader/>
                        {/*<SvgRocket/>*/}
                        {/*<h2 className="syncing-data">Syncing Data</h2>*/}
                      </div>
                    }
                    {
                      !thereIsImport &&
                      <HomepageActivityFeed/>
                    }
                  </div>
                </section>
              </main>
            </>
          }
        </>
      }
      {
        importFilesDialog.open &&
        <ImportFilesDialog open={importFilesDialog.open} data={importFilesDialog} onCancel={closeImportFilesDialog}/>
      }
      {
        activityFeedDialog.visible &&
        <ActivityFeedDialog data={activityFeedDialog} onCancel={closeActivityFeedDialog}/>
      }
      {

        isUploading &&
        <UploaderPopUp
          fileListData={filesBeingUploaded}
          cancelFile={cancelFile}
          percent={accPercentage}
          name={project ? project.name : 'General'}
          uploadFinished={uploadFinished}
        />
      }
    </div>

  )
}
