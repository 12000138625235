// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-editor {
  width: 60%;
  padding: 10px;
  border-bottom: 1px solid #D8D8D8;
  background-color: #f5f5f5;
  min-height: 41.3rem;
  margin-top: 10px;
}
.task-editor .task-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
}
.task-editor .task-option .square {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid #545e6b;
  margin-right: 10px;
  margin-top: 5px;
}
.task-editor .task-option textarea {
  overflow-wrap: break-word;
  border: 0;
  background-color: transparent;
  resize: none;
}
.task-editor .task-option textarea:focus {
  outline: 0;
}

.task-editor[is-focus=true] {
  border-color: #0a86ea !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/interview-labs-settings/add-edit-interview-lab/task-editor/task-editor.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,gCAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;EACA,WAAA;EACA,eAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;AACN;AAEI;EACE,yBAAA;EACA,SAAA;EACA,6BAAA;EACA,YAAA;AAAN;AAEM;EACE,UAAA;AAAR;;AAMA;EACE,gCAAA;AAHF","sourcesContent":[".task-editor {\n  width: 60%;\n  padding: 10px;\n  border-bottom: 1px solid #D8D8D8;;\n  background-color: #f5f5f5;\n  min-height: 41.3rem;\n  margin-top: 10px;\n\n  .task-option {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    background-color: transparent;\n    width: 100%;\n    font-size: 16px;\n\n    .square {\n      width: 20px;\n      height: 20px;\n      background-color: transparent;\n      border: 2px solid #545e6b;\n      margin-right: 10px;\n      margin-top: 5px;\n    }\n\n    textarea {\n      overflow-wrap: break-word;\n      border: 0;\n      background-color: transparent;\n      resize: none;\n\n      &:focus {\n        outline: 0;\n      }\n    }\n  }\n}\n\n.task-editor[is-focus=true] {\n  border-color: #0a86ea !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
