// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  height: 100vh;
  color: #707070;
  font-size: 16px;
  width: 100%;
  padding: 0 20%;
}
.error-page .error-message {
  width: 40%;
  margin-right: 180px;
}
.error-page .error-message .error-page-title {
  font-weight: 400;
  color: #545e6b;
  margin-bottom: 24px;
}
.error-page .error-message .error-page-reason {
  font-weight: 500;
  color: #1b87e6;
  margin-bottom: 24px;
}
.error-page .error-message .error-code {
  margin-bottom: 8px;
}
.error-page .sign-in {
  margin-top: 24px;
}
.error-page .sign-in .sign-in-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #d8d8d8;
  color: #545e6b;
  font-weight: 500;
  padding: 8px 75px;
  cursor: pointer;
}
.error-page .sign-in .sign-in-button span {
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/core/error-pages/error-page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,WAAA;EACA,cAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;AACJ;AACI;EACE,gBAAA;EACA,cAAA;EACA,mBAAA;AACN;AAEI;EACE,gBAAA;EACA,cAAA;EACA,mBAAA;AAAN;AAGI;EACE,kBAAA;AADN;AAKE;EACE,gBAAA;AAHJ;AAKI;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAHN;AAKM;EACE,iBAAA;AAHR","sourcesContent":[".error-page {\n  height: 100vh;\n  color: #707070;\n  font-size: 16px;\n  width: 100%;\n  padding: 0 20%;\n\n  .error-message {\n    width: 40%;\n    margin-right: 180px;\n\n    .error-page-title {\n      font-weight: 400;\n      color: #545e6b;\n      margin-bottom: 24px;\n    }\n\n    .error-page-reason {\n      font-weight: 500;\n      color: #1b87e6;\n      margin-bottom: 24px;\n    }\n\n    .error-code {\n      margin-bottom: 8px;\n    }\n  }\n\n  .sign-in {\n    margin-top: 24px;\n\n    .sign-in-button {\n      display: flex;\n      align-items: center;\n      background-color: transparent;\n      border: 1px solid #d8d8d8;\n      color: #545e6b;\n      font-weight: 500;\n      padding: 8px 75px;\n      cursor: pointer;\n\n      span {\n        padding-left: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
