import * as React from "react";

const SvgMicrosoftLogo = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <path d="M7.96667 0H0.5V7.46667H7.96667V0Z" fill="#F25022"/>
      <path d="M16.4999 0H9.0332V7.46667H16.4999V0Z" fill="#7FBA00"/>
      <path d="M7.96667 8.53345H0.5V16.0001H7.96667V8.53345Z" fill="#00A4EF"/>
      <path d="M16.4999 8.53345H9.0332V16.0001H16.4999V8.53345Z" fill="#FFB900"/>
    </svg>
  );
}

export default SvgMicrosoftLogo;