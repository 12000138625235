import React, { useEffect, useState } from 'react';
import classes from '../../google-drive-integration/tabs/settings/google-drive-project-import.module.scss';
import SvgSaving from '../../../../../../../assets/icons/js/Saving';
import SvgSuccess from '../../../../../../../assets/icons/js/Success';
import { Button, Dropdown, Input, Menu, Select, Table, Tag } from 'antd';
import SvgArrowDown from '../../../../../../../assets/icons/js/ArrowDown';
import SvgArrowBack from '../../../../../../../assets/icons/js/ArrowBack';
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import SvgGoogleDrive from '../../../../../../../assets/icons/js/googleDrive';
import GoogleDriveIntegrationApi from '../../google-drive-integration/api/google-drive-integration-api';
import { IGoogleDriveFolder } from '../../google-drive-integration/model/google-drive-folder.interface';
import { Loader } from '../../../../../../../_shared/components/loader/loader';
import { GoogleDriveStateEnum } from '../../google-drive-integration/model/google-drive-state.enum';
import { EditableCell } from './editable-cell';
import { EditableRow } from './edtiable-row';
import { IGoogleDriveProject } from '../../google-drive-integration/model/google-drive-project.interface';
import moment from 'moment';
import { AddEditTagsDialog } from '../../../../../../projects/project-edit/add-edit-tags-dialog/add-edit-tags-dialog';
import { IMetaTag } from '../../../../taxonomy/meta-tags/model/meta-tag.interface';
import { ConfirmationDialog } from '../../../../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import ZipImportApi from '../../zip-import/api/zip-import-api';
import qs from 'query-string';
import SvgZip from '../../../../../../../assets/icons/js/zip';
import DatePicker from '../../../../../../../_shared/components/date-picker/date-picker';

export const State = React.createContext(null);

export default function ImportTable() {
  const location: any = useLocation();
  const urlParam: any = qs.parse(location.search);
  let isZipImport: boolean = false;
  let isExcelImport: boolean = false;
  isZipImport = window.location.pathname.includes('zip-file-import');
  isExcelImport = window.location.pathname.includes('excel-file-import');

  const api = isZipImport ? ZipImportApi : GoogleDriveIntegrationApi;
  const navigate: NavigateFunction = useNavigate();
  const [loader, setLoader] = useState<boolean>(true);
  const [utilsLoader, setUtilsLoader] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [googleDriveFolder, setGoogleDriveFolder] = useState<any>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [metaTagDialog, setMetaTagDialog] = useState<any>({visible: false});
  const [utils, setUtils] = useState<any>();
  const [defaultProjectTypeId, setDefaultProjectTypeId] = useState<any>();
  const [defaultProgramId, setDefaultProgramId] = useState<any>();
  const [defaultRequestedBy, setDefaultRequestedBy] = useState<string>('');
  const [defaultDueDate, setDefaultDueDate] = useState<any>('');
  const [defaultDescription, setDefaultDescription] = useState<string>('');
  const [defaultTags, setDefaultTags] = useState<any[]>([]);
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const columnsData = [
    {
      title: '#',
      children: [
        {
          title: '',
          dataIndex: 'folderId',
          width: '3%',
          render: (value: string, project: any, index: number) => <span>{index}</span>
        },
      ],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Folder',
      children: [
        {
          title: 'Default Values:',
          dataIndex: 'projectName',
        },
      ],
      dataIndex: 'projectName',
      width: '20%',
    },
    {
      title: 'Project Type',
      editable: true,
      children: [
        {
          title: (
            <>
              <Select className="w-100" defaultValue={defaultProjectTypeId || utils?.projectTypes[0].id}
                      onChange={(id: number) => setDefaultProjectTypeId(id)}>
                {utils?.projectTypes.map((projectType: any) => {
                  return (
                    <Select.Option key={projectType.id}
                                   value={projectType.id}>
                      {projectType.title}
                    </Select.Option>
                  )
                })}
              </Select>
            </>
          ),
          dataIndex: 'projectTypeId',
          editable: true
        },
      ],
      width: '20%',
    },
    {
      title: 'Program',
      editable: true,
      children: [
        {
          title: (
            <>
              <Select className="w-100" defaultValue={defaultProgramId || utils?.programs[0].id}
                      onChange={(id: number) => setDefaultProgramId(id)}>
                {utils?.programs.map((programs: any) => {
                  return (
                    <Select.Option key={programs.id}
                                   value={programs.id}>
                      {programs.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </>
          ),
          dataIndex: 'programId',
          editable: true
        },
      ],
      width: '20%',
    },
    {
      title: 'Requested By',
      editable: true,
      children: [
        {
          title: (
            <>
              <Input value={defaultRequestedBy} onChange={(e: any) => setDefaultRequestedBy(e.target.value)}
                     placeholder={"Requested by"}/>
            </>
          ),
          dataIndex: 'requestedBy',
          editable: true
        },
      ],
      width: '20%',
    },
    {
      title: 'Due Date',
      editable: true,
      children: [
        {
          title: (
            <>
              <DatePicker className="w-100"
                          defaultValue={new Date()}
                          onChange={(date: any) => setDefaultDueDate(moment(date, 'MMMM D, YYYY').format())}/>
            </>
          ),
          dataIndex: 'dueDate',
          editable: true,
          width: '10%'
        },
      ],
      width: '10%',
    },
    {
      title: 'Description',
      editable: true,
      children: [
        {
          title: (
            <>
              <Input.TextArea placeholder={"Description"} style={{height: 33}}
                              onChange={(e: any) => setDefaultDescription(e.target.value)}/>
            </>
          ),
          dataIndex: 'description',
          editable: true
        },
      ],
      width: '20%',
    },
    {
      title: 'Tags',
      editable: true,
      children: [
        {
          title: (
            <>
              <div className="editable-cell-value-wrap"
                   data-testid={"openMetaTagDialog"}
                   style={{minHeight: 33, border: '1px solid #d9d9d9', background: '#fff'}} onClick={openMetaTagDialog}>
                {domDisplayTags()}
              </div>
            </>
          ),
          dataIndex: 'tags',
          editable: true
        },
      ],
      width: '20%',
    }
  ];


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  function mapColumns(col: any) {
    if (!col.editable) {
      return col;
    }

    const newCol = {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  }

  const columns = columnsData.map(mapColumns);

  useEffect(() => {
    const id: string | null = urlParam.id;
    const googleDriveFolder: IGoogleDriveFolder = location?.state?.folder;
    getUtils();

    if (id) {
      api.getImportById(id)
        .then((response: IGoogleDriveFolder) => {
          setGoogleDriveFolder(response);
          setDataSource(response.projects);
          selectAllRows(response.projects);
          setLoader(false);
        });
      return;
    }

    if (googleDriveFolder) {
      setGoogleDriveFolder(googleDriveFolder);
      setDataSource(googleDriveFolder.projects);
      selectAllRows(googleDriveFolder.projects);
      setLoader(false);
      return;
    } else {
      navigate(`/settings/google-drive-integration`);
    }

  }, []);

  function handleSave(row: any): void {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.folderId === item.folderId);
    const item = newData[index];
    newData.splice(index, 1, {...item, ...row});
    setDataSource(newData);
  }

  function onStateChanged(status: GoogleDriveStateEnum): void {
    editImportData(status);
  }

  function navigateBack(): void {
    if (isZipImport) {
      navigate('/settings/zip-file-import');
    } else {
      navigate('/settings/google-drive-integration');
    }
  }

  function editImportData(status: GoogleDriveStateEnum): void {
    const id: string | null = urlParam.id;
    setSaving(true);

    const folder: IGoogleDriveFolder = googleDriveFolder
    const projects: IGoogleDriveProject[] = [];

    if (googleDriveFolder) {
      selectedRowKeys.forEach((id: string) => {
        const project = dataSource.find((project: IGoogleDriveProject) => project.folderId === id);
        if (project) {
          projects.push(project);
        }
      });
    }

    folder.projects = projects;
    folder.status = status;

    if (id) {
      folder.id = id;
    }

    api.editImportData(folder)
      .then((response: any) => {
        setSaving(false);
        setSaved(true);
        setGoogleDriveFolder({...googleDriveFolder, id: response.id});
        if (isZipImport) {
          navigate({pathname: '/settings/zip-file-import/import', search: qs.stringify({id: response.id})});
        } else {
          navigate({pathname: '/settings/google-drive-integration', search: qs.stringify({id: response.id})});
        }
        if (status === GoogleDriveStateEnum.IMPORT) {
          navigateBack();
        }
      });
  }

  function openMetaTagDialog(): void {
    setMetaTagDialog({visible: true, tags: defaultTags});
  }

  function onTagSave(data: { modal: boolean, tags: number[] }): void {
    if (data.modal && data?.tags?.length) {
      setDefaultTags(data.tags);
    }

    onTagCancel();
  }

  function onTagCancel(): void {
    setMetaTagDialog({...metaTagDialog, visible: false});
  }

  function getUtils(): void {
    api.utils()
      .then((response: any) => {
        setUtils(response);
        setDefaultRequestedBy(response.defaultValues.requestedBy);
        setDefaultProjectTypeId(response.defaultValues.projectTypeId);
        setDefaultProgramId(response.defaultValues.programId);
        setUtilsLoader(false);
      }, () => {
        if (isZipImport) {
          navigate(`zip-file-import`);
        } else {
          navigate(`google-drive-integration`);
        }
      });
  }

  function applyDefaultValues(): void {
    const newDataSource: IGoogleDriveProject[] = [];
    const dftTags: string = JSON.stringify(defaultTags);

    const defaultGoogleDriveProjectValues: IGoogleDriveProject | any = {};
    defaultGoogleDriveProjectValues.projectTypeId = defaultProjectTypeId;
    defaultGoogleDriveProjectValues.programId = defaultProgramId;
    defaultGoogleDriveProjectValues.requestedBy = defaultRequestedBy;
    defaultGoogleDriveProjectValues.description = defaultDescription;
    defaultGoogleDriveProjectValues.tags = JSON.parse(dftTags);

    if (defaultDueDate) {
      defaultGoogleDriveProjectValues.dueDate = defaultDueDate;
    }

    dataSource.forEach((googleDriveProject: IGoogleDriveProject) => {
      googleDriveProject = {
        ...googleDriveProject,
        ...defaultGoogleDriveProjectValues
      };

      newDataSource.push(googleDriveProject);
    });

    setDataSource(newDataSource);
  }

  function domDisplayTags(): JSX.Element[] {
    const tags: any[] = [];
    let domDisplay: JSX.Element[];

    defaultTags.forEach((tagNumber: number) => {
      utils?.tags.forEach((metatag: IMetaTag) => {
        metatag.tags.forEach((tag: any) => {
          if (tagNumber === tag.id) {
            tags.push(tag);
          }
        });
      })
    });

    domDisplay = tags.map((tag: any, i: number) => {
      return (
        <span key={tag.id || i} style={{paddingRight: 3}}>
            {tag.name}{i === tags.length - 1 ? '' : ','}
          </span>
      )
    });

    return domDisplay;
  }

  function openConfirmationDialog(): void {
    setConfirmationDialog({
      visible: true,
      okText: 'Apply to all',
      title: 'Apply default values to all',
      titleSec: 'Continuing with this process will overwrite the values of all projects within the table.',
      description: <>
        All individual values can be edited afterwards
      </>
    });
  }

  function onConfirmationDialogConfirm(props: any): void {
    if (props.modal) {
      applyDefaultValues();
      onCancel();
    }
  }

  function onCancel(): void {
    setConfirmationDialog({visible: false});
  }

  function selectAllRows(projects: any[]): void {
    const selectKeys: any[] = [];

    projects.forEach((project: any) => {
      selectKeys.push(project.folderId);
    });

    setSelectedRowKeys(selectKeys);
  }

  const rowSelection = {
    onChange: (sRK: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(sRK);
    }
  };

  return (
    <div className={classes.googleDriveProjectImport}>
      <div className={`${classes.header} justify-space-between`}>
        <div className="row align-items-center">
          <button style={saving ? {opacity: 0.5, marginRight: 10} : {opacity: 1, marginRight: 10}}
                  className="icon-button back"
                  onClick={navigateBack}>
            <SvgArrowBack/>
          </button>
          {
            isZipImport
              ? <SvgZip width={18}/>
              : <SvgGoogleDrive width={18}/>
          }
          <span className="font-weight-300">
            {
              isZipImport
                ? 'Import from ZIP File'
                : 'Import from Google Drive'
            }
          </span>
          <Tag className={classes.chip}>{dataSource?.length} {dataSource?.length === 1 ? "Project" : "Projects"}</Tag>
        </div>
        <div className="row align-items-center">
          {
            saving && !saved &&
              <>
                  <SvgSaving/>
                  <span className="font-weight-300" style={{fontSize: '12px', marginLeft: 0}}>Saving ...</span>
              </>
          }
          {
            saved &&
              <>
                  <SvgSuccess/>
                  <span className="font-weight-300"
                        style={{fontSize: '12px', marginLeft: 5}}>All changes saved</span>
              </>
          }
        </div>
        <div className="row align-items-center">
          <Button style={{marginRight: 10}} onClick={navigateBack}>Cancel</Button>
          <Dropdown.Button type="primary"
                           onClick={() => onStateChanged(GoogleDriveStateEnum.DRAFT)}
                           icon={<SvgArrowDown color="#ffffff"/>}
                           overlay={
                             <Menu>
                               <Menu.Item key="1" onClick={() => onStateChanged(GoogleDriveStateEnum.DRAFT)}>
                                 Draft
                               </Menu.Item>
                               <Menu.Item key="2" onClick={() => onStateChanged(GoogleDriveStateEnum.IMPORT)}>
                                 Import
                               </Menu.Item>
                             </Menu>
                           }>
            {googleDriveFolder?.status ?
              googleDriveFolder?.status[0].toUpperCase() + googleDriveFolder?.status.slice(1).toLocaleLowerCase()
              :
              'Draft'
            }
          </Dropdown.Button>
        </div>
      </div>
      <>
        {loader || utilsLoader ? <Loader/> :
          <div className={`${classes.body}`}>
            <div className="column">
              <p className={classes.title}>Project Details</p>
              <div className="row justify-space-between">
                <span className={classes.description}>Click on any specific field you'd like to edit</span>
                <Button className="link" onClick={openConfirmationDialog}>Apply default values</Button>
              </div>
            </div>
            <div className={`${classes.table} column`}>
              <State.Provider value={utils}>
                <Table
                  rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    ...rowSelection
                  }}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  scroll={{x: 1500, y: 680}}
                  rowKey="folderId"
                  pagination={false}
                />
              </State.Provider>
            </div>
          </div>
        }
      </>
      {metaTagDialog.visible &&
          <AddEditTagsDialog data={metaTagDialog} googleDrive={true} onSave={onTagSave} onCancel={onTagCancel}/>}

      {confirmationDialog.visible &&
          <ConfirmationDialog data={confirmationDialog}
                              onConfirm={onConfirmationDialogConfirm}
                              onCancel={onCancel}/>}
    </div>
  )
}
