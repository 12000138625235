import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import FileTypePipe from '../../../../../../_shared/helpers/file-type-pipe';
import { IFile } from '../../../../../../_shared/model/files.Interface';
import { FileTypeEnum } from "../../../../tabs/_shared/model/file-type.enum";

export function ChangeDescriptionDialog(props: any) {
  const [description, setDescription] = useState(props.data.file.description ? props.data.file.description : '');

  const [fileType, setFileType] = useState('');

  useEffect(() => {
    const file: IFile = props.data.file;
    let fileCatch = file.name.toString().substring(file.name.toString().lastIndexOf('.') + 1);

    setFileType(fileCatch);
  }, []);

  const onConfirm = (): void => {

    const file: IFile = props.data.file;

    let fileCatch = file.name.toString().substring(file.name.toString().lastIndexOf('.') + 1);
    setFileType(fileCatch);

    props.handleChangeDescription(file.id, description);
  }

  const visible: boolean = props.data.visible;
  const file: IFile = props.data.file;

  return (
    <Modal
      title={'File description'}
      open={visible}
      onCancel={() => (props.onCancel())}
      centered
      width={416}
      footer={[
        <Button key="submit" type="primary" onClick={() => onConfirm()}>Save</Button>
      ]}>

      <div className="move-or-change-description-dialog column">
        <div className="row align-items-center">

          <FileTypePipe value={fileType as FileTypeEnum}/>

          <span style={{marginLeft: '10px', color: '#545e6b'}}>{file.name}</span>
        </div>

        <div className="row" style={{marginTop: 20}}>
          <div className="w-100">
              <textarea rows={5} value={description}
                        className="outline-input w-100"
                        onChange={(e) => setDescription(
                          e.target.value
                        )}/>
          </div>
        </div>
      </div>
    </Modal>
  );
}

