import React from 'react';

const SvgFiletypePdf2 = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4 0C3.44772 0 3 0.447715 3 1V6H2C1.44772 6 1 6.44772 1 7V13C1 13.5523 1.44772 14 2 14H3V15C3 15.5523 3.44772 16 4 16H14C14.5523 16 15 15.5523 15 15V6L9 0H4ZM12.5858 5L10 2.41421V5H12.5858ZM4.02734 10.5527H3.35352V12H2.3252V7.73438H4.02734C4.36914 7.73438 4.66309 7.79688 4.90918 7.92188C5.15723 8.04688 5.34766 8.21875 5.48047 8.4375C5.61328 8.6543 5.67969 8.90234 5.67969 9.18164C5.67969 9.45703 5.61328 9.69727 5.48047 9.90234C5.34766 10.1074 5.15723 10.2676 4.90918 10.3828C4.66309 10.4961 4.36914 10.5527 4.02734 10.5527ZM3.35352 8.52832V9.75879H4.02734C4.17188 9.75879 4.28906 9.73438 4.37891 9.68555C4.46875 9.63672 4.53418 9.56934 4.5752 9.4834C4.61816 9.39551 4.63965 9.29688 4.63965 9.1875C4.63965 9.07031 4.61816 8.96191 4.5752 8.8623C4.53418 8.7627 4.46875 8.68262 4.37891 8.62207C4.28906 8.55957 4.17188 8.52832 4.02734 8.52832H3.35352ZM7.61914 12H7.26172H6.67285H6.2334V7.73438H6.65527H7.26172H7.60449C7.89355 7.73438 8.1582 7.78418 8.39844 7.88379C8.64062 7.98145 8.84961 8.12207 9.02539 8.30566C9.20312 8.4873 9.33984 8.70312 9.43555 8.95312C9.5332 9.20117 9.58203 9.47656 9.58203 9.7793V9.95801C9.58203 10.2588 9.5332 10.5342 9.43555 10.7842C9.33984 11.0342 9.2041 11.25 9.02832 11.4316C8.85254 11.6133 8.64453 11.7539 8.4043 11.8535C8.16602 11.9512 7.9043 12 7.61914 12ZM7.61914 11.209H7.26172V8.52832H7.60449C7.75488 8.52832 7.8877 8.55469 8.00293 8.60742C8.12012 8.66016 8.21777 8.73926 8.2959 8.84473C8.37402 8.94824 8.43262 9.07812 8.47168 9.23438C8.5127 9.38867 8.5332 9.56836 8.5332 9.77344V9.95801C8.5332 10.2314 8.49707 10.4619 8.4248 10.6494C8.35449 10.835 8.25098 10.9746 8.11426 11.0684C7.97949 11.1621 7.81445 11.209 7.61914 11.209ZM11.1465 9.50977V8.52832H12.9717V7.73438H11.1465H10.8535H10.1182V12H11.1465V10.3008H12.8047V9.50977H11.1465Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>

  );
};

export default SvgFiletypePdf2;
