// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-metrics-table-wrapper {
  margin-top: 20px;
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/analytics/tabs/program-metrics/program-metrics.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".program-metrics-table-wrapper {\n  margin-top: 20px;\n  margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
