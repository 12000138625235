import React, { memo } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { IAnalyticsChart } from "../../../models/analytics-chart.interface";

interface Props {
  chart: IAnalyticsChart[];
}

function ProjectsChart(props: Props): JSX.Element {

  const config: ColumnConfig = {
    data: props.chart,
    xField: 'month',
    yField: 'value',
    xAxis: {
      label: {
        style: {
          fontFamily: "Fira Sans"
        }
      },
    },
    yAxis: {
      label: {
        style: {
          fontFamily: "Fira Sans"
        }
      },
    },
    seriesField: 'name',
    isGroup: true,
    padding: 'auto',
    style: {
      height: 360,
      padding: 20,
    },
    height: 360,
    autoFit: true,
    color: ['#99CFFF', '#1B87E6', '#1B3380'],
    marginRatio: 0.2,
    tooltip: false,
    intervalPadding: 10,
    dodgePadding: 10,
    label: {
      position: "top",
      style: {
        fontFamily: "Fira Sans",
      }
    },
    legend: {
      layout: "horizontal",
      position: "top-right",
      itemName: {
        spacing: 0.2,
        style: {
          fill: "#545e6b",
          fontFamily: "Fira Sans"
        },
        formatter: (text, item) => {
          return item.id;
        },
      }
    }
  };

  return (
    <Column {...config}/>
  )
}

export default memo(ProjectsChart)
