// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-card {
  width: 300px;
  height: 294px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  padding: 12px 17px;
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.project-card .content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
}
.project-card .content .project-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project-card .content .project-header .project-name {
  color: #1890ff;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
}
.project-card .content .project-header .bi-three-dots::before {
  transform: rotate(90deg);
}
.project-card .content .project-description {
  min-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.project-card .content .project-date-created :first-child {
  color: #BBBBBB;
}
.project-card .project-metrics {
  border: 0;
}
.project-card .project-metrics .ant-collapse-content-box {
  padding: 0;
}
.project-card .project-metrics .ant-collapse-item {
  border-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/components/project-card.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,SAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACN;AACM;EACE,cAAA;EACA,eAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,gBAAA;AACR;AAEM;EACE,wBAAA;AAAR;AAII;EACE,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;AAFN;AAOM;EACE,cAAA;AALR;AAUE;EACE,SAAA;AARJ;AAUI;EACE,UAAA;AARN;AAWI;EACE,gBAAA;AATN","sourcesContent":[".project-card {\n  width: 300px;\n  height: 294px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  border: 1px solid #d8d8d8;\n  padding: 12px 17px;\n  padding-bottom: 0;\n  overflow-y: auto;\n  overflow-x: hidden;\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    margin: 0;\n\n    .project-header {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n\n      .project-name {\n        color: #1890ff;\n        font-size: 16px;\n        display: -webkit-box;\n        -webkit-line-clamp: 2;\n        -webkit-box-orient: vertical;\n        overflow: hidden;\n        font-weight: 500;\n      }\n\n      .bi-three-dots::before {\n        transform: rotate(90deg);\n      }\n    }\n\n    .project-description {\n      min-height: 22px;\n      display: -webkit-box;\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n    }\n\n    .project-date-created {\n\n      :first-child {\n        color: #BBBBBB;\n      }\n    }\n  }\n\n  .project-metrics {\n    border: 0;\n\n    .ant-collapse-content-box {\n      padding: 0;\n    }\n\n    .ant-collapse-item {\n      border-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
