import React from 'react';

export function DisplayTasks(task: any): JSX.Element {
  return (
    <div className="display-highlight-card">
      <div className="task">
        Q{task.task.index}
      </div>
    </div>
  );
}
