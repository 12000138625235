import React, { useContext, useEffect, useRef, useState } from 'react';
import './recommendation-thumbs-up.scss'
import { ContentState, EditorState } from "draft-js";
import { getRecommendationByEntityMapId } from "../../../helpers/entity";
import StoryContext from "../../../../story-edit.context";
import GlobalContext from "../../../../../../../store/global-context";
import { MenuProps } from 'antd';

type RecommendationThumbsUpProps = {
  readOnly: boolean;
  index: number;
  value: any;
}
const RecommendationThumbsUp = (props: RecommendationThumbsUpProps) => {
  const storyContext = useContext(StoryContext);
  const globalContext = useContext(GlobalContext)
  const entity = getRecommendationByEntityMapId(storyContext?.editorState ? storyContext.editorState : storyContext.editorState, props.value[1][0].entityMapId)
  const accountsThatLiked: {
    id: number,
    userId: number,
    email: string,
    profilePic: string,
    firstName: string,
    lastName: string
  }[] | undefined = entity.entity.getData()?.accountsThatLiked;
  const getChecked = () => accountsThatLiked && accountsThatLiked?.findIndex(account => account.userId === globalContext.user.userId) > -1

  const [checked, setChecked] = useState(() => getChecked());
  const isFirstUpdate = useRef(true);

  //TODO: Refactor this
  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }

    if (props.readOnly || !storyContext.editorState || !storyContext.onSetEditorState) {
      return;
    }

    let newContent: ContentState | undefined = undefined;

    const content = storyContext.editorState.getCurrentContent()
    if (checked) {
      const tempAccount = {
        id: globalContext.user.id,
        userId: globalContext.user.userId,
        email: globalContext.user.emailAddress,
        profilePic: globalContext.user.profilePic,
        firstName: globalContext.user.firstName,
        lastName: globalContext.user.lastName
      }

      newContent = content.mergeEntityData(entity.entityKey, {
        ["accountsThatLiked"]: accountsThatLiked ? [...accountsThatLiked, tempAccount] : [tempAccount],
      })

      const newEditorState = EditorState.set(storyContext.editorState, {
        currentContent: newContent
      });

      storyContext.onSetEditorState(newEditorState)

      return;
    }

    if (!accountsThatLiked) {
      return;
    }

    const userIndex = accountsThatLiked.findIndex(account => globalContext.user.userId === account.userId);

    if (userIndex === -1) {
      return;
    }
    const tempAccountsThatLiked = [...accountsThatLiked]
    tempAccountsThatLiked.splice(userIndex, 1)

    newContent = content.mergeEntityData(entity.entityKey, {
      ["accountsThatLiked"]: tempAccountsThatLiked
    })

    const newEditorState = EditorState.set(storyContext.editorState, {
      currentContent: newContent
    });
    storyContext.onSetEditorState(newEditorState)
  }, [checked]);

  function onButtonClick(value: any) {
    if (props.readOnly) {
      return;
    }
    setChecked(prev => !prev)
  }

  const items: MenuProps['items'] = accountsThatLiked && accountsThatLiked.map((user) => {
    return {
      label: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email,
      key: user.userId,
      icon: user.profilePic ? <div style={{width: 20, height: 20, borderRadius: "50%"}}>
        <img style={{objectFit: "cover", width: 20, height: 20, borderRadius: "50%"}} src={user.profilePic} alt=""/>
      </div> : <></>
    }
  })


  return (
    <div className="recommendation-thumbs-up-wrapper row"
         style={{top: `${parseInt(String(props.value[0]))}px`, pointerEvents: props.readOnly ? 'none' : 'auto'}}>
      <button onClick={() => onButtonClick(props.value)}
              className={props.readOnly ? "recommendation-thumbs-up-icon" : "recommendation-thumbs-up-icon pointer"}
              key={props.index + Math.random()}>
        {
          checked || props.readOnly ? <i className="bi bi-hand-thumbs-up-fill"/> : <i className="bi bi-hand-thumbs-up"/>
        }
      </button>
      <div className="recommendation-thumbs-up-counter">
        {accountsThatLiked ? accountsThatLiked.length : 0}
      </div>
      {/*{*/}
      {/*  accountsThatLiked?.length &&*/}
      {/*  <Dropdown overlayClassName={"recommendation-thumbs-up-overlay"} menu={{items}} trigger={["click"]}>*/}
      {/*    <div className="recommendation-thumbs-up-dropdown">*/}
      {/*      <SvgArrowDown />*/}
      {/*    </div>*/}
      {/*  </Dropdown>*/}
      {/*}*/}
    </div>
  );
};

export default RecommendationThumbsUp;
