import React from 'react';
import "./activity-card.scss"
import { getInitials } from "../../../../../../_shared/helpers/get-initials-of-name";
import DaysLeftDatePipe from "../../../../../../_shared/helpers/days-left-date-pipe";
import SvgKnowledgeGraph from "../../../../../../assets/icons/js/knowledge-graph";
import { IActivityCardProps } from "../model/activity-card.props";
import { EventTypesEnum } from "../../../../../../_shared/helpers/general-event-type-pipe/event-types.enum";
import { useNavigateToPageForActivity } from "../../../../../../core/hooks/use-navigate-to-page";

const ActivityCard = (props: IActivityCardProps) => {
  const navigateToPage = useNavigateToPageForActivity();
  const {activity, projectId} = props;

  return (
    <div className="activity-card-wrapper">
      <div className="activity-card-main pointer" onClick={() => navigateToPage({
        eventNameEnum: activity.eventNameEnum as EventTypesEnum,
        id: activity.id,
        projectId,
        source: props.source === "fromActivity" ? "fromActivity" : "fromHome"
      })}>
        <span className="activity-type">
        {activity.eventName}
        </span>
        <span className="activity-primary-text">
          {activity.eventName.includes("Knowledge") && <SvgKnowledgeGraph color={"#008EEE"}/>}
          {activity.name}
        </span>
        <div className='activity-secondary-text'>
          {activity.description}
          {activity.nuggetDescription}
        </div>

        {
          activity.nodeName &&
            <span className="activity-nugget-update">
            Connected to <span className="activity-nugget-node">{activity.nodeName}</span>
          </span>
        }
        {/*<span className="activity-tags-update">*/}
        {/*  <div className='tag'>tag</div>*/}
        {/*  <div className='tag'>tag</div>*/}
        {/*</span>*/}
      </div>
      <div className='activity-card-side'>
        <div className="activity-user-info">
          <div className='user-avatar' style={{backgroundColor: activity.userColorCode}}>
            {activity.profilePicture ? <img src={activity.profilePicture} alt=''/> : getInitials(activity.user)}
          </div>
          <span className="user-name pointer" onClick={() => props.openUserActivityDialog({userId: activity.userId})}>
            {activity.user}
          </span>
        </div>
        <div className='updated-at'>
          {DaysLeftDatePipe(activity.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
