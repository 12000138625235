import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { ISnapshotProps, SnapshotTemplateEnum } from '../../model/snapshot.interface'
import FirstTemplateSnapshot from './first-template-snapshot/first-template-snapshot'
import SecondTemplateSnapshot from './second-template-snapshot/second-template-snaphot'
import ThirdTemplateSnapshot from './third-template-snapshot/third-template-snapshot'
import FourthTemplateSnapshot from './fourth-template-snapshot/fourth-template-snapshot'
import { SnapshotImage } from './snapshot-image';
import CustomThemeSnapshot from "./custom-theme-snapshot/custom-theme-snapshot";

const Snapshot = forwardRef((renderProps: ISnapshotProps, ref: any) => {
  const [downloading, setDownloading] = useState<boolean>(false);

  const renderTemplate = (downloadable?: boolean) => {
    const props: any = Object.assign({}, renderProps);
    if (downloadable) {
      delete props['mdSize']
      delete props['smSize']
      props['lgSize'] = true;
      delete props['noImageLabel']
    }

    if (renderProps.snapshotTheme) {
      return <CustomThemeSnapshot {...props} />
    }

    switch (props.template) {
      case SnapshotTemplateEnum.FIRST:
        return <FirstTemplateSnapshot {...props} />
      case SnapshotTemplateEnum.SECOND:
        return <SecondTemplateSnapshot {...props} />
      case SnapshotTemplateEnum.THIRD:
        return <ThirdTemplateSnapshot {...props} />
      case SnapshotTemplateEnum.FOURTH:
        return <FourthTemplateSnapshot {...props} />
      default:
        return <FirstTemplateSnapshot {...props} />
    }
  }

  useImperativeHandle(ref, () => ({
    download() {
      setDownloading(true);
    },
  }));

  const downloaded = () => {
    setDownloading(false);
  }

  return (
    <>
      <div className='snapshot-main-wrapper' data-testid="snapshot">
        {renderTemplate()}
      </div>
      {downloading &&
          <SnapshotImage updateDownloadLoader={renderProps.updateDownloadLoader}
                         downloading={downloading}
                         downloaded={downloaded}
                         children={renderTemplate(true)}/>
      }
    </>
  )
});

export default Snapshot
