// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-edit-tags-dialog .tag {
  border: 1px solid transparent;
  cursor: pointer;
  color: #545e6b;
  background-color: #F5F5F5;
  padding: 2px 7px;
}
.add-edit-tags-dialog .tag:hover {
  border: 1px solid #1b87e6;
}
.add-edit-tags-dialog .active-tag {
  border: 1px solid #1b87e6;
  background-color: #1b87e6;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/add-edit-tags-dialog/add-edit-tags-dialog.scss"],"names":[],"mappings":"AAEE;EACE,6BAAA;EACA,eAAA;EACA,cAAA;EACA,yBAAA;EACA,gBAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAKE;EACE,yBAAA;EACA,yBAAA;EACA,cAAA;AAHJ","sourcesContent":[".add-edit-tags-dialog {\n\n  .tag {\n    border: 1px solid transparent;\n    cursor: pointer;\n    color: #545e6b;\n    background-color: #F5F5F5;\n    padding: 2px 7px;\n\n    &:hover {\n      border: 1px solid #1b87e6;\n    }\n  }\n\n  .active-tag {\n    border: 1px solid #1b87e6;\n    background-color: #1b87e6;\n    color: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
