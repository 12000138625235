import * as React from "react";

const SvgBulletedList = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1075.000000, -208.000000)">
          <g id="Bulleted-list" transform="translate(1075.000000, 208.000000)">
            <rect id="Rectangle-Copy-15" x="0" y="0" width="16" height="16"/>
            <path
              d="M2,11 C2.55228475,11 3,11.4477153 3,12 C3,12.5522847 2.55228475,13 2,13 C1.44771525,13 1,12.5522847 1,12 C1,11.4477153 1.44771525,11 2,11 Z M15,11.5 L15,12.5 L5,12.5 L5,11.5 L15,11.5 Z M2,7 C2.55228475,7 3,7.44771525 3,8 C3,8.55228475 2.55228475,9 2,9 C1.44771525,9 1,8.55228475 1,8 C1,7.44771525 1.44771525,7 2,7 Z M15,7.5 L15,8.5 L5,8.5 L5,7.5 L15,7.5 Z M2,3 C2.55228475,3 3,3.44771525 3,4 C3,4.55228475 2.55228475,5 2,5 C1.44771525,5 1,4.55228475 1,4 C1,3.44771525 1.44771525,3 2,3 Z M15,3.5 L15,4.5 L5,4.5 L5,3.5 L15,3.5 Z"
              id="Combined-Shape" fill="#545E6B" fillRule="nonzero"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgBulletedList;
