import React from 'react';

export function TextResize(value: string, usageStats?: string) {
  if (usageStats === 'tasks') {
    if (value.length >= 21) {
      return <span>{value.substring(0, 18) + '...'}</span>;
    }
  }
  if (usageStats === 'flags') {
    if (value.length >= 18) {
      return <span>{value.substring(0, 15) + '...'}</span>;
    }
  } else {
    if (value.length >= 15) {
      return <span>{value.substring(0, 12) + '...'}</span>;
    }

    if (value.length >= 13) {
      return <span className="text-12">{value}</span>;
    }
  }

  return <span>{value}</span>;
}
