import { Table } from 'antd';
import OperationsApi from '../../../../../projects/project-edit/tabs/operations/api/operations-api';

export function SelectQuestion(props: any): JSX.Element {
  const {data, surveyId} = props.data;

  const columns = [
    {
      title: 'Question',
      dataIndex: 'text',
      key: 'text'
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKey: any, selectedRows: any) => {
      props.onSetApiLoading(true);

      OperationsApi.getQuestionDetails(surveyId, selectedRowKey)
        .then((response: any) => {
          if (!response?.highChartJSON) {
            props.onNextButtonError('Question does not have any answers or graph cannot be generated.');
          } else {
            props.onNextButtonError(false);
          }

          props.onSelectedQuestion(response);
          props.onSetApiLoading(false);
        }, () => {
          props.onNextButtonError('Question does not have any answers or graph cannot be generated.');
          props.onSetApiLoading(false);
        });
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="questionID"
      rowSelection={{
        type: 'radio',
        ...rowSelection,
      }}
    />
  );
}
