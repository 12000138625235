export function searchTree(tree: any, nodesProp: string, prop: string | number, value: any): any {
  let i,
    f = null;
  if (Array.isArray(tree)) {
    for (i = 0; i < tree.length; i++) {
      f = searchTree(tree[i], nodesProp, prop, value);
      if (f) {
        return f;
      }
    }
  } else if (typeof tree === 'object') {
    if (tree[prop] !== undefined && tree[prop] === value) {
      return tree;
    }
  }
  if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
    return searchTree(tree[nodesProp], nodesProp, prop, value);
  } else {
    return null;
  }
}
