import React from 'react';
import InformIconsPipe from '../../helpers/inform-icons-pipe';
import './narrow-project-type-card.scss';
import { IPublishedForms } from "../../../pages/home/models/published-forms.interface";

export function NarrowProjectTypeCard(props: {
  projectType: IPublishedForms,
  projectTypeIcon?: string,
  projectTypeColor?: string,
  selectedFormId: number | undefined,
}): JSX.Element {
  const {projectTypeIcon, projectTypeColor, projectType, selectedFormId} = props;

  return (
    <div className="narrow-project-type-card"
         style={{borderColor: selectedFormId === projectType.id ? "#1B87E6" : "#D8D8D8"}}>
      <div className="narrow-card-body">
        <div className="narrow-card-image">
          <InformIconsPipe icon={projectTypeIcon ? projectTypeIcon : projectType.icon} width={28} height={28}
                           color={projectTypeColor ? projectTypeColor : projectType.color}/>
        </div>
        <span className="narrow-card-detail">
          {projectType?.title?.length >= 45 ?
            projectType?.title?.substring(0, 45) + '...'
            :
            projectType?.title
          }
        </span>
      </div>
    </div>
  );
}
