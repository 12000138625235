import { Progress } from "antd";
import React, { useState } from "react";
import "./uploader-file.scss";
import SvgTrash from "../../../../assets/icons/js/Trash";
import SvgAlert from "../../../../assets/icons/js/alert";
import { ConfirmationDialog } from "../../confirmation-dialog/confirmation-dialog";
import { truncateTextInMiddle } from "../../../helpers/truncate-text-in-middle";

const UploaderFile = (props: any) => {
  const {file, cancelFile} = props;
  const [isHover, setIsHover] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const handleMouseOver = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleDeleteFile = () => {
    if (isHover && file.status === "uploading") {
      cancelFile(file);
    }
  };

  const handleClick = () => {
    if (file.status === "error") {
      openConfirmationDialog();
    }
  }

  const openConfirmationDialog = () => {
    setModalVisible(true);
  }

  const closeConfirmationDialog = () => {
    setModalVisible(false);
  }

  const confirmationDialogData = {
    title: "Error in File",
    visible: modalVisible,
    type: "error",
    titleSec: file.name,
    content: "Failed To Upload!",
    okText: "Continue"
  }

  return (
    <>
      <div
        onMouseOver={() => handleMouseOver()}
        onMouseLeave={() => handleMouseLeave()}
        className={
          isHover && file.status === "error"
            ? "uploader-file hover-error"
            : isHover && file.status === "uploading"
              ? "uploader-file hover"
              : "uploader-file"
        }
        onClick={handleClick}
      >
        <div
          className="uploader-file-icon"
          style={{
            cursor:
              isHover && (file.status === "uploading" || file.status === "error") ? "pointer" : "default",
          }}
          onClick={handleDeleteFile}
        >
          {isHover && file.status === "uploading" ? (
            <SvgTrash/>
          ) : file.status === "error" ? (
            <SvgAlert color="#545E6B"/>
          ) : (
            <Progress
              type="circle"
              percent={file.percent}
              width={16}
              showInfo={file.percent === 100}
              strokeWidth={8}
            />
          )}
        </div>
        <div
          className={
            file.status === "error"
              ? "uploader-file-name error"
              : "uploader-file-name"
          }
        >
          {truncateTextInMiddle(file.name, 25)}
        </div>

      </div>
      {
        modalVisible &&
          <ConfirmationDialog
              data={confirmationDialogData}
              onConfirm={closeConfirmationDialog}
              onCancel={closeConfirmationDialog}/>
      }
    </>
  );
};

export default UploaderFile;
