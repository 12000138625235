// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team {
  padding: 0 20px;
}
.team .header {
  padding: 28px 0 15px 0;
}
.team .email {
  color: #1B87E6;
}

.add-edit-team-member {
  flex-direction: column !important;
  gap: 10px;
}
.add-edit-team-member .outline-input-wrapper {
  width: 320px;
}
.add-edit-team-member .options {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}
.add-edit-team-member .input-options {
  width: 200px;
  height: 32px;
  display: flex;
  align-self: flex-end;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
}
.add-edit-team-member .input-options .option {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-edit-team-member .input-options .option:hover > svg > g > g > rect {
  fill: #1b87e6 !important;
}
.add-edit-team-member .input-options .option:hover > svg > g > g > path {
  fill: #ffffff !important;
}
.add-edit-team-member .input-options .option:hover > svg > g > g > rect:last-child {
  fill: #ffffff !important;
}
.add-edit-team-member .input-options .option:first-child {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/team/team.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,sBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;;AAIA;EACE,iCAAA;EACA,SAAA;AADF;AAGE;EACE,YAAA;AADJ;AAIE;EACE,aAAA;EACA,2BAAA;EACA,cAAA;AAFJ;AAKE;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAKI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAHN;AAKM;EACE,wBAAA;AAHR;AAMM;EACE,wBAAA;AAJR;AAOM;EACE,wBAAA;AALR;AAQM;EACE,kBAAA;AANR","sourcesContent":[".team {\n  padding: 0 20px;\n\n  .header {\n    padding: 28px 0 15px 0;\n  }\n\n  .email {\n    color: #1B87E6;\n  }\n}\n\n.add-edit-team-member {\n  flex-direction: column !important;\n  gap: 10px;\n\n  .outline-input-wrapper {\n    width: 320px;\n  }\n\n  .options {\n    display: flex;\n    justify-content: flex-start;\n    margin: 10px 0;\n  }\n\n  .input-options {\n    width: 200px;\n    height: 32px;\n    display: flex;\n    align-self: flex-end;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 0 16px;\n\n    .option {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      cursor: pointer;\n\n      &:hover > svg > g > g > rect {\n        fill: #1b87e6 !important;\n      }\n\n      &:hover > svg > g > g > path {\n        fill: #ffffff !important;\n      }\n\n      &:hover > svg > g > g > rect:last-child {\n        fill: #ffffff !important;\n      }\n\n      &:first-child {\n        margin-right: 15px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
