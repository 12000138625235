import { Divider } from 'antd'
import React, { useRef } from 'react'
import SvgTrash from '../../../../../assets/icons/js/Trash'
import { useOnClickOutside } from '../../../../../core/hooks/use-outside-click';
import SvgExternalLink from "../../../../../assets/icons/js/ExternalLink";

const QuickActionMenu = (props: any) => {
  const component = useRef<any>(null);
  const menu = [
    {
      icon: <span className="row align-items-center" style={{width: 'max-content', gap: 8}}>
        <span>Edit link</span>
       <SvgExternalLink color={"#545e6b"}/>
      </span>,
      onClick: () => {
        props.openLinkProjectModal(props.data.data.entityKey);
        props.close();
      }
    },
    {
      icon: <SvgTrash/>,
      onClick: () => {
        props.assignProjectToSelectedText({
          project: undefined,
          entityKey: props.data.data.entityKey,
          entityMapId: props.data.data.entityMapId
        });
        props.close();
      }
    },
  ]

  useOnClickOutside(component, () => {
    props.close();
  });


  return (
    <div ref={component} className="floating-menu-wrapper" style={{...props.data.position, zIndex: 2, width: 0}}>
      <div className="floating-button"
           onMouseDown={(e) => {
             e.stopPropagation()
             e.preventDefault()
           }}>
        {menu.map((item: any, index: number) => {
          return <div key={index} className="relative row align-items-center">
            <button className='floating-inside-button' onClick={(e) => item.onClick()}>{item.icon}</button>
            {
              (index === 0) &&
              <Divider type="vertical" className="floating-menu-divider"/>
            }
          </div>
        })}
        <div className="bottom-triangle"/>
      </div>
    </div>
  )
}

export default QuickActionMenu
