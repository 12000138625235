import { Loader } from '../../../../../../_shared/components/loader/loader';
import '../../integrations/google-drive-integration/google-drive-integration.scss';
import SvgArrowBack from '../../../../../../assets/icons/js/ArrowBack';
import { Button, Tabs } from 'antd';
import { AiOutlinePoweroff } from 'react-icons/all';
import React, { useEffect, useState } from 'react';
import { environment } from '../../../../../../environment';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import SvgOneDrive from "../../../../../../assets/icons/js/OneDrive";
import SvgMicrosoftLogo from "../../../../../../assets/icons/js/MicrosoftLogo";
import SharepointIntegrationApi from "./api/sharepoint-integration-api";
import SharepointLogo from "../../../../../../assets/icons/js/SharepointLogo";

const TabPane = Tabs.TabPane;

export default function SharepointIntegration() {
  const location = useLocation();
  const urlParam: any = qs.parse(location?.search);
  const navigate: NavigateFunction = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [installedLoading, setInstalledLoading] = useState<boolean>(false);
  const [sharepointInstalled, setSharepointInstalled] = useState<boolean>(false);

  useEffect(() => {
    isSharepointInstalled();
    installSharepoint();
  }, []);

  function navigateBack(): void {
    navigate('/settings?tab=5');
  }

  function uninstall(): void {
    SharepointIntegrationApi.uninstall()
      .then(() => {
        setSharepointInstalled(false);
        setLoader(false);
      });
  }


  function connectToSharepoint(): void {
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${environment.microsoft_client_id}&scope=offline_access Files.ReadWrite.All user.read mail.read&response_type=code&redirect_uri=${environment.microsoft_redirect_uri}`;
  }

  function installSharepoint(): void {
    const code: string | null = urlParam?.code;

    if (!code || !environment.microsoft_redirect_uri) {
      return;
    }

    SharepointIntegrationApi
      .install(code, environment.microsoft_redirect_uri)
      .then(() => {
        setSharepointInstalled(true);
        //navigate('/initial-page/import-external-data');
      });
  }

  function isSharepointInstalled(): void {
    SharepointIntegrationApi.isInstalled().then((response: any) => {
      if (response) {
        // get smth
      }
      setSharepointInstalled(response);
    })
  }

  return (
    <>
      {loader ? <Loader/>
        :
        <div className="google-drive-integration">
          <div className="header row">
            <div className="column align-items-center back-button">
              <button className="icon-button back" onClick={() => navigateBack()}>
                <SvgArrowBack/>
              </button>
            </div>
            <div className="column">
              <div className="row align-items-center">
                <div className="d-flex logo">
                  <SharepointLogo width={32} height={32}/>
                </div>
                <h2>SharePoint integration</h2>
              </div>
              <div className="row">
                  <span className="description">
                    Bring your files from SharePoint into InsightsHub projects in just a couple of clicks.
                  </span>
              </div>
              <div className="row button-wrapper">
                {
                  installedLoading ?
                    <Button type="primary" className="integration-button" icon={<AiOutlinePoweroff/>} loading/>
                    :
                    <>
                      {sharepointInstalled ?
                        <Button onClick={uninstall} type="primary" className="integration-button">Uninstall</Button>
                        :
                        <Button className="install-button"
                                onClick={() => connectToSharepoint()}>
                          <div className="google-icon">
                            <div className="google-icon-wrapper">
                              <div className="svg-signin-google">
                                <div style={{width: 18, height: 18}}>
                                  <SvgMicrosoftLogo/>
                                </div>
                              </div>
                              <span className="text-signin-google">
                                  <span>Sign in with Microsoft</span>
                                </span>
                            </div>
                          </div>
                        </Button>
                      }
                    </>
                }
              </div>
            </div>
          </div>
          <div className="body">
            {/*<Tabs>*/}
            {/*  <TabPane tab="Description" key="1">*/}
            {/*    <GoogleDriveDescriptionTab/>*/}
            {/*  </TabPane>*/}
            {/*  <TabPane tab="Requirements" key="2">*/}
            {/*    <GoogleDriveRequirementsTab/>*/}
            {/*  </TabPane>*/}
            {/*  <TabPane tab="Settings" key="3">*/}
            {/*    <GoogleDriveSettingsTab/>*/}
            {/*  </TabPane>*/}
            {/*</Tabs>*/}
          </div>
        </div>
      }
    </>
  )
}
