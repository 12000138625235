// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-feed-dialog-wrapper .activity-log-filters {
  display: flex;
  gap: 15px;
}
.activity-feed-dialog-wrapper .activity-log-filters .activity-filter {
  width: 180px;
}
.activity-feed-dialog-wrapper .activity-log-filters .activity-filter .ant-select-selection-placeholder {
  color: #545e6b;
}
.activity-feed-dialog-wrapper .activities-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 441.3px;
  min-height: 441.3px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/usage-dashboard/homepage-activity-feed/activity-feed-dialog/activity-feed-dialog.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,SAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAEM;EACE,cAAA;AAAR;AAME;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".activity-feed-dialog-wrapper {\n  .activity-log-filters {\n    display: flex;\n    gap: 15px;\n\n    .activity-filter {\n      width: 180px;\n\n      .ant-select-selection-placeholder {\n        color: #545e6b;\n      }\n\n    }\n  }\n\n  .activities-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-height: 441.3px;\n    min-height: 441.3px;\n    overflow-y: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
