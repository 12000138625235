import React from 'react';

const SvgFiletypeHtml = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16 4L12 0H11H4V7H5V1H11V5H15V15H13H5H4V16H16V5V4Z"
            fill={props?.color ? props.color : '#545e6b'}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14 8H0V14H14V8ZM3.0299 11.3079V13.1619H3.8279V9.01587H3.0299V10.6779H1.5539V9.01587H0.761905V13.1619H1.5539V11.3079H3.0299ZM6.89315 9.64587L6.97715 9.01587H3.89915V9.64587H5.01515V13.1619H5.81315V9.64587H6.89315ZM10.8276 13.1619H10.0536L9.94561 11.4279C9.90961 10.8079 9.89161 10.3159 9.89161 9.95187V9.73587L9.13561 12.7119H8.38561L7.58161 9.72987C7.58961 9.99387 7.59361 10.1939 7.59361 10.3299C7.59361 10.6939 7.58161 11.0679 7.55761 11.4519L7.45561 13.1619H6.68761L7.01761 9.01587H8.03761L8.77561 11.9199L9.47161 9.01587H10.5036L10.8276 13.1619ZM11.903 12.5139V9.01587H11.111V13.1619H13.475L13.565 12.5139H11.903Z"
            fill={props?.color ? props.color : '#545e6b'}/>
    </svg>

  );
};

export default SvgFiletypeHtml;
