import React from 'react';

const SvgBulletedList2 = (props: any) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5C3 2.32843 2.32843 3 1.5 3ZM1.5 7.5C0.671573 7.5 0 6.82843 0 6C0 5.17157 0.671573 4.5 1.5 4.5C2.32843 4.5 3 5.17157 3 6C3 6.82843 2.32843 7.5 1.5 7.5ZM1.5 12C0.671573 12 0 11.3284 0 10.5C0 9.67157 0.671573 9 1.5 9C2.32843 9 3 9.67157 3 10.5C3 11.3284 2.32843 12 1.5 12ZM5.25 0.75H11.25C11.6642 0.75 12 1.08579 12 1.5C12 1.91421 11.6642 2.25 11.25 2.25H5.25C4.83579 2.25 4.5 1.91421 4.5 1.5C4.5 1.08579 4.83579 0.75 5.25 0.75ZM5.25 5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H5.25C4.83579 6.75 4.5 6.41421 4.5 6C4.5 5.58579 4.83579 5.25 5.25 5.25ZM5.25 9.75H11.25C11.6642 9.75 12 10.0858 12 10.5C12 10.9142 11.6642 11.25 11.25 11.25H5.25C4.83579 11.25 4.5 10.9142 4.5 10.5C4.5 10.0858 4.83579 9.75 5.25 9.75Z"
            fill={props?.color ? props.color : '#545e6b'}/>
    </svg>

  );
};

export default SvgBulletedList2;
