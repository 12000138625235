import * as React from "react";

const SvgClose = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/Close"
           fill={props.color ? props.color : '#545E6B'}>
          <polygon id="Path"
                   points="16 1.77777778 14.2222222 9.09494702e-13 8 6.22222222 1.77777778 9.09494702e-13 -1.36424205e-12 1.77777778 6.22222222 8 -1.36424205e-12 14.2222222 1.77777778 16 8 9.77777778 14.2222222 16 16 14.2222222 9.77777778 8"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgClose;
