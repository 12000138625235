import { environment } from "../../../../../environment";
import { IImportJobs } from "../../../models/import-jobs.interface";
import { AxiosResponse } from "axios";
import { axiosClient } from "../../../../../axios-client";
import { ExternalImportDialogData } from "../external-import/external-import-dialog/external-import-dialog";

class QuestionProImportsApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getImportJobs(type: 'DEFAULT' | 'EXTERNAL'): Promise<IImportJobs[]> {
    try {
      const response: AxiosResponse<IImportJobs[]> = await axiosClient.get<IImportJobs[]>(`${this.baseUrl}/jobs`, {
        params: {type}
      });
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

    async startImportJob(partialImport?: boolean, selectedFolders?: number[], sync?: boolean, selectedNumberOfSurveys?: number): Promise<any> {
      try {
        const response: AxiosResponse<any> = await axiosClient.post<any>(`${this.baseUrl}/projects/import/surveys`, {
          partialImport,
          selectedFolders,
          sync,
          selectedNumberOfSurveys
        });
        return response.data;
      } catch (error) {
        throw (error);
    }
  }

  async testConnection(data: ExternalImportDialogData): Promise<any> {
    return await axiosClient.post<any>(`${this.baseUrl}/projects/import/surveys/test-connection`, data);
  }

  async startExternalImportJob(data: ExternalImportDialogData): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(`${this.baseUrl}/projects/import/surveys/external`, data);
      return response.data;
    } catch (error) {
      throw (error);
    }
  }

}

export default new QuestionProImportsApi()