import * as React from "react";

const SvgFiletypePpt = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g
        fill={props.color ? props.color : '#545E6B'}
        fillRule="nonzero">
        <path
          d="M12 0l4 4v12H4v-1h11V5h-4V1H5v6H4V0h8zm2 8v6H0V8h14zm-10.426.854H2.35V13h.792v-1.446h.486c.492 0 .886-.115 1.182-.345.296-.23.444-.575.444-1.035 0-.44-.147-.77-.441-.99-.257-.192-.606-.3-1.045-.325l-.194-.005zm3.546 0H5.896V13h.792v-1.446h.486c.492 0 .886-.115 1.182-.345.296-.23.444-.575.444-1.035 0-.44-.147-.77-.441-.99-.257-.192-.606-.3-1.045-.325l-.194-.005zm4.986 0H9.028v.63h1.116V13h.798V9.484h1.08l.084-.63zm-8.538.582c.572 0 .858.246.858.738 0 .288-.072.491-.216.609-.144.118-.358.177-.642.177h-.426V9.436h.426zm3.546 0c.572 0 .858.246.858.738 0 .288-.072.491-.216.609-.144.118-.358.177-.642.177h-.426V9.436h.426z"
          transform="translate(-499 -1908) translate(451 1832) translate(32 64) translate(16 8) translate(0 4)"/>
      </g>
    </svg>
  );
}

export default SvgFiletypePpt;
