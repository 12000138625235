// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-upload .form-input-text-area {
  width: 145px;
}
.widget-upload .ant-upload.ant-upload-drag {
  border: 0;
}
.widget-upload .ant-upload .ant-upload-btn {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/create-project/widgets/widget-upload/widget-upload.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAGE;EACE,SAAA;AADJ;AAIE;EACE,UAAA;AAFJ","sourcesContent":[".widget-upload {\n  .form-input-text-area {\n    width: 145px;\n  }\n\n  .ant-upload.ant-upload-drag {\n    border: 0;\n  }\n\n  .ant-upload .ant-upload-btn {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
