import React, { useState } from 'react';
import './reassign-workflow.scss';
import { Button, Modal, Select } from 'antd';
import { IWorkflowStatus } from '../model/workflow.interface';
import SvgArrowDown from '../../../../../assets/icons/js/ArrowDown';
import WorkflowsApi from '../api/workflows-api';

export function ReassignWorkflow(props: any): JSX.Element {
  const [reAssignments, setReAssignments] = useState<any>([]);

  const wf: IWorkflowStatus[] = props.data.statuses;
  const reassign: { projects: number, workflowStatus: IWorkflowStatus }[] = props.data.reassign;

  function save(): void {
    const payload = {
      reAssignments,
      workflowStatusDto: props.data.statuses
    }

    WorkflowsApi.reAssignProjectToWorkflows(props.data.id, payload)
      .then(() => {
        props.onConfirm({modal: true, id: props.data.id});
      });
  }

  function onChange(id: number, toId: number): void {
    const newReAssignments: any[] = [...reAssignments];

    const reAssignment = {
      fromId: id,
      toId: toId
    }

    reAssignments.push(reAssignment);

    setReAssignments(newReAssignments.slice());
  }

  return (
    <Modal
      title="Different statuses"
      open={props.data.visible}
      onCancel={() => (props.onCancel())}
      centered
      width="40%"
      footer={[
        <div className="reassign-workflow-footer">
          <span>These changes will affect {props?.data?.reassign[0]?.projects} active projects</span>
          <Button key="submit" type="primary" onClick={() => save()}>
            Save
          </Button>
        </div>
      ]}>

      <div className="reassign-workflow">
        <div className="reassign-table">
          <div className="reassign-header">
            <div className="left">
              <h3>Old status</h3>
            </div>
            <div/>
            <div className="right">
              <h3>New status</h3>
            </div>
            <div/>
          </div>
          {reassign?.map((reassign: any, i: number) => {
            return (
              <div className="reassign-row" key={i}>
                <span className="name">{reassign.status.name}</span>
                <div className="affected-projects">
                  {reassign.projects} projects
                  <SvgArrowDown color="#f5f5f5" width={28} height={28}/>
                </div>
                <div className="workflows-available">
                  <div className="outline-input-wrapper fxFlex">
                    <Select onChange={(toId: number) => onChange(reassign.status.id, toId)}>
                      {wf.map((workflow: IWorkflowStatus) => {
                        return (
                          <Select.Option key={workflow.id}
                                         value={workflow.id}>
                            {workflow.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
