import React, { useContext, useEffect, useRef, useState } from 'react';
import './story-editor.scss';
import {
  ContentBlock,
  DraftEditorCommand,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
  SelectionState,
  genKey
} from 'draft-js';
import Editor from '@draft-js-plugins/editor'
import BlocksMenu from './components/blocks-menu/blocks-menu';
import { getCursorPosition } from './helpers/getCursorPosition';
import CSS from 'csstype';
import { handleOnMouseUp } from '../../../../_shared/components/draft-js/components/helpers/handle-on-mouse-up';
import {
  onShowFloatingMenu
} from '../../../../_shared/components/draft-js/components/floating-menu/on-show-floating-menu';
import FloatingMenu from './components/floating-menu/floating-menu';
import 'draft-js/dist/Draft.css'
import HyperLinkModal from './components/hyperlink-modal/hyper-link-modal';
import { compositeDecorator } from './decorators';
import { findEntityInSelection } from './helpers/entity';
import { assignHyperlinkEntity } from './helpers/assign-hyperlink-entity';
import { extendSelectionByData, getEntities } from '../../../../_shared/components/draft-js/components/helpers/entity';
import { assignProjectEntity } from './helpers/assign-project-entity';
import QuickActionMenu from './components/quick-action-menu';
import { DebounceInput } from 'react-debounce-input';
import { insertImageEntity } from './helpers/insert-image-entity';
import MediaDialog from '../../../../_shared/components/media-dialog/media-dialog';
import ImageActionMenu from './components/image-action-menu';
import StoryContext from '../story-edit.context';
import { insertIframeEntity } from './helpers/insert-iframe-entity';
import IFrameModal from './components/iframe-modal/iframe-modal';
import { GraphDialog } from './components/graph-dialog/graph-dialog';
import { insertGraphEntity } from './helpers/insert-graph-entity';
import { insertRecommendationEntity } from "./helpers/insert-recommendation-entity";
import { usePlaceholder } from "./hooks/use-placeholder";
import { removeLastCharacter } from "./helpers/remove-last-character";
import { useOnLoadImages } from "../../../../core/hooks/use-on-load-images";
import { usePrevious } from "../../../../core/hooks/use-previous";
import LinkProjectModal from '../../../../_shared/components/link-project-modal/link-project-modal';
import { getBlocksMenuPosition } from "./helpers/get-blocks-menu-position";
import { getAskAiPosition } from "./helpers/get-ask-ai-position";
import AiInput from "./components/ai-input/ai-input";
import ReactDOM from 'react-dom';
import { extractSelectionDetails } from "./helpers/extract-selection-details";
import * as immutable from "immutable";
import { insertAiPromptAfterSelection } from './helpers/insert-ai-prompt-block';

type StoryEditorProps = {
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void,
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  readOnly: boolean,
  title: string,
  disableNodes?: string,
  updateLoadedImages: (lI: boolean) => void;
  setTags: React.Dispatch<React.SetStateAction<any[]>>
  onProjectAssign?: (project: any) => void;
}
export const StoryEditor = (props: StoryEditorProps) => {
  let storyEditor: any = useRef(null);
  const storyEditorWrapper = useRef<HTMLDivElement>(null);
  const loadedImages = useOnLoadImages(storyEditorWrapper);
  const prevLoadedImages = usePrevious(loadedImages);
  let titleInput: React.RefObject<HTMLInputElement> = useRef(null);
  const storyContext = useContext(StoryContext)
  const [blocksMenu, setBlocksMenu] = useState({visible: false, position: {}});
  const [mediaDialog, setMediaDialog] = useState({visible: false})
  const [graphDialog, setGraphDialog] = useState({visible: false})
  const [linkProjectModal, setLinkProjectModal] = useState<any>({visible: false});
  const [hyperlinkModal, setHyperlinkModal] = useState<any>({visible: false});
  const [IFrame, setIFrame] = useState<any>({visible: false})
  const [nodeButtonPosition, setNodeButtonPosition] = useState<CSS.Properties>()
  const [showFloatingMenu, setShowFloatingMenu] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [quickAction, setQuickAction] = useState<{ visible: boolean, position: any }>({visible: false, position: {}});
  const [imageAction, setImageAction] = useState<{ visible: boolean, position: any }>({visible: false, position: {}})
  const [readOnlyForImgCaption, setReadOnlyForImgCaption] = useState(false);
  const [storedSelection, setStoredSelection] = useState<SelectionState>();
  const [editorReadOnly, setEditorReadOnly] = useState(false);

  const {editorState, setEditorState} = props;

  const {placeholderVisible, placeholderPosition, placeholderFontSize} = usePlaceholder(editorState, titleInput);


  useEffect(() => {
    onInput();
    onFloatingMenu();
  }, [editorState, quickAction]);

  useEffect(() => {
    setReadOnlyForImgCaption(imageAction.visible);
  }, [imageAction.visible])

  useEffect(() => {
    if (loadedImages !== prevLoadedImages) {
      props.updateLoadedImages(loadedImages);
    }
  }, [loadedImages]);

  function insertImage(data: {
    url: string,
    username?: string,
    userUrl?: string,
    entityKey?: string,
    entityMapId?: number
  }) {
    setEditorState(insertImageEntity(editorState, data));
  }

  function insertIFrame(data: { url: string, entityKey?: string, entityMapId?: number }) {
    setEditorState(insertIframeEntity(editorState, data));
  }

  function insertGraph(data: {
    title: string,
    highChartJSON: string,
    entityKey?: string,
    entityMapId?: number,
    surveyInfo: any,
    selectedChart: any
  }) {
    setEditorState(insertGraphEntity(editorState, data));
  }

  const insertRecommendation = () => {
    const newEditorState = insertRecommendationEntity(removeLastCharacter(editorState));
    setEditorState(newEditorState)
  }

  const openIFrameModal = () => {
    setIFrame({visible: true})
  }

  const closeIFrameModal = () => {
    setIFrame({visible: false})
  }

  const closeMediaDialog = () => {
    setMediaDialog({visible: false})
  }

  const openMediaDialog = () => {
    setMediaDialog({visible: true})
  }

  const openGraphDialog = () => {
    setGraphDialog({visible: true})
  }

  const openLinkProjectModal = (entityKey?: string) => {
    let project: any = {};

    if (entityKey) {
      const entity: any = getEntities(editorState, 'PROJECT', entityKey);
      const newEditorState: EditorState = extendSelectionByData(editorState, entity);
      const contentState = newEditorState.getCurrentContent();
      const projectContent = contentState.getEntity(entityKey).getData();

      setLinkProjectModal({
        ...projectContent,
        entityKey: entityKey,
        visible: true
      })
    } else {
      const entity: any = findEntityInSelection(editorState, "PROJECT");
      if (entity && entity.entity?.data?.project) {
        project = entity.entity.data.project
      }

      const selectionState: SelectionState = editorState.getSelection();
      const anchorKey: string = selectionState.getAnchorKey();
      const currentBlock: ContentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);
      const start: number = selectionState.getStartOffset();
      const end: number = selectionState.getEndOffset();
      const selectedText: string = currentBlock.getText().slice(start, end);

      setTimeout(() => {
        setLinkProjectModal({
          project: project,
          selectedText,
          visible: true
        })
      }, 100)
    }
  }

  const closeLinkProjectModal = () => {
    setLinkProjectModal({visible: false})
  }

  const closeHyperlinkModal = () => {
    setHyperlinkModal({visible: false})
  }

  const openHyperlinkModal = (entityKey?: string) => {
    let url: string = ''

    if (entityKey) {
      const entity: any = getEntities(editorState, 'LINK', entityKey);
      const newEditorState: EditorState = extendSelectionByData(editorState, entity);
      const contentState = newEditorState.getCurrentContent();
      const linkContent = contentState.getEntity(entityKey).getData();

      setHyperlinkModal({
        ...linkContent,
        entityKey: entityKey,
        visible: true
      });
    } else {
      const entity: any = findEntityInSelection(editorState, "LINK");

      if (entity && entity.entity?.data?.url) {
        url = entity.entity.data.url
      }

      const selectionState: SelectionState = editorState.getSelection();
      const anchorKey: string = selectionState.getAnchorKey();
      const currentBlock: ContentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);
      const start: number = selectionState.getStartOffset();
      const end: number = selectionState.getEndOffset();
      const selectedText: string = currentBlock.getText().slice(start, end);

      setTimeout(() => {
        setHyperlinkModal({
          selectedText,
          url,
          visible: true
        });
      }, 100);
    }
  }

  const assignHyperlinkToSelectedText = (data: { url: string, entityKey?: string, entityMapId?: string }) => {
    setEditorState(assignHyperlinkEntity(editorState, data));
    closeHyperlinkModal();
  }

  const assignProjectToSelectedText = (data: { project?: any, entityKey?: string, entityMapId?: string }) => {


    setEditorState(assignProjectEntity(editorState, {
      project: {
        id: data.project.id,
        name: data.project.name,
        projectTags: data.project.projectTags,
        description: data.project.description,
        dueDate: data.project.dueDate,
      },
      entityKey: data.entityKey,
      entityMapId: data.entityMapId
    }));
    if (data?.project?.projectTags) {
      const tags = data.project.projectTags.map((tag: any) => tag.tag)
      props.setTags((prev) => [...prev, ...tags.filter((tag: {
        id: any
      }) => !prev.some(prevTag => prevTag.id === tag.id))])
    }
    closeLinkProjectModal();
  }


  function keyBindingFn(e: any): any {
    if (blocksMenu.visible) {
      e.preventDefault();
      return;
    }

    if (!editorState.getCurrentContent().hasText() && e.key === 'Backspace') {
      if (!titleInput.current) {
        return;
      }
      titleInput.current.focus();
    }

    if (e.key === '/') {
      // show blocks menu
      setTimeout(() => {

        setBlocksMenu({visible: true, position: getBlocksMenuPosition(editorState)})
      }, 0);

      return '';
    } else {
      if (blocksMenu.visible) {
        setBlocksMenu({visible: false, position: {}})
      }
    }

    if (e.key === "Enter") {
      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const anchorKey = selection.getAnchorKey();
      const blockType = currentContent.getBlockForKey(anchorKey).getType();

      if (blockType === 'ordered-list-item' || blockType === 'unordered-list-item') {
        return getDefaultKeyBinding(e)
      }

      //Split the block twice
      const editorState2 = EditorState.push(editorState, Modifier.splitBlock(currentContent, selection), "split-block");
      const finalContentState = Modifier.splitBlock(editorState2.getCurrentContent(), editorState2.getSelection());
      const finalEditorState = EditorState.push(editorState2, finalContentState, "split-block");


      if (blockType === 'header-two' || blockType === 'header-three' || blockType === 'recommendation') {
        setEditorState(RichUtils.toggleBlockType(editorState2, 'unstyled'));
        return ''
      }
      setEditorState(finalEditorState);
      return '';
    }

    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command: DraftEditorCommand | string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };


  function myBlockStyleFn(contentBlock: ContentBlock) {
    const type = contentBlock.getType();
    if (type === 'unstyled') {
      return 'fancy-text';
    }


    return type;
  }

  function myBlockRendererFn(contentBlock: ContentBlock) {
    const type = contentBlock.getType();
    if (type === 'ai-prompt') {
      return {
        component: AiInput,
        editable: false,
        props: {
          setEditorReadOnly,
          storedSelection,
          setStoredSelection,
          onProjectAssign: props.onProjectAssign
        },
      };
    }

    return type
  }


  const closeBlocksMenu = () => {
    setBlocksMenu({visible: false, position: {}});
    setTimeout(() => {
      // used to refocus the cursor in the last position after users clicks on block menu
      storyEditor.current.editor.focus();
    }, 0);
  }

  const onInput = () => {
    if (!titleInput.current) {
      return;
    }

    titleInput.current.style.height = "auto";
    titleInput.current.style.height = (titleInput.current.scrollHeight) + "px";
  }

  const handleOnKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      setTimeout(() => {
        storyEditor.current.editor.focus();
      }, 0);
    }
  }

  const onMouseUp = () => {
    if (props.readOnly) {
      return;
    }

    setNodeButtonPosition(handleOnMouseUp());
    !isFocus && setIsFocus(true);
  };

  const onFloatingMenu = () => {
    setShowFloatingMenu(onShowFloatingMenu(editorState, isFocus, props?.disableNodes));
    if (onShowFloatingMenu(editorState, isFocus, props?.disableNodes) && quickAction.visible) {
      closeQuickActionMenu();
    }
  }

  const onOpenQuickActionMenu = (data: { entityKey: string, entityMapId: number }) => {
    if (data) {
      setTimeout(() => {
        const position = getCursorPosition();
        setQuickAction((prev: { visible: boolean }) => {
          return {
            visible: !prev.visible,
            position: position,
            data
          }
        });
      }, 0);
    }
  }

  const closeQuickActionMenu = () => {
    setQuickAction({visible: false, position: {}});
  }

  const onOpenImageMenu = (data: any) => {
    if (data) {
      setTimeout(() => {
        let position = {top: 0, left: 0}
        const imageElement: HTMLElement | null = document.getElementById(data.entityMapId);
        if (imageElement) {
          position.top = imageElement.getBoundingClientRect().top;
          position.left = imageElement.getBoundingClientRect().left + imageElement.getBoundingClientRect().width / 2;
        }
        setImageAction((prev: { visible: boolean }) => {
          return {
            visible: true,
            position: position,
            ...data,
          }
        });
      }, 0);
    }
  }

  const closeImageMenu = () => {
    setImageAction({visible: false, position: {}});
  }

  const updateViewDataForImageMenu = (view: any) => {
    setImageAction((prev: any) => {
      return {
        ...prev,
        view
      }
    })
  }

  const showAskIHInputOnEditor = (generateFrom: "content" | "linkedProjects") => {
    setStoredSelection(editorState.getSelection());
    setEditorState(insertAiPromptAfterSelection(editorState, generateFrom));
  }


  return (
    <div className="story-editor-wrapper"
         ref={storyEditorWrapper}
         is-focus={JSON.stringify(isFocus)}
         onKeyUp={onMouseUp}
         onMouseUp={onMouseUp}>
      <div className="editor-size relative">
        <DebounceInput
          readOnly={props.readOnly}
          debounceTimeout={600}
          inputRef={titleInput}
          onChange={props.onTitleChange}
          value={props.title}
          className='title-input'
          placeholder='Title'
          element='textarea'
          maxLength={64}
          rows="1"
          onInput={onInput}
          onKeyDown={handleOnKeyDown}/>

        {
          placeholderVisible && !props.readOnly &&
          <div style={{top: placeholderPosition, fontSize: placeholderFontSize}} className='editor-placeholder'>Type
            '/'
            to add a block</div>
        }

        <div className='relative'>
          <Editor
            readOnly={editorReadOnly}
            ref={storyEditor}
            blockStyleFn={myBlockStyleFn}
            blockRendererFn={myBlockRendererFn}
            editorState={editorState}
            keyBindingFn={keyBindingFn}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
            decorators={[compositeDecorator(props.readOnly, openHyperlinkModal, openLinkProjectModal, onOpenQuickActionMenu, insertImage, onOpenImageMenu, updateViewDataForImageMenu, insertRecommendation)]}
          />
          {
            blocksMenu.visible &&
            <BlocksMenu
              data={blocksMenu}
              editorState={editorState}
              onSetEditorState={setEditorState}
              close={closeBlocksMenu}
              openAddImageModal={openMediaDialog}
              openGraphDialog={openGraphDialog}
              openIFrameModal={openIFrameModal}
              insertRecommendation={insertRecommendation}
              showAskIHInputOnEditor={showAskIHInputOnEditor}
            />
          }

        </div>
      </div>

      {
        mediaDialog.visible &&
        <MediaDialog visible={mediaDialog.visible}
                     onCancel={closeMediaDialog}
                     onConfirm={insertImage}/>
      }
      {showFloatingMenu &&
        <FloatingMenu
          nodeButtonPosition={nodeButtonPosition}
          editorState={editorState}
          onSetEditorState={setEditorState}
          openHyperlinkModal={openHyperlinkModal}
          openLinkProjectModal={openLinkProjectModal}
          showAskIHInputOnEditor={showAskIHInputOnEditor}
        />
      }
      {
        linkProjectModal.visible &&
        <LinkProjectModal
          data={linkProjectModal}
          onCancel={closeLinkProjectModal}
          onConfirm={assignProjectToSelectedText}/>
      }
      {
        hyperlinkModal.visible &&
          <HyperLinkModal
              data={hyperlinkModal}
              onCancel={closeHyperlinkModal}
              onConfirm={assignHyperlinkToSelectedText}/>
      }
      {
        quickAction.visible &&
          <QuickActionMenu openLinkProjectModal={openLinkProjectModal}
                           close={closeQuickActionMenu}
                           assignProjectToSelectedText={assignProjectToSelectedText}
                           data={quickAction}/>
      }
      {
        imageAction.visible &&
          <ImageActionMenu
              close={closeImageMenu}
              data={imageAction}
          />
      }
      {
        IFrame.visible &&
          <IFrameModal onCancel={closeIFrameModal} data={IFrame} onConfirm={insertIFrame}/>
      }
      {
        graphDialog.visible &&
          <GraphDialog
              data={graphDialog}
              onConfirm={insertGraph}
              onCancel={() => setGraphDialog({visible: false})}
          />
      }
    </div>
  )
}
