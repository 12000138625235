import React from 'react';
import './storage.scss';
import { Button, Progress } from "antd";
import SvgProject from "../../../../assets/icons/js/List";
import SvgInsightDesk from "../../../../assets/icons/js/InsightDesk";
import SvgMarketFlipped from "../../../../assets/icons/js/marketFlipped";
import SvgStories from "../../../../assets/icons/js/Stories";
import SvgFiletypePdf2 from "../../../../assets/icons/js/FiletypePdf2";
import { useQuery } from "react-query";
import AnalyticsApi from "../../api/analytics-api";
import { Loader } from "../../../../_shared/components/loader/loader";

const Storage = () => {
  const usageMetrics = useQuery(['usageMetrics'], () => AnalyticsApi.getUsageMetrics());

  return (
    <div className="analytics">
      {usageMetrics.isLoading ? <Loader/> : null}
      <div className="content">
        <div className="analytics-main">
          <div className="row analytics-cards storage-cards" style={{flexWrap: "nowrap"}}>
            <div className="analytics-project-card column" style={{width: "30%"}}>
              <div className="w-100">
                <div className="title">Overview</div>
                <div className="hr"></div>
              </div>
              <div className="w-100 column align-items-center justify-space-center">
                {
                  usageMetrics.data &&
                  <div className="column" style={{width: "60%"}}>
                    <h2>
                      {usageMetrics.data.overview.percentage}%
                    </h2>
                    <Progress percent={usageMetrics.data.overview.percentage}
                              showInfo={false}
                              strokeColor={"#1B3380"}
                              trailColor={"#D9D9D9"}/>
                    <p
                      style={{marginTop: 5}}>{usageMetrics.data.overview.usedSize} of {usageMetrics.data.overview.sizeLimit}GB</p>
                    <Button type={"link"}>UPGRADE LIMIT</Button>
                  </div>
                }
              </div>
            </div>
            <div className="analytics-project-card column" style={{width: "70%"}}>
              <div className="w-100">
                <div className="title">Details</div>
                <div className="hr"></div>
              </div>
              {
                usageMetrics.data &&
                <div className={"info-item-cards"}>
                  <div className="info-item-card">
                    <span className="item-count">{usageMetrics.data.details.totalProjects}</span>
                    <div className='hr'/>
                    <span className="item-label row align-items-center">
                      <SvgProject/>
                      Projects
                    </span>
                  </div>
                  <div className="info-item-card">
                    <span className="item-count">{usageMetrics.data.details.totalSurveys}</span>
                    <div className='hr'/>
                    <span className="item-label row align-items-center">
                      <SvgInsightDesk style={{marginLeft: 2}}/>
                      QuestionPro Surveys
                    </span>
                  </div>
                  <div className="info-item-card">
                    <span className="item-count">{usageMetrics.data.details.totalSnapshots}</span>
                    <div className='hr'/>
                    <span className="item-label row align-items-center">
                      <SvgMarketFlipped/>
                      Snapshots
                    </span>
                  </div>
                  <div className="info-item-card">
                    <span className="item-count">{usageMetrics.data.details.totalStories}</span>
                    <div className='hr'/>
                    <span className="item-label row align-items-center">
                      <SvgStories/>
                      Stories
                    </span>
                  </div>
                  <div className="info-item-card">
                    <span className="item-count">{usageMetrics.data.details.totalFiles}</span>
                    <div className='hr'/>
                    <span className="item-label row align-items-center">
                      <SvgFiletypePdf2/>
                      Files
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Storage;
