// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setup > .title {
  padding-bottom: 20px;
  margin: 16px 8px 20px 8px;
}
.setup > .title h4 {
  color: #9b9b9b;
}
.setup > .title .hr {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}
.setup .page {
  margin-top: 15px;
  min-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/taxonomy/taxonomy.scss"],"names":[],"mappings":"AAEE;EACE,oBAAA;EACA,yBAAA;AADJ;AAGI;EACE,cAAA;AADN;AAII;EACE,WAAA;EACA,WAAA;EACA,yBAAA;AAFN;AAME;EACE,gBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".setup {\n\n  > .title {\n    padding-bottom: 20px;\n    margin: 16px 8px 20px 8px;\n\n    h4 {\n      color: #9b9b9b;\n    }\n\n    .hr {\n      width: 100%;\n      height: 1px;\n      background-color: #d8d8d8;\n    }\n  }\n\n  .page {\n    margin-top: 15px;\n    min-height: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
