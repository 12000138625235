import React from 'react';

const SvgInsightsDeskWrapped = (props: any) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="16" cy="16" r="16" fill="#EDF6FD"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.8999 21.3999C13.7283 21.3999 14.3999 22.0715 14.3999 22.8999C14.3999 23.7283 13.7283 24.3999 12.8999 24.3999C12.0715 24.3999 11.3999 23.7283 11.3999 22.8999C11.3999 22.0715 12.0715 21.3999 12.8999 21.3999ZM18.4587 8.3999C21.1877 8.3999 23.3999 10.4892 23.3999 13.0666C23.3999 15.6439 21.1877 17.7332 18.4587 17.7332H14.2234L14.2234 19.8999H11.3999V15.0666H18.4587C19.6282 15.0666 20.5764 14.1712 20.5764 13.0666C20.5764 11.962 19.6282 11.0666 18.4589 11.0666H11.3999V8.3999H18.4587Z"
            fill={props?.color ? props.color : "#1B87E6"}/>
    </svg>

  );
};

export default SvgInsightsDeskWrapped;
