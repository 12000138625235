function truncateTextInMiddle(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  } else {
    const prefixLength: number = Math.floor(maxLength - 7);
    const suffixLength: number = 7;

    const prefix: string = text.slice(0, prefixLength);
    const suffix: string = text.slice(-suffixLength);

    return `${prefix}...${suffix}`;
  }
}

export { truncateTextInMiddle }