import { environment } from '../../../../../../../environment';
import { axiosClient } from '../../../../../../../axios-client';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

class SharepointIntegrationApi {
  private baseUrl: string = `${environment.backendUrl}/integrations/sharepoint`;

  //Integrations
  async install(code: string, redirectURI: string): Promise<null> {
    try {
      const response: AxiosResponse<null> = await axiosClient.post<null>(`${this.baseUrl}/install?code=${code}&redirectURI=${redirectURI}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uninstall(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/uninstall`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async isInstalled(): Promise<boolean> {
    try {
      const response: AxiosResponse<boolean> = await axiosClient.get<boolean>(`${this.baseUrl}/check-installed`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async getSharepointLinkData(url: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/items-count?url=${url}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async linkAndImport(url: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/import-projects-from-sharepoint-drive`, {
        url,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async listFiles(url: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/list-files?url=${url}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async importFilesToProject(projectId: number, url: string, fileNames: string[], config: AxiosRequestConfig) {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/import-drive-files-to-project`, {
        projectId,
        url,
        fileNames
      }, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SharepointIntegrationApi();
