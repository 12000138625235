import { EditorState, Modifier, SelectionState } from 'draft-js';
import { getTaskEntitiesByUuid } from './entity';

export function removeTaskFromEditorHelper(editorState: EditorState, uuid: number): EditorState {
  const entities: any = getTaskEntitiesByUuid(editorState, uuid);

  if (entities) {
    const contentState = editorState.getCurrentContent()
    const block = contentState.getBlockForKey(entities.blockKey);
    const mySelection = SelectionState.createEmpty(block.getKey());

    const selectionOfAtomicBlock = mySelection.merge({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: block.getKey(),
      focusOffset: block.getLength(),
    });

    // override the entity data and wipe it from the block. This prevents the data from being tacked onto another block
    const contentStateWithoutEntity = Modifier.applyEntity(contentState, selectionOfAtomicBlock, null);
    const editorStateWithoutEntity = EditorState.push(editorState, contentStateWithoutEntity, 'apply-entity');

    // now delete the block. Purge it from existance so that it may never harm your beautiful editor again
    const contentStateWithoutBlock = Modifier.removeRange(contentStateWithoutEntity, selectionOfAtomicBlock, 'backward');
    return EditorState.push(editorStateWithoutEntity, contentStateWithoutBlock, 'remove-range');
  } else {
    return editorState;
  }
}
