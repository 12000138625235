import React, { useState } from 'react';
import "./file-search-card.scss"
import FileTypePipe from "../../../../../../../_shared/helpers/file-type-pipe";
import ProjectEditApi from "../../../../../../projects/project-edit/api/project-edit-api";
import { ISearchFile } from "../../../models/search-file.interface";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import SvgFolder from "../../../../../../../assets/icons/js/Folder";
import { FileTypeEnum } from "../../../../../../projects/tabs/_shared/model/file-type.enum";

const FileSearchCard = (props: { file: ISearchFile }) => {
  const navigate = useNavigate()
  const {file} = props;
  const snippetMaxLength = 280;
  const [isSnippetExpanded, setIsSnippetExpanded] = useState(false);

  const downloadFile = (file: ISearchFile): void => {
    ProjectEditApi.downloadFile(file.id)
      .then((response: any) => {
        const filenamePath: string = response;
        const fileName: string = `${file.name.toLowerCase()}`
        const downloadElement = document.createElement('a');
        document.body.appendChild(downloadElement); //required in FF, optional for Chrome
        downloadElement.href = filenamePath;
        downloadElement.download = fileName;
        downloadElement.target = "_self";
        downloadElement.click();
      });
  }

  const navigateToPage = (projectId?: number, repositoryId?: number, repositoryName?: string) => {
    if (projectId) {
      navigate({pathname: '/projects/edit', search: qs.stringify({id: projectId, tab: '3'})})
    } else if (repositoryId && repositoryName) {
      navigate('/repositories', {state: {repositoryId, repositoryName}})
    }
  }

  const displaySnippet = () => {
    if (file?.snippet && file.snippet.length > snippetMaxLength) {
      return isSnippetExpanded
        ? file.snippet
        : file.snippet.slice(0, snippetMaxLength);
    }
    return file?.snippet;
  };

  return (
    <div className='file-search-container'>
      <span className='file-matches'>{file.matches} {file.matches === 1 ? 'match' : 'matches'}</span>
      <div className='file-search-card-wrapper column relative'>
        <div className="file-search-card-name row">
          <FileTypePipe value={file.type as FileTypeEnum}/>
          <span onClick={() => navigateToPage(file?.projectId, file?.repositoryId, file?.repositoryName)}
                className='card-name pointer'>{file.name}</span>
          <div data-testid={"download-button"} className='file-search-download-container'
               onClick={() => downloadFile(file)}>
            <span><i className="bi bi-download"/></span>
          </div>
        </div>
        <div className="column">
          <div className="file-search-description">
            <span dangerouslySetInnerHTML={{__html: displaySnippet()}}/>
            {file?.snippet && file.snippet.length > snippetMaxLength && (
              <span className="snippet-toggle" onClick={() => setIsSnippetExpanded((prev) => !prev)}>
              {isSnippetExpanded ? "See less" : "See more"}
            </span>
            )}
          </div>
          {file?.projectTitle &&
              <div className='file-search-project-name pointer' onClick={() => navigateToPage(file?.projectId)}>
                {file?.projectTitle}
              </div>
          }
          {
            file?.repositoryName && file?.repositoryId &&
              <div className='file-search-project-name pointer'
                   onClick={() => navigateToPage(undefined, file.repositoryId, file.repositoryName)}>
                <span className="row align-items-center"
                      style={{gap: 10}}><SvgFolder/> {file.repositoryName}
                </span>
              </div>
          }

          <div className='file-search-user-info'>
            {(file?.userProfilePicture || file?.userInitials) &&
                <div className='user-avatar' style={{backgroundColor: file.userColor}}>
                  {
                    file?.userProfilePicture
                      ? <img src={file.userProfilePicture} alt='user-profile-pic'/>
                      : file.userInitials
                  }
                </div>
            }
            <div className='user-name'>{file?.userDisplayName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileSearchCard;
