import { convertToRaw, EditorState } from 'draft-js';

export function getDisplayHighlights(editorState: EditorState): any {
  let displayHighlightsArray: any[] = [];
  let displayHighlightsObject: any = null;

  const contentState = convertToRaw(editorState.getCurrentContent());
  const recommendations = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'RECOMMENDATION').map((v: any) => v[1].data)
    .map(obj => {
      return ({...obj, position: obj.position, type: 'RECOMMENDATION'})
    });

  recommendations.length && displayHighlightsArray.push(...recommendations);


  if (displayHighlightsArray.length) {
    displayHighlightsArray.sort((a, b) => (a?.position?.left > b?.position?.left) ? 1 : -1).forEach((nItem: any) => {

      const parentTop = document.getElementsByClassName('story-editor-wrapper')[0]?.getBoundingClientRect()?.top;
      const topPosition = nItem?.position?.top - parentTop;
      const item = {
        ...nItem,
        position: {
          ...nItem.position,
          top: Math.round(topPosition),
        }
      };


      if (displayHighlightsObject === null) {
        displayHighlightsObject = {
          [topPosition]: [item]
        }
      } else {
        if (displayHighlightsObject[topPosition] !== undefined) {
          displayHighlightsObject[topPosition].push(item);
        } else {
          displayHighlightsObject[topPosition] = [item];
        }
      }
    });
  }
  if (displayHighlightsObject) {
    return Object.entries(displayHighlightsObject)
  }
  return null
}
