import React, { useContext, useEffect, useState } from 'react'
import LinkedProjectCard from './linked-project-card/linked-project-card'
import "./linked-projects.scss"
import { IProject } from '../../../projects/models/project.interface';
import { Empty } from 'antd';
import AddTagsModal from './add-tags-modal/add-tags-modal';
import StoryContext from "../story-edit.context";
import LinkProjectModal from "../../../../_shared/components/link-project-modal/link-project-modal";
import SvgAddBig from "../../../../assets/icons/js/addBig";
import usePagination from "../../../../core/hooks/use-pagination";
import Pagination from "../../../../_shared/components/pagination/pagination";
import LinkedKeyFindingsView from "./linked-key-findings/linked-key-findings";

const LinkedProjects = (props: {
  onProjectAssign: (project: IProject) => void;
  nonTextLinkedProjects: IProject[];
  onTagsChange: (tags: any) => void;
  tags: any[];
  linkedProjects: any[]
  readOnly: boolean;
  forPreview?: boolean;
}) => {
  const storyContext = useContext(StoryContext);
  const data: { project: IProject, text: string, entityMapId: number }[] = props.linkedProjects;

  const [mergedProjects, setMergedProjects] = useState<any[]>([]);
  useEffect(() => {
    const nonDuplicateIds: string | any[] = [];
    const modifiedProjects: any[] = [];

    data?.forEach((dataItem: any) => {
      const existingIndex = modifiedProjects.findIndex((item: {
        project: { id: any };
      }) => item.project.id === dataItem.project.id);

      if (existingIndex !== -1) {
        // Create a new object instead of mutating the existing one
        const updatedProject = {
          ...modifiedProjects[existingIndex],
          tags: modifiedProjects[existingIndex].project.projectTags,
          selectedText: [...modifiedProjects[existingIndex].selectedText, dataItem.text],
          entityMapIds: [...modifiedProjects[existingIndex].entityMapIds, dataItem.entityMapId]
        };
        modifiedProjects[existingIndex] = updatedProject;
      } else {
        nonDuplicateIds.push(dataItem.project.id);
        // Create a new project object
        const newProject = {
          project: dataItem.project,
          tags: dataItem.project.projectTags,
          selectedText: [dataItem.text],
          entityMapIds: [dataItem.entityMapId]
        };
        modifiedProjects.push(newProject);
      }
    });

    // Ensure immutability by creating new arrays instead of mutating existing ones
    const mergedProjects = [
      ...props.nonTextLinkedProjects.map((project) => ({project})),
      ...modifiedProjects.filter((modifiedProject: any) => (
        !props.nonTextLinkedProjects.some((nonTextProject: IProject) => nonTextProject.id === modifiedProject.project.id)
      ))
    ];

    setMergedProjects(mergedProjects);
    if (storyContext.updateLinkedProjects) {
      storyContext.updateLinkedProjects(mergedProjects);
    }

  }, [data, props.nonTextLinkedProjects]);


  const [addTagsModal, setAddTagsModal] = useState<any>({visible: false});
  const [linkProjectModal, setLinkProjectModal] = useState<any>({visible: false})
  const [page, setPage] = useState(1);
  const {items} = usePagination({currentPage: page, pageSize: 5}, mergedProjects);
  const [linkedKeyFindings, setLinkedKeyFindings] = useState({
    open: false,
    projectId: 0,
    projectName: ''
  });

  const openAddTagsModal = () => {
    setAddTagsModal({...addTagsModal, tags: props?.tags, visible: true})
  }

  const closeAddTagsModal = () => {
    setAddTagsModal({...addTagsModal, visible: false})
  }

  const openLinkProjectModal = () => {
    setLinkProjectModal({visible: true, storyId: storyContext.id})
  }

  const closeLinkProjectModal = () => {
    setLinkProjectModal({visible: false})
  }

  const assignProjectToStory = (data: { project: IProject }) => {
    props.onProjectAssign(data.project)
    closeLinkProjectModal();
  }
  const handlePaginationChange = (page: number) => {
    setPage(page)
  }

  const openLinkedKeyFindingsView = (projectId: number, projectName: string) => {
    setLinkedKeyFindings({open: true, projectId, projectName})
  }

  const closeLinkedKeyFindingsView = () => {
    setLinkedKeyFindings({open: false, projectId: 0, projectName: ''})
  }


  return (
    <div className="linked-projects-wrapper column" style={{
      marginTop: props.forPreview ? 240 : 0
    }}>
      <div className="linked-projects">
        <div className="linked-projects-header justify-space-between" style={{marginBottom: 10}}>
          <p>Tags</p>
          {
            !props?.readOnly &&
            <button className="icon-button" aria-label={"add-tags"} onClick={openAddTagsModal}>
              <SvgAddBig width={20} height={20}/>
            </button>
          }
        </div>
        <div className="linked-project-tags">
          {props.tags?.map((tag: any, i: number) => <div className='project-tag' key={i}>{tag.name}</div>)}
          {
            props.tags?.length === 0 ?
              <div className="w-100 column justify-space-center" data-testid={"empty-testid"}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
              </div> :
              <></>
          }
        </div>
      </div>

      <div className='linked-projects column'>
        {
          linkedKeyFindings.open ?
            <LinkedKeyFindingsView projectId={linkedKeyFindings.projectId}
                                   closeLinkedKeyFindingsView={closeLinkedKeyFindingsView}
                                   projectName={linkedKeyFindings.projectName}/> :
            <>

              <div className="linked-projects-header justify-space-between">
                <p>
                  Linked projects
                </p>
                <div className="row align-items-center" style={{gap: 5}}>
                  {
                    !!items.length &&
                    <Pagination totalItems={mergedProjects.length}
                                currentPage={page}
                                pageSize={5}
                                onChange={handlePaginationChange}/>
                  }

                  {
                    !props?.readOnly &&
                    <button className="icon-button" onClick={openLinkProjectModal} aria-label={"add-projects"}>
                      <SvgAddBig width={20} height={20}/>
                    </button>
                  }
                </div>
              </div>
              <div id="linked-project-wrapper" className="column" style={{gap: 32}}>
                {
                  items.length === 0 &&
                  <div className="w-100 column justify-space-center" data-testid={"empty-projects-testid"}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  </div>
                }
                {items.map((projectData: any, i: number) => {
                  return <LinkedProjectCard key={i} data={projectData}
                                            openLinkedKeyFindingsView={openLinkedKeyFindingsView}/>;
                })}
              </div>
            </>

        }


      </div>
      {
        addTagsModal.visible &&
          <AddTagsModal data={addTagsModal} onTagsChange={props.onTagsChange} onCancel={closeAddTagsModal}/>
      }
      {
        linkProjectModal.visible &&
          <LinkProjectModal data={linkProjectModal} onConfirm={assignProjectToStory}
                            onCancel={closeLinkProjectModal}/>
      }
    </div>
  )
}

export default LinkedProjects
