import { createContext, useEffect, useMemo, useState } from 'react';
import { EditorState } from 'draft-js';
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { getDisplayHighlights } from "./story-editor/helpers/get-display-highlights";

type StoryContextType = {
  editorState: EditorState,
  onSetEditorState: (editorState: EditorState) => void,
  id: number,
  updateLinkedProjects: (projects: any[]) => void,
  linkedProjects: any[],
}

const StoryContext = createContext<StoryContextType>({} as StoryContextType);

export function StoryEditContextProvider(props: { children: JSX.Element }) {
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [linkedProjects, setLinkedProjects] = useState<any[]>([]);

  function onSetEditorState(editorState: EditorState): void {
    setEditorState(editorState);
  }

  function updateLinkedProjects(projects: any[]): void {
    setLinkedProjects(projects);
  }

  const context: StoryContextType = {
    editorState: editorState,
    onSetEditorState,
    id: urlParam.id,
    linkedProjects,
    updateLinkedProjects
  };


  return <StoryContext.Provider value={context}>
    {props.children}
  </StoryContext.Provider>
}

export default StoryContext;

