import { Button, Modal } from 'antd';
import InformIconsPipe from '../../../../../../_shared/helpers/inform-icons-pipe';
import React, { useContext, useState } from 'react';
import ProjectTypeContext from '../store/project-type-context';
import { IProjectType } from '../model/project-type.interface';
import SvgSearch from '../../../../../../assets/icons/js/Search';
import { ProjectTypeCard } from '../../../../../../_shared/components/project-type-card/project-type-card';

export default function AddIconDialog(props: any) {
  const projectTypeContext = useContext(ProjectTypeContext);
  const projectType: IProjectType = projectTypeContext.projectType;
  const [projectTypeColor, setProjectTypeColor] = useState(projectType.color);
  const [projectTypeIcon, setProjectTypeIcon] = useState(projectType.icon);
  const [searchValue, setSearchValue] = useState("");

  const colors = ['#332288', '#0077BB', '#88CCEE', '#44AA99', '#117733', '#999933', '#DDAA33', '#CC6677', '#882255', '#AA4499'];

  const bootstrapIcons = require("../../../../../../assets/bootstrap-icons/bootstrap-icons.json")
  const [filteredIcons, setFilteredIcons] = useState(Object.keys(bootstrapIcons).slice(0, 28).map((key: string) => key));

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)
    setFilteredIcons(Object.keys(bootstrapIcons).filter((value: string) => value.includes(e.target.value)).slice(0, 28));
  }

  function onSubmit(): void {
    projectTypeContext.setProjectTypeIconColor(projectTypeIcon, projectTypeColor);
    props.onCancel();
  }

  return (
    <Modal
      title={<div style={{color: "#545E6B", fontWeight: 400}}>Add Icon</div>}
      open={props.visible}
      onCancel={() => (props.onCancel())}
      centered
      width={784}
      footer={[<Button key="submit" type='primary' onClick={onSubmit}>Save</Button>]}>
      <div className="modal-body">
        <div className="modal-body-left column">
          <span className='title'>Icon</span>
          <div className='relative'>
            <input
              style={{width: 400}}
              value={searchValue}
              placeholder="Search Icons"
              className='outline-input'
              onChange={handleSearchChange}/>
            <span className='absolute' style={{top: 8, right: 50}}><SvgSearch/></span>
          </div>

          <div className="icons">
            <div className="row flex-wrap">
              {
                filteredIcons.map((icon: string, i: number) => {
                  const setActiveBorder = icon === projectTypeIcon;
                  return <button className="radio-button-icons"
                                 key={i}
                                 style={{borderColor: setActiveBorder ? '#1B87E6' : '#D8D8D8'}}
                                 onClick={() => setProjectTypeIcon(icon)}>
                    <InformIconsPipe icon={icon} width={24}/>
                  </button>
                })
              }
            </div>
          </div>

        </div>
        <div className="modal-body-right column">
          <div style={{margin: '40px 0'}}>
            <ProjectTypeCard projectType={projectType} projectTypeIcon={projectTypeIcon}
                             projectTypeColor={projectTypeColor}/>
          </div>
          <div className="colors">
            <div className="title">Color</div>
            <div className="row flex-wrap">
              {colors.map((color: string, i: number) => {
                const setActiveBorder = color === projectTypeColor
                return (
                  <button
                    key={i}
                    name={color}
                    style={{border: setActiveBorder ? '2px solid ' + color : '2px solid rgba(216, 216, 216, 0.5)'}}
                    onClick={() => setProjectTypeColor(color)}
                    className="radio-button">
                    <div style={{backgroundColor: color && color}} className="radio-button-inner"/>
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
