import * as React from "react";

const SvgCalendar = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g
        transform="translate(-429.000000, -412.000000) translate(211.000000, 344.000000) translate(218.000000, 64.000000)">
        <path fill={props.color ? props.color : '#545E6B'}
              d="M13 3h1c.552 0 1 .448 1 1v2H1V4c0-.552.448-1 1-1h1V1h2v2h6V1h2v2zM1 7h14v7c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V7zm2 3v2h2v-2H3zm4 0v2h2v-2H7zm4 0v2h2v-2h-2z"
              transform="translate(0.000000, 4.000000)"/>
      </g>
    </svg>
  );
}

export default SvgCalendar;
