// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flags-drop-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -230px;
  top: -1px;
  max-height: 160px;
  width: 230px;
  min-height: 50px;
  overflow: auto;
  border: 1px solid #1b87e6;
  background-color: #fff;
  z-index: 99;
}
.flags-drop-down .flag-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 8px;
}
.flags-drop-down .flag-option:hover {
  background-color: #f5f5f5;
}
.flags-drop-down .flag-option .square {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid #545e6b;
  margin-right: 10px;
}
.flags-drop-down .flag-option .checked {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.flags-drop-down .flag-option .text {
  display: flex;
  align-items: center;
  color: #545e6b;
  font-size: 14px;
  font-weight: 400;
}
.flags-drop-down .flag-option .text svg {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/draft-js/components/floating-menu/flags-drop-down.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AACJ;AACI;EACE,yBAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,yBAAA;EACA,kBAAA;AAAN;AAGI;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AADN;AAII;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAFN;AAIM;EACE,kBAAA;AAFR","sourcesContent":[".flags-drop-down {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  right: -230px;\n  top: -1px;\n  max-height: 160px;\n  width: 230px;\n  min-height: 50px;\n  overflow: auto;\n  border: 1px solid #1b87e6;\n  background-color: #fff;\n  z-index: 99;\n\n  .flag-option {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    font-size: 16px;\n    padding: 8px;\n\n    &:hover {\n      background-color: #f5f5f5;\n    }\n\n    .square {\n      width: 20px;\n      height: 20px;\n      background-color: transparent;\n      border: 2px solid #545e6b;\n      margin-right: 10px;\n    }\n\n    .checked {\n      display: flex;\n      align-items: center;\n      margin-right: 10px;\n    }\n\n    .text {\n      display: flex;\n      align-items: center;\n      color: #545e6b;\n      font-size: 14px;\n      font-weight: 400;\n\n      svg {\n        margin-right: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
