import { daysLeftDateFormatter } from './days-left-date-formatter';

export default function DaysLeftDatePipe(date: string): JSX.Element {
  const dateFormatted: { diff: string, value: string } = daysLeftDateFormatter(date);

  return (
    <div className="row">
      <div style={{width: '120px', justifyContent: 'right'}}>
        <span>{dateFormatted.value}</span>
      </div>
    </div>
  );
}
