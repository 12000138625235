import * as React from "react";

const SvgMarketFlipped = (props: any) => {
  return (
    <svg style={{transform: "scaleX(-1)"}} width={16} height={16} viewBox="0 0 16 16" {...props}
         xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 5a3 3 0 010 6v3a1 1 0 01-2 0V5h2zM2 1.723a1 1 0 01.496.132L8 5v6l-5.504 3.145A1 1 0 011 13.277V2.723a1 1 0 011-1z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgMarketFlipped;
