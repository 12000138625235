import React from 'react';
import { IProgramGroup } from "../../model/program-group.interface";
import { Button } from "antd";
import SvgDelete from "../../../../../../../assets/icons/js/delete";
import { KeyMetricTypeEnum } from "../../../../project-management/key-metrics/model/key-metric-type.enum";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ProgramMetricInput } from "./program-metric-input/program-metric-input";

const GroupMetricsInput = (props: {
  group: IProgramGroup,
  removeGroup: (index: number) => void,
  index: number,
}) => {
  const {control, register, watch, formState} = useFormContext()
  const {errors} = formState;

  const {fields, remove, append} = useFieldArray({
    control: control,
    name: `groups.${props.index}.metrics`
  });


  const handleAddClick = () => {
    const programNumber = watch(`groups.${props.index}.metrics`).length + 1
    append({
      name: `Metric ${programNumber}`,
      code: `M${programNumber}`,
      type: KeyMetricTypeEnum.Numeric
    })
  };

  const handleRemove = () => {
    props.removeGroup(props.index)
  }

  const removeMetric = (index: number) => {
    remove(index)
  }

  return (
    <div style={{border: '2px solid #f5f5f5', padding: 10}}>
      <div className="row align-items-end" style={{gap: 10}}>
        <div className="outline-input-wrapper" style={{width: "70%"}}>
          <div className="outline-input-label">
            Group Label
          </div>
          <input type="text"
                 placeholder="Group label..."
                 className="outline-input"
                 {...register(`groups.${props.index}.name`)}
          />
        </div>
        <div className="input-options">
          <div className="option" onClick={() => handleRemove()}>
            <SvgDelete color="#F5F5F5"/>
          </div>
        </div>
        <Button onClick={() => handleAddClick()} type="ghost" className="add-metric-button"><span
          className='plus-icon'/> Add Metric</Button>
      </div>
      <ErrorMessage errors={errors}
                    name={`groups.${props.index}.name`}
                    render={({message}) => <small className="form-error">{message}</small>}/>
      <ErrorMessage errors={errors}
                    name={`groups.${props.index}.faultyMetrics`}
                    render={({message}) => <small className="form-error">{message}</small>}/>
      <div className="key-metrics column align-items-start" style={{marginTop: 10}}>
        {fields.map((metric: any, i: number) => {
          return (
            <ProgramMetricInput groupIndex={props.index}
                                removeMetric={removeMetric}
                                key={i}
                                index={i}
            />
          )
        })}
      </div>
    </div>
  );
};

export default GroupMetricsInput;
