// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-project-wrapper .link-project-header {
  gap: 40px;
}
.link-project-wrapper .link-project-header .project-placeholder {
  display: flex;
  gap: 30px;
  border-bottom: 3px solid #f5f5f5;
}
.link-project-wrapper .link-project-header .project-placeholder .cancel {
  cursor: pointer;
}
.link-project-wrapper .outline-input-wrapper {
  margin-top: 10px;
  width: 300px;
}
.link-project-wrapper .outline-input-wrapper .outline-input {
  padding-left: 23px;
}
.link-project-wrapper .outline-input-wrapper .search-logo {
  top: 7px;
  left: 5px;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/link-project-modal/link-project-modal.scss"],"names":[],"mappings":"AACE;EACE,SAAA;AAAJ;AAEI;EACE,aAAA;EACA,SAAA;EACA,gCAAA;AAAN;AAEM;EACE,eAAA;AAAR;AAKE;EACE,gBAAA;EACA,YAAA;AAHJ;AAKI;EACE,kBAAA;AAHN;AAMI;EACE,QAAA;EACA,SAAA;EACA,eAAA;AAJN","sourcesContent":[".link-project-wrapper {\n  .link-project-header {\n    gap: 40px;\n\n    .project-placeholder {\n      display: flex;\n      gap: 30px;\n      border-bottom: 3px solid #f5f5f5;\n\n      .cancel {\n        cursor: pointer;\n      }\n    }\n  }\n\n  .outline-input-wrapper {\n    margin-top: 10px;\n    width: 300px;\n\n    .outline-input {\n      padding-left: 23px;\n    }\n\n    .search-logo {\n      top: 7px;\n      left: 5px;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
