// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publish-story-wrapper {
  padding: 0 30px 20px 30px;
  gap: 20px;
}
.publish-story-wrapper .select-image-wrapper {
  width: 240px;
}
.publish-story-wrapper .select-image-wrapper .select-image-container {
  background-color: #f5f5f5;
  width: 200px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1b87e6;
}
.publish-story-wrapper .publish-story-form {
  width: 100%;
  gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/publish-story-dialog/publish-story-dialog.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;AACJ;AACI;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;AAAN;AAIE;EACE,WAAA;EACA,SAAA;AAFJ","sourcesContent":[".publish-story-wrapper {\n  padding: 0 30px 20px 30px;\n  gap: 20px;\n\n  .select-image-wrapper {\n    width: 240px;\n\n    .select-image-container {\n      background-color: #f5f5f5;\n      width: 200px;\n      height: 130px;\n      // cursor: pointer;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      border: 1px solid #1b87e6;\n    }\n  }\n\n  .publish-story-form {\n    width: 100%;\n    gap: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
