import { Divider, Modal } from 'antd'
import { useState } from 'react';
import SvgKnowledgeGraphRectangle from '../../../../../../../assets/icons/js/knowledge-graph-rectangle'
import projectEditApi from '../../../../api/project-edit-api';
import { KnowledgeGraphsDialog } from '../knowledge-graphs-dialog/knowledge-graphs-dialog';
import "./add-edit-nugget-dialog.scss"
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

const AddEditNuggetDialog = (props: {
  data: { description?: string, nuggetId?: number, projectId: number, nodes?: any[] },
  onCancel: (data?: { refetch: boolean }) => void,
  open: boolean
}) => {
  const schema = yup.object({
    description: yup.string().required('Key Finding title is required!').max(600, "Max length is 600!")
  })

  const {register, control, handleSubmit, formState: {errors}} = useForm({
    defaultValues: {
      description: props?.data?.description ?? ""
    },
    resolver: yupResolver(schema)
  })

  const description = useWatch({control, name: 'description'})

  const [nodes, setNodes] = useState(props.data?.nodes ? props.data.nodes : []);
  const [saveLoading, setSaveLoading] = useState(false);

  const [knowledgeGraphDialog, setKnowledgeGraphDialog] = useState<any>({
    visible: false,
    title: '',
    selectedNodes: '',
  });

  function closeKnowledgeGraphDialog(): void {
    setKnowledgeGraphDialog({
      ...knowledgeGraphDialog,
      visible: false,
    });
  }

  function openKnowledgeGraphDialog(): void {
    setKnowledgeGraphDialog({
      visible: true,
      title: description,
      selectedNodes: nodes,
    });
  }


  const onSave = (data: { description: string }) => {
    if (props.data?.nuggetId) {
      setSaveLoading(true);
      const payload = {
        projectNugget: {
          description: data.description,
          id: props.data.nuggetId,
          nodes: nodes.map((node: any) => node.id),
        },
        projectId: props.data.projectId
      }
      projectEditApi.updateNugget(payload).then(async () => {
        setSaveLoading(false);
        props.onCancel({refetch: true});
      })
    }

    if (!props.data?.nuggetId) {
      //create nugget
      setSaveLoading(true);
      const payload = {
        projectNugget: {
          description: data.description,
          nodes: nodes.map((node: any) => node.id),
        },
        projectId: props.data.projectId
      }
      projectEditApi.createNugget(payload).then(() => {
        setSaveLoading(false);
        //props.refetchProjectNuggets();
        props.onCancel({refetch: true});
      })
    }

  }

  const updateNodes = (data: { selectedNodes: any[], entityKey?: string }) => {
    setNodes(data.selectedNodes);
    closeKnowledgeGraphDialog()
  }

  return (
    <Modal
      open={props.open}
      width={800}
      title={props.data?.nuggetId ? "Edit key finding" : "Add key finding"}
      onCancel={() => props.onCancel()}
      onOk={handleSubmit(onSave)}
      okButtonProps={{loading: saveLoading}}
      okText={'Save'}
      cancelButtonProps={{type: 'link'}}>
      <div className="add-edit-nugget-wrapper">
        <div className='row align-items-center justify-space-center'>
          <div className="outline-input-wrapper nugget-title relative">
            <div className='outline-input-label'>Key finding title</div>
            <textarea
              className="outline-input"
              placeholder='type here'
              rows={5}
              maxLength={600}
              style={{paddingTop: 5}}
              {...register('description')}
            />
            <div className='character-count'>{description?.length ?? 0}/600</div>
          </div>
          <div onClick={() => openKnowledgeGraphDialog()} className='pointer row align-items-center'>
            <SvgKnowledgeGraphRectangle color="#F5F5F5"/>
          </div>
        </div>
        <ErrorMessage name={'description'} errors={errors} render={({message}) => <small
          className='form-error'>{message}</small>}/>

        <div className='row nugget-node-wrapper'>
          {nodes?.length ? (
            nodes.map(
              (node: any, i: number) => {
                return (
                  <span className="nugget-node" key={i}>{node.name}</span>
                );
              }
            )
          ) : (
            <span/>
          )}
        </div>
        {/*{*/}
        {/*  props.data?.nuggetId &&*/}
        {/*    <div className='overflow-evidence'>*/}
        {/*        <Evidence nuggetId={props.data.nuggetId}/>*/}
        {/*    </div>*/}
        {/*}*/}
      </div>


      <Divider style={{marginBottom: -15}}/>
      {knowledgeGraphDialog.visible && (
        <KnowledgeGraphsDialog
          data={knowledgeGraphDialog}
          onConfirm={updateNodes}
          onCancel={closeKnowledgeGraphDialog}
        />
      )}
    </Modal>
  )
}

export default AddEditNuggetDialog
