// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setup .main-title {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 16px 8px 20px 8px;
}
.setup .main-title h4 {
  color: #9b9b9b;
}
.setup .main-title > .title {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 16px 8px 20px 8px;
}
.setup .main-title > .title h4 {
  font-size: 14px;
  color: #9b9b9b;
}
.setup .main-title .hr {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}
.setup .page {
  margin-top: 15px;
  min-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/setup/setup.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;EACA,oBAAA;EACA,yBAAA;AADJ;AAII;EACE,cAAA;AAFN;AAeI;EACE,iBAAA;EACA,oBAAA;EACA,yBAAA;AAbN;AAeM;EACE,eAAA;EACA,cAAA;AAbR;AAiBI;EACE,WAAA;EACA,WAAA;EACA,yBAAA;AAfN;AAmBE;EACE,gBAAA;EACA,gBAAA;AAjBJ","sourcesContent":[".setup {\n\n  .main-title {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    margin: 16px 8px 20px 8px;\n    //font-size: 18px;\n\n    h4 {\n      color: #9b9b9b;\n      //font-size: 12px;\n      //font-weight: 500;\n      //line-height: 18px;\n      //text-align: left;\n    }\n\n    //.hr {\n    //  width: 100%;\n    //  height: 1px;\n    //  background-color: #d8d8d8;\n    //}\n\n    > .title {\n      padding-top: 10px;\n      padding-bottom: 10px;\n      margin: 16px 8px 20px 8px;\n\n      h4 {\n        font-size: 14px;\n        color: #9b9b9b;\n      }\n    }\n\n    .hr {\n      width: 100%;\n      height: 1px;\n      background-color: #d8d8d8;\n    }\n  }\n\n  .page {\n    margin-top: 15px;\n    min-height: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
