import React, { useEffect, useState } from 'react';
import '../insights.scss';
import './nuggets.scss';
import { Button, Empty, Spin, } from 'antd';
import SvgNugget from '../../../../../../assets/icons/js/nugget';
import AddEditNuggetDialog from './add-edit-nugget-dialog/add-edit-nugget-dialog';
import { useQuery } from 'react-query';
import projectEditApi from '../../../api/project-edit-api';
import { queryClient } from '../../../../../../index';
import NuggetCard from "./nugget-card/nugget-card";
import SvgAddBig from "../../../../../../assets/icons/js/addBig";
import Pagination from "../../../../../../_shared/components/pagination/pagination";

export function Nuggets(props: any) {
  const projectId = props.project.id;

  const [sortParam, setSortParam] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const params = {
    sort: sortParam,
    pageSize,
    page: currentPage
  }

  const projectNuggets = useQuery(['projectNuggets', projectId, params], () => projectEditApi.getProjectNuggets(projectId, params));

  const [addEditNuggetDialog, setAddEditNuggetDialog] = useState<any>({
    open: false,
    nodes: [],
    description: ""
  })


  const refetchProjectNuggets = () => {
    queryClient.invalidateQueries(['projectNuggets', projectId, params])
  }

  function openAddEditNuggetDialog(nuggetId?: number, nodes?: any[], description?: string): void {
    setAddEditNuggetDialog({
      open: true,
      nuggetId,
      nodes,
      projectId,
      description
    })
  }

  function closeAddEditNuggetDialog(data?: { refetch: boolean }): void {
    setAddEditNuggetDialog({
      open: false,
    })

    if (data?.refetch) {
      refetchProjectNuggets()
    }
  }

  return (
    <div className="project-nuggets-wrapper">
      <div className='row justify-space-between align-items-end' style={{marginBottom: 10}}>
        <div className='column'>
          <div className="row">
            <SvgNugget/>
            <h3 style={{marginLeft: 10}}>Key findings</h3>
          </div>
          <div className="row">
            <Button type='primary'
                    onClick={() => openAddEditNuggetDialog()}
                    icon={<span className="anticon"><SvgAddBig width={14} height={14} color={'#fff'}/></span>}>
              Add key finding
            </Button>
          </div>
        </div>
        {
          projectNuggets.data && projectNuggets.data?.pagination.totalElements > 0 &&
          <Pagination
            onChange={(page: number) => setCurrentPage(page)}
            currentPage={currentPage}
            totalItems={projectNuggets?.data?.pagination?.totalElements || 0}
            pageSize={5}/>
        }
      </div>

      <div className='nuggets-wrapper'>
        {
          projectNuggets?.isLoading ?
            <div className='w-100 column justify-space-center' style={{marginTop: 20}}>
              <Spin/>
            </div>
            :
            projectNuggets?.data &&
            projectNuggets?.data?.pagination?.totalElements > 0 ?
              projectNuggets?.data?.content.map((nugget) => {
                return <NuggetCard key={nugget.id}
                                   nugget={nugget}
                                   projectId={projectId}
                                   refetchProjectNuggets={refetchProjectNuggets}/>
              }) :
              <div className="column justify-space-center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
              </div>
        }
      </div>
      {
        addEditNuggetDialog.open && (
          <AddEditNuggetDialog
            open={addEditNuggetDialog.open}
            data={addEditNuggetDialog}
            onCancel={closeAddEditNuggetDialog}
          />
        )
      }
    </div>
  );
}
