import { EditorState } from "draft-js";

export const getBlocksMenuPosition = (editorState: EditorState) => {
  const blocksMenuHeight = 277;
  const position = {top: 0}
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const contentBlockElement = document.querySelector(`[data-offset-key="${anchorKey}-0-0"`) as HTMLElement | null;
  if (contentBlockElement) {
    position.top = contentBlockElement.offsetTop
    const distance = (window.innerHeight - contentBlockElement.getBoundingClientRect().top + contentBlockElement.offsetHeight);
    if (distance < blocksMenuHeight + 10) {
      const top = position?.top
      if (top) {
        position.top = Number(top) - blocksMenuHeight + 20
      }
    }
  }

  return position
}