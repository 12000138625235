import React from 'react';
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import { IStorySearchResult } from "../../../models/search-stories.interface";
import SvgStories from "../../../../../../../assets/icons/js/Stories";
import '../../notepads/notepad-search-card/notepad-search-card.scss'

const StoryCard = (props: { story: IStorySearchResult }) => {
  const navigate = useNavigate();
  const {story} = props;

  const navigateToStory = (id: number) => {
    navigate({
      pathname: '/stories/edit',
      search: qs.stringify({id})
    })

  }
  return (
    <div className='notepad-search-container'>
      <span className='notepad-matches'>{story.matches} {story.matches === 1 ? 'match' : 'matches'}</span>
      <div className='notepad-search-card-wrapper'>
        <div className='notepad-search-name'>
          <SvgStories width={20} height={20}/>
          <span className='notepad-search-name-title pointer'
                onClick={() => navigateToStory(story.s_id)}
                dangerouslySetInnerHTML={{__html: story.s_title}}/>
        </div>
        {/*<div className='notepad-search-description' dangerouslySetInnerHTML={{__html: notepad.content}}/>*/}
      </div>
    </div>
  )
}
export default StoryCard;
