import React from 'react';
import { FileTypeEnum } from '../../pages/projects/tabs/_shared/model/file-type.enum';
import SvgFiletypePpt from '../../assets/icons/js/FiletypePpt';
import SvgFiletypeXls from '../../assets/icons/js/FiletypeXls';
import SvgFiletypeImg from '../../assets/icons/js/SvgFiletypeImg';
import SvgFiletypePdf from '../../assets/icons/js/SvgFiletypePdf';
import SvgFiletypeDefault from '../../assets/icons/js/SvgFiletypeDefault';
import SvgFiletypeMedia from '../../assets/icons/js/SvgFiletypeMedia';
import SvgFiletypeDoc from '../../assets/icons/js/FiletypeDoc';

const FileTypePipe = (props: { value?: FileTypeEnum | null, width?: number, height?: number, className?: string }) => {
  const {value = SvgFiletypeDefault, width = 32, height = 32, className = ''} = props;

  const renderIcon = (IconComponent: React.ComponentType<any>) => (
    <div className={`file-type-pipe ${className}`}>
      <IconComponent width={width} height={height}/>
    </div>
  );

  switch (value) {
    case FileTypeEnum.DOC:
    case FileTypeEnum.DOCX:
      return renderIcon(SvgFiletypeDoc);
    case FileTypeEnum.XLS:
    case FileTypeEnum.XLSX:
      return renderIcon(SvgFiletypeXls);
    case FileTypeEnum.PPTX:
    case FileTypeEnum.PPT:
      return renderIcon(SvgFiletypePpt);
    case FileTypeEnum.PDF:
      return renderIcon(SvgFiletypePdf);
    case FileTypeEnum.PNG:
    case FileTypeEnum.JPG:
    case FileTypeEnum.JPEG:
    case FileTypeEnum.JFIF:
    case FileTypeEnum.SVG:
      return renderIcon(SvgFiletypeImg);
    case FileTypeEnum.AUDIO:
    case FileTypeEnum.VIDEO:
      return renderIcon(SvgFiletypeMedia);
    default:
      return renderIcon(SvgFiletypeDefault);
  }
};

export default FileTypePipe;
