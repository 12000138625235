import React from 'react';
import { useNavigate } from "react-router-dom";
import SvgInsightDesk from "../../../../../../../assets/icons/js/InsightDesk";
import qs from "query-string";

const SearchSurveyCard = ({survey}: any) => {
  const navigate = useNavigate();
  const navigateToSurvey = () => {
    navigate({pathname: "/surveys/details", search: qs.stringify({surveyId: survey?.surveyId, id: survey?.id})})
  }

  return (
    <div className='nugget-search-container'>
      <div className='nugget-search-card-wrapper'>
        <div>
          <SvgInsightDesk width={24} height={24}/>
        </div>

        <div className='nugget-search-card'>
          <div className='nugget-search-description pointer'
               onClick={navigateToSurvey}
               dangerouslySetInnerHTML={{__html: survey?.name}}/>
        </div>
      </div>
    </div>
  )
};

export default SearchSurveyCard;
