import './interview.scss';
import { CollaboratorStatusEnum, ICollaborator } from '../model/collaborator.interface';
import FlagIconsPipe from '../../../../../../_shared/helpers/flag-icons-pipe';
import React, { useContext } from 'react';
import GlobalContext from '../../../../../../store/global-context';
import { SvgIdle } from '../../../../../../assets/icons/js/svg-idle';
import { SvgComment } from '../../../../../../assets/icons/js/comment.svg';

export default function Collaboration(props: { collaborator: ICollaborator }): JSX.Element {
  const collaborator: ICollaborator = props.collaborator;
  const globalContext = useContext(GlobalContext);
  const user: any = globalContext?.user;

  if (collaborator.userId === user.id) {
    collaborator.displayName = 'My Notes';
  }

  function collaboratorStatus(status: CollaboratorStatusEnum): JSX.Element {
    if (status === CollaboratorStatusEnum.TYPING) {
      return (
        <div className="d-flex align-items-center">
          <span style={{marginRight: '10px'}}>typing...</span>
          <SvgComment/>
        </div>
      )
    } else {
      return (
        <div className="d-flex align-items-center w-100">
          <span style={{marginRight: '10px'}}>idle</span>
          <SvgIdle/>
        </div>
      )
    }
  }

  return (
    <div className="collaboration-card">
      <div className="row justify-space-between">
        <span className="display-name">{collaborator.displayName}</span>
        <span style={{paddingRight: '2px'}}>{collaboratorStatus(collaborator.status)}</span>
      </div>
      <div className="hr"/>
      <div className="display-highlight-container" style={{position: 'relative'}}>
        {collaborator.highlights && collaborator.highlights.map((highlight: any, i: number) => {
          if (highlight.nodes.length) {
            return (
              <div className="display-highlight-card" key={i}>
                {highlight?.nodes?.slice(0).reverse().map((node: any, i: number) => {
                  return (
                    <div className="node" key={node.id}>{node.name}</div>
                  )
                })}
              </div>
            )
          }
        })}
      </div>

      <div className="display-highlight-container" style={{position: 'relative'}}>
        {collaborator.highlights && collaborator.highlights.map((highlight: any, i: number) => {
          if (highlight.flags.length) {
            return (
              <div className="display-highlight-card" key={i}>
                {highlight?.flags?.slice(0).reverse().map((flag: any, i: number) => {
                  return (
                    <div className="flag" key={flag.id}>
                      <FlagIconsPipe icon={flag?.icon && flag?.icon}
                                     color={flag?.color && flag?.color}/>
                    </div>
                  )
                })}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}
