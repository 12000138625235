import React, { useState } from 'react';
import { Button, Divider, Modal, Select } from 'antd';
import SettingsApi from '../../../../api/settings-api';
import settingsApi from '../../../../api/settings-api';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { IProgram } from "../../programs/model/program.interface";

type IProps = {
  data: { name: string, programID: number, id: number | undefined, visible: boolean };
  onCancel: () => void,
  programs: IProgram[],
  onOk: (data?: { programID: number }) => void
}

export function AddEditFolderDialog(props: IProps): JSX.Element {
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const schema = yup.object({
    name: yup.string().required('Name is required!'),
    programID: yup.number().required('Program is required!'),
  });

  const formMethods = useForm<any>({
    defaultValues: props?.data ? {name: props.data.name, programID: props.data.programID} : {},
    resolver: yupResolver(schema)
  })
  const {control} = formMethods;
  const {errors} = formMethods.formState;
  const programID = useWatch({control, name: 'programID'})

  async function onFormSubmit(payload: { name: string, programId: number }) {
    setApiLoading(true);


    if (props.data.id) {
      settingsApi.editFolder(props.data.id, payload)
        .then(() => {
          setApiLoading(false);
          props.onOk({programID});
        }, () => {
          setApiLoading(false);
        });
    } else {
      SettingsApi.createFolder(payload)
        .then(() => {
          setApiLoading(false);
          props.onOk({programID});
        }, () => {
          setApiLoading(false);
        });
    }
  }

  return (
    <Modal
      title={props.data?.id ? "Edit folder" : "Add folder"}
      open={props.data.visible}
      bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
      onCancel={() => (props.onCancel())}
      okText='Save'
      cancelText='Cancel'
      okButtonProps={{loading: apiLoading}}
      cancelButtonProps={{type: 'link'}}
      onOk={formMethods.handleSubmit(onFormSubmit)}
      centered
      width={850}
    >
      <div className="add-edit-programs-dialog-wrapper">
        <FormProvider {...formMethods}>
          <div className='add-edit-programs-dialog-form'>
            <div className='outline-input-wrapper'>
              <div className='outline-input-label'>
                Name
              </div>
              <input
                type='text'
                className='outline-input'
                placeholder='Enter name'
                {...formMethods.register('name')}
              />
              <ErrorMessage errors={errors} name={'name'} render={({message}) => <small
                className="form-error">{message}</small>}/>
            </div>
            <div className='outline-input-wrapper'>
              <div className='outline-input-label'>
                Program
              </div>
              <Controller control={control}
                          name={'programID'}
                          render={({field}) => <Select
                            placeholder={"Select program"}
                            style={{fontSize: 12}}
                            {...field}>
                            {props.programs && props.programs.map((program, i) => {
                              return <Select.Option value={program.id} key={i}>{program.name}</Select.Option>
                            })}
                          </Select>}/>
              {/*<ErrorMessage errors={errors} name={'programID'} render={({message}) => <small*/}
              {/*  className="form-error">{message}</small>}/>*/}
            </div>
          </div>
        </FormProvider>
        <Divider style={{marginBottom: -20, marginTop: 20}}/>
      </div>
    </Modal>
  );
}
