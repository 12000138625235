import React, { ChangeEvent } from 'react';
import './switch.scss';
import { LoadingOutlined } from '@ant-design/icons';

type IProps = {
  name: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  onClick?: () => void;
  loading?: boolean;
}

const Switch = ({checked, onChange, loading, onClick, name}: IProps) => {
  return (
    <div className="switch-wrapper" data-testid="switch-wrapper">
      <input checked={checked}
             onClick={onClick}
             role={"switch"}
             type="checkbox"
             id={name}
             name={name}
             data-testid="switch-input"
             onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.checked)}/>
      <label htmlFor={name} className="switch-button"/>
      {
        loading && <div className="switch-loading" data-testid="switch-loading" style={{
          left: checked ? "unset" : 3,
          right: checked ? 3 : "unset"
        }}>
          <LoadingOutlined style={{fontSize: 10, color: "#545e6b"}}/>
        </div>
      }
    </div>
  );
};

export default Switch;
