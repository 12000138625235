import React from 'react';
import { EditorState } from "draft-js";
import RecommendationThumbsUp from "./reccomendation-thumbs-up/recommendation-thumbs-up";

type RecommendationHighlightsContainerProps = {
  readOnly: boolean;
  displayTextHighlights: any;
}

const RecommendationHighlightsContainer = (props: RecommendationHighlightsContainerProps) => {
  return (
    <div data-testid="recommendation-highlights-container" className="relative"
         style={props?.readOnly ? {right: -10, top: -90} : {right: -10}}>
      {props.displayTextHighlights?.length &&
        props.displayTextHighlights?.map((value: any, index: number) => {
          return <RecommendationThumbsUp readOnly={props.readOnly}
                                         index={index}
                                         value={value}
                                         key={index}
          />
        })
      }
    </div>
  );
};

export default RecommendationHighlightsContainer;
