import * as React from 'react';

const SvgFlagThunder = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m10.017 0-1 6h3.984c.252 0 .495.107.68.3.404.423.428 1.135.052 1.59L6.02 16l.999-6h-4.02a.941.941 0 0 1-.681-.302c-.404-.424-.426-1.136-.05-1.59L10.017 0z"
        fill={props.color ? props.color : "#9B9B9B"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlagThunder;
