export function hasDuplicateProperty(array: any[], propertyName: string) {
  let propertyNames = new Set();

  for (let i = 0; i < array.length; i++) {
    let obj = array[i];

    if (obj.hasOwnProperty(propertyName)) {
      if (propertyNames.has(obj[propertyName])) {
        // Found a duplicate property value
        return true;
      } else {
        propertyNames.add(obj[propertyName]);
      }
    }
  }

  // No duplicates found
  return false;
}
