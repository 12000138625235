// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-button-wrapper .ant-btn-icon-only {
  padding: 0 !important;
}

.zip-import .upload-file-input {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  border: 1px dashed #d8d8d8;
  width: 100%;
}
.zip-import .upload-file-input .info-text {
  font-size: 12px;
  font-weight: 400;
}
.zip-import .upload-file-input .ant-upload-drag:first-child {
  background-color: transparent;
  padding: 6px 16px;
  border: 0;
  cursor: default;
}
.zip-import .upload-file-input .ant-upload-btn {
  padding: 2px 0 !important;
}
.zip-import .upload-file-input .ant-upload-list {
  display: none;
}
.zip-import .isHover {
  border-color: #1b87e6;
  background-color: rgba(27, 135, 230, 0.08);
}
.zip-import .upload-file-button .ant-upload-list {
  display: none;
}
.zip-import .uploaded {
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/setup/integrations/zip-import/zip-import.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;;AAKE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,WAAA;AAFJ;AAII;EACE,eAAA;EACA,gBAAA;AAFN;AAKI;EACE,6BAAA;EACA,iBAAA;EACA,SAAA;EACA,eAAA;AAHN;AAMI;EACE,yBAAA;AAJN;AAOI;EACE,aAAA;AALN;AASE;EACE,qBAAA;EACA,0CAAA;AAPJ;AAWI;EACE,aAAA;AATN;AAaE;EACE,yBAAA;EACA,yBAAA;EACA,cAAA;AAXJ","sourcesContent":[".dropdown-button-wrapper {\n  .ant-btn-icon-only {\n    padding: 0 !important;\n  }\n}\n\n.zip-import {\n  .upload-file-input {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 16px;\n    font-size: 16px;\n    font-weight: 500;\n    border: 1px dashed #d8d8d8;\n    width: 100%;\n\n    .info-text {\n      font-size: 12px;\n      font-weight: 400;\n    }\n\n    .ant-upload-drag:first-child {\n      background-color: transparent;\n      padding: 6px 16px;\n      border: 0;\n      cursor: default;\n    }\n\n    .ant-upload-btn {\n      padding: 2px 0 !important;\n    }\n\n    .ant-upload-list {\n      display: none;\n    }\n  }\n\n  .isHover {\n    border-color: #1b87e6;\n    background-color: rgba(27, 135, 230, 0.08);\n  }\n\n  .upload-file-button {\n    .ant-upload-list {\n      display: none;\n    }\n  }\n\n  .uploaded {\n    border: 1px solid #f5f5f5;\n    background-color: #f5f5f5;\n    color: #545e6b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
