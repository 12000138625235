import React from 'react';
import { Form } from 'antd';

export const EditableContext = React.createContext(null);

export const EditableRow: React.FC = ({...props}) => {
  const [form]: any = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
