import { AxiosResponse } from "axios";
import { axiosClient } from "../../../axios-client";
import { environment } from "../../../environment";
import { IPublishedForms } from "../../home/models/published-forms.interface";
import { IMetaTag } from "../../settings/settings-tabs/taxonomy/meta-tags/model/meta-tag.interface";
import { IStory, IStoryData } from "../model/story-interface";
import { INuggetData } from "../../projects/models/nugget.interface";

class StoriesApi {
  private baseUrl: string = `${environment.backendUrl}`;

  async getStories(queryParams: {
    status: "DRAFT" | "PUBLISHED",
    page: number,
    pageSize: number
  }): Promise<IStoryData> {
    try {
      const response: AxiosResponse<IStoryData> = await axiosClient.get<IStoryData>(
        `${this.baseUrl}/story`,
        {
          params: queryParams
        }
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async createStory(payload: any): Promise<IStory> {
    try {
      const response: AxiosResponse<IStory> = await axiosClient.post<IStory>(
        `${this.baseUrl}/story`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async updateStory(id: number, payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        `${this.baseUrl}/story/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error
    }

  }

  async getStory(id: number | undefined): Promise<IStory> {
    try {
      const response: AxiosResponse<IStory> = await axiosClient.get(
        `${this.baseUrl}/story/details/${id}`
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async getMetaTags(): Promise<IMetaTag[]> {
    try {
      const response: any = await axiosClient.get<IPublishedForms>(`/metatags`);
      return response.data;
    } catch (error) {
      throw error
    }

  }

  async publishStory(id: number, payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/story/${id}/publish`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error
    }

  }

  async draftStory(id: number): Promise<any> {
    try {
      const response: any = await axiosClient.put<any>(
        `${this.baseUrl}/story/${id}/draft`
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async deleteStory(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(`${this.baseUrl}/story/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async archiveStory(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(`${this.baseUrl}/story/archive/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async restoreStory(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(`${this.baseUrl}/story/restore/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getArchivedStories(params: { page: number, pageSize: number }): Promise<IStoryData> {
    try {
      const response: AxiosResponse<IStoryData> = await axiosClient.get<IStoryData>(
        `${this.baseUrl}/story/archived`,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async getStoriesCounts(): Promise<{ draft: number, published: number, archived: number }> {
    try {
      const response: AxiosResponse<{ draft: number, published: number, archived: number }> = await axiosClient.get<{
        draft: number,
        published: number,
        archived: number
      }>(
        `${this.baseUrl}/story/count`
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async getLinkedKeyFindings(projectId: number, page: number, pageSize: number): Promise<INuggetData> {
    try {
      const response: AxiosResponse<INuggetData> = await axiosClient.get<INuggetData>(
        `${this.baseUrl}/projects/nuggets/${projectId}/public`,
        {
          params: {
            page,
            pageSize
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async getAiGeneratedContent(storyId: number, payload: {
    selectionDetails: any,
    useCase: string,
    tone?: string,
    language?: string
  }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/story/ai-transform/${storyId}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async getAiGeneratedContentFromPrompt(storyId: number, payload: {
    useCase: string,
    prompt?: string,
    projectId?: number
  }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        `${this.baseUrl}/story/ask-ih-generate/${storyId}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async submitAdditionalFeedback(chatId: number, payload: { additionalFeedback: string }) {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/langchain/update-chat/${chatId}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error
    }
  }
}


export default new StoriesApi();
