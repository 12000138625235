import * as React from 'react';

const SvgFlagTarget = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.07 7A7.005 7.005 0 0 1 7 1.07V0h2v1.07A7.005 7.005 0 0 1 14.93 7H16v2h-1.07A7.005 7.005 0 0 1 9 14.93V16H7v-1.07A7.005 7.005 0 0 1 1.07 9H0V7h1.07zM3.1 7H4v2h-.9A5.006 5.006 0 0 0 7 12.9V12h2v.9A5.006 5.006 0 0 0 12.9 9H12V7h.9A5.006 5.006 0 0 0 9 3.1V4H7v-.9A5.006 5.006 0 0 0 3.1 7zM8 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
        fill={props.color ? props.color : "#9B9B9B"}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default SvgFlagTarget;
