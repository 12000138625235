import React from 'react';
import { Button, Modal } from 'antd';
import SvgDelete from '../../../../../../assets/icons/js/delete';
import SvgAdd from '../../../../../../assets/icons/js/add';
import { DebounceInput } from 'react-debounce-input';
import './add-edit-authorized-email.dialog.scss';
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  authorizedEmail: yup.array().of(
    yup.object().shape({
      emailDomain: yup.string().matches(/^@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Please enter a valid email domain, ex. \'@email.com\'.')
    })
  )
});

export function AddEditAuthorizedEmailDialog(props: any): JSX.Element {
  const {handleSubmit, control, formState: {errors}} = useForm<any>({
    defaultValues: {
      authorizedEmail: [{emailDomain: ''}]
    },
    resolver: yupResolver(schema)
  })

  const {fields, append, remove} = useFieldArray({
    control, name: 'authorizedEmail', keyName: "key"
  })


  const onConfirm = (data: { authorizedEmail: { emailDomain: string }[] }) => {
    props.onConfirm({
      modal: true,
      authorizedEmail: data.authorizedEmail
    });
  }

  return (
    <Modal
      title="Authorized email domains"
      open={props.data.visible}
      onCancel={() => (props.onCancel())}
      centered
      width={765}
      footer={[
        <Button key="submit" type="primary" onClick={handleSubmit(onConfirm)}>
          {props.data.okText ? props.data.okText : 'Save'}
        </Button>,
      ]}>
      <div className="add-edit-authorized-email">
        {fields && fields.map((option, i: number) => {
          return (
            <div className="options" key={option.key}>
              <div className="option row">
                <div className="outline-input-wrapper">
                  <Controller control={control} name={`authorizedEmail.${i}.emailDomain`}
                              render={({field: {ref, ...field}}) =>
                                (
                                  <>
                                    <DebounceInput
                                      className="outline-input"
                                      placeholder="@domain.com"
                                      debounceTimeout={600}
                                      inputRef={ref}
                                      {...field}/>
                                    {errors.authorizedEmail && errors.authorizedEmail[i]?.emailDomain &&
                                        <small className="form-error">
                                          {errors.authorizedEmail[i].emailDomain.message}
                                        </small>
                                    }
                                  </>

                                )
                              }
                  />
                </div>
                <div className="input-options row">
                  <div className="option" onClick={() => append({emailDomain: ''})}>
                    <SvgAdd color="#F5F5F5"/>
                  </div>
                  {
                    fields.length !== 1 &&
                      <div className="option" onClick={() => remove(i)}>
                          <SvgDelete color="#F5F5F5"/>
                      </div>
                  }
                </div>
              </div>

            </div>
          );
        })}
      </div>
    </Modal>
  );
}
