import React, { ReactElement, useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import './draggable-status.scss';
import SvgAdd from '../../../../../assets/icons/js/add';
import SvgDelete from '../../../../../assets/icons/js/delete';
import { ColorResult } from 'react-color';
import SvgDrag from '../../../../../assets/icons/js/drag';
import { IWorkflowStatus } from '../model/workflow.interface';
import { WorkflowStageEnum } from '../model/workflow-stage.enum';
import SvgLock from '../../../../../assets/icons/js/lock';
import CustomColorPicker from "../../../../../_shared/components/color-picker/color-picker";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface DraggableStatusProps {
  index: number;
  appendStatus: (stage: WorkflowStageEnum) => void,
  removeStatus: (index: number) => void
}

const DraggableStatus: React.FC<DraggableStatusProps> = ({
                                                           index,
                                                           appendStatus,
                                                           removeStatus
                                                         }): ReactElement => {

  const {control, register, formState: {errors}, setValue, clearErrors} = useFormContext()
  const formName = `statuses.${index}`;
  const status = useWatch({control, name: formName})
  const statuses: IWorkflowStatus[] = useWatch({control, name: 'statuses'})

  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);

  const handleColorChange = (color: ColorResult) => {
    setValue(`${formName}.color`, color.hex)
    clearErrors(`${formName}.color`)
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  return (
    <Draggable draggableId={String(status.id)} index={index} isDragDisabled={status.lock}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="column"
          style={{
            zIndex: 99999999,
            ...provided.draggableProps.style,
            backgroundColor: snapshot.isDragging ? 'lightblue' : 'transparent',
          }}>
          <div className="workflow-row justify-space-between">
            <div className='w-50 d-flex align-items-center'>
              <button {...provided.dragHandleProps} className="icon-button"
                      style={{cursor: status.lock ? 'default' : 'grab', marginRight: '15px'}}>
                {status.lock ? <SvgLock/> : <SvgDrag/>}
              </button>
              <div className="color-picked pointer" onClick={toggleColorPicker}>
                <div style={{backgroundColor: status.color}} className="color-picked-inner"/>
              </div>
              {colorPickerVisible &&
                <div className="color-picker-wrapper">
                  <div
                    className="color-picker-cover"
                    onClick={toggleColorPicker}
                    onKeyDown={toggleColorPicker}
                    role="button"
                    tabIndex={0}
                    aria-label="Save"
                  />
                  <CustomColorPicker
                    color={status.color}
                    disableAlpha={true}
                    onChange={(colorResult: ColorResult) => handleColorChange(colorResult)}
                  />
                </div>
              }
              <div className='relative column flex'>
                <input
                  className="w-100 outline-input workflow-name"
                  type="text"
                  placeholder="Name"
                  {...register(`${formName}.name`)}
                />
              </div>
            </div>
            <div className='d-flex align-items-center justify-space-between'>
              <div className="input-options">
                <div className={statuses.length > 7 ? "option addDisabled" : "option"}
                     onClick={() => appendStatus(status.stage)}>
                  <SvgAdd color="#F5F5F5"/>
                </div>
                {
                  statuses.length !== 1 &&
                  <div className={status.lock ? "option deleteDisabled" : "option"}
                       onClick={() => removeStatus(index)}>
                    <SvgDelete color="#F5F5F5"/>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="column" style={{marginLeft: 82}}>
            <ErrorMessage errors={errors} name={`${formName}.name`} render={({message}) => <small
              className="form-error">{message}</small>}/>
            <ErrorMessage errors={errors} name={`${formName}.color`} render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableStatus;
