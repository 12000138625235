import './project-card.scss';
import { Link } from "react-router-dom";
import FormattedDate from "../../../_shared/helpers/formatted-date";
import React, { useRef } from "react";
import { Collapse, Dropdown, MenuProps } from "antd";
import { DetailsKeyMetrics } from "../project-edit/tabs/insights/components/details-key-metrics";
import { DetailsProgramKeyMetrics } from "../project-edit/tabs/insights/components/details-program-key-metrics";
import SvgTrash from "../../../assets/icons/js/Trash";
import SvgMove from "../../../assets/icons/js/move";

const {Panel} = Collapse;

export function ProjectCard(props: any): JSX.Element {
  let content = useRef<HTMLDivElement>(null);
  let collapse = useRef<HTMLDivElement>(null);
  const project = props.project;
  const openMoveDialog: Function = props.openMoveDialog;
  const openConfirmationDialog: Function = props.openConfirmationDialog;

  const menuItems: MenuProps['items'] = [
    {
      key: "move",
      label: (
        <div className="d-flex align-items-center" style={{gap: 10}} onClick={() => openMoveDialog(project)}>
          <SvgMove width={18} height={18}/> Move to Program
        </div>
      ),
    },
    {
      key: "delete",
      label: (
        <div className="d-flex align-items-center" style={{gap: 10}} onClick={() => openConfirmationDialog(project)}>
          <SvgTrash width={18} height={18}/> Delete
        </div>
      ),
    },
  ];

  const collapseChange = (items: string | string[]) => {
    if (content.current && collapse.current) {
      if (items.length) {
        content.current.style.height = '0';
        collapse.current.style.marginTop = '-14px';
      } else {
        setTimeout(() => {
          if (content.current && collapse.current) {
            content.current.style.height = 'auto';
            collapse.current.style.marginTop = '0';
          }
        }, 100);
      }
    }
  }

  return (
    <div className="project-card">
      <div className="content" ref={content}>
        <div className="project-header">
          <Link to={"/projects/edit?id=" + project.id} className="project-name">
            {project.name}
          </Link>

          <Dropdown trigger={["click"]} menu={{items: menuItems}}>
            <div className="pointer">
              <i className="bi bi-three-dots" style={{fontSize: 18}}/>
            </div>
          </Dropdown>
        </div>
        <div className="project-description">
          {project.description}
        </div>
        <div className="project-date-created">
          <span>Date Created: </span>
          <FormattedDate date={project.createdAt} formatString="MMM d, yyyy"/>
        </div>
        <div className="project-date-created">
          <span>Program: </span>
          <span>{project.program}</span>
        </div>
        <div className="project-date-created">
          <span>Type: </span>
          <span>{project?.form?.title}</span>
        </div>
        <div className="project-date-created">
          <span>Owner: </span>
          <span>{project?.owner?.displayName}</span>
        </div>
      </div>

      <div ref={collapse} style={{zIndex: 3}}>
        <Collapse style={{margin: '0 -18px 0 -18px'}} className="project-metrics"
                  onChange={collapseChange}>
          <Panel header={<>Metrics</>} key="1">
            <DetailsKeyMetrics project={project} viewOnly={true}/>
            {
              !!project?.projectProgramMetrics?.length &&
                <DetailsProgramKeyMetrics project={project} viewOnly={true}/>
            }
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}
