import { convertToRaw, EditorState } from 'draft-js';

export function RetrieveHighlightsForToolbar(editorState: EditorState) {
  const contentState = convertToRaw(editorState.getCurrentContent());

  const nodes = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'NODE').map((v: any) => v[1].data.nodes)
    .map(obj => ({nodes: obj?.nodes.map((n: any) => ({id: n.id, name: n?.name}))}));

  const nuggets = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'EVIDENCE').map((v: any) => v[1].data)
    .map(obj => ({nuggets: obj?.nuggets.map((n: any) => ({id: n.id, name: n?.description}))}));


  const flags = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'FLAG').map((v: any) => v[1].data)
    .map((obj: any) => ({flags: obj?.flags}));

  return {nodes, nuggets, flags};
}
