// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-sec {
  font-size: 16px;
  color: #545e6b;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.description {
  font-size: 14px;
  color: #545e6b;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.error-container {
  background-color: #FBE8E9;
  font-size: 18px;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #D0051F;
  align-items: center;
  border: 0.5px solid rgba(208, 2, 28, 0.402081);
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/confirmation-dialog/confirmation-dialog.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,SAAA;EACA,iBAAA;EACA,oBAAA;EACA,cAAA;EACA,mBAAA;EACA,8CAAA;AACF","sourcesContent":[".title-sec {\n  font-size: 16px;\n  color: #545e6b;\n  font-weight: 500;\n  line-height: 16px;\n  text-align: left;\n}\n\n.description {\n  font-size: 14px;\n  color: #545e6b;\n  font-weight: 400;\n  line-height: 18px;\n  text-align: left;\n}\n\n.error-container {\n  background-color: #FBE8E9;\n  font-size: 18px;\n  display: flex;\n  justify-content: center;\n  gap: 20px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  color: #D0051F;\n  align-items: center;\n  border: 0.5px solid rgba(208, 2, 28, 0.402081);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
