export function treeFilterIncludeSearch(array: any[], key: string, value: string) {
  return array?.some(o => o[key].toLowerCase().includes(value.toLowerCase()))
    ? array
    : array?.reduce((r, o) => {
      if (o[key].toLowerCase().includes(value.toLowerCase())) return [...r, o];
      if (o.nodes) {
        const nodes = treeFilterIncludeSearch(o.nodes, key, value);
        if (nodes.length) r.push({...o, nodes});
      }
      return r;
    }, []);
}
