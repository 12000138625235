import { environment } from '../environment';
import { AxiosResponse } from 'axios';
import { axiosClient } from '../axios-client';

class AuthApi {
  private baseUrl: string = `${environment.backendUrl}`;

  async login(qpCode: string): Promise<any> {
    const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/auth/signin/${qpCode}`);
    return response.data;
  }

  async logout(qpCode: string): Promise<any> {
    const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/auth/signout/${qpCode}`);
    return response.data;
  }

  async impersonateUser(organizationId: string, impersonatedId: string): Promise<null> {
    const response: AxiosResponse<any> = await axiosClient.post<null>(`${this.baseUrl}/auth/impersonate`, {
      organizationId: Number(organizationId),
      impersonatedId
    });

    return response.data;
  }

  async stopImpersonate(): Promise<null> {
    const response: AxiosResponse<null> = await axiosClient.post<null>(`${this.baseUrl}/auth/stop-impersonate`);
    return response.data;
  }

  async getProfile(): Promise<any> {
    const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/organizations/profile`);
    return response.data;
  }

}

export default new AuthApi();
