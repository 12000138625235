import { createContext, useEffect, useState } from 'react';
import { IProjectType, IWidget } from '../model/project-type.interface';
import ProjectTypeApi from '../api/project-type-api';
import projectTypeApi from '../api/project-type-api';
import { MainFormPositionEnum } from '../model/main-form-position.enum';
import generateRandomIcon from '../../../../../../_shared/helpers/random-icon';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';


const ProjectTypeContext = createContext({
  loader: true,
  saving: false,
  saved: false,
  id: 0,
  selectedWorkflow: {id: "", name: ""},
  workflows: [],
  projectType: {
    title: 'Untitled',
    icon: '',
    color: '',
    status: '',
    widgets: [
      {
        id: 0,
        type: '',
        label: '',
        question: '',
        required: false,
      }
    ]
  },
  isOptionValueSame: {index: NaN, isSame: false},
  setProjectId: (id: number) => {
  },
  setProjectTypeTitle: (title: string) => {
  },
  setProjectTypeIconColor: (icon: string, color: string) => {
  },
  setProjectStatus: (status: string) => {
  },
  onWorkflowChange: (id: string) => {
  },
  addWidget: (widget: IWidget | any, index: number) => {
  },
  copyWidget: (widget: IWidget, index: number) => {
  },
  deleteWidget: (widget: IWidget, index: number) => {
  },
  addWidgetOption: (widget: IWidget, index: number) => {
  },
  widgetQuestionChanged: (widget: IWidget, value: string, index: number) => {
  },
  widgetLabelChanged: (widget: IWidget, value: string, index: number) => {
  },
  widgetIsRequiredChanged: (widget: IWidget, value: boolean, index: number) => {
  },
  choiceTypeWidgetOption: (widget: IWidget, value: boolean, index: number) => {
  },
  deleteWidgetOption: (widget: IWidget, i: number, optionIndex: number) => {
  },
  optionValueChanged: (widget: IWidget, index: number, optionIndex: number, value: string) => {
  },
});

export function ProjectTypeContextProvider(props: any) {
  const navigate = useNavigate();
  const [disableUpdate, setDisableUpdate] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState({id: "", name: ""});
  const [projectType, setProjectType] = useState<IProjectType>({
    title: 'Untitled',
    icon: '',
    color: '',
    status: '',
    workflowId: "",
    widgets: [
      {
        id: 0,
        type: '',
        label: '',
        question: '',
        required: false,
      }
    ]
  });

  const [isOptionValueSame, setIsOptionValueSame] = useState({index: NaN, isSame: false});

  const context = {
    loader,
    saving,
    saved,
    id,
    projectType,
    isOptionValueSame,
    setProjectId,
    setProjectTypeTitle,
    setProjectStatus,
    setProjectTypeIconColor,
    addWidget,
    copyWidget,
    deleteWidget,
    widgetQuestionChanged,
    widgetLabelChanged,
    widgetIsRequiredChanged,
    choiceTypeWidgetOption,
    addWidgetOption,
    deleteWidgetOption,
    optionValueChanged,
    workflows,
    selectedWorkflow,
    onWorkflowChange
  };

  useEffect(() => {
    if (projectType) {
      createUpdateProjectType();
    }
  }, [projectType]);

  useEffect(() => {
    projectTypeApi.getWorkflows().then((response: any) => {
      setWorkflows(response?.workflows);
    })
  }, [])

  useEffect(() => {
    if (workflows.length && projectType) {
      const workflow: any = workflows.find((workflow: any) => workflow?.id === projectType.workflowId)
      setSelectedWorkflow(workflow);
    }
  }, [workflows, projectType])


  function onWorkflowChange(id: string) {
    const workflow: any = workflows.find((workflow: any) => workflow?.id === id);
    setSelectedWorkflow(workflow)

    enableUpdate();
    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, workflowId: workflow.id};
    });
  }

  function setProjectId(projectId: number): void {
    if (projectId) {
      setId(projectId);

      if (disableUpdate) {
        getById(projectId);
      }

    } else {
      const generatedRandomIconAndColor = generateRandomIcon();

      setProjectType(() => {
        return {
          title: 'Untitled',
          icon: generatedRandomIconAndColor?.icon || '',
          color: generatedRandomIconAndColor?.color || '',
          status: '',
          widgets: []
        }
      });
    }
  }

  function getById(id: number): void {
    ProjectTypeApi.getById(id)
      .then((response: IProjectType) => {
        setProjectType(response);
        setLoader(false);
      });
  }

  function setProjectTypeTitle(title: string): void {
    enableUpdate();

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, title};
    });
  }

  function setProjectTypeIconColor(icon: string, color: string): void {
    enableUpdate();

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, icon, color};
    });
  }

  function setProjectStatus(status: string): void {
    enableUpdate();

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, status};
    });
  }

  function addWidget(widget: IWidget, index: number): void {
    enableUpdate();

    widget.question = '';
    widget.label = '';

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    const newWidgets: IWidget[] = [];

    if (widget.position === MainFormPositionEnum.TOP) {
      widgets.splice(index, 0, {
        ...widget,
        type: widget.type,
      });

      widgets.forEach((widget: IWidget) => {
        const wE: IWidget = {
          ...widget,
        };

        newWidgets.push(wE);
      });
    } else {
      widgets.splice(index + 1, 0, {
        ...widget,
        type: widget.type,
      });

      widgets.forEach((widget: IWidget) => {
        const wE: IWidget = {
          ...widget,
        };

        newWidgets.push(wE);
      });
    }

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: newWidgets};
    });
  }

  function copyWidget(widget: IWidget, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    const newWidgets: IWidget[] = [];
    // @ts-ignore
    delete widget["id"];

    widgets.splice(index + 1, 0, {
      ...widget,
      type: widget.type,
    });

    widgets.forEach((widget: IWidget) => {
      const wE: IWidget = {
        ...widget,
      };

      newWidgets.push(wE);
    });

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: newWidgets};
    });
  }

  function deleteWidget(widget: IWidget, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));

    widgets.splice(index, 1);

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: widgets};
    });
  }

  function addWidgetOption(widget: IWidget, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    if (widgets.length) {
      // @ts-ignore
      widgets[index].options.push('');
    }

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: widgets};
    });
  }

  function widgetQuestionChanged(widget: IWidget, value: string, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    if (widgets.length) {
      //@ts-ignore
      widgets[index].question = value;
    }

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: widgets};
    });
  }

  function widgetLabelChanged(widget: IWidget, value: string, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    if (widgets.length) {
      //@ts-ignore
      widgets[index].label = value;
    }

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: widgets};
    });
  }

  function widgetIsRequiredChanged(widget: IWidget, value: boolean, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    if (widgets.length) {
      //@ts-ignore
      widgets[index].required = value;
    }

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: widgets};
    });
  }

  function choiceTypeWidgetOption(widget: IWidget, value: boolean, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    if (widgets.length) {
      //@ts-ignore
      widgets[index].multiple = value;
    }

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: widgets};
    });
  }

  function deleteWidgetOption(widget: IWidget, optionIndex: number, index: number): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    // @ts-ignore
    widgets[index].options.splice(optionIndex, 1);

    setProjectType((prevProjectTypes: IProjectType) => {
      return {...prevProjectTypes, widgets: widgets};
    });
  }

  function optionValueChanged(widget: IWidget, index: number, optionIndex: number, value: string): void {
    enableUpdate();

    const widgets: IWidget[] = JSON.parse(JSON.stringify(projectType.widgets));
    const options: string[] | undefined = widgets[index]?.options;

    if (options?.length) {
      setIsOptionValueSame({
        index: optionIndex,
        isSame: options?.some(((choice: string) => choice === value)) || false
      });

      options[optionIndex] = value;

      setProjectType((prevProjectTypes: IProjectType) => {
        return {...prevProjectTypes, widgets: widgets};
      });
    }
  }

  function createUpdateProjectType(): void {
    const projectTypeValid: boolean = !!projectType.icon && !!projectType.color;

    if (projectTypeValid && !id) {
      setSaving(true);
      setSaved(false);

      ProjectTypeApi.create(projectType)
        .then((response: IProjectType) => {
          if (response?.id) {
            setDisableUpdate(true);
            setProjectId(response?.id);
            navigate({pathname: '', search: qs.stringify({id: response.id})}, {replace: true})
            setProjectType(response);
          }

          setSaving(false);
          setSaved(true);
        });

      return;
    }

    if (projectTypeValid && !disableUpdate) {
      setSaving(true);
      setSaved(false);

      ProjectTypeApi.update(id, projectType)
        .then(() => {
          setSaving(false);
          setSaved(true);
        });
    }
  }

  function enableUpdate(): void {
    if (disableUpdate) {
      setDisableUpdate(false);
    }
  }

  return <ProjectTypeContext.Provider value={context}>
    {props.children}
  </ProjectTypeContext.Provider>
}

export default ProjectTypeContext;
