import { Input, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import _ from 'lodash';
import ProjectApi from "../../api/project-api";

type MappedDataType = {
  id: number;
  name: string;
  [metricName: string]: { id: number; value: string } | number | string;
};

const mapData = (data: any[]) => {

  return data.map((item) => {
    const mappedData: MappedDataType = {
      id: item.id,
      name: item.name,
    };

    item.projectProgramMetrics.forEach((metric: any) => {
      mappedData[`${metric.groupId}-${metric.name}`] = {id: metric.id, value: metric.value};
    });

    return mappedData;
  });
};

const createGroupedColumns = (
  data: any[],
  onInputChange: (value: number, rowIndex: number, name: string) => void,
  tableData: any
): ColumnsType<MappedDataType> => {

  return tableData.flatMap((program: any) => {
    if (!program.selected) return [];

    return program.groups.flatMap((group: any) => {
      if (!group.selected) return [];

      const metricsForGroup = data[0]?.projectProgramMetrics.filter((metric: any) => metric.groupId === group.id);

      if (metricsForGroup && metricsForGroup.length === 0) return [];

      return {
        title: group.name,
        children: metricsForGroup?.map((metric: any) => ({
          title: metric.name as React.ReactNode,
          dataIndex: `${group.name}-${metric.name}`,
          key: `${group.name}-${metric.name}`,
          render: (item: any, record: any, rowIndex: number) => {
            const metricKey = `${metric.groupId}-${metric.name}`;
            const value = record[metricKey]?.value || '';
            return (
              <Input
                value={value}
                type="number"
                onChange={(e) => onInputChange(Number(e.target.value), rowIndex, metricKey)}
              />
            );
          },
        })),
      };
    });
  });
};

export function ProgramMetricsTable({data, tableData}: any): JSX.Element {
  const [dataSource, setDataSource] = useState<MappedDataType[]>([]);

  useEffect(() => {
    setDataSource(mapData(data));
  }, [data]);

  const updateProgramMetricsDebounced = useMemo(() =>
      _.debounce((metric: any) => {
        ProjectApi.updateProgramMetric(metric.id, metric.value).then();
      }, 600),
    []
  );

  function onInputChange(value: number, rowIndex: number, metricName: string): void {
    const updatedDataSource: any = [...dataSource];
    const currentMetric: any = updatedDataSource[rowIndex][metricName];
    currentMetric.value = value;
    setDataSource(updatedDataSource);

    const updatedMetric = {
      id: currentMetric.id,
      name: metricName.split('-')[1],
      value: value
    };

    updateProgramMetricsDebounced(updatedMetric);
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      fixed: 'left',
      render: (value: string, project: any) => (
        <Link to={"/projects/edit?id=" + project.id} className="project-name">
          {value}
        </Link>
      ),
    },
    ...createGroupedColumns(data, onInputChange, tableData)
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={false}
      rowKey="id"
    />
  )
};
