import React from "react";
import { DraftDecoratorComponentProps } from "draft-js";

export const LinkStrategy = (props: DraftDecoratorComponentProps | any) => {
  let url: any;
  if (props.entityKey != null) {
    url = props.contentState.getEntity(props?.entityKey).getData().url;
  } else {
    return null;
  }

  const onUrlClick = (event: any) => {
    event.preventDefault();
    props.openHyperlinkModal(props.entityKey);
  }

  if (props.readOnly === true) {
    return (
      <a rel="noopener noreferrer" target="_blank" href={url ? url : null} onClick={() => window.open(url, "_blank")}>
        {props.children}
      </a>
    );
  }

  return (
    <a onClick={(e) => onUrlClick(e)}>
      {props.children}
    </a>
  );
};
