import * as React from "react";

const SvgMultipleChoice = (props: any) => {
  return (
    <svg width={33} height={33} viewBox="0 0 33 33" {...props}>
      <path fillRule="evenodd"
            fill={props.color ? props.color : '#545E6B'}
            d="M16.9,29.4c7.4,0,13.3-6,13.3-13.4s-6-13.3-13.4-13.3c-7.4,0-13.3,6-13.3,13.4C3.5,23.5,9.5,29.5,16.9,29.4z
	 M27.5,16c0,5.9-4.7,10.7-10.6,10.7C11,26.8,6.2,22,6.2,16.1c0-5.9,4.7-10.7,10.6-10.7C22.7,5.4,27.5,10.1,27.5,16z M16.1,9.4
	c-3.3,0-6,2.7-6,6l0,1.3c0,3.3,2.7,6,6,6l1.3,0c3.3,0,6-2.7,6-6l0-1.3c0-3.3-2.7-6-6-6L16.1,9.4z"/>
    </svg>
  );
}

export default SvgMultipleChoice;
