import React, { useState } from 'react';
import { RcFile, UploadChangeParam, UploadProps } from "antd/lib/upload/interface";
import { environment } from "../../../../../../../../environment";
import SvgUploadFile from "../../../../../../../../assets/icons/js/UploadFile";
import SvgCancel from "../../../../../../../../assets/icons/js/Cancel";
import { Upload } from "antd";
import './external-zip-import.scss'
import SvgFolder from "../../../../../../../../assets/icons/js/Folder";
import SvgClose from "../../../../../../../../assets/icons/js/Close";

const {Dragger} = Upload
type IProps = {
  folder: any;
  updateFolder: (folder: any) => void;
}
const ExternalZipImport = (props: IProps) => {
  const [onlyZipMessage, setOnlyZipMessage] = useState(false);
  const token = localStorage.getItem('access_token');
  let uploadProps: UploadProps = {
    name: 'file',
    openFileDialogOnClick: false,
    beforeUpload: (file: RcFile) => {
      setOnlyZipMessage(false);
      if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
        return true
      } else {
        setOnlyZipMessage(true);
        return false;
      }
    },
    headers: {
      contentType: 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    action: `${environment.backendUrl}/integrations/zip-file/upload`,
    onChange: (info: UploadChangeParam) => onFileChange(info),
    multiple: false,
    accept: 'application/zip, application/x-zip-compressed',
  };

  const onFileChange = (info: UploadChangeParam): void => {
    const {status} = info.file;
    if (status === 'done') {
      const response = info.file.response;
      props.updateFolder(response);
    }

    if (status === 'error') {
    }
  }

  function onFileDelete(): void {
    props.updateFolder(null);
  }


  return (
    <div>
      {
        props.folder ?
          <div className="external-zip-import-folder">
            <div className="column justify-space-between align-items-center">
              <div className="info-text column align-items-center w-100" style={{gap: 10}}>
                <SvgFolder width={34} height={34} color={"#1B3380"}/>
                <span className="w-100" style={{textAlign: "center"}}>{props.folder.folderName}</span>
                {/*<span className="w-100" style={{textAlign: "center"}}>Date</span>*/}
              </div>
            </div>
            <div data-testid="remove-folder-button"
                 className="remove-folder-button pointer absolute" style={{top: 8, right: 10}}
                 onClick={() => onFileDelete()}>
              <i className="bi bi-x-lg"/>
            </div>
          </div> :
          <div>
            <Dragger {...uploadProps} style={{width: '30%'}} className="external-zip-import-dragger">
              <div className="header column justify-space-between align-items-center">
                <div className="info-text column align-items-center w-100">
                  <SvgUploadFile width={30}/>
                  <span className="w-100" style={{textAlign: "center"}}>Drop your folder or <Upload {...uploadProps}
                                                                                                    openFileDialogOnClick={true}><span
                    className="link" style={{fontSize: 12}}>browse</span></Upload></span>
                </div>
              </div>
            </Dragger>
            <p style={{fontSize: 12, textAlign: "left"}}>Only ZIP files are allowed!</p>
            {onlyZipMessage &&
              <span style={{color: '#e53251', marginTop: 10}}>Only ZIP file types are allowed to be imported</span>}
          </div>
      }

    </div>
  );
};

export default ExternalZipImport;
