import React from 'react';

const SvgCompleted = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.4858 5.50767L10.5739 5.4325C10.8518 5.23201 11.2419 5.25654 11.4924 5.50639L11.4961 5.5101L11.5713 5.59955C11.7716 5.88129 11.7449 6.27444 11.4921 6.52632L7.0023 11L4.50019 8.49981L4.42563 8.41222C4.24887 8.16677 4.24874 7.83343 4.42531 7.58783L4.49981 7.50019C4.776 7.224 5.22394 7.22368 5.50029 7.49971L7.0023 9L10.4858 5.50767Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>
  );
};

export default SvgCompleted;
