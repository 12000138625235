import React, { useEffect, useState } from 'react';
import './evidence-dialog.scss';
import { Button, Modal } from 'antd';
import SvgNugget from '../../../../../assets/icons/js/nugget';
import SvgConversation from '../../../../../assets/icons/js/Conversation';
import SvgCheck from '../../../../../assets/icons/js/check';
import SvgTrash from '../../../../../assets/icons/js/Trash';
import { IProject } from '../../../../../pages/projects/models/project.interface';
import InformIconsPipe from '../../../../helpers/inform-icons-pipe';
import { Loader } from '../../../loader/loader';
import interviewsApi from '../../../../../pages/projects/project-edit/tabs/interviews-labs/api/interviews-api';

export function EvidenceDialog(props: any): JSX.Element {
  let propsNuggets: {
    id: number,
    description: string,
    selectedText?: string
  }[] = JSON.parse(JSON.stringify(props?.nuggets));
  const selectedNuggets: {
    id: number,
    description: string,
    selectedText?: string
  }[] = JSON.parse(JSON.stringify(props?.data?.selectedNuggets));
  const project: IProject = props?.project;
  const [newNugget, setNewNugget] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [nuggets, setNuggets] = useState<{
    id: number,
    description: string,
    selectedText?: string
  }[]>(propsNuggets ? propsNuggets : []);
  const [createdNuggets, setCreatedNuggets] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    if (selectedNuggets?.length >= 1) {
      selectedNuggets?.forEach((selectedNugget: any) => {
        const foundNugget = propsNuggets.findIndex((nugget: any) => nugget.id === selectedNugget.id);
        if (foundNugget >= 0) {
          propsNuggets[foundNugget].selectedText = props?.data?.selectedText;
        }
      });

      setNuggets(propsNuggets);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, []);

  function title(): JSX.Element {
    return (
      <div className="d-flex row align-items-center" style={{color: '#545e6b'}}>
        <SvgNugget style={{marginRight: 10}}/>
        <span>
          Link to insights key findings
        </span>
      </div>
    )
  }

  function onConfirm(): void {
    const newNuggets: any[] = JSON.parse(JSON.stringify(nuggets));
    const selectedNuggets: any[] = newNuggets.filter((nugget: any) => !!nugget.selectedText);

    props.onConfirm({
      selectedNuggets,
      entityKey: props?.data?.entityKey,
      entityMapId: props?.data?.entityMapId,
      newNuggets: createdNuggets
    });
  }

  function addEvidenceToNugget(id: number): void {
    const newNuggets: any[] = JSON.parse(JSON.stringify(nuggets));

    newNuggets.find((n: any) => {
      if (n.id === id) {
        n.selectedText = props?.data?.selectedText
        return n;
      }
    });

    setNuggets(newNuggets.slice());
  }

  function deleteEvidenceFromNugget(id: number): void {
    const newNuggets: any[] = JSON.parse(JSON.stringify(nuggets));

    newNuggets.find((n: any) => {
      if (n.id === id) {
        n.selectedText = null
        return n;
      }
    });

    setNuggets(newNuggets.slice());
  }

  function createNewNugget() {
    interviewsApi.createNugget(props?.data?.interviewId, name)
      .then((response: any[]) => {
        const newNuggets: any[] = JSON.parse(JSON.stringify(nuggets));
        newNuggets.unshift(response[0]);
        setNuggets(newNuggets);
        setCreatedNuggets(response);
        setName('');
        setNewNugget(false);
      });
  }

  return (
    <Modal
      title={title()}
      open={props.data.visible}
      className="draft-evidence"
      onCancel={() => (props?.onCancel())}
      centered
      width={630}
      footer={[
        <Button key="submit" type="primary" onClick={() => onConfirm()}>Save</Button>
      ]}>
      {loader ? <Loader/> :
        <div className="evidence-body">
          <div className="selected-text">
            {props?.data?.selectedText &&
                <span>“{props.data.selectedText}”</span>
            }
          </div>
          <div className="project column">
            <div className="project-header justify-space-between">
              <div className="title-icon d-flex align-items-center">
                <InformIconsPipe icon={project?.icon}
                                 width={20}
                                 height={20}
                                 color={project?.color}/>
                <span style={{marginLeft: 10}}>{project?.name}</span>
              </div>
              <div className="counter">
                {nuggets.length}
              </div>
            </div>
            <div className="project-body">
              {
                !newNugget ?
                  <button className="add-new-nugget" onClick={() => setNewNugget(true)}>
                    <SvgNugget style={{marginRight: 10}}/>
                    Add new key finding
                  </button>

                  :
                  <div className="add-new-nugget-container">
                    <input
                      className="add-new-nugget-input"
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e: any) => setName(e.target.value)}/>
                    <button className="add-new-nugget-button" onClick={createNewNugget}>
                      <SvgCheck width={25} height={25}/>
                    </button>
                  </div>
              }

              {nuggets?.map((nugget: { id: number, description: string, selectedText?: string }, index: number) => {
                return (
                  <div className="nugget-card" key={index}>
                    <div className="body">
                      <div className="dot"/>
                      {nugget?.description}
                    </div>
                    {
                      !nugget.selectedText ?
                        <button className="add-as-evidence" onClick={() => addEvidenceToNugget(nugget.id)}>
                          <SvgConversation style={{marginRight: 10}}/>
                          Add as evidence
                        </button>
                        :
                        <div className="added-evidence">
                          <div className="evidence">
                            “{nugget.selectedText}”
                            <div className="evidence-delete" onClick={() => deleteEvidenceFromNugget(nugget.id)}>
                              <SvgTrash/>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      }
    </Modal>
  );
}
