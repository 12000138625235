// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-thumbs-up-overlay {
  height: 150px;
  overflow: auto;
}
.recommendation-thumbs-up-overlay .ant-dropdown-menu-item {
  cursor: default;
}

.recommendation-thumbs-up-wrapper {
  font-size: 18px;
  position: absolute;
  border-left: 3px solid rgba(74, 117, 208, 0.2);
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  z-index: 7;
  pointer-events: auto;
}
.recommendation-thumbs-up-wrapper .recommendation-thumbs-up-dropdown {
  cursor: pointer;
  padding: 0 4px;
}
.recommendation-thumbs-up-wrapper .recommendation-thumbs-up-dropdown:hover {
  background-color: #EEEEEE;
}
.recommendation-thumbs-up-wrapper .recommendation-thumbs-up-icon {
  color: rgba(74, 117, 208, 0.5);
}
.recommendation-thumbs-up-wrapper .recommendation-thumbs-up-counter {
  font-size: 16px;
  color: rgba(74, 117, 208, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/story-editor/components/recommendation-highlights-container/reccomendation-thumbs-up/recommendation-thumbs-up.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,cAAA;AAAF;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,eAAA;EACA,kBAAA;EACA,8CAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EACA,UAAA;EACA,oBAAA;AADF;AAGE;EACE,eAAA;EACA,cAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAKE;EACE,8BAAA;AAHJ;AAME;EACE,eAAA;EACA,8BAAA;AAJJ","sourcesContent":["\n.recommendation-thumbs-up-overlay {\n  height: 150px;\n  overflow: auto;\n\n  .ant-dropdown-menu-item {\n    cursor: default;\n  }\n}\n\n.recommendation-thumbs-up-wrapper {\n  font-size: 18px;\n  position: absolute;\n  border-left: 3px solid rgba(74, 117, 208, 0.2);\n  padding-left: 5px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 5px;\n  z-index: 7;\n  pointer-events: auto;\n\n  .recommendation-thumbs-up-dropdown {\n    cursor: pointer;\n    padding: 0 4px;\n\n    &:hover {\n      background-color: #EEEEEE;\n    }\n  }\n\n  .recommendation-thumbs-up-icon {\n    color: rgba(74, 117, 208, 0.5);\n  }\n\n  .recommendation-thumbs-up-counter {\n    font-size: 16px;\n    color: rgba(74, 117, 208, 0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
