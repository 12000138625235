import React from 'react';

const SvgSubtitle = (props: any) => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.8477 0.179999L10.5677 2.28H6.96766V14H4.30766V2.28H0.587656V0.179999H10.8477ZM17.7523 13.5C17.379 13.7667 16.959 13.9667 16.4923 14.1C16.039 14.2333 15.5723 14.3 15.0923 14.3C14.0523 14.2867 13.259 13.9933 12.7123 13.42C12.179 12.8333 11.9123 11.9733 11.9123 10.84V5.26H10.2523V3.42H11.9123V1.08L14.4723 0.78V3.42H16.9323L16.6723 5.26H14.4723V10.78C14.4723 11.3 14.559 11.6733 14.7323 11.9C14.919 12.1267 15.2057 12.24 15.5923 12.24C15.979 12.24 16.399 12.1133 16.8523 11.86L17.7523 13.5Z"
        fill={props?.color ? props.color : '#545e6b'}
      />
    </svg>

  );
};

export default SvgSubtitle;
