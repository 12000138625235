import * as React from "react";

const SvgZip = (props: any) => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <path
        d="m24 0 8 8v24H8v-2h22V10h-8V2H10v12H8V0h16zm4 16v12H0V16h28zm-16.476 1.708h-5.28v1.26h3.504l-3.9 5.82V26h5.544l.168-1.296H7.696l3.828-5.772v-1.224zm3.012 0h-1.584V26h1.584v-8.292zm4.392 0H16.48V26h1.584v-2.892h.972c.984 0 1.772-.23 2.364-.69.592-.46.888-1.15.888-2.07 0-.88-.294-1.54-.882-1.98-.588-.44-1.414-.66-2.478-.66zm-.012 1.164c1.144 0 1.716.492 1.716 1.476 0 .576-.144.982-.432 1.218-.288.236-.716.354-1.284.354h-.852v-3.048z"
        fill={props.color ? props.color : '#545E6B'} fillRule="nonzero"/>
    </svg>
  );
}

export default SvgZip;
