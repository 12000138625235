import React from 'react';

const SvgExternalLink = (props: any) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.434 4.2596L11.9997 0.299805L8.03993 0.86549L9.1713 1.99686L5.44582 5.72234L6.57719 6.85372L10.3027 3.12823L11.434 4.2596ZM4.7998 1.09982C4.7998 0.657996 4.44163 0.299823 3.9998 0.299823H2.3998C1.51615 0.299823 0.799805 1.01617 0.799805 1.89982V9.89982C0.799805 10.7835 1.51615 11.4998 2.3998 11.4998H10.3998C11.2835 11.4998 11.9998 10.7835 11.9998 9.89982V8.29982C11.9998 7.858 11.6416 7.49982 11.1998 7.49982C10.758 7.49982 10.3998 7.858 10.3998 8.29982V9.89982H2.3998V1.89982H3.9998C4.44163 1.89982 4.7998 1.54165 4.7998 1.09982Z"
            fill={props?.color ? props.color : "#1B87E6"}/>
    </svg>

  );
};

export default SvgExternalLink;
