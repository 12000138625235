import React from 'react';

const SvgHeading = (props: any) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.40888 0.976L8.24888 2.272H5.19288V12H3.67288V2.272H0.536875V0.976H8.40888ZM13.6979 11.616C13.1432 12 12.5139 12.192 11.8099 12.192C11.0952 12.192 10.5352 11.9893 10.1299 11.584C9.73521 11.168 9.53788 10.5707 9.53788 9.792V4.704H8.06588V3.568H9.53788V1.664L11.0099 1.488V3.568H13.0099L12.8499 4.704H11.0099V9.728C11.0099 10.1653 11.0845 10.4853 11.2339 10.688C11.3939 10.88 11.6552 10.976 12.0179 10.976C12.3485 10.976 12.7219 10.864 13.1379 10.64L13.6979 11.616Z"
        fill={props?.color ? props.color : "#545e6b"}/>
    </svg>

  );
};

export default SvgHeading;
