import React from 'react';

const SvgPaginationArrowBackward = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11 15L4 8L11 1" stroke="#545E6B" strokeWidth="2"/>
    </svg>

  );
};

export default SvgPaginationArrowBackward;
