import React from 'react';
import isEqual from 'lodash.isequal';
import { DisplayNugget } from './display-nugget';

// made it as a class component because we need shouldComponentUpdate lifecycle
export class LeftDisplayHighlightContainer extends React.Component<any, any> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    return !isEqual(nextProps.leftDisplayTextHighlights, this.props.leftDisplayTextHighlights) || this.props?.readOnly !== nextProps?.readOnly;
  }

  render() {
    const {leftDisplayTextHighlights, readOnly} = this.props;

    return (
      <>
        {
          leftDisplayTextHighlights !== undefined && leftDisplayTextHighlights !== null && Object.entries(leftDisplayTextHighlights).map(([key, value], index) => {
            if (value) {
              //@ts-ignore
              return (
                <div
                  key={index + Math.random()}
                  className="display-highlight-container"
                  style={{top: `${parseInt(key) + 27}px`}}>
                  {
                    // @ts-ignore
                    value.length && value?.map((item: any, index) => {
                      if (item.type === 'EVIDENCE') {
                        return (
                          <DisplayNugget key={index}
                                         nugget={item}
                                         editorState={this.props.editorState}
                                         openEvidenceDialog={this.props.openEvidenceDialog}
                                         readOnly={readOnly}/>
                        )
                      }
                    })
                  }
                </div>
              )
            }
          })
        }
      </>
    );
  }
}
