import React, { useEffect, useState } from 'react';
import './all-flags.scss';
import FlagIconsPipe from '../../../../../../../../../_shared/helpers/flag-icons-pipe';
import { IFlag, ISelectedFlag, ITopFlagsDetails, } from '../../../../model/top-flags-details.interface';
import { Loader } from '../../../../../../../../../_shared/components/loader/loader';
import { Empty } from 'antd';
import SvgDocuments from '../../../../../../../../../assets/icons/js/documents';
import { IAllFlags } from '../../../../model/all-assigments.interface';
import { ITopFlag } from '../../../../model/top-three-graph.interface';

export default function AllFlags(props: any): JSX.Element {
  const [loader, setLoader] = useState<boolean>(true);
  const [flags, setFlags] = useState<IAllFlags>();
  const [selectedFlag, setSelectedFlag] = useState<ITopFlag>();

  useEffect(() => {
    setFlags(props?.flags);
    setSelectedFlag(props.flags.flags[0]);
  }, []);

  useEffect(() => {
    setFlags(props?.flags);
    setLoader(false);
  }, [props?.flags]);

  function onSelectFlag(flag: ITopFlag) {
    setLoader(true);
    setSelectedFlag(flag);
    props.loadPage({flag})
  }

  return (
    <div className="usage-stats-flags graph-nodes-wrapper">
      {loader ? <Loader/>
        :
        <>
          <div className="body">
            <div className="node-wrapper">
              <div className="node">
                <div className="nodes-wrapper flags-wrapper">
                  {flags?.flags?.length &&
                    flags?.flags.map((flag: ITopFlag, i: number) => {
                      return (
                        <div key={i}
                             className={selectedFlag?.id == flag.id ? 'flag active' : 'flag'}
                             onClick={() => onSelectFlag(flag)}>
                          <div className="d-flex" style={{marginLeft: 8}}>
                            <FlagIconsPipe
                              height={16}
                              width={16}
                              icon={flag?.icon && flag?.icon}
                              color={flag?.color && flag?.color}/>
                            <span style={{marginLeft: 8}}>{flag.name}</span>
                          </div>
                          <div className="flag-total-assigned">
                            <span>{flag.totalAssigned}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="projects">
              {flags?.interviews?.length ?
                <div className="top-connections-dialog-flags">
                  <div className="selected-flag-header">
                    <FlagIconsPipe
                      height={16}
                      width={16}
                      icon={selectedFlag?.icon && selectedFlag?.icon}
                      color={selectedFlag?.color && selectedFlag?.color}/>
                    <span>{selectedFlag?.name}</span>

                    <div className="selected-flag-card-total">
                      {/*<span>{selectedFlag?.totalAssigned}</span>*/}
                    </div>
                  </div>

                  {flags?.interviews.map(
                    (flagDetail: ITopFlagsDetails, index: number) => {
                      return (
                        <div key={index} className="interview">
                          <div className="title-container">
                            <div className="title-connections">
                              <SvgDocuments
                                color={'#1B87E6'}
                                style={{marginRight: 8}}
                              />
                              <div className="title">{flagDetail.title}</div>
                            </div>
                            <div className="flag-connections">
                              {flagDetail.totalConnections}
                            </div>
                          </div>
                          <div className="hr"/>
                          {flagDetail?.selected?.map(
                            (selected: ISelectedFlag, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className="selected-graph-card">
                                  <div className="content">
                                    “…{selected.selectedText}…”
                                  </div>
                                  <div className="footer">
                                    {selected?.flags?.map(
                                      (flag: IFlag, index: number) => {
                                        return (
                                          <div
                                            key={index}
                                            className={
                                              flag.selected
                                                ? 'flag-selected-nodes'
                                                : 'flag-nodes'}>
                                            <FlagIconsPipe
                                              height={12}
                                              width={12}
                                              icon={flag?.icon && flag?.icon}
                                              color={flag?.color && flag?.color}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
                :
                <div className="empty-align">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                </div>
              }
            </div>
          </div>
        </>
      }
    </div>
  );
}
