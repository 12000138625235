// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-name {
  color: #9b9b9b;
  line-height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.file-success {
  color: #545e6b;
  line-height: 32px;
  font-weight: light;
}

.error {
  color: #e53251;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
}

.logo-wrapper {
  padding: 15px;
  border: 1px solid #d8d8d8;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.logo-wrapper:hover {
  border: 1px solid #1b87e6;
}
.logo-wrapper .logo-img {
  height: 80px;
}
.logo-wrapper .logo-delete-button {
  position: absolute;
  right: 2px;
  top: 2px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-wrapper .logo-delete-button:hover {
  background-color: #1b87e6;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/setup/portal/welcome-message/welcome-message.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ;AAGI;EACE,yBAAA;AADN","sourcesContent":[".file-name {\n  color: #9b9b9b;\n  line-height: 16px;\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n}\n\n.file-success {\n  color: #545e6b;\n  line-height: 32px;\n  font-weight: light;\n}\n\n.error {\n  color: #e53251;\n  font-size: 10px;\n  font-weight: 400;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.logo-wrapper {\n  padding: 15px;\n  border: 1px solid #d8d8d8;\n  min-height: 100px;\n  min-width: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  cursor: pointer;\n\n  &:hover {\n    border: 1px solid #1b87e6;\n  }\n\n  .logo-img {\n    height: 80px;\n  }\n\n  .logo-delete-button {\n    position: absolute;\n    right: 2px;\n    top: 2px;\n    padding: 5px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    &:hover {\n      background-color: #1b87e6;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
