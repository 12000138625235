import React, { useEffect, useState } from 'react';
import './activity-log.scss';

import { Empty } from "antd";
import { useQuery } from "react-query";
import projectApi from "../../../api/project-api";
import ActivityCard from "./activity-card/activity-card";
import { Loader } from "../../../../../_shared/components/loader/loader";
import qs from "query-string";
import { useNavigate } from "react-router-dom";
import { getInitials } from "../../../../../_shared/helpers/get-initials-of-name";

import { useNavigateToPageForActivity } from "../../../../../core/hooks/use-navigate-to-page";
import {
  ActivitiesFilterEnum
} from "../../../../home/components/usage-dashboard/homepage-activity-feed/components/activity-filters/model/activities-filter.enum";
import ActivityFilters
  from "../../../../home/components/usage-dashboard/homepage-activity-feed/components/activity-filters/activity-filters";
import {
  IActivity
} from '../../../../home/components/usage-dashboard/homepage-activity-feed/model/activities.interface';
import UserActivityDialog
  from "../../../../home/components/usage-dashboard/homepage-activity-feed/user-activity-dialog/user-activity-dialog";
import Pagination from "../../../../../_shared/components/pagination/pagination";


const activityOptions = [
  {value: ActivitiesFilterEnum.ALL_ACTIVITY, label: "All activity"},
  {value: ActivitiesFilterEnum.PROJECTS_ACTIVITY, label: "Project updates"},
  {value: ActivitiesFilterEnum.NUGGETS_ACTIVITY, label: "Key findings"},
  {value: ActivitiesFilterEnum.NOTEPADS_ACTIVITY, label: "Notepads"}
]

export function ActivityLog(props: any): JSX.Element {
  const navigate = useNavigate();
  const [activitiesFilter, setActivitiesFilter] = useState<ActivitiesFilterEnum>(ActivitiesFilterEnum.ALL_ACTIVITY)
  const [membersFilter, setMembersFilter] = useState<null | any[]>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [totalCount, setTotalCount] = useState(0);

  const [userActivityDialog, setUserActivityDialog] = useState<any>({visible: false});

  const projectActivities = useQuery([`projectActivities${props.project.id}`, activitiesFilter, membersFilter, page, pageSize], () => projectApi.getProjectActivities(props.project.id, {
      page,
      pageSize,
      usersId: membersFilter ? membersFilter.toString() : "",
      activityTypes: activitiesFilter !== ActivitiesFilterEnum.ALL_ACTIVITY ? activitiesFilter.toString() : "",
    }),
    {
      onSuccess: data => setTotalCount(data.total[0].count)
    }
  )

  const projectContributors = useQuery(`projectsContributors${props.project.id}`, () => projectApi.getProjectContributors(props.project.id));
  const projectTopNodes = useQuery(`projectTopNodes${props.project.id}`, () => projectApi.getProjectTopNodes(props.project.id))

  useEffect(() => {
    setPage(1)
  }, [membersFilter, activitiesFilter, pageSize]);


  const openUserActivityDialog = (user: { userId: number }) => {
    setUserActivityDialog({visible: true, ...user})
  }

  const closeUserActivityDialog = () => {
    setUserActivityDialog({visible: false, userId: 0})
  }

  const navigateToNode = (nodeId: number) => {
    navigate({pathname: '/nodes/node', search: qs.stringify({id: nodeId})})
  }


  return (
    <div className="activity-log-wrapper">
      <div className='activity-log-feed'>
        <div className='activity-log-title'>
          Activity Feed
        </div>
        <div className='activity-log-header'>
          <ActivityFilters
            membersFilter={membersFilter}
            setMembersFilter={setMembersFilter}
            activitiesFilter={activitiesFilter}
            setActivitiesFilter={setActivitiesFilter}
            activityOptions={activityOptions}/>
          {
            totalCount > 0 &&
            <Pagination
              onChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
              }}
              pageSizeOptions={[5, 10, 20, 50]}
              pageSize={pageSize}
              showSizeChanger
              currentPage={page}
              totalItems={totalCount}/>
          }

        </div>
        <div className='activity-log-body'>
          {
            projectActivities.isLoading ? <Loader/> :
              projectActivities.data?.data.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                projectActivities.data &&
                projectActivities.data.data?.map((activity: IActivity, i: string | number | null | undefined) => {
                  return <ActivityCard activity={activity}
                                       key={i}
                                       openUserActivityDialog={openUserActivityDialog}
                                       projectId={props.project.id}
                                       source='fromActivity'/>
                })
          }
        </div>
      </div>
      <div className='activity-log-details'>
        <div className='activity-log-contributors-wrapper'>
          <div className='activity-log-title'>
            Top contributors
          </div>
          <div className='activity-log-contributors'>
            <div className='metric-table-ui'>
              {
                projectContributors.data &&
                (projectContributors.data.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                  projectContributors.data.map((contributor, i) => {
                    return <div className='metric-table-row d-flex' key={i}>
                      <div className='metric-user-info'>
                        <div className='metric-user-avatar'
                             style={{backgroundColor: contributor.profilePictureColor}}>
                          {contributor.profilePicture ?
                            <img src={contributor.profilePicture} alt=''/> : getInitials(contributor.contributorName)}
                        </div>
                        <div className='metric-user-name pointer'
                             onClick={() => openUserActivityDialog({userId: contributor.contributorId})}>
                          {contributor.contributorName}
                        </div>
                      </div>
                      <div className='metric-table-amount'>
                        {contributor.percentageUsage}%
                      </div>
                    </div>
                  }))
              }
            </div>
          </div>
        </div>
        {
          projectTopNodes.data &&
          projectTopNodes.data.length >= 3 &&
            <div className='activity-log-graphs-wrapper'>
                <div className='activity-log-title'>
                    Top Knowledge Graphs
                </div>
                <div className='activity-log-graphs'>
                  {projectTopNodes.data.map((node, i) => {
                    return <div className="row activity-top-node" key={i}>
                      <div className='activity-top-node-index'>
                        {i < 9 ? `0${i + 1}` : i + 1}
                      </div>
                      <div className='activity-top-node'
                           onClick={() => navigateToNode(node.id)}>{`${node.name} (${node.totalAssigned})`}</div>
                    </div>
                  })}
                </div>
            </div>
        }

      </div>
      {
        userActivityDialog.visible &&
          <UserActivityDialog data={userActivityDialog} onCancel={closeUserActivityDialog}/>
      }
    </div>
  );
}
