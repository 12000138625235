import * as React from "react";

const SvgListChecked = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z"/>
        <path fill={props.color ? props.color : '#545E6B'}
              fillRule="nonzero"
              d="M3.5 1.5V3h9V1.5H14V16H2V1.5h1.5zm8 6c-.249-.249-.636-.273-.912-.075L10.5 7.5 7 10.998 6 10c-.276-.276-.724-.276-1 0-.249.249-.273.636-.075.912L5 11l2 2 4.5-4.5c.276-.276.276-.724 0-1zM11 0v2H5V0h6z"/>
      </g>
    </svg>
  );
}

export default SvgListChecked;
