import React, { useEffect, useState } from 'react';
import { Button, Divider, Modal, notification } from "antd";
import { useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Axios from "axios";
import { environment } from "../../../../../../environment";
import questionProImportsApi from "../../api/question-pro-imports.api";

export type ExternalImportDialogData = {
  baseUrl: string,
  orgId: string | number,
  apiKey: string
}
const ExternalImportDialog = (props: { data: { open: boolean }, onCancel: (data?: { refetch: boolean }) => void }) => {
  const [startImportDisabled, setStartImportDisabled] = useState(true);
  const [testConnectionLoading, setTestConnectionLoading] = useState(false);
  const [startImportLoading, setStartImportLoading] = useState(false);
  const [testConnectionMessage, setTestConnectionMessage] = useState<{ status: number, message: string }>();
  const {register, handleSubmit, control, formState: {errors}} = useForm<ExternalImportDialogData>({
    defaultValues: {
      baseUrl: "",
      orgId: '',
      apiKey: ""
    }
  })

  const baseUrl = useWatch({control, name: 'baseUrl'})
  const orgId = useWatch({control, name: 'orgId'})
  const apiKey = useWatch({control, name: 'apiKey'})

  useEffect(() => {
    setStartImportDisabled(true);
    setTestConnectionMessage(undefined)
  }, [baseUrl, orgId, apiKey]);

  const onTestConnection = async (data: ExternalImportDialogData) => {
    setTestConnectionLoading(true)

    questionProImportsApi.testConnection(data).then((response) => {
      if (response.status > 200 && response.status < 300) {
        setTestConnectionMessage({status: response.status, message: response.data.message})
      }
      setTestConnectionLoading(false);
      setStartImportDisabled(false)
    }, (e) => {
      setTestConnectionMessage({status: e.response.status, message: e.response.data.message})
      setTestConnectionLoading(false)
    })
  }

  const onStartImport = (data: ExternalImportDialogData) => {
    setStartImportLoading(true)
    questionProImportsApi.startExternalImportJob(data).then((response) => {
      props.onCancel({refetch: true});
      setStartImportLoading(false);
      notification.open({
        message: "Done",
        description: response?.message ?? '',
        type: "success",
        className: "infobox-success",
        duration: 5,
        placement: "bottomRight",
      });
    })
  }

  return (
    <Modal title={"External import"}
           open={props.data.open}
           width={800}
           onCancel={() => props.onCancel()}
           footer={[
             <Button key={1} onClick={() => props.onCancel()} type={"link"}>
               Cancel
             </Button>,
             <Button key={2} loading={testConnectionLoading} type="primary" onClick={handleSubmit(onTestConnection)}>
               Test connection
             </Button>,
             <Button key={3} onClick={handleSubmit(onStartImport)} loading={startImportLoading} type="primary"
                     disabled={startImportDisabled}>
               Start import
             </Button>
           ]}>
      <div className="external-import-dialog-wrapper column" style={{gap: 20}}>
        <div className="outline-input-wrapper">
          <div className="outline-input-label">
            Base Url
          </div>
          <input {...register("baseUrl", {
            required: "Base Url is required!",
            pattern: {
              value: /^https?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,
              message: "Invalid URL, must start with http or https for ex. https://www.questionpro.com"
            }
          })}
                 type="text"
                 className="outline-input"
                 placeholder="baseUrl"/>
          <ErrorMessage errors={errors} name={'baseUrl'} render={({message}) => <small
            className="form-error">{message}</small>}/>
        </div>
        <div className="outline-input-wrapper">
          <div className="outline-input-label">
            Organisation Id
          </div>
          <input {...register("orgId",
            {
              required: "Organisation Id is required!",
              valueAsNumber: true,
            })}
                 type="number"
                 className="outline-input"
                 placeholder="orgId"/>
          <ErrorMessage errors={errors} name={'orgId'}
                        render={({message}) => <small className="form-error">{message}</small>}/>
        </div>
        <div className="outline-input-wrapper">
          <div className="outline-input-label">
            Api Key
          </div>
          <input {...register("apiKey", {required: "apiKey is required!"})} type="text" className="outline-input"
                 placeholder="apiKey"/>
          <ErrorMessage errors={errors} name={'apiKey'} render={({message}) => <small
            className="form-error">{message}</small>}/>
        </div>
        {
          testConnectionMessage &&
            <small
                className={testConnectionMessage.status >= 400 ? "form-error" : 'form-valid'}>{testConnectionMessage.message}</small>
        }
      </div>
      <Divider style={{marginBottom: -20}}/>
    </Modal>
  );
};

export default ExternalImportDialog;
