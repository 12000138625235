// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-edit-external-auth .input-margin > div:nth-child(2) {
  margin-left: 10px;
}
.add-edit-external-auth > .row .outline-input-wrapper {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/setup/portal/external-oauth/add-edit-oauth-provider-dialog/add-edit-oauth-provider.dialog.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".add-edit-external-auth {\n  .input-margin > div:nth-child(2) {\n    margin-left: 10px;\n  }\n\n  > .row .outline-input-wrapper {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
