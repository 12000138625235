import React, { useEffect } from 'react';
import './App.scss';
import Routing from './core/routing/routing';
import { useIsLatestVersion } from './core/hooks/useIsLatestVersion';
import { Loader } from './_shared/components/loader/loader';
import { useGlobalContext } from "./store/global-context";
import { useNavigate } from "react-router-dom";
//
function App() {
  const isLatestVersion = useIsLatestVersion();
  const {loader, user, isPartner, selectedOrganization} = useGlobalContext();
  const navigate = useNavigate();

  const showSelectHub = isPartner && !selectedOrganization;

  useEffect(() => {
    if (user && showSelectHub) {
      navigate('/select-hub');
    }
  }, [user, showSelectHub, navigate]);


  if (!isLatestVersion || loader) {
    return <Loader/>
  }

  return (
    <Routing user={user} showSelectHub={showSelectHub}/>
  );
}

export default App;
