import { convertToRaw, DraftDecoratorComponentProps, EditorState } from 'draft-js'
import React, { useContext, useEffect, useState } from 'react'
import "./image-strategy.scss"
import { DebounceInput } from 'react-debounce-input';
import StoryContext from '../../../story-edit.context';

const ImageStrategy = (props: DraftDecoratorComponentProps | any) => {
  let unsplashUrl: string = "https://www.unsplash.com"
  const storyContext = useContext(StoryContext);
  const [url, setUrl] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [userUrl, setUserUrl] = useState<string>("");
  const [captionValue, setCaptionValue] = useState<string>("");
  const [data, setData] = useState<any>({});
  const [view, setView] = useState({fullWidth: true, narrow: false, caption: false})

  useEffect(() => {
    if (props.entityKey) {
      const data = props.contentState.getEntity(props?.entityKey).getData();
      setData(data);
      setUrl(data.url)
      setUsername(data?.username ? data.username : "");
      setUserUrl(data?.userUrl ? data.userUrl : "");
      setCaptionValue(data?.captionValue ? data.captionValue : "");
      setView(data.view ? data.view : {fullWidth: true, narrow: false, caption: false});
    }
  }, [])

  useEffect(() => {
    props.updateViewDataForImageMenu(view);
    if (props.entityKey) {
      const editorState = storyContext.editorState;
      const contentState = editorState.getCurrentContent();
      const newContentState = contentState.mergeEntityData(props.entityKey, {view})
      const newEditorState = EditorState.set(editorState, {
        currentContent: newContentState
      });

      storyContext.onSetEditorState(newEditorState);
    }
  }, [view]);

  const handleHover = (e: any) => {
    if (props.readOnly) {
      return;
    }

    props?.onOpenImageMenu({
      entityKey: props.entityKey,
      entityMapId: data.entityMapId,
      setView,
      view,
      data
    });
  }

  const handleChange = (e: any) => {
    setCaptionValue(e.target.value);
    const editorState = storyContext.editorState;
    const contentState = editorState.getCurrentContent();
    const newContentState = contentState.mergeEntityData(props.entityKey, {captionValue: e.target.value})
    const newEditorState = EditorState.set(editorState, {
      currentContent: newContentState
    });

    storyContext.onSetEditorState(newEditorState);
  }

  return (
    <div id={data.entityMapId}
         className="pointer relative  row justify-space-center"
         onClick={handleHover}
         style={{marginTop: 10, marginBottom: 10}}>
      <div className='column' style={{width: view?.narrow ? "70%" : "100%"}}>
        <img src={url} alt=""/>
        {
          (username || userUrl) &&
            <div className='w-100 flex-end unsplash-label'>
                Photo By <a
                rel="noopener noreferrer"
                target="_blank"
                href={userUrl}
                onClick={() => window.open(userUrl, "_blank")}>
              {username}
            </a> on <a rel="noopener noreferrer"
                       target="_blank"
                       href={unsplashUrl}
                       onClick={() => window.open(unsplashUrl, "_blank")}>Unsplash</a>
            </div>
        }
        {
          view?.caption &&
            <DebounceInput
                readOnly={props.readOnly}
                id='img-caption-0'
                debounceTimeout={600}
                value={captionValue}
                type="text"
                placeholder='write a caption'
                className='unsplash-label'
                onChange={handleChange}/>
        }
      </div>
    </div>

  )
}

export default ImageStrategy
