import * as React from 'react';

const SvgFlagLike = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 15V1h2a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H5zM1 5h3v10H1V5z"
        fill={props.color ? props.color : "#9B9B9B"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlagLike;
