import React from 'react';

const SvgExpandText = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 2.32727C1 2.14652 1.1567 2 1.35 2H14.65C14.8433 2 15 2.14652 15 2.32727V3.85455C15 4.03529 14.8433 4.18182 14.65 4.18182H1.35C1.1567 4.18182 1 4.03529 1 3.85455V2.32727Z"
        fill="#545E6B"/>
      <path
        d="M1 5.6C1 5.41925 1.1567 5.27273 1.35 5.27273H14.65C14.8433 5.27273 15 5.41925 15 5.6V7.12727C15 7.30802 14.8433 7.45455 14.65 7.45455H1.35C1.1567 7.45455 1 7.30802 1 7.12727V5.6Z"
        fill="#545E6B"/>
      <path
        d="M1 8.87273C1 8.69198 1.1567 8.54545 1.35 8.54545H14.65C14.8433 8.54545 15 8.69198 15 8.87273V10.4C15 10.5807 14.8433 10.7273 14.65 10.7273H1.35C1.1567 10.7273 1 10.5807 1 10.4V8.87273Z"
        fill="#545E6B"/>
      <path
        d="M1 12.1455C1 11.9647 1.1567 11.8182 1.35 11.8182H7.65C7.8433 11.8182 8 11.9647 8 12.1455V13.6727C8 13.8535 7.8433 14 7.65 14H1.35C1.1567 14 1 13.8535 1 13.6727V12.1455Z"
        fill="#545E6B"/>
    </svg>
  );
};

export default SvgExpandText;
