// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}
.steps-wrapper .step-item {
  display: flex;
  align-items: center;
  color: #D8D8D8;
  font-weight: 500;
  font-size: 14px;
}
.steps-wrapper .step-item .step-item-arrow {
  margin-right: 10px;
  align-items: center;
  display: flex;
}
.steps-wrapper .step-item .step-item-arrow svg path {
  fill: #D8D8D8;
}
.steps-wrapper .step-item .step-item-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.steps-wrapper .step-item .step-item-icon svg path {
  fill: #D8D8D8;
}
.steps-wrapper .step-item-done {
  color: #54A5EC;
}
.steps-wrapper .step-item-done .step-item-arrow svg path {
  fill: #54A5EC;
}
.steps-wrapper .step-item-done .step-item-icon svg path {
  fill: #54A5EC;
}
.steps-wrapper .step-item-active {
  color: #1B3380;
}
.steps-wrapper .step-item-active .step-item-arrow svg path {
  fill: #54A5EC;
}
.steps-wrapper .step-item-active .step-item-icon svg path {
  fill: #1B3380;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/story-editor/components/graph-dialog/steps/steps.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EACE,kBAAA;EACA,mBAAA;EACA,aAAA;AACN;AAEQ;EACE,aAAA;AAAV;AAKI;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AAHN;AAMQ;EACE,aAAA;AAJV;AAUE;EACE,cAAA;AARJ;AAYQ;EACE,aAAA;AAVV;AAiBQ;EACE,aAAA;AAfV;AAqBE;EACE,cAAA;AAnBJ;AAuBQ;EACE,aAAA;AArBV;AA4BQ;EACE,aAAA;AA1BV","sourcesContent":[".steps-wrapper {\n  display: flex;\n  gap: 15px;\n  align-items: center;\n\n  .step-item {\n    display: flex;\n    align-items: center;\n    color: #D8D8D8;\n    font-weight: 500;\n    font-size: 14px;\n\n    .step-item-arrow {\n      margin-right: 10px;\n      align-items: center;\n      display: flex;\n\n      svg {\n        path {\n          fill: #D8D8D8;\n        }\n      }\n    }\n\n    .step-item-icon {\n      display: flex;\n      align-items: center;\n      margin-right: 10px;\n\n      svg {\n        path {\n          fill: #D8D8D8;\n        }\n      }\n    }\n  }\n\n  .step-item-done {\n    color: #54A5EC;\n\n    .step-item-arrow {\n      svg {\n        path {\n          fill: #54A5EC;\n        }\n      }\n    }\n\n    .step-item-icon {\n      svg {\n        path {\n          fill: #54A5EC;\n        }\n      }\n    }\n  }\n\n  .step-item-active {\n    color: #1B3380;\n\n    .step-item-arrow {\n      svg {\n        path {\n          fill: #54A5EC;\n        }\n      }\n    }\n\n    .step-item-icon {\n      svg {\n        path {\n          fill: #1B3380;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
