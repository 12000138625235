import React, { useState } from 'react';
import { DraggableColumns } from "./draggable-columns";
import { ICustomizeColumnTable, ICustomizeTable } from "../../models/customize-table.interface";

type ComponentType = {
  data: ICustomizeTable,
  onChange: (data: any) => void
}

export function CustomizeTableKeyMetrics({data, onChange}: ComponentType): JSX.Element {
  const [columns, setColumns] = useState<ICustomizeColumnTable[]>([...data.keyMetrics]);

  const columnsChanged = (newColumns: ICustomizeColumnTable[]): void => {
    setColumns(newColumns);

    onChange({keyMetrics: newColumns});
  }

  return <DraggableColumns columns={columns}
                           onColumnsChanged={(newColumns: ICustomizeColumnTable[]) => columnsChanged(newColumns)}/>
}
