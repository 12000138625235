import React from 'react';

const SvgPaginationArrowForward = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.99999 1L12 8L4.99999 15" stroke="#545E6B" strokeWidth="2"/>
    </svg>
  );
};

export default SvgPaginationArrowForward;
