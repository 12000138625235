import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SvgArrowBack from '../../../../../assets/icons/js/ArrowBack'
import './read-project.scss'
import qs from 'query-string';
import HomeApi from '../../../api/home-api';
import { Loader } from '../../../../../_shared/components/loader/loader';
import { UsageMetrics } from '../usage-metrics/usage-metrics';
import InformIconsPipe from '../../../../../_shared/helpers/inform-icons-pipe';
import { Tabs } from 'antd';
import Notepads from './notepads/notepads';
import Nuggets from './nuggets/nuggets';
import SvgNugget from '../../../../../assets/icons/js/nugget';
import SvgDocuments from '../../../../../assets/icons/js/documents';
import FormattedDate from '../../../../../_shared/helpers/formatted-date';
import { useQuery } from "react-query";

const ReadProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);
  const nodeId: any = urlParam?.nodeId;
  const projectId: any = urlParam?.projectId

  const [loader, setLoader] = useState<any>(true);
  const [project, setProject] = useState<any>();
  const node: any = useQuery(["node", nodeId], () => HomeApi.getNodeById(nodeId));

  useEffect(() => {
    if (node.data) {
      setProject(node.data.projects.find((project: any) => project.id === parseInt(projectId)))
      setLoader(false)
    }
  }, [node.data]);

  const navigateBack = () => {
    navigate(-1);
  }

  return (
    <div className='read-project'>
      {
        loader ? <Loader/> :
          <>
            <div className='intake-form-header'>
              <div className='project-header justify-space-between'>
                <div className='row align-items-center'>
                  <button onClick={() => navigateBack()} className='icon-button back'>
                    <SvgArrowBack/>
                  </button>
                  <span className='font-weight-500'>{node.data.node}</span>
                </div>
              </div>
            </div>
            <div className='body'>
              <div className="project-wrapper">
                <div className='row align-items-center project-name'>
                  <div>
                    <InformIconsPipe icon={project.icon} width={24} height={25}/>
                  </div>
                  <div className='project-title'>{project.name}</div>
                </div>
                <div className='row justify-space-between project-info'>
                  <span>By: {project?.owner?.displayName}</span>
                  <span><FormattedDate date={project.createdAt} formatString="MMM d, yyyy"/></span>
                </div>
                <div className='tags-wrapper'>
                  {
                    project?.tags.map((tag: string, index: number) => {
                      return (
                        <div className="tag" key={index}>
                          {tag}
                        </div>
                      )
                    })
                  }
                </div>
                <div className='row project-intro' style={{marginTop: 30}}>{project.intro}</div>
              </div>
              <div className='notepads-nuggets-wrapper'>
                <Tabs>
                  <Tabs.TabPane tab={<div><SvgNugget
                    height={16}/> {project.nuggets.length} {project.nuggets.length === 1 ? " Key Finding" : " Key Findings"}
                  </div>} key="1">
                    <Nuggets nuggets={project.nuggets}/>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<div><SvgDocuments
                    height={12}/> {project.notepads.length} {project.notepads.length === 1 ? " Notepad" : " Notepads"}
                  </div>} key="2">
                    <Notepads notepads={project.notepads}/>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
            {node.data.analytics.length !== 0 && (
              <UsageMetrics analytics={node.data.analytics}/>
            )}
          </>
      }

    </div>
  )
}

export default ReadProject
