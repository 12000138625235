// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mask-pii-form h4 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #545e6b;
}
.mask-pii-form h5 {
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/_shared/components/mask-pii-form/mask-pii-form.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AAIE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".mask-pii-form {\n  h4 {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    text-align: left;\n    color: #545e6b;\n\n  }\n\n  h5 {\n    color: #9B9B9B;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    text-align: left;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
