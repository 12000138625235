// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.surveys-page-wrapper .surveys-landing-page {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
.surveys-page-wrapper .surveys-landing-page h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}
.surveys-page-wrapper .surveys-landing-page h3 {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/surveys/surveys.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAN;AAGI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADN","sourcesContent":[".surveys-page-wrapper {\n  .surveys-landing-page {\n    height: 60vh;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap: 16px;\n\n    h2 {\n      font-size: 24px;\n      font-weight: 400;\n      line-height: 36px;\n      text-align: center;\n    }\n\n    h3 {\n      font-size: 18px;\n      font-weight: 300;\n      line-height: 27px;\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
