import { Empty } from 'antd';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import NuggetSearchCard from './nugget-search-card/nugget-search-card';
import { ISearchNuggetData } from "../../models/search-nugget";
import { UseQueryResult } from "react-query";

export function Nuggets(props: { searchNuggets: UseQueryResult<ISearchNuggetData, unknown> }): JSX.Element {
  const searchNuggets = props.searchNuggets;

  return <>
    {!searchNuggets?.isLoading ?
      <div className='column' style={{gap: 10, marginBottom: 20}}>
        {searchNuggets?.data?.nuggets?.length ? searchNuggets?.data.nuggets.map((nugget: any, i: number) => {
            return (
              <NuggetSearchCard key={i}
                                nugget={nugget}/>
            )
          })
          :
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }
      </div>
      :
      <>
        <Loader/>
      </>
    }
  </>
}
