import { Pie, PieConfig } from "@ant-design/plots";

interface IProps {
  data: { percentage: number }
}

const DonutChart = (props: IProps) => {
  const data = [
    {
      type: '',
      value: 100 - parseFloat(props.data.percentage.toFixed(2))
    },
    {
      type: 'completion',
      value: parseFloat(props.data.percentage.toFixed(2)),
    },
  ];

  const config: PieConfig = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#BED9F1', '#003C78'],
    radius: 1,
    innerRadius: 0.6,
    legend: false,
    label: false,
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
    },
  };
  return <Pie {...config} />;
};

export default DonutChart
