// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linked-key-findings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.linked-key-findings-wrapper h2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #1B87E6;
  margin-bottom: 0;
  margin-top: 0;
}
.linked-key-findings-wrapper h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #545e6b;
  margin-bottom: 0;
}
.linked-key-findings-wrapper .linked-key-findings {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
}
.linked-key-findings-wrapper .linked-key-findings .linked-key-finding-card {
  border: 1px solid #E8E8E8;
  padding: 8px 8px 8px 16px;
  gap: 24px;
  border-radius: 4px;
}
.linked-key-findings-wrapper .linked-key-findings .linked-key-finding-card .nugget-creator {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  margin-top: 10px;
}
.linked-key-findings-wrapper .linked-key-findings .linked-key-finding-card p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #545e6b;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/linked-projects/linked-key-findings/linked-key-findings.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAEE;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AADJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AAHJ;AAKI;EACE,yBAAA;EACA,yBAAA;EACA,SAAA;EACA,kBAAA;AAHN;AAKM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAHR;AAMM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAJR","sourcesContent":[".linked-key-findings-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n\n  h2 {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    text-align: left;\n    color: #1B87E6;\n    margin-bottom: 0;\n    margin-top: 0;\n  }\n\n  h3 {\n\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #545e6b;\n    margin-bottom: 0;\n\n  }\n\n  .linked-key-findings {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    margin-top: 32px;\n\n    .linked-key-finding-card {\n      border: 1px solid #E8E8E8;\n      padding: 8px 8px 8px 16px;\n      gap: 24px;\n      border-radius: 4px;\n\n      .nugget-creator {\n        font-size: 12px;\n        font-weight: 300;\n        line-height: 14px;\n        margin-top: 10px;\n      }\n\n      p {\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 21px;\n        text-align: left;\n        color: #545e6b;\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
