import * as React from "react";

const SvgEdit = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 2.702l3 3-9 9-4 1 1-4 9-9zm5-2c.75.75 1.105 1.895 0 3l-1 1-3-3 1-1c1.105-1.105 2.25-.75 3 0z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgEdit;
