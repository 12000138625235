import React, { useEffect, useState } from 'react';
import SvgSave from '../../../../../../../assets/icons/js/save';
import SvgCancel from '../../../../../../../assets/icons/js/Cancel';
import SvgEdit from '../../../../../../../assets/icons/js/Edit';
import MyProjectApi from '../../../../../api/project-api';
import { message, Spin } from 'antd';
import ProjectDetailsDialog from "./project-details-dialog/project-details-dialog";
import { useForm, useWatch } from 'react-hook-form';
import SvgTick from "../../../../../../../assets/icons/js/Tick";


export function DetailsRequirements(props: { project: any }): JSX.Element {
  const formMethods = useForm({
    defaultValues: {
      description: props?.project?.description ?? ''
    }
  })
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);
  const [project, setProject] = useState<any>({...props.project});

  const description = useWatch({control: formMethods.control, name: 'description'});

  const [projectDetailsDialog, setProjectDetailsDialog] = useState({visible: false})

  const submit = (data: { description: string }): void => {
    setApiLoading(true);
    MyProjectApi.updateRequirementsStatus(props.project.id, data).then(
      (response: any) => {
        setEdit(false);
        setProject({...response})

        message.success({
          content: "Requirements status has been updated",
          className: "custom-success-message",
          icon: <SvgTick color={"#227700"}/>
        })

        setApiLoading(false);
      }
    );
  };

  const openProjectDetailsDialog = () => {
    setProjectDetailsDialog({...projectDetailsDialog, visible: true})
  }

  const closeProjectDetailsDialog = (data?: { refetch: boolean }) => {
    setProjectDetailsDialog({...projectDetailsDialog, visible: false})
  }

  return (
    <div className="project-edit-card">
      <>
        <div className="project-edit-header">
          <div className="left">
            <h3>Introduction</h3>
          </div>
          <div className="right">
            {apiLoading ? (
              <>
                <div/>
                <Spin/>
              </>
            ) : (
              <>
                {edit ? (
                  <>
                    <button className="icon-button" onClick={formMethods.handleSubmit(submit)}>
                      <SvgSave width={20}/>
                    </button>
                    <button
                      className="icon-button cancel-icon-hover"
                      onClick={() => setEdit(false)}>
                      <SvgCancel width={16} height={16} color="red"/>
                    </button>
                  </>
                ) : (
                  <>
                    <span/>
                    <button className="icon-button" onClick={() => setEdit(true)}>
                      <SvgEdit/>
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="project-edit-body">
          <div className="content-block">
            {edit ? (
              <div>
                <div className="outline-input-wrapper w-100">
                  <textarea
                    style={{minWidth: '100%', fontSize: '14px', fontWeight: 400, minHeight: 200}}
                    className="w-100 outline-input"
                    rows={2}
                    maxLength={280}
                    {...formMethods.register('description')}
                  />
                  <div className='character-count'> {description.length}/280</div>
                </div>
              </div>
            ) : (
              <div className='column justify-space-between'>
                <span className='description' style={{minHeight: 100}}>
                  {project?.description}
                </span>
                <span className='link w-100 row' style={{justifyContent: "flex-end"}}
                      onClick={openProjectDetailsDialog}>
                  More details
                </span>
              </div>
            )}
          </div>
        </div>
        {
          projectDetailsDialog.visible &&
          <ProjectDetailsDialog visible={projectDetailsDialog.visible}
                                data={projectDetailsDialog}
                                setProject={setProject}
                                project={project} onCancel={closeProjectDetailsDialog}
          />
        }
      </>
    </div>
  );
}
