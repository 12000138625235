import { environment } from '../../../../../../../environment';
import { axiosClient } from '../../../../../../../axios-client';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

class OneDriveIntegrationApi {
  private baseUrl: string = `${environment.backendUrl}/integrations/one-drive`;

  //Integrations
  async install(code: string, redirectURI: string): Promise<null> {
    try {
      const response: AxiosResponse<null> = await axiosClient.post<null>(`${this.baseUrl}/install?code=${code}&redirectURI=${redirectURI}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uninstall(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/uninstall`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async isInstalled(): Promise<boolean> {
    try {
      const response: AxiosResponse<boolean> = await axiosClient.get<boolean>(`${this.baseUrl}/check-installed`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  // async getImports(): Promise<IGoogleDriveProject[]> {
  //   try {
  //     const response: AxiosResponse<IGoogleDriveProject[]> = await axiosClient.get<IGoogleDriveProject[]>(`${this.baseUrl}/imports`);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async getImportById(id: string): Promise<IGoogleDriveFolder> {
  //   try {
  //     const response: AxiosResponse<IGoogleDriveFolder> = await axiosClient.get<IGoogleDriveFolder>(`${this.baseUrl}/imports/${id}`);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getOneDriveMetaData(url: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/items-count?url=${url}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async editImportData(googleDriveFolder: IGoogleDriveFolder): Promise<IGoogleDriveFolder> {
  //   try {
  //     const response: AxiosResponse<IGoogleDriveFolder> = await axiosClient.put<IGoogleDriveFolder>(`${this.baseUrl}/import`, googleDriveFolder);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async deleteImport(id: string): Promise<null> {
  //   try {
  //     const response: AxiosResponse<null> = await axiosClient.delete<null>(`${this.baseUrl}/import/${id}`);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async utils(): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/imports/utils`);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async linkAndImport(url: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/import-projects`, {
        url,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async listFiles(url: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/list-files?url=${url}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async importFilesToProject(projectId: number, url: string, fileNames: string[], config: AxiosRequestConfig) {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/import-files-to-project`, {
        projectId,
        url,
        fileNames
      }, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new OneDriveIntegrationApi();
