import React from 'react';
import SvgUploadFile from '../../../../../../assets/icons/js/UploadFile';
import './widget-upload.scss';
import { environment } from '../../../../../../environment';
import { message, Upload } from "antd";
import SvgAlert from "../../../../../../assets/icons/js/alert";

export class WidgetUpload extends React.Component<any, any> {
  token = localStorage.getItem('access_token');

  uploadFile = {
    name: 'file',
    multiple: true,
    headers: {
      'Authorization': 'Bearer ' + this.token,
    },
    action: environment.backendUrl + '/projects/upload',
    onChange: (info: any) => this.onFileChange(info),
    onRemove: (info: any) => this.onRemove(info)
  };

  constructor(props: any) {
    super(props);
    this.state = {
      fileList: []
    }
  }

  onFileChange(info: any): void {
    const {status} = info.file;

    if (status === 'uploading') {
      this.setState({
        fileList: [...info.fileList]
      });
    }

    if (status === 'done') {
      const response = info.file.response;

      this.props.getFileInfo({
        index: this.props.index,
        value: response
      });
      this.setState({
        fileList: [...info.fileList]
      });

    } else if (status === 'error') {
      console.log(`${info.file.name} file upload failed.`)

      message.error({
        content: `${info.file.name} file upload failed. Please try again!`,
        className: 'custom-error-message',
        icon: <SvgAlert color={"#CC0000"}/>,
        duration: 5
      });

      this.setState({
        fileList: [...info.fileList].filter((file: any) => {
          return file.uid !== info.file.uid
        })
      })

    }
  }

  onRemove(info: any): void {
    this.props.removeFile({
      index: this.props.index,
      id: info.response[0].id
    });


    this.setState({
      fileList: [...this.state.fileList].filter((file: any) => {
        return file.uid !== info.uid
      })
    })
  }

  render() {
    return (
      <div className="widget-upload">
        <div className="form-input-text-area">
          <Upload.Dragger {...this.uploadFile}
                          fileList={this.state.fileList}
                          showUploadList={this.props.showUploadList !== false}>
            <div className="upload-file">
              <SvgUploadFile width="30"/>
              <div>
                <span>Drag your file here or </span>
                <span style={{color: '#1B87E6'}}>browse</span>
              </div>
            </div>
          </Upload.Dragger>
        </div>
      </div>
    );
  }
}
