import React, { useEffect, useState } from 'react';
import SvgKnowledgeGraph from '../../../../../assets/icons/js/knowledge-graph';
import SvgNugget from '../../../../../assets/icons/js/nugget';
import SvgFlag from '../../../../../assets/icons/js/Flag';
import { FlagsDropDown } from './flags-drop-down';
import {
  IFlagType
} from '../../../../../pages/settings/settings-tabs/interview-labs-settings/model/flag-type.interface';
import { findEntityInSelection } from '../helpers/entity';

export function FloatingMenu(props: any): JSX.Element {
  const [visibleDropDown, setVisibleDropDown] = useState<boolean>(false);
  const [selectedFlags, setSelectedFlags] = useState<IFlagType[]>([]);


  useEffect(() => {
    findFlagsData();

    return () => {
      setVisibleDropDown(false);
    }
  }, [])

  const findFlagsData = () => {
    const editorState = props.editorState;

    const entity: any = findEntityInSelection(editorState, "FLAG");

    if (entity && entity.entity.data?.flags) {
      setSelectedFlags(entity.entity.data?.flags);
    }
  }

  const menu: { icon: JSX.Element, onClick: () => any, openDropDown?: boolean }[] = [
    {
      icon: <SvgKnowledgeGraph width={16} height={16} color={'#545e6b'}/>,
      onClick: () => props.openKnowledgeGraphDialog()
    },
    {
      icon: <SvgNugget width={16} height={16} color={'#545e6b'}/>,
      onClick: () => props.openEvidenceDialog(),
    },
    {
      icon: <SvgFlag width={16} height={16} color={visibleDropDown ? '#ffffff' : '#545e6b'}/>,
      onClick: () => setVisibleDropDown(!visibleDropDown),
      openDropDown: true
    }
  ];

  function assignFlagsToSelectedText(selectedFlags: IFlagType[]): void {
    props.assignFlagsToSelectedText({
      selectedFlags: selectedFlags
    });
  }

  return (
    <div style={{...props.nodeButtonPosition, position: props.noteType ? 'fixed' : 'absolute'}}>
      <div className={`floating-button ${props.noteType ? 'floating-button-bottom' : ''}`}
           onMouseDown={(e) => {
             e.stopPropagation();
             e.preventDefault();
           }}>

        {menu.map(({icon, onClick, openDropDown}, index: number) => {
            return (
              <div key={index}>
                <button style={visibleDropDown && openDropDown ? {backgroundColor: '#1b87e6'} : {}}
                        className="floating-inside-button" onClick={onClick}>
                  {icon}
                </button>
                {
                  openDropDown && visibleDropDown &&
                  <FlagsDropDown key={index.toString(35) + index}
                                 selectedFlags={selectedFlags}
                                 assignFlagsToSelectedText={assignFlagsToSelectedText}
                                 flags={props?.flags}/>
                }
              </div>
            )
          }
        )}
        {
          props.noteType ?
            <div className="top-triangle"/> :
            <div className="bottom-triangle"/>
        }
      </div>
    </div>
  );
}
