import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

interface TextFitProps {
  children: ReactNode;
  mode?: 'multi' | 'single';
  min?: number;
  max?: number;
  style?: CSSProperties;
}

const TextFit: React.FC<TextFitProps> = ({children, mode = 'multi', min = 10, max = 100, style}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const [initialized, setInitialized] = useState(false);
  const [fontSize, setFontSize] = useState<number | null>(null);

  useEffect(() => {
    if (textRef.current && containerRef.current) {
      setInitialized(true);
    }
  }, [textRef.current, containerRef.current]);

  useEffect(() => {
    if (initialized) {
      requestAnimationFrame(() => {
        requestAnimationFrame(reSizeText);
      });
    }
  }, [children, min, max, mode, initialized, containerRef.current?.clientHeight]);

  const testPrimary = mode === 'multi'
    ? () => textRef.current!.scrollHeight <= containerRef.current!.offsetHeight
    : () => textRef.current!.scrollWidth <= containerRef.current!.offsetWidth;


  const testSecondary = mode === 'multi'
    ? () => textRef.current!.scrollWidth <= containerRef.current!.offsetWidth
    : () => textRef.current!.scrollHeight <= containerRef.current!.offsetHeight;

  function binarySearchForFontSize(low: number, high: number, testCondition: () => boolean) {
    let mid: number;
    while (low <= high) {
      mid = Math.floor((low + high) / 2);
      containerRef.current!.style.fontSize = `${mid}px`;

      if (testCondition()) low = mid + 1;
      else high = mid - 1;
    }
    return Math.min(low, high);
  }

  function reSizeText() {
    let mid: number;
    let optimalFontSize: number;

    mid = binarySearchForFontSize(min, max, testPrimary);

    if (mode === 'multi') {
      mid = binarySearchForFontSize(min, mid, testSecondary);
    }

    optimalFontSize = Math.max(mid, min);
    optimalFontSize = Math.min(optimalFontSize, max);

    setFontSize(optimalFontSize);
  }

  return (
    <div ref={containerRef} style={{...style, fontSize: fontSize !== null ? fontSize : undefined}}>
      <div ref={textRef} style={{display: 'block'}}>
        {children}
      </div>
    </div>
  );
};

export default TextFit;
