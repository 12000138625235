// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-settings .label-wrapper {
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 8px;
}
.data-settings p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #545e6b;
}
.data-settings h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #545e6b;
}
.data-settings h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #9B9B9B;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/data-settings/data-settings.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,mBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AADJ;AAIE;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAJJ","sourcesContent":[".data-settings {\n  .label-wrapper {\n    border-bottom: 1px solid #E8E8E8;\n    padding-bottom: 8px;\n  }\n\n  p {\n    margin-bottom: 0;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16px;\n    text-align: left;\n    color: #545e6b;\n  }\n\n  h4 {\n    //styleName: heading-04;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: left;\n    color: #545e6b;\n  }\n\n  h5 {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    text-align: left;\n    color: #9B9B9B;;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
