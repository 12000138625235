import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../store/global-context";

type PopUpTutorialReturn = {
  popUpTutorial: { open: boolean },
  onPopUpClose: (checked?: boolean) => void,
}

export function usePopUpTutorial(page: 'stories' | 'analytics' | 'graphs' | 'snapshots'): PopUpTutorialReturn {
  const [popUpTutorial, setPopUpTutorial] = useState({open: false});
  const globalContext = useGlobalContext();

  function isUserRegisteredMoreThanThreeMonths(createdAt: string): boolean {
    const registrationDate = new Date(createdAt);
    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 3));

    return registrationDate <= threeMonthsAgo;
  }

  useEffect(() => {
    if (!globalContext.user) {
      return;
    }

    if (isUserRegisteredMoreThanThreeMonths(globalContext.user.createdAt)) {
      return;
    }

    const hidePopUps = localStorage.getItem('hidePopUps');

    if (!hidePopUps) {
      setPopUpTutorial({open: true});
      return;
    }

    if (JSON.parse(hidePopUps).indexOf(page) > -1) {
      return;
    }

    setPopUpTutorial({open: true});
  }, []);

  function onPopUpClose(checked?: boolean) {
    setPopUpTutorial({open: false});

    if (!checked) {
      return;
    }

    const hidePopUps = localStorage.getItem('hidePopUps');

    if (!hidePopUps) {
      localStorage.setItem('hidePopUps', `["${page}"]`);
      return;
    }

    const newArr: string[] = JSON.parse(hidePopUps)
    newArr.push(page)
    localStorage.setItem('hidePopUps', JSON.stringify(newArr))
  }

  return {
    popUpTutorial,
    onPopUpClose
  }
}
