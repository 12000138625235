import axios, { AxiosResponse } from "axios";
import { axiosClient } from "../../../../axios-client";
import { environment } from "../../../../environment";
import { IGallery } from "../model/gallery.interface";

class MediaApi {
  private baseUrl: string = `${environment.backendUrl}`;

  async uploadImage(file: any): Promise<any> {
    const response: any = await axiosClient.put<any>(
      `${this.baseUrl}/story/gallery`,
      file
    );
    return response.data;
  }

  async getImages(params: any): Promise<IGallery> {
    const response: AxiosResponse<IGallery> = await axiosClient.get<IGallery>(
      `${this.baseUrl}/story/gallery`,
      {params}
    );
    return response.data;
  }

  async deleteImage(id: number): Promise<any> {
    const response: any = await axiosClient.delete(
      `${this.baseUrl}/story/gallery/${id}`
    );
    return response.data;
  }

  async getUnsplashPhotos(params: any, query: string): Promise<any> {
    let url = `https://api.unsplash.com/photos`;
    if (query.length) {
      url = `https://api.unsplash.com/search/photos?query=${query}`;
    }

    const response: AxiosResponse<any> = await axios.get(url, {
      params: params,
      headers: {
        Authorization: "Client-ID fNrmuquFqpCYieL2S9HHGIgdj2dVoz_ZTFv-W4JYtgc",
      },
    });

    return {
      results: query.length ? response.data.results : response.data,
      total: response.headers["x-total"],
    };
  }
}

export default new MediaApi();
