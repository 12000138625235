import { CompositeDecorator } from "draft-js";
import IFrameStrategy from "../../story-edit/story-editor/strategies/iframe-strategy/iframe-strategy";
import PreviewImageStrategy from "./strategies/image-strategy/preview-image-strategy";
import { PreviewLinkStrategy } from "./strategies/preview-link-strategy";
import { PreviewProjectStrategy } from "./strategies/project-strategy/preview-project-strategy";
import GraphStrategy from '../../story-edit/story-editor/strategies/graph-strategy/graph-strategy';
import {
  RecommendationStrategy
} from "../../story-edit/story-editor/strategies/recommendation-strategy/recommendation-strategy";

export const compositeDecorator = (readOnly?: boolean) =>
  new CompositeDecorator([
    {
      strategy: (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK"
          );
        }, callback);
      },
      component: PreviewLinkStrategy,
      props: {readOnly},
    },
    {
      strategy: (ContentBlock, callback, contentState) => {
        ContentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "PROJECT"
          );
        }, callback);
      },
      component: PreviewProjectStrategy,
      props: {readOnly},
    },
    {
      strategy: (ContentBlock, callback, contentState) => {
        ContentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "FIGURE"
          );
        }, callback);
      },
      component: PreviewImageStrategy,
      props: {readOnly},
    },
    {
      strategy: (ContentBlock, callback, contentState) => {
        ContentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "IFRAME"
          );
        }, callback);
      },
      component: IFrameStrategy,
      props: {readOnly},
    },
    {
      strategy: (ContentBlock, callback, contentState) => {
        ContentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "GRAPH"
          );
        }, callback);
      },
      component: GraphStrategy,
      props: {readOnly},
    },
    {
      strategy: (ContentBlock, callback, contentState) => {
        ContentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return entityKey !== null && contentState.getEntity(entityKey).getType() === "RECOMMENDATION";
        }, callback)
      },
      component: RecommendationStrategy,
      props: {readOnly}
    }
  ]);
