import React, { useState } from 'react';
import { Divider, Modal, Select, Tooltip } from "antd";
import { useQuery, UseQueryResult } from "react-query";
import './save-key-finding-dialog.scss'
import { IProjectTableData } from "../../../../../projects/models/project-table-data.interface";
import projectEditApi from "../../../../../projects/project-edit/api/project-edit-api";
import SvgSearch from "../../../../../../assets/icons/js/Search";
import SvgArrowDown from "../../../../../../assets/icons/js/ArrowDown";
import { IProjectTable } from "../../../../../projects/models/project-table.interface";
import ProjectApi from "../../../../../projects/api/project-api";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import SvgBetaLabel from "../../../../../../assets/icons/js/BetaLabel";

type IProps = {
  open: boolean;
  data: {
    chatId: number,
    description: string,
    projectReference?: { projectName: string, projectId: number },
    surveyReference?: { surveyID: number, surveyName: string },
    fileReference?: any
  };
  onCancel: (data?: { chatIdSaved: number }) => void;
  fromSurveyDetails?: boolean;
}
const SaveKeyFindingDialog = (props: IProps) => {
  const fileReference = props.data.fileReference;
  const {projectReference} = props.data;
  const {projectId: fileReferenceProjectId, proejctName: fileReferenceProjectName} = props.data.fileReference || {};

  const projectId = projectReference?.projectId || fileReferenceProjectId;
  const projectName = projectReference?.projectName || fileReferenceProjectName;

  const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(projectId);

  const [apiLoading, setApiLoading] = useState(false);

  const projects: UseQueryResult<IProjectTableData, unknown> = useQuery(["select-projects"], () => ProjectApi.getProjects({
    page: 1,
    pageSize: 9999,
    sort: 'updatedAt:desc',
  }), {
    refetchOnWindowFocus: false,
  });

  const filterOption = (input: string, option?: {
    label: string;
    value: string,
    children: string
  }) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase());


  const handleSelectChange = (id: number) => {
    setSelectedProjectId(id);
  }

  const onOk = () => {
    if (!selectedProjectId) {
      return;
    }

    setApiLoading(true);
    const payload = {
      projectNugget: {
        description: props.data.description,
        aiGenerated: true,
        nodes: [],
        fileID: fileReference?.id,
        reference: props.data.surveyReference?.surveyName
      },
      projectId: selectedProjectId,
      surveyID: props.data.surveyReference?.surveyID,
    }

    projectEditApi.createNugget(payload).then(() => {
      setApiLoading(false);
      props.onCancel({chatIdSaved: props.data.chatId});
    })
  }


  return (
    <Modal
      title={<div className="row align-items-center" style={{gap: 5}}>
        <div>Save key finding</div>
        <Tooltip placement="topLeft" className="pointer" title="Beta feature">
          <SvgBetaLabel/>
        </Tooltip>
      </div>}
      open={props.open}
      onCancel={() => props.onCancel()}
      okButtonProps={{disabled: !selectedProjectId, loading: apiLoading}}
      cancelButtonProps={{type: 'link'}}
      okText={"Save"}
      width={600}
      onOk={onOk}
    >
      <div className="save-key-finding-dialog-wrapper">
        {
          props.fromSurveyDetails && projectId ?
            <div>
              <p style={{fontWeight: 300, marginBottom: 0}}> This response will be saved as a project's key finding</p>
            </div> :
            <div>
              {/*{*/}
              {/*  projectId ?*/}
              {/*  <div>*/}
              {/*    <p>Project</p>*/}
              {/*    <p className="key-finding-project-name">*/}
              {/*      {projectName}*/}
              {/*    </p>*/}
              {/*  </div> :*/}
              {
                projects.isLoading ? <Loader/> :
                  <div className="outline-input-wrapper" style={{marginBottom: 20}}>
                    <div className="outline-input-label">
                      Choose the project to save key finding
                    </div>
                    <div className="relative">
                      <div className="absolute" style={{top: 6, left: 5, zIndex: 99}}>
                        <SvgSearch color={"#9B9B9B"} width={12} height={12}/>
                      </div>
                      <Select showSearch
                              placeholder={"Search for project"}
                              style={{width: '100%'}}
                              value={selectedProjectId}
                              aria-label={"Select project"}
                              filterOption={filterOption}
                              suffixIcon={<SvgArrowDown/>}
                              onChange={handleSelectChange}>
                        {projects.data && projects.data.content.map((project: IProjectTable, i: number) =>
                          <Select.Option
                            key={i}
                            value={project.id}>{project.name}</Select.Option>)}
                      </Select>
                    </div>
                  </div>
              }
              <div>
                <p>Key finding content</p>
                <p className="key-finding-description">
                  {props.data.description}
                </p>
              </div>
            </div>
        }
      </div>
      <Divider style={{marginBottom: -20, marginTop: 10}}/>
    </Modal>
  );
};

export default SaveKeyFindingDialog;
