import React, { useContext } from 'react'
import "./linked-project-card.scss"
import { IProject } from '../../../../projects/models/project.interface';
import StoryContext from "../../story-edit.context";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import FormattedDate from '../../../../../_shared/helpers/formatted-date';
import { Popover, Tooltip } from "antd";
import SvgNugget2 from "../../../../../assets/icons/js/Nugget2";

const LinkedProjectCard = (props: any) => {
  const storyContext = useContext(StoryContext);
  const navigate = useNavigate();

  const data: { project: any, selectedText: string[], entityMapIds: number[], tags: any[] } = props.data;
  const projectsLinkedInDraft: (HTMLElement | null)[] = data?.entityMapIds?.map((entityMapId: number) => document.getElementById(entityMapId + '-draft'))
  const project: IProject = data?.project;

  const tags = Array.isArray(data?.project?.projectTags) ? [...data?.project?.projectTags] : [];

  function isHovering(hover: boolean): void {
    projectsLinkedInDraft?.forEach((projectLinkedInDraft: HTMLElement | null) => {
      if (projectLinkedInDraft) {
        if (hover) {
          projectLinkedInDraft.style.color = '#1B3380';
        } else {
          projectLinkedInDraft.removeAttribute('style');
        }
      }
    })
  }

  function navigateToProject() {
    navigate({pathname: '/projects/edit', search: qs.stringify({id: project.id})}, {
      state: {
        fromStory: true,
        id: storyContext.id
      }
    })
  }

  return (
    <div key={project.id}
         onMouseEnter={() => isHovering(true)}
         onMouseLeave={() => isHovering(false)}
         id={JSON.stringify(project?.id)}
         className='linked-project'>
      <div className='linked-project-header'>
        <div className='name-and-icon'>
          <span onClick={() => navigateToProject()} className='project-name'>{project.name}</span>
        </div>
        <div className="row align-items-center" style={{gap: 5}}>
          <Tooltip title="Key findings" placement={"topRight"}>
            <button data-testid={"key-findings-view-btn"}
                    onClick={() => props.openLinkedKeyFindingsView(project.id, project.name)} className="icon-button">
              <SvgNugget2/>
            </button>
          </Tooltip>

          <div className='project-due-date'>
            <FormattedDate date={project.dueDate} formatString="MMM d, yyyy"/>
          </div>
        </div>
      </div>
      <div className="linked-project-content column">
        <span
          className='description'>{!!project?.description ? project.description : 'No introduction available'}</span>
      </div>
      <div className="linked-project-tags-wrapper">
        <div className="linked-project-tags">
          {
            tags && tags?.length > 0 ?
              [...tags].splice(0, 5).map((tag: any) => {
                return <div key={tag.id}
                            className='project-tag'>{tag.tag.name}</div>
              }) :
              <></>
          }
          {
            tags && tags?.length > 5 ?
              <Popover placement={"bottomLeft"}
                       content={<div className="popover-project-tags-wrapper">
                         {[...tags].slice(5).map((tag: any) => {
                           return <div className="project-tag" key={tag.id}>
                             {tag.tag.name}
                           </div>
                         })}
                       </div>}>
                <div className="project-tag">
                  +{tags.length - 5}
                </div>
              </Popover> :
              <></>
          }
          {
            tags && tags?.length === 0 ? <p>No tags available</p> : <></>
          }
        </div>
      </div>
    </div>
  )
}

export default LinkedProjectCard
