// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-repository-archived-wrapper h3 {
  font-size: 24px;
  line-height: 44px;
  color: #545e6b;
  font-weight: 400;
  margin-bottom: 0;
}
.file-repository-archived-wrapper .file-library-archived-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file-repository-archived-wrapper .file-library-archived-table {
  margin-top: 20px;
}
.file-repository-archived-wrapper .file-library-archived-table .action-hover-column {
  display: flex;
  justify-content: center;
}
.file-repository-archived-wrapper .file-library-archived-table-selected-rows .ant-table-thead .ant-table-cell:before {
  content: none;
}
.file-repository-archived-wrapper .file-library-archived-table-selected-rows .ant-table-thead .column-title-to-be-hided {
  display: none;
}
.file-repository-archived-wrapper .file-library-archived-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.file-repository-archived-wrapper .file-library-archived-table-header .empty-recycle-bin-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #1b87e6;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  padding: 2px 7px;
  cursor: pointer;
}
.file-repository-archived-wrapper .file-library-archived-table-header .empty-recycle-bin-button:hover {
  background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/pages/repositories/repositories-archived/repositories-archived.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAII;EACE,aAAA;EACA,uBAAA;AAFN;AASQ;EACE,aAAA;AAPV;AAWM;EACE,aAAA;AATR;AAcE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AAZJ;AAcI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EAEA,eAAA;AAbN;AAeM;EACE,yBAAA;AAbR","sourcesContent":[".file-repository-archived-wrapper {\n  h3 {\n    font-size: 24px;\n    line-height: 44px;\n    color: #545e6b;\n    font-weight: 400;\n    margin-bottom: 0;\n  }\n\n  .file-library-archived-title {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .file-library-archived-table {\n    margin-top: 20px;\n\n    .action-hover-column {\n      display: flex;\n      justify-content: center;\n    }\n  }\n\n  .file-library-archived-table-selected-rows {\n    .ant-table-thead {\n      .ant-table-cell {\n        &:before {\n          content: none;\n        }\n      }\n\n      .column-title-to-be-hided {\n        display: none;\n      }\n    }\n  }\n\n  .file-library-archived-table-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-top: 20px;\n\n    .empty-recycle-bin-button {\n      display: flex;\n      flex-direction: row;\n      gap: 10px;\n      color: #1b87e6;\n      font-size: 14px;\n      line-height: 16px;\n      align-items: center;\n      padding: 2px 7px;\n\n      cursor: pointer;\n\n      &:hover {\n        background-color: #f5f5f5;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
