import React, { useState } from 'react';
import { IMetaTag } from '../../../settings/settings-tabs/taxonomy/meta-tags/model/meta-tag.interface';
import { Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ITag } from '../../../settings/settings-tabs/taxonomy/meta-tags/model/tag.interface';
import './add-edit-tags-dialog.scss';
import SvgHelp from '../../../../assets/icons/js/Help';
import ProjectEditApi from '../api/project-edit-api';
import SettingsApi from "../../../settings/api/settings-api";
import Pagination from "../../../../_shared/components/pagination/pagination";
import { useQuery } from "react-query";

export function AddEditTagsDialog(props: any) {
  const columns: ColumnsType<IMetaTag> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 122,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 650,
      render: (tags: ITag[]) => (
        <div className="row" style={{gap: 10, flexWrap: 'wrap'}}>
          {tags && tags.map((tag: ITag) => {
            return (
              <span
                className={isSelected(tag) ? 'tag active-tag' : 'tag'}
                key={tag.id} onClick={() => toggleTag(tag)}>{tag.name}</span>
            )
          })}
        </div>
      )
    }
  ];

  const [tags, setTags] = useState<any[]>(props.data.tags);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const metaTags = useQuery(['allMetaTags', page, pageSize], () => SettingsApi.getMetaTags({
    page,
    pageSize
  }));

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }


  function title(): JSX.Element {
    return <div className="row align-items-center">
      <span style={{color: "#545e6b"}}>Meta tags</span>
      <SvgHelp style={{marginLeft: 8, color: "#545e6b"}}/>
    </div>
  }

  function isSelected(tag: any) {
    return tags.findIndex((val: any) => val.id == tag.id) > -1;
  }

  function onSave() {
    const payload = {
      tags: tags.map((tag: any) => tag.id)
    }

    if (props.googleDrive) {
      props.onSave({
        modal: true,
        tags
      });

      return;
    }

    ProjectEditApi.onSaveTags(props.data.id, payload)
      .then(() => {
        props.onSave({
          modal: true,
          tags
        });
      });
  }

  function toggleTag(tag: ITag): void {
    if (!isSelected(tag)) {
      setTags((prev: any) => [...prev, tag])
    } else {
      let tempSelectedTags = [...tags];
      const index = tempSelectedTags.findIndex((value: any) => value.id === tag.id);
      tempSelectedTags.splice(index, 1);
      setTags(tempSelectedTags);
    }
  }



  return (
    <Modal
      title={title()}
      open={props.data?.visible}
      centered
      width={820}
      onCancel={() => (props.onCancel())}
      footer={[
        <Button key="submit" type="primary" onClick={onSave}>
          {'Save'}
        </Button>,
      ]}>

      <div className="add-edit-tags-dialog">
        {
          metaTags.data && metaTags.data?.pagination.totalElements > 0 &&
          <Pagination totalItems={metaTags.data?.pagination.totalElements}
                      currentPage={page}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}/>
        }
        <Table style={{marginTop: 10}}
               columns={columns}
               pagination={false}
               dataSource={metaTags.data?.content}
               rowKey="id"
               loading={metaTags.isLoading}/>
      </div>
    </Modal>
  );
}
