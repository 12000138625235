import * as React from "react";

const SvgDownload = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g
        fill={props.color ? props.color : '#545E6B'}>
        <path
          d="M5.282 20c-.446 0-.607-.046-.77-.134-.163-.087-.291-.215-.378-.378-.088-.163-.134-.324-.134-.77V13c0-.552.448-1 1-1s1 .448 1 1v5h12v-5c0-.552.448-1 1-1s1 .448 1 1v5.718c0 .446-.046.607-.134.77-.087.163-.215.291-.378.378-.163.088-.324.134-.77.134zM14 4v5.999L16 10l-4 4-4.007-4L10 9.999V4h4z"
          transform="translate(-139 -328) translate(107 56) translate(24 264) translate(8 8)"/>
      </g>
    </svg>
  );
}

export default SvgDownload;
