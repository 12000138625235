import React, { memo, useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import HomeApi from '../../../api/home-api';
import { DatePicker, Select, TreeSelect } from 'antd';
import { IMetaTag } from '../../../../settings/settings-tabs/taxonomy/meta-tags/model/meta-tag.interface';
import { ITag } from '../../../../settings/settings-tabs/taxonomy/meta-tags/model/tag.interface';
import AnalyticsApi from '../../../../analytics/api/analytics-api';
import moment from 'moment';
import SvgArrowDown from '../../../../../assets/icons/js/ArrowDown';
import { ISearchData } from '../../../models/search-data.interface';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import "./search-filters.scss"
import { IProjectTypeFilter, ISearchFilters } from "../../../models/search-filters.interface";

const {Option} = Select;
const {RangePicker} = DatePicker;

function SearchFilters(props: {
  filtersChanged: (data: ISearchData) => void,
  searchData?: ISearchData,
  isMatchTagSearch?: boolean
}): JSX.Element {

  const filters: UseQueryResult<ISearchFilters, unknown> = useQuery('filters', () => AnalyticsApi.getFilters(), {
    refetchOnWindowFocus: false,
  });

  const metaTags: UseQueryResult<IMetaTag[], unknown> = useQuery("metaTags", HomeApi.getMetaTags, {
    refetchOnWindowFocus: false,
    select: (data: { content: IMetaTag[] }) => {
      const newData: any[] = data.content.map((data: IMetaTag) => {
        return {
          id: data.id + 'parent',
          children: data.tags.map((data: ITag) => {
            return {title: data.name, value: data.id}
          }),
          title: data.name,
          value: data.id + 'parent',
          disabled: true
        }
      });

      return newData;
    }
  });

  const location: any = useLocation();
  const urlParam: ISearchData = qs.parse(location?.search);

  const [projectType, setProjectType] = useState<number | null>(urlParam?.formId ? parseInt(String(urlParam.formId)) : null);
  const [programId, setProgramId] = useState<number | string>(urlParam?.programId ? parseInt(String(urlParam?.programId)) : '');
  const [dates, setDates] = useState<[moment.Moment, moment.Moment] | undefined>(urlParam?.startDate && urlParam?.endDate ? [moment(urlParam.startDate), moment(urlParam?.endDate)] : undefined)
  const toNumbers = (arr: any[] | undefined) => arr?.map(Number);
  const [tags, setTags] = useState<number[] | undefined>(props.searchData?.tags ? props.searchData.tags : urlParam?.tags ? toNumbers([...urlParam.tags]) : []);
  const [params, setParams] = useState<any>();

  useEffect(() => {
    const data = {
      ...params
    };

    if (!dates) {
      if (!dates?.[0]) {
        delete data.startDate;
      }
      if (!dates?.[1]) {
        delete data.endDate;
      }
    } else {
      data.startDate = dates[0].utcOffset(0).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString();
      data.endDate = dates[1].utcOffset(0).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString();
    }

    if (tags) {
      if (!tags.length) {
        delete data.tags
      } else {
        data.tags = tags;
      }
    }

    if (!programId) {
      delete data.programId
    } else {
      data.programId = programId;
    }

    if (!projectType) {
      delete data.formId
    } else {
      data.formId = projectType
    }

    props.filtersChanged(data);
  }, [params, projectType, programId, tags, dates]);


  return (
    <div className="row search-filters">
      <>
        <div className="column">
          <Select style={{minWidth: 240}}
            value={programId}
            suffixIcon={<SvgArrowDown/>}
            placeholder="All programs"
            onChange={(value: number | string) => setProgramId(value)}>
            <Option value="" key="all-programs">All programs</Option>
            {filters?.data?.programs?.map((projectType: IProjectTypeFilter) => {
              return (
                <Option value={projectType.id} key={projectType.id}>{projectType.name}</Option>
              )
            })}
          </Select>
        </div>

        <div className="column">
          <Select style={{minWidth: 240}}
                  placeholder="All project types"
                  value={projectType}
                  suffixIcon={<SvgArrowDown/>}
                  onChange={(value: number | string) => setProjectType(parseInt("" + value))}>
            <Option value="" key="all-project-types">All project types</Option>
            {filters?.data?.projectTypes?.map((projectType: IProjectTypeFilter) => {
              return (
                <Option value={projectType.id} key={projectType.id}>{projectType.name}</Option>
              )
            })}
          </Select>
        </div>

        <RangePicker
          disabledDate={(current: moment.Moment) => {
            return current.isAfter(moment())
          }}
          format="DD-MM-YYYY"
          value={dates}
          onChange={(values: any) => {
            setDates(values)
          }}/>
        {
          !props.isMatchTagSearch &&
            <div className="column">
                <TreeSelect
                  style={{width: 240}}
                  value={tags}
                  dropdownStyle={{width: 240, overflow: 'auto'}}
                  treeData={metaTags?.data}
                  placeholder="All tags"
                  treeDefaultExpandAll
                  multiple
                  suffixIcon={<SvgArrowDown/>}
                  showArrow
                  onChange={(value: any[]) => setTags(value)}
                />
            </div>
        }
      </>
    </div>
  );
}

export default memo(SearchFilters);
