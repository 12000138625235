import React from 'react';
import './loader.scss';

export function Loader(props: any) {
  return <>
    <div data-testid={"loader"} className="loader" {...props}>
      <span {...props}/>
      <span {...props}/>
      <span {...props}/>
      <span {...props}/>
    </div>
  </>

}
