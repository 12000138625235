import MyProjectApi from '../../../api/project-api';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../../_shared/components/loader/loader';
import { IProgram } from '../../../../settings/settings-tabs/taxonomy/programs/model/program.interface';
import { Button, Modal, Select } from 'antd';
import { IProject } from '../../../models/project.interface';

interface Props {
  data: {
    visible: boolean,
    project: any,
    programName: string;
  }
  loadPage?: any
  onCancel: any
}

export default function MoveProjectDialog(props: Props) {
  const [loader, setLoader] = useState<boolean>(true);
  const [programs, setPrograms] = useState<IProgram[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<number>();
  const [moveLoading, setMoveLoading] = useState<boolean>(false);


  useEffect(() => {
    loadPage();
  }, [selectedProgram]);

  function onConfirm(): void {
    if (selectedProgram) {
      setMoveLoading(true);
      MyProjectApi.moveProjectToProgram(props.data.project.id, selectedProgram)
        .then(() => {
          setMoveLoading(false);
          props?.loadPage && props.loadPage();
          props.onCancel();
        });
    }
  }

  function loadPage(): void {
    MyProjectApi.getPrograms()
      .then((response: { content: IProgram[] }) => {
        setPrograms(response.content);
        setLoader(false);
      })
  }

  return (
    <Modal
      title={`Move Project '${props.data.project.name}' to Program`}
      open={props.data.visible}
      onCancel={() => (props.onCancel())}
      centered
      width={620}
      footer={[
        <Button key="submit" type="primary" loading={moveLoading} onClick={onConfirm}>
          Move
        </Button>,
      ]}>

      <div className="confirmation-dialog">
        {loader ? <Loader/> :
          <div className="w-100">
            <p style={{color: "#545e6b", fontSize: 12}}>Currently this Project is assigned
              under <b>{props.data.project.program}</b> Program.</p>
            <div className="outline-input-wrapper fxFlex">
              <span className="outline-input-label">Change Program</span>
              <Select className="w-100"
                      value={selectedProgram}
                      onChange={(id: number) => setSelectedProgram(id
                      )}>
                {programs.map((program: IProgram) => {
                  return (
                    <Select.Option key={program.id}
                                   value={program.id}>
                      <span>{program.name}</span>
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}
