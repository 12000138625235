import { environment } from '../../../../../environment';
import { IProject } from '../models/project.interface';
import { IProgram } from '../../../../settings/settings-tabs/taxonomy/programs/model/program.interface';
import { ITeamMemberData } from '../../../../settings/settings-tabs/team/model/team-member.interface';
import { axiosClient } from '../../../../../axios-client';
import { AxiosResponse } from 'axios';

class CreateProjectApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getFormById(id: string): Promise<IProject> {
    try {
      const response: AxiosResponse<IProject> = await axiosClient.get<IProject>(
        `${this.baseUrl}/forms/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createIntakeProject(project: IProject): Promise<IProject> {
    try {
      const response: any = await axiosClient.post<IProject>(
        `${this.baseUrl}/projects`,
        project
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getPrograms(): Promise<{ content: IProgram[], totalSize: number }> {
    try {
      const response: AxiosResponse<{ content: IProgram[], totalSize: number }> = await axiosClient.get<{
        content: IProgram[],
        totalSize: number
      }>(
        `${this.baseUrl}/program`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getOwners(): Promise<ITeamMemberData> {
    try {
      const response: AxiosResponse<ITeamMemberData> = await axiosClient.get<ITeamMemberData>(
        `${this.baseUrl}/teammembers`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new CreateProjectApi();
