import React from 'react';
import './linked-stories.scss'
import SvgStories from "../../../../../../assets/icons/js/Stories";
import { IStory } from "../../../../../stories/model/story-interface";
import storiesApi from "../../../../../stories/api/stories-api";
import StoryTile from "../../../../../stories/components/story-tile/story-tile";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import projectEditApi from "../../../api/project-edit-api";
import { Button, Empty, message } from "antd";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import SvgAddBig from "../../../../../../assets/icons/js/addBig";
import SvgTrash from "../../../../../../assets/icons/js/Trash";
import SvgRestoreTrash from "../../../../../../assets/icons/js/RestoreTrash";
import { ConfirmationDialog } from "../../../../../../_shared/components/confirmation-dialog/confirmation-dialog";
import SvgTick from "../../../../../../assets/icons/js/Tick";
import SvgAlert from "../../../../../../assets/icons/js/alert";
import Pagination from "../../../../../../_shared/components/pagination/pagination";

const LinkedStories = (props: any) => {
  const navigate = useNavigate();
  const {project} = props;
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);


  const stories = useQuery(["projectStories", project.id, page, pageSize], () => projectEditApi.getProjectStories(project.id, {
    page,
    pageSize
  }));

  const [archiveConfirmationDialog, setArchiveConfirmationDialog] = React.useState<any>({visible: false, id: null});

  const navigateToStory = (id?: number) => {
    if (id) {
      navigate({pathname: "/stories/edit", search: qs.stringify({id: id})}, {state: {projectId: project.id}})
    } else {
      navigate({
        pathname: "/stories/edit",
      }, {
        state: {
          linkedProject: {
            id: project.id,
            form: {icon: project.icon},
            name: project.name,
            dueDate: project.dueDate,
            description: project.description
          }
        }
      })
    }
  }

  const archiveStory = (storyId: number | undefined) => {
    if (storyId) {
      storiesApi.archiveStory(storyId).then(() => {
        stories.refetch();
        message.success({
          content: 'Story archived successfully!',
          className: 'custom-success-message',
          icon: <SvgTick color={"#227700"}/>
        })
      }).catch(error => {
        message.error({
          content: 'Error archiving story!',
          className: 'custom-error-message',
          icon: <SvgAlert color={"#CC0000"}/>
        })
      })
    }
  }

  const onArchiveConfirmationDialog = (props: any) => {
    if (props.modal) {
      archiveStory(props.id);
      onDialogCancel();
    }
  }

  const onDialogCancel = () => {
    setArchiveConfirmationDialog({visible: false})
  }

  const openArchiveConfirmationDialog = (story?: Partial<IStory>) => {
    setArchiveConfirmationDialog({
      visible: true,
      id: story?.id,
      okText: 'Move',
      title: 'Move to Recycle Bin',
      titleSec: 'You\'re about to move this story to the Recycle Bin',
      content: `${story?.title || 'Untitled'}`,
      description: <>
        Continuing will remove the story from this list. You can easily restore it by going to
        the <SvgTrash/> Recycle Bin tab and clicking
        the <SvgRestoreTrash/> Restore From Recycle Bin button.
      </>
    });
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  return (
    <div className="linked-stories-wrapper">
      <div className="row align-items-start">
        <SvgStories/>
        <h3 style={{marginLeft: 10}}>Stories</h3>
      </div>
      <div className="row justify-space-between">
        <Button type='primary'
                onClick={() => navigateToStory()}
                icon={<span className="anticon"><SvgAddBig width={14} height={14} color={'#fff'}/></span>}>
          New story
        </Button>
        {stories.data && stories.data?.pagination.totalElements > 0 &&
          <Pagination totalItems={stories.data?.pagination.totalElements}
                      currentPage={page}
                      showSizeChanger
                      pageSize={pageSize}
                      onChange={handlePaginationChange}/>
        }
      </div>
      <div>
        {
          stories.isLoading ? <Loader/> :
            <div className="row linked-stories-library">
              {stories.data && stories.data?.pagination.totalElements === 0 ?
                <div className="w-100 column justify-space-center">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                </div> :
                stories?.data?.content.map((story: IStory, i: number) => {
                  return <StoryTile fromProjectEdit={true}
                                    navigateToStory={navigateToStory}
                                    story={story}
                                    key={i}
                                    openArchiveConfirmationDialog={openArchiveConfirmationDialog}
                  />
                })}
            </div>
        }

      </div>
      {
        archiveConfirmationDialog.visible &&
        <ConfirmationDialog data={archiveConfirmationDialog}
                            onConfirm={onArchiveConfirmationDialog}
                            onCancel={onDialogCancel}/>

      }
    </div>
  );
};

export default LinkedStories;
