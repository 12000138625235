import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Dropdown, Select } from "antd";
import SvgArrowDown from "../../../../../../../assets/icons/js/ArrowDown";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import "./activity-filters.scss"
import { IActivityFiltersProps } from "./model/activity-filters.props";
import ActivityFeedApi from "../../api/activity-feed-api";
import { ITeamMember } from "../../../../../../settings/settings-tabs/team/model/team-member.interface";


const ActivityFilters = (props: IActivityFiltersProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [membersChecked, setMembersChecked] = useState<any[]>();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [search, setSearch] = useState("")
  const [teamMembers, setTeamMembers] = useState<any[]>()
  const [filteredTeamMembers, setFilteredTeamMembers] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTeamMembers()
  }, []);

  useEffect(() => {
    if (search) {
      setFilteredTeamMembers(teamMembers?.filter((member: ITeamMember) => member.displayName.toLowerCase().includes(search.toLowerCase())));
    } else {
      setFilteredTeamMembers(teamMembers)
    }
  }, [search]);


  const loadTeamMembers = () => {
    setLoading(true)
    ActivityFeedApi.getTeamMembers("").then((data) => {
      setTeamMembers(data.content)
      setMembersChecked(data.content.map((member) => member.id ? member.id : 0));
      setFilteredTeamMembers(data.content)
      setLoading(false);
    })
  }

  const onChange = (list: CheckboxValueType[]) => {
    setMembersChecked(list);
    setIndeterminate(!!list.length && list.length < (teamMembers?.length ? teamMembers.length : 0));
    setCheckAll(list.length === (teamMembers?.length ? teamMembers.length : 0));
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setMembersChecked(e.target.checked ? teamMembers?.map((member) => member.id ? member.id : 0) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onApplyClick = (e: any) => {
    setDropdownVisible(false)
    if (checkAll) {
      props.setMembersFilter(null);
      return;
    }
    props.setMembersFilter(membersChecked);
  }

  const onClearClick = () => {
    setDropdownVisible(false)
    if (checkAll) {
      return;
    }

    props.setMembersFilter(null)
    setCheckAll(true);
    setMembersChecked(teamMembers?.map((member) => member.id ? member.id : 0))
    setIndeterminate(false)
  }
  

  return (
    <div className='activity-log-filters'>
      <div>
        <Dropdown
          className='dropdown-members-filter'
          trigger={['click']}
          open={dropdownVisible}
          onOpenChange={(value: boolean) => setDropdownVisible(value)}
          overlay={() => {
            return <div className="dropdown-overlay-wrapper">
              <div className='activity-search-members relative'>
                <input className="activity-search-members-input"
                       value={search}
                       onChange={(e) => setSearch(e.target.value)}
                       type='text'
                       placeholder="Search"/>
                <span className="activity-search-logo"><i className="bi bi-search"/></span>
              </div>
              <Checkbox className='dropdown-member-checkbox' indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}>
                All members
              </Checkbox>
              <Divider style={{marginTop: 5, marginBottom: 5}}/>
              {
                loading ? <span></span> :
                  <Checkbox.Group className='dropdown-member-checkbox'
                                  options={filteredTeamMembers?.map((member: ITeamMember) => {
                                    return {label: member.displayName, value: member.id ? member.id : 0}
                                  })}
                                  value={membersChecked}
                                  onChange={onChange}/>
              }
              <Divider style={{marginTop: 5, marginBottom: 5}}/>
              <div className='row members-filter-footer'>
                <Button onClick={onClearClick} type='link'>Clear</Button>
                <Button onClick={(e) => onApplyClick(e)} type='primary'>Apply</Button>
              </div>
            </div>
          }}
        >
          <div className="relative">
            <div className="dropdown-members-placeholder-ellipsis">
              {props.membersFilter ? props.membersFilter.map((memberId: number) => {
                const member = teamMembers?.find((member: any) => member.id == "" + memberId)
                if (member) {
                  return `${member.displayName}, `
                }
              }) : "All members"}
            </div>
            <div className="absolute" style={{right: 10, top: 3}}><SvgArrowDown/></div>
          </div>

        </Dropdown>
      </div>
      <div>
        <Select className='activity-filter'
                value={props.activitiesFilter}
                onChange={(value) => props.setActivitiesFilter(value)}
                suffixIcon={<SvgArrowDown/>}>
          {
            props.activityOptions.map((option) => {
              return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            })
          }
        </Select>
      </div>
    </div>
  );
};

export default ActivityFilters;
