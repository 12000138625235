import { Divider } from 'antd';
import React, { useEffect, useState } from 'react'
import SvgArrowDown from '../../../../../../assets/icons/js/ArrowDown';
import "./floating-menu.scss"
import TextTypesDropDown from './text-types-dropdown/text-types-dropdown';
import { RichUtils } from 'draft-js';
import SvgExternalTool from "../../../../../../assets/icons/js/ExternalTool";
import SvgExternalLink from "../../../../../../assets/icons/js/ExternalLink";
import SvgRobot from "../../../../../../assets/icons/js/robot";

const FloatingMenu = (props: any) => {
  const [visibleDropDown, setVisibleDropDown] = useState<boolean>(false);
  const currentStyle = props.editorState.getCurrentInlineStyle();
  const blockType = props.editorState.getCurrentContent().getBlockForKey(props.editorState.getSelection().getStartKey()).getType();
  const textHeight: number = props.nodeButtonPosition.height;
  const floatingMenuHeight: number = 30;
  const bottomTriangleHeight: number = 10;
  let positionTop: number = textHeight + floatingMenuHeight + bottomTriangleHeight;
  positionTop = blockType === 'header-one' ? (positionTop - 18) : positionTop;


  useEffect(() => {
    return () => {
      setVisibleDropDown(false)
    }
  }, []);

  const menu: { icon: JSX.Element, onClick?: any, type?: string, openDropDown?: boolean }[] = [
    {
      icon: <span className="row align-items-center" style={{gap: 8}}>
        <SvgRobot width={14} color={"#545e6b"}/>
        <span>AskIH</span>
      </span>,
      onClick: () => props.showAskIHInputOnEditor('content')
    },
    {
      icon: <span className="row align-items-center" style={{gap: 8}}>Text <SvgArrowDown
        width={8}/></span>,
      onClick: () => {
        setVisibleDropDown(!visibleDropDown)
      },
      openDropDown: true
    },
    {
      icon: <span className={"row align-items-center"} style={{gap: 8}}>
        <div style={{width: 'max-content'}}>Link Project</div>
       <SvgExternalLink color={"#545e6b"}/>
      </span>,
      onClick: () => props.openLinkProjectModal()
    },
    {
      icon: <i className="bi bi-type-bold" data-testid={"bi-type-bold"}/>,
      type: 'BOLD'
    },
    {
      icon: <i className="bi bi-type-italic" data-testid={"bi-type-italic"}/>,
      type: 'ITALIC'
    },
    {
      icon: <i className="bi bi-type-underline" data-testid={"bi-type-underline"}/>,
      type: 'UNDERLINE'
    },
    {
      icon: <i className="bi bi-type-strikethrough" data-testid={"bi-type-strikethrough"}/>,
      type: 'STRIKETHROUGH'
    },
    {
      icon: <SvgExternalTool/>,
      onClick: () => props.openHyperlinkModal(),
    },
  ];

  const handleInlineStyles = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    const newEditorState = RichUtils.toggleInlineStyle(props.editorState, inlineStyle);
    props.onSetEditorState(newEditorState);
  }

  const isCurrentStyle = (type: string) => {
    return currentStyle.has(type) ? 'active-button-style' : '';
  }

  return (
    <div style={props.nodeButtonPosition}>
      <div className="floating-button"
           style={{top: positionTop ? -positionTop : 0}}
           onMouseDown={(e) => {
             e.stopPropagation()
             e.preventDefault()
           }}>

        {menu.map((item: any, index: number) => {
            return (
              <div key={index} className="relative row align-items-center">
                <button
                  className={isCurrentStyle(item.type) ? 'floating-inside-button active' : 'floating-inside-button'}
                  onClick={(e: any) => item?.onClick ? item.onClick(e) : handleInlineStyles(e, item?.type)}>
                  {item.icon}
                </button>

                {
                  (index === 0 || index === 1 || index === 2 || index === 6 || index === 8) &&
                  <Divider type="vertical" className="floating-menu-divider"/>
                }
                {
                  item.openDropDown && visibleDropDown &&
                  <TextTypesDropDown key={index.toString(35) + index}
                                     onSetEditorState={props.onSetEditorState}
                                     editorState={props.editorState}
                  />
                }
              </div>


            )
          }
        )}
        <div className="bottom-triangle"/>
      </div>
    </div>
  )
}

export default FloatingMenu
