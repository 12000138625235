import React from "react";
import { BackgroundImage } from "../model/background-image";
import { BackgroundImageEnum } from "../model/background-image.enum";
import { LoginBackgroundImageEnum } from "../model/login-background-image-enum";
import beach from "../../../../../../assets/images/bg/beach.jpeg";
import mountains from "../../../../../../assets/images/bg/mountains.jpg";
import city from "../../../../../../assets/images/bg/city.jpg";
import desert from "../../../../../../assets/images/bg/desert.jpg";
import first from "../../../../../../assets/images/bg/login_1.jpg";
import second from "../../../../../../assets/images/bg/login_2.jpg";
import third from "../../../../../../assets/images/bg/login_3.jpg";
import fourth from "../../../../../../assets/images/bg/login_4.jpg";
import five from "../../../../../../assets/images/bg/login_5.jpg";
import DeskSettingsApi from "../../../../api/settings-api";
import { Tabs } from "antd";
import SvgInsightDesk from "../../../../../../assets/icons/js/InsightDesk";
import SvgArrowDown from "../../../../../../assets/icons/js/ArrowDown";
import SvgSearch from "../../../../../../assets/icons/js/Search";
import { queryClient } from "../../../../../../index";
import { IBackgroundSettingsProps } from "./model/background-settings-props.interface";
import SvgTick from "../../../../../../assets/icons/js/Tick";
import SvgGoogleLogo from "../../../../../../assets/icons/js/google-logo";

const TabPane = Tabs.TabPane;
const BackgroundSettings = (props: IBackgroundSettingsProps): JSX.Element => {

  const homeAvailableBackgrounds: BackgroundImage[] = [
    {
      name: "Default White",
      background: BackgroundImageEnum.WHITE,
    },
    {
      name: "Beach",
      background: BackgroundImageEnum.BEACH,
    },
    {
      name: "Desert",
      background: BackgroundImageEnum.DESERT,
    },
    {
      name: "Mountains",
      background: BackgroundImageEnum.MOUNTAINS,
    },
    {
      name: "City",
      background: BackgroundImageEnum.CITY,
    },
  ];

  const loginAvailableBackgrounds: BackgroundImage[] = [
    {
      name: "First",
      background: LoginBackgroundImageEnum.FIRST,
    },
    {
      name: "Second",
      background: LoginBackgroundImageEnum.SECOND,
    },
    {
      name: "Third",
      background: LoginBackgroundImageEnum.THIRD,
    },
    {
      name: "Fourth",
      background: LoginBackgroundImageEnum.FOURTH,
    },
    {
      name: "Five",
      background: LoginBackgroundImageEnum.FIVE,
    },
  ];

  const homeBackgroundImage = (background: BackgroundImageEnum): string => {
    switch (background) {
      case BackgroundImageEnum.BEACH:
        return beach;
      case BackgroundImageEnum.CITY:
        return city;
      case BackgroundImageEnum.DESERT:
        return desert;
      case BackgroundImageEnum.MOUNTAINS:
        return mountains;
      case BackgroundImageEnum.WHITE:
        return "";
    }
  };

  const loginBackgroundImage = (
    background: LoginBackgroundImageEnum
  ): string => {
    switch (background) {
      case LoginBackgroundImageEnum.FIRST:
        return first;
      case LoginBackgroundImageEnum.SECOND:
        return second;
      case LoginBackgroundImageEnum.THIRD:
        return third;
      case LoginBackgroundImageEnum.FOURTH:
        return fourth;
      case LoginBackgroundImageEnum.FIVE:
        return five;
    }
  };

  const homeSelectBackground = (background: BackgroundImage): void => {
    const payload = {
      background_image: background.background,
    };
    DeskSettingsApi.updateOrganisationSettings(payload).then(() => {
      queryClient.invalidateQueries("organisationSettings");
    });
  };

  const loginSelectBackground = (background: BackgroundImage): void => {
    const payload = {
      loginImage: background.background,
    };
    DeskSettingsApi.updateOrganisationSettings(payload).then(() => {
      queryClient.invalidateQueries("organisationSettings");
    });
  };

  return (
    <>
      <Tabs>
        <TabPane tab="Home" key="1">
          <>
            <div className="portal-background">
              <div
                className="selected-background"
                style={{
                  backgroundImage: `url(${homeBackgroundImage(props.backgroundImage)})`,
                  height: "424px",
                  border: '1px solid #D8D8D8',
                  borderTop: 0
                }}>
                <div className="header-wrapper row" style={{height: "20.5px",}}>
                  <div className="header-left row fxFlex" style={{height: "20.5px"}}>
                    <div
                      className="app-placeholder"
                      style={{
                        height: "20.5px",
                        width: "30%",
                        fontSize: "12px",
                      }}>
                      <SvgInsightDesk color="white" width={12}/>
                      <span>InsightsHub</span>
                    </div>
                  </div>
                  <div className="header-right row fxFlex">
                    <div
                      className="ant-dropdown-trigger user-avatar"
                      style={{
                        height: "17px",
                        width: "17px",
                        fontSize: "11px",
                        alignItems: "center",
                      }}
                    >
                      <span>G</span>
                    </div>
                  </div>
                </div>
                {
                  props.logoUrl &&
                    <img className="selected-logo" src={props.logoUrl} alt="logo"/>
                }
                <h1 className="selected-background-welcome-message">
                  {props.welcomeMessage}
                </h1>
                <span className="selected-background-description">
                  {props.description}
                </span>
                <div className="selected-search row">
                  <div className="selected-search-first justify-space-between">
                    <span>All</span>
                    <SvgArrowDown width={5} height={5}/>
                  </div>
                  <div className="selected-search-input justify-space-between fxFlex">
                    <span>Search by projects, insights, tags date...</span>
                    <SvgSearch width={11}/>
                  </div>
                </div>
              </div>
              <h4>Background picture</h4>
              <div className="row available-backgrounds">
                {homeAvailableBackgrounds.map(
                  (background: BackgroundImage, i: number) => {
                    return (
                      <div
                        key={i}
                        className="background-card"
                        onClick={() => homeSelectBackground(background)}
                      >
                        <span className="name">{background.name}</span>
                        {background.background ? (
                          <div
                            className="bg"
                            style={{
                              backgroundImage: `url(${homeBackgroundImage(
                                background.background
                              )})`,
                            }}
                          />
                        ) : (
                          <div
                            className="bg"
                            style={{
                              border: "1px solid #e8e8e8",
                            }}
                          ></div>
                        )}
                        {props.backgroundImage ===
                          background.background && (
                            <div className="bg-tick">
                              <SvgTick color={"#fff"}/>
                            </div>
                          )}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </>
        </TabPane>
        <TabPane tab="Login" key="2">
          <div className="portal-background">
            <div className="row portal-login">
              <div className="login-content column justify-space-around align-items-center  w-50">
                <div className="title">
                  <h1>
                    {props.welcomeMessage
                      ? props.welcomeMessage
                      : "Welcome to InsightsDesk!"}
                  </h1>
                </div>
                <div className="column login-sso">
                  <div className="row logo"><h1>Brandit!</h1></div>
                  <button className="sign-in-button">
                    <SvgGoogleLogo width={12}/>
                    <span>Sign in with Google</span>
                  </button>
                </div>
                <div className="powered-by">
                  <span>Powered by</span>{" "}
                  <a href="https://www.questionpro.com/"> QuestionPro</a>
                </div>
              </div>
              <div className="column w-50 static-image" style={{
                backgroundImage: `url(${loginBackgroundImage(
                  props.loginImage
                )})`,
              }}
              />
            </div>
            <h4>Background picture</h4>
            <div className="row available-backgrounds">
              {loginAvailableBackgrounds.map(
                (background: BackgroundImage, i: number) => {
                  return (
                    <div
                      key={i}
                      className="background-card"
                      onClick={() => loginSelectBackground(background)}
                    >
                      <span className="name">{background.name}</span>
                      {background.background ? (
                        <div
                          className="bg"
                          style={{
                            backgroundImage: `url(${loginBackgroundImage(
                              background.background
                            )})`,
                          }}
                        />
                      ) : (
                        <div
                          className="bg"
                          style={{
                            border: "1px solid #e8e8e8",
                          }}
                        ></div>
                      )}

                      {props.loginImage ===
                        background.background && (
                          <div className="bg-tick">
                            <SvgTick color={"#fff"}/>
                          </div>
                        )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default BackgroundSettings;
