import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { message, notification } from 'antd';
import { environment } from "../environment";
import { useGlobalContext } from "./global-context";
import SvgInfoCircleFilled from "../assets/icons/js/InfoCircleFilled";
import SvgTick from "../assets/icons/js/Tick";
import SvgAlert from "../assets/icons/js/alert";

interface ISyncSurveysContext {
  totalFolders: number;
  totalSurveys: number;
  allFolders: any[];
  progress: number;
  thereIsImport: boolean;
  importDone: boolean;
  folderCountingLoading: boolean;
  socket: any;
}

const SyncSurveysContext = createContext<ISyncSurveysContext>({} as ISyncSurveysContext);

export function SyncSurveysContextProvider({children}: any) {
  const globalContext = useGlobalContext()
  const [socket, setSocket] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [thereIsImport, setThereIsImport] = useState(false);
  const [importDone, setImportDone] = useState(false);

  const [totalFolders, setTotalFolders] = useState(0);
  const [totalSurveys, setTotalSurveys] = useState(0);
  const [allFolders, setAllFolders] = useState<any[]>([])
  const [folderCountingLoading, setFolderCountingLoading] = useState(false);

  const startConnection = () => {
      setSocket(io(`${environment.backendUrl}/sync-projects`, {
        query: {organizationID: String(globalContext.user.organization.id)},
        transports: ['websocket', 'polling']
      }));
    }
  ;

  const updateProgress = (description: string, percent: number) => {
    const hide = message.open({
      content: <div className="custom-loading-message">
        <SvgInfoCircleFilled color={"#1B3380"}/>
        <span style={{color: "#545e6b"}}>
        Your data is being synced ({percent.toFixed(2)}%)
        </span>
      </div>,
      key: 'progress',
      className: "custom-message",
      duration: 0
    });

    setProgress(percent);

    if (percent === 100) {
      setTimeout(hide, 1000);
    }
  };

  useEffect(() => {
    if (globalContext.user) {
      startConnection()
    }
  }, [globalContext?.user]);

  useEffect(() => {
    if (!socket) return; // Early return if socket is not defined

    const handleConnect = () => {
    };

    const handleStart = (progressStarted: boolean) => {
      if (progressStarted) {
        setImportDone(false);
        setThereIsImport(true);
        updateProgress("", 0.01);
      }
    };

    const handleProgress = ({description, progress}: { description: string, progress: number }) => {
      if (progress !== 0) {
        updateProgress(description, progress);
      }
      if (progress > 0 && progress < 100) {
        setThereIsImport(true);
      }
    };

    const handleComplete = (completed: boolean) => {
      if (completed) {
        setThereIsImport(false);
        setImportDone(true);
        message.success({
          content: 'All your projects have been synced successfully',
          className: 'custom-success-message',
          icon: <SvgTick color={"#227700"}/>
        });
      }
    };
  
    const handleInterrupt = (interrupted: boolean) => {
      if (interrupted) {
        setThereIsImport(false);
        setImportDone(true);
        message.error({
          content: 'Your syncing has been interrupted. Please try again later.',
          className: 'custom-error-message',
          icon: <SvgAlert color={"#CC0000"}/>
        });
      }
    };
  
    const handleFolderCountStart = (started: boolean) => {
      if (started) {
        setFolderCountingLoading(true);
      }
    };
  
    interface FolderNumberDetails {
      totalFolders: number;
      totalSurveys: number;
      allFolders: any[]; // Replace 'any' with a more specific type if possible
    }
    

    const handleGetFolderNumber = ({ totalFolders, totalSurveys, allFolders }: FolderNumberDetails) => {
      setTotalFolders(totalFolders);
      setTotalSurveys(totalSurveys);
      setAllFolders(allFolders);
    };
  
    const handleFolderCountComplete = (completed: boolean) => {
      if (completed) {
        setFolderCountingLoading(false);
      }
    };
  
    // Register event listeners
    socket.on('connect', handleConnect);
    socket.on('start', handleStart);
    socket.on('progress', handleProgress);
    socket.on('complete', handleComplete);
    socket.on('interrupt', handleInterrupt);
    socket.on('folder-count-start', handleFolderCountStart);
    socket.on('get-folder-number', handleGetFolderNumber);
    socket.on('folder-count-complete', handleFolderCountComplete);
  
    // Cleanup function to remove event listeners and disconnect socket
    return () => {
      socket.off('connect', handleConnect);
      socket.off('start', handleStart);
      socket.off('progress', handleProgress);
      socket.off('complete', handleComplete);
      socket.off('interrupt', handleInterrupt);
      socket.off('folder-count-start', handleFolderCountStart);
      socket.off('get-folder-number', handleGetFolderNumber);
      socket.off('folder-count-complete', handleFolderCountComplete);
      
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [socket]);


  const contextValue: ISyncSurveysContext = {
    totalFolders,
    totalSurveys,
    allFolders,
    importDone,
    thereIsImport,
    progress,
    folderCountingLoading,
    socket,
  }

  return <SyncSurveysContext.Provider value={contextValue}>
    {children}
  </SyncSurveysContext.Provider>
}

export const useSyncSurveysContext = () => useContext(SyncSurveysContext)
