import React from 'react';

const SvgText = (props: any) => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.21871 0.409999L7.12071 1.152H4.30671V10H3.48071V1.152H0.638711V0.409999H7.21871ZM11.6158 9.692C11.1491 10 10.6358 10.154 10.0758 10.154C9.5158 10.154 9.07714 9.99067 8.7598 9.664C8.44247 9.328 8.2838 8.84733 8.2838 8.222V3.336H7.0518V2.678H8.2838V0.928L9.0958 0.83V2.678H10.9858L10.8878 3.336H9.0958V8.18C9.0958 8.60933 9.18447 8.93133 9.3618 9.146C9.53914 9.35133 9.8098 9.454 10.1738 9.454C10.5471 9.454 10.9205 9.34667 11.2938 9.132L11.6158 9.692Z"
        fill={props?.color ? props.color : '#545e6b'}/>
    </svg>

  );
};

export default SvgText;
