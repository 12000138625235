// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-folder-modal-wrapper .new-folder-name-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.new-folder-modal-wrapper .new-folder-name-wrapper .new-folder-name-label {
  font-size: 12px;
  font-weight: 400;
  color: #545e6b;
}
.new-folder-modal-wrapper .new-folder-name-wrapper .new-folder-name-input {
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/pages/repositories/components/new-repository-modal/new-repository-modal.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAAN;AAGI;EACE,UAAA;AADN","sourcesContent":[".new-folder-modal-wrapper {\n  .new-folder-name-wrapper {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    align-items: center;\n\n    .new-folder-name-label {\n      font-size: 12px;\n      font-weight: 400;\n      color: #545e6b;\n    }\n\n    .new-folder-name-input {\n      width: 80%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
