import React from 'react';
import './normal-loader.scss'

export function NormalLoader({backgroundColor = " #0a86ea", ...props}: any) {
  return <>
    <div data-testid={"normal-loader"} className="normal-loader"  {...props}>
      <span style={{backgroundColor}} {...props}/>
      <span style={{backgroundColor}} {...props}/>
      <span style={{backgroundColor}} {...props}/>
      <span style={{backgroundColor}} {...props}/>
    </div>
  </>

}
