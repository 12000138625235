import { convertToRaw, EditorState } from 'draft-js';

export function RetrieveHighlightsFromDraft(editorState: EditorState) {
  const contentState = convertToRaw(editorState.getCurrentContent());

  const nodes = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'NODE').map((v: any) => v[1].data.nodes)
    .map(obj => ({
      nodes: obj.nodes.map((n: any) => n.id),
      selectedText: obj?.selectedText,
    }));

  const tasks = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'TASK').map((v: any) => v[1].data)
    .map(obj => ({selectedText: obj.answer, taskUUID: obj.task.uuid}));

  const nuggets = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'EVIDENCE').map((v: any) => v[1].data)
    .map(obj => ({selectedText: obj.selectedText, nuggets: obj.nuggets.map((n: any) => n.id)}));

  const flags = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'FLAG').map((v: any) => v[1].data)
    .map((obj: any) => ({selectedText: obj?.selectedText, flags: obj?.flags.map((f: any) => f.id)}));

  const mentions = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'mention').map((v: any) => v[1].data.mention)
    .map(obj => (obj.id));

  return {nodes, tasks, nuggets, flags, mentions};
}
