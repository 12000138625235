import React from 'react';

const SvgBarChart = (props: any) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1 0C1.55228 0 2 0.447715 2 1V12H13C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14H0V1C0 0.447715 0.447715 0 1 0ZM14 6V10H7V6H14ZM6 6V10H4V6H6ZM11 0V4H4V0H11ZM14 0V4H12V0H14Z"
            fill={props?.color ? props.color : '#545e6b'}/>
    </svg>

  );
};

export default SvgBarChart;
