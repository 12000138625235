import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Loader } from "../../../../../../_shared/components/loader/loader";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../../../../store/global-context";
import { Button } from "antd";
import projectApi from "../../../../../projects/api/project-api";
import HomeApi from "../../../../api/home-api";
import SyncingDataIndicator from "../../components/syncing-data-indicator/syncing-data-indicator";
import FolderSection from "./components/folders-section/folder-section";
import ImportSurveyDetails from "./components/import-survey-details/import-survey-details";
import questionProImportsApi
  from "../../../../../settings/settings-tabs/question-pro-imports/api/question-pro-imports.api";
import { useSyncSurveysContext } from "../../../../../../store/sync-surveys-context";
import { WuAppHeader } from "@questionproext/wick-ui-lib";

const SyncWithSurveys = () => {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [autoSync, setAutoSync] = useState<boolean>(false);
  const [showFoldersSelection, setShowFolderSelection] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [partialImport, setPartialImport] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [showSyncingView, setShowSyncingView] = useState(false);

  const {allFolders, totalFolders, totalSurveys, folderCountingLoading} = useSyncSurveysContext();


  useEffect(() => {
    projectApi.startSyncModalInit().then()
  }, []);

  const updateAutoSync = (checked: boolean) => {
    setAutoSync(checked);
  }

  const navigateBack = () => {
    if (showFoldersSelection) {
      setPartialImport(false);
      setSelectedRowKeys([]);
      setShowFolderSelection(false);
      return;
    }
    navigate('/initial-page');
  }

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const onContinue = async () => {
    setApiLoading(true);

    if (autoSync) {
      await HomeApi.autoSyncOrganization();
    }


    const selectedNumberOfSurveys = allFolders.reduce((acc: number, folder: any) => {
      if (selectedRowKeys.includes(folder.folderId)) {
        return acc + folder.surveyCount;
      }
      return acc;
    }, 0);

    await HomeApi.stopFolderCounter()
    await delay(2000);

    questionProImportsApi.startImportJob(partialImport, selectedRowKeys as number[], true, selectedNumberOfSurveys).then(() => {
      setApiLoading(false);
      setShowSyncingView(true);
    })
  }

  const updateShowFolderSelection = (checked: boolean) => {
    setShowFolderSelection(checked);
  }

  const updatePartialImport = (value: boolean) => {
    setPartialImport(value);
  }

  const updateSelectedRowKeys = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  return (
    <div className="main column">
      <WuAppHeader activeProductName={"InsightsHub"}
                   onLogoutClick={() => globalContext.logoutUser()}
                   productCategories={globalContext.user.categories}
                   myAccount={globalContext.user.myAccount}/>
      <div className="container">
        <div className="main-wrapper" style={{backgroundColor: "#F5F5F5"}}>
          <Suspense fallback={<Loader/>}>
            {
              !showSyncingView ?
                <div className="home-welcome-page-wrapper" data-testid={"sync-surveys"}>
                  <div className="home-welcome-page-container">
                    <h1>Sync with Surveys</h1>
                    <p>Synchronize survey folders for powerful insights, ensuring knowledge continuity. You can
                      edit
                      your selection later in Settings.</p>
                    {
                      showFoldersSelection ?
                        <FolderSection totalFolders={totalFolders}
                                       loading={folderCountingLoading}
                                       updatePartialImport={updatePartialImport}
                                       updateSelectedRowKeys={updateSelectedRowKeys}
                                       selectedRowKeys={selectedRowKeys}

                                       allFolders={allFolders}/> :
                        <ImportSurveyDetails totalSurveys={totalSurveys}
                                             totalFolders={totalFolders}
                                             loading={folderCountingLoading}
                                             updateAutoSync={updateAutoSync}
                                             updateShowFolderSelection={updateShowFolderSelection}
                                             autoSync={autoSync}
                        />

                    }
                    <div className="row" style={{gap: 10, marginTop: 30}}>
                      <Button type="link" onClick={navigateBack}>Go back</Button>
                      <Button type="primary" loading={apiLoading}
                              disabled={totalFolders === 0}
                              style={{padding: "5px 60px"}} onClick={onContinue}>Continue</Button>
                    </div>
                  </div>
                </div>
                :
                <SyncingDataIndicator onClick={() => navigate('/home')}/>
            }
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default SyncWithSurveys;
