import React, { useEffect, useState } from 'react';
import { Modal } from "antd";
import "./user-activity-dialog.scss"
import { useQuery } from "react-query";
import ActivityFeedApi from "../api/activity-feed-api";
import { ActivitiesFilterEnum } from "../components/activity-filters/model/activities-filter.enum";
import DaysLeftDatePipe from "../../../../../../_shared/helpers/days-left-date-pipe";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import { getInitials } from "../../../../../../_shared/helpers/get-initials-of-name";
import SvgKnowledgeGraph from "../../../../../../assets/icons/js/knowledge-graph";
import { EventTypesEnum } from "../../../../../../_shared/helpers/general-event-type-pipe/event-types.enum";
import { useNavigateToPageForActivity } from "../../../../../../core/hooks/use-navigate-to-page";
import Pagination from "../../../../../../_shared/components/pagination/pagination";

const UserActivityDialog = (props: any) => {
  const navigateToPage = useNavigateToPageForActivity();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [color, setColor] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [displayName, setDisplayName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [firstLoading, setFirstLoading] = useState(true)

  const activities = useQuery(['activities', pageSize, page, props.data.userId], () => ActivityFeedApi.getAllActivities({
    pageSize,
    page,
    usersId: "" + props.data.userId,
    activityTypes: `${ActivitiesFilterEnum.PROJECTS_ACTIVITY},${ActivitiesFilterEnum.STORIES_ACTIVITY},${ActivitiesFilterEnum.NUGGETS_ACTIVITY},${ActivitiesFilterEnum.KNOWLEDGE_GRAPHS_ACTIVITY}`
  }));

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false)
    }

    if (activities.data) {
      if (activities.data.data[0]) {
        setColor(activities.data.data[0].userColorCode);
        setCreatedAt(activities.data.data[0].userCreatedAt);
        setTotalCount(activities.data.total[0].count);
        setDisplayName(activities.data.data[0].user);
        setProfilePic(activities.data.data[0].profilePicture ? activities.data.data[0].profilePicture : "")
      }
    }
  }, [activities.data]);


  useEffect(() => {
    setPage(1)
  }, [pageSize]);

  return (
    <Modal title="User Activity"
           open={props.data.visible}
           onCancel={props.onCancel}
           width={800}
           footer={[]}
    >
      <div className='user-activity-dialog-wrapper'>
        {
          firstLoading ? <Loader/> :
            <>
              <div className='user-activity-info'>
                <div className='user-avatar'
                     style={{backgroundColor: color}}>
                  {
                    profilePic ? <img src={profilePic}
                                      alt=''/> : getInitials(displayName)
                  }
                </div>
                <span className="user-name">{displayName}</span>
                <span
                  className="member-since">Member since {createdAt && DaysLeftDatePipe(createdAt)}</span>
              </div>
              <div className='row pagination-wrapper' style={{marginBottom: 10}}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  pageSizeOptions={[5, 10, 20, 50]}
                  pageSize={pageSize}
                  showSizeChanger
                  currentPage={page}
                  totalItems={totalCount}/>
              </div>
              <div className='activity-cards-wrapper'>
                {
                  activities.isLoading ? <Loader/> : activities?.data &&
                    activities.data.data.map((activity, i) => {
                      return <div className='activity-card' key={i}>
                        <div className='activity-card-main-info pointer' onClick={() => navigateToPage({
                          eventNameEnum: activity.eventNameEnum as EventTypesEnum,
                          id: activity.id
                        })}>
                          <div className='activity-card-type'>
                            {activity.eventName}
                          </div>
                          <div className='activity-secondary-text'>
                            {activity.description}
                            {activity.nuggetDescription}
                          </div>
                          <div className='activity-primary-text'>
                            {activity.eventName.includes("Knowledge") && <SvgKnowledgeGraph color={"#008EEE"}/>}
                            {activity.name}
                          </div>
                          {
                            activity.nodeName &&
                              <span className="activity-nugget-update">
                        Connected to <span className="activity-nugget-node">{activity.nodeName}</span>
                      </span>
                          }
                        </div>
                        <div className='activity-card-side-info'>
                          <div className='updated-at'>
                            {DaysLeftDatePipe(activity.createdAt)}
                          </div>
                        </div>
                      </div>
                    })
                }
              </div>
            </>
        }
      </div>
    </Modal>
  );
};

export default UserActivityDialog;
