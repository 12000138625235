import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import './all-tasks.scss';
import SvgDocuments from '../../../../../../../../../assets/icons/js/documents';
import { ISelectedTasks, ITopTasksDetails, } from '../../../../model/top-tasks-details.interface';
import { IAllTasks } from '../../../../model/all-assigments.interface';

export default function AllTasks(props: any): JSX.Element {
  const [loader, setLoader] = useState<boolean>(true);

  const [tasks, setTasks] = useState<IAllTasks>();

  const [selectedTask, setSelectedTask] = useState<any>();

  useEffect(() => {
    setTasks(props?.tasks ? props.tasks : []);
    setSelectedTask(props.tasks.tasks[0]);
  }, []);

  useEffect(() => {
    setTasks(props?.tasks);
    setLoader(false);
  }, [props?.tasks]);

  function onSelectTask(task: any) {
    setLoader(true);
    setSelectedTask(task);
    props.loadPage({task})
  }

  return (
    <div className="graph-nodes-wrapper usage-stats-tasks">
      <>
        <div className="body">
          <div className="node-wrapper">
            <div className="node">
              <div className="nodes-wrapper">
                {tasks?.tasks?.length &&
                  tasks?.tasks.map((task: any, i: number) => {
                    return (
                      <div key={i}
                           onClick={() => onSelectTask(task)}
                           className={selectedTask?.id == task.id ? 'task active' : 'task'}>
                        <span>{task.name}</span>
                        <div className="task-total-assigned">
                          <span>{task.totalAssigned}</span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="projects">
            {tasks?.interviews?.length ?
              <div className="top-connections-dialog-tasks">
                <div className="selected-task-header">
                  <span>{selectedTask.name}</span>
                  <span>{selectedTask.totalAssigned}</span>
                </div>
                {tasks?.interviews.map(
                  (taskDetail: ITopTasksDetails, index: number) => {
                    return (
                      <div key={index} className="interview">
                        <div className="title-container">
                          <div className="title-connections">
                            <SvgDocuments
                              color={'#1B87E6'}
                              style={{marginRight: 8}}
                            />
                            <div className="title">{taskDetail.title}</div>
                          </div>
                          <div className="tasks-connections">
                            {taskDetail.totalConnections}
                          </div>
                        </div>
                        <div className="hr"/>
                        {taskDetail?.selected?.map(
                          (selected: ISelectedTasks, index: number) => {
                            return (
                              <div key={index} className="selected-graph-card">
                                <div className="content">
                                  “…{selected.selectedText}…”
                                </div>
                                <div className="footer">
                                  <div className="selected-user">
                                    {selected.user}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              :
              <div className="empty-align">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
              </div>
            }
          </div>
        </div>
      </>
    </div>
  );
}
