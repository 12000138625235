import { ContentState, EditorState, Modifier, SelectionState } from "draft-js";
import { moveSelectionToTheEndOfBlock } from "../../../../../_shared/components/draft-js/components/helpers/entity";

export function insertRecommendationEntity(eS: EditorState) {
  if (!eS) {
    return eS;
  }

  const editorStateMoveSelectionToEnd: EditorState = moveSelectionToTheEndOfBlock(eS);
  const splitBlock: ContentState = Modifier.splitBlock(editorStateMoveSelectionToEnd.getCurrentContent(), editorStateMoveSelectionToEnd.getSelection());
  const editorState: EditorState = EditorState.push(editorStateMoveSelectionToEnd, splitBlock, 'split-block');
  const selectionState: SelectionState = editorState.getSelection();
  const currentContent: ContentState = editorState.getCurrentContent();

  if (!selectionState) {
    return editorState;
  }

  const contentWithEntity = currentContent.createEntity('RECOMMENDATION', 'MUTABLE', {
    entityMapId: Math.floor(100000 + Math.random() * 900000)
  });

  const entityKey: string | null = contentWithEntity.getLastCreatedEntityKey();
  const textWithEntity = Modifier.insertText(currentContent, selectionState, '  ', undefined, entityKey);
  let newEditorState = EditorState.push(editorState, textWithEntity, "insert-characters");

  newEditorState = EditorState.push(editorState, textWithEntity, "insert-characters");
  newEditorState = EditorState.moveFocusToEnd(newEditorState);

  const newSelection = newEditorState.getSelection().merge({
    focusOffset: textWithEntity.getSelectionAfter().getFocusOffset() - 1,
    anchorOffset: textWithEntity.getSelectionAfter().getFocusOffset() - 1
  });

  newEditorState = EditorState.forceSelection(newEditorState, newSelection);

  return newEditorState;
}
