// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sync-type-box-wrapper {
  width: 257px;
  height: 255px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sync-type-box-wrapper .beta-label {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.sync-type-box-wrapper .sync-type-box-icon {
  width: 54px;
  height: 54px;
  margin-bottom: 8px;
}
.sync-type-box-wrapper .sync-type-box-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sync-type-box-wrapper .sync-type-box-title {
  font-size: 16px;
  font-weight: 500;
  color: #545e6b;
  margin-bottom: 8px;
  text-align: center;
}
.sync-type-box-wrapper .sync-type-box-text {
  font-size: 14px;
  font-weight: 400;
  color: #545e6b;
  margin-bottom: 8px;
  text-align: center;
}
.sync-type-box-wrapper .sync-type-box-button {
  margin-top: auto;
}
.sync-type-box-wrapper .sync-type-box-button .ant-btn-ghost {
  border-color: #1b87e6;
  color: #1b87e6;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/home-initial-sync/components/sync-type-box/sync-type-box.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;AAAJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAFN;AAME;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AAJJ;AAOE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AALJ;AAQE;EACE,gBAAA;AANJ;AAQI;EACE,qBAAA;EACA,cAAA;AANN","sourcesContent":[".sync-type-box-wrapper {\n  width: 257px;\n  height: 255px;\n  border: 1px solid #d8d8d8;\n  border-radius: 8px;\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n\n  .beta-label {\n    position: absolute;\n    right: 10px;\n    top: 10px;\n    cursor: pointer;\n  }\n\n\n  .sync-type-box-icon {\n    width: 54px;\n    height: 54px;\n    margin-bottom: 8px;\n\n    img {\n      width: 100%;\n      height: 100%;\n      object-fit: contain;\n    }\n  }\n\n  .sync-type-box-title {\n    font-size: 16px;\n    font-weight: 500;\n    color: #545e6b;\n    margin-bottom: 8px;\n    text-align: center;\n  }\n\n  .sync-type-box-text {\n    font-size: 14px;\n    font-weight: 400;\n    color: #545e6b;\n    margin-bottom: 8px;\n    text-align: center;\n  }\n\n  .sync-type-box-button {\n    margin-top: auto;\n    \n    .ant-btn-ghost {\n      border-color: #1b87e6;\n      color: #1b87e6;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
