import React, { useEffect, useState } from 'react';
import { ITask } from '../../../../../settings/settings-tabs/interview-labs-settings/model/task.interface';
import SvgCheck from '../../../../../../assets/icons/js/check';
import SvgTrash from '../../../../../../assets/icons/js/Trash';
import { DraftJs } from '../../../../../../_shared/components/draft-js/draft';

export function Guide(props: any): JSX.Element {
  const [tasks, setTasks] = useState<ITask[]>(JSON.parse(JSON.stringify(props.tasks)));
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
  const [stopUpdate, setStopUpdate] = useState<boolean>(true);

  useEffect(() => {
    if (props.tasks) {
      setTasks(JSON.parse(JSON.stringify(props.tasks)));
      setStopUpdate(true);
    }
  }, [props.tasks]);

  useEffect(() => {
    const newTasks: ITask[] = JSON.parse(JSON.stringify(tasks));
    newTasks.forEach((t: ITask) => {
      t.checked = false;
    });

    if (props?.activeTasks) {
      if (!props.activeTasks.length) {
        newTasks.forEach((t: ITask) => {
          t.checked = false;
        });
      }

      if (props.activeTasks.length >= 0) {
        props.activeTasks.forEach((uuid: string) => {
          newTasks.forEach((t: ITask) => {
            if (parseInt(String(t.uuid)) === parseInt(uuid)) {
              t.checked = true;
            }
          });
        });
      }

      setTasks(newTasks.slice());
    }
  }, [props?.activeTasks]);

  useEffect(() => {
    setSelectedTasks([]);
    const tempTasks: number[] = [];

    tasks.forEach((t: ITask) => {
      if (t.checked) {
        tempTasks.push(t.uuid);
      }
    });

    setSelectedTasks(tempTasks);
  }, [tasks]);

  function toggleCheckTask(task: ITask): void {
    if (stopUpdate) {
      setStopUpdate(false);
    }

    const newTasks: ITask[] = JSON.parse(JSON.stringify(tasks));

    newTasks.find((t: ITask, i: number) => {
      if (t.uuid === task.uuid) {
        t.checked = !task.checked
        sendTextToEditor({...t, index: i + 1});
        return t;
      }
    });

    setTasks(newTasks.slice());
  }

  function sendTextToEditor(task: ITask): void {
    props?.onSetChangeEditorState(task);
  }

  return (
    <div className="d-flex column">
      <div className="guide-task-wrapper">
        <div className="moderate-guide">
          <div className="guide-task-header">
            Moderator guide:
          </div>
          <DraftJs key="guide"
                   readOnly={true}
                   isGuide={true}
                   content={props.guide}/>
        </div>
        <div className="interview-tasks">
          <div className="guide-task-header justify-space-between">
            <span>Interview tasks:</span>
            <span className="selected-tasks-count">{selectedTasks.length}/{tasks.length}</span>
          </div>

          <div className="task-option-wrapper">
            {tasks?.map((task: ITask) => {
              return (
                <div className={task.checked ? "task-option task-option-checked" : "task-option"} key={task.uuid}
                     onClick={() => !task.checked && toggleCheckTask(task)}>
                  <div className="row">
                    {task.checked ? <div className="checked">
                        <SvgCheck width={20} height={20} color="transparent"/>
                      </div>
                      :
                      <div className="square"/>
                    }
                    <div className="text">{task.text}</div>
                  </div>
                  {task.checked &&
                      <button className="icon-button" onClick={() => toggleCheckTask(task)}>
                          <SvgTrash width={18} height={18}/>
                      </button>
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
