import * as React from "react";

const SvgTick = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g transform="translate(-963 -642) translate(739 574) translate(220 64)">
        <path fill={props.color ? props.color : '#545E6B'}
              d="M1.8 7.2c.497-.497 1.303-.497 1.8 0l1.8 1.796L11.7 2.7c.497-.497 1.303-.497 1.8 0 .497.497.497 1.303 0 1.8l-8.1 8.1L1.8 9c-.497-.497-.497-1.303 0-1.8z"
              transform="translate(4.8 4.8)"/>
      </g>
    </svg>
  );
}

export default SvgTick;
