import React, { useState } from 'react';
import './ask-ai.scss';
import { Table } from "antd";
import { useQuery } from "react-query";
import SettingsApi from "../../api/settings-api";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Pagination from "../../../../_shared/components/pagination/pagination";

export function AskAi(): JSX.Element {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const askAiChats = useQuery(['askAiChats', page, pageSize], () => SettingsApi.askAiAll({
    page, pageSize
  }));

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user'
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: '300px'
    },
    {
      title: 'Question',
      dataIndex: 'responseText',
      key: 'responseText',
      render: (responseText: any) => {
        const responseTextParsed = JSON.parse(responseText);
        return <>
          <span>{responseTextParsed.text}</span>
        </>

      }
    },
    {
      title: 'References',
      dataIndex: 'responseText',
      key: 'responseText',
      width: '300px',
      render: (responseText: any) => {
        const responseTextParsed = JSON.parse(responseText);
        return <ul>
          {responseTextParsed.references.map((reference: any, index: number) => {
            switch (reference.type) {
              case 'PROJECT':
                return (
                  <li key={index}>
                    <Link to={`/projects/edit?id=${reference.id}`}>{reference.name}</Link>
                  </li>
                );
              case 'STORY':
                return (
                  <li key={index}>
                    <Link to={`/stories/edit?id=${reference.id}`}>{reference.name}</Link>
                  </li>
                );
              case 'FILE':
                return (
                  <li key={index} style={{cursor: 'pointer'}}>
                    {reference.name}
                  </li>
                );
              default:
                return <li key={index}>{reference.name}</li>;
            }
          })}
        </ul>
      },
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: '200px',
      render: (timestamp: string) => (
        <span>{format(new Date(timestamp), 'MMM dd, yyyy, HH:mm:ss')}</span>
      ),
    },
  ];

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }

  return (
    <div className="repository-indexes">
      <div className="header"/>
      <div className="body">
        <div className="w-100 justify-space-end row" style={{marginBottom: 10}}>
          {
            askAiChats.data?.pagination?.totalElements &&
            <Pagination totalItems={askAiChats.data?.pagination?.totalElements}
                        currentPage={page}
                        pageSize={pageSize}
                        showSizeChanger={true}
                        onChange={handlePaginationChange}/>
          }

        </div>
        <Table columns={columns}
               pagination={false}
               loading={askAiChats.isLoading}
               dataSource={askAiChats.data?.content ?? []}>
        </Table>
      </div>
    </div>
  );
}
