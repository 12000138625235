import React from 'react';
import SvgServerError from '../../../assets/icons/js/server-error';


const LicenseError = () => {
  return (
    <div className="error-page row align-items-center justify-space-center">
      <div className="error-message column">
        <h1 className="error-page-title">InsightsHub</h1>
        <h2 className="error-page-reason">License has expired!</h2>

        <div className="column">
          <span>Feel free to contact us to renew license!</span>
        </div>
      </div>
      <div className="column logo">
        <SvgServerError/>
      </div>
    </div>
  )
}

export default LicenseError;
