import { DatePicker, Dropdown, Select } from 'antd';
import SvgArrowBack from '../../../assets/icons/js/ArrowBack';
import SvgArrowUp from '../../../assets/icons/js/ArrowUp';
import SvgArrowDown from '../../../assets/icons/js/ArrowDown';
import React, { useEffect, useState } from 'react';
import AnalyticsApi from '../../../pages/analytics/api/analytics-api';
import { useQuery } from 'react-query';
import ProjectApi from '../../../pages/projects/api/project-api';
import { format } from 'date-fns';

const {RangePicker} = DatePicker;

export function FilterForm(props: {
  filterFormChanged: any,
  disableOwners?: boolean,
  disablePrograms?: boolean,
  disableProjectTypes?: boolean
}): JSX.Element {
  const periodSearch: { name: string, value: string }[] = [
    {
      name: 'All time',
      value: 'alltime',
    },
    {
      name: 'Year',
      value: 'year'
    },
    {
      name: 'Month',
      value: 'month'
    },
    {
      name: 'Quarter',
      value: 'quarter'
    },
    {
      name: 'Date range',
      value: 'range'
    }
  ]


  const filters = useQuery('filters', () => AnalyticsApi.getFilters(), {
    select: response => {
      const prg: any[] = response.programs;
      const pT: any[] = response.projectTypes;

      const programs = prg.map(({id: value, name: label}) => ({
        value,
        label
      }));

      const projectTypes = pT.map(({id: value, name: label}) => ({
        value,
        label
      }));

      return {programs, projectTypes}
    }
  });

  const owners: any = useQuery(['teammembers', !props.disableOwners], () => ProjectApi.getOwners(), {
    refetchOnWindowFocus: false,
    select: response => {
      return response.content.map(({id: value, displayName: label}) => ({
        value,
        label
      }));
    }
  });

  const programSelectProps = {
    mode: 'multiple' as const,
    style: {width: '300px'},
    filterOption: (input: any, option: any) => option.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0,
    onChange: (newValue: string[]) => {
      setProgramSelectedValue(newValue);
    },
    placeholder: 'All programs',
    maxTagCount: 3,
  };

  const ownersSelectProps = {
    style: {width: '300px'},
    onChange: (newValue: string[]) => {
      setOwnerSelectedValue(newValue);
    },
    placeholder: 'All owners',
    maxTagCount: 3,
  };

  const projectTypeSelectProps = {
    value: 'id',
    label: 'name',
    mode: 'multiple' as const,
    style: {width: '300px'},
    filterOption: (input: any, option: any) => option.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0,
    onChange: (newValue: string[]) => {
      setProjectTypesSelectedValue(newValue);
    },
    placeholder: 'All project types',
    maxTagCount: 2,
  };

  const [dropDownVisible, setDropDownVisible] = useState<boolean>();
  const [periodSearchSelected, setPeriodSearchSelected] = useState<any>({name: 'All time', value: 'alltime'});

  const [programSelectedValue, setProgramSelectedValue] = useState<string[]>([]);
  const [ownerSelectedValue, setOwnerSelectedValue] = useState<string[]>([]);
  const [projectTypesSelectedValue, setProjectTypesSelectedValue] = useState<string[]>([]);
  const [displayDate, setDisplayDate] = useState<any>();
  const [datePickerValue, setDatePickerValue] = useState<any>();
  const [params, setParams] = useState<any>();

  function setPeriodSearch(period: { name: string, value: string }): void {
    if (period.value === 'alltime') {
      setPeriodSearchSelected(period);
      setDisplayDate('');
      setDatePickerValue(null);
      setParams({});
      return;
    }

    setPeriodSearchSelected(period);
    setDisplayDate('');
    setDatePickerValue(null);
  }

  function onDatePickerChanged(value: any, dateString?: any): void {
    let displayDate: string = '';

    if (periodSearchSelected.value === 'year') {
      const year = value.toDate().getFullYear();
      setParams({
        filterType: 'year',
        year: year,
      });

      displayDate = year;
    }

    if (periodSearchSelected.value === 'month') {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      const monthDate = value.toDate().getMonth();
      const month = monthNames[monthDate].toUpperCase();
      const year = value.toDate().getFullYear();
      setParams({
        filterType: 'monthly',
        month: month,
        year: year,
      });

      displayDate = `${month.charAt(0)}${month.substring(1).toLowerCase()}, ${value.toDate().getFullYear()}`;
    }

    if (periodSearchSelected.value === 'quarter') {
      const quarter = [...dateString].pop();
      const year = value.toDate().getFullYear();
      setParams({
        filterType: 'quarter',
        quarter: quarter,
        year: year,
      });

      displayDate = `${quarter}, ${value.toDate().getFullYear()}`;
    }

    if (periodSearchSelected.value === 'range') {
      const startDate = value[0].toISOString();
      const endDate = value[1].toISOString();
      setParams({
        filterType: 'range',
        startDate: startDate,
        endDate: endDate,
      });

      displayDate = `${format(new Date(startDate), 'MMM d, yyyy')} to ${format(new Date(endDate), 'MMM d, yyyy')}`;
    }

    setDropDownVisible(false);
    setDisplayDate(displayDate);
    setDatePickerValue(value);
  }

  useEffect(() => {
    const finalParams = {
      ...params
    }

    if (projectTypesSelectedValue) {
      if (!projectTypesSelectedValue.length) {
        delete finalParams.formId;
      } else {
        finalParams.formId = projectTypesSelectedValue.toString();
      }
    }

    if (programSelectedValue) {
      if (!programSelectedValue.length) {
        delete finalParams.programId;
      } else {
        finalParams.programId = programSelectedValue.toString();
      }
    }

    if (ownerSelectedValue) {
      if (!ownerSelectedValue.length && typeof ownerSelectedValue !== 'number') {
        delete finalParams.ownerId;
      } else {
        finalParams.ownerId = ownerSelectedValue.toString();
      }
    }

    props.filterFormChanged(finalParams);
  }, [params, projectTypesSelectedValue, programSelectedValue, ownerSelectedValue]);

  return (
    <>
      <div className="row justify-space-between">
        {props.disablePrograms ? <></>
          : <div className="period-search">
            <Select {...programSelectProps}
                    value={programSelectedValue}
                    suffixIcon={<SvgArrowDown/>}
                    placeholder={'All programs'}
                    options={filters.data?.programs}/>
          </div>
        }
        {props.disableOwners ? <></>
          : <div className="period-search">
            <Select {...ownersSelectProps}
                    showSearch
                    filterOption={(input: string, option: any) => (option?.label ?? '')?.includes(input)}
                    filterSort={(optionA, optionB) => (optionA?.label ?? '')?.toLowerCase().localeCompare((optionB?.label ?? '')?.toLowerCase())}
                    value={ownerSelectedValue}
                    options={owners?.data}
                    placeholder={'All owners'}
                    suffixIcon={<SvgArrowDown/>}/>
          </div>
        }
        {
          props.disableProjectTypes ? <></>
            :
            <div className="period-search">
              <Select {...projectTypeSelectProps}
                      value={projectTypesSelectedValue}
                      options={filters.data?.projectTypes}
                      showArrow
                      placeholder={'All project types'}
                      suffixIcon={<SvgArrowDown/>}/>
            </div>
        }
        <div className="period-search">
          <Dropdown className="period-search-dropdown" overlayClassName="period-search-container"
                    trigger={['click']}
                    open={dropDownVisible}
                    onOpenChange={(value: boolean) => setDropDownVisible(value)}
                    overlay={
                      <div className="dropdown" style={periodSearchSelected.value === 'range' ?
                        {width: 724, minHeight: 310}
                        :
                        {width: 445, minHeight: 310}}>
                        <div className="dropdown-navigation">
                          {periodSearch.map((period: { name: string, value: string }, i: number) => {
                            return (
                              <span key={i} className={periodSearchSelected.value === period.value ?
                                'dropdown-navigation-item-active dropdown-navigation-item' :
                                'dropdown-navigation-item'}
                                    onClick={() => setPeriodSearch(period)}>
                                  {period.name}
                                </span>
                            )
                          })}
                        </div>
                        <div className="content">
                          {dropDownVisible &&
                              <>
                                {periodSearchSelected.value !== 'range' && periodSearchSelected.value !== 'alltime' ?
                                  <DatePicker suffixIcon={<SvgArrowBack/>}
                                              dropdownClassName="period-search-date"
                                              value={datePickerValue}
                                              onChange={(value: any, dateString: any) => onDatePickerChanged(value, dateString)}
                                              picker={periodSearchSelected.value}
                                              open
                                              monthCellRender={(date: any) => {
                                                const month = format(new Date(date), 'MMM');
                                                return (
                                                  <div className="ant-picker-cell-inner">{month}</div>
                                                )
                                              }}
                                  />
                                  :
                                  <></>
                                }
                                {periodSearchSelected.value === 'range' &&
                                    <RangePicker dropdownClassName="range-search-date"
                                                 value={datePickerValue}
                                                 onChange={(value: any, dateString: any) => onDatePickerChanged(value, dateString)}
                                                 open/>}
                              </>
                          }
                        </div>
                      </div>
                    }>
            <div style={{borderBottom: "1px solid #d8d8d8", padding: "1px 4px"}}>
                <span style={{marginRight: 10, color: '#545e6b', fontWeight: 500}}>
                  {periodSearchSelected.name === 'All time'
                    ? periodSearchSelected.name
                    : periodSearchSelected.name}{displayDate ? ': ' + displayDate : null}
                </span>
              {
                dropDownVisible ?
                  <SvgArrowUp/>
                  : <SvgArrowDown/>
              }
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  )
}
