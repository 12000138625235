import React from 'react';
import { Checkbox, Radio } from 'antd';
import { MainFormTypeEnum } from '../../../../settings/settings-tabs/taxonomy/project-type/model/main-form-type.enum';

export class WidgetChoice extends React.Component<any, any> {

  onChange = (e: any) => {
    let value;
    if (this.props.choiceType) {
      value = e;
    } else {
      value = [e.target.value];
    }

    this.props.getSelected({
      index: this.props.index,
      type: MainFormTypeEnum.MULTI_CHOICE,
      value: value
    });
  };

  render() {
    const radioValue = this.props.value;

    return (
      <>
        {this.props.choiceType ?
          <Checkbox.Group className="column"
                          key={this.props.options}
                          defaultValue={this.props?.value}
                          options={this.props.options}
                          onChange={this.onChange}/>
          :
          <Radio.Group className="column"
                       key={this.props.options}
                       options={this.props?.options}
                       defaultValue={radioValue && radioValue[0]}
                       onChange={this.onChange}/>
        }
      </>
    );
  }
}
