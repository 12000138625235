import React, { useContext, useEffect, useRef, useState } from 'react';
import './analyze-chat.scss'
import { format, isThisWeek, isThisYear, isToday } from "date-fns";
import { useQuery } from "react-query";
import { message, Tooltip } from "antd";
import { ISurveyDetails } from "../../../model/survey.interface";
import HomeApi from "../../../../home/api/home-api";
import AiUserAvatar from "../../../../../_shared/components/ai-user-avatar/ai-user-avatar";
import SvgCopy from "../../../../../assets/icons/js/copy";
import SvgSend from "../../../../../assets/icons/js/Send";
import SaveKeyFindingDialog
  from "../../../../home/components/ai-chat/components/save-key-finding-dialog/save-key-finding-dialog";
import SvgTick from "../../../../../assets/icons/js/Tick";
import SvgNugget2 from "../../../../../assets/icons/js/Nugget2";
import SvgBetaLabel from "../../../../../assets/icons/js/BetaLabel";
import { NormalLoader } from "../../../../../_shared/components/normal-loader/normal-loader";
import SvgLike from "../../../../../assets/icons/js/Like";
import SvgDislike from "../../../../../assets/icons/js/Dislike";

type AnalyzeChatProps = {
  survey?: ISurveyDetails,
  projectId?: number;
  fileIDs?: number[];
}
const AnalyzeChat = (props: AnalyzeChatProps) => {
  const [chatHistory, setChatHistory]: any[] = useState([
    {id: 0, timestamp: new Date().toISOString(), responseText: {text: "Hi, how can I help you?", references: []}}
  ])

  const surveyAiChatHistory = useQuery(['surveyAiChatHistory', props?.survey?.surveyId], () => HomeApi.langChainHistory({
    surveyID: props?.survey?.surveyId
  }), {
    refetchOnWindowFocus: false,
    enabled: !!props?.survey
  });

  const projectAiChatHistory = useQuery(['projectAiChatHistory', props?.projectId], () => HomeApi.langChainHistory({
    projectID: props?.projectId,
  }), {
    refetchOnWindowFocus: false,
    enabled: !!props?.projectId
  });


  const textareaRef: any = useRef(null);
  const textareaContainerRef: any = useRef(null);
  const chatContainerRef: any = useRef(null);

  const [inputText, setInputText] = useState<string>('');
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [chatsIdSaved, setChatsIdSaved] = useState<number[]>([]);
  const [saveKeyFindingDialog, setKeyFindingDialog] = useState<any>({open: false});
  const [isHovered, setIsHovered] = useState(false);

  function formatDateTime(dateTime: string): string {
    const date = new Date(dateTime);

    const formats = [
      {condition: isToday, format: "h:mm aa"},
      {condition: isThisWeek, format: "EEE h:mm aa"},
      {condition: isThisYear, format: "MMM d h:mm aa"},
      {condition: () => true, format: "MMM d, yyyy h:mm aa"}, // Default format
    ];

    return format(date, formats.find(({condition}) => condition(date))!.format);
  }

  useEffect(() => {
    if (surveyAiChatHistory.data?.length) {
      setChatHistory((prev: any) => [...prev, ...surveyAiChatHistory.data]);
    }

    if (projectAiChatHistory.data?.length) {
      setChatHistory((prev: any) => [...prev, ...projectAiChatHistory.data]);
    }
  }, [surveyAiChatHistory.data, projectAiChatHistory.data]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTop = scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    if (!isHovered) {
      return;
    }

    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTop = scrollHeight;
    }
  }, [isHovered]);


  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (inputText.length <= 1 || false) {
        return;
      }

      send();
    }
  };

  function send() {
    setApiLoading(true);

    const userQuestion = {id: chatHistory.length + 1, timestamp: new Date().toISOString(), question: inputText};

    setChatHistory((prevHistory: any) => [...prevHistory, userQuestion]);

    if (props.survey) {
      HomeApi.askAI({
        question: inputText,
        specificSurvey: true,
        surveyName: props.survey.name,
        surveyID: props.survey.surveyId
      })
        .then((response: {
          id: number,
          timestamp: string,
          question: string,
          responseText: { text: string, references: Array<{ fileId: number, fileName: string }> }
        }) => {
          const aiResponse = {id: response.id, timestamp: response.timestamp, responseText: response.responseText};
          setChatHistory((prevHistory: any) => [...prevHistory, aiResponse]);
          setApiLoading(false);
        });
    } else if (props.projectId) {
      HomeApi.askAI({question: inputText, projectID: props.projectId, fileIDs: props.fileIDs})
        .then((response: {
          id: number,
          timestamp: string,
          question: string,
          responseText: { text: string, references: Array<{ fileId: number, fileName: string }> }
        }) => {
          const aiResponse = {id: response.id, timestamp: response.timestamp, responseText: response.responseText};
          setChatHistory((prevHistory: any) => [...prevHistory, aiResponse]);
          setApiLoading(false);
        });
    }
    setInputText('');
  }


  function copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(r => message.success({
      content: 'Response copied to clipboard',
      className: 'custom-success-message',
      icon: <SvgTick color={"#227700"}/>
    }));
  }

  function openSaveKeyFindingDialog(chatId: number, description: string) {
    if (props?.survey?.linkedProject) {
      setKeyFindingDialog((prev: any) => ({
        ...prev,
        open: true,
        description,
        chatId,
        projectReference: {projectId: props.survey?.linkedProject.id, projectName: props.survey?.linkedProject.name},
        surveyReference: {surveyID: props.survey?.surveyId, surveyName: props.survey?.name}
      }));
      return;
    }
    setKeyFindingDialog((prev: any) => ({...prev, open: true, chatId, description}));
  }

  function closeSaveKeyFindingDialog(data?: { chatIdSaved: number }) {
    if (data?.chatIdSaved) {
      message.success({
        content: 'Key finding saved',
        className: 'custom-success-message',
        icon: <SvgTick color={"#227700"}/>
      })
      setChatsIdSaved(prev => ([...prev, data.chatIdSaved]));
    }

    setKeyFindingDialog({open: false})
  }


  const isLastMessage = (index: number) => index === chatHistory.length - 1;
  const saveInsightIsDisabled = (chatId: number) => chatsIdSaved.includes(chatId);

  const likeResponse = (message: any) => {
    const reaction = message.liked === true ? null : "LIKED";
    HomeApi.likeDislikeResponse(message.id, reaction).then(() => {
      if (props.projectId) {
        projectAiChatHistory.refetch()
      } else {
        surveyAiChatHistory.refetch()
      }

    });
  }

  const dislikeResponse = (message: any) => {
    const reaction = message.liked === false ? null : "DISLIKED";
    HomeApi.likeDislikeResponse(message.id, reaction).then(() => {
      if (props.projectId) {
        projectAiChatHistory.refetch()
      } else {
        surveyAiChatHistory.refetch()
      }
    });
  }

  return (
    <div className='analyze-chat-wrapper column' style={{height: props.survey ? "calc(100vh - 280px)" : "100%"}}>
      <div className="analyze-chat-header">
        <div>
          <h3>Ask IH</h3>
        </div>
        {
          props.projectId && <span style={{color: "#545e6b"}}>
          {props.fileIDs?.length ? `Selected files: ${props.fileIDs.length}` : 'No files selected'}
        </span>
        }


      </div>
      <div className="analyze-ai-chats-wrapper relative" ref={chatContainerRef}>
        {chatHistory.map((message: any, index: number) => (
          <div key={index}>
            {message.question && <div key={index + 'question'} className="chat-text user-chat-text">
              <div className="user-info">
                <div className="user-display-name">
                              <span
                                className="timestamp">{formatDateTime(message.timestamp ?? new Date().toISOString())}</span>
                  <span>You</span>
                </div>
                <div style={{height: 30}}></div>
                {/*<UserAvatar user={globalContext.user}/>*/}
              </div>
              <div className="chat-bubble">
                <span style={{padding: 8}}>{message.question}</span>
              </div>
            </div>}
            {message.responseText && <div key={index + 'airesponse'} className="chat-text ai-chat-text">
              <div className="user-info">
                <div style={{width: 30, marginRight: 10}}>
                  <AiUserAvatar color={'#1B3380'}/>
                </div>
                <div className="user-display-name">
                  <span>AskIH</span>
                  <span className="timestamp">{formatDateTime(message.timestamp)}</span>
                </div>
              </div>
              <div className="chat-bubble-wrapper column pointer"
                   onMouseEnter={() => isLastMessage(index) && setIsHovered(true)}
                   onMouseLeave={() => isLastMessage(index) && setIsHovered(false)}>
                <div className="chat-bubble">
                  <span>{message?.responseText?.text}</span>
                </div>
                {
                  message.id !== 0 &&
                  <div className="chat-bubble-actions">
                    <Tooltip title={"Save as a key finding"}>
                      <div
                        className={saveInsightIsDisabled(message.id) ? "chat-bubble-action disabled-chat-bubble-action" : "chat-bubble-action"}
                        onClick={() => openSaveKeyFindingDialog(message.id, message.responseText.text)}>
                        <SvgNugget2/>
                      </div>
                    </Tooltip>
                    <Tooltip title={"Copy to clipboard"}>
                      <div className="chat-bubble-action"
                           onClick={() => copyToClipboard(message.responseText?.text)}>
                        <SvgCopy width={16} height={16}/>
                      </div>
                    </Tooltip>
                    <Tooltip title={"Good response"}>
                      <div
                        className={`chat-bubble-action ${message?.liked === true ? 'chat-bubble-action-active' : ''}`}
                        onClick={() => likeResponse(message)}>
                        <SvgLike/>
                      </div>
                    </Tooltip>
                    <Tooltip title={"Bad response"}>
                      <div
                        className={`chat-bubble-action ${message?.liked === false ? 'chat-bubble-action-active' : ''}`}
                        onClick={() => dislikeResponse(message)}>
                        <SvgDislike/>
                      </div>
                    </Tooltip>
                  </div>
                }
              </div>
            </div>}
          </div>
        ))
        }
        {
          apiLoading &&
          <div key={'loader' + 'airesponse'} className="chat-text ai-chat-text">
            <div className="user-info">
              <div style={{width: 30, marginRight: 10}}>
                <AiUserAvatar color={'#1B3380'}/>
              </div>
              <div className="user-display-name">
                <span>AskIH</span>
                {/*<span className="timestamp">{formatDateTime(message.timestamp)}</span>*/}
              </div>
            </div>
            <div className="chat-bubble-wrapper column pointer">
              <div className="chat-bubble">
                          <span className="">
                            <NormalLoader/>
                          </span>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="input-box w-100 relative"
           ref={textareaContainerRef}>
        <input className="input-text"
               ref={textareaRef}
               placeholder="Type here your question about this survey..."
               onKeyDown={onKeyDown}
               value={inputText} onChange={(e) => setInputText(e.target.value)}/>
        <button className="send-button" onClick={send} disabled={inputText.length <= 1 || false}>
          <SvgSend width={12} height={12}/>
        </button>
      </div>
      {
        saveKeyFindingDialog.open &&
        <SaveKeyFindingDialog fromSurveyDetails open={saveKeyFindingDialog.open} data={saveKeyFindingDialog}
                              onCancel={closeSaveKeyFindingDialog}/>
      }
    </div>
  );
};

export default AnalyzeChat;