import React from 'react';

const SvgFolder = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.999992 2C0.447712 2 0 2.44772 0 3L0.000122069 13C0.000122069 13.5523 0.447834 14 1.00011 14H15C15.5523 14 16 13.5523 16 13V5C16 4.44772 15.5523 4 15 4H7.99994V3C7.99994 2.44772 7.55223 2 6.99995 2H0.999992Z"
        fill={props?.color ? props.color : "#545e6b"}/>
    </svg>
  );
};

export default SvgFolder;
