import React from 'react';

const SvgImproveWriting = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.3077 6.94021C7.5631 8.12023 6.52023 9.1631 5.34021 11.9077C5.28737 12.0308 5.11263 12.0308 5.05979 11.9077C3.87977 9.1631 2.8369 8.12004 0.0922789 6.94021C-0.0307596 6.88737 -0.0307596 6.71263 0.0922789 6.65979C2.8369 5.47977 3.87977 4.4369 5.05979 1.69228C5.11263 1.56924 5.28737 1.56924 5.34021 1.69228C6.52023 4.4369 7.5631 5.47977 10.3077 6.65979C10.4308 6.71263 10.4308 6.88737 10.3077 6.94021Z"
        fill="#545E6B"/>
      <path
        d="M15.1361 12.4971C13.236 13.314 12.514 14.036 11.6971 15.9361C11.6605 16.0213 11.5395 16.0213 11.5029 15.9361C10.686 14.036 9.96401 13.3139 8.06389 12.4971C7.9787 12.4605 7.9787 12.3395 8.06389 12.3029C9.96401 11.486 10.686 10.764 11.5029 8.86389C11.5395 8.7787 11.6605 8.7787 11.6971 8.86389C12.514 10.764 13.236 11.486 15.1361 12.3029C15.2213 12.3395 15.2213 12.4605 15.1361 12.4971Z"
        fill="#545E6B"/>
      <path
        d="M15.9361 3.69707C14.036 4.51401 13.314 5.23599 12.4971 7.13611C12.4605 7.2213 12.3395 7.2213 12.3029 7.13611C11.486 5.23599 10.764 4.51388 8.86389 3.69707C8.7787 3.66048 8.7787 3.53952 8.86389 3.50293C10.764 2.68599 11.486 1.96401 12.3029 0.0638854C12.3395 -0.0212951 12.4605 -0.0212951 12.4971 0.0638854C13.314 1.96401 14.036 2.68599 15.9361 3.50293C16.0213 3.53952 16.0213 3.66048 15.9361 3.69707Z"
        fill="#545E6B"/>
    </svg>
  );
};

export default SvgImproveWriting;

