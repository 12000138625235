import { ContentBlock, ContentState, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';

let selectedNodes: any[] = [];

export function assignNodeEntity(editorState: EditorState, data: {
  selectedNodes: any[],
  entityKey?: string
}): EditorState {
  selectedNodes = data?.selectedNodes;
  const entityKey = data?.entityKey;

  if (entityKey) {
    return manageNodeWithEntityKey(editorState, entityKey);
  } else {
    return manageNodeWithSelection(editorState);
  }
}

function manageNodeWithEntityKey(editorState: EditorState, entityKey: string): EditorState {
  const selectionState: SelectionState = editorState.getSelection();
  const contentState: ContentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const contentBlock = contentState.getBlockForKey(startKey);
  const startOffset = selectionState.getStartOffset();
  const entity = contentBlock.getEntityAt(startOffset);

  // if selectedNodes is empty array remove the entity from selectedText in editor
  if (!selectedNodes?.length) {
    let entitySelection: SelectionState = selectionState;

    contentBlock.findEntityRanges(
      (character) => character.getEntity() === entity,
      (start, end) => {
        entitySelection = selectionState.merge({
          anchorOffset: start,
          focusOffset: end
        });
      }
    );

    const newContentState = Modifier.applyEntity(contentState, entitySelection, null);
    return EditorState.push(editorState, newContentState, 'apply-entity');
  } else {
    contentState.mergeEntityData(entityKey, {
      nodes: {
        nodes: selectedNodes
      }
    });

    return RichUtils.toggleLink(editorState, selectionState, entityKey);
  }
}

function manageNodeWithSelection(editorState: EditorState): EditorState {
  const selectionState: SelectionState = editorState.getSelection();
  const anchorKey: string = selectionState.getAnchorKey();
  const contentState: ContentState = editorState.getCurrentContent();
  const currentBlock: ContentBlock = contentState.getBlockForKey(anchorKey);

  const start: number = selectionState.getStartOffset();
  const end: number = selectionState.getEndOffset();
  const selectedText: string = currentBlock.getText().slice(start, end);

  // if selectedNodes is empty array remove the entity from selectedText in editor
  if (!selectedNodes?.length) {
    return RichUtils.toggleLink(editorState, selectionState, null);
  } else {
    const contentWithEntity: ContentState = contentState.createEntity("NODE", "MUTABLE", {
      nodes: {
        selectedText,
        nodes: selectedNodes,
      },
      entityMapId: Math.floor(100000 + Math.random() * 900000)
    });

    const newEditorState: EditorState = EditorState.push(editorState, contentWithEntity, "apply-entity");
    const entityKey: string | null = contentWithEntity.getLastCreatedEntityKey();
    return RichUtils.toggleLink(newEditorState, selectionState, entityKey);
  }
}
