import React from 'react';

const SvgListView = (props: any) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0 2H20V18H0V2ZM2 4H5V7H2V4ZM5 8.5H2V11.5H5V8.5ZM2 13H5V16H2V13ZM18 4H7V7H18V4ZM7 8.5H18V11.5H7V8.5ZM18 13H7V16H18V13Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>

  );
};

export default SvgListView;
