import React, { useState } from 'react';
import '../../team.scss';
import { Button, Modal } from 'antd';
import classes from './create-new-user.module.scss';
import DeskSettingsApi from '../../../../api/settings-api';
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

type Inputs = {
  firstName?: string,
  lastName?: string,
  emailAddress: string,
  password: string
}

export default function CreateNewUserDialog(props: any) {
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const schema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    emailAddress: yup.string().required("Email is required!").email("Email is not valid!"),
    password: yup.string().required("Password is required!").min(8, "Password should be at least 8 characters!")
  })

  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema)
  });

  async function onFormSubmit(data: Inputs | Record<string, any>) {
    setApiLoading(true);

    DeskSettingsApi.createQpUser(data).then(
      () => {
        setApiLoading(false);
        props.onOk();
      }
    ).catch((error) => {
      setApiLoading(false);
      if (error.response.data.message === "User already exists. Try another?") {
        //@ts-ignore
        setError('emailAddress', {type: "User Found", message: "User already exists. Try another?"})
      }
    })
  }


  return (
    <Modal
      title={props.data.title ? props.data.title : 'Create New User'}
      open={props.data.visible}
      onCancel={() => props.onCancel()}
      centered
      width={400}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={apiLoading}
          onClick={handleSubmit(onFormSubmit)}>
          {props.data.okText ? props.data.okText : 'Create User'}
        </Button>,
      ]}
    >
      <div className="modal-body column add-edit-team-member">
        <div className="row w-100">
          <div className={`outline-input-wrapper`} style={{width: '100%'}}>
						<span className="outline-input-label">
							<sup>*</sup>Email
						</span>
            <input
              className="outline-input"
              autoComplete="off"
              placeholder="Type here"
              {...register('emailAddress')}
            />
            <ErrorMessage errors={errors} name={'emailAddress'} render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
        </div>

        <div className="row justify-space-around w-100">
          <div
            className={`outline-input-wrapper ${classes.outlineInputWrapper}`}
            style={{width: '49%'}}
          >
            <span className="outline-input-label">First Name</span>
            <input
              className="outline-input"
              autoComplete="off"
              placeholder="Type here"
              {...register('firstName')}
            />
          </div>

          <div
            className={`outline-input-wrapper ${classes.outlineInputWrapper}`}
            style={{width: '49%'}}
          >
            <span className="outline-input-label">Last Name</span>
            <input
              className="outline-input"
              autoComplete="off"
              placeholder="Type here"
              {...register('lastName')}
            />
          </div>
        </div>

        <div className="row w-100">
          <div
            className={`outline-input-wrapper ${classes.outlineInputWrapper}`}
            style={{width: '100%'}}
          >
						<span className="outline-input-label">
							<sup>*</sup>Password
						</span>
            <input
              type="password"
              className="outline-input"
              placeholder="Type here"
              {...register('password', {pattern: /^.{8,}$/})}
            />
            <ErrorMessage errors={errors} name={'password'} render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
        </div>
      </div>
    </Modal>
  );
}
