import { Modal, Table } from 'antd'
import React, { useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useQuery } from 'react-query'
import "./link-project-modal.scss"
import DaysLeftDatePipe from "../../helpers/days-left-date-pipe";
import projectApi from "../../../pages/projects/api/project-api";
import { IProject } from "../../../pages/projects/models/project.interface";
import Pagination from "../pagination/pagination";

type LinkProjectModalProps = {
  data: {
    visible: boolean,
    project?: IProject,
    entityKey?: string,
    entityMapId?: string
  },
  onConfirm: (data: { project: IProject, entityMapId?: string, entityKey?: string }) => void,
  onCancel: () => void
}

const LinkProjectModal = (props: LinkProjectModalProps) => {
  const [sortedInfo, setSortedInfo] = useState<any>(null);
  let isSorted = sortedInfo || {};

  const columns: any = [
    {
      title: "Project",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: isSorted?.columnKey === "name" && isSorted.order,
      render: (value: string, record: any) => {
        return <div className='link' onClick={(e: any) => handleClick(record)}>{value}</div>
      }
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      sorter: true,
      sortOrder: isSorted?.columnKey === "owner" && isSorted.order,
      render: (value: any, project: any) => <span>{value?.displayName}</span>,
    },
    {
      title: "Program",
      dataIndex: "workflow",
      key: "workflow",
      sortOrder: isSorted?.columnKey === "workflow" && isSorted.order,
      sorter: true,
      render: (value: any, project: any) => <span>{value?.name}</span>,
    },
    {
      title: "Modified",
      dataIndex: "updatedAt",
      key: "modified",
      sortOrder: isSorted?.columnKey === "modified" && isSorted.order,
      sorter: true,
      render: (value: any) => <>{DaysLeftDatePipe(value)}</>
    },
  ]

  const [selectedProject, setSelectedProject] = useState<any>(props.data?.project ? props.data.project : undefined);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sort, setSort] = useState('')

  let params = {
    page,
    pageSize,
    sort,
    search: searchValue
  }

  const projects = useQuery(["projects", params], () => projectApi.getProjects(params))

  const handleChange = (_: any, filter: any, sorter: any) => {
    const order =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    if (order?.length > 0) {
      setSort(`${sorter.field}:${order}`);
      setSortedInfo(sorter);
    } else {
      setSortedInfo(undefined);
      setSort("");
    }
  };

  const handleClick = (record: any) => {
    setSelectedProject(record);
  }


  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  const onSave = () => {
    props.onConfirm({
      project: selectedProject,
      entityKey: props.data?.entityKey,
      entityMapId: props.data?.entityMapId
    })
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }

  return (
    <Modal
      open={props.data.visible}
      width={1000}
      onCancel={props.onCancel}
      onOk={onSave}
      cancelButtonProps={{type: "link"}}
      okButtonProps={{disabled: !selectedProject}}
      okText="Save"
      title="Link Project">
      <div className="link-project-wrapper" data-testid={"link-project-modal"}>
        <div className='row link-project-header'>
          <div>
            Select a project to link
          </div>
          {
            !!selectedProject?.name &&
            <div className="project-placeholder">
              <span>{selectedProject?.name}</span>
              <span className='cancel' onClick={() => setSelectedProject(undefined)}> <i
                className="bi bi-x"/></span>
            </div>
          }

        </div>
        <div className="row justify-space-between">
          <div className='relative outline-input-wrapper'>
            <DebounceInput
              debounceTimeout={600}
              type="text"
              className='outline-input'
              placeholder='Type project name to search'
              onChange={handleSearchChange}
              value={searchValue}/>
            <div className='absolute search-logo'><i className='bi bi-search'/></div>
          </div>
          {
            projects?.data && projects?.data?.pagination?.totalElements > 0 &&
            <Pagination totalItems={projects?.data?.pagination?.totalElements}
                        currentPage={page}
                        pageSize={pageSize}
                        onChange={handlePaginationChange}
                        showSizeChanger={true}/>
          }

        </div>
        <Table
          loading={projects.isLoading}
          dataSource={projects.data?.content}
          columns={columns}
          rowKey="id"
          onChange={handleChange}
          pagination={false}/>
      </div>
    </Modal>
  )
}

export default LinkProjectModal
