import * as React from "react";

const SvgNugget = (props: any) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(-139 -560) translate(131 552) translate(8 8)"/>
        <path
          fill={props.color ? props.color : '#545E6B'}
          d="M5.56 21.44c.196.195.196.511 0 .706l-1.414 1.415c-.195.195-.511.195-.707 0-.195-.196-.195-.512 0-.707l1.415-1.415c.195-.195.511-.195.707 0zm13.586 0l1.415 1.414c.195.195.195.511 0 .707-.196.195-.512.195-.707 0l-1.415-1.415c-.195-.195-.195-.511 0-.707.196-.195.512-.195.707 0zM12 22c.276 0 .5.224.5.5s-.224.5-.5.5-.5-.224-.5-.5.224-.5.5-.5zm1.43-17.283l5.409 1.45-.43 8.097-3.09 5.04-7.371 1.544-4.236-5.828 1.76-6.569 7.958-3.734zM15.95 14.78l-2.998.37-1.668 1.9 2.163.58 2.503-2.85zM7.05 11.221l-.092 4.668 1.87 1.675 3.338-3.8-5.116-2.543zM2 16c.276 0 .5.224.5.5s-.224.5-.5.5-.5-.224-.5-.5.224-.5.5-.5zm19 0c.276 0 .5.224.5.5s-.224.5-.5.5-.5-.224-.5-.5.224-.5.5-.5zm-7.075-8.804L8.717 9.321l4.823 3.639 2.704.725-.156-5.909-2.163-.58zM.575 9.743l.088.015 1.932.518c.267.071.425.345.354.612-.072.267-.346.425-.612.354l-1.932-.518c-.267-.071-.425-.345-.354-.612.072-.267.346-.425.612-.354zm22.762.015c.266-.071.54.087.612.354.071.267-.087.54-.354.612l-1.932.518c-.266.071-.54-.087-.612-.354-.071-.267.087-.54.354-.612zM5 4c.276 0 .5.224.5.5S5.276 5 5 5s-.5-.224-.5-.5.224-.5.5-.5zm14 0c.276 0 .5.224.5.5s-.224.5-.5.5-.5-.224-.5-.5.224-.5.5-.5zm-7-4c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5z"
          transform="translate(-139 -560) translate(131 552) translate(8 8)"/>
      </g>
    </svg>
  );
}

export default SvgNugget;
