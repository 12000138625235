import React, { useState } from "react";
import SvgConversation from '../../../../../../../assets/icons/js/Conversation';
import SvgMember from '../../../../../../../assets/icons/js/member';

export default function GoogleDriveRequirementsTab() {

  const [colorPicked, setColorPicked] = useState('#1b3380');

  const pickColor = (color: string): void => {
    if (!color) {
      return;
    }
    setColorPicked(color);
  }

  return (
    <div className="integration-tab column">
      <div className="row description-tab w-100">
        <div className="column">
          <h3 className="title">InsightsHub requires access to: </h3>
          <div className="column">
            <div className="content-title">
              <SvgMember/>
              <h4>Content and info about you</h4>
            </div>
            <ul>
              <li>View information about your identity</li>
            </ul>
          </div>
          <div className="column">
            <div className="content-title">
              <div>
                <SvgConversation/>
              </div>
              <div>
                <h4>See, edit and create all your Google Drive files</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="column image-picker w-100 d-flex align-items-end">
          <div>
            <div style={{backgroundColor: colorPicked}} className="image-picked"/>
            <div className="row">
              <div className="pick-image"
                   style={{backgroundColor: '#1b3380'}}
                   onClick={() => pickColor('#1b3380')}/>

              <div className="pick-image"
                   style={{backgroundColor: '#545e6b'}}
                   onClick={() => pickColor('#545e6b')}/>

              <div className="pick-image"
                   style={{backgroundColor: '#1b87e6'}}
                   onClick={() => pickColor('#1b87e6')}/>

              <div className="pick-image"
                   style={{backgroundColor: '#a5d5ff'}}
                   onClick={() => pickColor('#a5d5ff')}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
