import React from 'react'
import SvgNugget from '../../../../../../../assets/icons/js/nugget'
import { ISearchNugget } from '../../../models/search-nugget'
import "./nugget-search-card.scss"
import qs from 'query-string'
import { useNavigate } from 'react-router-dom'

const NuggetSearchCard = (props: { nugget: ISearchNugget }) => {
  const navigate = useNavigate();
  const nugget = props?.nugget
  const navigateToProject = (projectId: number) => {
    navigate({pathname: "/projects/edit", search: qs.stringify({tab: 1, id: projectId})})
  }
  return (
    <div className='nugget-search-container'>
      <span className='nugget-matches'>{nugget.matches} {nugget?.matches === 1 ? 'match' : 'matches'}</span>
      <div className='nugget-search-card-wrapper'>
        <div>
          <SvgNugget width={24} height={24}/>
        </div>

        <div className='nugget-search-card'>
          <div className='nugget-search-description pointer'
               onClick={() => navigateToProject(nugget?.projectId)}
               dangerouslySetInnerHTML={{__html: nugget?.description}}/>

          <div className='nugget-search-tags'>
            {nugget.nodes.map((node: any) => {
              return <div className='nugget-search-tag' key={node.id}>{node.name}</div>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NuggetSearchCard
