import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SvgArrowBack from '../../../../../../assets/icons/js/ArrowBack';
import { Tabs } from 'antd';
import './interview.scss';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { DebounceInput } from 'react-debounce-input';
import MyProjectApi from '../../../../api/project-api';
import InterviewsApi from '../api/interviews-api';
import { IProject } from '../../../../models/project.interface';
import InformIconsPipe from '../../../../../../_shared/helpers/inform-icons-pipe';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { environment } from '../../../../../../environment';
import { io } from 'socket.io-client';
import Collaboration from './collaboration';
import { IInterviewNote } from '../model/interview-note.interface';
import { ICollaborator } from '../model/collaborator.interface';
import debounce from 'lodash.debounce';
import qs from 'query-string';
import SvgCollaboration from '../../../../../../assets/icons/js/collaboration';
import SvgDetails from '../../../../../../assets/icons/js/details';
import { Details } from './details/details';
import SvgGuide from '../../../../../../assets/icons/js/guide';
import { Guide } from './guide';
import { ITask } from '../../../../../settings/settings-tabs/interview-labs-settings/model/task.interface';
import { ITeamMember, ITeamMemberData } from '../../../../../settings/settings-tabs/team/model/team-member.interface';
import { DraftJs } from '../../../../../../_shared/components/draft-js/draft';
import { usePrevious } from '../../../../../../core/hooks/use-previous';
import isEqual from 'lodash.isequal';
import useDebounce from '../../../../../../_shared/helpers/use-debounce.hook';
import { IFlagType } from '../../../../../settings/settings-tabs/interview-labs-settings/model/flag-type.interface';
import { DisplayHighlightContainer } from './display-highlights-components/display-highlight-container';
import { LeftDisplayHighlightContainer } from './display-highlights-components/left-display-highlight-container';
import { RetrieveHighlightsFromDraft } from './interview-utils/retrieve-highlights-from-draft';
import SvgSaving from '../../../../../../assets/icons/js/Saving';
import SvgSuccess from '../../../../../../assets/icons/js/Success';
import {
  getLeftDisplayHighlights
} from '../../../../../../_shared/components/draft-js/components/helpers/get-left-display-highlights';
import {
  getDisplayHighlights
} from '../../../../../../_shared/components/draft-js/components/helpers/get-display-highlights';
import {
  updateActiveTasks
} from '../../../../../../_shared/components/draft-js/components/helpers/update-active-tasks';
import GlobalContext from '../../../../../../store/global-context';
import Media from './details/media';
import TryMyUi from './details/try-my-ui';
import DetailsBlank from './details/details-blank';
import { findNotePadType } from '../_shared/notepad-type';
import SvgMultimedia from '../../../../../../assets/icons/js/multimedia';

const TabPane = Tabs.TabPane;

export default function Interview(): JSX.Element {
  const draftJsRef: any = useRef(null);
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const urlParam: any = qs.parse(location.search);
  let interviewId: string | null = urlParam?.interview;
  const projectId: string | null = urlParam?.id;
  let typeId: string | null = urlParam?.type;
  const globalContext = useContext(GlobalContext);
  const user: any = globalContext?.user;

  const [socket] = useState(() => io(`${environment.backendUrl}/sockets`, {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
    },
  }));

  const [loader, setLoader] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(true);
  const [saved, setSaved] = useState<boolean>(true);

  const [project, setProject] = useState<IProject>();
  const [teamMembers, setTeamMembers] = useState<any[]>();
  const [loadingMembers, setLoadingMembers] = useState<boolean>(true);
  const [flags, setFlags] = useState<IFlagType>();
  const [loadingFlags, setLoadingFlags] = useState<boolean>(true);
  const [nuggets, setNuggets] = useState<IFlagType>();
  const [loadingNuggets, setLoadingNuggets] = useState<boolean>(true);

  const [details, setDetails] = useState<any>({});
  const [title, setTitle] = useState<string>('');
  const [collaborations, setCollaborations] = useState<ICollaborator[]>([]);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [guide, setGuide] = useState<EditorState>();
  const [activeCollaborator, setActiveCollaborator] = useState<any>(user.userId);
  const [content, setContent] = useState<EditorState>();
  const [editorState, setEditorState] = useState<EditorState>();

  const [rawEditorState, setRawEditorState] = useState<any>();
  const debounceRawEditorState = useDebounce(rawEditorState, 600);
  const prevRawEditorState = usePrevious<any>(debounceRawEditorState);

  const [highlights, setHighlights] = useState<any>();
  const debounceHighlights = useDebounce(highlights, 600);
  const prevHighlights = usePrevious<any>(debounceHighlights);

  const leftDisplayTextHighlights = useMemo(() => {
    if (editorState) {
      return getLeftDisplayHighlights(editorState);
    } else {
      return null;
    }
  }, [editorState]);

  const displayTextHighlights = useMemo(() => {
    if (editorState) {
      return getDisplayHighlights(editorState);
    } else {
      return null;
    }
  }, [editorState]);

  const activeTasks = useMemo(() => {
    if (editorState) {
      return updateActiveTasks(editorState);
    } else {
      return [];
    }
  }, [editorState]);

  useEffect(() => {
    if (!user) {
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    }

    loadPage();
    loadTeamMembers();
    loadFlags();

    return () => {
      socket.close();
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("getInterview", (data: IInterviewNote) => {

        setSaved(true);
        setSaving(false);
        let newCollaborations: ICollaborator[] = [];

        data.collaborations.find((collaborator: ICollaborator) => {
          if (collaborator.userId === user.id) {
            collaborator.displayName = 'My Notes';
            newCollaborations.push(collaborator);
          }
        });

        data.collaborations.forEach((collaborator: ICollaborator) => {
          if (collaborator.userId === user.id) {
            return;
          }

          newCollaborations.push(collaborator);
        });

        setCollaborations(newCollaborations);

        if (data.lastEdit !== user.id) {
          setDetails(data);
          setTitle(data.title);
          setTasks(data.tasks);
          setGuide(data.guide);
        }
      });

      socket.on("connect_error", (err: any) => {
        console.log(err.message);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (debounceRawEditorState) {
      if (isEqual(debounceRawEditorState, prevRawEditorState)) {
        return;
      }

      const payload = {
        interviewId,
        content: rawEditorState,
        contentIndexed: convertFromRaw(JSON.parse(rawEditorState)).getPlainText('\u0001')
      }

      socket.emit('writeNotes', {...payload});

      debouncedChangeHandler();
    }
  }, [debounceRawEditorState])

  useEffect(() => {
    if (highlights) {
      if (isEqual(highlights, prevHighlights)) {
        return;
      }

      if (!activeCollaborator?.id) {
        // emit
        socket.emit('setHighlights', {
          interviewId,
          highlights,
          content: rawEditorState
        });
      }
    }
  }, [debounceHighlights]);

  const updateHighlightsForBacked = (editorState: EditorState) => {
    const highlights = RetrieveHighlightsFromDraft(editorState);
    setHighlights(highlights);
    setSaving(true);
    setSaved(false);
  }

  const onSetChangeEditorState = (task: ITask) => {
    if (task.checked) {
      draftJsRef.current.assignInterviewTaskEntityToEditor(task);
    } else {
      draftJsRef.current.removeInterviewTaskEntityToEditor(task.uuid)
    }
  }

  const updateEditorState = (editorState: EditorState) => {
    setRawEditorState(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    updateHighlightsForBacked(editorState);
    setEditorState(editorState);
    setSaving(true);
    setSaved(false);
  }

  const setIdle = () => {
    socket.emit('setIdle', {interviewId: interviewId});
  }

  const debouncedChangeHandler = useCallback(debounce(setIdle, 3000), []);

  function loadPage(): void {
    if (projectId) {
      MyProjectApi.getProject(projectId)
        .then((response: IProject) => {
          setProject(response);
        });
    }

    if (!interviewId && projectId && typeId) {
      InterviewsApi.create(projectId, typeId)
        .then((response: IInterviewNote) => {
          interviewId = response.id;
          typeId = response.type;
          loadNuggets(response.id);
          setFormValues(response);
        });
    }

    if (interviewId) {
      InterviewsApi.getById(interviewId)
        .then((response: any) => {
          loadNuggets(interviewId);
          setFormValues(response);
        });
    }
  }

  function loadTeamMembers() {
    MyProjectApi.getOwners()
      .then((teamMembersData: ITeamMemberData) => {
        const mappedTeamMembers = teamMembersData.content.map((member: ITeamMember) => {
          return {
            ...member,
            id: member.id,
            name: member.displayName
          };
        });

        setTeamMembers(mappedTeamMembers);
        setLoadingMembers(false);
      });
  }

  function loadFlags(): void {
    InterviewsApi.getFlags()
      .then((flags: IFlagType) => {
        setFlags(flags);
        setLoadingFlags(false)
      });
  }

  function loadNuggets(interviewId: any): void {
    InterviewsApi.getNuggets(interviewId)
      .then((nuggets: any) => {
        setNuggets(nuggets);
        setLoadingNuggets(false)
      });
  }

  function setFormValues(interview: IInterviewNote): void {
    const collaborations: ICollaborator[] = interview.collaborations;

    let newCollaborations: ICollaborator[] = [];

    collaborations.find((collaborator: ICollaborator) => {
      if (collaborator.userId === user.id) {
        collaborator.displayName = 'My Notes';
        newCollaborations.push(collaborator);
      }
    });

    collaborations.forEach((collaborator: ICollaborator) => {
      if (collaborator.userId === user.id) {
        return;
      }

      newCollaborations.push(collaborator);
    });

    setTitle(interview.title);
    setDetails(interview);
    setCollaborations(newCollaborations);
    setTasks(interview.tasks);
    setGuide(interview.guide);

    const collaborator = collaborations.find((collaborator => collaborator.userId === user.id))
    setContent(collaborator?.content);

    const params = {
      id: projectId,
      interview: interview.id,
    }


    navigate({pathname: '', search: qs.stringify(params)}, {
      state: location.state
    })
    connectToRoom(interview.id);
    setLoader(false);
  }

  function connectToRoom(interviewId: string): void {
    socket.emit('joinRoom', `${user.organization.id}/${projectId}/${interviewId}`);
  }

  function onCollaborator(userId: string): void {
    const collaborator = collaborations.find((collaborator => collaborator.userId === parseInt(userId)))

    if (parseInt(userId) === user.id) {
      setActiveCollaborator(null);
      setContent(collaborator?.content);
    } else {
      setActiveCollaborator(collaborator);
      if (collaborator?.content) {
        setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(collaborator.content))));
      } else {
        setEditorState(EditorState.createEmpty())
      }
    }
  }

  function assignFlagsToSelectedText(data: {
    selectedFlags: IFlagType[],
    entityKey?: string,
    entityMapId?: string
  }): void {
    if (data?.entityKey) {
      draftJsRef.current.onAssignFlagsToSelectedText(data);
    }
  }

  function openEvidenceDialog(data: any): void {
    if (data?.edit) {
      draftJsRef.current.onOpenEvidenceDialog(data.entityData.entityKey)
    }

    if (data?.delete) {
      draftJsRef.current.onAssignEvidenceToSelectedText(data)
    }
  }

  useEffect(() => {
    if (collaborations) {
      const collaborator = collaborations.find((collaborator => collaborator.id === activeCollaborator?.id));
      if (collaborator && activeCollaborator?.id) {
        setActiveCollaborator(collaborator);
      }
    }
  }, [collaborations]);

  function navigateBack(): void {
    const state: any = location.state;

    if (state?.fromAiChat) {
      navigate("/home?tab=2");
      return;
    }

    const queryParams = {
      id: urlParam.id,
      tab: state?.fromActivity ? '7' : '6'
    }

    navigate({pathname: '/projects/edit', search: qs.stringify(queryParams)});
  }

  function notepadTypeTab(): JSX.Element {
    let name = findNotePadType(details.typeId)?.name;

    return <><TabPane
      tab={<div className="d-flex align-items-center">
        {
          details.typeId === 3 ?
            <SvgMultimedia width={14} height={14}/> :
            <SvgDetails width={14} height={14}/>
        }
        <span style={{marginLeft: 10}}>{details.typeId === 3 ? "Media" : "Details"}</span>
      </div>} key="1">
      {
        details.typeId === 1 &&
          <Details
              details={details}
              projectId={projectId}
              teamMembers={teamMembers}
              interviewId={interviewId}
              socket={socket}
          />
      }
      {
        details.typeId === 2 &&
          <DetailsBlank
              socket={socket}
              projectId={projectId}
              interviewId={interviewId}
              details={details}
              teamMembers={teamMembers}
          />
      }
      {
        details.typeId === 3 &&
          <Media
              socket={socket}
              projectId={projectId}
              interviewId={interviewId}
              details={details}
              teamMembers={teamMembers}
          />
      }
      {
        details.typeId === 4 &&
          <TryMyUi
              socket={socket}
              projectId={projectId}
              interviewId={interviewId}
              details={details}
              teamMembers={teamMembers}
          />
      }
    </TabPane>
      {
        details.typeId === 1 &&
          <TabPane
              tab={
                <div className="d-flex align-items-center">
                  <SvgGuide width={14} height={14}/>
                  <span style={{marginLeft: 10}}>Guide</span>
                </div>}
              key="2">
              <Guide
                  socket={socket}
                  activeTasks={activeTasks}
                  onSetChangeEditorState={onSetChangeEditorState}
                  interviewId={interviewId}
                  guide={guide}
                  tasks={tasks}
              />
          </TabPane>
      }
    </>
  }

  return (
    <>
      {loader || loadingMembers || loadingFlags || loadingNuggets ? <Loader/>
        :
        <div className="interview-main d-flex row">
          <div className="interview">
            <div className="intake-form-header justify-space-between">
              <div className="row align-items-center">
                <button style={saving ? {opacity: 0.5, marginRight: 10} : {opacity: 1, marginRight: 10}}
                        className="icon-button back"
                        onClick={navigateBack}>
                  <SvgArrowBack/>
                </button>
                <InformIconsPipe icon={project?.icon} width={16} height={16} color={project?.color}/>
                <span className="font-weight-500">{project?.name}</span>
              </div>
              <div className="row align-items-center">
                {
                  saving && !saved &&
                    <>
                        <SvgSaving/>
                        <span className="font-weight-300" style={{fontSize: '12px', marginLeft: 0}}>Saving ...</span>
                    </>
                }
                {
                  saved &&
                    <>
                        <SvgSuccess/>
                        <span className="font-weight-300"
                              style={{fontSize: '12px', marginLeft: 5}}>All changes saved</span>
                    </>
                }
              </div>
              <div/>
            </div>
            <div className="interview-wrapper">
              <div className="display-highlight-wrapper left-display-highlight-wrapper">
                <LeftDisplayHighlightContainer editorState={editorState}
                                               leftDisplayTextHighlights={leftDisplayTextHighlights}
                                               openEvidenceDialog={openEvidenceDialog}
                                               readOnly={!!activeCollaborator?.id}/>
              </div>
              <div className="interview-editor">
                <div style={{width: '130%'}}>
                  <Tabs destroyInactiveTabPane={true} onChange={onCollaborator}>
                    {collaborations?.map((collaborator: ICollaborator) => {
                      return (
                        <TabPane tab={collaborator.displayName} key={collaborator.userId}/>
                      )
                    })}
                  </Tabs>
                </div>
                {activeCollaborator?.id
                  ?
                  <>
                    <div style={{height: 86.8}}/>
                    <DraftJs key={activeCollaborator.id}
                             readOnly={activeCollaborator.userId !== user.id}
                             content={activeCollaborator.content}/>
                  </>
                  :
                  <>
                    <div className="outline-input-wrapper">
                      <span className="outline-input-label">Title</span>
                      <DebounceInput
                        name="title"
                        className="outline-input"
                        placeholder="Title"
                        debounceTimeout={600}
                        style={{height: 40, marginBottom: 24, fontSize: 16}}
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value)
                          setDetails({...details, title: e.target.value})
                        }}/>
                    </div>

                    <DraftJs key={'a1'}
                             ref={draftJsRef}
                             content={content}
                             interviewId={interviewId}
                             teamMembers={teamMembers}
                             mention={true}
                             flag={true}
                             nugget={true}
                             flags={flags}
                             nuggets={nuggets}
                             project={project}
                             updateEditorState={updateEditorState}/>
                  </>
                }
              </div>
              <div className="display-highlight-wrapper">
                <DisplayHighlightContainer
                  editorState={editorState}
                  displayTextHighlights={displayTextHighlights}
                  flags={flags}
                  readOnly={!!activeCollaborator?.id}
                  assignFlagsToSelectedText={assignFlagsToSelectedText}/>
              </div>
            </div>
          </div>

          <div className="interview-tabs">
            <Tabs>
              {notepadTypeTab()}
              <TabPane tab={
                <div className="d-flex align-items-center">
                  <SvgCollaboration width={16} height={14}/>
                  <span style={{marginLeft: 10}}>Collaboration</span>
                </div>
              } key="3">
                {collaborations?.length
                  ?
                  <div className="collaboration">
                    {collaborations.map((collaborator: ICollaborator) => {
                      return (
                        <div className="column" key={collaborator?.id}>
                          <Collaboration collaborator={collaborator}/>
                        </div>
                      )
                    })}
                  </div>
                  :
                  <span>No Collaborators</span>
                }
              </TabPane>
            </Tabs>
          </div>
        </div>
      }
    </>
  )
}
