import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import React from 'react';

export function PreviewGraph(props: any): JSX.Element {
  const {data, selectedChart} = props;

  const highChartJSON = JSON.parse(data.highChartJSON);

  highChartJSON.chart.type = selectedChart.value;


  return (
    <>
      <HighchartsReact
        options={highChartJSON}
        highcharts={Highcharts}/>
    </>
  );
}
