import ReactDOM from 'react-dom';
import * as htmltoimage from 'html-to-image';
import { useEffect, useRef } from 'react';
import { useOnLoadImages } from "../../../../core/hooks/use-on-load-images";
import { delay } from "../../../../_shared/helpers/delay";

export function SnapshotImage(props: any): JSX.Element {
  const snapshot = useRef(null);
  const imagesLoaded = useOnLoadImages(snapshot)

  useEffect(() => {
    if (props?.updateDownloadLoader) {
      props.updateDownloadLoader(!imagesLoaded)
    }
  }, [imagesLoaded])

  useEffect(() => {
    download();

    return () => {
      props.downloaded();
    }
  }, []);

  const download = async () => {
    await delay(200)
    const snapshotContent: any = snapshot?.current;
    if (snapshotContent) {
      const downloadImage = htmltoimage.toBlob(snapshotContent.firstChild).then((blob: any) => {
        window.saveAs(blob, `snapshot-${new Date().getTime()}.png`);
      });

      downloadImage.finally(() => {
        props.downloaded();
      });
    }
  }

  return ReactDOM.createPortal(
    <div ref={snapshot} className="snapshot-image">
      {props.children}
    </div>,
    document.body
  )
}
