import React from 'react';

const SvgStepArrow = (props: any) => {
  return (
    <svg width="42" height="20" viewBox="0 0 42 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32.555 0.563018C31.7417 -0.187673 30.4232 -0.187673 29.6099 0.563018C28.7967 1.31371 28.7967 2.53082 29.6099 3.28151L34.7216 8H24C22.8954 8 22 8.89543 22 10C22 11.1046 22.8954 12 24 12H34.7216L29.6099 16.7185C28.7967 17.4692 28.7967 18.6863 29.6099 19.437C30.4232 20.1877 31.7417 20.1877 32.555 19.437C33.6849 18.394 34.8544 17.3688 36.0255 16.3423C37.8664 14.7285 39.7112 13.1115 41.4118 11.4166C41.8141 11.0343 42.01 10.5357 41.9996 10.0402C41.9999 10.0268 42 10.0134 42 10C42 9.99104 41.9999 9.98209 41.9998 9.97315C41.9998 9.96871 41.9997 9.96427 41.9996 9.95983C42.01 9.46428 41.8141 8.96571 41.4118 8.58337C39.7112 6.88852 37.8664 5.27142 36.0254 3.65767C34.8544 2.63115 33.6848 1.60598 32.555 0.563018Z"
        fill={props?.color ? props.color : '#545e6b'}/>
      <path
        d="M2 8C0.895432 8 0 8.89543 0 10C0 11.1046 0.895432 12 2 12H14C15.1046 12 16 11.1046 16 10C16 8.89543 15.1046 8 14 8H2Z"
        fill={props?.color ? props.color : '#545e6b'}/>
    </svg>

  );
};

export default SvgStepArrow;
