import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { message } from 'antd';
import HomeApi from "../../home/api/home-api";
import { SyncStatus } from "../../home/models/sync-status.type";
import SvgInfoCircleFilled from "../../../assets/icons/js/InfoCircleFilled";
import { UseQueryResult } from "react-query/types/index";
import { IProjectTableData } from "../models/project-table-data.interface"; // Assuming you're using Ant Design for messages

interface Params {
  search: string;

  [key: string]: any;
}

export const useCheckSyncStatus = (projects: UseQueryResult<IProjectTableData, unknown>, params: Params) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!projects.data) {
      return;
    }

    if (projects.isFetchedAfterMount) {
      if (params.search.length || Object.keys(params).length > 4) {
        return;
      }

      HomeApi.checkForSyncStatus().then((status: SyncStatus) => {
        if (status === 'FINISHED' && projects.data!.pagination.totalElements === 0) {
          if (location.state?.lastPath === '/home/project') {
            message.open({
              duration: 4,
              content: (
                <div className="custom-loading-message">
                  <SvgInfoCircleFilled color={"#1B3380"}/>
                  <span style={{color: "#545e6b"}}>
                    Redirecting to landing page in 5 seconds as you haven't created the project.
                  </span>
                </div>
              ),
              className: "custom-message",
            });

            setTimeout(() => {
              navigate('/initial-page');
            }, 5000);

            return;
          }

          navigate('/initial-page');
        }
      });
    }
  }, [projects.isRefetching, projects.data, projects.isFetchedAfterMount, params.search.length, params, location, navigate]);
};
