import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import SvgArrowBack from '../../../../../../assets/icons/js/ArrowBack';
import './slack-integration.scss';
import SvgSlackLogo from '../../../../../../assets/icons/js/slack-logo';
import { Button, Switch, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SlackDescriptionTab } from './tabs/slack-description-tab';
import { SlackRequirementsTab } from './tabs/slack-requirements-tab';
import { environment } from '../../../../../../environment';
import DeskSettingsApi from '../../../../api/settings-api';
import { AiOutlinePoweroff } from 'react-icons/all';
import { useLocation, useNavigate } from 'react-router-dom';

const TabPane = Tabs.TabPane;

export function SlackIntegration(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam: URLSearchParams = new URLSearchParams(location.search);
  const settingsColumns: ColumnsType<any> = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project'
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel'
    },
    {
      title: 'Notifications',
      dataIndex: 'notifications',
      key: 'notifications',
      align: 'right',
      render: (value: boolean, channel: any) => <>
        <Switch size="small"
                style={{width: '30px'}}
                checked={value}
                onClick={() =>
                  changeNotificationAndConversationStatus('notification', value, channel)
                }/>
      </>
    },
    {
      title: 'Conversation logs',
      dataIndex: 'conversationLogs',
      key: 'conversationLog',
      align: 'right',
      render: (value: boolean, channel: any) => <Switch size="small"
                                                        style={{width: '30px'}}
                                                        checked={value}
                                                        onClick={() =>
                                                          changeNotificationAndConversationStatus('conversationLog', value, channel)
                                                        }/>
    },
  ];

  const [loader, setLoader] = useState<boolean>(false);
  const [slackIsInstalled, setSlackIsInstalled] = useState<any>();
  const [isInstalledLoading, setIsInstalledLoading] = useState<boolean>(true);
  const [channels, setChannels] = useState<any[]>([]);

  useEffect(() => {
    isSlackInstalled();
    slackInstall();
  }, [])

  function slackInstall(): void {
    const code = urlParam.get('code');
    if (code) {
      DeskSettingsApi.installSlack(code)
        .then(() => {
          isSlackInstalled();
        });
    }
  }

  function connectToSlack(): void {
    window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${environment.slack_client_id}&scope=chat:write,channels:join,channels:manage,incoming-webhook&redirect_uri=${window.location.href}`;
  }

  function navigateBack(): void {
    navigate('/settings?tab=6');
  }

  function uninstallSlack(): void {
    DeskSettingsApi.uninstallSlack()
      .then(() => {
        isSlackInstalled();
      })
  }

  function changeNotificationAndConversationStatus(type: string, value: boolean, channel: any): void {
    const copiedChannels: any[] = [...channels];

    type === 'notification' ? copiedChannels.find(ch => ch.id === channel.id).notifications = !value
      : copiedChannels.find(ch => ch.id === channel.id).conversationLogs = !value

    setChannels([...copiedChannels]);

    DeskSettingsApi.changeNotificationAndConversationStatus(type, channel.id, !value)
      .then(() => {
        getChannels();
      });
  }

  function getChannels(): void {
    DeskSettingsApi.getChannels()
      .then((response) => {
        console.log(response)
        setChannels(response);
      });
  }

  function isSlackInstalled(): void {
    setLoader(true)
    DeskSettingsApi.slackInstalled()
      .then((response) => {
        if (response) {
          getChannels();
        } else {
          setChannels([]);
        }

        setSlackIsInstalled(response);
        setIsInstalledLoading(false);
        setLoader(false);
      });
  }

  return (
    <>
      {loader ? <Loader/>
        :
        <div className="slack-integration">
          <div className="header row">
            <div className="column align-items-center back-button">
              <button className="icon-button back" onClick={() => navigateBack()}>
                <SvgArrowBack/>
              </button>
            </div>
            <div className="column">
              <div className="row align-items-center">
                <div className="d-flex logo">
                  <SvgSlackLogo/>
                </div>
                <h2>Slack Integration</h2>
              </div>
              <div className="row">
                  <span className="description">
                    Seamlessly follow-up on your research projects,
                    communicate with all stakeholders and generate insights swiftly without leaving Slack.
                  </span>
              </div>
              <div className="row button-wrapper">
                {
                  isInstalledLoading ?
                    <Button type="primary" className="integration-button" icon={<AiOutlinePoweroff/>} loading/>
                    :
                    <>
                      {slackIsInstalled ?
                        <Button type="primary" className="integration-button"
                                onClick={() => uninstallSlack()}>Uninstall</Button>
                        :
                        <Button type="primary" className="integration-button"
                                onClick={() => connectToSlack()}>Install</Button>
                      }
                    </>
                }
              </div>
            </div>
          </div>
          <div className="body">
            <Tabs>
              <TabPane tab="Description" key="1">
                <SlackDescriptionTab/>
              </TabPane>
              <TabPane tab="Requirements" key="2">
                <SlackRequirementsTab/>
              </TabPane>
              <TabPane tab="Settings" key="3">
                <div className="integration-tab">
                  <Table columns={settingsColumns}
                         pagination={false}
                         dataSource={channels}
                         rowKey="id"/>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      }
    </>
  );
}
