import { Divider } from 'antd'
import React, { useContext, useRef } from 'react'
import SvgTrash from '../../../../../assets/icons/js/Trash';
import { useOnClickOutside } from '../../../../../core/hooks/use-outside-click';
import StoryContext from '../../story-edit.context';
import { insertImageEntity } from '../helpers/insert-image-entity';

const ImageActionMenu = (props: any) => {
  const component = useRef(null);
  const storyContext = useContext(StoryContext);
  const menu: any = [
    {
      icon: "Full Width",
      onClick: () => toggleFullWidth()
    },
    {
      icon: "Narrow",
      onClick: () => toggleNarrow()
    },
    {
      icon: "Caption",
      onClick: () => toggleCaption()
    },
    {
      icon: <SvgTrash/>,
      onClick: () => deleteImage()
    }
  ]

  const deleteImage = () => {
    storyContext.onSetEditorState(
      insertImageEntity(
        storyContext.editorState,
        {...props.data},
        true)
    );
  }

  const toggleNarrow = () => {
    if (!props.data.view.narrow) {
      props.data.setView((prev: any) => {
        return {...prev, fullWidth: !prev.fullWidth, narrow: !prev.narrow}
      })
    }
  }

  const toggleFullWidth = () => {
    if (!props.data.view.fullWidth) {
      props.data.setView((prev: any) => {
        return {...prev, fullWidth: !prev.fullWidth, narrow: !prev.narrow}
      })
    }
  }

  const toggleCaption = () => {
    props.data.setView((prev: any) => {
      return {...prev, caption: !prev.caption}
    })
  }

  const isActive = (option: string) => {
    switch (option) {
      case "Full Width":
        return props.data.view.fullWidth
      case "Narrow":
        return props.data.view.narrow;
      case "Caption":
        return props.data.view.caption;
      default:
        return false
    }
  }

  useOnClickOutside(component, () => {
    props.close();
  })

  return (
    <div ref={component} className="floating-menu-wrapper absolute" style={props.data.position}>
      <div className="floating-button"
           onMouseDown={(e) => {
             e.stopPropagation()
             e.preventDefault()
           }}>
        {menu.map((item: any, index: number) => {
          return <div key={index} className="relative row align-items-center" style={{width: "max-content"}}>
            <button className={isActive(item.icon) ? "floating-inside-button active" : "floating-inside-button"}
                    onClick={(e) => item.onClick()}>{item.icon}</button>
            {
              (index === 2) &&
                <Divider type="vertical" style={{marginLeft: 1, marginRight: 1}}/>
            }
          </div>
        })}
        <div className="bottom-triangle"/>
      </div>
    </div>
  )
}

export default ImageActionMenu
