import * as React from "react";

const SvgDrag = (props: any) => {
  return (
    <svg width={12} height={20} viewBox="0 0 12 20" {...props}>
      <g id="02" transform="translate(-275.000000, -366.000000)" fill={props.color ? props.color : '#545E6B'}>
        <g id="Group-5" transform="translate(275.000000, 360.000000)">
          <path
            d="M2,22 C3.1045695,22 4,22.8954305 4,24 C4,25.1045695 3.1045695,26 2,26 C0.8954305,26 1.3527075e-16,25.1045695 0,24 C-1.3527075e-16,22.8954305 0.8954305,22 2,22 Z M10,22 C11.1045695,22 12,22.8954305 12,24 C12,25.1045695 11.1045695,26 10,26 C8.8954305,26 8,25.1045695 8,24 C8,22.8954305 8.8954305,22 10,22 Z M2,14 C3.1045695,14 4,14.8954305 4,16 C4,17.1045695 3.1045695,18 2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 C-1.3527075e-16,14.8954305 0.8954305,14 2,14 Z M10,14 C11.1045695,14 12,14.8954305 12,16 C12,17.1045695 11.1045695,18 10,18 C8.8954305,18 8,17.1045695 8,16 C8,14.8954305 8.8954305,14 10,14 Z M2,6 C3.1045695,6 4,6.8954305 4,8 C4,9.1045695 3.1045695,10 2,10 C0.8954305,10 1.3527075e-16,9.1045695 0,8 C-1.3527075e-16,6.8954305 0.8954305,6 2,6 Z M10,6 C11.1045695,6 12,6.8954305 12,8 C12,9.1045695 11.1045695,10 10,10 C8.8954305,10 8,9.1045695 8,8 C8,6.8954305 8.8954305,6 10,6 Z"
            id="Combined-Shape"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgDrag;
