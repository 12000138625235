import React from 'react';

const SvgLike = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.8 16V0H7.04C8.27712 0 9.28 0.95939 9.28 2.14286V4.28571H13.76C14.9971 4.28571 16 5.2451 16 6.42857V13.8571C16 15.0406 14.9971 16 13.76 16H4.8ZM0 4.80078H3.2V16.0008H0V4.80078Z"
            fill="#545e6b"/>
    </svg>
  );
};

export default SvgLike;
