import React from 'react'
import { useNavigate } from 'react-router-dom'
import SvgKnowledgeGraph from "../../../../../../../assets/icons/js/knowledge-graph";

const SearchKnowledgeGraphCard = (props: { node: any }) => {
  const navigate = useNavigate();
  const node = props?.node
  const navigateToNodes = () => {
    navigate({pathname: "/nodes"})
  }

  return (
    <div className='nugget-search-container'>
      <div className='nugget-search-card-wrapper'>
        <div>
          <SvgKnowledgeGraph width={24} height={24}/>
        </div>

        <div className='nugget-search-card'>
          <div className='nugget-search-description pointer'
               onClick={navigateToNodes}
               dangerouslySetInnerHTML={{__html: node?.name}}/>
        </div>
      </div>
    </div>
  )
}

export default SearchKnowledgeGraphCard
