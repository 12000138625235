import * as React from "react";

const SvgCancel = (props: any) => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1207 -566) translate(131 552)">
          <path fill={props.color ? props.color : '#545E6B'}
                fillRule="nonzero"
                d="M9.182.697L6 3.878 2.818.697C2.232.11 1.282.11.697.697L.594.81C.114 1.4.148 2.269.697 2.818L3.878 6 .697 9.182c-.586.586-.586 1.536 0 2.121l.114.103c.589.48 1.458.446 2.007-.103L6 8.121l3.182 3.182c.586.586 1.536.586 2.121 0l.103-.114c.48-.589.446-1.458-.103-2.007L8.121 5.999l3.182-3.181c.586-.586.586-1.536 0-2.121-.585-.586-1.535-.586-2.121 0z"
                transform="translate(1076 14)"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgCancel;
