import React from 'react';

const SvgThemeOverlayGradient = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_217_17289" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_217_17289)">
        <path
          d="M8.5 11.5V10H10V11.5H8.5ZM7 13V11.5H8.5V13H7ZM10 13V11.5H11.5V13H10ZM11.5 11.5V10H13V11.5H11.5ZM5.5 11.5V10H7V11.5H5.5ZM4.49646 17C4.06826 17 3.71181 16.8429 3.42708 16.5288C3.14236 16.2146 3 15.837 3 15.3958V4.60417C3 4.16301 3.15708 3.78537 3.47123 3.47123C3.78538 3.15708 4.16303 3 4.60417 3H15.3958C15.837 3 16.2146 3.15708 16.5288 3.47123C16.8429 3.78537 17 4.16301 17 4.60417V15.3958C17 15.837 16.8429 16.2146 16.5288 16.5288C16.2146 16.8429 15.837 17 15.3958 17H4.49646ZM5.5 15.5H7V14.5H5.5V15.5ZM8.5 15.5H10V14.5H8.5V15.5ZM14.5 15.5H15.5V14.5H14.5V15.5ZM4.5 14.5H5.5V13H7V14.5H8.5V13H10V14.5H11.5V13H13V14.5H14.5V13H13V11.5H14.5V10H15.5V4.5H4.5V11.5H5.5V13H4.5V14.5ZM14.5 11.5V13H15.5V11.5H14.5ZM11.5 14.5V15.5H13V14.5H11.5Z"
          fill="#545E6B"/>
      </g>
    </svg>
  );
};

export default SvgThemeOverlayGradient;
