import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { environment } from '../environment';
import { axiosClient } from '../axios-client';
import { useMutation, useQuery } from 'react-query';
import selectHubApi from "../core/components/select-hub/api/select-hub-api";
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../index';
import AuthApi from './auth.api';
import { IGlobalContext } from './global-context.interface';

const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

export function GlobalContextProvider({children}: any): JSX.Element {
  const navigate = useNavigate();
  const [qpCode, setQPCode] = useState<string>(localStorage.getItem('qpcode') || '');
  const [isLoggedIn, setIsLoggedIn] = useState<string>(localStorage.getItem('isLoggedIn') || '');

  useQuery(['login', qpCode], () => AuthApi.login(qpCode), {
      enabled: qpCode.length > 0 && isLoggedIn !== 'true',
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => handleLoginSuccess(data),
      onError: (error: AxiosError) => handleLoginFailure(error)
    }
  );

  const userData = useQuery(['userData', qpCode], () => AuthApi.getProfile(), {
    enabled: isLoggedIn === 'true',
    refetchOnWindowFocus: false,
    onError: () => redirectToQPLogin(),
    onSuccess: (data) => {
      if (!data?.licenseValid) {
        navigate('/license-error', {replace: true});
      }
    }
  });

  const logoutUser = useMutation(() => AuthApi.logout(qpCode), {
    onSettled: () => {
      clearOnLogout();
      window.location.replace("https://www.questionpro.com/a/logout.do");
    }
  });

  const partnerOrganizationsLocal: string | null = localStorage.getItem('partnerOrganizations');
  const [partnerOrganizations, setPartnerOrganizations] = useState(partnerOrganizationsLocal !== null && partnerOrganizationsLocal !== 'undefined' ? JSON.parse(partnerOrganizationsLocal) : [])
  const selectedOrganization = useMemo(() => (localStorage.getItem('selectedOrganization')), []);
  const [isPartner, setIsPartner] = useState<boolean>(localStorage.getItem('isPartner') === 'true' ?? false);

  useEffect(() => {
    if (window.location.href.includes('/oauth?code=')
      || window.location.href.includes('unauthorized-401')
      || window.location.href.includes('not-found')
      || window.location.href.includes('internal-error')) {
      return;
    }

    if (isLoggedIn !== 'true') {
      redirectToQPLogin();
    }
  }, []);

  async function switchOrganizations(organization?: any) {
    const {apiKey, organizationID, emailAddress, userID} = organization;

    if (organizationID === 'AGENCY') {
      await selectHubApi.signOutAs();
    } else {
      const payload = {apiKey, organizationID, emailAddress, userID};
      await selectHubApi.signInAs(payload);
    }

    localStorage.setItem('selectedOrganization', organizationID);
    window.location.reload();
  }

  function logIn(QPCode: string): void {
    setQPCode(QPCode);
    localStorage.setItem('qpcode', QPCode);
    queryClient.invalidateQueries('login');
  }

  function handleLoginSuccess(data: any): void {
    // Set user data in local storage
    localStorage.setItem('access_token', data.user.accessToken);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('isPartner', String(data.isPartner));
    localStorage.setItem('partnerOrganizations', JSON.stringify(data.partnerOrganizations));
    localStorage.setItem('selectedOrganization', '');

    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${data.user.accessToken}`;

    // Set state variables
    setIsPartner(data.isPartner);
    setPartnerOrganizations(data.partnerOrganizations);
    setIsLoggedIn('true');

    // Redirect to last location or home
    const lastLocation = localStorage.getItem('current_location');
    if (lastLocation && !lastLocation.includes('oauth')) {
      window.location.replace(lastLocation);
      localStorage.removeItem('current_location');
    } else {
      navigate('/home', {replace: true});
    }
  }

  function handleLoginFailure(error: AxiosError): void {
    if (error.response?.data?.statusCode === 401 && error.response?.data?.message === 'User is not active!') {
      // navigate('/unauthorized-401', {replace: true});
      // clearOnLogout();
      // redirectToQPLogin();
    } else {
      clearOnLogout();
      redirectToQPLogin();
    }
  }

  function redirectToQPLogin(): void {
    clearOnLogout();
    window.location.replace(`${environment.oauthUrl}/?client_id=${environment.oauthClientId}`);
  }

  function clearOnLogout(): void {
    const keysToRemove = [
      'qpcode',
      'isLoggedIn',
      'access_token',
      'monitor',
      'partnerOrganizations',
      'isPartner',
      'selectedOrganization'
    ];

    keysToRemove.forEach(key => localStorage.removeItem(key));
  }

  const context: IGlobalContext = {
    qpCode: qpCode,
    loader: userData.isLoading,
    user: userData?.data,
    logIn,
    redirectToQPLogin,
    logoutUser: () => logoutUser.mutate(),
    selectedOrganization,
    isPartner,
    switchOrganizations,
    partnerOrganizations
  };

  return <GlobalContext.Provider value={context}>
    {children}
  </GlobalContext.Provider>
}

export default GlobalContext;
export const useGlobalContext = () => useContext(GlobalContext);
