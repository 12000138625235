import React, { useEffect, useState } from 'react';
import './search.scss';
import { DebounceInput } from 'react-debounce-input';
import SvgSearch from '../../../../assets/icons/js/Search';
import { ISearchData } from '../../models/search-data.interface';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import SearchFilters from './components/search-filters';
import { Select } from 'antd';
import SvgArrowDown from '../../../../assets/icons/js/ArrowDown';
import { SearchTypeEnum } from '../../pages/search-results/models/search-type.enum';
import homeApi from "../../api/home-api";
import { useQuery } from "react-query";
import { ITag } from "../../../../_shared/model/tag.interface";
import SvgLabel from "../../../../assets/icons/js/label";

export function Search(props: {
  searchChanged: (params: ISearchData) => void,
  searchData?: ISearchData,
  home?: boolean,
  matchTag?: ITag
}): JSX.Element {
  const divRef: React.RefObject<HTMLDivElement> = React.createRef()
  const location = useLocation();
  const urlParam: ISearchData = qs.parse(location?.search);

  const [searchText, setSearchText] = useState<string>(props?.searchData?.search ? props.searchData.search : urlParam.search ? urlParam.search : '');
  const [searchType, setSearchType] = useState<SearchTypeEnum>(props?.searchData?.searchType ? props.searchData.searchType : urlParam.searchType ? urlParam.searchType : SearchTypeEnum.ANY_WORD)
  const [data, setData] = useState<ISearchData | undefined>(props?.searchData);

  const misspellData = useQuery(
    ['misspellData', data?.search],
    () => homeApi.checkForMisspell(data?.search),
    {
      enabled: !!data?.search && !props.home,
    }
  );

  useEffect(() => {
    if (data) {
      props.searchChanged(data);
    }
  }, [data]);

  function correctWord(word: string) {
    setData({...data, search: word})
    setSearchText(word)
  }

  function search(event: any): void {
    if (event.key === "Enter" || event === 'Enter') {
      event.key === "Enter" && event.preventDefault();

      const sT: string = event?.target?.value.length ? event.target.value : searchText;

      const tData: ISearchData = {...data}

      if (sT) {
        tData['search'] = sT;
      }

      setData({...tData, enter: true, searchType: searchType})
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);

    const sT: string = event.target.value;

    const tData: ISearchData = {...data}

    tData['search'] = sT

    setData({...tData, searchType: searchType})
  }

  function filtersChanged(data: ISearchData): void {
    setData({search: searchText, searchType: searchType, ...data});
  }

  return (
    <div ref={divRef} className="home-search-container" style={props?.home ? {width: '100%'} : {}}>
      <div className="search-wrapper row" style={{marginBottom: props?.home ? 30 : 10}}>
        <div className="search-wrapper-input fxFlex row">
          {
            !!props.matchTag ?
              <div className="w-100 relative ">
                <div className="absolute" style={{left: 10, top: 15}}>
                  <SvgLabel/>
                </div>
                <input style={{paddingLeft: 34}} readOnly={true} type="text" className="insight-search"
                       value={props.matchTag?.name}/>
              </div> :
              <>
                <DebounceInput
                  name="theme"
                  autoComplete="off"
                  className="insight-search"
                  placeholder="Search for projects, insights, surveys, knowledge graphs, snapshots, stories, files and users..."
                  debounceTimeout={500}
                  value={searchText}
                  onKeyDown={(e: any) => search(e)}
                  onChange={onChange}/>
                {
                  props.home ?
                    <button className="icon-button search-icon" style={{top: 6, left: 12}}
                            onClick={() => search('Enter')}>
                      <SvgSearch color={'#9B9B9B'} width={16} height={16}/>
                    </button> :
                    <button className="icon-button search-icon" style={{top: 12, right: 12}}
                            onClick={() => search('Enter')}>
                      <SvgSearch width={24} height={24}/>
                    </button>
                }
              </>
          }

        </div>
        {
          !(props?.home || !!props?.matchTag) &&
            <div className='words-dropdown'>
                <Select
                    style={{minWidth: 240}}
                    suffixIcon={<SvgArrowDown/>}
                    value={searchType}
                    onChange={(val: any) => {
                      setData({...data, searchType: val})
                      setSearchType(val)
                    }}
                    placeholder="Has any of the words"
                >
                    <Select.Option value={SearchTypeEnum.ANY_WORD} key={0}>
                        Has any of the words
                    </Select.Option>
                    <Select.Option value={SearchTypeEnum.EXACT} key={1}>
                        Exact phrase
                    </Select.Option>
                </Select>
            </div>
        }
      </div>
      <>
        {
          !props?.home && misspellData.data?.misspelled && data?.search &&
            <div className="misspelled-search-container">
              {
                misspellData?.data.suggestions.length ?
                  <div className="misspelled-did-you-mean">
                    Did you mean:
                  </div> :
                  <div className="misspelled-did-you-mean">
                    No suggestion found for this word/sentence
                  </div>
              }

                <div className="misspelled-suggestions-list">
                  {misspellData.data.suggestions.map((word, i) => {
                    return <span key={i} onClick={() => correctWord(word)}>{word}</span>
                  })}
                </div>
            </div>
        }
        {
          !props?.home &&
            <SearchFilters searchData={props.searchData} isMatchTagSearch={!!props.matchTag}
                           filtersChanged={filtersChanged}/>
        }
      </>
    </div>
  );
}
