import React, { memo, useState } from 'react';
import './read-knowledge-graph.scss';
import SvgArrowDownNoFill from '../../../assets/icons/js/ArrowDownNoFill';
import SvgArrowUpNoFill from '../../../assets/icons/js/ArrowUpNoFill';
import { IKnowledgeNode } from "../../../pages/knowledge/model/knowledge-node.interface";
import ReadKnowledgeNode from "./read-knowledge-node/read-knowledge-node";

function ReadKnowledgeGraph(props: any): JSX.Element {
  const [graph, setGraph] = useState<IKnowledgeNode>(props.graph);
  const [collapsed, setCollapsed] = useState<boolean>(true);

  function toggleCollapsed(): void {
    setCollapsed(!collapsed);
  }

  return (
    <div className="knowledge-node" style={{minHeight: collapsed ? 0 : 250}} key={props?.graphId}>
      <div className="knowledge-node-header">
        <div className='row'>
          <div onClick={() => toggleCollapsed()} className='pointer toggle-collapse relative'>
            {
              collapsed ?
                <SvgArrowDownNoFill color="#545e6b" style={{position: "relative", top: 2, left: 1}}/> :
                <SvgArrowUpNoFill color="#545e6b" style={{position: "relative", top: 1}}/>
            }
          </div>
          <div className='knowledge-node-header-name'>
            {graph.name}
          </div>
        </div>

      </div>
      {/* <div className="hr" /> */}
      <div className="knowledge-node-body-wrapper">


        {
          !collapsed &&
          <div
            style={{zIndex: 2}}
            className={
              graph.nodes.length === 0 ?
                "parent-node parent-node-has-0-children" : graph.nodes.length === 1 ?
                  "parent-node parent-node-has-1-children" : "parent-node parent-node-has-2-more-children"}>{graph.name}
            <div className='draw-line' style={{zIndex: 1}}/>
          </div>
        }
        {
          !collapsed &&
          <div className="knowledge-node-body">
            {graph.nodes && graph.nodes.map((node: any, i: number) => {
              return (
                <ReadKnowledgeNode key={i}
                                   index={i}
                                   graphId={props.graphId}
                                   mainNode={node}
                                   node={node}
                                   isParent={true}
                                   isSubNode={false}
                                   isNodeSelected={props.isNodeSelected}
                                   addRemoveNode={props.addRemoveNode}
                                   selectedNodes={props.selectedNodes}
                                   isSelected={props.isNodeSelected(node.id)}
                />
              )
            })}
          </div>
        }
      </div>

    </div>
  );
}

export default memo(ReadKnowledgeGraph)
