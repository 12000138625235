import { Empty } from 'antd';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import { ISearchNuggetData } from "../../models/search-nugget";
import { UseQueryResult } from "react-query";
import SearchKnowledgeGraphCard from "./search-knowledge-graph-card/search-knowledge-graph-card";

export default function SearchKnowledgeGraphs(props: {
  searchKnowledgeGraphs: UseQueryResult<any, unknown>
}): JSX.Element {
  const searchKnowledgeGraphs = props.searchKnowledgeGraphs;

  return <>
    {!searchKnowledgeGraphs?.isLoading ?
      <div className='column' style={{gap: 10, marginBottom: 20}}>
        {searchKnowledgeGraphs?.data?.nodes?.length ? searchKnowledgeGraphs?.data.nodes.map((node: any, i: number) => {
            return (
              <SearchKnowledgeGraphCard key={i}
                                        node={node}/>
            )
          })
          :
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }
      </div>
      :
      <>
        <Loader/>
      </>
    }
  </>
}
