import React from 'react';

const SvgTitle = (props: any) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.47813 0.943999L8.25413 2.624H5.37413V12H3.24613V2.624H0.270125V0.943999H8.47813ZM14.0019 11.6C13.7032 11.8133 13.3672 11.9733 12.9939 12.08C12.6312 12.1867 12.2579 12.24 11.8739 12.24C11.0419 12.2293 10.4072 11.9947 9.96988 11.536C9.54321 11.0667 9.32988 10.3787 9.32988 9.472V5.008H8.00188V3.536H9.32988V1.664L11.3779 1.424V3.536H13.3459L13.1379 5.008H11.3779V9.424C11.3779 9.84 11.4472 10.1387 11.5859 10.32C11.7352 10.5013 11.9645 10.592 12.2739 10.592C12.5832 10.592 12.9192 10.4907 13.2819 10.288L14.0019 11.6Z"
        fill="#545E6B"/>
    </svg>
  );
};

export default SvgTitle;
