import React from 'react';
import { MainFormTypeEnum } from '../../../../settings/settings-tabs/taxonomy/project-type/model/main-form-type.enum';

export class WidgetInput extends React.Component<any, any> {

  setTextAreaValue = (e: any) => {
    const value = e.target.value;
    this.props.getTextAreaValue({
      index: this.props.index,
      type: MainFormTypeEnum.TEXT_INPUT,
      value: value
    });
  }

  render() {
    return (
      <div className="outline-input-wrapper">
        <textarea rows={5} value={this.props?.value}
                  className="outline-input"
                  onChange={(e) => this.setTextAreaValue(e)}/>
      </div>
    );
  }
}
