import { Modal } from 'antd'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import "./hyper-link-modal.scss"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from '@hookform/error-message';

const HyperLinkModal = (props: any) => {
  const schema = yup.object({
    url: yup.string()
      .url('Invalid URL')
      .required('URL is required'),
  });
  const {handleSubmit, register, formState: {errors}} = useForm({
    defaultValues: {
      url: props.data?.url ?? ''
    },
    resolver: yupResolver(schema)
  })
  const onSave = (data: { url: string }) => {
    props.onConfirm({
      url: data.url,
      entityKey: props.data?.entityKey,
      entityMapId: props.data?.entityMapId
    });
  }

  return (
    <Modal
      onCancel={props.onCancel}
      onOk={handleSubmit(onSave)}
      okText="Save"
      open={props.data.visible}
      width={600}
      cancelButtonProps={{type: "link"}}
      title="Add Hyperlink">
      <div className='hyperlink-wrapper'>
        <div className="outline-input-wrapper">
          <input
            type="text"
            className='outline-input'
            placeholder='Paste URL'
            {...register('url')}
          />
          <ErrorMessage errors={errors} name={'url'} render={({message}) => <small
            className="form-error">{message}</small>}/>
        </div>
      </div>
    </Modal>
  )
}

export default HyperLinkModal
