import React, { useEffect, useState } from 'react';
import './import-survey-dialog.scss'
import { Empty, Modal, Table, Tabs } from "antd";
import { DebounceInput } from "react-debounce-input";
import OperationsApi from "../../../projects/project-edit/tabs/operations/api/operations-api";
import TabPane = Tabs.TabPane;
import { ISurvey } from "../../../projects/project-edit/tabs/operations/model/survey.interface";
import { AxiosResponse } from "axios";
import { ColumnsType } from "antd/lib/table";
import { Loader } from "../../../../_shared/components/loader/loader";
import Pagination from "../../../../_shared/components/pagination/pagination";

type ImportSurveyDialogProps = {
  data: { open: boolean }
  onCancel: () => void,
  onConfirm: (survey: ISurvey) => void
}
const ImportSurveyDialog = (props: ImportSurveyDialogProps) => {
  const surveysColumns: ColumnsType<any> = [
    {
      title: 'Related Services',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Started',
      dataIndex: 'started',
      key: 'started'
    },
    {
      title: 'Completed',
      dataIndex: 'completedResponses',
      key: 'completedResponses'
    },
  ];


  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedSurvey(selectedRows[0])
    },
  };


  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  let searchText: string = '';

  const [loader, setLoader] = useState<boolean>(true);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [surveysLoading, setSurveysLoading] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<any>();
  const [selectedSurvey, setSelectedSurvey] = useState<any | undefined>(undefined);
  const [folders, setFolders] = useState<any[]>([]);
  const [folderId, setFolderId] = useState<number | undefined>(undefined);


  useEffect(() => {
    loadPage()
  }, []);

  useEffect(() => {
    if (folderId) {
      loadSurveys();
    }
  }, [folderId, page, pageSize]);


  function onFolderChange(idString: string): void {
    if (idString !== 'null') {
      setFolderId(parseInt(idString));
    }
  }

  function handlePaginationChange(currentPage: number, currentPageSize: number): void {
    setPage(currentPage);
    setPageSize(currentPageSize);
  }

  function search(value: string): void {
    searchText = value;
    if (value.length) {
      searchSurveys();
      return;
    }

    loadSurveys();
  }

  function searchSurveys(): void {
    setSurveysLoading(true);

    const data: { page: number, size: number, search: string } = {
      page: page,
      size: pageSize,
      search: searchText
    };

    OperationsApi.searchSurveys(data.page, data.size, data.search)
      .then((response: AxiosResponse<any>) => {
        setSurveys(response);
        setLoader(false);
        setSurveysLoading(false);
      });
  }

  function loadSurveys(): void {
    setSurveysLoading(true);

    const data: { page: number, size: number, search: string } = {
      page,
      size: pageSize,
      search: searchText
    };

    OperationsApi.loadSurveyFolder(folderId, data.page, data.size)
      .then((response: any) => {
        setSurveys(response);
        setLoader(false);
        setSurveysLoading(false);
      });
  }

  function loadPage(): void {
    OperationsApi.loadFolders()
      .then((response: any[]) => {
        if (response?.length) {
          setFolderId(response[0]?.folderId);
          setFolders(response);
        } else {
          setFolders([{folderId: null, folderName: "My Surveys"}]);
        }
      }, () => {
        setLoader(false);
        setFolders([]);
      });
  }

  return (
    <Modal open={props.data.open}
           title="Import Survey"
           className={"survey-dialog"}
           onOk={(e) => props.onConfirm(selectedSurvey)}
           okText={"Import Survey"}
           okButtonProps={{disabled: !selectedSurvey}}
           cancelButtonProps={{type: 'link'}}
           width={920}
           onCancel={props.onCancel}
    >
      <div data-testid="import-survey-modal">
        {
          loader ? <Loader/> :
            <>
              {!folders.length ?
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                :
                <Tabs type="card" tabPosition={'left'} onChange={(id: any) => id && onFolderChange(id)}>
                  {folders.map((folder: any) => {
                    return (
                      <TabPane tab={folder.folderName} key={folder.folderId}>
                        <div className="row justify-space-between" style={{margin: '10px 0'}}>
                          <div className="outline-input-wrapper">
                            <DebounceInput
                              className="outline-input"
                              placeholder='Search...'
                              debounceTimeout={600}
                              value=''
                              onChange={e => search(e.target.value)}/>
                          </div>
                          <Pagination totalItems={surveys.pagination.totalElements}
                                      showSizeChanger
                                      currentPage={page}
                                      pageSize={pageSize} onChange={handlePaginationChange}/>
                        </div>
                        <Table
                          rowSelection={{
                            type: 'radio',
                            ...rowSelection,
                          }}
                          loading={surveysLoading}
                          pagination={false}
                          columns={surveysColumns}
                          className="w-100"
                          dataSource={surveys?.content}
                          rowKey="surveyId"/>

                      </TabPane>
                    )
                  })}
                </Tabs>
              }
            </>
        }
      </div>
    </Modal>
  );
};

export default ImportSurveyDialog;
