import React, { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import RepositoriesApi from "../api/repositories-api";
import SvgArrowBack from "../../../assets/icons/js/ArrowBack";
import Pagination from "../../../_shared/components/pagination/pagination";
import { message, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import SvgRestoreTrash from "../../../assets/icons/js/RestoreTrash";
import { IRepoFile } from "../model/repo-file.interface";
import FileTypePipe from "../../../_shared/helpers/file-type-pipe";
import { FileTypeEnum } from "../../projects/tabs/_shared/model/file-type.enum";
import DaysLeftDatePipe from "../../../_shared/helpers/days-left-date-pipe";
import repositoriesApi from "../api/repositories-api";
import SvgTick from "../../../assets/icons/js/Tick";

const RepositoryEditArchived = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const navigate = useNavigate();
  const page = parseInt(searchParams.get("page") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || "10");
  const id = Number(searchParams.get("id"));
  const columns: ColumnsType<any> = [
    {
      title: selectedRows.length ?
        <div className='selected-rows-title-wrapper'>
          <span>{selectedRows.length} selected</span>
          <div data-testid={"bulk-restore"} className='selected-rows-btn' onClick={bulkRestoreFiles}>
            <SvgRestoreTrash color={'#1b87e6'}/>
            &nbsp; Restore
          </div>
          {/*<div className="selected-rows-btn">*/}
          {/*  <SvgDelete color={"#1b87e6"}/>*/}
          {/*  Delete permanently*/}
          {/*</div>*/}
        </div> : "Name"
      ,
      dataIndex: 'file',
      render: (file: IRepoFile) => {
        const nameArr = file.name.split(".");
        return <div className="row align-items-center" style={{gap: 10}}>
          <span> <FileTypePipe width={20} height={20} value={nameArr[nameArr.length - 1] as FileTypeEnum}/> </span>
          <span style={{wordBreak: "break-word"}}>{file.name}</span>
        </div>
      }
    },
    {
      title: <span className='column-title-to-be-hided'>Owner</span>,
      dataIndex: 'owner',
      width: '30%',
      render: (value: any) =>
        <span>{value?.firstName ? value.firstName + " " + value?.lastName : value.emailAddress}</span>,
    },
    {
      title: <span className='column-title-to-be-hided'>Archived on</span>,
      dataIndex: 'archivedAt',
      //sorter: !selectedRows.length,
      width: '10%',
      render: (archivedAt: any) => <span>{archivedAt && DaysLeftDatePipe(archivedAt)}</span>,
    },
    {
      title: "",
      dataIndex: "id",
      key: "x",
      width: "5%",
      align: "right",
      render: (id: number, record: any) => (
        <div className="action-hover-column row">
          <div className="action-buttons row">
            <Tooltip placement="bottom" title="Restore repository">
              <button className="icon-button project-icon-button" onClick={() => restoreFile(record.file.id)}>
                <SvgRestoreTrash/>
              </button>
            </Tooltip>
            {/*<Tooltip placement="bottom" title="Delete repository">*/}
            {/*  <button className="icon-button project-icon-button">*/}
            {/*    <SvgDelete/>*/}
            {/*  </button>*/}
            {/*</Tooltip>*/}
          </div>
        </div>
      )
    }
  ]

  const archivedFiles = useQuery(["archivedFiles", id, page, pageSize], () => RepositoriesApi.getArchivedRepositoryFiles(id, {
    page,
    pageSize
  }))

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
  };


  const handlePaginationChange = (page: number, pageSize: number) => {
    setSearchParams(prevParams => {
      prevParams.set('page', page.toString());
      prevParams.set('pageSize', pageSize.toString());
      return prevParams;
    })
  }

  function restoreFile(fileId: number) {
    repositoriesApi.bulkRestoreFiles(id, [fileId]).then(() => {
      message.success({
        content: 'file restored successfully',
        icon: <SvgTick color={"#227700"}/>,
        className: 'custom-success-message'
      });
      archivedFiles.refetch();
    })
  }

  function bulkRestoreFiles() {
    repositoriesApi.bulkRestoreFiles(id, selectedRows.map((file) => file.file.id)).then(() => {
      message.success({
        content: 'Files restored successfully',
        icon: <SvgTick color={"#227700"}/>,
        className: 'custom-success-message'
      })
      archivedFiles.refetch();
      setSelectedRows([])
    })
  }

  return (
    <div className='file-repository-archived-wrapper'>
      <div className='file-repository-archived-title row align-items-center' style={{marginBottom: 10}}>
        <button className='icon-button' onClick={() => navigate(-1)}>
          <SvgArrowBack/>
        </button>
        <h2>Deleted files</h2>
      </div>
      <div className='row justify-space-end' style={{marginBottom: 20}}>
        {archivedFiles.data && archivedFiles.data.pagination?.totalElements > 0 &&
          <Pagination totalItems={archivedFiles.data.pagination.totalElements}
                      currentPage={page}
                      pageSize={pageSize}
                      showSizeChanger={true}
                      onChange={handlePaginationChange}/>
        }
      </div>
      <div
        className={selectedRows.length ? 'file-repository-archived-table file-repository-archived-table-selected-rows' : 'file-repository-archived-table'}>
        <Table
          pagination={false}
          rowKey={"id"}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          loading={archivedFiles.isLoading}
          dataSource={archivedFiles?.data ? archivedFiles?.data.content : []}
          columns={columns}/>
      </div>
    </div>
  );
};

export default RepositoryEditArchived;
