export function isGuide(isGuide: boolean, textEditor: any): any {
  if (isGuide && textEditor) {
    textEditor.getElementsByClassName('public-DraftEditor-content')[0].style.minHeight = 'auto';
    textEditor.getElementsByClassName('DraftEditor-root')[0].style.backgroundColor = 'transparent';
    textEditor.getElementsByClassName('DraftEditor-root')[0].style.borderRight = '0';
    textEditor.getElementsByClassName('DraftEditor-editorContainer')[0].style.backgroundColor = 'transparent';
    textEditor.getElementsByClassName('DraftEditor-editorContainer')[0].style.borderRight = '0';
    textEditor.getElementsByClassName('text-editor-toolbar')[0].style.minHeight = '0';

    return textEditor;
  }
}
