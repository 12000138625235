import React from 'react';
import TextFit from "../../../../../../_shared/components/text-fit/text-fit";
import SvgQpLogoIH from "../../../../../../assets/icons/js/qp-logo-ih";
import { ICustomTheme } from "../interface/custom-theme.interface";
import { format } from "date-fns";

const BottomTheme = ({nugget, snapshotTheme, project, createdAt, createdBy}: ICustomTheme) => {
  return (
    <div className="relative w-100 h-100">
      <div className="absolute flex justify-space-between" style={{
        top: '10px',
        left: '10px',
        right: '10px',
      }}>
        <div className="logo-img-wrapper align-self-start">
          {snapshotTheme?.logoUrl && (
            <img src={snapshotTheme.logoUrl} alt="Logo" style={{height: '48px'}}/>
          )}
        </div>
        <SvgQpLogoIH/>
      </div>

      <div className="absolute" style={{
        bottom: '60px',
        left: '10px',
        right: '10px',
        height: '150px'
      }}>
        <TextFit
          mode="multi"
          min={12}
          max={56}
          style={{
            height: '100%',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '500',
          }}>
          {nugget}
        </TextFit>
      </div>
      <div className="project-details-wrapper absolute w-full" style={{bottom: 0, textAlign: "center", width: "100%"}}>
        {
          snapshotTheme.showDetails &&
          <>
            {project}
            <div style={{fontWeight: 500}}>
              - {createdBy.displayName} , {format(new Date(createdAt), 'MMMM yyyy')}
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default BottomTheme;
