import { environment } from '../../../../../../environment';
import { axiosClient } from '../../../../../../axios-client';
import { AxiosResponse } from 'axios';
import { ISurveyResponse } from "../model/survey.interface";
import { IExternalTool } from "../model/external-tool.interface";
import { LinkExternalToolPayload } from "../components/link-tool-dialog/link-tool-dialog";
import { IPagination } from "../../../../../../core/model/pagination.interface";

class OperationsApi {
  baseUrl: string = `${environment.backendUrl}`;

  async unlinkSurvey(projectId: number, surveyId: any): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.delete(`${environment.backendUrl}/survey/${projectId}/${surveyId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async loadSurveys(projectId: number): Promise<ISurveyResponse> {
    try {
      const response: AxiosResponse<ISurveyResponse> =
        await axiosClient.get<ISurveyResponse>(environment.backendUrl + '/survey/project/' + projectId)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addLinkedSurvey(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.put(environment.backendUrl + '/survey', payload)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async editLinkedSurvey(projectId: number, surveyId: number, payload: { description: string }): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.put(environment.backendUrl + `/survey/${projectId}/${surveyId}`, payload)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async aiSummary(projectId: any, surveyId: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(`/survey/details/${projectId}/${surveyId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async aiSummaryPrompt(data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post(`/survey/prompt`, {...data})
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async searchSurveys(page: any, pageSize: any, pageSearch: any): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.get(`${environment.backendUrl}/survey?page=${page.page}&pageSize=${pageSize}&search=${pageSearch}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async loadFolders(): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> =
        await axiosClient.get(environment.backendUrl + '/survey/folders')
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async loadSurveyFolder(folderId: any, page: any, pageSize: any, projectId?: number): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.get(`${environment.backendUrl}/survey/folder${folderId !== undefined ? '/' + folderId : ''}${projectId ? `/${projectId}` : ''}?page=${page}&pageSize=${pageSize}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSurveyQuestions(surveyId: string): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.get(`${environment.backendUrl}/survey/question/${surveyId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getQuestionDetails(surveyId: string, questionId: string): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.get(`${environment.backendUrl}/survey/question/results/${surveyId}/${questionId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async newSurvey(projectId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.post(environment.backendUrl + '/survey', {projectId})
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addExternalTool(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.post(environment.backendUrl + '/projects/external-tools', payload)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateExternalTool(externalToolId: number, payload: LinkExternalToolPayload): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.put(environment.backendUrl + `/projects/external-tools/${externalToolId}`, payload)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getExternalTools(projectId: number): Promise<IExternalTool[]> {
    try {
      const response: AxiosResponse<IExternalTool[]> =
        await axiosClient.get<IExternalTool[]>(environment.backendUrl + `/projects/external-tools/${projectId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getExternalToolsPaginated(projectId: number, params: { page: number, pageSize: number }): Promise<{
    content: IExternalTool[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<{ content: IExternalTool[], pagination: IPagination }> =
        await axiosClient.get<{
          content: IExternalTool[],
          pagination: IPagination
        }>(environment.backendUrl + `/projects/external-tools/${projectId}/paginated`, {
          params
        })
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteExternalTool(surveyId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.delete(environment.backendUrl + `/projects/external-tools/${surveyId}`)
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new OperationsApi();
