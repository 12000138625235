import React from 'react';
import './upload.scss';
import SvgUploadFile from '../../../../../../../../assets/icons/js/UploadFile';

const Upload = () => {
  return (
    <div className="input column">
      <div className="form-input-text-area">
        <span className="form-input-text-area-title">Please upload a file:</span>
        <div className="upload-file">
          <SvgUploadFile width="30"/>
          <div>
            <span>Drag your file here or </span>
            <span style={{color: '#1B87E6'}}>browse</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Upload;
