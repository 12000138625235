import React, { useEffect, useState } from "react";
import { DraftDecoratorComponentProps } from "draft-js";
import "./preview-project-strategy.scss"

export const PreviewProjectStrategy = (props: DraftDecoratorComponentProps | any) => {
  let data: any;
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const projectCard: HTMLElement | null = document.getElementById(data?.project?.id);
    const linkedProjectWrapper: HTMLElement | null = document.getElementById('linked-project-wrapper');

    if (isHover && projectCard && linkedProjectWrapper) {
      projectCard.style.borderLeftColor = '#1B87E6'
    }

    if (!isHover && projectCard && linkedProjectWrapper) {
      projectCard.style.borderLeftColor = '#9B9B9B'
    }

  }, [isHover]);
  

  if (props.entityKey != null) {
    data = props.contentState.getEntity(props?.entityKey).getData();
  } else {
    return null;
  }


  return (
    <span className="project-link relative"
          id={data?.entityMapId + "-draft"}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}>
      {props.children}
    </span>
  );
};
