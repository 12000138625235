import { notification, Progress, Select, Upload } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import SvgUploadFile from "../../../../../../../assets/icons/js/UploadFile";
import { usePrevious } from "../../../../../../../core/hooks/use-previous";
import { environment } from "../../../../../../../environment";
import "./media.scss";
import { IOwner } from "../../../../../models/owner.interface";
import SvgAlert from "../../../../../../../assets/icons/js/alert";

const {Dragger} = Upload;

const Media = (props: any) => {
  const [update, setUpdate] = useState<boolean>(false);
  const [owners, setOwners] = useState<IOwner[]>([]);
  const [radioValue, setRadioValue] = useState("VIDEO");
  const [displayName, setDisplayName] = useState<string>("");
  const [organizer, setOrganizer] = useState<number>();
  const [description, setDescription] = useState<any>();
  const [title, setTitle] = useState(props?.details.title);
  const [videoUrl, setVideoUrl] = useState<string>();
  const [audioUrl, setAudioUrl] = useState<string>();
  const [percent, setPercent] = useState<number>();
  const [errorVideo, setErrorVideo] = useState<string>("");
  const [errorAudio, setErrorAudio] = useState<string>("");
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [isUploadingAudio, setIsUploadingAudio] = useState(false);
  const [videoTitle, setVideoTitle] = useState();
  const [audioTitle, setAudioTitle] = useState();

  const prevDisplayName = usePrevious<string>(displayName);
  const prevOrganizer = usePrevious<any>(organizer);
  const prevDescription = usePrevious<string>(description);
  const prevVideoUrl = usePrevious<string>(videoUrl);
  const prevAudioUrl = usePrevious<string>(audioUrl);

  useEffect(() => {
    const details = props.details;
    setOwners(props.teamMembers ? props.teamMembers : []);
    setOrganizer(details.organizerId);
    setDisplayName(details.displayName ? details.displayName : "");
    setDescription(details.description ? details.description : "");
    setVideoUrl(details.videoUrl ? details.videoUrl : "");
    setAudioUrl(details.audioUrl ? details.audioUrl : "");
    setVideoTitle(details.videoFileName ? details.videoFileName : "");
    setAudioTitle(details.audioFileName ? details.audioFileName : "");
  }, [props.details]);

  useEffect(() => {
    if (valuesHasChanged() && update) {
      const payload = {
        projectId: props.projectId,
        interviewId: props.interviewId,
        organizerId: organizer,
        description: description,
        displayName: displayName,
        audioUrl: audioUrl,
        videoUrl: videoUrl,
        title: title,
      };
      props.socket.emit("editDetails", {...payload});
      setUpdate(false);
    } else {
      return;
    }
  }, [organizer, description, displayName, audioUrl, videoUrl, title]);

  useEffect(() => {
    if (props.details?.title) {
      const details = props.details;
      setTitle(details.title);
      setUpdate(true);
    }
  }, [props.details?.title]);

  function valuesHasChanged(): boolean {
    return !(
      prevDisplayName === displayName &&
      prevOrganizer === organizer &&
      prevDescription === description &&
      prevAudioUrl === audioUrl &&
      prevVideoUrl === videoUrl
    );
  }

  const beforeUpload = (file: any) => {
    const isValidVideo =
      file.type === "video/mp4" ||
      file.type === "video/mov" ||
      file.type === "video/avi" ||
      file.type === "video/vnd.avi" ||
      file.type === "video/msvideo" ||
      file.type === "video/x-msvideo";
    const isValidAudio =
      file.type === "audio/mpeg" ||
      file.type === "audio/MPA" ||
      file.type === "audio/mpa-robust" ||
      file.type === "audio/vnd.wave" ||
      file.type === "audio/wave" ||
      file.type === "audio/x-wav" ||
      file.type === "audio/wav" ||
      file.type === "audio/m4a";

    if (radioValue === "VIDEO") {
      if (!isValidVideo)
        setErrorVideo("Format Invalid. Allowed file extensions: mp4, mow, avi");
      return isValidVideo;
    } else {
      if (!isValidAudio)
        setErrorAudio("Format Invalid. Allowed file extensions: mp3, wav, m4a");
      return isValidAudio;
    }
  };

  const showSuccessToast = () => {
    notification.open({
      message: "Done",
      description: `Your file has been uploaded successfuly`,
      type: "success",
      className: "infobox-success",
      duration: 3,
      placement: "bottomRight",
    });
  };

  const method: any = "PUT";

  const draggerProps = {
    customRequest: (options: any) => uploadFile(options, radioValue),
    multiple: false,
    method,
    showUploadList: false,
    beforeUpload,
  };

  const uploadFile = async (options: any, type: string) => {
    const {file, onProgress} = options;

    setErrorAudio("");
    setErrorVideo("");
    if (type === "VIDEO") setIsUploadingVideo(true);
    if (type === "AUDIO") setIsUploadingAudio(true);

    let token = localStorage.getItem("access_token");
    const fmData = new FormData();
    fmData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setPercent(percent);
        onProgress({percent: (event.loaded / event.total) * 100});
      },
    };
    try {
      Axios.put(
        `${environment.backendUrl}/interviews/${props.interviewId}/project/${props.projectId}/upload/${type}`,
        fmData,
        config
      ).then((response) => {
        if (type === "VIDEO") setIsUploadingVideo(false);
        if (type === "AUDIO") setIsUploadingAudio(false);

        setVideoUrl(response.data.videoUrl);
        setVideoTitle(response.data.videoFileName);
        setAudioUrl(response.data.audioUrl);
        setAudioTitle(response.data.audioFileName);
        showSuccessToast();

        setUpdate(true);
      });
    } catch (err) {
      //setError("Failed to upload File");
      throw err;
    }
  };

  return (
    <div className="interview-form">
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Media Type</span>
        <div className="fancy-radio-group">
          <div
            style={{
              backgroundColor: radioValue === "VIDEO" ? "#1b87e6" : "#d8d8d8",
              color: radioValue === "VIDEO" ? "white" : "gray",
            }}
            onClick={() => setRadioValue("VIDEO")}
            className="radio-button"
          >
            Video
          </div>
          <div
            style={{
              backgroundColor: radioValue === "AUDIO" ? "#1b87e6" : "#d8d8d8",
              color: radioValue === "AUDIO" ? "white" : "gray",
            }}
            onClick={() => {
              setRadioValue("AUDIO");
            }}
            className="radio-button"
          >
            Audio
          </div>
        </div>
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Media File</span>
        {radioValue === "VIDEO" ? (
          <div>
            {videoUrl ? (
              <div style={{display: 'inline', maxHeight: 195}}>
                <video src={videoUrl} width="100%" height="100%" controls/>
                <p className="video-title"> {videoTitle} </p>
              </div>
            ) : (
              <>
                <Dragger className="dragger" {...draggerProps}>
                  <div className="dragger-wrapper">
                    <SvgUploadFile/>
                    {!isUploadingVideo ? (
                      <p className="dragger-text">
                        Drop your file here or{" "}
                        <span className="link">Browse</span>
                      </p>
                    ) : (
                      <Progress percent={percent} type="line"/>
                    )}
                  </div>
                </Dragger>
                {errorVideo?.length > 0 && (
                  <div className="error error-message">
                    <SvgAlert/> {errorVideo}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            {audioUrl ? (
              <div style={{display: 'inline', maxHeight: 195}}>
                <audio src={audioUrl} controls/>
                <p className="video-title"> {audioTitle} </p>
              </div>
            ) : (
              <>
                <Dragger className="dragger" {...draggerProps}>
                  <div className="dragger-wrapper">
                    <SvgUploadFile/>
                    {!isUploadingAudio ? (
                      <p className="dragger-text">
                        Drop your file here or{" "}
                        <span className="link">Browse</span>
                      </p>
                    ) : (
                      <Progress percent={percent} type="line"/>
                    )}
                  </div>
                </Dragger>
                {errorAudio?.length > 0 && (
                  <div className="error error-message">
                    <SvgAlert/> {errorAudio}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Title</span>
        <DebounceInput
          debounceTimeout={600}
          onChange={(e) => {
            setUpdate(true);
            setDisplayName(e.target.value);
          }}
          value={displayName}
          placeholder="Type Here"
          className="outline-input"
        />
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Organizer</span>
        <Select
          value={organizer}
          onChange={(id: number) => {
            setUpdate(true);
            setOrganizer(id);
          }}
        >
          {owners?.map((owner: any) => {
            return (
              owner.id && (
                <Select.Option key={owner.id} value={owner.id}>
                  {owner?.name}
                </Select.Option>
              )
            );
          })}
        </Select>
      </div>
      <div className="outline-input-wrapper">
        <span className="outline-input-label">Desciption</span>
        <DebounceInput
          debounceTimeout={600}
          onChange={(e) => {
            setUpdate(true);
            setDescription(e.target.value);
          }}
          value={description}
          //maxLength={6}
          element="textarea"
          placeholder="Description"
          className="outline-input"
        />
      </div>
    </div>
  );
};

export default Media;
