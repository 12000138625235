import React from 'react';

const SvgSend = (props: any) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.5025 5.22753C12.1658 5.52899 12.1658 6.47101 11.5025 6.77247L0 12L1.50926 6.77247L6.75 6L1.50926 5.22753L0 0L11.5025 5.22753Z"
            fill={props?.color ?? "white"}/>
    </svg>
  );
};

export default SvgSend;
