import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, InputNumber } from 'antd';
import classes from '../../google-drive-integration/tabs/settings/google-drive-project-import.module.scss';
import FormattedDate from '../../../../../../../_shared/helpers/formatted-date';
import { ExcelEditableContext } from "./excel-editable-row";

export const ExcelEditableCell = (props: any) => {
  const [editing, setEditing] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(true);
  const [openSelect, setOpenSelect] = useState(true);
  const inputRef = useRef<any>(null);
  const form = useContext(ExcelEditableContext);

  useEffect(() => {
    if (editing && inputRef.current) {
      setOpenDatePicker(true);
      setOpenSelect(true);

      // @ts-ignore
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    // @ts-ignore
    form.setFieldsValue({
      [props.dataIndex]: props.record[props.dataIndex],
      dueDate: moment(props.record['dueDate'])
    });

  };


  const save = async () => {
    try {
      // @ts-ignore
      const values = await form.validateFields();

      if (values.dueDate) {
        values.dueDate = moment(values.dueDate, 'MMMM D, YYYY').format();
      }

      toggleEdit();
      setOpenDatePicker(false);
      setOpenSelect(false);
      props.handleSave({...props.record, ...values});
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
      setOpenDatePicker(false);
      setOpenSelect(false);
    }
  };

  let childNode = props.children;

  if (props.editable) {
    let input: JSX.Element = <Input ref={inputRef} onBlur={save} onPressEnter={save}/>;
    let domDisplay = props.children;


    if (props.dataIndex.includes('PROGRAM-')) {
      domDisplay = domDisplay[1];
      input = <InputNumber ref={inputRef} onBlur={save} onPressEnter={save}/>
    }

    if (props.dataIndex.includes('ORG-')) {
      domDisplay = domDisplay[1];
      input = <InputNumber ref={inputRef} onBlur={save} onPressEnter={save}/>
    }

    if (props.dataIndex === 'dueDate') {
      domDisplay = <FormattedDate date={domDisplay[1]} formatString="MMM d, yyyy"/>
      input = <DatePicker className="w-100" ref={inputRef} onClick={() => setOpenDatePicker(true)}
                          onChange={() => setOpenDatePicker(false)} open={openDatePicker} onBlur={save}/>
    }

    if (props.dataIndex === 'description') {
      input = <Input.TextArea autoSize={true} className="w-100"
                              placeholder="description" ref={inputRef} onPressEnter={save} onBlur={save}/>
    }

    childNode = editing ? (
      <Form.Item
        style={{margin: 0}}
        name={props.dataIndex}
        rules={[{required: false, message: `Field is required.`}]}>
        {input}
      </Form.Item>
    ) : (
      <div className={`editable-cell-value-wrap + ${classes.EditableCellValueWrap}`} style={{paddingRight: 24}}
           onClick={toggleEdit}>
        {domDisplay}
      </div>
    );
  }


  return <>
    <td {...props.restProps}>{childNode}</td>
  </>;
};

