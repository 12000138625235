import React from 'react';

const SvgFileCorrupted = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.75 0C1.05964 0 0.5 0.559644 0.5 1.25V7.5L0.503064 8.75C0.5 9.5 0.5 9 0.516359 10L0.5 12.5C0.5 13.1904 0.5 15 0.5 15.5V17.5V18.75C0.5 19.4404 1.05964 20 1.75 20H14.25C14.9404 20 15.5 19.4404 15.5 18.75V7.5L8 0H1.75ZM12.4822 6.25L9.25 3.01777V6.25H12.4822Z"
            fill="#1B3380"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M8 9C10.2091 9 12 10.7909 12 13C12 15.2091 10.2091 17 8 17C5.79086 17 4 15.2091 4 13C4 10.7909 5.79086 9 8 9ZM8 14.5C7.72386 14.5 7.5 14.7239 7.5 15C7.5 15.2761 7.72386 15.5 8 15.5C8.27614 15.5 8.5 15.2761 8.5 15C8.5 14.7239 8.27614 14.5 8 14.5ZM8.48781 13.5V10.5H7.48781L7.48781 13.5H8.48781Z"
            fill="white"/>
    </svg>

  );
};

export default SvgFileCorrupted;
