import React, { useContext } from 'react';
import './add-edit-interview-lab.scss';
import { convertToRaw, EditorState } from 'draft-js';
import { TaskEditor } from './task-editor/task-editor';
import InterviewLabsSettingsContext from '../context/interview-labs-settings.context';
import { Loader } from '../../../../../_shared/components/loader/loader';
import { DraftJs } from '../../../../../_shared/components/draft-js/draft';
import { FlagsSetup } from './flag-setup/flags-setup';

export function AddEditInterviewLab(): JSX.Element {
  const context = useContext(InterviewLabsSettingsContext);

  const updateEditorState = (editorState: EditorState) => {
    context.onSetEditorState(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
  }

  return (
    <div className="add-edit-interview-lab">
      <div style={{marginBottom: 30}}>
        <FlagsSetup/>
      </div>
      {context?.loader ? <Loader/> :
        <div className="content">
          <div className="guide-wrapper">
            <h2>Moderator guide</h2>
            <span>Create guidelines for your interview moderators.</span>
            <div className="text-editor">
              <DraftJs disableNodes={true}
                       updateEditorState={updateEditorState}
                       content={context.content}/>
            </div>
          </div>
          <div className="tasks-wrapper">
            <h2>Interview tasks</h2>
            <span>Add a checklist of action items for your interviews.</span>
            <div className="tasks-editor">
              <span style={{marginBottom: 10}}>Task List</span>
              <TaskEditor tasks={context.tasks}/>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
