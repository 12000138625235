import React from 'react';

const SvgThemeDefaultLayout = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M-6.10352e-05 1.99988C-6.10352e-05 0.895309 0.895369 -0.00012207 1.99994 -0.00012207H13.9999C15.1045 -0.00012207 15.9999 0.895308 15.9999 1.99988V9.99988C15.9999 11.1044 15.1045 11.9999 13.9999 11.9999H1.99994C0.895369 11.9999 -6.10352e-05 11.1044 -6.10352e-05 9.99988V1.99988ZM1.99994 1.99988H13.9999V9.99988H1.99994V1.99988ZM1.00001 14C0.447726 14 1.02698e-05 14.4477 1.02698e-05 15C1.02698e-05 15.5523 0.447726 16 1.00001 16H15C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14H1.00001Z"
            fill="#545E6B"/>
    </svg>
  );
};

export default SvgThemeDefaultLayout;
