import React, { useState } from 'react';
import { useQuery } from "react-query";
import StoriesApi from "../../../api/stories-api";
import SvgArrowBack from "../../../../../assets/icons/js/ArrowBack";
import SvgNugget from "../../../../../assets/icons/js/nugget";
import Pagination from "../../../../../_shared/components/pagination/pagination";
import AiUserAvatar from "../../../../../_shared/components/ai-user-avatar/ai-user-avatar";
import "./linked-key-findings.scss";
import UserAvatar from "../../../../../_shared/components/user-avatar/user-avatar";
import { INugget } from "../../../../projects/models/nugget.interface";
import { Empty } from "antd";
import { NormalLoader } from "../../../../../_shared/components/normal-loader/normal-loader";

type LinkedKeyFindingsViewProps = {
  projectId: number;
  projectName: string;
  closeLinkedKeyFindingsView: () => void;
}
const LinkedKeyFindingsView = ({projectId, projectName, closeLinkedKeyFindingsView}: LinkedKeyFindingsViewProps) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const linkedKeyFindings = useQuery(["linkedKeyFindings", projectId, page, pageSize], () => StoriesApi.getLinkedKeyFindings(projectId, page, pageSize));

  const onChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  return (
    <div className="linked-key-findings-wrapper">
      <div className="row align-items-center" style={{gap: 10}}>
        <button onClick={closeLinkedKeyFindingsView} className="icon-button"><SvgArrowBack width={16} height={16}/>
        </button>
        <h2>{projectName}</h2>
      </div>

        <div>
          <div className="row justify-space-between align-items-center">
            <div className="row align-items-center" style={{gap: 8}}>
              <SvgNugget width={21} height={24}/>
              <h3>Key Findings</h3>
            </div>
            {
              linkedKeyFindings.data && linkedKeyFindings.data?.pagination.totalElements > 0 &&
              <Pagination totalItems={linkedKeyFindings.data?.pagination.totalElements}
                          currentPage={page}
                          pageSize={pageSize}
                          showSizeChanger
                          onChange={onChange}/>
            }
          </div>
          <div className="linked-key-findings">
            {
              linkedKeyFindings.isLoading &&
              <div className={"w-100 justify-space-center"}>
                <NormalLoader/>
              </div>

            }
            {
              linkedKeyFindings.data && linkedKeyFindings.data.pagination.totalElements === 0 &&
              <div>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
              </div>
            }
            {
              linkedKeyFindings.data && linkedKeyFindings.data.content.map((nugget: INugget, i: number) => {
                return (
                  <div className="linked-key-finding-card" key={i}>
                    <p>{nugget.description}</p>
                    <div className='nugget-creator row align-items-center'>
                      {nugget.aiGenerated ? <AiUserAvatar size="sm"/> :
                        <UserAvatar size={24} user={nugget.createdBy}/>} &nbsp; &nbsp;
                      {nugget.aiGenerated ?
                        <span>Ask AI
                          {
                            nugget.lastUpdatedBy && <span>
                              <span> - Last edited by </span>
                              <span style={{fontWeight: 400}}>{nugget.lastUpdatedBy}</span>
                            </span>
                          }
                        </span>
                        : nugget.createdBy?.displayName}

                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
    </div>
  );
};

export default LinkedKeyFindingsView;
