import React from 'react';

const SvgExit = (props: any) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6 14.5C6 15.0523 6.44772 15.5 7 15.5H13C14.0544 15.5 14.9182 14.6841 14.9945 13.6493L15 13.5V3.5C15 2.39543 14.1046 1.5 13 1.5H7C6.44772 1.5 6 1.94772 6 2.5C6 3.05228 6.44772 3.5 7 3.5H13V13.5H7C6.44772 13.5 6 13.9477 6 14.5ZM11 8.5L7 11.5V9.5H1V7.5H7V5.5L11 8.5Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>

  );
};

export default SvgExit;
