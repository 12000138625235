import React, { useEffect } from 'react';
import './program-metric-input.scss'
import { Select } from 'antd';
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ProgramMetricTypeEnum } from "../../../model/program-metric-type.enum";
import SvgDelete from "../../../../../../../../assets/icons/js/delete";
import SvgArrowDown from "../../../../../../../../assets/icons/js/ArrowDown";
import CalculatedFormulaEditor from "./calculated-formula-editor/calculated-formula-editor";
import { validateMathFormula } from "../../../../../../../../_shared/helpers/validate-math-formula";

export function ProgramMetricInput(props: {
  index: number;
  groupIndex: number;
  removeMetric: (index: number) => void;
}): JSX.Element {
  const {control, register, watch, formState, clearErrors} = useFormContext()
  const {errors} = formState;
  const {index, groupIndex, removeMetric} = props;

  const formIndexedName = `groups.${groupIndex}.metrics.${index}`;
  const isCalculatedMetric = watch(`${formIndexedName}.type`) === ProgramMetricTypeEnum.CALCULATED;

  const nameValue = useWatch({control, name: `${formIndexedName}.name`});
  const typeValue = useWatch({control, name: `${formIndexedName}.type`});

  useEffect(() => {
    if (nameValue.length) {
      //TODO: format the metric code based on metric name
    }
  }, [nameValue]);


  return (
    <div className="program-metric-input w-100" style={{marginBottom: 20, border: '2px solid #f5f5f5', padding: 10}}>
      <div className="">
        <div className="row align-items-start">
          <div style={{width: '80%'}}>
            <div className="outline-input-wrapper">
              <span className="outline-input-label">Metric Name</span>
              <input
                className="outline-input"
                placeholder="Metric Name..."
                {...register(`${formIndexedName}.name`)}
              />
            </div>
            <ErrorMessage errors={errors} name={`${formIndexedName}.name`} render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>

          <div style={{width: '80%'}}>
            <div className="outline-input-wrapper">
              <span className="outline-input-label">Code</span>
              <input
                className="outline-input"
                placeholder="Code used for calculations"
                {...register(`${formIndexedName}.code`, {
                  required: "Metric code is required!", onChange: () => {
                    const {groups} = errors || {};
                    const faultyMetrics = groups?.[groupIndex]?.faultyMetrics;

                    if (faultyMetrics) {
                      clearErrors(`groups.${groupIndex}.faultyMetrics`);
                    }
                  }
                })}
              />
            </div>
            <ErrorMessage errors={errors} name={`${formIndexedName}.code`} render={({message}) => <small
              className="form-error">{message}</small>}/>
          </div>
          <div>
            <div className="outline-input-wrapper">
              <span className="outline-input-label">Type</span>
              <Controller control={control} render={({field}) => (
                <Select {...field} style={{width: 112}} suffixIcon={<SvgArrowDown/>}>
                  <Select.Option value={ProgramMetricTypeEnum.NUMERIC}>{ProgramMetricTypeEnum.NUMERIC}</Select.Option>
                  <Select.Option
                    value={ProgramMetricTypeEnum.PERCENTAGE}>{ProgramMetricTypeEnum.PERCENTAGE}</Select.Option>
                  <Select.Option
                    value={ProgramMetricTypeEnum.CALCULATED}>{ProgramMetricTypeEnum.CALCULATED}</Select.Option>
                </Select>
              )} name={`${formIndexedName}.type`}/>
            </div>
          </div>
          <div className="metric-input-options">
            <div className="option" onClick={() => {
              removeMetric(index)
            }}>
              <SvgDelete color="#F5F5F5"/>
            </div>
          </div>
        </div>
        {
          isCalculatedMetric &&
            <div style={{marginTop: 10, width: '50%'}}>
                <div className="outline-input-wrapper">
                    <div className="outline-input-label">Calculated Formula</div>
                    <Controller name={`${formIndexedName}.calculationFormula`}
                                rules={{
                                  validate: (value) => {
                                    const isValid = validateMathFormula(value);
                                    if (!isValid) {
                                      return "Formula being used is not valid!"
                                    }
                                    return true
                                  }
                                }}
                                render={({field}) => {
                                  return <CalculatedFormulaEditor
                                    index={index}
                                    groupIndex={groupIndex}
                                    onChange={field.onChange}
                                    value={field.value}/>
                                }
                                }/>
                </div>
                <ErrorMessage errors={errors}
                              name={`${formIndexedName}.calculationFormula`}
                              render={({message}) => <small className="form-error">{message}</small>}/>
            </div>

        }
      </div>
    </div>
  )

}
