import { BlockMap, CharacterMetadata, EditorState } from "draft-js";

export function manageHighlights(editorState: EditorState): void {
  const contentState = editorState.getCurrentContent();
  const blockMap: BlockMap = contentState.getBlockMap();

  blockMap.map((block: any) => {
    block.findEntityRanges((character: CharacterMetadata) => {
      const entityKey = character.getEntity();
      const foundElement = document.getElementById(entityKey);
      let elementPosition = null;

      if (!foundElement) {
        return;
      }

      elementPosition = foundElement.getBoundingClientRect();

      if (!entityKey || !elementPosition) {
        return;
      }

      const entityType: string = contentState.getEntity(entityKey).getType();

      switch (entityType) {
        case 'PROJECT':
          const projectContent = contentState.getEntity(entityKey).getData();
          projectContent.position = elementPosition;
          break;
        case 'RECOMMENDATION':
          const recommendationContent = contentState.getEntity(entityKey).getData();
          if (foundElement?.innerText.length) {
            recommendationContent.selectedText = foundElement?.innerText;
          }
          recommendationContent.position = elementPosition;
          break;
      }
    });
  });
}
