// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.files-dropzone {
  width: 100%;
  height: 376px;
  background-color: #fff;
  border: 1px dashed #d8d8d8;
  z-index: 98;
}
.files-dropzone .ant-upload-drag:first-child {
  background-color: transparent;
  padding: 3px 16px;
  border: 0;
  cursor: default;
}
.files-dropzone .dragger-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.files-dropzone .dragger-wrapper .dragger-text {
  color: #545e6b;
  font-size: 14px;
  margin-top: 20px;
  line-height: 12px;
}
.files-dropzone .dragger-wrapper .dragger-text .ant-upload {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/files/files-dropzone/files-dropzone.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,0BAAA;EACA,WAAA;AACF;AACE;EACE,6BAAA;EACA,iBAAA;EACA,SAAA;EACA,eAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAAJ;AAEI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAN;AAEM;EACE,UAAA;AAAR","sourcesContent":[".files-dropzone {\n  width: 100%;\n  height: 376px;\n  background-color: #fff;\n  border: 1px dashed #d8d8d8;\n  z-index: 98;\n\n  .ant-upload-drag:first-child {\n    background-color: transparent;\n    padding: 3px 16px;\n    border: 0;\n    cursor: default;\n  }\n\n  .dragger-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n\n    .dragger-text {\n      color: #545e6b;\n      font-size: 14px;\n      margin-top: 20px;\n      line-height: 12px;\n\n      .ant-upload {\n        padding: 0;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
