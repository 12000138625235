// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linked-stories-wrapper .linked-stories-library {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/linked-stories/linked-stories.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,mBAAA;AADJ","sourcesContent":[".linked-stories-wrapper {\n\n  .linked-stories-library {\n    margin-top: 20px;\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    flex-wrap: wrap;\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
