import React from 'react';

const SvgExternalTool = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10 3H14.7181C15.1638 3 15.3255 3.04641 15.4884 3.13357C15.6514 3.22072 15.7793 3.34861 15.8664 3.51158C15.9536 3.67454 16 3.83618 16 4.28194V11.7181C16 12.1638 15.9536 12.3255 15.8664 12.4884C15.7793 12.6514 15.6514 12.7793 15.4884 12.8664C15.3255 12.9536 15.1638 13 14.7181 13H10V11H14V5H10V3ZM1.28194 3H6V5H2V11H6V13H1.28194C0.836183 13 0.67454 12.9536 0.511577 12.8664C0.348614 12.7793 0.22072 12.6514 0.133566 12.4884C0.0464128 12.3255 0 12.1638 0 11.7181V4.28194C0 3.83618 0.0464128 3.67454 0.133566 3.51158C0.22072 3.34861 0.348614 3.22072 0.511577 3.13357C0.67454 3.04641 0.836183 3 1.28194 3ZM7 7H9C9.55229 7 10 7.44772 10 8C10 8.55228 9.55229 9 9 9H7C6.44772 9 6 8.55228 6 8C6 7.44772 6.44772 7 7 7Z"
            fill={props?.color ? props.color : "#545E6B"}/>
    </svg>
  );
};

export default SvgExternalTool;
