import React from 'react';
import AnalyticsApi from "../../api/analytics-api";
import { Loader } from "../../../../_shared/components/loader/loader";
import { Empty } from "antd";
import ProjectsChart from "./projects-chart/projects-chart";
import { useQuery } from "react-query";
import { ProjectTabs } from "./project-tabs/project-tabs";

const Overview = (props: { queryParams: any }): JSX.Element => {
  const {queryParams} = props;
  const projectsCount = useQuery(['analyticsProjectsCount', queryParams], () => AnalyticsApi.getProjectsCount(queryParams));
  const chart = useQuery(['analyticsChart', queryParams], () => AnalyticsApi.getChart(queryParams), {
    select: data => {
      let chartData: any[] = data;
      let newData: any[] = [];
      chartData.forEach((item: any) => {
        const val = parseInt(item.value);
        newData.push({
          ...item,
          value: val
        })
      });

      return newData;
    }
  });


  const pricingModels = useQuery(['analyticsPricingModels', queryParams], () => AnalyticsApi.getPricingModels(queryParams));


  return (
    <div className="analytics">
      {projectsCount.isLoading || chart.isLoading || pricingModels.isLoading ? <Loader/>
        :
        <div className="content">
          <div className="analytics-main column">
            <div className='row analytics-cards'>
              <div className="analytics-project-card column">
                <div className='w-100'>
                  <div className='ti tle'>Snapshot</div>
                  <div className='hr'></div>
                </div>
                {projectsCount &&
                    <>
                      {projectsCount?.data?.active || projectsCount.data?.queued || projectsCount.data?.completed ?
                        <div className="row w-100 justify-space-around">
                          <div className="column align-items-center">
                            <span className="project-count">{projectsCount?.data?.queued}</span>
                            <div className='hr'/>
                            <span className="project-status row align-items-center"> <div className='status-circle'
                                                                                          style={{backgroundColor: "#A5CEFA"}}/>Queued</span>
                          </div>
                          <div className="column align-items-center">
                            <span className="project-count">{projectsCount?.data?.active}</span>
                            <div className='hr'/>
                            <span className="project-status row align-items-center"> <div className='status-circle'
                                                                                          style={{backgroundColor: "#4084DF"}}/> Active</span>
                          </div>
                          <div className="column align-items-center">
                            <span className="project-count">{projectsCount?.data?.completed}</span>
                            <div className='hr'/>
                            <span className="project-status row align-items-center"> <div className='status-circle'
                                                                                          style={{backgroundColor: "#1B3380"}}/>Completed</span>
                          </div>
                        </div>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                      }
                    </>
                }
                {/*<div className="highlights">*/}
                {/*  <p>Highlights</p>*/}
                {/*  <div className="hr"/>*/}
                {/*  <ul>*/}
                {/*    {highlights?.data?.length ? highlights.data?.map((h: any, i: number) => {*/}
                {/*        return (*/}
                {/*          <li key={i}>{h?.highlight}</li>*/}
                {/*        )*/}
                {/*      })*/}
                {/*      : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>*/}
                {/*    }*/}
                {/*  </ul>*/}
                {/*</div>*/}
              </div>
              {pricingModels?.data && pricingModels?.data?.map((pricingModel: any, i: number) => {
                return (
                  <div key={i} className="analytics-small-card">
                    <span className="title">{pricingModel?.name}</span>
                    <div className='hr'/>
                    <span className="price">{pricingModel?.average}</span>
                    <span className="average-text">Avg.</span>
                    <div className="hr"/>
                    <div className="footer row justify-space-evenly">
                      <div className="column align-items-center justify-space-center">
                        <span style={{marginBottom: 10}}>{pricingModel?.min}</span>
                        <span>Min</span>
                      </div>
                      <div className="column align-items-center justify-space-center">
                        <span style={{marginBottom: 10}}>{pricingModel?.max}</span>
                        <span>Max</span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="row justify-space-between">
              <div className="analytics-card column ">
                <div className='hr'></div>
                {chart?.data?.length ?
                  <ProjectsChart chart={chart.data}/>
                  :
                  <div className="column justify-space-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  </div>
                }
              </div>
            </div>
          </div>
          <ProjectTabs queryParams={queryParams}/>
        </div>
      }
    </div>
  );
};

export default Overview;
