import { useEffect, useRef } from "react";

export const usePrevious = <T>(value?: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  if (!value) {
    return;
  }

  return ref.current;
};
