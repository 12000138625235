// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph-question-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
  color: #545e6b;
  border-left: 5px solid #1b87e6;
  padding: 4px 10px;
}

.graph-strategy-info {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #545e6b;
}
.graph-strategy-info .graph-strategy-source {
  color: #1B87E6;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.graph-strategy-info .graph-strategy-date {
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.graph-strategy-info .graph-strategy-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/story-editor/strategies/graph-strategy/graph-strategy.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;EACA,8BAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,WAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AADJ","sourcesContent":[".graph-question-title {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  margin-bottom: 20px;\n  color: #545e6b;\n  border-left: 5px solid #1b87e6;\n  padding: 4px 10px;\n}\n\n.graph-strategy-info {\n  margin-top: 20px;\n  display: flex;\n  width: 100%;\n  justify-content: flex-start;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 20px;\n  color: #545e6b;\n\n  .graph-strategy-source {\n    color: #1B87E6;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 21px;\n    display: flex;\n    align-items: center;\n  }\n\n  .graph-strategy-date {\n    color: #9B9B9B;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 21px;\n  }\n\n  .graph-strategy-link {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
