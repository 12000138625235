import React from 'react';
import SvgPeople from '../../../../../../assets/icons/js/people';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ISlackMember } from '../../../../models/slack-info-members.interface';

export class SlackMembers extends React.Component<any, any> {
  columns: ColumnsType<ISlackMember> = [
    {
      title: 'Display Name',
      dataIndex: 'realName',
    },
    {
      title: 'Slack Handle',
      dataIndex: 'displayName',
      render: (value: null, member: ISlackMember) => {
        return (
          <span>{
            member?.displayName ? '@' + member?.displayName.toLowerCase().replace(/\s/g, '')
              : member?.realName ? '@' + member?.realName.toLowerCase().replace(/\s/g, '') : null
          }
          </span>
        )
      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Last Activity',
      dataIndex: 'lastActivity',
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {}
  }

  render() {
    const {slackChannelInfo} = this.props.data;
    return (
      <Modal
        title={this.props.data?.channelName ? '#' + this.props.data.channelName : ''}
        open={this.props.data?.visible}
        onCancel={this.props.onCancel}
        width={820}
        footer={[]}>
        <div className="column align-items-start">
          <div className="row align-items-center">
            <div style={{padding: '0 10px 6px 0'}}>
              <SvgPeople/>
            </div>
            <h3>Members on this channel: {slackChannelInfo?.members?.length}</h3>
          </div>
        </div>
        <div className="row">
          <Table className="w-100"
                 columns={this.columns}
                 pagination={false}
                 dataSource={slackChannelInfo.members}
                 rowKey="realName"/>
        </div>
      </Modal>
    );
  }
}
