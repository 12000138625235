import { InstanceEnum, IUserData } from "../../core/model/user-data.interface";

export const getInstance = (userData: IUserData): string => {
  switch (userData?.instance) {
    case InstanceEnum.US:
    case InstanceEnum.AU:
      return 'com'
    case InstanceEnum.EU:
      return 'eu'
    case InstanceEnum.CA:
      return 'ca'
    case InstanceEnum.AE:
      return 'ae'
    default:
      return 'com'
  }
}
