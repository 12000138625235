import React from 'react';
import { Modal } from "antd";
import './tag-matches-dialog.scss'
import SearchResults from "../../../pages/home/pages/search-results/search-results";
import { SearchTypeEnum } from "../../../pages/home/pages/search-results/models/search-type.enum";
import { ITag } from "../../model/tag.interface";

type TagMatchesDialogProps = {
  data: {
    open: boolean,
    tag: ITag
  },
  onSave: () => void,
  onCancel: () => void
}
const TagMatchesDialog = (props: TagMatchesDialogProps) => {
  const tag = props.data.tag
  return (
    <Modal title='Tag Matches'
           open={props.data.open}
           footer={[]}
           width={1000}
           onCancel={props.onCancel}>
      <div className="tag-matches-dialog-wrapper">
        <SearchResults matchTag={tag}
                       searchData={{search: "", searchType: SearchTypeEnum.EXACT, tags: [tag.id]}}/>
      </div>
    </Modal>
  );
};

export default TagMatchesDialog;
