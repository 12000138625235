import React, { useEffect, useState } from 'react';
import './external-url-import.scss'
import { Button, Select, Tooltip } from "antd";
import SvgArrowDown from "../../../../../../../../assets/icons/js/ArrowDown";
import GoogleDriveIntegrationApi
  from "../../../../../../../settings/settings-tabs/setup/integrations/google-drive-integration/api/google-drive-integration-api";
import { environment } from "../../../../../../../../environment";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { DebounceInput } from "react-debounce-input";
import OneDriveIntegrationApi
  from "../../../../../../../settings/settings-tabs/setup/integrations/one-drive-integration/api/one-drive-integration-api";
import OneDriveTooltip from '../../../../../../../../assets/images/one-drive-tooltip.png'
import GoogleDriveTooltip from '../../../../../../../../assets/images/google-drive-tooltip.png'
import SharepointTooltip from '../../../../../../../../assets/images/sharepoint-tooltip.png'
import { InfoCircleFilled } from '@ant-design/icons';
import SharepointIntegrationApi
  from "../../../../../../../settings/settings-tabs/setup/integrations/sharepoint-integration/api/sharepoint-integration-api";

interface ExternalURLImportProps {
  url: string;
  updateUrl: (value: string) => void;
  error: string;
  driveSelected: "GOOGLE" | "MICROSOFT" | "SHAREPOINT";
  updateDriveSelected: (drive: "GOOGLE" | "MICROSOFT" | "SHAREPOINT") => void;
  fromHome?: boolean;
  selectedProjectId?: number;
  updateDriveInstalled?: (drive: "GOOGLE" | "MICROSOFT" | "SHAREPOINT", value: boolean) => void;
}

const ExternalURLImport = (props: ExternalURLImportProps) => {
  const location = useLocation();
  const navigate = useNavigate()
  const urlParam: any = qs.parse(location?.search);
  const microsoftRedirectUri = props.fromHome ? environment.microsoft_redirect_uri_home : environment.microsoft_redirect_uri_initial;
  const googleRedirectUri = props.fromHome ? environment.google_redirect_uri_home : environment.google_redirect_uri_initial;

  const [googleDriveInstalled, setGoogleDriveInstalled] = useState(false);
  const [oneDriveInstalled, setOneDriveInstalled] = useState(false);
  const [sharepointInstalled, setSharepointInstalled] = useState(false);

  const [loader, setLoader] = useState(true);
  const [installing, setInstalling] = useState(false);

  useEffect(() => {
    Promise.all([isGoogleDriveInstalled(), isOneDriveInstalled(), isSharepointInstalled()]).then(() => {
      setLoader(false);
    })

    const base64State: string | null = urlParam?.state;
    if (!base64State) {
      return;
    }

    const base64DecodedState: string = atob(base64State);
    const state = JSON.parse(base64DecodedState);
    props.updateDriveSelected(state.type);

    switch (state.type) {
      case "GOOGLE":
        installGoogleDrive();
        break;
      case "MICROSOFT":
        installOneDrive();
        break;
      case "SHAREPOINT":
        installSharepoint();
        break;
    }

  }, []);


  function connectToGoogleDrive(): void {
    const state = {
      type: "GOOGLE",
      ...(props.selectedProjectId && {projectId: props.selectedProjectId})
    }

    const stringifyState: string = JSON.stringify(state);
    const base64state: string = btoa(stringifyState);

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.google_client_id}&response_type=code&prompt=consent&access_type=offline&redirect_uri=${googleRedirectUri}&state=${base64state}&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/docs`;
  }

  function connectToOneDrive(): void {
    const state = {
      type: "MICROSOFT",
      ...(props.selectedProjectId && {projectId: props.selectedProjectId})
    }

    const stringifyState: string = JSON.stringify(state);
    const base64state: string = btoa(stringifyState);

    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${environment.microsoft_client_id}&scope=offline_access Files.ReadWrite.All user.read mail.read&response_type=code&redirect_uri=${microsoftRedirectUri}&state=${base64state}`;
  }

  function connectToSharepoint(): void {
    const state = {
      type: "SHAREPOINT",
      ...(props.selectedProjectId && {projectId: props.selectedProjectId})
    }

    const stringifyState: string = JSON.stringify(state);
    const base64state: string = btoa(stringifyState);

    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${environment.microsoft_client_id}&scope=offline_access Files.ReadWrite.All user.read mail.read&response_type=code&redirect_uri=${microsoftRedirectUri}&state=${base64state}`;
  }

  async function isGoogleDriveInstalled() {
    const response = await GoogleDriveIntegrationApi.isInstalled()
    setGoogleDriveInstalled(response);
    if (props.updateDriveInstalled) {
      props.updateDriveInstalled("GOOGLE", response);
    }
  }

  async function isOneDriveInstalled() {
    const response = await OneDriveIntegrationApi.isInstalled();
    setOneDriveInstalled(response);
    if (props.updateDriveInstalled) {
      props.updateDriveInstalled("MICROSOFT", response);
    }
  }

  async function isSharepointInstalled() {
    const response = await SharepointIntegrationApi.isInstalled();
    setSharepointInstalled(response);
    if (props.updateDriveInstalled) {
      props.updateDriveInstalled("SHAREPOINT", response);
    }
  }

  function installGoogleDrive(): void {
    const code: string | null = urlParam?.code;

    if (!code || !googleRedirectUri) {
      return;
    }

    setInstalling(true)

    GoogleDriveIntegrationApi
      .install(code, googleRedirectUri)
      .then(() => {
        setGoogleDriveInstalled(true);
        if (props.updateDriveInstalled) {
          props.updateDriveInstalled("GOOGLE", true);
        }
        setInstalling(false)
        navigate(location.pathname)
      });
  }

  function installOneDrive(): void {
    const code: string | null = urlParam?.code;

    if (!code || !microsoftRedirectUri) {
      return;
    }

    setInstalling(true)

    OneDriveIntegrationApi
      .install(code, microsoftRedirectUri)
      .then(() => {
        setOneDriveInstalled(true);
        if (props.updateDriveInstalled) {
          props.updateDriveInstalled("MICROSOFT", true);
        }
        setInstalling(false);
        //reset pathname with react router
        navigate(location.pathname)

      });
  }

  function installSharepoint(): void {
    const code: string | null = urlParam?.code;

    if (!code || !microsoftRedirectUri) {
      return;
    }

    setInstalling(true)

    SharepointIntegrationApi
      .install(code, microsoftRedirectUri)
      .then(() => {
        setSharepointInstalled(true);
        if (props.updateDriveInstalled) {
          props.updateDriveInstalled("SHAREPOINT", true);
        }
        setInstalling(false);
        //reset pathname with react router
        navigate(location.pathname)

      });
  }

  function onSignInClick() {
    switch (props.driveSelected) {
      case "GOOGLE":
        connectToGoogleDrive();
        break;
      case "MICROSOFT":
        connectToOneDrive();
        break;
      case "SHAREPOINT":
        connectToSharepoint();
        break;
    }
  }


  return (
    <div className="external-url-import-wrapper column" style={{gap: 10}}>
      <div className="row" style={{color: "#545e6b", gap: 10}}>
        <span>
        {props.fromHome ? "Or import from URL" : "Import your data from an external site"}
        </span>
        <Tooltip title={<div>
          <img
            src={props.driveSelected === "GOOGLE" ? GoogleDriveTooltip : props.driveSelected === "SHAREPOINT" ? SharepointTooltip : OneDriveTooltip}
            width={220} alt=""/>
        </div>}>
          <div className="tooltip-content pointer" style={{width: 'fit-content'}}>
            <InfoCircleFilled/>
          </div>
        </Tooltip>
      </div>
      <div className="row" style={{gap: 10}}>
        <div>
          <Select style={{width: 150}}
                  value={props.driveSelected}
                  suffixIcon={<SvgArrowDown/>}
                  onChange={(value) => props.updateDriveSelected(value)}>
            <Select.Option value="GOOGLE">Google drive</Select.Option>
            <Select.Option value="MICROSOFT">One drive</Select.Option>
            <Select.Option value="SHAREPOINT">SharePoint</Select.Option>
          </Select>
        </div>
        {
          (props.driveSelected === "GOOGLE" && googleDriveInstalled) || (props.driveSelected === "MICROSOFT" && oneDriveInstalled) || (props.driveSelected === "SHAREPOINT" && sharepointInstalled) ?
            <div className="outline-input-wrapper" style={{flex: "auto"}}>
              <DebounceInput type="text" className="outline-input"
                             onChange={(e) => props.updateUrl(e.target.value)}
                             value={props.url}
                             placeholder={"Paste file URL here"}
                             debounceTimeout={600}/>
              {!props.fromHome && props.error && <small className="form-error">{props.error}</small>}
            </div>
            :
            <div className="row align-items-center" style={{gap: 10}}>
              <Button type="primary"
                      onClick={onSignInClick}>{props.driveSelected === "GOOGLE" ? "Sign in with Google" : "Sign in with Microsoft"}</Button>
            </div>
        }
      </div>
    </div>
  );
};

export default ExternalURLImport;
