// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reassign-workflow {
  padding: 18px;
}
.reassign-workflow .reassign-table {
  display: flex;
  flex-direction: column;
}
.reassign-workflow .reassign-table .reassign-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  color: #9b9b9b;
  padding: 0 4px;
}
.reassign-workflow .reassign-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 4px;
  font-size: 14px;
  font-weight: 500;
  color: #545e6b;
}
.reassign-workflow .reassign-row .affected-projects {
  display: flex;
  align-items: center;
  color: #9b9b9b;
}
.reassign-workflow .reassign-row .affected-projects > svg {
  margin-left: 10px;
  transform: rotate(-90deg);
}
.reassign-workflow .reassign-row .workflows-available {
  min-width: 250px;
}

.reassign-workflow-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #545e6b;
  border-top: 1px solid #e8e8e8;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/workflows/reassign-workflow/reassign-workflow.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gCAAA;EACA,cAAA;EACA,cAAA;AACN;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AADN;AAGM;EACE,iBAAA;EACA,yBAAA;AADR;AAKI;EACE,gBAAA;AAHN;;AAQA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EACA,6BAAA;EACA,aAAA;AALF","sourcesContent":[".reassign-workflow {\n  padding: 18px;\n\n  .reassign-table {\n    display: flex;\n    flex-direction: column;\n\n    .reassign-header {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      border-bottom: 1px solid #e8e8e8;\n      color: #9b9b9b;\n      padding: 0 4px;\n    }\n  }\n\n  .reassign-row {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 16px 4px;\n    font-size: 14px;\n    font-weight: 500;\n    color: #545e6b;\n\n    .affected-projects {\n      display: flex;\n      align-items: center;\n      color: #9b9b9b;\n\n      > svg {\n        margin-left: 10px;\n        transform: rotate(-90deg);\n      }\n    }\n\n    .workflows-available {\n      min-width: 250px;\n    }\n  }\n}\n\n.reassign-workflow-footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #545e6b;\n  border-top: 1px solid #e8e8e8;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
