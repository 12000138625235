import React, { useState } from 'react';
import { Table } from 'antd';
import { Loader } from '../../../_shared/components/loader/loader';
import AnalyticsApi from '../api/analytics-api';
import './analytics-timeline.scss';
import { ColumnsType } from 'antd/lib/table';
import { FilterForm } from '../../../_shared/components/filter-form/filter-form';
import { useQuery } from 'react-query';

export function AnalyticsTimeline(): JSX.Element {
  const workFlowStatusesColumns: ColumnsType<any> = [
    {
      title: 'Status',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 250,
      render: (name: any, workflowStatus: any) => {
        return (
          <div className="row align-items-center">
            <div className="color-circle" style={{backgroundColor: workflowStatus?.color}}/>
            <div>
              {name}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Average',
      dataIndex: 'average',
      key: 'average',
      align: 'left',
      width: 150,
      render: (average: any, workflowStatus: any) => {
        return (
          <div className="d-flex w-100 align-items-center">
            <span>{average?.days > 0 && average.days + 'days'}</span>
            <span>{average?.days === 0 && '0 days'}</span>
          </div>
        )
      }
    },
    {
      dataIndex: 'progress',
      key: 'progress',
      render: (average: any, workflowStatus: any) => {
        return (
          <div className="workflow-status-progress-wrapper w-100">
            <div className="workflow-status-progress-bar"
                 style={{backgroundColor: workflowStatus?.color, width: workflowStatus.percentage + '%'}}/>
            <span style={{opacity: 0}}>.</span>
          </div>
        )
      }
    },
  ];

  const [queryParams, setQueryParams] = useState<any>({});
  const workflow = useQuery(['timelineWorkflow', queryParams], () => AnalyticsApi.getWorkflowTimeline(queryParams));

  function filterFormChanged(params: any): void {
    setQueryParams(params);
  }

  return (
    <div className="analytics analytics-timeline">
      <div className="header justify-space-between">
        <h2>Analytics timeline</h2>
        <FilterForm filterFormChanged={filterFormChanged}/>
      </div>
      {workflow.isLoading ? <Loader/>
        :
        <div className="content">
          <div className="row justify-space-between workflow-stats w-50">
            <div>Time spent across <span className="link">{workflow?.data?.projectsNumber} projects</span></div>
            <div className="d-flex align-items-center">Total
              average: {workflow?.data?.totalAverage?.days > 0 ? workflow?.data.totalAverage.days + ' days' : "0 days"}</div>
          </div>
          <div className="table">
            <Table columns={workFlowStatusesColumns}
                   pagination={false}
                   className="w-100"
                   dataSource={workflow.data?.workflowStatuses}
                   rowKey="id"/>
          </div>
        </div>
      }
    </div>
  );
}
