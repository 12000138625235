import React from 'react';

const SvgImportBig = (props: any) => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2165_49462)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M17.1429 45.0714C17.1429 46.965 18.6779 48.5 20.5714 48.5H41.1429C44.7578 48.5 47.7194 45.7027 47.9812 42.1546L48 41.6429V7.35714C48 3.57005 44.93 0.5 41.1429 0.5H20.5714C18.6779 0.5 17.1429 2.03502 17.1429 3.92857C17.1429 5.82212 18.6779 7.35714 20.5714 7.35714H41.1429V41.6429H20.5714C18.6779 41.6429 17.1429 43.1779 17.1429 45.0714ZM34.2857 24.5L20.5714 34.7857V27.9286H0V21.0714H20.5714V14.2143L34.2857 24.5Z"
              fill="#1B87E6"/>
      </g>
      <defs>
        <clipPath id="clip0_2165_49462">
          <rect width="48" height="48" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default SvgImportBig;
