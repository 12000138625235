import React, { useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import './meta-tags.scss';
import { AddEditMetaTagDialog } from './add-edit-meta-tag-dialog';
import SvgEdit from '../../../../../assets/icons/js/Edit';
import { ColumnsType } from 'antd/lib/table'
import { IMetaTag } from './model/meta-tag.interface';
import { ITag } from './model/tag.interface';
import { ConfirmationDialog } from '../../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import { Loader } from '../../../../../_shared/components/loader/loader';
import SvgTrash from '../../../../../assets/icons/js/Trash';
import SvgRestoreTrash from '../../../../../assets/icons/js/RestoreTrash';
import DeskSettingsApi from '../../../api/settings-api';
import SettingsApi from '../../../api/settings-api';
import { useQuery } from 'react-query';
import Pagination from "../../../../../_shared/components/pagination/pagination";

export function MetaTags(props: any): JSX.Element {
  const columns: ColumnsType<IMetaTag> = [
    {
      title: 'Group name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'name',
      render: (tag: ITag[]) => (
        <div className="tags-wrapper">
          {tag && tag.sort((a, b) => a.name.localeCompare(b.name)).map((tag: ITag) => {
            return (
              <span className="tag" key={tag.id}>{tag.name}</span>
            )
          })}
        </div>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      align: 'right',
      render: (metaTag: IMetaTag) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <Tooltip placement="bottom" title="Edit meta tag">
              <button className="icon-button" onClick={() => openEditMetaTagDialog(metaTag)}>
                <SvgEdit/>
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title="Move to recycle bin">
              <button className="icon-button" style={{marginLeft: 10}} onClick={() => openConfirmationDialog(metaTag)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      )
    }
  ];
  const [modalData, setModalData] = useState<any>({visible: false});
  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const metaTags = useQuery(['allMetaTags', page, pageSize], () => SettingsApi.getMetaTags({
    page,
    pageSize
  }));


  const openConfirmationDialog = (metaTag: any) => {
    setConfirmationDialog({
      visible: true,
      id: metaTag.id,
      okText: 'Move',
      title: 'Move to Recycle Bin',
      titleSec: 'You\'re about to move this Meta tag to the Recycle Bin',
      content: `${metaTag.name}`,
      description: <>
        Continuing will remove the Meta tag from this
        list{metaTag.totalAssign > 0 ? `, and removes the Tags from ${metaTag.totalAssign} projects` : ''}. You can
        easily restore it by going to
        the <SvgTrash/> Recycle Bin tab and clicking
        the <SvgRestoreTrash/> Restore From Recycle Bin button.
      </>
    });
  }

  const archiveMetaTag = (id?: number) => {
    if (id) {
      DeskSettingsApi.deleteMetaTag(id)
        .then(() => {
          metaTags.refetch();
          props.updateArchive(true);
        });
    }
  }

  const openAddMetaTagDialog = () => {
    setModalData({visible: true});
  }

  const openEditMetaTagDialog = (metaTag: IMetaTag) => {
    setModalData({
      id: metaTag.id,
      name: metaTag.name,
      tags: metaTag.tags,
      visible: true
    });
  }

  const onCancel = () => {
    setConfirmationDialog({visible: false});
    setModalData({visible: false});
    metaTags.refetch();
  }

  const onOk = (props: any) => {
    if (props.modal) {
      onCancel();
    }
  }

  const onConfirmationDialogConfirm = (props: any) => {
    if (props.modal) {
      archiveMetaTag(props.id);
      onCancel();
    }
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }

  return (
    <div className="meta-tags-wrapper">
      <div className="justify-space-between row" style={{marginBottom: 20}}>
        <Button type="primary" onClick={openAddMetaTagDialog}>Add new tag group</Button>
        {
          metaTags.data && metaTags.data?.pagination.totalElements > 0 &&
          <Pagination totalItems={metaTags.data?.pagination.totalElements}
                      currentPage={page}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}
                      showSizeChanger/>
        }
      </div>
      <div className="body">
        <Table columns={columns}
               loading={metaTags.isLoading}
               pagination={false}
               dataSource={metaTags?.data?.content}
               rowKey="id"/>
      </div>
      {modalData.visible &&
        <AddEditMetaTagDialog data={modalData} onOk={onOk} onCancel={onCancel}/>
      }

      {confirmationDialog.visible &&
        <ConfirmationDialog data={confirmationDialog}
                            onConfirm={onConfirmationDialogConfirm}
                            onCancel={onCancel}/>}
    </div>
  )
}
