import React, { useState } from 'react';
import {
  MainFormTypeEnum
} from "../../../../../../../settings/settings-tabs/taxonomy/project-type/model/main-form-type.enum";
import { WidgetChoice } from "../../../../../../../home/pages/create-project/widgets/widget-choice";
import { WidgetInput } from "../../../../../../../home/pages/create-project/widgets/widget-input";
import { WidgetUpload } from "../../../../../../../home/pages/create-project/widgets/widget-upload/widget-upload";
import { Divider, Modal, notification } from "antd";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import MyProjectApi from "../../../../../../api/project-api";
import { DraftJs } from "../../../../../../../../_shared/components/draft-js/draft";
import SvgTrash from "../../../../../../../../assets/icons/js/Trash";
import "../../../insights.scss"
import "./projects-details-dialog.scss";

const ProjectDetailsDialog = (props: any) => {
  const {setProject, project} = props;
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const initialState = props.data?.project?.encodedFullDescription
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.data?.project?.encodedFullDescription)))
    : EditorState.createEmpty();

  const [editorState, setEditorState] = React.useState<EditorState>(() => initialState);

  const setWidget = (data: { index: number; type: MainFormTypeEnum; value: any; }) => {
    const widgets = project.formValues;
    widgets[data.index] = {
      ...widgets[data.index],
      value: data.value,
    };

    setProject({...project, formValues: widgets});
  };

  const setWidgetUpload = (data: { index: number; value: any }) => {
    const widgets = project.formValues;

    if (widgets[data.index]?.value?.length) {
      widgets[data.index].value.push(...data.value);
    } else {
      widgets[data.index].value = [...data.value];
    }

    setProject({...project, formValues: widgets});
  };

  const removeFileFromWidget = (data: { index: number; id: string }) => {
    const widgets = project.formValues;

    if (widgets[data.index]?.value?.length) {
      widgets[data.index]?.value.splice(
        widgets[data.index]?.value.findIndex((file: any) => {
          return file.id === data.id;
        }),
        1
      );
    }

    setProject({...project, formValues: widgets});
  };

  const setWidgetsMultiChoice = (data: { index: number; type: MainFormTypeEnum; value: any; }) => {
    const widgets = project.formValues;
    widgets[data.index] = {
      ...widgets[data.index],
      value: data.value,
    };

    setProject({...project, formValues: widgets});
  };

  const downloadFile = (file: any): void => {
    MyProjectApi.downloadFile(file.id).then((response: any) => {
      const link = document.createElement('a');
      const fileName = `${file.name.toLowerCase()}.${file.extension}`;
      link.href = response;
      link.setAttribute('download', fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      // @ts-ignore
      link.parentNode.removeChild(link);
    });
  };

  const updateEditorState = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };


  const submit = (): void => {
    setApiLoading(true);

    const payload: {
      fullDescription: any;
      formValues: any;
      encodedFullDescription: any;
    } = {
      encodedFullDescription: JSON.stringify(convertToRaw(editorState?.getCurrentContent())),
      fullDescription: editorState?.getCurrentContent().getPlainText('\u0001'),
      formValues: project.formValues,
    };

    MyProjectApi.updateRequirementsStatus(project.id, payload).then(
      (response: any) => {
        setProject({...response});
        setApiLoading(false);
        notification.open({
          message: 'Requirements Status',
          description: `Has been updated`,
          type: 'success',
          className: 'infobox-success',
          duration: 3,
          placement: 'bottomRight',
        });
        props.onCancel()
      }
    );
  };


  return (
    <Modal title='Project Details'
           okText="Save"
           cancelButtonProps={{type: "link"}}
           open={props.visible}
           onCancel={props.onCancel}
           okButtonProps={{loading: apiLoading}}
           onOk={submit}
           width={1019}>
      <div className='project-details-dialog-wrapper'>
        <div className="project-edit-body">
          <div className="content-block">
            <span className="title">More Details</span>
            <div className="outline-input-wrapper w-100 details-requirement-draft-js">
              <DraftJs disableNodes={true}
                       style={{height: 300}}
                       updateEditorState={updateEditorState}
                       content={project?.encodedFullDescription}/>
            </div>
          </div>
          <Divider style={{marginBottom: -5}}/>
          {project?.formValues &&
            project.formValues.map((form: any, widgetIndex: number) => {
              return (
                <div className="content-block" key={widgetIndex}>
                  <>
                    <span className="title">
                      {form.label?.length ? form.label : form?.question}
                    </span>
                    <div className="description">
                      {form.widgetType === MainFormTypeEnum.TEXT_INPUT && (
                        <div>
                          <WidgetInput
                            index={widgetIndex}
                            value={form.value}
                            getTextAreaValue={setWidget}
                          />
                        </div>
                      )}
                      {form.widgetType === MainFormTypeEnum.MULTI_CHOICE && (
                        <div>
                          <WidgetChoice
                            index={widgetIndex}
                            options={form.options}
                            value={form.value}
                            choiceType={form.multiple}
                            getSelected={setWidgetsMultiChoice}
                          />
                        </div>
                      )}
                      {form.widgetType === MainFormTypeEnum.FILE_UPLOAD && (
                        <div>
                          <WidgetUpload
                            index={widgetIndex}
                            options={form.options}
                            value={form.value}
                            showUploadList={false}
                            removeFile={removeFileFromWidget}
                            getFileInfo={setWidgetUpload}
                          />
                          {form.widgetType === MainFormTypeEnum.FILE_UPLOAD &&
                            form.value &&
                            form.value.length > 0 && (
                              <div className="column">
                                {form?.value?.map((value: any, i: number) => {
                                  return (
                                    <div
                                      key={i}
                                      className="row align-items-center"
                                    >
                                      <span
                                        className="pointer"
                                        style={{
                                          color: '#1b87e6',
                                          zIndex: 99999999,
                                          marginTop: 5,
                                        }}
                                        onClick={() => downloadFile(value)}
                                      >
                                        {value?.name ? value.name : ''}
                                      </span>
                                      <button
                                        className="icon-button"
                                        style={{marginLeft: 10}}
                                        onClick={() =>
                                          removeFileFromWidget({
                                            index: widgetIndex,
                                            id: value.id,
                                          })
                                        }
                                      >
                                        <SvgTrash/>
                                      </button>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </>
                </div>
              );
            })}
        </div>
      </div>
      <Divider style={{marginBottom: -20}}/>
    </Modal>
  );
};

export default ProjectDetailsDialog;
