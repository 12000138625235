import * as React from "react";

const SvgMore = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/More"
           fill={props.color ? props.color : '#545E6B'}>
          <path
            d="M8,12 C9.1045695,12 10,12.8954305 10,14 C10,15.1045695 9.1045695,16 8,16 C6.8954305,16 6,15.1045695 6,14 C6,12.8954305 6.8954305,12 8,12 Z M8,6 C9.1045695,6 10,6.8954305 10,8 C10,9.1045695 9.1045695,10 8,10 C6.8954305,10 6,9.1045695 6,8 C6,6.8954305 6.8954305,6 8,6 Z M8,0 C9.1045695,0 10,0.8954305 10,2 C10,3.1045695 9.1045695,4 8,4 C6.8954305,4 6,3.1045695 6,2 C6,0.8954305 6.8954305,0 8,0 Z"
            id="Icons/More"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgMore;
