import * as React from "react";

const SvgWorkSpaceCircles = (props: any) => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill={props.color ? props.color : '#545E6B'}>
          <path
            d="M3 8c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm8 0c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM3 0c1.657 0 3 1.343 3 3S4.657 6 3 6 0 4.657 0 3s1.343-3 3-3zm8 0c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
            transform="translate(-171 -1042) translate(171 1036) translate(0 6)"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgWorkSpaceCircles;
