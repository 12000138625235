// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[0].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[4]!./assets/fonts/fira-sans/fira-sans.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[0].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[4]!../node_modules/@questionproext/wick-ui-icons/dist/icomoon/css/wick-ui-icon.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[0].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[4]!../node_modules/@questionproext/wick-ui-icons/dist/icomoon/css/animation.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[0].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[4]!../node_modules/@questionproext/wick-ui-lib/dist/esm/wick-ui-bundle.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/helvetica-255);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/times-new-roman);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/arial);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/verdana);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/georgia-2);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/itc-avant-garde-pro-md);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Fira Sans", sans-serif;
}

h1 {
  font-weight: 100;
  color: #9B9B9B;
  font-size: 48px;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  color: #545E6B;
  margin: 5px 0;
}

h3 {
  font-weight: 400;
  font-size: 16px;
  color: #545E6B;
}

.editor {
  border-bottom: 2px solid #d8d8d8;
}

.CodeMirror {
  font-size: 13px !important;
}
.CodeMirror .CodeMirror-linenumber {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}
.CodeMirror .CodeMirror-gutters {
  border: 0;
  background-color: #f5f5f5;
}

.display-none {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAWA;EACE,SAAA;EACA,UAAA;EACA,oCAAA;EACA,mCAAA;EACA,kCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;EACA,eAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAAF;;AAGA;EACE,gCAAA;AAAF;;AAGA;EACE,0BAAA;AAAF;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,SAAA;EACA,yBAAA;AADJ;;AAMA;EACE,wBAAA;AAHF","sourcesContent":["@import \"assets/fonts/fira-sans/fira-sans.css\";\n@import url('https://fonts.cdnfonts.com/css/helvetica-255');\n@import url('https://fonts.cdnfonts.com/css/times-new-roman');\n@import url('https://fonts.cdnfonts.com/css/arial');\n@import url('https://fonts.cdnfonts.com/css/verdana');\n@import url('https://fonts.cdnfonts.com/css/georgia-2');\n@import url('https://fonts.cdnfonts.com/css/itc-avant-garde-pro-md');\n@import '@questionproext/wick-ui-icons/dist/icomoon/css/wick-ui-icon.css';\n@import '@questionproext/wick-ui-icons/dist/icomoon/css/animation.css';\n@import '@questionproext/wick-ui-lib/dist/esm/wick-ui-bundle.css';\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Fira Sans', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: 'Fira Sans', sans-serif;\n}\n\nh1 {\n  font-weight: 100;\n  color: #9B9B9B;\n  font-size: 48px;\n}\n\nh2 {\n  font-weight: 400;\n  font-size: 24px;\n  color: #545E6B;\n  margin: 5px 0;\n}\n\nh3 {\n  font-weight: 400;\n  font-size: 16px;\n  color: #545E6B;\n}\n\n.editor {\n  border-bottom: 2px solid #d8d8d8;\n}\n\n.CodeMirror {\n  font-size: 13px !important;\n\n  .CodeMirror-linenumber {\n    text-align: left;\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n\n  .CodeMirror-gutters {\n    border: 0;\n    background-color: #f5f5f5;\n  }\n}\n\n\n.display-none {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
