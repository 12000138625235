import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import ProjectsTable from "./projects-table/projects-table";
import AnalyticsApi from "../../../api/analytics-api";
import FormattedDate from "../../../../../_shared/helpers/formatted-date";
import { ReviewStateEnum } from "../../../../projects/tabs/_shared/model/review-state.enum";
import StatusPipe from "../../../../../_shared/helpers/status-pipe";
import { ProjectStatusEnum } from "../../../models/project-status.enum";
import { Loader } from "../../../../../_shared/components/loader/loader";

const TabPane = Tabs.TabPane;

export function ProjectTabs(props: any): JSX.Element {
  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  useEffect(() => {
    AnalyticsApi.getColumns().then((response) => {
      const columns: any[] = [
        {
          title: 'Project',
          dataIndex: 'name',
          key: 'name',
          render: (value: string, projects: any) => <Link className='table-project-name'
                                                          to={'/projects/edit?id=' + projects.id}
                                                          state={{fromAnalytics: true, tab: '1'}}>{value}</Link>
        },
        {
          title: 'Requested by',
          dataIndex: 'requestBy',
          key: 'requestBy',
          align: 'right'
        },
        {
          title: 'Completed',
          dataIndex: 'completed',
          key: 'completed',
          align: 'right',
          render: ((value: any) => <FormattedDate date={value} formatString="MMM d, yyyy"/>)
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: ((value: ReviewStateEnum) => <div className="flex-end">
              <StatusPipe value={value}/>
            </div>
          )
        },
        {
          title: 'Contact email address',
          dataIndex: 'contactEmailAddress',
        }
      ];

      response.columns.forEach((column, columnIndex: number) => {
        const addColumn: ColumnType<any> = {
          title: column.name,
          dataIndex: column.id,
          key: columnIndex,
          align: 'right',
        }

        columns.push(addColumn);
      });

      setColumns(columns.slice());
    })
  }, [])


  return (
    <Tabs className="tabs">
      <TabPane tab="Active" key="1">
        {!columns.length ? <Loader/> :
          <ProjectsTable columns={columns} queryParams={props.queryParams} status={ProjectStatusEnum.ACTIVE}/>
        }
      </TabPane>
      <TabPane tab="Completed" key="2">
        {!columns.length ? <Loader/> :
          <ProjectsTable columns={columns} queryParams={props.queryParams} status={ProjectStatusEnum.COMPLETED}/>
        }
      </TabPane>
      <TabPane tab="Queued" key="3">
        {!columns.length ? <Loader/> :
          <ProjectsTable columns={columns} queryParams={props.queryParams} status={ProjectStatusEnum.QUEUED}/>
        }
      </TabPane>
    </Tabs>
  );
}
