import React, { useState } from 'react';
import SvgResearch from '../../../../../../../assets/icons/js/research';
import SvgPeople from '../../../../../../../assets/icons/js/people';
import SvgListChecked from '../../../../../../../assets/icons/js/list-checked';
import slackImage1 from '../../../../../../../assets/images/slack_install_1.png';
import slackImage2 from '../../../../../../../assets/images/slack_install_2.png';
import slackImage3 from '../../../../../../../assets/images/slack_install_3.png';
import slackImage4 from '../../../../../../../assets/images/slack_install_4.png';
import slackImage5 from '../../../../../../../assets/images/slack_install_5.png';

export function SlackDescriptionTab(): JSX.Element {
  const [imagePicked, setImagePicked] = useState<any>(slackImage1);

  function pickImage(image: string): void {
    if (!image) {
      return;
    }

    setImagePicked(image)
  }

  return (
    <div className="integration-tab column">
      <div className="row description-tab w-100">
        <div className="column">
          <h3 className="title">InsightsHub allows you to: </h3>
          <div className="column">
            <div className="content-title">
              <SvgResearch/>
              <h4>Quickstart you research projects</h4>
            </div>
            <ul>
              <li>Initiate research projects right from Slack by typing /insightshub into the message box.</li>
            </ul>
          </div>
          <div className="column">
            <div className="content-title">
              <SvgPeople/>
              <h4>Get everyone involved</h4>
            </div>
            <ul>
              <li>Automatically generate slack channels upon project creation.
                All conversations within this channel will get saved as notes within the project.
              </li>
            </ul>
          </div>
          <div className="column">
            <div className="content-title">
              <SvgListChecked/>
              <h4>Keep you and your stakeholders up to date</h4>
            </div>
            <ul>
              <li>Get notified with every status update made on your related projects.</li>
            </ul>
          </div>
        </div>
        <div className="column image-picker w-100 d-flex align-items-center">
          <div>
            <img alt="" src={imagePicked} className="image-picked"/>
            <div className="row">
              <img className="pick-image"
                   alt="Slack 1"
                   src={slackImage1}
                   onClick={() => pickImage(slackImage1)}/>

              <img className="pick-image"
                   alt="Slack 2"
                   src={slackImage2}
                   onClick={() => pickImage(slackImage2)}/>

              <img className="pick-image"
                   alt="Slack 3"
                   src={slackImage3}
                   onClick={() => pickImage(slackImage3)}/>

              <img className="pick-image"
                   alt="Slack 4"
                   src={slackImage4}
                   onClick={() => pickImage(slackImage4)}/>
              <img className="pick-image"
                   alt="Slack 5"
                   src={slackImage5}
                   onClick={() => pickImage(slackImage5)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
