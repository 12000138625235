// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-welcome-page-wrapper {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-welcome-page-wrapper .home-welcome-page-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-welcome-page-wrapper .home-welcome-page-container h1 {
  color: #545E6B;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
}
.home-welcome-page-wrapper .home-welcome-page-container p {
  color: #545E6B;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}

.sync-syncing-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  justify-content: center;
  gap: 40px;
}
.sync-syncing-view h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #1B3380;
}
.sync-syncing-view p {
  color: #545E6B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/home-initial-sync/home-initial-sync.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AACN;AAEI;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAAN;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,SAAA;AAFF;AAIE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAFJ;AAKE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAHJ","sourcesContent":[".home-welcome-page-wrapper {\n  height: 80vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  .home-welcome-page-container {\n    width: 60%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    h1 {\n      color: #545E6B;\n      text-align: center;\n      font-size: 32px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 40px; /* 125% */\n    }\n\n    p {\n      color: #545E6B;\n      text-align: center;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 300;\n      line-height: 24px; /* 150% */\n    }\n  }\n}\n\n.sync-syncing-view {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 80vh;\n  justify-content: center;\n  gap: 40px;\n\n  h1 {\n    font-size: 24px;\n    font-weight: 500;\n    line-height: 36px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #1B3380;\n  }\n\n  p {\n    color: #545E6B;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 21px; /* 150% */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
