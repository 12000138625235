import { Modal } from 'antd'
import React from 'react'
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";


const IFrameModal = (props: any) => {
  const schema = yup.object({
    url: yup
      .string()
      .required('IFrame Embed Code is required')
      .test('iframe', 'Invalid Embed Code', (value) => {
        if (!value) return true;
        return value.toLowerCase().includes('<iframe src') && value.toLowerCase().includes('</iframe>');
      }),
  });

  const {handleSubmit, register, formState: {errors}} = useForm({
    defaultValues: {
      url: props.data?.url ?? ''
    },
    resolver: yupResolver(schema)
  })

  const onSave = (data: { url: string }) => {
    props.onConfirm({
      url: data.url,
      entityKey: props.data?.entityKey,
      entityMapId: props.data?.entityMapId
    });
    props.onCancel();
  }


  return (
    <Modal
      onCancel={props.onCancel}
      onOk={handleSubmit(onSave)}
      okText="Save"
      open={props.data.visible}
      width={600}
      cancelButtonProps={{type: "link"}}
      title="Add IFrame">
      <div className='hyperlink-wrapper'>
        <div className="outline-input-wrapper">
          <input
            type="text"
            className='outline-input'
            placeholder='Paste Embed Code'
            {...register('url')}
          />
          <ErrorMessage errors={errors} name={'url'} render={({message}) => <small
            className="form-error">{message}</small>}/>
        </div>
      </div>
    </Modal>
  )
}

export default IFrameModal
