import React from 'react';

const SvgPieChart = (props: any) => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7 1V8L11.9676 12.9318C11.6583 13.2433 11.32 13.5259 10.957 13.7751C9.83138 14.5478 8.4685 15 7 15C3.13401 15 0 11.866 0 8C0 4.13401 3.13401 1 7 1ZM14 3C15.2499 4.2644 16 6.08164 16 8C16 9.92604 15.2587 11.7343 14 13L9 8L14 3ZM8 7V0C9.16349 0 10.2607 0.283859 11.2261 0.786102C11.7316 1.0491 12.3237 1.41887 12.7827 1.80562L13 2L8 7Z"
            fill={props?.color ? props.color : '#545e6b'}/>
    </svg>
  );
};

export default SvgPieChart;
