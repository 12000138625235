import { ContentBlock, EditorState } from 'draft-js'
import React, { useEffect, useRef } from 'react'
import Editor from "@draft-js-plugins/editor"
import "./preview-story-editor.scss"
import { compositeDecorator } from './preview-decorators';
import FormattedDate from '../../../../_shared/helpers/formatted-date';
import { useOnLoadImages } from "../../../../core/hooks/use-on-load-images";
import { usePrevious } from "../../../../core/hooks/use-previous";

type PreviewStoryEditorProps = {
  editorState: EditorState,
  setEditorState: React.Dispatch<React.SetStateAction<EditorState | undefined>>,
  story: any,
  updateLoadedImages: (lI: boolean) => void
}
const PreviewStoryEditor = ({
                              editorState,
                              setEditorState,
                              story,
                              updateLoadedImages
                            }: PreviewStoryEditorProps) => {
  const storyEditorWrapper = useRef<HTMLDivElement>(null);
  const loadedImages = useOnLoadImages(storyEditorWrapper);
  const prevLoadedImages = usePrevious(loadedImages);

  useEffect(() => {
    if (loadedImages !== prevLoadedImages) {
      updateLoadedImages(loadedImages);
    }
  }, [loadedImages]);

  function myBlockStyleFn(contentBlock: ContentBlock) {
    const type = contentBlock.getType();
    if (type === 'unstyled') {
      return 'fancy-text';
    }
    return type;
  }

  return (
    <div className='story-editor-wrapper relative' ref={storyEditorWrapper}>
      <div className='editor-size'>
        <span className='title-input'>{story?.title}</span>
        <div className='divider'></div>
        <div className='owner-date-container'>
          <div>By: {story.createdBy}</div>
          <div><FormattedDate date={story.createdAt} formatString="MMM d, yyyy"/></div>
        </div>

        <Editor
          blockStyleFn={myBlockStyleFn}
          readOnly={true}
          editorState={editorState}
          onChange={setEditorState}
          decorators={[compositeDecorator(true)]}/>
      </div>

    </div>
  )
}

export default PreviewStoryEditor
