import React from 'react';

const SvgNumberedList2 = (props: any) => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.1705 4.11455L2.17067 0H1.53538L1.39933 0.0547492L-0.000244141 1.35997L0.279143 1.77357L0.31145 1.81624C0.336237 1.84353 0.367124 1.8682 0.401723 1.88838C0.456419 1.92027 0.516833 1.93593 0.57987 1.93593C0.621834 1.93593 0.663376 1.92769 0.704154 1.91233L0.752135 1.88958L0.812121 1.84906L1.22922 1.46455V4.11455L0.323842 4.11497V5H2.99976V4.11497L2.1705 4.11455Z"
        fill={props?.color ? props.color : '#545e6b'}/>
      <path
        d="M2.08351 7.09923C1.91726 7.03296 1.73931 7 1.55086 7C1.36124 7 1.18252 7.03221 1.01582 7.09701C0.847271 7.16253 0.696837 7.25897 0.565921 7.38558C0.434836 7.51236 0.327574 7.66826 0.244246 7.85169C0.182452 7.98772 0.136201 8.13739 0.105194 8.30034L0.0465801 8.67666L0.502363 8.76811L0.554502 8.77565L0.60406 8.77834C0.700859 8.77834 0.78957 8.74632 0.859799 8.67977C0.924681 8.61829 0.965512 8.53496 0.98943 8.43384C1.00551 8.34748 1.02835 8.28108 1.06044 8.2215C1.09283 8.16137 1.13175 8.11031 1.17758 8.06739C1.22183 8.02597 1.27276 7.99354 1.33154 7.9697C1.38898 7.94639 1.45271 7.9345 1.52396 7.9345C1.59636 7.9345 1.6622 7.94628 1.72258 7.96951C1.77843 7.99099 1.82585 8.02235 1.86668 8.06453C1.90638 8.10554 1.93889 8.15965 1.96401 8.22924C1.98995 8.30108 2.00364 8.3893 2.00364 8.49461C2.00364 8.60504 1.99032 8.70732 1.96398 8.80204C1.93637 8.90131 1.89659 8.99996 1.84435 9.09816C1.79006 9.20022 1.72434 9.30259 1.64704 9.40519C1.59261 9.47746 1.53418 9.55191 1.47178 9.62853L1.37377 9.74678L0.14127 11.1904L0.0959231 11.2507C0.0685092 11.2926 0.0468203 11.3377 0.0313988 11.3857L0.0116445 11.464L0.000591619 11.5613L-0.000244141 12H2.99976V11.4339L2.99347 11.3529C2.98089 11.2741 2.94933 11.2025 2.89828 11.143C2.84932 11.0859 2.78702 11.0467 2.71625 11.028L2.64058 11.0168L1.43376 11.0145L2.22792 10.066L2.37864 9.87746C2.48162 9.74614 2.57281 9.60997 2.6521 9.469C2.73391 9.32356 2.79892 9.17135 2.84695 9.01265C2.89698 8.84731 2.92175 8.66916 2.92175 8.47892C2.92175 8.25418 2.8873 8.04961 2.81728 7.8665C2.74735 7.68361 2.65004 7.52624 2.52564 7.3963C2.40092 7.26601 2.25292 7.16675 2.08351 7.09923Z"
        fill={props?.color ? props.color : '#545e6b'}/>
      <path
        d="M11.8678 0.741405H5.86784C5.45363 0.741405 5.11784 1.07719 5.11784 1.49141C5.11784 1.90562 5.45363 2.24141 5.86784 2.24141H11.8678C12.2821 2.24141 12.6178 1.90562 12.6178 1.49141C12.6178 1.07719 12.2821 0.741405 11.8678 0.741405Z"
        fill={props?.color ? props.color : '#545e6b'}/>
      <path
        d="M11.8678 5.24141H5.86784C5.45363 5.24141 5.11784 5.57719 5.11784 5.99141C5.11784 6.40562 5.45363 6.74141 5.86784 6.74141H11.8678C12.2821 6.74141 12.6178 6.40562 12.6178 5.99141C12.6178 5.57719 12.2821 5.24141 11.8678 5.24141Z"
        fill={props?.color ? props.color : '#545e6b'}/>
      <path
        d="M11.8678 9.74141H5.86784C5.45363 9.74141 5.11784 10.0772 5.11784 10.4914C5.11784 10.9056 5.45363 11.2414 5.86784 11.2414H11.8678C12.2821 11.2414 12.6178 10.9056 12.6178 10.4914C12.6178 10.0772 12.2821 9.74141 11.8678 9.74141Z"
        fill={props?.color ? props.color : '#545e6b'}/>
    </svg>


  );
};

export default SvgNumberedList2;
