import React from "react";

export const NodeStrategy = (props: any) => {
  function openKnowledgeGraph(): void {
    if (!props?.readOnly) {
      props.openKnowledgeGraph(true, props.entityKey);
    }
  }

  // onclick clickNode
  return (
    <span onClick={openKnowledgeGraph} id={props.entityKey} className={!props?.readOnly ? "node pointer" : "node"}>
      {props.children}
    </span>
  );
};
