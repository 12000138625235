import React from 'react';

const SvgDislike = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.4 0H2.08C0.931248 0 0 0.89543 0 2V10C0 11.1046 0.931248 12 2.08 12H6.24V14C6.24 15.1046 7.17125 16 8.32 16H10.4V0ZM16 12V0H12V12H16Z"
            fill="#545E6B"/>
    </svg>

  );
};

export default SvgDislike;
