import React from 'react';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalContextProvider } from './store/global-context';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';
import {
  FileProgressContextProvider
} from "./_shared/components/uploader-popup/file-progress-context/file-progress-context";
import { SyncSurveysContextProvider } from "./store/sync-surveys-context";


const rootElement: HTMLElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 6,
    }
  }
});

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <SyncSurveysContextProvider>
          <FileProgressContextProvider>
            <App/>
          </FileProgressContextProvider>
        </SyncSurveysContextProvider>
      </GlobalContextProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
