import React, { useContext, useState } from 'react';
import './select-hub.scss'
import { Button } from "antd";
import "../layout/layout.scss"
import GlobalContext from "../../../store/global-context";


const SelectHub = () => {
  const globalContext = useContext(GlobalContext);
  const [loading, setLoading] = useState<string | number | undefined>(undefined);

  const getInitials = (organizationName: string) => {
    let initials = "";
    organizationName.split(" ").forEach((word: string) => {
      initials = initials + word.substring(0, 1).toUpperCase();
    })
    return initials.substring(0, 3);
  }

  const handleClick = (organization: any) => {
    if (loading) {
      return
    }

    setLoading(organization.organizationID)
    globalContext.switchOrganizations(organization);
  }

  return (
    <div className='select-hub-wrapper'>
      <div className='hub-wrapper column justify-space-center'>
        <h1>Welcome to {globalContext.user?.organization.name} InsightsHub</h1>
        <Button type='primary' onClick={() => handleClick({organizationID: "AGENCY"})}>Explore my
          Hub</Button>
      </div>
      <div className='client-hubs-wrapper'>
        <div className='title'>Client hubs</div>
        <div className='client-hubs'>
          {
            globalContext.partnerOrganizations &&
            globalContext.partnerOrganizations.map((organization: any, i: number) => {
              return <div key={i} onClick={() => handleClick(organization)} className='client-hub'>
                <div className='client-logo-wrapper'>
                  <div className='client-initials'>
                    {getInitials(organization.name)}
                  </div>
                  {/*<img*/}
                  {/*  src='https://insights-desk-public-bucket.s3.us-east-2.amazonaws.com/3e809fff-9ddf-43af-a9e6-76dce01a625e-unnamed.png'*/}
                  {/*  alt='' className='client-logo'/>*/}
                </div>
                <div className='client-details'>
                  <div className='client-name'>
                    {organization.name}
                  </div>
                  <div className='client-members'>
                    {organization.userCount} members
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  );
};

export default SelectHub;
