import * as React from "react";

const SvgItalic = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-971.000000, -208.000000)">
          <g id="Italics" transform="translate(971.000000, 208.000000)">
            <rect id="Rectangle-Copy-4" x="0" y="0" width="16" height="16"/>
            <polygon id="Combined-Shape" fill="#545E6B" fillRule="nonzero"
                     points="12 2 12 3 9.881 3 7.154 13 10 13 10 14 4 14 4 13 6.117 13 8.845 3 6 3 6 2"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgItalic;
