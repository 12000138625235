export function SvgIdle(props: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9 8V4H7V10H11V8H9ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z"
            fill={props.color ? props.color : '#9B9B9B'}/>
    </svg>

  )
}
