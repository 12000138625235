import React from "react";
import { DraftDecoratorComponentProps } from "draft-js";

export const TaskStrategy = ({contentState, entityKey, children}: DraftDecoratorComponentProps | any) => {
  // onclick task
  return (
    <span id={entityKey} className="task">
      {children}
    </span>
  );
};
