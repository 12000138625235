import React from 'react';
import SvgClose from "../../../../../../assets/icons/js/Close";
import './trending-prompts.scss';
import SvgFire from "../../../../../../assets/icons/js/fire";
import { useQuery } from "react-query";
import HomeApi from "../../../../api/home-api";

type TrendingPromptsProps = {
  onCancelClick: () => void;
  onSendClick: (text: string) => void;
}
const TrendingPrompts = ({onCancelClick, onSendClick}: TrendingPromptsProps) => {
  const trendingPrompts = useQuery('trendingPrompts', () => HomeApi.getTrendingPrompts())
  const handleOnPromptClick = (text: string) => {
    onSendClick(text);
    onCancelClick();
  }

  return (
    <div className="trending-prompts-wrapper">
      <div className="trending-prompts-header">
        <div className="row align-items-center" style={{gap: 10}}>
          <div><SvgFire/></div>
          <h3>Trending prompts</h3>
        </div>
        <button onClick={onCancelClick} data-testid={"cancel-btn"} className="absolute reset-btn"
                style={{right: 20, top: 5}}><SvgClose/></button>
      </div>
      <div className="trending-suggestions">
        {trendingPrompts.data?.map((data: { question: string }, i: number) => (
          <div key={i} className="trending-suggestion" onClick={() => handleOnPromptClick(data.question)}>
            {data.question}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingPrompts;
