import { Button, Dropdown, Menu } from 'antd'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SvgArrowBack from '../../../assets/icons/js/ArrowBack'
import SvgArrowDown from '../../../assets/icons/js/ArrowDown'
import SvgSuccess from '../../../assets/icons/js/Success'
import qs from "query-string"
import "./story-edit.scss"
import SvgSaving from '../../../assets/icons/js/Saving'
import LinkedProjects from './linked-projects/linked-projects'
import PublishStoryModal from './publish-story-dialog/publish-story-dialog'
import { StoryEditor } from './story-editor/story-editor';
import { StoryStatusEnum } from '../model/story-status.enum'
import storiesApi from '../api/stories-api'
import { Loader } from '../../../_shared/components/loader/loader'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { RetrieveHighlightsFromStoryEditor } from './story-editor/helpers/retrieve-highlights-from-story-editor'
import useDebounce from '../../../_shared/helpers/use-debounce.hook'
import { usePrevious } from '../../../core/hooks/use-previous'
import isEqual from 'lodash.isequal'
import { IStory } from '../model/story-interface'
import SvgEdit from '../../../assets/icons/js/Edit'
import MediaDialog from '../../../_shared/components/media-dialog/media-dialog'
import StoryContext from './story-edit.context';
import SvgNoImage from "../../../assets/icons/js/noImage";
import { IProject } from "../../projects/models/project.interface";
import RecommendationHighlightsContainer
  from "./story-editor/components/recommendation-highlights-container/recommendation-highlights-container";
import { manageHighlights } from "./story-editor/helpers/manage-highlights";
import { getDisplayHighlights } from "./story-editor/helpers/get-display-highlights";

const StoryEdit = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam: any = qs.parse(location.search);
  const storyContext = useContext(StoryContext);
  const {editorState, onSetEditorState} = storyContext;
  const slug: any = useRef(null);
  const [loadedImages, setLoadedImages] = useState(false);
  const [getStoryLoading, setGetStoryLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [publishStoryModal, setPublishStoryModal] = useState<any>({visible: false})
  const [mediaDialog, setMediaDialog] = useState<any>({visible: false})
  const [linkedProjects, setLinkedProjects] = useState<any[]>([]);
  const [nonTextLinkedProjects, setNonTextLinkedProjects] = useState<IProject[]>([])
  const [highlights, setHighlights] = useState<any>(null);
  const [id, setId] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const [cover, setCover] = useState<string>();
  const [prevCover, setPrevCover] = useState<any>(cover);
  const [title, setTitle] = useState("");
  const prevTitle = usePrevious<any>(title);
  const [rawEditorState, setRawEditorState] = useState<any>();
  const debounceRawEditorState = useDebounce(rawEditorState, 600);
  const prevRawEditorState = usePrevious<any>(debounceRawEditorState);
  const prevNonTextLinkedProjects = usePrevious<any>(nonTextLinkedProjects)

  const [story, setStory] = useState<IStory | any>();
  const [tags, setTags] = useState<any[]>([]);
  const prevTags = usePrevious<any>(tags);

  const [displayTextHighlights, setDisplayTextHighlights] = useState<any>()

  useEffect(() => {
    if (!editorState) {
      return;
    }

    onContentChange(editorState);

  }, [editorState]);

  useEffect(() => {
    if (!editorState) {
      return
    }

    manageHighlights(editorState)
    setDisplayTextHighlights(getDisplayHighlights(editorState))

  }, [loadedImages, editorState]);


  useEffect(() => {
    if (!id) {
      const initialEditorState = EditorState.createEmpty();
      onSetEditorState(initialEditorState);
      // onContentChange(initialEditorState);
      return;
    }
  }, []);


  useEffect(() => {
    window.onbeforeunload = (e: any) => {
      if (saving) {
        return 'Are you sure you want to leave?';
      } else {
        e.preventDefault();
      }
    }

  }, [saving]);

  useEffect(() => {
    const storyId: any = parseInt(urlParam?.id) || id;
    setStoryId(storyId);
  }, [])

  useEffect(() => {
    if (debounceRawEditorState && !!prevRawEditorState) {
      if (isEqual(debounceRawEditorState, prevRawEditorState)
        && title === prevTitle
        && tags === prevTags
        && prevCover === cover
        && prevNonTextLinkedProjects === nonTextLinkedProjects) {
        return;
      }


      const modifiedNonTextLinkedProjects = nonTextLinkedProjects ? nonTextLinkedProjects.map((project) => {
        return {
          text: "",
          projectId: project.id
        }
      }) : [];

      const modifiedHighlights: any = {
        projects: highlights?.projects ? [...highlights.projects.map((value: any) => {
          return {
            text: value.text,
            projectId: value.project.id
          }
        }), ...modifiedNonTextLinkedProjects] : [...modifiedNonTextLinkedProjects]
      }

      const payload = {
        title: title,
        tags: tags?.length ? tags?.map((tag: any) => tag.id) : [],
        content: rawEditorState,
        cover: cover,
        highlights: modifiedHighlights
      }

      createUpdateStory(payload);
    }
  }, [debounceRawEditorState, title, tags, cover, nonTextLinkedProjects]);

  const createUpdateStory = (payload: any) => {
    setSaving(true);

    if (!id) {
      storiesApi.createStory(payload).then((response) => {
        if (response?.id) {
          if (location.state?.linkedProject) {
            setNonTextLinkedProjects([location.state?.linkedProject])
          }
          setId(response?.id);
          navigate({pathname: '', search: qs.stringify({id: response.id})}, {replace: true, state: location.state})
        }
        setSaving(false);
      })

      return;
    }

    storiesApi.updateStory(id, payload).then(() => {
      setSaving(false);
    });
  }

  const setStoryId = (storyId: number | undefined): void => {
    if (storyId) {
      setId(storyId);
      getById(storyId);
    }
  }

  const getById = (id: number) => {
    setLoading(true);

    setGetStoryLoading(true);

    storiesApi.getStory(id)
      .then((response: IStory) => {
        setTitle(response.title);
        setRawEditorState(response.content);
        const content = response.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.content))) : EditorState.createEmpty();
        onSetEditorState(content)
        setLinkedProjects(RetrieveHighlightsFromStoryEditor(content).projects)
        setNonTextLinkedProjects(response.nonTextLinkedProjects);
        // storyContext.onSetEditorState(content)
        setCover(response.cover);
        setStory(response);
        setTags(response.tags);
        setLoading(false);
        setGetStoryLoading(false);
      }).catch(() => {
      //TODO: handle error
    });
  }

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }

  const onContentChange = (editorState: EditorState) => {
    setRawEditorState(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    const highlights = RetrieveHighlightsFromStoryEditor(editorState);
    setHighlights(highlights);
    setLinkedProjects(highlights?.projects);
  }

  const onTagsChange = (tags: any) => {
    setTags(tags);
  }

  const onProjectAssign = (project: any) => {
    setNonTextLinkedProjects((prev) => [...prev, project])

    setTags((prev) => [...prev, ...project.projectTags.map((tag: any) => tag.tag).filter((tag: {
      id: any
    }) => !prev.some(prevTag => prevTag.id === tag.id))])
  }

  const closePublishStoryModal = (data?: { published?: boolean }) => {
    if (data?.published) {
      if (id) {
        getById(id);
      }
    }
    setPublishStoryModal({...publishStoryModal, visible: false})
  }

  const openPublishStoryModal = () => {
    let storyCover: Element | null = document.querySelector(".DraftEditor-root figure img");
    setPublishStoryModal({
      ...publishStoryModal,
      id,
      title,
      imageSrc: cover || storyCover?.getAttribute("src"),
      visible: true,
    })
  }

  const closeMediaDialog = () => {
    setMediaDialog({visible: false})
  }

  const openMediaDialog = () => {
    setMediaDialog({visible: true})
  }

  function navigateBack(): void {
    const state: any = location.state;
    if (state?.fromHome) {
      navigate("/home")
    }

    if (state?.fromAiChat) {
      navigate("/home?tab=2");
      return
    }

    if (state?.linkedProject) {
      navigate({pathname: '/projects/edit', search: qs.stringify({id: state.linkedProject.id})})
      return;
    }
    if (state?.projectId) {
      navigate({pathname: '/projects/edit', search: qs.stringify({id: state.projectId})})
      return;
    }

    navigate({pathname: '/stories', search: qs.stringify({tab: story?.status})})

  }

  const handleSlugClick = () => {
    slug.current.select()
  }

  const draftStory = (id: number) => {
    setStatusLoading(true);
    storiesApi.draftStory(id).then(() => {
      setStory({
        ...story,
        status: StoryStatusEnum.DRAFT
      })
      setStatusLoading(false);
    })
  }

  const openPreviewStory = () => {
    window.open(`${window.location.origin}/stories/preview?id=${id}`)
  }

  const changeCover = (data: { url: string }) => {
    setCover(data.url);
  }

  const updatedLoadedImages = (lI: boolean) => {
    setLoadedImages(lI)
  }

  return (
    <div className='story-edit-wrapper column'>
      <div className='story-edit column'>
        <div className="intake-form-header justify-space-between">
          <div className="row align-items-center">
            <button style={saving ? {opacity: 0.5} : {opacity: 1}}
                    className="icon-button back"
                    onClick={navigateBack}>
              <SvgArrowBack/>
            </button>
            <span className="font-weight-500">{story?.title?.length ? story?.title : "New Story"}</span>
          </div>
          {
            story?.status !== StoryStatusEnum.PUBLISHED &&
              <div className="row align-items-center">
                {
                  saving ?
                    <>
                      <SvgSaving/>
                      <span className="font-weight-300" style={{fontSize: '12px', marginLeft: 0}}>Saving ...</span>
                    </> :
                    <>
                      <SvgSuccess/>
                      <span className="font-weight-300"
                            style={{fontSize: '12px', marginLeft: 5}}>All changes saved</span>
                    </>
                }
              </div>
          }
          <div className="row align-items-center">
            {
              story?.status === StoryStatusEnum.PUBLISHED &&
                <input onClick={handleSlugClick} readOnly ref={slug} value={story?.slug} className='slug-container'/>
            }
            <Dropdown.Button
              loading={statusLoading}
              type="primary"
              icon={<SvgArrowDown color="#ffffff"/>}
              overlay={
                <Menu>
                  {
                    story?.status === StoryStatusEnum.PUBLISHED ?
                      <Menu.Item key="1" onClick={() => draftStory(id)}>
                        Draft Story
                      </Menu.Item> :
                      <Menu.Item key="2" onClick={() => openPublishStoryModal()}>
                        Publish Story
                      </Menu.Item>
                  }
                  <Menu.Item onClick={openPreviewStory} key={"3"}>Preview</Menu.Item>
                </Menu>
              }>
              {story?.status === StoryStatusEnum.PUBLISHED ? "Published" : "Draft"}
            </Dropdown.Button>
          </div>
        </div>
        <div className="story-content row">
          {
            loading ? <Loader/> :
              <>
                <div style={{width: "50%"}}>
                  <div data-testid={"story-cover"} className='story-cover'
                       style={{
                         backgroundImage: cover ? `url(${cover})` : "",
                         backgroundColor: "#EEEEEE"
                       }}>
                    {
                      story?.status !== StoryStatusEnum.PUBLISHED &&
                      <Button onClick={openMediaDialog} className='story-cover-edit-btn'> <SvgEdit color="#1B87E6"/>
                        <span style={{marginLeft: 10}}>{cover ? "Edit cover image" : "Add cover image"}</span>
                      </Button>
                    }
                    {
                      !cover &&
                        <SvgNoImage width={80} height={64}/>
                    }
                  </div>
                  {editorState &&
                      <div className="row">
                          <StoryEditor
                            updateLoadedImages={updatedLoadedImages}
                            editorState={editorState}
                            setEditorState={onSetEditorState}
                            readOnly={story?.status === StoryStatusEnum.PUBLISHED}
                            onTitleChange={onTitleChange}
                            setTags={setTags}
                            onProjectAssign={onProjectAssign}
                            title={story?.title}/>
                        {
                          story?.status === StoryStatusEnum.PUBLISHED &&
                            <RecommendationHighlightsContainer displayTextHighlights={displayTextHighlights}
                                                               readOnly={false}
                            />
                        }
                      </div>
                  }
                </div>
                <LinkedProjects onProjectAssign={onProjectAssign}
                                nonTextLinkedProjects={nonTextLinkedProjects}
                                onTagsChange={onTagsChange}
                                tags={tags}
                                linkedProjects={linkedProjects}
                                readOnly={story?.status === StoryStatusEnum.PUBLISHED}/>
              </>
          }
        </div>
      </div>
      {
        publishStoryModal.visible &&
          <PublishStoryModal data={publishStoryModal} onCancel={closePublishStoryModal}/>
      }
      {
        mediaDialog.visible &&
          <MediaDialog onConfirm={changeCover} onCancel={closeMediaDialog} visible={mediaDialog.visible}/>
      }
    </div>
  )
}
export default StoryEdit
