import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { IProjectTable } from "../../../../pages/projects/models/project-table.interface";

interface IFilesProgressContext {
  accPercentage: number;
  updateFilesAreBeingChanged: () => void;
  updateFilesBeingUploaded: (file: any) => void;
  isUploading: boolean;
  updateUploading: (value: boolean) => void;
  uploadFinished: () => void;
  filesBeingUploaded: any[];
  cancelFile: (file: any) => void;
  updateProject: (project: IProjectTable) => void;
  project: IProjectTable | undefined,
}

const FileProgressContext = createContext<IFilesProgressContext>({} as IFilesProgressContext);

export function FileProgressContextProvider({children}: any) {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [filesBeingUploaded, setFilesBeingUploaded] = useState<any[]>([]);
  const [filesAreBeingChanged, setFilesAreBeingChanged] = useState(0);
  const [project, setProject] = useState<IProjectTable>();

  const accPercentage = useMemo(() => {
    return filesBeingUploaded.reduce(
      (acc: number, file: any) => acc + file.percent,
      0
    ) / filesBeingUploaded.length
  }, [filesAreBeingChanged]);

  useEffect(() => {
    if (!filesBeingUploaded.length) {
      return;
    }

    if (filesBeingUploaded.filter((file: any) => file.status === "error").length !== 0) {
      //showErrorToast()
      return;
    }

    if (filesBeingUploaded.filter((file: any) => file.status === "uploading").length === 0) {
      showSuccessToast();
      uploadFinished();

      if (project) {
        setTimeout(() => {
          navigate('/projects/edit?id=' + project.id + "&tab=3")
        }, 2000)
      }
    }
  }, [filesBeingUploaded, filesAreBeingChanged])

  const updateProject = (project: IProjectTable) => {
    setProject(project)
  }

  const updateFilesBeingUploaded = (file: any) => {
    setFilesBeingUploaded(prevList => [...prevList, file]);
  }

  const cancelFile = (file: any): void => {
    file.source.cancel("canceled");
    setFilesBeingUploaded(prev => prev.filter((files: any) => files.uid !== file.uid))
  };

  const updateUploading = (value: boolean) => {
    setIsUploading(value);
  }

  const updateFilesAreBeingChanged = () => {
    setFilesAreBeingChanged(prevState => prevState + 1);
  }

  const uploadFinished = (): void => {
    setIsUploading(false);
    setFilesBeingUploaded([]);
  }

  const showSuccessToast = () => {
    notification.open({
      message: "Done",
      description: `All your file have been uploaded successfully`,
      type: "success",
      className: "infobox-success",
      duration: 3,
      placement: "bottomRight",
    });
  };

  return <FileProgressContext.Provider
    value={{
      updateFilesBeingUploaded,
      updateUploading,
      updateFilesAreBeingChanged,
      isUploading,
      accPercentage,
      uploadFinished,
      filesBeingUploaded,
      cancelFile,
      updateProject,
      project
    }}>
    {children}
  </FileProgressContext.Provider>
}

export const useFileProgressContext = () => useContext(FileProgressContext)
