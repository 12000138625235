import React from 'react';
import './input.scss';

const Input = () => {
  return (
    <div className="input column">
      <div className="form-input-text-area">
        <span className="form-input-text-area-title">Multiple row answer row answer text</span>
        <div className="text-area">
          <div className="dash"/>
          <div className="dash"/>
          <div className="dash"/>
        </div>
      </div>
    </div>
  )
}

export default Input
