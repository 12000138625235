import { DebounceInput } from 'react-debounce-input';
import React, { useEffect, useRef, useState } from 'react';
import SvgAdd from '../../../../../../assets/icons/js/add';
import './key-metrics-input.scss';
import { Select } from 'antd';
import SvgDelete from '../../../../../../assets/icons/js/delete';
import isEqual from 'lodash.isequal';
import { usePrevious } from '../../../../../../core/hooks/use-previous';
import { Controller, useFormContext, useWatch } from "react-hook-form";

export function KeyMetricsInput(props: any): JSX.Element {
  const isFirstUpdate = useRef(true);
  const {control} = useFormContext();

  const type: string = useWatch({control, name: `keyMetrics.${props.inputKey}.type`});
  const name: string = useWatch({control, name: `keyMetrics.${props.inputKey}.name`});
  const id: string = useWatch({control, name: `keyMetrics.${props.inputKey}.id`})

  const prevName = usePrevious<string>(name);
  const prevType = usePrevious<string>(type);

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }

    if (isEqual(type, prevType) && isEqual(prevName, name)) {
      return;
    }

    props.addKeyMetric({id, type, name})
  }, [id, name, type]);


  return (
    <div className="key-metrics-input">
      <div className="options">
        <div className="option row">
          <div className="outline-input-wrapper">
            <span className="outline-input-label">Name</span>
            <Controller control={control}
                        render={({field: {ref, ...field}}) => <DebounceInput
                          style={{width: 320}}
                          className="outline-input"
                          placeholder="Key Metric Name..."
                          debounceTimeout={600}
                          inputRef={ref}
                          {...field}/>}
                        name={`keyMetrics.${props.inputKey}.name`}/>
          </div>
          <div className="outline-input-wrapper">
            <span className="outline-input-label">Type</span>
            <Controller control={control}
                        render={({field}) => <Select {...field} style={{width: 112}}>
                          <Select.Option value="Numeric">Numeric</Select.Option>
                          <Select.Option value="Percentage">Percentage</Select.Option>
                        </Select>}
                        name={`keyMetrics.${props.inputKey}.type`}/>
          </div>
          <div className="input-options">
            {
              props.isAddAvailable &&
                <div className="option" onClick={() => props.addInput()}>
                    <SvgAdd color="#F5F5F5"/>
                </div>
            }
            {
              props.isDeleteAvailable &&
                <div className="option" onClick={() => {
                  props.deleteKeyMetric(id, props.inputKey)
                }}>
                    <SvgDelete color="#F5F5F5"/>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )

}
