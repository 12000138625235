import React, { useState } from 'react';
import { Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import repositoriesApi from "../../api/repositories-api";
import { queryClient } from "../../../../index";
import SvgTick from "../../../../assets/icons/js/Tick";

const RepositoriesLandingPage = () => {
  const [newRepositoryInput, setNewRepositoryInput] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required('Name is required!')
  })

  const form = useForm({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema)
  });

  const handleSubmit = (formData: { name: string }) => {
    repositoriesApi.createRepository(formData).then(() => {
      queryClient.invalidateQueries('repositories');
      message.success({
        content: 'Repository successfully created',
        className: 'custom-success-message',
        icon: <SvgTick/>
      })
    })
  }

  return (
    <div className='repositories-landing-page'>
      <h1>Welcome to Repositories</h1>
      <h2>Keep your files organized and accessible in customized repositories</h2>
      <h3>Create a new repository to get started: </h3>
      {
        newRepositoryInput ? <div className="row" style={{gap: 10}}>
            <div className="outline-input-wrapper">
              <input className="outline-input"
                     style={{width: 200}}
                     type="text"
                     {...form.register('name')}
                     placeholder={"Repository name"}/>
              <ErrorMessage name={"name"} errors={form.formState.errors}
                            render={({message}) => <small className="form-error">{message}</small>}/>
            </div>
            <Button type={"primary"} onClick={form.handleSubmit(handleSubmit)}>
              Create
            </Button>
          </div> :
          <Button type="primary"
                  onClick={() => setNewRepositoryInput(true)}
                  icon={<PlusOutlined/>}>New
            repository</Button>
      }
    </div>
  );
};

export default RepositoriesLandingPage;
