import { environment } from '../../../../environment';
import { axiosClient } from '../../../../axios-client';
import { AxiosResponse } from 'axios';
import { IMetaTag, IMetaTagData } from '../../../settings/settings-tabs/taxonomy/meta-tags/model/meta-tag.interface';
import { IProjectStatusData } from '../project-status-dialog/model/project-status.interface';
import { INuggetData } from "../../models/nugget.interface";
import { IStory } from "../../../stories/model/story-interface";
import { IFile } from "../../../../_shared/model/files.Interface";
import { IPagination } from "../../../../core/model/pagination.interface";

class ProjectEditApi {
  baseUrl: string = `${environment.backendUrl}`;

  // async loadMetaTags(params: { page: number, pageSize: number }): Promise<IMetaTagData> {
  //   try {
  //     const response: AxiosResponse<IMetaTagData> = await axiosClient.get<IMetaTagData>(
  //       this.baseUrl + "/metatags",
  //       {params}
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }


  async deleteFiles(ids: number[]): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.delete(`${environment.backendUrl}/projects/files`, {
          data: {fileIDs: ids}
        });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async markAsPrivate(id: number, privacy: 0 | 1): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.put(`${environment.backendUrl}/projects/files/privacy/${id}`, {
          privacy
        });
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async assignFiles(projectId: number, payload: any): Promise<IFile[]> {
    try {
      const response: AxiosResponse<IFile[]> =
        await axiosClient.post<IFile[]>(`${environment.backendUrl}/projects/${projectId}/files`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async onSaveTags(dataId: any, payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        this.baseUrl + "/projects/tags/" + dataId,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async saveProjectDetails(projectId: number, payload: {
    title: string,
    projectTypeId: number,
    programId: number,
    folderId: number
  }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        this.baseUrl + "/projects/details/" + projectId,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async toggleAutoGenerateKeyFindings(projectId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        `${this.baseUrl}/projects/toggle-auto-generate-key-findings`,
        {
          projectId,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async submitRecommendation(payload: any): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.put(
  //       this.baseUrl + "/projects/recommendations",
  //       payload
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  //
  // async submitNuggets(payload: any): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.put(
  //       this.baseUrl + "/projects/nuggets",
  //       payload
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  //
  // async submitTheme(payload: any): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.put(
  //       this.baseUrl + "/projects/themes",
  //       payload
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async submitFindings(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        this.baseUrl + "/projects/findings",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async downloadFile(fileId: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(
        `${environment.backendUrl}/projects/download/${fileId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async moveFile(
  //   projectId: number,
  //   fileId: any,
  //   workFlowId: any
  // ): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.put(
  //       `${environment.backendUrl}/projects/${projectId}/files/move/${fileId}`,
  //       {workflowStatusId: workFlowId}
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async descriptionChange(
    projectId: number,
    fileId: any,
    workFlowId: any
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        `${environment.backendUrl}/projects/${projectId}/files/${fileId}`,
        {description: workFlowId}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async loadFiles(projectId: number, params: { page: number, size: number, types: string | undefined }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(
        environment.backendUrl + "/projects/files/" + projectId,
        {
          params: params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async loadEvidences(nuggetId: number): Promise<any[]> {
  //   try {
  //     const response: AxiosResponse<any[]> = await axiosClient.get<any[]>(
  //       `${environment.backendUrl}/projects/nugget/${nuggetId}/evidences`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async uploadEvidenceImages(nuggetId: number): Promise<any[]> {
  //   try {
  //     const response: AxiosResponse<any[]> = await axiosClient.put<any[]>(
  //       `${environment.backendUrl}/projects/nuggets/${nuggetId}/images`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async deleteEvidenceImages(nuggetId: number, imageId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(
        `${environment.backendUrl}/projects/nuggets/${nuggetId}/image/${imageId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async getActivityLogs(projectId: any, query?: string): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.get<any>(
  //       `/notifications/projects-logs/${projectId}/${
  //         query?.length ? query : ""
  //       }`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getProjectStatusData(projectId: number): Promise<IProjectStatusData> {
    try {
      const response: AxiosResponse<IProjectStatusData> =
        await axiosClient.get<IProjectStatusData>(
          `${this.baseUrl}/projects/status/${projectId}/history`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateProjectStatusData(projectId: number, payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        `${this.baseUrl}/projects/status/${projectId}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectNuggets(
    projectId: number,
    params: any
  ): Promise<INuggetData> {
    try {
      const response: AxiosResponse<INuggetData> =
        await axiosClient.get<INuggetData>(
          `${this.baseUrl}/projects/nuggets/${projectId}`,
          {params}
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createNugget(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post(
        `${this.baseUrl}/projects/nuggets`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateNugget(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put(
        `${this.baseUrl}/projects/nuggets`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteNugget(nuggetId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(
        `${this.baseUrl}/projects/nuggets/${nuggetId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getNuggetEvidences(nuggetId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get(
        `${this.baseUrl}/projects/nuggets/${nuggetId}/evidences`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectStories(projectId: number, params: { page: number, pageSize: number }): Promise<{
    content: IStory[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<{ content: IStory[], pagination: IPagination }> = await axiosClient.get<{
        content: IStory[],
        pagination: IPagination
      }>(`${this.baseUrl}/story/project/${projectId}`, {
        params
      });
      return response.data
    } catch (error) {
      throw error;
    }
  }
}

export default new ProjectEditApi();
