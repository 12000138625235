import * as React from "react";

const SvgCheck = (props: any) => {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <g>
        <path fill={props.color ? props.color : '#545e6b'}
              stroke={props.color ? props.color : '#545e6b'}
              d="M0 0H16V16H0z"
              transform="translate(-168 -387) translate(169 388)"/>
        <path fill="#FFF" fillRule="nonzero" d="M6.62 8.95L11.57 4 13.041 5.471 6.677 11.835 3 8.158 4.414 6.744z"
              transform="translate(-168 -387) translate(169 388)"/>
      </g>
    </svg>
  );
}

export default SvgCheck;
