import { ContentBlock, EditorState, genKey, Modifier, SelectionState } from "draft-js";
import { extractSelectionDetails } from "./extract-selection-details";
import immutable from "immutable";

const insertAiPromptAfterSelection = (editorState: EditorState, generateFrom: "content" | "linkedProjects") => {
  // Step 1: Retrieve Current State
  let contentState = editorState.getCurrentContent();
  let selectionState = editorState.getSelection();
  const selectionDetails = extractSelectionDetails(editorState);

  // Step 2: Determine the Block to Insert After
  let targetBlockKey: string | undefined;
  if (selectionState.isCollapsed()) {
    // Selection is collapsed; use the block containing the cursor
    targetBlockKey = selectionState.getStartKey();

    const anchorOffset = selectionState.getAnchorOffset();
    if (anchorOffset > 0) {
      const block = contentState.getBlockForKey(targetBlockKey);
      const blockText = block.getText();
      if (blockText[anchorOffset - 1] === "/") {
        // Create a selection state that selects the "/" character
        const charSelection = selectionState.merge({
          anchorOffset: anchorOffset - 1,
          focusOffset: anchorOffset,
          isBackward: false,
        }) as SelectionState;

        // Remove the "/" character
        contentState = Modifier.removeRange(
          contentState,
          charSelection,
          "backward"
        );

        // Adjust the selectionState, since we removed a character
        selectionState = selectionState.merge({
          anchorOffset: anchorOffset - 1,
          focusOffset: anchorOffset - 1,
        }) as SelectionState;
      }
    }
  } else {
    // Selection is not collapsed; use the block where the selection ends
    targetBlockKey = selectionState.getEndKey();
  }

  // Get the block map
  const blockMap = contentState.getBlockMap();

  // Step 3: Create a New Block
  const newBlockKey = genKey();

  const newBlock = new ContentBlock({
    key: newBlockKey,
    type: "ai-prompt",
    text: "",
    data: immutable.Map({
      selectionDetails: selectionDetails,
      generateFrom: generateFrom,
    }),
  });

  // Step 4: Manipulate the Block Map
  const blocksBefore = blockMap.takeUntil(
    (block) => block?.getKey() === targetBlockKey
  );
  const targetBlock = blockMap.get(targetBlockKey);
  const blocksAfter = blockMap
    .skipUntil((block) => block?.getKey() === targetBlockKey)
    .rest();

  const newBlocks = blocksBefore
    .concat(
      [[targetBlock.getKey(), targetBlock]],
      [[newBlock.getKey(), newBlock]],
      blocksAfter
    )
    .toOrderedMap();

  // Step 5: Create a New Content State
  const newContentState = contentState.merge({
    blockMap: newBlocks,
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  }) as any;

  // Step 6: Push to Editor State
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    "insert-fragment",
  );

  return EditorState.acceptSelection(newEditorState, selectionState)
};

export { insertAiPromptAfterSelection };
