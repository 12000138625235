import React, { useEffect, useState } from 'react';
import './folder-section.scss';
import { TableRowSelection } from "antd/es/table/interface";
import { ColumnsType } from "antd/es/table";
import { Table, Tooltip } from "antd";
import { DebounceInput } from "react-debounce-input";
import SvgInfoCircleFilled from "../../../../../../../../assets/icons/js/InfoCircleFilled";
import usePagination from "../../../../../../../../core/hooks/use-pagination";
import Pagination from "../../../../../../../../_shared/components/pagination/pagination";

type IProps = {
  totalFolders: number;
  updatePartialImport: (partialImport: boolean) => void;
  updateSelectedRowKeys: (selectedRowKeys: React.Key[]) => void;
  selectedRowKeys: React.Key[];
  allFolders: any[];
  loading: boolean;
}
const FolderSection = (props: IProps) => {
  const columns: ColumnsType<{
    folderName: string;
    folderId: number;
  }> = [
    {
      title: "Folder Name",
      dataIndex: 'folderName'
    },
    {
      title: "Surveys",
      dataIndex: 'surveyCount',
    },
  ]

  const [page, setPage] = useState(1);
  const [filteredFolders, setFilteredFolders] = useState<any[]>(props.allFolders);
  const {items} = usePagination({currentPage: page, pageSize: 8}, filteredFolders);

  useEffect(() => {
    setFilteredFolders(props.allFolders)
  }, [props.allFolders]);

  const onSearchChange = (e: any) => {
    if (e.target.value === '') {
      setFilteredFolders(props.allFolders);
    } else {
      setFilteredFolders(props.allFolders.filter(folder => folder.folderName.toLowerCase().includes(e.target.value.toLowerCase())));
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: {
    folderName: string,
    folderId: number
  }[]) => {
    props.updateSelectedRowKeys(newSelectedRowKeys);
    
    if (newSelectedRowKeys.length !== props.allFolders.length) {
      props.updatePartialImport(true);
    } else {
      props.updatePartialImport(false);
    }
  };

  const rowSelection: TableRowSelection<{
    folderName: string;
    folderId: number;
  }> = {
    selectedRowKeys: props.selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ]
  }

  const updatePage = (page: number, pageSize?: number) => {
    setPage(page)
  }

  return (
    <div className="folder-section-wrapper">
      <header className="justify-space-between column">
        <div className="row " style={{gap: 10}}>
          <p>Select the folder(s) to import into InsightsHub </p>
          <Tooltip
            style={{width: "max-content"}}
            title="Syncing surveys can take up to 5 minutes"> <SvgInfoCircleFilled color={"#1B3380"}/> </Tooltip>
        </div>
        <div className="row justify-space-between" style={{marginBottom: 10}}>
          <DebounceInput
            placeholder="Search for a folder"
            className="outline-input"
            onChange={onSearchChange}
            debounceTimeout={600}
            style={{width: '80%'}}/>
          {
            filteredFolders.length > 0 &&
            <Pagination totalItems={filteredFolders.length} pageSize={8} onChange={updatePage} currentPage={page}/>
          }

        </div>
      </header>
      <div>
        <Table columns={columns}
               pagination={false}
               scroll={{y: 300}}
               rowKey={"folderId"}
               rowSelection={rowSelection}
               dataSource={items}
        />
      </div>
    </div>
  );
};

export default FolderSection;
