// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-type {
  position: relative;
}
.project-type .section-title {
  display: flex;
  align-items: center;
  background-color: #EBEBEB;
  margin: 16px 0px;
  padding: 4px;
  font-size: 14px;
}
.project-type .section-title h4 {
  color: #545E6B;
  margin: 0;
  text-align: center;
}

.add-new {
  width: 244px;
  max-width: 244px;
  min-height: 84px;
  max-height: 94px;
  transition: background-color 200ms, box-shadow 200ms;
  cursor: pointer;
  text-align: center;
  padding: 0;
  margin: 8px;
  border: 0;
}
.add-new:hover {
  background-color: rgba(216, 216, 216, 0.2);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.add-new .body {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.add-new .body .text {
  color: #1B87E6;
  font-size: 12px;
  padding-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/taxonomy/project-type/project-type.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AACJ;AACI;EACE,cAAA;EACA,SAAA;EACA,kBAAA;AACN;;AAIA;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,oDAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;AADF;AAGE;EACE,0CAAA;EACA,+GAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAII;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AAFN","sourcesContent":[".project-type {\n  position: relative;\n\n  .section-title {\n    display: flex;\n    align-items: center;\n    background-color: #EBEBEB;\n    margin: 16px 0px;\n    padding: 4px;\n    font-size: 14px;\n\n    h4 {\n      color: #545E6B;\n      margin: 0;\n      text-align: center;\n    }\n  }\n}\n\n.add-new {\n  width: 244px;\n  max-width: 244px;\n  min-height: 84px;\n  max-height: 94px;\n  transition: background-color 200ms, box-shadow 200ms;\n  cursor: pointer;\n  text-align: center;\n  padding: 0;\n  margin: 8px;\n  border: 0;\n\n  &:hover {\n    background-color: rgba(216, 216, 216, 0.2);\n    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);\n  }\n\n  .body {\n    display: flex;\n    flex-direction: row;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n\n    .text {\n      color: #1B87E6;\n      font-size: 12px;\n      padding-left: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
