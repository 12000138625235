import * as React from "react";

const SvgCollaboration = (props: any) => {
  return (
    <svg width={12} height={10} viewBox="0 0 12 10" {...props}>
      <g id="Nov-9-21" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01-My-page---Details-tab--Copy-5" transform="translate(-1220.000000, -147.000000)" fill="#545E6B">
          <g id="Group-5-Copy" transform="translate(1220.000000, 140.000000)">
            <g id="Icon/SideNav/List" transform="translate(0.000000, 6.000000)">
              <path
                d="M4.5,1.5 C6.15685425,1.5 7.5,2.84314575 7.5,4.5 C7.5,5.29855904 7.18798995,6.02424422 6.67922703,6.56179836 C8.06307167,7.32920718 9,8.80518792 9,10.5 L0,10.5 C0,8.80518792 0.936928334,7.32920718 2.32108614,6.56175664 C1.81201005,6.02424422 1.5,5.29855904 1.5,4.5 C1.5,2.84314575 2.84314575,1.5 4.5,1.5 Z M7.5,1.5 C9.15685425,1.5 10.5,2.84314575 10.5,4.5 C10.5,5.29855904 10.1879899,6.02424422 9.67922703,6.56179836 C11.0098469,7.29969145 11.9272675,8.69269912 11.9958699,10.3054438 L12,10.5 L10.5,10.5 L10.4958699,10.3054438 C10.4272675,8.69269912 9.50984687,7.29969145 8.17922703,6.56179836 C8.68798995,6.02424422 9,5.29855904 9,4.5 C9,3.10209744 8.04388897,1.92750658 6.74993618,1.59449669 C6.98975955,1.53280893 7.24104831,1.5 7.5,1.5 Z"
                id="Combined-Shape-Copy-24"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgCollaboration;
