// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nugget-card-wrapper {
  padding: 8px 16px;
  min-height: 65px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #FFFFFF;
  border: 1px solid #E8E8E8;
  transition: 0.15s linear;
}
.nugget-card-wrapper:hover {
  border: 1px solid #1B87E6;
}
.nugget-card-wrapper:hover .action-buttons-wrapper .action-buttons-hover {
  opacity: 1;
}
.nugget-card-wrapper .nugget-description {
  width: 90%;
  color: #545e6b;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.nugget-card-wrapper .nugget-creator {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  margin-top: 10px;
}
.nugget-card-wrapper .nugget-node-wrapper {
  padding: 8px 0;
}
.nugget-card-wrapper .nugget-node-wrapper .empty-wrapper {
  margin-top: -20px;
  margin-bottom: -38px;
}
.nugget-card-wrapper .action-buttons-wrapper {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}
.nugget-card-wrapper .action-buttons-wrapper .action-buttons-hover {
  display: flex;
  gap: 10px;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/nuggets/nugget-card/nugget-card.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,wBAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEM;EACE,UAAA;AAAR;AAKE;EACE,UAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAJJ;AAOE;EACE,cAAA;AALJ;AAOI;EACE,iBAAA;EACA,oBAAA;AALN;AASE;EACE,UAAA;EACA,aAAA;EACA,yBAAA;EACA,uBAAA;EACA,SAAA;AAPJ;AASI;EACE,aAAA;EACA,SAAA;EACA,UAAA;AAPN","sourcesContent":[".nugget-card-wrapper {\n  padding: 8px 16px;\n  min-height: 65px;\n  height: fit-content;\n  background-color: #FFFFFF;\n  border: 1px solid #E8E8E8;\n  transition: .15s linear;\n\n  &:hover {\n    border: 1px solid #1B87E6;\n\n    .action-buttons-wrapper {\n      .action-buttons-hover {\n        opacity: 1;\n      }\n    }\n  }\n\n  .nugget-description {\n    width: 90%;\n    color: #545e6b;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n  }\n\n  .nugget-creator {\n    font-size: 12px;\n    font-weight: 300;\n    line-height: 14px;\n    margin-top: 10px;\n  }\n\n  .nugget-node-wrapper {\n    padding: 8px 0;\n\n    .empty-wrapper {\n      margin-top: -20px;\n      margin-bottom: -38px;\n    }\n  }\n\n  .action-buttons-wrapper {\n    width: 10%;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-start;\n    gap: 10px;\n\n    .action-buttons-hover {\n      display: flex;\n      gap: 10px;\n      opacity: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
