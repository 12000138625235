import './search-results.scss';
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import homeApi from '../../api/home-api';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search } from '../../components/search/search';
import { ISearchData } from '../../models/search-data.interface';
import { useQuery, UseQueryResult } from 'react-query';
import { ISearchProject } from './models/search-project';
import { ITag } from '../../../../_shared/model/tag.interface';
import { renderToString } from 'react-dom/server';
import { Projects } from './components/projects/projects';
import { Nuggets } from './components/nuggets/nuggets';
import { ISearchNugget, ISearchNuggetData } from './models/search-nugget';
import { SearchTypeEnum } from './models/search-type.enum';
import { LoadingOutlined } from '@ant-design/icons';
import Users from "./components/users/users";
import { ISearchUser, ISearchUsersData } from "./models/search-users.interface";
import { ISearchStoriesData, IStorySearchResult } from "./models/search-stories.interface";
import Stories from "./components/stories/stories";
import SearchKnowledgeGraphs from "./components/search-knowledge-graphs/search-knowledge-graphs";
import SearchSnapshots from "./components/search-snapshots/search-snapshots";
import SearchSurveys from "./components/search-surveys/search-surveys";
import { ISearchFile, ISearchFileData } from "./models/search-file.interface";
import Files from "./components/files/files";

export default function SearchResults(props: { searchData?: ISearchData, matchTag?: ITag }) {
  const divRef: any = React.createRef();
  const location = useLocation();
  const navigate: any = useNavigate();
  const urlParam: ISearchData = qs.parse(location?.search);
  const isModalSearch = !!props.searchData
  const [searchData, setSearchData] = useState<ISearchData>(props.searchData ? props.searchData : urlParam ? {
    ...urlParam,
  } : {searchType: SearchTypeEnum.ANY_WORD});


  const searchProjects = useQuery([`searchProjects${JSON.stringify(searchData)}`, searchData], () => homeApi.getProjects(searchData ? searchData : {}), {
    refetchOnWindowFocus: false,
    select: (response: any) => {
      if (searchData && searchData.search?.length) {
        const newArr: ISearchProject[] = response.projects.filter((item: ISearchProject) => {
          if (searchData.searchType === SearchTypeEnum.EXACT) {
            return item.description?.toLowerCase().includes(searchData.search?.toLowerCase() as string) ||
              item.title?.toLowerCase().includes(searchData.search?.toLowerCase() as string) ||
              item.tags?.map((tag: ITag) => tag.name?.toLocaleLowerCase().includes(searchData.search?.toLocaleLowerCase() as string))
          } else {
            let returnValue = false;
            const words = searchData.search?.split(" ");
            words?.forEach((word: string) => {
              returnValue = returnValue ||
                item.description?.toLowerCase().includes(word.toLowerCase() as string) ||
                item.title?.toLowerCase().includes(word.toLowerCase() as string) ||
                item.tags?.map((tag: ITag) => tag.name?.toLocaleLowerCase().includes(word.toLocaleLowerCase() as string))
            })
            return returnValue;
          }
        })
          .map((project: ISearchProject) => {
            const words = searchData?.search?.split(" ");

            let newTitle = project?.title;
            let newDescription = project?.description;

            if (searchData?.searchType === SearchTypeEnum.EXACT) {
              newTitle = newTitle?.replace(
                new RegExp(searchData.search as string, 'gi'),
                match =>
                  `<span style="font-weight: 600; color: #eab308">${match}</span>`
              )

              newDescription = newDescription?.replace(
                new RegExp(searchData.search as string, 'gi'),
                match =>
                  `<span style="font-weight: 600; color: #eab308">${match}</span>`
              )
            } else {
              words?.forEach((word: string) => {
                if (word) {
                  newTitle = newTitle?.replace(
                    new RegExp(word as string, 'gi'),
                    match =>
                      `<span style='font-weight: 600; color: #eab308'>${match}</span>`)


                  newDescription = newDescription?.replace(
                    new RegExp(word as string, 'gi'),
                    match =>
                      `<span style="font-weight: 600; color: #eab308">${match}</span>`)
                }
              })
            }

            let newTags: any[] = project.tags.filter((tag: string) => {
              let tagName: any = tag;

              if (searchData?.searchType === SearchTypeEnum.EXACT) {
                if (tagName.toLowerCase() === searchData.search?.toLowerCase() as string) {
                  tagName = renderToString(<div className="tag-matched">{tag}</div>);
                }
              } else {
                words?.forEach((word: string) => {
                  if (tagName.toLowerCase() === word as string) {
                    tagName = renderToString(<div className="tag-matched">{tag}</div>);
                  }
                })
              }

              tag = tagName
              return tag;
            });

            // @ts-ignore

            return {
              ...project,
              title: newTitle,
              description: newDescription,
              tags: [...newTags],
            }
          });

        return {...response, projects: newArr, total: response.total};
      } else {
        return response;
      }
    },
    enabled: false
  });


  // const searchNotepads = useQuery([`searchNotepads${JSON.stringify({searchData})}`, searchData], () => homeApi.getNotepads(searchData), {
  //   refetchOnWindowFocus: false,
  //   select: (response: ISearchNotepadData) => {
  //     if (searchData && searchData.search?.length) {
  //       const newArr: ISearchNotepad[] = response.notes.filter((notepad: ISearchNotepad) => {
  //           if (searchData.searchType === SearchTypeEnum.EXACT) {
  //             return notepad.content?.toLowerCase().includes(searchData.search?.toLowerCase() as string) || notepad.title.toLowerCase().includes(searchData?.search?.toLowerCase() as string)
  //           } else {
  //             const words = searchData.search?.split(" ");
  //             let returnValue = false;
  //             words?.forEach((word: string) => returnValue = returnValue ||
  //               (notepad.content?.toLowerCase().includes(word.toLowerCase() as string) ||
  //                 notepad.title?.toLowerCase().includes(word.toLowerCase() as string)))
  //
  //             return returnValue
  //           }
  //         }
  //       ).map((notepad: ISearchNotepad) => {
  //         let newContent = notepad?.content;
  //         let newTitle = notepad?.title;
  //         const words = searchData?.search?.split(" ");
  //         if (searchData.searchType === SearchTypeEnum.EXACT) {
  //           newContent = notepad.content?.replaceAll(
  //             new RegExp(searchData.search as string, 'gi'),
  //             match => `<span style="font-weight: 600; color: #eab308">${match}</span>`
  //           )
  //
  //           newTitle = notepad?.title?.replace(
  //             new RegExp(searchData.search as string, 'gi'),
  //             match =>
  //               `<span style="font-weight: 600; color: #eab308">${match}</span>`
  //           )
  //         } else {
  //           words?.forEach((word: string) => {
  //             if (word) {
  //               newContent = newContent?.replaceAll(
  //                 new RegExp(word as string, 'gi'),
  //                 match => `<span style="font-weight: 600; color: #eab308">${match}</span>`)
  //
  //               newTitle = newTitle?.replace(
  //                 new RegExp(word as string, 'gi'),
  //                 match => `<span style="font-weight: 600; color: #eab308">${match}</span>`)
  //             }
  //           })
  //         }
  //         return {...notepad, content: newContent, title: newTitle}
  //       })
  //       return {total: newArr.length, notes: newArr}
  //     } else {
  //       return response
  //     }
  //   },
  //   enabled: false
  // })

  const searchNuggets = useQuery([`searchNuggets${JSON.stringify({searchData})}`, searchData], () => homeApi.getNuggets(searchData), {
    refetchOnWindowFocus: false,
    select: (response: ISearchNuggetData) => {
      if (searchData && searchData.search?.length) {
        const newArr: ISearchNugget[] = response.nuggets.filter((nugget: ISearchNugget) => {
          if (searchData?.searchType === SearchTypeEnum.EXACT) {
            return nugget.description?.toLowerCase().includes(searchData.search?.toLowerCase() as string)
          } else {
            const words = searchData?.search?.split(" ");
              let returnValue = false;
              words?.forEach((word: string) => {
                returnValue = returnValue || nugget.description.toLowerCase().includes(word.toLowerCase() as string)
              })
              return returnValue
            }
          }
        ).map((nugget: ISearchNugget) => {
          let newDescription = nugget?.description;
          const words = searchData?.search?.split(" ");
          if (searchData?.searchType === SearchTypeEnum.EXACT) {
            newDescription = nugget.description?.replaceAll(
              new RegExp(searchData.search as string, 'gi'),
              match => `<span style="font-weight: 600; color: #eab308">${match}</span>`
            )
          } else {
            newDescription = nugget?.description;
            words?.forEach((word: string) => {
              if (word) {
                newDescription = newDescription?.replaceAll(
                  new RegExp(word as string, 'gi'),
                  match => `<span style="font-weight: 600; color: #eab308">${match}</span>`
                )
              }
            })
          }
          return {...nugget, description: newDescription}
        })
        return {total: newArr.length, nuggets: newArr}
      } else {
        return response
      }
    },
    enabled: false
  })

  const searchKnowledgeGraphs = useQuery([`searchKnowledgeGraphs${JSON.stringify({searchData})}`, searchData], () => homeApi.searchKnowledgeGraphs(searchData), {
    refetchOnWindowFocus: false,
    select: (response: any) => {
      if (searchData && searchData.search?.length) {
        const newArr: ISearchNugget[] = response['knowledge-graphs']
          .filter((node: any) => {
              if (searchData?.searchType === SearchTypeEnum.EXACT) {
                return node.name?.toLowerCase().includes(searchData.search?.toLowerCase() as string)
              } else {
                const words = searchData?.search?.split(" ");
                let returnValue = false;
                words?.forEach((word: string) => {
                  returnValue = returnValue || node.name.toLowerCase().includes(word.toLowerCase() as string)
                })
                return returnValue
              }
            }
          ).map((node: any) => {
            let newName = node?.name;
            const words = searchData?.search?.split(" ");
            if (searchData?.searchType === SearchTypeEnum.EXACT) {
              newName = node.description?.replaceAll(
                new RegExp(searchData.search as string, 'gi'),
                (match: any) => `<span style="font-weight: 600; color: #eab308">${match}</span>`
              )
            } else {
              newName = node?.name;
              words?.forEach((word: string) => {
                if (word) {
                  newName = newName?.replaceAll(
                    new RegExp(word as string, 'gi'),
                    (match: any) => `<span style="font-weight: 600; color: #eab308">${match}</span>`
                  )
                }
              })
            }
            return {...node, name: newName}
          })
        return {total: newArr.length, nodes: newArr}
      } else {
        return response
      }
    },
    enabled: false
  });

  const searchSnapshots = useQuery([`searchSnapshots${JSON.stringify({searchData})}`, searchData], () => homeApi.searchSnapshots(searchData), {
    select: (response: any) => {
      if (searchData && searchData.search?.length) {
        const newArr: any[] = response.snapshots
          .filter((snapshot: any) => {
              if (searchData?.searchType === SearchTypeEnum.EXACT) {
                return snapshot.projectNugget.description?.toLowerCase().includes(searchData.search?.toLowerCase() as string)
              } else {
                const words = searchData?.search?.split(" ");
                let returnValue = false;
                words?.forEach((word: string) => {
                  returnValue = returnValue || snapshot.projectNugget.description?.toLowerCase().includes(word.toLowerCase() as string)
                })
                return returnValue
              }
            }
          ).map((snapshot: any) => {
            let newDescription = snapshot?.projectNugget.description;
            const words = searchData?.search?.split(" ");
            if (searchData?.searchType === SearchTypeEnum.EXACT) {
              newDescription = snapshot?.projectNugget.description.replaceAll(
                new RegExp(searchData.search as string, 'gi'),
                (match: any) => `<span style="font-weight: 600; color: #eab308">${match}</span>`
              )
            } else {
              newDescription = snapshot.projectNugget?.description;
              words?.forEach((word: string) => {
                if (word) {
                  newDescription = newDescription?.replaceAll(
                    new RegExp(word as string, 'gi'),
                    (match: any) => `<span style="font-weight: 600; color: #eab308">${match}</span>`
                  )
                }
              })
            }
            return {...snapshot, projectNugget: {...snapshot.projectNugget, description: newDescription}}
          })
        return {total: newArr.length, snapshots: newArr}
      } else {
        return response
      }
    },
  })

  const searchFiles: UseQueryResult<ISearchFileData, unknown> = useQuery([`searchFiles${JSON.stringify({searchData})}`, searchData], () => homeApi.getSearchFiles(searchData), {
    refetchOnWindowFocus: false,
    enabled: false,
    select: (response: any) => {
      if (searchData && searchData.search?.length) {
        const newArr: any[] = response.files.filter((file: ISearchFile) => {
            if (searchData?.searchType === SearchTypeEnum.EXACT) {
              return file.snippet?.toLowerCase().includes(searchData.search?.toLowerCase() as string)
            } else {
              const words = searchData?.search?.split(" ");
              let returnValue = false;
              words?.forEach((word: string) => {
                returnValue = returnValue || file.snippet.toLowerCase().includes(word.toLowerCase() as string)
              })
              return returnValue
            }
          }
        ).map((file: ISearchFile) => {
          let newSnippet = file?.snippet;
          const words = searchData?.search?.split(" ");
          if (searchData?.searchType === SearchTypeEnum.EXACT) {
            newSnippet = file.snippet?.replaceAll(
              new RegExp("\\*" + searchData.search + "\\*" as string, 'gi'),
              match => `<span style="font-weight: 600; color: #eab308">${match.substring(1, match.length - 1)}</span>`
            )
          } else {
            newSnippet = file?.snippet;
            words?.forEach((word: string) => {
              if (word) {
                newSnippet = newSnippet?.replaceAll(
                  new RegExp("\\*" + word + "\\*" as string, 'gi'),
                  match => `<span style="font-weight: 600; color: #eab308"">${match.substring(1, match.length - 1)}</span>`
                )
              }
            })
          }
          return {...file, snippet: newSnippet}
        })
        return {total: newArr.length, files: newArr}
      } else {
        return response
      }
    }
  })

  const searchSurveys = useQuery([`searchSurveys${JSON.stringify({searchData})}`, searchData], () => homeApi.searchSurveys(searchData), {
    select: (response: any) => {
      if (searchData && searchData.search?.length) {
        const newArr: any[] = response['organization-surveys']
          .filter((survey: any) => {
              if (searchData?.searchType === SearchTypeEnum.EXACT) {
                return survey.name?.toLowerCase().includes(searchData.search?.toLowerCase() as string)
              } else {
                const words = searchData?.search?.split(" ");
                let returnValue = false;
                words?.forEach((word: string) => {
                  returnValue = returnValue || survey.name.toLowerCase().includes(word.toLowerCase() as string)
                })
                return returnValue
              }
            }
          ).map((survey: any) => {
            let newName = survey?.name;
            const words = searchData?.search?.split(" ");
            if (searchData?.searchType === SearchTypeEnum.EXACT) {
              newName = survey.name?.replaceAll(
                new RegExp(searchData.search as string, 'gi'),
                (match: any) => `<span style="font-weight: 600; color: #eab308">${match}</span>`
              )
            } else {
              newName = survey?.name;
              words?.forEach((word: string) => {
                if (word) {
                  newName = newName?.replaceAll(
                    new RegExp(word as string, 'gi'),
                    (match: any) => `<span style="font-weight: 600; color: #eab308">${match}</span>`
                  )
                }
              })
            }
            return {...survey, name: newName}
          })
        return {total: newArr.length, surveys: newArr}
      } else {
        return response
      }
    },
  })

  const searchUsers: UseQueryResult<ISearchUsersData, unknown> = useQuery([`searchUsers${JSON.stringify({searchData})}`, searchData], () => homeApi.getSearchUsers(searchData), {
    refetchOnWindowFocus: false,
    enabled: false,
    select: (response: ISearchUsersData) => {
      if (!searchData || !searchData.search || !searchData.search.length) {
        return response;
      }

      const searchQuery = searchData.search.toLowerCase();
      const searchWords = searchQuery.split(" ");

      const filteredUsers = response.users.filter((user: ISearchUser) => {
        const matchFirstName = user.firstName.toLowerCase().includes(searchQuery);
        const matchLastName = user.lastName.toLowerCase().includes(searchQuery);
        const matchEmailAddress = user.emailAddress.toLowerCase().includes(searchQuery);

        if (searchData.searchType === SearchTypeEnum.EXACT) {
          return matchFirstName || matchLastName || matchEmailAddress;
        } else {
          return searchWords.some(word =>
            user.firstName.toLowerCase().includes(word) ||
            user.lastName.toLowerCase().includes(word) ||
            user.emailAddress.toLowerCase().includes(word)
          );
        }
      }).map((user: ISearchUser) => {
        let newFirstName = user.firstName;
        let newLastName = user.lastName;
        let newEmailAddress = user.emailAddress;

        searchWords.forEach(word => {
          const regex = new RegExp(word, 'gi');
          newFirstName = newFirstName.replaceAll(regex, match => `<span style="font-weight: 600; color: #eab308">${match}</span>`);
          newLastName = newLastName.replaceAll(regex, match => `<span style="font-weight: 600; color: #eab308">${match}</span>`);
          newEmailAddress = newEmailAddress.replace(regex, match => `<span style="font-weight: 600; color: #eab308">${match}</span>`);
        });


        return {...user, firstName: newFirstName, lastName: newLastName, emailAddress: newEmailAddress};
      });

      return {total: filteredUsers.length, users: filteredUsers};
    }
  });

  const searchStories: UseQueryResult<ISearchStoriesData, unknown> = useQuery([`searchStories${JSON.stringify({searchData})}`, searchData], () => homeApi.getSearchStories(searchData), {
    refetchOnWindowFocus: false,
    enabled: false,
    select: (response: ISearchStoriesData) => {
      if (!searchData || !searchData.search || !searchData.search.length) {
        return response;
      }

      const searchQuery = searchData.search.toLowerCase();
      const searchWords = searchQuery.split(" ");
      const filteredStories = response.searchResult.filter((story: IStorySearchResult) => {


        if (searchData.searchType === SearchTypeEnum.EXACT) {
          return story.s_title.toLowerCase().includes(searchQuery);
        } else {
          return searchWords.some(word =>
            story.s_title.toLowerCase().includes(word)
          );
        }
      }).map((story: IStorySearchResult) => {
        let newTitle = story.s_title;

        searchWords.forEach(word => {
          const regex = new RegExp(word, 'gi');
          newTitle = newTitle.replaceAll(regex, match => `<span style="font-weight: 600; color: #eab308">${match}</span>`);
        });

        return {...story, s_title: newTitle};
      });

      return {total: filteredStories.length, searchResult: filteredStories};
    }
  });

  function searchChanged(data: ISearchData): void {
    setSearchData(() => {
      return data;
    });
  }



  useEffect(() => {
    if (searchData && !isModalSearch) {
      searchProjects.refetch();
      // searchNotepads.refetch();
      searchNuggets.refetch();
      searchFiles.refetch();
      searchKnowledgeGraphs.refetch();
      searchUsers.refetch();
      searchStories.refetch();
      searchSnapshots.refetch();
      searchSurveys.refetch()
    }

    if (isModalSearch) {
      searchProjects.refetch();
      return
    }

    navigate({
      pathname: '',
      search: qs.stringify(searchData ? searchData : {})
    }, {replace: true});
  }, [searchData]);

  return (
    <div className="insight-library">
      {
        !isModalSearch &&
        <div className="header">
          <h2>Search results</h2>
        </div>
      }

      <div ref={divRef} className="body">
        <Search searchChanged={searchChanged}
                searchData={searchData}
                matchTag={props.matchTag}/>
        <Tabs className="tabs">
          <Tabs.TabPane tab={<div className='projects-tab'>
            <span className='projects-tab-name'>Projects</span>
            <span className='projects-tab-length'>{
              searchProjects.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchProjects?.data?.total
            }</span>
          </div>} key="1">
            <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
              <Projects isModalSearch={isModalSearch} searchProjects={searchProjects}/>
            </div>
          </Tabs.TabPane>
          {
            !isModalSearch &&
            <>
              <Tabs.TabPane tab={<div className='projects-tab'>
                <span className='projects-tab-name'>Insights</span>
                <span className='projects-tab-length'>
              {searchNuggets.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchNuggets?.data?.total}
            </span>
              </div>} key="2">
                <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
                  <Nuggets searchNuggets={searchNuggets}/>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={<div className='projects-tab'>
                <span className='projects-tab-name'>QuestionPro Surveys</span>
                <span className='projects-tab-length'>
              {searchSurveys.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchSurveys?.data?.total}
            </span>
              </div>} key="3">
                <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
                  <SearchSurveys searchSurveys={searchSurveys}/>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={<div className='projects-tab'>
                <span className='projects-tab-name'>Knowledge graphs</span>
                <span className='projects-tab-length'>
              {searchKnowledgeGraphs.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchKnowledgeGraphs?.data?.total}
            </span>
              </div>} key="4">
                <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
                  <SearchKnowledgeGraphs searchKnowledgeGraphs={searchKnowledgeGraphs}/>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={<div className='projects-tab'>
                <span className='projects-tab-name'>Snapshots</span>
                <span className='projects-tab-length'>
              {searchSnapshots.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchSnapshots?.data?.total}
            </span>
              </div>} key="5">
                <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
                  <SearchSnapshots searchSnapshots={searchSnapshots}/>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={<div className='projects-tab'>
                <span className='projects-tab-name'>Stories</span>
                <span className='projects-tab-length'>
              {searchStories.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchStories?.data?.total
              }
              </span>
              </div>} key="7">
                <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
                  <Stories searchStories={searchStories}/>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={<div className='projects-tab'>
                <span className='projects-tab-name'>Files</span>
                <span className='projects-tab-length'>
              {searchFiles.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchFiles?.data?.total}
            </span>
              </div>} key="8">
                <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
                  <Files searchFiles={searchFiles}/>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={<div className='projects-tab'>
                <span className='projects-tab-name'>Users</span>
                <span className='projects-tab-length'>
              {searchUsers.isLoading
                ? <LoadingOutlined style={{fontSize: 12, marginRight: 0}} spin/>
                : searchUsers?.data?.total}
            </span>
              </div>} key="9">
                <div className={isModalSearch ? "modal-tab-items-wrapper" : "tab-items-wrapper"}>
                  <Users searchUsers={searchUsers}/>
                </div>
              </Tabs.TabPane>
            </>
          }
        </Tabs>
      </div>
    </div>
  )
}
