import { AxiosResponse } from "axios";
import { axiosClient } from "../../../../../axios-client";
import { environment } from "../../../../../environment";
import { IWorkflow } from '../model/workflow.interface';

class WorkflowsApi {
  baseUrl: string = `${environment.backendUrl}/workflow`;

  async getAll(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async create(workflow: IWorkflow): Promise<IWorkflow> {
    try {
      const response: AxiosResponse<IWorkflow> = await axiosClient.post<IWorkflow>(`${this.baseUrl}`, workflow);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async update(workflow: IWorkflow): Promise<IWorkflow> {
    try {
      const response: AxiosResponse<IWorkflow> = await axiosClient.put<IWorkflow>(`${this.baseUrl}`, workflow);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async toggleCustomWorkflow(value: boolean): Promise<boolean> {
    try {
      const response: AxiosResponse<boolean> = await axiosClient.put<boolean>(`${this.baseUrl}/enable`, {isEnabled: value});
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async checkForChanges(id: number[], workflow: IWorkflow): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await axiosClient.put<any[]>(`${this.baseUrl}/${id}/check`, workflow);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async reAssignProjectToWorkflows(id: number, payload: any): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await axiosClient.put<any[]>(
        `${this.baseUrl}/${id}/reassign`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async timeline(queryParams: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/analytics`, {params: queryParams});
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new WorkflowsApi();
