import React, { useEffect, useState } from 'react';
import './graph-dialog.scss';
import { Button, Divider, Empty, Modal, Select, Table, Tabs } from 'antd';
import { AxiosResponse } from 'axios';
import { ColumnsType } from 'antd/lib/table';
import { DebounceInput } from 'react-debounce-input';
import { Loader } from '../../../../../../_shared/components/loader/loader';
import SvgInsightDesk from '../../../../../../assets/icons/js/InsightDesk';
import OperationsApi from '../../../../../projects/project-edit/tabs/operations/api/operations-api';
import { SelectQuestion } from './select-question';
import { PreviewGraph } from './preview-graph';
import Steps from "./steps/steps";
import SvgConversation from "../../../../../../assets/icons/js/Conversation";
import SvgSurvey from "../../../../../../assets/icons/js/Survey";
import SvgArrowDown from '../../../../../../assets/icons/js/ArrowDown';
import SvgPieChart from "../../../../../../assets/icons/js/PieChart";
import SvgColumnChart from "../../../../../../assets/icons/js/ColumnChart";
import SvgBarChart from "../../../../../../assets/icons/js/BarChart";
import SvgLineChart from "../../../../../../assets/icons/js/LineChart";

const TabPane = Tabs.TabPane;

const steps = [
  {
    icon: <SvgSurvey width={24} height={24}/>,
    title: 'Select Survey',
    content: 'First-content',
  },
  {
    icon: <SvgConversation width={24} height={24}/>,
    title: 'Select Question',
    content: 'Second-content',
  },
  {
    icon: <SvgColumnChart/>,
    title: 'Set Chart',
    content: 'Last-content',
  },
];

export function GraphDialog(props: any): JSX.Element {
  const surveysColumns: ColumnsType<any> = [
    {
      title: 'Related Services',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Started',
      dataIndex: 'started',
      key: 'started'
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed'
    },
  ];

  const chartItems: { label: string, key: string, icon: JSX.Element, value: string }[] = [
    {
      label: 'Column',
      key: "0",
      icon: <SvgColumnChart width={16} height={16}/>,
      value: 'column'
    },
    {
      label: 'Pie',
      key: "1",
      icon: <SvgPieChart/>,
      value: 'pie'
    },
    {
      label: 'Bar',
      key: "2",
      icon: <SvgBarChart/>,
      value: 'bar'
    },
    {
      label: 'Line',
      key: "3",
      icon: <SvgLineChart/>,
      value: 'line'
    },
  ];


  let page: number = 1;
  let pageSize: number = 10;
  let searchText: string = '';

  const [current, setCurrent] = useState(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [surveysLoading, setSurveysLoading] = useState<boolean>(false);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [surveys, setSurveys] = useState<any>();
  const [folders, setFolders] = useState<any[]>([]);
  const [folderId, setFolderId] = useState<number | undefined>(undefined);
  const [nextButtonError, setNextButtonError] = useState<string | boolean>(false);
  const [selectedChartValue, setSelectedChartValue] = useState("column");

  useEffect(() => {
    if (selectedQuestion?.highChartJSON) {
      const highChartJSON = JSON.parse(selectedQuestion?.highChartJSON);
      const chartItem = chartItems.find((item: {
        label: string,
        key: string,
        icon: JSX.Element,
        value: string
      }) => item.value === highChartJSON?.chart?.type);
      chartItem && setSelectedChartValue(chartItem.value);
    }
  }, [selectedQuestion]);

  const rowSelection = {
    onChange: (selectedRowKey: any, selectedRows: any) => {
      setApiLoading(true);

      OperationsApi.getSurveyQuestions(selectedRows[0].surveyId)
        .then((response: AxiosResponse<any[]>) => {
          setNextButtonError(false);
          setSelectedSurvey({data: response, surveyId: selectedRowKey, surveyInfo: selectedRows[0]});
          setApiLoading(false);
        }, () => {
          setNextButtonError('Survey does not have any questions or graph cannot be generated.');
          setApiLoading(false);
        });
    }
  };


  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (folderId) {
      loadSurveys();
    }
  }, [folderId]);

  function title(): JSX.Element {
    return <div className="row align-items-center">
      <SvgInsightDesk style={{marginRight: 8, color: "#545e6b"}}/>
      <span style={{color: "#545e6b"}}>Add Survey Chart</span>
    </div>
  }

  function onFolderChange(idString: string): void {
    if (idString !== 'null') {
      setFolderId(parseInt(idString));
    }
  }

  function handleTableChange(pagination: any): void {
    page = pagination.current
    loadSurveys();
  }

  function search(value: string): void {
    searchText = value;
    if (value.length) {
      searchSurveys();
      return;
    }

    loadSurveys();
  }

  function searchSurveys(): void {
    setSurveysLoading(true);

    const data: { page: number, size: number, search: string } = {
      page: page,
      size: pageSize,
      search: searchText
    };

    OperationsApi.searchSurveys(data.page, data.size, data.search)
      .then((response: AxiosResponse<any>) => {
        setSurveys(response);
        setLoader(false);
        setSurveysLoading(false);
      });
  }

  function loadPage(): void {
    OperationsApi.loadFolders()
      .then((response: any[]) => {
        if (response?.length) {
          setFolderId(response[0]?.folderId);
          setFolders(response);
        } else {
          setFolders([{folderId: null, folderName: "My Surveys"}]);
        }
      }, () => {
        setLoader(false);
        setFolders([]);
      });
  }

  function loadSurveys(): void {
    setSurveysLoading(true);

    const data: { page: number, size: number, search: string } = {
      page,
      size: pageSize,
      search: searchText
    };

    OperationsApi.loadSurveyFolder(folderId, data.page, data.size, props.data.projectId)
      .then((response: any) => {
        setSurveys(response);
        setLoader(false);
        setSurveysLoading(false);
      });
  }

  const done = () => {
    props.onConfirm({
      ...selectedQuestion, ...selectedSurvey,
      selectedChart: chartItems.find((item: {
        label: string;
        key: string;
        icon: JSX.Element;
        value: string;
      }) => item.value == selectedChartValue)
    });
    props.onCancel();
  };

  const next = (current: number) => {
    setCurrent(current + 1);
  };

  const prev = (current: number) => {
    if (current === 1) {
      setSelectedSurvey(undefined);
    }

    if (current === 2) {
      setSelectedQuestion(undefined);
    }

    setCurrent(current - 1);
  };

  const onSelectChange = (value: string) => {
    setSelectedChartValue(value)
  }

  return (
    <Modal
      title={title()}
      open={props.data.visible}
      centered
      className="graph-dialog"
      width={920}
      onCancel={() => (props.onCancel())}
      footer={
        !loader &&
          <>
              <Divider style={{marginBottom: 10}}/>
              <div className="row justify-space-between">
                  <Steps current={current} items={steps}/>
                  <span>
            <Button type='link' disabled={current === 0} style={{margin: '0 8px'}} onClick={() => prev(current)}>
              Back
            </Button>
                    {current < steps.length - 1 && (
                      <Button type="primary" danger={typeof nextButtonError !== "boolean"}
                              disabled={typeof nextButtonError === "boolean" && (!selectedSurvey?.data?.length || current === 1 && !selectedQuestion?.highChartJSON)}
                              loading={apiLoading}
                              onClick={() => typeof nextButtonError === "boolean" && next(current)}>
                        {typeof nextButtonError !== "boolean" ? nextButtonError : 'Next'}
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button type="primary" onClick={() => done()}>
                        {props.data.okText ? props.data.okText : 'Add Survey Chart'}
                      </Button>
                    )}
        </span>
              </div>
          </>}>
      <>
        {loader ? <Loader/>
          :
          <div>
            {current === 0 &&
                <>
                  {!folders.length ?
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    :
                    <Tabs type="card" tabPosition={'left'} onChange={(id: any) => id && onFolderChange(id)}>
                      {folders.map((folder: any) => {
                        return (
                          <TabPane tab={folder.folderName} key={folder.folderId}>
                            <div className="column align-items-start" style={{margin: '10px 0 -50px 0 '}}>
                              <div className="outline-input-wrapper" style={{width: 250}}>
                                <DebounceInput
                                  className="outline-input"
                                  placeholder='Search by Survey Name or Id'
                                  debounceTimeout={600}
                                  value=''
                                  onChange={e => search(e.target.value)}/>
                              </div>
                            </div>
                            <Table
                              loading={surveysLoading}
                              pagination={{
                                position: ['topRight'],
                                defaultCurrent: surveys?.pagination?.number,
                                total: surveys?.pagination?.totalElements,
                                pageSize: surveys?.pagination?.size
                              }}
                              columns={surveysColumns}
                              rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                              }}
                              onChange={(p) => handleTableChange(p)}
                              className="w-100"
                              dataSource={surveys?.content}
                              rowKey="surveyId"/>
                          </TabPane>
                        )
                      })}
                    </Tabs>
                  }
                </>
            }
            {current === 1 &&
                <div>
                    <div className='survey-title'>
                      {selectedSurvey.surveyInfo.name}
                    </div>
                    <div className='select-question-wrapper'>
                        <SelectQuestion
                            data={selectedSurvey}
                            onSelectedQuestion={(data: any) => setSelectedQuestion(data)}
                            onNextButtonError={(value: string) => setNextButtonError(value)}
                            onSetApiLoading={(value: boolean) => setApiLoading(value)}/>
                    </div>

                </div>
            }

            {current === 2 &&
                <div>
                    <div className='row justify-space-between'>
                        <div className='survey-title'>
                          {selectedQuestion.title}
                        </div>
                        <div className='chart-switch-wrapper'>
                            <Select value={selectedChartValue} onChange={onSelectChange}
                                    className='chart-switch-wrapper-select'
                                    popupClassName='chart-switch-dropdown'
                                    suffixIcon={<SvgArrowDown/>}>
                              {
                                chartItems.map((chartItem) => {
                                  return <Select.Option value={chartItem.value} key={chartItem.key}>
                                    {chartItem?.icon} &nbsp; {chartItem?.label}
                                  </Select.Option>
                                })
                              }
                            </Select>
                        </div>
                    </div>

                    <PreviewGraph data={selectedQuestion} selectedChart={chartItems.find((item: {
                      label: string;
                      key: string;
                      icon: JSX.Element;
                      value: string;
                    }) => item.value == selectedChartValue)}/>


                </div>
            }
          </div>
        }
      </>
    </Modal>
  );
}
