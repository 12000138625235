import * as React from "react";

const SvgFiletypeMedia = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 32 32" {...props}>
      <g fill="none" fill-rule="evenodd">
        <g fill={props?.color ? props.color : "#545e6b"} fill-rule="nonzero">
          <g>
            <path d="M32 4v24H0V4h32zm-2 2H2v20h28V6zm-16 4l8 6-8 6V10zm-2 0v12h-2V10h2z"
                  transform="translate(-341.000000, -360.000000) translate(243.000000, 328.000000) translate(98.000000, 32.000000)"/>
          </g>
        </g>
      </g>
    </svg>

  );
}

export default SvgFiletypeMedia;
