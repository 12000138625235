export interface ICustomizeTable {
  view: TableViewEnum;
  basic: ICustomizeColumnTable[];
  keyMetrics: ICustomizeKeyMetricTable[];
  programs: ICustomizeProgramTable[];
}

export enum TableViewEnum {
  BASIC_VIEW = "BASIC_VIEW",
  KEY_METRICS_VIEW = "KEY_METRICS_VIEW",
  PROGRAM_METRICS_VIEW = "PROGRAM_METRICS_VIEW",
}

export interface ICustomizeColumnTable {
  id?: number | string
  name: string;
  selected: boolean;
  order: number;
}

export interface ICustomizeProgramTable extends ICustomizeColumnTable {
  id: number;
  groups: ICustomizeKeyMetricTable[];
}

export interface ICustomizeKeyMetricTable extends ICustomizeColumnTable {
  id: number;
}
