import React, { lazy } from 'react';

import { IRoute } from './route-interface';
import { ReadNode } from '../../pages/home/components/read-node/read-node';
import SearchResults from '../../pages/home/pages/search-results/search-results';
import { ProjectEdit } from '../../pages/projects/project-edit/project-edit';
import Interview from '../../pages/projects/project-edit/tabs/interviews-labs/interview/interview';
import UsageStatsViewAll
  from '../../pages/projects/project-edit/tabs/interviews-labs/usage-stats/usage-stats-view-all/usage-stats-view-all';
import ProjectTypeForm
  from '../../pages/settings/settings-tabs/taxonomy/project-type/project-type-form/project-type-form';
import { AnalyticsTimeline } from '../../pages/analytics/timeline/analytics-timeline';
import { Settings } from '../../pages/settings/settings';
import ManageInsightsAdmins
  from '../../pages/settings/settings-tabs/team/manage-insights-admins/manage-insights-admins';
// import {
//   HighlightEditor
// } from '../../pages/settings/settings-tabs/taxonomy/highlight-analytics/highlight-editor/highlight-editor';
import {
  SlackIntegration
} from '../../pages/settings/settings-tabs/setup/integrations/slack-integration/slack-integration';
import { ZipImport } from '../../pages/settings/settings-tabs/setup/integrations/zip-import/zip-import';
import ImportTable from '../../pages/settings/settings-tabs/setup/integrations/_shared/import-table/import-table';
import GoogleDriveIntegration
  from '../../pages/settings/settings-tabs/setup/integrations/google-drive-integration/google-drive-integration';
import {
  AddEditInterviewLab
} from '../../pages/settings/settings-tabs/interview-labs-settings/add-edit-interview-lab/add-edit-interview-lab';
import {
  ProjectTypeContextProvider
} from '../../pages/settings/settings-tabs/taxonomy/project-type/store/project-type-context';
import { Stories } from '../../pages/stories/stories';
import StoryEdit from '../../pages/stories/story-edit/story-edit';
import Knowledge from '../../pages/knowledge/knowledge';
import ReadProject from '../../pages/home/components/read-node/read-project/read-project';
import { StoryEditContextProvider } from '../../pages/stories/story-edit/story-edit.context';
import Snapshots from '../../pages/snapshots/snapshots';
import SnapshotEdit from '../../pages/snapshots/snapshot-edit/snapshot-edit';
import Repositories from "../../pages/repositories/repositories";
import RepositoriesArchived from "../../pages/repositories/repositories-archived/repositories-archived";
import Projects from "../../pages/projects/projects";
import { ExcelImport } from "../../pages/settings/settings-tabs/setup/integrations/excel-import/excel-import";
import ImportExcelTable
  from "../../pages/settings/settings-tabs/setup/integrations/excel-import/import-excel-table/import-excel-table";
import Analytics from "../../pages/analytics/analytics";
import Surveys from "../../pages/surveys/surveys";
import SurveyDetails from "../../pages/surveys/survey-details/survey-details";
import CreateProjectPage from "../../pages/home/pages/create-project/create-project-page/create-project-page";
import { moduleTypes } from "../../pages/admin/admin-tabs/licenses/model/licenses.interface";
import OneDriveIntegration
  from "../../pages/settings/settings-tabs/setup/integrations/one-drive-integration/one-drive-integration";
import { RecycleBinProjects } from "../../pages/projects/tabs/recycle-bin-projects/recycle-bin-projects";
import { Home } from "../../pages/home/home";
import SharepointIntegration
  from "../../pages/settings/settings-tabs/setup/integrations/sharepoint-integration/sharepoint-integration";
import RepositoryEdit from "../../pages/repositories/repository-edit/repository-edit";
import RepositoryEditArchived from "../../pages/repositories/repository-edit-archived/repository-edit-archived";

const AdminTabs = lazy(() => import('../../pages/admin/admin'));
const PerformanceMonitor = lazy(() => import('../../pages/admin/admin-tabs/performance-monitor/performance-monitor'));

export const nonModulesRoutes: IRoute[] = [
  {
    path: '/settings',
    element: <Settings/>
  },
  {
    path: '/settings/manage-insights-admins',
    element: <ManageInsightsAdmins/>,
  },
  // {
  //   path: '/settings/highlight-editor',
  //   element: <HighlightEditor/>
  // },
  {
    path: '/settings/slack-integration',
    element: <SlackIntegration/>
  },
  {
    path: '/settings/zip-file-import',
    element: <ZipImport/>
  },
  {
    path: '/settings/zip-file-import/import',
    element: <ImportTable/>
  },
  {
    path: '/settings/excel-file-import',
    element: <ExcelImport/>
  },
  {
    path: '/settings/excel-file-import/import',
    element: <ImportExcelTable/>
  },
  {
    path: '/settings/google-drive-integration',
    element: <GoogleDriveIntegration/>
  },
  {
    path: '/settings/one-drive-integration',
    element: <OneDriveIntegration/>
  },
  {
    path: '/settings/sharepoint-integration',
    element: <SharepointIntegration/>
  },
  {
    path: '/settings/google-drive-integration/import',
    element: <ImportTable/>
  },
  {
    path: '/settings/project-type',
    element: <ProjectTypeContextProvider><ProjectTypeForm/></ProjectTypeContextProvider>
  },
  {
    path: '/settings/interview-labs',
    element: <AddEditInterviewLab/>
  },
  {
    path: '/performance-monitor',
    element: <PerformanceMonitor/>,
  },
  {
    path: '/admin',
    element: <AdminTabs/>,
    protected: true
  },
];

export const moduleSeperatedRoutes: Record<moduleTypes, IRoute[]> = {
  'HOMEPAGE': [
    {
      path: '/home',
      element: <Home/>
    },
    {
      path: '/home/project',
      element: <CreateProjectPage/>
    },
    {
      path: '/home/search',
      element: <SearchResults/>
    },
  ],
  'KNOWLEDGE_GRAPHS': [
    {
      path: '/nodes',
      element: <Knowledge/>
    },
    {
      path: '/nodes/node',
      element: <ReadNode/>
    },
    {
      path: '/nodes/node/project',
      element: <ReadProject/>
    },
  ],
  'SNAPSHOTS': [
    {
      path: '/snapshots',
      element: <Snapshots/>
    },
    {
      path: '/snapshots/edit',
      element: <SnapshotEdit/>
    },
  ],
  'STORIES': [
    {
      path: '/stories',
      element: <Stories/>
    },
    {
      path: '/stories/edit',
      element: <StoryEditContextProvider>
        <StoryEdit/>
      </StoryEditContextProvider>
    },
  ],
  'PROJECTS': [
    {
      path: '/projects',
      element: <Projects/>
    },
    {
      path: '/projects/edit',
      element: <ProjectEdit/>
    },
    {
      path: '/projects/archived',
      element: <RecycleBinProjects/>
    },
    {
      path: '/projects/edit/interview',
      element: <Interview/>
    },
    {
      path: '/projects/edit/interview/usage-stats',
      element: <UsageStatsViewAll/>
    },
  ],
  'METRICS': [
    {
      path: '/metrics',
      element: <Analytics/>
    },
    {
      path: '/metrics/timeline',
      element: <AnalyticsTimeline/>
    },
  ],
  'REPOSITORIES': [
    {
      path: '/repositories',
      element: <Repositories/>
    },
    {
      path: '/repositories/archived',
      element: <RepositoriesArchived/>
    },
    {
      path: '/repositories/edit',
      element: <RepositoryEdit/>
    },
    {
      path: '/repositories/edit/archived',
      element: <RepositoryEditArchived/>
    }
  ],
  'SURVEYS': [
    {
      path: '/surveys',
      element: <Surveys/>
    },
    {
      path: '/surveys/details',
      element: <SurveyDetails/>
    }
  ]
}
