import { environment } from "../../../../../../environment";
import { AxiosResponse } from "axios";
import { axiosClient } from "../../../../../../axios-client";
import { INote } from "../model/note.interface";

class NotesApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getNotes(projectId: number, params: any): Promise<{ data: INote[], total: number }> {
    try {
      const response: AxiosResponse<{ data: INote[], total: number }> = await axiosClient.get<{
        data: INote[],
        total: number
      }>(this.baseUrl + `/notes/${projectId}`, {
        params
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getNote(noteId: number): Promise<INote> {
    try {
      const response: AxiosResponse<INote> = await axiosClient.get<INote>(
        this.baseUrl + `/notes/note/${noteId}`
      )
      return response.data
    } catch (error) {
      throw error;
    }
  }

  async createNote(payload: {
    projectId: number,
    content?: string,
    title?: string,
    fileId?: number,
    externalSource?: string;
    mediaName?: string,
    highlights?: any
  }): Promise<INote> {
    try {
      const response: AxiosResponse<INote> = await axiosClient.post<INote>(
        this.baseUrl + `/notes`, payload
      )
      return response.data
    } catch (error) {
      throw error;
    }
  }

  async updateNote(noteId: number, payload: {
    content?: string,
    title?: string,
    fileId?: number,
    externalReSource?: string;
    highlights?: any
  }): Promise<INote> {
    try {
      const response: AxiosResponse<INote> = await axiosClient.put<INote>(
        this.baseUrl + `/notes/note/${noteId}`, payload
      )
      return response.data
    } catch (error) {
      throw error;
    }
  }

  async deleteNote(noteId: number): Promise<INote> {
    try {
      const response: AxiosResponse<INote> = await axiosClient.delete<INote>(
        this.baseUrl + `/notes/note/${noteId}`
      )
      return response.data
    } catch (error) {
      throw error;
    }
  }

  async getExternalLinkInfo(externalLink: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(
        this.baseUrl + `/notes/external-link/data`, {externalLink},
      )
      return response.data
    } catch (error) {
      throw error;
    }
  }

  async uploadMedia(fmData: FormData): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put('/notes/upload-media', fmData)
      return response.data;
    } catch (error) {
      throw (error)
    }
  }

  async deleteMedia(fileId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete(this.baseUrl + `/notes/delete-media/${fileId}`)
      return response.data;
    } catch (error) {
      throw error
    }
  }
}

export default new NotesApi();
