import * as React from "react";

const SvgDocuments = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M12 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h8zm-1 11H5a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zm0-4H5a1 1 0 1 0 0 2h6a1 1 0 0 0 0-2zm0-4H5a1 1 0 1 0 0 2h6a1 1 0 0 0 0-2z"
        fill={props.color ? props.color : '#545E6B'}/>
    </svg>
  );
}

export default SvgDocuments;
