import React, { useState } from 'react';
import './files-dropzone.scss'
import { Button, notification, Upload } from "antd";
import Axios from "axios";
import { environment } from "../../../../../../environment";
import { queryClient } from "../../../../../../index";
import UploaderPopUp from "../../../../../../_shared/components/uploader-popup/uploader-popup";
import {
  useFileProgressContext
} from "../../../../../../_shared/components/uploader-popup/file-progress-context/file-progress-context";
import SvgAddBig from "../../../../../../assets/icons/js/addBig";
import SvgUpload from "../../../../../../assets/icons/js/Upload";
import SvgUpload3 from "../../../../../../assets/icons/js/Upload3";


const FilesDropzone = (props: any) => {

  const {
    isUploading,
    filesBeingUploaded,
    accPercentage,
    uploadFinished,
    cancelFile,
    project,
    updateFilesAreBeingChanged,
    updateUploading,
    updateFilesBeingUploaded,
    updateProject
  } = useFileProgressContext();


  const showErrorToast = () => {
    notification.open({
      message: "Error",
      description: "There has been a problem with your upload",
      type: "error",
      className: "infobox-error",
      duration: 3,
      placement: "bottomRight",
    });
  };


  const uploadFile = async (options: any) => {
    updateUploading(true);
    const {onSuccess, onError, file, onProgress} = options;

    const fmData = new FormData();
    let token = localStorage.getItem('access_token');

    const source = Axios.CancelToken.source();

    file.source = source;
    file.percent = 0;
    file.status = 'uploading';

    updateFilesBeingUploaded(file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        'Authorization': 'Bearer ' + token,
      },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        file.percent = percent;
        onProgress({percent: (event.loaded / event.total) * 100});
        updateFilesAreBeingChanged()
      },
      cancelToken: source.token
    };

    fmData.append('file', file)

    try {
      const res = await Axios.post(`${environment.backendUrl}/projects/upload/${props.projectId}`, fmData, config);
      onSuccess("Ok");
      if (res.data) {
        file.status = 'done';
        updateFilesAreBeingChanged()
        queryClient.invalidateQueries([`files${props.projectId}`, props.projectId, props.params])
        queryClient.invalidateQueries(['project', String(props.projectId)])
      }
      
    } catch (err) {
      if (err?.message === "canceled") {
        file.status = "canceled"
      } else {
        file.status = "error"
        showErrorToast()
      }
      onError({err})
    }
  }


  let uploadProps = {
    name: 'file',
    multiple: true,
    openFileDialogOnClick: false,
    customRequest: (options: any) => uploadFile(options),
    showUploadList: false
  };

  return (
    <>
      <Upload {...uploadProps} openFileDialogOnClick={true}>
        <Button type="primary" icon={<span className={"anticon"}><SvgUpload3 width={14} height={14}
                                                                             color={"#fff"}/></span>}>
          Upload
        </Button>
      </Upload>
      {
        isUploading &&
        <UploaderPopUp
          fileListData={filesBeingUploaded}
          cancelFile={cancelFile}
          percent={accPercentage}
          name={project?.name || ''}
          uploadFinished={uploadFinished}
        />
      }
    </>
  );
};

export default FilesDropzone;
