import React, { useEffect, useState } from 'react';
import './recycle-bin-projects.scss';
import { Loader } from '../../../../_shared/components/loader/loader';
import { ColumnsType } from 'antd/lib/table';
import InformIconsPipe from '../../../../_shared/helpers/inform-icons-pipe';
import { message, notification, Table } from 'antd';
import { ConfirmationDialog } from '../../../../_shared/components/confirmation-dialog/confirmation-dialog';
import SvgRestoreTrash from '../../../../assets/icons/js/RestoreTrash';
import MyProjectApi from '../../api/project-api';
import { useQuery } from 'react-query';
import FormattedDate from '../../../../_shared/helpers/formatted-date';
import SvgArrowBack from "../../../../assets/icons/js/ArrowBack";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../_shared/components/pagination/pagination";
import usePagination from "../../../../core/hooks/use-pagination";
import SvgTick from "../../../../assets/icons/js/Tick";


export function RecycleBinProjects(props: any): JSX.Element {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const hasSelected = selectedRowKeys.length > 0;

  const archivedColumns: ColumnsType<any> = [
    {
      title: hasSelected ? <div className='selected-rows-title-wrapper'>
        <span>{selectedRowKeys.length} selected</span>
        <div className='selected-rows-btn'
             onClick={() => openConfirmationDialogForMultipleProjects(selectedRowKeys as number[])}>
          <SvgRestoreTrash color={'#9B9B9B'}/>
          &nbsp;Restore
        </div>
      </div> : 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'projectType',
      key: 'projectType',
      render: (projectType: any) => <span className="row align-items-center">
        <InformIconsPipe icon={projectType.icon}
                         width={16}
                         height={16}/>
        &nbsp;
        {projectType.title}
      </span>
    },
    {
      title: 'Archived',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (value: string) => <span>
        <FormattedDate date={value} formatString="MMM d, yyyy"/>
      </span>
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      align: 'right',
      render: (project: any) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <button className="icon-button" onClick={() => openConfirmationDialog(project)}>
              <SvgRestoreTrash/>
            </button>
          </div>
        </div>
      )
    }
  ];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const [confirmationDialog, setConfirmationDialog] = useState<any>({visible: false});
  const archivedProjects = useQuery('archivedProjects', () => MyProjectApi.getArchivedProjects(), {
    onSuccess: (data) => {
      setTotalElements(data?.pagination.totalElements);
    }
  });
  const {items} = usePagination({currentPage: page, pageSize}, archivedProjects.data?.content);
  const navigate = useNavigate();


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const openConfirmationDialog = (project?: any) => {
    setConfirmationDialog({
      visible: true,
      id: project.id,
      okText: 'Restore',
      title: 'Restore',
      titleSec: 'You\'re about to Restore this Project',
      content: `${project.name}`
    })
  }

  const onConfirmationDialogConfirm = (props: any) => {
    if (props.modal) {
      if (props.id) {
        unArchiveProject(props.id, confirmationDialog);
      }

      if (props.ids) {
        unArchiveProjects(props.ids);
      }
      onCancel();
    }
  }

  const openConfirmationDialogForMultipleProjects = (selectedRowKeys?: number[]) => {
    setConfirmationDialog({
      visible: true,
      ids: selectedRowKeys,
      okText: 'Restore',
      title: 'Restore to Projects',
      titleSec: 'You\'re about to restore these projects to the Recycle Bin',
      content: `${selectedRowKeys?.length} projects`,
      description: <>
        Continuing will remove the projects from this list..
      </>
    });
  }

  const unArchiveProjects = (ids: number[]) => {
    if (ids) {
      MyProjectApi.bulkRestoreProjects(ids)
        .then(() => {
          archivedProjects.refetch();
          message.success({
            className: 'custom-success-message',
            content: `${ids.length} projects have been restored`,
            icon: <SvgTick color={'#00A86B'}/>,
          });
        });
    }
  }

  const onCancel = () => {
    setConfirmationDialog({visible: false});
  }

  function unArchiveProject(id: number, project: any): void {
    if (id) {
      MyProjectApi.restoreProject(id)
        .then(() => {
          archivedProjects.refetch();
          message.success({
            className: 'custom-success-message',
            content: `${project.content} has been restored`,
            icon: <SvgTick color={'#00A86B'}/>,
          })
        });
    }
  }

  return (
    <>
      {archivedProjects.isLoading ? <Loader/> :
        <div className="archived-projects" style={{marginBottom: 40}}>
          <div className="row align-items-center" style={{gap: 10, marginBottom: 20}}>
            <div className="column align-items-center back-button">
              <button className="icon-button back" onClick={() => navigate(-1)}>
                <SvgArrowBack/>
              </button>
            </div>
            <h2>Recycle Bin</h2>
          </div>
          {
            totalElements > 0 &&
            <div className="row justify-space-end" style={{marginBottom: 20}}>
              <Pagination currentPage={page}
                          totalItems={totalElements}
                          pageSize={pageSize}
                          showSizeChanger
                          onChange={(page, pageSize) => {
                            setPage(page)
                            setPageSize(pageSize)
                          }}/>
            </div>
          }

          <Table columns={archivedColumns}
                 pagination={false}
                 dataSource={items}
                 rowSelection={rowSelection}
                 rowKey="id"/>
        </div>
      }
      {confirmationDialog.visible &&
          <ConfirmationDialog data={confirmationDialog}
                              onConfirm={onConfirmationDialogConfirm}
                              onCancel={onCancel}/>
      }
    </>
  );
}
