import { Loader } from '../../../../../../../_shared/components/loader/loader';
import './top-connections-dialog.scss';
import React, { useEffect, useState } from 'react';
import { Empty, Modal } from 'antd';
import SvgKnowledgeGraph from '../../../../../../../assets/icons/js/knowledge-graph';
import InterviewsApi from '../../api/interviews-api';
import { IGraph, ISelectedGraph, ITopKnowledgeGraphDetails, } from '../../model/top-knowledge-graph-details.interface';
import SvgDocuments from '../../../../../../../assets/icons/js/documents';

export function TopConnectionsDialog(props: any): JSX.Element {
  const [data] = useState<any>(props.data);
  const [loader, setLoader] = useState<boolean>(true);
  const [graphDetails, setGraphDetails] = useState<ITopKnowledgeGraphDetails[]>([]);

  useEffect(() => {
    loadPage();
  }, []);

  function title(): JSX.Element {
    return (
      <div
        className="d-flex row align-items-center"
        style={{color: '#545e6b'}}
      >
        <SvgKnowledgeGraph style={{marginRight: 10}}/>
        <span>
          {data.graph.name} - {data.graph.totalAssigned} connections
        </span>
      </div>
    );
  }

  function loadPage(): void {
    InterviewsApi.getSpecificGraph(data.graph.id, data.projectId).then(
      (response: ITopKnowledgeGraphDetails[]) => {
        setGraphDetails(response);
        setLoader(false);
      }
    );
  }

  return (
    <Modal
      title={title()}
      open={props.visible}
      onCancel={() => props.onCancel()}
      centered
      width={'60%'}
      footer={[]}>
      <div style={{maxHeight: '85vh', overflow: 'scroll'}}>
        {loader ? <Loader/> :
          <div className="top-connections-dialog">
            {!!graphDetails && graphDetails.length ? (
              <>
                {graphDetails.map(
                  (graphDetail: ITopKnowledgeGraphDetails, index: number) => {
                    return (
                      <div key={index} className="interview">
                        <div className="title-container">
                          <div className="title-connections">
                            <SvgDocuments
                              color={'#1B87E6'}
                              style={{marginRight: 8}}
                            />
                            <div className="title">{graphDetail.title}</div>
                            <div className="connections">
                              {graphDetail.totalConnections} connections
                            </div>
                          </div>
                          <div className="date">{graphDetail.date}</div>
                        </div>
                        <div className="hr"/>
                        {graphDetail?.selected?.map(
                          (selected: ISelectedGraph, index: number) => {
                            return (
                              <div key={index} className="selected-graph-card">
                                <div className="content">
                                  “…{selected.selectedText}…”
                                </div>
                                <div className="footer">
                                  {selected?.graphs?.map((graph: IGraph) => {
                                    return (
                                      <div
                                        className={
                                          graph.selected
                                            ? 'node node-selected'
                                            : 'node'
                                        }
                                      >
                                        {graph.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            )}
          </div>
        }
      </div>
    </Modal>
  );
}
