import React from "react";

export const EvidenceStrategy = (props: any) => {
  function openEvidenceDialog(): void {
    if (!props?.readOnly) {
      props?.openEvidenceDialog(true, props?.entityKey);
    }
  }

  // onclick clickNode
  return (
    <span id={props?.entityKey}
          onClick={openEvidenceDialog}
          className={!props?.readOnly ? "evidence pointer" : "evidence"}>
      {props?.children}
    </span>
  );
};
