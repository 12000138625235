import * as React from 'react';

const SvgFlagAlert = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm0-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM7.024 3v6h2V3h-2z"
        fill={props.color ? props.color : "#9B9B9B"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlagAlert;
