import React, { memo, useCallback, useEffect, useState } from 'react';
import { Popover, Spin, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { FilterDropdownProps } from "antd/es/table/interface";
import FilterDropdown from "../../filter-dropdown/filter-dropdown";
import InformIconsPipe from "../../../../_shared/helpers/inform-icons-pipe";
import DaysLeftDatePipe from "../../../../_shared/helpers/days-left-date-pipe";
import { IProject } from "../../models/project.interface";
import SvgMove from "../../../../assets/icons/js/move";
import SvgTrash from "../../../../assets/icons/js/Trash";
import SvgArrowUp from "../../../../assets/icons/js/ArrowUp";
import SvgArrowDown from "../../../../assets/icons/js/ArrowDown";
import { ProjectColumnPipe } from "../../models/project-columns.pipe";
import { ITag } from "../../../settings/settings-tabs/taxonomy/meta-tags/model/tag.interface";
import SvgEdit from "../../../../assets/icons/js/Edit";
import TagMatchesDialog from "../../../../_shared/components/tag-matches-dialog/tag-matches-dialog";
import SvgDownload from "../../../../assets/icons/js/download";
import { LoadingOutlined } from "@ant-design/icons";

function CustomTable({
                       projects,
                       filters,
                       openMoveDialog,
                       openConfirmationDialog,
                       openConfirmationDialogForMultipleProjects,
                       projectParams,
                       onSetParams,
                       selectedRowKeys,
                       setSelectedRowKeys,
                       tableData
                     }: any): JSX.Element {
  const [columns, setColumns] = useState<any>();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: "updatedAt", order: "descend"
  });
  const [sort, setSort] = useState<any>('updatedAt:desc');
  const [dropdownVisible, setDropdownVisible] = useState<any>({});

  const [params, setParams] = useState<any>({...projectParams});

  const [tagMatchesDialog, setTagMatchesDialog] = useState<any>({open: false});

  let isSorted = sortedInfo || {};


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const initialColumns: any = [
    {

      title: hasSelected ?
        <div className='selected-rows-title-wrapper'>
          <span>{selectedRowKeys.length} selected</span>
          <div className='selected-rows-btn' onClick={() => openConfirmationDialogForMultipleProjects(selectedRowKeys)}>
            <SvgTrash color={'#9B9B9B'}/>
            &nbsp;Delete
          </div>
        </div> : <RenderTitle columnKey={"name"}>Project</RenderTitle>,
      dataIndex: "name",
      key: "name",
      width: 300,
      sorter: !hasSelected,
      sortOrder: sortedInfo && sortedInfo?.columnKey === "name" && sortedInfo.order,
      className: "only-sorters",
      render: (value: string, project: any) => (
        <Link to={"/projects/edit?id=" + project.id} className="project-name">
          {value}
        </Link>
      ),
      hidden: false,
    },
    {
      title: () => <RenderTitle filter={params.projectType} sortName={"projectType"}>Type</RenderTitle>,
      dataIndex: "form",
      key: "type",
      width: 300,
      filterDropdown: (props: FilterDropdownProps) => <FilterDropdown
        {...props}
        sort={sort}
        params={params}
        handleSortChange={handleSortChangeOnDropdown}
        sortName={"projectType"}
        paramsValue={"projectType"}
        setFilter={(val: any) => setToParams({projectType: val})}
        title="Type"
        options={filters.data?.projectTypes}/>,
      sorter: true,
      sortOrder: isSorted?.columnKey === "projectType" && isSorted.order,
      className: "filters-and-sorters",
      filterIcon: () => renderIcon("type", params.projectType, "projectType"),
      onFilterDropdownVisibleChange: (visible: boolean) => handleDropdownVisibleChange("type", visible),
      render: (value: any) =>
        <div className="row align-items-center" style={{gap: 10}}>
          <div>
            <InformIconsPipe
              icon={value?.icon}
              width={16}
              height={16}/>
          </div>
          <div className="cell-value-color">
            {value?.title}
          </div>
        </div>,
      hidden: false,
      ellipsis: true
    },
    {
      title: () => <RenderTitle filter={params.owners} sortName={"owner"}>Owner</RenderTitle>,
      key: "owner",
      dataIndex: "owner",
      width: 200,
      sorter: true,
      sortOrder: isSorted?.columnKey === "owner" && isSorted.order,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterDropdown
          {...props}
          sort={sort}
          params={params}
          handleSortChange={handleSortChangeOnDropdown}
          sortName={"owner"}
          setFilter={(value) => setToParams({owners: value})}
          title="Owner"
          options={filters.data?.owners}
        />
      ),
      className: "filters-and-sorters",
      filterIcon: () => renderIcon("owner", params.owners, "owner"),
      onFilterDropdownVisibleChange: (visible: boolean) =>
        handleDropdownVisibleChange("owner", visible),
      render: (value: any) => <span>{value?.displayName}</span>,
      hidden: false,
      ellipsis: true
    },
    {
      title: () => (
        <RenderTitle filter={params.workflow} sortName={"workflow"}>
          Workflow
        </RenderTitle>
      ),
      dataIndex: "workflow",
      key: "workflow",
      width: 174,
      sorter: true,
      sortOrder: isSorted?.columnKey === "workflow" && isSorted.order,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterDropdown
          {...props}
          params={params}
          sort={sort}
          handleSortChange={handleSortChangeOnDropdown}
          sortName={"workflow"}
          setFilter={(val: any) => setToParams({workflow: val})}
          title="Workflow"
          options={filters.data?.workflows}
        />
      ),
      className: "filters-and-sorters",
      filterIcon: () => renderIcon("workFlow", params.workflow, "workflow"),
      onFilterDropdownVisibleChange: (visible: boolean) =>
        handleDropdownVisibleChange("workFlow", visible),
      render: (value: any) => <span>{value?.name}</span>,
      hidden: false,
      ellipsis: true
    },
    {
      title: () => (<RenderTitle filter={params.workflowStatus} sortName={"status"}>Status</RenderTitle>),
      dataIndex: "workflowStatus",
      key: "status",
      width: 174,
      sorter: true,
      sortOrder: isSorted?.columnKey === "status" && isSorted.order,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterDropdown
          {...props}
          sort={sort}
          params={params}
          handleSortChange={handleSortChangeOnDropdown}
          sortName={"status"}
          setFilter={(val: any) => setToParams({workflowStatus: val})}
          title="Status"
          options={filters.data?.workflowsStatuses}
        />
      ),
      className: "filters-and-sorters",
      filterIcon: () => renderIcon("status", params.workflowStatus, "status"),
      onFilterDropdownVisibleChange: (visible: boolean) =>
        handleDropdownVisibleChange("status", visible),
      render: (value: any) => (
        <div>
          {value?.name}
        </div>
      ),
      hidden: false,
      ellipsis: true
    },
    {
      title: () => (<RenderTitle filter={params.programIds} sortName={"program"}>Program</RenderTitle>),
      dataIndex: "program",
      key: "program",
      width: 174,
      sorter: true,
      sortOrder: isSorted?.columnKey === "program" && isSorted.order,
      filterDropdown: (props: any) => (
        <FilterDropdown
          {...props}
          sort={sort}
          params={params}
          handleSortChange={handleSortChangeOnDropdown}
          sortName={"program"}
          setFilter={(val: any) => setToParams({programIds: val})}
          title="Program"
          options={filters.data?.programs}
        />
      ),
      className: "filters-and-sorters",
      filterIcon: () => renderIcon("program", params.programIds, "program"),
      onFilterDropdownVisibleChange: (visible: boolean) =>
        handleDropdownVisibleChange("program", visible),
      render: (value: any) => (
        <div>
          {value}
        </div>
      ),
      hidden: false,
      ellipsis: true
    },
    {
      title: "Tags",
      dataIndex: "projectTags",
      key: "projectTags",
      width: 300,
      sorter: true,
      sortOrder: isSorted?.columnKey === "projectTags" && isSorted.order,
      className: "only-sorters",
      render: (tags: any) => <div className="project-tags-wrapper">
        {tags.slice(0, 5).map((tag: any) => {
          return (
            <div className="project-tag pointer" key={tag.id} onClick={() => openTagMatchesDialog(tag.tag)}>
              {tag.tag.name}
            </div>
          )
        })
        }
        {
          tags && tags.length > 5 ?
            <Popover placement={"bottomLeft"}
                     content={<div className="popover-project-tags-wrapper">
                       {tags.slice(5).map((tag: any) => {
                         return <div className="project-tag pointer" key={tag.id}
                                     onClick={() => openTagMatchesDialog(tag.tag)}>
                           {tag.tag.name}
                         </div>
                       })}
                     </div>}>
              <div className="project-tag">
                +{tags.length - 5}
              </div>
            </Popover>
            : <></>
        }

      </div>,
      hidden: false,

    },
    {
      title: () => "Folder",
      dataIndex: "folder",
      key: "folder",
      className: "only-sorters",
      render: (value: string, project: any) => (<span>{project?.folder?.name}</span>),
      hidden: false,
      ellipsis: true,
      width: 150,
    },
    {
      title: () => <span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>Contact email address</span>,
      dataIndex: "contactEmailAddress",
      key: "contactEmailAddress",
      className: "only-sorters",
      hidden: false,
      width: 200,
      // ellipsis: true
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 144,
      sorter: true,
      sortOrder: isSorted?.columnKey === "dueDate" && isSorted.order,
      className: "only-sorters",
      render: (value: any) => <span>{DaysLeftDatePipe(value)}</span>,
      hidden: false,
      ellipsis: true
    },
    {
      title: "Created on",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 144,
      sorter: true,
      sortOrder: isSorted?.columnKey === "createdAt" && isSorted.order,
      className: "only-sorters",
      render: (value: any) => <span>{DaysLeftDatePipe(value)}</span>,
      hidden: false,
      // ellipsis: true
    },
    {
      title: "Modified",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 144,
      sorter: true,
      sortOrder: isSorted?.columnKey === "updatedAt" && isSorted.order,
      className: "only-sorters",
      render: (value: any) => <span>{DaysLeftDatePipe(value)}</span>,
      hidden: false,
    },
    {
      title: "",
      dataIndex: "id",
      key: "x",
      width: 45,
      align: "right",
      render: (id: number, project: IProject) => (
        <div className="action-hover-column">
          <div className="action-buttons">
            <Tooltip placement="bottom" title="Change Program">
              <button className="icon-button" onClick={() => openMoveDialog(project)}>
                <SvgMove/>
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title="Move To Recycle Bin">
              <button className="icon-button" onClick={() => openConfirmationDialog(project)}>
                <SvgTrash/>
              </button>
            </Tooltip>
          </div>
        </div>
      ),
      hidden: false
    },
  ].filter((item: any) => !item.hidden);

  // useEffect(() => {
  //   const thsWithoutSelectedRowsTitle = document.querySelectorAll('th:not(:has(.selected-rows-title-wrapper))');
  //   if (hasSelected) {
  //
  //
  //     thsWithoutSelectedRowsTitle.forEach(th => {
  //       const thElement = th as HTMLElement;
  //       const specifiedClassElement = thElement.querySelector('.ant-table-filter-column') as HTMLElement;
  //       const specifiedClassElement1 = thElement.querySelector('.ant-table-column-sorters') as HTMLElement;
  //       if (specifiedClassElement) {
  //         specifiedClassElement.style.display = 'none';
  //       }
  //       if (specifiedClassElement1) {
  //         specifiedClassElement1.style.display = 'none';
  //       }
  //     });
  //   } else {
  //     thsWithoutSelectedRowsTitle.forEach(th => {
  //       const thElement = th as HTMLElement;
  //       const specifiedClassElement = thElement.querySelector('.ant-table-filter-column') as HTMLElement;
  //       const specifiedClassElement1 = thElement.querySelector('.ant-table-column-sorters') as HTMLElement;
  //       if (specifiedClassElement) {
  //         specifiedClassElement.style.display = 'flex';
  //       }
  //       if (specifiedClassElement1) {
  //         specifiedClassElement1.style.display = 'flex';
  //       }
  //     })
  //   }
  // }, [hasSelected]);

  useEffect(() => {
    if (tableData) {
      reOrderColumns();
    }
  }, [tableData]);

  useEffect(() => {
    onSetParams({
      ...params,
      sort,
    });
  }, [params]);

  useEffect(() => {
    if (projects.data) {
      setColumns(initialColumns);
    }

    reOrderColumns();
  }, [projects.data, selectedRowKeys]);

  const setToParams = (value: any): any => {
    setParams((prev: any) => ({
      ...(prev || {}),
      ...value
    }));
  }

  const handleChange = (pagination: any, filter: any, sorter: any) => {
    const orderMap: Record<string, string> = {
      ascend: "asc",
      descend: "desc"
    };

    const order: string | undefined = orderMap[sorter.order];

    if (order) {
      const sortValue = `${sorter.field}:${order}`;
      setSort(sortValue);
      setSortedInfo(sorter);
      setToParams({sort: sortValue})
    } else {
      setSort("");
      setToParams({sort: ""})
      setSortedInfo(undefined);
    }
  };

  const handleSortChangeOnDropdown = useCallback((sortName: string, order: string | undefined) => {
    if (order) {
      const updatedSortedInfo = {
        columnKey: sortName,
        order: order === "asc" ? "ascend" : "descend"
      };

      if (sortName === updatedSortedInfo.columnKey) {
        const sortValue = `${sortName}:${order}`
        setSort(sortValue);
        setToParams({sort: sortValue})
        setSortedInfo(updatedSortedInfo);
      }
    } else if (sortName === sort.split(':')[0]) {
      setToParams({sort: ""})
      setSort("");
      setSortedInfo(null);
    }

  }, [sort]);

  const renderIcon = (columnKey: string, filter: string, sortName: string): JSX.Element => {
    const color = filter?.length > 0 || sort.includes(sortName) ? "#1b87e6" : "#bfbfbf";

    if (dropdownVisible[columnKey]) {
      return <SvgArrowUp color={color}/>
    } else {
      return <SvgArrowDown color={color}/>
    }
  };

  function RenderTitle(props: any): JSX.Element {
    return (
      <div style={{
        color:
          props.filter?.length > 0 || sort.includes(props.sortName)
            ? "#1b87e6"
            : "#545e6b",
      }}>
        {props.children}
      </div>
    );
  };

  const handleDropdownVisibleChange = (columnKey: string, visible: boolean) => {
    let temp: any = dropdownVisible;
    temp[columnKey] = visible;
    setDropdownVisible(temp);
  };

  const reOrderColumns = () => {
    tableData.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);
    const newColumns: any[] = [];

    tableData.forEach((customizeColumn: any) => {
      const columnTitle = ProjectColumnPipe(customizeColumn.name);
      const match = initialColumns.find((column: any) => column.dataIndex === columnTitle);
      if (match) {
        match.hidden = !customizeColumn.selected;
        newColumns.push(match);
      }
    });

    setColumns(newColumns.filter((item: any) => !item.hidden));
  }

  const openTagMatchesDialog = (tag: ITag) => {
    setTagMatchesDialog({open: true, tag: tag})
  }

  const closeTagMatchesDialog = () => {
    setTagMatchesDialog({open: false})
  }


  return <>
    <Table
      loading={projects.isLoading}
      dataSource={projects.data?.content}
      columns={columns}
      scroll={{y: "calc(100vh - 320px)"}}
      onChange={handleChange}
      rowSelection={rowSelection}
      rowKey="id"
      pagination={false}/>
    {
      tagMatchesDialog.open &&
      <TagMatchesDialog data={tagMatchesDialog} onSave={() => {
      }} onCancel={closeTagMatchesDialog}/>
    }
  </>
}


export default memo(CustomTable);
