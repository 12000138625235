import React, { useEffect, useRef, useState } from 'react';
import { IFlagType } from '../../../../../../settings/settings-tabs/interview-labs-settings/model/flag-type.interface';
import FlagIconsPipe from '../../../../../../../_shared/helpers/flag-icons-pipe';
import {
  FlagsDropDown
} from '../../../../../../../_shared/components/draft-js/components/floating-menu/flags-drop-down';
import { useOnClickOutside } from '../../../../../../../core/hooks/use-outside-click';
import { getFlagByEntityMapId } from '../../../../../../../_shared/components/draft-js/components/helpers/entity';

export function DisplayFlag(props: any): JSX.Element {
  const flags: IFlagType[] = props.flag?.flags;
  const [entityData, setEntityData] = useState<any>();
  const [newSelectedFlags, setNewSelectedFlags] = useState<any>();
  let component = useRef(null);
  const [visibleDropDown, setVisibleDropDown] = useState<boolean>(false);

  useOnClickOutside(component, () => {
    if (visibleDropDown) {
      props.assignFlagsToSelectedText({
        selectedFlags: newSelectedFlags,
        entityKey: entityData.entityKey,
        entityMapId: props?.flag.entityMapId
      });

      setVisibleDropDown(false);
    }
  });

  useEffect(() => {
    if (!entityData) {
      setEntityData(getFlagByEntityMapId(props.editorState, props.flag?.entityMapId));
    }
  }, []);

  function openDropDown(): void {
    if (!props?.readOnly) {
      setVisibleDropDown(true);
    }
  }

  function assignFlagsToSelectedText(selectedFlags: IFlagType[]): void {
    setNewSelectedFlags(selectedFlags);
  }


  return (
    <div ref={component} data-testid={"open-dropdown"}
         className={props?.readOnly ? "display-highlight-card" : "display-highlight-card pointer"}
         onClick={openDropDown}>
      {
        flags.length && flags.map((flag: IFlagType, index: number) => {
          return (
            <div key={index} className="flag">
              <FlagIconsPipe icon={flag?.icon && flag?.icon}
                             color={flag?.color && flag?.color}/>

              {visibleDropDown &&
                <FlagsDropDown key={index.toString(35) + index}
                               selectedFlags={entityData.flags}
                               assignFlagsToSelectedText={assignFlagsToSelectedText}
                               flags={props?.flags}/>
              }
            </div>
          )
        })
      }
    </div>
  );
}
