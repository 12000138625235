import React from 'react';
import './project-management.scss';
import { Metrics } from "./key-metrics/metrics";
import { Workflows } from '../workflows/workflows';

interface IPage {
  title: string;
  content: JSX.Element;
}

export function ProjectManagement(): JSX.Element {
  const pages: IPage[] = [
    {
      title: "Workflows",
      content: <Workflows/>
    },
    {
      title: "Metrics",
      content: <Metrics/>
    }
  ]

  return (
    <div className="setup column">
      {pages.map((page: IPage, i: number) =>
        <div className="main-title" key={i}>
          <h4>{page.title}</h4>
          <div className="page">
            {page.content}
          </div>
        </div>
      )}
    </div>
  );
}
