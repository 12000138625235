// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags-table .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.tags-table .tags .tag {
  padding: 2px 7px;
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
}
.tags-table .tags .tag:hover {
  border: 1px solid #1b87e6;
}
.tags-table .selected {
  background-color: #1b87e6 !important;
  color: white;
  border: 1px solid #1b87e6 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/linked-projects/add-tags-modal/add-tags-modal.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,eAAA;EACA,SAAA;AAAJ;AAEI;EACE,gBAAA;EACA,yBAAA;EACA,yBAAA;AAAN;AAEM;EACE,yBAAA;AAAR;AAKE;EACE,oCAAA;EACA,YAAA;EACA,oCAAA;AAHJ","sourcesContent":[".tags-table {\n  .tags {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n\n    .tag {\n      padding: 2px 7px;\n      background-color: #F5F5F5;\n      border: 1px solid #F5F5F5;\n\n      &:hover {\n        border: 1px solid #1b87e6;\n      }\n    }\n  }\n\n  .selected {\n    background-color: #1b87e6 !important;\n    color: white;\n    border: 1px solid #1b87e6 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
