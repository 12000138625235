import React, { useState } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { INotepadType } from "../model/types.interface";
import "./create-notepad.scss";
import { notepadTypes } from "../_shared/notepad-type";

const CreateNotepadModal = (props: any) => {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState<null | number>(2);

  const selectType = (id: number): any => {
    if (id === selectedTemplate) {
      setSelectedTemplate(null);
    } else {
      setSelectedTemplate(id);
    }
  };

  const handleApply = (notepadId: any) => {
    if (!notepadId) {
      return;
    }

    handleCancel();
    navigate(
      `/projects/edit/interview?id=${props.projectId}&type=${selectedTemplate}`
    );
  };

  const handleCancel = () => {
    props.closeModal();
  };

  return (
    <Modal
      open={props.visible}
      onOk={() => handleApply(selectedTemplate?.toString())}
      onCancel={handleCancel}
      cancelButtonProps={{type: "link"}}
      width={800}
      title={<div style={{color: "#545E6B"}}>New Interview</div>}
      okText="Create New Interview">
      <div className="create-notepad-container">
        <p>Select a template to create a new interview</p>
        <div className="create-notepad-wrapper">
          {notepadTypes.map((type: INotepadType) => (
            <div
              key={type.id}
              onClick={() => selectType(type.id)}
              className={
                selectedTemplate === type.id ? "card card-selected" : "card"
              }
            >
              <div className="card-body">
                <div className="card-image">{type.icon}</div>
                <span className="card-title">{type.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CreateNotepadModal;
