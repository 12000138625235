import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import Upload, { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import SvgTrash from '../../../../assets/icons/js/Trash';
import { Loader } from '../../loader/loader';
import mediaApi from '../api/media-api';
import { IImage } from '../model/gallery.interface';
import Pagination from "../../pagination/pagination";

const MediaGallery = (props: any) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState("");
  const [pageSize, setPageSize] = useState(9);
  const [page, setPage] = useState(1);

  const params = {pageSize, page}
  const gallery = useQuery(['gallery', params], () => mediaApi.getImages(params));

  const handleDeleteClick = (e: any, image: IImage) => {
    e.stopPropagation();
    setDeleteLoading(true);
    mediaApi.deleteImage(image.id).then(() => {
      setDeleteLoading(false);
      gallery.refetch();
    })
  }

  const isSelected = (url: string) => {
    return props.selectedUrl === url
  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setError("Format unvalid. Allowed file extensions: png, jpg");
    }
    return isJpgOrPng;
  };


  const uploadProps = {
    name: 'image',
    multiple: false,
    customRequest: (options: any) => uploadImage(options),
    showUploadList: false,
    beforeUpload

  }

  const uploadImage = (options: any) => {
    const {file} = options;
    setError('');
    const fmData = new FormData();
    fmData.append('image', file);
    mediaApi.uploadImage(fmData).then(() => {
      gallery.refetch();
    })
  }

  const handleChange = (page: any) => {
    setPage(page);
  }

  return (
    <div className="media-library">
      <div className='fetch-container'>
        {
          gallery.isFetching || deleteLoading &&
            <SyncOutlined spin/>
        }
      </div>
      <div className='images'>
        <Upload {...uploadProps}>
          <div className="upload-container">
            <PlusOutlined style={{fontSize: 60, color: "#1b87e6"}}/>
          </div>
        </Upload>
        {
          gallery.isLoading ? <Loader/> :
            gallery.data?.content.map((image: IImage, index: number) => {
              return <div onClick={() => props.handleImageClick({url: image.publicUrl, name: image.name})} key={index}
                          className={isSelected(image.publicUrl) ? "image-container selected-image relative" : "image-container relative"}>
                <img className='image' height={90} src={image.publicUrl} alt=""/>
                <span className='image-name'>{image.name}</span>
                <span onClick={(e: any) => handleDeleteClick(e, image)}
                      className='absolute delete-container'><SvgTrash/></span>
              </div>
            })
        }
      </div>
      <small className='form-error'>{error && error}</small>
      <div className='justify-space-center w-100' style={{marginTop: 20}}>
        {
          gallery.data && gallery.data.pagination.totalElements > 0 &&
          <Pagination
            currentPage={page}
            totalItems={gallery?.data?.pagination?.totalElements}
            pageSize={pageSize}
            onChange={handleChange}/>
        }
      </div>
    </div>
  )
}

export default MediaGallery
