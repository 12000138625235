import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Modal, Progress, Spin, Upload } from "antd";

import { DraftJs } from "../../../../../../_shared/components/draft-js/draft";
import SvgUploadFile from "../../../../../../assets/icons/js/UploadFile";
import { DebounceInput } from "react-debounce-input";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import {
  getLeftDisplayHighlights
} from "../../../../../../_shared/components/draft-js/components/helpers/get-left-display-highlights";
import {
  getDisplayHighlights
} from "../../../../../../_shared/components/draft-js/components/helpers/get-display-highlights";
import {
  DisplayHighlightContainer
} from "../../interviews-labs/interview/display-highlights-components/display-highlight-container";
import "./create-edit-note-dialog.scss"
import { IFlagType } from "../../../../../settings/settings-tabs/interview-labs-settings/model/flag-type.interface";
import InterviewsApi from "../../interviews-labs/api/interviews-api";
import ProjectEditApi from "../../../api/project-edit-api";
import { INugget } from "../../../../models/nugget.interface";
import {
  LeftDisplayHighlightContainer
} from "../../interviews-labs/interview/display-highlights-components/left-display-highlight-container";
import { Loader } from "../../../../../../_shared/components/loader/loader";
import notesApi from "../api/notes-api";
import SvgCancel from "../../../../../../assets/icons/js/Cancel";
import DaysLeftDatePipe from "../../../../../../_shared/helpers/days-left-date-pipe";
import { getMediaTypeByExtension } from "../../../../../../_shared/helpers/get-media-type-by-extension";
import Axios from "axios";
import { environment } from "../../../../../../environment";
import SvgAlert from "../../../../../../assets/icons/js/alert";
import { queryClient } from "../../../../../../index";


const {Dragger} = Upload;

const CreateEditNoteDialog = (props: any) => {
  const draftJsRef: any = useRef(null);

  const uploadProps = {
    customRequest: (options: any) => uploadFile(options),
    multiple: false,
    beforeUpload,
    showUploadList: false
  }

  const [noteId, setNoteId] = useState(props?.data?.noteId ? props?.data.noteId : undefined)
  const [title, setTitle] = useState("");
  const [content, setContent] = useState<string>();
  const [editorState, setEditorState] = useState<EditorState>();
  const [createdBy, setCreatedBy] = useState("");
  const [externalSource, setExternalSource] = useState("");
  const [externalSourceData, setExternalSourceData] = useState<any>();
  const [fetchingExternalSource, setFetchingExternalSource] = useState(false);
  const [fileId, setFileId] = useState<number>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [fileError, setFileError] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const [editMode, setEditMode] = useState(!props?.data?.noteId);
  const [lastEdit, setLastEdit] = useState("")
  const [noteLoading, setNoteLoading] = useState(true);
  const [nuggets, setNuggets] = useState<INugget[]>();
  const [nuggetsLoading, setNuggetsLoading] = useState(true);
  const [flags, setFlags] = useState<IFlagType>();
  const [flagsLoading, setFlagsLoading] = useState(true);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    loadFlags();
    loadNuggets();
  }, []);

  useEffect(() => {
    if (noteId) {
      //fetch note
      notesApi.getNote(noteId).then((note) => {
        setCreatedBy(note.createdBy)
        setLastEdit(note.lastUpdate);
        setFileId(note.fileId)
        setFileUrl(note.fileUrl)
        setFileName(note.fileName)
        setTitle(note.title);
        setContent(note.content);
        setExternalSource(note.externalUrl)
        updateEditorState(note.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(note.content))) : EditorState.createEmpty());
        setNoteLoading(false);
      })
    } else {
      setNoteLoading(false)
    }
  }, []);

  useEffect(() => {
    setFileError(false);
  }, [editMode]);

  useEffect(() => {
    if (!noteLoading) {
      if (externalSource?.length) {
        setFetchingExternalSource(true)
        notesApi.getExternalLinkInfo(externalSource).then((response) => {
          setExternalSourceData(response)
          setFetchingExternalSource(false)
        })
      }
    }

  }, [externalSource])


  const leftDisplayTextHighlights = useMemo(() => {
    if (editorState) {
      return getLeftDisplayHighlights(editorState);
    } else {
      return null;
    }
  }, [editorState]);

  const displayTextHighlights = useMemo(() => {
    if (editorState) {
      return getDisplayHighlights(editorState);
    } else {
      return null;
    }
  }, [editorState]);

  const onSave = () => {
    if (editMode) {
      const payload = {
        projectId: props.data.projectId,
        title,
        content: editorState && JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        //highlights: editorState && RetrieveHighlightsFromDraft(editorState),
        fileId: fileId && fileId,
        externalResource: externalSource ? externalSource : ""
      }

      if (noteId) {
        delete payload.projectId
      }

      if (!noteId) {
        //create note
        setApiLoading(true);
        notesApi.createNote(payload).then((note) => {
          setNoteId(note.id);
          setLastEdit(note.lastUpdate)
          setApiLoading(false);
          setEditMode(false);
          queryClient.invalidateQueries([`notes${props.data.projectId}`, props.data.projectId])
        })

      } else {
        //update note
        setApiLoading(true)
        notesApi.updateNote(noteId, payload).then((response) => {
          setApiLoading(false);
          setEditMode(false);
          queryClient.invalidateQueries([`notes${props.data.projectId}`, props.data.projectId])
        })
      }
      return
    }
    setEditMode(true);
  }

  const onCancel = () => {
    if (editMode) {
      setEditMode(false)
    } else {
      props.onCancel();
    }
  }


  const updateEditorState = (editorState: EditorState) => {
    setEditorState(editorState);
  }

  function openEvidenceDialog(data: any): void {
    if (data?.edit) {
      draftJsRef.current.onOpenEvidenceDialog(data.entityData.entityKey)
    }

    if (data?.delete) {
      draftJsRef.current.onAssignEvidenceToSelectedText(data)
    }
  }

  function assignFlagsToSelectedText(data: {
    selectedFlags: IFlagType[],
    entityKey?: string,
    entityMapId?: string
  }): void {
    if (data?.entityKey) {

      draftJsRef.current.onAssignFlagsToSelectedText(data);
    }
  }

  const uploadFile = (options: any) => {
    setFileError(false);
    setIsUploadingFile(true);
    const baseUrl: string = `${environment.backendUrl}`;
    const {onSuccess, onError, file, onProgress} = options;
    const fmData = new FormData();
    fmData.append('file', file)

    let token = localStorage.getItem('access_token');
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        'Authorization': 'Bearer ' + token,
      },
      onUploadProgress: (event: any) => {
        setPercent(Math.floor((event.loaded / event.total) * 100));
        onProgress({percent: (event.loaded / event.total) * 100});
      },
    };

    Axios.put(`${baseUrl}/notes/upload-media`, fmData, config).then((response) => {
      setFileId(response.data.fileId);
      setFileName(response.data.mediaName);
      setFileUrl(response.data.mediaUrl);
      setIsUploadingFile(false)
    })
  }

  const deleteMedia = (fileId: number | undefined) => {
    if (fileId) {
      notesApi.deleteMedia(fileId).then(() => {
        setFileId(undefined);
        setFileUrl("");
        setFileName("")
      })
    }
  }

  function beforeUpload(file: any) {
    const mediaType = getMediaTypeByExtension(file.name.split(".").pop());
    if (mediaType === 'unknown') {
      setFileError(true);
    }

    return mediaType === 'video' || mediaType === 'photo'
  }

  function loadFlags(): void {
    InterviewsApi.getFlags()
      .then((flags: IFlagType) => {
        setFlags(flags);
        setFlagsLoading(false)
      });
  }


  function loadNuggets(): void {
    ProjectEditApi.getProjectNuggets(props.data.projectId, {pageSize: 9999})
      .then((data) => {
        setNuggets(data.content);
        setNuggetsLoading(false);
      });
  }

  function windowOpen(url: string, name?: string, specs?: string) {
    if (!url.match(/^https?:\/\//i) || !url.match(/^http?:\/\//i)) {
      url = 'http://' + url;
    }
    return window.open(url, name, specs);
  }


  return (
    <Modal
      width={displayTextHighlights !== null ? 1250 : 1000}
      onCancel={onCancel}
      open={props.visible}
      title={props?.data?.noteId ? " Edit note" : "New note"}
      cancelButtonProps={{type: 'link'}}
      cancelText={editMode ? 'Cancel' : 'Close'}
      onOk={onSave}
      okButtonProps={{loading: apiLoading}}
      okText={editMode ? "Save Note" : "Edit Note"}
    >
      <>
        {
          (nuggetsLoading || flagsLoading || noteLoading) ? <Loader/> :
            <div className='note-dialog-wrapper' style={{width: displayTextHighlights?.length ? "80%" : "100%"}}>
              <div className='note-dialog-main'>
                {
                  editMode ?
                    <div className='outline-input-wrapper'>
                      <div className='outline-input-label'>
                        Title
                      </div>
                      <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}
                             className='outline-input'
                             placeholder='Note title' readOnly={!editMode}/>
                    </div> :
                    <div className="note-title-wrapper">
                      <div className="note-title">{title}</div>
                      <div className="note-title-divider"/>
                      <div className='note-user-info'>
                        <span className="note-user-name">{createdBy}</span>
                        {
                          lastEdit &&
                            <span className="note-last-edit">
                            Last edit was {DaysLeftDatePipe(lastEdit)}
                          </span>
                        }
                      </div>
                    </div>
                }
                {
                  (editorState?.getCurrentContent().hasText() || editMode) &&
                    <div className="row" style={{
                      marginLeft: leftDisplayTextHighlights?.length ? -38 : 0
                    }}>
                        <div className="display-highlight-wrapper left-display-highlight-wrapper"
                             style={{marginTop: editMode ? 0 : -12}}
                        >
                            <LeftDisplayHighlightContainer editorState={editorState}
                                                           leftDisplayTextHighlights={leftDisplayTextHighlights}
                                                           openEvidenceDialog={openEvidenceDialog}
                                                           readOnly={!editMode}
                            />
                        </div>
                        <div
                            className={editMode ? "note-editor-wrapper" : "note-editor-wrapper note-editor-wrapper-read-only"}
                        >
                            <DraftJs noteType
                                     ref={draftJsRef}
                                     readOnly={!editMode}
                                     content={content}
                                     nuggets={nuggets}
                                     flags={flags}
                                     flag={true}
                                     updateEditorState={updateEditorState}
                                     assignFlagsToSelectedText={assignFlagsToSelectedText}/>
                        </div>
                      {
                        displayTextHighlights?.length &&
                          <div className="display-highlight-wrapper"
                            // style={{marginTop: editMode ? 10 : -15}}
                          >
                              <DisplayHighlightContainer
                                  flags={flags}
                                  editorState={editorState}
                                  displayTextHighlights={displayTextHighlights}
                                  assignFlagsToSelectedText={assignFlagsToSelectedText}
                                  readOnly={!editMode}
                              />
                          </div>
                      }
                    </div>
                }

              </div>
              <div className='note-type-additional-info'>
                {
                  editMode ?
                    <div className='note-external-source'>
                      <div className='outline-input-wrapper'>
                        <div className='outline-input-label'>
                          Link external source
                        </div>
                        <DebounceInput className='outline-input'
                                       placeholder='Paste your URL here'
                                       value={externalSource}
                                       debounceTimeout={600}
                                       onChange={(e) => setExternalSource(e.target.value)}/>
                      </div>
                      {
                        fetchingExternalSource ? <div style={{marginTop: 10}}>
                            <Spin/>
                          </div>
                          // : externalSourceError ?
                          // <small
                          //   className="form-error"><SvgAlert/> &nbsp; Failed to fetch meta
                          //   information, change url
                          // </small>
                          : externalSourceData && externalSource ?
                            <>
                              <div className='row align-items-center' style={{gap: 10}}>
                                <div className="external-source-name"
                                     onClick={() => windowOpen(externalSource)}>
                                  <i className="bi bi-link-45deg"/>
                                  <span>
                                  {externalSourceData?.ogSiteName}
                                  </span>
                                  <span className='external-source-logo'>
                                  <img src={externalSourceData?.ogImage?.url} alt=''/>
                                  </span>
                                </div>
                                <div className='row align-items-center' style={{marginTop: 5}}>
                                  <span className='external-source-remove-btn pointer'
                                        onClick={() => setExternalSource("")}>
                                    <i className="bi bi-x-lg" style={{fontSize: 16}}/>
                                  </span>
                                </div>
                              </div>

                              <div className="external-source-description">{externalSourceData?.ogDescription}</div>
                            </> :
                            <></>
                      }
                    </div> :
                    externalSource ?
                      <div className='note-external-source note-external-source-read-only'>
                        {
                          fetchingExternalSource ?
                            <div className='row justify-space-center align-items-center' style={{marginTop: 10}}>
                              <Spin/>
                            </div> :
                            <>
                              <div className="external-source-name"
                                   onClick={() => windowOpen(externalSource)}>
                                <i className="bi bi-link-45deg"/> {externalSourceData?.ogSiteName}
                              </div>
                              <div className="external-source-description">{externalSourceData?.ogDescription}</div>
                              <div className="external-source-link row">
                                {
                                  externalSourceData?.ogImage?.url &&
                                    <span className='external-source-logo'>
                                    <img src={externalSourceData?.ogImage?.url} alt=''/>
                                  </span>
                                }
                                <span>
                                  {externalSource}
                                </span>
                              </div>
                            </>
                        }
                      </div> : <></>
                }

                {
                  editMode ?
                    <div className="note-media-dropzone">
                      {
                        fileUrl && fileName ?
                          (getMediaTypeByExtension(fileName?.split(".")?.pop()) === 'video' ?
                            <>
                              <div className="note-media-wrapper">
                                <video controls src={fileUrl}/>
                              </div>
                              <span className="note-media-name">{fileName}
                                <span className='delete-media-btn' onClick={() => deleteMedia(fileId)}> <SvgCancel
                                  width={10}
                                  height={10}/></span>
                                </span>
                            </>
                            :
                            <>
                              <div className="note-media-wrapper">
                                <img src={fileUrl} alt=''/>
                              </div>
                              <span className="note-media-name">{fileName}
                                <span className='delete-media-btn' onClick={() => deleteMedia(fileId)}>
                                <i className="bi bi-x-lg" style={{fontSize: 16}}/>
                                </span>
                                </span>
                            </>)
                          :
                          <div className="note-media-wrapper">
                            <Dragger className="dragger" {...uploadProps}>
                              <div className="dragger-wrapper" style={{height: 90}}>
                                <SvgUploadFile/>
                                {!isUploadingFile ? (
                                  <p className="dragger-text">
                                    Drop your file here or{" "}
                                    <span className="link">Browse</span>
                                  </p>
                                ) : (
                                  <Progress percent={percent} type="line"/>
                                )}
                              </div>
                            </Dragger>
                            <small
                              className="form-error">{fileError ? <> <SvgAlert/> &nbsp; Invalid File
                              type </> : ''}
                            </small>
                          </div>
                      }
                    </div> : (fileUrl && fileName) ?
                      <div className='note-media-dropzone note-media-dropzone-read-only'>
                        {getMediaTypeByExtension(fileName?.split(".")?.pop()) === 'video' ?
                          <>
                            <div className='note-media-wrapper'>
                              <video controls src={fileUrl}/>
                            </div>
                            <span className="note-media-name">{fileName}</span>
                          </>
                          :
                          <>
                            <div className="note-media-wrapper">
                              <img src={fileUrl} alt=''/>
                            </div>
                            <span className="note-media-name">{fileName}</span>
                          </>
                        }
                      </div> : <></>
                }
              </div>
            </div>
        }
      </>
      <Divider style={{marginBottom: -20,}}/>
    </Modal>
  );
};

export default CreateEditNoteDialog;
