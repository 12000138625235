import { RichUtils } from 'draft-js'
import React from 'react'
import "./text-types-dropdown.scss"
import SvgSubtitle from "../../../../../../../assets/icons/js/subtitle";
import SvgHeading from "../../../../../../../assets/icons/js/heading";
import SvgText from "../../../../../../../assets/icons/js/text";
import SvgTitle from "../../../../../../../assets/icons/js/title";

const TextTypesDropDown = (props: any) => {
  const menu: { icon: JSX.Element, text: string, blockType: string, fontSize?: number }[] = [
    {
      icon: <SvgTitle width={14} height={12}/>,
      fontSize: 30,
      text: "Title",
      blockType: 'header-one',
    },
    {
      icon: <SvgSubtitle width={12} height={12}/>,
      text: "Subtitle",
      blockType: 'header-two',
    },
    {
      icon: <SvgHeading width={11} height={12}/>,
      text: "Heading",
      blockType: "header-three"
    },
    {
      icon: <SvgText width={10} height={12}/>,
      text: "Text",
      blockType: "unstyled"
    },
  ];
  const blockType = props.editorState.getCurrentContent().getBlockForKey(props.editorState.getSelection().getStartKey()).getType();

  const handleBlockTypes = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault();
    const newEditorState = RichUtils.toggleBlockType(props.editorState, blockType);
    props.onSetEditorState(newEditorState);
  }

  const isActive = (type: string) => {
    return blockType === type ? 'active-button' : '';
  }

  return (
    <div className='text-types-drop-down'>
      {menu.map((menuItem: any, index: number) => {
        return <div key={index}
                    className={isActive(menuItem.blockType) ? 'text-option active' : 'text-option'}
                    onMouseDown={(e: React.MouseEvent) => handleBlockTypes(e, menuItem.blockType)}>
          <div className="menu-item-icon">{menuItem.icon}</div>
          <div className="text">
            {menuItem.text}
          </div>
        </div>
      })}
    </div>
  )
}

export default TextTypesDropDown
