import React, { useContext, useEffect, useState } from 'react';
import SvgArrowBack from '../../../../../assets/icons/js/ArrowBack';
import DeskSettingsApi from '../../../api/settings-api';
import { Button, Table } from 'antd';
import { Loader } from '../../../../../_shared/components/loader/loader';
import { ColumnsType } from 'antd/lib/table';
import { DebounceInput } from 'react-debounce-input';
import CreateNewUserDialog from './create-new-user/create-new-user-dialog';
import GlobalContext from '../../../../../store/global-context';
import { useNavigate } from 'react-router-dom';

export default function ManageInsightsAdmins(): JSX.Element {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [loader, setLoading] = useState<boolean>(true);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState<boolean>(false);
  const [teamMembers, setTeamMembers] = useState<any>({});
  const [activeMembers, setActiveTeamMembers] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [modalData, setModalData] = useState<any>({visible: false});
  const teamMembersColumns: ColumnsType<any> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    }
  ];

  useEffect(() => {
    getQPTeamMembers();
  }, [searchText]);

  function goBack(): void {
    navigate(-1);
  }

  function openAddTeamMember(): void {
    setModalData({visible: true});
  }

  function onDialogOk(): void {
    getQPTeamMembers();
    onDialogCancel();
  }

  function onDialogCancel(): void {
    setModalData({visible: false});
  }

  // function toggleAdmin(value: boolean, id: string): void {
  //   setLoadingTeamMembers(true);
  //
  //   DeskSettingsApi.toggleAdmin(value, id)
  //     .then((response: any) => {
  //       setTeamMembers(response.users);
  //       setActiveTeamMembers(response.active);
  //       setLoadingTeamMembers(false);
  //     });
  // }

  function getQPTeamMembers(): void {
    setLoadingTeamMembers(true);
    let params: any;

    if (searchText) {
      params = {
        search: searchText
      }
    }

    DeskSettingsApi.getQPTeamMembers(params)
      .then((response: any) => {
        setTeamMembers(response.users);
        setActiveTeamMembers(response.active);
        setLoading(false);
        setLoadingTeamMembers(false);
      });
  }

  return (
    <>
      {
        loader ?
          <Loader/>
          :
          <div className='intake-form column' style={{paddingTop: 100, paddingBottom: 0}}>
            <div className="intake-form-header justify-space-between">
              <div className="row align-items-center">
                <button data-testid={"go-back"} className="icon-button" onClick={goBack}>
                  <SvgArrowBack color="#545E6B"/>
                </button>
                <span className="font-weight-300">Manage insights admins</span>
              </div>
            </div>
            <div className="body">
              <div className="row justify-space-between" style={{marginBottom: 25}}>
                <div className="outline-input-wrapper" style={{width: '250px'}}>
                  <DebounceInput
                    name="theme"
                    className="outline-input"
                    debounceTimeout={400}
                    placeholder="Search users from QuestionPro"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}/>
                </div>

                <Button type="primary" style={{marginLeft: '15px'}} onClick={openAddTeamMember}>Create new user</Button>
              </div>
              <Table
                loading={{spinning: loadingTeamMembers, indicator: <Loader/>}}
                columns={teamMembersColumns}
                className="w-100"
                pagination={false}
                dataSource={teamMembers}
                rowKey="email"/>
            </div>
            {modalData.visible
              && <CreateNewUserDialog data={modalData} onOk={onDialogOk} onCancel={onDialogCancel}/>
            }
          </div>
      }
    </>
  )
}
