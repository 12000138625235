import { convertToRaw, EditorState } from 'draft-js';

export function RetrieveHighlightsFromStoryEditor(editorState: EditorState) {
  const contentState = convertToRaw(editorState.getCurrentContent());
  const projects = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'PROJECT').map((v: any) => v[1].data)
    .map(obj => {
      return {
        project: obj?.project,
        text: obj?.selectedText, // selectedText
        entityMapId: obj?.entityMapId
      }
    });

  const links = Object.entries(contentState.entityMap).filter((v: any) => v[1].type === 'LINK').map((v: any) => v[1].data)
    .map(obj => ({
      url: obj.url,
      text: obj?.selectedText, // selectedText
    }));

  return {projects, links};
}