export enum EventTypesEnum {
  PROJECT_STATUS_CHANGED = "PROJECT_STATUS_CHANGED",
  PROJECT_NAME_CHANGED = "PROJECT_NAME_CHANGED",
  PROJECT_OWNER_CHANGED = "PROJECT_OWNER_CHANGED",
  PROJECT_CREATED = "PROJECT_CREATED",
  PROJECT_PRICING_MODEL_CHANGED = "PROJECT_PRICING_MODEL_CHANGED",
  PROJECT_DESCRIPTION_CHANGED = "PROJECT_DESCRIPTION_CHANGED",
  PROJECT_KEY_METRIC_CHANGED = "PROJECT_KEY_METRIC_CHANGED",
  PROJECT_NUGGET_CREATED = "PROJECT_NUGGET_CREATED",
  PROJECT_CHANGED_REQUESTED = "PROJECT_CHANGED_REQUESTED",
  PROJECT_NUGGET_LINKED_TO_KG = "PROJECT_NUGGET_LINKED_TO_KG",
  PROJECT_QUOTE_LINKED_TO_KG = "PROJECT_QUOTE_LINKED_TO_KG",
  PROJECT_QUOTE_LINKED_TO_FLAG = "PROJECT_QUOTE_LINKED_TO_FLAG",
  PROJECT_QUOTE_LINKED = "PROJECT_QUOTE_LINKED",
  PROJECT_TAGS_CHANGED = "PROJECT_TAGS_CHANGED",
  PROJECT_THEME_CHANGED = "PROJECT_THEME_CHANGED",
  PROJECT_RECOMMENDATION_CHANGED = "PROJECT_RECOMMENDATION_CHANGED",
  PROJECT_FINDING_CREATED = "PROJECT_FINDING_CREATED",
  PROJECT_FINDING_LINKED_TO_KG = "PROJECT_FINDING_LINKED_TO_KG",
  PROJECT_FORM_VALUE_CHANGED = "PROJECT_FORM_VALUE_CHANGED",
  PROJECT_SURVEY_LINKED = "PROJECT_SURVEY_LINKED",
  INTERVIEW_CREATED = "INTERVIEW_CREATED",
  UPDATED = 'UPDATED',
  ARCHIVED = 'ARCHIVED',
  PROJECT_INDEX = "PROJECT_INDEX",
  STORY_CREATED = "STORY_CREATED",
  STORY_VIEWED_FROM_HOME = "STORY_VIEWED_FROM_HOME",
  STORY_VIEWED_FROM_MENU = "STORY_VIEWED_FROM_MENU",
  KNOWLEDGE_GRAPH_CREATED = "KNOWLEDGE_GRAPH_CREATED",
  KNOWLEDGE_GRAPH_UPDATED = "KNOWLEDGE_GRAPH_UPDATED",
}

export enum GeneralEventTypesEnum {
  PROJECT_RELATED = "PROJECT_RELATED",
  STORY_RELATED = "STORY_RELATED",
  KNOWLEDGE_GRAPH_RELATED = "KNOWLEDGE_GRAPH_RELATED",
  NUGGET_RELATED = "NUGGET_RELATED",
  NOTEPAD_RELATED = "NOTEPAD_RELATED"
}