import * as React from "react";

const SvgShopping = (props: any) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13 11a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4zM5 1l.75 2H14a1 1 0 011 1v4a1 1 0 01-1 1H5L3 3H2a1 1 0 110-2h3z"
        fill={props.color ? props.color : '#545E6B'}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgShopping;
