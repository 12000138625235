import { ProjectTypeIconsEnum } from '../model/project-type-icons.enum';
import SvgConversation from '../../assets/icons/js/Conversation';
import SvgMarket from '../../assets/icons/js/Market';
import SvgRating from '../../assets/icons/js/Rating';
import SvgProduct from '../../assets/icons/js/Product';
import SvgRestaurant from '../../assets/icons/js/Restaurant';
import SvgSatisfaction from '../../assets/icons/js/Satisfaction';
import SvgScience from '../../assets/icons/js/Science';
import SvgShopping from '../../assets/icons/js/Shopping';
import SvgAnimal from '../../assets/icons/js/Animal';
import SvgSurvey from '../../assets/icons/js/Survey';

const InformIconsPipe = (props: any) => {
  const iconProps: { width?: string, height?: string, color?: string } = {}

  if (props.width) {
    iconProps.width = props.width
  }

  if (props.height) {
    iconProps.height = props.height
  }

  if (props.color) {
    iconProps.color = props.color
  }

  const pipe = () => {
    switch (props.icon) {
      case ProjectTypeIconsEnum.IH_CONVERSATION:
        return <SvgConversation {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_MARKET:
        return <SvgMarket {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_RATING:
        return <SvgRating {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_PRODUCT:
        return <SvgProduct {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_RESTAURANT:
        return <SvgRestaurant {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_SATISFACTION:
        return <SvgSatisfaction {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_SCIENCE:
        return <SvgScience {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_SHOPPING:
        return <SvgShopping {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_SURVEY:
        return <SvgSurvey {...iconProps}/>;
      case ProjectTypeIconsEnum.IH_ANIMAL:
        return <SvgAnimal {...iconProps}/>;
      default:
        return null;
    }
  }

  const renderIcon = (): JSX.Element => {
    const oldIcon = pipe();

    if (oldIcon === null) {
      return <i className={`bi-${props.icon}`} style={{
        fontSize: iconProps.width ? iconProps.width : 20,
        color: iconProps.color ? iconProps.color : '#545E6B'
      }}/>
    } else {
      return oldIcon;
    }
  }

  return (
    <>
      {renderIcon()}
    </>
  )
}

export default InformIconsPipe;
