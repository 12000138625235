import React, { useEffect, useRef, useState } from 'react';
import { ContentState, Editor, EditorState, Modifier } from "draft-js";
import './calculated-formula-editor.scss'
import { useFormContext, useWatch } from "react-hook-form";

const CalculatedFormulaEditor = (props: { onChange: any, value: string, groupIndex: number, index: number }) => {
  const operations = [
    {
      icon: <i className="bi bi-plus"/>,
      value: "+"
    },
    {
      icon: <i className="bi bi-dash"/>,
      value: "-"
    },
    {
      icon: <span style={{fontSize: 12}}>*</span>,
      value: "*"
    },
    {
      icon: <i className="bi bi-slash"/>,
      value: "/"
    },
    {
      icon: <span style={{fontSize: 10}}>(</span>,
      value: "("
    },
    {
      icon: <span style={{fontSize: 10}}>)</span>,
      value: ")"
    }
  ]


  const {control} = useFormContext()
  const metrics = useWatch({control: control, name: `groups.${props.groupIndex}.metrics`});


  const {onChange, value} = props;
  const [editorState, setEditorState] = useState(value?.length ? EditorState.createWithContent(ContentState.createFromText(value)) : EditorState.createEmpty());

  const editorRef = useRef(null)
  //
  // useEffect(() => {
  //   if (editorRef.current) {
  //     // @ts-ignore
  //     editorRef?.current?.focus();
  //   }
  // }, []);

  useEffect(() => {
    onChange(editorState.getCurrentContent().getPlainText())
  }, [editorState]);

  function insertCharacter(editorState: EditorState, character: string) {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const ncs = Modifier.insertText(contentState, selection, character);
    const es = EditorState.push(editorState, ncs, 'insert-characters');
    setEditorState(EditorState.forceSelection(es, es.getSelection()))
  }

  return (
    <div className="calculated-formula-editor">
      <Editor editorState={editorState}
              ref={editorRef}
              ariaLabel={"formula-editor"}
              placeholder={"Ex: (M1*M2*M3*(200-M4))/100"}
              onChange={(val) => {
                setEditorState(val)
              }
              }/>
      <div className="calculated-formula-editor-operations">
        <div className="row" style={{gap: 5, marginBottom: 5, flexWrap: "wrap"}}>
          {
            operations.map((operation, index) => {
              return <div className="calculated-formula-operation relative" key={index}
                          onClick={() => insertCharacter(editorState, operation.value)}>
                {operation.icon}
              </div>
            })
          }
          {
            metrics?.length && metrics.filter((metric: any, index: number) => index !== props.index).map((metric: any, index: number) => {
              return <div className="calculated-formula-operation relative" style={{fontSize: 10}} key={`metric-${index}`}
                          onClick={() => insertCharacter(editorState, metric.code)}>
                {metric.code}
              </div>
            })
          }
        </div>
        <span>You can use "+", "-", "*" and "/" operators by typing them on your keyboard.</span>
      </div>
    </div>
  );
};

export default CalculatedFormulaEditor;
