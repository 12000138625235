import React from 'react';
import { ITeamMember } from '../../../../../../settings/settings-tabs/team/model/team-member.interface';

export function DisplayMention(m: any): JSX.Element {
  const mention: ITeamMember = m.mention;
  return (
    <div className="display-highlight-card">
      <div className="mention">
        {mention.displayName}
      </div>
    </div>
  );
}
