import React from 'react';
import DefaultImport from "./default-import/default-import";
import ExternalImport from "./external-import/external-import";

interface IPage {
  title: string;
  content: JSX.Element;
}

const QuestionProImports = () => {
  const pages: IPage[] = [
    {
      title: "Default import",
      content: <DefaultImport/>
    },
    {
      title: "External import",
      content: <ExternalImport/>
    }
  ]


  return (
    <div className="setup column">
      {pages.map((page: IPage, i: number) =>
        <div className="main-title" key={i}>
          <h4>{page.title}</h4>
          <div className="page">
            {page.content}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionProImports;
