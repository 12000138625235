// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key-finding-details-dialog-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.key-finding-details-dialog-wrapper .key-finding-detail-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #9B9B9B;
  margin-bottom: 0;
}
.key-finding-details-dialog-wrapper .key-finding-detail-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #545E6B;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/project-edit/tabs/insights/nuggets/key-finding-details-dialog/key-finding-details-dialog.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAAJ","sourcesContent":[".key-finding-details-dialog-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  .key-finding-detail-label {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n    color: #9B9B9B;\n    margin-bottom: 0;\n  }\n\n  .key-finding-detail-info {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #545E6B;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
