import React from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation, } from "react-color/lib/components/common";
import ChromeFields from './chrome-fields/chrome-fields';
import "./color-picker.scss"


function MyColorPicker({hex, hsl, hsv, rgb, onChange}: any) {
  const colors = ['#332288', '#0077BB', '#88CCEE', '#44AA99', '#117733', '#999933', '#DDAA33', '#CC6677', '#882255', '#AA4499'];
  

  const setColor = (color: string) => {
    onChange({
      hex: color,
      source: "hex"
    })
  }

  let saturationProps = {hsl, hsv}
  let hueProps = {hsl};

  return <div className='custom-picker-container'>
    <div className='saturation'>
      {/*// @ts-ignore*/}
      <Saturation {...saturationProps} onChange={onChange} pointer={() => <div className='chrome-pointer-circle'/>}/>
    </div>

    <div className="hue-circle">
      <div className='color-circle' style={{backgroundColor: hex}}/>
      <div className='hue'>
        {/*// @ts-ignore*/}
        <Hue {...hueProps} onChange={onChange} pointer={() => <div className='chrome-pointer'/>}/>
      </div>
    </div>
    <ChromeFields
      rgb={rgb}
      hsl={hsl}
      hex={hex}
      onChange={onChange}
    />
    <div className="colors">
      <div className="row flex-wrap justify-space-center">
        {colors.map((color: string, i: number) => {
          const setActiveBorder = color.toLowerCase() === hex
          return (
            <button
              data-testid={`color-button-${i}`}
              key={i}
              style={{border: setActiveBorder ? '1px solid ' + color : '1px solid rgba(216, 216, 216, 0.5)'}}
              onClick={() => setColor(color)}
              className="radio-button">
              <div style={{backgroundColor: color && color}} className="radio-button-inner"/>
            </button>
          )
        })}
      </div>
    </div>
  </div>;
}

export default CustomPicker(MyColorPicker);
