import { Button } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./home-initial-sync.scss";
import SvgInsightDesk from "../../../../assets/icons/js/InsightDesk";
import SvgAddBig from "../../../../assets/icons/js/addBig";
import SvgExit from "../../../../assets/icons/js/Exit";
import GlobalContext from "../../../../store/global-context";
import SyncTypeBox from "./components/sync-type-box/sync-type-box";
import SvgCreateProjectBig from "../../../../assets/icons/js/CreateProjectBig";
import SvgQPLogoBig from "../../../../assets/icons/js/QPLogoBig";
import SvgImportBig from "../../../../assets/icons/js/ImportBig";
import { WuAppHeader } from "@questionproext/wick-ui-lib";

const HomeInitialSync = () => {
  const [syncSurveysLoading, setSyncSurveysLoading] = React.useState(false);
  const syncTypes = [
    {
      // image: NewProjectImage,
      icon: <SvgCreateProjectBig/>,
      title: 'Create a new project',
      text: 'Build your research from the ground up.',
      button: <Button className='row align-items-center' type='ghost'
                      onClick={() => navigate('/home/project', {state: {fromInitialPage: true}})}
                      icon={<SvgAddBig width={14} height={14} color={'#1E87E6'}/>}>
        New project
      </Button>,
    },
    {
      // image: SyncSurveysImage,
      icon: <SvgQPLogoBig/>,
      title: 'Sync with QuestionPro Surveys',
      text: 'Link your QuestionPro Surveys for insightful knowledge flow.',
      button: <Button className='row align-items-center' type='ghost'
                      onClick={() => onSyncSurveys()}
                      loading={syncSurveysLoading}
                      icon={<SvgInsightDesk width={14} height={14} color={'#1E87E6'}/>}>
        Sync surveys
      </Button>,
      isBetaFeature: true
    },
    {
      // image: ExternalDataImage,
      icon: <SvgImportBig/>,
      title: 'Import external data',
      text: 'Synchronize your non-QuestionPro data for centralized management.',
      button: <Button className='row align-items-center' type='ghost'
                      onClick={() => navigate('import-external-data')}
                      icon={<SvgExit width={14} height={14} color={'#1E87E6'}/>}>
        Import
      </Button>,
      isBetaFeature: true
    }
  ]

  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const onSyncSurveys = () => {
    setSyncSurveysLoading(true);
    delay(2300).then(() => {
      setSyncSurveysLoading(false);
      navigate('import-surveys');
    })
  }


  return (
    <div className="main column">
      <WuAppHeader activeProductName={"InsightsHub"}
                   onLogoutClick={() => globalContext.logoutUser()}
                   productCategories={globalContext.user.categories}
                   myAccount={globalContext.user.myAccount}/>
      <div className="container">
        <div className="main-wrapper">
          {/*<Suspense fallback={<Loader/>}>*/}
          <div className="home-welcome-page-wrapper">
            <div className="home-welcome-page-container">
              <h1>Welcome to InsightsHub!</h1>
              <p>Get started by populating your research, streamline collaboration, fast-track decisions, and
                boost
                productivity with AI-driven insights extraction and analysis.</p>
              <div className="row" style={{gap: 32}}>
                {
                  syncTypes.map((syncType, index) => {
                    return <SyncTypeBox {...syncType} key={index}/>
                  })
                }
              </div>
            </div>
          </div>
          {/*</Suspense>*/}
        </div>
      </div>
    </div>
  );
};

export default HomeInitialSync;
