import { InterviewLabsSettingsContextProvider } from './context/interview-labs-settings.context';
import { AddEditInterviewLab } from './add-edit-interview-lab/add-edit-interview-lab';

export function InterviewLabsSettings(): JSX.Element {
  return (
    <InterviewLabsSettingsContextProvider>
      <div className="interview-labs-settings">
        <AddEditInterviewLab/>
      </div>
    </InterviewLabsSettingsContextProvider>
  );
}
