import React from 'react';

export function DisplayNode(node: any): JSX.Element {
  return (
    <div className="display-highlight-card">
      {
        node?.node?.length && node.node.map((val: any, index: number) => {
          return (
            <div key={index} className="node">
              {val?.name}
            </div>
          );
        })
      }
    </div>
  );
}
