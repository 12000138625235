import React from 'react';
import TextFit from "../../../../../../_shared/components/text-fit/text-fit";
import SvgQpLogoIH from "../../../../../../assets/icons/js/qp-logo-ih";
import { ICustomTheme } from "../interface/custom-theme.interface";
import { format } from "date-fns";

const DefaultTheme: React.FC<ICustomTheme> = ({snapshotTheme, nugget, createdBy, createdAt, project}) => {
  return (
    <div className="relative column w-100 h-100 justify-space-between">
      <div className="logo-img-wrapper" style={{alignSelf: 'center'}}>
        {snapshotTheme?.logoUrl && (
          <img src={snapshotTheme.logoUrl} alt="Logo" style={{height: '48px'}}/>
        )}
      </div>

      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
      }}>
        <div className="key-finding-wrapper" style={{height: '231px', width: '100%'}}>
          <TextFit
            mode="multi"
            min={12}
            max={51}
            style={{
              height: '100%',
              width: '100%',
              lineHeight: 1.5,
              textAlign: 'center',
              fontWeight: '500'
            }}>
            {nugget}
          </TextFit>
        </div>
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <div className="project-details-wrapper">
          {
            snapshotTheme.showDetails &&
            <>
              {project}
              <div style={{fontWeight: 500}}>
                - {createdBy.displayName} , {format(new Date(createdAt), 'MMMM yyyy')}
              </div>
            </>
          }
        </div>
        <div className="qp-logo-wrapper">
          <SvgQpLogoIH/>
        </div>
      </div>
    </div>
  );
};

export default DefaultTheme;
