import React from 'react';
import { ISearchUser } from "../../../models/search-users.interface";
import '../../notepads/notepad-search-card/notepad-search-card.scss'
import UserAvatar from "../../../../../../../_shared/components/user-avatar/user-avatar";
import { format } from "date-fns";

const UserCard = (props: { user: ISearchUser }) => {
  const {user} = props;

  return (
    <div className='notepad-search-container'>
      {/*<span className='notepad-matches'>{story.matches} {notepad.matches === 1 ? 'match' : 'matches'}</span>*/}
      <div className='notepad-search-card-wrapper'>
        <div className='notepad-search-name'>
          <UserAvatar user={user as any}/>
          <span className='notepad-search-name-title'
                dangerouslySetInnerHTML={{__html: user.firstName ? user.firstName + " " + user.lastName : user.emailAddress}}/>
        </div>
        <div className='' style={{marginTop: 10, color: "#545e6b", fontWeight: 300}}>
          {format(new Date(user.createdAt), 'MMM d, yyyy')}
        </div>
      </div>
    </div>
  )
};

export default UserCard;
