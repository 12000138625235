import React, { useEffect, useState } from "react";
import { SortAscendingOutlined, SortDescendingOutlined, } from "@ant-design/icons";
import { Button, Checkbox, Divider, Input } from "antd";
import "./filter-dropdown.scss";
import SvgSearch from '../../../assets/icons/js/Search';
import { FilterDropdownProps } from "antd/es/table/interface";

export interface CustomFilterDropdownProps extends FilterDropdownProps {
  params: any;
  sort: any;
  handleSortChange: (sortName: string, order: string | undefined) => void;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  sortName: string;
  paramsValue?: string;
  options: any;
  title: string;
}

const FilterDropdown = (props: CustomFilterDropdownProps): JSX.Element => {
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any[]>(props.options);

  useEffect(() => {
    setFilteredOptions(props.options);
  }, [props.options]);

  const handleChange = (checkedValues: any) => {
    setSelectedValues(checkedValues);
  };

  const handleReset = () => {
    props.setFilter("");
    setSearchValue("");
    setSelectedValues([]);
    props.handleSortChange(props.sortName, undefined);
    props.confirm();
  };

  const handleApply = () => {
    props.setFilter(selectedValues.toString());
    props.confirm();
  };

  const handleSortAsc = (): void => {
    props.handleSortChange(props.sortName, "asc");
    props.confirm();
  };

  const handleSortDsc = (): void => {
    props.handleSortChange(props.sortName, "desc");
    props.confirm();
  };

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value);
    setFilteredOptions(
      props.options.filter((option: any) => {
        return (
          option?.title?.toLowerCase()?.includes(e.target.value)
        );
      })
    );
  };

  return (
    <div className="filter-container">
      <div className="filter-sorter">
        <div
          onClick={handleSortAsc}
          style={{color: props.sort === props.sortName + ":asc" ? "#1b87e6" : "#545e6b",}}
          className="filter-sorter-asc">
          <SortAscendingOutlined/> Sort A-Z
        </div>
        <div
          onClick={handleSortDsc}
          style={{color: props.sort === props.sortName + ":desc" ? "#1b87e6" : "#545e6b"}}
          className="filter-sorter-dsc">
          <SortDescendingOutlined/> Sort Z-A
        </div>
      </div>
      <Divider className="divider"/>
      <div className="filter-filters-wrapper">
        <div className="filter-filters-search">
          <div className="filter-title">Filter By {props.title}</div>
          {props.options?.length >= 7 && (
            <Input
              style={{width: 200, marginBottom: 10}}
              value={searchValue}
              placeholder="Search"
              suffix={<SvgSearch/>}
              onChange={handleSearchChange}
            />
          )}
        </div>
        <div className="filter-filters" style={{maxHeight: 200}}>
          <Checkbox.Group value={selectedValues} onChange={handleChange}>
            {filteredOptions?.map((option: any, i: number) => {
              return (
                <div key={i} style={{marginBottom: 8}}>
                  <Checkbox value={option.id} style={{color: "#545e6b"}}>
                    {option?.name || option?.displayName || option?.title}
                  </Checkbox>
                </div>
              );
            })}
          </Checkbox.Group>
        </div>
        <Divider className="divider"/>
        <div className="filter-footer">
          <Button type="link" onClick={handleReset}>
            Clear
          </Button>
          <Button type="primary" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;
