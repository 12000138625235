import { AxiosResponse } from "axios";
import { axiosClient } from "../../../axios-client";
import { IRepoFile } from "../model/repo-file.interface";
import { environment } from "../../../environment";
import { IRepository } from "../model/repository.interface";
import { IPagination } from "../../../core/model/pagination.interface";

class RepositoriesApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getRepositories(params: { page: number, pageSize: number }): Promise<{
    content: IRepository[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<{ content: IRepository[], pagination: IPagination }> = await axiosClient.get<{
        content: IRepository[],
        pagination: IPagination
      }>(`${this.baseUrl}/repository`, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getArchivedFiles(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/repository/archived`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createRepository(payload: { name: string }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.post<any>(`${this.baseUrl}/repository`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async updateRepository(repositoryId: number, payload: { name: string }): Promise<any> {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/repository/${repositoryId}`, payload)
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async deleteRepository(repositoryId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(`${this.baseUrl}/repository/${repositoryId}`)
      return response.data
    } catch (error) {
      throw error;
    }
  }

  async markAsPrivate(repositoryId: number, fileId: number, privacy: 0 | 1): Promise<any> {
    try {
      const response: AxiosResponse<any> =
        await axiosClient.put(`${environment.backendUrl}/repository/privacy/${repositoryId}/${fileId}`, {
          privacy
        });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getRepositoryFiles(repositoryId: number, params?: { page: number, pageSize: number }): Promise<{
    content: IRepoFile[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<{ content: IRepoFile[], pagination: IPagination }> = await axiosClient.get<{
        content: IRepoFile[],
        pagination: IPagination
      }>(`${this.baseUrl}/repository/files/${repositoryId}`, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async archiveFile(repositoryId: number, fileId: number): Promise<any> {
    try {
      const response: AxiosResponse<IRepoFile[]> = await axiosClient.delete<IRepoFile[]>(`${this.baseUrl}/repository/files/${repositoryId}/${fileId}`);
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async restoreFile(repositoryId: number, fileId: number): Promise<any> {
    try {
      const response: AxiosResponse<IRepoFile[]> = await axiosClient.put<IRepoFile[]>(`${this.baseUrl}/repository/files/${repositoryId}/restore/${fileId}`);
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async emptyRecycleBin(): Promise<any> {
    try {
      const response: AxiosResponse<IRepoFile[]> = await axiosClient.post<IRepoFile[]>(`${this.baseUrl}/file-repository/empty`);
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async bulkArchiveFiles(repositoryId: number, fileIds: number[]) {
    try {
      const response: AxiosResponse<IRepoFile[]> = await axiosClient.put<any>(`${this.baseUrl}/repository/files/${repositoryId}/delete-bulk`, {fileIds});
      return response.data;
    } catch (error) {
      throw error
    }
  }

  // async bulkRestore(repositoryId: number, fileIds: number[]) {
  //   try {
  //     const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/repository/files/${repositoryId}/ restore/bulk`, {
  //       fileIds
  //     },);
  //     return response.data;
  //   } catch (error) {
  //     throw error
  //   }
  // }


  async getProjects(): Promise<{ id: number, name: string }[]> {
    try {
      const response: AxiosResponse<{ id: number, name: string }[]> = await axiosClient.get<{
        id: number,
        name: string
      }[]>(`${this.baseUrl}/snapshots/projects`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async assignFilesToProject(projectId: number, filesIds: number[]): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/projects/attach-files/${projectId}`, {
        filesIds
      });

      return response.data;
    } catch (err) {
      throw err
    }
  }

  async getArchivedRepositories(params?: { page: number, pageSize: number }): Promise<{
    content: IRepository[],
    pagination: IPagination
  }> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/repository/archived`, {
        params
      });
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async bulkArchiveRepositories(repositoryIds: number[]) {
    try {
      const response: AxiosResponse<any> = await axiosClient.delete<any>(`${this.baseUrl}/repository/bulk-archive`, {
        data: {repositoryIds}
      });
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async restoreRepository(repositoryId: number) {
    try {
      const response = await axiosClient.put(`${this.baseUrl}/repository/restore/${repositoryId}`);
      return response.data;

    } catch (error) {
      throw error;
    }
  }

  async bulkRestoreRepositories(repositoryIds: number[]) {
    try {
      const response = await axiosClient.put(`${this.baseUrl}/repository/bulk-unarchive`, {
        repositoryIds
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getArchivedRepositoryFiles(repositoryId: number, params?: { page: number, pageSize: number }): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/repository/files/${repositoryId}/archived`, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getArchivedRepositoriesCount(): Promise<number> {
    try {
      const response: AxiosResponse<number> = await axiosClient.get<number>(`${this.baseUrl}/repository/archived/count`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getRepositoryInfo(repositoryId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.get<any>(`${this.baseUrl}/repository/archived/${repositoryId}/files-count`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async bulkRestoreFiles(repositoryId: number, fileIds: number[]) {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(`${this.baseUrl}/repository/files/${repositoryId}/restore/bulk`, {
        fileIds
      });
      return response.data;
    } catch (error) {
      throw error
    }
  }
}

export default new RepositoriesApi()
