// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nugget-search-container .nugget-search-card-wrapper {
  width: 100%;
  min-height: 80px;
  border: 1px solid #e8e8e8;
  border-left: 5px solid #e8e8e8;
  padding: 14px 10px;
  display: flex;
  gap: 10px;
}
.nugget-search-container .nugget-search-card-wrapper .nugget-search-card .nugget-search-description {
  color: #545e6b;
  font-size: 16px;
  display: inline;
}
.nugget-search-container .nugget-search-card-wrapper .nugget-search-card .nugget-search-tags {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}
.nugget-search-container .nugget-search-card-wrapper .nugget-search-card .nugget-search-tags .nugget-search-tag {
  background-color: #d8d8d8;
  color: #545e6b;
  padding: 1px 8px;
  font-size: 14px;
}
.nugget-search-container .nugget-matches {
  color: #D4D4D4;
  font-weight: 600;
  margin-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/search-results/components/nuggets/nugget-search-card/nugget-search-card.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;AAAJ;AAGM;EACE,cAAA;EACA,eAAA;EACA,eAAA;AADR;AAIM;EACE,gBAAA;EACA,aAAA;EACA,SAAA;AAFR;AAIQ;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAFV;AASE;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AAPJ","sourcesContent":[".nugget-search-container {\n  .nugget-search-card-wrapper {\n    width: 100%;\n    min-height: 80px;\n    border: 1px solid #e8e8e8;\n    border-left: 5px solid #e8e8e8;\n    padding: 14px 10px;\n    display: flex;\n    gap: 10px;\n\n    .nugget-search-card {\n      .nugget-search-description {\n        color: #545e6b;\n        font-size: 16px;\n        display: inline;\n      }\n\n      .nugget-search-tags {\n        margin-top: 10px;\n        display: flex;\n        gap: 10px;\n\n        .nugget-search-tag {\n          background-color: #d8d8d8;\n          color: #545e6b;\n          padding: 1px 8px;\n          font-size: 14px;\n        }\n      }\n    }\n\n  }\n\n  .nugget-matches {\n    color: #D4D4D4;\n    font-weight: 600;\n    margin-bottom: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
