// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unsplash-label {
  color: #9b9b9b;
  font-weight: 300;
}
.unsplash-label a {
  color: inherit;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/preview-story/preview-story-editor/strategies/image-strategy/preview-image-strategy.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;AACF;AACE;EACE,cAAA;EACA,0BAAA;AACJ","sourcesContent":[".unsplash-label {\n  color: #9b9b9b;\n  font-weight: 300;\n\n  a {\n    color: inherit;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
