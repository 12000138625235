import React, { useState } from 'react';
import './usage-metrics.scss';
import { Empty, Spin } from 'antd';

export function UsageMetrics(props: any): JSX.Element {
  const analytics = props?.analytics;

  return (
    <div className="metric-content-wrapper">
      <div className="metric-card metric-content metric-table-content">
        <div className="metric-table-inner-content">
          <p className="metric-table-p">Commonly paired with</p>
          <div className="metric-table-ui">
            {props.changeNodeLoader ?
              <div className="row justify-space-center align-items-center">
                <Spin/>
              </div> :
              analytics.commonlyNodes.length > 0 ?
                analytics.commonlyNodes.map((commonlyNodes: any, i: number) => {
                  return (
                    <div key={i} className="metric-table-row d-flex">
                      <div className="metric-table-text fxFlex">
                        {commonlyNodes.name}
                      </div>
                      <div className="metric-table-amount">
                        {commonlyNodes.percentage}
                      </div>
                    </div>
                  );
                }) :
                <div className="w-100 column justify-space-center">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                </div>
            }
          </div>
        </div>
      </div>


      <div className="metric-card metric-content metric-table-content">
        <div className="metric-table-inner-content">
          <p className="metric-table-p">Top contributors</p>
          <div className="metric-table-ui">
            {props.changeNodeLoader ?
              <div className="row justify-space-center align-items-center">
                <Spin/>
              </div> :
              analytics.collaborators.length > 0 ?
                analytics.collaborators.map((collaborator: any, i: number) => {
                  return (
                    <div className="metric-table-row d-flex" key={i}>
                      <div className="metric-table-text fxFlex">
                        {collaborator.username}
                      </div>
                      <div className="metric-table-amount">
                        {collaborator.percentage}
                      </div>
                    </div>
                  );
                }) :
                <div className="w-100 column justify-space-center">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
