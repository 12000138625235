import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import { getGraphSelectionByEntityMapId } from "./entity";

export function insertGraphEntity(
  editorState: EditorState,
  data: {
    title: string;
    highChartJSON: string;
    entityKey?: string;
    entityMapId?: number,
    surveyInfo: any,
    selectedChart: any
  },
  isDelete?: boolean
) {
  if (isDelete && data?.entityKey && data?.entityMapId) {
    const selectionState: any = getGraphSelectionByEntityMapId(
      editorState,
      data.entityMapId
    );

    const newContentState = Modifier.applyEntity(
      editorState.getCurrentContent(),
      selectionState,
      null
    );

    return EditorState.push(editorState, newContentState, "apply-entity");
  } else {


    const entityKey = editorState
      .getCurrentContent()
      .createEntity("GRAPH", "MUTABLE", {
        selectedChart: data.selectedChart,
        surveyInfo: data.surveyInfo,
        title: data.title,
        highChartJSON: data.highChartJSON,
        entityMapId: Math.floor(100000 + Math.random() * 900000),
      })
      .getLastCreatedEntityKey();

    const newEditorState: EditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );
    return newEditorState;
  }
}
