// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additional-feedback-dialog-wrapper .outline-input-wrapper label {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #545e6b;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/stories/story-edit/story-editor/components/ai-input/additional-feedback-dialog/additional-feedback-dialog.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AADN","sourcesContent":[".additional-feedback-dialog-wrapper {\n  .outline-input-wrapper {\n    label {\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 16px;\n      text-align: left;\n      color: #545e6b;\n      margin-bottom: 15px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
