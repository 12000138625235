// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desk-settings .header {
  padding-bottom: 30px;
}
.desk-settings .header .description {
  size: 14px;
  color: #9B9B9B;
  font-weight: 300;
}
.desk-settings .body {
  overflow-y: auto;
}
.desk-settings .body .ant-tabs-content {
  height: calc(100vh - 48px - 32px - 73px - 46px - 36px);
}
.desk-settings .body .ant-tabs-nav-list {
  width: 100%;
}
.desk-settings .body .ant-tabs-nav-list > div:nth-child(8) {
  position: absolute;
  right: 0;
}
@media (max-width: 1565px) {
  .desk-settings .body .ant-tabs-nav-list > div:nth-child(8) {
    position: relative;
    right: unset;
  }
}
.desk-settings .body .archived {
  padding-right: 25px;
  align-items: center;
  color: #9b9b9b;
}
.desk-settings .body .archived span {
  font-size: 14px;
  padding-right: 10px;
}

.custom-loading-message {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  background: #CCF0FF;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.custom-message .ant-message-notice-content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings.scss"],"names":[],"mappings":"AAEE;EACE,oBAAA;AADJ;AAGI;EACE,UAAA;EACA,cAAA;EACA,gBAAA;AADN;AAKE;EACE,gBAAA;AAHJ;AAKI;EACE,sDAAA;AAHN;AAMI;EACE,WAAA;AAJN;AAMM;EACE,kBAAA;EACA,QAAA;AAJR;AAQI;EAGI;IACE,kBAAA;IACA,YAAA;EARR;AACF;AAYI;EACE,mBAAA;EACA,mBAAA;EACA,cAAA;AAVN;AAYM;EACE,eAAA;EACA,mBAAA;AAVR;;AAgBA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EAEA,+CAAA;AAdF;;AAkBE;EACE,UAAA;AAfJ","sourcesContent":[".desk-settings {\n\n  .header {\n    padding-bottom: 30px;\n\n    .description {\n      size: 14px;\n      color: #9B9B9B;\n      font-weight: 300;\n    }\n  }\n\n  .body {\n    overflow-y: auto;\n\n    .ant-tabs-content {\n      height: calc(100vh - 48px - 32px - 73px - 46px - 36px);\n    }\n\n    .ant-tabs-nav-list {\n      width: 100%;\n\n      & > div:nth-child(8) {\n        position: absolute;\n        right: 0;\n      }\n    }\n\n    @media (max-width: 1565px) {\n      .ant-tabs-nav-list {\n\n        & > div:nth-child(8) {\n          position: relative;\n          right: unset;\n        }\n      }\n    }\n\n    .archived {\n      padding-right: 25px;\n      align-items: center;\n      color: #9b9b9b;\n\n      span {\n        font-size: 14px;\n        padding-right: 10px;\n      }\n    }\n  }\n}\n\n.custom-loading-message {\n  display: flex;\n  padding: 16px;\n  align-items: center;\n  gap: 16px;\n  background: #CCF0FF;\n  width: fit-content;\n\n  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);\n}\n\n.custom-message {\n  .ant-message-notice-content {\n    padding: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
