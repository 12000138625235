import { CompositeDecorator } from 'draft-js';
import { LinkStrategy } from './strategies/link-strategy';
import { NodeStrategy } from './strategies/node-strategy';
import { TaskStrategy } from './strategies/task-strategy';
import { FlagStrategy } from './strategies/flag-strategy';
import { EvidenceStrategy } from './strategies/evidence-strategy';

export const compositeDecorator = (readOnly?: boolean, openKnowledgeGraph?: any, openEvidenceDialog?: any, plugins?: any) => new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
      }, callback);
    },
    component: LinkStrategy,
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "NODE";
      }, callback);
    },
    component: NodeStrategy,
    props: {readOnly, openKnowledgeGraph}
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "TASK";
      }, callback);
    },
    component: TaskStrategy,
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "FLAG";
      }, callback);
    },
    component: FlagStrategy,
    // props: {openFlagDropDownDialog}
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "EVIDENCE";
      }, callback);
    },
    component: EvidenceStrategy,
    props: {readOnly, openEvidenceDialog}
  },
]);
