import React, { useContext, useEffect } from 'react';
import './login.scss';
import GlobalContext from '../../../store/global-context';
import SvgServerError from "../../../assets/icons/js/server-error";

export default function Login() {
  const GlobalStore = useContext(GlobalContext);
  const qpCode = localStorage.getItem('qpcode');
  const paramQPCode = new URLSearchParams(window.location.search).get('code') ?? '';
  const is503 = new URLSearchParams(window.location.search).get('503') ?? '';

  useEffect(() => {
    if (is503) {
      console.log(is503);
    }

    if (paramQPCode) {
      GlobalStore.logIn(paramQPCode);
      return;
    }

    if (qpCode) {
      GlobalStore.logIn(qpCode);
      return;
    }

    GlobalStore.redirectToQPLogin();
  }, []);

  return (
    <div className="login column">

      {
        is503 ?
          <div className="error-page row align-items-center justify-space-center">
            <div className="error-message column">
              <h1 className="error-page-title">InsightsHub</h1>
              <h2 className="error-page-reason">Sorry, our server is currently down for maintenance.</h2>

              <div className="column">
                <span className="error-code"><b>Error 503</b> - Service Unavailable</span>
                <span>Our servers are currently undergoing maintenance or are temporarily unavailable. Please try again later.</span>
              </div>

              <div className="row sign-in">
                <span>Retrying to connect...</span>
              </div>
            </div>
            <div className="column logo">
              <SvgServerError/>
            </div>
          </div>
          :
          <>
            <h1>Logging in...</h1>
            <div>
              <small>If you're stuck on this page or if login failed, please</small>
              <span className="link" onClick={GlobalStore.redirectToQPLogin}> Try again.</span>
            </div>
          </>
      }
    </div>


  )
}
