import { DraftDecoratorComponentProps } from 'draft-js'
import React from 'react'
import './iframe-strategy.scss'

const IFrameStrategy = (props: DraftDecoratorComponentProps | any) => {
  let url: any;
  let urlSrc: string = ''
  let urlHeight: string = ''

  if (props.entityKey != null) {
    url = props.contentState.getEntity(props?.entityKey).getData().url;
  } else {
    return null;
  }

  if (url) {
    urlSrc = url?.split('src=\'').pop().split('\'')[0];
    urlHeight = url?.split('height=\'').pop().split('\'')[0];
  }

  return (
    <>
      <div className="d-flex justify-space-center">
        {
          urlSrc?.length > 1 &&
            <iframe id='myIframe'
                    title={urlSrc}
                    src={urlSrc} width="90%"
                    frameBorder="none"
                    scrolling="no"
                    height={urlHeight}
                    style={{border: 'none', display: 'block'}}/>
        }
      </div>
    </>
  )
}

export default IFrameStrategy
