// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-details-wrapper {
  width: 920px;
  padding: 32px;
  border-radius: 8px;
  background-color: #FFF;
}
.import-details-wrapper .import-details {
  padding: 0 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.import-details-wrapper .import-details h3 {
  color: #545E6B;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
  margin-bottom: 2px;
}
.import-details-wrapper .import-details p {
  color: #9B9B9B;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/home-initial-sync/pages/sync-with-surveys/components/import-survey-details/import-survey-details.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,aAAA;EACA,kBAAA;EACA,sBAAA;AAAF;AAGE;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;AAGI;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,kBAAA;AADN;AAII;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,gBAAA;AAFN","sourcesContent":[".import-details-wrapper {\n  width: 920px;\n  //height: 242px;\n  padding: 32px;\n  border-radius: 8px;\n  background-color: #FFF;\n  //gap: 48px;\n\n  .import-details {\n    padding: 0 64px;\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n\n    h3 {\n      color: #545E6B;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 21px; /* 150% */\n      margin-bottom: 2px;\n    }\n\n    p {\n      color: #9B9B9B;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 16px; /* 133.333% */\n      margin-bottom: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
