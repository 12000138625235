import * as React from "react";

const SvgGoogleDrive = (props: any) => {
  return (
    <svg width={32} height={27} viewBox="0 0 32 27" {...props}>
      <title>Google Drive</title>
      <g id="The-one" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="6-Drive-Integration" transform="translate(-763.000000, -354.000000)">
          <g id="Group-3" transform="translate(763.000000, 354.000000)">
            <polygon id="Path" fill="#2684FC"
                     points="32 18.6923077 26.9090909 27 7.63636364 27 12.7272727 18.6923077"/>
            <polygon id="Path" fill="#00AC47"
                     points="9.81818182 2.42307692 14.9090909 10.7307692 5.09090909 27 0 18.6923077 0 18.3461538"/>
            <polygon id="Path" fill="#FFBA00"
                     points="21.0909091 0 31.2727273 16.6153846 21.0909091 16.6153846 10.9090909 0"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgGoogleDrive;
