// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folder-section-wrapper {
  width: 920px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 32px;
  border-radius: 8px;
  background-color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/home-initial-sync/pages/sync-with-surveys/components/folders-section/folder-section.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,wBAAA;EAAA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AACF","sourcesContent":[".folder-section-wrapper {\n  width: 920px;\n  height: fit-content;\n  padding: 32px;\n  border-radius: 8px;\n  background-color: #FFF;\n\n  //gap: 48px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
