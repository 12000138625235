import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { notification } from 'antd';
import { environment } from './environment';

const token = localStorage.getItem('access_token');
let errorCount: number = 0;

export const axiosClient = axios.create({
  baseURL: environment.backendUrl
});

function redirectToQPLogin(): void {
  const keysToRemove = [
    'qpcode',
    'isLoggedIn',
    'access_token',
    'monitor',
    'partnerOrganizations',
    'isPartner',
    'selectedOrganization'
  ];

  keysToRemove.forEach(key => localStorage.removeItem(key));
  window.location.replace(`${environment.oauthUrl}/?client_id=${environment.oauthClientId}`);
}

axiosClient.interceptors.request.use((request: AxiosRequestConfig) => {
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
    request.headers['ts'] = performance.now();
  }

  return request;
});

axiosClient.interceptors.response.use((response: AxiosResponse) => {
  if (errorCount !== 0) {
    errorCount = 0;
  }

  const monitor: string | null = localStorage.getItem('monitor');
  const data: any[] = monitor ? JSON.parse(monitor) : [];

  const startTime = response?.config?.headers['ts'];
  const endTime = performance.now();
  const duration = Math.trunc(endTime - startTime);

  if (startTime) {
    const saveRes = {
      id: Math.floor(100000 + Math.random() * 900000),
      status: response.status,
      url: !response.config.url?.startsWith('https') ? `${response.config.baseURL}${response.config.url}` : response.config.url,
      method: response.config.method,
      contentLength: response.headers['content-length'],
      date: new Date(),
      duration,
    }

    data.push(saveRes);
    localStorage.setItem('monitor', JSON.stringify(data));
  }

  return response;
}, (error) => {
  const monitor: string | null = localStorage.getItem("monitor");
  const data: any[] = monitor ? JSON.parse(monitor) : [];

  const startTime = error?.response?.config?.headers["ts"];
  const endTime = performance.now();
  const duration = Math.trunc(endTime - startTime);

  if (startTime) {
    const saveRes = {
      id: Math.floor(100000 + Math.random() * 900000),
      status: error.response.status,
      url: !error.config.url?.startsWith("https")
        ? `${error.config.baseURL}${error.config.url}`
        : error.config.url,
      method: error.response.config.method,
      date: new Date(),
      duration,
    };

    data.push(saveRes);
    localStorage.setItem("monitor", JSON.stringify(data));
  }

  if (errorCount < 1 && error.response) {
    // console.log(error.response)
    notification.error({
      message: `${error?.response?.status} - ${error?.response?.statusText}`,
      description: `${error?.response?.data?.message} - 
      Error Id: ${error.response.data?.errorId}`,
      style: {borderColor: "red"},
      duration: 3,
    });
  }

  if (!error.response && error.message === 'Network Error') {
    const url = new URL(window.location.href);
    url.searchParams.set('503', '1');
    window.history.replaceState(null, '', url.toString());
  }

  if (error.response && error.response.status === 500) {
    window.location.replace(`/internal-error?id=${error.response.data.errorId}`);
  }

  // if (error.response && error.response.status === 404) {
  //   window.location.replace(`/not-found`);
  // }

  // if (error.response && error.response.status === 401 && error.response.data.message === "User is not active!") {
  //   redirectToQPLogin();
  // }

  if (error.response && error.response.status === 403) {
    errorCount += 1;

    if (errorCount === 1) {
      localStorage.setItem("isLoggedIn", "false");
      localStorage.setItem("current_location", window.location.href);
      window.location.replace(`/oauth`);
    }
  }

  return Promise.reject(error);
});
