import React from 'react';
import { Modal } from "antd";
import './key-finding-details-dialog.scss'
import { format } from "date-fns";
import SvgInsightDesk from "../../../../../../../assets/icons/js/InsightDesk";
import { truncateText } from "../../../../../../../_shared/helpers/truncate-text";
import { useNavigate } from "react-router-dom";
import FileTypePipe from "../../../../../../../_shared/helpers/file-type-pipe";
import { getFileType } from "../../../../../../../_shared/helpers/get-file-type";
import { ISearchFile } from "../../../../../../home/pages/search-results/models/search-file.interface";
import ProjectEditApi from "../../../../api/project-edit-api";
import { truncateTextInMiddle } from "../../../../../../../_shared/helpers/truncate-text-in-middle";
import { INugget } from "../../../../../models/nugget.interface";
import { FileTypeEnum } from "../../../../../tabs/_shared/model/file-type.enum";

type IProps = {
  open: boolean;
  data: { keyFinding: INugget };
  onCancel: () => void;
}

const KeyFindingDetailsDialog = (props: IProps) => {
  const navigate = useNavigate();
  const navigateToSurvey = () => {
    if (props.data.keyFinding?.surveyID) {
      navigate(`/surveys/details?surveyId=${props.data.keyFinding.surveyID}`)
    }
  }

  const downloadFile = (fileID?: number, fileName?: string): void => {
    if (!fileID || !fileName) {
      return
    }

    ProjectEditApi.downloadFile(fileID)
      .then((response: any) => {
        const filenamePath: string = response;
        const fileName2: string = `${fileName.toLowerCase()}`
        const downloadElement = document.createElement('a');
        document.body.appendChild(downloadElement); //required in FF, optional for Chrome
        downloadElement.href = filenamePath;
        downloadElement.download = fileName2;
        downloadElement.target = "_self";
        downloadElement.click();
      });
  }


  return (
    <Modal title="Key finding details"
           open={props.open}
           footer={[]}
           onCancel={props.onCancel}
    >
      <div className="key-finding-details-dialog-wrapper">
        {
          props.data.keyFinding?.reference &&
          <div>
            <p className="key-finding-detail-label">
              Source
            </p>
            <p className="key-finding-detail-info">
              QuestionPro survey
            </p>
          </div>
        }
        {
          props.data.keyFinding?.fileID && !props.data.keyFinding?.reference &&
          <div>
            <p className="key-finding-detail-label">
              Source
            </p>
            <p className="key-finding-detail-info">
              File
            </p>
          </div>
        }
        <div>
          <p className="key-finding-detail-label">
            Generated by
          </p>
          <p className="key-finding-detail-info">
            {props.data.keyFinding?.aiGenerated ?
              <div>Auto-generated</div> :
              <div>
                {props.data.keyFinding.createdBy?.displayName}
              </div>
            }
          </p>
        </div>
        {
          props.data.keyFinding?.fileName && props.data.keyFinding?.fileURL && !props.data.keyFinding?.reference &&
          <div>
            <p className="key-finding-detail-label">
              File reference
            </p>
            <p className="key-finding-detail-info row align-items-center">
              <span>
                <FileTypePipe className="row" width={16} height={16}
                              value={getFileType(props.data.keyFinding.fileName) as FileTypeEnum}/>
              </span>
              &nbsp;
              <button className="reset-btn pointer text-underline"
                      onClick={() => downloadFile(props.data.keyFinding.fileID, props.data.keyFinding.fileName)}>
                {truncateTextInMiddle(props.data.keyFinding.fileName, 50)}
              </button>
            </p>
          </div>
        }
        {
          props.data.keyFinding?.reference &&
          <div>
            <p className="key-finding-detail-label">
              Survey reference
            </p>
            <p className="key-finding-detail-info row align-items-center">
              <span className="row">
                <SvgInsightDesk/>
              </span>
              &nbsp;
              <button className="reset-btn pointer text-underline" onClick={() => navigateToSurvey()}>
                {props.data.keyFinding.reference}
              </button>
            </p>
          </div>
        }
        <div>
          <p className="key-finding-detail-label">
            Date of creation
          </p>
          <p className="key-finding-detail-info">
            {format(new Date(props.data.keyFinding.createdAt), 'MMMM do yyyy, h:mm a')}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default KeyFindingDetailsDialog;
