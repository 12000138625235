import { environment } from "../../../environment";
import { AxiosResponse } from "axios";
import { axiosClient } from "../../../axios-client";
import { ISurveyDetails, ISurveyTableData, ISurveyTableElement } from "../model/survey.interface";

class SurveysApi {
  baseUrl: string = `${environment.backendUrl}`;

  async getSurveys(params: {
    page: number,
    pageSize: number,
    orderBy?: string,
    orderDirection?: "ASC" | "DESC",
  }): Promise<ISurveyTableData> {
    try {
      const response: AxiosResponse<ISurveyTableData> = await axiosClient.get<ISurveyTableData>(
        this.baseUrl + `/organization-surveys`,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSurveyDetails(id: number): Promise<ISurveyDetails> {
    try {
      const response: AxiosResponse<ISurveyDetails> = await axiosClient.get<ISurveyDetails>(
        this.baseUrl + `/organization-surveys/${id}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSurveyDetailsFromSurveyId(surveyId: number): Promise<ISurveyDetails> {
    try {
      const response: AxiosResponse<ISurveyDetails> = await axiosClient.get<ISurveyDetails>(
        this.baseUrl + `/organization-surveys/survey/${surveyId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async importSurvey(payload: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axiosClient.put<any>(
        this.baseUrl + `/organization-surveys`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getGraphs(surveyId: number): Promise<{ highChartJSON: string, title: string }[]> {
    try {
      const response: AxiosResponse<{ highChartJSON: string, title: string }[]> = await axiosClient.get<{
        highChartJSON: string,
        title: string
      }[]>(
        this.baseUrl + `/organization-surveys/questions/results-all/${surveyId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SurveysApi()