import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import { getFigureSelectionByEntityMapId } from "./entity";

export function insertImageEntity(editorState: EditorState, data: {
  url: string;
  username?: string;
  userUrl?: string;
  entityKey?: string;
  entityMapId?: number;
}, isDelete?: boolean) {
  if (isDelete && data?.entityKey && data?.entityMapId) {
    const selectionState: any = getFigureSelectionByEntityMapId(
      editorState,
      data.entityMapId
    );

    const newContentState = Modifier.applyEntity(
      editorState.getCurrentContent(),
      selectionState,
      null
    );

    return EditorState.push(editorState, newContentState, "apply-entity");
  } else {
    const entityKey = editorState
      .getCurrentContent()
      .createEntity("FIGURE", "MUTABLE", {
        url: data.url,
        username: data.username,
        userUrl: data.userUrl,
        entityMapId: Math.floor(100000 + Math.random() * 900000),
      })
      .getLastCreatedEntityKey();

    const newEditorState: EditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );
    return newEditorState;
  }
}
