export function getMediaTypeByExtension(extension: string | undefined): string {
  const photoExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff"];
  const videoExtensions = ["mp4", "mov", "avi", "wmv", "flv", "mkv"];

  if (!extension) {
    return "unknown"
  }

  if (photoExtensions.includes(extension)) {
    return "photo";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}
