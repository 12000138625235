// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  padding: 10px 15px;
  text-align: center;
  width: 145px;
  height: 100px;
  background-color: #F5F5F5;
  color: #545E6B;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings-tabs/taxonomy/project-type/project-type-form/forms/upload/upload.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".upload-file {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  font-weight: 300;\n  padding: 10px 15px;\n  text-align: center;\n  width: 145px;\n  height: 100px;\n  background-color: #F5F5F5;\n  color: #545E6B;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
