// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
  padding: 8px;
  background-color: white;
}
.filter-container .divider {
  margin-bottom: 5px;
  margin-top: 5px;
}
.filter-container .filter-sorter-asc {
  cursor: pointer;
  margin-top: 5px;
}
.filter-container .filter-sorter-dsc {
  cursor: pointer;
  margin-top: 5px;
}
.filter-container .filter-filters-wrapper .filter-filters-search .filter-title {
  color: #545e6b;
  font-weight: 500;
  margin-bottom: 10px;
}
.filter-container .filter-filters-wrapper .filter-filters {
  overflow: auto;
  padding: 4px 0;
}
.filter-container .filter-footer {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/filter-dropdown/filter-dropdown.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,uBAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;AACJ;AAEE;EACE,eAAA;EACA,eAAA;AAAJ;AAGE;EACE,eAAA;EACA,eAAA;AADJ;AAMM;EACE,cAAA;EACA,gBAAA;EACA,mBAAA;AAJR;AAQI;EACE,cAAA;EACA,cAAA;AANN;AAUE;EACE,aAAA;EACA,yBAAA;AARJ","sourcesContent":[".filter-container {\n  padding: 8px;\n  background-color: white;\n\n  .divider {\n    margin-bottom: 5px;\n    margin-top: 5px;\n  }\n\n  .filter-sorter-asc {\n    cursor: pointer;\n    margin-top: 5px;\n  }\n\n  .filter-sorter-dsc {\n    cursor: pointer;\n    margin-top: 5px;\n  }\n\n  .filter-filters-wrapper {\n    .filter-filters-search {\n      .filter-title {\n        color: #545e6b;\n        font-weight: 500;\n        margin-bottom: 10px;\n      }\n    }\n\n    .filter-filters {\n      overflow: auto;\n      padding: 4px 0;\n    }\n  }\n\n  .filter-footer {\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
